import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useAddAddressMutation } from '../../../Create/Create'

import { AddressDetailsNonCustomerForm } from './AddressDetailsNonCustomer.Form'

export const AddressDetailsNonCustomer: React.FC = () => {
  const { t } = useTranslation('address-book/modals')

  const [{ addMultiAssetNonCustomerAddress }, { loading }] =
    useAddAddressMutation()

  return (
    <FullScreen totalSteps={4}>
      <Center className="text-center" variant="horizontal">
        <h2
          className="text-2xl text-black-900 font-circular-bold"
          data-testid="address-details-step-title"
        >
          {t`addAddress.addressDetails.non_customer_entity.title`}
        </h2>
        <h3
          className="mt-2 font-circular text-lg leading-6 text-black-400"
          data-testid="address-details-step-subtitle"
        >
          {t`addAddress.addressDetails.non_customer_entity.subtitle`}
        </h3>
      </Center>
      <AddressDetailsNonCustomerForm
        loading={loading}
        onSubmit={addMultiAssetNonCustomerAddress}
      />
    </FullScreen>
  )
}

import { DescriptionList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { CbitBankAccount } from '@shared/graphql'

const { Label, Description } = DescriptionList

export const Cbit: React.FC<CbitBankAccount> = ({ walletAddress }) => {
  const { t } = useTranslation('express/modal')

  return (
    <DescriptionList
      className="min-w-160"
      data-testid="cbit-bank-account"
      size="sm"
    >
      <Label className="w-64">{t`addRoute.review.label.bank`}</Label>
      <Description>{t`addRoute.review.label.cbit`}</Description>
      <Label className="w-64">{t`addRoute.review.label.cbitWalletAddress`}</Label>
      <Description>{walletAddress}</Description>
    </DescriptionList>
  )
}

import { Header, NumberBox } from '@circlefin/components'
import { TOTAL_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { TransWithLink, IconTooltip } from '@shared/components/common'
import { useBalancesTotalQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Locale } from '@circlefin/money/variants'

export const PaymentsPageHeaderNumbers: React.FC = () => {
  const { t } = useTranslation('payments')
  const { locale } = useRouter()

  const { data, loading } = useBalancesTotalQuery({
    pollInterval: TOTAL_BALANCE_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  const amount = data?.balances.total?.balance

  return (
    <Header.Numbers className="mt-7">
      {/* Total Balance */}
      <NumberBox loading={loading} size="lg">
        <NumberBox.Amount currency={amount?.currency} locale={locale as Locale}>
          {amount?.amount}
        </NumberBox.Amount>
        <NumberBox.Title>
          <span className="flex flex-row">
            {t`balance.totalAccountBalance.title`}
            <IconTooltip
              anchorId="total-balance-tooltip"
              closeEvents={['click']}
              content={
                <>
                  <p>{t`balance.totalAccountBalance.tooltip.p-1`}</p>
                  <p>
                    <TransWithLink
                      i18nKey="payments:balance.totalAccountBalance.tooltip.p-2"
                      urlI18nKey="payments:balance.totalAccountBalance.tooltip.link"
                      variant="url"
                    />
                  </p>
                </>
              }
              name="InformationCircleSolid"
              openEvent="click"
            />
          </span>
        </NumberBox.Title>
      </NumberBox>
    </Header.Numbers>
  )
}

import { Icon } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { User } from '@shared/graphql'

export interface ApprovalHelpSectionProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Hides the help section.
   */
  hidden?: boolean
  /**
   * Approvers.
   */
  approvers: User[]
  /**
   * Is approver in workflow.
   */
  isApproverInWorkflow: (approver: User) => boolean
}

export const ApprovalHelpSection: React.FC<ApprovalHelpSectionProps> = ({
  className,
  hidden,
  approvers,
  isApproverInWorkflow,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  if (hidden) {
    return null
  }

  return (
    <div
      className={classNames(
        'border-gradient from-purple-200 to-blue-300 rounded-sm border-2 p-4 w-56',
        className,
      )}
    >
      <h3 className="text-base leading-4 text-neutral-strong font-circular-extrablack">
        {t`approval.helpSection.title`}
      </h3>
      <p className="mt-2 text-xs text-neutral-strong text-opacity-70 font-circular-regular">
        {t`approval.helpSection.description`}
      </p>
      <ul className="mt-2 space-y-2">
        {approvers.map((approver) => (
          <li
            key={approver.id}
            className="flex items-center justify-start gap-x-2.5"
          >
            <div className="w-4">
              {isApproverInWorkflow(approver) ? (
                <Icon
                  className="text-green-400"
                  name="CheckCircleSolid"
                  size={16}
                />
              ) : (
                <Icon
                  className="text-black-200"
                  name="XCircleSolid"
                  size={16}
                />
              )}
            </div>

            <div className="flex flex-col gap-y-0.5 overflow-hidden">
              <div className="truncate text-xs text-neutral-strong font-circular-bold">
                {approver.name}
              </div>
              <div className="truncate text-xs text-neutral-subtle font-circular-regular">
                {approver.email}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

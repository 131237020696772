import { Toggle } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { SETTINGS_MUTATION } from '@services/permissions'

import type { ToggleProps } from '@circlefin/components/lib/Toggle'

// TODO: [LEX-1257] deprecate this, a shared component is created
// see @shared/components/common/Buttons/Toggles/Settings
export const Settings: React.FC<ToggleProps> = (props) => {
  const [, { isAuthorized }] = usePermission(SETTINGS_MUTATION)

  return <Toggle {...props} disabled={props.disabled ?? !isAuthorized} />
}

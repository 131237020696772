import { useCallback } from 'react'

import { Button, LottieIcon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const handleDone = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <FullScreen totalSteps={7}>
      <Center className="justify-items-center" variant="horizontal">
        <LottieIcon height={65} name="Check" width={65} />

        <div className="mt-8 w-100 text-center text-base leading-6 text-black-600">
          <h2 className="text-3xl font-circular-bold" data-testid="title">
            {t('common:success')}
          </h2>
          <p
            className="mt-5 text-xl font-circular-regular"
            data-testid="message"
          >{t`walletBackup.success.message`}</p>
        </div>

        <Center className="pt-14" variant="horizontal">
          <Button
            className="mr-6 w-60"
            onClick={handleDone}
            variant="secondary"
          >
            {t('common:done')}
          </Button>
        </Center>
      </Center>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { Button, LottieIcon } from '@circlefin/components'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { Center } from '@shared/components/layout'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { FullScreen } from '../FullScreen/FullScreen'

export interface SuccessQueryProps {
  /**
   * Title text.
   */
  title?: string
  /**
   * Message text.
   */
  message?: string
  /**
   * Route of page to redirect to on DONE.
   */
  onDoneRedirectTo?: string
}

export const Success: React.FC<SuccessQueryProps> = ({
  title,
  message,
  onDoneRedirectTo,
}) => {
  const { t } = useTranslation()
  const modal = useModal()
  const router = useRouter()
  const { history } = useModalHistory()

  const onDone = useCallback(() => {
    // if we have a `onDoneRedirectTo` url, navigate there
    if (onDoneRedirectTo) {
      void router.push(onDoneRedirectTo)

      return
    }

    modal.close()
  }, [modal, onDoneRedirectTo, router])

  return (
    <FullScreen onCloseClick={onDone} totalSteps={history.length + 1}>
      <Center>
        <LottieIcon height={152} name="Check" width={152} />
      </Center>

      <Center>
        <div className="mt-4 w-100 text-center text-base leading-6">
          <h2 className="font-circular-bold" data-testid="title">
            {title}
          </h2>
          <p data-testid="message">{message}</p>
        </div>
      </Center>

      <Center className="mt-9" variant="horizontal">
        <Button className="w-100" onClick={onDone} variant="primary">
          {t('common:ok')}
        </Button>
      </Center>
    </FullScreen>
  )
}

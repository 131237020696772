import { Button, Card } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import GetStartedWithVaultsImg from '../../images/GetStartedWithVaults.svg'

import type { StaticImageData } from 'next/image'

export interface EmptyVaultWalletListPlaceholderProps {
  /**
   * The id of the vault.
   */
  vaultId: string
}

export const EmptyVaultWalletListPlaceholder: React.FC<EmptyVaultWalletListPlaceholderProps> =
  ({ vaultId }) => {
    const { t } = useTranslation('vaults')

    return (
      <Card variant="dashed/base">
        <Card.Content>
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-2xl leading-7 text-black-600 font-circular-bold">
                {t`emptyVaultWalletListPlaceholder.title`}
              </h2>
              <p className="mt-2 max-w-160 text-lg text-black-600 text-opacity-70 font-circular-regular">
                {t`emptyVaultWalletListPlaceholder.description`}
              </p>
              <ModalLink
                pathname={routes.vault.createVaultWallet.selectAsset}
                query={{ vaultId }}
              >
                <Button className="mt-4" variant="primary">
                  {t`emptyVaultWalletListPlaceholder.cta`}
                </Button>
              </ModalLink>
            </div>
            <Image
              alt={t`emptyVaultWalletListPlaceholder.title`}
              src={GetStartedWithVaultsImg as StaticImageData}
            />
          </div>
        </Card.Content>
      </Card>
    )
  }

import { useContext } from 'react'

import { IdempotencyKeyContext } from './lib/context'

/**
 * IdempotencyKey hook.
 */
export const useIdempotencyKey = () => {
  return useContext(IdempotencyKeyContext)
}

import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../../../hooks/permission'

export const NeedsToBeApproved: React.FC = () => {
  const { vaultMetadata } = useVaultPermission()
  const { t } = useTranslation('vaults')

  if (
    !vaultMetadata ||
    vaultMetadata.state !== VaultStateEnum.CHANGES_READY_FOR_APPROVAL
  )
    return null

  return (
    <p className="text-sm leading-4 text-black-600 font-circular-book">
      {t('buttons.vault.helpText.changesNeedToBeApproved')}
      <ModalLink
        className="ml-0.5"
        pathname={routes.vault.vaultDetails.learnMore}
      >{t`common:learn-more`}</ModalLink>
    </p>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

import type { Scalars } from '@shared/graphql'

/**
 * Vault Deposit flow values.
 */
export interface VaultDepositState {
  /**
   * Vault parent wallet id.
   */
  selectedParentWalletId?: Scalars['ID']['output']
  /**
   * Vault wallet id (child/mapped wallet id).
   */
  selectedChildWalletId?: Scalars['ID']['output']
}

/**
 * Vault Deposit flow Actions.
 */
interface Actions {
  /**
   * Value change handler.
   */
  setVaultDepositState: (state: Partial<VaultDepositState>) => void
}

const defaultValues: VaultDepositState = {
  selectedParentWalletId: undefined,
  selectedChildWalletId: undefined,
}

/**
 * Create Vault Deposit Context.
 */
const Context = createContext<[VaultDepositState, Actions]>([
  { ...defaultValues },
  {
    setVaultDepositState: () => null,
  },
])

interface VaultDepositProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<VaultDepositState>
}

/**
 * Vault Deposit Provider.
 */
export const Provider: React.FC<VaultDepositProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<VaultDepositState>({
    ...defaultValues,
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<VaultDepositState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const resetContext = useCallback(() => {
    handleValueChange(defaultValues)
  }, [handleValueChange])

  useEffect(() => {
    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <Context.Provider
      value={[values, { setVaultDepositState: handleValueChange }]}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * Vault Deposit context hook.
 */
export const useVaultDeposit = () => {
  return useContext(Context)
}

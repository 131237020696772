import { FixedBanner } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { CIRCLE_WALLET_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import {
  useWalletApprovalPolicyQuery,
  useWalletApprovalSubmittedPolicyProgressQuery,
  WalletApprovalPolicyState,
} from '@shared/graphql'

import * as BannerContent from './Content'

import type { Currency } from '@shared/graphql'

export interface PolicyStateBannerProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Currency.
   */
  currency: Currency
  /**
   * Hidden.
   */
  hidden?: boolean
}

export const PolicyStateBanner: React.FC<PolicyStateBannerProps> = ({
  className,
  currency,
  hidden,
}) => {
  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)

  const policyQuery = useWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: hidden || !isAuthorized,
  })

  const submittedPolicyQuery = useWalletApprovalSubmittedPolicyProgressQuery({
    variables: {
      policyId: policyQuery.data?.walletApprovalPolicy?.id ?? '',
    },
    skip:
      policyQuery.data?.walletApprovalPolicy?.state !==
        WalletApprovalPolicyState.SUBMITTED ||
      !policyQuery.data?.walletApprovalPolicy?.id ||
      hidden ||
      !isAuthorized,
    fetchPolicy: 'cache-and-network',
    pollInterval: CIRCLE_WALLET_DETAILS_POLL_INTERVAL,
    onCompleted: (data) => {
      // once we know we are no longer awaiting approvals, refetch the new active policy
      if (
        data.walletApprovalSubmittedPolicyProgress.pendingApprovalsUser
          .length === 0
      ) {
        void policyQuery.refetch()
      }
    },
  })

  // policy is in draft, notify user to submit for approval
  if (
    policyQuery.data?.walletApprovalPolicy?.state ===
    WalletApprovalPolicyState.DRAFT
  ) {
    return (
      <FixedBanner className={className} visible={!hidden}>
        <BannerContent.Draft />
      </FixedBanner>
    )
  }

  // policy is submitted, show policy progress banner
  if (
    policyQuery.data?.walletApprovalPolicy?.state ===
      WalletApprovalPolicyState.SUBMITTED &&
    submittedPolicyQuery.data?.walletApprovalSubmittedPolicyProgress
  ) {
    return (
      <FixedBanner className={className} visible={!hidden}>
        <BannerContent.Submitted
          pendingApprovers={
            submittedPolicyQuery.data.walletApprovalSubmittedPolicyProgress
              .pendingApprovalsUser.length
          }
          policyId={policyQuery.data.walletApprovalPolicy.id}
        />
      </FixedBanner>
    )
  }

  // there is no policy created yet
  if (policyQuery.data?.walletApprovalPolicy === null) {
    return (
      <FixedBanner className={className} visible={!hidden}>
        <BannerContent.Default currency={currency} />
      </FixedBanner>
    )
  }

  return null
}

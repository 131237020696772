import { Icon, SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { Currency, XpayTransferInstruction } from '@shared/graphql'

export interface XpayTransferInstructionGridProps {
  /**
   * Xpay transfer instruction details.
   */
  xpayTransferInstruction?: XpayTransferInstruction
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const XpayTransferInstructionGrid: React.FC<XpayTransferInstructionGridProps> =
  ({ xpayTransferInstruction, currency, loading = false }) => {
    const { t } = useTranslation('payments')

    return (
      <>
        <SkeletonBox className="block h-16" loading={loading}>
          <div data-testid="currency">
            <CopyToClipboard
              label={t(
                'depositFlow.beneficiaryBankAccountDetails.labels.currency',
              )}
              value={currency}
            />
          </div>
        </SkeletonBox>
        <SkeletonBox className="block h-16" loading={loading}>
          <div data-testid="bankAccountNumber">
            <CopyToClipboard
              label={t(
                'depositFlow.beneficiaryBankAccountDetails.labels.bankAccountNumber',
              )}
              value={xpayTransferInstruction?.accountNumber ?? ''}
            />
          </div>
        </SkeletonBox>

        <div>
          <SkeletonBox className="block h-16" loading={loading}>
            <div data-testid="referenceIDMemo">
              <CopyToClipboard
                label={t(
                  'depositFlow.beneficiaryBankAccountDetails.labels.referenceIDMemo',
                )}
                value={xpayTransferInstruction?.trackingRef ?? ''}
              />
            </div>
          </SkeletonBox>
          {xpayTransferInstruction?.virtualAccountEnabled === false &&
            xpayTransferInstruction?.trackingRef && (
              <div className="flex items-end">
                <p className="min-h-10 text-sm leading-5 font-circular-regular">
                  <Trans
                    components={{
                      icon: (
                        <Icon
                          className="relative -top-px inline-block text-orange-400"
                          name="ExclamationSolid"
                          size={14}
                        />
                      ),
                      bold: <strong />,
                    }}
                    i18nKey="payments:depositFlow.beneficiaryBankAccountDetails.instructionMessage.xpay"
                  />
                </p>
              </div>
            )}
        </div>
      </>
    )
  }

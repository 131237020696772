import { BankAccountType } from '@shared/graphql'

import type {
  CbitTransferInstruction,
  SenTransferInstruction,
  SignetTransferInstruction,
  TransferInstruction,
  WireTransferInstruction,
  XpayTransferInstruction,
  RtpTransferInstruction,
  PixTransferInstruction,
} from '@shared/graphql'

/**
 * Type guard to check if a given instruction is a wire transfer instruction.
 */
export function isWire(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is WireTransferInstruction {
  return (
    accountType === BankAccountType.wire ||
    (instruction?.__typename === 'WireTransferInstruction' &&
      instruction.type === BankAccountType.wire)
  )
}

/**
 * Type guard to check if a given instruction is a RTP transfer instruction.
 */
export function isRtp(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is RtpTransferInstruction {
  return (
    accountType === BankAccountType.rtp ||
    (instruction?.__typename === 'RtpTransferInstruction' &&
      instruction.type === BankAccountType.rtp)
  )
}

/**
 * Type guard to check if a given instruction is a signet transfer instruction.
 */
export function isSignet(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is SignetTransferInstruction {
  return (
    accountType === BankAccountType.signet ||
    (instruction?.__typename === 'SignetTransferInstruction' &&
      instruction.type === BankAccountType.signet)
  )
}

/**
 * Type guard to check if a given instruction is a cbit transfer instruction.
 */
export function isCbit(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is CbitTransferInstruction {
  return (
    accountType === BankAccountType.cbit ||
    (instruction?.__typename === 'CbitTransferInstruction' &&
      instruction.type === BankAccountType.cbit)
  )
}

/**
 * Type guard to check if a given instruction is a sen transfer instruction.
 */
export function isSen(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is SenTransferInstruction {
  return (
    accountType === BankAccountType.sen ||
    (instruction?.__typename === 'SenTransferInstruction' &&
      instruction.type === BankAccountType.sen)
  )
}

/**
 * Type guard to check if a given instruction is a xpay transfer instruction.
 */
export function isXpay(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is XpayTransferInstruction {
  return (
    accountType === BankAccountType.xpay ||
    (instruction?.__typename === 'XpayTransferInstruction' &&
      instruction.type === BankAccountType.xpay)
  )
}

/**
 * Type guard to check if a given instruction is a PIX transfer instruction.
 */
export function isPix(
  instruction: TransferInstruction | undefined,
  accountType: BankAccountType,
): instruction is PixTransferInstruction {
  return (
    accountType === BankAccountType.pix ||
    (instruction?.__typename === 'PixTransferInstruction' &&
      instruction.type === BankAccountType.pix)
  )
}

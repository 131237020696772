import { Button } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { ADDRESS_BOOK_MUTATION } from '@services/permissions'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export const AddressBook: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [, { isAuthorized }] = usePermission(ADDRESS_BOOK_MUTATION)

  return (
    <Button
      data-testid="address-book-button"
      {...props}
      disabled={Boolean(props.disabled) || !isAuthorized}
    >
      {children}
    </Button>
  )
}

import { ModalRoute } from '@circlefin/modal-router'
import { ExportReport } from '@features/report/modals'
import { report } from '@services/sections/modal/report/report'

/**
 * Common Router.
 */
export const ReportRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<ExportReport />} path={report.exportReport} />
    </>
  )
}

import { useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { Currency as CurrencyEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { FormDropdownProps } from '@circlefin/form/Form.Dropdown'

export const CurrencySchema = y.string().oneOf(Object.values(CurrencyEnum))

const schema = y.object({
  /**
   * Selected Currency.
   */
  currency: CurrencySchema,
})

export type CurrencyFormValues = y.InferType<typeof schema>

export type CurrencyProps = Omit<
  FormDropdownProps<CurrencyFormValues>,
  'items' | 'name' | 'variant'
> & {
  variant?: 'fiat' | 'crypto'
  itemsFilter?: CurrencyEnum[]
}

export const Currency: React.FC<CurrencyProps> = ({
  variant = 'crypto',
  label,
  itemsFilter = [],
  ...props
}) => {
  const { t } = useTranslation('forms')
  const [Form] = useForm<CurrencyFormValues>({ schema })
  const items = useMemo(() => {
    const allItems = [
      {
        label: variant === 'fiat' ? CurrencyEnum.USD : CurrencyEnum.USDC,
        value: CurrencyEnum.USD,
      },
      {
        label: variant === 'fiat' ? CurrencyEnum.EUR : CurrencyEnum.EURC,
        value: CurrencyEnum.EUR,
      },
    ]
    if (itemsFilter.length > 0) {
      return allItems.filter((currency) => itemsFilter.includes(currency.value))
    }
    return allItems
  }, [variant, itemsFilter])

  return (
    <Form.Dropdown
      {...props}
      items={items}
      label={label ?? t`dropdown.common.currency.label`}
      name="currency"
    />
  )
}

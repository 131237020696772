import { useCallback, useEffect, useRef } from 'react'

import { useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { PROTECTED } from '@services/permissions'
import { findDynamicSectionByPath } from '@services/sections'
import { onboarding } from '@services/sections/modal/onboarding/onboarding'
import { FactorStatus } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { TakeoverInfoQuery } from '@shared/graphql'

export const usePinCodeTakeover = () => {
  const [, { isAuthorized }] = usePermission(PROTECTED)
  const hasOpenedOnceInCurrentPath = useRef(false)
  const { pathname } = useRouter()
  const modal = useModal()
  const { t } = useTranslation('common')
  const toast = useToast()

  const open = useCallback(() => {
    hasOpenedOnceInCurrentPath.current = true

    modal.router.push({
      pathname: onboarding.pinCode.takeover,
      query: { onActivation: () => toast.success(t('pinCodeSuccess')) },
    })
  }, [modal.router, t, toast])

  const shouldOpen = useCallback(
    (data: TakeoverInfoQuery) => {
      const section = findDynamicSectionByPath(pathname)

      if (section?.takeover?.pinCode === false) {
        return false
      }

      return (
        data.scaFactorInfo?.pinCode?.status === FactorStatus.NOT_SETUP &&
        hasOpenedOnceInCurrentPath.current === false &&
        isAuthorized
      )
    },
    [isAuthorized, pathname],
  )

  useEffect(() => {
    // We want reopen the takeover modal on route change complete if the requirement is still not met
    hasOpenedOnceInCurrentPath.current = false
  }, [pathname])

  return {
    shouldOpen,
    open,
  }
}

import { manageUsers } from '@services/sections/lib/settings'
import useTranslation from 'next-translate/useTranslation'

import { FeatureTourStep } from '../../FeatureTour.Step'

export const ManageUsers: React.FC = () => {
  const { t } = useTranslation('modals.common')

  return (
    <FeatureTourStep
      data-testid="users"
      header={t`featureTour.users.header`}
      iconName="User"
      link={{
        text: t`featureTour.users.linkText`,
        url: manageUsers.route,
      }}
      subHeader={t(`featureTour.users.subHeader`)}
    />
  )
}

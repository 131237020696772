import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FeatureSwitch } from '@services/feature-switch'
import { HK_LAUNCH_ENABLED, PIX_ENABLED } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { CountryCode } from '@shared/graphql'

import { InformationVariants } from './Information/Information.types'

import type { AddressFormValues } from './Address/Form/Address.Form'
import type { InformationForms } from './Information/Information.types'
import type { LocationFormSubmit as LocationFormValues } from './Location/Form/Location.Form'
import type { UsTypes } from './Type/Type'

export interface LinkContextActions {
  /**
   * Set Bank Location.
   */
  setLocation: (location: LocationFormValues) => void
  /**
   * Set US type.
   */
  setType: (type: UsTypes, goNext?: boolean) => void
  /**
   * Set Bank Account Information.
   */
  setInformation: (information: InformationForms) => void
  /**
   * Set Bank Account Address.
   */
  setAddress: (address: AddressFormValues) => void
}

export interface LinkContextData {
  /**
   * Total Steps (Include MFA).
   */
  steps: number
  /**
   * Link Variant.
   */
  variant?: InformationVariants
  /**
   * Bank Location.
   */
  location?: LocationFormValues
  /**
   * Bank Account Information.
   */
  information: InformationForms
  /**
   * Bank Account Address.
   */
  address?: AddressFormValues
}

const initialValues: LinkContextData = {
  steps: 5,
  information: {},
}

const LinkContext = createContext<[LinkContextData, LinkContextActions]>([
  initialValues,
  {
    setLocation: () => null,
    setType: () => null,
    setInformation: () => null,
    setAddress: () => null,
  },
])

export interface LinkProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Provider: React.FC<LinkProviderProps> = ({ children }) => {
  const { router, events } = useModal()
  const [data, setData] = useState<LinkContextData>(initialValues)
  const { travelRuleRequired } = useTravelRuleRequired()
  const [, { isAuthorized }] = usePermission(HK_LAUNCH_ENABLED)
  const [, { isAuthorized: pixEnabled }] = usePermission(PIX_ENABLED)

  // Merge new data with existing
  const mergeData = useCallback((state: Partial<LinkContextData>) => {
    setData((prevState) => ({ ...prevState, ...state }))
  }, [])

  // Reset Data
  const reset = useCallback(() => {
    setData(initialValues)
  }, [])

  // Reset data on modal close
  useEffect(() => {
    events.on('onCloseEnd', reset)
    return () => events.off('onCloseEnd', reset)
  }, [events, reset])

  // Set Bank Location
  const setLocation = useCallback(
    //TODO: Fix this complexity issue. This will likely look like breaking this file up into
    // many helper functions that can all be called from switch cases like this.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (location: LocationFormValues) => {
      const displayAccountInformationPage = (code: CountryCode) =>
        code === CountryCode.SG || (code === CountryCode.HK && isAuthorized)

      switch (location.country?.code) {
        case CountryCode.US:
          // Merge US Location Data
          mergeData({
            // US bank link have extra step
            // New flow combined information and type, so it has one less step
            steps: FeatureSwitch.rtpBankSupportEnabled() ? 5 : 6,
            variant: InformationVariants.WIRE,
            location,
          })

          // Next Step
          // If the user is SG entity and selects a US bank, we force them down the wire path
          // Since they should not see alternative banking rails
          if (travelRuleRequired) {
            mergeData({
              steps: 6,
              variant: InformationVariants.WIRE,
            })
            router.push(routes.bankAccount.link.information)
            break
          }

          if (FeatureSwitch.rtpBankSupportEnabled()) {
            router.push(routes.bankAccount.link.accountInformation)
          } else {
            router.push(routes.bankAccount.link.type)
          }
          break

        case CountryCode.CA:
          // Merge CA Location Data
          mergeData({
            steps: 5,
            variant: InformationVariants.NON_IBAN_CANADA,
            location,
          })

          // Next Step
          router.push(routes.bankAccount.link.information)
          break

        case CountryCode.SG:
        case CountryCode.HK:
          mergeData({
            steps: 5,
            variant: InformationVariants.NON_IBAN,
            location,
          })

          //Next Step
          if (displayAccountInformationPage(location.country?.code)) {
            router.push(routes.bankAccount.link.accountInformation)
          } else {
            router.push(routes.bankAccount.link.information)
          }
          break

        case CountryCode.BR:
          if (pixEnabled) {
            mergeData({
              steps: 5,
              variant: InformationVariants.PIX,
              location,
            })

            router.push(routes.bankAccount.link.accountInformation)
          } else {
            mergeData({
              steps: 5,
              variant: location.country?.support.iban
                ? InformationVariants.IBAN
                : InformationVariants.NON_IBAN,
              location,
            })

            // Next Step
            router.push(routes.bankAccount.link.information)
          }

          break

        default:
          // Merge Other Locations Data
          mergeData({
            steps: 5,
            variant: location.country?.support.iban
              ? InformationVariants.IBAN
              : InformationVariants.NON_IBAN,
            location,
          })

          // Next Step
          router.push(routes.bankAccount.link.information)
      }
    },
    [isAuthorized, mergeData, pixEnabled, router, travelRuleRequired],
  )

  // Set US type
  const setType = useCallback(
    (variant: UsTypes, goNext = false) => {
      if (
        variant === InformationVariants.CBIT ||
        variant === InformationVariants.XPAY
      ) {
        mergeData({
          steps: 5,
          variant,
        })
      } else {
        mergeData({
          steps: 6,
          variant,
        })
      }

      // Next Step
      if (goNext) {
        router.push(routes.bankAccount.link.information)
      }
    },
    [mergeData, router],
  )

  // Set Bank Account Information
  const setInformation = useCallback(
    ({
      iban,
      nonIban,
      nonIbanCanada,
      wire,
      cbit,
      xpay,
      pix,
    }: InformationForms) => {
      if (data.variant == null) return

      // Merge Information Data
      switch (data.variant) {
        // IBAN Account Information
        case InformationVariants.IBAN:
          mergeData({
            information: {
              iban,
            },
          })
          break

        // Non-IBAN Account Information
        case InformationVariants.NON_IBAN:
          mergeData({
            information: {
              nonIban,
            },
          })
          break

        // Non-IBAN (Canada) Account Information
        case InformationVariants.NON_IBAN_CANADA:
          mergeData({
            information: {
              nonIbanCanada,
            },
          })
          break

        // Wire Account Information
        case InformationVariants.WIRE:
        case InformationVariants.CUBI_WIRE:
        case InformationVariants.CRB_WIRE:
          mergeData({
            information: {
              wire,
            },
          })
          break

        // Cbit Account Information
        case InformationVariants.CBIT:
          mergeData({
            information: {
              cbit,
            },
          })
          return

        // Xpay Account Information
        case InformationVariants.XPAY:
          mergeData({
            information: {
              xpay,
            },
          })
          return

        // Pix Account Information
        case InformationVariants.PIX:
          mergeData({
            information: {
              pix,
            },
          })
          break
      }

      switch (data.variant) {
        case InformationVariants.WIRE:
        case InformationVariants.RTP:
          if (FeatureSwitch.rtpBankSupportEnabled()) {
            router.push(routes.bankAccount.link.accountAddress)
          } else {
            router.push(routes.bankAccount.link.address)
          }
          break
        default:
          router.push(routes.bankAccount.link.address)
      }
    },
    [data.variant, mergeData, router],
  )

  // Set Bank Account Address
  const setAddress = useCallback(
    (address: AddressFormValues) => {
      mergeData({ address })
    },
    [mergeData],
  )

  return (
    <LinkContext.Provider
      value={[data, { setLocation, setType, setInformation, setAddress }]}
    >
      {children}
    </LinkContext.Provider>
  )
}

/**
 * Link Bank Account Hook.
 */
export const useLinkBankAccount = () => {
  return useContext(LinkContext)
}

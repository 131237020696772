import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'

export const ChooseVaultType: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { router } = useModal()

  const handleStorageChoose = useCallback(() => {
    router.push(routes.vault.createVault.initialize)
  }, [router])

  const handleWeb3Choose = useCallback(() => {
    router.push({
      pathname: routes.vault.createVault.initialize,
      query: {
        isWalletConnectStepVisible: true,
      },
    })
  }, [router])

  return (
    <FullScreen>
      <Center variant="horizontal">
        <h2 className="mb-3 text-center text-xl font-circular-bold">
          {t`chooseVaultType.title`}
        </h2>
        <Buttons.Cards.ChooseVault
          className="w-116"
          icon="CustodyOutline"
          onClick={handleStorageChoose}
          subtitle={t('chooseVaultType.storage.subtitle')}
          title={t('chooseVaultType.storage.title')}
        />
        <Buttons.Cards.ChooseVault
          className="mt-5 w-116"
          icon="CustodyOutline"
          onClick={handleWeb3Choose}
          subtitle={t('chooseVaultType.web3.subtitle')}
          title={t('chooseVaultType.web3.title')}
        />
      </Center>
    </FullScreen>
  )
}

export enum SegmentModalScreenNames {
  Withdraw = 'withdraw_to_bank',
  EurcWithdraw = 'euroc_withdraw_to_bank',
  NoWalletAddress = 'send_no_wallet_notification',
  AddCardDetails = 'add_credit_card_details',
  MFA = 'mfa',
  MFASca = 'mfa_sca',
  MFASuccess = 'mfa_success',
  MFAFailure = 'mfa_failure',
  MFASetup = 'mfa_setup',
  MFASetupOktaVerify = 'mfa_setup_okta_verify',
  MFAResetPinCode = 'mfa_reset_pin_code',
  MFAUpdatePinCode = 'mfa_update_pin_code',
  EnableAutoPay = 'enable_autopay',
  EditRecipient = 'edit_recipient_address_book',
  linkBankAccount = 'link_bank_account',
  NoLinkedBankAccounts = 'no_linked_bank_accounts_step',
  setBankAccountNickname = 'set_bank_account_nickname',
  ChooseDepositType = 'deposit_method',
  ChooseTransferType = 'transfer_method',
  SendOnChainRecipient = 'send_on_chain_step_1',
  SendOnChainAmount = 'send_on_chain_step_2',
  SendOnChainReview = 'send_on_chain_step_3',
  TestWireTransfer = 'deposit_wire_step_0',
  WireTransferSelectBankAccount = 'deposit_wire_step_1',
  WireTransferSelectCurrency = 'deposit_wire_select_currency_step',
  WireTransferBankAccountDetails = 'deposit_wire_step_2',
  WireTransferBeneficiaryBankAccount = 'deposit_wire_step_3',
  WireTransferDisclosures = 'deposit_wire_step_4',
  WalletTransferChooseBlockchain = 'deposit_blockchain_step_1',
  WalletTransferBlockchainTransferInstruction = 'deposit_blockchain_step_2',
  IpAllowlistingToggleSetting = 'ip_allowlisting_toggle_setting',
  PayInvoiceSelectPaymentMethod = 'pay_invoice_step_1',
  PayInvoiceConfirmPayment = 'pay_invoice_step_2',
  PeriodicReviewTakeover = 'periodic_review_takeover',
  PinCodeTakeover = 'pin_code_takeover',
  AdminApprovalsSettings = 'admin_approvals_settings',
  AdminApprovalsSettingsSuccess = 'admin_approvals_settings_success',
  IpAllowlistingIpAddressRemove = 'ip_allowlisting_ip_address_remove',
  IpAllowlistingIpAddressCreate = 'ip_allowlisting_ip_address_create',
  DelayedWithdrawalsToggleSetting = 'delayed_withdrawals_toggle_setting',
  IpAllowlistingIpAddressEmpty = 'ip_allowlisting_ip_address_empty',
  TransferFundsChooseWallet = 'transfer_funds',
  TransferFundsSuccess = 'transfer_funds_success',
  ChangePinConfirmation = 'change_pin_confirmation',
  ChangePinSuccess = 'change_pin_success',
  ResetPinConfirmation = 'reset_pin_confirmation',
  ResetPinUploadBackup = 'reset_pin_upload_backup',
  ResetPinEnterPassphrase = 'reset_pin_enter_passphrase',
  ResetPinPin = 'reset_pin_pin',
  ResetPinSuccess = 'reset_pin_success',
  CancelUserApprovalRequest = 'cancel_user_approval_request',
  CreateVaultPolicyName = 'create_vault_policy_name',
  CreateVaultPermissions = 'create_vault_permissions',
  CreateVaultLimitsPolicy = 'create_vault_limits_policy',
  CreateVaultLimitsUser = 'create_vault_limits_user',
  CreateVaultApproval = 'create_vault_approval',
  CreateVaultOutgoingAddresses = 'create_vault_outgoing_addresses',
  CreateVaultWalletConnect = 'create_vault_wallet_connect',
  CreateVaultReview = 'create_vault_review',
  CreateVaultFinished = 'create_vault_finished',
  VaultDetailsLearnMore = 'vault_details_learn_more',
  VaultDetailsSubmissionBlocked = 'vault_details_submission_blocked',
  VaultWalletDetailsUnarchive = 'vault_wallet_details_unarchive',
  VaultWalletDetailsArchive = 'vault_wallet_details_archive',
  VaultWalletDetailsDiscardDraft = 'vault_wallet_details_discard_draft',
  VaultDetailsDiscardDraftPolicy = 'vault_details_discard_draft_policy',
  VaultDetailsCancelPendingApproval = 'vault_details_cancel_pending_approval',
  VaultWalletDetailsConfirmUnarchive = 'vault_wallet_details_confirm_unarchive',
  CreateVaultWalletAsset = 'create_vault_wallet_asset',
  CreateVaultWalletBlockchain = 'create_vault_wallet_blockchain',
  CreateVaultWalletConfirm = 'create_vault_wallet_confirm',
  CreateVaultWalletSuccess = 'create_vault_wallet_success',
  PairDeviceDownload = 'pair_device_download',
  PairDevicePair = 'pair_device_pair',
  PairDevicePin = 'pair_device_pin',
  PairDeviceSuccess = 'pair_device_success',
  PairNewDeviceConfirmation = 'pair_new_device_confirmation',
  PairNewDevicePair = 'pair_new_device_pair',
  PairNewDeviceDownload = 'pair_new_device_download',
  ChooseVaultType = 'choose_vault_type',
  ConfirmWalletActivationFee = 'confirm_wallet_activation_fee',
  VaultUserRemoveWarning = 'vault_user_remove_warning',
  VaultUserRemoveOwnerWarning = 'vault_user_remove_owner_warning',
  VaultDepositChooseType = 'vault_deposit_choose_type',
  VaultDepositBlockchainDetails = 'vault_deposit_blockchain_details',
  VaultDepositBlockchainInstruction = 'vault_deposit_blockchain_instructions',
  VaultTransferChooseType = 'vault_transfer_choose_type',
  VaultTransferSendOnChainExternalRecipient = 'vault_transfer_send_on_chain_external_recipient',
  VaultTransferSendOnChainInternalRecipient = 'vault_transfer_send_on_chain_internal_recipient',
  VaultTransferSendOnChainAmount = 'vault_transfer_send_on_chain_amount',
  VaultTransferSendOnChainReview = 'vault_transfer_send_on_chain_review',
  VaultTransferSendOnChainFinished = 'vault_transfer_send_on_chain_finished',
  VaultDownloadTransactionsReport = 'vault_download_transactions_report',
  UserRemove = 'user_remove',
  UserRemoveWithAdminApproval = 'user_remove_with_admin_approval',
  UserResendInvite = 'user_resend_invite',
  UserChangePasswordError = 'user_change_password_error',
  UserChangePasswordSuccess = 'user_change_password_success',
  AddressBookVerifyAddressOwnerType = 'address_book_add_address_verify_address_owner_type',
  AddressBookSelectRecipient = 'address_book_add_address_select_recipient',
  AddressBookCustomer = 'address_book_add_address_customer',
  AddressBookNonCustomer = 'address_book_add_address_non_customer',
  AddressBookRemoveAddressConfirmation = 'address_book_remove_address_confirmation',
  WalletBackupConfirmation = 'wallet_backup_confirmation',
  WalletBackupCreatePassphrase = 'wallet_backup_create_passphrase',
  WalletBackupPin = 'wallet_backup_pin',
  WalletBackupDownload = 'wallet_backup_download',
  WalletBackupSuccess = 'wallet_backup_success',
  WalletBackupError = 'wallet_backup_error',
  WalletConnectOverview = 'wallet_connect_overview',
  WalletConnectConfirmation = 'wallet_connect_confirmation',
  WalletApprovalPolicyPermissions = 'wallet_approval_policy_permissions',
  WalletApprovalPolicyLimitsPolicy = 'wallet_approval_policy_limits_policy',
  WalletApprovalPolicyLimitsUser = 'wallet_approval_policy_limits_user',
  WalletApprovalPolicyApproval = 'wallet_approval_policy_approval',
  WalletApprovalPolicySettings = 'wallet_approval_policy_settings',
  WalletApprovalPolicyReview = 'wallet_approval_policy_review',
  WalletApprovalPolicyFinished = 'wallet_approval_policy_finished',
  WalletApprovalPolicyDiscardDraft = 'wallet_approval_policy_discard_draft',
  WalletApprovalPolicyCancelApprovalRequest = 'wallet_approval_policy_cancel_approval_request',
  WalletApprovalPolicyCancelPendingTransaction = 'wallet_approval_policy_cancel_pending_transaction',
  WalletApprovalPolicyApproveApprovalRequest = 'wallet_approval_policy_approve_approval_request',
  WalletApprovalPolicyRejectApprovalRequest = 'wallet_approval_policy_reject_approval_request',
  WalletApprovalPolicySubmissionBlocked = 'wallet_approval_policy_submission_blocked',
  WalletApprovalSubmittedPolicyProgress = 'wallet_approval_submitted_policy_progress',
  SubscriptionAddConfirmation = 'subscription_add_confirmation',
  ExportReport = 'export_report',
  OnboardingOtherInterest = 'onboarding_other_interest',
  TransferApprovalRequired = 'transfer_approval_required',
  ChooseWalletAndDepositType = 'choose_wallet_and_deposit_type',
  ChooseWalletAndTransferType = 'choose_wallet_and_transfer_type',
  IdentityChallenge = 'show_identity_challenge_instructions',
  OwnershipDisclaimer = 'address_book_add_address_ownership_disclaimer',
  AddNewAddressError = 'address_book_add_address_error',
  ThirdPartyWarning = 'address_book_third_party_warning',
  FxDeposit = 'transfer_fx_deposit',
  FxWithdraw = 'transfer_fx_withdraw',
  FxDepositInstructions = 'transfer_fx_deposit_instructions',
  FxReview = 'transfer_fx_withdraw_review',
  ApprovalRequest = 'address_book_approval_request',
}

import { useMemo } from 'react'

import { support } from '@services/sections/lib/home'
import { useSegment } from '@services/segment'
import classNames from 'classnames'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { TransProps } from 'next-translate'

interface CoreTransWithLinkProps extends Pick<TransProps, 'components'> {
  /**
   * The translation key which contains a support link.
   */
  i18nKey: string
  /**
   * Enable white space no wrap on the email link.
   */
  noWrap?: boolean
  /**
   * Additional classnames applied to the email link.
   */
  className?: string
}

type ConditionalTransWithLinkProps =
  | {
      /**
       * The variant of the link we want to display -- when 'url', we expect
       * custom urlI18nKey to be passed in.
       */
      variant: 'url'
      /**
       * The translation key which contains a URL string.
       */
      urlI18nKey: string
    }
  | {
      /**
       * The variant of the link we want to display. These variants determine
       * which email or support URL to use.
       */
      variant: 'support' | 'sales' | 'internal-support'
      /**
       * Never expect a custom translation key when using 'support', 'sales' or
       * 'internal-support' variants.
       */
      urlI18nKey?: never
    }

export type TransWithLinkProps = CoreTransWithLinkProps &
  ConditionalTransWithLinkProps

export const TransWithLink: React.FC<TransWithLinkProps> = ({
  variant,
  className,
  i18nKey,
  urlI18nKey,
  components,
  noWrap = true,
}) => {
  const { t } = useTranslation()
  const { trackLinkClick } = useSegment()

  const email = useMemo(() => {
    switch (variant) {
      case 'support':
        return t`common:supportEmail`
      case 'sales':
        return t`common:salesEmail`
    }
  }, [t, variant])

  const href = useMemo(() => {
    switch (variant) {
      case 'url':
        return t(urlI18nKey)
      case 'support':
        return `mailto:${t('common:supportEmail')}`
      case 'sales':
        return `mailto:${t('common:salesEmail')}`
      case 'internal-support':
        return support.route
    }
  }, [t, urlI18nKey, variant])

  return (
    <Trans
      components={{
        a: (
          <Link
            className={classNames(
              {
                'whitespace-nowrap': noWrap,
              },
              className,
            )}
            href={href}
            onClick={trackLinkClick}
            rel="noopener noreferrer"
            target="_blank"
          />
        ),
        ...components,
      }}
      i18nKey={i18nKey}
      values={
        email
          ? {
              email,
            }
          : undefined
      }
    />
  )
}

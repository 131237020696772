import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import { CountryCode } from '@shared/graphql'

import type {
  MerchantBillingDetailsInput,
  MerchantCreditCardInput,
} from '@shared/graphql'

/**
 * Add Credit Card Actions.
 */
interface AddCreditCardActions {
  /**
   * Value change handler.
   */
  setBillingDetails: (values: Partial<MerchantBillingDetailsInput>) => void
  setCardDetails: (
    values: Partial<Omit<MerchantCreditCardInput, 'billingDetails'>>,
  ) => void
}

const defaultBillingDetails: MerchantBillingDetailsInput = {
  name: '',
  email: '',
  line1: '',
  line2: '',
  country: CountryCode.US,
  city: '',
  postalCode: '',
  state: '',
}

const defaultCardDetails: MerchantCreditCardInput = {
  isDefault: false,
  cardNumber: '',
  expMonth: '',
  expYear: '',
  cvv: '',
  billingDetails: defaultBillingDetails,
}

/**
 * Create Send-On-Chain Context.
 */
const AddCreditCardContext = createContext<
  [MerchantCreditCardInput, MerchantBillingDetailsInput, AddCreditCardActions]
>([
  {
    ...defaultCardDetails,
  },
  {
    ...defaultBillingDetails,
  },
  {
    setCardDetails: () => null,
    setBillingDetails: () => null,
  },
])

/**
 * Add Credit Card Provider props.
 */
interface AddCreditCardProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: MerchantCreditCardInput | MerchantBillingDetailsInput
}

/**
 * Add Credit Card Provider.
 */
export const AddCreditCardProvider: React.FC<AddCreditCardProviderProps> = ({
  children,
  initValues = {},
}) => {
  const { events } = useModal()
  const [valuesCard, setValuesCard] = useState<MerchantCreditCardInput>({
    ...defaultCardDetails,
    ...initValues,
  })

  const [valuesBilling, setValuesBilling] =
    useState<MerchantBillingDetailsInput>({
      ...defaultBillingDetails,
      ...initValues,
    })

  const setCardDetails = useCallback(
    (values: Partial<MerchantCreditCardInput>) => {
      setValuesCard((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const setBillingDetails = useCallback(
    (values: Partial<MerchantBillingDetailsInput>) => {
      setValuesBilling((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  useEffect(() => {
    const resetContext = () => {
      setBillingDetails(defaultBillingDetails)
      setCardDetails(defaultCardDetails)
    }

    events.on('onCloseStart', resetContext)

    return () => {
      events.off('onCloseStart', resetContext)
    }
  })

  return (
    <AddCreditCardContext.Provider
      value={[valuesCard, valuesBilling, { setCardDetails, setBillingDetails }]}
    >
      {children}
    </AddCreditCardContext.Provider>
  )
}

/**
 * Add Credit Card hook.
 */
export const useAddCreditCard = () => {
  return useContext(AddCreditCardContext)
}

import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useSegment, SegmentEvents } from '@services/segment'
import cn from 'classnames'
import Link from 'next/link'

import type { Amount } from '@shared/graphql'

export interface LimitProps {
  /**
   * Additional classes.
   */
  className?: string
  /**
   * Used amount.
   */
  used: Amount
  /**
   * Total allowed amount.
   */
  cap: Amount
  /**
   * Title label for limit.
   */
  title: string
  /**
   * Link config props.
   */
  link: {
    /**
     * Link label.
     */
    label: string
    /**
     * Routes user to specified action route.
     */
    href: string
  }
}

export const Limits: React.FC<LimitProps> = ({
  className,
  title,
  used,
  cap,
  link,
}) => {
  const { money } = useMoney({ locale: 'en-US' })
  const { track } = useSegment()

  const formattedUsedAmount = money({
    number: used.amount,
    variant: used.currency,
  })
  const formattedAllowedAmount = money({
    number: cap.amount,
    variant: cap.currency,
  })

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      track(SegmentEvents.IncreaseMyLimitsClicked, {
        event: e,
      })
    },
    [track],
  )

  return (
    <div
      className={cn(
        'font-circular sm:flex justify-between items-center',
        className,
      )}
      data-testid="limits-content"
    >
      <div>
        <span
          className="text-base leading-6 font-circular-bold"
          data-testid="limits-title"
        >
          {title}
        </span>
        <span
          className="ml-5 text-base leading-6 font-circular-book"
          data-testid="limits-used-amount"
        >
          {formattedUsedAmount}
        </span>
        <span className="text-base leading-6 text-black-300 font-circular-regular">
          {' / '}
        </span>
        <span
          className="text-base leading-6 text-black-300 font-circular-regular"
          data-testid="limits-cap-amount"
        >
          {formattedAllowedAmount}
        </span>
      </div>

      <Link href={link.href} legacyBehavior passHref>
        <a onClick={onClick}>
          {link.label}
          <Icon className="ml-2 inline" name="ChevronRightSolid" />
        </a>
      </Link>
    </div>
  )
}

Limits.displayName = 'Limits'

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../../layout'

export const Finished: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const [{ currency = Currency.USDC }] = useCurrentCurrency()
  const [{ inEdit }] = useWalletApprovalPolicy()

  const { close } = useModal()

  const handleClose = useCallback(() => {
    close({ context: 'onConfirm' })
  }, [close])

  return (
    <WalletApprovalPolicyLayout
      currentStep={WalletApprovalPolicyStep.FINISHED}
      disableSaveAndExit
    >
      <div className="grid max-w-176 grid-cols-1 justify-items-start">
        <h2 className="text-neutral-strong type-h-page-sm">
          {t(`review.finished.${inEdit ? 'update' : 'create'}.title`, {
            currency,
          })}
        </h2>

        <h3 className="mb-1 mt-4 text-neutral-strong type-intro-sm">
          {t(`review.finished.${inEdit ? 'update' : 'create'}.subtitle`)}
        </h3>

        <div className="mt-8">
          <Button className="w-60" onClick={handleClose} variant="primary">
            {t('review.finished.goToWallet', { currency })}
          </Button>
        </div>
      </div>
    </WalletApprovalPolicyLayout>
  )
}

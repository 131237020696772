import { useCallback } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import * as PaymentButtons from '@features/payments/buttons'
import { FX_LAUNCH_ENABLED } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  BankAccountFtuLinkStatus,
  useCircleWalletsFtuStatusQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface DepositTypeProps {
  /**
   * Specify if wire transfers should be disabled.
   */
  disableWireTransfer?: boolean
  /**
   * Specify if select currency should be shown.
   */
  showSelectCurrencyScreen: boolean
  /**
   * Specify modal currency.
   */
  currency?: Currency
}

export const DepositType: React.FC<DepositTypeProps> = ({
  disableWireTransfer,
  showSelectCurrencyScreen,
  currency,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { track } = useSegment()
  const { router } = useModal()
  const [, { isAuthorized: fxEnabled }] = usePermission(FX_LAUNCH_ENABLED)

  const { data: { circleWalletsFtuStatus } = {}, loading } =
    useCircleWalletsFtuStatusQuery({
      fetchPolicy: 'cache-and-network',
    })

  const [, { setCurrency, tokenToCurrency }] = useCurrentCurrency({
    // Current currency is set in the first modal step so that it's available in subsequent modal steps
    initialCurrency: currency,
  })

  const handleWireChoose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      track(SegmentEvents.DepositTypeSelected, {
        props: {
          deposit_method: 'wire',
        },
        event,
        trackPageName: false,
      })

      // This step will accept either USDC from the Total balance card and EURC from the EURC balance card.
      // This is because the user can choose to do a wire deposit or from a blockchain wallet...
      // So we will standardize on the component accepting USDC/EURC
      // However, the wire path works with USD and EURO since the user is depositing in money from a bank account
      // Before navigation set the currency for the flow
      if (currency != null) {
        setCurrency({
          currency: tokenToCurrency(currency),
        })
      }

      if (
        disableWireTransfer ||
        circleWalletsFtuStatus?.linkStatus !== BankAccountFtuLinkStatus.complete
      ) {
        router.push(routes.bankAccount.noLinked.wireTransfer)
        return
      }

      // If FX is enabled, we send the user to select their currency then into the FX flow.
      if (fxEnabled) {
        // If a currency is present, we use this currency to skip the user selecting one again in the FX flow.
        // Else, we have to go to the choose wallet modal first then into the FX form.
        router.push(
          currency != null
            ? {
                pathname: routes.transfer.fx.deposit.form,
                query: { currency },
              }
            : routes.transfer.chooseWalletAndDepositType,
        )
        return
      }

      if (showSelectCurrencyScreen) {
        router.push(routes.transfer.wire.selectCurrency)
        return
      }

      //Else, we send the user down the default / old select bank flow.
      router.push(routes.transfer.wire.selectBankAccount)
    },
    [
      track,
      currency,
      disableWireTransfer,
      circleWalletsFtuStatus?.linkStatus,
      showSelectCurrencyScreen,
      fxEnabled,
      router,
      setCurrency,
      tokenToCurrency,
    ],
  )

  const handleBlockchainChoose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      track(SegmentEvents.DepositTypeSelected, {
        props: {
          deposit_method: 'blockchain',
        },
        event,
        trackPageName: false,
      })

      router.push(routes.transfer.wallet.chooseBlockchain)
    },
    [router, track],
  )

  return (
    <div className="flex flex-col gap-5">
      <SkeletonBox className="h-22 w-116" loading={loading}>
        <PaymentButtons.Cards.BankWires
          actionType="deposit"
          className="w-116"
          icon="LibrarySolid"
          onClick={handleWireChoose}
          subtitle={t('chooseDepositType.chooseRail.fundsDescription')}
          title={t('chooseDepositType.chooseRail.funds')}
        />
      </SkeletonBox>

      <PaymentButtons.Cards.Blockchain
        actionType="deposit"
        className="w-116"
        icon="LinkOutline"
        onClick={handleBlockchainChoose}
        subtitle={t('chooseDepositType.chooseRail.walletDescription')}
        title={t('chooseDepositType.chooseRail.wallet')}
      />
    </div>
  )
}

import { useMemo } from 'react'

import { AdvancedTable } from '@circlefin/components'

import { StateHandler } from './StateHandler/StateHandler'

import type { StateHandlerProps } from './StateHandler/StateHandler'
import type { AdvancedTableProps } from '@circlefin/components/lib/AdvancedTable'
import type { PaginationLinks } from '@shared/graphql'

interface Paginated<Data> {
  /**
   * Paginated Links.
   */
  links?: PaginationLinks
  /**
   * Paginated Data.
   */
  list: Data[]
}

const DEFAULT = {
  list: [],
}

export type ComplexTableProps<Data extends object, Filter extends object> =
  Omit<AdvancedTableProps<Data, Filter>, 'data' | 'getCoreRowModel'> &
    StateHandlerProps & {
      /**
       * Table Data.
       */
      data?: Paginated<Data> | Data[]
    }

/**
 * Complex Table Component.
 */
export function ComplexTable<
  Data extends object,
  Filter extends object = Record<string, never>,
>({
  error,
  retry,
  fallbackError,
  empty,
  data = DEFAULT,
  loading,
  className,
  ...props
}: ComplexTableProps<Data, Filter>): React.ReactElement {
  // Convert data to paginated data.
  const paginated = 'list' in data ? data : { list: data }

  const paginationLinks = useMemo(
    () =>
      // Advanced table requires `to` field, but some apis do not always return `to` field.
      // TODO: update components-web to make `to` field optional
      paginated.links
        ? {
            next: paginated.links.next
              ? {
                  ...paginated.links.next,
                  to: paginated.links.next.to ?? new Date(),
                }
              : undefined,
            prev: paginated.links.prev
              ? {
                  ...paginated.links.prev,
                  to: paginated.links.prev.to ?? new Date(),
                }
              : undefined,
          }
        : undefined,
    [paginated.links],
  )

  return (
    <div>
      <AdvancedTable<Data, Filter>
        className={className}
        data={paginated.list}
        loading={loading}
        paginationLinks={paginationLinks}
        {...props}
      />
      <StateHandler
        empty={empty}
        error={error}
        fallbackError={fallbackError}
        loading={loading}
        retry={retry}
        size={paginated.list.length}
      />
    </div>
  )
}

import { Button } from '@circlefin/components'
import { signIn } from '@services/sections/lib/home'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

export interface AlreadyHaveAccountProps {
  /**
   * Custom style.
   */
  className?: string
}

export const AlreadyHaveAccount: React.FC<AlreadyHaveAccountProps> = ({
  className,
}) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={classNames(
        'font-circular text-base tracking-tight text-black-400',
        className,
      )}
    >
      {t`have-account`}
      <Button.Link
        className="ml-2"
        href={signIn.route}
        size="sm"
        variant="text"
      >
        {t`sign-in`}
      </Button.Link>
    </div>
  )
}

import { useCallback } from 'react'

import { FormProvider } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import type { ValidationError } from '@circlefin/form/Form.Context'

export interface FormsProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const FormsProvider: React.FC<FormsProviderProps> = ({ children }) => {
  const { t } = useTranslation('common')

  const translate = useCallback(
    ({ key, ...values }: ValidationError) => {
      return t(`form.v2.validation.${key}`, values)
    },
    [t],
  )

  return <FormProvider translate={translate}>{children}</FormProvider>
}

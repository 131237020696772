import { FixedBanner } from '@circlefin/components'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { BRLDepositInstructionsGrid } from './Currency/BRL/BRLDepositInstructionsGrid'
import { USDDepositInstructionsGrid } from './Currency/USD/USDDepositInstructionsGrid'

import type { BankAccount, TransferInstruction } from '@shared/graphql'

export interface InstructionsWithoutBankAccountDetailsProps {
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Transfer instruction.
   */
  bankAccount: BankAccount
  /**
   * Transfer instruction.
   */
  transferInstruction?: TransferInstruction
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Is query loading.
   */
  loading?: boolean
}

export const InstructionsWithoutBankAccountDetails: React.FC<InstructionsWithoutBankAccountDetailsProps> =
  ({
    loading,
    currency,
    transferInstruction,
    bankAccount,
    virtualAccountEnabled = false,
  }) => {
    const { t } = useTranslation('modals.transfer')

    if (currency === Currency.USD)
      return (
        <USDDepositInstructionsGrid
          bankAccount={bankAccount}
          loading={loading}
          transferInstruction={transferInstruction}
          virtualAccountEnabled={virtualAccountEnabled}
        />
      )

    if (currency === Currency.BRL)
      return (
        <BRLDepositInstructionsGrid
          bankAccount={bankAccount}
          loading={loading}
          transferInstruction={transferInstruction}
          virtualAccountEnabled={virtualAccountEnabled}
        />
      )

    return (
      <FixedBanner level="site" status="warning" visible>
        <FixedBanner.Title>
          {t`fx.depositDetailsMismatch.title`}
        </FixedBanner.Title>
        <FixedBanner.Description>
          <p>{t`fx.depositDetailsMismatch.body`}</p>
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

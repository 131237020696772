import { Card } from '@circlefin/components'
import classNames from 'classnames'

import { Body } from './Body/Body'
import { Filters } from './Filters/Filters'
import { Header } from './Header/Header'
import { Title } from './Title/Title'
import { ViewAll } from './ViewAll/ViewAll'

import type { CardProps } from '@circlefin/components/lib/Card'

export interface TableCardProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Flag to disable the card's appearance.
   */
  disableCardAppearance?: boolean
  /**
   * Card Variant.
   */
  variant?: CardProps['variant']
  /**
   * Padding Variant.
   */
  paddingVariant?: 'lg' | 'sm'
  /**
   * Custom Style?
   */
  className?: string
}

/**
 * Subcomponents Interface.
 */
interface SubComponents {
  Body: typeof Body
  Filters: typeof Filters
  Header: typeof Header
  Title: typeof Title
  ViewAll: typeof ViewAll
}

export const TableCard: React.FC<TableCardProps> & SubComponents = ({
  children,
  className,
  variant = 'primary/base',
  paddingVariant = 'lg',
  ...rest
}) => (
  <section {...rest}>
    <Card className={className} variant={variant}>
      <Card.Content
        className={classNames({
          'p-6': paddingVariant === 'lg',
          'p-3': paddingVariant === 'sm',
        })}
      >
        {children}
      </Card.Content>
    </Card>
  </section>
)

TableCard.displayName = 'TableCard'
TableCard.Body = Body
TableCard.Filters = Filters
TableCard.Header = Header
TableCard.Title = Title
TableCard.ViewAll = ViewAll

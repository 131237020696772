import { Header, SkeletonBox } from '@circlefin/components'
import { vaultDetailsSection } from '@services/sections/lib/vaults'
import { BackButton } from '@shared/components/layout'
import { useVaultWalletQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { VaultWalletDetailHeaderActions } from './VaultWalletDetailHeader.Actions'

export interface VaultWalletDetailHeaderProps {
  /**
   * Vault id.
   */
  vaultId: string
  /**
   * Vault wallet id.
   */
  walletId: string
}

export const VaultWalletDetailHeader: React.FC<VaultWalletDetailHeaderProps> =
  ({ vaultId, walletId }) => {
    const { t } = useTranslation('vaults')
    const { data, loading } = useVaultWalletQuery({
      variables: {
        vaultId,
        walletId,
      },
    })

    return (
      <>
        <BackButton query={{ vaultId }} section={vaultDetailsSection}>
          {t`vaultWallets.detail.header.back`}
        </BackButton>
        <Header loading={loading}>
          <Header.Head
            title={`${data?.vaultWallet.assetMetadata.name ?? ''} wallet`}
          >
            <SkeletonBox className="h-10 w-44" loading={loading}>
              <VaultWalletDetailHeaderActions
                vaultId={vaultId}
                walletId={walletId}
              />
            </SkeletonBox>
          </Header.Head>
        </Header>
      </>
    )
  }

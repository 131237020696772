import { DescriptionList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { SignetBankAccount } from '@shared/graphql'

const { Label, Description } = DescriptionList

export const Signet: React.FC<SignetBankAccount> = ({ walletAddress }) => {
  const { t } = useTranslation('express/modal')

  return (
    <DescriptionList
      className="min-w-160"
      data-testid="signet-bank-account"
      size="sm"
    >
      <Label className="w-64">{t`addRoute.review.label.bank`}</Label>
      <Description>{t`addRoute.review.label.signet`}</Description>
      <Label className="w-64">{t`addRoute.review.label.signetWalletAddress`}</Label>
      <Description>{walletAddress}</Description>
    </DescriptionList>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * SOL blockchain USDC access permission.
 */
export const SOL_BLOCKCHAIN_WITHDRAWAL: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [
    SynthRole.SYNTH_SOL_BLOCKCHAIN_ROLE,
    SynthRole.SYNTH_SOL_WITHDRAWAL_BLOCKCHAIN_ROLE,
  ],
}

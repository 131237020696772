import type React from 'react'

import { TextLink } from '@circlefin/components'
import { support } from '@services/sections/lib/home'
import { IconNotice } from '@shared/components/common'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const SalesforceSupportSuccess: React.FC = () => {
  const { t } = useTranslation('support')

  return (
    <div className="mt-12">
      <IconNotice
        intent="success"
        name="CheckOutline"
        size="large"
        subtitle={
          <>
            {t`form.success.subtitle`}
            <br />

            <Link href={support.route} legacyBehavior passHref>
              <TextLink.Link className="mt-3" variant="primary">
                {t`form.success.submitAgain`}
              </TextLink.Link>
            </Link>
          </>
        }
        title={t`form.success.title`}
      />
    </div>
  )
}

import { VAULT_QUERY } from '@services/permissions'

import type { Section } from '../types'

export const vaultTransactionDetailsSection: Section = {
  label: 'layout:page.vault.transaction.title',
  route: '/vaults/transaction/[id]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Transaction Detail Page',
  },
}

export const vaultWalletDetailsSection: Section = {
  label: 'layout:page.vault.wallet.title',
  route: '/vaults/[vaultId]/wallets/[walletId]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Wallet Detail Page',
  },
}

export const vaultDetailsSection: Section = {
  label: 'layout:page.vault.vaults.title',
  route: '/vaults/[vaultId]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Detail Page',
  },
}

export const vaultPolicySection: Section = {
  label: 'layout:page.vault.vaults.policy',
  route: '/vaults/[vaultId]/policy',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Policy Page',
  },
}

export const vaultActivitySection: Section = {
  label: 'layout:page.vault.vaults.activity',
  route: '/vaults/[vaultId]/activity',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Activity Page',
  },
}

export const vaultsSection: Section = {
  icon: 'VaultSolid',
  label: 'layout:page.vault.title',
  route: '/vaults',
  permissions: VAULT_QUERY,
  pages: [
    vaultTransactionDetailsSection,
    vaultDetailsSection,
    vaultWalletDetailsSection,
    vaultPolicySection,
    vaultActivitySection,
  ],
  segment: {
    page: 'Vaults Homepage',
  },
}

import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../Link.Context'

import { AddressDescription } from './Description/Address.Description'
import { AddressForm } from './Form/Address.Form'

export const Address: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ steps }] = useLinkBankAccount()

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-8" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`link.address.title`}
        </h2>

        <AddressDescription className="mt-2 text-base leading-6 font-circular-regular" />
      </Center>

      <Center variant="horizontal">
        <AddressForm />
      </Center>
    </FullScreen>
  )
}

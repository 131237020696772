import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { BlockchainAlert } from '@features/blockchain/components'
import { BlockchainForms } from '@features/blockchain/forms'
import { InfoAlert } from '@features/delayed-withdrawals/containers'
import { LocalsForms } from '@features/locales/forms'
import { FeatureSwitch } from '@services/feature-switch'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  BlockchainActivityType,
  Currency,
  useBlockchainsQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const schema = y.object({
  /**
   * Recipient name.
   */
  description: y.string(),
  /**
   * Recipient address.
   */
  address: BlockchainForms.Input.AddressSchema.required(),
  /**
   * Recipient blockchain.
   */
  blockchain: BlockchainForms.Dropdown.BlockchainSchema.required().optional(),
  /**
   * Recipient blockchain currency.
   */
  currency: LocalsForms.Dropdown.CurrencySchema,
  /**
   * Recipient email.
   */
  email: y.string().email(),
  /**
   * Some blockchain addresses require a memo field.
   */
  memo: y.string(),
})

export type AddressDetailsNonCustomerFormValues = y.InferType<typeof schema>

/**
 * Set chain as required in submit function.
 */
type AddressDetailsSubmitValues = AddressDetailsNonCustomerFormValues &
  Required<Pick<AddressDetailsNonCustomerFormValues, 'blockchain'>>

export interface AddressDetailsNonCustomerFormProps {
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: AddressDetailsSubmitValues) => void
  /**
   * Loading state of mutation.
   */
  loading: boolean
}

export const AddressDetailsNonCustomerForm: React.FC<AddressDetailsNonCustomerFormProps> =
  ({ loading, onSubmit }) => {
    const { t } = useTranslation('address-book/modals')

    const blockchains = useBlockchainsQuery({
      variables: {
        activityType: BlockchainActivityType.ALL,
      },
    })

    // Create form
    const [Form, { watch, trigger }] =
      useForm<AddressDetailsNonCustomerFormValues>({
        schema,
        defaultValues: {
          currency: Currency.USD,
        },
      })

    // Handles form submit
    const handleSubmit = useCallback(
      (values: AddressDetailsNonCustomerFormValues) => {
        // chain is required
        if (values.blockchain) {
          onSubmit({ ...values, blockchain: values.blockchain })
        }
      },
      [onSubmit],
    )

    // Handle Currency change.
    const handleCurrencyChange = useCallback(
      (newVal: SelectListItem<Currency>) => {
        void blockchains.refetch({
          currency: newVal.value,
        })
      },
      [blockchains],
    )

    // Form Values
    const blockchain = watch('blockchain')

    // Handle field change dependencies
    watch(({ address }, { name }) => {
      // When the blockchain field changed and address is not empty
      if (name === 'blockchain' && address !== undefined) {
        // Trigger revalidation
        void trigger('address')
      }
    })

    return (
      <GraphQLErrorBoundary
        error={blockchains.error}
        retry={blockchains.refetch}
      >
        <Center>
          <Form
            className="grid w-96 gap-y-6"
            data-testid="address-details-form"
            onSubmit={handleSubmit}
          >
            {/* Currency */}
            <LocalsForms.Dropdown.Currency
              className="w-full"
              data-testid="input-currency"
              onChange={handleCurrencyChange}
            />

            {/* Description */}
            <Form.Input
              className="w-full"
              data-testid="input-description"
              display="floating"
              label={t`addAddress.addressDetails.non_customer_entity.description`}
              name="description"
            />

            {/* Blockchains */}
            <BlockchainForms.Dropdown.Blockchains
              activityType={BlockchainActivityType.WITHDRAWAL}
              className="w-full"
              currency={watch('currency')}
              data-testid="dropdown-blockchain"
              display="floating"
            />

            {/* Email */}
            {FeatureSwitch.newAddressBookEmailsEnabled() && (
              <Form.Input
                className="w-full"
                data-testid="input-email"
                display="floating"
                label={t`addAddress.addressDetails.non_customer_entity.email`}
                name="email"
              />
            )}

            {/* Address */}
            <BlockchainForms.Input.Address
              className="w-full"
              data-testid="input-address"
              display="floating"
            />

            {/* Memo */}
            <BlockchainForms.Input.Memo
              chain={blockchain?.chain}
              className="w-full"
              data-testid="input-memo"
              display="floating"
            />

            {/* Blockchain Alert */}
            <BlockchainAlert blockchain={blockchain?.chain} />

            {/* Delayed Withdrawals Alert */}
            <InfoAlert />

            {/* Flow Disclaimer */}
            {blockchain?.chain === BlockchainAbbreviation.FLOW && (
              <p className="w-full text-center text-sm">
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="address-book/modals:addAddress.addressDetails.flowDisclaimer"
                />
              </p>
            )}

            {/* Submit Form */}
            <Center className="pt-6" variant="horizontal">
              <Form.SubmitButton
                className="w-64"
                data-testid="button-submit"
                loading={loading}
                variant="primary"
              >{t`common:continue`}</Form.SubmitButton>
            </Center>
          </Form>
        </Center>
      </GraphQLErrorBoundary>
    )
  }

import { useCallback } from 'react'

import { ButtonCard, Heading } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FullScreen } from '@modals/layout'
import { VAULT_MUTATION } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const SelectRecipient: React.FC = () => {
  const { router } = useModal()
  const { t } = useTranslation('address-book/modals')

  const [, { isAuthorized }] = usePermission(VAULT_MUTATION)

  const handleCustomerClick = useCallback(
    () =>
      router.push(
        isAuthorized
          ? routes.account.addressBook.vaultsEnabled.addNewAddress.multiAsset
              .customer
          : routes.account.addressBook.vaultsEnabled.addNewAddress.default
              .customer,
      ),
    [router, isAuthorized],
  )

  const handleNonCustomerClick = useCallback(
    () =>
      router.push(
        isAuthorized
          ? routes.account.addressBook.vaultsEnabled.addNewAddress.multiAsset
              .nonCustomer
          : routes.account.addressBook.vaultsEnabled.addNewAddress.default
              .nonCustomer,
      ),
    [router, isAuthorized],
  )

  return (
    <FullScreen totalSteps={4}>
      <Center className="text-center" variant="horizontal">
        <h2 className="text-2xl text-black-900 font-circular-bold">
          {t`selectRecipient.title`}
        </h2>
        <h3 className="mt-2 font-circular text-lg leading-6 text-black-400">
          {t`selectRecipient.subtitle`}
        </h3>
        <Center className="mt-14" variant="horizontal">
          <div className="w-87">
            <ButtonCard onClick={handleCustomerClick}>
              <Heading iconName="OfficeBuildingSolid">
                <Heading.Header>{t`selectRecipient.customer`}</Heading.Header>
              </Heading>
            </ButtonCard>
            <ButtonCard className="mt-6" onClick={handleNonCustomerClick}>
              <Heading iconName="UserSolid">
                <Heading.Header>{t`selectRecipient.non_customer_entity`}</Heading.Header>
              </Heading>
            </ButtonCard>
          </div>
        </Center>
      </Center>
    </FullScreen>
  )
}

import { Sidenav } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { isPartOfSection } from '@services/sections'
import { inAppNotificationsSection } from '@services/sections/lib/inAppNotifications'
import { useSegment } from '@services/segment'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { UnreadInAppNotificationCount } from './UnreadInAppNotificationCount/UnreadInAppNotificationCount'

export const InAppNotificationNavItem: React.FC = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const [{ Authorized }] = usePermission(inAppNotificationsSection.permissions)
  const { trackLinkClick } = useSegment()

  const isActive = isPartOfSection(router.pathname, inAppNotificationsSection)

  return (
    <Authorized>
      <Link href={inAppNotificationsSection.route} legacyBehavior passHref>
        {/* TODO: update components-web to accept react components as children instead of just strings */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Sidenav.Item
          active={isActive}
          id={inAppNotificationsSection.label}
          name={inAppNotificationsSection.icon ?? 'BellOutline'}
          onClick={trackLinkClick}
        >
          <span className="inline-flex items-center">
            {t(inAppNotificationsSection.label)}

            <UnreadInAppNotificationCount className="ml-2.5" />
          </span>
        </Sidenav.Item>
      </Link>
    </Authorized>
  )
}

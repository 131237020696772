import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { WalletApprovalPolicyProvider } from '@features/wallets/hooks/approval-policy/provider'
import { WalletsApprovalPolicy } from '@features/wallets/modals'
import { walletApprovalPolicy } from '@services/sections/modal/wallet-approval-policy/wallet-approval-policy'

/**
 * Wallets Router.
 */
export const WalletsRouter: React.FC = () => {
  return (
    <>
      {/* Wallet Approval Policy Routes */}
      <ModalSwitch path={walletApprovalPolicy.createPolicy.switch}>
        <WalletApprovalPolicyProvider>
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Initialize />}
            path={walletApprovalPolicy.createPolicy.initialize}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Permissions />}
            path={walletApprovalPolicy.createPolicy.permissions}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Limits.Policy />}
            path={walletApprovalPolicy.createPolicy.policyLimits}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Limits.User />}
            path={walletApprovalPolicy.createPolicy.userLimits}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Approval />}
            path={walletApprovalPolicy.createPolicy.approval}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Settings />}
            path={walletApprovalPolicy.createPolicy.settings}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Review.Policy />}
            path={walletApprovalPolicy.createPolicy.review}
          />
          <ModalRoute
            content={<WalletsApprovalPolicy.Create.Review.Finished />}
            path={walletApprovalPolicy.createPolicy.finished}
          />
        </WalletApprovalPolicyProvider>
      </ModalSwitch>

      {/* Discard draft policy */}
      <ModalRoute
        content={<WalletsApprovalPolicy.DiscardDraftPolicy />}
        path={walletApprovalPolicy.discardDraftPolicy}
      />

      {/* Cancel pending transaction */}
      <ModalRoute
        content={<WalletsApprovalPolicy.CancelPendingTransaction />}
        path={walletApprovalPolicy.cancelPendingTransaction}
      />

      {/* Cancel approval request */}
      <ModalRoute
        content={<WalletsApprovalPolicy.CancelApprovalRequest />}
        path={walletApprovalPolicy.cancelApprovalRequest}
      />

      {/* Approve approval request */}
      <ModalRoute
        content={<WalletsApprovalPolicy.ApproveApprovalRequest />}
        path={walletApprovalPolicy.approveApprovalRequest}
      />

      {/* Reject approval request */}
      <ModalRoute
        content={<WalletsApprovalPolicy.RejectApprovalRequest />}
        path={walletApprovalPolicy.rejectApprovalRequest}
      />

      {/* Submission blocked */}
      <ModalRoute
        content={<WalletsApprovalPolicy.SubmissionBlocked />}
        path={walletApprovalPolicy.submissionBlocked}
      />

      {/* Submitted policy progress blocked */}
      <ModalRoute
        content={<WalletsApprovalPolicy.SubmittedPolicyProgress />}
        path={walletApprovalPolicy.submittedPolicyProgress}
      />
    </>
  )
}

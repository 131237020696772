import { useCallback, useMemo, useState } from 'react'

import { Button } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import {
  UserLimitsTable,
  useUserLimitsValidate,
} from '../../../../../containers/approval-policy'
import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../../layout'

import type { UserLimitsRowProps } from '../../../../../containers/approval-policy/UserLimitsTable/UserLimits.Row'
import type { WalletApprovalPolicyUserLimit } from '@shared/graphql'

export const User: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const { router } = useModal()
  const formatMoney = useMoney({ locale: 'en-US' }).money
  const [{ currency = Currency.USDC }, { tokenToCurrency }] =
    useCurrentCurrency()
  const [{ policyLimits, userLimits = [] }, { setUserLimits }] =
    useWalletApprovalPolicy()
  const { operators, isOverPolicyTransactionLimit, isOverPolicyVolumeLimit } =
    useUserLimitsValidate()

  const [formErrors, setFormErrors] = useState<
    WalletApprovalPolicyUserLimit['user']['id'][]
  >([])

  const onUserRowChange = useCallback(
    (updatedValues: WalletApprovalPolicyUserLimit) => {
      const foundIndex = userLimits.findIndex(
        (item) => item.user.id === updatedValues.user.id,
      )

      if (foundIndex === -1) {
        return
      }

      const updated = [...userLimits]
      updated[foundIndex] = {
        ...updated[foundIndex],
        ...updatedValues,
      }

      setUserLimits(updated)

      return updated
    },
    [setUserLimits, userLimits],
  )

  const onUserRowError = useCallback<Required<UserLimitsRowProps>['onError']>(
    (userId, hasError) => {
      setFormErrors((curr) => {
        if (!hasError) {
          return curr.filter((value) => value !== userId)
        }

        return [...curr, userId]
      })
    },
    [],
  )

  const continueDisabled = useMemo(() => formErrors.length > 0, [formErrors])

  const onContinue = useCallback(() => {
    void router.push(routes.walletApprovalPolicy.createPolicy.approval)
  }, [router])

  return (
    <WalletApprovalPolicyLayout
      currentStep={WalletApprovalPolicyStep.USER_LIMITS}
    >
      <div className="max-w-160">
        <h2 className="text-neutral-strong type-h-page-sm">
          {t('limits.user.title')}
        </h2>
        <h3 className="mt-4 type-intro-sm">{t('limits.user.subtitle')}</h3>

        <UserLimitsTable
          className="mt-3.5"
          currency={tokenToCurrency(currency)}
          onChange={onUserRowChange}
          onError={onUserRowError}
          policyLimits={policyLimits}
          userLimits={operators}
        />

        {isOverPolicyVolumeLimit && (
          <p className="mt-4 text-sm leading-5 text-error font-circular-book">
            {t('limits.user.errors.policyVolumeLimit', {
              limit: formatMoney({
                number: policyLimits?.maxAmount ?? '0',
                variant: tokenToCurrency(currency),
              }),
            })}
          </p>
        )}

        {isOverPolicyTransactionLimit && (
          <p className="mt-4 text-sm leading-5 text-error font-circular-book">
            {t('limits.user.errors.policyTransactionLimit', {
              limit: Number(policyLimits?.maxTransactions ?? '0'),
            })}
          </p>
        )}

        <div className="mt-4">
          <ModalBackButton />

          <Button
            className="w-60"
            disabled={continueDisabled}
            onClick={onContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </WalletApprovalPolicyLayout>
  )
}

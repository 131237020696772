import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { Center } from '@shared/components/layout'
import {
  StablecoinExpressRoutesDocument,
  StablecoinExpressType,
  useCreateStablecoinExpressWithdrawalRouteMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../../../AddRoute.Context'
import { BankAccountDetails, Blockchain, Heading } from '../../Details'

import type { ApolloError } from '@apollo/client'
import type { BankAccount, Blockchain as BlockchainType } from '@shared/graphql'

export interface WithdrawalDetailsProps {
  /**
   * Blockchain Source.
   */
  source: BlockchainType
  /**
   * Bank Account Destination.
   */
  destination: BankAccount
}

export const WithdrawalVariant: React.FC<WithdrawalDetailsProps> = ({
  source,
  destination,
}) => {
  const { t } = useTranslation('express/modal')
  const modal = useModal()
  const [data] = useAddRoute()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createWithdrawal] = useCreateStablecoinExpressWithdrawalRouteMutation({
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error: ApolloError) => {
      mfaError({ error })
    },
    refetchQueries: [StablecoinExpressRoutesDocument],
  })

  const onConfirm = useCallback(() => {
    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        if (
          data.direction === StablecoinExpressType.WITHDRAWAL &&
          data.source &&
          data.destination &&
          data.currency != null
        ) {
          void createWithdrawal({
            variables: {
              input: {
                bankId: data.destination.id,
                bankType: data.destination.type,
                chain: data.source.chain,
                currency: data.currency,
              },
            },
            context: context(code),
          })
        } else {
          mfaError({ message: t`common:generalError` })
        }
      },
      onAbort: () => {
        modal.close()
      },
    })
  }, [context, createWithdrawal, data, mfaError, modal, t, withMfa])

  return (
    <>
      <Heading>{t`addRoute.review.from`}</Heading>
      <Blockchain.Withdrawal chain={source.chain} />

      <Heading>{t`addRoute.review.to`}</Heading>
      <BankAccountDetails bankAccount={destination} />

      <Center className="pt-12" variant="horizontal">
        <Button className="w-60" onClick={onConfirm} variant="primary">
          {t`common:confirm`}
        </Button>
      </Center>
    </>
  )
}

import { useCallback, useEffect, useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { TypeGuards } from '@services/type-guards'
import { useBankAccountsQuery } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { useBankAccountTypeByCurrency } from '../../../hooks/type-by-currency'

import type { BankAccount } from '@shared/graphql'

export const BankAccountsSchema = y.mixed<BankAccount>()

const Dropdown = createFormDropdown()

export interface BankAccountsProps
  extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  /**
   * Controls loading state.
   */
  loading?: boolean
  /**
   * Account Types.
   */
  types?: BankAccount['type'][]
  /**
   * Controls if we only want banks w/ a "completed" status in the dropdown.
   */
  status?: BankAccount['status'][]
}

export const BankAccounts: React.FC<BankAccountsProps> = ({
  className,
  types,
  loading = false,
  status = [],
  label,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const loadingClasses = classNames('block h-10', className)

  const [{ currency }] = useCurrentCurrency()
  const { bankAccountType, setCurrency } = useBankAccountTypeByCurrency()

  useEffect(() => {
    if (currency != null) {
      setCurrency(currency)
    }
  }, [setCurrency, currency])

  const account = useBankAccountsQuery({
    variables: {
      types: types ?? bankAccountType,
    },
  })

  const getBankAccountLabel = useCallback(
    (account: BankAccount) => {
      if (!account.label) {
        if (TypeGuards.BankAccount.isSignet(account)) {
          return t('common:form.signetWithoutNicknameLabel', {
            label: account.label,
          })
        }

        if (TypeGuards.BankAccount.isCbit(account)) {
          return t('common:form.cbitWithoutNicknameLabel', {
            label: account.label,
          })
        }
      }

      return account.label
    },
    [t],
  )

  const items = useMemo(() => {
    let accounts = account.data?.bankAccounts ?? []
    if (status.length > 0) {
      accounts = accounts.filter((account) => status.includes(account.status))
    }

    return accounts.map((account) => ({
      value: account,
      label: getBankAccountLabel(account),
    }))
  }, [account, status, getBankAccountLabel])

  return (
    <SkeletonBox
      className={loadingClasses}
      loading={loading || account.loading}
    >
      <Dropdown
        className={className}
        items={items}
        label={label ?? t('dropdown.bankAccount.accounts.label')}
        {...props}
      />
    </SkeletonBox>
  )
}

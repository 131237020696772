import { useMemo } from 'react'

import { useEntityQuery, LegalEntity } from '@shared/graphql'

export const useTravelRuleRequired = () => {
  const { data, loading, error } = useEntityQuery()

  const travelRuleRequired = useMemo(() => {
    return data?.entity?.legalEntity === LegalEntity.CIRCLE_SG
  }, [data?.entity?.legalEntity])

  return { travelRuleRequired, loading, error }
}

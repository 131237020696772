import { Sidenav } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { isPartOfSection } from '@services/sections'
import { useSegment } from '@services/segment'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Section } from '@services/sections/types'

export const MainNavItem: React.FC<Section> = (section) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [{ Authorized }] = usePermission(section.permissions)
  const { trackLinkClick } = useSegment()

  const isActive = isPartOfSection(router.pathname, section)

  if (!section.icon) {
    return null
  }

  return (
    <Authorized>
      <Link href={section.route} legacyBehavior passHref>
        <Sidenav.Item
          active={isActive}
          id={section.label}
          name={section.icon}
          onClick={trackLinkClick}
        >
          {t(section.label)}
        </Sidenav.Item>
      </Link>
    </Authorized>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount } from '@shared/graphql'

export interface BankSetNicknameActionProps {
  /**
   * Bank account that we are setting the nickname for.
   */
  bankAccount: BankAccount
}

export const BankSetNicknameAction: React.FC<BankSetNicknameActionProps> = ({
  bankAccount,
}) => {
  const { t } = useTranslation('settings')
  const modal = useModal()

  const handleClick = useCallback(() => {
    modal.router.push({
      pathname: routes.bankAccount.setNickname,
      query: {
        bankAccount,
      },
    })
  }, [modal, bankAccount])

  return (
    <PaymentButtons.BankAccount
      data-testid="set-nickname"
      onClick={handleClick}
      variant="secondary"
    >
      {t`common:edit`}
    </PaymentButtons.BankAccount>
  )
}

BankSetNicknameAction.displayName = 'BankSetNicknameAction'

import { Header } from '@circlefin/components'
import { HistoryBackButton } from '@containers/layout'
import { TransactionCard } from '@shared/components/common'
import { Content } from '@shared/components/layout'
import { TransactionType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { AddressVerificationInstruction } from '../AddressVerificationInstruction/AddressVerificationInstruction'

import type { TransactionCardProps } from '@shared/components/common'
import type { Payment } from '@shared/graphql'

export interface TransactionDetailProps
  extends Omit<TransactionCardProps, 'transaction'> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Transaction.
   */
  transaction?: Payment
  /**
   * Source Address Id.
   */
  addressId?: string | null
}

export const BlockchainTransactionDetail: React.FC<TransactionDetailProps> = ({
  className,
  transaction,
  addressId,
  loading = false,
  ...cardProps
}) => {
  const { t } = useTranslation()

  return (
    <Content className={className}>
      {/* Header */}
      <HistoryBackButton />
      <Header variant="secondary">
        <Header.Head title={t`payments:transactionDetail.title`} />
        <Header.Description>
          {t('payments:transactionDetail.transactionId', {
            id: transaction?.id,
          })}
        </Header.Description>
      </Header>

      {/* Content */}
      {transaction?.type === TransactionType.onchain_receive && addressId && (
        <AddressVerificationInstruction addressId={addressId} />
      )}
      <TransactionCard
        loading={loading}
        transaction={transaction}
        {...cardProps}
      />
    </Content>
  )
}

import { useMemo } from 'react'

import { ButtonCard, Heading } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { BLOCKCHAIN_DEPOSIT, BLOCKCHAIN_TRANSFER } from '@services/permissions'

import type { ButtonCardProps } from '@circlefin/components/lib/ButtonCard'

export interface BlockchainProps extends ButtonCardProps {
  /**
   * Icon.
   */
  icon?: React.ComponentProps<typeof Heading>['iconName']
  /**
   * Title text.
   */
  title: string
  /**
   * Subtitle text.
   */
  subtitle?: string
  /**
   * Blockchain transaction type.
   */
  actionType: 'deposit' | 'withdraw'
}

export const Blockchain: React.FC<BlockchainProps> = ({
  children,
  icon,
  title,
  subtitle,
  actionType,
  ...props
}) => {
  const permission = useMemo(() => {
    switch (actionType) {
      case 'deposit':
        return BLOCKCHAIN_DEPOSIT
      case 'withdraw':
        return BLOCKCHAIN_TRANSFER
    }
  }, [actionType])

  const [, { isAuthorized }] = usePermission(permission)

  return (
    <ButtonCard
      data-testid="blockchain-button-card"
      {...props}
      disabled={!isAuthorized}
    >
      <Heading iconName={icon}>
        <Heading.Header>{title}</Heading.Header>
        <Heading.Intro>{subtitle}</Heading.Intro>
      </Heading>
    </ButtonCard>
  )
}

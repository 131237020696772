import { useCallback, useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { BlockchainForms } from '@features/blockchain/forms'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  BlockchainActivityType,
  Currency,
  useMerchantPaymentIntentQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useRefund } from '../Refund.Context'
import { Summary } from '../Summary/Summary'

import { SourceCurrencySelection } from './SourceCurrencySelection/SourceCurrencySelection'

import type { Blockchain } from '@shared/graphql'

const schema = y.object({
  /**
   * Payment Intent Id.
   */
  paymentIntentId: y.string(),
  /**
   * Destination Address.
   */
  address: y.string().required(),
  /**
   * Destination chain.
   */
  chain: y.string().oneOf(Object.values(BlockchainAbbreviation)).required(),
  /**
   * Refund Amount.
   */
  refundAmount: y.number().required(),
  /**
   * Refund Currency.
   */
  refundCurrency: y.string().oneOf(Object.values(Currency)),
  /**
   * Source Currency.
   */
  sourceCurrency: y.string().oneOf(Object.values(Currency)).optional(),
  /**
   * Agreement.
   */
  agreement: y.boolean().required().oneOf([true]),
})

export type DetailsFormValues = y.InferType<typeof schema>

export interface DetailsFormProps {
  /**
   * Payment Intent ID to be refunded.
   */
  paymentIntentId: string
}

export const DetailsForm: React.FC<DetailsFormProps> = (props) => {
  const { t } = useTranslation('platform/modals')
  const { money } = useMoney()
  const { router } = useModal()

  const [state, { handleValueChange }] = useRefund()

  const { data, error, refetch } = useMerchantPaymentIntentQuery({
    variables: {
      id: props.paymentIntentId,
    },
  })

  // Amount Refundable is the remaining amount after deducting amount refunded from amount paid.
  const amountRefundable = useMemo(() => {
    const payment = data?.merchantPaymentIntent

    if (payment?.amountPaid?.amount && payment?.amountRefunded?.amount) {
      return (
        Number(payment.amountPaid.amount) -
        Number(payment.amountRefunded.amount)
      )
    }

    return 0
  }, [data])

  const currency = useMemo(() => {
    return (
      data?.merchantPaymentIntent.currency ??
      data?.merchantPaymentIntent.amount?.currency ??
      Currency.USD
    )
  }, [data])

  const [Form] = useForm<DetailsFormValues>({
    defaultValues: {
      address: state.address,
      chain: state.chain,
      agreement: state.agreement,
      paymentIntentId: state.paymentIntentId,
      refundAmount: state.refundAmount,
      refundCurrency: state.refundCurrency,
      sourceCurrency: state.sourceCurrency,
    },
    schema: schema.shape({
      refundAmount: y
        .number()
        .moreThan(0, {
          key: 'amount.moreThan',
          moreThan: money({
            number: 0,
            variant: currency,
          }),
        })
        .when([], {
          is: !isNaN(amountRefundable),
          then: (schema) =>
            schema.max(amountRefundable, {
              key: 'amount.max',
              min: money({
                number: amountRefundable,
                variant: currency,
              }),
            }),
        })
        .required()
        .allowEmpty(),
    }),
  })

  const onChainSelect = useCallback(
    (blockchain: Blockchain) =>
      handleValueChange({
        chain: BlockchainAbbreviation[blockchain.chain],
      }),
    [handleValueChange],
  )

  const handleFormSubmit = useCallback(
    (values: DetailsFormValues) => {
      // Update context
      handleValueChange(values)

      // Route to the next screen
      router.push({
        pathname: routes.platform.paymentIntent.review,
        query: {
          paymentIntentId: props.paymentIntentId,
        },
      })
    },
    [handleValueChange, router, props.paymentIntentId],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      <Form
        className="flex size-full flex-col items-stretch justify-start"
        onSubmit={handleFormSubmit}
      >
        <div className="grid grid-cols-6">
          <div className="col-span-2 mt-2 text-left text-sm font-circular-bold">
            {t('payment-intent.refund.details.title')}
          </div>
          <div className="col-span-4 col-start-3">
            <Form.Input
              className="mt-2 w-full"
              data-testid="address"
              label={t('payment-intent.refund.details.walletAddress')}
              name="address"
            />
            <FixedBanner
              className="mb-6 mt-2"
              status="warning"
              multiline
              visible
            >
              <FixedBanner.Title>{t`payment-intent.refund.details.alertTitle`}</FixedBanner.Title>
              <FixedBanner.Description>{t`payment-intent.refund.details.alertSubtitle`}</FixedBanner.Description>
            </FixedBanner>
            {currency === Currency.USD && (
              <BlockchainForms.Dropdown.Blockchains
                activityType={BlockchainActivityType.ALL}
                data-testid="dropdown-destination"
                label={t`payment-intent.refund.details.chain`}
                onChange={onChainSelect}
              />
            )}
            <Form.Checkbox
              className="mt-4 items-baseline"
              label={t`payment-intent.refund.details.confirmation`}
              name="agreement"
            />
          </div>
          <div className="col-span-2 mt-6 text-left text-sm font-circular-bold">
            {t('payment-intent.refund.details.refundAmount')}
          </div>
          <div className="col-span-4 col-start-3">
            <Form.MoneyInput
              className="mt-4 w-full"
              currencyVariant={currency}
              name="refundAmount"
              placeholder={amountRefundable.toString()}
              padded
            />

            <Summary
              amountReceived={data?.merchantPaymentIntent.amountPaid}
              amountRefundable={amountRefundable.toString()}
              amountRefunded={data?.merchantPaymentIntent.amountRefunded}
            />
          </div>
          <div className="col-span-2 mt-6 text-left text-sm font-circular-bold">
            {t('payment-intent.refund.details.source')}
          </div>
          {currency != null && (
            <div className="col-span-4 col-start-3">
              <SourceCurrencySelection currency={currency} />
            </div>
          )}
          {data?.merchantPaymentIntent.settlementCurrency != null &&
          currency !== data?.merchantPaymentIntent.settlementCurrency ? (
            <div className="col-span-4 col-start-3">
              <SourceCurrencySelection
                currency={data?.merchantPaymentIntent.settlementCurrency}
              />
            </div>
          ) : null}
        </div>

        <Center className="pt-10" variant="horizontal">
          <Form.SubmitButton
            className="h-10 w-60"
            data-testid="next-button"
            variant="primary"
          >{t`common:continue`}</Form.SubmitButton>
        </Center>
      </Form>
    </GraphQLErrorBoundary>
  )
}

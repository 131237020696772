import { useCallback, useEffect } from 'react'

import { Button } from '@circlefin/components'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { useTransfer } from '../../../hooks/transfer'

import type { BankAccount } from '@shared/graphql'

interface TestLandingQueryProps {
  /**
   * Pre-selected bank account.
   */
  bankAccount?: BankAccount
}

export const TestLanding: React.FC<TestLandingQueryProps> = ({
  bankAccount,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { track } = useSegment()
  const { router } = useModal()
  const [{ showSelectCurrencyScreen }, { handleValueChange }] = useTransfer()
  const [, { setCurrency }] = useCurrentCurrency()
  const modalHistory = useModalHistory()

  const { travelRuleRequired } = useTravelRuleRequired()

  useEffect(() => {
    // if the prefilled bank account is a sen account and has a currency defined,
    // we can skip the currency selection screen
    if (
      bankAccount &&
      TypeGuards.BankAccount.isSen(bankAccount) &&
      bankAccount.currency != null
    ) {
      // update context state
      setCurrency({
        currency: bankAccount.currency,
      })

      handleValueChange({
        showSelectCurrencyScreen: false,
      })
    }

    // Only USDC is supported in Singapore, we can skip the currency selection screen
    // TODO: remove this logic once EURC is supported in SG
    if (travelRuleRequired) {
      setCurrency({
        currency: Currency.USD,
      })

      handleValueChange({
        showSelectCurrencyScreen: false,
      })
    }
  }, [bankAccount, travelRuleRequired, handleValueChange, setCurrency])

  const handleContinueClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ContinueClicked, {
        event,
      })

      // set the bank account before navigating to the next screen
      if (bankAccount) {
        handleValueChange({ bankAccount })
      }

      if (showSelectCurrencyScreen) {
        router.push(routes.transfer.wire.selectCurrency)
        return
      }

      router.push(routes.transfer.wire.selectBankAccount)
    },
    [showSelectCurrencyScreen, track, router, handleValueChange, bankAccount],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <Center variant="horizontal">
        <h2 className="mb-6 text-center text-xl font-circular-bold">
          {t('wire.testLanding.section1.header')}
        </h2>
        <div
          className="flex h-full w-176 flex-col justify-between gap-y-5 font-circular"
          data-testid="landing-step-content"
        >
          <div className="flex">
            <Image
              alt="usdc icon"
              className="-mt-4"
              height="166"
              objectFit="contain"
              src="/images/USDC1.png"
              width="166"
            />
            <div className="ml-4 flex flex-col">
              <p className="mt-2 text-lg leading-6 font-circular-regular">
                {t(`wire.testLanding.section1.subHeader`)}
              </p>
              <p className="mt-4 text-lg italic leading-6 font-circular-regular">
                {t(`wire.testLanding.section1.recommendation`)}
              </p>
            </div>
          </div>

          <div className="mt-8 grid grid-cols-3 gap-x-7">
            <div>
              <p className="text-lg leading-7 font-circular-bold">
                {t('wire.testLanding.section2.header')}
              </p>
              <p className="mt-3 text-base leading-6 font-circular-regular">
                {t('wire.testLanding.section2.subHeader')}
              </p>
            </div>
            <div>
              <p className="text-lg leading-7 font-circular-bold">
                {t('wire.testLanding.section3.header')}
              </p>
              <p className="mt-3 text-base leading-6 font-circular-regular">
                {t('wire.testLanding.section3.subHeader')}
              </p>
            </div>
            <div>
              <p className="text-lg leading-7 font-circular-bold">
                {t('wire.testLanding.section4.header')}
              </p>
              <p className="mt-3 text-base leading-6 font-circular-regular">
                {t('wire.testLanding.section4.subHeader')}
              </p>
            </div>
          </div>
        </div>
      </Center>

      <Center className="pt-12" variant="horizontal">
        <Button
          className="w-64"
          onClick={handleContinueClick}
          variant="primary"
        >
          {t('common:continue')}
        </Button>
      </Center>
    </FullScreen>
  )
}

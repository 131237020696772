import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Permissions for guarding FFC Memo feature during bank account linking.
 */
export const FFC_MEMO_ENABLED: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_FFC_MEMO_ROLE],
}

import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { OnboardingActiveUsers } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const activeUsersSchema = y.string().required()

export interface ActiveUsersProps
  extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  /**
   * Force loading state.
   */
  loading?: boolean
}

const Dropdown = createFormDropdown()

export const ActiveUsers: React.FC<ActiveUsersProps> = ({
  loading,
  ...props
}) => {
  const { t } = useTranslation('onboard/account-setup')

  const data: Array<SelectListItem<OnboardingActiveUsers>> = useMemo(() => {
    const data = Object.values(OnboardingActiveUsers)
      .filter((value) => value !== OnboardingActiveUsers.notApplicable)
      .map((option) => ({
        label: t(
          `onboard/common:form.activeUsers.${OnboardingActiveUsers[option]}`,
        ),
        value: OnboardingActiveUsers[option],
      }))

    return [
      // Ensure 'Not applicable' option is the first to appear in the dropdown
      // Because generated GQL enums are sorted alphabetically
      {
        label: t(
          `onboard/common:form.activeUsers.${OnboardingActiveUsers.notApplicable}`,
        ),
        value: OnboardingActiveUsers.notApplicable,
      },
      ...data,
    ]
  }, [t])

  return (
    <SkeletonBox className="mt-6 h-10 w-full" loading={loading}>
      <Dropdown
        data-testid="dropdown-active-users"
        items={data}
        label={t`confirm.form.activeUsers.label`}
        maxMenuItems={3}
        placeholder={t`confirm.form.activeUsers.placeholder`}
        {...props}
      />
    </SkeletonBox>
  )
}

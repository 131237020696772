import { useEffect } from 'react'

import { FeatureList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { VAULT_DEVICE_REGISTRATION_STATUS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  VaultDeviceRegistrationStatusCode,
  useTriggerPinSetupMutation,
  useVaultDeviceRegistrationStatusQuery,
} from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import PairPhonePin from '../../../images/PairPhonePin.gif'

export const Pin: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const { data } = useVaultDeviceRegistrationStatusQuery({
    pollInterval: VAULT_DEVICE_REGISTRATION_STATUS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  const [triggerPinSetup, { error }] = useTriggerPinSetupMutation()

  useEffect(() => {
    void triggerPinSetup()
  }, [triggerPinSetup])

  useEffect(() => {
    if (
      data?.vaultDeviceRegistrationStatus.statusCode ===
      VaultDeviceRegistrationStatusCode.READY
    ) {
      modal.router.push(routes.vault.pairDevice.success)
    }
  }, [data?.vaultDeviceRegistrationStatus, modal.router])

  return (
    <FullScreen totalSteps={4}>
      <GraphQLErrorBoundary error={error} retry={triggerPinSetup}>
        <div className="mx-auto flex h-full max-w-192 flex-col justify-between">
          <Center>
            <h2 className="mb-10 text-center text-3xl text-black-600 font-circular-bold">
              {t`pairDevice.pin.title`}
            </h2>
            <div className="flex flex-row justify-center">
              <Image
                alt={t`pairDevice.pin.pairPhonePinAltText`}
                height="183"
                src={PairPhonePin}
                width="320"
              />
            </div>

            <FeatureList className="mt-18 w-120">
              <FeatureList.Item
                heading={t`pairDevice.pin.features.prompt1.heading`}
                icon="LockClosedSolid"
              >
                {t`pairDevice.pin.features.prompt1.message`}
              </FeatureList.Item>
              <FeatureList.Item
                heading={t`pairDevice.pin.features.prompt2.heading`}
                icon="ShieldExclamationOutline"
              >
                {t`pairDevice.pin.features.prompt2.message`}
              </FeatureList.Item>
            </FeatureList>
          </Center>
        </div>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const Confirmation: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const onContinue = useCallback(() => {
    modal.router.push({
      pathname: routes.vault.pairNewDevice.download,
      query: {
        route: routes.vault.pairNewDevice.pair,
        totalSteps: 3,
      },
    })
  }, [modal])

  return (
    <FullScreen>
      <Center
        className="m-auto w-120 justify-items-center text-center"
        variant="horizontal"
      >
        <h2 className="text-3xl text-black-600 font-circular-bold">
          {t`pairNewDevice.confirmation.title`}
        </h2>

        <h3 className="mt-14 text-xl leading-7 font-circular-bold">
          {t`pairNewDevice.confirmation.message1`}
        </h3>

        <p className="mt-3 leading-6 font-circular-regular">
          {t`pairNewDevice.confirmation.message2`}
        </p>

        <div className="mt-6">
          <Button className="w-60" onClick={onContinue} variant="primary">
            {t('common:continue')}
          </Button>
        </div>
      </Center>
    </FullScreen>
  )
}

import { useCallback, useEffect, useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { TransWithLink } from '@shared/components/common'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'

import { AmountMessage } from './AmountMessage'

const isEitherUsdcOrEurcFilled = (
  usdcRedemptionAmount?: number,
  eurcRedemptionAmount?: number,
) => usdcRedemptionAmount != null || eurcRedemptionAmount != null

/**
 * When isEeaUser, either usdcRedemptionAmount or eurcRedemptionAmount must be filled.
 * When is not EeaUser, usdcRedemptionAmount must be empty, but eurcRedemptionAmount is required.
 */
export const createRolrRedemptionAmountSchema = (isEeaUser?: boolean) => {
  const usdcRedemptionAmount = y.number().allowEmpty()
  const eurcRedemptionAmount = y.number().allowEmpty()

  if (isEeaUser) {
    return y.object({
      /**
       * USDC Redemption amount.
       */
      usdcRedemptionAmount: usdcRedemptionAmount.test({
        name: 'eitherUsdcOrEurcRequired',
        message: { key: 'required' },
        test: function (value) {
          const { eurcRedemptionAmount } = this.parent as {
            eurcRedemptionAmount?: number
          }

          return isEitherUsdcOrEurcFilled(value, eurcRedemptionAmount)
        },
      }),
      /**
       * EURC Redemption amount.
       */
      eurcRedemptionAmount: eurcRedemptionAmount.test({
        name: 'eitherUsdcOrEurcRequired',
        message: { key: 'required' },
        test: function (value) {
          const { usdcRedemptionAmount } = this.parent as {
            usdcRedemptionAmount?: number
          }

          return isEitherUsdcOrEurcFilled(value, usdcRedemptionAmount)
        },
      }),
    })
  }

  return y.object({
    /**
     * USDC Redemption amount.
     */
    usdcRedemptionAmount: usdcRedemptionAmount.test({
      name: 'mustBeEmpty',
      test: (value) => value == null,
    }),
    /**
     * EURC Redemption amount.
     */
    eurcRedemptionAmount: eurcRedemptionAmount.required(),
  })
}

export type RolrRedemptionAmountValues = y.InferType<
  ReturnType<typeof createRolrRedemptionAmountSchema>
>

export const RolrRedemptionAmount: React.FC = () => {
  const { t } = useTranslation('onboard/rolr-signup')

  const [{ businessInfo, redemptionAmount }, { computeNextStep }] =
    useRolrSetup()

  const isEeaUser = businessInfo?.isEeaUser

  const schema = useMemo(
    () => createRolrRedemptionAmountSchema(isEeaUser),
    [isEeaUser],
  )

  const [Form, { watch, trigger, getFieldState }] =
    useForm<RolrRedemptionAmountValues>({
      schema,
      defaultValues: {
        eurcRedemptionAmount: redemptionAmount?.eurcRedemptionAmount,
        usdcRedemptionAmount: isEeaUser
          ? redemptionAmount?.usdcRedemptionAmount
          : undefined,
      },
    })

  useEffect(() => {
    if (!isEeaUser) {
      return
    }

    const subscription = watch((_, { name, type }) => {
      if (type === 'change') {
        switch (name) {
          case 'usdcRedemptionAmount':
            if (getFieldState('eurcRedemptionAmount').isTouched) {
              void trigger('eurcRedemptionAmount')
            }
            break
          case 'eurcRedemptionAmount':
            if (getFieldState('usdcRedemptionAmount').isTouched) {
              void trigger('usdcRedemptionAmount')
            }
            break
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [getFieldState, isEeaUser, trigger, watch])

  const complete = useCallback(
    ({
      usdcRedemptionAmount,
      eurcRedemptionAmount,
    }: RolrRedemptionAmountValues) => {
      computeNextStep({
        step: 'redemptionAmount',
        values: {
          usdcRedemptionAmount,
          eurcRedemptionAmount,
        },
      })
    },
    [computeNextStep],
  )

  return (
    <div className="h-full w-96">
      <h1 className="type-h-page-sm">{t`redemptionAmount.title`}</h1>
      <h2 className="mb-8 mt-1 text-neutral-subtle type-intro-sm">{t`redemptionAmount.desc`}</h2>

      <FixedBanner
        className="mb-6"
        status="info"
        visible={isEeaUser === false}
        multiline
      >
        <FixedBanner.Title>{t`redemptionAmount.nonEeaBanner.title`}</FixedBanner.Title>
        <FixedBanner.Description>
          {/* TODO: Update link */}
          <span>
            <TransWithLink
              i18nKey="onboard/rolr-signup:redemptionAmount.nonEeaBanner.desc"
              urlI18nKey="onboard/rolr-signup:redemptionAmount.nonEeaBanner.link"
              variant="url"
            />
          </span>
        </FixedBanner.Description>
      </FixedBanner>

      <Form className="flex flex-col gap-y-4" onSubmit={complete}>
        <Form.MoneyInput
          className="w-full"
          currencyVariant="USD"
          disabled={!isEeaUser}
          label={t('redemptionAmount.form.amount.label', {
            currency: Currency.USDC,
          })}
          message={<AmountMessage amount={watch('usdcRedemptionAmount')} />}
          name="usdcRedemptionAmount"
          disableCurrencyVariant
        />

        <Form.MoneyInput
          className="w-full"
          currencyVariant="EUR"
          label={t('redemptionAmount.form.amount.label', {
            currency: Currency.EURC,
          })}
          message={<AmountMessage amount={watch('eurcRedemptionAmount')} />}
          name="eurcRedemptionAmount"
          disableCurrencyVariant
        />

        <Form.SubmitButton className="mt-2 w-full" variant="primary">
          {t`redemptionAmount.complete`}
        </Form.SubmitButton>
      </Form>
    </div>
  )
}

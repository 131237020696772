import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useMerchantPaymentCancellationMutation } from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { CancelForm } from './Cancel.Form'

import type { MerchantPaymentCancellationInput } from '@shared/graphql'

export interface CancelQueryProps {
  /**
   * Payment ID to cancel.
   */
  paymentId?: string
}

export const Cancel: React.FC<CancelQueryProps> = (props) => {
  const { t } = useTranslation('platform/modals')
  const { close } = useModal()
  const { track } = useSegment()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [initiateCancellation] = useMerchantPaymentCancellationMutation({
    onCompleted: () => {
      mfaSuccess({
        message: t`payment.cancel.success`,
      })
    },
    onError: (error) => {
      mfaError({
        error,
        message: t`payment.cancel.failure`,
      })
    },
  })

  const onSubmit = useCallback(
    (input: MerchantPaymentCancellationInput) => {
      withMfa({
        onComplete: (code) => {
          void initiateCancellation({
            variables: {
              input,
            },
            context: context(code),
          })
          track(SegmentEvents.CancelMerchantPaymentClicked)
        },
      })
    },
    [context, initiateCancellation, track, withMfa],
  )

  return (
    <BaseTitle title={t`payment.cancel.title`}>
      <PropsErrorBoundary<CancelQueryProps> props={props} variant="page">
        {({ paymentId }) => (
          <CancelForm
            onCancel={close}
            onSubmit={onSubmit}
            paymentId={paymentId}
          />
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

Cancel.displayName = 'Payment.Cancel'

import { FixedBanner } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface DefaultProps {
  /**
   * Currency.
   */
  currency: Currency
}

export const Default: React.FC<DefaultProps> = ({ currency }) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <>
      <FixedBanner.Title>
        {t('policy.banners.defaultPolicy.title')}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t('policy.banners.defaultPolicy.description', { currency })}</p>
      </FixedBanner.Description>
    </>
  )
}

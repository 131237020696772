import { useMemo } from 'react'

import { ButtonCard, Glyph } from '@circlefin/components'
import { FeatureSwitch } from '@services/feature-switch'
import { createAccount } from '@services/sections/lib/getStarted'
import { signupSection } from '@services/sections/lib/signup'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const BusinessServices: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  // Determine which sign-up screen CTA routes to
  const ctaRoute = useMemo(() => {
    return FeatureSwitch.businessAccountCreationEnabled()
      ? createAccount.route
      : signupSection.route
  }, [])

  return (
    <ButtonCard.Link href={ctaRoute} onClick={trackLinkClick} hideArrow>
      <div className="flex items-center gap-4">
        <Glyph className="shrink-0" name="Financial" />
        <div>
          <h4 className="text-neutral-strong type-h-title-md">
            {t`platformSelect.options.business.title`}
          </h4>
          <p className="text-neutral-subtle type-body-sm">
            {t`platformSelect.options.business.description`}
          </p>
        </div>
      </div>
    </ButtonCard.Link>
  )
}

import { useCallback, useMemo, useState } from 'react'

import {
  ButtonCard,
  Heading,
  Icon,
  ScrollArea,
  SkeletonBox,
  Tooltip,
} from '@circlefin/components'
import { Input } from '@circlefin/components/lib/Input'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useAssetsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVaultWallet } from '../CreateVaultWallet.Context'

import { Empty } from './Empty'

import type { Asset } from '@shared/graphql'

export interface ChooseAssetQueryProps {
  /**
   * Vault Id.
   */
  vaultId?: string
}

export const ChooseAsset: React.FC<ChooseAssetQueryProps> = ({ vaultId }) => {
  const modal = useModal()
  const { t } = useTranslation('modals.vault')

  const [query, setQuery] = useState('')

  const { data, error, refetch, loading } = useAssetsQuery({
    variables: {
      input: {
        vaultId,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const [, { setAsset, setVaultId, setBlockchain }] = useCreateVaultWallet()

  const handleQueryChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((evt) => {
      setQuery(evt.target.value)
    }, [])

  const handleAssetSelection = useCallback(
    (asset: Asset) => {
      return () => {
        setAsset(asset)
        setVaultId(vaultId ?? '')
        if (asset.blockchains.length > 1) {
          // this asset is supported on multiple blockchains
          modal.router.push(routes.vault.createVaultWallet.selectBlockchain)
        } else {
          setBlockchain(asset.blockchains[0])
          // only one blockchain is supported --> directly show confirmation screen
          modal.router.push(routes.vault.createVaultWallet.confirm)
        }
      }
    },
    [modal.router, setAsset, setBlockchain, setVaultId, vaultId],
  )

  const filteredAssets = useMemo(
    () =>
      (data?.assets ?? []).filter((asset) =>
        asset.name.toLowerCase().includes(query.toLowerCase()),
      ),
    [data?.assets, query],
  )

  return (
    <FullScreen totalSteps={4} variant="fixed">
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        {/* Header */}
        <div className="mx-auto grid h-full w-200 grid-rows-auto-1">
          <div className="w-full text-center">
            {/* Headline */}
            <h2 className="mb-8 text-2xl text-black-900 font-circular-bold">
              {t('createVaultWallet.chooseAsset.title')}
            </h2>

            {/* Search Input */}
            <SkeletonBox className="h-10 w-full" loading={loading}>
              <Input
                className="w-full"
                placeholder={t`createVaultWallet.chooseAsset.searchPlaceholder`}
                value={query}
              >
                <Input.Field.Text onChange={handleQueryChange}>
                  <Input.Inline.Icon align="left" name="SearchOutline" />
                </Input.Field.Text>
              </Input>
            </SkeletonBox>

            {/* Divider */}
            <hr className="my-4 text-black-100" />
          </div>

          {/* Assets */}
          <ScrollArea>
            <div className="grid grid-cols-3 gap-5 px-4 text-black-600">
              {/* Loading State */}
              {loading &&
                Array.from(Array(6).keys()).map((key) => (
                  <SkeletonBox key={key} className="h-18 w-full" loading />
                ))}

              {/* Empty State */}
              {!loading && filteredAssets.length === 0 && (
                <div className="col-span-3">
                  <Empty />
                </div>
              )}

              {/* Data State */}
              {!loading &&
                filteredAssets.length > 0 &&
                filteredAssets.map((asset, idx) => {
                  /* Item is in rightmost column */
                  const rightColumn = (idx + 1) % 3 === 0
                  return (
                    <div key={idx} className="relative">
                      <ButtonCard
                        key={asset.symbol}
                        className="h-18 w-full"
                        disabled={!asset.available}
                        onClick={handleAssetSelection(asset)}
                        variant="primary/sm"
                        hideArrow
                      >
                        <Heading iconName={asset.icon} variant="title/sm">
                          <Heading.Header>{asset.name}</Heading.Header>
                          <Heading.Intro>{asset.symbol}</Heading.Intro>
                        </Heading>
                      </ButtonCard>
                      {!asset.available && (
                        <Tooltip
                          className="z-10"
                          content={t('createVaultWallet.chooseAsset.tooltip', {
                            token: asset.name,
                          })}
                          place={rightColumn ? 'left' : 'right'}
                          width="hug"
                          /* Container hides tooltip, change orientation based on column */
                        >
                          <div className="absolute right-3 top-3 self-start">
                            <Icon
                              className="text-black-300"
                              height={18}
                              name="InformationCircleOutline"
                              width={18}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )
                })}
            </div>
          </ScrollArea>
        </div>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

export default ChooseAsset

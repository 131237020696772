import { useMemo } from 'react'

import { FixedBanner, TextLink } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainAbbreviation } from '@shared/graphql'

export interface BlockchainAlertProps {
  /**
   * Blockchain abbreviation.
   */
  blockchain?: BlockchainAbbreviation
  /**
   * Custom className.
   */
  className?: string

  /**
   * Is deposit flow.
   */
  isDeposit?: boolean
}

export const BlockchainAlert: React.FC<BlockchainAlertProps> = ({
  blockchain,
  className,
  isDeposit = false,
}) => {
  const { t } = useTranslation('payments')

  /**
   * Array of chains with legal/notice copy.
   */
  const chainsWithNotice = useMemo(
    () => Object.keys(t('blockchainAlert', null, { returnObjects: true })),
    [t],
  )

  if (blockchain == null || !chainsWithNotice.includes(blockchain)) {
    return null
  }

  return (
    <FixedBanner
      className={className}
      data-testid="legal-alert"
      status="warning"
      multiline
    >
      <FixedBanner.Title>
        {t(
          `blockchainAlert.${blockchain}.${
            isDeposit ? 'deposit' : 'transfer'
          }.title`,
        )}
      </FixedBanner.Title>
      <FixedBanner.Description>
        {t(
          `blockchainAlert.${blockchain}.${
            isDeposit ? 'deposit' : 'transfer'
          }.copy`,
        )}
      </FixedBanner.Description>
      <FixedBanner.Footer>
        <TextLink.Link
          key="blockchain-alert-link"
          href={t(`blockchainAlert.${blockchain}.link`)}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t(`blockchainAlert.${blockchain}.learnMore`)}
        </TextLink.Link>
      </FixedBanner.Footer>
    </FixedBanner>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { FeatureSwitch } from '@services/feature-switch'
import {
  loginAndSecuritySection,
  personalInfo,
} from '@services/sections/lib/settings'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals/settings/users')
  const router = useRouter()
  const modal = useModal()

  const handleConfirm = useCallback(() => {
    // Ensure modal closes fully before pushing to next route
    setTimeout(() => {
      modal.close()
    }, 2000)

    if (FeatureSwitch.pinCodeResetEnabled()) {
      void router.push(loginAndSecuritySection.route)
      return
    }

    void router.push(personalInfo.route)
  }, [modal, router])

  return (
    <BaseTitle title={t('password.success.title')} variant="center">
      <p className="text-sm leading-6 text-black-600">
        {t`password.success.description`}
      </p>
      <Modal.Footer className="mt-6" variant="stretch" dense>
        <Button onClick={handleConfirm} variant="primary">
          {t`common:gotIt`}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  VaultDocument,
  useCancelPendingApprovalMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface CancelPendingApprovalProps {
  /**
   * Id of the vault whose approval request should be canceled.
   */
  vaultId?: string
}

export const CancelPendingApproval: React.FC<CancelPendingApprovalProps> = ({
  vaultId,
}) => {
  const { t } = useTranslation('modals.vault')
  const { close } = useModal()

  const handleCancel = useCallback(() => {
    close({ context: 'onDismiss' })
  }, [close])

  const [cancelPendingApproval, { loading }] = useCancelPendingApprovalMutation(
    {
      variables: {
        vaultId: vaultId ?? '',
      },
      onCompleted: handleCancel,
      refetchQueries: [
        {
          query: VaultDocument,
          variables: {
            id: vaultId,
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  )

  const handleCancelPendingApproval = useCallback(() => {
    void cancelPendingApproval()
  }, [cancelPendingApproval])

  return (
    <>
      <Modal.Title>{t`vaultDetails.cancelPendingApproval.title`}</Modal.Title>
      <Modal.Body>
        <p className="mt-2 text-center">{t`vaultDetails.cancelPendingApproval.description`}</p>
      </Modal.Body>
      <Modal.Footer variant="stretch-equal">
        <Button
          disabled={loading}
          onClick={handleCancel}
          variant="secondary"
        >{t`common:cancel`}</Button>
        <Button
          loading={loading}
          onClick={handleCancelPendingApproval}
          variant="primary"
        >{t`vaultDetails.cancelPendingApproval.action`}</Button>
      </Modal.Footer>
    </>
  )
}

import type { HTMLAttributes } from 'react'
import { cloneElement } from 'react'

import classNames from 'classnames'

export interface FormGridElementProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The child that should be rendered. It will automatically receive the w-full class.
   */
  children: React.ReactElement<HTMLAttributes<HTMLDivElement>>
  /**
   * Set this property to true to disable the automatic addition of the class "w-full".
   */
  omitFullWidth?: boolean
}

export const FormGridElement: React.FC<FormGridElementProps> = ({
  children,
  className,
  omitFullWidth,
  ...rest
}) => (
  <div className={classNames(className, 'w-full min-h-16')} {...rest}>
    {cloneElement(children, {
      ...children.props,
      // we want to add w-full to the element so that is takes as much space as possible
      className: classNames(
        {
          'w-full': !omitFullWidth,
        },
        children.props.className,
      ),
    })}
  </div>
)

FormGridElement.displayName = 'FormGrid.Element'

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useMerchantPaymentRefundMutation } from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { RefundForm } from './Refund.Form'

import type { MerchantPaymentRefundInput } from '@shared/graphql'

export interface RefundQueryProps {
  /**
   * Payment ID to refund.
   */
  paymentId?: string
}

export const Refund: React.FC<RefundQueryProps> = (props) => {
  const { t } = useTranslation('platform/modals')
  const { close } = useModal()
  const { track } = useSegment()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [initiateRefund] = useMerchantPaymentRefundMutation({
    onCompleted: () => {
      mfaSuccess({
        message: t`payment.refund.success`,
      })
    },
    onError: (error) => {
      mfaError({
        error,
        message: t`payment.refund.failure`,
      })
    },
  })

  const onSubmit = useCallback(
    (input: MerchantPaymentRefundInput) => {
      withMfa({
        onComplete: (code) => {
          void initiateRefund({
            variables: {
              input,
            },
            context: context(code),
          })
          track(SegmentEvents.RefundMerchantPaymentClicked)
        },
      })
    },
    [context, initiateRefund, track, withMfa],
  )

  return (
    <BaseTitle title={t`payment.refund.title`}>
      <PropsErrorBoundary<RefundQueryProps> props={props} variant="page">
        {({ paymentId }) => (
          <RefundForm
            onCancel={close}
            onSubmit={onSubmit}
            paymentId={paymentId}
          />
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

Refund.displayName = 'Payment.Refund'

import { SettingsNav, Footer } from '@containers/layout'
import { Main } from '@shared/components/layout'

import { BaseLayout } from '../core'

import type { AppLayoutType } from '../core'

/**
 * Account Layout.
 */
export const AccountLayout: AppLayoutType = ({ children }) => (
  <BaseLayout className="grid min-h-screen w-full">
    <SettingsNav />
    <Main className="ml-64 flex flex-col bg-surface-background">
      <div className="grid flex-1 content-start gap-y-12 py-12">{children}</div>
      <Footer />
    </Main>
  </BaseLayout>
)

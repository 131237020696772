import { useCallback } from 'react'

import { signIn } from '@circlefin/auth'
import {
  Button,
  Heading,
  Icon,
  Statement,
  TextLink,
} from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { useReCaptcha } from '@circlefin/recaptcha/react'
import { useHubspot } from '@services/hubspot/react'
import { signIn as signInSection } from '@services/sections/lib/home'
import { routes } from '@services/sections/modal/routes'
import { SegmentEvents, useSegment } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import { useCreateAccountMutation } from '@shared/graphql'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { CreateAccountForm } from '../../CreateAccountForm'

import type { CreateAccountFormValues } from '../../CreateAccountForm'
// eslint-disable-next-line import/extensions
import type { SvgCommonIcons } from '@circlefin/components/lib/Icon/Icon.Icons'

const icons: (keyof typeof SvgCommonIcons)[] = [
  'USDCOutline',
  'LockClosedOutline',
  'GlobeOutline',
]

export const CreateAccount: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const pageRouter = useRouter()
  const { router: modalRouter } = useModal()
  const { withReCaptcha, createContext } = useReCaptcha({
    action: 'create_account',
  })
  const { submitBusinessAccountSignupForm } = useHubspot()
  const { track } = useSegment()
  const [createAccount, { error, reset }] = useCreateAccountMutation()

  const handleOpenOtherInterestModal = useCallback(() => {
    track(SegmentEvents.OtherInterestClicked)

    modalRouter.push({
      pathname: routes.onboarding.getStarted.otherInterest,
      query: {
        title: t`createAccount.businessServices.otherInterest`,
      },
    })
  }, [modalRouter, t, track])

  const handleSubmit = useCallback(
    async (values: CreateAccountFormValues) => {
      // consents are for the HubSpot tracking only
      const { privacyConsent, marketingConsent, ...mutationValues } = values

      try {
        await withReCaptcha({
          onCompleted: async (token) => {
            const { data } = await createAccount({
              variables: {
                input: mutationValues,
              },
              context: createContext(token),
            })

            // Track event
            const { password, ...trackingValues } = values

            submitBusinessAccountSignupForm(trackingValues)

            track(SegmentEvents.FormSubmitted, {
              props: {
                values: trackingValues,
              },
            })

            // Unresolved keeps the submit button in loading state while we are
            // redirecting the user to the next page to avoid form resubmission.
            await new Promise(() => {
              if (data?.createAccount) {
                // If we have a sessionToken, we can can auto-login
                void signIn(
                  { legacy: true },
                  {
                    sessionToken: data.createAccount.sessionToken,
                  },
                )
              } else {
                // If no sessionToken, fallback to login screen
                void pageRouter.push(signInSection.route)
              }
            })
          },
        })
      } catch (e) {
        // Error UI handled in GraphQLErrorBoundary
      }
    },
    [
      createAccount,
      createContext,
      submitBusinessAccountSignupForm,
      track,
      withReCaptcha,
      pageRouter,
    ],
  )

  return (
    <div className="flex flex-col-reverse gap-12 md:flex-row">
      <div className="mt-6 sm:w-128 md:w-64 lg:w-88">
        <div className="space-y-4">
          <Heading variant="title/lg">
            <Heading.Header as="h4">{t`createAccount.businessServices.title`}</Heading.Header>
            <Heading.Intro className="type-body-sm">{t`createAccount.businessServices.subtitle`}</Heading.Intro>
          </Heading>

          <p className="text-neutral-subtle type-body-base">
            {t`createAccount.businessServices.description`}
          </p>
        </div>

        <div className="mt-6">
          <p className="text-neutral-subtle">{t`createAccount.businessServices.useCases.description`}</p>

          <div className="mt-5 flex flex-col gap-y-7">
            {icons.map((icon, index) => (
              <div key={index} className="flex flex-row items-center gap-x-4">
                <Icon.Circle
                  className="shrink-0 bg-primary text-icon-primary"
                  name={icon}
                />

                <span className="text-neutral-subtle">
                  {t(`createAccount.businessServices.useCases.items.${index}`)}
                </span>
              </div>
            ))}
          </div>
        </div>
        <TextLink
          className="mt-10"
          onClick={handleOpenOtherInterestModal}
          size="sm"
          variant="primary"
        >
          {t`createAccount.businessServices.otherInterest`}
        </TextLink>
      </div>
      <div className="rounded-lg bg-white px-8 py-6 shadow-md md:w-104 lg:w-128">
        <h3 className="mb-7.5 type-h-section-md">
          {t`onboard/common:createAccount.form.title`}
        </h3>
        <div aria-live="polite">
          {error && (
            <Statement
              status="warning"
              subtitle={
                <TransWithLink
                  i18nKey="onboard/common:createAccount.form.error"
                  variant="support"
                  noWrap
                />
              }
            >
              <Button onClick={reset} variant="secondary">
                {t`common:retry`}
              </Button>
            </Statement>
          )}
          <div className={classNames({ hidden: Boolean(error) })}>
            <CreateAccountForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  )
}

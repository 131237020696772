import { ModalRoute } from '@circlefin/modal-router'
import {
  OtherInterest,
  PeriodicReview,
  PinCode,
} from '@features/onboarding/modals'
import { onboarding } from '@services/sections/modal/onboarding/onboarding'

/**
 * Onboarding Router.
 */
export const OnboardingRouter: React.FC = () => {
  return (
    <>
      {/* Get Started */}
      <ModalRoute
        content={<OtherInterest />}
        path={onboarding.getStarted.otherInterest}
      />

      {/* Periodic Review */}
      <ModalRoute
        content={<PeriodicReview.Takeover />}
        path={onboarding.periodicReview.takeover}
      />

      {/* PIN Code Enrollment */}
      <ModalRoute
        content={<PinCode.Takeover />}
        path={onboarding.pinCode.takeover}
      />
    </>
  )
}

import { useCallback, useMemo } from 'react'

import { Button, Icon, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FullScreen } from '@modals/layout'
import { CIRCLE_WALLET_APPROVAL } from '@services/permissions'
import { Center } from '@shared/components/layout'
import {
  useActiveWalletApprovalPolicyQuery,
  Currency,
  WalletApprovalProposalApprovalMethod,
} from '@shared/graphql'
import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import ChangePin from '../../images/ChangePin.png'

import type { LinkProps } from 'next/link'

export interface ApprovalRequiredQueryProps {
  /**
   * Is the transfer to a vault wallet.
   */
  isToVaultWalletTransfer?: boolean
  /**
   * Action link href.
   */
  actionLink?: LinkProps['href']
  /**
   * Total step count for progress bar.
   */
  totalSteps?: number
  /**
   * Currency for this approval required instruction.
   */
  currency?: Currency
}

export const ApprovalRequired: React.FC<ApprovalRequiredQueryProps> = ({
  totalSteps,
  isToVaultWalletTransfer,
  actionLink,
  currency,
}) => {
  const modal = useModal()
  const { t } = useTranslation('modals.transfer')
  const [, { isAuthorized: isWebAppApprovalMethodEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )
  const {
    data: { activeWalletApprovalPolicy } = {},
    loading,
    error,
  } = useActiveWalletApprovalPolicyQuery({
    variables: {
      currency: currency ?? Currency.USD,
    },
    skip: currency == null || !isWebAppApprovalMethodEnabled,
  })

  const handleModalClose = useCallback(() => {
    modal.close()
  }, [modal])

  const approvalRequiredDescription = useMemo(() => {
    if (isToVaultWalletTransfer) {
      return t`approvalRequired.description.toVaultWallet`
    }

    if (isWebAppApprovalMethodEnabled) {
      const proposalApprovalMethod =
        activeWalletApprovalPolicy?.settings.proposalApprovalMethod

      if (error || proposalApprovalMethod == null) {
        return t`approvalRequired.description.default.fallback`
      }

      return t(`approvalRequired.description.default.${proposalApprovalMethod}`)
    }

    return t`approvalRequired.description.default.MOBILE_APP`
  }, [
    activeWalletApprovalPolicy?.settings.proposalApprovalMethod,
    error,
    isWebAppApprovalMethodEnabled,
    isToVaultWalletTransfer,
    t,
  ])

  return (
    <FullScreen totalSteps={totalSteps}>
      <Center className="font-circular" variant="horizontal">
        <Center>
          <Icon.Circle
            className="bg-success text-icon-success"
            name="CheckCircleSolid"
          />
        </Center>
        <Center>
          <h3 className="my-6 w-152 text-center type-h-page-sm">{t`approvalRequired.title`}</h3>
        </Center>
        <Center>
          <SkeletonBox className="mb-4 h-20 w-120" loading={loading}>
            <p className="mb-4 w-152 text-center text-neutral-subtle type-intro-sm">
              {approvalRequiredDescription}
            </p>
          </SkeletonBox>
        </Center>
        {activeWalletApprovalPolicy?.settings.proposalApprovalMethod ===
          WalletApprovalProposalApprovalMethod.MOBILE_APP && (
          <Center>
            <Image alt="chain-pin" className="size-44" src={ChangePin} />
          </Center>
        )}
        <Center variant="vertical">
          <div className="mt-4 flex space-x-6">
            <Button
              className="w-full border-none bg-accent-purple-gradient"
              onClick={handleModalClose}
              variant="secondary"
            >
              {t`common:done`}
            </Button>
            {actionLink != null && (
              <Link href={actionLink} legacyBehavior passHref>
                <Button.Link
                  className="w-full whitespace-nowrap"
                  variant="primary"
                >
                  {t`approvalRequired.action`}
                </Button.Link>
              </Link>
            )}
          </div>
        </Center>
      </Center>
    </FullScreen>
  )
}

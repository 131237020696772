import { useCallback, useState } from 'react'

import { TextLink } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useHubspot } from '@services/hubspot/react'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  AuthorizedRepOccupation,
  useInviteAuthorizedRepMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../../hooks/account-setup'
import { useAuthorizedRepOccupation } from '../../../../hooks/authorized-rep-occupation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const schema = y.object({
  invitedFirstName: y.string().required(),
  invitedLastName: y.string().required(),
  invitedEmail: y.string().required().email(),
  invitedOccupation: y.string().required(),
  otherOccupation: y.string().when('invitedOccupation', {
    is: AuthorizedRepOccupation.other,
    then: (schema) => schema.required(),
  }),
})

export type FormValues = y.InferType<typeof schema>

export interface InviteAuthRepProps {
  /**
   * Return to the Role in Organization.
   */
  onBack: () => void
}

export const InviteAuthRep: React.FC<InviteAuthRepProps> = ({ onBack }) => {
  const { t } = useTranslation('onboard/account-setup')
  const { data } = useAuthorizedRepOccupation()
  const [showOtherOccupation, setShowOtherOccupation] = useState(false)
  const { submitAuthorizedRepForm } = useHubspot()

  const [Form] = useForm<FormValues>({
    schema,
    mode: 'onTouched',
  })

  const [{ businessDetails }, { completeStep }] = useAccountSetup()

  const [inviteAuthorizedRep, { error, reset, loading }] =
    useInviteAuthorizedRepMutation()

  const handleOccupationChange = useCallback(
    (occupation: SelectListItem<AuthorizedRepOccupation>) => {
      setShowOtherOccupation(occupation.value === AuthorizedRepOccupation.other)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const { invitedOccupation, otherOccupation, ...rest } = values

      try {
        await inviteAuthorizedRep({
          variables: {
            input: {
              ...rest,
              invitedOccupation:
                values.invitedOccupation !==
                String(AuthorizedRepOccupation.other)
                  ? invitedOccupation
                  : otherOccupation ?? '',
            },
          },
        })

        // Fire event to mark invited user as Authorized Rep
        submitAuthorizedRepForm({
          email: values.invitedEmail,
          firstName: values.invitedFirstName,
          lastName: values.invitedLastName,
          businessName: businessDetails?.businessName ?? '',
          businessCountry: businessDetails?.businessCountry ?? '',
          businessState: businessDetails?.businessState ?? '',
          entityType: businessDetails?.entityType ?? '',
          businessNature: businessDetails?.businessNature ?? '',
          mintingVolume: businessDetails?.mintingVolume ?? '',
          activeUsers: businessDetails?.activeUsers ?? '',
        })

        await completeStep('INVITE_AUTH_REP')
      } catch {
        // Error handled in GraphQLErrorBoundary
      }
    },
    [
      completeStep,
      businessDetails,
      inviteAuthorizedRep,
      submitAuthorizedRepForm,
    ],
  )

  return (
    <>
      <h2 className="type-h-page-sm">{t`confirmRole.invite.title`}</h2>

      <p className="mt-1 text-neutral-subtle type-intro-sm">
        {t`confirmRole.invite.description`}
      </p>

      <GraphQLErrorBoundary error={error} retry={reset}>
        <Form className="mt-8 flex flex-col gap-y-6" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-4 lg:flex-row">
            <Form.Input
              className="w-full"
              label={t`confirmRole.invite.form.invitedFirstName`}
              name="invitedFirstName"
              placeholder={t`confirmRole.invite.form.invitedFirstName`}
            />
            <Form.Input
              className="w-full"
              label={t`confirmRole.invite.form.invitedLastName`}
              name="invitedLastName"
              placeholder={t`confirmRole.invite.form.invitedLastName`}
            />
          </div>

          <Form.Input
            className="w-full"
            label={t`confirmRole.invite.form.invitedEmail`}
            name="invitedEmail"
            placeholder={t`confirmRole.invite.form.invitedEmail`}
          />

          <Form.Dropdown
            className="w-full"
            items={data}
            label={t`confirmRole.invite.form.invitedOccupation`}
            maxMenuItems={3}
            name="invitedOccupation"
            onChange={handleOccupationChange}
            placeholder={t`confirmRole.invite.form.invitedOccupation`}
          />
          {showOtherOccupation && (
            <Form.Input
              className="-mt-4 w-full"
              data-testid="other-occupation-input"
              name="otherOccupation"
              placeholder={t`confirmRole.invite.form.invitedOccupation`}
            />
          )}

          <Form.SubmitButton
            className="w-full"
            loading={loading}
            variant="primary"
          >{t`confirmRole.invite.form.submit`}</Form.SubmitButton>
        </Form>
        <TextLink
          className="mx-auto mt-6 block"
          onClick={onBack}
          size="sm"
          variant="primary"
        >
          {t`confirmRole.invite.backToRole`}
        </TextLink>
      </GraphQLErrorBoundary>
    </>
  )
}

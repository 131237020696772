import { useCallback } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { WireTransferLimit } from '@features/bank-account/containers'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { Center } from '@shared/components/layout'
import {
  WireTransferTypeEnum,
  useBalancesMainQuery,
  useWireTransferLimitQuery,
  Currency,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useWithdraw } from '../../../hooks/withdraw'

import type { MoneyInputValues } from '@circlefin/components/lib/MoneyInput'

const amountSchema = y.number().allowEmpty().positive().required()

const schema = y.object({
  /**
   * Transfer amount.
   */
  amount: amountSchema,
})

export type SelectAmountFormValues = y.InferType<typeof schema>

interface SelectAmountFormProps {
  /**
   * Handles form submit.
   */
  onSubmit: () => void
}

export const SelectAmountForm: React.FC<SelectAmountFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('modals.payout')
  const { money } = useMoney()
  const [{ currency }] = useCurrentCurrency()

  const { data, loading } = useBalancesMainQuery({
    // Always render a modal step with fresh balance
    fetchPolicy: 'cache-and-network',
    variables: {
      currency,
    },
  })

  const { data: withdrawLimit } = useWireTransferLimitQuery({
    variables: {
      type: WireTransferTypeEnum.withdrawal,
      currency: currency ?? Currency.USDC,
    },
  })

  const [{ amount }, { onFormChange }] = useWithdraw()

  /**
   * If we have cached data - ignore loading.
   */
  const balanceLoading = !data && loading

  const balance = data?.balances.main?.balance

  const availableBalance = Number(data?.balances.main?.balance.amount ?? 0)
  const availableLimit = Number(
    withdrawLimit?.wireTransferLimit?.available.amount ?? 0,
  )

  const [Form] = useForm<SelectAmountFormValues>({
    schema: schema.shape({
      amount: amountSchema.multipleMax({
        limits: [
          {
            value: availableBalance,
            error: { key: 'payout.exceeds.accountBalance' },
          },
          {
            value: availableLimit,
            error: { key: 'payout.exceeds.withdrawalLimit' },
          },
        ],
        allLimitsError: { key: 'payout.exceeds.both' },
      }),
    }),
    defaultValues: {
      amount: amount ? Number(amount) : undefined,
    },
  })

  const handleAmountChange = useCallback(
    ({ value }: MoneyInputValues) => {
      if (value) {
        onFormChange({
          amount: value,
          bankAccount: undefined,
        })
      }
    },
    [onFormChange],
  )

  return (
    <Form className="flex flex-col items-center" onSubmit={onSubmit}>
      <div className="w-176 border-y-2 border-black-50 py-5">
        <WireTransferLimit
          currency={currency}
          type={WireTransferTypeEnum.withdrawal}
        />
      </div>

      <div className="my-4 w-96">
        <Form.MoneyInput
          className="mt-4 w-full"
          currencyVariant={currency}
          label={t<string>('withdraw.selectAmount.form.amountField.label')}
          name="amount"
          onChange={handleAmountChange}
          placeholder="0.00"
          padded
        />

        <SkeletonBox className="h-4 w-36" loading={balanceLoading}>
          {balance != null && (
            <div
              className="mt-1 text-sm leading-5 text-black-500 font-circular-regular"
              data-testid="available-funds"
            >
              {t('withdraw.selectAmount.form.amountField.balanceLimit', {
                balance: money({
                  number: balance.amount,
                  variant: balance.currency,
                }),
              })}
            </div>
          )}
        </SkeletonBox>
      </div>

      <Center className="pt-10" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          variant="primary"
        >
          {t('common:continue')}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

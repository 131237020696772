import { usePermission } from '@circlefin/permissions'
import { FullScreen } from '@modals/layout'
import {
  STABLECOIN_EXPRESS_MINT,
  STABLECOIN_EXPRESS_BURN,
} from '@services/permissions'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { DepositCard, WithdrawalCard } from './Cards'

export const Direction: React.FC = () => {
  const { t } = useTranslation('express/modal')

  const [{ Authorized: MintAuthorized }] = usePermission(
    STABLECOIN_EXPRESS_MINT,
  )
  const [{ Authorized: BurnAuthorized }] = usePermission(
    STABLECOIN_EXPRESS_BURN,
  )

  return (
    <FullScreen totalSteps={5}>
      <Center
        className="mb-8 text-center"
        data-testid="direction"
        variant="horizontal"
      >
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`addRoute.direction.title`}
        </h2>

        <div className="mt-10 grid grid-flow-row gap-11">
          <MintAuthorized>
            <DepositCard />
          </MintAuthorized>
          <BurnAuthorized>
            <WithdrawalCard />
          </BurnAuthorized>
        </div>
      </Center>
    </FullScreen>
  )
}

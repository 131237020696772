import { useEffect } from 'react'

import {
  FixedBanner,
  SkeletonBox,
  DescriptionList,
} from '@circlefin/components'
import { useDebounceValue, useCustomMoney } from '@circlefin/hooks'
import { LoadingSpinner } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  VaultTransferSpeed,
  useVaultTransactionFeeQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../../../SendOnChain.Context'

import type { SendOnChainState } from '../../../SendOnChain.Context'

const { Label, Description } = DescriptionList

type FeeTableProps = Pick<SendOnChainState, 'amount'>

export const FeeTable: React.FC<FeeTableProps> = ({ amount }) => {
  const { t } = useTranslation('modals.vault')
  const { customMoney } = useCustomMoney()
  const [{ assetWallet }, { setSendOnChainState }, { derivedDestination }] =
    useSendOnChain()
  const debouncedAmount = useDebounceValue<FeeTableProps['amount']>({
    value: amount,
    delay: 2000,
  })

  // TODO: Add back speed preference support when backend is ready [https://circlepay.atlassian.net/browse/BIZ-1397].
  // const [speedPreference, setSpeedPreference] = useState<VaultTransferSpeed>(
  //   // Defaulted to `fast` even if blockchain doesn't support multiple options.
  //   VaultTransferSpeed.fast
  // )
  const { data, loading, previousData, error } = useVaultTransactionFeeQuery({
    variables: {
      input: {
        walletId: assetWallet?.id ?? '',
        vaultId: assetWallet?.vaultMetadata.id ?? '',
        toAddressRefId: derivedDestination?.id ?? '',
        amount: debouncedAmount ?? 0,
        preference: VaultTransferSpeed.fast, // TODO: Return to using state value [https://circlepay.atlassian.net/browse/BIZ-1397].
      },
    },
    skip: !assetWallet || !derivedDestination || debouncedAmount === undefined,
    onCompleted: (data) => {
      // On complete, update context value (next-tick to prevent Formik errors)
      setTimeout(() => {
        setSendOnChainState({
          transactionFees: data.vaultTransactionFee,
        })
      }, 0)
    },
    // Always want up-to-date fees
    fetchPolicy: 'network-only',
  })

  /**
   * Handle speed preference change.
   */
  // TODO: Add back speed preference support when backend is ready [https://circlepay.atlassian.net/browse/BIZ-1397].
  // const handleSpeedPreference = useCallback(
  //   (speed: VaultTransferSpeed) => () => {
  //     setSpeedPreference(speed)
  //   },
  //   []
  // )

  // For debouncing or data fetch, UI should render as loading
  const loadingOrDebouncing = loading || amount !== debouncedAmount

  // Trigger reset of fee value if loading or debouncing
  useEffect(() => {
    if (loadingOrDebouncing) {
      setSendOnChainState({ transactionFees: undefined })
    }
  }, [loadingOrDebouncing, setSendOnChainState])

  // Fallback to previousData to keep DOM elements in place while loading
  // As long as the currency remains the same, the backend endpoint should
  // provide the same object shape
  const { maximumFee } =
    data?.vaultTransactionFee ?? previousData?.vaultTransactionFee ?? {}

  // If amount is empty (or deleted), don't render anything
  if (amount === undefined) {
    return null
  }

  // While the amount is debouncing or for the first fetch data, render loading spinner
  if (loadingOrDebouncing && !data && !previousData) {
    return (
      <Center className="pb-1 pt-10">
        <LoadingSpinner height={75} width={75} />
      </Center>
    )
  }

  if (data?.vaultTransactionFee.availableSpeeds?.length === 0) {
    return (
      <FixedBanner
        className="mt-8"
        data-testid="fee-unavailable"
        status="info"
        visible
      >
        <FixedBanner.Description>
          {t('transfer.sendOnChain.transferAmount.feeTable.feeUnavailable', {
            currencyCode: assetWallet?.blockchain,
          })}
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

  // If data or previous data is available, render table
  if (Boolean(data) || Boolean(previousData) || Boolean(error)) {
    return (
      <div
        className="mt-8 rounded-md border border-black-75 bg-white"
        data-testid="fee-table"
      >
        <GraphQLErrorBoundary error={error}>
          {/* TODO: Add back speed preference support when backend is ready [https://circlepay.atlassian.net/browse/BIZ-1397]. */}
          {/* {availableSpeeds && availableSpeeds.length > 0 && (
          <TabSwitch selected={speedPreference}>
            <TabSwitch.Tabs variant="underlined">
              {availableSpeeds.map((speed) => (
                <TabSwitch.Tab id={speed} key={speed}>
                  <span className="h-4">
                    <Button
                      variant="text"
                      className="absolute inset-0 p-0 text-blue-500"
                      onClick={handleSpeedPreference(speed)}
                    >
                      {t(
                        `transfer.sendOnChain.transferAmount.feeTable.tabs.${speed}`
                      )}
                    </Button>
                  </span>
                </TabSwitch.Tab>
              ))}
            </TabSwitch.Tabs>
          </TabSwitch>
        )} */}
          <DescriptionList className="grid-cols-2 items-start py-2">
            {maximumFee && (
              <>
                <Label className="pl-6 text-sm">{t`transfer.sendOnChain.transferAmount.feeTable.maximum.fee`}</Label>
                <Description className="flex flex-col items-end pr-6">
                  <span className="flex justify-end">
                    <SkeletonBox
                      className="mb-1 h-4 w-28"
                      loading={loadingOrDebouncing}
                    >
                      {customMoney({
                        number: maximumFee.native.amount,
                        currencyConfig: {
                          name: maximumFee.native.asset.symbol,
                          decimals: maximumFee.native.asset.decimals,
                          isCryptoCurrency: true,
                        },
                        options: { padded: false },
                      })}
                    </SkeletonBox>
                  </span>
                  <span className="flex justify-end text-sm text-black-400">
                    <SkeletonBox
                      className="mt-1 h-4 w-24"
                      loading={loadingOrDebouncing}
                    >
                      {customMoney({
                        number: maximumFee.fiat.amount,
                        currencyConfig: {
                          name: maximumFee.fiat.asset.symbol,
                          decimals: maximumFee.fiat.asset.decimals,
                          isCryptoCurrency: true,
                        },
                        options: { padded: false },
                      })}
                    </SkeletonBox>
                  </span>
                </Description>
              </>
            )}
          </DescriptionList>
        </GraphQLErrorBoundary>
      </div>
    )
  }

  // By default, render nothing
  return null
}

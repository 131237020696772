import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'

export const RolrLanding: React.FC = () => {
  const { t } = useTranslation('onboard/rolr-signup')

  const [, { computeNextStep }] = useRolrSetup()

  const complete = useCallback(() => {
    computeNextStep({
      step: 'landing',
      values: {
        letsGoClicked: true,
      },
    })
  }, [computeNextStep])

  return (
    <div className="h-full w-128">
      <h1 className="type-h-page-sm">{t`landing.title`}</h1>
      <p className="mt-1 text-neutral-subtle type-intro-sm">{t`landing.desc`}</p>
      <div
        className="mt-8 flex w-120 flex-col gap-1"
        data-testid="landing-terms"
      >
        {t<string[]>(`landing.items`, {}, { returnObjects: true }).map(
          (term, index) => (
            <p key={term} className="text-neutral-subtle type-body-sm">{`${
              // list starts with 1
              index + 1
            }. ${term}`}</p>
          ),
        )}
      </div>
      <Button className="mt-6 w-full" onClick={complete} variant="primary">
        {t`landing.complete`}
      </Button>
    </div>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal'
import { Center, Justify } from '@shared/components/layout'
import { Currency, WireTransferTypeEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { LimitCard } from '../../../components/LimitCard/LimitCard'
import { ExchangeRateCard } from '../ExchangeRateCard/ExchangeRateCard'
import { FxTransferForm } from '../Form/Fx.Form'

export interface FxDepositProps {
  /**
   * Specify which token is selected by the user to convert too.
   */
  currency?: Currency
}

export const FxDeposit: React.FC<FxDepositProps> = ({ currency }) => {
  const { t } = useTranslation('modals.transfer')
  const { router } = useModal()

  const handleFormSubmit = useCallback(() => {
    router.push(routes.transfer.fx.deposit.instructions)
  }, [router])

  return (
    <FullScreen totalSteps={3}>
      <Center>
        <h2 className="mb-2 text-center text-3xl font-circular-bold">
          {t`fx.depositFromBank`}
        </h2>
        <h3 className="text-sm text-neutral font-circular-regular">
          {t`fx.initiateDeposit`}
        </h3>
      </Center>

      <Justify>
        <Justify.Content variant="center">
          <FxTransferForm
            fixedCurrency={currency ?? Currency.USDC}
            mode="deposit"
            onSubmit={handleFormSubmit}
          />
        </Justify.Content>
        <Justify.Content className="mt-6 flex flex-col gap-6" variant="right">
          <div>
            <ExchangeRateCard />
          </div>
          <div className="h-2/6">
            <LimitCard
              currency={currency ?? Currency.USD}
              mode={WireTransferTypeEnum.deposit}
            />
          </div>
        </Justify.Content>
      </Justify>

      <Center className="text-center" variant="horizontal">
        <p className="inset-x-0 mx-auto w-248 text-sm font-circular-regular">
          <Trans
            components={{
              bold: <strong />,
            }}
            i18nKey="modals.transfer:fx.disclaimer"
          />
        </p>
      </Center>
    </FullScreen>
  )
}

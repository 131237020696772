import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { useBlockchainAssetsQuery } from '@shared/graphql'
import classNames from 'classnames'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { BlockchainAbbreviation } from '@shared/graphql'

export const assetSchema = y.string()

export interface AssetsProps {
  /**
   * Selected blockchain.
   */
  chain?: BlockchainAbbreviation
  /**
   * Label.
   */
  label: string
  /**
   * Disabled.
   */
  disabled?: boolean
  /**
   * Custom style.
   */
  className?: string
}

const Dropdown = createFormDropdown()

export const Assets: React.FC<AssetsProps> = ({
  chain,
  label,
  disabled,
  className,
  ...props
}) => {
  const { loading, data } = useBlockchainAssetsQuery({
    variables: {
      chain,
    },
  })

  const items: Array<SelectListItem<string>> = useMemo(
    () =>
      (data?.blockchainAssets ?? [])
        .filter((asset) => asset.txcoreCurrency != null)
        .map((asset) => ({
          label: asset.symbol,
          value: asset.txcoreCurrency ?? undefined,
          icon: asset.icon,
        })),
    [data?.blockchainAssets],
  )

  return (
    <SkeletonBox
      className={classNames('w-full h-10 mt-6', className)}
      loading={loading}
    >
      <Dropdown
        className={className}
        disabled={disabled}
        items={items}
        label={label}
        name="asset"
        {...props}
      />
    </SkeletonBox>
  )
}

/**
 * Account: Security Routes.
 */
export const security = {
  adminApprovals: {
    settings: '/security/admin-approvals/settings',
    success: '/security/admin-approvals/success',
  },
  ipAllowlisting: {
    switch: '/security/ip-allowlisting/',
    toggleSetting: '/security/ip-allowlisting/toggle-setting',
    ipAddress: {
      create: '/security/ip-allowlisting/ip-address/create',
      remove: '/security/ip-allowlisting/ip-address/remove',
      empty: '/security/ip-allowlisting/ip-address/empty',
    },
  },
  fraudManagement: {
    addToWatchList: {
      form: '/security/fraud-management/add-to-list',
      success: '/security/fraud-management/add-to-list/success',
    },
  },
}

import { useCallback } from 'react'

import { Button, Icon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount } from '@shared/graphql'

interface PleaseTransferFundsProps {
  /**
   * The token currency that will be added to the users account upon completion of this deposit.
   */
  receivingToken?: Currency
  /**
   * The bank account that was used to generate the prior screens deposit instructions.
   */
  bankAccount?: BankAccount
}

export const PleaseTransferFunds: React.FC<PleaseTransferFundsProps> = ({
  receivingToken,
  bankAccount,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { track } = useSegment()
  const { close } = useModal()
  const [, { tokenToCurrency }] = useCurrentCurrency()
  const receivingFiatEquivalent = tokenToCurrency(
    receivingToken ?? Currency.USDC,
  )

  const handleDoneClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.DoneClicked, { event: e })
      close()
    },
    [close, track],
  )

  const isVirtualAccount =
    bankAccount &&
    'virtualAccountEnabled' in bankAccount &&
    bankAccount.virtualAccountEnabled

  return (
    <FullScreen>
      <Center variant="horizontal">
        <div className="mb-8 text-center">
          <h2 className="mb-3 text-2xl font-circular-bold">
            {t`wire.pleaseTransferFunds.header`}
          </h2>
        </div>

        <div className="flex justify-between space-x-12 text-lg">
          <div className="flex w-75 flex-col items-center space-y-3 text-center">
            <div className="flex size-10 items-center justify-center rounded-full bg-blue-50 text-blue-500">
              <Icon name="SwitchHorizontalOutline" size={18} />
            </div>

            <h3 className="font-circular-bold">{t`wire.pleaseTransferFunds.initiateTransfer.header`}</h3>
            <p>{t`wire.pleaseTransferFunds.initiateTransfer.description`}</p>
          </div>

          <div className="flex w-75 flex-col items-center space-y-3 text-center">
            <div className="flex size-10 items-center justify-center rounded-full bg-green-50 text-green-400">
              {/* TODO: Remove EURC stable release permission hook after launch. */}
              <Icon
                name={
                  receivingFiatEquivalent === Currency.USD
                    ? 'CurrencyDollarOutline'
                    : 'CurrencyEuroOutline'
                }
                size={18}
              />
            </div>

            <h3 className="font-circular-bold">
              {t('wire.pleaseTransferFunds.receive.header', {
                currency: receivingToken,
              })}
            </h3>
            <p>
              {t('wire.pleaseTransferFunds.receive.description', {
                currency: receivingToken,
              })}
            </p>
          </div>
        </div>

        {!isVirtualAccount && (
          <Center>
            <div
              className="mt-12 flex w-144 flex-col items-center justify-between space-y-3 text-center text-base"
              data-testid="wire-disclosure"
            >
              <div className="flex items-center justify-center space-x-3">
                <div className="flex size-10 items-center justify-center rounded-full bg-orange-50 text-orange-400">
                  <Icon name="ExclamationOutline" size={18} />
                </div>

                <h3 className="font-circular-bold">{t`wire.pleaseTransferFunds.disclosure.header`}</h3>
              </div>

              <p>{t`wire.pleaseTransferFunds.disclosure.description`}</p>
            </div>
          </Center>
        )}

        <Center>
          <Button
            className="mt-12 w-64"
            onClick={handleDoneClick}
            variant="primary"
          >
            {t('common:done')}
          </Button>
        </Center>

        <div className="mt-20 flex w-168 flex-col space-y-4 text-base leading-6 text-black-400 font-circular-regular">
          <p>{t`wire.pleaseTransferFunds.feesDisclosure`}</p>
          <p>
            <TransWithLink
              i18nKey="modals.transfer:wire.pleaseTransferFunds.moreInfo"
              urlI18nKey="modals.transfer:wire.pleaseTransferFunds.helpLink"
              variant="url"
            />
          </p>
        </div>
      </Center>
    </FullScreen>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { useModal } from '@circlefin/modal-router'

import { defaultValues, CreateVaultContext } from './context'

import type {
  CreateVaultState,
  CreateVaultTouched,
  CreateVaultActions,
} from './context'
import type { VaultPolicyLimits } from '@shared/graphql'

interface CreateVaultProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<CreateVaultState>
}

export const CreateVaultProvider: React.FC<CreateVaultProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<CreateVaultState>({
    ...defaultValues,
    ...initValues,
  })

  const mergeValues = useCallback((values: Partial<CreateVaultState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
      touched: {
        ...curr.touched,
        ...values.touched,
      },
    }))
  }, [])

  const mergeTouched = useCallback(
    (touched: CreateVaultTouched) => {
      mergeValues({ touched })
    },
    [mergeValues],
  )

  const resetUserLimitsBasedOnPolicyLimits = useCallback(
    (policyLimits: VaultPolicyLimits) => {
      setValues((curr) => {
        // if the user's global policy limits has changed after the daily limits have been configured,
        // there could be a case where the newly configured policy limits are less than the previously configured user daily limits.
        // if this is the case we should reset any daily users that go over the policy limits.

        const updatedUserPolicy = curr.usersPolicy?.map((userPolicy) => {
          return {
            ...userPolicy,
            maxAmount:
              Number(userPolicy.maxAmount ?? 0) >
              Number(policyLimits.maxAmount ?? 0)
                ? undefined
                : userPolicy.maxAmount,
            maxTransactions:
              Number(userPolicy.maxTransactions ?? 0) >
              Number(policyLimits.maxTransactions ?? 0)
                ? undefined
                : userPolicy.maxTransactions,
          }
        })

        return {
          ...curr,
          policyLimits,
          usersPolicy: updatedUserPolicy,
        }
      })
    },
    [],
  )

  const actions: CreateVaultActions = useMemo(
    () => ({
      setName: (name) => {
        mergeValues({ name })
        mergeTouched({ name: true })
      },
      setUsersPolicy: (usersPolicy) => {
        mergeValues({ usersPolicy })
        mergeTouched({ usersPolicy: true })
      },
      setPolicyLimits: (policyLimits) => {
        mergeValues({ policyLimits })
        mergeTouched({ policyLimits: true })
      },
      setOutgoingAddresses: (outgoingAddresses) => {
        setValues((curr) => ({
          ...curr,
          outgoingAddresses: {
            external: [],
            internal: [],
            ...curr.outgoingAddresses,
            ...outgoingAddresses,
          },
        }))

        mergeTouched({ outgoingAddresses: true })
      },
      setApprovalLevelIndexInEdit: (approvalLevelIndexInEdit) => {
        mergeValues({ approvalLevelIndexInEdit })
      },
      setApprovalWorkflow: (approvalWorkflow) => {
        mergeValues({ approvalWorkflow })
        mergeTouched({ approvalWorkflow: true })
      },
      setWalletConnectPolicy: (walletConnectPolicy) => {
        mergeValues({ walletConnectPolicy })
        mergeTouched({ walletConnectPolicy: true })
      },
      setWalletConnectPolicyEnabled: (isWalletConnectPolicyEnabled) =>
        mergeValues({ isWalletConnectPolicyEnabled }),
      setWalletConnectPolicyInEdit: (isWalletConnectPolicyInEdit) =>
        mergeValues({ isWalletConnectPolicyInEdit }),
      setWalletConnectStepVisible: (isWalletConnectStepVisible) =>
        mergeValues({ isWalletConnectStepVisible }),
      setFlowComplete: (isFlowComplete) => mergeValues({ isFlowComplete }),
      setTouched: mergeTouched,
      resetUserLimitsBasedOnPolicyLimits,
      setPolicyInEdit: (policy) => {
        mergeValues({
          id: policy.id,
          name: policy.name,
          policyLimits: policy.policyLimits ?? undefined,
          usersPolicy: policy.usersPolicy ?? undefined,
          approvalWorkflow: policy.approvalWorkflow ?? undefined,
          outgoingAddresses: policy.outgoingAddresses ?? undefined,
          walletConnectPolicy: policy.walletConnectPolicy ?? undefined,
          isWalletConnectPolicyEnabled:
            policy.walletConnectPolicy?.operators !== null,
          policyInEditState: policy.state,
          inEdit: true,
        })
      },
      setVaultId: (vaultId) => {
        mergeValues({
          vaultId,
        })
      },
    }),
    [resetUserLimitsBasedOnPolicyLimits, mergeTouched, mergeValues],
  )

  useEffect(() => {
    const resetContext = () => {
      setValues(defaultValues)
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <CreateVaultContext.Provider value={[values, actions]}>
      {children}
    </CreateVaultContext.Provider>
  )
}

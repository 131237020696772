import classNames from 'classnames'

import { Description } from './Description/Description'
import { Header } from './Header/Header'
import { Subtitle } from './Subtitle/Subtitle'

export interface DisclosuresProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Style?
   */
  className?: string
}

interface SubComponents {
  Header: typeof Header
  Subtitle: typeof Subtitle
  Description: typeof Description
}

export const Disclosures: React.FC<DisclosuresProps> & SubComponents = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      className,
      'text-black-600 leading-4 font-circular-regular',
    )}
    data-testid="disclosure"
  >
    {children}
  </div>
)

Disclosures.displayName = 'Disclosures'
Disclosures.Header = Header
Disclosures.Subtitle = Subtitle
Disclosures.Description = Description

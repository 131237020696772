import { createContext } from 'react'

import type { NonNullableProperties } from '@circlefin/types'
import type {
  VaultPolicy,
  VaultPolicyApprovalLevel,
  VaultPolicyLimits,
  VaultPolicyOutgoingAddressWhitelist,
  VaultPolicyState,
  VaultUserPolicy,
  VaultWalletConnectPolicy,
} from '@shared/graphql'

export interface CreateVaultState
  extends Omit<NonNullableProperties<VaultPolicy>, 'id' | 'name' | 'state'> {
  /**
   * Policy ID in edit.
   */
  id?: string
  /**
   * Policy's state in edit.
   */
  policyInEditState?: VaultPolicyState
  /**
   * Vault id of the vault where we will create/update this policy.
   */
  vaultId?: string
  /**
   * Policy name.
   */
  name?: string
  /**
   * Approval level currently in edit.
   */
  approvalLevelIndexInEdit?: number
  /**
   * Is Wallet Connect policy enabled.
   */
  isWalletConnectPolicyEnabled?: boolean
  /**
   * Is Wallet Connect policy being edited.
   */
  isWalletConnectPolicyInEdit?: boolean
  /**
   * Is Wallet Connect step visible.
   */
  isWalletConnectStepVisible?: boolean
  /**
   * Saved successfully?
   */
  isFlowComplete?: boolean
  /**
   * Touched state.
   */
  touched?: CreateVaultTouched
  /**
   * Is the vault being edited.
   */
  inEdit?: boolean
}

export type CreateVaultTouched = Partial<
  Record<
    Exclude<keyof CreateVaultState, 'touched' | 'isFlowComplete' | 'inEdit'>,
    boolean
  >
>

export interface CreateVaultActions {
  /**
   * Set the name of the vault policy.
   */
  setName: (name: string) => void
  /**
   * Set the users policy for the vault.
   */
  setUsersPolicy: (usersPolicy: VaultUserPolicy[]) => void
  /**
   * Set the global limits of the vault policy.
   */
  setPolicyLimits: (limits: VaultPolicyLimits) => void
  /**
   * Set the outgoing addresses of the vault policy.
   */
  setOutgoingAddresses: (
    addresses: Partial<VaultPolicyOutgoingAddressWhitelist>,
  ) => void
  /**
   * Set the approval workflow of the vault policy.
   */
  setApprovalWorkflow: (
    workflow: VaultPolicyApprovalLevel[] | undefined,
  ) => void
  /**
   * Set the approval level index in edit.
   */
  setApprovalLevelIndexInEdit: (index: number | undefined) => void
  /**
   * Set the wallet connect policy of the vault policy.
   */
  setWalletConnectPolicy: (
    walletConnectPolicy: VaultWalletConnectPolicy | undefined,
  ) => void
  /**
   * Set Wallet Connect Policy state.
   */
  setWalletConnectPolicyEnabled: (isWalletConnectPolicyEnabled: boolean) => void
  /**
   * Set Wallet Connect Policy in edit.
   */
  setWalletConnectPolicyInEdit: (isWalletConnectPolicyInEdit: boolean) => void
  /**
   * Set Wallet Connect step visible.
   */
  setWalletConnectStepVisible: (isWalletConnectStepVisible: boolean) => void
  /**
   * Reset user limits objects that have their values greater than the policy limit.
   */
  resetUserLimitsBasedOnPolicyLimits: (limits: VaultPolicyLimits) => void
  /**
   * Set Policy to be edited.
   */
  setPolicyInEdit: (policy: VaultPolicy) => void
  /**
   * Set Vault Id state.
   */
  setVaultId: (vaultId: string) => void
  /**
   * Set touched state.
   */
  setTouched: (touched: CreateVaultTouched) => void
  /**
   * Set Flow Complete state.
   */
  setFlowComplete: (isFlowComplete: boolean) => void
}

export const defaultValues: CreateVaultState = {
  touched: {},
}

export const CreateVaultContext = createContext<
  [CreateVaultState, CreateVaultActions]
>([
  defaultValues,
  {
    setName: () => null,
    setUsersPolicy: () => null,
    setPolicyLimits: () => null,
    setOutgoingAddresses: () => null,
    setApprovalWorkflow: () => null,
    setApprovalLevelIndexInEdit: () => null,
    setWalletConnectPolicy: () => null,
    setWalletConnectPolicyEnabled: () => null,
    setWalletConnectPolicyInEdit: () => null,
    setWalletConnectStepVisible: () => null,
    resetUserLimitsBasedOnPolicyLimits: () => null,
    setPolicyInEdit: () => null,
    setVaultId: () => null,
    setTouched: () => null,
    setFlowComplete: () => null,
  },
])

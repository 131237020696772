import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { LocalsForms } from '@features/locales/forms'
import { useCountries } from '@features/locales/hooks/countries'
import { useStates } from '@features/locales/hooks/states'
import { CountryCode, useEntityQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../Create/Create'
import { useLinkBankAccount } from '../../Link.Context'

import type { DropdownItem } from '@circlefin/components/lib/Dropdown'

const schema = y.object({
  /**
   * Holder's Name.
   */
  name: y.string().required(),
  /**
   * Address Line 1.
   */
  line1: y.string().required(),
  /**
   * Address Line 2.
   */
  line2: y.string(),
  /**
   * City.
   */
  city: y.string().required(),
  /**
   * Country.
   */
  country: y.mixed<CountryCode>().oneOf(Object.values(CountryCode)).required(),
  /**
   * Postal Code.
   */
  postalCode: y.string().postalCode().required(),
  /**
   * State / Province.
   */
  state: LocalsForms.Dropdown.stateSchema.required(),
  /**
   * Flag to determine if account belongs to organization.
   */
  isOrganizationAccount: y.boolean().required(),
})

export type AccountAddressFormValues = y.InferType<typeof schema>

export interface AccountAddressFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: AccountAddressFormValues) => void
}

export const AccountAddressForm: React.FC<AccountAddressFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ address }, { setAddress }] = useLinkBankAccount()
  const [createBankAccount] = useLinkCreate()
  const { countries } = useCountries({
    link: { registeredIn: true },
  })

  const { data } = useEntityQuery()

  const entityName = data?.entity?.institutionName
    ? t('link.accountAddress.isOrganizationAccount.entity', {
        entityName: data.entity.institutionName,
      })
    : ''

  const [Form, { register, watch, formState, getValues, setValue }] =
    useForm<AccountAddressFormValues>({
      schema,
      mode: 'onBlur',
      defaultValues: {
        name: address?.name,
        line1: address?.line1,
        line2: address?.line2,
        postalCode: address?.postalCode,
        city: address?.city,
        state: address?.state,
        country: address?.country ?? CountryCode.US,
        isOrganizationAccount: address?.isOrganizationAccount ?? false,
      },
    })

  const [country, isOrganizationAccount] = watch([
    'country',
    'isOrganizationAccount',
  ])

  const { states } = useStates({
    country: country ?? CountryCode.US,
    link: { registeredIn: true },
  })

  const handleCountryChange = useCallback(
    (country: DropdownItem<CountryCode>) => {
      if (country.value === CountryCode.SG) {
        setValue('state', 'Singapore')
      } else {
        setValue('state', '')
      }
    },
    [setValue],
  )

  const stateLabel = useMemo(() => {
    switch (country) {
      case CountryCode.US:
        return t`link.accountAddress.state`
      case CountryCode.CA:
        return t`link.accountAddress.province`
      default:
        return t`link.accountAddress.territory`
    }
  }, [country, t])

  const stateInput = useMemo(() => {
    switch (country) {
      case CountryCode.US:
      case CountryCode.CA:
        return (
          <Form.Dropdown
            data-testid="dropdown-state"
            items={states.toDropdown()}
            label={stateLabel}
            maxMenuItems={6}
            name="state"
          />
        )
      case CountryCode.SG:
        return (
          <input
            data-testid="input-state"
            type="hidden"
            value="Singapore"
            {...register('state')}
          />
        )
      default:
        return (
          <Form.Input
            className="w-full"
            data-testid="input-state"
            label={stateLabel}
            name="state"
          />
        )
    }
  }, [country, Form, states, stateLabel, register])

  const onFormSubmit = useCallback(
    (address: AccountAddressFormValues) => {
      setAddress(address)
      onSubmit?.(address)
      createBankAccount()
    },
    [createBankAccount, onSubmit, setAddress],
  )

  const handleCheckboxUpdate = useCallback(() => {
    setAddress(getValues())
  }, [setAddress, getValues])

  return (
    <div data-testid="account-address-form">
      <Form className="grid w-128 gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-name"
          label={t`link.accountAddress.name.label`}
          message={t`link.accountAddress.name.message`}
          name="name"
        />

        <Form.Input
          className="w-full"
          data-testid="input-line1"
          label={t`link.accountAddress.line1`}
          name="line1"
        />

        <Form.Input
          className="w-full"
          data-testid="input-line2"
          label={t`link.accountAddress.line2`}
          name="line2"
        />

        <Form.Input
          className="w-full"
          data-testid="input-postalCode"
          label={t`link.accountAddress.postalCode`}
          name="postalCode"
        />

        <Form.Input
          className="w-full"
          data-testid="input-city"
          label={t`link.accountAddress.city`}
          name="city"
        />

        <Form.Dropdown
          data-testid="dropdown-country"
          items={countries.toDropdown()}
          label={t`link.accountAddress.country`}
          maxMenuItems={6}
          name="country"
          onChange={handleCountryChange}
        />

        {stateInput}

        <Form.Checkbox
          className="mt-3 items-baseline"
          data-testid="input-isOrganizationAccount"
          disabled={!formState.isValid}
          label={
            <Trans
              components={{ strong: <strong />, br: <br /> }}
              i18nKey="modals.bankAccount:link.accountAddress.isOrganizationAccount.label"
              values={{
                entity: entityName,
              }}
            />
          }
          name="isOrganizationAccount"
          onChange={handleCheckboxUpdate}
        />

        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          disabled={!isOrganizationAccount}
          variant="primary"
        >{t`link.accountAddress.addBank`}</Form.SubmitButton>
      </Form>
    </div>
  )
}

import { FeatureSwitch } from '@services/feature-switch'
import { segment } from '@services/sections/lib/api'
import getConfig from 'next/config'

import type { Segment, SegmentApiRoute } from '../server/server.types'

const config = getConfig()

export enum SegmentEvents {
  LinkClicked = 'Link Clicked',
  ButtonClicked = 'Button Clicked',
  RetryButtonClicked = 'Retry Button Clicked',
  MFASuccess = 'Multi Factor Authentication Success',
  MFAFailure = 'Multi Factor Authentication Failure',
  BackClicked = 'Back Clicked',
  CancelClicked = 'Cancel Clicked',
  ContinueClicked = 'Continue Button Clicked',
  CountrySelected = 'Country Selected',
  CryptoPayoutDetailsClicked = 'Crypto Payout Details Clicked',
  BankTypeClicked = 'Bank Type Clicked',
  AddBankClicked = 'Add Bank Clicked',
  WithdrawClicked = 'Withdraw Clicked',
  DoneClicked = 'Done Clicked',
  StateSelected = 'State Selected',
  SubmitClicked = 'Submit Clicked',
  DepositClicked = 'Deposit Clicked',
  ConfirmClicked = 'Confirm Clicked',
  DepositTypeSelected = 'Deposit Type Selected',
  GetWireInfoClicked = 'Get Wire Info Clicked',
  BlockchainTypeClicked = 'Blockchain Type Clicked',
  IncreaseMyLimitsClicked = 'Increase My Limits Clicked',
  ViewAllClicked = 'View All Clicked',
  TransactionDetailsClicked = 'Transaction Details Clicked',
  LoanDetailsClicked = 'Loan Details Clicked',
  InviteUserClicked = 'Invite User Clicked',
  InviteSent = 'Invite Sent',
  SendInviteClicked = 'Send Invite Clicked',
  CancelInviteClicked = 'Cancel Invite Clicked',
  TransferClicked = 'Transfer Clicked',
  SendClicked = 'Send Clicked',
  AddWalletAddressClicked = 'Add Wallet Address Clicked',
  AddFundsClicked = 'Add Funds Clicked',
  ErrorDisplayed = 'Error Displayed',
  ApplyNowClicked = 'Apply Now Clicked',
  FundLoanClicked = 'Fund Loan Clicked',
  TransferFundsClicked = 'Transfer Funds Clicked',
  LoanTypeSelected = 'Loan Type Selected',
  LinkedAccountsClicked = 'Linked Accounts Clicked',
  SettlementDetailsClicked = 'Settlement Details Clicked',
  ReversalDetailsClicked = 'Reversal Details Clicked',
  CheckoutSessionDetailsClicked = 'Checkout Session Details Clicked',
  MerchantPaymentDetailsClicked = 'Merchant Payment Details Clicked',
  MerchantRefundDetailsClicked = 'Merchant Refund Details Clicked',
  MerchantPaymentIntentDetailsClicked = 'Merchant Payment Intent Details Clicked',
  MerchantPayoutDetailsClicked = 'Merchant Payout Details Clicked',
  TransferDetailsClicked = 'Transfer Details Clicked',
  MerchantChargebackDetailsClicked = 'Merchant Chargeback Details Clicked',
  InvoiceDetailsClicked = 'Invoice Details Clicked',
  TourButtonClicked = 'Tour Button Clicked',
  GetStartedClicked = 'Get Started Clicked',
  FieldEntered = 'Field Entered',
  DropdownSelected = 'Dropdown Selected',
  CheckboxClicked = 'Checkbox Clicked',
  CreateAccountClicked = 'Create Account Clicked',
  SignInClicked = 'Sign In Clicked',
  SignUpClicked = 'Sign Up Clicked',
  SignOutClicked = 'Sign Out Clicked',
  AccessAccountClicked = 'Access Account Clicked',
  FormSubmitted = 'Form Submitted',
  AddCardClicked = 'Add Card Clicked',
  PayInvoiceClicked = 'Pay Invoice Clicked',
  SwitchClicked = 'Switch Clicked',
  GoToApplicationClicked = 'Go To Application Clicked',
  SetDefaultPaymentClicked = 'Set Default Payment Method Clicked',
  CardDetailsClick = 'Card Details Clicked',
  MerchantReportDownloaded = 'Merchant Report Downloaded',
  EnableAutoPayClicked = 'Enable AutoPay Clicked',
  BlockEntityClicked = 'Block Entity Clicked',
  AllowEntityClicked = 'Allow Entity Clicked',
  RefundMerchantPaymentClicked = 'Refund Merchant Payment Clicked',
  CancelMerchantPaymentClicked = 'Cancel Merchant Payment Clicked',
  NonApiCustomerButtonClicked = 'Non-API Customer Button Clicked',
  PlatformTabClicked = 'Platform Tab Clicked',
  BlockEntityClickedFromPayment = 'Block Entity Clicked From Payment',
  AddWalletButtonInVaultDetailClicked = 'Add Wallet Button in Vault Detail Clicked',
  DepositButtonInVaultDetailClicked = 'Deposit Button in Vault Detail Clicked',
  TransferButtonInVaultDetailClicked = 'Transfer Button in Vault Detail Clicked',
  DepositToVaultWalletClicked = 'Deposit to Vault Wallet Clicked',
  DepositToVaultClicked = 'Deposit To Vault Clicked',
  TransferOutOfVaultClicked = 'Transfer Out Of Vault Clicked',
  TransferOutOfVaultWalletClicked = 'Transfer out of Vault Wallet Clicked',
  AddVaultWalletClicked = 'Add Vault Wallet Clicked',
  CompleteVaultSetupClicked = 'Complete Vault Setup clicked',
  SubmitVaultForApprovalClicked = 'Submit Vault For Approval Clicked',
  DiscardVaultPolicyClicked = 'Discard Vault Policy Clicked',
  EditVaultPolicyClicked = 'EditVaultPolicyClicked',
  DownloadVaultReportButtonClicked = 'DownloadVaultReportButtonClicked',
  DownloadVaultWalletReportButtonClicked = 'DownloadVaultWalletReportButtonClicked',
  ActivateVaultWalletClicked = 'Activate Vault Wallet Clicked',
  APILogRowClicked = 'API Log Row Clicked',
  RemoveSubscriptionClicked = 'Remove Subscription Clicked',
  AddSubscriptionSuccess = 'Add Subscription Success',
  AddSubscriptionError = 'Add Subscription Error',
  VaultCancelPendingApprovalClicked = 'Cancel Pending Approval Clicked',
  VaultCompleteSetupClicked = 'Complete Vault Setup Clicked',
  CreateVaultClicked = 'Create Vault Clicked',
  DiscardDraftPolicyClicked = 'Discard Draft Policy Clicked',
  OtherInterestClicked = 'USDC Other Interest Clicked',
  NovaRedirect = 'Nova Redirect',
  UnsupportedBusinessNatureSelected = 'Unsupported Business Nature Selected',
  UnsupportedEntityTypeSelected = 'Unsupported Entity Type Selected',
  HomePageTransferClicked = 'Home Page Header CTA - Transfer Clicked',
  HomePageDepositClicked = 'Home Page Header CTA - Deposit Clicked',
  HomePageWalletTransferClicked = 'Home Page - Wallet Action - Transfer Clicked',
  HomePageWalletDepositClicked = 'Home Page - Wallet Action - Deposit Clicked',
  DiscardChangesClicked = 'Discard Changes Clicked',
  SaveAndExitClicked = 'Save And Exit Clicked',
  SaveAndContinueClicked = 'Save and Continue Clicked',
  OverviewSectionClicked = 'Overview Section Clicked',
  SubmitApplicationClicked = 'Submit Application Clicked',
  CompletionStatusUpdated = 'Completion Status Updated',
}

/**
 * Segment Service - should only be used client side.
 */
export function SegmentClientService() {
  /**
   * Helper function to see if we have the enabled flag set and the api write key defined.
   */
  function isEnabled(): boolean {
    // if it is enabled and we have a key defined
    return FeatureSwitch.segmentEnabled() && !!getAPIKey()
  }

  /**
   * Get api key from env variable.
   */
  function getAPIKey(): string | undefined {
    return config?.publicRuntimeConfig?.SEGMENT_ANALYTICS_WRITE_KEY
  }

  /**
   * Gets the screen name from the event target object.
   */
  function getModalScreenNameFromEvent<T extends Element>(
    event: React.ChangeEvent<T> | React.MouseEvent<T, MouseEvent>,
  ): string | null {
    const el = (event.currentTarget ?? event.target)?.closest(
      '[data-segmentscreenname]',
    ) as Element & {
      dataset?: DOMStringMap
    }

    return el?.dataset?.segmentscreenname ?? null
  }

  /**
   * Gets modal screen name from a mapping object, each key represents the steps index and the value represents the modal screen name.
   */
  function getModalScreenAttributeName(
    mapping: Record<number, string> = {},
    stepIndex: number,
  ) {
    return mapping[stepIndex]
  }

  /**
   * Gets the properties for the current page.
   */
  function getPageProperties() {
    if (typeof window === 'undefined') {
      return undefined
    }

    const properties: Segment.Page.Properties = {
      name: window.location.pathname,
      path: window.location.pathname,
      referrer: document.referrer,
      search: window.location.search,
      url: window.location.href,
      title: document.title,
    }

    return properties
  }

  /**
   * Makes api call to the segment api route.
   */
  async function postToEndpoint(body: SegmentApiRoute.Body) {
    if (!isEnabled()) {
      return
    }

    try {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      const response = await fetch(segment.route, options)

      if (!response.ok) {
        return
      }

      await response.json()
    } catch (err) {
      // TODO: handle the error?
    }
  }

  return {
    isEnabled,
    getAPIKey,
    getModalScreenNameFromEvent,
    getModalScreenAttributeName,
    getPageProperties,
    postToEndpoint,
  }
}

import { useCallback, useMemo } from 'react'

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { BankAccountType, useBankAccountsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { DropdownOptionsLocale } from '@circlefin/components/lib/Dropdown'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const BankAccountTypeSchema = y
  .mixed<BankAccountType>()
  .oneOf(Object.values(BankAccountType))

export const schema = y.object({
  /**
   * Bank Account.
   */
  bankAccountType: BankAccountTypeSchema,
})

const Dropdown = createFormDropdown()

export interface BankAccountTypesProps
  extends Omit<React.ComponentProps<typeof Dropdown>, 'items' | 'onChange'> {
  /**
   * Custom Style.
   */
  className?: string
  /**
   * Label.
   */
  label?: string
  /**
   * Placeholder.
   */
  placeholder?: string
  /**
   * On change handler.
   */
  onChange?: (type: BankAccountType) => void
}

export const BankAccountTypes: React.FC<BankAccountTypesProps> = ({
  className,
  label,
  name,
  placeholder,
  onChange,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const handleChange = useCallback(
    (item: SelectListItem<BankAccountType | undefined>) => {
      if (item.value != null) {
        onChange?.(item.value)
      }
    },
    [onChange],
  )

  const { data, loading } = useBankAccountsQuery()

  // Get unique bank account types
  const uniqueTypes = useMemo(() => {
    return [
      ...new Set((data?.bankAccounts ?? []).map((account) => account.type)),
    ]
  }, [data?.bankAccounts])

  const bankAccountTypeItems = useMemo(() => {
    return uniqueTypes.map((type) => ({
      value: type,
      label: t(`dropdown.bankAccount.types.list.${type}`),
    }))
  }, [uniqueTypes, t])

  // Dropdown Locale.
  const locale: DropdownOptionsLocale = useMemo(
    () => ({
      noItemsMessage: t`dropdown.bankAccount.types.noItemsMessage`,
    }),
    [t],
  )

  return (
    <Dropdown
      className={className}
      data-testid="dropdown-bankaccount-types"
      inputLoading={loading}
      items={bankAccountTypeItems}
      label={label ?? t('dropdown.bankAccount.types.label')}
      locale={locale}
      maxMenuItems={6}
      name={name ?? 'bankAccountType'}
      onChange={handleChange}
      placeholder={placeholder ?? t('dropdown.bankAccount.types.placeholder')}
      {...props}
    />
  )
}

import { createContext, useEffect, useMemo, useRef, useState } from 'react'

import { PUBLIC_ONLY } from '@services/permissions'
import { findDynamicSectionByPath } from '@services/sections'
import { homeSection } from '@services/sections/lib/home'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

/**
 * Create Idle Context.
 */
export const IdleContext = createContext<boolean>(false)

export interface IdleProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Idle Provider.
 */
export const IdleProvider: React.FC<IdleProviderProps> = ({ children }) => {
  const { data: session, status } = useSession()
  const nonNullSessionSet = useRef(false)
  const [refreshing, setRefreshing] = useState(false)
  const { pathname, push } = useRouter()

  const section = findDynamicSectionByPath(pathname)

  const shouldRedirectFromPublicOnlyPage = useMemo(
    () => session && section?.permissions === PUBLIC_ONLY,
    [section?.permissions, session],
  )

  useEffect(() => {
    // Determine if user has a non-null session.
    if (session && status === 'authenticated') {
      nonNullSessionSet.current = true
    }

    // This a temporary approach until we have a long-term solution: https://circlefin.slack.com/archives/C01P7BXMU14/p1721659547687789?thread_ts=1721658089.693369&cid=C01P7BXMU14
    if (shouldRedirectFromPublicOnlyPage) {
      void push(homeSection.route)
    }

    // Determine if user who had session no longer has a session.
    if (!session && nonNullSessionSet.current) {
      setRefreshing(true)
      window.location.reload()
    }
  }, [status, session, shouldRedirectFromPublicOnlyPage, push])

  return (
    <IdleContext.Provider value={nonNullSessionSet.current}>
      {!refreshing && !shouldRedirectFromPublicOnlyPage && children}
    </IdleContext.Provider>
  )
}

import { useCallback, useMemo } from 'react'

import { FixedBanner, TextLink } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import {
  BURN_FEE_V2_ENABLED,
  ROLR_REDEMPTION_ONLY_ACCOUNT,
} from '@services/permissions'
import {
  SettingsFeaturesType,
  useSetSettingsFeatureMutation,
  useSettingsFeaturesQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface BurnFeeV2BannerProps {
  /**
   * Whether the banner should be multiline or not. Defaults to false.
   */
  multiline?: boolean
  /**
   * Custom className.
   */
  className?: string
}

export const BurnFeeV2Banner: React.FC<BurnFeeV2BannerProps> = ({
  multiline = false,
  className,
}) => {
  const { t } = useTranslation('custody/common')
  const { data, loading } = useSettingsFeaturesQuery()
  const [setSettingsFeature] = useSetSettingsFeatureMutation()
  const [, { isAuthorized: burnFeeV2Enabled }] =
    usePermission(BURN_FEE_V2_ENABLED)
  const [, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )
  //TODO: Add correct link in .json once provided by customer support
  const burnFeeLearnMoreUrl = ''

  const shouldShowBanner = useMemo(() => {
    return (
      burnFeeV2Enabled &&
      !data?.settings.features?.burnFeeAnnouncement &&
      !isRolr
    )
  }, [burnFeeV2Enabled, data?.settings.features?.burnFeeAnnouncement, isRolr])

  const onDismiss = useCallback(() => {
    void setSettingsFeature({
      variables: {
        type: SettingsFeaturesType.burnFeeAnnouncement,
      },
    })
  }, [setSettingsFeature])

  if (loading) {
    return null
  }

  return (
    <FixedBanner
      className={className}
      data-testid="burnfee-banner"
      level="page"
      multiline={multiline}
      onDismiss={onDismiss}
      status="info"
      visible={shouldShowBanner}
    >
      <FixedBanner.Title>{t('burnFeeV2Banner.title')}</FixedBanner.Title>
      <FixedBanner.Description>
        {t('burnFeeV2Banner.description')}
        <TextLink.Link
          href={burnFeeLearnMoreUrl}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t('burnFeeV2Banner.link')}
        </TextLink.Link>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

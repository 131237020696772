import { useCallback, useState } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { ModalBackButton } from '@containers/layout'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { CIRCLE_WALLET_APPROVAL } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import {
  AddApprovalUsersCta,
  ApprovalHelpSection,
  useApprovalWorkflowValidate,
} from '../../../../containers/approval-policy'
import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../layout'

import { LevelForm, LevelSummary } from './Level'
import { UnassignedApproversWarning } from './UnassignedApproversWarning/UnassignedApproversWarning'

export const Approval: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const [, { isAuthorized: isPolicyMethodChangeEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  const { router } = useModal()

  const [{ approvalLevelInEdit }, { setApprovalLevelInEdit }] =
    useWalletApprovalPolicy()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()

  const {
    isValid,
    approvers,
    missingApproversInWorkflow,
    approvalHelpSectionHidden,
    isApproverInWorkflow,
    isOptional,
  } = useApprovalWorkflowValidate()
  const [showUnassignedApproversWarning, setUnassignedApproversWarning] =
    useState(false)

  const goToNextStep = useCallback(() => {
    void router.push(
      isPolicyMethodChangeEnabled
        ? routes.walletApprovalPolicy.createPolicy.settings
        : routes.walletApprovalPolicy.createPolicy.review,
    )
  }, [isPolicyMethodChangeEnabled, router])

  const goToPermissionStep = useCallback(() => {
    void router.push(routes.walletApprovalPolicy.createPolicy.permissions)
  }, [router])

  const onContinue = useCallback(() => {
    if (missingApproversInWorkflow.size > 0) {
      setUnassignedApproversWarning(true)
      return
    }

    if (isValid) {
      setApprovalLevelInEdit(false)
    }

    goToNextStep()
  }, [
    goToNextStep,
    isValid,
    missingApproversInWorkflow.size,
    setApprovalLevelInEdit,
  ])

  const onCloseUnassignedApproversWarning = useCallback(() => {
    setUnassignedApproversWarning(false)
  }, [])

  return (
    <WalletApprovalPolicyLayout
      currentStep={WalletApprovalPolicyStep.APPROVAL}
      helpTextColumn={
        <ApprovalHelpSection
          approvers={approvers}
          hidden={approvalHelpSectionHidden}
          isApproverInWorkflow={isApproverInWorkflow}
        />
      }
    >
      <UnassignedApproversWarning
        onCancel={onCloseUnassignedApproversWarning}
        onContinue={goToNextStep}
        open={showUnassignedApproversWarning}
      />
      <div className="grid max-w-176 grid-cols-1 justify-items-start">
        <p className="text-neutral-subtle type-intro-sm">
          {t('common:optional')}
        </p>
        <h2 className="text-neutral-strong type-h-page-sm">
          {t('approval.title')}
        </h2>

        <p className="mt-4 text-neutral-strong type-intro-sm">
          {t('approval.instruction', { currency })}
        </p>

        {approvers.length === 0 ? (
          <AddApprovalUsersCta className="mt-9" onClick={goToPermissionStep} />
        ) : (
          <div className="mt-9 w-full pt-1">
            {approvalLevelInEdit ? (
              <LevelForm approvers={approvers} />
            ) : (
              <LevelSummary />
            )}
          </div>
        )}

        <div className="mt-5">
          <ModalBackButton />

          <Button
            className="w-60"
            disabled={!isOptional && !isValid}
            onClick={onContinue}
            variant="primary"
          >
            {t(isOptional ? 'common:skip' : 'common:continue')}
          </Button>
        </div>
      </div>
    </WalletApprovalPolicyLayout>
  )
}

import { createContext } from 'react'

export interface RouteHistoryContextProps {
  /**
   * Previous route.
   */
  prevRoute: string | null
}

export const RouteHistoryContext = createContext<RouteHistoryContextProps>({
  prevRoute: null,
})

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Account funding mutation.
 * Which users are allowed to transfer to or deposit from bank OR blockchain wallet?
 */
export const ACCOUNT_FUNDING_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WALLETS_ROLE, Role.WIRES_ROLE],
}

import { useEffect } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { VAULT_DEVICE_REGISTRATION_STATUS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  VaultDeviceRegistrationStatusCode,
  useVaultDeviceRegistrationStatusQuery,
  useVaultRegistrationTokenQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'
import ReactQRCode from 'qrcode.react'

export const Pair: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const { data, error, loading, refetch } = useVaultRegistrationTokenQuery()

  const { data: dataDevice } = useVaultDeviceRegistrationStatusQuery({
    pollInterval: VAULT_DEVICE_REGISTRATION_STATUS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const statusCode = dataDevice?.vaultDeviceRegistrationStatus.statusCode
    if (statusCode === VaultDeviceRegistrationStatusCode.PIN_NOT_SET) {
      modal.router.push(routes.vault.pairDevice.pin)
    } else if (statusCode === VaultDeviceRegistrationStatusCode.READY) {
      modal.router.push(routes.vault.pairDevice.success)
    }
  }, [dataDevice?.vaultDeviceRegistrationStatus, modal.router])

  return (
    <FullScreen totalSteps={4}>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <div className="mx-auto flex h-full max-w-192 flex-col justify-between">
          <Center>
            <h2 className="text-center text-3xl text-black-600 font-circular-bold">
              {t`pairDevice.pair.title`}
            </h2>
            <ol className="mt-12 flex max-w-104 flex-col gap-y-8">
              <li className="flex items-center gap-7">
                <span className="flex size-14 shrink-0 items-center justify-center rounded-full border border-black-75 text-2xl text-black-500 font-circular-medium">
                  {t`common:number.1`}
                </span>
                <span>{t`pairDevice.pair.step-1`}</span>
              </li>
              <li>
                <span className="flex items-center gap-x-7">
                  <span className="flex size-14 shrink-0 items-center justify-center rounded-full border border-black-75 text-2xl text-black-500 font-circular-medium">
                    {t`common:number.2`}
                  </span>
                  <span>{t`pairDevice.pair.step-2`}</span>
                </span>
                <span className="flex gap-x-7">
                  <div className="w-14" />
                  <SkeletonBox className="size-32" loading={loading}>
                    <div className="size-32">
                      <ReactQRCode
                        height="100%"
                        renderAs="svg"
                        value={data?.vaultRegistrationToken.pairingUrl ?? ''}
                        width="100%"
                      />
                    </div>
                  </SkeletonBox>
                </span>
              </li>
            </ol>
          </Center>
        </div>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { FixedBanner, Button } from '@circlefin/components'
import { MultiStep } from '@modals/layout'
import { Center } from '@shared/components/layout'
import { useBalancesMainQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useRefund } from '../Refund.Context'

export const Review: React.FC = () => {
  const { t } = useTranslation('platform/modals')

  const [state, { initiateRefund }] = useRefund()

  const { data } = useBalancesMainQuery({
    // Always render a modal step with fresh balance
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: state.sourceCurrency,
    },
  })

  const handleSubmit = useCallback(() => {
    initiateRefund(state)
  }, [initiateRefund, state])

  return (
    <MultiStep
      subtitle={t('common:step', { start: 2, end: 2 })}
      title={t`payment-intent.refund.review.title`}
    >
      <div className="grid grid-cols-6 font-circular">
        <div
          className="col-span-2 mt-2 font-circular-medium"
          data-testid="walletAddress"
        >
          {t('payment-intent.refund.review.walletAddress')}
        </div>
        <div className="col-span-4 mt-2">{state.address}</div>
        <div
          className="col-span-2 mt-2 font-circular-medium"
          data-testid="chain"
        >
          {t('payment-intent.refund.review.chain')}
        </div>
        <div className="col-span-4 mt-2">{state.chain}</div>
        <div className="col-span-4 col-start-3 mt-2">
          <FixedBanner status="warning" visible>
            <FixedBanner.Title>{t`payment-intent.refund.review.alertTitle`}</FixedBanner.Title>
            <FixedBanner.Description>
              {t(`payment-intent.refund.review.alertSubtitle`)}
            </FixedBanner.Description>
          </FixedBanner>
        </div>
        <div
          className="col-span-2 mt-2 font-circular-medium"
          data-testid="refundSummary"
        >
          {t('payment-intent.refund.review.refundSummary')}
        </div>
        <div className="col-span-4 mt-2">
          {state.refundAmount}
          <span className="ml-2 leading-7">{state.refundCurrency}</span>
        </div>
        <div
          className="col-span-2 mt-2 font-circular-medium"
          data-testid="refundSummary"
        >
          {t('payment-intent.refund.review.refundSource')}
        </div>
        <div className="col-span-2 col-start-3 mt-2">
          {state.sourceCurrency}
        </div>
        <div className="col-span-2 col-start-5 mt-2">
          {t('payment-intent.refund.review.availableBalance')}
        </div>
        <div className="col-span-2 col-start-5 mt-2">
          <span className="leading-7">
            {data?.balances.main?.balance.amount}
          </span>
          <span className="ml-2 leading-7">
            {data?.balances.main?.balance.currency}
          </span>
        </div>
      </div>

      <Center className="pt-6" variant="horizontal">
        <Button
          className="w-64"
          data-testid="submit-button"
          onClick={handleSubmit}
          variant="primary"
        >{t`payment-intent.refund.review.refundButton`}</Button>
      </Center>
    </MultiStep>
  )
}

import { Table } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { PIX_ENABLED } from '@services/permissions'
import { SimpleTable, TableCard } from '@shared/components/tables'
import { BankAccountType, useBankAccountsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { BankAccountTableRow } from './BankAccountsTable.Row'

export const BankAccountsTable: React.FC = () => {
  const { t } = useTranslation('settings')
  const [, { isAuthorized: pixEnabled }] = usePermission(PIX_ENABLED)

  const { data, loading, error } = useBankAccountsQuery({
    variables: {
      // We will continue to show linked sen accounts for historical purposes
      types: [
        BankAccountType.wire,
        BankAccountType.sen,
        BankAccountType.signet,
        BankAccountType.cbit,
        BankAccountType.xpay,
        BankAccountType.rtp,
        ...(pixEnabled ? [BankAccountType.pix] : []),
      ],
    },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <TableCard>
      <SimpleTable
        empty={t`linkedAccounts.table.noResults`}
        error={error}
        loading={loading}
        numLoadingRows={3}
        size={data?.bankAccounts?.length}
        hoverHighlight
      >
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell>{t`linkedAccounts.headers.bankName`}</Table.Head.Cell>
            <Table.Head.Cell>{t`linkedAccounts.headers.type`}</Table.Head.Cell>
            <Table.Head.Cell>{t`linkedAccounts.headers.accountHolder`}</Table.Head.Cell>
            <Table.Head.Cell>{t`linkedAccounts.headers.status`}</Table.Head.Cell>
            <Table.Head.Cell />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {data?.bankAccounts?.map((account, key) => (
            <BankAccountTableRow key={key} bankAccount={account} />
          ))}
        </Table.Body>
      </SimpleTable>
    </TableCard>
  )
}

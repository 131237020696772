import { useCallback } from 'react'

import { Button, SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { Center } from '@shared/components/layout'
import { Currency, useBalancesMainQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../SendOnchain.Context'

import type { BlockchainAbbreviation } from '@shared/graphql'

const schema = y.object({
  /**
   * Transfer amount.
   */
  amount: y.number().required(),
  /**
   * Transfer fee.
   */
  fee: y.number().allowEmpty().required(),
  /**
   * Total transfer amount (Amount - Fee).
   */
  total: y.number().allowEmpty().required(),
})

type FormValues = y.InferType<typeof schema>

interface TransferAmountFormProps {
  /**
   * Handles form submit.
   */
  onSubmit: (values: FormValues) => void
  /**
   * Blockchain.
   */
  blockchain?: BlockchainAbbreviation
}

export const TransferAmountForm: React.FC<TransferAmountFormProps> = ({
  onSubmit,
  blockchain,
}) => {
  const { t } = useTranslation('modals.blockchain')
  const { money } = useMoney()
  const { router } = useModal()
  const { track } = useSegment()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()

  const { loading, data } = useBalancesMainQuery({
    // Always render a modal step with fresh balance
    fetchPolicy: 'cache-and-network',
    variables: {
      currency,
    },
  })

  const [{ amount, fee, total }, { setTransferState }] = useSendOnChain()

  /**
   * If we have cached data - ignore loading.
   */
  const balanceLoading = !data && loading

  const balance = data?.balances.main?.balance
  const availableBalance = Number(balance?.amount ?? 0)

  const [Form, { watch }] = useForm<FormValues>({
    schema: schema.shape({
      amount: y
        .number()
        .moreThan(0, {
          key: 'amount.moreThan',
          moreThan: money({
            number: 0,
            variant: currency,
          }),
        })
        .max(availableBalance, {
          key: 'amount.max',
          max: money({
            number: availableBalance,
            variant: currency,
          }),
        })
        .required()
        .allowEmpty(),
    }),
    defaultValues: {
      amount,
      fee,
      total,
    },
  })

  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      setTransferState({
        amount: values.amount,
        fee: values.fee,
        total: Number(values.amount) + Number(values.fee),
      })

      onSubmit?.(values)
    },
    [onSubmit, setTransferState],
  )

  const handleFormChange = useCallback(() => {
    const amount = Number(watch('amount'))
    const fee = Number(watch('fee'))
    const total = amount + fee

    setTransferState({
      amount,
      fee,
      total,
    })
  }, [setTransferState, watch])

  const handleAddFundsClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      track(SegmentEvents.AddFundsClicked, {
        event: e,
      })

      router.push({
        pathname: routes.transfer.chooseDepositType,
        query: {
          currency,
        },
      })
    },
    [currency, router, track],
  )

  return (
    <Form onChange={handleFormChange} onSubmit={handleFormSubmit}>
      <Form.MoneyInput
        className="mt-4 w-full"
        currencyVariant={currency}
        label="Amount"
        name="amount"
        padded
      />

      <div className="mt-1 flex items-center justify-between">
        <SkeletonBox className="h-8 w-36" loading={balanceLoading}>
          {balance != null && (
            <div
              className="text-sm leading-5 text-black-500 font-circular-regular"
              data-testid="available-funds"
            >
              {money({ number: balance.amount, variant: balance.currency })}{' '}
              available
            </div>
          )}
        </SkeletonBox>

        <SkeletonBox className="h-8 w-36" loading={balanceLoading}>
          <Button.Link
            data-testid="add-funds-button"
            onClick={handleAddFundsClick}
            size="sm"
            variant="text"
          >
            {t`sendOnchain.transferAmount.amount.addFunds`}
          </Button.Link>
        </SkeletonBox>
      </div>

      <div className="mt-8 flex items-center justify-between">
        <span className="leading-6 text-black-600 font-circular-medium">
          {t`sendOnchain.transferAmount.fee.label`}
        </span>
        <span className="leading-6">
          -
          {money({
            number: fee,
            variant: currency,
            options: { symbol: false },
          })}
        </span>
      </div>

      <span className="text-xs text-black-500">
        {t`sendOnchain.transferAmount.fee.disclaimer`}
      </span>

      <div className="mt-4 flex items-center justify-between border-t border-black-50 pt-4 leading-6 font-circular-medium">
        <span>{t`sendOnchain.transferAmount.amount.totalTransferred`}</span>
        <span data-testid="total-transferred-amount">
          {money({
            number: total,
            variant: currency,
            options: {
              symbol: false,
            },
          })}{' '}
          {/* TODO: Revisit L2 treatment in [https://circlepay.atlassian.net/browse/BRAAV-10935]. */}
          {/* TODO: Update useMoney hook or pass in currency variable when supporting EURC onchain transactions */}
          {t(
            `payments:currencyByChain.${blockchain ?? ''}`,
            { currency },
            { fallback: `payments:${currency.toLowerCase()}` },
          )}
        </span>
      </div>

      <Center className="pt-10" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          variant="primary"
        >
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

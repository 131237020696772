import { useMemo } from 'react'

import { VaultUserPolicyRoleEnum } from '@shared/graphql'

import { useCreateVault } from '../../../../hooks/create'

import type { User } from '@shared/graphql'

export const useApprovalWorkflowValidate = () => {
  const [{ approvalWorkflow = [], usersPolicy = [], touched }] =
    useCreateVault()

  const approvers = useMemo(
    () =>
      usersPolicy
        .filter(({ permissions }) =>
          permissions.includes(VaultUserPolicyRoleEnum.APPROVER),
        )
        .map((policy) => policy.user),
    [usersPolicy],
  )

  const missingApproversInWorkflow: Map<User['id'], User> = useMemo(() => {
    const set = new Map<User['id'], User>()

    approvers.forEach((approver) => {
      // is this user present in one of the levels
      const isUserInSomeLevel = approvalWorkflow.some((level) =>
        Boolean(level.approvers.find(({ id }) => approver.id === id)),
      )

      if (!isUserInSomeLevel) {
        set.set(approver.id, approver)
      }
    })

    return set
  }, [approvalWorkflow, approvers])

  const allLevelFieldsValid = useMemo(() => {
    if (approvalWorkflow.length === 0) {
      return false
    }

    return approvalWorkflow.every(
      ({ transferAmount, approvers, minNeedConfirmed }) => {
        return (
          transferAmount &&
          minNeedConfirmed &&
          parseFloat(transferAmount) > 0 &&
          approvers.length > 0
        )
      },
    )
  }, [approvalWorkflow])

  const isEmpty = useMemo(() => {
    if (approvalWorkflow.length === 0) {
      return true
    }

    return approvalWorkflow.every(
      ({ transferAmount, approvers, minNeedConfirmed }) => {
        return !transferAmount && !minNeedConfirmed && approvers.length === 0
      },
    )
  }, [approvalWorkflow])

  const isValid = approvers.length > 0 && allLevelFieldsValid

  const isTouched = touched?.approvalWorkflow

  return {
    isOptional: approvalWorkflow.length === 0,
    isValid,
    isEmpty,
    approvers,
    missingApproversInWorkflow,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

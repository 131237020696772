import { useState, useCallback } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'
import { Choose } from '../../containers'
import { useTransfer } from '../../hooks/transfer'

import type { Currency } from '@shared/graphql'

export const ChooseWalletAndDepositType: React.FC = () => {
  const [currency, setCurrency] = useState<Currency>()
  const { t } = useTranslation('modals.transfer')

  const [, { handleValueChange }] = useTransfer()
  const modalHistory = useModalHistory()

  const handleChooseCurrency = useCallback(
    (currency: Currency) => {
      setCurrency(currency)
      handleValueChange({
        showSelectCurrencyScreen: currency != null,
      })
    },
    [handleValueChange],
  )

  return (
    // New screens added to the flow:
    // Choose deposit type (Bank) -> Choose bank account -> Beneficiary details -> Confirmation (4 total)
    // Choose deposit type (Blockchain) -> Choose chain -> Confirmation (3 total)
    // TODO: revisit the totalSteps logic, it varies by deposit type
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <div className="flex flex-col gap-6">
        <Center variant="horizontal">
          <h2 className="type-h-page-sm">{t`chooseWalletAndDepositType.chooseWallet`}</h2>
        </Center>
        <Center variant="horizontal">
          <Buttons.Cards.ChooseWallet
            className="w-116"
            currency={currency}
            onChooseCurrency={handleChooseCurrency}
          />
        </Center>
      </div>

      {currency != null && (
        <div className="mt-12 flex flex-col gap-6">
          <Center variant="horizontal">
            <h2 className="type-h-page-sm">{t`chooseWalletAndDepositType.chooseDepositType`}</h2>
          </Center>
          <Center variant="horizontal">
            <Choose.DepositType
              currency={currency}
              showSelectCurrencyScreen={currency != null}
            />
          </Center>
        </div>
      )}
    </FullScreen>
  )
}

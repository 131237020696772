import { useMemo, useEffect } from 'react'

import { useDatadogRum, withDatadogRum } from '@circlefin/datadog'
import { findSectionByPath } from '@services/sections'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import type { DatadogTeam } from '@services/sections/types'
import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export interface DatadogProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

const DatadogRum: React.FC<DatadogProviderProps> = ({ children }) => {
  const router = useRouter()
  const { setGlobalContext } = useDatadogRum()
  const { data } = useSession()

  const assignedTeam = useMemo(
    () => findSectionByPath(router.route)?.team,
    [router.route],
  )

  // if no datadog team assigned to the route, default to general
  const team: DatadogTeam = assignedTeam ?? 'account-web-general'

  const session = useMemo(() => {
    if (data?.user && data.user.entityId) {
      return {
        entity: {
          id: data.user.entityId,
        },
        user: {
          id: data.user.id,
          name: data.user.name,
          email: data.user.email,
        },
        team,
      }
    }
  }, [data, team])

  useEffect(() => {
    if (session) {
      setGlobalContext(session)
    }
  }, [setGlobalContext, session])

  return <>{children}</>
}

export const DatadogProvider = withDatadogRum({
  applicationId: publicRuntimeConfig?.DATADOG_APPLICATION_ID ?? '',
  clientToken: publicRuntimeConfig?.DATADOG_CLIENT_TOKEN ?? '',
  privacyLevel: publicRuntimeConfig?.DATADOG_PRIVACY_LEVEL ?? '',
  service: publicRuntimeConfig?.SERVICE_NAME ?? '',
  env: publicRuntimeConfig?.ENV ?? '',
  version: publicRuntimeConfig?.CI_VERSION ?? '',
  overwriteConfig: {
    sessionReplaySampleRate:
      publicRuntimeConfig?.DATADOG_SESSION_REPLAY_SAMPLE_RATE
        ? Number(publicRuntimeConfig?.DATADOG_SESSION_REPLAY_SAMPLE_RATE)
        : undefined,
    /**
     * Use a proxy to improve the datadog tracking rate.
     * Some ad/privacy blockers or vpns will filter out tracking requests.
     *
     * Requests are rewritten in next.config.js.
     */
    proxy: (options) => {
      /**
       * Deliberately not using options.path dynamically here as
       * some ad blockers look for patterns such as `/rum?ddsource=`.
       */
      switch (options.path) {
        case '/api/v2/rum':
          return `/browser-intake-monitor?${options.parameters}`
        case '/api/v2/logs':
          return `/browser-intake-logs?${options.parameters}`
        case '/api/v2/replay':
          return `/browser-intake-replay?${options.parameters}`

        default:
          return `/browser-intake${options.path}?${options.parameters}`
      }
    },
  },
})(DatadogRum)

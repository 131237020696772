import { useCallback, useEffect } from 'react'

import {
  Card,
  SkeletonBox,
  Chip,
  Header,
  TruncatedText,
  DescriptionList,
} from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { WalletApprovalTransactionDetailApprovalSummary } from '@features/payments/containers'
import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { TypeGuards } from '@services/type-guards'
import { PAYMENT_ACTIVITY_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { BackButton, Content } from '@shared/components/layout'
import {
  useWalletApprovalTransactionDetailQuery,
  WalletApprovalTransactionStatus,
  WalletApprovalTransactionType,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CancelPendingTransaction } from '../../../buttons/WalletApprovalPolicy'
import { WalletApprovalPendingTransactionVoteButtons } from '../WalletApprovalPendingTransactionVoteButtons/WalletApprovalPendingTransactionVoteButtons'

import type { Currency } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface WalletApprovalPendingTransactionDetailProps {
  /**
   * Additional classes that will be applied to the element.
   */
  className?: string
  /**
   * Proposal id.
   */
  proposalId: string
  /**
   * Currency.
   */
  currency: Currency
}

export const WalletApprovalPendingTransactionDetail: React.FC<WalletApprovalPendingTransactionDetailProps> =
  ({ className, proposalId, currency }) => {
    const { t } = useTranslation('walletApprovalPolicy')
    const { money } = useMoney()
    const { date } = useDate()

    const transactionDetailQuery = useWalletApprovalTransactionDetailQuery({
      variables: {
        proposalId,
      },
      fetchPolicy: 'cache-and-network',
    })

    const {
      data: { walletApprovalTransactionDetail } = {},
      startPolling,
      stopPolling,
    } = transactionDetailQuery

    const loading =
      !transactionDetailQuery.called ||
      (!transactionDetailQuery.data && transactionDetailQuery.loading)

    useEffect(() => {
      if (!walletApprovalTransactionDetail) {
        return
      }

      // if the proposal is in pending state, start polling to get updates, the initiator may have cancelled the proposal.
      if (
        [
          WalletApprovalTransactionStatus.PENDING_APPROVAL,
          WalletApprovalTransactionStatus.PENDING_PROCESSING,
        ].includes(walletApprovalTransactionDetail.status)
      ) {
        startPolling(PAYMENT_ACTIVITY_POLL_INTERVAL)
      } else {
        // we have reached terminal state
        stopPolling()
      }

      return () => {
        stopPolling()
      }
    }, [startPolling, stopPolling, walletApprovalTransactionDetail])

    const handleRetry = useCallback(() => {
      if (transactionDetailQuery.error) {
        void transactionDetailQuery.refetch()
      }
    }, [transactionDetailQuery])

    return (
      <Content className={className}>
        {/* Header */}
        <BackButton query={{ currency }} section={circleWalletDetailSection}>
          {t('common:back')}
        </BackButton>
        <Header>
          <Header.Head title={t`pendingTransactions.details.title`}>
            {Boolean(walletApprovalTransactionDetail?.showCancelAction) && (
              <CancelPendingTransaction proposalId={proposalId} />
            )}
            {Boolean(walletApprovalTransactionDetail?.showVoteAction) && (
              <WalletApprovalPendingTransactionVoteButtons
                proposalId={proposalId}
              />
            )}
          </Header.Head>
          <Header.Description>
            {t('pendingTransactions.details.transactionId', {
              id: proposalId,
            })}
          </Header.Description>
        </Header>

        {/* Content */}
        <Card className={className}>
          <Card.Content className="px-10 pb-0 pt-8">
            <GraphQLErrorBoundary
              error={transactionDetailQuery.error}
              retry={handleRetry}
            >
              <div data-testid="transaction-card">
                {/* Transaction amount */}
                <div className="mb-14 flex">
                  <div>
                    <SkeletonBox className="h-9 w-64" loading={loading}>
                      <div className="h-9 text-neutral-strong font-circular-medium type-h-section-lg">
                        <span>
                          <SkeletonBox loading={loading}>
                            {walletApprovalTransactionDetail &&
                              money({
                                number:
                                  -1 * walletApprovalTransactionDetail.amount,
                                variant:
                                  walletApprovalTransactionDetail.currency,
                                options: {
                                  symbol: false,
                                  signDisplay: true,
                                },
                              })}
                          </SkeletonBox>
                        </span>
                        <span className="ml-2 text-xl leading-7 text-black-200 font-circular-black">
                          {walletApprovalTransactionDetail?.currency}
                        </span>
                      </div>
                    </SkeletonBox>
                    <div className="mt-1 text-xl leading-7 text-black-200 font-circular-regular">
                      {t('pendingTransactions.details.amount')}
                    </div>
                  </div>
                  <div className="ml-auto text-black-500 font-circular-bold type-body-base">
                    <SkeletonBox className="h-7 w-80" loading={loading}>
                      <span>
                        {t('pendingTransactions.details.summaryAsOf', {
                          date:
                            walletApprovalTransactionDetail &&
                            date(
                              walletApprovalTransactionDetail.date,
                              'datetimeWithYear',
                            ),
                        })}
                      </span>
                    </SkeletonBox>
                  </div>
                </div>

                {/* Transaction type */}
                <DescriptionList>
                  <Label>{t('pendingTransactions.columns.type')}</Label>
                  <Description>
                    <SkeletonBox className="h-6 w-56" loading={loading}>
                      {t(
                        `pendingTransactions.${
                          walletApprovalTransactionDetail?.type ===
                          WalletApprovalTransactionType.PAYOUT
                            ? 'withdrawal'
                            : 'sendOnChain'
                        }`,
                      )}
                    </SkeletonBox>
                  </Description>

                  {/* Transaction status */}
                  <Label>{t('pendingTransactions.columns.status')}</Label>
                  <Description>
                    <SkeletonBox className="h-6 w-48" loading={loading}>
                      <Chip
                        className="type-body-base"
                        variant={
                          walletApprovalTransactionDetail?.status != null &&
                          [
                            WalletApprovalTransactionStatus.REJECTED_BY_USER,
                            WalletApprovalTransactionStatus.CANCELLED_BY_INITIATOR,
                          ].includes(walletApprovalTransactionDetail.status)
                            ? 'status/error'
                            : 'status/neutral'
                        }
                      >
                        {t(
                          `pendingTransactions.status.${
                            walletApprovalTransactionDetail?.status ?? ''
                          }`,
                        )}
                      </Chip>
                    </SkeletonBox>
                  </Description>

                  {/* Transaction source wallet */}
                  <Label>{t('pendingTransactions.columns.wallet')}</Label>
                  <Description>
                    <SkeletonBox className="h-6 w-48" loading={loading}>
                      {t('pendingTransactions.details.wallet', {
                        currency,
                      })}
                    </SkeletonBox>
                  </Description>

                  {/* Transaction approval summary */}
                  <WalletApprovalTransactionDetailApprovalSummary
                    loading={loading}
                    {...walletApprovalTransactionDetail}
                  />

                  {/* Onchain transfer recipient */}
                  {TypeGuards.WalletApprovalTransactionDetail.isOnchain(
                    walletApprovalTransactionDetail,
                  ) && (
                    <>
                      <Label>
                        {t('pendingTransactions.columns.recipient')}
                      </Label>
                      <Description>
                        <SkeletonBox className="h-6 w-48" loading={loading}>
                          {
                            walletApprovalTransactionDetail.recipientAddress
                              .description
                          }
                          <p>
                            {
                              walletApprovalTransactionDetail.recipientAddress
                                .address
                            }
                          </p>
                        </SkeletonBox>
                      </Description>
                    </>
                  )}

                  {/* Payout transfer destination */}
                  {TypeGuards.WalletApprovalTransactionDetail.isPayout(
                    walletApprovalTransactionDetail,
                  ) && (
                    <>
                      <Label>
                        {t('pendingTransactions.columns.destination')}
                      </Label>
                      <Description>
                        <SkeletonBox className="h-6 w-48" loading={loading}>
                          {walletApprovalTransactionDetail.fiatAccount.nickname}

                          {/* Fiat account specific description */}
                          {TypeGuards.BankAccount.isCbit(
                            walletApprovalTransactionDetail.fiatAccount,
                          ) ||
                          TypeGuards.BankAccount.isSignet(
                            walletApprovalTransactionDetail.fiatAccount,
                          ) ? (
                            <TruncatedText>
                              {
                                walletApprovalTransactionDetail.fiatAccount
                                  .walletAddress
                              }
                            </TruncatedText>
                          ) : (
                            <p>
                              {
                                walletApprovalTransactionDetail.fiatAccount
                                  .description
                              }
                            </p>
                          )}
                        </SkeletonBox>
                      </Description>
                    </>
                  )}

                  {/* Vault wallet destination */}
                  {TypeGuards.WalletApprovalTransactionDetail.isVaultWallet(
                    walletApprovalTransactionDetail,
                  ) && (
                    <>
                      <Label>
                        {t('pendingTransactions.columns.recipient')}
                      </Label>
                      <Description>
                        <SkeletonBox className="h-6 w-48" loading={loading}>
                          {walletApprovalTransactionDetail.vaultWalletAddress}
                        </SkeletonBox>
                      </Description>
                    </>
                  )}
                </DescriptionList>
              </div>
            </GraphQLErrorBoundary>
          </Card.Content>
        </Card>
      </Content>
    )
  }

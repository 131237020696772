import { accountSetupSection } from '@services/sections/lib/accountSetup'
import { LoadingSpinner } from '@shared/components/common'
import { useUserActivationQuery } from '@shared/graphql'

import { ActivationError } from '../ActivationError/ActivationError'

export interface ActivateEmailProps {
  /**
   * Circle authentication token.
   */
  token?: string
}

export const ActivateEmail: React.FC<ActivateEmailProps> = ({ token }) => {
  const { data, error } = useUserActivationQuery({
    variables: {
      token: token ?? '',
    },
  })

  if (data) {
    // If user activation is successful,
    // Redirect user to /account-setup
    // On /account-setup, they will be redirected to log in if not already logged in
    // We use location.replace to make sure the session is updated with accountType on /account-setup page
    window.location.replace(accountSetupSection.route)
  }

  if (error) {
    return <ActivationError />
  }

  return (
    <div className="flex w-full justify-center pt-22">
      <LoadingSpinner />
    </div>
  )
}

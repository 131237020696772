import { useCustomMoney } from '@circlefin/hooks'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useVaultWalletBalanceQuery } from '@shared/graphql'

import { AvailableBalanceText } from '../../../../../containers'
import { useSendOnChain } from '../../SendOnChain.Context'

import { DirectSourceAmountForm } from './DirectSourceAmount.Form'

export const DirectSourceAmount: React.FC = () => {
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const [{ assetWallet }] = useSendOnChain()
  const { data, error, refetch } = useVaultWalletBalanceQuery({
    variables: {
      vaultId: assetWallet?.vaultMetadata.id ?? '',
      walletId: assetWallet?.id ?? '',
    },
    skip: !assetWallet?.vaultMetadata.id || !assetWallet?.id,
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
  })

  return (
    <GraphQLErrorBoundary error={error} retry={refetch}>
      <AvailableBalanceText
        fiatBalance={
          data &&
          customMoney({
            number: data?.vaultWalletBalance.fiat.amount,
            currencyConfig: {
              name: data?.vaultWalletBalance.fiat.asset.symbol,
              decimals: data?.vaultWalletBalance.fiat.asset.decimals,
              isCryptoCurrency: true,
            },
            options: { padded: false },
          })
        }
        nativeBalance={
          data &&
          customMoney({
            number: data?.vaultWalletBalance.native.amount,
            currencyConfig: {
              name: data?.vaultWalletBalance.native.asset.symbol,
              decimals: data?.vaultWalletBalance.native.asset.decimals,
              isCryptoCurrency: true,
            },
            options: { padded: false },
          })
        }
      />
      <Center variant="horizontal">
        <div data-testid="direct-source-amount-form">
          <DirectSourceAmountForm availableBalance={data?.vaultWalletBalance} />
        </div>
      </Center>
    </GraphQLErrorBoundary>
  )
}

import { FixedBanner } from '@circlefin/components'
import { useSegment } from '@services/segment'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface BlockchainDepositZKSAlertProps {
  /**
   * Custom className.
   */
  className?: string
}

export const BlockchainDepositZKSAlert: React.FC<BlockchainDepositZKSAlertProps> =
  ({ className }) => {
    const { t } = useTranslation('payments')
    const { trackLinkClick } = useSegment()

    return (
      <FixedBanner
        className={className}
        data-testid="deposit-zks-alert"
        status="warning"
        multiline
        visible
      >
        <FixedBanner.Description>
          <div>
            <Trans
              components={{
                a: (
                  <a
                    href={t(
                      `transferInstructions.depositNativeUSDCOnlyWarning.chains.ZKS.warningLink`,
                    )}
                    onClick={trackLinkClick}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
              }}
              i18nKey="payments:transferInstructions.depositNativeUSDCOnlyWarning.chains.ZKS.warning"
            />
          </div>
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { vaultDetailsSection } from '@services/sections/lib/vaults'
import { VaultDocument, useDiscardDraftWalletMutation } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export interface DiscardDraftProps {
  /**
   * ID of the vault the wallet belongs to.
   */
  vaultId?: string
  /**
   * ID of the wallet that should be discarded.
   */
  vaultWalletId?: string
}
export const DiscardDraft: React.FC<DiscardDraftProps> = ({
  vaultId,
  vaultWalletId,
}) => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const router = useRouter()

  const handleCloseModal = useCallback(() => {
    modal.close()
  }, [modal])

  const [discardDraftWallet, { loading }] = useDiscardDraftWalletMutation({
    variables: {
      vaultId: vaultId ?? '',
      walletId: vaultWalletId ?? '',
    },
    onCompleted: () => {
      void router.push({
        pathname: vaultDetailsSection.route,
        query: {
          vaultId,
        },
      })
    },
    refetchQueries: [
      {
        query: VaultDocument,
        variables: {
          id: vaultId,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  const handleDiscardDraft = useCallback(() => {
    void discardDraftWallet()
  }, [discardDraftWallet])

  return (
    <BaseTitle title={t('vaultWalletDetails.discardDraft.title')}>
      <p className="text-center">{t`vaultWalletDetails.discardDraft.description`}</p>

      <Modal.Footer variant="stretch-equal">
        <Button
          disabled={loading}
          onClick={handleCloseModal}
          variant="secondary"
        >
          {t`common:cancel`}
        </Button>
        <Button
          loading={loading}
          onClick={handleDiscardDraft}
          variant="primary"
        >
          {t`vaultWalletDetails.discardDraft.action`}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

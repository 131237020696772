import { Card, DescriptionList } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CUSTODY_SETTINGS_MUTATION } from '@services/permissions'
import useTranslation from 'next-translate/useTranslation'

import { AuthenticatorSettingsPairDevice } from './AuthenticatorSettings.PairDevice'
import { AuthenticatorSettingsResetPin } from './AuthenticatorSettings.ResetPin'

export interface AuthenticatorSettingsProps {
  /**
   * Custom style.
   */
  className?: string
}

export const AuthenticatorSettings: React.FC<AuthenticatorSettingsProps> = ({
  className,
}) => {
  const { t } = useTranslation('settings')

  const [CustodySettingsMutation] = usePermission(CUSTODY_SETTINGS_MUTATION)

  return (
    <CustodySettingsMutation.Authorized>
      <Card className={className}>
        <Card.Content className="px-8 py-6">
          <div className="pb-5 text-xl font-circular-bold">
            {t`walletSecurity.authenticatorSettings.title`}
          </div>

          <DescriptionList density="loose">
            <AuthenticatorSettingsPairDevice />
            <AuthenticatorSettingsResetPin />
          </DescriptionList>
        </Card.Content>
      </Card>
    </CustodySettingsMutation.Authorized>
  )
}

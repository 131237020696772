import { DescriptionList } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CUSTODY_POLICY_SECTION } from '@services/permissions'
import { TypeGuards } from '@services/type-guards'
import { MaybeNull } from '@shared/components/common'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransactionDetail } from '../TransactionDetail/TransactionDetail'
import { WalletApprovalTransactionDetailApprovalSummary } from '../WalletApprovalTransactionDetailApprovalSummary/WalletApprovalTransactionDetailApprovalSummary'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type { RtpPaymentDetail as RtpPaymentDetailType } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface RtpPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  rtpPaymentDetail?: RtpPaymentDetailType
}

export const RtpPaymentDetail: React.FC<RtpPaymentDetailProps> = ({
  rtpPaymentDetail,
  error,
}) => {
  const { t } = useTranslation('payments')

  const isReceive = TypeGuards.Payments.isRtpReceiveType(rtpPaymentDetail)
  const [, { isAuthorized }] = usePermission(CUSTODY_POLICY_SECTION)

  const { data: { walletApprovalTransactionDetailByJobId } = {} } =
    useWalletApprovalTransactionDetailByJobIdQuery({
      variables: {
        jobId: rtpPaymentDetail?.payment.id ?? '',
      },
      skip: !rtpPaymentDetail?.payment.id || isReceive || !isAuthorized,
    })

  const bankDescriptionLabel = isReceive
    ? t('transactionDetail.fundingSource')
    : t('transactionDetail.destination')

  const bankAccount = isReceive
    ? rtpPaymentDetail?.source
    : rtpPaymentDetail?.destination

  return (
    <TransactionDetail error={error} transaction={rtpPaymentDetail?.payment}>
      {walletApprovalTransactionDetailByJobId && (
        <WalletApprovalTransactionDetailApprovalSummary
          {...walletApprovalTransactionDetailByJobId}
        />
      )}

      <Label>{bankDescriptionLabel}</Label>
      <Description>
        <MaybeNull>{bankAccount?.nickname}</MaybeNull>
        <p>
          <MaybeNull>{bankAccount?.description}</MaybeNull>
        </p>
      </Description>

      {isReceive && (
        <>
          <Label>{t('transactionDetail.referenceID')}</Label>
          <Description>{bankAccount?.trackingRef}</Description>
        </>
      )}
    </TransactionDetail>
  )
}

import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const MetamaskWarning: React.FC = () => {
  const { t } = useTranslation('modals.verite')

  return (
    <Center>
      <h1 className="p-8">{t('connect-wallet-error-msg')}</h1>
    </Center>
  )
}

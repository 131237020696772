import type React from 'react'
import { useMemo } from 'react'

import { Heading } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { HistoryBackButton } from '@containers/layout'
import {
  isDepositTransaction,
  isPayoutTransaction,
} from '@services/type-guards/BankAccount'
import {
  DepositCard,
  PayoutCard,
  currencyToToken,
} from '@shared/components/common'
import { Content } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'
import type { Deposit, Payout } from '@shared/graphql'

export interface FxTransactionDetailProps {
  /**
   * The transaction we want to render. Either a Payout or Deposit object.
   */
  transaction?: Payout | Deposit
  /**
   * Error from fetching transaction details.
   */
  error?: ApolloError
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}
export const FxTransactionDetail: React.FC<FxTransactionDetailProps> = ({
  transaction,
  error,
  children,
}) => {
  const { t } = useTranslation()
  const { money } = useMoney()
  const titleString = useMemo(() => {
    return isPayoutTransaction(transaction)
      ? 'payments:fxTransactionDetail.title.payout'
      : 'payments:fxTransactionDetail.title.deposit'
  }, [transaction])

  return (
    <Content>
      <HistoryBackButton />

      <Heading variant="page/sm">
        <Heading.Eyebrow>{t(titleString)}</Heading.Eyebrow>
        <Heading.Header>
          {transaction?.amount &&
            money({
              number: transaction.amount.amount,
              variant: currencyToToken(transaction.amount.currency),
              options: {
                symbol: true,
                signDisplay: false,
              },
            })}
        </Heading.Header>
        <Heading.Intro>
          {t('payments:transactionDetail.transactionId', {
            id: transaction?.id,
          })}
        </Heading.Intro>
      </Heading>

      {/* Content */}
      {isPayoutTransaction(transaction) && (
        <PayoutCard error={error} payout={transaction}>
          {children}
        </PayoutCard>
      )}

      {isDepositTransaction(transaction) && (
        <DepositCard deposit={transaction} error={error} />
      )}
    </Content>
  )
}

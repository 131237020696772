import type { ChangeEvent } from 'react'
import { useCallback } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useStates } from '@features/locales/hooks/states'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { CountryCode } from '@shared/graphql'

export const businessStateSchema = y.string().required()

const schema = y.object({
  /**
   * Business state code.
   */
  businessState: businessStateSchema,
})

export type BusinessStateFormValues = y.InferType<typeof schema>

export interface BusinessStateProps {
  /**
   * Selected country.
   */
  country: CountryCode
  /**
   * Force loading state.
   */
  loading?: boolean
}

export const BusinessState: React.FC<BusinessStateProps> = ({
  country,
  loading,
}) => {
  const { t } = useTranslation('onboard/account-setup')
  const { track } = useSegment()
  const [Form] = useForm<BusinessStateFormValues>({
    schema,
  })
  const states = useStates({
    country,
    link: { locatedIn: true },
  })

  const handleChange = useCallback(
    (value: SelectListItem<BusinessStateFormValues['businessState']>) => {
      track(SegmentEvents.DropdownSelected, {
        props: {
          field_name: 'Business state',
          value,
        },
      })
    },
    [track],
  )

  const handleBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      track(SegmentEvents.FieldEntered, {
        props: {
          field_name: 'Business state',
          value: event.target.value,
        },
      })
    },
    [track],
  )

  if (country == null) {
    return null
  }

  if (loading || states.loading) {
    return <SkeletonBox className="mt-6 h-10 w-full" />
  }

  if (states.data?.states && states.data?.states.codes.length > 0) {
    return (
      <Form.Dropdown
        data-testid={`dropdown-business-state-${country}`}
        items={states.states.toDropdown()}
        label={t(`confirm.form.businessState.${country}.label`)}
        name="businessState"
        onChange={handleChange}
        placeholder={t(`confirm.form.businessState.${country}.placeholder`)}
      />
    )
  }

  return (
    <Form.Input
      className="w-full"
      data-testid="other-business-state-input"
      label={t`confirm.form.businessState.other.label`}
      name="businessState"
      onBlur={handleBlur}
      placeholder={t`confirm.form.businessState.other.placeholder`}
    />
  )
}

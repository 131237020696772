import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Modal takeover.
 */
export const MODAL_TAKEOVER: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [SynthRole.SYNTH_TAKEOVER_ROLE],
}

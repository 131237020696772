import React from 'react'

import classNames from 'classnames'

import { VaultStep } from './VaultStep'

import type { VaultStepProps } from './VaultStep'

interface VaultStepperProps {
  /**
   * Current active step.
   */
  currentStep: number
  /**
   * Custom style.
   */
  className?: string
  /**
   * Children Steps.
   */
  children:
    | Array<React.ReactElement<VaultStepProps>>
    | React.ReactElement<VaultStepProps>
}

export const VaultStepper: React.FC<VaultStepperProps> & {
  Step: typeof VaultStep
} = ({ className, children, currentStep }) => {
  // convert children to array
  const childrenArray = Array.isArray(children) ? children : [children]

  const steps = childrenArray.map((step, index) => {
    return React.cloneElement(step, {
      ...step.props,
      key: index,
      stepIndex: index,
      isLast: index === childrenArray.length - 1,
      isActive: currentStep === index,
      isComplete: index < currentStep,
    })
  })

  return (
    <ol
      className={classNames(
        'flex items-center justify-center border-t border-b border-solid border-black-50 py-3',
        className,
      )}
      data-testid="progress-bar"
    >
      {steps}
    </ol>
  )
}

VaultStepper.Step = VaultStep

import { useMemo, useState } from 'react'

import { BankAccountType, Currency } from '@shared/graphql'

export interface BankAccountTypeByCurrencyProps {
  initialCurrency?: Currency
}

export const useBankAccountTypeByCurrency = ({
  initialCurrency = Currency.USD,
}: BankAccountTypeByCurrencyProps = {}) => {
  const [currency, setCurrency] = useState<Currency>(initialCurrency)

  const bankAccountType = useMemo(() => {
    switch (currency) {
      case Currency.EUR:
      case Currency.EURC:
        return [BankAccountType.wire]
      default:
        // Passing undefined to bank accounts query will show all available accounts
        return undefined
    }
  }, [currency])

  return {
    bankAccountType,
    setCurrency,
  }
}

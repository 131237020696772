import { support } from '@services/sections/lib/home'
import useTranslation from 'next-translate/useTranslation'

import { FeatureTourStep } from '../../FeatureTour.Step'

export const ContactSupport: React.FC = () => {
  const { t } = useTranslation('modals.common')

  return (
    <FeatureTourStep
      data-testid="contact-support"
      header={t`featureTour.support.header`}
      iconName="logo"
      link={{
        text: t('featureTour.support.linkText'),
        url: support.route,
        external: true,
      }}
      subHeader={t(`featureTour.support.subHeader`)}
    />
  )
}

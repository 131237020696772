import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import type { InternalRefetchQueriesInclude } from '@apollo/client'
import type { ButtonStyleProps } from '@circlefin/components/lib/Button'

export interface ApproveApprovalRequestProps extends Partial<ButtonStyleProps> {
  /**
   * Policy id.
   */
  proposalId: string
  /**
   * Refetch queries.
   */
  refetchQueries?: InternalRefetchQueriesInclude
}

export const ApproveApprovalRequest: React.FC<ApproveApprovalRequestProps> = ({
  proposalId,
  refetchQueries,
  ...styleProps
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <ModalLink
      pathname={routes.walletApprovalPolicy.approveApprovalRequest}
      query={{
        proposalId,
        refetchQueries,
      }}
    >
      <Button size="sm" variant="primary" {...styleProps}>
        {t('buttons.approveApprovalRequest')}
      </Button>
    </ModalLink>
  )
}

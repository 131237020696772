import { TypeGuards } from '@services/type-guards'

import { InstructionWithBankAccountDetails } from './WithBankAccountDetails'
import { InstructionsWithoutBankAccountDetails } from './WithoutBankAccountDetails'

import type {
  BankAccount,
  Currency,
  TransferInstruction,
  WireTransferInstruction,
} from '@shared/graphql'

export interface DepositInstructionGridProps {
  /**
   * Selected bank account.
   */
  bankAccount: BankAccount
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Transfer instruction.
   */
  transferInstruction?: TransferInstruction
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Is query loading.
   */
  loading?: boolean
}

export const DepositInstructionGrid: React.FC<DepositInstructionGridProps> = ({
  loading,
  bankAccount,
  currency,
  transferInstruction,
  virtualAccountEnabled = false,
}) => {
  if (TypeGuards.BankAccount.doesAccountHaveBankInformation(bankAccount)) {
    return (
      <InstructionWithBankAccountDetails
        currency={currency}
        loading={loading}
        transferInstruction={transferInstruction as WireTransferInstruction}
        virtualAccountEnabled={virtualAccountEnabled}
      />
    )
  } else {
    return (
      <InstructionsWithoutBankAccountDetails
        bankAccount={bankAccount}
        currency={currency}
        loading={loading}
        transferInstruction={transferInstruction}
        virtualAccountEnabled={virtualAccountEnabled}
      />
    )
  }
}

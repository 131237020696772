import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { CreateVaultProvider } from '@features/vault/hooks/create/provider'
import {
  ApprovalRequired,
  CybavoAppDownload,
  ChangePin,
  ChooseVaultType,
  ConfirmWalletActivationFee,
  CreateVault,
  CreateVaultWallet,
  Deposit,
  PairDevice,
  PairNewDevice,
  Report,
  ResetPin,
  Transfer,
  VaultDetails,
  VaultWalletDetails,
  WalletBackup,
  WalletConnect,
} from '@features/vault/modals'
import { vault } from '@services/sections/modal/vault/vault'

/**
 * Vault Router.
 */
export const VaultRouter: React.FC = () => {
  return (
    <>
      {/* Vault | Create Vault Routes */}
      <ModalSwitch path={vault.createVault.switch}>
        <CreateVaultProvider>
          <ModalRoute
            content={<CreateVault.Initialize />}
            path={vault.createVault.initialize}
          />
          <ModalRoute
            content={<CreateVault.PolicyName />}
            path={vault.createVault.policyName}
          />
          <ModalRoute
            content={<CreateVault.Permissions />}
            path={vault.createVault.permissions}
          />
          <ModalRoute
            content={<CreateVault.Limits.Policy />}
            path={vault.createVault.policyLimits}
          />
          <ModalRoute
            content={<CreateVault.Limits.User />}
            path={vault.createVault.userLimits}
          />
          <ModalRoute
            content={<CreateVault.OutgoingAddresses />}
            path={vault.createVault.outgoingAddress}
          />
          <ModalRoute
            content={<CreateVault.Approval />}
            path={vault.createVault.approval}
          />
          <ModalRoute
            content={<CreateVault.WalletConnect />}
            path={vault.createVault.walletConnect}
          />
          <ModalRoute
            content={<CreateVault.Policy />}
            path={vault.createVault.review}
          />
          <ModalRoute
            content={<CreateVault.Finished />}
            path={vault.createVault.finished}
          />
        </CreateVaultProvider>
      </ModalSwitch>

      {/* Vault Details | Learn More  */}
      <ModalRoute
        content={<VaultDetails.LearnMore />}
        path={vault.vaultDetails.learnMore}
      />

      {/* Vault | Confirm Wallet Activation Fee  */}
      <ModalRoute
        content={<ConfirmWalletActivationFee />}
        path={vault.confirmWalletActivationFee}
      />

      {/* Vault Details | Vault Submission Blocked  */}
      <ModalRoute
        content={<VaultDetails.VaultSubmissionBlocked />}
        path={vault.vaultDetails.submissionBlocked}
      />

      {/* Vault Details | Discard Draft Policy  */}
      <ModalRoute
        content={<VaultDetails.DiscardDraftPolicy />}
        path={vault.vaultDetails.discardDraftPolicy}
      />

      {/* Vault Details | Cancel Pending Approval  */}
      <ModalRoute
        content={<VaultDetails.CancelPendingApproval />}
        path={vault.vaultDetails.cancelPendingApproval}
      />

      {/* Vault Wallet Details | Confirm Archive */}
      <ModalRoute
        content={<VaultWalletDetails.Archive />}
        path={vault.vaultWalletDetails.archive}
      />

      {/* Vault Wallet Details | Unarchive */}
      <ModalRoute
        content={<VaultWalletDetails.Unarchive />}
        path={vault.vaultWalletDetails.unarchive}
      />

      {/* Vault Wallet Details | Discard Draft */}
      <ModalRoute
        content={<VaultWalletDetails.DiscardDraft />}
        path={vault.vaultWalletDetails.discardDraft}
      />

      {/* Vault | Create Vault Wallet Routes */}
      <ModalSwitch path={vault.createVaultWallet.switch}>
        <CreateVaultWallet.Provider>
          <ModalRoute
            content={<CreateVaultWallet.ChooseAsset />}
            path={vault.createVaultWallet.selectAsset}
          />
          <ModalRoute
            content={<CreateVaultWallet.ChooseBlockchain />}
            path={vault.createVaultWallet.selectBlockchain}
          />
          <ModalRoute
            content={<CreateVaultWallet.Confirm />}
            path={vault.createVaultWallet.confirm}
          />
          <ModalRoute
            content={<CreateVaultWallet.Success />}
            path={vault.createVaultWallet.success}
          />
        </CreateVaultWallet.Provider>
      </ModalSwitch>

      {/* Vault | Vault Deposit Routes */}
      <ModalSwitch path={vault.deposit.switch}>
        <Deposit.Provider>
          <ModalRoute
            content={<Deposit.ChooseDepositType />}
            path={vault.deposit.chooseDepositType}
          />
          <ModalSwitch path={vault.deposit.blockchain.switch}>
            <Deposit.Blockchain.Provider>
              <ModalRoute
                content={<Deposit.Blockchain.DepositDetails />}
                path={vault.deposit.blockchain.depositDetails}
              />
              <ModalRoute
                content={<Deposit.Blockchain.TransferInstructions />}
                path={vault.deposit.blockchain.transferInstructions}
              />
            </Deposit.Blockchain.Provider>
          </ModalSwitch>
        </Deposit.Provider>
      </ModalSwitch>

      {/* Vault | Vault Transfer Routes */}
      <ModalSwitch path={vault.transfer.switch}>
        <Transfer.Provider>
          <ModalRoute
            content={<Transfer.ChooseTransferType />}
            path={vault.transfer.chooseTransferType}
          />
          {/* Vault | Send On Chain */}
          <ModalSwitch path={vault.transfer.sendOnChain.switch}>
            <Transfer.SendOnChain.SendOnChainProvider>
              <ModalRoute
                content={<Transfer.SendOnChain.External.TransferRecipient />}
                path={vault.transfer.sendOnChain.recipientExternal}
              />
              <ModalRoute
                content={<Transfer.SendOnChain.Internal.TransferRecipient />}
                path={vault.transfer.sendOnChain.recipientInternal}
              />
              <ModalRoute
                content={<Transfer.SendOnChain.TransferAmount />}
                path={vault.transfer.sendOnChain.amount}
              />
              <ModalRoute
                content={<Transfer.SendOnChain.ReviewTransferDetails />}
                path={vault.transfer.sendOnChain.review}
              />
              <ModalRoute
                content={<ApprovalRequired />}
                path={vault.transfer.sendOnChain.finished}
              />
            </Transfer.SendOnChain.SendOnChainProvider>
          </ModalSwitch>
        </Transfer.Provider>
      </ModalSwitch>

      {/* Vault | Pair Device */}
      <ModalSwitch path={vault.pairDevice.switch}>
        <PairDevice.Provider>
          <ModalRoute
            content={<PairDevice.Initialize />}
            path={vault.pairDevice.initialize}
          />
          <ModalRoute
            content={<CybavoAppDownload />}
            path={vault.pairDevice.download}
          />
          <ModalRoute
            content={<PairDevice.Pair />}
            path={vault.pairDevice.pair}
          />
          <ModalRoute
            content={<PairDevice.Pin />}
            path={vault.pairDevice.pin}
          />
          <ModalRoute
            content={<PairDevice.Success />}
            path={vault.pairDevice.success}
          />
        </PairDevice.Provider>
      </ModalSwitch>

      {/* Vault | Pair New Device */}
      <ModalRoute
        content={<PairNewDevice.Confirmation />}
        path={vault.pairNewDevice.confirmation}
      />
      <ModalRoute
        content={<CybavoAppDownload />}
        path={vault.pairNewDevice.download}
      />
      <ModalRoute
        content={<PairNewDevice.Pair />}
        path={vault.pairNewDevice.pair}
      />

      {/* Vault | Change Pin */}
      <ModalRoute
        content={<ChangePin.Confirmation />}
        path={vault.changePin.confirmation}
      />
      <ModalRoute
        content={<ChangePin.Success />}
        path={vault.changePin.success}
      />

      {/* Vault | Reset Pin */}
      <ModalSwitch path={vault.resetPin.switch}>
        <ResetPin.Provider>
          <ModalRoute
            content={<ResetPin.Confirmation />}
            path={vault.resetPin.confirmation}
          />
          <ModalRoute
            content={<ResetPin.UploadBackup />}
            path={vault.resetPin.uploadBackup}
          />
          <ModalRoute
            content={<ResetPin.EnterPassphrase />}
            path={vault.resetPin.enterPassphrase}
          />
          <ModalRoute content={<ResetPin.Pin />} path={vault.resetPin.pin} />
          <ModalRoute
            content={<ResetPin.Success />}
            path={vault.resetPin.success}
          />
        </ResetPin.Provider>
      </ModalSwitch>

      {/* Vault | Wallet Backup */}
      <ModalSwitch path={vault.walletBackup.switch}>
        <WalletBackup.Provider>
          <ModalRoute
            content={<WalletBackup.Confirmation />}
            path={vault.walletBackup.confirmation}
          />
          <ModalRoute
            content={<WalletBackup.CreatePassphrase />}
            path={vault.walletBackup.createPassphrase}
          />
          <ModalRoute
            content={<WalletBackup.Pin />}
            path={vault.walletBackup.pin}
          />
          <ModalRoute
            content={<WalletBackup.Download />}
            path={vault.walletBackup.download}
          />
          <ModalRoute
            content={<WalletBackup.Success />}
            path={vault.walletBackup.success}
          />
          <ModalRoute
            content={<WalletBackup.Error />}
            path={vault.walletBackup.error}
          />
        </WalletBackup.Provider>
      </ModalSwitch>

      {/* Vault | WalletConnect */}
      <ModalRoute
        content={<WalletConnect.Overview />}
        path={vault.walletConnect.overview}
      />
      <ModalRoute
        content={<WalletConnect.Confirmation />}
        path={vault.walletConnect.confirmation}
      />

      {/* Vault | Download Vault Transactions Report */}
      <ModalRoute
        content={<Report.DownloadTransactionsReport />}
        path={vault.downloadTransactionsReport}
      />

      {/* Vault | Choose Vault Type */}
      <ModalRoute content={<ChooseVaultType />} path={vault.chooseVaultType} />
    </>
  )
}

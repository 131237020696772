import { FeatureSwitch } from '@services/feature-switch'
import {
  billing,
  checkoutWidget,
  delayedWithdrawalsSection,
  fraudManagement,
  ipAllowListingSection,
  limits,
  linkedAccounts,
  manageUsers,
  personalInfo,
  walletSecurity,
  verite,
  adminApprovalsSection,
  loginAndSecuritySection,
} from '@services/sections/lib/settings'

import type { GroupProps } from '../../MainNav/MainNav.Groups/Group/Group'

/**
 * Settings Groups.
 */
export const groups: GroupProps[] = [
  {
    header: 'layout:nav.settings.profile',
    items: [personalInfo, verite],
  },
  {
    header: 'layout:nav.settings.organization',
    items: [manageUsers, billing],
  },
  {
    header: 'layout:nav.settings.transactions',
    items: [linkedAccounts, limits],
  },
  {
    header: 'layout:nav.settings.merchant-payments',
    items: [checkoutWidget],
  },
  {
    header: 'layout:nav.settings.security',
    items: [
      ...(FeatureSwitch.pinCodeResetEnabled() ? [loginAndSecuritySection] : []),
      ipAllowListingSection,
      delayedWithdrawalsSection,
      adminApprovalsSection,
      fraudManagement,
      walletSecurity,
    ],
  },
]

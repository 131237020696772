import { Modal } from '@circlefin/components'
import classNames from 'classnames'

export interface BaseLayoutProps
  extends Pick<React.ComponentProps<typeof Modal.Body>, 'variant'> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom class.
   */
  className?: string
}

/**
 * Base Modal Layout.
 */
export const Base: React.FC<BaseLayoutProps> = ({
  children,
  className,
  variant,
}) => {
  return (
    <>
      <Modal.Close />
      <Modal.Body
        className={classNames('min-h-10', className)}
        variant={variant}
      >
        {children}
      </Modal.Body>
    </>
  )
}

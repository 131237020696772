import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import useTranslation from 'next-translate/useTranslation'

export const ThirdPartyWarning: React.FC = () => {
  const { t } = useTranslation('address-book/modals')
  const modal = useModal()

  const handleUnderstand = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <FullScreen onCloseClick={handleUnderstand} hideHeader>
      <Statement
        status="warning"
        subtitle={
          <span className="block w-120">
            {t(`thirdPartyWarning.description`)}
          </span>
        }
        title={t`thirdPartyWarning.title`}
        variant="page"
      >
        <Button onClick={handleUnderstand} variant="primary">
          {t`thirdPartyWarning.btn`}
        </Button>
      </Statement>
    </FullScreen>
  )
}

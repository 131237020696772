import { useCallback } from 'react'

import { Button, Icon } from '@circlefin/components'
import classNames from 'classnames'

import { HeaderPublic } from '../../HeaderPublic/HeaderPublic'

export interface StepperHeaderProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Hide back btn.
   */
  hideBackBtn?: boolean
  /**
   * Function that will be called when the back icon is clicked.
   */
  onBack?: () => void
}

export const Header: React.FC<StepperHeaderProps> = ({
  className,
  hideBackBtn = false,
  onBack,
}) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      onBack?.()
    },
    [onBack],
  )

  return (
    <div className={classNames('relative', className)}>
      <HeaderPublic size="tall">
        <HeaderPublic.Logo align="center" variant="full" />
      </HeaderPublic>
      {!hideBackBtn && (
        <Button
          className="absolute left-0 top-1/2 -mt-5 ml-5 flex items-center justify-center rounded-full p-3 text-black-600 hover:bg-black-50"
          data-testid="back-icon"
          onClick={onClick}
          variant="text"
        >
          <Icon className="size-4" name="ChevronLeftOutline" />
        </Button>
      )}
    </div>
  )
}

Header.displayName = 'Stepper.Header'

import { DescriptionList } from '@circlefin/components'
import { TypeGuards } from '@services/type-guards'
import { MaybeNull } from '@shared/components/common'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { Fees } from '../../components'
import { TransactionDetail } from '../TransactionDetail/TransactionDetail'
import { WalletApprovalTransactionDetailApprovalSummary } from '../WalletApprovalTransactionDetailApprovalSummary/WalletApprovalTransactionDetailApprovalSummary'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type { SepaPaymentDetail as SepaPaymentDetailType } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface SepaPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  sepaPaymentDetail?: SepaPaymentDetailType
}

export const SepaPaymentDetail: React.FC<SepaPaymentDetailProps> = ({
  sepaPaymentDetail,
  error,
}) => {
  const { t } = useTranslation('payments')

  const isReceive = TypeGuards.Payments.isSepaReceiveType(sepaPaymentDetail)

  const { data: { walletApprovalTransactionDetailByJobId } = {} } =
    useWalletApprovalTransactionDetailByJobIdQuery({
      variables: {
        jobId: sepaPaymentDetail?.payment.id ?? '',
      },
      skip: !sepaPaymentDetail?.payment.id || isReceive,
    })

  const bankDescriptionLabel = isReceive
    ? t('transactionDetail.fundingSource')
    : t('transactionDetail.destination')

  const bankAccount = isReceive
    ? sepaPaymentDetail?.source
    : sepaPaymentDetail?.destination

  return (
    <TransactionDetail error={error} transaction={sepaPaymentDetail?.payment}>
      {walletApprovalTransactionDetailByJobId && (
        <WalletApprovalTransactionDetailApprovalSummary
          {...walletApprovalTransactionDetailByJobId}
        />
      )}

      <Label>{bankDescriptionLabel}</Label>
      <Description>
        <MaybeNull>{bankAccount?.nickname}</MaybeNull>
        <p>
          <MaybeNull>{bankAccount?.description}</MaybeNull>
        </p>
      </Description>
      {sepaPaymentDetail?.fees && <Fees fees={sepaPaymentDetail?.fees} />}

      {isReceive && (
        <>
          <Label>{t('transactionDetail.referenceID')}</Label>
          <Description>{bankAccount?.trackingRef}</Description>
        </>
      )}
    </TransactionDetail>
  )
}

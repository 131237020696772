import { AccessPermissions } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Custody StableCoin Section Permissions.
 */
export const STABLECOIN_SECTION: PermissionSet = {
  access: FeatureSwitch.stableCoinExpressCustodyDashboardEnabled()
    ? AccessPermissions.protected
    : AccessPermissions.restricted,
}

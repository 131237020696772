import { Icon } from '@circlefin/components'
import classNames from 'classnames'

import { Center } from '../../layout'

// TODO: https://circlepay.atlassian.net/browse/CF-515
// eslint-disable-next-line import/extensions
import type { IconCircleProps } from '@circlefin/components/lib/Icon/Icon.Circle/Icon.Circle'

export interface IconNoticeProps extends Omit<IconCircleProps, 'size'> {
  /**
   * Title of the modal.
   */
  title?: React.ReactNode
  /**
   * Subtitle message.
   */
  subtitle?: React.ReactNode
  /**
   * Notice size.
   */
  size?: keyof typeof sizes
}

interface IconNoticeSize {
  /**
   * Width and Height of the icon.
   */
  iconSize: IconCircleProps['size']
  /**
   * Text size.
   */
  textSize: string
}

/**
 * Notice size options.
 */
const sizes: Record<'small' | 'default' | 'large', IconNoticeSize> = {
  small: {
    iconSize: 'small',
    textSize: 'text-base',
  },
  default: {
    iconSize: 'default',
    textSize: 'text-base',
  },
  large: {
    iconSize: 'large',
    textSize: 'text-lg',
  },
}

export const IconNotice: React.FC<IconNoticeProps> = ({
  title,
  subtitle,
  size = 'default',
  ...icon
}) => {
  const { iconSize, textSize } = sizes[size]

  return (
    <Center
      className="size-full text-center font-circular"
      data-testid={`icon-notice-${size}`}
    >
      <Center className="mb-6">
        <Icon.Circle size={iconSize} {...icon} />
      </Center>

      {title != null && (
        <h3
          className={classNames(
            'font-circular-medium leading-7 text-black-600',
            textSize,
          )}
          data-testid="title"
        >
          {title}
        </h3>
      )}
      {subtitle != null && (
        <p
          className={classNames(
            'font-circular-regular leading-6 text-black-600',
            textSize,
          )}
          data-testid="subtitle"
        >
          {subtitle}
        </p>
      )}
    </Center>
  )
}

import { Icon, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { Currency } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../Create'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

import type { VaultPolicyApprovalLevel } from '@shared/graphql'

const { Label, Description } = DescriptionList

export const ApproverLevels: React.FC = () => {
  const { t } = useTranslation('vaults')
  const formatMoney = useMoney({ locale: 'en-US' }).money

  const {
    condensed,
    approvalWorkflow = [],
    isWalletConnectPolicyEnabled,
    walletConnectPolicy,
  } = usePolicySummaryContext()

  const renderApprovalWorkflow = (
    approvalWorkflow: VaultPolicyApprovalLevel[],
  ) => {
    return approvalWorkflow.map((level, index) => (
      <List.Item key={index}>
        <List.Item.Title stepName={CreateVaultNavigationStep.APPROVAL}>
          {t('vault.policy.summary.approvers.level', {
            num: index + 1,
          })}
        </List.Item.Title>
        <List.Item.Subtitle>
          <div className="grid grid-cols-1">
            <div className="flex items-center gap-x-2 text-base leading-6 text-neutral-strong">
              {t('vault.policy.summary.approvers.transferAmount', {
                amount: formatMoney({
                  number: level.transferAmount,
                  variant: Currency.USD,
                }),
              })}
              <Icon className="inline-block" name="EllipseSolid" size={4} />

              {t('vault.policy.summary.approvers.required', {
                count: Number(level.minNeedConfirmed),
              })}
            </div>
            <div>
              {t('vault.policy.summary.approvers.name', {
                names: level.approvers
                  .map((approver) => approver.name)
                  .join(', '),
              })}
            </div>
          </div>
        </List.Item.Subtitle>
      </List.Item>
    ))
  }

  const hasApprovalWorkflow = approvalWorkflow.length > 0
  const hasWalletConnectApprovalWorkflow =
    isWalletConnectPolicyEnabled &&
    Number(walletConnectPolicy?.minNeedConfirmed) > 0

  if (!hasApprovalWorkflow && !hasWalletConnectApprovalWorkflow) {
    return null
  }

  return (
    <>
      <Label className="h-full">
        {t('vault.policy.summary.labels.approvalSteps')}
      </Label>
      <Description
        className={classNames('flex flex-col gap-y-4', {
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="approval-steps"
      >
        {hasApprovalWorkflow && (
          <>
            <List>
              <List.Item>
                <List.Item.Title
                  className="font-circular-bold"
                  stepName={CreateVaultNavigationStep.APPROVAL}
                  showEdit
                >
                  {t('vault.policy.summary.labels.transfers')}
                </List.Item.Title>
              </List.Item>
            </List>
            <List>{renderApprovalWorkflow(approvalWorkflow)}</List>
          </>
        )}
        {hasApprovalWorkflow && hasWalletConnectApprovalWorkflow && (
          <hr className="mb-4 h-px border-black-100" />
        )}
        {hasWalletConnectApprovalWorkflow && (
          <>
            <List>
              <List.Item>
                <List.Item.Title
                  className="font-circular-bold"
                  stepName={CreateVaultNavigationStep.WALLET_CONNECT}
                  showEdit
                >
                  {t('vault.policy.summary.labels.contractInteractions')}
                </List.Item.Title>
                <List.Item.Subtitle>
                  <div className="grid grid-cols-1">
                    <div className="flex items-center gap-x-2 text-base leading-6 text-neutral-strong">
                      {t('vault.policy.summary.approvers.required', {
                        count: Number(walletConnectPolicy?.minNeedConfirmed),
                      })}
                    </div>
                    <div>
                      {t('vault.policy.summary.approvers.name', {
                        names: walletConnectPolicy?.approvers
                          .map((approver) => approver.name)
                          .join(', '),
                      })}
                    </div>
                  </div>
                </List.Item.Subtitle>
              </List.Item>
            </List>
          </>
        )}
      </Description>
    </>
  )
}

import Head from 'next/head'

export const Meta: React.FC = () => {
  return (
    <Head>
      <meta key="charSet" charSet="utf-8" />
      <meta
        key="viewport"
        content="initial-scale=1.0, width=device-width"
        name="viewport"
      />
      <meta key="og:image" content="/logo.svg" property="og:image" />
    </Head>
  )
}

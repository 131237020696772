import type { ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { Icon } from '@circlefin/components'
import classNames from 'classnames'

type Variant = 'info' | 'warning' | 'alert' | 'disabled'

export interface CollapsibleBadgeProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Specify which variant should be displayed.
   */
  variant: Variant
  /**
   * Specify the text that should be displayed; when the Badge is collapsed,
   * This should be shorter than the original text.
   */
  collapsedText: ReactNode
}

export const CollapsibleBadge: React.FC<CollapsibleBadgeProps> = ({
  children,
  collapsedText,
  variant,
}) => {
  const [collapsed, setCollapsed] = useState(true)

  const handleClick = useCallback(() => {
    setCollapsed((prev) => !prev)
  }, [])

  return (
    <div
      className={classNames(
        'inline-flex justify-center items-center px-3 py-2 rounded-lg space-x-3',
        {
          'bg-blue-75 text-blue-500': variant === 'info',
          'bg-yellow-75 text-yellow-600': variant === 'warning',
          'bg-red-75 text-red-600': variant === 'alert',
          'bg-black-50 text-black-400': variant === 'disabled',
        },
      )}
    >
      <span className="text-xs leading-none font-circular-regular">
        {collapsed ? (
          <span className="font-circular-book">{collapsedText}</span>
        ) : (
          children
        )}
      </span>
      <button className="hover:opacity-80">
        <Icon
          className={classNames({
            'text-blue-500': variant === 'info',
            'text-yellow-400': variant === 'warning',
            'text-red-500': variant === 'alert',
            'text-black-400': variant === 'disabled',
          })}
          name={collapsed ? 'InformationCircleSolid' : 'XCircleSolid'}
          onClick={handleClick}
          size={16}
        />
      </button>
    </div>
  )
}

import { useCallback, useMemo } from 'react'

import { Icon, Table, TruncatedText } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { linkedAccountDetail } from '@services/sections/lib/settings'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import { BankAccountStatus } from '@shared/graphql'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount } from '@shared/graphql'

interface LinkedBankAccountTableRowProps {
  /**
   * Bank account to display.
   */
  bankAccount: BankAccount
}

export const BankAccountTableRow: React.FC<LinkedBankAccountTableRowProps> = ({
  bankAccount,
}) => {
  const { id, status, createDate, nickname, type } = bankAccount

  const router = useRouter()
  const { t } = useTranslation('settings')
  const { date } = useDate()
  const modal = useModal()

  const isWireAccount = TypeGuards.BankAccount.isWire(bankAccount)
  const isSignetAccount = TypeGuards.BankAccount.isSignet(bankAccount)
  const isCbitAccount = TypeGuards.BankAccount.isCbit(bankAccount)
  const isXpayAccount = TypeGuards.BankAccount.isXpay(bankAccount)
  const isRtpAccount = TypeGuards.BankAccount.isRtp(bankAccount)

  const showSendTestDepositBtn =
    bankAccount.status === BankAccountStatus.pending &&
    ((isWireAccount && !bankAccount?.userConfirmedWireSend) ||
      isSignetAccount ||
      isCbitAccount ||
      isXpayAccount)

  const wireRtpBankAccountBillingLabel = useMemo(() => {
    if (!(isWireAccount || isRtpAccount)) {
      return
    }

    return [
      bankAccount?.billingDetails?.name,
      bankAccount?.billingDetails?.city,
    ]
      .filter((str) => str)
      .join(', ')
  }, [bankAccount, isWireAccount, isRtpAccount])

  const rowClick = useCallback(() => {
    void router.push({
      pathname: linkedAccountDetail.route,
      query: {
        id,
        type: bankAccount.type.toLowerCase(),
      },
    })
  }, [bankAccount.type, id, router])

  const onShowSendTestDepositBtnClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation()
        modal.router.push({
          pathname: routes.transfer.wire.testTransferLanding,
          query: {
            bankAccount,
          },
        })
      },
      [bankAccount, modal.router],
    )

  const onAddNickname: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation()
      modal.router.push({
        pathname: routes.bankAccount.setNickname,
        query: {
          bankAccount,
        },
      })
    },
    [modal, bankAccount],
  )

  const description =
    isSignetAccount || isCbitAccount ? (
      <TruncatedText>{bankAccount.walletAddress ?? ''}</TruncatedText>
    ) : (
      bankAccount.description
    )

  return (
    <Table.Body.Row
      className="cursor-pointer"
      data-testid="account-table-row"
      onClick={rowClick}
    >
      <Table.Body.Cell className="mt-4 text-neutral-strong type-body-sm">
        <div className="flex flex-row items-center justify-start">
          <div className="mr-6">
            <Icon name="LibraryOutline" size={18} />
          </div>
          <div className="flex flex-col items-start justify-center">
            <div className="type-body-sm">
              {nickname ?? (
                <PaymentButtons.BankAccount
                  className="-ml-1"
                  onClick={onAddNickname}
                  size="sm"
                  variant="text"
                >
                  {t('linkedAccounts.table.addNickname')}
                </PaymentButtons.BankAccount>
              )}
            </div>
            <div
              className={classnames(
                'whitespace-nowrap text-neutral-subtlest type-body-sm',
                {
                  'w-44': isSignetAccount || isCbitAccount,
                },
              )}
            >
              {description}
            </div>
          </div>
        </div>
      </Table.Body.Cell>
      <Table.Body.Cell className="text-neutral-strong">
        {t(`linkedAccounts.table.types.${type}`)}
      </Table.Body.Cell>
      <Table.Body.Cell className="text-neutral-strong">
        <div className="w-44 truncate">{wireRtpBankAccountBillingLabel}</div>
      </Table.Body.Cell>
      <Table.Body.Cell>
        <div className="text-neutral-strong type-body-sm">
          {t(`linkedAccounts.table.bankAccount.${status}`)}
        </div>
        <div>
          {showSendTestDepositBtn ? (
            <PaymentButtons.BankWires.Deposit
              className="-ml-1"
              data-testid="account-table-send-deposit"
              onClick={onShowSendTestDepositBtnClick}
              size="sm"
              variant="text"
            >
              {t`linkedAccounts.sendDeposit`}
            </PaymentButtons.BankWires.Deposit>
          ) : (
            <span
              className="whitespace-nowrap text-neutral-subtlest type-body-sm"
              data-testid="account-table-date"
            >
              <Trans
                i18nKey="settings:linkedAccounts.table.addedDate"
                values={{ date: date(createDate, 'date') }}
              />
            </span>
          )}
        </div>
      </Table.Body.Cell>
      <Table.Body.Cell className="text-neutral-strong">
        <div className="flex items-center justify-between">
          <Icon
            className="text-icon-neutral-strong"
            name="ChevronRightSolid"
            size={20}
          />
        </div>
      </Table.Body.Cell>
    </Table.Body.Row>
  )
}

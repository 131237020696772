import { useMemo } from 'react'

import { Icon } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { NewAddressText } from '@features/delayed-withdrawals/containers'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { TransferRecipientForm } from './TransferRecipient.Form'

export const TransferRecipient: React.FC = () => {
  const { t } = useTranslation('modals.blockchain')

  const { travelRuleRequired } = useTravelRuleRequired()

  const addNewAddressLink = useMemo(
    () =>
      travelRuleRequired
        ? routes.account.addressBook.addNewAddress.ownershipDisclaimer
        : routes.account.addressBook.addNewAddress.selectRecipient,
    [travelRuleRequired],
  )

  return (
    <FullScreen totalSteps={5}>
      <Center className="font-circular" variant="horizontal">
        <h2 className="mb-2 w-176 text-center text-2xl text-black-600 font-circular-bold">
          {t('sendOnchain.transferRecipient.title')}
        </h2>

        <Center className="text-center" variant="horizontal">
          <h3 className="text-lg leading-6 text-black-400">
            {t('sendOnchain.transferRecipient.subtitle')}
          </h3>
        </Center>

        <Center className="mt-8" variant="horizontal">
          <div className="w-104">
            <TransferRecipientForm />
          </div>
        </Center>

        <Center className="mt-16" variant="horizontal">
          <h4 className="pb-4 text-center leading-6 text-black-600 font-circular-bold">{t`sendOnchain.transferRecipient.addNew.header`}</h4>
        </Center>

        <Center>
          <div className="flex w-176 justify-between border-t border-black-50 pt-4">
            <div className="flex flex-col">
              <h5 className="leading-6 text-black-600 font-circular-bold">{t`sendOnchain.transferRecipient.addNew.title`}</h5>
              <NewAddressText />
            </div>
            <span>
              <ModalLink
                data-testid="add-new-address-link"
                pathname={addNewAddressLink}
              >
                {t`sendOnchain.transferRecipient.addNew.action`}
                <Icon className="ml-2 inline" name="ChevronRightSolid" />
              </ModalLink>
            </span>
          </div>
        </Center>
      </Center>
    </FullScreen>
  )
}

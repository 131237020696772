/**
 * MFA Routes.
 */
export const mfa = {
  code: '/mfa/code',
  success: '/mfa/success',
  error: '/mfa/error',
  setupTotp: '/mfa/setupTotp',
  setupOktaVerify: '/mfa/setupOktaVerify',
  updatePinCode: '/mfa/updatePinCode',
  resetPinCode: '/mfa/resetPinCode',
}

import { useCallback } from 'react'

import { useCountries } from '@features/locales/hooks/countries'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'
import { isEeaUser } from '../../../hooks/rolr/utils'

import { RolrBizInfoForm } from './RolrBizInfo.Form'

import type { RolrBizInfoFormValues } from './RolrBizInfo.Form'

export const RolrBizInfo: React.FC = () => {
  const { t } = useTranslation('onboard/rolr-signup')
  const {
    data: { countries } = {},
    loading,
    error,
    refetch,
  } = useCountries({
    onboarding: {
      eea: true,
    },
  })

  const [, { computeNextStep }] = useRolrSetup()

  const complete = useCallback(
    ({
      primaryPlaceOfBusiness,
      countryOfIncorporation,
      ...rest
    }: RolrBizInfoFormValues) => {
      // Fallback to an empty array, but the fallback will never be used
      // Because we always wait for the query to resolve before releasing the form from the loading state
      const eeaCountryCodes = countries?.codes ?? []

      computeNextStep({
        step: 'businessInfo',
        values: {
          ...rest,
          isEeaUser: isEeaUser(
            { primaryPlaceOfBusiness, countryOfIncorporation },
            eeaCountryCodes,
          ),
          primaryPlaceOfBusiness,
          countryOfIncorporation,
        },
      })
    },
    [computeNextStep, countries?.codes],
  )

  return (
    <div className="h-full w-96">
      <h1 className="type-h-page-sm">{t`bizInfo.title`}</h1>
      <p className="mt-1 text-neutral-subtle type-intro-sm">{t`bizInfo.desc`}</p>
      <GraphQLErrorBoundary error={error} retry={refetch}>
        <RolrBizInfoForm
          className="mt-8"
          loading={loading}
          onSubmit={complete}
        />
      </GraphQLErrorBoundary>
    </div>
  )
}

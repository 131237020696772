import type { ComponentType, FunctionComponent, ReactElement } from 'react'

import { PageError } from '@shared/components/errors'

import type { ApolloError } from '@apollo/client'
import type { NextPage } from 'next'

export interface AppComponentProps {
  error?: ApolloError
}

export type NextPageWithLayout = NextPage<AppComponentProps> &
  AppPageProps & {
    hideProgressBar: boolean // Make hideProgressBar required since we actively set a default value in AppPage if it is not specified.
  }

export interface AppPageProps {
  /**
   * Function to render the layout around passed children (page).
   */
  getLayout: (page: ReactElement) => JSX.Element
  /**
   * The variant of error page that should be displayed for this page.
   * If 'skip', the page itself will have to handle the SSR errors.
   */
  errorPageVariant?: 'default' | 'not-found' | 'refresh' | 'skip'
  /**
   * Specify if the progress bar should be hidden.
   */
  hideProgressBar?: boolean
}

/**
 * Entry point for rendering pages with metadata, shared layout components,
 * and behavior for permission checks.
 */
export const AppPage = (
  PageComponent:
    | ComponentType<AppComponentProps>
    | FunctionComponent<AppComponentProps>,
  { getLayout, errorPageVariant = 'default', hideProgressBar }: AppPageProps,
): NextPageWithLayout => {
  function Component({ error, ...props }: AppComponentProps) {
    return error && errorPageVariant !== 'skip' ? (
      <PageError error={error} variant={errorPageVariant} />
    ) : (
      <PageComponent {...props} error={error} />
    )
  }

  Component.getLayout = getLayout
  Component.errorPageVariant = errorPageVariant
  Component.hideProgressBar = hideProgressBar ?? false

  return Component
}

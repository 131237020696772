import { SkeletonBox } from '@circlefin/components'
import { MaybeNull } from '@shared/components/common'
import { useCardPaymentMethodByIdQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'

export interface CardProps {
  /**
   * Card ID.
   */
  cardId: string
}

export const Card: React.FC<CardProps> = ({ cardId }) => {
  const { data, loading } = useCardPaymentMethodByIdQuery({
    variables: {
      id: cardId,
    },
  })

  return (
    <div className="flex justify-start">
      <SkeletonBox loading={loading}>
        <div className="flex flex-col">
          <div className="text-sm leading-5 font-circular-book">
            <MaybeNull>
              <Trans
                i18nKey="modals/settings/billing:invoice.selectPaymentMethod.linkedCards.cardTitle"
                values={{
                  cardName: data?.cardPaymentMethodById?.network,
                  last4: data?.cardPaymentMethodById?.last4,
                }}
              />
            </MaybeNull>
          </div>
          <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular">
            <MaybeNull>
              <Trans
                i18nKey="modals/settings/billing:invoice.selectPaymentMethod.linkedCards.expiry"
                values={{
                  expMonth: data?.cardPaymentMethodById?.expMonth,
                  expYear: data?.cardPaymentMethodById?.expYear,
                }}
              />
            </MaybeNull>
          </div>
        </div>
      </SkeletonBox>
    </div>
  )
}

import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BankAccountsDocument,
  useAddXpayBankAccountMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'

import { useLinkBankAccount } from '../../../Link.Context'

import type { ApolloError } from '@apollo/client'
import type {
  AddXpayBankAccountMutationVariables,
  Maybe,
} from '@shared/graphql'

export const useLinkXpay = () => {
  const modal = useModal()

  const [{ information }] = useLinkBankAccount()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createMutation, mutation] = useAddXpayBankAccountMutation({
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error: ApolloError) => {
      mfaError({ error })
    },
    refetchQueries: [BankAccountsDocument],
  })

  const variables = useMemo((): Maybe<AddXpayBankAccountMutationVariables> => {
    if (information.xpay) {
      return {
        input: {
          ...information.xpay,
        },
      }
    }
    return null
  }, [information.xpay])

  const create = useCallback(() => {
    if (variables !== null) {
      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createMutation({
            variables,
            context: context(code),
          })
        },
        onAbort: () => {
          modal.close()
        },
      })
    }
  }, [context, createMutation, modal, variables, withMfa])

  return [create, mutation] as const
}

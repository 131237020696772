import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import { Currency } from '@shared/graphql'

import type {
  AddAddressDefaultCustomerValues,
  AddAddressDefaultNonCustomerValues,
} from './AddressDetails/Default'
import type {
  AddAddressMultiAssetCustomerValues,
  AddAddressMultiAssetNonCustomerValues,
} from './AddressDetails/MultiAsset'

export interface AddAddressState {
  /**
   * Customer form values (multi-asset).
   */
  multiAssetCustomer?: AddAddressMultiAssetCustomerValues
  /**
   * Non customer form values (multi-asset).
   */
  multiAssetNonCustomer?: AddAddressMultiAssetNonCustomerValues
  /**
   * Customer form values (single-asset).
   */
  defaultCustomer?: AddAddressDefaultCustomerValues
  /**
   * Non customer form values (single-asset).
   */
  defaultNonCustomer?: AddAddressDefaultNonCustomerValues
}

interface AddAddressActions {
  /**
   * Updates customer state (multi-asset).
   */
  setMultiAssetCustomer: (values: AddAddressMultiAssetCustomerValues) => void
  /**
   * Updates non customer state (multi-asset).
   */
  setMultiAssetNonCustomer: (
    values: AddAddressMultiAssetNonCustomerValues,
  ) => void
  /**
   * Updates customer state (multi-asset).
   */
  setDefaultCustomer: (values: AddAddressDefaultCustomerValues) => void
  /**
   * Updates non customer state (multi-asset).
   */
  setDefaultNonCustomer: (values: AddAddressDefaultNonCustomerValues) => void
}

export const DEFAULT_VALUES: AddAddressState = {
  multiAssetCustomer: {
    nickname: '',
    address: '',
    asset: [],
  },
  multiAssetNonCustomer: {
    recipientName: '',
    recipientEmail: '',
    address: '',
    asset: [],
  },
  defaultCustomer: {
    nickname: '',
    address: '',
    asset: Currency.USD,
  },
  defaultNonCustomer: {
    recipientName: '',
    recipientEmail: '',
    address: '',
    asset: Currency.USD,
  },
}

const CreateVaultWalletContext = createContext<
  [AddAddressState, AddAddressActions]
>([
  DEFAULT_VALUES,
  {
    setMultiAssetCustomer: () => null,
    setMultiAssetNonCustomer: () => null,
    setDefaultCustomer: () => null,
    setDefaultNonCustomer: () => null,
  },
])

interface AddAddressProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<AddAddressState>
}

export const Provider: React.FC<AddAddressProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<AddAddressState>({
    ...initValues,
  })

  const handleValueChange = useCallback((values: Partial<AddAddressState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  const actions: AddAddressActions = useMemo(
    () => ({
      setMultiAssetCustomer: (values) =>
        handleValueChange({ multiAssetCustomer: values }),
      setMultiAssetNonCustomer: (values) =>
        handleValueChange({ multiAssetNonCustomer: values }),
      setDefaultCustomer: (values) =>
        handleValueChange({ defaultCustomer: values }),
      setDefaultNonCustomer: (values) =>
        handleValueChange({ defaultNonCustomer: values }),
    }),
    [handleValueChange],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange({})
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <CreateVaultWalletContext.Provider value={[values, actions]}>
      {children}
    </CreateVaultWalletContext.Provider>
  )
}

/**
 * Create add address context hook.
 */
export const useAddAddress = () => {
  return useContext(CreateVaultWalletContext)
}

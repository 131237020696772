import { FeatureSwitch } from '@services/feature-switch'
import {
  PROTECTED,
  PUBLIC,
  PUBLIC_ONLY,
  USER_SUPPORT,
  RESTRICTED,
} from '@services/permissions'

import type { Section } from '../types'

export const example: Section = {
  label: 'layout:page.example',
  route: '/example',
  permissions: FeatureSwitch.devOnlyEnabled() ? PROTECTED : RESTRICTED, // TODO: Create DEV_ONLY permission
  segment: {
    page: 'home',
  },
}

export const error404: Section = {
  label: 'layout:page.404',
  route: '/404',
  permissions: PUBLIC,
  segment: {
    page: 'error',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

export const error500: Section = {
  label: 'layout:page.500',
  route: '/500',
  permissions: PUBLIC,
  segment: {
    page: 'error',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

export const error401: Section = {
  label: 'layout:page.401',
  route: '/401',
  permissions: PUBLIC,
  segment: {
    page: 'error',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

export const signIn: Section = {
  label: 'layout:page.signin',
  route: '/signin',
  permissions: PUBLIC_ONLY,
  segment: {
    page: 'home',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

export const signOutSection: Section = {
  label: 'layout:page.signout',
  route: '/signout',
  permissions: PUBLIC,
  segment: {
    page: 'sign out',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

export const support: Section = {
  label: 'layout:page.support',
  route: '/support',
  permissions: USER_SUPPORT,
  segment: {
    page: 'support',
  },
}

export const homeSection: Section = {
  icon: 'HomeOutline',
  label: 'layout:page.payments.title',
  route: '/',
  permissions: PROTECTED,
  pages: [
    signIn,
    signOutSection,
    error404,
    error500,
    error401,
    example,
    support,
  ],
  segment: {
    page: 'home',
  },
}

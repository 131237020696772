import { Card, Icon } from '@circlefin/components'
import { PropsErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import { CreateWallet } from '../../../../containers'
import { useCreateVaultWallet } from '../../CreateVaultWallet.Context'

import type { CreateVaultWalletState } from '../../CreateVaultWallet.Context'

/**
 * Confirm variant when user is creating new wallet with non-native token.
 */
export const NonNativeToken: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  const [state] = useCreateVaultWallet()

  return (
    <PropsErrorBoundary<CreateVaultWalletState> props={state} variant="page">
      {({ asset, blockchain }) => {
        const blockchainName = t(`payments:chainNames.${blockchain.chain}`)
        return (
          <>
            <h2 className="mb-6 text-center text-4xl text-black-900 font-circular-bold">
              {t('createVaultWallet.confirm.nonNativeToken.title', {
                asset: asset?.symbol,
                blockchain: blockchainName,
              })}
            </h2>

            <p className="max-w-176 text-center text-base leading-6 font-circular-book">
              {t('createVaultWallet.confirm.nonNativeToken.subtitle', {
                asset: asset?.symbol,
                blockchain: blockchainName,
                native: blockchain.chain,
              })}
            </p>

            <Card className="m-auto mt-6 w-112 overflow-hidden">
              <Card.Content>
                <h3 className="mb-6 text-lg font-circular-bold">
                  {t('createVaultWallet.confirm.nonNativeToken.card.title', {
                    blockchain: blockchainName,
                  })}
                </h3>

                <div className="flex flex-row items-center">
                  <div className="mr-2 flex size-9 items-center justify-center rounded-full bg-black-50">
                    <Icon height={16} name={asset.icon} width={16} />
                  </div>
                  <div className="">
                    <p className="text-base font-circular-bold">
                      {asset?.name}
                    </p>
                    <p className="text-sm">
                      {t(
                        'createVaultWallet.confirm.nonNativeToken.card.newAsset',
                        {
                          asset: asset?.symbol,
                          blockchain: blockchainName,
                        },
                      )}
                    </p>
                  </div>
                </div>

                <div className="mt-8 flex flex-row items-center">
                  <div className="mr-2 flex size-9 items-center justify-center rounded-full bg-black-50">
                    <Icon height={16} name={blockchain.icon} width={16} />
                  </div>
                  <div className="">
                    <p className="text-base font-circular-bold">
                      {blockchainName}
                    </p>
                    <p className="text-sm">
                      {t(
                        'createVaultWallet.confirm.nonNativeToken.card.nativeToken',
                        {
                          native: blockchain.chain,
                        },
                      )}
                    </p>
                  </div>
                </div>
              </Card.Content>
            </Card>

            <CreateWallet.TokenOptInAlert
              asset={asset}
              blockchain={blockchain}
              className="m-auto mt-6 w-112"
            />
          </>
        )
      }}
    </PropsErrorBoundary>
  )
}

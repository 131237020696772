import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'

import { Warnings } from './Policy.Warnings'

const schema = y.object({
  /**
   * Max Amount.
   */
  maxAmount: y.number().required(),
  /**
   * Max Transactions.
   */
  maxTransactions: y
    .number()
    .moreThan(0, {
      key: 'amount.moreThan',
      moreThan: 0,
    })
    .allowEmpty()
    .required(),
})

export type PolicyFormValues = y.InferType<typeof schema>

export const PolicyForm: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const { money } = useMoney()
  const modal = useModal()
  const [
    { policyLimits },
    { setPolicyLimits, resetUserLimitsBasedOnPolicyLimits },
  ] = useWalletApprovalPolicy()
  const [{ currency = Currency.USDC }, { tokenToCurrency }] =
    useCurrentCurrency()

  const [Form, { formState, watch }] = useForm<PolicyFormValues>({
    defaultValues: {
      maxAmount: policyLimits?.maxAmount
        ? parseFloat(policyLimits.maxAmount)
        : undefined,
      maxTransactions: policyLimits?.maxTransactions
        ? parseFloat(policyLimits.maxTransactions)
        : undefined,
    },
    schema: schema.shape({
      maxAmount: y
        .number()
        .moreThan(0, {
          key: 'amount.moreThan',
          moreThan: money({
            number: 0,
            variant: tokenToCurrency(currency),
          }),
        })
        .required()
        .allowEmpty(),
    }),
  })

  const handleSubmit = useCallback(
    (values: PolicyFormValues) => {
      const limits = {
        maxAmount: values.maxAmount.toString(),
        maxTransactions: values.maxTransactions.toString(),
      }

      setPolicyLimits(limits)
      resetUserLimitsBasedOnPolicyLimits(limits)

      modal.router.push(routes.walletApprovalPolicy.createPolicy.userLimits)
    },
    [modal.router, resetUserLimitsBasedOnPolicyLimits, setPolicyLimits],
  )

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="sm:w-144">
        <h2 className="text-neutral-strong type-h-page-sm">
          {t('limits.policy.title')}
        </h2>
        <h3 className="mt-4 type-intro-sm">{t('limits.policy.subtitle')}</h3>

        <Form className="mt-4 sm:w-100" onSubmit={handleSubmit}>
          <Form.MoneyInput
            className="mb-4 w-full"
            currencyVariant={tokenToCurrency(currency)}
            label={t<string>('limits.policy.maxAmount')}
            name="maxAmount"
            padded
          />
          <Form.Input
            className="w-full"
            data-testid="transactions-input"
            label={t('limits.policy.maxTransactions')}
            name="maxTransactions"
          />

          <div className="mt-4">
            <ModalBackButton />

            <Form.SubmitButton className="w-60" variant="primary">
              {t('common:continue')}
            </Form.SubmitButton>
          </div>

          <Warnings
            maxAmount={watch('maxAmount')}
            maxAmountError={formState.errors.maxAmount?.message}
            maxTransactions={watch('maxTransactions')}
            maxTransactionsError={formState.errors.maxTransactions?.message}
          />
        </Form>
      </div>
    </div>
  )
}

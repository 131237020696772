import { useForm, y } from '@circlefin/form'
import { FormGrid } from '@shared/components/form'
import {
  AllowEntityReason,
  BlockEntityReason,
  WatchLists,
  WatchlistTypes,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { SecurityForms } from '../../../../forms'

const allowReasonSchema = y
  .mixed<AllowEntityReason>()
  .oneOf(Object.values(AllowEntityReason))
  .required()

const blockReasonSchema = y
  .mixed<BlockEntityReason>()
  .oneOf(Object.values(BlockEntityReason))
  .required()

const schema = y.object({
  /**
   * Type.
   */
  type: y
    .mixed<WatchlistTypes>()
    .oneOf(Object.values(WatchlistTypes))
    .required(),
  /**
   * Entity.
   */
  entity: y.string().required(),
  /**
   * Reason.
   */
  reason: y.string().required(),
})

export type AddItemFormValues = y.InferType<typeof schema>

export interface AddItemFormProps {
  /**
   * Triggered when a form is submitted.
   */
  onSubmit: (value: AddItemFormValues) => void
  /**
   * Type of operation.
   */
  variant: WatchLists
}

export const initialValues: AddItemFormValues = {
  type: WatchlistTypes.avs_code,
  entity: '',
  reason: AllowEntityReason.trusted,
}

export const AddItemForm: React.FC<AddItemFormProps> = ({
  onSubmit,
  variant,
}) => {
  const { t } = useTranslation('modals/settings/fraud-management')
  const [Form, { watch }] = useForm<AddItemFormValues>({
    schema: schema.shape({
      reason:
        variant === WatchLists.allow ? allowReasonSchema : blockReasonSchema,
    }),
    defaultValues: {
      ...initialValues,
      type:
        variant === WatchLists.allow
          ? WatchlistTypes.email_address
          : WatchlistTypes.avs_code,
    },
  })

  return (
    <Form onSubmit={onSubmit}>
      <FormGrid>
        <FormGrid.Element>
          <SecurityForms.Dropdown.Watchlist.Types
            name="type"
            variant={variant}
          />
        </FormGrid.Element>
        <FormGrid.Element>
          <SecurityForms.Dropdown.Watchlist.Entities
            name="entity"
            type={watch('type')}
          />
        </FormGrid.Element>
        <FormGrid.Element>
          {variant === WatchLists.allow ? (
            <SecurityForms.Dropdown.Watchlist.AllowReasons name="reason" />
          ) : (
            <SecurityForms.Dropdown.Watchlist.BlockReasons name="reason" />
          )}
        </FormGrid.Element>
      </FormGrid>
      <div className="mb-4 mt-16 flex">
        <Form.SubmitButton
          className="mx-auto w-64"
          data-testid="button-submit"
          variant="secondary"
        >
          {variant === WatchLists.allow ? t`allowList.cta` : t`blockList.cta`}
        </Form.SubmitButton>
      </div>
    </Form>
  )
}

AddItemForm.displayName = 'AddItemForm'

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Account settings.
 * Which users are allowed to change account settings?
 */
export const SETTINGS_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.ACCOUNT_WEB_ROLE],
}

import get from 'lodash.get'

import type { GraphQLError } from 'graphql'

/**
 * Error when action could not be resolved e.g. 403.
 */
export const RESOLVE_ERROR = 'RESOLVE_ERROR'

/**
 * HTTP Errors.
 */
export const NOT_FOUND = '404: Not Found'
export const UNAUTHORIZED = '401 Unauthorized'

/**
 * Custom Error Codes.
 */
export const CANNOT_RETRIEVE_SESSION_TOKEN_FROM_OKTA = '10005'

/**
 * Unauthenticated Errors.
 */
export const UNAUTHENTICATED = 'UNAUTHENTICATED'

/**
 * User Permission error.
 */
export const USER_PERMISSION = 'USER_PERMISSION_ERROR'

/**
 * Missing mock data error.
 */
export const MISSING_MOCK_DATA = 'MISSING_MOCK_DATA_ERROR'

/**
 * Error when certain action is forbidden e.g. MFA code invalid.
 */
export const FORBIDDEN = 'FORBIDDEN'

/**
 * Error when MFA is required
 * but not provided.
 */
export const MFA_REQUIRE = 'MFA_REQUIRE'

/**
 * Error when change password failed.
 */
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

/**
 * Error when enrolling pincode failed.
 */
export const ENROLL_PINCODE_FAILED = 'ENROLL_PINCODE_FAILED'

/**
 * Error when update pincode failed.
 */
export const UPDATE_PIN_CODE_FAILED = 'UPDATE_PIN_CODE_FAILED'

/**
 * Error when reset pincode failed.
 */
export const RESET_PIN_CODE_FAILED = 'RESET_PIN_CODE_FAILED'

/**
 * Error when create password failed.
 */
export const CREATE_PASSWORD_FAILED = 'CREATE_PASSWORD_FAILED'

/**
 * Error when complete invite failed.
 */
export const COMPLETE_INVITE_FAILED = 'COMPLETE_INVITE_FAILED'

/**
 * Error when remove user failed.
 */
export const REMOVE_USER_FAILED = 'REMOVE_USER_FAILED'

/**
 * Error when invite user failed.
 */
export const INVITE_USER_FAILED = 'INVITE_USER_FAILED'

/**
 * Error when resending user activation email.
 */
export const RESEND_USER_ACTIVATION = 'RESEND_USER_ACTIVATION'

/**
 * Error when activation token expired.
 */
export const EXPIRED_USER_ACTIVATION = 'EXPIRED_USER_ACTIVATION'

/**
 * Error when change user role failed.
 */
export const CHANGE_USER_ROLE_FAILED = 'CHANGE_USER_ROLE_FAILED'

/**
 * Error when requesting Nova Redirect URL.
 */
export const NOVA_REDIRECT_FAILED = 'NOVA_REDIRECT_FAILED'

/**
 * Error when requesting Qualification Progress.
 */
export const QUALIFICATION_PROGRESS_FAILED = 'QUALIFICATIONS_PROGRESS_FAILED'

/**
 * Error when creating a Qualification.
 */
export const CREATE_QUALIFICATION_FAILED = 'CREATE_QUALIFICATION_FAILED'

/**
 * Error when creating a Transfer.
 */
export const CREATE_TRANSFER_FAILED = 'CREATE_TRANSFER_FAILED'

/**
 * Error when transferring funds.
 */
export const TRANSFER_FUNDS_FAILED = 'TRANSFER_FUNDS_FAILED'

/**
 * Error when retrieving factor.
 */
export const GET_FACTOR_FAILED = 'GET_FACTOR_FAILED'

/**
 * Error when linking bank accounts.
 */
export const LINK_BANK_ACCOUNT_FAILED = 'LINK_BANK_ACCOUNT_FAILED'

/**
 * Error when trying to remove a user who belongs to one or more vaults.
 */
export const REMOVE_VAULT_USER_FAILED = 'REMOVE_VAULT_USER_FAILED'

/**
 * Error when trying to remove a user who is an owner in one or more vaults.
 */
export const REMOVE_VAULT_OWNER_FAILED = 'REMOVE_VAULT_OWNER_FAILED'

/**
 * Error when trying to remove a user who has submitted a main wallet policy.
 */
export const CANNOT_REMOVE_USER_WITH_SUBMITTED_MAIN_WALLET_POLICIES =
  'CANNOT_REMOVE_USER_WITH_SUBMITTED_MAIN_WALLET_POLICIES'

/**
 * Error when trying to remove a user who is part of an active main wallet policy.
 */
export const CANNOT_REMOVE_USER_IN_ACTIVE_MAIN_WALLET_POLICY =
  'CANNOT_REMOVE_USER_IN_ACTIVE_MAIN_WALLET_POLICY'

/**
 * Error when adding wallet address.
 */
export const ADD_BLOCKCHAIN_RECIPIENT_ADDRESS_FAILED =
  'ADD_BLOCKCHAIN_RECIPIENT_ADDRESS_FAILED'

/**
 * Error when deleting wallet address.
 */
export const REMOVE_BLOCKCHAIN_RECIPIENT_ADDRESS_FAILED =
  'REMOVE_BLOCKCHAIN_RECIPIENT_ADDRESS_FAILED'

/**
 * Error when creating wallet address with metadata.
 */
export const CREATE_BLOCKCHAIN_RECIPIENT_ADDRESS_WITH_METADATA_FAILED =
  'CREATE_BLOCKCHAIN_RECIPIENT_ADDRESS_WITH_METADATA_FAILED'

/**
 * Error when creating wallet address with metadata due to RISK - owner type not allowed.
 */
export const OWNER_TYPE_NOT_ALLOWED = 'OWNER_TYPE_NOT_ALLOWED'

/**
 * Error when creating wallet address with metadata due to RISK - address is not eligible to be used for this purpose.
 */
export const ADDRESS_IS_NOT_ELIGIBLE = 'ADDRESS_IS_NOT_ELIGIBLE'

/**
 * Error when creating wallet address with metadata due to RISK - address failed risk check due to provider not supporting this address.
 */
export const ADDRESS_IS_NOT_TRM_SUPPORTED = 'ADDRESS_IS_NOT_TRM_SUPPORTED'

/**
 * Error where starting onboarding application.
 */
export const START_ONBOARDING_APPLICATION = 'START_ONBOARDING_APPLICATION'

/**
 * Error when updating onboarding data.
 */
export const UPDATE_ONBOARDING_DATA_FAILED = 'UPDATE_ONBOARDING_DATA_FAILED'

/**
 * Error when setting a user's product interests.
 */
export const SET_PRODUCT_INTERESTS_FAILED = 'SET_PRODUCT_INTERESTS_FAILED'

/**
 * Error when country code is missing in states.
 */
export const MISSING_COUNTRY_CODE = 'MISSING_COUNTRY_CODE'

/**
 * Error when adding IP allowlisting.
 */
export const IP_ALLOWLISTING_FAILED = 'IP_ALLOWLISTING_FAILED'

/**
 * Error when creating payout.
 */
export const CREATE_PAYOUT_FAILED = 'CREATE_PAYOUT_FAILED'

/**
 * Error when refunding a payment.
 */
export const CREATE_REFUND_FAILED = 'CREATE_REFUND_FAILED'

/**
 * Error when setting default payment method.
 */
export const SET_DEFAULT_PAYMENT_METHOD_FAILED =
  'SET_DEFAULT_PAYMENT_METHOD_FAILED'

/**
 * Error when user failed to create notification subscription.
 */
export const CREATE_NOTIFICATION_SUBSCRIPTION_FAILED =
  'CREATE_NOTIFICATION_SUBSCRIPTION_FAILED'

/**
 * Error when user failed to delete an existing notification subscription.
 */
export const DELETE_NOTIFICATION_SUBSCRIPTION_FAILED =
  'DELETE_NOTIFICATION_SUBSCRIPTION_FAILED'

/**
 * Error when user failed to submit a vote.
 */
export const EMAIL_APPROVAL_VOTE_SUBMISSION_FAILED =
  'EMAIL_APPROVAL_VOTE_SUBMISSION_FAILED'

/**
 * Error when user failed to delete an existing notification subscription.
 */
export const TRANSFER_FROM_DIRECT_CUSTODY_SOURCE_FAILED =
  'TRANSFER_FROM_DIRECT_CUSTODY_SOURCE_FAILED'

/**
 * Error when submitting draft policy.
 */
export const SUBMIT_DRAFT_WALLET_APPROVAL_POLICY_FAILED =
  'SUBMIT_DRAFT_WALLET_APPROVAL_POLICY_FAILED'

/**
 * Error when user failed to submit an Address Book vote.
 */
export const ADDRESS_BOOK_APPROVAL_VOTE_SUBMISSION_FAILED =
  'ADDRESS_BOOK_APPROVAL_VOTE_SUBMISSION_FAILED'

/**
 * Error when executing Rebalancer / Auto Transfer mutations.
 */
export const REBALANCER_RULE_MUTATION_FAILED = 'REBALANCER_RULE_MUTATION_FAILED'

/**
 * Error when trying to create an account.
 */
export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED'

/**
 * Error when there is not enough approver(s) to approve an user management approval request.
 */
export const USER_APPROVAL_REQUEST_FAILED = 'USER_APPROVAL_REQUEST_FAILED'

/**
 * Find Error Code.
 */
export const isErrorCode = (
  /**
   * Error Code.
   */
  errorCode: string,
  /**
   * Readonly GraphQLError[].
   */
  errors?: Readonly<GraphQLError[]>,
): boolean => (errors ? !!getError(errorCode, errors) : false)

/**
 * Get Error By Error Code.
 */
export const getError = (
  /**
   * Error Code.
   */
  errorCode: string,
  /**
   * Readonly GraphQLError[].
   */
  errors: Readonly<GraphQLError[]>,
) =>
  errors?.find(
    ({ extensions }) =>
      extensions?.code === errorCode ||
      get(extensions, 'response.body.code') === errorCode,
  )

/**
 * Extract Error Code from Error.
 */
export const getErrorCode = (
  /**
   * Unknown error object.
   */
  error: unknown,
): string =>
  (
    get(error, 'extensions.code', '') ||
    get(error, 'extensions.response.body.code', '')
  ).toString()

/**
 * Extract Error Status from Error.
 */
export const getErrorStatus = (
  /**
   * Unknown error object.
   */
  error: unknown,
): number => get(error, 'extensions.status', 0) as number

/**
 * Check if an error is a MFA error and return error .
 */
export const getI18nErrorKey = (
  graphQLErrors?: readonly GraphQLError[],
): string | undefined => {
  if (!graphQLErrors) {
    return
  }

  return (graphQLErrors?.[0]?.extensions?.i18n as string) ?? undefined
}

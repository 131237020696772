import { useCallback } from 'react'

import { Button, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  VaultDocument,
  VaultsDashboardDocument,
  useCreateVaultWalletMutation,
  useVaultQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVaultWallet } from '../CreateVaultWallet.Context'

import { ExistingWallet, NativeToken, NonNativeToken } from './index'

export const Confirm: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { router } = useModal()

  const [{ asset, blockchain, vaultId }] = useCreateVaultWallet()

  const { data, error, refetch, loading } = useVaultQuery({
    variables: {
      id: vaultId ?? '',
    },
  })

  const [createVaultWallet] = useCreateVaultWalletMutation({
    onCompleted: () => {
      void router.push(routes.vault.createVaultWallet.success)
    },
    onError: () => null,
    refetchQueries: [
      VaultsDashboardDocument,
      {
        query: VaultDocument,
        variables: {
          id: vaultId,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  // Find existing wallet with selected blockchain
  const existingWallet = data?.vault.walletsByBlockchain.find(
    (wallet) => wallet.blockchain === blockchain?.chain,
  )

  /**
   * Find native token wallet since this is the parent wallet.
   */
  const existingParentWallet = existingWallet
    ? existingWallet.wallets.find(
        (wallet) => wallet.icon === existingWallet.icon,
      )
    : null

  // User is adding token to existing wallet with selected blockchain
  const isExistingWallet = !!existingWallet

  // User is creating new wallet with native token
  const isNativeToken = !isExistingWallet && asset?.symbol === blockchain?.chain

  // User is creating new wallet with non-native token
  const isNonNativeToken = !isExistingWallet && !isNativeToken

  const handleCreateVaultWallet = useCallback(() => {
    if (!blockchain || !asset?.symbol || !vaultId) {
      return
    }
    void createVaultWallet({
      variables: {
        input: {
          vaultId,
          blockchain: blockchain.chain,
          assetSymbol: asset.symbol,
          assetName: asset.name,
          parentWalletId: existingParentWallet?.id,
        },
      },
    })
  }, [blockchain, asset, vaultId, createVaultWallet, existingParentWallet?.id])

  return (
    <FullScreen data-testid="create-vault-wallet-confirm" totalSteps={4}>
      <GraphQLErrorBoundary error={error} retry={refetch}>
        <Center variant="horizontal">
          <SkeletonBox className="m-auto h-60 w-112" loading={loading}>
            {isExistingWallet && <ExistingWallet />}
            {isNativeToken && <NativeToken />}
            {isNonNativeToken && <NonNativeToken />}
          </SkeletonBox>
          <Button
            className="m-auto mt-4 w-112"
            disabled={loading}
            onClick={handleCreateVaultWallet}
            variant="primary"
          >
            {t`common:continue`}
          </Button>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

export default Confirm

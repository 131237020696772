import { useEffect, useState } from 'react'
import tagManager from 'react-gtm-module'

import getConfig from 'next/config'

import type { NextConfig } from 'next/config'
const { publicRuntimeConfig } = getConfig() as NextConfig

/**
 * Google Tag Manager.
 */
export const GoogleTagManager = () => {
  // Prevent script from being re-inserted on page transition
  const [initialized, setInitialized] = useState(false)
  const googleTagManagerId = publicRuntimeConfig?.GOOGLE_TAG_MANAGER_ID

  useEffect(() => {
    if (googleTagManagerId && typeof window === 'object' && !initialized) {
      setInitialized(true)
      tagManager.initialize({
        gtmId: googleTagManagerId,
      })
    }
  }, [googleTagManagerId, initialized])

  return null
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../../../Create/Create'
import { useLinkBankAccount } from '../../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().accountNumber({ variant: 'us' }).required(),
})

export type XpayFormValues = y.InferType<typeof schema>

export interface XpayFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: XpayFormValues) => void
}

export const XpayForm: React.FC<XpayFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const [createBankAccount] = useLinkCreate()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<XpayFormValues>({
    schema,
    defaultValues: {
      ...information.xpay,
    },
  })

  const onFormSubmit = useCallback(
    (xpay: XpayFormValues) => {
      // Set data to context
      setInformation({ xpay })

      // Pass data to submit callback (for testing)
      onSubmit?.(xpay)

      // Create Bank Account
      createBankAccount()
    },
    [createBankAccount, onSubmit, setInformation],
  )

  return (
    <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
      {/* Bank Account Nickname */}
      <Form.Input
        className="w-full"
        data-testid="input-nickname"
        label={t('link.information.nickname')}
        name="nickname"
      />

      {/* Bank Account Number */}
      <Form.Input
        className="w-full"
        data-testid="input-accountNumber"
        label={t('link.information.accountNumber')}
        name="accountNumber"
      />

      {/* Continue */}
      <Form.SubmitButton
        className="mt-6 w-64"
        variant="primary"
      >{t`common:continue`}</Form.SubmitButton>
    </Form>
  )
}

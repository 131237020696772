import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * BASE blockchain EURC access permission.
 */
export const BASE_BLOCKCHAIN_EURC: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_BASE_BLOCKCHAIN_EURC_ROLE],
}

import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { FeatureSwitch } from '@services/feature-switch'
import { useHubspot } from '@services/hubspot/react'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  CountryCode,
  useOnboardingNaturesOfBusinessQuery,
  useVerificationQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { OnboardingForms } from '../../../forms'

import type { OnboardingEntityType } from '@shared/graphql'

const baseSchema = y.object({
  /**
   * Business name.
   */
  businessName: y.string().required(),
  /**
   * Expected monthly minting volume.
   */
  mintingVolume: OnboardingForms.Dropdown.mintingVolumeSchema,
  /**
   * Number of active users on a platform.
   */
  activeUsers: OnboardingForms.Dropdown.activeUsersSchema,
  /**
   * Business country code.
   */
  businessCountry: OnboardingForms.Dropdown.businessCountrySchema,
  /**
   * Business state code.
   */
  businessState: OnboardingForms.Dropdown.businessStateSchema,
  /**
   * Entity type.
   */
  entityType: OnboardingForms.Dropdown.entityTypeSchema,
  /**
   * Business nature.
   */
  businessNature: y.string().required(),
})
export type ConfirmFormValues = y.InferType<typeof baseSchema>

export interface ConfirmFormProps {
  /**
   * Cb invoked when user fills/submits in the form.
   */
  onSubmit: (values: ConfirmFormValues) => void | Promise<void>
}

export const ConfirmForm: React.FC<ConfirmFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('onboard/account-setup')
  const { submitBusinessInformationForm, submitLegacyBusinessInformationForm } =
    useHubspot()
  const { track } = useSegment()

  const verificationQuery = useVerificationQuery()
  const kybProperties =
    verificationQuery.data?.onboardingQualifications.kyb?.properties

  // Get nature of business data
  const naturesOfBusinessQuery = useOnboardingNaturesOfBusinessQuery()
  // Get list of prohibited natures of business
  const prohibited = useMemo(
    () =>
      naturesOfBusinessQuery.data?.onboardingNaturesOfBusiness
        .filter(({ prohibited }) => prohibited)
        .map(({ value }) => value) ?? [],
    [naturesOfBusinessQuery.data],
  )

  // setup form schema
  const businessNatureCopSchema = y
    .string()
    .test(
      'validateBusinessNature',
      { key: 'onboard.unsupportedBusinessNature' },
      (value) => value != null && !prohibited.includes(value),
    )
  const schema = baseSchema.shape({
    /**
     * Business nature.
     */
    businessNature: y
      .string()
      .when([], {
        is: () => FeatureSwitch.isCopOnboardingEnabled(),
        then: (schema) => schema.concat(businessNatureCopSchema),
        otherwise: (schema) =>
          schema.concat(OnboardingForms.Dropdown.businessNatureSchema),
      })
      .required(),
  })
  type FormValues = y.InferType<typeof schema>

  const [Form, { watch, resetField, formState }] = useForm<FormValues>({
    schema,
    defaultValues: {
      businessName: undefined,
      businessCountry: CountryCode.US,
      businessState: undefined,
      entityType: undefined,
      businessNature: undefined,
      mintingVolume: undefined,
      activeUsers: undefined,
    },
    values: kybProperties
      ? {
          businessName: kybProperties.businessName,
          businessCountry: kybProperties.businessCountry,
          businessState: kybProperties.businessState,
          entityType: kybProperties.entityType as OnboardingEntityType,
          businessNature: kybProperties.natureOfBusiness,
          mintingVolume: kybProperties.expectedUsdcMintingVolume ?? '',
          activeUsers: kybProperties.activeUsersOnPlatform ?? '',
        }
      : undefined,
  })

  const loading = verificationQuery.loading || naturesOfBusinessQuery.loading

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (FeatureSwitch.inviteOnlyPhase2Enabled()) {
        submitBusinessInformationForm({
          businessEmail: kybProperties?.userEmail ?? '',
          ...values,
        })
      } else {
        submitLegacyBusinessInformationForm({
          businessEmail: kybProperties?.userEmail ?? '',
          ...values,
        })
      }

      track(SegmentEvents.FormSubmitted, {
        props: {
          values,
        },
      })

      await onSubmit(values)
    },
    [
      track,
      onSubmit,
      submitBusinessInformationForm,
      kybProperties?.userEmail,
      submitLegacyBusinessInformationForm,
    ],
  )

  // Handle field change dependencies
  watch((_, { name }) => {
    // When business country field changes & state field is dirty, reset state
    if (name === 'businessCountry' && formState.dirtyFields.businessState) {
      resetField('businessState')
    }
  })

  return (
    <div className="pt-8">
      <GraphQLErrorBoundary
        error={verificationQuery.error}
        retry={verificationQuery.refetch}
      >
        <GraphQLErrorBoundary
          error={naturesOfBusinessQuery.error}
          retry={naturesOfBusinessQuery.refetch}
        >
          <Form data-testid="confirm-form" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4 ">
              <Form.Input
                className="w-full"
                data-testid="input-businessName"
                label={t`confirm.form.businessName.label`}
                message={t`confirm.form.businessName.message`}
                name="businessName"
                placeholder={t`confirm.form.businessName.placeholder`}
              />

              <OnboardingForms.Dropdown.BusinessCountry
                country={watch('businessCountry')}
                loading={loading}
              />

              <OnboardingForms.Dropdown.BusinessState
                country={watch('businessCountry')}
                loading={loading}
              />

              <OnboardingForms.Dropdown.EntityType
                label={t`confirm.form.entityType.label`}
                loading={loading}
                placeholder={t`confirm.form.entityType.placeholder`}
              />

              {!FeatureSwitch.isCopOnboardingEnabled() && (
                <OnboardingForms.Dropdown.BusinessNature
                  label={t`confirm.form.businessNature.label`}
                  loading={loading}
                  placeholder={t`confirm.form.businessNature.placeholder`}
                />
              )}

              {FeatureSwitch.isCopOnboardingEnabled() && (
                <OnboardingForms.Dropdown.BusinessNatureCOP loading={loading} />
              )}

              {FeatureSwitch.inviteOnlyPhase2Enabled() ? (
                <OnboardingForms.Dropdown.QuarterlyMintingVolume
                  loading={loading}
                  name="mintingVolume"
                />
              ) : (
                <OnboardingForms.Dropdown.MintingVolume
                  loading={loading}
                  name="mintingVolume"
                />
              )}

              <OnboardingForms.Dropdown.ActiveUsers
                loading={loading}
                name="activeUsers"
              />
            </div>

            <Form.SubmitButton className="mt-6 w-full" variant="primary">
              {t`confirm.form.submitButton`}
            </Form.SubmitButton>
          </Form>
        </GraphQLErrorBoundary>
      </GraphQLErrorBoundary>
    </div>
  )
}

import { useMemo } from 'react'

import { Logo } from '@circlefin/components'
import { homeSection } from '@services/sections/lib/home'
import classNames from 'classnames'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export interface HeaderPublicLogoProps {
  /**
   * Circle icon logo or full logo.
   */
  variant?: 'icon' | 'full' | 'mobile-full'
  /**
   * Circle logo alignment.
   */
  align?: 'left' | 'center'
  /**
   * Logo is "home" link boolean.
   */
  asLink?: boolean
}

export const HeaderPublicLogo: React.FC<HeaderPublicLogoProps> = ({
  variant = 'icon',
  align = 'left',
  asLink,
}) => {
  const { t } = useTranslation()

  /**
   * Determine logo variant.
   */
  const logo = useMemo(() => {
    switch (variant) {
      case 'icon':
        return <Logo size={2.5} wordMark={false} />
      case 'full':
        return (
          <>
            {/* Desktop Logo */}
            <Logo
              className="size-0 overflow-hidden sm:size-auto sm:overflow-visible"
              size={11.25}
            />
            {/* Mobile Logo */}
            <Logo className="sm:hidden" size={8} />
          </>
        )
      case 'mobile-full':
        return <Logo size={8} />
    }
  }, [variant])

  return (
    <div
      className={classNames('flex', {
        'col-start-1': align === 'left',
        'col-start-2 items-center justify-center': align === 'center',
      })}
      data-testid="logo-container"
    >
      {asLink ? (
        /* Logo wrapped in a link */
        <Link
          aria-label={t(homeSection.label)}
          href={homeSection.route}
          title={t(homeSection.label)}
        >
          {logo}
        </Link>
      ) : (
        /* Logo wrapped in <h1> */
        <h1 className="text-base">
          <span className="sr-only">Circle</span>
          {logo}
        </h1>
      )}
    </div>
  )
}

HeaderPublicLogo.displayName = 'HeaderPublic.Logo'

import { Chip } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { VaultPolicyApprovalLevel } from '@shared/graphql'

export type LevelSummaryProps = Partial<
  Omit<VaultPolicyApprovalLevel, 'isEdit'>
>

export const LevelSummary: React.FC<LevelSummaryProps> = ({
  transferAmount = '',
  approvers = [],
  minNeedConfirmed,
}) => {
  const { t } = useTranslation('modals.vault')
  const formatMoney = useMoney({ locale: 'en-US' }).money

  return (
    <div className="grid grid-cols-1 gap-y-6">
      <div className="text-lg text-black-600">
        {t('createVault.approval.level.summary.transferAmount', {
          money: formatMoney({ number: transferAmount, variant: Currency.USD }),
        })}
      </div>

      <div>
        <div className="text-sm text-black-400 font-circular-book">
          {t('createVault.approval.level.summary.approvers', {
            count: minNeedConfirmed,
          })}
        </div>
        <div className="mt-2 flex flex-wrap gap-2">
          {approvers.map((user) => (
            <Chip
              key={user.id}
              className="shrink-0 px-4 py-2 text-base text-black-700 font-circular-bold"
              data-testid="user-chip"
              variant="default/info"
            >
              {user.name}
            </Chip>
          ))}
        </div>
      </div>
    </div>
  )
}

import { useMemo, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useVeriteVerifierChallengeMutation } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import ReactQRCode from 'qrcode.react'

export interface VerifierQRCodeQueryProps {
  /**
   * The type of credential.
   */
  credentialType?: string
  /**
   * The blockchain network the credential holder is on.
   */
  network?: string
  /**
   * The address of the credential holder.
   */
  subject?: string
}

export const VerifierQRCode: React.FC<VerifierQRCodeQueryProps> = (props) => {
  const { t } = useTranslation()

  const [qrCodeData, setQrCodeData] = useState('')

  const [veriteVerifierChallengeMutation, { error, loading }] =
    useVeriteVerifierChallengeMutation({
      onCompleted: ({ veriteVerifierChallenge }) => {
        setQrCodeData(JSON.stringify(veriteVerifierChallenge))
      },
    })

  const { network = '', subject = '' } = props

  useMemo(() => {
    void veriteVerifierChallengeMutation({
      variables: {
        input: {
          network,
          subject,
        },
      },
    })
  }, [veriteVerifierChallengeMutation, network, subject])

  return (
    <PropsErrorBoundary<VerifierQRCodeQueryProps> props={props} variant="page">
      {({ credentialType }) => (
        <GraphQLErrorBoundary error={error} variant="page">
          <Center>
            <h1>
              <Trans
                i18nKey="verite:verifier-scan-qr-code-modal-title"
                values={{ credentialType: credentialType ?? '' }}
              />
            </h1>
            <Center className="mt-3">
              {t('verite:verifier-scan-qr-code-instructions')}
            </Center>
            <SkeletonBox loading={loading}>
              <div className="mt-6 h-48">
                <ReactQRCode
                  height="100%"
                  renderAs="svg"
                  value={qrCodeData ?? ''}
                  width="100%"
                />
              </div>
            </SkeletonBox>
            <Center>
              <a className="mt-6">
                {t('verite:scan-qr-code-supported-wallets')}
              </a>
            </Center>
          </Center>
        </GraphQLErrorBoundary>
      )}
    </PropsErrorBoundary>
  )
}

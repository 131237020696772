import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { TokenActivationState, VaultWalletState } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export interface TransferProps
  extends Pick<ButtonProps, 'variant' | 'disabled'> {
  /**
   * Id of the wallet (either parentWalletId or walletId has to be provided).
   */
  walletId?: string
  /**
   * Id of the parent wallet (either parentWalletId or walletId has to be provided).
   */
  parentWalletId?: string
  /**
   * State of the wallet.
   */
  walletState: VaultWalletState
  /**
   * Current token activation state.
   */
  tokenActivationState?: TokenActivationState
}

export const Transfer: React.FC<TransferProps> = ({
  walletId,
  walletState,
  parentWalletId,
  variant,
  tokenActivationState,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const { vaultMetadata } = useVaultPermission()
  const { track } = useSegment()
  const { router } = useModal()

  const handleClick = useCallback(() => {
    track(SegmentEvents.TransferOutOfVaultWalletClicked)

    router.push({
      pathname: routes.vault.transfer.chooseTransferType,
      query: {
        walletId,
        parentWalletId,
        vaultId: vaultMetadata?.id,
      },
    })
  }, [track, router, vaultMetadata?.id, walletId, parentWalletId])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider.
   */
  if (!vaultMetadata) return null

  /**
   * Only allow to deposit if the wallet does not need to be activated first.
   */
  if (
    tokenActivationState != null &&
    [
      TokenActivationState.NOT_ACTIVATED,
      TokenActivationState.PENDING_ACTIVATION,
    ].includes(tokenActivationState)
  ) {
    return null
  }

  /**
   * Either walletId or parentWalletId has to be provided.
   */
  if (!walletId && !parentWalletId) return null

  return (
    <Button
      disabled={
        /**
         * Do not allow transferring out of a wallet that is not yet active.
         */
        walletState !== VaultWalletState.ACTIVE ||
        /**
         * User is not an operator of the vault.
         */
        !vaultMetadata.isOperator ||
        /**
         * Disabled through the parent component.
         */
        disabled
      }
      onClick={handleClick}
      variant={variant}
    >{t`common:transfer`}</Button>
  )
}

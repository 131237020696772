import classNames from 'classnames'

interface BodyProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Styles?
   */
  className?: string
}

export const Body: React.FC<BodyProps> = ({ className, children }) => (
  <div
    className={classNames('overflow-x-auto', className)}
    data-testid="table-card-body"
  >
    {children}
  </div>
)

Body.displayName = 'TableCard.Body'

import { useCallback, useEffect } from 'react'

import { Button, Modal, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { MultiStep } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  PaymentMethodType,
  useDefaultPaymentMethodQuery,
  LegalEntity,
  useEntityQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { usePayInvoice } from '../PayInvoice.Context'

import { CardPaymentMethods } from './CardPaymentMethods/CardPaymentMethods'
import { WalletPaymentMethods } from './WalletPaymentMethods/WalletPaymentMethods'

export interface PaymentMethodSelectionProps {
  /**
   * Invoice ID.
   */
  invoiceId?: string
}

export const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = (
  props,
) => {
  const { t } = useTranslation('modals/settings/billing')
  const { track } = useSegment()
  const { router } = useModal()
  const [{ paymentMethodSelected }, { setPaymentDetails }] = usePayInvoice()
  const { data } = useDefaultPaymentMethodQuery({ fetchPolicy: 'network-only' })
  const {
    travelRuleRequired,
    loading: travelRuleRequiredLoading,
    error: travelRuleRequiredError,
  } = useTravelRuleRequired()
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = useEntityQuery()

  const defaultPaymentMethod = data?.defaultPaymentMethod

  /**
   * Automatically select the default payment.
   */
  useEffect(() => {
    if (!paymentMethodSelected && defaultPaymentMethod) {
      const isCard = defaultPaymentMethod.type === PaymentMethodType.card

      setPaymentDetails({
        cardId: isCard ? defaultPaymentMethod.id : undefined,
        paymentType: defaultPaymentMethod.type,
        paymentMethodSelected: true,
      })
    }
  }, [setPaymentDetails, paymentMethodSelected, defaultPaymentMethod])

  const handleAddCard = useCallback(
    (invoiceId: string) => () => {
      track(SegmentEvents.AddCardClicked)
      router.push({
        pathname: routes.account.billing.paymentMethod.addCreditCard.details,
        query: { invoiceId },
      })
    },
    [router, track],
  )

  const handleContinue = useCallback(
    (invoiceId: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ContinueClicked, {
        event: e,
      })
      router.push({
        pathname: routes.account.billing.payInvoice.confirmPayment,
        query: { invoiceId },
      })
    },
    [router, track],
  )

  return (
    <MultiStep
      subtitle={t('common:step', { start: 1, end: 2 })}
      title={t`invoice.title`}
    >
      <PropsErrorBoundary<PaymentMethodSelectionProps>
        props={props}
        variant="page"
      >
        {({ invoiceId }) => (
          <>
            <div className="flex size-full flex-col items-stretch justify-start space-y-2">
              <div className="text-xl leading-7 text-black-700 font-circular-bold">
                {t('invoice.selectPaymentMethod.linkedWallet.wallets')}
              </div>
              <WalletPaymentMethods radioName="payment_method" />
              <GraphQLErrorBoundary
                error={travelRuleRequiredError || entityError}
              >
                <SkeletonBox
                  loading={travelRuleRequiredLoading || entityLoading}
                >
                  {!travelRuleRequired &&
                    !travelRuleRequiredLoading &&
                    entityData?.entity?.legalEntity !== LegalEntity.CIRCLE_FR &&
                    !entityLoading && (
                      <>
                        <div className="mb-4 pt-6 text-xl leading-7 text-black-700 font-circular-bold">
                          {t('invoice.selectPaymentMethod.linkedCards.cards')}
                        </div>
                        <CardPaymentMethods radioName="payment_method" />
                        <div className="py-2">
                          <Button
                            onClick={handleAddCard(invoiceId)}
                            variant="text"
                          >
                            {t('invoice.selectPaymentMethod.addCard')}
                          </Button>
                        </div>
                      </>
                    )}
                </SkeletonBox>
              </GraphQLErrorBoundary>
            </div>
            <Modal.Footer variant="center">
              <Button
                className="w-64"
                data-testid="next-button"
                onClick={handleContinue(invoiceId)}
                variant="primary"
              >{t`common:continue`}</Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </MultiStep>
  )
}

import { createContext } from 'react'

import type { NonNullableProperties } from '@circlefin/types'
import type {
  Currency,
  User,
  WalletApprovalPolicy,
  WalletApprovalPolicyApprovalLevel,
  WalletApprovalPolicyLimits,
  UpsertWalletApprovalPolicySettings,
  WalletApprovalPolicyUserLimit,
} from '@shared/graphql'

export interface BaseWalletApprovalPolicyState
  extends NonNullableProperties<
    Partial<
      Pick<
        WalletApprovalPolicy,
        'policyLimits' | 'userLimits' | 'approvalWorkflow'
      >
    >
  > {
  /**
   * Policy settings.
   */
  settings?: Partial<UpsertWalletApprovalPolicySettings>
}

export interface WalletApprovalPolicyState
  extends BaseWalletApprovalPolicyState {
  /**
   * Approval workflow currently in edit.
   */
  approvalLevelInEdit?: boolean
  /**
   * Saved successfully?
   */
  isFlowComplete?: boolean
  /**
   * Touched state.
   */
  touched?: WalletApprovalPolicyTouched
  /**
   * Is the policy being edited.
   */
  inEdit?: boolean
  /**
   * Owner of policy in edit.
   */
  ownerOfPolicyInEdit?: User
  /**
   * Policy in edit initial values (before any updates).
   */
  policyInEditInitialValues?: BaseWalletApprovalPolicyState
}

export type WalletApprovalPolicyTouched = Partial<
  Record<
    Extract<keyof WalletApprovalPolicyState, keyof WalletApprovalPolicy>,
    boolean
  >
>

export enum WalletApprovalPolicyStep {
  PERMISSIONS = 'permissions',
  POLICY_LIMITS = 'limits',
  USER_LIMITS = 'limits/user',
  APPROVAL = 'approval',
  SETTINGS = 'settings',
  REVIEW = 'review',
  FINISHED = 'review/finished',
}

export interface WalletApprovalPolicyInitializeQueryProps {
  /**
   * Name of the step to start the flow.
   */
  startingStep?: WalletApprovalPolicyStep
  /**
   * Chosen currency that this policy applies to.
   */
  currency?: Currency
  /**
   * Policy in edit.
   */
  policyInEdit?: WalletApprovalPolicy
}

export interface WalletApprovalPolicyActions {
  /**
   * Set the user limits for the wallet policy.
   */
  setUserLimits: (
    usersPolicy: WalletApprovalPolicyUserLimit[],
    touched?: boolean,
  ) => void
  /**
   * Set the global limits of the wallet policy.
   */
  setPolicyLimits: (limits: WalletApprovalPolicyLimits) => void
  /**
   * Set the approval workflow of the wallet policy.
   */
  setApprovalWorkflow: (
    workflow: WalletApprovalPolicyApprovalLevel | undefined,
  ) => void
  /**
   * Set settings for the wallet policy.
   */
  setPolicySettings: (
    settings: Partial<UpsertWalletApprovalPolicySettings>,
  ) => void
  /**
   * Set the flag to indicate that the approval workflow is in edit.
   */
  setApprovalLevelInEdit: (status: boolean) => void
  /**
   * Reset user limits objects that have their values greater than the policy limit.
   */
  resetUserLimitsBasedOnPolicyLimits: (
    limits: WalletApprovalPolicyLimits,
  ) => void
  /**
   * Set touched state.
   */
  setTouched: (touched: WalletApprovalPolicyTouched) => void
  /**
   * Set Flow Complete state.
   */
  setFlowComplete: (isFlowComplete: boolean) => void
  /**
   * Set Policy to be edited.
   */
  setPolicyInEdit: (policy: WalletApprovalPolicy) => void
}

export const defaultValues: WalletApprovalPolicyState = {
  touched: {},
  approvalLevelInEdit: true,
}

export const WalletApprovalPolicyContext = createContext<
  [WalletApprovalPolicyState, WalletApprovalPolicyActions]
>([
  defaultValues,
  {
    setUserLimits: () => null,
    setPolicyLimits: () => null,
    setApprovalWorkflow: () => null,
    setApprovalLevelInEdit: () => null,
    setPolicySettings: () => null,
    resetUserLimitsBasedOnPolicyLimits: () => null,
    setTouched: () => null,
    setFlowComplete: () => null,
    setPolicyInEdit: () => null,
  },
])

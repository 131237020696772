import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  vaultDetailsSection,
  vaultsSection,
} from '@services/sections/lib/vaults'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../../containers/Create'
import { useCreateVault } from '../../../../hooks/create'
import * as VaultLayout from '../../../../layout'

import type { CreateVaultState } from '../../../../hooks/create/context'

export const Finished: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [{ inEdit, vaultId }] = useCreateVault()

  const router = useRouter()
  const { close } = useModal()

  const goToVaults = useCallback(
    (vaultId: string) => () => {
      /**
       * Go to detail view if we are on the vaults dashboard.
       */
      if (router.pathname === vaultsSection.route) {
        // go to detail view
        void router.push({
          pathname: vaultDetailsSection.route,
          query: { vaultId },
        })
      } else {
        // just close the modal
        close({ context: 'onConfirm' })
      }
    },
    [close, router],
  )

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.FINISHED}
      disableSaveAndExit
    >
      <PropsErrorBoundary<Pick<CreateVaultState, 'vaultId'>>
        props={{ vaultId }}
        variant="page"
      >
        {({ vaultId }) => (
          <div className="grid max-w-176 grid-cols-1 justify-items-start">
            <h2 className="text-4xl text-black-600 font-circular-bold">
              {t(
                `createVault.review.finished.${
                  inEdit ? 'update' : 'create'
                }.title`,
              )}
            </h2>

            <h3 className="mb-1 mt-4 text-lg text-black-600">
              {t(
                `createVault.review.finished.${
                  inEdit ? 'update' : 'create'
                }.subtitle`,
              )}
            </h3>

            <div className="mt-8">
              <Button
                className="w-60"
                onClick={goToVaults(vaultId)}
                variant="primary"
              >
                {t('createVault.review.finished.goToVault')}
              </Button>
            </div>
          </div>
        )}
      </PropsErrorBoundary>
    </VaultLayout.CreateVault>
  )
}

import { useForm, y } from '@circlefin/form'
import { BlockchainAbbreviation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { FormInputProps } from '@circlefin/form/Form.Input'

export const memoSchema = y.string()

const schema = y.object({
  /**
   * Blockchain Memo.
   */
  memo: memoSchema,
})

export type MemoFormValues = y.InferType<typeof schema>

export interface MemoProps
  extends Omit<FormInputProps<MemoFormValues>, 'name'> {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Selected blockchain.
   */
  chain?: BlockchainAbbreviation
}

// Blockchains that require memo.
export const visibleBlockchains = [
  BlockchainAbbreviation.HBAR,
  BlockchainAbbreviation.XLM,
]

export const Memo: React.FC<MemoProps> = ({ className, chain, ...props }) => {
  const { t } = useTranslation('forms')
  const [Form] = useForm<MemoFormValues>({ schema })

  if (chain != null && visibleBlockchains.includes(chain)) {
    return (
      <Form.Input
        className={className}
        label={t`input.blockchain.memo.label`}
        message={t`input.blockchain.memo.message`}
        name="memo"
        {...props}
      />
    )
  }

  return null
}

Memo.displayName = 'Input.Blockchain.Memo'

import { useCallback, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'

import { CreateVaultNavigationStep } from '../../../containers/Create'
import { useCreateVault } from '../../../hooks/create'
import * as VaultLayout from '../../../layout'
import { PolicyConfirm } from '../PolicyConfirm/PolicyConfirm'

import { PolicyNameForm } from './PolicyName.Form'

export const PolicyName: React.FC = () => {
  const { router } = useModal()
  const [{ inEdit }] = useCreateVault()

  const [confirmModal, setConfirmModal] = useState(false)

  const onSubmit = useCallback(() => {
    if (inEdit) {
      void router.push(routes.vault.createVault.permissions)
      return
    }
    setConfirmModal(true)
  }, [inEdit, router])

  // TODO: save the user's preference to not show the confirmation modal, push to next screen
  const onContinue = useCallback(() => {
    // TODO: Save the user's preference to not show the confirm modal
    setConfirmModal(false)
    void router.push(routes.vault.createVault.permissions)
  }, [router])

  const onCancel = useCallback(() => setConfirmModal(false), [])

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.POLICY_NAME}
    >
      <PolicyNameForm onSubmit={onSubmit} />
      <PolicyConfirm
        onCancel={onCancel}
        onContinue={onContinue}
        open={confirmModal}
      />
    </VaultLayout.CreateVault>
  )
}

import { useCallback } from 'react'

import { Header, NumberBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useBalancesMainQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useRedemptionOnlyExpress } from '../../hooks/redemption-only-express'

import type { Locale } from '@circlefin/money/variants'
import type { Currency } from '@shared/graphql'

export interface CircleWalletDetailHeaderProps {
  /**
   * Currency.
   */
  currency: Currency
}

export const CircleWalletDetailHeader: React.FC<CircleWalletDetailHeaderProps> =
  ({ currency }) => {
    const { locale } = useRouter()
    const { t } = useTranslation('custody/common')
    const { track } = useSegment()
    const modal = useModal()
    const { canRedeem: canDepositAsRolr } = useRedemptionOnlyExpress()

    const { data, loading } = useBalancesMainQuery({
      pollInterval: BALANCE_POLL_INTERVAL, // 10sec interval
      fetchPolicy: 'cache-and-network',
      variables: {
        currency,
      },
    })

    // if we have cached data, ignore loading
    const balanceLoading = !data && loading

    const handleTransferClick = useCallback(() => {
      track(SegmentEvents.SendClicked)

      modal.router.push({
        pathname: routes.transfer.chooseTransferType,
        query: {
          currency,
        },
      })
    }, [track, modal.router, currency])

    return (
      <Header loading={balanceLoading}>
        <Header.Head
          title={t('circleWallets.detailHeader.title', {
            currency,
          })}
        >
          <div className="flex h-12 items-end gap-x-3 space-y-3">
            <PaymentButtons.AccountFunding.Transfer
              currency={currency}
              loading={balanceLoading}
              onClick={handleTransferClick}
              type="button"
              variant="secondary"
            >
              {t('common:transfer')}
            </PaymentButtons.AccountFunding.Transfer>
            <PaymentButtons.AccountFunding.Deposit
              currency={currency}
              disabled={!canDepositAsRolr(currency)}
              eventSource="WalletDetailsPage"
              loading={balanceLoading}
              type="button"
              variant="primary"
            >
              {t('common:deposit')}
            </PaymentButtons.AccountFunding.Deposit>
          </div>
        </Header.Head>
        <Header.Numbers className="mt-7">
          <NumberBox loading={balanceLoading} size="lg">
            <NumberBox.Amount
              currency={data?.balances.main?.balance.currency}
              locale={locale as Locale}
            >
              {data?.balances.main?.balance.amount}
            </NumberBox.Amount>
          </NumberBox>
        </Header.Numbers>
      </Header>
    )
  }

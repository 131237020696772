import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export const Transfer: React.FC = () => {
  const { t } = useTranslation()
  const { vaultMetadata } = useVaultPermission()
  const { track } = useSegment()
  const { router } = useModal()

  const handleClick = useCallback(() => {
    track(SegmentEvents.TransferOutOfVaultClicked)

    router.push({
      pathname: routes.vault.transfer.chooseTransferType,
      query: {
        vaultId: vaultMetadata?.id,
      },
    })
  }, [track, router, vaultMetadata?.id])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider.
   */
  if (!vaultMetadata) return null

  /**
   * Do not show anything until a vault is active.
   */
  if (
    [
      VaultStateEnum.SETUP_IN_PROGRESS,
      VaultStateEnum.READY_FOR_APPROVAL,
      VaultStateEnum.PENDING_APPROVAL,
    ].includes(vaultMetadata.state)
  )
    return null

  return (
    <Button
      disabled={
        /**
         * User is not an operator of the vault.
         */
        !vaultMetadata.isOperator
      }
      onClick={handleClick}
      variant="text"
    >{t`common:transfer`}</Button>
  )
}

import { useCallback, useEffect, useMemo } from 'react'

import { Modal } from '@circlefin/components'
import { LoadingSpinner, TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useResetPinCodeMutation } from '@shared/graphql'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

export const ResetPinCode: React.FC = () => {
  const { t } = useTranslation('mfa')
  const { data: sessionData } = useSession()

  const [resetPinCode, { data, loading, error, reset }] =
    useResetPinCodeMutation({
      onError: () => null,
    })

  useEffect(() => {
    void resetPinCode()
  }, [resetPinCode])

  const body = useMemo(() => {
    if (loading) {
      return <LoadingSpinner />
    }

    if (data) {
      return (
        <span
          className="mb-2 mt-4 type-h-title-md"
          data-testid="reset-pin-email"
        >
          {sessionData?.user.email}
        </span>
      )
    }
  }, [data, loading, sessionData?.user.email])

  const handleRetry = useCallback(() => {
    reset()
    void resetPinCode()
  }, [reset, resetPinCode])

  return (
    <>
      <Modal.Header
        iconName="LockClosedSolid"
        title={t('resetPinCode.title')}
        variant="info"
      >
        {!loading && !error && t('resetPinCode.subtitle')}
      </Modal.Header>
      <Modal.Body className="flex flex-col items-center pt-2">
        <GraphQLErrorBoundary error={error} retry={handleRetry}>
          {body}
        </GraphQLErrorBoundary>
      </Modal.Body>
      <Modal.Footer className="flex justify-center">
        <TransWithLink
          i18nKey="mfa:resetPinCode.contactCustomerSupport"
          variant="support"
        />
      </Modal.Footer>
    </>
  )
}

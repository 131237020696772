import { useMemo } from 'react'

import { TableFilters } from '@circlefin/components'
import { VaultTransactionType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { FilterBaseProps } from '@circlefin/components/lib/TableFilters/types'

/**
 * Vault Transaction Type Filter.
 */
export const TransactionType: React.FC<FilterBaseProps> = (props) => {
  const { t } = useTranslation('vaults')

  const items: Array<SelectListItem<VaultTransactionType[] | undefined>> =
    useMemo(
      () => [
        {
          value: undefined,
          label: t('transactionsTable.filter.types.allTypes'),
        },
        {
          value: [VaultTransactionType.deposit],
          label: t('transactionsTable.filter.types.deposits'),
        },
        {
          value: [VaultTransactionType.withdrawal],
          label: t('transactionsTable.filter.types.withdrawals'),
        },
      ],
      [t],
    )

  return <TableFilters.Dropdown {...props} items={items} />
}

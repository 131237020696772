import { useCallback } from 'react'

import { FullScreen } from '@modals/layout'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../SendOnChain.Context'

import { CircleSourceReview } from './CircleSourceReview/CircleSourceReview'
import { Description } from './Description'
import { DirectSourceReview } from './DirectSourceReview/DirectSourceReview'
import { Label } from './Label'

export const ReviewTransferDetails: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [
    { sourceWallet },
    ,
    { directSourceWallet, derivedDestination, derivedAssetSymbol },
  ] = useSendOnChain()

  const TransferMutationWrapper: React.FC<React.PropsWithChildren> =
    useCallback(
      ({ children }) => {
        if (!sourceWallet) {
          return <></>
        }

        if (TypeGuards.InternalWallet.isDirect(sourceWallet)) {
          return <DirectSourceReview>{children}</DirectSourceReview>
        }

        if (TypeGuards.InternalWallet.isCircle(sourceWallet)) {
          return <CircleSourceReview>{children}</CircleSourceReview>
        }

        return <></>
      },
      [sourceWallet],
    )

  return (
    <FullScreen totalSteps={6}>
      <Center className="text-center font-circular" variant="horizontal">
        <h2 className="mb-2 text-center text-4xl text-black-600 font-circular-bold">
          {t`transfer.sendOnChain.reviewTransferDetails.title`}
        </h2>
        <h3 className="text-base leading-6 text-black-400">
          {t`transfer.sendOnChain.reviewTransferDetails.subtitle`}
        </h3>
        <TransferMutationWrapper>
          <Label>{t`transfer.sendOnChain.reviewTransferDetails.source`}</Label>
          <Description className="break-words">
            <span className="flex justify-between">
              <span>{sourceWallet?.name}</span>
              <span className="text-black-400">
                {directSourceWallet?.vaultMetadata.name}
              </span>
            </span>
          </Description>
          <Label>
            {t`transfer.sendOnChain.reviewTransferDetails.destination`}
          </Label>
          <Description>
            <span className="flex justify-between">
              <span>{derivedDestination?.description}</span>
              <span className="text-black-400">
                {derivedDestination?.label}
              </span>
            </span>
          </Description>
          <Label>{t`transfer.sendOnChain.reviewTransferDetails.asset`}</Label>
          <Description>{derivedAssetSymbol}</Description>
        </TransferMutationWrapper>
      </Center>
    </FullScreen>
  )
}

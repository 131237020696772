import { ADDRESS_BOOK_QUERY } from '@services/permissions'

import type { Section } from '../types'

export const addressBookDetails: Section = {
  label: 'layout:page.settings.address-book.wallet',
  route: '/address-book/[id]',
  permissions: ADDRESS_BOOK_QUERY,
  segment: {
    page: 'settings',
  },
}

export const addressBookOnchainSend: Section = {
  label: 'layout:page.settings.address-book.onchain',
  route: '/address-book/onchain-send/[id]',
  permissions: ADDRESS_BOOK_QUERY,
  icon: 'LinkOutline',
  segment: {
    page: 'settings',
  },
}

export const addressBookOnchainReceive: Section = {
  label: 'layout:page.settings.address-book.onchain',
  route: '/address-book/onchain-receive/[id]',
  permissions: ADDRESS_BOOK_QUERY,
  icon: 'LinkOutline',
  segment: {
    page: 'settings',
  },
}

export const addressBook: Section = {
  label: 'layout:page.settings.address-book.title',
  route: '/address-book',
  permissions: ADDRESS_BOOK_QUERY,
  icon: 'LinkOutline',
  pages: [
    addressBookDetails,
    addressBookOnchainSend,
    addressBookOnchainReceive,
  ],
  segment: {
    page: 'settings',
  },
  takeover: {
    periodicReview: false,
    pinCode: false,
  },
}

import { useCallback, useState } from 'react'

import { Button, Table } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { WalletForms } from '@features/wallets/forms'
import { routes } from '@services/sections/modal/routes'
import { SimpleTable, TableCard } from '@shared/components/tables'
import { VaultUserPolicyRoleEnum, useUserQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../containers/Create'
import { usePermissionValidate } from '../../../containers/CreateVault'
import { useCreateVault } from '../../../hooks/create'
import * as VaultLayout from '../../../layout'

import {
  ApprovalWarning,
  ApprovalWarningVariant,
} from './ApprovalWarning/ApprovalWarning'
import { PermissionRow } from './Permissions.Row'

import type { ApprovalWarningProps } from './ApprovalWarning/ApprovalWarning'
import type { Maybe, User, VaultUserPolicy } from '@shared/graphql'

const schema = y.object({
  /**
   * Verified User to add to permission list.
   */
  verifiedUser: WalletForms.Combobox.verifiedUserSchema,
})

type VerifiedUserFormValues = y.InferType<typeof schema>

export const Permissions: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  const { router } = useModal()

  const [{ usersPolicy = [], approvalWorkflow }, { setUsersPolicy }] =
    useCreateVault()

  const [Form, { reset }] = useForm<VerifiedUserFormValues>({
    schema,
    defaultValues: {
      verifiedUser: undefined,
    },
  })

  const { isValid, atLeastOneApprover } = usePermissionValidate()

  const { data, loading, error, refetch } = useUserQuery({
    onCompleted: ({ user }) => {
      if (usersPolicy.length !== 0) {
        return
      }

      // initialize users' policy list with the currently logged-in user/owner
      setUsersPolicy([
        {
          user,
          permissions: [
            VaultUserPolicyRoleEnum.CREATOR,
            VaultUserPolicyRoleEnum.AUDITOR,
          ],
        },
      ])
    },
  })

  const [approvalWarning, setApprovalWarning] = useState<
    Omit<ApprovalWarningProps, 'onDismiss'>
  >({
    open: false,
  })

  const onContinue = useCallback(() => {
    void router.push(routes.vault.createVault.policyLimits)
  }, [router])

  const onUserSelection = useCallback(
    (selectedUser: Maybe<User>) => {
      if (selectedUser) {
        // Reset form values because we don't want to keep the selected user in the form context.
        // This will also clear the combo box value on each selection.
        reset()

        if (usersPolicy.find(({ user }) => user.id === selectedUser.id)) {
          return
        }

        if (selectedUser.current) {
          // if the owner is selected, they should always be shown first in the list
          setUsersPolicy([
            {
              user: selectedUser,
              permissions: [
                VaultUserPolicyRoleEnum.CREATOR,
                VaultUserPolicyRoleEnum.AUDITOR,
              ],
            },
            ...usersPolicy,
          ])
        } else {
          setUsersPolicy([
            ...usersPolicy,
            {
              user: selectedUser,
              permissions: [VaultUserPolicyRoleEnum.AUDITOR],
            },
          ])
        }
      }
    },
    [usersPolicy, reset, setUsersPolicy],
  )

  const isUserInApprovalWorkFlow = useCallback(
    (user: User) => {
      return approvalWorkflow?.some(({ approvers }) =>
        approvers.find((approver) => approver.id === user.id),
      )
    },
    [approvalWorkflow],
  )

  const onChange = useCallback(
    (newValue: VaultUserPolicy) => {
      if (
        isUserInApprovalWorkFlow(newValue.user) &&
        !newValue.permissions.includes(VaultUserPolicyRoleEnum.APPROVER)
      ) {
        // show warning
        setApprovalWarning({
          open: true,
          action: ApprovalWarningVariant.CHANGED,
          user: newValue.user,
        })
        return
      }

      const foundIndex = usersPolicy.findIndex(
        (value) => value.user.id === newValue.user.id,
      )

      if (foundIndex === -1) {
        return
      }

      const isOperator = newValue.permissions.includes(
        VaultUserPolicyRoleEnum.OPERATOR,
      )

      const updated = [...usersPolicy]
      updated[foundIndex] = {
        ...newValue,
        // if the user is no longer an operator, one who can initiate transfers, reset their user limits
        maxTransactions: isOperator
          ? updated[foundIndex].maxTransactions
          : undefined,
        maxAmount: isOperator ? updated[foundIndex].maxAmount : undefined,
      }

      setUsersPolicy(updated)
    },
    [isUserInApprovalWorkFlow, usersPolicy, setUsersPolicy],
  )

  const onRemove = useCallback(
    (user: User) => {
      if (isUserInApprovalWorkFlow(user)) {
        // show warning
        setApprovalWarning({
          open: true,
          action: ApprovalWarningVariant.REMOVED,
          user,
        })
        return
      }

      setUsersPolicy(usersPolicy.filter((v) => v.user.id !== user.id))
    },
    [isUserInApprovalWorkFlow, usersPolicy, setUsersPolicy],
  )

  const onDismissApprovalWarning = useCallback(() => {
    setApprovalWarning((curr) => ({
      ...curr,
      open: false,
    }))
  }, [])

  return (
    <>
      <ApprovalWarning
        {...approvalWarning}
        onDismiss={onDismissApprovalWarning}
      />
      <VaultLayout.CreateVault
        currentStep={CreateVaultNavigationStep.PERMISSIONS}
      >
        <div className="max-w-160">
          <h2 className="text-4xl text-black-600 font-circular-bold">
            {t('createVault.permissions.title')}
          </h2>
          <h3 className="mt-4 text-lg text-black-600">
            {t('createVault.permissions.subtitle')}
          </h3>

          <Form>
            <WalletForms.Combobox.VerifiedUsers
              className="mt-10"
              onChange={onUserSelection}
            />
          </Form>

          <hr className="my-4 h-px border-black-100" />

          <TableCard>
            <SimpleTable
              empty={t`common:emptyTable`}
              error={error}
              loading={loading}
              retry={refetch}
              size={usersPolicy.length}
            >
              <Table.Head>
                <Table.Head.Row>
                  <Table.Head.Cell className="px-4 text-sm normal-case">
                    {t('createVault.permissions.table.headers.user')}
                  </Table.Head.Cell>
                  <Table.Head.Cell className="w-8/12 px-7 text-sm normal-case">
                    {t('createVault.permissions.table.headers.permissions')}
                  </Table.Head.Cell>
                </Table.Head.Row>
              </Table.Head>
              {!loading && (
                <Table.Body>
                  {usersPolicy.map((userPermission) => (
                    <PermissionRow
                      key={userPermission.user.id}
                      isOwner={userPermission.user.id === data?.user.id}
                      onChange={onChange}
                      onRemove={onRemove}
                      userPolicy={userPermission}
                    />
                  ))}
                </Table.Body>
              )}
            </SimpleTable>
          </TableCard>

          {!atLeastOneApprover && !loading && usersPolicy.length > 0 && (
            <p className="mt-4 text-sm leading-5 font-circular-book">
              {t('createVault.permissions.atLeastOneApprover')}
            </p>
          )}

          {!isValid && !loading && usersPolicy.length > 0 && (
            <p className="mt-4 text-sm leading-5 text-red-500 font-circular-book">
              {t('createVault.permissions.atLeastOneInitiator')}
            </p>
          )}

          <div className="mt-4">
            <ModalBackButton />

            <Button
              className="w-60"
              disabled={!isValid}
              loading={loading}
              onClick={onContinue}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>
        </div>
      </VaultLayout.CreateVault>
    </>
  )
}

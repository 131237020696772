import type { PropsWithChildren } from 'react'

import classNames from 'classnames'

export interface DescriptionProps {
  className?: string
}

export const Description = ({
  children,
  className,
}: PropsWithChildren<DescriptionProps>) => (
  <p
    className={classNames(
      'mb-4 last:mb-0 text-xs leading-4 font-circular-regular text-black-400',
      className,
    )}
    data-testid="disclosure-description"
  >
    {children}
  </p>
)

Description.displayName = 'Disclosures.Description'

import { useCallback, useMemo } from 'react'

import { Button, Modal } from '@circlefin/components'
import { WalletApprovalPolicyRole } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useApprovalWorkflowValidate } from '../../../../../containers/approval-policy'
import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'

export interface UnassignedApproversWarningProps {
  /**
   * Open modal.
   */
  open?: boolean
  /**
   * On cancel handler.
   */
  onCancel?: () => void
  /**
   * On continue handler.
   */
  onContinue?: () => void
}

export const UnassignedApproversWarning: React.FC<UnassignedApproversWarningProps> =
  ({ open, onCancel, onContinue }) => {
    const { t } = useTranslation('modals.walletApprovalPolicy')

    const [{ userLimits = [] }, { setUserLimits }] = useWalletApprovalPolicy()
    const { missingApproversInWorkflow } = useApprovalWorkflowValidate()

    const approvers = useMemo(
      () => Array.from(missingApproversInWorkflow.values()),
      [missingApproversInWorkflow],
    )

    const handleContinue = useCallback(() => {
      const updatedUsersPolicy = userLimits.map((usersPolicy) => {
        if (!missingApproversInWorkflow.has(usersPolicy.user.id)) {
          return usersPolicy
        }

        return {
          ...usersPolicy,
          permissions: [WalletApprovalPolicyRole.OPERATOR],
        }
      })

      setUserLimits(updatedUsersPolicy)

      onContinue?.()
    }, [missingApproversInWorkflow, onContinue, setUserLimits, userLimits])

    return (
      <Modal open={open} size="sm" disableClose>
        <Modal.Title>
          {t`approval.unassignedApproversWarning.title`}
        </Modal.Title>
        <Modal.Body>
          <p className="text-center text-base text-neutral-strong font-circular-regular">
            {t`approval.unassignedApproversWarning.description`}
          </p>

          <hr className="my-6 h-px border-black-100" />

          <div className="text-sm text-neutral-strong">
            {t`approval.unassignedApproversWarning.newPermissions`}
          </div>
          <ul className="mt-2 space-y-2">
            {approvers.map((approver) => (
              <li key={approver.id} className="flex flex-col gap-y-0.5">
                <div className="flex gap-x-2">
                  <div className="text-sm leading-5 text-neutral-strong font-circular-bold">
                    {approver.name}
                  </div>
                  <div className="text-sm leading-5 text-neutral-subtle font-circular-regular">
                    {approver.email}
                  </div>
                </div>
                <div className="text-sm leading-5 text-neutral-subtle font-circular-regular">
                  <Trans
                    components={{
                      b: <span />,
                    }}
                    i18nKey="walletApprovalPolicy:permissions.menu.options.initiate"
                  />
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer variant="stretch-equal">
          <Button onClick={onCancel} variant="secondary">
            {t('common:cancel')}
          </Button>
          <Button onClick={handleContinue} variant="primary">
            {t('common:continue')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import type { InternalRefetchQueriesInclude } from '@apollo/client'
import type { ButtonStyleProps } from '@circlefin/components/lib/Button'

export interface RejectApprovalRequestProps extends Partial<ButtonStyleProps> {
  /**
   * Policy id.
   */
  proposalId: string
  /**
   * Refetch queries.
   */
  refetchQueries?: InternalRefetchQueriesInclude
}

export const RejectApprovalRequest: React.FC<RejectApprovalRequestProps> = ({
  proposalId,
  refetchQueries,
  ...styleProps
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <ModalLink
      pathname={routes.walletApprovalPolicy.rejectApprovalRequest}
      query={{
        proposalId,
        refetchQueries,
      }}
    >
      <Button
        intent="destructive"
        size="sm"
        variant="secondary"
        {...styleProps}
      >
        {t('buttons.rejectApprovalRequest')}
      </Button>
    </ModalLink>
  )
}

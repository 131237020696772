import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface DepositProps {
  /**
   * Custom style.
   */
  className?: string
}

export const Deposit: React.FC<DepositProps> = ({ className }) => {
  const { t } = useTranslation('custody/common')

  return (
    <FixedBanner className={className} visible>
      <FixedBanner.Title>
        {t('firstTimeUserExperience.firstDeposit.title')}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t('firstTimeUserExperience.firstDeposit.description')}</p>
        <ModalLink pathname={routes.transfer.chooseDepositType}>
          <TextLink size="sm" variant="primary">
            {t('firstTimeUserExperience.firstDeposit.action')}
          </TextLink>
        </ModalLink>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import classNames from 'classnames'

import { SubTitle } from './ListItem.SubTitle'
import { Title } from './ListItem.Title'

export interface ListItemProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

/**
 * Subcomponents Interface.
 */
interface SubComponents {
  /**
   * Title component.
   */
  Title: typeof Title
  /**
   * Subtitle component.
   */
  Subtitle: typeof SubTitle
}

export const ListItem: React.FC<ListItemProps> & SubComponents = ({
  className,
  children,
}) => {
  return (
    <li className={classNames('w-full py-4 first:pt-0', className)}>
      {children}
    </li>
  )
}

ListItem.Title = Title
ListItem.Subtitle = SubTitle

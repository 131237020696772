/**
 * Blockchain Routes.
 */
export const blockchain = {
  sendOnchain: {
    switch: '/blockchain/send-on-chain/',
    recipient: '/blockchain/send-on-chain/recipient',
    amount: '/blockchain/send-on-chain/amount',
    review: '/blockchain/send-on-chain/review',
    approvalRequired: '/blockchain/send-on-chain/approvalRequired',
  },
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Transaction Limit settings
 * Which users are allowed to access the limit settings page?
 */
export const TRANSACTION_LIMIT_SETTINGS: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.AGENT_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_READ_ROLE,
    Role.TRANSACTION_LIMIT_ROLE,
  ],
}

import type { ChangeEvent } from 'react'
import { useCallback, useState } from 'react'

import { Tooltip, Icon, Checkbox } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { circleWalletPendingTransactionApprovalDetailSection } from '@services/sections/lib/custody'
import { PAYMENT_ACTIVITY_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { ComplexTable, TableCard } from '@shared/components/tables'
import {
  useWalletApprovalPendingTransactionsLazyQuery,
  useWalletApprovalTransactionPendingProposalCountQuery,
  WalletApprovalTransactionStatus,
} from '@shared/graphql'
import useIsMounted from 'ismounted'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useWalletApprovalPendingTransactionsColumns } from './WalletApprovalPendingTransactions.Columns'

import type {
  onFetchDataProps,
  Row,
} from '@circlefin/components/lib/AdvancedTable'
import type {
  Payment,
  Currency,
  WalletApprovalTransaction,
} from '@shared/graphql'

const { Body } = TableCard

interface FiltersProps {
  /**
   * Hides rejected/expired transactions.
   */
  hideRejectedExpired: boolean
}

export interface WalletApprovalPendingTransactionsProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Filter by currency - show all activity for a specific currency.
   */
  currency: Currency
}

export const WalletApprovalPendingTransactions: React.FC<WalletApprovalPendingTransactionsProps> =
  ({ className, currency }) => {
    const { t } = useTranslation('walletApprovalPolicy')
    const router = useRouter()
    const isMounted = useIsMounted()
    const columns = useWalletApprovalPendingTransactionsColumns()

    const [filters, setFilters] = useState<FiltersProps>({
      hideRejectedExpired: false,
    })

    const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)

    const [fetchPayments, fetchPaymentsQueryState] =
      useWalletApprovalPendingTransactionsLazyQuery({
        pollInterval: PAYMENT_ACTIVITY_POLL_INTERVAL, // 10sec interval
        fetchPolicy: 'cache-and-network', // ensure table has fresh data on render
      })

    const pendingProposalCountQuery =
      useWalletApprovalTransactionPendingProposalCountQuery({
        variables: {
          currency,
        },
        pollInterval: PAYMENT_ACTIVITY_POLL_INTERVAL, // keep in sync with the table
        fetchPolicy: 'cache-and-network',
        skip: !isAuthorized,
      })

    const handleCheckboxChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setFilters({
          hideRejectedExpired: e.target.checked,
        })
      },
      [],
    )

    const handleFetchData = useCallback(
      ({ currentPageLink }: onFetchDataProps<Payment>) => {
        // we only want to invoke this query, when the component is still mounted
        if (isMounted.current && isAuthorized) {
          void fetchPayments({
            variables: {
              // We set the pageSize/to/from parameters only on the first page.
              // For the other pages we should follow the link parameters.
              // Every time the user changes the filters the page link is
              // set to undefined, and we go back to the first page.
              page: currentPageLink
                ? {
                    pageSize: currentPageLink.pageSize,
                    to: currentPageLink.to,
                    from: currentPageLink.from,
                    pageAfter: currentPageLink.pageAfter,
                    pageBefore: currentPageLink.pageBefore,
                  }
                : {
                    pageSize: 10,
                  },
              filter: {
                currency,
                status: filters.hideRejectedExpired
                  ? [WalletApprovalTransactionStatus.PENDING_APPROVAL]
                  : undefined,
              },
            },
          })
        }
      },
      [
        isMounted,
        isAuthorized,
        fetchPayments,
        currency,
        filters.hideRejectedExpired,
      ],
    )

    const onRowClick = useCallback(
      ({ original }: Row<WalletApprovalTransaction>) => {
        void router.push({
          pathname: circleWalletPendingTransactionApprovalDetailSection.route,
          query: {
            currency,
            id: original.id,
          },
        })
      },
      [currency, router],
    )

    const proposalCount =
      pendingProposalCountQuery.data
        ?.walletApprovalTransactionPendingProposalCount.count ?? 0

    return (
      <div className={className}>
        <div className="flex justify-between pr-6">
          <div className="mb-4 flex items-center gap-x-3">
            <div className="flex items-center justify-start">
              <h3 className="flex flex-row type-h-section-md">{t`pendingTransactions.header.label`}</h3>

              <Tooltip
                className="z-60 w-144"
                content={t`pendingTransactions.header.tooltip`}
                place="right"
              >
                <span className="ml-1.5 self-start">
                  <Icon
                    className="text-neutral-subtlest"
                    name="InformationCircleSolid"
                    size={16}
                  />
                </span>
              </Tooltip>
            </div>

            <div className="text-neutral-subtle font-circular-medium type-body-sm">
              {proposalCount > 0 &&
                t('pendingTransactions.pendingProposalCount', {
                  count: proposalCount,
                })}
            </div>
          </div>

          <div className="mb-3 self-end">
            {/* TODO: create TableFilters.Checkbox component in components-web */}
            <Checkbox
              checked={filters.hideRejectedExpired}
              className="text-neutral-subtle"
              label={t`pendingTransactions.hideRejectedExpired`}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>

        <TableCard>
          <Body>
            <GraphQLErrorBoundary
              error={pendingProposalCountQuery.error}
              retry={pendingProposalCountQuery.refetch}
              variant="component"
            >
              <ComplexTable<WalletApprovalTransaction, FiltersProps>
                columns={columns}
                data={
                  fetchPaymentsQueryState?.data
                    ?.walletApprovalPendingTransactions
                }
                empty={{ subtitle: t`pendingTransactions.empty-table-label` }}
                enableSorting={false}
                error={fetchPaymentsQueryState.error}
                filters={filters}
                loading={
                  !fetchPaymentsQueryState.called ||
                  (!fetchPaymentsQueryState.data &&
                    fetchPaymentsQueryState.loading)
                }
                onFetchData={handleFetchData}
                onRowClick={onRowClick}
                pageSize={10}
                pagination="links"
                retry={fetchPaymentsQueryState.refetch}
                manualFiltering
              />
            </GraphQLErrorBoundary>
          </Body>
        </TableCard>
      </div>
    )
  }

WalletApprovalPendingTransactions.displayName =
  'WalletApprovalPendingTransactions'

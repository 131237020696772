import { useMemo } from 'react'

import { Button, FixedBanner } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { usePermission } from '@circlefin/permissions'
import { PROTECTED } from '@services/permissions'
import { l3Onboarding } from '@services/sections/lib/onboarding'
import { usePeriodicReviewQualificationQuery } from '@shared/graphql'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Locale } from '@circlefin/money/variants'

export const PeriodicReviewBanner: React.FC = () => {
  const { t } = useTranslation('common')
  const { locale } = useRouter()
  const [, { isAuthorized }] = usePermission(PROTECTED)
  const { date } = useDate({ locale: locale as Locale })

  const { data } = usePeriodicReviewQualificationQuery({
    skip: !isAuthorized,
  })

  const { dueDate, actionRequired } = data?.periodicReviewQualification ?? {}

  const description = useMemo(
    () =>
      dueDate != null
        ? t('periodicReview.alert.description.withDueDate', {
            date: date(dueDate, 'monthDayYear'),
          })
        : t`periodicReview.alert.description.noDueDate`,
    [date, dueDate, t],
  )

  return (
    <FixedBanner
      level="page"
      status="warning"
      visible={actionRequired === true}
      multiline
    >
      <FixedBanner.Title>{t`periodicReview.alert.title`}</FixedBanner.Title>
      <FixedBanner.Description>
        <p>{description}</p>
      </FixedBanner.Description>
      <FixedBanner.Footer>
        <Link href={l3Onboarding.route} legacyBehavior passHref>
          <Button.Link size="sm" variant="primary">
            {t`periodicReview.alert.linkText`}
          </Button.Link>
        </Link>
      </FixedBanner.Footer>
    </FixedBanner>
  )
}

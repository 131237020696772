import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * User role management permission.
 *
 * Note that we currently don't support self-service role management,
 * but this permission will exclude SSO from seeing support text that implies role changes via Diameter.
 */
export const USER_ROLE_MANAGEMENT: PermissionSet = {
  access: AccessPermissions.protected,
  excludeAny: [Role.SSO_ENABLED_ROLE],
}

import { useCallback, useState } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useResetMfaRequestMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  email: y.string().required().email(),
  password: y.string().required(),
})

export type ResetMfaRequestFormValues = y.InferType<typeof schema>

export const ResetMfaRequestForm: React.FC = () => {
  const { t } = useTranslation('mfa')
  const [showSuccess, setShowSuccess] = useState(false)

  const [Form, { reset: resetForm }] = useForm<ResetMfaRequestFormValues>({
    schema,
  })

  const [resetMfaRequest, { error, reset }] = useResetMfaRequestMutation({
    onCompleted: () => {
      setShowSuccess(true)

      resetForm({
        email: '',
        password: '',
      })
    },
    onError: () => null,
  })

  const handleSubmit = useCallback(
    async (values: ResetMfaRequestFormValues) => {
      setShowSuccess(false)

      await resetMfaRequest({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      })
    },
    [resetMfaRequest],
  )

  const retry = useCallback(() => {
    resetForm(
      {},
      {
        keepValues: true,
      },
    )
    reset()
  }, [reset, resetForm])

  return (
    <>
      <FixedBanner
        className="mt-4"
        status="success"
        visible={showSuccess}
        multiline
      >
        <FixedBanner.Title>{t`reset.request.banner.title`}</FixedBanner.Title>
        <FixedBanner.Description>
          {t`reset.request.banner.description`}
        </FixedBanner.Description>
      </FixedBanner>

      <GraphQLErrorBoundary error={error} retry={retry}>
        <Form className="mt-8 flex flex-col gap-y-4" onSubmit={handleSubmit}>
          <Form.Input
            className="w-full"
            label={t`reset.request.form.email`}
            name="email"
            placeholder={t`reset.request.form.email`}
          />
          <Form.PasswordInput
            className="w-full"
            data-testid="mfa-reset-request-password"
            label={t`reset.request.form.password`}
            name="password"
            placeholder={t`reset.request.form.password`}
          />
          <Form.SubmitButton
            className="mt-2 w-full"
            variant="primary"
          >{t`common:submit`}</Form.SubmitButton>
        </Form>
      </GraphQLErrorBoundary>
    </>
  )
}

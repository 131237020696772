import { useMemo } from 'react'

import { OnboardingEntityType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const useEntityType = () => {
  const { t } = useTranslation('onboard/common')

  const data: Array<SelectListItem<OnboardingEntityType>> = useMemo(
    () =>
      Object.values(OnboardingEntityType).map((option) => ({
        label: t(`form.entityTypes.${OnboardingEntityType[option]}`),
        value: OnboardingEntityType[option],
      })),
    [t],
  )

  return {
    data,
  }
}

import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import { useSegment, SegmentEvents } from '@services/segment'
import { useGraphQLError } from '@shared/hooks/common'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'
import type { StatementProps } from '@circlefin/components/lib/Statement'

export interface GraphQLErrorBoundaryProps
  extends Pick<StatementProps, 'variant' | 'status'> {
  /**
   * Error Boundary id.
   */
  id?: string
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * GraphQL errors.
   */
  error?: ApolloError
  /**
   * Fallback error message.
   */
  fallbackError?: string
  /**
   * Retry action.
   */
  retry?: () => unknown
}

/**
 * GraphQL errors boundary.
 */
export const GraphQLErrorBoundary: React.FC<GraphQLErrorBoundaryProps> = ({
  error,
  fallbackError,
  retry,
  variant,
  status = 'warning',
  id = 'graphql',
  children,
}) => {
  const { t } = useTranslation('common')
  const { getError } = useGraphQLError()
  const { track } = useSegment()

  const onRetry: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      track(SegmentEvents.RetryButtonClicked, {
        event: e,
      })
      void retry?.()
    },
    [retry, track],
  )

  if (error) {
    return (
      <Statement
        data-testid={`${id}-error-boundary`}
        status={status}
        subtitle={getError(error, fallbackError)}
        variant={variant}
      >
        {retry && (
          <Button
            data-testid="retry-button"
            onClick={onRetry}
            variant="secondary"
          >{t`retry`}</Button>
        )}
      </Statement>
    )
  }

  return <>{children}</>
}

import { useCallback } from 'react'

import { SkeletonBox, Icon, CodeInput } from '@circlefin/components'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useFactorQuery, FactorType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { NoFactorError } from '../../../containers'

import type { MfaCode } from '@shared/apollo/links'

export interface ScaCodesOktaVerifyProps {
  /**
   * Callback for code complete.
   */
  onComplete: (mfaCode: MfaCode) => void
}

export const ScaCodesOktaVerify: React.FC<ScaCodesOktaVerifyProps> = ({
  onComplete,
}) => {
  const { t } = useTranslation('mfa')

  const { data, loading, error, refetch } = useFactorQuery({
    variables: {
      // NOTE: The DS is feature switched so this will still return legacy TOTP factor
      factorType: FactorType.PUSH,
    },
  })

  const handleComplete = useCallback(
    (code: string) => {
      if (data?.factor) {
        onComplete({
          code,
          id: data?.factor?.id,
        })
      }
    },
    [data?.factor, onComplete],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      {!loading && !data?.factor ? (
        <NoFactorError />
      ) : (
        <div className="flex h-full flex-col items-center justify-center text-center">
          <SkeletonBox className="mb-6 size-16 rounded-full" loading={loading}>
            <Icon.Circle
              className="mb-6"
              intent="accent-blue"
              name="Misc/OKTA"
              size="large"
            />
          </SkeletonBox>
          <h3 className="mb-4 text-lg font-circular-bold">
            <SkeletonBox className="h-7 w-60" loading={loading}>
              {t`factor.okta-verify.enter-code.title`}
            </SkeletonBox>
          </h3>
          <p className="mb-6 text-black-500">
            <SkeletonBox className="h-5 w-80" loading={loading}>
              {t`factor.okta-verify.enter-code.description`}
            </SkeletonBox>
          </p>
          <SkeletonBox className="h-11 w-64" loading={loading}>
            <CodeInput
              allowedCharacters="numeric"
              length={6}
              onComplete={handleComplete}
            />
          </SkeletonBox>
        </div>
      )}
    </GraphQLErrorBoundary>
  )
}

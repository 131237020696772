import {
  PUBLIC_ONLY,
  ROLR_SECTION,
  SANDBOX_SIGN_UP_SECTION,
} from '@services/permissions'

import type { Section } from '../types'

export const sandboxSignUp: Section = {
  label: 'layout:page.signup',
  route: '/signup/sandbox',
  permissions: SANDBOX_SIGN_UP_SECTION,
  segment: {
    page: 'Sandbox sign up',
  },
}

export const rolrSection: Section = {
  label: 'layout:page.signup',
  route: '/signup/rolr',
  permissions: ROLR_SECTION,
  segment: {
    page: 'Sign up - ROLR',
  },
}

export const rolrBusinessInfoSection: Section = {
  label: 'layout:page.signup',
  route: '/signup/rolr/business-info',
  permissions: ROLR_SECTION,
  segment: {
    page: 'Sign up - ROLR Business Information',
  },
}

export const rolrAuthRepSection: Section = {
  label: 'layout:page.signup',
  route: '/signup/rolr/auth-rep',
  permissions: ROLR_SECTION,
  segment: {
    page: 'Sign up - ROLR Auth Rep',
  },
}

export const rolrAmountSection: Section = {
  label: 'layout:page.signup',
  route: '/signup/rolr/amount',
  permissions: ROLR_SECTION,
  segment: {
    page: 'Sign up - ROLR Amount',
  },
}

export const rolrCreateAccountSection: Section = {
  label: 'layout:page.signup',
  route: '/signup/rolr/create-account',
  permissions: ROLR_SECTION,
  segment: {
    page: 'Sign up - ROLR Create Account',
  },
}

export const signupSection: Section = {
  label: 'layout:page.signup',
  route: '/signup',
  permissions: PUBLIC_ONLY,
  pages: [
    sandboxSignUp,
    rolrSection,
    rolrBusinessInfoSection,
    rolrAuthRepSection,
    rolrAmountSection,
    rolrCreateAccountSection,
  ],
  segment: {
    page: 'Sign up',
  },
}

import { useContext, useMemo } from 'react'

import { Logo } from '@circlefin/components'
import { FeatureSwitch } from '@services/feature-switch'
import { SandboxBadge } from '@shared/components/layout'
import classnames from 'classnames'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { MainNavContext } from '../MainNav.Context/MainNav.Context'

import type { Section } from '@services/sections/types'

export interface MainNavLogoProps {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Logo Section.
   */
  section: Section
}

export const MainNavLogo: React.FC<MainNavLogoProps> = ({
  section,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const { collapsed } = useContext(MainNavContext)

  const size = useMemo(() => (collapsed ? 2 : 7.7), [collapsed])

  return (
    <header className={classnames('flex p-5 pb-8', className)} {...props}>
      <Link
        aria-label={t(section.label)}
        className="inline-block w-fit p-0"
        href={section.route}
        title={t(section.label)}
      >
        <Logo size={size} wordMark={!collapsed} dark />
      </Link>
      {FeatureSwitch.sandboxBadgeEnabled() && (
        <SandboxBadge className="ml-3 flex items-center overflow-visible" />
      )}
    </header>
  )
}

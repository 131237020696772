import classNames from 'classnames'

export interface SubTitleProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

export const SubTitle: React.FC<SubTitleProps> = ({ className, children }) => {
  return (
    <div
      className={classNames('text-base leading-6 text-black-400', className)}
    >
      {children}
    </div>
  )
}

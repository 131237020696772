import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseHeader } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  WatchLists,
  WatchlistDocument,
  useAddEntriesToWatchListMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { AddItemForm } from './AddItemForm/AddItem.Form'

import type { AddItemFormValues } from './AddItemForm/AddItem.Form'

export interface AddToWatchListQueryProps {
  /**
   * Type of operation: `allow` or `block`.
   */
  variant?: WatchLists
}

export const AddToWatchList: React.FC<AddToWatchListQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/fraud-management')
  const { router } = useModal()
  const { track } = useSegment()

  const trackWatchList = useCallback(
    (variant: WatchLists) => {
      variant === WatchLists.allow
        ? track(SegmentEvents.AllowEntityClicked)
        : track(SegmentEvents.BlockEntityClicked)
    },
    [track],
  )

  const [addEntriesToWatchList, { error, reset }] =
    useAddEntriesToWatchListMutation({
      refetchQueries: [WatchlistDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        router.push({
          pathname:
            routes.account.security.fraudManagement.addToWatchList.success,
          query: {
            variant: props.variant,
          },
          options: {
            disableBack: true,
          },
        })
      },
    })

  const onSubmit = useCallback(
    (values: AddItemFormValues) => {
      if (props.variant != null) {
        void addEntriesToWatchList({
          variables: {
            input: {
              variant: props.variant,
              entries: [
                {
                  ...values,
                },
              ],
            },
          },
        })
        trackWatchList(props.variant)
      }
    },
    [addEntriesToWatchList, props.variant, trackWatchList],
  )

  return (
    <BaseHeader
      className="min-h-96 px-10"
      title={
        props.variant === WatchLists.allow
          ? t`allowList.title`
          : t`blockList.title`
      }
    >
      <PropsErrorBoundary<AddToWatchListQueryProps>
        props={props}
        variant="page"
      >
        {({ variant }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <p className="text-black-400">
              {variant === WatchLists.allow
                ? t`allowList.instructions`
                : t`blockList.instructions`}
            </p>
            <AddItemForm onSubmit={onSubmit} variant={variant} />
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseHeader>
  )
}

AddToWatchList.displayName = 'AddToWatchList'

import { useCallback, useState } from 'react'

import { Button, FileUploader } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { TransWithLink } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useResetPin } from '../ResetPin.Context'

const ONE_HUNDRED_MEGABYTES = 100000000

export const UploadBackup: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [, { setWalletBackupFile }] = useResetPin()
  const modal = useModal()

  const [uploadedFile, setUploadedFile] = useState<File>()

  const onContinue = useCallback(() => {
    void modal.router.push(routes.vault.resetPin.enterPassphrase)
  }, [modal])

  const onFileSelected = useCallback(
    (walletBackupFile: File | null) => {
      if (!walletBackupFile) {
        return
      }

      const fileReader = new FileReader()
      fileReader.readAsText(walletBackupFile)
      fileReader.onload = () => {
        setWalletBackupFile(fileReader.result as string)
        setUploadedFile(walletBackupFile)
      }
    },
    [setWalletBackupFile],
  )

  return (
    <FullScreen totalSteps={5}>
      <Center className="m-auto justify-items-center" variant="horizontal">
        <h2 className="text-4xl text-black-600 font-circular-bold">{t`resetPin.title`}</h2>

        <h3 className="mb-1 mt-14 text-center text-xl leading-7 font-circular-medium">
          {t`resetPin.uploadBackup.title`}
        </h3>

        <span className="mb-10 text-center text-lg font-circular-regular">
          <TransWithLink
            i18nKey="modals.vault:resetPin.uploadBackup.subtitle"
            variant="internal-support"
          />
        </span>

        <FileUploader
          className="text-center"
          maxSize={ONE_HUNDRED_MEGABYTES}
          onFileSelected={onFileSelected}
        >
          <div>
            {!uploadedFile ? (
              <Trans
                components={{
                  b: <b className="text-blue-500 font-circular-book" />,
                }}
                i18nKey="modals.vault:resetPin.uploadBackup.uploadNewFile"
              />
            ) : (
              <>
                <p>{uploadedFile.name}</p>
                <p className="text-blue-500 font-circular-book">{t`resetPin.uploadBackup.uploadDifferentFile`}</p>
              </>
            )}
          </div>
        </FileUploader>

        <div className="mt-14">
          <Button
            className="w-60"
            disabled={!uploadedFile}
            onClick={onContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Button>
        </div>
      </Center>
    </FullScreen>
  )
}

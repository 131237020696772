import { useCallback } from 'react'

import { ButtonCard, Heading, Icon, Tooltip } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVaultWallet } from '../CreateVaultWallet.Context'

import type { AssetBlockchain } from '@shared/graphql'

export const ChooseBlockchain: React.FC = () => {
  const modal = useModal()
  const { t } = useTranslation('modals.vault')

  const [{ asset }, { setBlockchain }] = useCreateVaultWallet()

  const handleBlockchainSelection = useCallback(
    (blockchain: AssetBlockchain) => {
      return () => {
        setBlockchain(blockchain)
        modal.router.push(routes.vault.createVaultWallet.confirm)
      }
    },
    [modal.router, setBlockchain],
  )

  return (
    <FullScreen totalSteps={4}>
      <Center className="text-center" variant="horizontal">
        <h2 className="mb-4 text-2xl text-black-900 font-circular-bold">
          {t`createVaultWallet.chooseBlockchain.title`}
        </h2>

        <p className="max-w-176 text-lg leading-6 font-circular-book">
          <Trans
            components={{ b: <b /> }}
            i18nKey="modals.vault:createVaultWallet.chooseBlockchain.subtitle"
            values={{ asset: asset?.name }}
          />
        </p>

        <div className="mt-8 grid w-160 grid-cols-4 gap-3 text-black-600">
          {asset?.blockchains.map(
            ({ chain, icon, available, requiresActivation }, i) => {
              const chainName = t(`payments:chainNames.${chain}`)
              return (
                <div key={i} className="relative">
                  <ButtonCard
                    key={chain}
                    className="h-32"
                    disabled={!available}
                    onClick={handleBlockchainSelection({
                      chain,
                      icon,
                      available,
                      requiresActivation,
                    })}
                    variant="primary/sm"
                    hideArrow
                  >
                    <Heading
                      alignment="center"
                      iconName={icon}
                      layout="vertical"
                      variant="title/sm"
                    >
                      <Heading.Header>{chainName}</Heading.Header>
                    </Heading>
                  </ButtonCard>
                  {!available && (
                    <Tooltip
                      className="z-10"
                      content={t('createVaultWallet.chooseBlockchain.tooltip', {
                        token: asset.symbol,
                        chain: chainName,
                      })}
                      place="right"
                      width="hug"
                    >
                      <div className="absolute right-3 top-3">
                        <Icon
                          className="text-black-300"
                          height={18}
                          name="InformationCircleOutline"
                          width={18}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              )
            },
          )}
        </div>
      </Center>
    </FullScreen>
  )
}
export default ChooseBlockchain

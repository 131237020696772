import { Button, Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { FeatureSwitch } from '@services/feature-switch'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainRecipientAddressWithMetadata } from '@shared/graphql'

const schema = y.object({
  /**
   * (required) Recipient name.
   */
  name: y.string().required(),
  /**
   * (optional) Recipient email.
   */
  email: y.string().email(),
})

export type EditFormValues = y.InferType<typeof schema>

export interface EditFormProps {
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: EditFormValues) => void
  /**
   * On Form Cancel callback.
   */
  onCancel: () => void
  /**
   * Recipient address with metadata.
   */
  recipientAddressWithMetadata: BlockchainRecipientAddressWithMetadata
  /**
   * Loading state.
   */
  loading: boolean
}

export const EditRecipientForm: React.FC<EditFormProps> = ({
  recipientAddressWithMetadata,
  onSubmit,
  onCancel,
  loading,
}) => {
  const { t } = useTranslation('address-book/modals')

  const [Form] = useForm<EditFormValues>({
    schema,
    values: {
      name: recipientAddressWithMetadata?.description ?? '',
      email: recipientAddressWithMetadata?.email ?? '',
    },
  })

  return (
    <Form onSubmit={onSubmit}>
      <div className="mt-4 flex flex-col items-center justify-center">
        <Form.Input
          className="mb-4 h-24 w-96"
          data-testid="name-input"
          disabled={loading}
          display="floating"
          label={t`editRecipient.recipientName`}
          name="name"
        />
        {FeatureSwitch.newAddressBookEmailsEnabled() && (
          <Form.Input
            className="mb-4 h-24 w-96"
            data-testid="email-input"
            disabled={loading}
            display="floating"
            label={t`editRecipient.recipientEmail`}
            name="email"
          />
        )}
      </div>
      <Modal.Footer variant="stretch-equal">
        <Button
          data-testid="cancel-btn"
          disabled={loading}
          onClick={onCancel}
          variant="secondary"
        >
          {t`editRecipient.cancel`}
        </Button>
        <Form.SubmitButton
          data-testid="submit-btn"
          loading={loading}
          variant="primary"
        >
          {t`editRecipient.submit`}
        </Form.SubmitButton>
      </Modal.Footer>
    </Form>
  )
}

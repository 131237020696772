import { useMemo } from 'react'
import type { FieldErrors } from 'react-hook-form'

import { FixedBanner } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVault } from '../../../../hooks/create'

import type { VaultPolicyLimits } from '@shared/graphql'

interface WarningsProps {
  /**
   * Current form values.
   */
  currentValues: VaultPolicyLimits
  /**
   * Current errors.
   */
  errors: FieldErrors<VaultPolicyLimits>
}

export const Warnings: React.FC<WarningsProps> = ({
  currentValues: { maxAmount, maxTransactions },
  errors,
}) => {
  const { t } = useTranslation('modals.vault')
  const { money } = useMoney({ locale: 'en-US' })

  const [{ usersPolicy }] = useCreateVault()

  const showMaximumAmountPerDayWarning = useMemo(() => {
    return (
      !errors.maxAmount &&
      Number(maxAmount ?? 0) > 0 &&
      (usersPolicy ?? []).some(
        (userPolicy) => Number(userPolicy.maxAmount ?? 0) > Number(maxAmount),
      )
    )
  }, [errors.maxAmount, maxAmount, usersPolicy])

  const showMaximumTransactionsPerDayWarning = useMemo(() => {
    return (
      !errors.maxTransactions &&
      Number(maxTransactions ?? 0) > 0 &&
      (usersPolicy ?? []).some(
        (userPolicy) =>
          Number(userPolicy.maxTransactions ?? 0) > Number(maxTransactions),
      )
    )
  }, [errors.maxTransactions, maxTransactions, usersPolicy])

  return (
    <>
      <FixedBanner
        className="mt-4"
        status="warning"
        visible={showMaximumAmountPerDayWarning}
      >
        <FixedBanner.Description>
          {maxAmount &&
            t('createVault.limits.policy.warnings.maxAmount', {
              amount: money({
                number: maxAmount,
                variant: Currency.USD,
              }),
            })}
        </FixedBanner.Description>
      </FixedBanner>

      <FixedBanner
        className="mt-4"
        status="warning"
        visible={showMaximumTransactionsPerDayWarning}
      >
        <FixedBanner.Description>
          {t('createVault.limits.policy.warnings.maxTransactions', {
            value: maxTransactions,
          })}
        </FixedBanner.Description>
      </FixedBanner>
    </>
  )
}

import { useCallback, useMemo } from 'react'

import { InlineNotification } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Currency, useTransferInstructionQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { DepositInstructionGrid } from '../../../../components/DepositInstructionGrid/DepositInstructionGrid'

import type { BankAccount } from '@shared/graphql'

const schema = y.object({
  virtual: y.boolean(),
  /**
   * Checkbox for Non-Virtual Account.
   */
  nonVirtualAccountAgreement: y.boolean().when('virtual', {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required().oneOf([true]),
  }),
})

/**
 * State managed by the form.
 */
export type BeneficiaryAgreementProps = y.InferType<typeof schema>

export interface DepositInstructionsFormProps {
  /**
   * Cta button label.
   */
  ctaButtonText?: string
  /**
   * The selected bank account.
   */
  bankAccount: BankAccount
  /**
   * Currency specific beneficiary bank account details.
   */
  currency?: Currency
  /**
   * Cb that will be called when form is submitted.
   */
  onContinue: (formValues: BeneficiaryAgreementProps) => void
}

export const DepositInstructionsForm: React.FC<DepositInstructionsFormProps> =
  ({ ctaButtonText, bankAccount, currency = Currency.USD, onContinue }) => {
    const { t } = useTranslation('modals.transfer')
    const { track } = useSegment()

    const { loading, data, error, refetch } = useTransferInstructionQuery({
      variables: {
        input: {
          bankAccountId: bankAccount.id,
          type: bankAccount.type,
          currency,
        },
      },
    })

    const isVirtualAccount = useMemo(() => {
      return (
        Boolean(
          'virtualAccountEnabled' in bankAccount &&
            bankAccount.virtualAccountEnabled,
        ) ||
        Boolean(
          data &&
            'virtualAccountEnabled' in data.transferInstruction &&
            data.transferInstruction.virtualAccountEnabled,
        )
      )
    }, [bankAccount, data])

    const [Form] = useForm<BeneficiaryAgreementProps>({
      schema,
      values: {
        virtual: isVirtualAccount,
      },
    })

    const handleSubmit = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        track(SegmentEvents.ContinueClicked, {
          event: e,
        })
      },
      [track],
    )

    return (
      <GraphQLErrorBoundary error={error} retry={refetch} variant="component">
        <Form
          className="flex flex-col justify-between gap-y-5 font-circular"
          onSubmit={onContinue}
        >
          <div className="mt-2" data-testid="transfer-instruction-grid">
            <DepositInstructionGrid
              bankAccount={bankAccount}
              currency={currency}
              loading={loading}
              transferInstruction={data?.transferInstruction}
              virtualAccountEnabled={isVirtualAccount}
            />
          </div>

          <div className="grid grid-cols-1 gap-y-4">
            {!isVirtualAccount && (
              <Form.Checkbox
                label={t(
                  `fx.beneficiaryBankAccountDetails.nonVirtualAccountCheckboxAgreement`,
                )}
                name="nonVirtualAccountAgreement"
              />
            )}

            <InlineNotification intent="warning">
              <div className="text-neutral-subtle type-body-sm">
                {t('fx.agreement.disclaimer')}
              </div>
            </InlineNotification>

            <Form.SubmitButton
              className="w-full"
              onClick={handleSubmit}
              variant="primary"
            >
              {ctaButtonText ?? t('common:continue')}
            </Form.SubmitButton>
          </div>
        </Form>
      </GraphQLErrorBoundary>
    )
  }

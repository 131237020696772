import { FeatureSwitch } from '@services/feature-switch'
import {
  RESTRICTED,
  L3_ONBOARDING,
  PREQUAL_ONBOARDING,
} from '@services/permissions'

import type { Section } from '../types'

const sharedOnboarding: Partial<Section> = {
  takeover: {
    periodicReview: false,
  },
}

export const l3Onboarding: Section = {
  ...sharedOnboarding,
  label: 'layout:page.onboarding.l3.title',
  route: '/onboarding/l3',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingTwoFactor: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.twoFactor',
  route: '/onboarding/l3/two-factor',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationEntityOperations: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.entityOperations',
  route: '/onboarding/l3/application/additional-information/entity-operations',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationBusinessInformation: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.businessDetails',
  route: '/onboarding/l3/application/business-details/business-information',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationBusinessRegistration: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.businessRegistrationDetails',
  route: '/onboarding/l3/application/business-details/business-registration',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationPhysicalAddress: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.physicalBusinessAddress',
  route:
    '/onboarding/l3/application/business-details/physical-business-address',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationRegisteredAddress: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.registeredOfficeAddress',
  route:
    '/onboarding/l3/application/business-details/registered-business-address',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationIntendedUse: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.intendedUseOfAccount',
  route: '/onboarding/l3/application/business-details/intended-use',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationBeneficialOwners: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.beneficialOwners',
  route: '/onboarding/l3/application/related-parties/beneficial-owners',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationTokenHolders: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.tokenHolders',
  route: '/onboarding/l3/application/related-parties/token-holders',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationLegalControl: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.individualsWithSignificantControl',
  route: '/onboarding/l3/application/related-parties/legal-control',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationRelatedPartiesToTrust: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.trustRelatedParties',
  route: '/onboarding/l3/application/related-parties/related-parties',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationInvestors: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.investors',
  route: '/onboarding/l3/application/related-parties/investors',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationTopFiveInvestors: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.investors',
  route: '/onboarding/l3/application/related-parties/top-five-investors',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationDonors: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.donors',
  route: '/onboarding/l3/application/related-parties/donors',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationDonorsAbove25Pct: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.donors',
  route: '/onboarding/l3/application/related-parties/donors-above-25-pct',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationAssetManager: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.assetManager',
  route: '/onboarding/l3/application/related-parties/asset-manager',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationPartyPerformingInvestorDueDiligence: Section =
  {
    ...sharedOnboarding,
    label: 'l3Onboarding:navigation.partyPerformingInvestorDueDiligence',
    route:
      '/onboarding/l3/application/related-parties/party-performing-investor-due-diligence',
    permissions: L3_ONBOARDING,
  }

export const l3OnboardingApplicationLicensing: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.licensingAndRegistration',
  route: '/onboarding/l3/application/additional-information/licensing',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationFinancialCrimesInformation: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.financialCrimesCompliance',
  route:
    '/onboarding/l3/application/additional-information/financial-crimes-information',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationAdditionalUsers: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.additionalUsers',
  route: '/onboarding/l3/application/additional-users',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationAdditionalUserKyc: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.additionalUsersKyc',
  route: '/onboarding/l3/application/individual-information',
  // Remove FS check in: [https://circlepay.atlassian.net/browse/BBSS-1208].
  permissions: FeatureSwitch.isCopNewUserOnboardingEnabled()
    ? PREQUAL_ONBOARDING
    : RESTRICTED,
}

export const l3OnboardingApplicationAuthorizedRepresentative: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.authorizedRepresentative',
  route: '/onboarding/l3/application/authorized-representative',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationCertifications: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.certifications',
  route: '/onboarding/l3/application/certifications',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationCertificationsKyc: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.kycCertifications',
  route: '/onboarding/l3/application/individual-certifications',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationTaxInfoEeaVat: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.taxInfoEea',
  route: '/onboarding/l3/application/tax-info-eea/vat',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationFatcaInformation: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.taxInfoEea',
  route: '/onboarding/l3/application/tax-info-eea/fatca-information',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingApplicationSelfCertificationCrs: Section = {
  ...sharedOnboarding,
  label: 'l3Onboarding:navigation.taxInfoEea',
  route: '/onboarding/l3/application/tax-info-eea/self-certification-crs',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingDenied: Section = {
  ...sharedOnboarding,
  label: 'layout:page.onboarding.l3.title',
  route: '/onboarding/l3/denied',
  permissions: L3_ONBOARDING,
}

export const l3OnboardingInitiate: Section = {
  ...sharedOnboarding,
  label: 'layout:page.onboarding.l3.title',
  route: '/onboarding/initiate',
  // Remove FS check in: [https://circlepay.atlassian.net/browse/BBSS-1208].
  permissions: FeatureSwitch.isCopNewUserOnboardingEnabled()
    ? PREQUAL_ONBOARDING
    : RESTRICTED,
}

export const onboardingSection: Section = {
  label: '',
  route: '/onboarding', // for now, no root /onboarding UI
  permissions: RESTRICTED,
  team: 'account-web-onboarding',
  pages: [
    l3Onboarding,
    l3OnboardingTwoFactor,
    l3OnboardingApplicationBusinessRegistration,
    l3OnboardingApplicationPhysicalAddress,
    l3OnboardingApplicationRegisteredAddress,
    l3OnboardingApplicationIntendedUse,
    l3OnboardingApplicationBusinessInformation,
    l3OnboardingApplicationEntityOperations,
    l3OnboardingApplicationBeneficialOwners,
    l3OnboardingApplicationTokenHolders,
    l3OnboardingApplicationLegalControl,
    l3OnboardingApplicationRelatedPartiesToTrust,
    l3OnboardingApplicationInvestors,
    l3OnboardingApplicationTopFiveInvestors,
    l3OnboardingApplicationDonors,
    l3OnboardingApplicationDonorsAbove25Pct,
    l3OnboardingApplicationAssetManager,
    l3OnboardingApplicationPartyPerformingInvestorDueDiligence,
    l3OnboardingApplicationLicensing,
    l3OnboardingApplicationFinancialCrimesInformation,
    l3OnboardingApplicationAdditionalUsers,
    l3OnboardingApplicationAdditionalUserKyc,
    l3OnboardingApplicationAuthorizedRepresentative,
    l3OnboardingApplicationCertifications,
    l3OnboardingApplicationCertificationsKyc,
    l3OnboardingDenied,
    l3OnboardingInitiate,
    l3OnboardingApplicationTaxInfoEeaVat,
    l3OnboardingApplicationFatcaInformation,
    l3OnboardingApplicationSelfCertificationCrs,
  ],
}

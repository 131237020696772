import { useCallback } from 'react'

import { VerticalStepper } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export enum CreateVaultNavigationStep {
  POLICY_NAME = 'policyName',
  PERMISSIONS = 'permissions',
  POLICY_LIMITS = 'limits',
  USER_LIMITS = 'limits/user',
  OUTGOING_ADDRESS = 'outgoingAddress',
  APPROVAL = 'approval',
  WALLET_CONNECT = 'walletConnect',
  REVIEW = 'review',
  FINISHED = 'finished',
}

export interface NavigationProps {
  /**
   * The active step.
   */
  currentStep: CreateVaultNavigationStep
  /**
   * Is policy name step valid.
   */
  isPolicyNameValid?: boolean
  /**
   * Is permissions step valid.
   */
  isPermissionsValid?: boolean
  /**
   * Is policy limits step valid.
   */
  isPolicyLimitsValid?: boolean
  /**
   * Is user limits step valid.
   */
  isUserLimitsValid?: boolean
  /**
   * Is outgoing address step valid.
   */
  isOutgoingAddressValid?: boolean
  /**
   * Is approval workflow step valid.
   */
  isApprovalWorkflowValid?: boolean
  /**
   * Is wallet connect step valid.
   */
  isWalletConnectValid?: boolean
  /**
   * Is review step valid.
   */
  isReviewValid?: boolean
  /**
   * Is wallet connect step visible.
   */
  isWalletConnectStepVisible?: boolean
  /**
   * Is flow complete.
   */
  isFlowComplete?: boolean
}

export const Navigation: React.FC<NavigationProps> = ({
  currentStep,
  isPolicyNameValid,
  isPolicyLimitsValid,
  isPermissionsValid,
  isUserLimitsValid,
  isOutgoingAddressValid,
  isApprovalWorkflowValid,
  isWalletConnectValid,
  isReviewValid,
  isWalletConnectStepVisible,
  isFlowComplete,
}) => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const isLimitsValid = isPolicyLimitsValid && isUserLimitsValid

  const handleStepClick = useCallback(
    (route: string) => {
      return () => modal.router.push(route)
    },
    [modal],
  )

  return (
    <VerticalStepper isFlowComplete={isFlowComplete}>
      <VerticalStepper.Step
        isActive={currentStep === CreateVaultNavigationStep.POLICY_NAME}
        isStepComplete={isPolicyNameValid}
        label={t`createVault.steps.name`}
        onClick={handleStepClick(routes.vault.createVault.policyName)}
      />
      <VerticalStepper.Step
        isActive={currentStep === CreateVaultNavigationStep.PERMISSIONS}
        isDisabled={!isPolicyNameValid}
        isStepComplete={isPermissionsValid}
        label={t`createVault.steps.permissions`}
        onClick={handleStepClick(routes.vault.createVault.permissions)}
      />
      <VerticalStepper.Step
        isActive={
          currentStep === CreateVaultNavigationStep.POLICY_LIMITS ||
          currentStep === CreateVaultNavigationStep.USER_LIMITS
        }
        isDisabled={!isPermissionsValid}
        isStepComplete={isLimitsValid}
        label={t`createVault.steps.limits`}
        onClick={handleStepClick(routes.vault.createVault.policyLimits)}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === CreateVaultNavigationStep.POLICY_LIMITS}
          isStepComplete={isPolicyLimitsValid}
          label={t`createVault.steps.policyLimits`}
          onClick={handleStepClick(routes.vault.createVault.policyLimits)}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === CreateVaultNavigationStep.USER_LIMITS}
          isDisabled={!isPolicyLimitsValid}
          isStepComplete={isUserLimitsValid}
          label={t`createVault.steps.userLimits`}
          onClick={handleStepClick(routes.vault.createVault.userLimits)}
        />
      </VerticalStepper.Step>
      <VerticalStepper.Step
        isActive={currentStep === CreateVaultNavigationStep.OUTGOING_ADDRESS}
        isDisabled={!isLimitsValid}
        isStepComplete={isOutgoingAddressValid}
        label={t`createVault.steps.addresses`}
        onClick={handleStepClick(routes.vault.createVault.outgoingAddress)}
      />
      <VerticalStepper.Step
        isActive={currentStep === CreateVaultNavigationStep.APPROVAL}
        isDisabled={!isOutgoingAddressValid}
        isStepComplete={isApprovalWorkflowValid}
        label={t`createVault.steps.approval`}
        onClick={handleStepClick(routes.vault.createVault.approval)}
      />
      <VerticalStepper.Step
        isActive={currentStep === CreateVaultNavigationStep.WALLET_CONNECT}
        isDisabled={!isOutgoingAddressValid}
        isHidden={!isWalletConnectStepVisible}
        isStepComplete={isWalletConnectValid}
        label={t`createVault.steps.walletConnect`}
        onClick={handleStepClick(routes.vault.createVault.walletConnect)}
      />
      <VerticalStepper.Step
        isActive={
          currentStep === CreateVaultNavigationStep.REVIEW ||
          currentStep === CreateVaultNavigationStep.FINISHED
        }
        isDisabled={!isReviewValid}
        label={t`createVault.steps.reviewAndFinish`}
        onClick={handleStepClick(routes.vault.createVault.review)}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === CreateVaultNavigationStep.REVIEW}
          isStepComplete={currentStep === CreateVaultNavigationStep.FINISHED}
          label={t`createVault.steps.review`}
          onClick={handleStepClick(routes.vault.createVault.review)}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === CreateVaultNavigationStep.FINISHED}
          isDisabled={!isFlowComplete}
          label={t`createVault.steps.finished`}
          onClick={handleStepClick(routes.vault.createVault.finished)}
        />
      </VerticalStepper.Step>
    </VerticalStepper>
  )
}
export default Navigation

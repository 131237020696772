import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import {
  AddRoute,
  DepositInstruction,
  DisableRoute,
  WithdrawalInstruction,
} from '@features/express/modals'
import { express } from '@services/sections/modal/express/express'

export const ExpressRouter: React.FC = () => {
  return (
    <>
      {/* Instruction Route */}
      <ModalRoute
        content={<DepositInstruction />}
        path={express.instruction.deposit}
      />
      <ModalRoute
        content={<WithdrawalInstruction />}
        path={express.instruction.withdrawal}
      />

      {/* Add Route */}
      <ModalSwitch path={express.addRoute.switch}>
        <AddRoute.Provider>
          <ModalRoute
            content={<AddRoute.Deposit />}
            path={express.addRoute.deposit}
          />
          <ModalRoute
            content={<AddRoute.Direction />}
            path={express.addRoute.direction}
          />
          <ModalRoute
            content={<AddRoute.Review />}
            path={express.addRoute.review}
          />
          <ModalRoute
            content={<AddRoute.Withdrawal />}
            path={express.addRoute.withdrawal}
          />
        </AddRoute.Provider>
      </ModalSwitch>

      {/* Disable Route */}
      <ModalRoute content={<DisableRoute />} path={express.disableRoute} />
    </>
  )
}

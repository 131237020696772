/**
 * Account: Users Routes.
 */
export const users = {
  remove: '/account/users/remove',
  removeWithAdminApproval: '/account/users/removeWithAdminApproval',
  resendInvite: '/account/users/resendInvite',
  cancelApprovalRequest: '/account/users/cancelApprovalRequest',
  vault: {
    removeWarning: {
      default: '/account/users/vault/removeWarning/default',
      owner: '/account/users/vault/removeWarning/owner',
    },
  },
  invite: {
    error: '/account/users/invite/error',
    success: '/account/users/invite/success',
  },
  password: {
    error: '/account/users/password/error',
    success: '/account/users/password/success',
  },
}

import { useContext } from 'react'

import { RolrSetupContext } from './context'

export const useRolrSetup = () => {
  const context = useContext(RolrSetupContext)

  if (context === null) {
    throw new Error('useRolrSetup must be used within a RolrSetupProvider')
  }

  return context
}

import { useCallback, useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Currency, useTransferInstructionQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransferInstructionGrid } from './TransferInstructionGrid'

import type { BankAccount } from '@shared/graphql'

const schema = y.object({
  /**
   * Checkbox state.
   */
  beneficiaryAgreement: y.boolean().required().oneOf([true]),
})

/**
 * State managed by the form.
 */
export type BeneficiaryFormProps = y.InferType<typeof schema>

export interface BeneficiaryBankAccountFormProps {
  /**
   * Initial form values.
   */
  initialValues?: BeneficiaryFormProps
  /**
   * Cta button label.
   */
  ctaButtonText?: string
  /**
   * The selected bank account.
   */
  bankAccount: BankAccount
  /**
   * Currency-specific beneficiary bank account details.
   */
  currency?: Currency
  /**
   * Cb that will be called when the form is submitted.
   */
  onContinue: (formValues: BeneficiaryFormProps) => void
}

export const BeneficiaryBankAccountForm: React.FC<BeneficiaryBankAccountFormProps> =
  ({
    initialValues,
    ctaButtonText,
    bankAccount,
    currency = Currency.USD,
    onContinue,
  }) => {
    const { t } = useTranslation('payments')
    const { track } = useSegment()

    const { loading, data, error, refetch } = useTransferInstructionQuery({
      variables: {
        input: {
          bankAccountId: bankAccount.id,
          type: bankAccount.type,
          currency,
        },
      },
    })

    const isVirtualAccount = useMemo(() => {
      return (
        Boolean(
          'virtualAccountEnabled' in bankAccount &&
            bankAccount.virtualAccountEnabled,
        ) ||
        Boolean(
          data &&
            'virtualAccountEnabled' in data.transferInstruction &&
            data.transferInstruction.virtualAccountEnabled,
        )
      )
    }, [bankAccount, data])

    const [Form] = useForm<BeneficiaryFormProps>({
      schema,
      values: {
        beneficiaryAgreement: isVirtualAccount
          ? true
          : initialValues?.beneficiaryAgreement ?? false,
      },
    })

    const handleSubmit = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        track(SegmentEvents.ContinueClicked, {
          event: e,
        })
      },
      [track],
    )

    return (
      <GraphQLErrorBoundary error={error} retry={refetch} variant="component">
        <Form
          className="flex flex-col justify-between gap-y-5 font-circular"
          onSubmit={onContinue}
        >
          <div data-testid="beneficiary-details-step">
            <div
              className="mt-2"
              data-testid="beneficiary-details-field-container"
            >
              <TransferInstructionGrid
                bankAccount={bankAccount}
                currency={currency}
                loading={loading}
                transferInstruction={data?.transferInstruction}
              />
            </div>
          </div>

          <div className="mt-7 grid grid-cols-1 justify-items-center gap-y-6">
            {!isVirtualAccount ? (
              <Form.Checkbox
                label={t(
                  `depositFlow.beneficiaryBankAccountDetails.checkBoxAgreement.${bankAccount.type}`,
                )}
                name="beneficiaryAgreement"
              />
            ) : null}
            <FixedBanner status="info" visible={isVirtualAccount} multiline>
              <FixedBanner.Description>
                <span>
                  <TransWithLink
                    i18nKey="payments:depositFlow.beneficiaryBankAccountDetails.virtualAccountAlert.message"
                    urlI18nKey="payments:depositFlow.beneficiaryBankAccountDetails.virtualAccountAlert.link"
                    variant="url"
                  />
                </span>
              </FixedBanner.Description>
            </FixedBanner>
            <Form.SubmitButton
              className="w-64"
              onClick={handleSubmit}
              variant="primary"
            >
              {ctaButtonText ?? t('common:continue')}
            </Form.SubmitButton>
          </div>
        </Form>
      </GraphQLErrorBoundary>
    )
  }

import { Header } from '@circlefin/components'
import { HistoryBackButton } from '@containers/layout'
import { TransactionCard } from '@shared/components/common'
import { Content } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import type { TransactionCardProps } from '@shared/components/common'
import type { Payment } from '@shared/graphql'

export interface TransactionDetailProps
  extends Omit<TransactionCardProps, 'transaction'> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Transaction.
   */
  transaction?: Payment
}

export const TransactionDetail: React.FC<TransactionDetailProps> = ({
  className,
  loading = false,
  ...cardProps
}) => {
  const { t } = useTranslation()

  return (
    <Content className={className}>
      {/* Header */}

      <HistoryBackButton />

      <Header variant="secondary">
        <Header.Head title={t`payments:transactionDetail.title`} />
        <Header.Description>
          {t('payments:transactionDetail.transactionId', {
            id: cardProps.transaction?.id,
          })}
        </Header.Description>
      </Header>

      {/* Content */}
      <TransactionCard loading={loading} {...cardProps} />
    </Content>
  )
}

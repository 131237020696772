import { useContext } from 'react'

import { RedemptionOnlyExpressContext } from './context'

/**
 * Wallet Approval Policy context hook.
 */
export const useRedemptionOnlyExpress = () => {
  return useContext(RedemptionOnlyExpressContext)
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { TransferInformation } from '@features/blockchain/containers'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  useBlockchainDepositAddressQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useTransfer } from '../../../hooks/transfer'

import type { TransferState } from '../../../hooks/transfer/context'

export const TransferInstructions: React.FC = () => {
  const { t } = useTranslation('common')
  const [formState] = useTransfer()
  const { close } = useModal()
  const { track } = useSegment()
  const [{ currency }] = useCurrentCurrency()

  const { called, loading, data, error, refetch } =
    useBlockchainDepositAddressQuery({
      variables: {
        input: {
          abbreviation:
            // to please ts, set a default chain, notice we skip if the chain is falsy
            formState.chosenBlockchain ?? BlockchainAbbreviation.ETH,
          currency,
        },
      },
      skip: formState.chosenBlockchain == null,
    })

  const handleDoneClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.DoneClicked, {
        event,
      })

      close()
    },
    [track, close],
  )

  return (
    <FullScreen>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <PropsErrorBoundary<Pick<TransferState, 'chosenBlockchain'>>
          props={{ chosenBlockchain: formState.chosenBlockchain }}
          variant="page"
        >
          {({ chosenBlockchain }) => (
            <Center
              className="m-auto w-176 justify-items-center"
              variant="horizontal"
            >
              <TransferInformation
                abbreviation={chosenBlockchain}
                address={data?.blockchainDepositAddress?.address}
                addressTag={
                  data?.blockchainDepositAddress?.addressTag ?? undefined
                }
                currency={currency}
                loading={!called || loading}
              />
              <Button
                className="mt-12 w-60"
                onClick={handleDoneClick}
                variant="primary"
              >
                {t('done')}
              </Button>
            </Center>
          )}
        </PropsErrorBoundary>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { useCreateVault } from '../../../../hooks/create'

export const usePolicyNameValidate = () => {
  const [{ name, touched }] = useCreateVault()

  const isValid = Boolean(name)
  const isEmpty = !name
  const isTouched = touched?.name

  return {
    isValid,
    isEmpty,
    enableSaveExit: !isTouched || isValid,
  }
}


    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Toaster } from '@circlefin/components';
import { FormsProvider, PageHead } from '@containers/layout';
import { SegmentSetup, PageLoadProgress, IdleProvider, } from '@containers/services';
import { CustodyFeatureGuide } from '@features/custody/containers';
import { InAppNotificationProvider } from '@features/in-app-notifications/hooks';
import { MfaStepUpModal } from '@features/mfa/containers';
import { TakeoverManager } from '@features/onboarding/containers';
import { AnalyticsProvider } from '@features/services/containers';
import { FeatureSwitch } from '@services/feature-switch';
import { AuthenticatedPermissionProvider } from '@services/permissions/provider';
import { initApolloClient } from '@shared/apollo';
import { ErrorBoundary } from '@shared/components/common';
import { Meta } from '@shared/components/seo';
import { RouteHistoryProvider } from '@shared/hooks/common/router/history/provider';
import { AppLayout } from '@shared/layout/core';
import { SessionProvider } from 'next-auth/react';
import { ModalProvider } from '../modals/provider';
import type { NextPageWithLayout } from '@shared/layout/core';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import '@circlefin/components/lib/tailwind/components.css';
import '@circlefin/fonts/circular';
import '@circlefin/tailwind/css/style.css';
import 'intl-pluralrules'; // Intl.PluralRules Polyfill (used by next-translate) for Safari browser versions \< 13.
type AppPropsWithLayout<P = Record<string, never>> = Omit<AppProps<P>, 'Component'> & {
    Component: NextPageWithLayout | NextPage<Record<string, unknown>>;
};
/**
 * My Main App
 * First, we have to initialize Providers, to get the context in each page
 * and to be able to utilize hooks like permission and locales.
 */
function MyApp({ Component, pageProps, ...rest }: AppPropsWithLayout) {
    const apolloClient = useMemo(() => initApolloClient(pageProps.apolloState), [pageProps.apolloState]);
    return (<ApolloProvider client={apolloClient}>
      <SessionProvider refetchInterval={60} // Re-fetch session every minute
     session={pageProps.session}>
        <AuthenticatedPermissionProvider>
          <AnalyticsProvider>
            <IdleProvider>
              <Meta />
              <PageHead />
              <PageLoadProgress hide={isNextPageWithLayout(Component)
            ? Component.hideProgressBar
            : false}/>
              <RouteHistoryProvider>
                <InAppNotificationProvider>
                  <FormsProvider>
                    <ModalProvider>
                      <SegmentSetup />
                      <MfaStepUpModal />
                      {FeatureSwitch.isCustodyTourEnabled() && (<CustodyFeatureGuide />)}
                      <Toaster />
                      <TakeoverManager />
                      <ErrorBoundary>
                        {isNextPageWithLayout(Component) ? (<AppLayout Component={Component}>
                            {(error) => (<Component error={error} {...pageProps} {...rest}/>)}
                          </AppLayout>) : (<Component {...pageProps} {...rest}/>)}
                      </ErrorBoundary>
                    </ModalProvider>
                  </FormsProvider>
                </InAppNotificationProvider>
              </RouteHistoryProvider>
            </IdleProvider>
          </AnalyticsProvider>
        </AuthenticatedPermissionProvider>
      </SessionProvider>
    </ApolloProvider>);
}
const __Next_Translate__Page__19171aa6e51__ = MyApp;
function isNextPageWithLayout(Component: unknown): Component is NextPageWithLayout {
    return (Component != null &&
        typeof Component === 'function' &&
        `getLayout` in Component &&
        Component.getLayout !== undefined);
}

    export default __appWithI18n(__Next_Translate__Page__19171aa6e51__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  
import { useCallback } from 'react'

import { Button, Modal, SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  MerchantPaymentCancellationReason,
  useMerchantPaymentQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { PlatformForms } from '../../../forms'

import type { MerchantPaymentRefundInput } from '@shared/graphql'

const schema = y.object({
  /**
   * Amount to be refunded. This value should come from the payment data,
   * and it is read-only.
   */
  amount: y.number().positive().required(),
  /**
   * Reason for the refund to be requested.
   */
  reason: PlatformForms.Dropdown.reasonsSchema.required(),
})

export type RefundFormValues = y.InferType<typeof schema>

export interface RefundFormProps {
  /**
   * Payment ID to be refunded.
   */
  paymentId: string
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: MerchantPaymentRefundInput) => void
  /**
   * On Form Cancel callback.
   */
  onCancel: () => void
}

export const RefundForm: React.FC<RefundFormProps> = ({
  paymentId,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('platform/modals')

  const { data, loading, error, refetch } = useMerchantPaymentQuery({
    variables: {
      id: paymentId,
    },
  })

  const [Form] = useForm<RefundFormValues>({
    schema,
    values: {
      amount: Number(data?.merchantPayment.amount.amount ?? 0),
      reason: MerchantPaymentCancellationReason.duplicate,
    },
  })

  const onFormSubmit = useCallback(
    ({ amount, reason }: RefundFormValues) => {
      if (data?.merchantPayment) {
        onSubmit({
          paymentId: data.merchantPayment.id,
          amount: {
            amount: String(amount),
            currency: data.merchantPayment.amount.currency,
          },
          reason,
        })
      }
    },
    [data?.merchantPayment, onSubmit],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      <Form
        className="flex size-full flex-col items-stretch justify-start font-circular"
        onSubmit={onFormSubmit}
      >
        <div className="px-18">
          <SkeletonBox className="mt-6 h-10 w-full" loading={loading}>
            <Form.MoneyInput
              className="w-full"
              currencyVariant={data?.merchantPayment.amount.currency}
              label={t`payment.refund.form.amount`}
              name="amount"
              disabled
            />
          </SkeletonBox>
          <PlatformForms.Dropdown.Reasons
            className="mt-6 w-full"
            label={t`payment.refund.form.reason`}
            name="reason"
          />
        </div>
        <Modal.Footer variant="stretch-equal">
          <Button onClick={onCancel} variant="secondary">
            {t`payment.refund.form.cancel`}
          </Button>
          <Form.SubmitButton data-testid="button-submit" variant="primary">
            {t`payment.refund.form.submit`}
          </Form.SubmitButton>
        </Modal.Footer>
      </Form>
    </GraphQLErrorBoundary>
  )
}

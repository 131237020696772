import { useCallback, useEffect } from 'react'

import { signIn } from '@circlefin/auth'
import { useForm, y } from '@circlefin/form'
import { UsersForms } from '@features/users/forms'
import { signIn as signInSection } from '@services/sections/lib/home'
import { useSegment, SegmentEvents } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useCompleteInviteMutation } from '@shared/graphql'
import { useTransComponent } from '@shared/hooks/component'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { UserActivation } from '@shared/graphql'

const schema = y.object({
  firstName: y.string().required(),
  lastName: y.string().required(),
  email: y.string().required().email(),
  password: UsersForms.Input.generatePasswordSchema('email'),
})

export type ActivateUserFormValues = y.InferType<typeof schema>

export interface ActivateUserFormProps extends UserActivation {
  /**
   * Custom style.
   */
  className?: string
}

export const ActivateUserForm: React.FC<ActivateUserFormProps> = ({
  email,
  institutionName,
  stateToken,
  className,
}) => {
  const { t } = useTranslation('onboard/common')
  const router = useRouter()
  const { trackLinkClick, track, page, identify } = useSegment()
  const [completeInvite, { error, reset }] = useCompleteInviteMutation()
  const [Form, { formState, trigger }] = useForm<ActivateUserFormValues>({
    schema,
    values: {
      firstName: '',
      lastName: '',
      email: email ?? '',
      password: '',
    },
  })

  const handlePasswordChange = useCallback(() => {
    // Trigger validation for password on every change
    void trigger('password')
  }, [trigger])

  const handleSubmit = useCallback(
    async (values: ActivateUserFormValues) => {
      try {
        const { data } = await completeInvite({
          variables: {
            input: {
              ...values,
              stateToken: stateToken as string,
            },
          },
        })

        track(SegmentEvents.CreateAccountClicked, {
          props: {
            page: 'Set up account',
          },
          userTraits: {
            email: email ?? undefined,
          },
        })

        // Unresolved keeps the submit button in loading state while we are
        // redirecting the user to the next page to avoid form resubmission.
        await new Promise(() => {
          if (data?.completeInvite) {
            // If we have a sessionToken, we can can auto-login
            void signIn(
              { legacy: true },
              {
                sessionToken: data.completeInvite.sessionToken,
              },
            )
          } else {
            // If no sessionToken, fallback to login screen
            void router.push(signInSection.route)
          }
        })
      } catch (e) {
        // Error UI handled in GraphQLErrorBoundary
      }
    },
    [completeInvite, email, router, stateToken, track],
  )

  const [TermsWithLinks] = useTransComponent('onboard/common', (link, i) => (
    <a
      key={`checkbox-link-${i}`}
      href={link}
      onClick={trackLinkClick}
      rel="noopener noreferrer"
      target="_blank"
    />
  ))

  useEffect(() => {
    if (email) {
      identify({ traits: { email } })
      page({ userTraits: { email } })
    }
    // identify and page do not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  return (
    <div
      className={classNames(
        'm-auto text-neutral-subtle max-w-116 text-center',
        className,
      )}
      data-testid="default-content"
    >
      <div>
        {institutionName && (
          <h1 className="type-intro-lg">
            <span className="text-neutral-subtle">{institutionName}</span>
          </h1>
        )}
        <h2 className="mt-4 type-h-page-sm">{t`accountActivation.activateUser.setupYourAccount`}</h2>
        <p className="mt-4 type-intro-sm">
          {t(`accountActivation.activateUser.details`)}
        </p>

        <GraphQLErrorBoundary error={error} retry={reset}>
          <Form className="mt-6 text-left" onSubmit={handleSubmit}>
            <h4 className="text-center type-h-title-md">{email}</h4>
            <div className="flex flex-col gap-4 lg:flex-row">
              <Form.Input
                className="w-full"
                data-testid="activate-user-first-name"
                label={t`form.firstName`}
                name="firstName"
                placeholder={t`form.firstName`}
              />
              <Form.Input
                className="w-full"
                data-testid="activate-user-last-name"
                label={t`form.lastName`}
                name="lastName"
                placeholder={t`form.lastName`}
              />
            </div>
            <Form.PasswordInput
              aria-describedby="validation-list"
              className="mt-4 w-full"
              data-testid="input-password"
              label={t`form.password.label`}
              name="password"
              onChange={handlePasswordChange}
              placeholder={t`form.password.label`}
              hideError
            />
            <UsersForms.Input.PasswordValidationList
              disabled={formState.isSubmitting}
              errors={formState.errors.password}
              id="validation-list"
              shouldShowValidation={Boolean(formState.dirtyFields.password)}
            />
            <Form.SubmitButton className="mt-6 w-full" variant="primary">
              {t`common:continue`}
            </Form.SubmitButton>

            <p className="mt-6 type-body-xs" data-testid="terms">
              <TermsWithLinks
                i18nArrayKey="accountActivation.activateUser.terms.term1.links"
                i18nKey="accountActivation.activateUser.terms.term1.label"
              />
              <br />
              {t`accountActivation.activateUser.terms.term2.label`}
            </p>
          </Form>
        </GraphQLErrorBoundary>
      </div>
    </div>
  )
}

import { SkeletonBox } from '@circlefin/components'
import { useCustomMoney } from '@circlefin/hooks'
import { VAULT_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useVaultBalanceQuery } from '@shared/graphql'

export interface VaultCardBalanceProps {
  /**
   * The id of the vault the balance should be displayed for.
   */
  vaultId: string
}

export const VaultCardBalance: React.FC<VaultCardBalanceProps> = ({
  vaultId,
  ...props
}) => {
  const { data, loading } = useVaultBalanceQuery({
    variables: { vaultId },
    pollInterval: VAULT_BALANCE_POLL_INTERVAL,
  })

  const { customMoney } = useCustomMoney()

  return (
    <div {...props}>
      <SkeletonBox className="h-5 w-40" loading={loading}>
        {data?.vaultBalance
          ? customMoney({
              number: data?.vaultBalance.amount,
              currencyConfig: {
                decimals: data.vaultBalance.asset.decimals,
                name: data.vaultBalance.asset.symbol,
                isCryptoCurrency: true,
              },
            })
          : null}
      </SkeletonBox>
    </div>
  )
}

import { Button, Card, Heading } from '@circlefin/components'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const MintSelect: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  return (
    <Card className="flex h-full w-87 min-w-min flex-col justify-between">
      <Card.Content>
        <Heading variant="title/lg">
          <Heading.Header as="h3">{t`productSelect.mint.title`}</Heading.Header>
          <Heading.Intro className="type-body-sm">{t`productSelect.mint.subtitle`}</Heading.Intro>
        </Heading>

        <p className="pt-6 text-neutral type-body-sm">
          {t`productSelect.mint.description`}
        </p>
      </Card.Content>

      <Card.Footer>
        <Button.Link
          className="w-full"
          href={t`common:mintProductLink`}
          onClick={trackLinkClick}
          variant="primary"
        >
          {t`productSelect.mint.cta`}
        </Button.Link>
      </Card.Footer>
    </Card>
  )
}

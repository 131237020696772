import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { Base } from '@modals/layout'
import { LoadingSpinner } from '@shared/components/common'
import { useUsersFromVaultPolicyThatHaveNotFinishedSetupQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface VaultSubmissionBlockedProps {
  /**
   * The id of the vault the policy belongs to.
   */
  vaultId?: string
  /**
   * The id of the policy.
   */
  vaultPolicyId?: string
}
export const VaultSubmissionBlocked: React.FC<VaultSubmissionBlockedProps> = ({
  vaultId,
  vaultPolicyId,
}) => {
  const { t } = useTranslation('modals.vault')

  const { close } = useModal()

  const { data, loading } =
    useUsersFromVaultPolicyThatHaveNotFinishedSetupQuery({
      variables: {
        vaultId: vaultId ?? '',
        vaultPolicyId: vaultPolicyId ?? '',
      },
    })

  const handleClose = useCallback(() => {
    close({ context: 'onDismiss' })
  }, [close])

  return (
    <Base className="flex flex-col items-center text-sm leading-5 text-black-600">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h2 className="max-w-100 text-center text-lg leading-6 font-circular-book">{t`vaultDetails.vaultSubmissionBlocked.title`}</h2>
          <p className="mt-2 text-center">{t`vaultDetails.vaultSubmissionBlocked.description`}</p>
          <div className="my-6 w-full border-b border-black-50" />
          <div className="w-full">
            <p>{t`vaultDetails.vaultSubmissionBlocked.users`}</p>
            {data?.usersFromVaultPolicyThatHaveNotFinishedSetup.map((user) => (
              <div key={user?.id} className="mt-2">
                <strong className="block text-black-600">
                  {[user?.firstName, user?.lastName].join(' ')}
                </strong>
                <span className="block text-black-400">{user?.email}</span>
              </div>
            ))}
          </div>
          <Button
            className="mt-6 w-40 self-center"
            onClick={handleClose}
            variant="primary"
          >
            {t`vaultDetails.vaultSubmissionBlocked.button`}
          </Button>
        </>
      )}
    </Base>
  )
}

import { useCallback, useMemo, useState } from 'react'

import { Button, Table } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import { SimpleTable, TableCard } from '@shared/components/tables'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../../containers/Create'
import { useUserLimitsValidate } from '../../../../containers/CreateVault'
import { useCreateVault } from '../../../../hooks/create'
import * as VaultLayout from '../../../../layout'

import { UserLimitsRow } from './UserLimits.Row'

import type { UserLimitsRowProps } from './UserLimits.Row'
import type { VaultUserPolicy } from '@shared/graphql'

export const User: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  const { router } = useModal()

  const formatMoney = useMoney({ locale: 'en-US' }).money

  const [{ policyLimits, usersPolicy = [] }, { setUsersPolicy }] =
    useCreateVault()

  const { operators, isOverPolicyTransactionLimit, isOverPolicyVolumeLimit } =
    useUserLimitsValidate()

  const [formErrors, setFormErrors] = useState<VaultUserPolicy['user']['id'][]>(
    [],
  )

  const onUserRowChange = useCallback(
    (updatedValues: VaultUserPolicy) => {
      const foundIndex = usersPolicy.findIndex(
        (item) => item.user.id === updatedValues.user.id,
      )

      if (foundIndex === -1) {
        return
      }

      const updated = [...usersPolicy]
      updated[foundIndex] = {
        ...updated[foundIndex],
        ...updatedValues,
      }

      setUsersPolicy(updated)

      return updated
    },
    [setUsersPolicy, usersPolicy],
  )

  const onUserRowError = useCallback<UserLimitsRowProps['onError']>(
    (userId, hasError) => {
      setFormErrors((curr) => {
        if (!hasError) {
          return curr.filter((value) => value !== userId)
        }

        return [...curr, userId]
      })
    },
    [],
  )

  const continueDisabled = useMemo(() => formErrors.length > 0, [formErrors])

  const onContinue = useCallback(() => {
    void router.push(routes.vault.createVault.outgoingAddress)
  }, [router])

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.USER_LIMITS}
    >
      <div className="max-w-160">
        <h2 className="text-4xl text-black-600 font-circular-bold">
          {t('createVault.limits.user.title')}
        </h2>
        <h3 className="mt-4 text-lg text-black-600">
          {t('createVault.limits.user.subtitle')}
        </h3>

        <TableCard>
          <SimpleTable
            className="mt-3.5 table-fixed"
            empty={t`common:emptyTable`}
            loading={false}
            size={usersPolicy.length}
          >
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell className="px-4 text-sm normal-case">
                  {t('createVault.limits.user.headers.user')}
                </Table.Head.Cell>
                <Table.Head.Cell className="px-4 text-sm normal-case">
                  {t('createVault.limits.user.headers.maxAmount')}
                </Table.Head.Cell>
                <Table.Head.Cell className="px-4 text-sm normal-case">
                  {t('createVault.limits.user.headers.maxTransactions')}
                </Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              {operators.map((user, key) => (
                <UserLimitsRow
                  key={key}
                  onChange={onUserRowChange}
                  onError={onUserRowError}
                  policyTransactionLimit={policyLimits?.maxTransactions ?? '0'}
                  policyVolumeLimit={policyLimits?.maxAmount ?? '0'}
                  userPolicy={user}
                />
              ))}
            </Table.Body>
          </SimpleTable>
        </TableCard>

        {isOverPolicyVolumeLimit && (
          <p className="mt-4 text-sm leading-5 text-red-500 font-circular-book">
            {t('createVault.limits.user.errors.policyVolumeLimit', {
              limit: formatMoney({
                number: policyLimits?.maxAmount ?? '0',
                variant: Currency.USD,
              }),
            })}
          </p>
        )}

        {isOverPolicyTransactionLimit && (
          <p className="mt-4 text-sm leading-5 text-red-500 font-circular-book">
            {t('createVault.limits.user.errors.policyTransactionLimit', {
              limit: Number(policyLimits?.maxTransactions ?? '0'),
            })}
          </p>
        )}

        <div className="mt-4">
          <ModalBackButton />

          <Button
            className="w-60"
            disabled={continueDisabled}
            onClick={onContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </VaultLayout.CreateVault>
  )
}

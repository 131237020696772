import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useAddAddressMutation } from '../../../Create/Create'

import { AddressDetailsCustomerForm } from './AddressDetailsCustomer.Form'

export const AddressDetailsCustomer: React.FC = () => {
  const { t } = useTranslation('address-book/modals')

  const [{ addMultiAssetCustomerAddress }, { loading }] =
    useAddAddressMutation()

  return (
    <FullScreen totalSteps={4}>
      <Center className="text-center" variant="horizontal">
        <h2
          className="text-2xl text-black-900 font-circular-bold"
          data-testid="address-details-step-title"
        >
          {t`addAddress.addressDetails.customer.title`}
        </h2>
        <h3
          className="mt-2 font-circular text-lg leading-6 text-black-400"
          data-testid="address-details-step-subtitle"
        >
          {t`addAddress.addressDetails.customer.subtitle`}
        </h3>
      </Center>
      <AddressDetailsCustomerForm
        loading={loading}
        onSubmit={addMultiAssetCustomerAddress}
      />
    </FullScreen>
  )
}

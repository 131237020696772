import deepmerge from 'deepmerge'

import type { FieldMergeFunction } from '@apollo/client'
import type { Settings } from '@shared/graphql'

/**
 * Merge Settings Type.
 */
export const mergeSettings: FieldMergeFunction<Settings> = (
  existing,
  incoming,
  { cache },
) => {
  cache.modify({
    fields: {
      settings: () => {
        return deepmerge.all([{}, existing ?? {}, incoming ?? {}])
      },
    },
  })
  return incoming
}

import { createContext } from 'react'

import type { InAppNotification, Maybe } from '@shared/graphql'

export interface InAppNotificationsContextState {
  /**
   * Latest in app notification from server.
   */
  notification?: Maybe<InAppNotification>
}

export const InAppNotificationsContext =
  createContext<InAppNotificationsContextState>({
    notification: undefined,
  })

import { SkeletonBox } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface AssetBalanceTextProps {
  /**
   * Balance amount.
   */
  amount?: string
  /**
   * Loading.
   */
  loading?: boolean
}

export const AssetBalanceText: React.FC<AssetBalanceTextProps> = ({
  amount,
  loading,
}) => {
  const { t } = useTranslation('common')

  return (
    <div className="flex items-center gap-1">
      {t('availableBalance', {
        amount: amount && !loading ? amount : '',
      })}
      <SkeletonBox className="h-4 w-20" loading={loading} />
    </div>
  )
}

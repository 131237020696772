import { UserRoleInfoFragmentDoc } from '@shared/graphql'

import type { MutationUpdaterFn } from '@apollo/client'
import type { ChangeUserRoleMutation, User } from '@shared/graphql'

/**
 * Update User with new User Role.
 */
export const updateUserRole =
  (user: User): MutationUpdaterFn<ChangeUserRoleMutation> =>
  (cache, { data }) => {
    // Get new role info from the cache
    const newRole = cache.readFragment({
      // find the UserRole Reference
      id: cache.identify({
        id: data?.changeUserRole?.roleId,
        // Since we don't have any other information on the UserRole,
        // We have to pass the meta typename
        __typename: 'UserRole',
      }),
      // Use the fragment to define the fields in UserRole
      fragment: UserRoleInfoFragmentDoc,
    })
    // Update user with the new role
    cache.modify({
      // Find the User Reference
      // @ts-expect-error We should guarantee that "user" satisfies the function requirement
      id: cache.identify(user),
      fields: {
        // Update the user role using the role id provided in the response
        role: () => newRole,
      },
    })
  }

import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { WithdrawalForm } from './Withdrawal.Form'

import type { WithdrawalFormValues } from './Withdrawal.Form'

export interface WithdrawalQueryProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: WithdrawalFormValues) => void
}

export const Withdrawal: React.FC<WithdrawalQueryProps> = ({ onSubmit }) => {
  const { t } = useTranslation('express/modal')
  const { travelRuleRequired, loading } = useTravelRuleRequired()

  return (
    <FullScreen totalSteps={5}>
      <Center className="mb-8 text-center" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`addRoute.withdrawal.title`}
        </h2>
        <p className="w-160">{t`addRoute.withdrawal.subtitle`}</p>
      </Center>

      <Center variant="horizontal">
        <WithdrawalForm
          loading={loading}
          onSubmit={onSubmit}
          travelRuleRequired={travelRuleRequired}
        />
      </Center>
    </FullScreen>
  )
}

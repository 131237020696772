import type { InAppNotificationsRefs } from './mergeInAppNotifications'
import type {
  FieldFunctionOptions,
  FieldMergeFunction,
  Reference,
} from '@apollo/client'
import type { Maybe } from '@shared/graphql'

/**
 * Merge long poll in app notification.
 */
export const mergeLongPollInAppNotification: FieldMergeFunction<
  Maybe<Reference>,
  Maybe<Reference>
> = (_, incoming, { cache }: FieldFunctionOptions) => {
  // we did not receive a new message within the polled time frame, don't update the apollo cache
  if (!incoming) {
    return incoming
  }

  const mergeInAppNotifications = (
    inAppNotificationsRefs: Reference | InAppNotificationsRefs,
  ) => {
    if ('list' in inAppNotificationsRefs) {
      return {
        ...inAppNotificationsRefs,
        list: [incoming, ...inAppNotificationsRefs.list],
      }
    }

    return inAppNotificationsRefs
  }

  cache.modify({
    fields: {
      /**
       * Add incoming notification to the front of the unread list.
       */
      unreadInAppNotifications: mergeInAppNotifications,

      /**
       * Add incoming notification to the front of the all list.
       */
      allInAppNotifications: mergeInAppNotifications,

      /**
       * Increment unread count by 1.
       */
      unreadInAppNotificationsCount(existingCount: number) {
        return (existingCount ?? 0) + 1
      },
    },
  })

  return incoming
}

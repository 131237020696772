import { useMemo } from 'react'

import { TableFilters } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { FX_LAUNCH_ENABLED } from '@services/permissions'
import { TransactionType as TransactionTypeEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { FilterBaseProps } from '@circlefin/components/lib/TableFilters/types'

/**
 * Payment Transaction Type Filter.
 */
export const TransactionType: React.FC<FilterBaseProps> = (props) => {
  const { t } = useTranslation('payments')
  const [, { isAuthorized: fxEnabled }] = usePermission(FX_LAUNCH_ENABLED)

  const items: Array<SelectListItem<TransactionTypeEnum[] | undefined>> =
    useMemo(
      () => [
        {
          value: undefined,
          label: t('activity.types.allTypes'),
        },
        {
          value: [
            TransactionTypeEnum.wire_receive,
            TransactionTypeEnum.signet_receive,
            TransactionTypeEnum.sen_receive,
            TransactionTypeEnum.cbit_receive,
            TransactionTypeEnum.xpay_receive,
            TransactionTypeEnum.rtp_receive,
            TransactionTypeEnum.rtgs_receive,
            TransactionTypeEnum.sepa_receive,
            TransactionTypeEnum.sepa_instant_receive,
          ],
          label: t(
            `${
              fxEnabled ? 'activity.types.mints' : 'activity.types.bankDeposits'
            }`,
          ),
        },
        {
          value: [TransactionTypeEnum.onchain_receive],
          label: t(
            `${
              fxEnabled
                ? 'activity.types.receiveOnChain'
                : 'activity.types.blockchainDeposits'
            }`,
          ),
        },
        {
          value: [TransactionTypeEnum.onchain_send],
          label: t('activity.types.sendOnChain'),
        },
        {
          value: [
            TransactionTypeEnum.wire_withdrawal,
            TransactionTypeEnum.signet_withdrawal,
            TransactionTypeEnum.sen_withdrawal,
            TransactionTypeEnum.cbit_withdrawal,
            TransactionTypeEnum.xpay_withdrawal,
            TransactionTypeEnum.rtp_withdrawal,
            TransactionTypeEnum.rtgs_withdrawal,
            TransactionTypeEnum.sepa_withdrawal,
            TransactionTypeEnum.sepa_instant_withdrawal,
          ],
          label: t(
            `${
              fxEnabled
                ? 'activity.types.redeems'
                : 'activity.types.withdrawals'
            }`,
          ),
        },
      ],
      [fxEnabled, t],
    )

  return <TableFilters.Dropdown {...props} items={items} />
}

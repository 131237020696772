import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

type DepositQueryProps = {
  /**
   * Id of the parent wallet.
   */
  parentWalletId?: string
  /**
   * Id of the wallet.
   */
  walletId?: string
}

/**
 * Fee Balance Alert props.
 */
export interface FeeBalanceAlertProps {
  /**
   * Currency code.
   */
  currencyCode?: string
  /**
   * Visible -- is alert visible.
   */
  visible?: boolean
  /**
   * Is the balance to over fee zero?
   */
  isBalanceZero?: boolean
  /**
   * Query props to pass to the deposit flow.
   */
  depositQueryProps?: DepositQueryProps
  /**
   * Custom className.
   */
  className?: string
  /**
   * Custom label to override default.
   */
  label?: React.ReactNode
}

export const FeeBalanceAlert: React.FC<FeeBalanceAlertProps> = ({
  currencyCode,
  visible = false,
  isBalanceZero = false,
  label,
  depositQueryProps,
  className,
}) => {
  const { t } = useTranslation('vaults')

  return (
    <FixedBanner
      className={className}
      data-testid="fee-balance-alert"
      status="error"
      visible={visible}
    >
      <FixedBanner.Description>
        <p>
          {label ??
            t(`feeBalanceAlert.${isBalanceZero ? 'zero' : 'insufficient'}`, {
              currencyCode,
            })}
        </p>
        <ModalLink
          pathname={routes.vault.deposit.chooseDepositType}
          query={depositQueryProps}
        >
          <TextLink data-testid="add-funds-button" size="sm" variant="primary">
            {t`common:addFunds`}
          </TextLink>
        </ModalLink>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import { Header } from '@circlefin/components'
import { Content } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { PaymentsPageHeaderActions } from './PaymentsPageHeader.Actions'
import { PaymentsPageHeaderNumbers } from './PaymentsPageHeader.Numbers'

export const PaymentsPageHeader: React.FC = () => {
  const { t } = useTranslation('payments')

  return (
    <Content className="mt-12" data-testid="payments-page-header">
      <Header>
        <Header.Head title={t`title`}>
          <PaymentsPageHeaderActions />
        </Header.Head>
        <PaymentsPageHeaderNumbers />
      </Header>
    </Content>
  )
}

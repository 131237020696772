import { useMemo } from 'react'

import { OnboardingBusinessNature } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const useBusinessNature = () => {
  const { t } = useTranslation('onboard/common')

  const data: Array<SelectListItem<OnboardingBusinessNature>> = useMemo(
    () =>
      Object.values(OnboardingBusinessNature).map((option) => ({
        label: t(`form.businessNatures.${OnboardingBusinessNature[option]}`),
        value: OnboardingBusinessNature[option],
      })),
    [t],
  )

  return {
    data,
  }
}

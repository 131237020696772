import { FeatureSwitch } from '@services/feature-switch'
import { RESTRICTED, PUBLIC } from '@services/permissions'
import { PROTECTED_UNVERIFIED_EMAIL } from '@services/permissions/sets'

import type { Section } from '../types'

export const activateEmail: Section = {
  label: 'layout:page.verifyEmail.activate',
  route: '/verify-email/[activationToken]',
  permissions: FeatureSwitch.businessAccountCreationEnabled()
    ? PUBLIC
    : RESTRICTED,
  segment: {
    page: 'activate email',
  },
}

export const verifyEmailSection: Section = {
  label: 'layout:page.verifyEmail.verify',
  route: '/verify-email',
  permissions: PROTECTED_UNVERIFIED_EMAIL,
  pages: [activateEmail],
  segment: {
    page: 'verify email',
  },
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Restricted API Key Permissions
 * Defines if a user has access to see and view self serve restricted API Key page.
 */
export const RESTRICTED_API_KEY_ENABLED: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [SynthRole.SYNTH_RESTRICTED_API_KEY_ROLE],
}

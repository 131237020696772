import { DescriptionList } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const ApprovalMethod: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')

  const { condensed, settings } = usePolicySummaryContext()

  return (
    <>
      <Label className="h-full">
        {t('policy.summary.labels.approvalMethod')}
      </Label>
      <Description
        className={classNames('flex flex-col', {
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="approval-method"
      >
        <List>
          <List.Item>
            <List.Item.Title
              stepName={WalletApprovalPolicyStep.SETTINGS}
              showEdit
            >
              {settings?.proposalApprovalMethod != null
                ? t(
                    `policy.summary.approvalMethod.title.${settings.proposalApprovalMethod}`,
                  )
                : t(`policy.summary.approvalMethod.approvalMethodNotSet`)}
            </List.Item.Title>
            {settings?.proposalApprovalMethod != null && (
              <List.Item.Subtitle>
                <div className="flex items-center gap-x-2">
                  <span>
                    {t(
                      `policy.summary.approvalMethod.subtitle.${settings.proposalApprovalMethod}`,
                    )}
                  </span>
                </div>
              </List.Item.Subtitle>
            )}
          </List.Item>
        </List>
      </Description>
    </>
  )
}

import { useCallback, useEffect } from 'react'

import { Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { VaultForms } from '../../../../forms'
import { useVaultDeposit } from '../../VaultDeposit.Context'
import { useBlockchainDeposit } from '../BlockchainDeposit.Context'

const schema = y.object({
  /**
   * Vault parent wallet.
   */
  vaultParentWallet: VaultForms.Combobox.ParentWalletsSchema.required(),
  /**
   * Vault child wallet.
   */
  childWallet: VaultForms.Combobox.WalletsByParentSchema.required(),
})

type FormValues = y.InferType<typeof schema>

export const DepositDetailsForm: React.FC = () => {
  const { router } = useModal()
  const [{ parentWallet, childWallet }, { setBlockchainDepositState }] =
    useBlockchainDeposit()
  const { t } = useTranslation('modals.vault')
  const [Form, { formState, watch, getValues }] = useForm<FormValues>({
    schema,
    defaultValues: {
      vaultParentWallet: parentWallet,
      childWallet,
    },
  })
  const [
    { selectedParentWalletId, selectedChildWalletId },
    { setVaultDepositState },
  ] = useVaultDeposit()

  useEffect(() => {
    const subscription = watch(() => {
      setBlockchainDepositState({
        parentWallet: getValues('vaultParentWallet'),
        childWallet: getValues('childWallet'),
      })

      // If parent wallet is auto-selected, clear context value
      if (parentWallet && selectedParentWalletId) {
        setVaultDepositState({ selectedParentWalletId: undefined })
      }

      // If child/mapped wallet is auto-selected, clear context value
      if (childWallet && selectedChildWalletId) {
        setVaultDepositState({ selectedChildWalletId: undefined })
      }
    })

    return () => subscription.unsubscribe()
  }, [
    childWallet,
    getValues,
    parentWallet,
    selectedChildWalletId,
    selectedParentWalletId,
    setBlockchainDepositState,
    setVaultDepositState,
    watch,
  ])

  /**
   * Handle Form onSubmit.
   */
  const handleFormOnSubmit = useCallback(() => {
    router.push(routes.vault.deposit.blockchain.transferInstructions)
  }, [router])

  return (
    <Form onSubmit={handleFormOnSubmit}>
      {/* From (Parent Wallets) Field */}
      <VaultForms.Combobox.ParentWallets
        label={t`deposit.blockchain.depositDetails.parentWallets.label`}
        name="vaultParentWallet"
        parentWalletId={selectedParentWalletId}
        placeholder={t`deposit.blockchain.depositDetails.parentWallets.placeholder`}
      />

      {/* Assets Field */}
      <VaultForms.Combobox.WalletsByParent
        className="mt-6"
        disabled={watch('vaultParentWallet') == null}
        name="childWallet"
        parentWalletId={watch('vaultParentWallet')?.id}
        walletId={selectedChildWalletId}
      />

      <Modal.Footer>
        <Center className="w-full">
          <Form.SubmitButton
            className="mt-2 h-10 w-60"
            data-testid="submit-button"
            disabled={
              formState.isValidating ||
              !formState.isValid ||
              !childWallet ||
              !parentWallet
            }
            variant="primary"
          >
            {t`common:continue`}
          </Form.SubmitButton>
        </Center>
      </Modal.Footer>
    </Form>
  )
}

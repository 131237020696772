/**
 * MFA Routes.
 */
export const express = {
  instruction: {
    deposit: '/express/instruction/deposit',
    withdrawal: '/express/instruction/withdrawal',
  },
  addRoute: {
    switch: '/express/add-route/',
    deposit: '/express/add-route/deposit',
    direction: '/express/add-route/direction',
    review: '/express/add-route/review',
    withdrawal: '/express/add-route/withdrawal',
  },
  disableRoute: '/express/disable-route',
}

import { useCallback } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { useRemoveUserWithAdminApprovalMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { User } from '@shared/graphql'

export interface RemoveWithAdminApprovalProps {
  /**
   * User being removed.
   */
  user?: User
}

export const RemoveWithAdminApproval: React.FC<RemoveWithAdminApprovalProps> = (
  props,
) => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()
  const toast = useToast()

  const [removeUserWithAdminApproval, { reset, loading, error }] =
    useRemoveUserWithAdminApprovalMutation({
      onCompleted: () => {
        modal.close()
        toast.success(
          t(`removeUserWithAdminApproval.success`, {
            name: props.user?.name,
          }),
        )
      },
      // Error is handled in the GraphQLErrorBoundary
      onError: () => null,
    })

  const handleCancel = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleConfirm = useCallback(
    (userId: string) => () => {
      void removeUserWithAdminApproval({
        variables: {
          userId,
        },
      })
    },
    [removeUserWithAdminApproval],
  )

  return (
    <BaseTitle title={t(`removeUserWithAdminApproval.title`)} variant="center">
      <PropsErrorBoundary props={props} variant="page">
        {({ user }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <p className="text-sm leading-6 text-black-600">
              {t(`removeUserWithAdminApproval.description`, {
                name: user.name,
                email: user.email,
              })}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={handleCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button
                loading={loading}
                onClick={handleConfirm(user.id)}
                variant="primary"
              >
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

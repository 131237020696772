import { useCallback, useState } from 'react'

import {
  Button,
  Icon,
  SkeletonBox,
  DescriptionList,
} from '@circlefin/components'
import { FullScreen } from '@modals/layout'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  useSubmitAddressBookApprovalVoteMutation,
  useAddressBookProposalQuery,
  ProposalStatus,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { RequestResponse } from './Response/RequestResponse'

import type { Currency } from '@shared/graphql'

type AddressBookCurrency = Currency.USDC | Currency.EURC | Currency.ETH

export interface ApprovalRequestProps {
  /**
   * Proposal ID associated with the attempted addition of an address book entry.
   */
  proposalId?: string
}

export const ApprovalRequest: React.FC<ApprovalRequestProps> = ({
  proposalId,
}) => {
  const { t } = useTranslation('address-book/modals')
  const [postApproval, setPostApproval] = useState<ProposalStatus>()

  const {
    data: proposalData,
    error: proposalDataError,
    loading: proposalDataLoading,
  } = useAddressBookProposalQuery({
    variables: { id: proposalId ?? '' },
    onCompleted: (data) => {
      setPostApproval(data.addressBookProposal.status)
    },
  })

  const addressInfo = proposalData?.addressBookProposal?.externalAddress
  const currency = addressInfo?.currency as AddressBookCurrency

  const [submitVote, { called, error: fetchProposalError }] =
    useSubmitAddressBookApprovalVoteMutation()

  const submitApprovalVote = useCallback(
    (isApproved: boolean) => {
      if (!called && proposalId) {
        void submitVote({
          variables: {
            input: {
              proposalId,
              isApproved,
            },
          },
          onCompleted: () => {
            setPostApproval(
              isApproved ? ProposalStatus.COMPLETED : ProposalStatus.REJECTED,
            )
          },
        })
      }
    },
    [called, proposalId, submitVote],
  )

  const handleApprove = useCallback(() => {
    submitApprovalVote(true)
  }, [submitApprovalVote])

  const handleReject = useCallback(() => {
    submitApprovalVote(false)
  }, [submitApprovalVote])

  return postApproval != null &&
    postApproval !== ProposalStatus.PENDING_APPROVAL ? (
    <RequestResponse
      address={addressInfo?.address ?? ''}
      nickname={addressInfo?.nickname ?? ''}
      type={postApproval}
    />
  ) : (
    <FullScreen totalSteps={1}>
      <Center
        className="justify-items-center gap-2 text-center"
        variant="horizontal"
      >
        <h1 className="type-h-page-sm">{t('approvalRequest.title')}</h1>
        <SkeletonBox className="h-6 w-100" loading={proposalDataLoading}>
          <h2 className="type-body-base">{t('approvalRequest.body.add')}</h2>
        </SkeletonBox>
      </Center>
      <Center variant="horizontal">
        <PropsErrorBoundary<ApprovalRequestProps>
          props={{ proposalId }}
          variant="component"
        >
          {() => (
            <GraphQLErrorBoundary error={fetchProposalError}>
              <GraphQLErrorBoundary error={proposalDataError}>
                <DescriptionList className="mt-8 grid w-152 grid-cols-2">
                  <DescriptionList.Label>
                    {t('approvalRequest.nickname')}
                  </DescriptionList.Label>
                  <DescriptionList.Description>
                    <SkeletonBox className="h-5" loading={proposalDataLoading}>
                      {addressInfo?.nickname}
                    </SkeletonBox>
                  </DescriptionList.Description>

                  <DescriptionList.Label>
                    {t('approvalRequest.blockchain')}
                  </DescriptionList.Label>
                  <DescriptionList.Description>
                    <SkeletonBox className="h-5" loading={proposalDataLoading}>
                      <span className="flex items-center gap-1">
                        <Icon name={addressInfo?.chain ?? 'ETH'} />
                        {t(`common:chain.${addressInfo?.chain}`)}
                      </span>
                    </SkeletonBox>
                  </DescriptionList.Description>

                  <DescriptionList.Label>
                    {t('approvalRequest.currency')}
                  </DescriptionList.Label>
                  <DescriptionList.Description>
                    <SkeletonBox className="h-5" loading={proposalDataLoading}>
                      <span className="flex items-center gap-1">
                        <Icon name={currency} />
                        {currency}
                      </span>
                    </SkeletonBox>
                  </DescriptionList.Description>

                  <DescriptionList.Label>
                    {t('approvalRequest.walletAddress')}
                  </DescriptionList.Label>
                  <DescriptionList.Description>
                    <SkeletonBox className="h-5" loading={proposalDataLoading}>
                      {addressInfo?.address}
                    </SkeletonBox>
                  </DescriptionList.Description>
                </DescriptionList>
                <div className="mt-8 flex flex-row gap-4">
                  <Button
                    className="flex-1"
                    intent="destructive"
                    onClick={handleReject}
                    variant="secondary"
                  >
                    {t('approvalRequest.reject')}
                  </Button>
                  <Button
                    className="flex-1"
                    onClick={handleApprove}
                    variant="primary"
                  >
                    {t('approvalRequest.approve')}
                  </Button>
                </div>
              </GraphQLErrorBoundary>
            </GraphQLErrorBoundary>
          )}
        </PropsErrorBoundary>
      </Center>
    </FullScreen>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Which users are allowed to deposit onchain?
 */
export const BLOCKCHAIN_DEPOSIT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WALLETS_ROLE, Role.ONCHAIN_DEPOSIT_ROLE],
}

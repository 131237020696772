import { useCallback, useEffect, useState } from 'react'

import { Button, ComponentGroup, FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useReCaptcha } from '@circlefin/recaptcha/react'
import { SegmentEvents, useSegment } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useResendUserActivationMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  /**
   * Email address to send new activation link to.
   */
  email: y.string().required().email(),
})

export type ActivationErrorFormValues = y.InferType<typeof schema>

export const ActivationError: React.FC = () => {
  const { t } = useTranslation('onboard/common')
  const [Form, { watch, formState, reset: resetForm }] =
    useForm<ActivationErrorFormValues>({
      schema,
    })
  const [succeeded, setSucceeded] = useState(false)
  const { track } = useSegment()
  const { withReCaptcha, createContext } = useReCaptcha({
    action: 'resend_user_activation',
  })

  const [resendActivationToken, { error, reset }] =
    useResendUserActivationMutation()

  useEffect(() => {
    track(SegmentEvents.ErrorDisplayed)
  }, [track])

  const handleSubmit = useCallback(
    async ({ email }: ActivationErrorFormValues) => {
      track(SegmentEvents.FormSubmitted, {
        props: {
          value: email,
        },
      })

      await withReCaptcha({
        onCompleted: async (reCaptchaToken) => {
          try {
            await resendActivationToken({
              variables: { email },
              context: createContext(reCaptchaToken),
            })

            resetForm({ email: '' })

            setSucceeded(true)
          } catch {
            // Error handled in GraphQLErrorBoundary
          }
        },
      })
    },
    [createContext, resendActivationToken, resetForm, track, withReCaptcha],
  )

  const handleDismiss = useCallback(() => setSucceeded(false), [])

  return (
    <div className="flex max-w-152 flex-col items-center gap-y-8">
      {/* Heading */}
      <h2 className="type-h-page-sm">{t`accountActivation.activationError.title`}</h2>
      <p className="text-center text-neutral type-body-base">{t`accountActivation.activationError.description`}</p>

      {/* Resend activation link */}
      <GraphQLErrorBoundary error={error} retry={reset}>
        <div className="max-w-116">
          <FixedBanner
            className="mb-4"
            data-testid="activation-resend-banner"
            onDismiss={handleDismiss}
            status="success"
            visible={succeeded}
          >
            <FixedBanner.Description>
              <span className="text-left">
                <TransWithLink
                  i18nKey="onboard/common:accountActivation.activationError.success"
                  variant="support"
                />
              </span>
            </FixedBanner.Description>
          </FixedBanner>

          <Form onSubmit={handleSubmit}>
            <ComponentGroup>
              <Form.Input
                label={t`accountActivation.activationError.resend.label`}
                name="email"
                placeholder={t`accountActivation.activationError.resend.placeholder`}
              />
              <Button
                className="mt-6"
                disabled={
                  !watch('email') ||
                  Boolean(formState.errors.email) ||
                  Boolean(formState.isSubmitting)
                }
                type="submit"
                variant="primary"
              >{t`accountActivation.activationError.resend.button`}</Button>
            </ComponentGroup>
          </Form>
        </div>
      </GraphQLErrorBoundary>
    </div>
  )
}

import classNames from 'classnames'

export interface StepperStepProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
  /**
   * Title label.
   */
  title?: string
  /**
   * Subtitle label.
   */
  subtitle?: string
  /**
   * Adds spacing to the top of the container.
   */
  withMarginTop?: boolean
}

export const Step: React.FC<StepperStepProps> = ({
  className,
  title,
  subtitle,
  withMarginTop = true,
  children,
}) => {
  return (
    <>
      <div
        className={classNames(
          'font-circular relative overflow-visible w-full pb-12',
          {
            'mt-10 sm:mt-20': withMarginTop,
          },
          className,
        )}
      >
        <div className="grid items-center justify-center text-center">
          {title && (
            <h2 className="text-3xl text-black-600 font-circular-black">
              {title}
            </h2>
          )}
          {subtitle && (
            <h3 className="pt-1 text-lg text-black-400">{subtitle}</h3>
          )}
        </div>
        {children}
      </div>
    </>
  )
}

Step.displayName = 'Stepper.Step'

import classNames from 'classnames'

export interface StepperProgressBarProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Current step index.
   */
  stepIndex: number
  /**
   * Total number of steps.
   */
  totalSteps: number
}

export const ProgressBar: React.FC<StepperProgressBarProps> = ({
  className,
  stepIndex,
  totalSteps,
}) => {
  const lastStepIndex = totalSteps - 1
  const progress = (stepIndex / lastStepIndex) * 100

  return (
    <div className={classNames('w-full h-1 bg-black-75', className)}>
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={progress}
        className="h-full bg-blue-300 transition-width duration-100"
        role="progressbar"
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  )
}

ProgressBar.displayName = 'Stepper.ProgressBar'

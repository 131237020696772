import { FixedBanner, Icon, TextLink } from '@circlefin/components'
import { useDelayedWithdrawalsSettingsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

/**
 * Delayed Withdrawals: Info Alert props.
 */
export interface InfoAlertProps {
  /**
   * Custom className.
   */
  className?: string
}

/**
 * Delayed Withdrawals: Info Alert.
 */
export const InfoAlert: React.FC<InfoAlertProps> = ({ className }) => {
  const { t } = useTranslation('common')
  const { data: { delayedWithdrawalsSettings } = {} } =
    useDelayedWithdrawalsSettingsQuery()

  if (!delayedWithdrawalsSettings) {
    return null
  }

  return (
    <FixedBanner
      className={className}
      data-testid="info-alert"
      status="info"
      visible={delayedWithdrawalsSettings?.enabled}
    >
      <FixedBanner.Description>
        <p>
          {t('delayedWithdrawals.infoAlert.text', {
            hours: delayedWithdrawalsSettings.delayHours,
          })}
        </p>
        <TextLink.Link
          href={t`delayedWithdrawals.infoAlert.link.url`}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t`delayedWithdrawals.infoAlert.link.text`}
          <Icon name="ArrowNarrowRightSolid" />
        </TextLink.Link>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import { useMemo } from 'react'

import { ButtonCard, Heading } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { BANK_DEPOSIT, BANK_WITHDRAW } from '@services/permissions'

import type { ButtonCardProps } from '@circlefin/components/lib/ButtonCard'

export interface BankWiresProps extends ButtonCardProps {
  /**
   * Icon.
   */
  icon?: React.ComponentProps<typeof Heading>['iconName']
  /**
   * Title text.
   */
  title: string
  /**
   * Subtitle text.
   */
  subtitle?: string
  /**
   * Bank wire type.
   */
  actionType: 'deposit' | 'withdraw'
}

export const BankWires: React.FC<BankWiresProps> = ({
  children,
  icon,
  title,
  subtitle,
  actionType,
  ...props
}) => {
  const permission = useMemo(() => {
    switch (actionType) {
      case 'deposit':
        return BANK_DEPOSIT
      case 'withdraw':
        return BANK_WITHDRAW
    }
  }, [actionType])

  const [, { isAuthorized }] = usePermission(permission)

  return (
    <ButtonCard
      data-testid="bank-wires-button-card"
      {...props}
      disabled={!isAuthorized || props.disabled}
    >
      <Heading iconName={icon}>
        <Heading.Header>{title}</Heading.Header>
        <Heading.Intro>{subtitle}</Heading.Intro>
      </Heading>
    </ButtonCard>
  )
}

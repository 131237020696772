import { useCallback, useMemo, useState } from 'react'

import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useOnboardingNaturesOfBusinessQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'

const Combobox = createFormCombobox()

interface BusinessNatureCOPProps {
  /**
   * Field label.
   */
  label?: string
  /**
   * Field name.
   */
  name?: string
  /**
   * Optional field placeholder.
   */
  placeholder?: string
  /**
   * Force loading state.
   */
  loading?: boolean
}

export const BusinessNatureCOP: React.FC<BusinessNatureCOPProps> = ({
  loading,
  label,
  name,
  placeholder,
}) => {
  const { t } = useTranslation('onboard/account-setup')
  const { data: natureOfBusinessList, loading: queryLoading } =
    useOnboardingNaturesOfBusinessQuery()
  const data = useMemo(
    () =>
      natureOfBusinessList?.onboardingNaturesOfBusiness.map((option) => ({
        label: option.name,
        value: option.value,
      })) ?? [],
    [natureOfBusinessList],
  )
  const [searchTerm, setSearchTerm] = useState('')
  const handleChange = useCallback(() => {
    setSearchTerm('')
  }, [])

  const handleInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  const filtered = useMemo(() => {
    if (searchTerm) {
      return matchSorter(data, searchTerm, {
        keys: ['label'],
      })
    }
    return undefined
  }, [data, searchTerm])

  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
    }),
    [t],
  )

  return (
    <Combobox
      data-testid="combobox-business-nature-cop"
      filtered={filtered}
      inputLoading={loading && queryLoading}
      items={data}
      label={label ?? t`confirm.form.businessNature.label`}
      locale={locale}
      maxMenuItems={6}
      name={name ?? 'businessNature'}
      onChange={handleChange}
      onInputChange={handleInputChange}
      placeholder={placeholder ?? t`confirm.form.businessNature.placeholder`}
      searchTerm={searchTerm}
    />
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { Card, Icon, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { DELAYED_WITHDRAWALS_SETTINGS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import * as Buttons from '@shared/components/common/Buttons'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useDelayedWithdrawalsSettingsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const ToggleCard: React.FC = () => {
  const { t } = useTranslation('settings')
  const modal = useModal()
  const { track } = useSegment()
  const { data, loading, error, refetch } = useDelayedWithdrawalsSettingsQuery({
    pollInterval: DELAYED_WITHDRAWALS_SETTINGS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  /**
   * Optimistic toggle (ensures toggle updates immediately).
   */
  const [optimisticToggle, setOptimisticToggle] = useState(false)

  /**
   * Determine toggle UI states.
   *
   * NOTE: UI toggle should render `disabled` during the 48 hour grace period.
   */
  const toggleState = useMemo(() => {
    const { enabled, disabledAfterHours } =
      data?.delayedWithdrawalsSettings ?? {}

    if (enabled && disabledAfterHours == null) {
      return {
        enabled: true,
        headerText: t`security.delayedWithdrawals.toggleCard.title.enabled`,
      }
    } else if (enabled && disabledAfterHours != null) {
      return {
        enabled: false,
        headerText: t(
          'security.delayedWithdrawals.toggleCard.title.disabledInHours',
          {
            count: data?.delayedWithdrawalsSettings.disabledAfterHours,
          },
        ),
      }
    }

    return {
      enabled: false,
      headerText: t`security.delayedWithdrawals.toggleCard.title.disabled`,
    }
  }, [data?.delayedWithdrawalsSettings, t])

  /**
   * Every time the setting is updated (initial load or after successful mutation), update the optimisticToggle state.
   */
  useEffect(() => {
    setOptimisticToggle(toggleState.enabled ?? false)
  }, [toggleState.enabled])

  /**
   * Add modal event listeners.
   */
  useEffect(() => {
    const onModalClose = () => {
      // user cancelled the mutation, reset optimistic toggle back to the actual setting
      setOptimisticToggle(toggleState.enabled ?? false)
    }

    modal.events.on('onCloseStart', onModalClose)

    return () => {
      modal.events.off('onCloseStart', onModalClose)
    }
  }, [modal.events, toggleState.enabled])

  /**
   * Handle clicking of toggle.
   * @param status - Toggle on/off boolean.
   */
  const handleToggle = useCallback(
    (status: boolean) => {
      // optimistically set toggle state
      setOptimisticToggle(status)

      track(SegmentEvents.SwitchClicked, {
        props: {
          switch_type: 'Delayed Withdrawals feature',
          value: status,
        },
      })

      modal.router.push({
        pathname: routes.delayedWithdrawals.toggleSetting,
        query: {
          variant: status ? 'enable' : 'disable',
        },
      })
    },
    [modal.router, track],
  )

  return (
    <SkeletonBox className="h-28" loading={loading}>
      <Card className="font-circular" data-testid="toggle-card">
        <GraphQLErrorBoundary error={error} retry={refetch}>
          <Card.Content className="p-10">
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center justify-between">
                <div className="flex gap-x-2">
                  <Center className="self-center" variant="vertical">
                    <Icon name="ClockOutline" size={24} />
                  </Center>
                  <h3
                    className="justify-self-start pr-4 text-2xl leading-8 text-black-700 font-circular-book"
                    data-testid="header"
                  >
                    {toggleState.headerText}
                  </h3>
                </div>
                <div className="justify-self-end">
                  <Buttons.Toggles.Settings
                    data-testid="toggle"
                    onChange={handleToggle}
                    selected={optimisticToggle}
                    type="delayedWithdrawal"
                  />
                </div>
              </div>
            </div>
          </Card.Content>
        </GraphQLErrorBoundary>
      </Card>
    </SkeletonBox>
  )
}

/**
 * NOTE: This component utilizes the Salesforce Web-To-Case form
 * [https://help.salesforce.com/s/articleView?id=sf.setting_up_web-to-case_prereqs.htm&type=5].
 * This form requires a traditional form page submission instead of our
 * conventional data-collection/async API call form submissions. This component
 * goes against common form conventions and should not be used for reference.
 */
import type React from 'react'
import { useCallback, useMemo, useState } from 'react'
import GoogleReCAPTCHA from 'react-google-recaptcha'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import classNames from 'classnames'
import getConfig from 'next/config'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

const schema = y.object({
  /**
   * Subject.
   */
  subject: y.string().required(),
  /**
   * Description.
   */
  description: y.string().required(),
  /**
   * ReCAPTCHA.
   */
  recaptcha: y.string().required(),
})

type SupportFormValues = y.InferType<typeof schema>

export const SalesforceSupportForm: React.FC = () => {
  const { t } = useTranslation('support')
  const { data: session } = useSession()
  const [timestamp, setTimestamp] = useState(new Date().getTime())
  const [recaptchaLoading, setRecaptchaLoading] = useState<boolean>(true)
  const [Form, { setValue, trigger }] = useForm<SupportFormValues>({
    schema,
    defaultValues: {
      subject: '',
      description: '',
      recaptcha: '',
    },
  })

  // ReCAPTCHA Loaded
  const handleAsyncScriptLoad = useCallback(() => {
    setRecaptchaLoading(false)
  }, [])

  // ReCAPTCHA Change
  const handleChange = useCallback(
    (token: string | null) => {
      setTimestamp(new Date().getTime())

      if (token) {
        setValue('recaptcha', token)
      }

      void trigger('recaptcha')
    },
    [setValue, trigger],
  )

  // ReCAPTCHA Expired
  const handleExpired = useCallback(() => {
    setValue('recaptcha', '')

    void trigger('recaptcha')
  }, [setValue, trigger])

  // Return URL
  const retURL = useMemo(() => {
    if (typeof window !== 'undefined') {
      return `${window.location.href}?success=true`
    }
  }, [])

  return (
    <Form
      action={`${
        publicRuntimeConfig?.SALESFORCE_FORM_ACTION ?? ''
      }?encoding=UTF-8`}
      className="flex w-full flex-col gap-4"
      method="POST"
      isActionSubmit
    >
      {/* // Hidden inputs */}
      <input
        name="captcha_settings"
        type="hidden"
        value={`{"keyname":"${
          publicRuntimeConfig?.SALESFORCE_RECAPTCHA_KEY_NAME ?? ''
        }","fallback":"true","orgId":"${
          publicRuntimeConfig?.SALESFORCE_ORG_ID ?? ''
        }","ts":"${timestamp}"}`}
      />
      <input
        name="orgid"
        type="hidden"
        value={publicRuntimeConfig?.SALESFORCE_ORG_ID}
      />
      <input
        id="recordType"
        name="recordType"
        type="hidden"
        value="0128b000000u8O3"
      />
      <input id="origin" name="origin" type="hidden" value="Circle Account" />
      <input id="retURL" name="retURL" type="hidden" value={retURL} />
      {session && (
        <input
          id="email"
          name="email"
          type="hidden"
          value={session.user.email}
        />
      )}
      <input
        id="name"
        name="name"
        type="hidden"
        value={`${session?.user.givenName ?? ''} ${
          session?.user.familyName ?? ''
        }`}
      />
      <input
        id="Circle_Account_Entity_Id__c"
        name="Circle_Account_Entity_Id__c"
        type="hidden"
        value="111111-0fc0-4cb0-bc52-ea10666c8abb"
      />
      <input
        id="Circle_Account_User_Id__c"
        name="Circle_Account_User_Id__c"
        type="hidden"
        value="111111-0fc0-4cb0-bc52-ea10666c8abb"
      />

      {/* // Visible inputs */}
      <SkeletonBox className="mt-6 h-10 w-full" loading={!session}>
        <Form.Input
          className="w-full"
          label={t`form.subject.label`}
          maxLength={80}
          name="subject"
          placeholder={t`form.subject.placeholder`}
        />
      </SkeletonBox>

      <SkeletonBox className="mt-6 h-32 w-full" loading={!session}>
        <Form.Textarea
          className="w-full"
          label={t`form.description.label`}
          maxLength={2000}
          name="description"
          placeholder={t`form.description.placeholder`}
        />
      </SkeletonBox>

      {/* // Google Recaptcha */}
      <SkeletonBox
        className="h-18 w-75"
        loading={!session || recaptchaLoading}
      />
      <div
        className={classNames({ hidden: !session || recaptchaLoading })}
        data-testid="recaptcha"
      >
        <GoogleReCAPTCHA
          asyncScriptOnLoad={handleAsyncScriptLoad}
          onChange={handleChange}
          onExpired={handleExpired}
          sitekey={publicRuntimeConfig?.SALESFORCE_RECAPTCHA_SITE_KEY ?? ''}
        />
      </div>

      <SkeletonBox className="h-10 w-full" loading={!session}>
        <Form.SubmitButton variant="primary">
          {t`common:submit`}
        </Form.SubmitButton>
      </SkeletonBox>
    </Form>
  )
}

import { Statement } from '@circlefin/components'
import { useScaFactorInfoQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { Layout } from '../Layout/Layout'

import { ScaCodes } from './ScaCodes/ScaCodes'
import { TotpCode } from './TotpCode/TotpCode'

export const Code: React.FC = () => {
  const { t } = useTranslation('mfa')
  const { data, loading } = useScaFactorInfoQuery()

  if (loading) {
    return (
      <Layout title={t`verifyAccount`}>
        <div className="grid h-full content-center">
          <Statement
            iconName="CircleSpinnerSolid"
            padded={false}
            title={t`loading`}
            variant="page"
            loop
          />
        </div>
      </Layout>
    )
  }

  if (data?.scaFactorInfo.isScaRequired) {
    return <ScaCodes />
  }

  return <TotpCode />
}

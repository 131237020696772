import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Circle wallets query.
 * Which users are allowed to view wallet policies?
 */
export const CIRCLE_WALLETS_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CUSTODY_POLICY_ROLE],
  includeAny: [Role.ACCOUNT_WEB_ROLE, Role.ACCOUNT_WEB_READ_ROLE],
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { y, useFormContext } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useVaultWalletsQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type {
  ComboboxOptionsLocale,
  ComboboxProps,
} from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { Scalars, VaultWalletWithVaultMetadata } from '@shared/graphql'

type WalletsByParentItem = SelectListItem<VaultWalletWithVaultMetadata>

export const WalletsByParentSchema = y.mixed<VaultWalletWithVaultMetadata>()

export interface WalletsByParentProps
  extends Pick<
    ComboboxProps<WalletsByParentItem>,
    'className' | 'label' | 'placeholder' | 'disabled' | 'message'
  > {
  /**
   * Parent Wallet ID to filter wallet list by.
   */
  parentWalletId?: Scalars['ID']['output']
  /**
   * Assets to filter the wallet list by.
   */
  assets?: string[]
  /**
   * Wallet id used to pre-select dropdown.
   */
  walletId?: Scalars['ID']['output']
  /**
   * Name of the form field.
   */
  name: string
  /**
   * Custom text to show if there are no wallets.
   */
  noWalletsMessage?: string
}

const Combobox = createFormCombobox()

/**
 * Wrapper around a Combobox component for reusable WalletsByParent field.
 */
export const WalletsByParent: React.FC<WalletsByParentProps> = ({
  parentWalletId,
  assets,
  walletId,
  className,
  label,
  placeholder,
  disabled,
  message,
  name,
  noWalletsMessage,
}) => {
  const { t } = useTranslation('forms')
  const schema = useMemo(
    () =>
      y.object({
        /**
         * Wallets by parent.
         */
        [name]: WalletsByParentSchema,
      }),
    [name],
  )

  const { setValue, resetField } = useFormContext<y.InferType<typeof schema>>()
  const wallets = useVaultWalletsQuery({
    variables: {
      parentWalletId,
    },
    // Only fetch data if we have parentWalletId
    skip: !parentWalletId,
  })

  const prevParentWalletId = useRef<Scalars['ID']['output']>(
    parentWalletId ?? '',
  )
  const [searchTerm, setSearchTerm] = useState('')

  const updateFormValue = useCallback(
    (value?: VaultWalletWithVaultMetadata) => {
      if (value) {
        setValue(name, value, {
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    },
    [name, setValue],
  )

  // Determine value whenever parentWalletId, walletId, assets, or data changes.
  useEffect(() => {
    setSearchTerm('')

    if (wallets.data?.vaultWallets.length === 1) {
      // If only one item then auto-select
      updateFormValue(wallets.data.vaultWallets[0])
    } else if (walletId) {
      // If walletId provided, pre-select dropdown item
      updateFormValue(
        wallets.data?.vaultWallets.find((wallet) => wallet.id === walletId),
      )
    } else if (
      parentWalletId &&
      parentWalletId !== prevParentWalletId.current
    ) {
      // Clear form value if parentWalletId changes (but not when mounting)
      resetField(name)

      return () => {
        // Set prevPrevParentWalletId for comparison on mount or re-render
        prevParentWalletId.current = parentWalletId
      }
    }

    // NOTE: Don't need to trigger effect when `walletId` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetField, parentWalletId, wallets.data?.vaultWallets, name])

  // WalletsByParent List.
  const walletsList: WalletsByParentItem[] = useMemo(() => {
    return wallets.data
      ? wallets.data?.vaultWallets
          .filter((wallet) =>
            // if we have an asset filter, we filter out the wallets that don't have those assets.
            // otherwise, we return all the wallets.
            assets ? assets.includes(wallet.assetMetadata.symbol) : true,
          )
          .map((wallet) => ({
            value: wallet,
            label: wallet.assetMetadata.symbol,
            description: wallet.assetMetadata.name,
            icon: wallet.assetMetadata.icon,
          }))
      : []
  }, [assets, wallets.data])

  const filtered = useMemo(() => {
    if (searchTerm) {
      return matchSorter(walletsList, searchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [walletsList, searchTerm])

  // Update search term.
  const onInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  // Clear search term and trigger combobox value changes.
  const onChange = useCallback(() => onInputChange(''), [onInputChange])

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage:
        noWalletsMessage ?? t`combobox.vault.walletsByParent.noItemsMessage`,
    }),
    [noWalletsMessage, t],
  )

  return (
    <Combobox
      className={className}
      data-testid="vault-wallets-by-parent-combobox"
      debounce={300}
      disabled={disabled}
      filtered={filtered}
      inputLoading={wallets.loading}
      items={walletsList}
      label={label ?? t`combobox.vault.walletsByParent.label`}
      locale={locale}
      maxMenuItems={6}
      message={message}
      name={name}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={placeholder ?? t`combobox.vault.walletsByParent.placeholder`}
      searchTerm={searchTerm}
    />
  )
}

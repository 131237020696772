import { useEffect, useRef } from 'react'

import { useRouter } from 'next/router'

import { RouteHistoryContext } from './context'

export interface RouteHistoryProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const RouteHistoryProvider: React.FC<RouteHistoryProviderProps> = ({
  children,
}) => {
  const { asPath, isReady } = useRouter()

  const ref = useRef<string | null>(null)

  useEffect(() => {
    if (isReady) {
      ref.current = asPath
    }
  }, [asPath, isReady])

  return (
    <RouteHistoryContext.Provider value={{ prevRoute: ref.current }}>
      {children}
    </RouteHistoryContext.Provider>
  )
}

import { useMemo } from 'react'

import { VaultUserPolicyRoleEnum } from '@shared/graphql'

import { useCreateVault } from '../../../../hooks/create'

export const useWalletConnectValidate = () => {
  const [
    {
      isWalletConnectStepVisible,
      isWalletConnectPolicyEnabled,
      walletConnectPolicy,
      usersPolicy = [],
      touched,
    },
  ] = useCreateVault()

  const approvers = useMemo(
    () =>
      usersPolicy
        .filter(({ permissions }) =>
          permissions.includes(VaultUserPolicyRoleEnum.APPROVER),
        )
        .map((policy) => policy.user),
    [usersPolicy],
  )

  const operators = useMemo(
    () =>
      usersPolicy
        .filter(({ permissions }) =>
          permissions.includes(VaultUserPolicyRoleEnum.OPERATOR),
        )
        .map((policy) => policy.user),
    [usersPolicy],
  )

  const isTouched = touched?.walletConnectPolicy

  const isValid = useMemo(() => {
    if (!walletConnectPolicy || !isWalletConnectPolicyEnabled) {
      return false
    }

    return operators.length > 0 && walletConnectPolicy.operators.length > 0
  }, [isWalletConnectPolicyEnabled, operators.length, walletConnectPolicy])

  const isEmpty = useMemo(() => {
    if (!walletConnectPolicy) {
      return true
    }

    return (
      walletConnectPolicy.operators.length === 0 &&
      walletConnectPolicy.approvers.length === 0 &&
      !walletConnectPolicy.minNeedConfirmed
    )
  }, [walletConnectPolicy])

  return {
    isOptional: !isWalletConnectStepVisible || !isWalletConnectPolicyEnabled,
    isValid,
    isEmpty,
    approvers,
    operators,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

import type { PropsWithChildren } from 'react'

import { Coolwhip } from '@circlefin/components'
import { Footer } from '@containers/layout'

export const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="grid min-h-screen w-full grid-rows-[auto_min-content] font-circular">
      <Coolwhip
        className="fixed bottom-0 h-screen w-full translate-y-56 opacity-0 md:opacity-100"
        variant="fade-top/green-blue-violet"
      />
      <div className="relative h-full overflow-y-auto">{children}</div>
      <div className="z-10 bg-neutral type-body-xs">
        <Footer />
      </div>
    </div>
  )
}

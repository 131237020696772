import { useCallback } from 'react'

import { VerticalStepper } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'
import { CIRCLE_WALLET_APPROVAL } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import { WalletApprovalPolicyStep } from '../../../hooks/approval-policy/context'

export interface NavigationProps {
  /**
   * The active step.
   */
  currentStep: WalletApprovalPolicyStep
  /**
   * Is permissions step valid.
   */
  isPermissionsValid?: boolean
  /**
   * Is policy limits step valid.
   */
  isPolicyLimitsValid?: boolean
  /**
   * Is user limits step valid.
   */
  isUserLimitsValid?: boolean
  /**
   * Is policy settings step valid.
   */
  isPolicySettingsValid?: boolean
  /**
   * Is approval workflow step valid.
   */
  isApprovalWorkflowValid?: boolean
  /**
   * Is review step valid.
   */
  isReviewValid?: boolean
  /**
   * Is flow complete.
   */
  isFlowComplete?: boolean
}

export const Navigation: React.FC<NavigationProps> = ({
  currentStep,
  isPolicyLimitsValid,
  isPermissionsValid,
  isUserLimitsValid,
  isApprovalWorkflowValid,
  isPolicySettingsValid,
  isReviewValid,
  isFlowComplete,
}) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const modal = useModal()
  const [, { isAuthorized: isPolicyMethodChangeEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  const isLimitsValid = isPolicyLimitsValid && isUserLimitsValid

  const handleStepClick = useCallback(
    (route: string) => {
      return () => modal.router.push(route)
    },
    [modal],
  )

  return (
    <VerticalStepper isFlowComplete={isFlowComplete}>
      <VerticalStepper.Step
        isActive={currentStep === WalletApprovalPolicyStep.PERMISSIONS}
        isStepComplete={isPermissionsValid}
        label={t`navigation.steps.permissions`}
        onClick={handleStepClick(
          routes.walletApprovalPolicy.createPolicy.permissions,
        )}
      />
      <VerticalStepper.Step
        isActive={
          currentStep === WalletApprovalPolicyStep.POLICY_LIMITS ||
          currentStep === WalletApprovalPolicyStep.USER_LIMITS
        }
        isDisabled={!isPermissionsValid}
        isHidden={!FeatureSwitch.walletApprovalLimitsStepsEnabled()}
        isStepComplete={isLimitsValid}
        label={t`navigation.steps.limits`}
        onClick={handleStepClick(
          routes.walletApprovalPolicy.createPolicy.policyLimits,
        )}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === WalletApprovalPolicyStep.POLICY_LIMITS}
          isStepComplete={isPolicyLimitsValid}
          label={t`navigation.steps.policyLimits`}
          onClick={handleStepClick(
            routes.walletApprovalPolicy.createPolicy.policyLimits,
          )}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === WalletApprovalPolicyStep.USER_LIMITS}
          isDisabled={!isPolicyLimitsValid}
          isStepComplete={isUserLimitsValid}
          label={t`navigation.steps.userLimits`}
          onClick={handleStepClick(
            routes.walletApprovalPolicy.createPolicy.userLimits,
          )}
        />
      </VerticalStepper.Step>
      <VerticalStepper.Step
        isActive={currentStep === WalletApprovalPolicyStep.APPROVAL}
        isDisabled={
          FeatureSwitch.walletApprovalLimitsStepsEnabled()
            ? !isLimitsValid
            : !isPermissionsValid
        }
        isStepComplete={isApprovalWorkflowValid}
        label={t`navigation.steps.approval`}
        onClick={handleStepClick(
          routes.walletApprovalPolicy.createPolicy.approval,
        )}
      />
      <VerticalStepper.Step
        isActive={currentStep === WalletApprovalPolicyStep.SETTINGS}
        isHidden={!isPolicyMethodChangeEnabled}
        isStepComplete={isPolicySettingsValid}
        label={t`navigation.steps.settings`}
        onClick={handleStepClick(
          routes.walletApprovalPolicy.createPolicy.settings,
        )}
      />
      <VerticalStepper.Step
        isActive={
          currentStep === WalletApprovalPolicyStep.REVIEW ||
          currentStep === WalletApprovalPolicyStep.FINISHED
        }
        isDisabled={!isReviewValid}
        label={t`navigation.steps.reviewAndFinish`}
        onClick={handleStepClick(
          routes.walletApprovalPolicy.createPolicy.review,
        )}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === WalletApprovalPolicyStep.REVIEW}
          isStepComplete={currentStep === WalletApprovalPolicyStep.FINISHED}
          label={t`navigation.steps.review`}
          onClick={handleStepClick(
            routes.walletApprovalPolicy.createPolicy.review,
          )}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === WalletApprovalPolicyStep.FINISHED}
          isDisabled={!isFlowComplete}
          label={t`navigation.steps.finished`}
          onClick={handleStepClick(
            routes.walletApprovalPolicy.createPolicy.finished,
          )}
        />
      </VerticalStepper.Step>
    </VerticalStepper>
  )
}
export default Navigation

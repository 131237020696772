import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { vaultsSection } from '@services/sections/lib/vaults'
import { TransWithLink } from '@shared/components/common'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export const Default: React.FC = () => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()
  const router = useRouter()

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const goToVaults = useCallback(() => {
    void router.push(vaultsSection.route)
  }, [router])

  return (
    <BaseTitle title={t('vault.removeWarning.title')}>
      <p className="text-sm leading-6 text-black-600">
        {t(`vault.removeWarning.default.section1`)}
      </p>

      <p className="mt-6 text-sm leading-6 text-black-600">
        {t(`vault.removeWarning.default.section2`)}
      </p>

      <p className="mt-6 text-sm leading-6 text-black-600">
        <TransWithLink
          i18nKey="modals/settings/users:vault.removeWarning.default.section3"
          noWrap={false}
          variant="internal-support"
        />
      </p>
      <Modal.Footer className="mt-6" variant="stretch" dense>
        <Button onClick={closeModal} variant="secondary">
          {t('common:dismiss')}
        </Button>
        <Button onClick={goToVaults} variant="primary">
          {t('vault.removeWarning.goToVaults')}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Circle wallets mutation.
 * Which users are allowed to make wallet policy changes?
 */
export const CIRCLE_WALLETS_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CUSTODY_POLICY_ROLE, Role.ACCOUNT_WEB_ROLE],
}

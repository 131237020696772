import { useCallback, useMemo } from 'react'

import { Header } from '@circlefin/components'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { InformationVariants } from '../Information/Information.types'
import { useLinkBankAccount } from '../Link.Context'

import { DefaultVariant, NonIbanVariant, BrazilVariant } from './Variants'

export const AccountInformation: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ steps, variant }, { setType }] = useLinkBankAccount()

  const handleTabSwitch = useCallback(
    (tab: string) => {
      switch (tab) {
        case 'wire':
          setType(InformationVariants.WIRE)
          break
        case 'xpay':
          setType(InformationVariants.XPAY)
          break
        case 'cbit':
          setType(InformationVariants.CBIT)
          break
        default:
          throw new Error(`Missing type for tab ${tab}.`)
      }
    },
    [setType],
  )

  const Variant = useMemo(() => {
    switch (variant) {
      case InformationVariants.NON_IBAN:
        return <NonIbanVariant />
      case InformationVariants.PIX:
        return <BrazilVariant />
      default:
        return <DefaultVariant handleTabSwitch={handleTabSwitch} />
    }
  }, [variant, handleTabSwitch])

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-4" variant="horizontal">
        <Header>
          <Header.Head title={t`link.accountInformation.title`} />
        </Header>
      </Center>

      <Center variant="horizontal">{Variant}</Center>
    </FullScreen>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Can a user submit an email vote?
 */
export const EMAIL_APPROVAL_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [SynthRole.SYNTH_EMAIL_APPROVAL_ROLE, Role.USER_MANAGEMENT_ROLE],
}

import { useCallback } from 'react'

import { getI18nErrorKey } from '@shared/apollo/lib/error/codes'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'

/**
 * Hook to get the error message from the Apollo error.
 */
export const useGraphQLError = () => {
  const { t } = useTranslation('common')

  const getError = useCallback(
    (error?: ApolloError, fallbackError?: string): string => {
      // Check for existing Graphql i18n error key
      const i18nErrorKey = getI18nErrorKey(error?.graphQLErrors)

      // When key exists, use it
      if (i18nErrorKey) {
        return t(i18nErrorKey, null, { fallback: 'generalError' })
      }

      // Fallback or general error message
      return fallbackError ?? t`generalError`
    },
    [t],
  )

  return {
    getError,
  }
}

import { expressSection } from '@services/sections/lib/express'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { FeatureTourStep } from '../../FeatureTour.Step'

export const StablecoinExpress: React.FC = () => {
  const { t } = useTranslation('modals.common')

  return (
    <FeatureTourStep
      data-testid="stablecoin-express"
      disclaimer={
        <Trans
          components={{
            b: <b />,
          }}
          i18nKey="modals.common:featureTour.stablecoinExpress.disclaimer"
        />
      }
      header={t`featureTour.stablecoinExpress.header`}
      iconName="USDC"
      link={{
        text: t`featureTour.stablecoinExpress.linkText`,
        url: expressSection.route,
      }}
      subHeader={t`featureTour.stablecoinExpress.subHeader`}
    />
  )
}

import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { OnboardingMintingVolume } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export interface MintingVolumeProps
  extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  /**
   * Force loading state.
   */
  loading?: boolean
}

const Dropdown = createFormDropdown()

export const MintingVolume: React.FC<MintingVolumeProps> = ({
  loading,
  ...props
}) => {
  const { t } = useTranslation('onboard/account-setup')

  const data: Array<SelectListItem<OnboardingMintingVolume>> = useMemo(
    () =>
      Object.values(OnboardingMintingVolume).map((option) => ({
        label: t(
          `onboard/common:form.mintingVolume.${OnboardingMintingVolume[option]}`,
        ),
        value: OnboardingMintingVolume[option],
      })),
    [t],
  )

  return (
    <SkeletonBox className="mt-6 h-10 w-full" loading={loading}>
      <Dropdown
        data-testid="dropdown-minting-volume"
        items={data}
        label={t`confirm.form.mintingVolume.label`}
        maxMenuItems={6}
        placeholder={t`confirm.form.mintingVolume.placeholder`}
        {...props}
      />
    </SkeletonBox>
  )
}

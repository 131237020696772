import { useCallback } from 'react'

import { Chip, Table } from '@circlefin/components'
import { UserRoleGroupType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CreateVault } from '../../../containers'

import type {
  User,
  VaultUserPolicy,
  VaultUserPolicyRoleEnum,
} from '@shared/graphql'

export interface PermissionRowProps {
  /**
   * User policy value.
   */
  userPolicy: VaultUserPolicy
  /**
   * Is owner of the vault being created.
   */
  isOwner?: boolean
  /**
   * Triggered when form gets changed.
   */
  onChange: (value: VaultUserPolicy) => void
  /**
   * Handler that will remove user from policy.
   */
  onRemove: (user: User) => void
}

export const PermissionRow: React.FC<PermissionRowProps> = ({
  userPolicy,
  isOwner = false,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation('modals.vault')

  const onMenuItemClick = useCallback(
    (selectedPermissions: VaultUserPolicyRoleEnum[]) => {
      onChange({
        ...userPolicy,
        permissions: selectedPermissions,
      })
    },
    [onChange, userPolicy],
  )

  const handleRemove = useCallback(
    () => onRemove(userPolicy.user),
    [onRemove, userPolicy.user],
  )

  return (
    <Table.Body.Row data-testid="user-limit-row">
      <Table.Body.Cell className="px-4 py-5">
        {userPolicy.user.name}
        {isOwner && (
          <Chip className="ml-1" variant="default/info">
            {t('createVault.permissions.owner')}
          </Chip>
        )}
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        {userPolicy.user.role?.groupType === UserRoleGroupType.VIEWER ? (
          <CreateVault.Menu.Viewer isOwner={isOwner} onRemove={handleRemove} />
        ) : (
          <CreateVault.Menu.Default
            isOwner={isOwner}
            onClick={onMenuItemClick}
            onRemove={handleRemove}
            selectedPermissions={userPolicy.permissions}
          />
        )}
      </Table.Body.Cell>
    </Table.Body.Row>
  )
}

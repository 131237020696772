import { vaultsSection } from '@services/sections/lib/vaults'
import { VAULT_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { BackButton, Content } from '@shared/components/layout'
import { useVaultQuery } from '@shared/graphql'
import { PageLayout } from '@shared/layout'
import { useRouter } from 'next/router'

import { VaultDetailHeader } from '../../containers'
import { VaultPermissionProvider } from '../../hooks/permission/provider'

import type { AppLayoutType } from '@shared/layout/core'

// TODO: We shouldn't have query in the layout, it should move to the page or context
export const VaultLayout: AppLayoutType = ({ children }) => {
  const { query } = useRouter()

  const vaultId = query?.vaultId as string

  const { data, loading } = useVaultQuery({
    variables: {
      id: vaultId,
    },
    pollInterval: VAULT_DETAILS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  return (
    <PageLayout>
      <VaultPermissionProvider vaultMetadata={data?.vault}>
        <Content>
          <BackButton section={vaultsSection} />
          <VaultDetailHeader
            loading={loading}
            vault={data?.vault}
            vaultId={vaultId}
          />
        </Content>
        {children}
      </VaultPermissionProvider>
    </PageLayout>
  )
}

import { usePermission } from '@circlefin/permissions'
import { CIRCLE_WALLET_APPROVAL } from '@services/permissions'

import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'

export const usePolicySettingsValidate = () => {
  const [{ touched, settings }] = useWalletApprovalPolicy()
  const [, { isAuthorized: isPolicyMethodChangeEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  // Ignore policy settings when not enabled
  const isTouched = touched?.settings || !isPolicyMethodChangeEnabled
  const isValid =
    settings?.proposalApprovalMethod != null || !isPolicyMethodChangeEnabled
  const isEmpty =
    settings?.proposalApprovalMethod == null || !isPolicyMethodChangeEnabled

  return {
    isTouched,
    isValid,
    isEmpty,
    enableSaveExit: isValid,
  }
}

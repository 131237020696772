import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { MaybeNull } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  LegalEntity,
  useWalletPaymentMethodQuery,
  Currency,
  useEntityQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { WalletPaymentMethod } from '@shared/graphql'

export const Wallet = () => {
  const { t } = useTranslation('modals/settings/billing')
  const { data, loading, error } = useWalletPaymentMethodQuery()
  const { money } = useMoney()
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = useEntityQuery()

  const mainWallet: WalletPaymentMethod | undefined = useMemo(
    () =>
      data?.walletPaymentMethods.find((wallet) => {
        if (entityData?.entity?.legalEntity === LegalEntity.CIRCLE_FR) {
          return wallet?.balance.currency === Currency.EURC
        }
        return wallet?.balance.currency === Currency.USDC
      }),
    [data, entityData?.entity?.legalEntity],
  )

  return (
    <div className="flex justify-start">
      <GraphQLErrorBoundary error={error || entityError}>
        <SkeletonBox loading={loading || entityLoading}>
          <div className="flex flex-col">
            <div className="text-sm leading-5 font-circular-book">
              {t('invoice.selectPaymentMethod.linkedWallet.mainWallet')}
            </div>
            <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular">
              <MaybeNull>
                {mainWallet && (
                  <Trans
                    i18nKey="modals/settings/billing:invoice.selectPaymentMethod.linkedWallet.availableBalance"
                    values={{
                      amount: money({
                        number: mainWallet.balance.amount,
                        variant: mainWallet.balance.currency,
                        options: {
                          symbol: true,
                          signDisplay: false,
                          padded: false,
                        },
                      }),
                    }}
                  />
                )}
              </MaybeNull>
            </div>
          </div>
        </SkeletonBox>
      </GraphQLErrorBoundary>
    </div>
  )
}

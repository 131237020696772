import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * CELO blockchain USDC access permission.
 */
export const CELO_BLOCKCHAIN: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CELO_BLOCKCHAIN_ROLE],
}

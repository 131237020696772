import useTranslation from 'next-translate/useTranslation'

export interface MaybeNullProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const MaybeNull: React.FC<MaybeNullProps> = ({ children }) => {
  const { t } = useTranslation('common')

  // if we have children, we just render that, otherwise, we render the placeholder
  return <>{children != null ? children : t('placeholder')}</>
}

import type { FocusEventHandler } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { Button, Icon, Chip } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import classNames from 'classnames'
import uniqBy from 'lodash.uniqby'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { UserPermissionRoleText } from '../../../components'

import type { UserPermissionRoleTextProps } from '../../../components'
import type {
  ComboboxOptionsLocale,
  ComboboxProps,
} from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { User } from '@shared/graphql'

type ApproverItem = SelectListItem<User>

export const policyApproverSchema = y.object({
  /**
   * Current selected Approver.
   * This is the state stored by the inner combobox.
   */
  selectedApprover: y.mixed<User>(),
  /**
   * List of selected approvers.
   * This is the actual state the form cares about.
   */
  selected: y
    .array(y.mixed<User>().required())
    .min(1, { key: 'select.policyApprover' })
    .required(),
})

export interface ApproversProps
  extends Pick<ComboboxProps<ApproverItem>, 'error' | 'message'> {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Combobox Name.
   */
  name: string
  /**
   * Selected approvers.
   */
  selected: User[]
  /**
   * Approvers list (all).
   */
  approvers?: User[]
  /**
   * Label displayed for the combobox.
   */
  label?: string
  /**
   * Message displayed for empty state (No items).
   */
  noItemsMessage?: string
  /**
   * Hide Role Text.
   */
  hideRoleText?: boolean
  /**
   * On selected change handler.
   */
  onSelectedChange?: (newSelect: User[]) => void
  /**
   * On blur handler.
   */
  onBlur?: FocusEventHandler<HTMLDivElement>
  /**
   * Get user permissions.
   */
  getUserPermissions?: (
    user: User,
  ) => UserPermissionRoleTextProps['permissions']
}

const Combobox = createFormCombobox()

export const PolicyApprovers: React.FC<ApproversProps> = ({
  className,
  name,
  error,
  message,
  approvers = [],
  selected,
  label = '',
  noItemsMessage = '',
  hideRoleText = false,
  onSelectedChange,
  getUserPermissions,
  onBlur,
}) => {
  const { t } = useTranslation('forms')

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  // Approver list.
  const list: ApproverItem[] = useMemo(() => {
    // filter out selected approvers from the list of options the user can select from
    return approvers
      .filter(
        (approver) => !selected.find((selected) => selected.id === approver.id),
      )
      .map((approver) => {
        return {
          value: approver,
          label: approver.name,
        }
      })
  }, [approvers, selected])

  const filtered = useMemo(() => {
    if (debouncedSearchTerm) {
      return matchSorter(list, debouncedSearchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [debouncedSearchTerm, list])

  const handleAddSelected = useCallback(
    (item: ApproverItem | null) => {
      setSearchTerm('')
      setDebouncedSearchTerm('')

      if (item?.value) {
        onSelectedChange?.(uniqBy([...selected, item.value], 'id'))
      }
    },
    [onSelectedChange, selected],
  )

  const onRemoveSelected = useCallback(
    (selectedUser: User) => () => {
      onSelectedChange?.(selected.filter((user) => user.id !== selectedUser.id))
    },
    [onSelectedChange, selected],
  )

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-neutral-subtlest">
          <Trans
            components={{
              span: <span className="text-neutral-subtle font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: noItemsMessage,
    }),
    [t, noItemsMessage],
  )

  return (
    <div data-testid="approver-combobox" onBlur={onBlur}>
      <Combobox
        className={className}
        debounce={300}
        error={error}
        filtered={filtered}
        items={list}
        label={label}
        locale={locale}
        maxMenuItems={6}
        message={message}
        name={name}
        onChange={handleAddSelected}
        onDebouncedInputChange={setDebouncedSearchTerm}
        onInputChange={setSearchTerm}
        searchTerm={searchTerm}
        hideError
      />

      {selected.length > 0 && (
        <div className="mt-6 grid grid-cols-1 gap-y-2">
          {selected.map((user) => (
            <Chip
              key={user.id}
              className="block w-full px-4 py-2"
              data-testid="user-chip"
              variant="default/info"
            >
              <span
                className={classNames('grid items-center gap-x-4', {
                  'grid-cols-3': !hideRoleText,
                  'grid-cols-2': hideRoleText,
                })}
              >
                <span className="mr-4 max-w-40 justify-self-start truncate text-black-700 type-body-base-bold">
                  {user.name}
                </span>
                {!hideRoleText && (
                  <span className="min-w-60 justify-self-start text-neutral-subtle font-circular-regular type-body-base">
                    <UserPermissionRoleText
                      permissions={getUserPermissions?.(user) ?? []}
                    />
                  </span>
                )}
                <Button
                  className="justify-self-end"
                  label={t`common:remove`}
                  onClick={onRemoveSelected(user)}
                  size="sm"
                  variant="primary"
                  iconOnly
                >
                  <Icon name="XSolid" />
                </Button>
              </span>
            </Chip>
          ))}
        </div>
      )}
    </div>
  )
}

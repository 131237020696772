import { useCallback } from 'react'

import { signIn } from '@circlefin/auth'
import { Button, Statement } from '@circlefin/components'
import { y } from '@circlefin/form'
import { useReCaptcha } from '@circlefin/recaptcha/react'
import { signIn as signInSection } from '@services/sections/lib/home'
import { SegmentEvents, useSegment } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import {
  AccountType,
  CountryCode,
  useCreateRolrAccountMutation,
} from '@shared/graphql'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'
import {
  CreateAccountForm,
  createAccountFormSchemaFields,
} from '../../CreateAccountForm'

import type { CreateAccountFormValues } from '../../CreateAccountForm'

const { privacyConsent, marketingConsent, ...desiredSchemaFields } =
  createAccountFormSchemaFields

export const createRolrAccountFormSchema = y.object(desiredSchemaFields)

export interface RolrCreateAccountFormValues
  extends Omit<
    CreateAccountFormValues,
    'privacyConsent' | 'marketingConsent'
  > {}

export const RolrCreateAccount: React.FC = () => {
  const { t } = useTranslation('onboard/rolr-signup')
  const router = useRouter()

  const { track } = useSegment()
  const { withReCaptcha, createContext } = useReCaptcha({
    action: 'create_rolr_account',
  })

  const [{ businessInfo, redemptionAmount }, { computeNextStep }] =
    useRolrSetup()
  const [createAccount, { error, reset }] = useCreateRolrAccountMutation()

  const complete = useCallback(
    async (formValues: CreateAccountFormValues) => {
      const { privacyConsent, marketingConsent, ...values } = formValues

      await withReCaptcha({
        onCompleted: async (token) => {
          if (!businessInfo || !redemptionAmount) {
            return
          }

          computeNextStep({ step: 'createAccount', values })

          try {
            const { data } = await createAccount({
              variables: {
                input: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.businessEmail,
                  businessName: values.businessName,
                  accountType: businessInfo.isEeaUser
                    ? AccountType.MINT_ROLR_EEA
                    : AccountType.MINT_ROLR_NON_EEA,
                  countryOfIncorporation: values.country,
                  primaryPlaceOfBusiness: businessInfo?.primaryPlaceOfBusiness,
                  legalEntityType: businessInfo.legalEntityType,
                  natureOfBusiness: businessInfo.businessType,
                  redemptionAmount: redemptionAmount,
                  password: values.password,
                },
              },
              context: createContext(token),
            })

            // Track event
            const { password, ...trackingValues } = values

            track(SegmentEvents.FormSubmitted, {
              props: {
                values: trackingValues,
              },
            })

            // Unresolved keeps the submit button in loading state while we are
            // redirecting the user to the next page to avoid form resubmission.
            await new Promise(() => {
              if (data?.createRolrAccount) {
                // If we have a sessionToken, we can can auto-login
                void signIn(
                  { legacy: true },
                  {
                    sessionToken: data.createRolrAccount.sessionToken,
                  },
                )
              } else {
                // If no sessionToken, fallback to login screen
                void router.push(signInSection.route)
              }
            })
          } catch (e) {
            // Error UI handled in GraphQLErrorBoundary
          }
        },
      })
    },
    [
      businessInfo,
      redemptionAmount,
      withReCaptcha,
      computeNextStep,
      createAccount,
      createContext,
      track,
      router,
    ],
  )

  return (
    <div className="h-full w-128">
      <h1 className="mb-8 type-h-page-sm">{t`createAccount.title`}</h1>
      <div aria-live="polite">
        {error && (
          <Statement
            status="warning"
            subtitle={
              <TransWithLink
                i18nKey="onboard/common:createAccount.form.error"
                variant="support"
                noWrap
              />
            }
          >
            <Button onClick={reset} variant="secondary">
              {t`common:retry`}
            </Button>
          </Statement>
        )}
        <div className={classNames({ hidden: Boolean(error) })}>
          <CreateAccountForm
            initialValues={{
              businessName: businessInfo?.businessName ?? '',
              country: businessInfo?.countryOfIncorporation ?? CountryCode.US,
            }}
            onSubmit={complete}
            disableCountryField
          />
        </div>
      </div>
    </div>
  )
}

import { useContext } from 'react'

import { TransferWithFxContext } from './context'

/**
 * Transfer with FX flow hook.
 */
export const useTransferWithFx = () => {
  return useContext(TransferWithFxContext)
}

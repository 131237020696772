import type { ReactElement } from 'react'
import { useCallback, useMemo } from 'react'

import { Menu } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FeatureSwitch } from '@services/feature-switch'
import { routes } from '@services/sections/modal/routes'
import { VaultWalletState } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export interface VaultWalletDetailMenuProps {
  /**
   * ID of the vault wallet.
   */
  id: string
  /**
   * State of the vault wallet.
   */
  state: VaultWalletState
  /**
   * Flag that indicates if the wallet is archived.
   */
  archived: boolean
}
export const VaultWalletDetailMenu: React.FC<VaultWalletDetailMenuProps> = ({
  id,
  state,
  archived,
}) => {
  const { t } = useTranslation('vaults')
  const { vaultMetadata } = useVaultPermission()
  const { router } = useModal()

  const handleArchive = useCallback(() => {
    router.push({
      pathname: routes.vault.vaultWalletDetails.archive,
      query: {
        vaultId: vaultMetadata?.id,
        vaultWalletId: id,
      },
    })
  }, [vaultMetadata?.id, router, id])

  const handleDiscardDraft = useCallback(() => {
    router.push({
      pathname: routes.vault.vaultWalletDetails.discardDraft,
      query: {
        vaultId: vaultMetadata?.id,
        vaultWalletId: id,
      },
    })
  }, [vaultMetadata?.id, router, id])

  const options = useMemo(() => {
    const result: ReactElement[] = []

    /**
     * Only the creator of the vault and subsequently the wallet is allowed to do anything with it.
     */
    if (!vaultMetadata?.isCreator) return result

    if (
      state === VaultWalletState.ACTIVE &&
      !archived &&
      FeatureSwitch.vaultArchivingWalletsEnabled()
    ) {
      result.push(
        <Menu.Item
          key="archive"
          label={t`vaultWallets.detail.header.menu.archive`}
          onClick={handleArchive}
        />,
      )
    }

    if (state === VaultWalletState.DRAFT) {
      result.push(
        <Menu.Item
          key="remove"
          label={t`vaultWallets.detail.header.menu.remove`}
          onClick={handleDiscardDraft}
        />,
      )
    }

    return result
  }, [
    state,
    t,
    archived,
    vaultMetadata?.isCreator,
    handleArchive,
    handleDiscardDraft,
  ])

  /**
   * Do not render the menu if there are no actions available.
   */
  if (options.length === 0) return null

  return (
    <div className="inline-flex size-10 items-center justify-center rounded-sm border border-black-300">
      <Menu className="z-50">
        <Menu.Icon className="text-black-300" name="DotsVerticalSolid" />
        <Menu.Options>{options}</Menu.Options>
      </Menu>
    </div>
  )
}

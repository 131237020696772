import { ModalRoute } from '@circlefin/modal-router'
import { FeatureTour, ProductTour } from '@features/promotions/modals'
import { promotions } from '@services/sections/modal/promotions/promotions'

/**
 * Common Router.
 */
export const PromotionsRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<FeatureTour />} path={promotions.featureTour} />
      <ModalRoute content={<ProductTour />} path={promotions.productTour} />
    </>
  )
}

import { ModalRoute } from '@circlefin/modal-router'
import { ToggleSetting } from '@features/delayed-withdrawals/modals'
import { delayedWithdrawals } from '@services/sections/modal/delayed-withdrawals/delayed-withdrawals'

/**
 * Account: Security Router.
 */
export const DelayedWithdrawalsRouter: React.FC = () => {
  return (
    <>
      <ModalRoute
        content={<ToggleSetting />}
        path={delayedWithdrawals.toggleSetting}
      />
    </>
  )
}

import type { MutationUpdaterFn } from '@apollo/client'
import type { SetBlockchainRecipientAddressWithMetadataMutation } from '@shared/graphql'

/**
 * Update Blockchain Recipient With Metadata in cache.
 */
export const setBlockchainRecipientAddressWithMetadata: MutationUpdaterFn<SetBlockchainRecipientAddressWithMetadataMutation> =
  (cache, { data }) => {
    if (data?.setBlockchainRecipientAddressWithMetadata) {
      cache.modify({
        id: cache.identify({
          id: data.setBlockchainRecipientAddressWithMetadata.id,
          __typename: 'BlockchainRecipientAddressWithMetadata',
        }),
        fields: {
          /** Update modifiable fields in blockchain recipient address. */
          email: () => {
            return data.setBlockchainRecipientAddressWithMetadata.email ?? null
          },
          description: () => {
            return (
              data.setBlockchainRecipientAddressWithMetadata.description ?? null
            )
          },
          owner: () => {
            return data.setBlockchainRecipientAddressWithMetadata.owner
          },
        },
      })
    }
  }

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Which users are allowed to deposit to a bank account?
 * In the initial stage, we will assign WIRE_DEPOSIT_ROLE to new EU redemption-only customers, existing customers will have WIRES_ROLE
 * WIRES_ROLE will be deprecated in the future.
 */
export const BANK_DEPOSIT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WIRES_ROLE, Role.WIRES_DEPOSIT_ROLE],
}

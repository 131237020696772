import { WalletApprovalTransactionType } from '@shared/graphql'

import type {
  WalletApprovalTransactionDetail,
  WalletApprovalOnchainTransactionDetail,
  WalletApprovalPayoutTransactionDetail,
  WalletApprovalVaultWalletTransactionDetail,
  Maybe,
} from '@shared/graphql'

/**
 * Type guard to check if a given transaction detail is an onchain transaction.
 */
export function isOnchain(
  transaction?: Maybe<WalletApprovalTransactionDetail>,
): transaction is WalletApprovalOnchainTransactionDetail {
  return transaction?.type === WalletApprovalTransactionType.ONCHAIN
}

/**
 * Type guard to check if a given transaction detail is a payout transaction.
 */
export function isPayout(
  transaction?: Maybe<WalletApprovalTransactionDetail>,
): transaction is WalletApprovalPayoutTransactionDetail {
  return transaction?.type === WalletApprovalTransactionType.PAYOUT
}

/**
 * Type guard to check if a given transaction detail is a vault wallet transaction.
 */
export function isVaultWallet(
  transaction?: Maybe<WalletApprovalTransactionDetail>,
): transaction is WalletApprovalVaultWalletTransactionDetail {
  return transaction?.type === WalletApprovalTransactionType.VAULT_WALLET
}

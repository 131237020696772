import { FeatureSwitch } from '@services/feature-switch'

import { useApprovalWorkflowValidate } from '../useApprovalWorkflowValidate/useApprovalWorkflowValidate'
import { usePermissionValidate } from '../usePermissionValidate/usePermissionValidate'
import { usePolicyLimitsValidate } from '../usePolicyLimitsValidate/usePolicyLimitsValidate'
import { usePolicySettingsValidate } from '../usePolicySettingsValidate/usePolicySettingsValidate'
import { useUserLimitsValidate } from '../useUserLimitsValidate/useUserLimitsValidate'

export const useWalletApprovalPolicyValidate = () => {
  const permissionValidate = usePermissionValidate()
  const policyLimitValidate = usePolicyLimitsValidate()
  const userLimitsValidate = useUserLimitsValidate()
  const policySettingsValidate = usePolicySettingsValidate()
  const approvalWorkflowValidate = useApprovalWorkflowValidate()

  const limitsStepValid = FeatureSwitch.walletApprovalLimitsStepsEnabled()
    ? policyLimitValidate.isValid && userLimitsValidate.isValid
    : true

  const limitsStepEmpty = FeatureSwitch.walletApprovalLimitsStepsEnabled()
    ? policyLimitValidate.isEmpty && userLimitsValidate.isEmpty
    : true

  const limitsSaveAndExitEnabled =
    FeatureSwitch.walletApprovalLimitsStepsEnabled()
      ? policyLimitValidate.enableSaveExit && userLimitsValidate.enableSaveExit
      : true

  const isValid =
    permissionValidate.isValid &&
    limitsStepValid &&
    (approvalWorkflowValidate.isOptional || approvalWorkflowValidate.isValid) &&
    policySettingsValidate.isValid

  const enableSaveExit =
    permissionValidate.enableSaveExit &&
    limitsSaveAndExitEnabled &&
    approvalWorkflowValidate.enableSaveExit &&
    policySettingsValidate.enableSaveExit

  const isAllStepsEmpty =
    permissionValidate.isEmpty &&
    limitsStepEmpty &&
    approvalWorkflowValidate.isEmpty &&
    policySettingsValidate.isEmpty

  return {
    isValid,
    enableSaveExit,
    allStepsEmpty: isAllStepsEmpty,
  }
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

import type { Asset, AssetBlockchain } from '@shared/graphql'

export interface CreateVaultWalletState {
  /**
   * The asset that should be deposited.
   */
  asset?: Asset
  /**
   * Blockchain the asset should be stored on.
   */
  blockchain?: AssetBlockchain
  /**
   * Vault id.
   */
  vaultId?: string
}

interface CreateVaultWalletActions {
  /**
   * Set vault id.
   */
  setVaultId: (vaultId: string) => void
  /**
   * Set the asset that should be stored.
   */
  setAsset: (asset: Asset) => void
  /**
   * Set the blockchain that should be stored.
   */
  setBlockchain: (blockchain: AssetBlockchain) => void
}

const CreateVaultWalletContext = createContext<
  [CreateVaultWalletState, CreateVaultWalletActions]
>([
  {},
  {
    setAsset: () => null,
    setBlockchain: () => null,
    setVaultId: () => null,
  },
])

/**
 * Create Wallet Provider props.
 */
interface CreateVaultWalletProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<CreateVaultWalletState>
}

export const Provider: React.FC<CreateVaultWalletProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<CreateVaultWalletState>({
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<CreateVaultWalletState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const actions: CreateVaultWalletActions = useMemo(
    () => ({
      setAsset: (asset) => handleValueChange({ asset }),
      setBlockchain: (blockchain) => handleValueChange({ blockchain }),
      setVaultId: (vaultId) => handleValueChange({ vaultId }),
    }),
    [handleValueChange],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange({})
    }

    events.on('onCloseStart', resetContext)

    return () => {
      events.off('onCloseStart', resetContext)
    }
  })

  return (
    <CreateVaultWalletContext.Provider value={[values, actions]}>
      {children}
    </CreateVaultWalletContext.Provider>
  )
}

/**
 * Create wallet hook.
 */
export const useCreateVaultWallet = () => {
  return useContext(CreateVaultWalletContext)
}

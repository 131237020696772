import type { ChangeEvent } from 'react'
import { useCallback } from 'react'

import { Card, Icon, SelectCard, SkeletonBox } from '@circlefin/components'
import { MaybeNull } from '@shared/components/common'
import { PaymentMethodType, useCardPaymentMethodsQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { usePayInvoice } from '../../PayInvoice.Context'

export interface CardPaymentMethodsProps {
  /**
   * Radio name.
   */
  radioName: string
}

export const CardPaymentMethods: React.FC<CardPaymentMethodsProps> = ({
  radioName,
}) => {
  const { t } = useTranslation('modals/settings/billing')
  const { data, loading } = useCardPaymentMethodsQuery({
    variables: {
      page: {
        pageSize: 200,
      },
    },
  })

  const [{ cardId }, { setPaymentDetails }] = usePayInvoice()

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPaymentDetails({
        paymentType: PaymentMethodType.card,
        cardId: e.target.value,
      })
    },
    [setPaymentDetails],
  )

  return (
    <div className="grid gap-y-3" data-testid="card-payment-methods">
      <SkeletonBox className="h-18 w-full" loading={loading}>
        {data && data?.cardPaymentMethods.list.length > 0 ? (
          data?.cardPaymentMethods.list.map((card, index) => {
            return (
              <SelectCard
                key={index}
                checked={cardId === card.id}
                id={`radio-${card.network}-${card.last4}`}
                label={t('invoice.selectPaymentMethod.linkedCards.cardTitle', {
                  network: card.network,
                  last4: card.last4,
                })}
                name={radioName}
                onChange={onChange}
                type="radio"
                value={card.id}
                variant="primary/sm"
              >
                <div className="flex items-center space-x-10 py-3">
                  <Icon name="LinkOutline" size={18} />
                  <div className="flex justify-start">
                    <div className="flex flex-col">
                      <div className="text-sm leading-5 font-circular-book">
                        <MaybeNull>
                          <Trans
                            i18nKey="modals/settings/billing:invoice.selectPaymentMethod.linkedCards.cardTitle"
                            values={{
                              cardName: card.network,
                              last4: card.last4,
                            }}
                          />
                        </MaybeNull>
                      </div>
                      <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular">
                        <MaybeNull>
                          <Trans
                            i18nKey="modals/settings/billing:.invoice.selectPaymentMethod.linkedCards.expiry"
                            values={{
                              expMonth: card.expMonth,
                              expYear: card.expYear,
                            }}
                          />
                        </MaybeNull>
                      </div>
                    </div>
                  </div>
                </div>
              </SelectCard>
            )
          })
        ) : (
          <Card
            className="flex h-18 items-center justify-center"
            data-testid="no-linked-cards"
          >
            <div className="text-sm font-circular-book">
              {t`invoice.selectPaymentMethod.noAddedCards`}
            </div>
          </Card>
        )}
      </SkeletonBox>
    </div>
  )
}

import { useEffect, useRef } from 'react'

import { useReactiveVar } from '@apollo/client'
import { mfaRequiredVar } from '@shared/apollo/links'
import { useMfa } from '@shared/mfa'

export const MfaStepUpModal = () => {
  const [stepUp, { mfaSuccess, mfaError }] = useMfa()
  const { required, retry, skip } = useReactiveVar(mfaRequiredVar)

  // Apollo reactive var will fire state update even
  // when the value didn't change, we have to keep
  // the last state and compare to it to prevent
  // multiple re-renders inside `useEffect`
  const prev = useRef(false)

  useEffect(() => {
    // Check reactive var against the last value
    if (required && !prev.current) {
      // Fire up MFA modal
      stepUp({
        // Call retry when user enter full code
        onComplete: (code) => {
          prev.current = false
          retry?.({
            ...code,
            onError(error) {
              mfaError({ error })
            },
            onSuccess: mfaSuccess,
          })
        },
        // Skip retry when the user close the modal
        onAbort: () => {
          prev.current = false
          skip?.()
        },
      })
      // Save current value as prev value
      prev.current = required
    }
    // Apollo reactive vars trigger `useEffect` when it's not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required])

  return null
}

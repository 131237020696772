import { Icon, Tooltip } from '@circlefin/components'
import cn from 'classnames'

import type { IconProps } from '@circlefin/components/lib/Icon'
import type { TooltipProps } from '@circlefin/components/lib/Tooltip'

export type IconTooltipProps = Pick<IconProps, 'name'> &
  Omit<TooltipProps, 'children'> & {
    /**
     * Icon color.
     */
    iconColor?: 'default' | 'neutral'
    /**
     * Custom class name.
     */
    className?: string
  }

export const IconTooltip: React.FC<IconTooltipProps> = ({
  name,
  iconColor,
  className,
  ...props
}) => {
  return (
    <div className="flex items-center">
      <Tooltip place="right" size="sm" width="hug" {...props}>
        <div
          className={cn(
            'ml-1.5 flex cursor-pointer flex-col justify-center',
            className,
          )}
        >
          <Icon
            className={cn({
              'text-icon-neutral': iconColor === 'neutral',
            })}
            name={name}
            size={20}
          />
        </div>
      </Tooltip>
    </div>
  )
}

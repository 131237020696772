import { useCallback, useEffect, useState } from 'react'

import { FixedBanner, TextLink } from '@circlefin/components'
import { useReCaptcha } from '@circlefin/recaptcha/react'
import { homeSection } from '@services/sections/lib/home'
import { SegmentEvents, useSegment } from '@services/segment'
import { LoadingSpinner, TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary, PageError } from '@shared/components/errors'
import {
  useLongPollEmailVerifiedNotificationLazyQuery,
  useResendUserActivationMutation,
  useUpdateAndReturnEmailVerifiedMutation,
} from '@shared/graphql'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslation('common')
  const { data: session, update: updateSession } = useSession()
  const router = useRouter()
  const [succeeded, setSucceeded] = useState(false)
  const { track } = useSegment()
  const { withReCaptcha, createContext } = useReCaptcha({
    action: 'resend_verify_email',
  })

  const [startLongPollEmailVerified, query] =
    useLongPollEmailVerifiedNotificationLazyQuery({
      onCompleted: (data) => {
        // If this query completes successfully with a emailVerified=true,
        // refresh session data which will trigger page redirect.
        // For other successful responses, refetch query to poll again.
        if (
          data.longPollEmailVerifiedNotification?.payload.emailVerified === true
        ) {
          void updateSession()
        } else {
          void query.refetch()
        }
      },
    })

  // Long polling endpoint will only trigger a session update if the email is verified when the browser is open
  // If the browser is closed when the email is verified, we'll miss the notification and this page will stuck in the unverified email state
  // So we'll need to manually check and update the email verified status only once on each page load, in case the email got verified when the browser is closed or the notification is missed
  // And only start long polling if the response is not a verified email
  const [
    updateAndReturnEmailVerified,
    { called: updateAndReturnEmailVerifiedCalled },
  ] = useUpdateAndReturnEmailVerifiedMutation({
    onCompleted: (data) => {
      if (data.updateAndReturnEmailVerified === true) {
        void updateSession()
      } else {
        void startLongPollEmailVerified()
      }
    },
  })

  useEffect(() => {
    if (
      session?.user.emailVerified === false &&
      !updateAndReturnEmailVerifiedCalled
    ) {
      void updateAndReturnEmailVerified()
    }
  }, [
    session?.user.emailVerified,
    updateAndReturnEmailVerified,
    updateAndReturnEmailVerifiedCalled,
  ])

  const [resendActivationToken, { error, loading, reset }] =
    useResendUserActivationMutation()

  const handleResend = useCallback(async () => {
    track(SegmentEvents.ButtonClicked, {
      props: {
        button_label: 'Resend email activation link',
      },
    })

    if (session?.user.email) {
      await withReCaptcha({
        onCompleted: async (reCaptchaToken) => {
          try {
            await resendActivationToken({
              variables: { email: session.user.email },
              context: createContext(reCaptchaToken),
            })

            setSucceeded(true)
          } catch {
            // Error handled in GraphQLErrorBoundary
          }
        },
      })
    }
  }, [
    createContext,
    resendActivationToken,
    session?.user.email,
    track,
    withReCaptcha,
  ])

  const handleClick = useCallback(() => {
    void handleResend()
  }, [handleResend])

  // Display loading state while session is null
  if (!session) {
    return (
      <div className="flex w-full justify-center pt-32">
        <LoadingSpinner />
      </div>
    )
  }

  // Redirect to home page if email is already verified;
  // this can kick in if tab is open when email is verified
  if (session.user.emailVerified) {
    void router.push(homeSection.route)
  }

  // Display page error if no session email
  if (!session.user.email) {
    return <PageError variant="refresh" />
  }

  return (
    <div className="max-w-2xl text-center">
      <h2 className="type-h-page-sm">{t`verifyEmail.title`}</h2>

      <p className="mt-8 text-neutral type-body-base" data-testid="subtitle">
        <Trans
          components={{
            b: <b />,
          }}
          i18nKey="common:verifyEmail.subtitle"
          values={{ email: session.user.email }}
        />
      </p>

      <GraphQLErrorBoundary error={error} retry={reset}>
        <FixedBanner
          className="mt-6"
          data-testid="verify-email-banner"
          status="success"
          visible={succeeded}
        >
          <FixedBanner.Description>
            <span className="text-left">
              <TransWithLink
                i18nKey="common:verifyEmail.success"
                variant="support"
              />
            </span>
          </FixedBanner.Description>
        </FixedBanner>

        {!succeeded && (
          <>
            <p className="mt-6 text-neutral type-body-base">
              {t`verifyEmail.resend.description`}
            </p>

            <TextLink
              disabled={loading}
              onClick={handleClick}
              variant="primary"
            >
              {t`verifyEmail.resend.cta`}
            </TextLink>
          </>
        )}
      </GraphQLErrorBoundary>
    </div>
  )
}

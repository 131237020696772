import { createContext } from 'react'

import type { VaultMetadata } from '@shared/graphql'

export interface VaultPermissionContextProps {
  /**
   * Summary of the current vault.
   */
  vaultMetadata?: Omit<
    /**
     * Omit typename so that we are able to pass a Vault object as well.
     */
    VaultMetadata,
    '__typename'
  >
}

export const VaultPermissionContext =
  createContext<VaultPermissionContextProps | null>(null)

import { useCallback } from 'react'

import { Button, Icon, DescriptionList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal'
import useTranslation from 'next-translate/useTranslation'

const { Label, Description } = DescriptionList

export const AuthenticatorSettingsPairDevice: React.FC = () => {
  const { t } = useTranslation('settings')

  const { router } = useModal()

  const openPairNewDevice = useCallback(() => {
    void router.push(routes.vault.pairNewDevice.confirmation)
  }, [router])

  return (
    <>
      <Label className="w-64">{t`walletSecurity.authenticatorSettings.pairing.label`}</Label>
      <Description className="flex items-center">
        {t`walletSecurity.authenticatorSettings.pairing.description`}
        <Button onClick={openPairNewDevice} variant="text">
          <Icon name="ChevronRightSolid" />
        </Button>
      </Description>
    </>
  )
}

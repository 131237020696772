import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface LinkAccountFailedProps {
  /**
   * Custom style.
   */
  className?: string
}

export const LinkAccountFailed: React.FC<LinkAccountFailedProps> = ({
  className,
}) => {
  const { t } = useTranslation('custody/common')

  return (
    <FixedBanner className={className} visible>
      <FixedBanner.Title>
        {t('firstTimeUserExperience.linkBankFailed.title')}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t('firstTimeUserExperience.linkBankFailed.description')}</p>
        <ModalLink pathname={routes.bankAccount.link.location}>
          <TextLink size="sm" variant="primary">
            {t('firstTimeUserExperience.linkBankFailed.action')}
          </TextLink>
        </ModalLink>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

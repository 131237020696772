import classNames from 'classnames'

export interface StepperDescProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Description content.
   */
  children: React.ReactNode
}

export const Desc: React.FC<StepperDescProps> = ({ className, children }) => {
  return (
    <div
      className={classNames(
        'flex flex-col gap-3 pt-1 text-black-400 text-lg text-center',
        className,
      )}
      data-testid="desc"
    >
      {children}
    </div>
  )
}

Desc.displayName = 'Stepper.Desc'

import { useEffect, useState } from 'react'

import { Card, Icon, SkeletonBox, Tooltip } from '@circlefin/components'
import { currencyToToken, tokenToCurrency } from '@shared/components/common'
import { useFxQuoteLazyQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useTransferWithFx } from '../../../hooks/fx'

export const ExchangeRateCard: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const { quote } = useTransferWithFx()
  const [displayRate, setDisplayRate] = useState<string>()
  const [fetchRate, { loading }] = useFxQuoteLazyQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  })

  // We need to know if the currencies are equivalent (IE USDC -> USD) to know what strings to show
  const isSameCurrency =
    quote?.from.currency != null &&
    quote?.to.currency != null &&
    (currencyToToken(quote.from.currency) === quote.to.currency ||
      tokenToCurrency(quote.from.currency) === quote.to.currency)

  useEffect(() => {
    // If currencies are the same USDC:USD or EURC:EUR, display rate will always be 1
    if (isSameCurrency) {
      setDisplayRate('1.0000')
      return
    }

    if (quote?.from.currency == null || quote?.to.currency == null) {
      setDisplayRate(t`fx.exchangeRateCard.tbd`)
      return
    }

    const input = {
      from: { currency: quote.from.currency, amount: '1' },
      to: { currency: quote.to.currency },
    }

    void fetchRate({
      variables: {
        input,
      },
      onCompleted: (data) => {
        setDisplayRate((1 / (data.fxQuote.rate ?? 1)).toFixed(4))
      },
      onError: () => {
        setDisplayRate(t`fx.exchangeRateCard.tbd`)
      },
    })
  }, [fetchRate, t, isSameCurrency, quote?.from.currency, quote?.to.currency])

  return (
    <Card className="h-fit" variant="primary/sm">
      <Card.Content className="flex flex-col gap-5">
        <span className="flex items-center gap-1 type-body-sm-bold">
          {isSameCurrency ? (
            t`fx.exchangeRateCard.title.sameCurrency`
          ) : (
            <>
              {t`fx.exchangeRateCard.title.differentCurrency`}
              <Tooltip
                className="z-[70]"
                content={<span>{t`fx.exchangeRateCard.tooltip`}</span>}
                place="top"
                size="sm"
              >
                <span>
                  <Icon name="InformationCircleOutline" />
                </span>
              </Tooltip>
            </>
          )}
        </span>
        <SkeletonBox className="h-7 w-80" loading={loading}>
          <span className="text-lg text-neutral font-circular-bold">
            {quote &&
              t('fx.exchangeRateCard.marketExchangeRateDetail', {
                fromMoney: `1.00 ${quote?.from.currency}`,
                toMoney: `${displayRate} ${quote?.to.currency}`,
              })}
          </span>
        </SkeletonBox>
      </Card.Content>
    </Card>
  )
}

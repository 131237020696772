import { useCallback, useEffect } from 'react'

import { Icon, TextLink } from '@circlefin/components'
import { FeatureSwitch } from '@services/feature-switch'
import { awaitingReview } from '@services/sections/lib/accountSetup'
import { useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  OnboardingActiveUsers,
  useUpdateOnboardingDataMutation,
} from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../hooks/account-setup'
import { ProgressShell } from '../ProgressShell/ProgressShell'

import { ConfirmForm } from './Confirm.Form'

import type { ConfirmFormValues } from './Confirm.Form'

export const Confirm: React.FC = () => {
  const { t } = useTranslation('onboard/account-setup')
  const { trackLinkClick } = useSegment()
  const [
    {
      accountSetupStates: { isOnboardingInitiator },
    },
    { completeStep, setBusinessDetails },
  ] = useAccountSetup()
  const [updateOnboardingData, { error, reset }] =
    useUpdateOnboardingDataMutation()
  const router = useRouter()

  useEffect(() => {
    if (FeatureSwitch.inviteOnlyPhase2Enabled()) {
      return
    }

    // Prefetch next possible steps
    void router.prefetch(awaitingReview.route)
  }, [router])

  const handleTrackLinkClick = useCallback(
    (name: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      trackLinkClick(e, {
        props: {
          label: name,
        },
      })
    },
    [trackLinkClick],
  )

  const handleSubmit = useCallback(
    async (values: ConfirmFormValues) => {
      setBusinessDetails(values)

      const activeUsersOnPlatform =
        values.activeUsers === String(OnboardingActiveUsers.notApplicable)
          ? undefined
          : values.activeUsers

      try {
        // Execute mutation
        await updateOnboardingData({
          variables: {
            input: {
              onboardingCompanyInfo: {
                businessCountry: values.businessCountry,
                businessEntityName: values.businessName,
                entityType: values.entityType,
                businessState: values.businessState,
                natureOfBusiness: values.businessNature,
                expectedUsdcMintingVolume: values.mintingVolume,
                ...(activeUsersOnPlatform ? { activeUsersOnPlatform } : {}),
              },
              businessInfoConfirmed: isOnboardingInitiator ? false : true,
            },
          },
        })

        // If no errors occur, step is completed
        await completeStep('CONFIRM_DETAILS')
      } catch {
        // Error handled in GraphQLErrorBoundary
      }
    },
    [
      completeStep,
      setBusinessDetails,
      isOnboardingInitiator,
      updateOnboardingData,
    ],
  )

  return (
    <ProgressShell currentStep="CONFIRM_DETAILS">
      <h2 className="type-h-page-sm">{t`confirm.title`}</h2>

      <GraphQLErrorBoundary error={error} retry={reset}>
        <ConfirmForm onSubmit={handleSubmit} />
      </GraphQLErrorBoundary>

      <div className="mt-6 flex flex-col items-start gap-y-4">
        {['businesses', 'countries'].map((link, index) => (
          <TextLink.Link
            key={index}
            href={t(`confirm.form.links.${link}.link`)}
            onClick={handleTrackLinkClick(link)}
            rel="noopener noreferrer"
            size="sm"
            target="_blank"
            variant="primary"
          >
            {t(`confirm.form.links.${link}.text`)}
            <Icon className="shrink-0" name="ExternalLinkOutline" />
          </TextLink.Link>
        ))}
      </div>
    </ProgressShell>
  )
}

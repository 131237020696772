import { TabsNav as CTabsNav } from '@circlefin/components'

import { TabsNavItem } from './TabsNav.Item'

import type { Section } from '@services/sections/types'
import type { ParsedUrlQueryInput } from 'querystring'

export interface TabsNavProps {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Sections.
   */
  sections: Section[]
  /**
   * Optional query that will be passed to the link component to replace
   * route parameters if a dynamic route is used.
   */
  query?: ParsedUrlQueryInput
  /**
   * Flag to specify whether we want to just have an exact match the given section's URL
   * and exclude matching by sub page routes.
   */
  exactMatch?: boolean
}

export const TabsNav: React.FC<TabsNavProps> = ({
  className,
  sections,
  query,
  exactMatch = false,
}) => {
  return (
    <CTabsNav className={className} data-testid="tabs-nav">
      {sections.map((section, i) => (
        <TabsNavItem
          key={i}
          {...section}
          exactMatch={exactMatch}
          query={query}
        />
      ))}
    </CTabsNav>
  )
}

import { Modal } from '@circlefin/components'

export interface CarouselLayoutProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Carousel Title.
   */
  title: string
}

export const Carousel: React.FC<CarouselLayoutProps> = ({
  title,
  children,
}) => {
  return (
    <>
      <Modal.Header title={title} />
      <Modal.Body className="min-h-119 px-14 font-circular">
        {children}
      </Modal.Body>
    </>
  )
}

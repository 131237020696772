import { FixedBanner, TextLink } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface TravelRuleAlertProps {
  /**
   * Visibility.
   */
  visible?: boolean
  /**
   * Custom className.
   */
  className?: string
}

export const TravelRuleAlert: React.FC<TravelRuleAlertProps> = ({
  visible = false,
  className,
}) => {
  const { t } = useTranslation('payments')

  return (
    <FixedBanner
      className={className}
      status="warning"
      visible={visible}
      multiline
    >
      <FixedBanner.Title>{t('travelRuleAlert.title')}</FixedBanner.Title>
      <FixedBanner.Description>
        {t('travelRuleAlert.description')}
      </FixedBanner.Description>
      <FixedBanner.Footer>
        <TextLink.Link
          href={t`travelRuleAlert.link`}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >{t`travelRuleAlert.learnMore`}</TextLink.Link>
      </FixedBanner.Footer>
    </FixedBanner>
  )
}

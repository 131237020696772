import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Name.
   */
  bankName: y.string().max(35, { key: 'max', max: 35 }).required(),
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Identifier Code / Swift.
   */
  routingNumber: y.string().bic().required(),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().accountNumber({ variant: 'other' }).required(),
})

export type NonIbanDefaultFormValues = y.InferType<typeof schema>

export interface DefaultFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: NonIbanDefaultFormValues) => void
}

export const DefaultForm: React.FC<DefaultFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<NonIbanDefaultFormValues>({
    schema,
    defaultValues: {
      ...information.nonIban,
    },
  })

  const onFormSubmit = useCallback(
    (nonIban: NonIbanDefaultFormValues) => {
      // Set data to context
      setInformation({ nonIban })

      // Pass data to submit callback (for testing)
      onSubmit?.(nonIban)
    },
    [onSubmit, setInformation],
  )

  return (
    <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
      {/* Bank Account Name */}
      <Form.Input
        className="w-full"
        data-testid="input-bankName"
        label={t('link.information.bankName')}
        name="bankName"
      />

      {/* Bank Account Nickname */}
      <Form.Input
        className="w-full"
        data-testid="input-nickname"
        label={t('link.information.nickname')}
        name="nickname"
      />

      {/* Bank Identifier Code / Swift */}
      <Form.Input
        className="w-full"
        data-testid="input-routingNumber"
        label={t('link.information.bic')}
        name="routingNumber"
      />

      {/* Bank Account Number */}
      <Form.Input
        className="w-full"
        data-testid="input-accountNumber"
        label={t('link.information.accountNumber')}
        name="accountNumber"
      />

      {/* Continue */}
      <Center className="pt-6" variant="horizontal">
        <Form.SubmitButton
          className="mt-6 w-64"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { useCallback, useMemo } from 'react'

import {
  Card,
  ButtonCard,
  SkeletonBox,
  Heading,
  DescriptionList,
} from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import {
  PropsErrorBoundary,
  GraphQLErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  usePendingOnchainReceivesQuery,
  useSetBlockchainRecipientAddressWithMetadataMutation,
  BlockchainRecipientAddressOwner,
  useEntityQuery,
  useOwnerTypeQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface OwnerTypeProps {
  /**
   * Transaction Id.
   */
  transactionId?: string
  /**
   * Source Address Id.
   */
  addressId?: string
}

const { Label, Description } = DescriptionList

export const OwnerType: React.FC<OwnerTypeProps> = ({
  transactionId,
  addressId,
}) => {
  const { t } = useTranslation('address-book/modals')
  const modal = useModal()
  const { money } = useMoney({ locale: 'en-US' })
  const { date } = useDate()

  const ownerType = useOwnerTypeQuery({
    variables: {
      transactionId: transactionId ?? '',
      addressId: addressId ?? '',
    },
    skip: !addressId || !transactionId,
  })

  const entity = useEntityQuery()

  const payment = ownerType.data?.onchainReceive.payment
  const sourceAddress = ownerType.data?.blockchainRecipientAddressWithMetadata
  const loading = ownerType.loading

  const [updateAddress, { error, reset, loading: updating }] =
    useSetBlockchainRecipientAddressWithMetadataMutation()

  const { refetch: refetchPendingOnchainReceives } =
    usePendingOnchainReceivesQuery()

  const handleClickThirdParty = useCallback(() => {
    if (!sourceAddress) return

    void updateAddress({
      variables: {
        input: {
          id: sourceAddress.id,
          owner: BlockchainRecipientAddressOwner.non_customer_entity,
        },
      },
      onCompleted: () => {
        // Refetch the pending onchain receives query, third party address does not need verification
        void refetchPendingOnchainReceives()
        modal.router.push({
          pathname: routes.account.addressBook.verifyAddress.thirdPartyWarning,
        })
      },
    })
  }, [
    sourceAddress,
    updateAddress,
    refetchPendingOnchainReceives,
    modal.router,
  ])

  const handleClickFirstParty = useCallback(() => {
    if (!sourceAddress) return

    void updateAddress({
      variables: {
        input: {
          id: sourceAddress.id,
          owner: BlockchainRecipientAddressOwner.customer,
        },
      },
      onCompleted: () =>
        modal.router.push({
          pathname: routes.account.addressBook.verifyAddress.identityChallenge,
          query: {
            addressId: sourceAddress.id,
          },
        }),
    })
  }, [modal, updateAddress, sourceAddress])

  const disableButton = useMemo(
    () => loading || updating || !payment || !sourceAddress,
    [loading, payment, updating, sourceAddress],
  )

  return (
    <FullScreen totalSteps={2}>
      <PropsErrorBoundary<OwnerTypeProps>
        props={{ transactionId, addressId }}
        variant="page"
      >
        {() => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <Center
              className="justify-items-center gap-3 text-center"
              variant="horizontal"
            >
              <h2 className="type-h-page-sm">{t`verifyAddress.ownerType.title`}</h2>
              <p className="w-152 type-intro-sm">{t`verifyAddress.ownerType.instruction1`}</p>
              <p className="w-152 type-intro-sm">
                {t(`verifyAddress.ownerType.instruction2`)}
              </p>
            </Center>
            <Center className="mt-8" variant="horizontal">
              <Card className="w-144">
                <Card.Content>
                  <DescriptionList className="grid-cols-4" density="loose">
                    {/* From Wallet */}
                    <Label className="col-span-1 type-label-sm">
                      {t`verifyAddress.ownerType.fromWallet`}
                    </Label>
                    <Description className="col-span-3 type-body-base">
                      <SkeletonBox className="h-3 w-56" loading={loading}>
                        {sourceAddress?.address}
                      </SkeletonBox>
                    </Description>

                    {/* Blockchain */}
                    <Label className="col-span-1 type-label-sm">
                      {t`verifyAddress.ownerType.blockchain`}
                    </Label>
                    <Description className="col-span-3 type-body-base">
                      <SkeletonBox className="h-3 w-56" loading={loading}>
                        {sourceAddress?.chain != null
                          ? t(`common:chain.${sourceAddress.chain}`)
                          : ''}
                      </SkeletonBox>
                    </Description>

                    {/* Amount */}
                    <Label className="col-span-1 type-label-sm">
                      {t`verifyAddress.ownerType.amount`}
                    </Label>
                    <Description className="col-span-3 type-body-base">
                      <SkeletonBox className="h-3 w-56" loading={loading}>
                        {payment?.amount
                          ? money({
                              number: Number(payment.amount.amount),
                              variant: payment.amount.currency,
                            })
                          : ''}
                      </SkeletonBox>
                    </Description>

                    {/* Date */}
                    <Label className="col-span-1 type-label-sm">
                      {t`verifyAddress.ownerType.date`}
                    </Label>
                    <Description className="col-span-3 type-body-base">
                      <SkeletonBox className="h-3 w-56" loading={loading}>
                        {payment?.createDate
                          ? date(payment.createDate, 'monthDayYear')
                          : ''}
                      </SkeletonBox>
                    </Description>
                  </DescriptionList>
                </Card.Content>
              </Card>
            </Center>
            <Center className="mt-8 gap-4" variant="horizontal">
              <SkeletonBox className="h-22 w-116" loading={loading}>
                <ButtonCard
                  className="w-116"
                  disabled={disableButton}
                  onClick={handleClickFirstParty}
                >
                  <Heading iconName="OfficeBuildingSolid">
                    <Heading.Header>
                      {entity.data?.entity?.institutionName
                        ? t('verifyAddress.ownerType.cta.firstParty', {
                            company: entity.data?.entity?.institutionName,
                          })
                        : t('verifyAddress.ownerType.cta.firstPartyBackup')}
                    </Heading.Header>
                  </Heading>
                </ButtonCard>
              </SkeletonBox>
              <ButtonCard
                className="w-116"
                disabled={disableButton}
                onClick={handleClickThirdParty}
              >
                <Heading iconName="UserSolid">
                  <Heading.Header>
                    {t`verifyAddress.ownerType.cta.thirdParty`}
                  </Heading.Header>
                </Heading>
              </ButtonCard>
            </Center>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

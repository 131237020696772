import { useCallback, useMemo } from 'react'

import { Menu } from '@circlefin/components'
import { WalletApprovalPolicyRole } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { UserPermissionRoleText } from '../../../components'

// Enum mapping only for the translation keys
enum Options {
  INITIATE_APPROVE = 'initiateApprove',
  INITIATE = 'initiate',
  APPROVE = 'approve',
}

export interface WalletApprovalPolicyPermissionMenuProps {
  /**
   * Is owner of the policy being created.
   */
  isOwner: boolean
  /**
   * Selected permission.
   */
  selectedPermissions: WalletApprovalPolicyRole[]
  /**
   * OnClick handler.
   */
  onClick?: (selected: WalletApprovalPolicyRole[]) => void
  /**
   * Remove handler.
   */
  onRemove?: () => void
}

export const WalletApprovalPolicyPermissionMenu: React.FC<WalletApprovalPolicyPermissionMenuProps> =
  ({ isOwner, selectedPermissions, onClick, onRemove }) => {
    const { t } = useTranslation('walletApprovalPolicy')

    const handleClick = useCallback(
      (value: WalletApprovalPolicyRole[]) => () => {
        onClick?.(value)
      },
      [onClick],
    )

    const permissionValue = useMemo(() => {
      if (
        selectedPermissions.includes(WalletApprovalPolicyRole.APPROVER) &&
        selectedPermissions.includes(WalletApprovalPolicyRole.OPERATOR)
      ) {
        return Options.INITIATE_APPROVE
      }

      if (selectedPermissions.includes(WalletApprovalPolicyRole.APPROVER)) {
        return Options.APPROVE
      }

      return Options.INITIATE
    }, [selectedPermissions])

    return (
      <Menu>
        <Menu.Dropdown>
          <span>
            <UserPermissionRoleText permissions={selectedPermissions} bolded />
          </span>
        </Menu.Dropdown>
        <Menu.Options anchorPosition="left" className="z-30 max-w-80">
          <Menu.Item
            icon={
              Options.INITIATE_APPROVE === permissionValue
                ? 'CheckOutline'
                : undefined
            }
            label={
              <Trans
                components={{
                  b: <b />,
                }}
                i18nKey={`walletApprovalPolicy:permissions.menu.options.${Options.INITIATE_APPROVE}`}
              />
            }
            onClick={handleClick([
              WalletApprovalPolicyRole.OPERATOR,
              WalletApprovalPolicyRole.APPROVER,
            ])}
            variant="secondary"
          />
          <Menu.Item
            icon={
              Options.INITIATE === permissionValue ? 'CheckOutline' : undefined
            }
            label={
              <Trans
                components={{
                  b: <b />,
                }}
                i18nKey={`walletApprovalPolicy:permissions.menu.options.${Options.INITIATE}`}
              />
            }
            onClick={handleClick([WalletApprovalPolicyRole.OPERATOR])}
            variant="secondary"
          />
          <Menu.Item
            icon={
              Options.APPROVE === permissionValue ? 'CheckOutline' : undefined
            }
            label={
              <Trans
                components={{
                  b: <b />,
                }}
                i18nKey={`walletApprovalPolicy:permissions.menu.options.${Options.APPROVE}`}
              />
            }
            onClick={handleClick([WalletApprovalPolicyRole.APPROVER])}
            variant="secondary"
          />
          <Menu.Divider inactive={isOwner} />
          <Menu.Item
            inactive={isOwner}
            label={t('permissions.menu.options.remove')}
            onClick={onRemove}
            variant="secondary"
          />
        </Menu.Options>
      </Menu>
    )
  }

import { Button, Modal } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface PolicyConfirmProps {
  /**
   * Is Open?
   */
  open: boolean
  /**
   * On Cancel.
   */
  onCancel?: () => void
  /**
   * On Continue.
   */
  onContinue?: () => void
}

export const PolicyConfirm: React.FC<PolicyConfirmProps> = ({
  open,
  onCancel,
  onContinue,
}) => {
  const { t } = useTranslation('modals.vault')

  return (
    <Modal className="grid gap-y-6" open={open} size="sm" disableClose>
      <Modal.Title className="type-h-title-md">{t`createVault.policyConfirm.title`}</Modal.Title>
      <Modal.Body className="grid gap-y-4 text-center type-body-base">
        <p>{t`createVault.policyConfirm.subtitle`}</p>
        <p>{t`createVault.policyConfirm.description`}</p>
      </Modal.Body>
      <Modal.Footer variant="stretch">
        <Button onClick={onCancel} variant="secondary">
          {t`common:cancel`}
        </Button>
        <Button onClick={onContinue} variant="primary">
          {t`common:continue`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useUploadVaultWalletBackupMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useResetPin } from '../ResetPin.Context'

import { EnterPassphraseForm } from './Form/EnterPassphraseForm'

import type { EnterPassphraseFormValues } from './Form/EnterPassphraseForm'
import type { UploadVaultWalletBackupMutation } from '@shared/graphql'

export const EnterPassphrase: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const [{ walletBackupFile }, { setTwoFactorToken }] = useResetPin()

  const [UploadWalletBackupMutation, { error, reset }] =
    useUploadVaultWalletBackupMutation({
      onCompleted: (data: UploadVaultWalletBackupMutation) => {
        setTwoFactorToken(data.uploadVaultWalletBackup.twoFactorToken)
        modal.router.push({
          pathname: routes.vault.resetPin.pin,
          options: {
            disableBack: true,
          },
        })
      },
      // To avoid uncaught error
      onError: () => null,
    })

  const onSubmit = useCallback(
    ({ password }: EnterPassphraseFormValues) => {
      void UploadWalletBackupMutation({
        variables: {
          input: {
            walletBackupData: walletBackupFile ?? '',
            securityPassphrase: password,
          },
        },
      })
    },
    [UploadWalletBackupMutation, walletBackupFile],
  )

  return (
    <FullScreen totalSteps={5}>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="text-4xl text-black-600 font-circular-bold">{t`resetPin.title`}</h2>

          <h3 className="mb-1 mt-14 text-center text-xl leading-7 font-circular-medium">
            {t`resetPin.enterPassphrase.title`}
          </h3>

          <EnterPassphraseForm onSubmit={onSubmit} />
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { useMemo } from 'react'

import { Button } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLETS_QUERY,
  CIRCLE_WALLET_APPROVAL,
} from '@services/permissions'
import {
  useWalletApprovalIncompleteDevicePairingUsersQuery,
  useWalletApprovalPolicyQuery,
  WalletApprovalPolicyState,
  WalletApprovalProposalApprovalMethod,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { Currency } from '@shared/graphql'

export interface SubmitDraftProps extends ButtonProps {
  /**
   * Currency.
   */
  currency: Currency
}

export const SubmitDraft: React.FC<SubmitDraftProps> = ({
  currency,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  const [, { isAuthorized: isAuthorizedForQuery }] =
    usePermission(CIRCLE_WALLETS_QUERY)
  const [{ Authorized }] = usePermission(CIRCLE_WALLETS_MUTATION)
  const [, { isAuthorized: isEmailApprovalMethodEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  const walletApprovalPolicyQuery = useWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: !isAuthorizedForQuery,
  })

  const incompleteDevicePairingQuery =
    useWalletApprovalIncompleteDevicePairingUsersQuery({
      variables: {
        currency,
      },
      skip: !isAuthorizedForQuery,
    })

  const minNumOfUsersWhoMustPair =
    incompleteDevicePairingQuery.data
      ?.walletApprovalIncompleteDevicePairingUsers?.minNumOfUsersWhoMustPair ??
    0

  const isProposalApprovalMethodMobile = useMemo(
    () =>
      walletApprovalPolicyQuery.data?.walletApprovalPolicy?.settings
        .proposalApprovalMethod ===
      WalletApprovalProposalApprovalMethod.MOBILE_APP,
    [
      walletApprovalPolicyQuery.data?.walletApprovalPolicy?.settings
        .proposalApprovalMethod,
    ],
  )

  const isButtonDisabled = useMemo(() => {
    if (isEmailApprovalMethodEnabled) {
      // if the approval method is mobile, there must be enough admin who pairs up their devices to approve the change
      return (
        (isProposalApprovalMethodMobile &&
          (minNumOfUsersWhoMustPair > 0 ||
            incompleteDevicePairingQuery.loading)) ||
        disabled
      )
    }

    return (
      minNumOfUsersWhoMustPair > 0 ||
      incompleteDevicePairingQuery.loading ||
      disabled
    )
  }, [
    disabled,
    incompleteDevicePairingQuery.loading,
    isEmailApprovalMethodEnabled,
    isProposalApprovalMethodMobile,
    minNumOfUsersWhoMustPair,
  ])

  if (
    walletApprovalPolicyQuery.data?.walletApprovalPolicy?.state !==
    WalletApprovalPolicyState.DRAFT
  ) {
    return null
  }

  return (
    <Authorized>
      <Button
        disabled={isButtonDisabled}
        loading={
          isEmailApprovalMethodEnabled &&
          isProposalApprovalMethodMobile &&
          incompleteDevicePairingQuery.loading
        }
        size="sm"
        {...rest}
      >
        {t`buttons.submitForApproval`}
      </Button>
    </Authorized>
  )
}

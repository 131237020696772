import { useCallback, useState } from 'react'

import { Button, Checkbox, FeatureList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useAcceptWalletConnectTermsMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const Confirmation: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const [agreement, setAgreement] = useState(false)

  const [acceptWalletConnectTerms, { loading, error, reset }] =
    useAcceptWalletConnectTermsMutation({
      onCompleted: () => {
        modal.router.push({
          pathname: routes.vault.createVault.initialize,
          query: {
            isWalletConnectStepVisible: true,
          },
        })
      },
      onError: () => null,
    })

  const onContinue = useCallback(() => {
    void acceptWalletConnectTerms()
  }, [acceptWalletConnectTerms])

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreement(event.target.checked)
    },
    [setAgreement],
  )

  return (
    <FullScreen>
      <GraphQLErrorBoundary error={error} retry={reset}>
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="w-100 text-center text-4xl text-black-600 font-circular-bold">
            {t(`walletConnect.title`)}
          </h2>
          <h3 className="mt-3 w-160 px-4 text-base text-black-600">
            {t(`walletConnect.confirm.message`)}
          </h3>

          <FeatureList className="mt-16 w-120 px-5">
            <FeatureList.Item
              heading={t`walletConnect.confirm.prompt1.heading`}
              icon="ExclamationOutline"
            >
              {t`walletConnect.confirm.prompt1.message`}
            </FeatureList.Item>
            <FeatureList.Item
              heading={t`walletConnect.confirm.prompt2.heading`}
              icon="ShieldExclamationOutline"
            >
              {t`walletConnect.confirm.prompt2.message`}
            </FeatureList.Item>
          </FeatureList>

          <Checkbox
            checked={agreement}
            className="mt-6 text-lg leading-6 text-black-400 font-circular-regular"
            label={t`walletConnect.confirm.checkbox`}
            onChange={onCheckboxChange}
          />

          <Center className="pt-4" variant="horizontal">
            <Button
              className="w-40"
              disabled={!agreement}
              loading={loading}
              onClick={onContinue}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </Center>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { FixedBanner } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { Asset, AssetBlockchain } from '@shared/graphql'

export interface TokenOptInAlertProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Selected asset.
   */
  asset: Asset
  /**
   * Selected blockchain.
   */
  blockchain: AssetBlockchain
}

export const TokenOptInAlert: React.FC<TokenOptInAlertProps> = ({
  className,
  asset,
  blockchain,
}) => {
  const { t } = useTranslation('vaults')

  const blockchainName = t(`payments:chainNames.${blockchain.chain}`)

  return (
    <FixedBanner
      className={className}
      status="info"
      visible={blockchain.requiresActivation}
    >
      <FixedBanner.Description>
        {t('vaultWallets.tokenOptInAlert', {
          asset: asset?.symbol,
          blockchainName,
          native: blockchain?.chain,
        })}
      </FixedBanner.Description>
    </FixedBanner>
  )
}

export interface HeadingProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Heading: React.FC<HeadingProps> = ({ children }) => (
  <h4 className="mt-10 pb-2 text-xl leading-8 text-black-300 font-circular-medium">
    {children}
  </h4>
)

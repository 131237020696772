import { useCallback, useEffect, useMemo, useState } from 'react'

import { FixedBanner, Radio, SkeletonBox } from '@circlefin/components'
import { useForm } from '@circlefin/form'
import { useDebounce, useMoney } from '@circlefin/hooks'
import { BankAccounts } from '@features/bank-account/forms/Combobox'
import { isWire } from '@services/type-guards/BankAccount'
import { Center } from '@shared/components/layout'
import { Currency, useWireBankAccountLazyQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { FxAgreement } from '../../../components/FxAgreement/FxAgreement'
import { useTransferWithFx } from '../../../hooks/fx'
import {
  getCurrenciesAndDefault,
  getDefaultRail,
  getEligibleRails,
  fiatCurrencyOptions,
  isSameCurrency,
  transferLimitValidation,
} from '../../../hooks/fx/helpers'
import { schema } from '../../../hooks/fx/provider'

import type { FxFormValues } from '../../../hooks/fx/provider'
import type { IconProps } from '@circlefin/components/lib/Icon'
import type { BankAccount, RailType } from '@shared/graphql'

export interface FxTransferFormProps {
  /**
   * The flow we are on, either deposit or withdrawal.
   */
  mode: 'deposit' | 'withdrawal'
  /**
   * The currency that the user would like to receive or redeem. Fixed based on prior selection.
   */
  fixedCurrency: Currency
  /**
   * On Form Submit.
   */
  onSubmit: () => void
}

const CURRENCY_ICONS_MAP: Map<Currency, IconProps['name']> = new Map([
  [Currency.SGD, 'SGP'],
  [Currency.USDC, 'USDC'],
  [Currency.USD, 'USA'],
  [Currency.BRL, 'BR'],
  [Currency.MXN, 'MXN'],
  [Currency.EUR, 'EU'],
  [Currency.EURC, 'EURC'],
])

export const FxTransferForm: React.FC<FxTransferFormProps> = ({
  onSubmit,
  fixedCurrency,
  mode,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { money } = useMoney()
  const [fiatCurrencies, setFiatCurrencies] =
    useState<Currency[]>(fiatCurrencyOptions)
  const [getWireBankAccount, { loading: wireBankLoading }] =
    useWireBankAccountLazyQuery()
  const {
    refetch,
    quote,
    loading,
    formValues,
    setFormValues,
    initForm,
    balance,
    quoteError,
  } = useTransferWithFx()
  const isDepositMode = mode === 'deposit'

  useEffect(() => {
    if (!formValues) initForm(fixedCurrency)
  }, [fixedCurrency, formValues, initForm])

  const fixedCurrencyDropdownItems = useMemo(
    () => [
      {
        label: fixedCurrency,
        value: fixedCurrency,
        icon: CURRENCY_ICONS_MAP.get(fixedCurrency),
      },
    ],
    [fixedCurrency],
  )

  // Tracks whether the selected currencies require FX action (IE its false when USD -> USDC or EUR -> EURC)
  const isFxQuote = !isSameCurrency(
    formValues?.fromCurrency ?? Currency.USD,
    formValues?.toCurrency ?? Currency.USD,
  )

  const [Form, { watch }] = useForm<FxFormValues>({
    schema: isDepositMode
      ? schema.shape({
          fromAmount: transferLimitValidation,
        })
      : schema.shape({
          toAmount: transferLimitValidation,
        }),
    mode: 'onChange',
    values: formValues,
  })
  const [account, fromCurrency, toCurrency, fromAmount, rail] = watch([
    'account',
    'fromCurrency',
    'toCurrency',
    'fromAmount',
    'rail',
  ])

  const withdrawalOverBalance = useMemo(
    () =>
      balance
        ? parseFloat(fromAmount) > parseFloat(balance) && !isDepositMode
        : false,
    [balance, fromAmount, isDepositMode],
  )

  const newFiatCurrencyDropdownItems = useMemo(
    () =>
      fiatCurrencies?.map((currency) => ({
        label: currency,
        value: currency,
        icon: CURRENCY_ICONS_MAP.get(currency),
      })),
    [fiatCurrencies],
  )

  const debouncedFetchQuote = useDebounce({
    callback: ({
      fromAmount,
      fromCurrency,
      toAmount,
      toCurrency,
    }: {
      fromAmount: string
      toAmount: string
      fromCurrency: Currency
      toCurrency: Currency
    }) => {
      const newFetchInputs =
        fromAmount !== quote?.from.amount ||
        fromCurrency !== quote?.from.currency
          ? // If the send amount or currency changes, we omit the receive amount
            {
              from: { amount: fromAmount, currency: fromCurrency },
              to: { currency: toCurrency },
            }
          : toAmount !== quote?.to.amount || toCurrency !== quote?.to.currency
          ? // If the receive amount changes, we omit the send amount
            {
              from: { currency: fromCurrency },
              to: { amount: toAmount, currency: toCurrency },
            }
          : undefined

      if (!newFetchInputs) return

      refetch(newFetchInputs)
    },
    delay: 1500,
  })

  // Listen for changes to the amount inputs and fire off a new fetch quote
  useEffect(() => {
    const { unsubscribe } = watch(
      ({ fromAmount, fromCurrency, toAmount, toCurrency }) => {
        debouncedFetchQuote({ fromAmount, fromCurrency, toAmount, toCurrency })
      },
    )
    return () => unsubscribe()
  }, [debouncedFetchQuote, watch])

  // Get the set of rails that are available on the account that has been selected.
  const rails = useMemo(() => {
    if (!account) return

    if (isWire(account) && account.transferTypes) {
      return getEligibleRails({ bankAccount: account })
    } else {
      return [account?.type]
    }
  }, [account])

  // Manually handle rail changes, since they are not a subcomponent of Form
  const handleRailChange = useCallback(
    (rail: string | number) => {
      // Get new eligible fiat currencies and default currency based on default rail
      const newFiatValues = getCurrenciesAndDefault({
        newRail: rail.toString() as RailType,
        bankAccount: account!,
      })
      // Set state value for eligible fiat currencies
      setFiatCurrencies(newFiatValues.options)
      // Update form values for all these new defaults
      setFormValues({
        rail: rail.toString(),
        toCurrency: newFiatValues.default,
      })
    },
    [account, setFormValues],
  )

  // Manually handle account changes, since they are not a subcomponent of Form
  const handleAccountChange = useCallback(
    ({ value: account }: { value?: BankAccount }) => {
      if (!account) return

      if (account == null || !isWire(account)) {
        // If the account selected is anything but wire we follow this simple flow.
        setFormValues({
          account: account,
          rail: getDefaultRail({ account, isDepositMode }),
        })
        return
      }

      // The wire bank account ID endpoint has more details about rails than the list endpoint
      // So we have to fetch from there for wire accounts.
      void getWireBankAccount({
        variables: { accountId: account?.id },
        onCompleted(data) {
          // Get new default rail
          const newDefaultRail = getDefaultRail({
            account: data.wireBankAccount,
            isDepositMode,
          }) as RailType

          // Get new eligible fiat currencies and default currency based on default rail
          const newFiatValues = getCurrenciesAndDefault({
            newRail: newDefaultRail,
            bankAccount: data.wireBankAccount,
          })

          // Set state value for eligible fiat currencies
          setFiatCurrencies(newFiatValues.options)

          // Update form values for all these new defaults
          setFormValues({
            account: data.wireBankAccount,
            rail: newDefaultRail,
            ...(!isDepositMode
              ? { toCurrency: newFiatValues.default }
              : { fromCurrency: newFiatValues.default }),
          })
        },
      })
    },
    [getWireBankAccount, isDepositMode, setFormValues],
  )

  const handleFormSubmit = useCallback(
    (finalFormValues: FxFormValues) => {
      if (!quote) return
      setFormValues(finalFormValues)
      onSubmit()
    },
    [onSubmit, quote, setFormValues],
  )

  return (
    <Form onSubmit={handleFormSubmit}>
      <BankAccounts
        className="mb-6"
        label={t`fx.selectAccount`}
        name="account"
        onChange={handleAccountChange}
      />
      {/* rail selection is only needed by transfer flow */}
      {!isDepositMode && !wireBankLoading && rails !== undefined && (
        <Radio.Group
          className="mb-6"
          direction="vertical"
          label={t`fx.availableTransferMethods`}
          name="rail"
          onChange={handleRailChange}
          value={rail}
        >
          {rails
            .filter((r) => r != null)
            .map((rail) => (
              <Radio key={rail} value={rail}>
                <span className="flex justify-between">
                  <span>{t(`fx.rails.${rail}.label`)}</span>
                  <span>{t(`fx.rails.${rail}.info`)}</span>
                </span>
              </Radio>
            ))}
        </Radio.Group>
      )}

      <div className="flex flex-row gap-2">
        <SkeletonBox className="mt-6 h-10 w-full" loading={loading}>
          <Form.MoneyInput
            className="w-full"
            currencyVariant={fromCurrency}
            data-testid="send-amount"
            label={
              isDepositMode
                ? t`fx.send`
                : t`fx.withdraw.reviewDetails.withdrawAmountInput`
            }
            message={
              isDepositMode
                ? t`fx.chooseCorrespondingCurrency`
                : balance &&
                  t('fx.availableBalance', {
                    balance: money({
                      number: balance ?? '',
                      variant: fixedCurrency,
                    }),
                  })
            }
            name="fromAmount"
            placeholder="0.00"
            disableCurrencyVariant
          />
        </SkeletonBox>

        <SkeletonBox className="mt-6 h-10 w-40" loading={loading}>
          <Form.Dropdown
            className="mt-6 w-40"
            data-testid="send-currency"
            disabled={mode === 'withdrawal'}
            items={
              isDepositMode
                ? newFiatCurrencyDropdownItems
                : fixedCurrencyDropdownItems
            }
            name="fromCurrency"
          />
        </SkeletonBox>
      </div>
      <div className="mt-6 flex flex-row gap-2">
        <SkeletonBox className="mt-6 h-10 w-full" loading={loading}>
          <Form.MoneyInput
            className="w-full"
            currencyVariant={toCurrency}
            data-testid="receive-amount"
            label={t`fx.receive`}
            message={!isDepositMode && t`fx.chooseCorrespondingCurrency`}
            name="toAmount"
            placeholder="0.00"
            disableCurrencyVariant
          />
        </SkeletonBox>
        <SkeletonBox className="mt-6 h-10 w-36" loading={loading}>
          <Form.Dropdown
            className="mt-6 w-40"
            disabled={isDepositMode}
            items={
              isDepositMode
                ? fixedCurrencyDropdownItems
                : newFiatCurrencyDropdownItems
            }
            name="toCurrency"
          />
        </SkeletonBox>
      </div>

      {/* Show an error banner if something goes wrong with fetching a quote. Also disables continuing the form untill a valid quote is fetched. */}
      <FixedBanner
        className="mt-4"
        status="error"
        visible={quoteError !== undefined}
      >
        <FixedBanner.Title>{t('fx.formError.generic.title')}</FixedBanner.Title>
        <FixedBanner.Description>
          {t('fx.formError.generic.description')}
        </FixedBanner.Description>
      </FixedBanner>

      {/* Show an error banner if the user is attempting to redeem more tokens than they have in their account. */}
      <FixedBanner
        className="mt-4"
        status="error"
        visible={withdrawalOverBalance}
      >
        <FixedBanner.Title>
          {t('fx.formError.withdrawalTooLarge.title')}
        </FixedBanner.Title>
        <FixedBanner.Description>
          {t('fx.formError.withdrawalTooLarge.description')}
        </FixedBanner.Description>
      </FixedBanner>

      {/* FX agreement is only needed by deposit flow and only if Non-USD -> USDC */}
      {isDepositMode && isFxQuote && (
        <Form.Checkbox
          className="mt-6"
          label={<FxAgreement mode="deposit" />}
          name="checkbox"
        />
      )}

      <Center className="pt-6" variant="horizontal">
        <Form.SubmitButton
          className="mt-6 w-64"
          disabled={
            !account || quoteError !== undefined || withdrawalOverBalance
          }
          variant="primary"
        >
          {isDepositMode
            ? t`fx.viewDepositDetails`
            : t`fx.withdraw.reviewDetails.reviewDetails`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

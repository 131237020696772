import { SkeletonBox } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { VaultWithdrawalLimits } from '@shared/graphql'

/**
 * Limits Table props.
 */
export interface LimitsTableProps {
  /**
   * Withdrawal limits.
   */
  withdrawalLimits?: VaultWithdrawalLimits
  /**
   * Loading - limits are loading.
   */
  loading: boolean
  /**
   * Custom className.
   */
  className?: string
}

export const LimitsTable: React.FC<LimitsTableProps> = ({
  withdrawalLimits,
  loading,
  className,
}) => {
  const { t } = useTranslation('modals.vault')
  const { money } = useMoney()

  return (
    <dl className={className} data-testid="withdrawal-limits-table">
      <dt className="border-b border-black-50 pb-4 text-center text-base leading-6 font-circular-bold">
        {t`transfer.sendOnChain.transferAmount.withdrawalLimits.list.userLimits`}
      </dt>
      <div className="flex justify-between py-6">
        <dd className="pr-6">
          <SkeletonBox className="h-6 w-60" loading={loading}>
            <Trans
              components={{ b: <b /> }}
              i18nKey="modals.vault:transfer.sendOnChain.transferAmount.withdrawalLimits.list.transactionsRemaining"
              values={{
                available: withdrawalLimits?.operator.available.transactions,
                limit: withdrawalLimits?.operator.max.transactions,
              }}
            />
          </SkeletonBox>
        </dd>
        <dd className="pl-6">
          <SkeletonBox className="h-6 w-48" loading={loading}>
            <Trans
              components={{ b: <b /> }}
              i18nKey="modals.vault:transfer.sendOnChain.transferAmount.withdrawalLimits.list.limitPerTransaction"
              values={{
                limit:
                  withdrawalLimits &&
                  money({
                    number: Number(
                      withdrawalLimits?.operator.max.fiatAmount.amount,
                    ),
                    variant: withdrawalLimits?.operator.max.fiatAmount.currency,
                  }),
              }}
            />
          </SkeletonBox>
        </dd>
      </div>
      <dt className="border-b border-black-50 pb-4 pt-6 text-center text-base leading-6 font-circular-bold">
        {t`transfer.sendOnChain.transferAmount.withdrawalLimits.list.walletLimits`}
      </dt>
      <div className="flex justify-between py-6">
        <dd className="pr-6">
          <SkeletonBox className="h-6 w-60" loading={loading}>
            <Trans
              components={{ b: <b /> }}
              i18nKey="modals.vault:transfer.sendOnChain.transferAmount.withdrawalLimits.list.transactionsRemaining"
              values={{
                available: withdrawalLimits?.wallet.available.transactions,
                limit: withdrawalLimits?.wallet.max.transactions,
              }}
            />
          </SkeletonBox>
        </dd>
        <dd className="pl-6">
          <SkeletonBox className="h-6 w-48" loading={loading}>
            <Trans
              components={{ b: <b /> }}
              i18nKey="modals.vault:transfer.sendOnChain.transferAmount.withdrawalLimits.list.amountAvailable"
              values={{
                available:
                  withdrawalLimits &&
                  money({
                    number: Number(
                      withdrawalLimits?.wallet.available.fiatAmount.amount,
                    ),
                    variant:
                      withdrawalLimits?.wallet.available.fiatAmount.currency,
                  }),
                limit:
                  withdrawalLimits &&
                  money({
                    number: Number(
                      withdrawalLimits?.wallet.max.fiatAmount.amount,
                    ),
                    variant: withdrawalLimits?.wallet.max.fiatAmount.currency,
                  }),
              }}
            />
          </SkeletonBox>
        </dd>
      </div>
    </dl>
  )
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Name.
   */
  bankName: y.string().max(35, { key: 'max', max: 35 }).required(),
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Account Routing Number.
   */
  routingNumber: y.string().bic().required(),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().accountNumber({ variant: 'other' }).required(),
})

export type NonIbanWireFormValues = y.InferType<typeof schema>

export interface NonIbanWireFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: NonIbanWireFormValues) => void
}

export const NonIbanWireForm: React.FC<NonIbanWireFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<NonIbanWireFormValues>({
    schema,
    mode: 'onBlur',
    defaultValues: {
      ...information.nonIban,
    },
  })

  const onFormSubmit = useCallback(
    (nonIban: NonIbanWireFormValues) => {
      // Set data to context
      setInformation({ nonIban })

      // Pass data to submit callback (for testing)
      onSubmit?.(nonIban)
    },
    [onSubmit, setInformation],
  )
  return (
    <div className="gap-3">
      <Form className="grid w-full gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-bankName"
          label={t('modals.bankAccount:link.accountInformation.bankName')}
          name="bankName"
        />

        <Form.Input
          className="w-full"
          data-testid="input-nickname"
          label={t('modals.bankAccount:link.accountInformation.nickname')}
          name="nickname"
        />

        <Form.Input
          className="w-full"
          data-testid="input-routingNumber"
          label={t('modals.bankAccount:link.accountInformation.bic')}
          name="routingNumber"
        />

        <Form.Input
          className="w-full"
          data-testid="input-accountNumber"
          label={t('modals.bankAccount:link.accountInformation.accountNumber')}
          name="accountNumber"
        />

        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Form>
    </div>
  )
}

import classNames from 'classnames'

export interface CenterProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Center Variant.
   */
  variant?: 'vertical' | 'horizontal' | 'both'
  /**
   * Custom Styles?
   */
  className?: string
}

const variants: Record<string, string> = {
  /**
   * Vertical Center.
   */
  vertical: 'content-center items-center',
  /**
   * Horizontal Center.
   */
  horizontal: 'justify-center',
  /**
   * Vertical and Horizontal Center.
   */
  both: 'content-center items-center justify-center',
}

/**
 * Center Component.
 */
export const Center: React.FC<CenterProps> = ({
  variant = 'both',
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames('grid h-full', className, variants[variant])}
      data-testid={`center-${variant}`}
      {...props}
    >
      {children}
    </div>
  )
}

import { useMemo } from 'react'

import { Icon, Tooltip } from '@circlefin/components'
import { Center } from '@shared/components/layout'
import { DefaultCell } from '@shared/components/tables'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainAbbreviation, BlockchainAsset } from '@shared/graphql'

export interface AssetsProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Blockchain assets.
   */
  assets: Array<Omit<BlockchainAsset, 'txcoreCurrency'>>
  /**
   * Blockchain name.
   */
  chain?: BlockchainAbbreviation
  /**
   * Number of tokens visible.
   */
  visibleCount?: number
  /**
   * If this is property is true, the token list will be rendered as a comma separated list.
   */
  variant?: 'default' | 'comma-separated'
}

export const Assets: React.FC<AssetsProps> = ({
  assets,
  chain,
  visibleCount = 2,
  variant = 'default',
}) => {
  const { t } = useTranslation('payments')

  const visible = useMemo(
    () => assets.slice(0, visibleCount),
    [assets, visibleCount],
  )

  const shownInTooltip = useMemo(
    () => assets.slice(visibleCount),
    [assets, visibleCount],
  )

  if (assets.length === 0) {
    if (chain == null) {
      return null
    }

    return (
      <DefaultCell className="whitespace-normal break-all">
        {t('allAssets', { chainName: t<string>(`chainNames.${chain}`) })}
      </DefaultCell>
    )
  }

  if (variant === 'comma-separated') {
    return (
      <div className="whitespace-normal">
        {assets.map((asset) => asset.symbol).join(', ')}
      </div>
    )
  }

  return (
    <div className="flex gap-x-1">
      {visible.map((asset, index) => (
        <Tooltip
          key={index}
          content={
            <span
              className="text-sm leading-5 text-black-600"
              data-testid={`tooltip-content-${asset.symbol}`}
            >
              {asset.symbol}
            </span>
          }
          place="top"
          width="hug"
        >
          <span>
            <Icon name={asset.icon} size={24} />
          </span>
        </Tooltip>
      ))}

      {assets.length > visibleCount && (
        <Tooltip
          content={
            <div
              className="grid grid-cols-1 gap-y-1.5"
              data-testid="tooltip-content"
            >
              {shownInTooltip.map((asset, index) => (
                <div key={index} className="flex items-center gap-x-2.5">
                  <Icon name={asset.icon} size={16} />
                  <span className="text-sm leading-5 text-black-600 font-circular-regular">
                    {asset.symbol}
                  </span>
                </div>
              ))}
            </div>
          }
          place="top"
          width="hug"
        >
          <div>
            <Center className="size-6 rounded-full bg-black-50 p-1">
              <span className="text-xs text-black-400 font-circular-regular">
                +{assets.length - visibleCount}
              </span>
            </Center>
          </div>
        </Tooltip>
      )}
    </div>
  )
}

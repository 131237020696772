import type React from 'react'

import { Card, Header } from '@circlefin/components'
import { TransWithLink } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

import { SalesforceSupportForm } from './SalesforceSupport.Form'
import { SalesforceSupportSuccess } from './SalesforceSupport.Success'

export interface SalesforceSupportProps {
  /**
   * Indicates if form should render post-submission "success" state.
   */
  success: boolean
}
export const SalesforceSupport: React.FC<SalesforceSupportProps> = ({
  success,
}) => {
  const { t } = useTranslation('support')

  return (
    <div className="h-full px-16">
      <Header>
        <Header.Head title={t`header`} />
        <Header.Description>
          <TransWithLink
            i18nKey="support:description"
            urlI18nKey="common:helpCenter"
            variant="url"
          />
        </Header.Description>
      </Header>

      <Card className="mt-6 w-full max-w-xl">
        <Card.Content className="p-10">
          {success ? <SalesforceSupportSuccess /> : <SalesforceSupportForm />}
        </Card.Content>
      </Card>
    </div>
  )
}

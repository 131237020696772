import { useCallback } from 'react'

import { Button, Card, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  Currency,
  PaymentsDocument,
  useActiveWalletApprovalPolicyQuery,
  useWalletApprovalCreateOnchainTransferMutation,
  WalletApprovalPendingTransactionsDocument,
} from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../SendOnchain.Context'

import { Description } from './Description'
import { Label } from './Label'

import type { CreateTransferInput } from '@shared/graphql'

export const ReviewTransferDetails: React.FC = () => {
  const { t } = useTranslation('modals.blockchain')
  const [{ destination, amount, total, fee, blockchain }] = useSendOnChain()
  const { idempotencyKey } = useIdempotencyKey()

  const { money } = useMoney()
  const modal = useModal()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)
  const walletPolicyQuery = useActiveWalletApprovalPolicyQuery({
    variables: { currency },
    skip: !isAuthorized,
    fetchPolicy: 'cache-and-network',
  })
  const hasWalletPolicyWithApproverSteps =
    walletPolicyQuery.data?.activeWalletApprovalPolicy?.approvalWorkflow != null

  const [createTransfer, { loading }] =
    useWalletApprovalCreateOnchainTransferMutation({
      refetchQueries: [
        /**
         * Refetch Payments Activity Table.
         */
        PaymentsDocument,
        WalletApprovalPendingTransactionsDocument,
        /**
         * NOTE: Doesn't help to refetch balances here as they aren't
         * up-to-date immediately after a successful transfer.
         */
      ],
      onCompleted: () => {
        // If there is an active policy, navigate to the finished screen (this screens tells them their transfer needs approval)
        if (hasWalletPolicyWithApproverSteps) {
          modal.router.push({
            pathname: routes.blockchain.sendOnchain.approvalRequired,
            query: {
              totalSteps: 6,
              actionLink: {
                pathname: circleWalletDetailSection.route,
                query: { currency },
              },
              currency,
            },
          })
          return
        }

        mfaSuccess({
          message: t`common:success`,
        })
      },
      onError: (error) =>
        mfaError({
          error,
          message: t`common:generalError`,
        }),
    })

  /**
   * Invoke transfer mutation after successful MFA.
   */
  const onTransferClick = useCallback(() => {
    if (!destination) {
      return
    }

    const input: CreateTransferInput = {
      idempotencyKey,
      amount: {
        amount: String(amount),
        currency,
      },
      destination: {
        id: destination.id,
        address: destination.address,
        chain: destination.chain,
        description: destination.description,
      },
    }

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createTransfer({
          variables: {
            input,
          },
          context: context(code),
        })
      },
      onAbort: () => {
        modal.close({ context: 'onDismiss' })
      },
    })
  }, [
    amount,
    context,
    createTransfer,
    currency,
    destination,
    idempotencyKey,
    modal,
    withMfa,
  ])

  return (
    <FullScreen totalSteps={5}>
      <GraphQLErrorBoundary
        error={walletPolicyQuery.error}
        retry={walletPolicyQuery.refetch}
        variant="page"
      >
        <Center className="text-center font-circular" variant="horizontal">
          <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
            {t`sendOnchain.reviewTransferDetails.title`}
          </h2>
          <h3 className="mb-4 text-lg leading-6 text-black-400">
            {t`sendOnchain.reviewTransferDetails.subtitle`}
          </h3>

          <Card className="w-192 px-4 py-2" variant="secondary/base">
            <DescriptionList
              className="bg-transparent"
              data-testid="description-list"
            >
              <Label>{t`sendOnchain.reviewTransferDetails.walletAddress`}</Label>
              <Description className="break-words">
                {destination?.address ?? ''}
              </Description>
              <Label>{t`sendOnchain.reviewTransferDetails.recipient`}</Label>
              <Description>{destination?.description}</Description>
              <Label>{t`sendOnchain.reviewTransferDetails.blockchain`}</Label>
              <Description>{blockchain}</Description>
              <Label>{t`sendOnchain.reviewTransferDetails.fees`}</Label>
              <Description>
                {money({ number: fee, variant: currency })}
              </Description>
              <Label>{t`sendOnchain.reviewTransferDetails.amount`}</Label>
              <Description>
                <span className="font-circular-bold" data-testid="amount">
                  {money({
                    number: total,
                    variant: currency,
                    options: {
                      symbol: false,
                    },
                  })}{' '}
                  {/* TODO: Revisit L2 treatment in [https://circlepay.atlassian.net/browse/BRAAV-10935]. */}
                  {/* TODO: Update useMoney hook or pass in currency variable when supporting EURC onchain transactions */}
                  {t(
                    `payments:currencyByChain.${blockchain ?? ''}`,
                    { currency },
                    { fallback: `payments:${currency.toLowerCase()}` },
                  )}
                </span>
              </Description>
            </DescriptionList>
          </Card>
        </Center>

        <Center className="pt-10" variant="horizontal">
          <Button
            className="h-10 w-60"
            data-testid="transfer-button"
            disabled={walletPolicyQuery.loading}
            loading={loading}
            onClick={onTransferClick}
            variant="primary"
          >
            {t`sendOnchain.reviewTransferDetails.transfer`}
          </Button>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

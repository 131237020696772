import { useCallback } from 'react'

import { Button, LottieIcon, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import { ReportStatus, useReportQuery, ReportType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Report, ReportComplete, Currency } from '@shared/graphql'

export interface ExportReportQueryProps {
  /**
   * Title of the modal.
   */
  title?: string
  /**
   * Type of report.
   */
  type?: ReportType
  /**
   * Year of the report.
   */
  year?: number
  /**
   * Month of the report.
   */
  month?: number
  /**
   * Currency to filter by.
   */
  currency?: Currency
  /**
   * Start of date range to filter by.
   */
  fromDate?: Date
  /**
   * End of date range to filter by.
   */
  toDate?: Date
}

/**
 * ReportComplete Typeguard.
 */
const isReportComplete = (report: Report): report is ReportComplete => {
  return report.status === ReportStatus.complete
}

/**
 * Extract the filename of a URL.
 */
const extractFilenameFromUrl = (url: string): string =>
  decodeURIComponent(url.split('?')[0].substring(url.lastIndexOf('/') + 1))

export const ExportReport = ({
  title,
  type,
  year,
  month,
  currency,
  fromDate,
  toDate,
}: ExportReportQueryProps) => {
  const { t } = useTranslation('common')
  const { close } = useModal()

  const { data, loading, error } = useReportQuery({
    // type is a required input, if the prop is not passed in, we will catch it and show an error via the PropsErrorBoundary component.
    // to please typescript, set a default type and skip the query call
    variables: {
      input: {
        type: type ?? ReportType.transaction,
        year,
        month,
        currency,
        fromDate,
        toDate,
      },
    },
    skip: type == null,
  })

  const onClose = useCallback(() => close({ context: 'onDismiss' }), [close])

  /**
   * Download handler to initiate the report download.
   */
  const downloadHandler = useCallback(() => {
    if (data && isReportComplete(data.report)) {
      window.location.href = data.report.downloadUrl
    }

    onClose()
  }, [data, onClose])

  return (
    <BaseTitle title={title ?? t('exportData.modal.header')} variant="center">
      <PropsErrorBoundary props={{ type }} variant="page">
        {() => (
          <>
            <div className="mb-12 mt-6">
              {(loading || data?.report.status === ReportStatus.pending) && (
                <>
                  <p className="text-neutral-strong type-body-base">
                    {t('exportData.modal.processing')}
                  </p>
                  <p className="text-neutral-strong type-body-base">
                    {t('exportData.modal.delay-notice')}
                  </p>
                  <div className="mx-auto w-10 pt-6">
                    <LottieIcon
                      height={40}
                      name="LoadingSpinner"
                      width={40}
                      loop
                    />
                  </div>
                </>
              )}

              {data && isReportComplete(data.report) && (
                <>
                  <p
                    className="text-neutral-strong type-body-base"
                    data-testid="status"
                  >
                    {t('exportData.modal.available')}
                  </p>
                  <p className="text-base leading-10 text-black-600">
                    {extractFilenameFromUrl(data.report.downloadUrl)}
                  </p>
                </>
              )}

              {(error || data?.report.status === ReportStatus.failed) && (
                <>
                  <p className="text-neutral-strong type-body-base">
                    {t('exportData.modal.processing')}
                  </p>
                  <p className="text-error type-body-base" data-testid="status">
                    {t('exportData.modal.error')}
                  </p>
                </>
              )}
            </div>

            <Modal.Footer variant="stretch-equal">
              <Button onClick={onClose} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button
                disabled={data?.report.status !== ReportStatus.complete}
                onClick={downloadHandler}
                variant="primary"
              >
                {t('common:download')}
              </Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { ButtonCard, Heading } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { VAULT_MUTATION } from '@services/permissions'

import type { ButtonCardProps } from '@circlefin/components/lib/ButtonCard'

export interface ChooseVaultProps extends ButtonCardProps {
  /**
   * Icon.
   */
  icon?: React.ComponentProps<typeof Heading>['iconName']
  /**
   * Title text.
   */
  title: string
  /**
   * Subtitle text.
   */
  subtitle?: string
}

export const ChooseVault: React.FC<ChooseVaultProps> = ({
  children,
  icon,
  title,
  subtitle,
  ...props
}) => {
  const [, { isAuthorized }] = usePermission(VAULT_MUTATION)

  return (
    <ButtonCard
      data-testid="choose-vault-button-card"
      {...props}
      disabled={!isAuthorized || props.disabled}
    >
      <Heading iconName={icon}>
        <Heading.Header>{title}</Heading.Header>
        <Heading.Intro>{subtitle}</Heading.Intro>
      </Heading>
    </ButtonCard>
  )
}

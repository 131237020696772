import { useMemo } from 'react'

import { AuthorizedRepOccupation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const useAuthorizedRepOccupation = () => {
  const { t } = useTranslation('onboard/account-setup')

  const data: Array<SelectListItem<AuthorizedRepOccupation>> = useMemo(() => {
    const defaultOccupations = Object.values(AuthorizedRepOccupation)
      .filter((occupation) => occupation !== AuthorizedRepOccupation.other)
      .map((option) => ({
        label: t(
          `confirmRole.invite.form.occupations.${AuthorizedRepOccupation[option]}`,
        ),
        value: AuthorizedRepOccupation[option],
      }))

    return [
      ...defaultOccupations,
      // Ensure 'Other' option is the last to appear in the dropdown
      // Because generated GQL enums are sorted alphabetically
      {
        label: t(
          `confirmRole.invite.form.occupations.${AuthorizedRepOccupation.other}`,
        ),
        value: AuthorizedRepOccupation.other,
      },
    ]
  }, [t])

  return {
    data,
  }
}

import { useCallback, useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../../buttons'
import { useVaultTransfer } from '../VaultTransfer.Context'

import type { Scalars } from '@shared/graphql'

export interface ChooseTransferTypeQueryProps {
  /**
   * Specify parentWalletId to transfer from.
   */
  parentWalletId?: Scalars['ID']['output']
  /**
   * Specify child/mapped wallet id to transfer from.
   */
  walletId?: Scalars['ID']['output']
}

export const ChooseTransferType: React.FC<ChooseTransferTypeQueryProps> = ({
  parentWalletId,
  walletId,
}) => {
  const { router } = useModal()
  const { t } = useTranslation('modals.vault')
  const [, { setVaultTransferState }] = useVaultTransfer()
  const [, { setCurrency }] = useCurrentCurrency()

  // When transferring from Vault, clear current-currency
  useEffect(() => {
    setCurrency({ currency: undefined })
  }, [setCurrency])

  // const { loading, data } = useBankAccountsQuery()

  /* const hasLinkedBankAccounts = useMemo(
    () =>
      data?.bankAccounts.some(
        ({ status }) => status === BankAccountStatus.complete
      ),
    [data?.bankAccounts]
  ) */

  /* const handleBankWithdrawalModal = useCallback(() => {
    if (hasLinkedBankAccounts) {
      router.push(routes.transfer.withdraw.amount)
      return
    }

    router.push(routes.bankAccount.noLinked.withdraw)
  }, [router, hasLinkedBankAccounts]) */

  const handleSendOnChainModal = useCallback(() => {
    // Update transfer context
    setVaultTransferState({
      selectedParentWalletId: parentWalletId,
      selectedWalletId: walletId,
    })

    // Route to recipient screen
    router.push(routes.vault.transfer.sendOnChain.recipientExternal)
  }, [parentWalletId, router, setVaultTransferState, walletId])

  const handleTransferCircleWalletsModal = useCallback(() => {
    // Update transfer context
    setVaultTransferState({
      selectedParentWalletId: parentWalletId,
      selectedWalletId: walletId,
    })

    // Route to recipient screen
    router.push(routes.vault.transfer.sendOnChain.recipientInternal)
  }, [parentWalletId, router, setVaultTransferState, walletId])

  return (
    <FullScreen totalSteps={6}>
      <Center className="font-circular" variant="horizontal">
        <h2 className="text-center text-4xl text-black-600 font-circular-bold">
          {t`transfer.chooseTransferType.title`}
        </h2>

        <div className="mx-auto mt-10 w-full max-w-120 space-y-6">
          <Buttons.Cards.TransferVault
            icon="LinkOutline"
            onClick={handleSendOnChainModal}
            subtitle={t`transfer.chooseTransferType.on-chain.subtitle`}
            title={t`transfer.chooseTransferType.on-chain.title`}
          />

          <Buttons.Cards.TransferVault
            icon="SwitchHorizontalOutline"
            onClick={handleTransferCircleWalletsModal}
            subtitle={t`transfer.chooseTransferType.wallets.subtitle`}
            title={t(`transfer.chooseTransferType.wallets.title`)}
          />
        </div>
      </Center>
    </FullScreen>
  )
}

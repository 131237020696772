import { useContext } from 'react'

import { TransferContext } from './context'

/**
 * Transfer context hook.
 */
export const useTransfer = () => {
  return useContext(TransferContext)
}

import { Table } from '@circlefin/components'
import { SimpleTable, TableCard } from '@shared/components/tables'
import { useUserQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { PermissionRow } from './Permissions.Row'

import type { PermissionRowProps } from './Permissions.Row'
import type { WalletApprovalPolicyUserLimit } from '@shared/graphql'

export interface PermissionsTableProps
  extends Partial<Pick<PermissionRowProps, 'onChange' | 'onRemove'>> {
  /**
   * User limits.
   */
  userLimits: WalletApprovalPolicyUserLimit[]
}

export const PermissionsTable: React.FC<PermissionsTableProps> = ({
  userLimits,
  onChange,
  onRemove,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  const { data, loading, error, refetch } = useUserQuery()

  return (
    <TableCard>
      <SimpleTable
        empty={t`common:emptyTable`}
        error={error}
        loading={loading}
        retry={refetch}
        size={userLimits.length}
      >
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell className="px-4 normal-case type-body-sm">
              {t('permissions.table.headers.user')}
            </Table.Head.Cell>
            <Table.Head.Cell className="w-8/12 px-7 normal-case type-body-sm">
              {t('permissions.table.headers.permissions')}
            </Table.Head.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {userLimits.map((userLimit) => (
            <PermissionRow
              key={userLimit.user.id}
              isOwner={userLimit.user.id === data?.user.id}
              onChange={onChange}
              onRemove={onRemove}
              userLimit={userLimit}
            />
          ))}
        </Table.Body>
      </SimpleTable>
    </TableCard>
  )
}

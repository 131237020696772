import { Icon } from '@circlefin/components'
import classNames from 'classnames'

export interface VaultStepProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Step index.
   */
  stepIndex?: number
  /**
   * Is active step.
   */
  isActive?: boolean
  /**
   * Is step completed.
   */
  isComplete?: boolean
  /**
   * Is last step.
   */
  isLast?: boolean
  /**
   * Step title.
   */
  title: string
}

export const VaultStep: React.FC<VaultStepProps> = ({
  className,
  stepIndex = 0,
  isActive = false,
  isLast,
  isComplete = false,
  title,
}) => {
  return (
    <li
      aria-current={isActive ? 'step' : 'false'}
      className={classNames('flex items-center', className)}
    >
      <div className="flex items-center gap-x-3">
        <span
          className={classNames(
            'border-2 w-6 h-6 rounded-full border-solid flex justify-center items-center border-black-300 text-xs text-black-300',
            {
              'border-none text-white ': isActive || isComplete,
              'bg-blue-500': isActive,
              'bg-green-400': isComplete,
            },
          )}
        >
          {isComplete && <span className="sr-only">Completed</span>}
          <span className="sr-only">Step</span>
          {isComplete ? <Icon name="CheckOutline" size={14} /> : stepIndex + 1}
        </span>
        <span
          className={classNames('text-sm font-circular-medium uppercase', {
            'text-blue-500': isActive,
            'text-black-300': !isActive,
          })}
        >
          {title}
        </span>
      </div>
      {!isLast && (
        <div className="mx-4 w-10">
          <div className="block border-t-2 border-solid border-black-75" />
        </div>
      )}
    </li>
  )
}

import { useCallback } from 'react'

import { Button, LottieIcon, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FullScreen } from '@modals/layout'
import { FeatureSwitch } from '@services/feature-switch'
import {
  CIRCLE_WALLETS_MUTATION,
  VAULT_CREATOR_MUTATION,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { usePairDeviceContext } from '../PairDevice.Context'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [, circleWalletsPermission] = usePermission(CIRCLE_WALLETS_MUTATION)
  const [, vaultsPermission] = usePermission(VAULT_CREATOR_MUTATION)
  const modal = useModal()

  const [{ successFlow = 'createVault' }] = usePairDeviceContext()

  const handleDone = useCallback(() => {
    modal.close()
  }, [modal])

  const handleCreateVault = useCallback(() => {
    modal.router.push(
      FeatureSwitch.walletConnectEnabled()
        ? routes.vault.chooseVaultType
        : routes.vault.createVault.initialize,
    )
  }, [modal.router])

  const showCreateVaultCta =
    vaultsPermission.isAuthorized && successFlow === 'createVault'

  return (
    <FullScreen>
      <Center className="justify-items-center" variant="horizontal">
        <LottieIcon height={65} name="Check" width={65} />

        <div className="mt-8 w-100 text-center text-base leading-6 text-neutral-strong">
          <h2 className="text-3xl font-circular-bold" data-testid="title">
            {t('common:success')}
          </h2>
          <p
            className="mt-5 text-xl font-circular-regular"
            data-testid="message"
          >
            {circleWalletsPermission.isAuthorized ||
            vaultsPermission.isAuthorized
              ? t(`pairDevice.success.${successFlow}.message`)
              : t(`pairDevice.success.pairDeviceSuccess.message`)}
          </p>
        </div>

        <Modal.Footer
          className="mt-14 gap-x-6"
          variant={showCreateVaultCta ? 'center' : undefined}
        >
          <Button className="w-60" onClick={handleDone} variant="secondary">
            {t('common:done')}
          </Button>
          {showCreateVaultCta && (
            <Button
              className="w-60"
              onClick={handleCreateVault}
              variant="primary"
            >
              {t('pairDevice.success.createVault.cta')}
            </Button>
          )}
        </Modal.Footer>
      </Center>
    </FullScreen>
  )
}

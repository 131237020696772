/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

import { SkeletonBox } from '@circlefin/components'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useVeriteIssuerChallengeQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import ReactQRCode from 'qrcode.react'

export interface IssuerQRCodeQueryProps {
  /**
   * The type of credential.
   */
  credentialType?: string
}

export const IssuerQRCode: React.FC<IssuerQRCodeQueryProps> = ({
  credentialType,
}) => {
  const { t } = useTranslation()
  const { data, error, loading, refetch } = useVeriteIssuerChallengeQuery()
  const qrCodeData = JSON.stringify(data?.veriteIssuerChallenge)

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      <div className="flex flex-col items-center p-6">
        <h1>
          <Trans
            i18nKey="verite:scan-qr-code-modal-title"
            values={{ credentialType: credentialType ?? '' }}
          />
        </h1>
        <p className="mt-3 max-w-xs justify-center">
          {t('verite:scan-qr-code-instructions')}
        </p>
        <SkeletonBox loading={loading}>
          <div className="mt-6 size-48">
            <ReactQRCode
              height="100%"
              renderAs="svg"
              value={qrCodeData ?? ''}
              width="100%"
            />
          </div>
        </SkeletonBox>
        <a className="mt-6">{t('verite:scan-qr-code-supported-wallets')}</a>
      </div>
    </GraphQLErrorBoundary>
  )
}

import { Icon } from '@circlefin/components'
import { useSegment } from '@services/segment'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export const Empty: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { trackLinkClick } = useSegment()

  return (
    <div
      className="mt-10 flex w-full flex-col items-center justify-center"
      data-testid="choose-asset-empty"
    >
      <div className="mb-4 flex size-16 flex-row items-center justify-center rounded-full bg-black-50">
        <Icon
          className="text-black-300"
          height={18}
          name="SearchOutline"
          width={18}
        />
      </div>
      <h2 className="text-xl font-circular-bold">{t`createVaultWallet.chooseAsset.empty.title`}</h2>
      <p className="text-lg font-circular-regular">
        <Trans
          components={{
            a: (
              <a
                href={t`modals.vault:createVaultWallet.chooseAsset.empty.link`}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
          }}
          i18nKey="modals.vault:createVaultWallet.chooseAsset.empty.subtitle"
        />
      </p>
    </div>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultWalletState } from '@shared/graphql'
import Trans from 'next-translate/Trans'

import { useVaultPermission } from '../../../hooks/permission'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { VaultTransactionType } from '@shared/graphql'

export interface DownloadReportProps
  extends Pick<ButtonProps, 'disabled' | 'variant' | 'size'> {
  /**
   * Id of the wallet the transactions should be downloaded for.
   */
  walletId: string
  /**
   * The name of the wallet. Will be used to generate the exported filename.
   */
  walletName: string
  /**
   * The state of the wallet.
   */
  walletState: VaultWalletState
  /**
   * Specify from which date transactions are to be downloaded.
   *
   * This is optional. If you do not provide a value the creation date of the wallet will be used as start date.
   */
  startDate?: Date
  /**
   * Specify until which date transactions are to be downloaded.
   *
   * This is optional. If you do not provide a value the current value will be used as end date.
   */
  endDate?: Date
  /**
   * Specify which types of transactions should be present in the report.
   */
  transactionTypes?: VaultTransactionType[]
}

export const DownloadReport: React.FC<DownloadReportProps> = ({
  walletId,
  walletName,
  walletState,
  startDate,
  endDate,
  transactionTypes,
  disabled,
  variant = 'primary',
  size,
}) => {
  const { track } = useSegment()
  const { router } = useModal()

  const { vaultMetadata } = useVaultPermission()

  const handleClick = useCallback(() => {
    /**
     * Even though this should never happen,
     * we will just return to satisfy typescript.
     */
    if (!vaultMetadata?.id) return

    track(SegmentEvents.DownloadVaultWalletReportButtonClicked)
    void router.push({
      pathname: routes.vault.downloadTransactionsReport,
      query: {
        input: {
          vaultId: vaultMetadata.id,
          vaultName: vaultMetadata.name,
          walletId,
          walletName,
          startDate: startDate?.toISOString() ?? undefined,
          endDate: endDate?.toISOString() ?? undefined,
          transactionTypes,
        },
      },
    })
  }, [
    vaultMetadata,
    router,
    track,
    walletId,
    walletName,
    startDate,
    endDate,
    transactionTypes,
  ])

  // Do not return anything if button was not wrapped with a VaultPermissionProvider.
  if (!vaultMetadata) return null

  return (
    <Button
      disabled={
        // Do not allow downloading transactions for a wallet that is not yet active.
        walletState !== VaultWalletState.ACTIVE || disabled
      }
      onClick={handleClick}
      size={size}
      variant={variant}
    >
      <Trans
        components={{
          span: <span className="ml-1 normal-case" />,
        }}
        i18nKey={`vaults:buttons.downloadReport.${
          startDate != null || endDate != null || transactionTypes != null
            ? 'filtered'
            : 'all'
        }`}
      />
    </Button>
  )
}

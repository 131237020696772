import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal'
import { BurnFeeV2Banner } from '@shared/components/common'
import { Center, Justify } from '@shared/components/layout'
import { Currency, WireTransferTypeEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { LimitCard } from '../../../../components/LimitCard/LimitCard'
import { ExchangeRateCard } from '../../ExchangeRateCard/ExchangeRateCard'
import { FxTransferForm } from '../../Form/Fx.Form'

export interface FxWithdrawProps {
  /**
   * Specify which token is selected by the user to redeem from.
   */
  currency?: Currency
}

export const FxWithdraw: React.FC<FxWithdrawProps> = ({ currency }) => {
  const { t } = useTranslation('modals.payout')
  const { router } = useModal()

  const handleFormSubmit = useCallback(() => {
    router.push(routes.transfer.fx.withdraw.review)
  }, [router])

  return (
    <FullScreen totalSteps={3}>
      <Center className="mb-8" variant="horizontal">
        <h2 className="mb-2 text-center text-3xl font-circular-bold">
          {t`withdraw.fx.title`}
        </h2>
        <h3 className="text-sm text-neutral font-circular-regular">
          {t`withdraw.fx.subtitle`}
        </h3>
      </Center>

      <Justify>
        <Justify.Content className="grid gap-y-6" variant="center">
          <BurnFeeV2Banner />
          <FxTransferForm
            fixedCurrency={currency ?? Currency.USDC}
            mode="withdrawal"
            onSubmit={handleFormSubmit}
          />
        </Justify.Content>
        <Justify.Content className="mt-6 flex flex-col gap-6" variant="right">
          <div>
            <ExchangeRateCard />
          </div>
          <div className="h-2/6">
            <LimitCard
              currency={currency ?? Currency.USD}
              mode={WireTransferTypeEnum.withdrawal}
            />
          </div>
        </Justify.Content>
      </Justify>

      <div className="fixed bottom-0 left-0 w-screen text-center">
        <p className="inset-x-0 mx-auto w-248 text-sm font-circular-regular">
          <Trans
            components={{
              bold: <strong />,
            }}
            i18nKey="modals.payout:withdraw.fx.disclaimer"
          />
        </p>
      </div>
    </FullScreen>
  )
}

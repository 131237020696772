import { useMemo } from 'react'

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { MerchantPaymentCancellationReason } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const reasonsSchema = y
  .string()
  .oneOf(Object.values(MerchantPaymentCancellationReason))

export type ReasonsProps = Omit<React.ComponentProps<typeof Dropdown>, 'items'>

const Dropdown = createFormDropdown()

export const Reasons: React.FC<ReasonsProps> = (props) => {
  const { t } = useTranslation('forms')

  const items = useMemo(
    () => [
      {
        value: MerchantPaymentCancellationReason.bank_account_ineligible,
        label: t`dropdown.platform.reasons.list.bank_account_ineligible`,
      },
      {
        value: MerchantPaymentCancellationReason.bank_transaction_error,
        label: t`dropdown.platform.reasons.list.bank_transaction_error`,
      },
      {
        value: MerchantPaymentCancellationReason.duplicate,
        label: t`dropdown.platform.reasons.list.duplicate`,
      },
      {
        value: MerchantPaymentCancellationReason.fraudulent,
        label: t`dropdown.platform.reasons.list.fraudulent`,
      },
      {
        value: MerchantPaymentCancellationReason.insufficient_funds,
        label: t`dropdown.platform.reasons.list.insufficient_funds`,
      },
      {
        value: MerchantPaymentCancellationReason.invalid_account_number,
        label: t`dropdown.platform.reasons.list.invalid_account_number`,
      },
      {
        value: MerchantPaymentCancellationReason.invalid_ach_rtn,
        label: t`dropdown.platform.reasons.list.invalid_ach_rtn`,
      },
      {
        value: MerchantPaymentCancellationReason.payment_failed,
        label: t`dropdown.platform.reasons.list.payment_failed`,
      },
      {
        value: MerchantPaymentCancellationReason.payment_returned,
        label: t`dropdown.platform.reasons.list.payment_returned`,
      },
      {
        value: MerchantPaymentCancellationReason.payment_stopped_by_issuer,
        label: t`dropdown.platform.reasons.list.payment_stopped_by_issuer`,
      },
      {
        value: MerchantPaymentCancellationReason.requested_by_customer,
        label: t`dropdown.platform.reasons.list.requested_by_customer`,
      },
      {
        value: MerchantPaymentCancellationReason.unauthorized_transaction,
        label: t`dropdown.platform.reasons.list.unauthorized_transaction`,
      },
    ],
    [t],
  )

  return <Dropdown {...props} items={items} />
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Delayed withdrawal settings.
 * Which users are allowed to change delayed withdrawal settings?
 */
export const DELAYED_WITHDRAWAL_SETTINGS_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.ACCOUNT_WEB_ROLE, Role.DELAYED_WITHDRAWAL_ROLE],
}

import { useCallback } from 'react'

import { Button, Icon, Modal, SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  IpAllowListingIpAddressType,
  useClientIpAddressQuery,
  useIpAllowListingSettingQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { SecurityForms } from '../../../../../forms'

import type { FieldPath } from 'react-hook-form/dist/types/path'

const ipAddressTypeSchema = y
  .string()
  .oneOf(Object.values(IpAllowListingIpAddressType))

const schema = y.object({
  /**
   * Ip Address Min.
   */
  ipMin: y
    .string()
    .ipAddress()
    .when('ipType', {
      is: IpAllowListingIpAddressType.range,
      then: (schema) =>
        schema
          // Override default ip address keys
          .ipAddressRange({
            minIpKey: 'ipMin',
            maxIpKey: 'ipMax',
          }),
    })
    .required(),
  /**
   * Ip Address Max.
   */
  ipMax: y.string().when('ipType', {
    is: IpAllowListingIpAddressType.range,
    then: (schema) =>
      schema
        .ipAddress()
        .ipAddressRange({
          minIpKey: 'ipMin',
          maxIpKey: 'ipMax',
        })
        .required(),
  }),
  /**
   * The IP address type.
   */
  ipType: ipAddressTypeSchema.required(),
})

export type IpAddressCreateFormState = y.InferType<typeof schema>

/**
 * Props interface.
 */
export interface CreateFormProps {
  /**
   * Initial form state.
   */
  initialValues?: IpAddressCreateFormState
  /**
   * On submit callback.
   */
  onSubmit: (formValues: IpAddressCreateFormState) => void
}

export const CreateForm: React.FC<CreateFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation('modals/settings/security')
  const { track } = useSegment()
  const clientIpQuery = useClientIpAddressQuery()
  const settingQuery = useIpAllowListingSettingQuery()

  const loading = clientIpQuery.loading || settingQuery.loading
  const clientIp = clientIpQuery.data?.clientIpAddress.ip
  const isSettingEnabled = settingQuery.data?.ipAllowListingSetting.enabled

  const [
    Form,
    {
      watch,
      setValue,
      trigger,
      formState: { errors },
    },
  ] = useForm<IpAddressCreateFormState>({
    schema,
    defaultValues: {
      ipMin: '',
      ipMax: '',
      ipType: IpAllowListingIpAddressType.single,
      ...initialValues,
    },
  })

  const ipType = watch('ipType')

  const setIpMin = useCallback(() => {
    if (clientIp) {
      void setValue('ipMin', clientIp, {
        shouldTouch: true,
        shouldValidate: true,
      })

      // Also revalidate ip address max field
      void trigger('ipMax')
    }
  }, [clientIp, setValue, trigger])

  const setIpType = useCallback(
    (type: IpAllowListingIpAddressType) => {
      setValue('ipType', type, {
        shouldTouch: true,
        shouldValidate: true,
      })
    },
    [setValue],
  )

  const revalidateField = useCallback(
    (field: FieldPath<IpAddressCreateFormState>) => () => {
      if (ipType === IpAllowListingIpAddressType.range) {
        void trigger(field)
      }
    },
    [ipType, trigger],
  )

  const handleSubmitClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        track(SegmentEvents.ButtonClicked, {
          props: {
            button_label: 'Add to allowlist',
          },
          event: e,
        })
      },
      [track],
    )

  const handleAddRangeClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        track(SegmentEvents.ButtonClicked, {
          props: {
            button_label: 'Add range',
          },
          event: e,
        })

        void setIpType(IpAllowListingIpAddressType.range)

        // Revalidate fields
        void trigger('ipMax')
        void trigger('ipMin')
      },
      [track, setIpType, trigger],
    )

  const handleRemoveRange = useCallback(() => {
    void setIpType(IpAllowListingIpAddressType.single)

    // Revalidate ip address min field
    void trigger('ipMin')
  }, [setIpType, trigger])

  return (
    <Form
      className="flex h-full flex-col justify-between gap-y-8 font-circular"
      onSubmit={onSubmit}
    >
      <div>
        <p className="mb-7 text-sm leading-5 text-black-400 font-circular-regular">
          {t('ipAllowlisting.createIpAddress.description')}
        </p>

        <div className="flex w-full content-start gap-x-2.5">
          <SkeletonBox className="h-16 w-72" loading={loading}>
            <SecurityForms.Input.IpAddressMin
              className="mt-0.5 w-full"
              data-testid="ip-address-min"
              ipType={ipType}
              onChange={revalidateField('ipMax')}
              onUseMyIp={setIpMin}
              showUseMyIp={Boolean(clientIp)}
            />
          </SkeletonBox>

          {ipType === IpAllowListingIpAddressType.single ? (
            <SkeletonBox className="-ml-4 h-16 w-36" loading={loading}>
              <div className="min-h-16 w-full">
                <Button
                  className="mt-6"
                  onClick={handleAddRangeClick}
                  variant="text"
                >
                  <Icon className="mr-2.5" name="PlusCircleOutline" />
                  {t('ipAllowlisting.createIpAddress.addRange')}
                </Button>
              </div>
            </SkeletonBox>
          ) : (
            <>
              <div className="mt-0.5 min-h-20 pt-7">-</div>
              <div className="flex min-h-16 w-full gap-x-2.5">
                <SecurityForms.Input.IpAddressMax
                  className="w-full"
                  data-testid="ip-address-max"
                  // Hide range error on max ip field will appear on min ip field
                  hideError={errors.ipMax?.type === 'ipAddressRange'}
                  onChange={revalidateField('ipMin')}
                  onRemoveRange={handleRemoveRange}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div>
        <Modal.Footer variant="center">
          <Form.SubmitButton
            className="w-64"
            onClick={handleSubmitClick}
            variant="secondary"
          >
            {t('ipAllowlisting.createIpAddress.addToAllowlist')}
          </Form.SubmitButton>
        </Modal.Footer>
        <p className="mt-2 text-center text-sm leading-5 text-black-400 font-circular-regular">
          {t(
            `ipAllowlisting.createIpAddress.warning.${
              isSettingEnabled ? 'enabled' : 'disabled'
            }`,
          )}
        </p>
      </div>
    </Form>
  )
}

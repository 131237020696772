import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Account deposit.
 * Which users are allowed to deposit from bank OR blockchain wallet?
 */
export const ACCOUNT_DEPOSIT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.WALLETS_ROLE,
    Role.WIRES_ROLE,
    Role.WIRES_DEPOSIT_ROLE,
    Role.ONCHAIN_DEPOSIT_ROLE,
  ],
}

import { useMemo } from 'react'

import { useCreateVault } from '../../../../hooks/create'

export const usePolicyLimitsValidate = () => {
  const [{ policyLimits, touched }] = useCreateVault()

  const isValid = useMemo(() => {
    if (!policyLimits) {
      return false
    }

    const { maxAmount, maxTransactions } = policyLimits

    const amountValid = Boolean(maxAmount && parseFloat(maxAmount) > 0)
    const transactionsValid = Boolean(
      maxTransactions && parseFloat(maxTransactions) > 0,
    )

    return amountValid && transactionsValid
  }, [policyLimits])

  const isTouched = touched?.policyLimits

  const isEmpty =
    !policyLimits || (!policyLimits.maxAmount && !policyLimits.maxTransactions)

  return {
    isValid,
    isEmpty,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

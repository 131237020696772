import { Button } from '@circlefin/components'
import { signIn } from '@services/sections/lib/home'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { ResetMfaRequestForm } from './ResetMfaRequest.Form'

export const ResetMfaRequest: React.FC = () => {
  const { t } = useTranslation('mfa')

  return (
    <div className="flex max-w-lg flex-col">
      <h2 className="text-center text-neutral-strong type-h-page-sm">{t`reset.request.title`}</h2>
      <h3 className="mt-4 text-center text-neutral type-body-base">{t`reset.request.description`}</h3>

      <ResetMfaRequestForm />

      <Link href={signIn.route} legacyBehavior passHref>
        <Button.Link
          className="mx-auto mt-8 block uppercase"
          size="sm"
          variant="text"
        >
          {t`reset.request.back`}
        </Button.Link>
      </Link>
    </div>
  )
}

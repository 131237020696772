import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultWalletState, TokenActivationState } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export interface ActivateProps extends Pick<ButtonProps, 'disabled'> {
  /**
   * Wallet that needs to be activated.
   */
  walletId: string
  /**
   * Id of the parent wallet.
   */
  parentWalletId: string
  /**
   * Blockchain the activation will take place on.
   */
  blockchain: string
  /**
   * State of the wallet.
   */
  walletState: VaultWalletState
  /**
   * The current token activation state.
   */
  tokenActivationState: TokenActivationState
}

export const Activate: React.FC<ActivateProps> = ({
  walletId,
  parentWalletId,
  walletState,
  tokenActivationState,
}) => {
  const { t } = useTranslation('vaults')
  const { vaultMetadata } = useVaultPermission()
  const { track } = useSegment()
  const { router } = useModal()

  const handleConfirmWalletOptIn = useCallback(() => {
    track(SegmentEvents.ActivateVaultWalletClicked)

    router.push({
      pathname: routes.vault.confirmWalletActivationFee,
      query: {
        vaultId: vaultMetadata?.id,
        parentWalletId,
        walletId,
      },
    })
  }, [router, walletId, parentWalletId, vaultMetadata?.id, track])

  // Do not return anything if button was not wrapped with a VaultPermissionProvider.
  if (!vaultMetadata) return null

  /**
   * Do not return anything if the current user does not have the operator role for the vault
   * the wallet is in.
   */
  if (!vaultMetadata.isOperator) {
    return null
  }

  // Do not return anything if the wallet is not activated.
  if (walletState !== VaultWalletState.ACTIVE) {
    return null
  }

  /**
   * Do not return anything if the wallet does not need to be activated
   * or an activation is already pending.
   */
  if (
    [
      TokenActivationState.NOT_APPLICABLE,
      TokenActivationState.PENDING_ACTIVATION,
    ].includes(tokenActivationState)
  ) {
    return null
  }

  return (
    <Button
      onClick={handleConfirmWalletOptIn}
      variant="primary"
    >{t`buttons.wallet.activate`}</Button>
  )
}

import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface LinkAccountProps {
  /**
   * Custom style.
   */
  className?: string
}

export const LinkAccount: React.FC<LinkAccountProps> = ({ className }) => {
  const { t } = useTranslation('custody/common')

  return (
    <FixedBanner className={className} visible>
      <FixedBanner.Title>
        {t('firstTimeUserExperience.linkBank.title')}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t(`firstTimeUserExperience.linkBank.description`)}</p>
        <ModalLink pathname={routes.bankAccount.link.location}>
          <TextLink size="sm" variant="primary">
            {t('firstTimeUserExperience.linkBank.action')}
          </TextLink>
        </ModalLink>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import { LottieIcon } from '@circlefin/components'

interface LoadingSpinnerProps {
  width?: number
  height?: number
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  width = 125,
  height = 125,
}) => <LottieIcon height={height} name="LoadingSpinner" width={width} loop />

import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { Payment, PaymentIntent } from '@features/platform/modals'
import { platform } from '@services/sections/modal/platform/platform'

/**
 * Platform Router.
 */
export const PlatformRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<Payment.Refund />} path={platform.payment.refund} />
      <ModalRoute content={<Payment.Cancel />} path={platform.payment.cancel} />
      <ModalRoute content={<Payment.Block />} path={platform.payment.block} />
      {/* Payment Intent Refund routes */}
      <ModalSwitch path={platform.paymentIntent.switch}>
        <PaymentIntent.RefundProvider>
          <ModalRoute
            content={<PaymentIntent.Details />}
            path={platform.paymentIntent.refund}
          />
          <ModalRoute
            content={<PaymentIntent.Review />}
            path={platform.paymentIntent.review}
          />
        </PaymentIntent.RefundProvider>
      </ModalSwitch>
    </>
  )
}

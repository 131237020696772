import {
  CUSTODY_POLICY_SECTION,
  PROTECTED,
  STABLECOIN_SECTION,
  VAULT_QUERY,
} from '@services/permissions'

import { paymentsSections } from './payments'

import type { Section } from '../types'

export const circleWalletPolicySection: Section = {
  label: 'layout:page.circleWallet.policy.title',
  route: '/custody/circle-wallet/[currency]/policy',
  permissions: CUSTODY_POLICY_SECTION,
  segment: {
    page: 'Circle Wallet Policy Page',
  },
}

export const circleWalletPendingTransactionApprovalDetailSection: Section = {
  label: 'layout:page.circleWallet.pendingTransactionApprovalDetail.title',
  route: '/custody/circle-wallet/[currency]/transaction/[id]',
  permissions: CUSTODY_POLICY_SECTION,
  segment: {
    page: 'Circle Wallet Transaction Approval Detail Page',
  },
}

export const circleWalletDetailSection: Section = {
  label: 'common:activity',
  route: '/custody/circle-wallet/[currency]',
  permissions: PROTECTED,
  segment: {
    page: 'Circle Wallet Detail Page',
  },
}

export const stableCoinExpressSection: Section = {
  label: 'layout:page.custody.stableCoinExpress.title',
  route: '/custody/stablecoin-express',
  permissions: STABLECOIN_SECTION,
  segment: {
    page: 'Custody Stablecoin Express Page',
  },
}

export const vaultTransactionDetailsSection: Section = {
  label: 'layout:page.custody.transaction.title',
  route: '/custody/vaults/transaction/[id]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Transaction Detail Page',
  },
}

export const vaultActivityDetailsSection: Section = {
  label: 'layout:page.vault.activity.title',
  route: '/custody/vaults/[vaultId]/activities/[id]/types/[type]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Activity Detail Page',
  },
}

export const vaultDetailsSection: Section = {
  label: 'layout:page.custody.vaults.title',
  route: '/custody/vaults/[vaultId]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Detail Page',
  },
}

export const vaultPolicySection: Section = {
  label: 'layout:page.custody.vaults.policy',
  route: '/custody/vaults/[vaultId]/policy',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Policy Page',
  },
}

export const vaultActivitySection: Section = {
  label: 'layout:page.custody.vaults.activity',
  route: '/custody/vaults/[vaultId]/activities',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Activity Page',
  },
}

export const vaultWalletDetailsSection: Section = {
  label: 'layout:page.custody.wallet.title',
  route: '/custody/vaults/[vaultId]/wallets/[walletId]',
  permissions: VAULT_QUERY,
  segment: {
    page: 'Vault Wallet Detail Page',
  },
}

export const custodySection: Section = {
  icon: 'HomeOutline',
  label: 'layout:page.custody.title',
  route: '/',
  permissions: PROTECTED,
  pages: [
    stableCoinExpressSection,
    circleWalletPendingTransactionApprovalDetailSection,
    circleWalletDetailSection,
    circleWalletPolicySection,
    vaultTransactionDetailsSection,
    vaultActivityDetailsSection,
    vaultWalletDetailsSection,
    vaultDetailsSection,
    vaultPolicySection,
    vaultActivitySection,
    ...paymentsSections,
  ],
  segment: {
    page: 'home',
  },
}

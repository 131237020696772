import { Card } from '@circlefin/components'
import classNames from 'classnames'

import { LevelForm } from './LevelForm/LevelForm'
import { LevelSummary } from './LevelSummary/LevelSummary'
import { LevelTitle } from './LevelTitle/LevelTitle'

import type { VaultPolicyApprovalLevel } from '@shared/graphql'

export interface LevelProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Level index.
   */
  levelIndex: number
  /**
   * Is the level being edited.
   */
  isEdit?: boolean
  /**
   * Is edit/delete disabled for this level.
   */
  disabled?: boolean
  /**
   * Level information.
   */
  level?: VaultPolicyApprovalLevel
  /**
   * The threshold of the previous level (if existent).
   */
  previousLevelThreshold?: string
  /**
   * List of all approvers.
   */
  approvers: VaultPolicyApprovalLevel['approvers']
  /**
   * OnChange handler.
   */
  onChange: (values: VaultPolicyApprovalLevel) => void
  /**
   * OnSubmit handler.
   */
  onSubmit: (values: VaultPolicyApprovalLevel) => void
  /**
   * On edit click.
   */
  onEdit?: () => void
  /**
   * On delete click.
   */
  onDelete?: () => void
}

export const Level: React.FC<LevelProps> = ({
  className,
  levelIndex,
  disabled,
  isEdit,
  level,
  previousLevelThreshold,
  approvers,
  onChange,
  onSubmit,
  onEdit,
  onDelete,
}) => {
  return (
    <Card className={classNames('w-full', className)}>
      <Card.Content>
        <LevelTitle
          disabled={disabled}
          isEdit={isEdit}
          levelIndex={levelIndex}
          onDelete={onDelete}
          onEdit={onEdit}
        />

        {isEdit ? (
          <LevelForm
            approvers={approvers}
            level={level}
            onChange={onChange}
            onSubmit={onSubmit}
            previousLevelThreshold={previousLevelThreshold}
          />
        ) : (
          <LevelSummary {...level} />
        )}
      </Card.Content>
    </Card>
  )
}

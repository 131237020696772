import { useMemo } from 'react'

import { VaultUserPolicyRoleEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'

export interface VaultUserPolicyRoleTextProps {
  /**
   * Should key words in the copy be bolded.
   */
  bolded?: boolean
  /**
   * Selected Permissions.
   */
  permissions: VaultUserPolicyRoleEnum[]
}

export const VaultUserPolicyRoleText: React.FC<VaultUserPolicyRoleTextProps> =
  ({ bolded = false, permissions }) => {
    const i18nKey = useMemo(() => {
      if (
        permissions.includes(VaultUserPolicyRoleEnum.APPROVER) &&
        permissions.includes(VaultUserPolicyRoleEnum.OPERATOR)
      ) {
        return 'vaults:vault.policy.summary.permissions.options.initiateApprove'
      }

      if (permissions.includes(VaultUserPolicyRoleEnum.APPROVER)) {
        return 'vaults:vault.policy.summary.permissions.options.approve'
      }

      if (permissions.includes(VaultUserPolicyRoleEnum.OPERATOR)) {
        return 'vaults:vault.policy.summary.permissions.options.initiate'
      }

      if (
        permissions.includes(VaultUserPolicyRoleEnum.WC_APPROVER) &&
        permissions.includes(VaultUserPolicyRoleEnum.WC_OPERATOR)
      ) {
        return 'vaults:vault.policy.summary.contractInteractions.options.initiateApprove'
      }

      if (permissions.includes(VaultUserPolicyRoleEnum.WC_APPROVER)) {
        return 'vaults:vault.policy.summary.contractInteractions.options.approve'
      }

      if (permissions.includes(VaultUserPolicyRoleEnum.WC_OPERATOR)) {
        return 'vaults:vault.policy.summary.contractInteractions.options.initiate'
      }

      return 'vaults:vault.policy.summary.permissions.options.viewOnly'
    }, [permissions])

    return (
      <Trans
        components={{
          b: bolded ? <b /> : <span />,
        }}
        i18nKey={i18nKey}
      />
    )
  }

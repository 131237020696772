import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import useTranslation from 'next-translate/useTranslation'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals/settings/security')
  const modal = useModal()

  const closeModal = useCallback(() => modal.close(), [modal])

  const handleClick = useCallback(() => {
    closeModal()
  }, [closeModal])

  return (
    <>
      <Modal.Header title={t`adminApprovals.success.title`} variant="success" />
      <Modal.Body className="pt-2" variant="center">
        {t`adminApprovals.success.body`}
      </Modal.Body>
      <Modal.Footer variant="stretch">
        <Button onClick={handleClick} variant="primary">{t`common:ok`}</Button>
      </Modal.Footer>
    </>
  )
}

import { useCallback, useMemo } from 'react'

import { Button } from '@circlefin/components'
import { y, useForm } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { BankAccountForms } from '@features/bank-account/forms'
import { BlockchainForms } from '@features/blockchain/forms'
import { LocalsForms } from '@features/locales/forms'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { USDC_WALLET_QUERY, EURC_WALLET_QUERY } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import { BlockchainActivityType, Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../AddRoute.Context'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export interface WithdrawalProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: WithdrawalFormValues) => void
  /**
   * If travel rule is required.
   */
  travelRuleRequired: boolean
  /**
   * If the component is loading.
   */
  loading: boolean
}

const schema = y.object({
  /**
   * Bank account.
   */
  bankAccount: BankAccountForms.Dropdown.BankAccountsSchema.required(),
  /**
   * Blockchain.
   */
  blockchain: BlockchainForms.Dropdown.BlockchainSchema.required(),
  /**
   * Currency.
   */
  currency: LocalsForms.Dropdown.CurrencySchema.required(),
})

export type WithdrawalFormValues = y.InferType<typeof schema>

export const WithdrawalForm: React.FC<WithdrawalProps> = ({
  onSubmit,
  travelRuleRequired,
  loading,
}) => {
  const { t } = useTranslation('express/modal')
  const { router } = useModal()
  const [data, { setWithdrawal }] = useAddRoute()

  const [, { isAuthorized: isUSDCAuthorized }] =
    usePermission(USDC_WALLET_QUERY)

  const [, { isAuthorized: isEURCAuthorized }] =
    usePermission(EURC_WALLET_QUERY)

  const [{ currency }, { setCurrency }] = useCurrentCurrency({
    initialCurrency: Currency.USD,
  })

  const [Form, { resetField }] = useForm({
    schema,
    defaultValues: {
      currency: isUSDCAuthorized ? Currency.USD : Currency.EUR,
      bankAccount: undefined,
      blockchain: undefined,
      ...data,
    },
  })

  const onFormSubmit = useCallback(
    (values: WithdrawalFormValues) => {
      // Set Withdrawal Data.
      setWithdrawal({
        source: values.blockchain,
        destination: values.bankAccount,
        currency: values.currency,
      })

      // On Form Submit (for tests)
      onSubmit?.(values)
    },
    [onSubmit, setWithdrawal],
  )

  const onCurrencyChange = useCallback(
    (newVal: SelectListItem<Currency | undefined>) => {
      const currencyValue = newVal.value ?? Currency.USD
      setCurrency({ currency: currencyValue })

      // We would like to reset the below fields on currency change
      // as they changed based on the currency.
      resetField('bankAccount')
      resetField('blockchain')
    },
    [setCurrency, resetField],
  )

  const goToLinkBank = useCallback(() => {
    router.push(routes.bankAccount.link.location)
  }, [router])

  const currencyFilter = useMemo(() => {
    return travelRuleRequired
      ? [Currency.USD]
      : [
          ...(isUSDCAuthorized ? [Currency.USD] : []),
          ...(isEURCAuthorized ? [Currency.EUR] : []),
        ]
  }, [travelRuleRequired, isEURCAuthorized, isUSDCAuthorized])

  return (
    <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
      {/* Currency */}
      <LocalsForms.Dropdown.Currency
        data-testid="dropdown-currency"
        itemsFilter={currencyFilter}
        label={t`addRoute.shared.currency`}
        onChange={onCurrencyChange}
        variant="crypto"
      />

      {/* Wallet Source */}
      <BlockchainForms.Dropdown.Blockchains
        activityType={BlockchainActivityType.ALL}
        className="w-full"
        currency={currency}
        data-testid="dropdown-source"
        label={t`addRoute.withdrawal.source`}
      />

      {/* Bank Destination */}
      <span>
        <BankAccountForms.Dropdown.BankAccounts
          data-testid="dropdown-destination"
          label={t`addRoute.withdrawal.destination`}
          maxMenuItems={6}
          name="bankAccount"
        />

        {/* Link Bank Account */}
        <div className="mt-1 flex w-full flex-row items-center justify-end text-sm leading-5 font-circular-regular">
          <Button onClick={goToLinkBank} size="sm" variant="text">
            {t`addRoute.shared.link.bank`}
          </Button>
        </div>
      </span>

      {/* Continue */}
      <Center className="pt-6" variant="horizontal">
        <Form.SubmitButton
          className="mt-6 w-64"
          loading={loading}
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Center>
    </Form>
  )
}

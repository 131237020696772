import classNames from 'classnames'

export interface BodyProps {
  /**
   * Primary text.
   */
  title: string
  /**
   * Secondary text.
   */
  description: string
  /**
   * Custom style.
   */
  className?: string
}

export const Body: React.FC<BodyProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <div className={classNames('flex flex-col gap-4', className)}>
      <h2 className="text-2xl text-black-700 font-circular-bold">{title}</h2>
      <p>{description}</p>
    </div>
  )
}

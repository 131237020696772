import { DescriptionList } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CUSTODY_POLICY_SECTION } from '@services/permissions'
import { TypeGuards } from '@services/type-guards'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransactionDetail } from '../TransactionDetail/TransactionDetail'
import { WalletApprovalTransactionDetailApprovalSummary } from '../WalletApprovalTransactionDetailApprovalSummary/WalletApprovalTransactionDetailApprovalSummary'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type { XpayPaymentDetail as XpayPaymentDetailType } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface XpayPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  xpayPaymentDetail?: XpayPaymentDetailType
}

export const XpayPaymentDetail: React.FC<XpayPaymentDetailProps> = ({
  xpayPaymentDetail,
  error,
}) => {
  const { t } = useTranslation('payments')

  const isReceive = TypeGuards.Payments.isXpayReceiveType(xpayPaymentDetail)
  const [, { isAuthorized }] = usePermission(CUSTODY_POLICY_SECTION)

  const { data: { walletApprovalTransactionDetailByJobId } = {} } =
    useWalletApprovalTransactionDetailByJobIdQuery({
      variables: {
        jobId: xpayPaymentDetail?.payment.id ?? '',
      },
      skip: !xpayPaymentDetail?.payment.id || isReceive || !isAuthorized,
    })

  const bankDescriptionLabel = isReceive
    ? t('transactionDetail.fundingSource')
    : t('transactionDetail.destination')

  const bankAccount = isReceive
    ? xpayPaymentDetail?.source
    : xpayPaymentDetail?.destination

  return (
    <TransactionDetail error={error} transaction={xpayPaymentDetail?.payment}>
      {walletApprovalTransactionDetailByJobId && (
        <WalletApprovalTransactionDetailApprovalSummary
          {...walletApprovalTransactionDetailByJobId}
        />
      )}

      <Label className="w-64">{bankDescriptionLabel}</Label>
      <Description>{bankAccount?.nickname}</Description>

      {isReceive && (
        <>
          <Label className="w-64">{t('transactionDetail.referenceID')}</Label>
          <Description>{bankAccount?.trackingRef}</Description>
        </>
      )}
    </TransactionDetail>
  )
}

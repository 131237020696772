import { SectionNav, Footer } from '@containers/layout'
import { Main } from '@shared/components/layout'
import classNames from 'classnames'

import { BaseLayout } from '../core'

import type { AppLayoutType } from '../core'

export interface PageLayoutProps {
  /**
   * Dense page?
   */
  dense?: boolean
  /**
   * Content Position.
   */
  position?: 'center' | 'top'
}

export const PageLayout: AppLayoutType<PageLayoutProps> = ({
  dense = false,
  position = 'top',
  children,
}) => (
  <BaseLayout>
    <SectionNav />
    <Main className="ml-64 flex flex-col bg-surface-background">
      <div
        className={classNames('grid flex-1 gap-y-12', {
          'pt-12': !dense,
          'content-start': position === 'top',
        })}
      >
        {children}
      </div>
      <Footer className="mt-12" />
    </Main>
  </BaseLayout>
)

import { AccessPermissions } from '@circlefin/permissions'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Payment Intents - Crypto Payments Section Permissions,
 * Will make the feature available only in the environments with the environment variable CRYPTO_PAYMENTS=true.
 * For another env where it's not enabled, we only want to allow to a test user.
 */
export const CRYPTO_PAYMENTS: PermissionSet = {
  access: AccessPermissions.protected,
}

import { STABLECOIN_EXPRESS_QUERY } from '@services/permissions'

import type { Section } from '../types'

export const expressSection: Section = {
  label: 'layout:page.express.title',
  route: '/express',
  permissions: STABLECOIN_EXPRESS_QUERY,
  icon: 'SwitchHorizontalOutline',
  segment: {
    page: 'express',
  },
}

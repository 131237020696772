import { useCallback, useState } from 'react'

import { Button, Icon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../containers/Create'
import { useApprovalWorkflowValidate } from '../../../containers/CreateVault'
import { useCreateVault } from '../../../hooks/create'
import * as VaultLayout from '../../../layout'

import { AddApprovalUsersCta } from './AddApprovalUsersCta/AddApprovalUsersCta'
import { HelpSection } from './HelpSection/HelpSection'
import { Level } from './Level/Level'
import { UnassignedApproversWarning } from './UnassignedApproversWarning/UnassignedApproversWarning'

import type { VaultPolicyApprovalLevel } from '@shared/graphql'

export const Approval: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  const { router } = useModal()

  const [
    {
      approvalWorkflow = [],
      approvalLevelIndexInEdit,
      isWalletConnectStepVisible,
    },
    { setApprovalWorkflow, setApprovalLevelIndexInEdit },
  ] = useCreateVault()

  const { isOptional, approvers, missingApproversInWorkflow } =
    useApprovalWorkflowValidate()
  const [showUnassignedApproversWarning, setUnassignedApproversWarning] =
    useState(false)

  const hasLevelInEdit = approvalLevelIndexInEdit !== undefined

  const goToNextStep = useCallback(() => {
    if (isWalletConnectStepVisible) {
      void router.push(routes.vault.createVault.walletConnect)
      return
    }

    void router.push(routes.vault.createVault.review)
  }, [isWalletConnectStepVisible, router])

  const onContinue = useCallback(() => {
    if (missingApproversInWorkflow.size > 0) {
      setUnassignedApproversWarning(true)
      return
    }

    goToNextStep()
  }, [goToNextStep, missingApproversInWorkflow.size])

  const onCloseUnassignedApproversWarning = useCallback(() => {
    setUnassignedApproversWarning(false)
  }, [])

  const onLevelUpdate = useCallback(
    (levelIndex: number) => (updatedState: VaultPolicyApprovalLevel) => {
      if (approvalWorkflow[levelIndex] === undefined) {
        return
      }

      const updated = [...approvalWorkflow]
      updated[levelIndex] = updatedState

      setApprovalWorkflow(updated)
    },
    [approvalWorkflow, setApprovalWorkflow],
  )

  const onLevelSubmit = useCallback(
    (levelIndex: number) => (updatedState: VaultPolicyApprovalLevel) => {
      onLevelUpdate(levelIndex)(updatedState)
      setApprovalLevelIndexInEdit(undefined)
    },
    [onLevelUpdate, setApprovalLevelIndexInEdit],
  )

  const onLevelAdd = useCallback(() => {
    const updatedLevels: VaultPolicyApprovalLevel[] = [
      ...approvalWorkflow,
      {
        transferAmount: '',
        approvers: [],
        minNeedConfirmed: '',
      },
    ]

    setApprovalWorkflow(updatedLevels)
    setApprovalLevelIndexInEdit(updatedLevels.length - 1)
  }, [approvalWorkflow, setApprovalWorkflow, setApprovalLevelIndexInEdit])

  const onLevelEdit = useCallback(
    (levelIndex: number) => () => {
      if (approvalWorkflow[levelIndex] === undefined) {
        return
      }

      setApprovalLevelIndexInEdit(levelIndex)
    },
    [approvalWorkflow, setApprovalLevelIndexInEdit],
  )

  const onLevelDelete = useCallback(
    (levelIndex: number) => () => {
      const updated = [...approvalWorkflow]
      updated.splice(levelIndex, 1)

      setApprovalWorkflow(updated)
      setApprovalLevelIndexInEdit(undefined)
    },
    [approvalWorkflow, setApprovalWorkflow, setApprovalLevelIndexInEdit],
  )

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.APPROVAL}
      helpTextColumn={<HelpSection />}
    >
      <UnassignedApproversWarning
        onCancel={onCloseUnassignedApproversWarning}
        onContinue={goToNextStep}
        open={showUnassignedApproversWarning}
      />
      <div className="grid max-w-176 grid-cols-1 justify-items-start">
        <p className="text-lg text-black-400"> {t('common:optional')}</p>
        <h2 className="mt-2 text-4xl text-black-600 font-circular-bold">
          {t('createVault.approval.title')}
        </h2>

        {approvers.length === 0 ? (
          <AddApprovalUsersCta className="mt-8" />
        ) : (
          <>
            {approvalWorkflow.length > 0 && (
              <div className="mt-8 grid w-full grid-cols-1 gap-y-2 pt-1">
                {approvalWorkflow.map((level, index, levels) => (
                  <Level
                    key={index}
                    approvers={approvers ?? []}
                    disabled={hasLevelInEdit}
                    isEdit={index === approvalLevelIndexInEdit}
                    level={level}
                    levelIndex={index}
                    onChange={onLevelUpdate(index)}
                    onDelete={onLevelDelete(index)}
                    onEdit={onLevelEdit(index)}
                    onSubmit={onLevelSubmit(index)}
                    previousLevelThreshold={
                      index > 0 ? levels[index - 1].transferAmount : undefined
                    }
                  />
                ))}
              </div>
            )}

            <Button
              className="mt-8"
              disabled={hasLevelInEdit}
              onClick={onLevelAdd}
              size="sm"
              variant="text"
            >
              <Icon className="mr-2" name="PlusSolid" />
              {t`createVault.approval.level.add`}
            </Button>
          </>
        )}

        <div className="mt-4">
          <ModalBackButton />

          <Button
            className="w-60"
            disabled={hasLevelInEdit} // TODO: Determine when this should be disabled, we may need more validation
            onClick={onContinue}
            variant="primary"
          >
            {t(isOptional ? 'common:skip' : 'common:continue')}
          </Button>
        </div>
      </div>
    </VaultLayout.CreateVault>
  )
}

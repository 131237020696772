import { useEffect } from 'react'

import { Statement } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import useTranslation from 'next-translate/useTranslation'

import { Layout } from '../Layout/Layout'

import type { MfaSuccess } from '@shared/mfa/lib/context'

export const Success: React.FC<MfaSuccess> = ({
  message,
  description,
  duration,
}) => {
  const { t } = useTranslation('mfa')
  const { close } = useModal()

  useEffect(() => {
    if (duration === undefined || duration === 0) {
      return
    }

    const timeout = setTimeout(() => {
      close({ context: 'onConfirm' })
    }, duration)

    return () => {
      clearTimeout(timeout)
    }
  }, [duration, close])

  return (
    <Layout title={t`success`}>
      <div className="grid h-full content-center">
        <Statement
          iconName="CheckCircleSolid"
          padded={false}
          status="success"
          subtitle={description}
          title={message}
          variant="page"
        />
      </div>
    </Layout>
  )
}

import { useCallback, useState } from 'react'

import { Button, Checkbox, FeatureList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const Confirmation: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [agreement, setAgreement] = useState(false)
  const modal = useModal()

  const onContinue = useCallback(() => {
    void modal.router.push(routes.vault.resetPin.uploadBackup)
  }, [modal])

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreement(event.target.checked)
    },
    [setAgreement],
  )

  return (
    <FullScreen totalSteps={5}>
      <Center className="m-auto justify-items-center" variant="horizontal">
        <h2 className="text-4xl text-black-600 font-circular-bold">{t`resetPin.title`}</h2>

        <FeatureList className="mt-14 w-120">
          <FeatureList.Item
            heading={t`resetPin.confirm.prompt1.heading`}
            icon="LockClosedSolid"
          >
            {t`resetPin.confirm.prompt1.message`}
          </FeatureList.Item>
          <FeatureList.Item
            heading={t`resetPin.confirm.prompt2.heading`}
            icon="ShieldExclamationOutline"
          >
            {t`resetPin.confirm.prompt2.message`}
          </FeatureList.Item>
        </FeatureList>

        <Checkbox
          checked={agreement}
          className="mt-6 text-lg leading-6 text-black-400 font-circular-regular"
          label={t`resetPin.confirm.checkbox`}
          onChange={onCheckboxChange}
        />

        <div className="mt-14">
          <Button
            className="w-60"
            disabled={!agreement}
            onClick={onContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Button>
        </div>
      </Center>
    </FullScreen>
  )
}

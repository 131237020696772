import { usePermission } from '@circlefin/permissions'
import { BANK_ACCOUNT_MUTATION } from '@services/permissions'
import { support } from '@services/sections/lib/home'
import { useSegment } from '@services/segment'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export const SupportText: React.FC = () => {
  const { t } = useTranslation()
  const { trackLinkClick } = useSegment()
  const [{ Authorized }] = usePermission(BANK_ACCOUNT_MUTATION)

  return (
    <Authorized>
      <div
        className="text-sm leading-5 text-black-400 font-circular-regular"
        data-testid="customer-service"
      >
        <Trans
          components={{
            a: (
              <Link
                href={support.route}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
            learn: (
              <a
                href={t`settings:linkedAccounts.addRemove.link`}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
          }}
          i18nKey="settings:linkedAccounts.addRemove.message"
        />
      </div>
    </Authorized>
  )
}

import { InAppNotificationInfoFragmentDoc } from '@shared/graphql'

import type { InAppNotificationsRefs } from './mergeInAppNotifications'
import type {
  FieldMergeFunction,
  InMemoryCache,
  Reference,
  StoreObject,
} from '@apollo/client'
import type { ModifierDetails } from '@apollo/client/cache/core/types/common'
import type {
  InAppNotification,
  MutationMarkInAppNotificationsAsReadArgs,
} from '@shared/graphql'

/**
 * Merge mark in app notifications as read.
 */
export const mergeMarkInAppNotificationsAsRead: FieldMergeFunction = (
  _,
  __,
  { args, cache },
): void => {
  if (!args) {
    return
  }

  const { notificationIds } = args as MutationMarkInAppNotificationsAsReadArgs

  cache.modify({
    fields: {
      /**
       * Update unreadInAppNotifications cached object.
       */
      unreadInAppNotifications(
        inAppNotificationsRefs: Reference | InAppNotificationsRefs,
        bag,
      ) {
        return getUpdatedListRefsForUnread(
          inAppNotificationsRefs,
          notificationIds,
          cache,
          bag,
        )
      },
      /**
       * Update allInAppNotifications cached object.
       */
      allInAppNotifications(
        inAppNotificationsRefs: Reference | InAppNotificationsRefs,
        bag,
      ) {
        return getUpdatedListRefsForShowAll(
          inAppNotificationsRefs,
          notificationIds,
          cache,
          bag,
        )
      },
      /**
       * Update the existing count to reflect the marked notifications.
       */
      unreadInAppNotificationsCount(existingCount: number) {
        if (existingCount == null) {
          return existingCount
        }

        const res = existingCount - notificationIds.length
        return res >= 0 ? res : 0
      },
    },
  })
}

/**
 * For each notification id, mark the cached the notification as read, and omit it from the unread list.
 */
const getUpdatedListRefsForUnread = (
  inAppNotificationsRefs: Reference | InAppNotificationsRefs,
  notificationIds: string[],
  cache: InMemoryCache,
  { readField }: ModifierDetails,
) => {
  if (!isInAppNotificationsRefs(inAppNotificationsRefs)) {
    // return without modification
    return inAppNotificationsRefs
  }

  const updatedListRef: InAppNotificationsRefs['list'] = []

  for (const ref of inAppNotificationsRefs.list) {
    const id = readField('id', ref)

    if (typeof id !== 'string') {
      continue
    }

    if (!notificationIds.includes(id)) {
      updatedListRef.push(ref)
      continue
    }

    cache.updateFragment<InAppNotification>(
      {
        id: cache.identify(ref),
        fragmentName: 'InAppNotificationInfo',
        fragment: InAppNotificationInfoFragmentDoc,
      },
      (data) => {
        return data ? { ...data, isRead: true } : null
      },
    )
  }

  return {
    ...inAppNotificationsRefs,
    list: updatedListRef,
  }
}

/**
 * For notification id, mark each cached notification as read.
 */
const getUpdatedListRefsForShowAll = (
  inAppNotificationsRefs: Reference | InAppNotificationsRefs,
  notificationIds: string[],
  cache: InMemoryCache,
  { readField, toReference }: ModifierDetails,
) => {
  if (!isInAppNotificationsRefs(inAppNotificationsRefs)) {
    // return without modification
    return inAppNotificationsRefs
  }

  const updatedListRef: InAppNotificationsRefs['list'] = []

  for (const ref of inAppNotificationsRefs.list) {
    const id = readField('id', ref)

    if (typeof id !== 'string') {
      continue
    }

    if (!notificationIds.includes(id)) {
      updatedListRef.push(ref)
      continue
    }

    const updated = cache.updateFragment<InAppNotification>(
      {
        id: cache.identify(ref),
        fragmentName: 'InAppNotificationInfo',
        fragment: InAppNotificationInfoFragmentDoc,
      },
      (data) => {
        return data ? { ...data, isRead: true } : null
      },
    )

    if (updated) {
      const ref = toReference(updated as unknown as StoreObject)

      if (ref) {
        updatedListRef.push(ref)
      }
    }
  }

  return {
    ...inAppNotificationsRefs,
    list: updatedListRef,
  }
}

/**
 * InAppNotificationsRefs type guard.
 */
const isInAppNotificationsRefs = (
  ref: Reference | InAppNotificationsRefs,
): ref is InAppNotificationsRefs => 'list' in ref

import { DEVELOPER_MUTATION, DEVELOPER_QUERY } from '@services/permissions'

import type { Section } from '../types'

export const apiKeyDetails: Section = {
  label: 'layout:page.developer.api-key-details',
  route: '/developer/api-key/[id]',
  permissions: DEVELOPER_MUTATION,
  segment: {
    page: 'developer',
  },
}

export const apiKeyEdit: Section = {
  label: 'layout:page.developer.api-key-edit',
  route: '/developer/api-key/[id]/edit',
  permissions: DEVELOPER_MUTATION,
  segment: {
    page: 'developer',
  },
}

export const createApiKey: Section = {
  label: 'layout:page.developer.create-api-key',
  route: '/developer/api-key/create',
  permissions: DEVELOPER_MUTATION,
  segment: {
    page: 'developer',
  },
}

export const apiKeys: Section = {
  label: 'layout:page.developer.api-keys',
  route: '/developer/api-key',
  permissions: DEVELOPER_QUERY,
  pages: [apiKeyDetails, createApiKey, apiKeyEdit],
  segment: {
    page: 'developer',
  },
}

export const subscriptionsManagement: Section = {
  label: 'layout:page.developer.subscriptions',
  route: '/developer/subscriptions',
  icon: 'SpeakerphoneSolid',
  permissions: DEVELOPER_QUERY,
  segment: {
    page: 'developer',
  },
}

export const apiLogs: Section = {
  label: 'layout:page.developer.api-logs',
  route: '/developer/logs',
  icon: 'MenuAlt2Outline',
  permissions: DEVELOPER_QUERY,
  segment: {
    page: 'developer',
  },
}

export const developerSection: Section = {
  icon: 'CodeOutline',
  label: 'layout:page.developer.title',
  route: '/developer/api-key',
  permissions: DEVELOPER_QUERY,
  pages: [
    apiLogs,
    subscriptionsManagement,
    apiKeys,
    createApiKey,
    apiKeyDetails,
    apiKeyEdit,
  ],
  segment: {
    page: 'developer',
  },
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export const AddWallet: React.FC = () => {
  const { t } = useTranslation('vaults')
  const { track } = useSegment()
  const { router } = useModal()

  const { vaultMetadata } = useVaultPermission()

  const handleClick = useCallback(() => {
    /**
     * Even though this should never happen,
     * we will just return to satisfy typescript.
     */
    if (!vaultMetadata?.id) return

    track(SegmentEvents.AddWalletButtonInVaultDetailClicked)
    void router.push({
      pathname: routes.vault.createVaultWallet.selectAsset,
      query: {
        vaultId: vaultMetadata.id,
      },
    })
  }, [vaultMetadata?.id, router, track])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata || !vaultMetadata.isCreator) return null

  return (
    <Button
      disabled={
        /**
         * Do not allow adding wallets, whenever the vault is currently in the approval process.
         */
        [
          VaultStateEnum.PENDING_APPROVAL,
          VaultStateEnum.CHANGES_PENDING_APPROVAL,
        ].includes(vaultMetadata.state)
      }
      onClick={handleClick}
      variant="secondary"
    >{t`buttons.vault.addWallet`}</Button>
  )
}

import { useCallback, useState } from 'react'

import { Heading, Modal } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { SetupTotpCodeInput } from './SetupTotp.CodeInput'
import { SetupTotpSecret } from './SetupTotp.Secret'

export interface SetupTotpProps {
  /**
   * Callback after successfully activating TOTP MFA.
   */
  onActivation?: () => void | Promise<void>
}

export const SetupTotp: React.FC<SetupTotpProps> = ({ onActivation }) => {
  const { t } = useTranslation('mfa')
  const [factorId, setFactorId] = useState<string>()

  const handleSetFactorId = useCallback(
    (factorId: string) => setFactorId(factorId),
    [],
  )

  return (
    <>
      <Modal.Header title={t`setupTotp.title`} />
      <Modal.Body>
        <div className="flex max-w-2xl flex-col items-start gap-y-6">
          {/* Step 1 */}
          <Heading variant="title/sm">
            <Heading.Header>{t`setupTotp.steps.step-1.heading`}</Heading.Header>
            <Heading.Intro>{t`setupTotp.steps.step-1.subheading`}</Heading.Intro>
          </Heading>

          {/* Step 2 */}
          <div>
            <Heading className="mb-4" variant="title/sm">
              <Heading.Header>{t`setupTotp.steps.step-2.heading`}</Heading.Header>
              <Heading.Intro>{t`setupTotp.steps.step-2.subheading`}</Heading.Intro>
            </Heading>
            <SetupTotpSecret setFactorId={handleSetFactorId} />
          </div>

          {/* Step 3 */}
          <div>
            <Heading className="mb-4" variant="title/sm">
              <Heading.Header>{t`setupTotp.steps.step-3.heading`}</Heading.Header>
              <Heading.Intro>{t`setupTotp.steps.step-3.subheading`}</Heading.Intro>
            </Heading>
            <SetupTotpCodeInput
              factorId={factorId}
              onActivation={onActivation}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

import { MultiStep } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import { InvoiceDisplay } from './InvoiceDisplay/InvoiceDisplay'

export interface ConfirmPaymentProps {
  /**
   * Invoice Id.
   */
  invoiceId?: string
}

export const ConfirmPayment: React.FC<ConfirmPaymentProps> = (props) => {
  const { t } = useTranslation('modals/settings/billing')

  return (
    <MultiStep
      subtitle={t('common:step', { start: 2, end: 2 })}
      title={t`invoice.title`}
    >
      <PropsErrorBoundary<ConfirmPaymentProps> props={props} variant="page">
        {({ invoiceId }) => <InvoiceDisplay invoiceId={invoiceId} />}
      </PropsErrorBoundary>
    </MultiStep>
  )
}

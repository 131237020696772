import { SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import useTranslation from 'next-translate/useTranslation'

import type { Currency, SenTransferInstruction } from '@shared/graphql'

export interface SenTransferInstructionGridProps {
  /**
   * Sen transfer instruction details.
   */
  senTransferInstruction?: SenTransferInstruction
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const SenTransferInstructionGrid: React.FC<SenTransferInstructionGridProps> =
  ({ senTransferInstruction, currency, loading = false }) => {
    const { t } = useTranslation('payments')

    return (
      <>
        <SkeletonBox className="block h-16" loading={loading}>
          <div data-testid="currency">
            <CopyToClipboard
              label={t(
                'depositFlow.beneficiaryBankAccountDetails.labels.currency',
              )}
              value={currency}
            />
          </div>
        </SkeletonBox>
        <SkeletonBox className="block h-16" loading={loading}>
          <div data-testid="referenceIDMemo">
            <CopyToClipboard
              label={t(
                'depositFlow.beneficiaryBankAccountDetails.labels.referenceIDMemo',
              )}
              value={senTransferInstruction?.trackingRef ?? ''}
            />
          </div>
        </SkeletonBox>
      </>
    )
  }

import { useCallback } from 'react'

import {
  Button,
  Modal,
  SkeletonBox,
  DescriptionList,
} from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { useSegment, SegmentEvents } from '@services/segment'
import { MaybeNull } from '@shared/components/common'
import {
  InvoiceDetailDocument,
  InvoicePaymentDetailDocument,
  InvoicePaymentDocument,
  useInvoiceDetailQuery,
  useInvoicePaymentMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { usePayInvoice } from '../../PayInvoice.Context'
import { PaymentDisplay } from '../PaymentDisplay/PaymentDisplay'

const { Label, Description } = DescriptionList

export interface InvoiceDisplayProps {
  /**
   * Invoice Id.
   */
  invoiceId: string
}

export const InvoiceDisplay: React.FC<InvoiceDisplayProps> = ({
  invoiceId,
}) => {
  const { t } = useTranslation('modals/settings/billing')
  const [{ paymentType, cardId }] = usePayInvoice()
  const modal = useModal()
  const { track } = useSegment()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const { data, loading } = useInvoiceDetailQuery({
    variables: {
      id: invoiceId,
    },
  })
  const [invoicePayment] = useInvoicePaymentMutation({
    refetchQueries: [
      InvoicePaymentDocument,
      InvoiceDetailDocument,
      InvoicePaymentDetailDocument,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      mfaSuccess({
        message: t`common:success`,
        description: t`invoice.confirmPayment.success`,
      })
    },
    onError: (error) => {
      mfaError({ error, message: t`common:generalError` })
    },
  })
  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onClick = useCallback(() => {
    track(SegmentEvents.PayInvoiceClicked)
    withMfa({
      onComplete: (code) => {
        void invoicePayment({
          variables: {
            invoicePaymentInput: {
              invoiceId,
              paymentType,
              cardId,
            },
          },
          context: context(code),
        })
      },
      onAbort: closeModal,
    })
  }, [
    cardId,
    closeModal,
    context,
    invoiceId,
    invoicePayment,
    paymentType,
    withMfa,
    track,
  ])

  return (
    <>
      <div className="flex size-full flex-col justify-start space-y-2">
        <h3 className="pb-6 text-lg leading-7 font-circular-bold">
          {t('invoice.confirmPayment.title')}
        </h3>
        <DescriptionList
          className="grid-cols-4"
          data-testid="dl"
          density="loose"
        >
          <Label className="col-span-1">
            {t('invoice.confirmPayment.label.invoice-number')}
          </Label>
          <Description className="col-span-3">
            <SkeletonBox className="h-6 w-64" loading={loading}>
              <MaybeNull>{data?.invoiceDetail.invoiceNumber}</MaybeNull>
            </SkeletonBox>
          </Description>
          <Label className="col-span-1">
            {t('invoice.confirmPayment.label.amount-due')}
          </Label>
          <Description className="col-span-3">
            <SkeletonBox className="h-6 w-64" loading={loading}>
              <MaybeNull>
                {data?.invoiceDetail.amountDue.amount}{' '}
                {data?.invoiceDetail.amountDue.currency}
              </MaybeNull>
            </SkeletonBox>
          </Description>
          <Label className="col-span-1">
            {t('invoice.confirmPayment.label.total-received')}
          </Label>
          <Description className="col-span-3">
            <SkeletonBox className="h-6 w-64" loading={loading}>
              {data?.invoiceDetail.amountPaid.amount}{' '}
              {data?.invoiceDetail.amountPaid.currency} /{' '}
              {data?.invoiceDetail.amountTotal.amount}{' '}
              {data?.invoiceDetail.amountTotal.currency}
            </SkeletonBox>
          </Description>
          <Label className="col-span-1">
            {t('invoice.confirmPayment.label.payment-method')}
          </Label>
          <Description className="col-span-3">
            <SkeletonBox className="h-6 w-64" loading={loading}>
              <PaymentDisplay cardId={cardId} variant={paymentType} />
            </SkeletonBox>
          </Description>
        </DescriptionList>
      </div>
      <Modal.Footer variant="center">
        <Button
          className="w-64"
          data-testid="button-submit"
          onClick={onClick}
          variant="secondary"
        >
          {t`common:pay-now`}
        </Button>
      </Modal.Footer>
    </>
  )
}

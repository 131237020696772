import { createContext, useCallback, useContext, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { StablecoinExpressType } from '@shared/graphql'

import type {
  BankAccount,
  Blockchain,
  BlockchainRecipientAddress,
  Currency,
} from '@shared/graphql'

export interface AddRouteContextActions {
  /**
   * Set Route Direction.
   */
  setDirection: (direction: StablecoinExpressType) => void
  /**
   * Set Deposit Data.
   */
  setDeposit: (deposit: AddRouteContextDepositData) => void
  /**
   * Set Withdrawal Data.
   */
  setWithdrawal: (withdrawal: AddRouteContextWithdrawalData) => void
}

export interface AddRouteContextSharedData {
  /**
   * Currency.
   */
  currency?: Currency
}

export interface AddRouteContextDepositData extends AddRouteContextSharedData {
  /**
   * Route Direction.
   */
  direction?: StablecoinExpressType.DEPOSIT
  /**
   * Bank Account Source.
   */
  source?: BankAccount
  /**
   * Blockchain Destination.
   */
  destination?: BlockchainRecipientAddress
}

export interface AddRouteContextWithdrawalData
  extends AddRouteContextSharedData {
  /**
   * Route Direction.
   */
  direction?: StablecoinExpressType.WITHDRAWAL
  /**
   * Blockchain Source.
   */
  source?: Blockchain
  /**
   * Bank Account Destination.
   */
  destination?: BankAccount
}

export type AddRouteContextData =
  | AddRouteContextDepositData
  | AddRouteContextWithdrawalData

const AddRouteContext = createContext<
  [AddRouteContextData, AddRouteContextActions]
>([
  {},
  {
    setDirection: () => null,
    setDeposit: () => null,
    setWithdrawal: () => null,
  },
])

export type AddRouteProviderProps = AddRouteContextData & {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Provider: React.FC<AddRouteProviderProps> = ({
  children,
  ...initialValues
}) => {
  const { router } = useModal()
  const [data, setData] = useState<AddRouteContextData>({ ...initialValues })

  // Set Direction.
  const setDirection = useCallback(
    (direction: StablecoinExpressType) => {
      setData({ direction })

      switch (direction) {
        case StablecoinExpressType.DEPOSIT:
          router.push(routes.express.addRoute.deposit)
          break

        case StablecoinExpressType.WITHDRAWAL:
          router.push(routes.express.addRoute.withdrawal)
          break
      }
    },
    [router],
  )

  // Set Deposit Data.
  const setDeposit = useCallback(
    (deposit: AddRouteContextDepositData) => {
      setData({
        direction: StablecoinExpressType.DEPOSIT,
        ...deposit,
      })
      router.push(routes.express.addRoute.review)
    },
    [router],
  )

  // Set Withdrawal Data.
  const setWithdrawal = useCallback(
    (withdrawal: AddRouteContextWithdrawalData) => {
      setData({
        direction: StablecoinExpressType.WITHDRAWAL,
        ...withdrawal,
      })
      router.push(routes.express.addRoute.review)
    },
    [router],
  )

  return (
    <AddRouteContext.Provider
      value={[data, { setDirection, setDeposit, setWithdrawal }]}
    >
      {children}
    </AddRouteContext.Provider>
  )
}

/**
 * Add Express Route Hook.
 */
export const useAddRoute = () => {
  return useContext(AddRouteContext)
}

/**
 * Wallet Approval Routes.
 */
export const walletApprovalPolicy = {
  createPolicy: {
    switch: '/walletApprovalPolicy/createPolicy/',
    initialize: '/walletApprovalPolicy/createPolicy/initialize',
    permissions: '/walletApprovalPolicy/createPolicy/permissions',
    policyLimits: '/walletApprovalPolicy/createPolicy/limits',
    userLimits: '/walletApprovalPolicy/createPolicy/limits/user',
    approval: '/walletApprovalPolicy/createPolicy/approval',
    review: '/walletApprovalPolicy/createPolicy/review',
    settings: '/walletApprovalPolicy/createPolicy/settings',
    finished: '/walletApprovalPolicy/createPolicy/review/finished',
  },
  discardDraftPolicy: '/walletApprovalPolicy/discardDraftPolicy',
  cancelApprovalRequest: '/walletApprovalPolicy/cancelApprovalRequest',
  submissionBlocked: '/walletApprovalPolicy/submissionBlocked',
  submittedPolicyProgress: '/walletApprovalPolicy/submittedPolicyProgress',
  approveApprovalRequest: '/walletApprovalPolicy/approveApprovalRequest',
  rejectApprovalRequest: '/walletApprovalPolicy/rejectApprovalRequest',
  cancelPendingTransaction: '/walletApprovalPolicy/cancelPendingTransaction',
}

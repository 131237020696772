import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BankAccountsDocument,
  useAddPixBankAccountMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'

import { useLinkBankAccount } from '../../../Link.Context'

import type { ApolloError } from '@apollo/client'
import type { AddPixBankAccountMutationVariables, Maybe } from '@shared/graphql'

export const useLinkPix = () => {
  const modal = useModal()

  const [{ information }] = useLinkBankAccount()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createMutation, mutation] = useAddPixBankAccountMutation({
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error: ApolloError) => {
      mfaError({ error })
    },
    refetchQueries: [BankAccountsDocument],
  })

  const variables = useMemo((): Maybe<AddPixBankAccountMutationVariables> => {
    if (information.pix) {
      return {
        input: {
          accountNumber: information.pix.accountNumber,
          accountType: information.pix.accountType,
          ispb: information.pix.bankId,
          taxId: information.pix.taxId,
          name: information.pix.bankName,
          nickname: information.pix.nickname,
        },
      }
    }
    return null
  }, [information.pix])

  const create = useCallback(() => {
    if (variables !== null) {
      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createMutation({
            variables,
            context: context(code),
          })
        },
        onAbort: () => {
          modal.close()
        },
      })
    }
  }, [context, createMutation, modal, variables, withMfa])

  return [create, mutation] as const
}

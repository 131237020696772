import { useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLET_APPROVAL,
} from '@services/permissions'
import {
  WalletApprovalPolicyDocument,
  WalletApprovalSubmittedPolicyProgressDocument,
  useWalletApprovalPolicyQuery,
} from '@shared/graphql'

import {
  ApproveApprovalRequest,
  RejectApprovalRequest,
} from '../../../buttons/WalletApprovalPolicy'

import type { Currency } from '@shared/graphql'

export interface WalletApprovalPolicyVoteButtonsProps {
  /**
   * Currency.
   */
  currency: Currency
}

export const WalletApprovalPolicyVoteButtons: React.FC<WalletApprovalPolicyVoteButtonsProps> =
  ({ currency }) => {
    const [, WalletsMutationPermissionStatus] = usePermission(
      CIRCLE_WALLETS_MUTATION,
    )
    const [, ApprovalPermissionStatus] = usePermission(CIRCLE_WALLET_APPROVAL)

    const isAuthorized = useMemo(
      () =>
        WalletsMutationPermissionStatus.isAuthorized &&
        ApprovalPermissionStatus.isAuthorized,
      [
        ApprovalPermissionStatus.isAuthorized,
        WalletsMutationPermissionStatus.isAuthorized,
      ],
    )

    const { data } = useWalletApprovalPolicyQuery({
      variables: {
        currency,
      },
      skip: !isAuthorized,
    })

    const refetchQueries = useMemo(
      () => [
        {
          query: WalletApprovalSubmittedPolicyProgressDocument,
          variables: {
            policyId: data?.walletApprovalPolicy?.id,
          },
        },
        {
          query: WalletApprovalPolicyDocument,
          variables: {
            currency,
          },
        },
      ],
      [currency, data?.walletApprovalPolicy?.id],
    )

    if (data?.walletApprovalPolicy?.showVoteAction !== true) {
      return null
    }

    return (
      <>
        <RejectApprovalRequest
          proposalId={data?.walletApprovalPolicy.id}
          refetchQueries={refetchQueries}
        />
        <ApproveApprovalRequest
          proposalId={data?.walletApprovalPolicy.id}
          refetchQueries={refetchQueries}
        />
      </>
    )
  }

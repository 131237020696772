import { Button } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { IpAllowListingIpAddressType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { FormInputProps } from '@circlefin/form/Form.Input'

export const ipAddressMinSchema = y.object({
  /**
   * Lower bound ip address.
   */
  ipMin: y.string().ipAddress(),
})

export type IpAddressMinFormValues = y.InferType<typeof ipAddressMinSchema>

export type IpAddressMinProps = Omit<
  FormInputProps<IpAddressMinFormValues>,
  'labelMessage' | 'alignLabelMessage' | 'name'
> & {
  /**
   * Ip address type.
   */
  ipType?: IpAllowListingIpAddressType
  /**
   * Controls `Use my ip` button.
   */
  showUseMyIp?: boolean
  /**
   * Callback handler for use my ip button.
   */
  onUseMyIp?: () => void
}

export const IpAddressMin: React.FC<IpAddressMinProps> = ({
  ipType = IpAllowListingIpAddressType.single,
  label,
  onChange,
  showUseMyIp = true,
  onUseMyIp,
  ...props
}) => {
  const { t } = useTranslation('forms')
  const [Form] = useForm<IpAddressMinFormValues>({ schema: ipAddressMinSchema })

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between type-body-sm-bold">
        <label>
          <span className="text-neutral">
            {label ?? t(`input.settings.ipAddressMin.label.${ipType}`)}
          </span>
        </label>
        {showUseMyIp && (
          <Button
            className="p-0 normal-case"
            onClick={onUseMyIp}
            variant="text"
          >
            {t('input.settings.ipAddressMin.useMyIp')}
          </Button>
        )}
      </div>
      <Form.Input
        {...props}
        data-testid="ip-address-min"
        name="ipMin"
        onChange={onChange}
      />
    </div>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

import type {
  BlockchainAbbreviation,
  BlockchainRecipientAddress,
  BlockchainRecipientAddressWithMetadata,
} from '@shared/graphql'

/**
 * Send-On-Chain flow values.
 */
export type SendOnChainDestination =
  | BlockchainRecipientAddress
  | BlockchainRecipientAddressWithMetadata

export interface SendOnChainState {
  /**
   * Amount to be sent.
   */
  amount?: number
  /**
   * Transfer fee.
   */
  fee: number
  /**
   * Transfer total.
   */
  total: number
  /**
   * Blockchain used to transfer.
   */
  blockchain?: BlockchainAbbreviation
  /**
   * Destination blockchain address.
   */
  destination?: SendOnChainDestination
}

/**
 * Send-On-Chain Actions.
 */
interface SendOnChainActions {
  /**
   * Set blockchain state handler.
   */
  setBlockchain: (blockchain?: BlockchainAbbreviation) => void
  /**
   * Set transfer form state handler.
   */
  setTransferState: (state: Partial<SendOnChainState>) => void
}

const defaultSendOnChainValues: SendOnChainState = {
  amount: undefined,
  fee: 0,
  total: 0,
  blockchain: undefined,
  destination: undefined,
}

/**
 * Create Send-On-Chain Context.
 */
const SendOnChainContext = createContext<
  [SendOnChainState, SendOnChainActions]
>([
  { ...defaultSendOnChainValues },
  {
    setBlockchain: () => null,
    setTransferState: () => null,
  },
])

/**
 * Send-On-Chain Provider props.
 */
interface SendOnChainProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<SendOnChainState>
}

/**
 * Send-On-Chain Provider.
 */
export const SendOnChainProvider: React.FC<SendOnChainProviderProps> = ({
  children,
  initValues = {},
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<SendOnChainState>({
    ...defaultSendOnChainValues,
    ...initValues,
  })

  const handleValueChange = useCallback((values: Partial<SendOnChainState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  useEffect(() => {
    const resetContext = () => {
      handleValueChange(defaultSendOnChainValues)
    }

    events.on('onCloseStart', resetContext)

    return () => {
      events.off('onCloseStart', resetContext)
    }
  })

  return (
    <SendOnChainContext.Provider
      value={[
        values,
        {
          setBlockchain: (newBlockchain) => {
            handleValueChange({
              ...defaultSendOnChainValues,
              blockchain: newBlockchain,
            })
          },
          setTransferState: handleValueChange,
        },
      ]}
    >
      {children}
    </SendOnChainContext.Provider>
  )
}

/**
 * Send on Chain hook.
 */
export const useSendOnChain = () => {
  return useContext(SendOnChainContext)
}

import { Header, Icon, NumberBox, Tooltip } from '@circlefin/components'
import { BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useCustodyBalancesQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const VaultsEnabledNumbers: React.FC = () => {
  const { t } = useTranslation('custody/common')

  const { data, loading, error } = useCustodyBalancesQuery({
    pollInterval: BALANCE_POLL_INTERVAL, // 10sec interval
    fetchPolicy: 'cache-and-network',
  })

  const balanceLoading = !data && loading // if we have cached data, ignore loading

  return (
    <Header.Numbers className="mt-7" primary>
      <NumberBox loading={balanceLoading} size="lg">
        <NumberBox.Amount currency="USD">
          {error ? undefined : data?.custodyBalances.total?.amount}
        </NumberBox.Amount>
        <NumberBox.Title>
          <div className="flex items-center justify-start">
            <span className="flex flex-row">{t`numbers.total`}</span>
            <Tooltip className="z-60" content={t`numbers.tooltip.total`}>
              <span className="ml-1.5">
                <Icon name="InformationCircleSolid" size={16} />
              </span>
            </Tooltip>
          </div>
        </NumberBox.Title>
      </NumberBox>
      <NumberBox loading={balanceLoading} size="sm">
        <NumberBox.Amount currency="USD">
          {error ? undefined : data?.custodyBalances.circleManaged?.amount}
        </NumberBox.Amount>
        <NumberBox.Title>
          <div className="flex items-center justify-start">
            <span className="flex flex-row">{t`numbers.circleManaged`}</span>
            <Tooltip
              className="z-60"
              content={t`numbers.tooltip.circleManaged`}
              place="bottom"
            >
              <span className="ml-1.5">
                <Icon name="InformationCircleSolid" size={16} />
              </span>
            </Tooltip>
          </div>
        </NumberBox.Title>
      </NumberBox>
      <NumberBox loading={balanceLoading} size="sm">
        <NumberBox.Amount currency="USD">
          {error ? undefined : data?.custodyBalances.selfManaged?.amount}
        </NumberBox.Amount>
        <NumberBox.Title>
          <div className="flex items-center justify-start">
            <span className="flex flex-row">{t`numbers.selfManaged`}</span>
            <Tooltip className="z-60" content={t`numbers.tooltip.selfManaged`}>
              <span className="ml-1.5">
                <Icon name="InformationCircleSolid" size={16} />
              </span>
            </Tooltip>
          </div>
        </NumberBox.Title>
      </NumberBox>
    </Header.Numbers>
  )
}

import { TransWithLink } from '@shared/components/common'
import classNames from 'classnames'

export interface LandingFooterProps {
  /**
   * Custom style.
   */
  className?: string
}

export const LandingFooter: React.FC<LandingFooterProps> = ({ className }) => {
  return (
    <section
      className={classNames(
        'flex justify-center items-center py-6 px-4 w-full bg-black-600',
        className,
      )}
      data-testid="footer"
    >
      <div>
        <TransWithLink
          className="text-blue-300 font-circular-bold"
          components={{
            span: <span className="text-white" />,
          }}
          i18nKey="onboard/sandbox-signup:landing-footer.label"
          variant="sales"
        />
      </div>
    </section>
  )
}

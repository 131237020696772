import { useMemo } from 'react'

import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { Assets } from '@features/blockchain/components'
import { DefaultCell } from '@shared/components/tables'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainRecipientAddressWithMetadata } from '@shared/graphql'

const columnHelper =
  createColumnHelper<BlockchainRecipientAddressWithMetadata>()

export const useTableColumns = () => {
  const { t } = useTranslation('vaults')

  return useMemo(() => {
    /**
     * Nickname Column.
     */
    const nickname = columnHelper.accessor('description', {
      header: t<string>('createVault.outgoingAddressesTable.headers.nickname'),
      meta: {
        className: 'w-24',
      },
      cell: ({ getValue }) => (
        <DefaultCell className="truncate">{getValue()}</DefaultCell>
      ),
    })

    /**
     * Blockchain Column.
     */
    const blockchain = columnHelper.accessor('chain', {
      header: t<string>(
        'createVault.outgoingAddressesTable.headers.blockchain',
      ),
      meta: {
        className: 'w-16',
      },
      cell: ({ getValue }) => (
        <DefaultCell>{t(`payments:chainNames.${getValue()}`)}</DefaultCell>
      ),
    })

    /**
     * Owner Column.
     */
    const owner = columnHelper.accessor('owner', {
      header: t<string>('createVault.outgoingAddressesTable.headers.owner'),
      meta: {
        className: 'w-16',
      },
      cell: ({ getValue }) => (
        <DefaultCell className="whitespace-normal break-all">
          {t(`createVault.outgoingAddressesTable.owner.${getValue()}`)}
        </DefaultCell>
      ),
    })

    /**
     * Supported assets.
     */
    const assets = columnHelper.accessor('assets', {
      header: t<string>('common:supportedAssets'),
      meta: {
        className: 'w-20',
      },
      cell: ({ getValue, row }) => (
        <Assets assets={getValue()} chain={row.original.chain} />
      ),
    })

    return [nickname, blockchain, owner, assets]
  }, [t])
}

import { useCallback, useMemo, useState } from 'react'

import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import { OnboardingBusinessNature } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useBusinessNature } from '../../../hooks/business-nature'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const UNSUPPORTED_BUSINESS_NATURE = [
  OnboardingBusinessNature.casino,
  OnboardingBusinessNature.manufacturingWeapons,
  OnboardingBusinessNature.marijuana,
  OnboardingBusinessNature.preciousMetals,
]

type BusinessNatureItem = SelectListItem<OnboardingBusinessNature>

export const businessNatureSchema = y
  .string()
  .test(
    'validateBusinessNature',
    { key: 'onboard.unsupportedBusinessNature' },
    (value) =>
      !UNSUPPORTED_BUSINESS_NATURE.includes(value as OnboardingBusinessNature),
  )

export interface BusinessNatureProps {
  /**
   * Field label.
   */
  label: string
  /**
   * Field name.
   */
  name?: string
  /**
   * Optional field placeholder.
   */
  placeholder?: string
  /**
   * Force loading state.
   */
  loading?: boolean
}

export const BusinessNature: React.FC<BusinessNatureProps> = ({
  loading = false,
  placeholder,
  name = 'businessNature',
  label,
}) => {
  const { t } = useTranslation('common')
  const { track } = useSegment()
  const [Form] = useForm({
    schema: y.object({
      [name]: businessNatureSchema,
    }),
  })
  const { data } = useBusinessNature()
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = useCallback(
    (value: BusinessNatureItem | null) => {
      setSearchTerm('')

      if (
        value?.value != null &&
        UNSUPPORTED_BUSINESS_NATURE.includes(value.value)
      ) {
        track(SegmentEvents.UnsupportedBusinessNatureSelected, {
          props: {
            value,
          },
        })
      }
    },
    [track],
  )

  const filtered = useMemo(() => {
    if (searchTerm) {
      return matchSorter(data, searchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [data, searchTerm])

  const handleInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`clear-search`,
    }),
    [t],
  )

  return (
    <Form.Combobox
      data-testid="combobox-business-nature"
      filtered={filtered}
      inputLoading={loading}
      items={data}
      label={label}
      locale={locale}
      maxMenuItems={6}
      name={name}
      onChange={handleChange}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      searchTerm={searchTerm}
    />
  )
}

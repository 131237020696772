import { useCallback, useEffect } from 'react'

import { Button } from '@circlefin/components'
import { confirm } from '@services/sections/lib/accountSetup'
import { SegmentEvents, useSegment } from '@services/segment'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../hooks/account-setup'
import { ProgressShell } from '../ProgressShell/ProgressShell'

export const Welcome: React.FC = () => {
  const { t } = useTranslation('onboard/account-setup')
  const [, { completeStep }] = useAccountSetup()
  const { prefetch } = useRouter()
  const { track } = useSegment()

  useEffect(() => {
    // Prefetch next step
    void prefetch(confirm.route)
  }, [prefetch])

  /**
   * Handle where to route user next.
   */
  const handleClick = useCallback(() => {
    track(SegmentEvents.ButtonClicked, {
      props: {
        button_label: 'Confirm',
      },
    })

    void completeStep('WELCOME')
  }, [completeStep, track])

  return (
    <ProgressShell currentStep="WELCOME">
      <h2 className="type-h-page-sm">{t`welcome.title`}</h2>
      <p className="mt-1 text-neutral-subtle type-intro-sm">{t`welcome.description`}</p>
      <p className="mt-8 text-neutral-subtle type-body-sm">{t`welcome.legal`}</p>
      <p className="mt-6 text-neutral-subtle type-body-sm">{t`welcome.progress`}</p>
      <Button className="mt-6" onClick={handleClick} variant="primary">
        {t`welcome.button`}
      </Button>
    </ProgressShell>
  )
}

import { useCallback, useState } from 'react'

import { CodeInput, SkeletonBox, Statement } from '@circlefin/components'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useActivateMfaMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface SetupTotpCodeInputProps {
  /**
   * ID of factor to be activated.
   */
  factorId?: string
  /**
   * Callback after successfully activating 2FA.
   */
  onActivation?: () => void | Promise<void>
}

export const SetupTotpCodeInput: React.FC<SetupTotpCodeInputProps> = ({
  factorId,
  onActivation,
}) => {
  const { t } = useTranslation('mfa')
  const { track } = useSegment()

  const [activateMfa, { error, reset }] = useActivateMfaMutation()

  const [loading, setLoading] = useState(false)

  const submit = useCallback(
    async (code: string) => {
      if (factorId) {
        setLoading(true)

        try {
          await activateMfa({
            variables: {
              factorId,
              code,
            },
          })

          await onActivation?.()
        } catch {
          setLoading(false)

          // Error handled in GraphQLErrorBoundary
        }
      }
    },
    [activateMfa, factorId, onActivation],
  )

  const handleComplete = useCallback(
    (code: string) => {
      track(SegmentEvents.FormSubmitted)

      void submit(code)
    },
    [submit, track],
  )

  return (
    <>
      <span className="mb-1 block type-body-sm-bold">
        {t`setupTotp.codeInput.label`}
      </span>

      <GraphQLErrorBoundary error={error} retry={reset}>
        {/* Code input */}
        {loading ? (
          <Statement
            className="mx-0 items-start pt-1"
            iconName="CircleSpinnerSolid"
            padded={false}
            title={t`loading`}
            loop
          />
        ) : (
          // Show code input only when factorId is available.
          <SkeletonBox className="mb-0.5 h-11 w-64" loading={!factorId}>
            <CodeInput
              allowedCharacters="numeric"
              ariaLabel={t`setupTotp.codeInput.label`}
              length={6}
              onComplete={handleComplete}
            />
          </SkeletonBox>
        )}
      </GraphQLErrorBoundary>
    </>
  )
}

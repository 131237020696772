import { Button, Card } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface AddApprovalUsersCtaProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Onclick handler.
   */
  onClick?: () => void
}

export const AddApprovalUsersCta: React.FC<AddApprovalUsersCtaProps> = ({
  className,
  onClick,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <Card className={className} variant="dashed/base">
      <Card.Content>
        <div>
          <h3 className="text-2xl leading-7 text-neutral-strong font-circular-bold">
            {t`addApprovalUsersCta.title`}
          </h3>
          <p className="mt-4 text-base text-neutral-strong text-opacity-70 font-circular-regular">
            {t`addApprovalUsersCta.description`}
          </p>
          <Button className="mt-4" onClick={onClick} variant="secondary">
            {t`addApprovalUsersCta.cta`}
          </Button>
        </div>
      </Card.Content>
    </Card>
  )
}

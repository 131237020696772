import { useCallback, useState } from 'react'

import { useTransComponent } from '@shared/hooks/component'
import useTranslation from 'next-translate/useTranslation'

import { ActivateCodeInput } from './Activate.CodeInput'
import { ActivateSecret } from './Activate.Secret'

export interface ActivateProps {
  /**
   * Callback after successfully activating 2FA.
   */
  onActivation?: () => void | Promise<void>
}

export const Activate: React.FC<ActivateProps> = ({ onActivation }) => {
  const { t } = useTranslation('mfa')

  const [factorId, setFactorId] = useState<string>()

  const handleSetFactorId = useCallback(
    (factorId: string) => setFactorId(factorId),
    [],
  )

  const [AppsWithLinks] = useTransComponent('mfa', (link, i) => (
    <a
      key={`mfa-setup-link-${i}`}
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    />
  ))

  const isAndroidDevice = /android/i.test(navigator.userAgent)

  return (
    <div className="flex max-w-2xl flex-col items-start">
      {/* Heading */}
      <h2 className="type-h-page-sm">{t`enrollment.activate.title`}</h2>
      <p
        className="mt-1 text-neutral-subtle type-intro-sm"
        data-testid="apps-with-links"
      >
        <AppsWithLinks
          i18nArrayKey={`enrollment.activate.links.${
            isAndroidDevice ? 'android' : 'ios'
          }`}
          i18nKey="enrollment.activate.subtitle"
        />
      </p>

      {/* Heading */}
      <h3 className="mt-4 text-neutral type-h-title-lg">
        {t`enrollment.activate.step-1`}
      </h3>

      {/* MFA Enrollment - QR code and shared secret */}
      <ActivateSecret setFactorId={handleSetFactorId} />

      {/* Heading */}
      <h3 className="mt-12 text-neutral type-h-title-lg">
        {t`enrollment.activate.step-2`}
      </h3>

      {/* MFA Activation - 6 digit code input */}
      <ActivateCodeInput factorId={factorId} onActivation={onActivation} />
    </div>
  )
}

import {
  apiKeys,
  apiLogs,
  subscriptionsManagement,
} from '@services/sections/lib/developer'
import classNames from 'classnames'

import { TabsNav } from '../TabsNav/TabsNav'

export interface DeveloperNavProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Style?
   */
  className?: string
}

const sections = [apiKeys, apiLogs, subscriptionsManagement]

export const DeveloperNav: React.FC<DeveloperNavProps> = ({ className }) => {
  return (
    <TabsNav
      className={classNames('mb-5 mt-7', className)}
      sections={sections}
    />
  )
}

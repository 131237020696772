import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Is wallet policy approval enabled?
 */
export const CIRCLE_WALLET_APPROVAL: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_EMAIL_APPROVAL_ROLE],
}

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { CountryCode } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useCountries } from '../../../hooks/countries'

import type {
  DropdownOptionsProps,
  DropdownProps,
} from '@circlefin/components/lib/Dropdown'
import type { CountriesInput } from '@shared/graphql'

export interface CountryFormProps
  extends Pick<
      DropdownProps<CountryCode>,
      'dropDirection' | 'className' | 'label' | 'onChange'
    >,
    Pick<DropdownOptionsProps<CountryCode>, 'maxMenuItems'> {
  /**
   * Loading?
   */
  loading?: boolean
  /**
   * Custom name?
   */
  name?: string
  /**
   * Country query filters.
   */
  filters?: CountriesInput
}

export const countrySchema = y
  .mixed<CountryCode>()
  .oneOf(Object.values(CountryCode))

const Dropdown = createFormDropdown()

export const Country: React.FC<CountryFormProps> = ({
  maxMenuItems = 6,
  dropDirection = 'down',
  className,
  name = 'country',
  label,
  loading: loadingProp = false,
  filters,
  ...props
}) => {
  const { t } = useTranslation('forms')
  const { loading, countries } = useCountries({ ...filters })

  return (
    <Dropdown
      className={className}
      data-testid={`dropdown-${name}`}
      dropDirection={dropDirection}
      inputLoading={loading || loadingProp}
      items={countries.toDropdown()}
      label={label ?? t`dropdown.locale.country.label`}
      maxMenuItems={maxMenuItems}
      name={name}
      {...props}
    />
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import {
  BlockchainAddressStatus,
  RecipientAddressFilterBy,
  useBlockchainRecipientAddressesWithMetadataQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { groupAddresses } from './RecipientAddresses.util'

import type { AddressItem } from './RecipientAddresses.util'
import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { BlockchainAbbreviation } from '@shared/graphql'

export interface RecipientAddressesProps {
  /**
   * Combobox Name.
   */
  name: string
  /**
   * Selected blockchain.
   */
  blockchain?: BlockchainAbbreviation
  /**
   * Form element label.
   */
  label?: string
  /**
   * Form element placeholder text.
   */
  placeholder?: string
  /**
   * Disabled.
   */
  disabled?: boolean
  /**
   * Custom Style?
   */
  className?: string
}

const Combobox = createFormCombobox()

export const RecipientAddresses: React.FC<RecipientAddressesProps> = ({
  name,
  blockchain,
  label,
  placeholder,
  disabled,
  className,
}) => {
  const { t } = useTranslation('forms')

  const [{ currency }] = useCurrentCurrency()

  const { data, loading, refetch } =
    useBlockchainRecipientAddressesWithMetadataQuery({
      variables: {
        filter: {
          chain: blockchain != null ? [blockchain] : undefined,
          currency,
          show: RecipientAddressFilterBy.ALL_ADDRESSES,
          status: [
            BlockchainAddressStatus.ACTIVE,
            BlockchainAddressStatus.ON_HOLD,
          ],
        },
      },
      fetchPolicy: 'cache-and-network',
    })

  const [searchTerm, setSearchTerm] = useState('')

  // Clear search term when blockchain changes.
  useEffect(() => {
    setSearchTerm('')
  }, [blockchain])

  // Address list.
  const addressList: Array<AddressItem> = useMemo(() => {
    // Customer header item
    const customerHeader: AddressItem = {
      label: t('combobox.blockchain.recipientAddresses.customer'),
      header: true,
    }

    // Non customer header item
    const nonCustomerHeader: AddressItem = {
      label: t('combobox.blockchain.recipientAddresses.non_customer_entity'),
      header: true,
    }

    // group addresses into each list
    const addresses = groupAddresses(
      data?.blockchainRecipientAddressesWithMetadata,
    )

    // return items with headers
    return [
      ...(addresses.customer.length > 0 ? [customerHeader] : []),
      ...addresses.customer,
      ...(addresses.nonCustomer.length > 0 ? [nonCustomerHeader] : []),
      ...addresses.nonCustomer,
    ]
  }, [t, data])

  // Trigger api when combobox text input changes.
  const onDebouncedInputChange = useCallback(
    (search: string) => {
      void refetch({
        filter: {
          chain: blockchain != null ? [blockchain] : undefined,
          descriptionFilter: search,
          currency,
          show: RecipientAddressFilterBy.ALL_ADDRESSES,
          status: [
            BlockchainAddressStatus.ACTIVE,
            BlockchainAddressStatus.ON_HOLD,
          ],
        },
      })
    },
    [refetch, blockchain, currency],
  )

  // Clear search term and trigger api combobox value changes.
  const onChange = useCallback(() => {
    setSearchTerm('')
    onDebouncedInputChange('')
  }, [onDebouncedInputChange])

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.blockchain.recipientAddresses.noItemsMessage`,
    }),
    [t],
  )

  return (
    <Combobox
      className={className}
      debounce={300}
      disabled={disabled}
      inputLoading={loading}
      items={addressList}
      label={label ?? t`combobox.blockchain.recipientAddresses.walletAddresses`}
      locale={locale}
      maxMenuItems={6}
      name={name}
      onChange={onChange}
      onDebouncedInputChange={onDebouncedInputChange}
      onInputChange={setSearchTerm}
      placeholder={
        placeholder ?? t`combobox.blockchain.recipientAddresses.placeholder`
      }
      searchTerm={searchTerm}
    />
  )
}

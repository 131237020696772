import { useCallback, useEffect, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import {
  DelayedWithdrawalsAlert,
  BlockchainAlert,
} from '@features/blockchain/components'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import { Currency, InternalWalletType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { FrozenWalletAlert } from '../../../../../components'
import { AssetBalanceText } from '../../../../../containers'
import { VaultForms } from '../../../../../forms'
import { useVaultTransfer } from '../../../VaultTransfer.Context'
import { useSendOnChain } from '../../SendOnChain.Context'

const schema = y.object({
  /**
   * Source Wallet used to transfer.
   */
  sourceWallet: VaultForms.Combobox.InternalWalletsSourceSchema.required(),
  /**
   * Asset Wallet used to transfer.
   */
  assetWallet: VaultForms.Combobox.WalletsByParentSchema.required(),
  /**
   * Destination wallet to be transferred to.
   */
  destination:
    VaultForms.Combobox.WhitelistedRecipientAddressesSchema.required(),
})

type FormValues = y.InferType<typeof schema>

export const TransferRecipientForm: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const { router } = useModal()
  const [{ sourceWallet, assetWallet, destination }, { setSendOnChainState }] =
    useSendOnChain()
  const [
    { selectedParentWalletId, selectedWalletId },
    { setVaultTransferState },
  ] = useVaultTransfer()
  const [Form, { formState, watch, getValues }] = useForm<FormValues>({
    schema,
  })

  // Convert to Typed Source
  // NOTE: For now external transfers only supports a direct wallet source
  const vaultParentWallet = useMemo(
    () =>
      sourceWallet && TypeGuards.InternalWallet.isDirect(sourceWallet)
        ? sourceWallet
        : undefined,
    [sourceWallet],
  )

  // Convert to Typed Destination Address
  const destinationAddress = useMemo(
    () =>
      destination &&
      TypeGuards.VaultTransfer.Destination.isExternalAddress(destination)
        ? destination
        : undefined,
    [destination],
  )

  useEffect(() => {
    const subscription = watch(() => {
      // Sync provider states with the form values
      setSendOnChainState({
        // watch is used to trigger the callback, and getValues is used to get the precise field values
        sourceWallet: getValues('sourceWallet'),
        assetWallet: getValues('assetWallet'),
        destination: getValues('destination'),
      })

      // If the parent wallet is auto-selected, clear context value
      if (sourceWallet && selectedParentWalletId) {
        setVaultTransferState({ selectedParentWalletId: undefined })
      }

      // If child/mapped wallet is auto-selected, clear context value
      if (assetWallet && selectedWalletId) {
        setVaultTransferState({ selectedWalletId: undefined })
      }
    })

    return () => subscription.unsubscribe()
  }, [
    assetWallet,
    getValues,
    selectedParentWalletId,
    selectedWalletId,
    setSendOnChainState,
    setVaultTransferState,
    sourceWallet,
    watch,
  ])

  /**
   * Handle Form onSubmit.
   */
  const handleFormOnSubmit = useCallback(() => {
    router.push(routes.vault.transfer.sendOnChain.amount)
  }, [router])

  return (
    <Form onSubmit={handleFormOnSubmit}>
      {/* From (Parent Wallets) Field */}
      <VaultForms.Combobox.InternalWallets
        filter={{ excludeType: InternalWalletType.CIRCLE }}
        internalWalletId={selectedParentWalletId}
        label={t`forms:combobox.vault.parentWallets.label`}
        name="sourceWallet"
        placeholder={t`forms:combobox.vault.parentWallets.placeholder`}
      />

      {/* Assets Field */}
      <VaultForms.Combobox.WalletsByParent
        className="mt-6"
        disabled={!vaultParentWallet}
        message={
          <AssetBalanceText.DirectWallet
            vaultId={vaultParentWallet?.vaultMetadata.id}
            walletId={assetWallet?.id}
          />
        }
        name="assetWallet"
        parentWalletId={vaultParentWallet?.parentWalletId}
        walletId={selectedWalletId}
      />

      <FrozenWalletAlert className="mt-6" vaultWallet={assetWallet} />

      <VaultForms.Combobox.WhitelistedRecipientAddresses
        className="mt-6"
        disabled={!assetWallet}
        vaultWallet={assetWallet}
      />

      {/* When sending USDC, ensure we display any relevant blockchain alerts. */}
      {assetWallet?.assetMetadata.symbol === Currency.USDC && (
        <BlockchainAlert
          blockchain={vaultParentWallet?.blockchain}
          className="mt-6"
        />
      )}

      <DelayedWithdrawalsAlert
        className="mt-6"
        delayedWithdrawalInfo={
          destinationAddress?.delayedWithdrawalInfo ?? undefined
        }
      />

      <Center className="pt-8" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          disabled={
            Boolean(destinationAddress?.delayedWithdrawalInfo?.enabled) ||
            !formState.isValid ||
            assetWallet?.frozen
          }
          variant="primary"
        >
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { PUBLIC_ONLY } from '@services/permissions'

import type { Section } from '../types'

export const resetMfaToken: Section = {
  label: 'layout:page.reset-authenticator.title',
  route: '/reset-authenticator/[activationToken]',
  permissions: PUBLIC_ONLY,
  segment: {
    page: 'Reset Authenticator - Token',
  },
}

export const resetAuthenticatorSection: Section = {
  label: 'layout:page.reset-authenticator.title',
  route: '/reset-authenticator',
  permissions: PUBLIC_ONLY,
  pages: [resetMfaToken],
  segment: {
    page: 'Reset Authenticator - Request',
  },
}

import { BlockchainRecipientAddressInfoFragmentDoc } from '@shared/graphql'

import type { MutationUpdaterFn } from '@apollo/client'
import type { AddBlockchainRecipientAddressMutation } from '@shared/graphql'

export const addBlockchainRecipientAddressUpdate: MutationUpdaterFn<AddBlockchainRecipientAddressMutation> =
  (cache, { data }) => {
    if (data?.addBlockchainRecipientAddress) {
      cache.modify({
        fields: {
          blockchainRecipientAddresses(existingAddresses = []) {
            const blockchainAddressRef = cache.writeFragment({
              data: data.addBlockchainRecipientAddress,
              fragment: BlockchainRecipientAddressInfoFragmentDoc,
            })
            return [blockchainAddressRef, ...existingAddresses]
          },
        },
      })
    }
  }

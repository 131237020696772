import { useCallback } from 'react'

import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { EmptyVaultListPlaceholder } from '../EmptyVaultListPlaceholder/EmptyVaultListPlaceholder'
import { VaultCard } from '../VaultCard/VaultCard'

import type { VaultInfoFragment } from '@shared/graphql'

export interface VaultListProps {
  /**
   * Vaults of the user.
   */
  vaults: VaultInfoFragment[]
}

export const VaultList: React.FC<VaultListProps> = ({ vaults }) => {
  const { t } = useTranslation('vaults')

  const renderVault = useCallback(
    (vault: VaultInfoFragment) => <VaultCard key={vault.id} vault={vault} />,
    [],
  )

  const vaultsInDraft = vaults.filter((vault) =>
    [
      VaultStateEnum.SETUP_IN_PROGRESS,
      VaultStateEnum.READY_FOR_APPROVAL,
      VaultStateEnum.PENDING_APPROVAL,
    ].includes(vault.state),
  )

  const activeVaults = vaults.filter((vault) => !vaultsInDraft.includes(vault))

  const headlineClassNames =
    'mb-4 text-sm font-circular-medium tracking-wide leading-5 text-black-400 uppercase'

  return (
    <div className="space-y-8">
      {vaults.length === 0 ? <EmptyVaultListPlaceholder /> : null}
      {vaultsInDraft.length > 0 ? (
        <div>
          <h3 className={headlineClassNames}>{t`vaultList.drafts`}</h3>
          <ul className="space-y-4">{vaultsInDraft.map(renderVault)}</ul>
        </div>
      ) : null}
      {activeVaults.length > 0 ? (
        <div>
          <h3 className={headlineClassNames}>{t`vaultList.active`}</h3>
          <ul className="space-y-4">{activeVaults.map(renderVault)}</ul>
        </div>
      ) : null}
    </div>
  )
}

import { UserStatusLabel, UserStatusType } from '@shared/graphql'

import type { MutationUpdaterFn } from '@apollo/client'
import type { ResendInviteMutation } from '@shared/graphql'

/**
 * Update user status of reinvited user in cache.
 */
export const resendInvite: MutationUpdaterFn<ResendInviteMutation> = (
  cache,
  { data },
) => {
  if (data?.resendInvite) {
    cache.modify({
      id: cache.identify({
        id: data.resendInvite.userId,
        __typename: 'User',
      }),
      fields: {
        /** Change status of user to Pending. */
        status: () => {
          return {
            type: UserStatusType.PENDING,
            label: UserStatusLabel.Pending,
            __typename: 'UserStatus',
          }
        },
        /** Update status changed date. */
        statusChanged: () => new Date().toISOString(),
      },
    })
  }
}

import { FeatureSwitch } from '@services/feature-switch'
import {
  CHECKOUT,
  FRAUD_MANAGEMENT,
  PROTECTED,
  CHANGE_PASSWORD,
  PUBLIC,
  USER_MANAGEMENT_MUTATION,
  USER_MANAGEMENT_QUERY,
  VERITE_SECTION,
  RESTRICTED,
  CUSTODY_SETTINGS_SECTION,
  TRANSACTION_LIMIT_SETTINGS,
  DELAYED_WITHDRAWAL_SETTINGS_QUERY,
} from '@services/permissions'
import { BILLING_DASHBOARD } from '@services/permissions/sets/account/billingDashboard/billingDashboard'
import getConfig from 'next/config'

import type { Section } from '../types'

const config = getConfig()

export const changePassword: Section = {
  label: 'layout:page.settings.change-password',
  route: '/settings/change-password',
  permissions: CHANGE_PASSWORD,
  segment: {
    page: 'settings',
  },
}

export const personalInfo: Section = {
  label: 'layout:page.settings.personal-info',
  route: '/settings',
  permissions: PROTECTED,
  icon: 'UserOutline',
  pages: [changePassword],
  segment: {
    page: 'settings',
  },
}

export const forgotPassword: Section = {
  label: 'layout:page.settings.forgot-password',
  route: `${
    config?.publicRuntimeConfig.SIGN_IN_URL ?? ''
  }/signin/forgot-password`,
  permissions: PUBLIC,
  segment: {
    page: 'settings',
  },
}

export const inviteUser: Section = {
  label: 'layout:page.settings.users.invite',
  route: '/settings/users/invite',
  permissions: USER_MANAGEMENT_MUTATION,
  segment: {
    page: 'settings',
  },
}

export const manageUsers: Section = {
  label: 'layout:page.settings.users.title',
  route: '/settings/users',
  icon: 'UserGroupOutline',
  permissions: USER_MANAGEMENT_QUERY,
  pages: [inviteUser],
  segment: {
    page: 'settings',
  },
}

export const linkedWireAccountDetail: Section = {
  label: 'layout:page.settings.bank.wire',
  route: '/settings/bank/wire/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedSenAccountDetail: Section = {
  label: 'layout:page.settings.bank.sen',
  route: '/settings/bank/sen/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedSignetAccountDetail: Section = {
  label: 'layout:page.settings.bank.signet',
  route: '/settings/bank/signet/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedCbitAccountDetail: Section = {
  label: 'layout:page.settings.bank.cbit',
  route: '/settings/bank/cbit/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedPixAccountDetail: Section = {
  label: 'layout:page.settings.bank.pix',
  route: '/settings/bank/pix/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedXpayAccountDetail: Section = {
  label: 'layout:page.settings.bank.xpay',
  route: '/settings/bank/xpay/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const linkedRtpAccountDetail: Section = {
  label: 'layout:page.settings.bank.rtp',
  route: '/settings/bank/rtp/[id]',
  permissions: FeatureSwitch.rtpBankSupportEnabled() ? PROTECTED : RESTRICTED,
  segment: {
    page: 'settings',
  },
}

export const linkedAccountDetail: Section = {
  label: 'layout:page.settings.bank.detail',
  route: '/settings/bank/[type]/[id]',
  permissions: PROTECTED,
  matchRoute: [
    linkedWireAccountDetail.route,
    linkedSenAccountDetail.route,
    linkedSignetAccountDetail.route,
    linkedCbitAccountDetail.route,
    linkedXpayAccountDetail.route,
    linkedRtpAccountDetail.route,
    linkedPixAccountDetail.route,
  ],
  segment: {
    page: 'settings',
  },
}

export const linkedAccounts: Section = {
  label: 'layout:page.settings.bank.title',
  route: '/settings/bank',
  permissions: PROTECTED,
  icon: 'LibraryOutline',
  pages: [linkedAccountDetail],
  segment: {
    page: 'settings',
  },
}

export const limits: Section = {
  label: 'layout:page.settings.limits',
  route: '/settings/limits',
  permissions: TRANSACTION_LIMIT_SETTINGS,
  icon: 'ArrowCircleUpOutline',
  segment: {
    page: 'settings',
  },
}

export const cardDetails: Section = {
  label: 'layout:page.settings.billing.payment-methods.card',
  route: '/settings/billing/payment-methods/[id]',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const paymentMethodsSection: Section = {
  label: 'layout:page.settings.billing.payment-methods.title',
  route: '/settings/billing/payment-methods',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const invoiceDetailsSection: Section = {
  label: 'layout:page.settings.billing.invoices.detail',
  route: '/settings/billing/invoices/[id]',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const invoicesSection: Section = {
  label: 'layout:page.settings.billing.invoices.title',
  route: '/settings/billing/invoices',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const autoPaySection: Section = {
  label: 'layout:page.settings.billing.autopay',
  route: '/settings/billing/autopay',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const feeHistorySection: Section = {
  label: 'layout:page.settings.billing.feeHistory',
  route: '/settings/billing/feeHistory',
  permissions: BILLING_DASHBOARD,
  segment: {
    page: 'settings',
  },
}

export const billing: Section = {
  label: 'layout:page.settings.billing.title',
  route: '/settings/billing/invoices',
  permissions: BILLING_DASHBOARD,
  icon: 'CurrencyDollarOutline',
  pages: [
    invoiceDetailsSection,
    invoicesSection,
    cardDetails,
    paymentMethodsSection,
    autoPaySection,
    feeHistorySection,
  ],
  segment: {
    page: 'settings',
  },
}

export const loginAndSecuritySection: Section = {
  label: 'layout:page.settings.login-and-security',
  route: '/settings/login-and-security',
  permissions: FeatureSwitch.pinCodeResetEnabled() ? PROTECTED : RESTRICTED,
  pages: [changePassword],
  segment: {
    page: 'settings',
  },
}

export const fraudManagement: Section = {
  label: 'layout:page.settings.fraud-management',
  route: '/settings/fraud-management',
  permissions: FRAUD_MANAGEMENT,
  icon: 'ShieldCheckOutline',
  segment: {
    page: 'settings',
  },
}

export const ipAllowListingSection: Section = {
  label: 'layout:page.settings.ip-allow-listing',
  route: '/settings/ip-allowlisting',
  permissions: PROTECTED,
  segment: {
    page: 'settings',
  },
}

export const delayedWithdrawalsSection: Section = {
  label: 'layout:page.settings.delayed-withdrawals',
  route: '/settings/delayed-withdrawals',
  permissions: DELAYED_WITHDRAWAL_SETTINGS_QUERY,
  segment: {
    page: 'settings',
  },
}

export const adminApprovalsSection: Section = {
  label: 'layout:page.settings.admin-approvals',
  route: '/settings/admin-approvals',
  permissions: USER_MANAGEMENT_MUTATION,
  segment: {
    page: 'settings',
  },
}

export const verite: Section = {
  label: 'layout:page.settings.verite',
  route: '/settings/verite',
  permissions: VERITE_SECTION,
  icon: 'LockClosedSolid',
  segment: {
    page: 'settings',
  },
}

export const walletSecurity: Section = {
  label: 'layout:page.settings.wallet-security',
  route: '/settings/wallet-security',
  permissions: CUSTODY_SETTINGS_SECTION,
  icon: 'CollectionOutline',
  segment: {
    page: 'settings',
  },
}

export const checkoutWidget: Section = {
  label: 'layout:page.settings.merchant-payments.checkout-widget',
  route: '/settings/checkout-widget',
  permissions: CHECKOUT,
  segment: {
    page: 'settings',
  },
}

export const settingsSection: Section = {
  icon: 'CogOutline',
  label: 'layout:page.settings.title',
  route: '/settings',
  permissions: PROTECTED,
  pages: [
    personalInfo,
    manageUsers,
    inviteUser,
    linkedAccounts,
    linkedAccountDetail,
    linkedWireAccountDetail,
    linkedSenAccountDetail,
    linkedSignetAccountDetail,
    linkedCbitAccountDetail,
    linkedXpayAccountDetail,
    linkedRtpAccountDetail,
    linkedPixAccountDetail,
    limits,
    billing,
    changePassword,
    forgotPassword,
    ipAllowListingSection,
    delayedWithdrawalsSection,
    adminApprovalsSection,
    loginAndSecuritySection,
    fraudManagement,
    walletSecurity,
    verite,
    checkoutWidget,
  ],
  segment: {
    page: 'settings',
  },
}

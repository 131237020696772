import { Coolwhip } from '@circlefin/components'
import classNames from 'classnames'

import { Desc } from './Desc/Desc'
import { Header } from './Header/Header'
import { ProgressBar } from './ProgressBar/ProgressBar'
import { Step } from './Step/Step'

export interface StepperProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Step content to render.
   */
  children: React.ReactNode
  /**
   * Shows/hides the coolwhip.
   */
  hideBackground?: boolean
}

/**
 * Stepper component.
 */
export function Stepper({
  children,
  className,
  hideBackground = false,
}: StepperProps) {
  return (
    <>
      {!hideBackground && (
        <Coolwhip
          className="fixed bottom-0 h-screen w-full translate-y-56"
          data-testid="coolwhip"
          variant="fade-top/green-blue-violet"
        />
      )}
      <div className={classNames('w-full h-full', className)}>{children}</div>
    </>
  )
}

Stepper.displayName = 'Stepper'
Stepper.Step = Step
Stepper.Header = Header
Stepper.ProgressBar = ProgressBar
Stepper.Desc = Desc

import { useCallback } from 'react'

import { Button, LottieIcon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import { ProposalStatus } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface RequestResponseProps {
  /**
   * Wallet nickname.
   */
  nickname: string
  /**
   * Wallet address.
   */
  address: string
  /**
   * Type of post approval modal to show.
   */
  type: ProposalStatus
}

export const RequestResponse: React.FC<RequestResponseProps> = ({
  nickname,
  address,
  type,
}) => {
  const modal = useModal()
  const { t } = useTranslation('address-book/modals')
  const truncatedAddress =
    address.substring(0, 5) + '...' + address.substring(address.length - 5)

  const handleDoneClick = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <FullScreen>
      <Center>
        <LottieIcon
          height={152}
          name={type === ProposalStatus.COMPLETED ? 'Check' : 'Caution'}
          width={152}
        />
      </Center>

      <Center>
        <div className="mt-4 w-100 text-center text-base leading-6">
          <h2 className="font-circular-bold" data-testid="title">
            {t(`approvalRequest.${type}.title`)}
          </h2>
          <p data-testid="message">
            <Trans
              i18nKey={`address-book/modals:approvalRequest.${type}.body`}
              values={{
                nickname,
                address: truncatedAddress,
              }}
            />
          </p>
        </div>
      </Center>

      <Center className="mt-9" variant="horizontal">
        <Button className="w-100" onClick={handleDoneClick} variant="primary">
          {t('approvalRequest.done')}
        </Button>
      </Center>
    </FullScreen>
  )
}

import { createContext, useContext, useMemo } from 'react'

import { Currency } from '@shared/graphql'

import type { PolicySummaryProps } from './types'

type PolicySummaryContextProps = Omit<PolicySummaryProps, 'className'>

/**
 * Create Policy Summary Context.
 */
const PolicySummaryContext = createContext<PolicySummaryContextProps>({
  currency: Currency.USD,
  condensed: true,
  editEnabled: true,
  userLimits: [],
  policyLimits: undefined,
  approvalWorkflow: undefined,
  onEditClick: () => undefined,
})

export interface PolicySummaryProviderProps extends PolicySummaryContextProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Create Policy Summary Provider.
 */
export const PolicySummaryProvider: React.FC<PolicySummaryProviderProps> = ({
  children,
  ...rest
}) => {
  /**
   * Memoize Provider Value.
   */
  const value = useMemo(() => rest, [rest])

  return (
    <PolicySummaryContext.Provider value={value}>
      {children}
    </PolicySummaryContext.Provider>
  )
}

/**
 * Policy summary context hook.
 */
export const usePolicySummaryContext = () => {
  return useContext(PolicySummaryContext)
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Sandbox Experience Permissions
 * Defines if a user has been enabled sandbox experience.
 */
export const SANDBOX_EXPERIENCE: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.SANDBOX_USER_ROLE, Role.SANDBOX_USER_READ_ROLE],
}

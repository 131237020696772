import { useCallback } from 'react'
import type { FallbackProps } from 'react-error-boundary'

import { Statement, Button } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface GenericPageErrorProps extends FallbackProps {}

export const GenericPageError: React.FC<GenericPageErrorProps> = ({
  resetErrorBoundary,
}) => {
  const { t } = useTranslation('common')

  const onRetry = useCallback(() => {
    resetErrorBoundary()
  }, [resetErrorBoundary])

  return (
    <Statement status="warning" subtitle={t`generalError`} variant="page">
      <Button onClick={onRetry} variant="secondary">
        {t`retry`}
      </Button>
    </Statement>
  )
}

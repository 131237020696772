import { useCallback, useEffect, useMemo, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { omit } from 'lodash'

import { WalletApprovalPolicyContext, defaultValues } from './context'

import type {
  WalletApprovalPolicyState,
  WalletApprovalPolicyTouched,
  WalletApprovalPolicyActions,
  BaseWalletApprovalPolicyState,
} from './context'
import type { WalletApprovalPolicyLimits } from '@shared/graphql'

interface WalletApprovalPolicyProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<WalletApprovalPolicyState>
}

export const WalletApprovalPolicyProvider: React.FC<WalletApprovalPolicyProviderProps> =
  ({ children, initValues }) => {
    const { events } = useModal()
    const [values, setValues] = useState<WalletApprovalPolicyState>({
      ...defaultValues,
      ...initValues,
    })

    const mergeValues = useCallback(
      (values: Partial<WalletApprovalPolicyState>) => {
        setValues((curr) => ({
          ...curr,
          ...values,
          touched: {
            ...curr.touched,
            ...values.touched,
          },
        }))
      },
      [],
    )

    const mergeTouched = useCallback(
      (touched: WalletApprovalPolicyTouched) => {
        mergeValues({ touched })
      },
      [mergeValues],
    )

    const resetUserLimitsBasedOnPolicyLimits = useCallback(
      (policyLimits: WalletApprovalPolicyLimits) => {
        setValues((curr) => {
          // if the user's global policy limits has changed after the daily limits have been configured,
          // there could be a case where the newly configured policy limits are less than the previously configured user daily limits.
          // if this is the case we should reset any daily users that go over the policy limits.

          const updatedUserLimits = curr.userLimits?.map((limit) => {
            return {
              ...limit,
              maxAmount:
                Number(limit.maxAmount ?? 0) >
                Number(policyLimits.maxAmount ?? 0)
                  ? undefined
                  : limit.maxAmount,
              maxTransactions:
                Number(limit.maxTransactions ?? 0) >
                Number(policyLimits.maxTransactions ?? 0)
                  ? undefined
                  : limit.maxTransactions,
            }
          })

          return {
            ...curr,
            policyLimits,
            usersPolicy: updatedUserLimits,
          }
        })
      },
      [],
    )

    const actions: WalletApprovalPolicyActions = useMemo(
      () => ({
        setUserLimits: (userLimits, touched = true) => {
          mergeValues({ userLimits })
          mergeTouched({ userLimits: touched })
        },
        setPolicyLimits: (policyLimits) => {
          mergeValues({ policyLimits })
          mergeTouched({ policyLimits: true })
        },
        setApprovalLevelInEdit: (approvalLevelInEdit) => {
          mergeValues({ approvalLevelInEdit })
        },
        setApprovalWorkflow: (approvalWorkflow) => {
          mergeValues({ approvalWorkflow })
          mergeTouched({ approvalWorkflow: true })
        },
        setPolicySettings: (settings) => {
          setValues((curr) => ({
            ...curr,
            settings: {
              ...curr.settings,
              ...settings,
            },
          }))
          if (settings.allowInitiatorToApproveTransfer != null) {
            mergeTouched({ approvalWorkflow: true })
          }
          if (settings.proposalApprovalMethod != null) {
            mergeTouched({ settings: true })
          }
        },
        setFlowComplete: (isFlowComplete) => mergeValues({ isFlowComplete }),
        setTouched: mergeTouched,
        resetUserLimitsBasedOnPolicyLimits,
        setPolicyInEdit: (policy) => {
          const initialValues: BaseWalletApprovalPolicyState = {
            policyLimits: policy.policyLimits ?? undefined,
            userLimits: policy.userLimits ?? undefined,
            approvalWorkflow: policy.approvalWorkflow ?? undefined,
            settings: omit(policy.settings, '__typename'),
          }

          mergeValues({
            inEdit: true,
            ownerOfPolicyInEdit: policy.owner ?? undefined,
            ...initialValues,
            policyInEditInitialValues: initialValues,
          })
        },
      }),
      [resetUserLimitsBasedOnPolicyLimits, mergeTouched, mergeValues],
    )

    useEffect(() => {
      const resetContext = () => {
        setValues(defaultValues)
      }

      events.on('onCloseEnd', resetContext)

      return () => {
        events.off('onCloseEnd', resetContext)
      }
    }, [events])

    return (
      <WalletApprovalPolicyContext.Provider value={[values, actions]}>
        {children}
      </WalletApprovalPolicyContext.Provider>
    )
  }

import { Statement } from '@circlefin/components'
import { LoadingSpinner } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../hooks/account-setup'

export interface LoadingGuardProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const LoadingGuard: React.FC<LoadingGuardProps> = ({ children }) => {
  const [{ rerouting, processingBizInfo }] = useAccountSetup()
  const { t } = useTranslation('onboard/account-setup')

  if (rerouting) {
    return (
      <div className="flex w-full justify-center pt-32">
        <LoadingSpinner />
      </div>
    )
  }

  if (processingBizInfo) {
    return (
      <div className="flex w-full justify-center pt-16">
        <Statement
          iconName="CircleSpinnerSolid"
          title={t`loading`}
          variant="page"
          loop
        />
      </div>
    )
  }

  return <>{children}</>
}

import { useCallback, useMemo } from 'react'

import { SkeletonBox, Button, DescriptionList } from '@circlefin/components'
import { useCustomMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  useVaultWalletBalanceQuery,
  useWalletActivationFeeQuery,
  useActivateWalletMutation,
  VaultDocument,
  VaultWalletDocument,
  useVaultWalletQuery,
} from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import useTranslation from 'next-translate/useTranslation'

import { AvailableBalanceText, FeeBalanceAlert } from '../../containers'

import type { AvailableBalanceTextProps } from '../../containers'

export interface ConfirmWalletActivationFeeProps {
  /**
   * Vault wallet that needs to be activated.
   */
  walletId?: string
  /**
   * The id of the parent wallet the wallet belongs to.
   */
  parentWalletId?: string
  /**
   * The id of the vault the wallet belongs to.
   */
  vaultId?: string
}

export const ConfirmWalletActivationFee: React.FC<ConfirmWalletActivationFeeProps> =
  ({ vaultId = '', parentWalletId = '', walletId = '' }) => {
    const { customMoney } = useCustomMoney({ locale: 'en-US' })
    const { t } = useTranslation('modals.vault')
    const modal = useModal()
    const { idempotencyKey } = useIdempotencyKey()

    const walletQuery = useVaultWalletQuery({
      variables: {
        vaultId,
        walletId,
      },
    })

    const [activateWalletMutation, { loading }] = useActivateWalletMutation({
      onCompleted: () => {
        modal.close()
      },
      // To avoid uncaught error
      onError: () => null,
      refetchQueries: [
        {
          query: VaultDocument,
          variables: {
            id: vaultId,
          },
        },
        {
          query: VaultWalletDocument,
          variables: {
            vaultId,
            walletId,
          },
        },
      ],
      awaitRefetchQueries: true,
    })

    const walletBalanceQuery = useVaultWalletBalanceQuery({
      variables: {
        vaultId,
        walletId: parentWalletId,
      },
      skip: !vaultId || !parentWalletId,
      pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
      fetchPolicy: 'cache-and-network',
    })

    const walletActivationFeeQuery = useWalletActivationFeeQuery({
      variables: {
        vaultId,
        walletId,
      },
      skip: !vaultId || !walletId,
      // Always want up-to-date fees
      fetchPolicy: 'network-only',
    })

    const { vaultWalletBalance } = walletBalanceQuery.data ?? {}
    const { walletActivationFee } = walletActivationFeeQuery.data ?? {}

    const walletLacksFirstDeposit =
      walletActivationFee?.walletLacksFirstDeposit ?? false

    // Determine if balance is too low to cover fee
    const feeBalanceTooLow = useMemo(() => {
      if (!vaultWalletBalance || !walletActivationFee?.fee) {
        return false
      }

      // If Native balance is zero, show error
      if (Number(vaultWalletBalance.native.amount) === 0) {
        return true
      }

      // balance must exceed max fee amount
      return (
        Number(walletActivationFee.fee.native.amount) >
        Number(vaultWalletBalance.native.amount)
      )
    }, [vaultWalletBalance, walletActivationFee])

    const depositFlowQueryProps: AvailableBalanceTextProps['depositQueryProps'] =
      {
        parentWalletId,
        // the asset being deposited, prefill with the parent wallet asset
        walletId: parentWalletId,
      }

    const handleConfirmAccount = useCallback(() => {
      if (
        !walletActivationFee?.fee ||
        !walletActivationFee?.minRemainingAmount
      ) {
        return null
      }

      const {
        fee: { fiat, native },
        minRemainingAmount,
      } = walletActivationFee

      void activateWalletMutation({
        variables: {
          input: {
            refId: idempotencyKey,
            vaultId,
            walletId,
            fiatActivationFee: Number(fiat.amount),
            nativeActivationFee: Number(native.amount),
            minRemainingAmount: Number(minRemainingAmount),
          },
        },
      })
    }, [
      walletActivationFee,
      activateWalletMutation,
      idempotencyKey,
      vaultId,
      walletId,
    ])

    return (
      <FullScreen totalSteps={2}>
        <GraphQLErrorBoundary
          error={walletQuery.error}
          retry={walletQuery.refetch}
          variant="page"
        >
          <GraphQLErrorBoundary
            error={walletBalanceQuery.error}
            retry={walletBalanceQuery.refetch}
            variant="page"
          >
            <GraphQLErrorBoundary
              error={walletActivationFeeQuery.error}
              retry={walletActivationFeeQuery.refetch}
              variant="page"
            >
              <Center className="font-circular" variant="horizontal">
                <h2 className="mb-2 w-168 text-center text-4xl text-black-600 font-circular-bold">
                  {t('confirmWalletActivationFee.title', {
                    blockchainName: t<string>(
                      `payments:chainNames.${
                        walletQuery.data?.vaultWallet.blockchain ?? ''
                      }`,
                    ),
                    token: walletQuery.data?.vaultWallet.assetMetadata.symbol,
                  })}
                </h2>
                <p className="mt-1 w-168 text-center text-base leading-6 text-black-400 font-circular-regular">
                  {t('confirmWalletActivationFee.subtitle')}
                </p>

                <Center variant="horizontal">
                  <SkeletonBox
                    className="mb-4 mt-10 h-9 w-100"
                    loading={walletBalanceQuery.loading}
                  >
                    <AvailableBalanceText
                      className="mt-10"
                      depositQueryProps={depositFlowQueryProps}
                      fiatBalance={
                        vaultWalletBalance?.fiat &&
                        customMoney({
                          number: vaultWalletBalance.fiat.amount,
                          currencyConfig: {
                            name: vaultWalletBalance.fiat.asset.symbol,
                            decimals: vaultWalletBalance.fiat.asset.decimals,
                            isCryptoCurrency: true,
                          },
                          options: { padded: false },
                        })
                      }
                      nativeBalance={
                        vaultWalletBalance?.native &&
                        customMoney({
                          number: vaultWalletBalance.native.amount,
                          currencyConfig: {
                            name: vaultWalletBalance.native.asset.symbol,
                            decimals: vaultWalletBalance.native.asset.decimals,
                            isCryptoCurrency: true,
                          },
                          options: { padded: false },
                        })
                      }
                      type="walletActivation"
                    />
                  </SkeletonBox>
                </Center>

                <Center>
                  <div className="rounded-md border border-black-75 bg-white">
                    <DescriptionList className="w-104 grid-cols-2 items-start py-2">
                      <>
                        <DescriptionList.Label className="pl-6 text-sm">{t`confirmWalletActivationFee.maximumFee`}</DescriptionList.Label>
                        <DescriptionList.Description className="flex flex-col items-end pr-6">
                          {walletLacksFirstDeposit ? (
                            <span className="flex justify-end">
                              {t('confirmWalletActivationFee.notAvailable')}
                            </span>
                          ) : (
                            <>
                              <span className="flex justify-end">
                                <SkeletonBox
                                  className="mb-1 h-4 w-28"
                                  loading={walletActivationFeeQuery.loading}
                                >
                                  {walletActivationFee?.fee &&
                                    customMoney({
                                      number:
                                        walletActivationFee.fee.native.amount,
                                      currencyConfig: {
                                        name: walletActivationFee.fee.native
                                          .asset.symbol,
                                        decimals:
                                          walletActivationFee.fee.native.asset
                                            .decimals,
                                        isCryptoCurrency: true,
                                      },
                                      options: { padded: false },
                                    })}
                                </SkeletonBox>
                              </span>
                              <span className="flex justify-end text-sm text-black-400">
                                <SkeletonBox
                                  className="mt-1 h-4 w-24"
                                  loading={walletActivationFeeQuery.loading}
                                >
                                  {walletActivationFee?.fee &&
                                    customMoney({
                                      number:
                                        walletActivationFee.fee.fiat.amount,
                                      currencyConfig: {
                                        name: walletActivationFee.fee.fiat.asset
                                          .symbol,
                                        decimals:
                                          walletActivationFee.fee.fiat.asset
                                            .decimals,
                                        isCryptoCurrency: true,
                                      },
                                      options: { padded: false },
                                    })}
                                </SkeletonBox>
                              </span>
                            </>
                          )}
                        </DescriptionList.Description>
                      </>
                    </DescriptionList>
                  </div>

                  <FeeBalanceAlert
                    className="mt-6 w-104"
                    currencyCode={vaultWalletBalance?.native.asset.symbol ?? ''}
                    depositQueryProps={depositFlowQueryProps}
                    isBalanceZero={
                      Number(vaultWalletBalance?.native.amount) === 0
                    }
                    label={
                      walletLacksFirstDeposit
                        ? t(
                            `confirmWalletActivationFee.errors.walletLacksFirstDeposit`,
                            {
                              childWalletSymbol:
                                walletQuery.data?.vaultWallet.assetMetadata
                                  .symbol,
                              parentWalletSymbol:
                                walletQuery.data?.vaultWallet.blockchain,
                              blockchainName: t<string>(
                                `payments:chainNames.${
                                  walletQuery.data?.vaultWallet.blockchain ?? ''
                                }`,
                              ),
                            },
                          )
                        : undefined
                    }
                    visible={walletLacksFirstDeposit || feeBalanceTooLow}
                  />
                </Center>

                <Center className="pt-10" variant="horizontal">
                  <Button
                    className="h-10 w-60"
                    disabled={
                      loading ||
                      walletBalanceQuery.loading ||
                      walletActivationFeeQuery.loading ||
                      walletLacksFirstDeposit ||
                      feeBalanceTooLow
                    }
                    onClick={handleConfirmAccount}
                    variant="primary"
                  >
                    {t('confirmWalletActivationFee.confirmAccount')}
                  </Button>
                </Center>
              </Center>
            </GraphQLErrorBoundary>
          </GraphQLErrorBoundary>
        </GraphQLErrorBoundary>
      </FullScreen>
    )
  }

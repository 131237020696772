import { CreateVaultNavigationStep } from '../../../../containers/Create'
import * as VaultLayout from '../../../../layout'

import { PolicyForm } from './Policy.Form'

export const Policy: React.FC = () => {
  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.POLICY_LIMITS}
    >
      <PolicyForm />
    </VaultLayout.CreateVault>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { support } from '@services/sections/lib/home'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const Owner: React.FC = () => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  return (
    <BaseTitle title={t('vault.removeWarning.title')}>
      <p className="text-sm leading-6 text-black-600">
        {t('vault.removeWarning.owner.section1')}
      </p>

      <p className="mt-6 text-sm leading-6 text-black-600">
        {t('vault.removeWarning.owner.section2')}
      </p>

      <Modal.Footer className="mt-6" variant="stretch-equal" dense>
        <Button onClick={closeModal} variant="secondary">
          {t('common:dismiss')}
        </Button>
        <Link href={support.route} legacyBehavior passHref>
          <Button.Link
            rel="noopener noreferrer"
            target="_blank"
            variant="primary"
          >
            {t('common:contactSupport')}
          </Button.Link>
        </Link>
      </Modal.Footer>
    </BaseTitle>
  )
}

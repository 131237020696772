import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * USDC wallet query.
 * Display USDC wallet on home page.
 *
 */
export const USDC_WALLET_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.AGENT_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_READ_ROLE,
    Role.USDC_WALLET_ROLE,
  ],
}

import { AssetIcon, CountryCode, Currency } from '@shared/graphql'

/**
 * Type guard to check if a given token string is a valid asset icon.
 */
export const isAssetIcon = (token: string): token is AssetIcon =>
  Object.keys(AssetIcon).includes(token)

/**
 * Type guard to check if a given token or symbol string is a valid currency.
 */
export const isCurrency = (name?: string): name is Currency =>
  name ? Object.keys(Currency).includes(name) : false

/**
 * Type guard to check if country code is US or CA.
 */
export const isUsaOrCanada = (
  name?: CountryCode,
): name is CountryCode.US | CountryCode.CA =>
  name != null ? [CountryCode.US, CountryCode.CA].includes(name) : false

/**
 * Type guard to check if country code.
 */
export const isCountryCode = (code?: string): code is CountryCode =>
  code != null ? Object.keys(CountryCode).includes(code) : false

import { Observable, makeVar, ApolloError } from '@apollo/client'

import { MFA_REQUIRE, getError } from '../../../lib/error/codes'

import type { ErrorHandler } from '@apollo/client/link/error'
import type { GraphQLError } from 'graphql'

export interface MfaCode {
  /**
   * Factor ID.
   */
  id: string
  /**
   * Factor Code.
   */
  code: string
}

interface TotpCode {
  /**
   * Totp type.
   */
  type: 'totp'
  /**
   * Generic TOTP Code.
   */
  totpCode: MfaCode
}

interface ScaCodes {
  /**
   * SCA type.
   */
  type: 'sca'
  /**
   * Generic TOTP Code.
   */
  oktaCode: MfaCode
  /**
   * Pin Code.
   */
  pinCode: MfaCode
}

export type MfaCodes = TotpCode | ScaCodes

interface MfaRetry {
  /**
   * Handle error.
   */
  onError?: (error: ApolloError) => void
  /**
   * Handle Success.
   */
  onSuccess?: () => void
}

interface MfaStepUp {
  /**
   * Require MFA flag.
   */
  required: boolean
  /**
   * Retry Action.
   */
  retry?: (args: MfaRetry & MfaCodes) => void
  /**
   * Skip Action.
   */
  skip?: () => void
}

/**
 * Reactive variable to link with react hook.
 */
export const mfaRequiredVar = makeVar<MfaStepUp>({
  /**
   * Default to not require.
   */
  required: false,
})

/**
 * Handle MFA Error.
 */
export const mfaHandler: ErrorHandler = ({
  graphQLErrors,
  operation,
  forward,
}) => {
  // Some flows handle the mfa token directly. To be able to allow these flows to
  // handle errors regarding the mfa token directly, we will skip the mfaRequired flow.
  if (operation.getContext().mfaRequired === false) {
    // Skip cycle
    return forward(operation)
  }

  // Create Observable to wait for MFA to be resolved
  return new Observable((observer) => {
    // Attempt operation
    const attempt = ({ onError, onSuccess }: MfaRetry) => {
      // Forward operation with MFA headers
      return forward(operation).subscribe({
        // Handle attempt response
        next: (response) => {
          // Handle response error
          if (response.errors) {
            return onError?.(
              // Convert GraphQL error to ApolloError
              new ApolloError({
                graphQLErrors: response.errors,
              }),
            )
          }

          // All good, cycle resolved
          observer.next(response)
          onSuccess?.()
        },
      })
    }

    // Hold MFA cycle until resolved or rejected
    new Promise((resolve, reject) => {
      // Link resolve MFA to reactive variable
      mfaRequiredVar({
        // Turn on MFA required cycle
        required: true,

        // Retry handler
        retry: ({ onError, onSuccess, ...code }) => {
          const type = code.type

          if (type === 'totp') {
            const { totpCode } = code
            if (!totpCode.id && !totpCode.code) {
              // Reject cycle with error
              reject(graphQLErrors)
            }

            // Set MFA headers in the operation context
            operation.setContext({
              ...operation.getContext(),
              headers: {
                'X-MFA-ID': totpCode.id,
                'X-MFA-CODE': totpCode.code,
              },
            })
          }

          if (type === 'sca') {
            const { oktaCode, pinCode } = code
            if (
              (!oktaCode.id && !oktaCode.code) ||
              (!pinCode.id && !pinCode.code)
            ) {
              // Reject cycle with error
              reject(graphQLErrors)
            }

            // Set MFA headers in the operation context
            operation.setContext({
              ...operation.getContext(),
              headers: {
                'X-MFA-ID': oktaCode.id,
                'X-MFA-CODE': oktaCode.code,
                'X-MFA-PIN-CODE-ID': pinCode.id,
                'X-MFA-PIN-CODE': pinCode.code,
              },
            })
          }

          // Attempt another operation with MFA headers
          return attempt({
            // Attempt failed
            onError,

            // Attempt succeed
            onSuccess: () => {
              onSuccess?.()

              // Turn off MFA required cycle
              mfaRequiredVar({
                required: false,
              })

              // Resolve successful cycle
              resolve(true)
            },
          })
        },

        // Skip handler
        skip: () => {
          // Turn off MFA required cycle
          mfaRequiredVar({
            required: false,
          })

          // Reject cycle with error
          reject(graphQLErrors)
        },
      })
    })
      // Catch any other error, and close observable with error
      // It will skip the MFA request and provide the error to graphQL
      .catch((errors: GraphQLError[]) => {
        return observer.error(getError(MFA_REQUIRE, errors))
      })
  })
}

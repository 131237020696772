import { LottieIcon } from '@circlefin/components'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals.developer')

  return (
    <FullScreen>
      <Center className="justify-items-center" variant="horizontal">
        <LottieIcon height={65} name="Check" width={65} />
        <div className="mt-8 w-100 text-center text-base leading-6 text-black-600">
          <h2 className="text-3xl font-circular-bold">{t`common:success`}</h2>
          <p className="mt-5 text-xl font-circular-regular">{t`createSubscription.success`}</p>
        </div>
      </Center>
    </FullScreen>
  )
}

import { findDynamicSectionByPath } from '@services/sections'
import { Description, Title } from '@shared/components/seo'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export const PageHead: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  // Find current section
  const section = findDynamicSectionByPath(pathname)

  return (
    <>
      <Title suffix={t`common:meta.suffix`}>
        {t(section?.label ?? 'common:meta.title')}
      </Title>
      <Description>
        {t(section?.description ?? 'common:meta.description')}
      </Description>
    </>
  )
}

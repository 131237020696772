import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { TransWithLink } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

export const LearnMore: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const handleButtonClick = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <BaseTitle title={t`vaultDetails.learnMore.title`}>
      <div className="my-6 text-center text-sm text-black-600">
        <div className="mb-4">
          <h3 className="font-circular-bold">{t`vaultDetails.learnMore.reviewTitle`}</h3>
          <p>{t`vaultDetails.learnMore.reviewBody`}</p>
        </div>
        <div className="mb-4">
          <h3 className="font-circular-bold">{t`vaultDetails.learnMore.approvalTitle`}</h3>
          <p>{t`vaultDetails.learnMore.approvalBody`}</p>
        </div>
        <TransWithLink
          className="font-circular-medium"
          i18nKey="modals.vault:vaultDetails.learnMore.help"
          urlI18nKey="modals.vault:vaultDetails.learnMore.helpLink"
          variant="url"
        />
      </div>
      <Modal.Footer variant="stretch" dense>
        <Button onClick={handleButtonClick} type="button" variant="primary">
          {t`vaultDetails.learnMore.button`}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

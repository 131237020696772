import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { WireTransferLimit } from '@features/bank-account/containers'
import { BankAccountForms } from '@features/bank-account/forms'
import { useBankAccountTypeByCurrency } from '@features/bank-account/hooks/type-by-currency'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  Currency,
  useBankAccountsQuery,
  WireTransferTypeEnum,
} from '@shared/graphql'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useTransfer } from '../../../hooks/transfer'

import type { BankAccount } from '@shared/graphql'

const schema = y.object({
  /**
   * Preselected Bank Account.
   */
  bankAccount: BankAccountForms.Combobox.BankAccountSchema.required(),
})

type SelectBankAccountFormProps = y.InferType<typeof schema>

export interface SelectBankAccountQueryProps {
  /**
   * Preselected Bank Account.
   */
  bankAccount?: BankAccount
}

export const SelectBankAccount: React.FC<SelectBankAccountQueryProps> = ({
  bankAccount,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { track } = useSegment()
  const { router } = useModal()
  const modalHistory = useModalHistory()

  const [formState, { handleValueChange }] = useTransfer()
  const [{ currency }] = useCurrentCurrency()

  const [Form] = useForm<SelectBankAccountFormProps>({
    schema,
    defaultValues: {
      bankAccount: bankAccount ?? formState.bankAccount,
    },
  })

  const { bankAccountType } = useBankAccountTypeByCurrency({
    initialCurrency: currency,
  })

  const bankAccounts = useBankAccountsQuery({
    variables: {
      types: bankAccountType,
    },
  })

  const showLimits = currency === Currency.USD

  const handleSubmit = useCallback(
    (values: Pick<typeof formState, 'bankAccount'>) => {
      handleValueChange(values)

      router.push(routes.transfer.wire.beneficiaryBankAccount)
    },
    [router, handleValueChange],
  )

  const handleContinueClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ContinueClicked, {
        event,
      })
    },
    [track],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <GraphQLErrorBoundary
        error={bankAccounts.error}
        retry={bankAccounts.refetch}
        variant="component"
      >
        <Center variant="horizontal">
          <Form className="w-176 font-circular" onSubmit={handleSubmit}>
            <div data-testid="bank-account-details-step-content">
              <h2 className="mb-6 text-center text-xl font-circular-bold">
                {t('wire.selectBankAccount.title')}
              </h2>

              <h3 className="mt-2 text-center text-lg leading-6 font-circular-regular">
                {showLimits
                  ? t`wire.selectBankAccount.subHeader`
                  : t`wire.selectBankAccount.subHeaderNoLimits`}
              </h3>

              {showLimits && (
                <WireTransferLimit
                  className="mt-10 border-b border-black-50 py-4"
                  type={WireTransferTypeEnum.deposit}
                />
              )}

              <Center className="mt-3" variant="horizontal">
                <BankAccountForms.Combobox.BankAccounts
                  className="w-100"
                  name="bankAccount"
                  types={bankAccountType}
                />
              </Center>

              <Center className="mt-8" variant="horizontal">
                <Form.SubmitButton
                  className="w-64"
                  onClick={handleContinueClick}
                  variant="primary"
                >
                  {t('common:continue')}
                </Form.SubmitButton>
              </Center>

              {showLimits && (
                <p className="mt-10 text-sm text-black-600">
                  <TransWithLink
                    i18nKey="modals.transfer:wire.selectBankAccount.weeklyLimit.message"
                    urlI18nKey="modals.transfer:wire.selectBankAccount.weeklyLimit.link"
                    variant="url"
                  />
                </p>
              )}

              <p
                className={classNames(
                  'text-sm font-circular-regular leading-5 text-black-600',
                  {
                    'mt-4': showLimits,
                    'mt-12': !showLimits,
                  },
                )}
              >
                <Trans
                  components={{
                    bold: <strong />,
                  }}
                  i18nKey="modals.transfer:wire.testLanding.disclaimer"
                />
              </p>
            </div>
          </Form>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { Card, DescriptionList } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'
import { CIRCLE_WALLET_APPROVAL } from '@services/permissions'
import classNames from 'classnames'

import { PolicySummaryProvider } from './PolicySummary.Context'
import { ApprovalMethod } from './Variants/ApprovalMethod/ApprovalMethod'
import { ApproverLevels } from './Variants/ApproverLevels/ApproverLevels'
import { PolicyLimit } from './Variants/PolicyLimit/PolicyLimit'
import { UserLimits } from './Variants/UserLimits/UserLimits'
import { UserPermissions } from './Variants/UserPermissions/UserPermissions'

import type { PolicySummaryProps } from './types'

export const PolicySummary: React.FC<PolicySummaryProps> = ({
  className,
  condensed,
  ...rest
}) => {
  const [, { isAuthorized: isPolicyMethodChangeEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  return (
    <PolicySummaryProvider {...rest} condensed={condensed}>
      <Card
        className={classNames('w-full font-circular', className, {
          'max-w-160 ': condensed,
        })}
      >
        <Card.Content>
          <DescriptionList
            className={classNames('grid items-start', {
              'grid-cols-3': condensed,
              'grid-cols-5': !condensed,
            })}
          >
            <UserPermissions />
            {FeatureSwitch.walletApprovalLimitsStepsEnabled() && (
              <>
                <PolicyLimit />
                <UserLimits />
              </>
            )}
            <ApproverLevels />
            {isPolicyMethodChangeEnabled && <ApprovalMethod />}
          </DescriptionList>
        </Card.Content>
      </Card>
    </PolicySummaryProvider>
  )
}

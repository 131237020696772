import { useCallback, useMemo, useState } from 'react'

import {
  FixedBanner,
  Card,
  SkeletonBox,
  Tooltip,
  Icon,
  Button,
  DescriptionList,
} from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { VAULT_MUTATION, VAULT_QUERY } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useWalletBackupStatusQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Locale } from '@circlefin/money/variants'

const { Label, Description } = DescriptionList

export interface WalletBackupProps {
  /**
   * Custom style.
   */
  className?: string
}

// We currently do not need the Wallet Backup flow.
// Keeping this in case we decide to re-enable 8 digit pin in Cybavo.
export const WalletBackup: React.FC<WalletBackupProps> = ({ className }) => {
  const { t } = useTranslation('settings')
  const [VaultMutationPermission] = usePermission(VAULT_MUTATION)
  const [VaultQueryPermission, vaultQueryPermissionStatus] =
    usePermission(VAULT_QUERY)
  const { router } = useModal()
  const { locale } = useRouter()
  const { date: formatDate } = useDate({ locale: locale as Locale })
  const [open, setOpen] = useState<boolean>(true)
  const {
    data: { walletBackupStatus } = {},
    loading,
    error,
    refetch,
  } = useWalletBackupStatusQuery({
    // do not use cached data
    fetchPolicy: 'network-only',
    skip: !vaultQueryPermissionStatus.isAuthorized,
  })

  const openWalletBackupFlow = useCallback(() => {
    void router.push(routes.vault.walletBackup.confirmation)
  }, [router])

  const handleDismiss = useCallback(() => {
    setOpen(false)
  }, [])

  const requireBackup = useMemo(
    () => Boolean(walletBackupStatus?.isWalletBackupRequired) && !error && open,
    [error, open, walletBackupStatus?.isWalletBackupRequired],
  )

  return (
    <VaultQueryPermission.Authorized>
      <FixedBanner
        onDismiss={handleDismiss}
        status="warning"
        visible={requireBackup}
      >
        <FixedBanner.Title>{t`walletSecurity.walletBackup.backup.alert.title`}</FixedBanner.Title>
        <FixedBanner.Description>{t`walletSecurity.walletBackup.backup.alert.message`}</FixedBanner.Description>
      </FixedBanner>

      <Card className={className}>
        <Card.Content className="px-8 py-6">
          <div className="flex items-center pb-5 text-xl font-circular-bold">
            <span>{t`walletSecurity.walletBackup.title`}</span>
            <Tooltip
              className="z-60"
              content={t`walletSecurity.walletBackup.tooltip`}
            >
              <span className="ml-1.5">
                <Icon
                  className="text-black-200"
                  name="InformationCircleSolid"
                  size={16}
                />
              </span>
            </Tooltip>
          </div>
          <GraphQLErrorBoundary error={error} retry={refetch}>
            <DescriptionList>
              <Label className="flex w-60 items-center">
                {t`walletSecurity.walletBackup.backup.label`}
              </Label>
              <div>
                <VaultMutationPermission.Authorized>
                  <Description className="flex items-center">
                    {t`walletSecurity.walletBackup.backup.description`}
                    <Button onClick={openWalletBackupFlow} variant="text">
                      <Icon name="ChevronRightSolid" />
                    </Button>
                  </Description>
                </VaultMutationPermission.Authorized>
                <SkeletonBox className="h-4 w-58" loading={loading}>
                  <div className="flex items-center text-black-500 type-body-sm">
                    {walletBackupStatus?.walletBackupDate && (
                      <div className="mr-1">
                        {t('walletSecurity.walletBackup.backup.date', {
                          date: formatDate(
                            walletBackupStatus.walletBackupDate,
                            'datetimeWithYear',
                          ),
                        })}
                      </div>
                    )}
                  </div>
                </SkeletonBox>
              </div>
            </DescriptionList>
          </GraphQLErrorBoundary>
        </Card.Content>
      </Card>
    </VaultQueryPermission.Authorized>
  )
}

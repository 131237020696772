import { TransactionType } from '@shared/graphql'

import type {
  CbitReceive,
  CbitPaymentDetail,
  RtpReceive,
  RtpPaymentDetail,
  XpayReceive,
  XpayPaymentDetail,
  SepaReceive,
  SepaPaymentDetail,
  SepaInstantReceive,
  SepaInstantPaymentDetail,
} from '@shared/graphql'

/**
 * Type guard to check if is a CBIT Receive Payment.
 */
export function isCbitReceiveType(
  cbitDetail?: CbitPaymentDetail,
): cbitDetail is CbitReceive | undefined {
  return cbitDetail?.payment.type === TransactionType.cbit_receive
}

/**
 * Type guard to check if is a Xpay Receive Payment.
 */
export function isXpayReceiveType(
  xpayDetail?: XpayPaymentDetail,
): xpayDetail is XpayReceive | undefined {
  return xpayDetail?.payment.type === TransactionType.xpay_receive
}

/**
 * Type guard to check if is a RTP Receive Payment.
 */
export function isRtpReceiveType(
  rtpDetail?: RtpPaymentDetail,
): rtpDetail is RtpReceive | undefined {
  return rtpDetail?.payment.type === TransactionType.rtp_receive
}

/**
 * Type guard to check if is a Sepa Receive Payment.
 */
export function isSepaReceiveType(
  sepaDetail?: SepaPaymentDetail,
): sepaDetail is SepaReceive | undefined {
  return sepaDetail?.payment.type === TransactionType.sepa_receive
}

/**
 * Type guard to check if is a Sepa Instant Receive Payment.
 */
export function isSepaInstantReceiveType(
  sepaInstantDetail?: SepaInstantPaymentDetail,
): sepaInstantDetail is SepaInstantReceive | undefined {
  return (
    sepaInstantDetail?.payment.type === TransactionType.sepa_instant_receive
  )
}

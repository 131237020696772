import { useCallback } from 'react'

import { Setup } from '@features/mfa/containers'

import { useAccountSetup } from '../../../hooks/account-setup'
import { ProgressShell } from '../ProgressShell/ProgressShell'

export const SecurityDownload: React.FC = () => {
  const [, { completeStep }] = useAccountSetup()

  const onSetup = useCallback(
    async () => await completeStep('SECURITY_DOWNLOAD'),
    [completeStep],
  )

  return (
    <ProgressShell currentStep="SECURITY_DOWNLOAD">
      <Setup onSetup={onSetup} />
    </ProgressShell>
  )
}

import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export interface MainProps {
  /**
   * Content to be displayed on Main.
   */
  children: ReactNode
  /**
   * Custom style?
   */
  className?: string
}
/**
 * Main Component
 * Help us manage main width and margin matching the designs.
 */
export const Main = forwardRef<HTMLDivElement, MainProps>(
  ({ children, className }, ref) => (
    <main ref={ref} className={className}>
      {children}
    </main>
  ),
)

Main.displayName = 'Main'

/**
 * Transfer Routes.
 */
export const transfer = {
  switch: '/transfer/',
  chooseTransferType: '/transfer/chooseTransferType',
  chooseDepositType: '/transfer/chooseDepositType',
  chooseWalletAndDepositType: '/transfer/chooseWalletAndDepositType',
  chooseWalletAndTransferType: '/transfer/chooseWalletAndTransferType',
  wire: {
    selectBankAccount: '/transfer/wire/selectBankAccount',
    selectCurrency: '/transfer/wire/selectCurrency',
    beneficiaryBankAccount: '/transfer/wire/beneficiaryBankAccount',
    pleaseTransferFunds: '/transfer/wire/pleaseTransferFunds',
    testTransferLanding: '/transfer/wire/testTransferLanding',
  },
  wallet: {
    chooseBlockchain: '/transfer/wallet/chooseBlockchain',
    transferInstructions: '/transfer/wallet/transferInstructions',
  },
  withdraw: {
    switch: '/transfer/withdraw/',
    chooseRecipient: '/transfer/withdraw/chooseRecipient',
    amount: '/transfer/withdraw/amount',
    review: '/transfer/withdraw/review',
    approvalRequired: '/transfer/withdraw/approvalRequired',
  },
  fx: {
    deposit: {
      switch: '/transfer/fx/deposit',
      form: '/transfer/fx/deposit/form',
      instructions: '/transfer/fx/deposit/instructions',
    },
    withdraw: {
      switch: '/transfer/fx/withdraw',
      form: '/transfer/fx/withdraw/form',
      review: '/transfer/fx/withdraw/review',
    },
  },
}

import { useCallback } from 'react'

import { Button, Logo, LottieIcon } from '@circlefin/components'
import { Carousel } from '@modals/layout'
import { Center } from '@shared/components/layout'
import classNames from 'classnames'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import type { LottieIconsList } from '@circlefin/components/lib/LottieIcon'

export interface FeatureTourStepProps {
  /**
   * Icon to render.
   */
  iconName: keyof typeof LottieIconsList | 'logo'
  /**
   * Main header text.
   */
  header: string
  /**
   * Subheader text.
   */
  subHeader: string
  /**
   * Link config.
   */
  link: {
    /**
     * Text to show in the Link button.
     */
    text: string
    /**
     * Url to navigate the user to.
     */
    url: string
    /**
     * Is this link external? If so it will open the link in a new window.
     */
    external?: boolean
  }
  /**
   * Disclaimer text to show.
   */
  disclaimer?: React.ReactElement
}

export const FeatureTourStep: React.FC<FeatureTourStepProps> = ({
  iconName,
  header,
  subHeader,
  link,
  disclaimer,
  ...props
}) => {
  const { t } = useTranslation('modals.common')

  const StepIcon = useCallback(() => {
    if (iconName === 'logo') {
      return (
        <div data-testid="logo">
          <Logo className="mt-1" size={5.7} wordMark={false} />
        </div>
      )
    }
    return <LottieIcon height={96} name={iconName} width={96} />
  }, [iconName])

  return (
    <Carousel title={t(`featureTour.title`)}>
      <div
        className="flex flex-col items-center justify-center text-center"
        {...props}
      >
        <p
          className={classNames(
            'mb-8 text-sm font-circular-regular leading-5 text-black-400 min-h-16',
            { invisible: !disclaimer },
          )}
          data-testid="disclaimer"
        >
          {disclaimer}
        </p>
        <div className="mb-12">
          <StepIcon />
        </div>
        <h3 className="mb-4 text-4xl leading-10 text-black-600 font-circular-bold">
          {header}
        </h3>
        <p
          className="text-lg leading-6 text-black-500"
          data-testid="sub-header"
        >
          {subHeader}
        </p>
      </div>
      <Center className="pt-6" variant="horizontal">
        <Link href={link.url} legacyBehavior passHref>
          <Button.Link
            className="mb-20 w-64"
            rel={link.external ? 'noopener noreferrer' : undefined}
            target={link.external ? '_blank' : undefined}
            variant="secondary"
          >
            {link.text}
          </Button.Link>
        </Link>
      </Center>
    </Carousel>
  )
}

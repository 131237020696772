import { TypeGuards } from '@services/type-guards'

import { CbitTransferInstructionGrid } from './CbitTransferInstructionGrid/CbitTransferInstructionGrid'
import { RtpTransferInstructionGrid } from './RtpTransferInstructionGrid/RtpTransferInstructionGrid'
import { SenTransferInstructionGrid } from './SenTransferInstructionGrid/SenTransferInstructionGrid'
import { SignetTransferInstructionGrid } from './SignetTransferInstructionGrid/SignetTransferInstructionGrid'
import { WireTransferInstructionGrid } from './WireTransferInstructionGrid/WireTransferInstructionGrid'
import { XpayTransferInstructionGrid } from './XpayTransferInstructionGrid/XpayTransferInstructionGrid'

import type {
  BankAccount,
  Currency,
  TransferInstruction,
} from '@shared/graphql'

export interface TransferInstructionGridProps {
  /**
   * Selected bank account.
   */
  bankAccount: BankAccount
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Transfer instruction.
   */
  transferInstruction?: TransferInstruction
  /**
   * Is query loading.
   */
  loading?: boolean
}

export const TransferInstructionGrid: React.FC<TransferInstructionGridProps> =
  ({ loading, bankAccount, currency, transferInstruction }) => {
    if (
      TypeGuards.TransferInstruction.isWire(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-8 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="wire-transfer-instructions"
        >
          <WireTransferInstructionGrid
            currency={currency}
            loading={loading}
            wireTransferInstruction={transferInstruction}
          />
        </div>
      )
    }

    if (
      TypeGuards.TransferInstruction.isRtp(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-8 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="rtp-transfer-instructions"
        >
          <RtpTransferInstructionGrid
            currency={currency}
            loading={loading}
            rtpTransferInstruction={transferInstruction}
          />
        </div>
      )
    }

    if (
      TypeGuards.TransferInstruction.isSen(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-6 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="sen-transfer-instructions"
        >
          <SenTransferInstructionGrid
            currency={currency}
            loading={loading}
            senTransferInstruction={transferInstruction}
          />
        </div>
      )
    }

    if (
      TypeGuards.TransferInstruction.isSignet(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-6 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="signet-transfer-instructions"
        >
          <SignetTransferInstructionGrid
            loading={loading}
            signetTransferInstruction={transferInstruction}
          />
        </div>
      )
    }

    if (
      TypeGuards.TransferInstruction.isCbit(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-6 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="cbit-transfer-instructions"
        >
          <CbitTransferInstructionGrid
            cbitTransferInstruction={transferInstruction}
            loading={loading}
          />
        </div>
      )
    }

    if (
      TypeGuards.TransferInstruction.isXpay(
        transferInstruction,
        bankAccount.type,
      )
    ) {
      return (
        <div
          className="mt-6 grid grid-cols-1 gap-x-10 gap-y-6 sm:grid-cols-2"
          data-testid="xpay-transfer-instructions"
        >
          <XpayTransferInstructionGrid
            currency={currency}
            loading={loading}
            xpayTransferInstruction={transferInstruction}
          />
        </div>
      )
    }

    return null
  }

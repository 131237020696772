import { useCallback, useState } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'
import { Choose } from '../../containers'

import type { Currency } from '@shared/graphql'

export const ChooseWalletAndTransferType: React.FC = () => {
  const [currency, setCurrency] = useState<Currency>()
  const { t } = useTranslation('modals.transfer')

  const modalHistory = useModalHistory()

  const handleCurrencySelect = useCallback((currency: Currency) => {
    setCurrency(currency)
  }, [])

  return (
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <div className="flex flex-col gap-6">
        <Center variant="horizontal">
          <h2 className="type-h-page-sm">{t`chooseWalletAndTransferType.chooseWallet`}</h2>
        </Center>
        <Center variant="horizontal">
          <Buttons.Cards.ChooseWallet
            className="w-116"
            currency={currency}
            onChooseCurrency={handleCurrencySelect}
          />
        </Center>
      </div>

      {currency != null && (
        <div className="mt-12 flex flex-col gap-6">
          <Center variant="horizontal">
            <h2 className="type-h-page-sm">{t`chooseWalletAndTransferType.chooseTransferType`}</h2>
          </Center>
          <Center variant="horizontal">
            <Choose.TransferType currency={currency} />
          </Center>
        </div>
      )}
    </FullScreen>
  )
}

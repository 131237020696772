import { useMemo, useCallback, useState, useEffect } from 'react'

import { TourGuideContent } from '../TourGuideContent/TourGuideContent'

import type {
  TourGuideStep,
  TourGuideStepRoute,
} from '../TourGuideContent/TourGuideContent'

export interface TourGuideBodyProps {
  /**
   * Current route path.
   */
  currentRoutePath: string
  /**
   * Steps (needs to be memoized).
   */
  steps: TourGuideStep[]
  /**
   * Push handler.
   */
  push: (route: TourGuideStepRoute) => Promise<unknown>
  /**
   * On done handler.
   */
  onDone: () => void
}

export const TourGuideBody: React.FC<TourGuideBodyProps> = ({
  currentRoutePath,
  push,
  steps,
  onDone,
}) => {
  // state to maintain if the current step's target has been mounted
  const [visibleTargetRendered, setVisibleTargetRendered] = useState(false)

  // step index counter within the steps that belong to a certain route
  const [currentRouteStepIndex, setCurrentRouteStepIndex] = useState(0)

  // Steps the user is authorized to see
  const authorizedSteps = useMemo(
    () => steps.filter((s) => s.isAuthorized),
    [steps],
  )

  // all the steps filtered for the current route
  const stepsForCurrentRoute = useMemo(
    () => authorizedSteps.filter((s) => s.route.pathname === currentRoutePath),
    [authorizedSteps, currentRoutePath],
  )

  // computed step index from the complete list of steps
  const currentGlobalStepIndex = useMemo(() => {
    return authorizedSteps.findIndex(
      (s) =>
        s.targetSelector ===
        stepsForCurrentRoute[currentRouteStepIndex]?.targetSelector,
    )
  }, [authorizedSteps, currentRouteStepIndex, stepsForCurrentRoute])

  const isLastStep = currentGlobalStepIndex + 1 === authorizedSteps.length

  // we want to only render the tour guide for the given step once the target is rendered
  useEffect(() => {
    if (visibleTargetRendered || stepsForCurrentRoute.length === 0) {
      return
    }

    const interval = setInterval(() => {
      const el = document.querySelector<HTMLElement>(
        stepsForCurrentRoute[currentRouteStepIndex].targetSelector,
      )

      // clear interval once the target element for the current step is found
      if (el) {
        clearInterval(interval)
        setVisibleTargetRendered(true)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [stepsForCurrentRoute, currentRouteStepIndex, visibleTargetRendered])

  const goToRoute = useCallback(
    async ({
      nextStep,
      nextCurrentRouteStepIndex,
    }: {
      nextStep: TourGuideStep
      nextCurrentRouteStepIndex: number
    }) => {
      await push(nextStep.route)
      setVisibleTargetRendered(false)
      setCurrentRouteStepIndex(nextCurrentRouteStepIndex)
    },
    [push],
  )

  const handleBack = useCallback(() => {
    // if there are more steps within the same route
    if (currentRouteStepIndex > 0) {
      setCurrentRouteStepIndex(currentRouteStepIndex - 1)
      setVisibleTargetRendered(false)
      return
    }

    // this means the next step index is no longer within this current route
    const nextIndex =
      currentGlobalStepIndex - 1 < 0 ? 0 : currentGlobalStepIndex - 1

    const nextStep = authorizedSteps[nextIndex]

    // calculate the list of steps rendered on the next route we went back to, set the current route step index to the last index
    const stepsInNextRoute = authorizedSteps.filter(
      (s) => s.route.pathname === nextStep.route.pathname,
    )
    void goToRoute({
      nextStep,
      nextCurrentRouteStepIndex: stepsInNextRoute.length - 1,
    })
  }, [
    currentGlobalStepIndex,
    currentRouteStepIndex,
    goToRoute,
    authorizedSteps,
  ])

  const handleNext = useCallback(() => {
    // if there are more steps within the same route
    if (currentRouteStepIndex < stepsForCurrentRoute.length - 1) {
      const nextIndex = currentRouteStepIndex + 1
      setCurrentRouteStepIndex(nextIndex)
      setVisibleTargetRendered(false)

      return
    }

    // this means the next step index is no longer within this current route
    const nextIndex = isLastStep
      ? currentGlobalStepIndex
      : currentGlobalStepIndex + 1

    // reset the currentStepRouteIndex to zero since we are the next route
    void goToRoute({
      nextStep: authorizedSteps[nextIndex],
      nextCurrentRouteStepIndex: 0,
    })
  }, [
    currentGlobalStepIndex,
    currentRouteStepIndex,
    goToRoute,
    isLastStep,
    authorizedSteps,
    stepsForCurrentRoute.length,
  ])

  const visibleStep = stepsForCurrentRoute[currentRouteStepIndex]

  if (visibleStep == null || !visibleTargetRendered) {
    return null
  }

  return (
    <TourGuideContent
      key={visibleStep.targetSelector}
      currentStepIndex={currentGlobalStepIndex}
      isLastStep={isLastStep}
      numOfSteps={authorizedSteps.length}
      onBack={handleBack}
      onDone={onDone}
      onNext={handleNext}
      step={visibleStep}
    />
  )
}

import { createContext } from 'react'

import type {
  Asset,
  BankAccount,
  BlockchainAbbreviation,
  VaultParentWallet,
} from '@shared/graphql'

/**
 * Transfer flow values.
 */
export interface TransferState {
  /**
   * Selected bank account.
   */
  bankAccount?: BankAccount
  /**
   * User details agreement checkbox state.
   */
  userDetailsAgreement: boolean
  /**
   * Beneficiary agreement checkbox state.
   */
  beneficiaryAgreement: boolean
  /**
   * Chosen blockchain.
   */
  chosenBlockchain?: BlockchainAbbreviation
  /**
   * If the currency value is passed as a query param to the "ChooseDepositType",
   * then we don't need to let the user select a screen within the wire transfer flow.
   * Or if a bank account is a sen account and has a home currency. The `Select Currency` step can be skipped.
   */
  showSelectCurrencyScreen?: boolean
  /**
   * Vault wallet asset.
   */
  asset?: Asset
  /**
   * Vault parent wallet.
   */
  vaultParentWallet?: VaultParentWallet
}

/**
 * Transfer flow Actions.
 */
interface Actions {
  /**
   * Value change handler.
   */
  handleValueChange: (values: Partial<TransferState>) => void
}

export const defaultValues: TransferState = {
  bankAccount: undefined,
  userDetailsAgreement: false,
  beneficiaryAgreement: false,
  chosenBlockchain: undefined,
  showSelectCurrencyScreen: true,
  asset: undefined,
  vaultParentWallet: undefined,
}

/**
 * Create Transfer Context.
 */
export const TransferContext = createContext<[TransferState, Actions]>([
  { ...defaultValues },
  {
    handleValueChange: () => null,
  },
])

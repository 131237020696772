import { LottieIcon, Modal } from '@circlefin/components'
import { Center } from '@shared/components/layout'

export interface MultiStepLayoutProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Modal title.
   */
  title: string
  /**
   * Modal subtitle.
   */
  subtitle?: string
  /**
   * Loading State?
   */
  loading?: boolean
}

/**
 * Multi-Step Modal Layout.
 */
export const MultiStep: React.FC<MultiStepLayoutProps> = ({
  children,
  title,
  subtitle,
  loading = false,
  ...props
}) => {
  return (
    <>
      <Modal.Header title={title}>{subtitle}</Modal.Header>
      <Modal.Body className="min-h-119">
        <div
          className="flex size-full flex-col px-8 pb-4"
          {...props}
          data-testid="wrapper"
        >
          {loading ? (
            <Center>
              <LottieIcon height={125} name="LoadingSpinner" width={125} loop />
            </Center>
          ) : (
            children
          )}
        </div>
      </Modal.Body>
    </>
  )
}

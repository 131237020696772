import { useCallback } from 'react'

import { TypeGuards } from '@services/type-guards'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount } from '@shared/graphql'

export const useBankAccounts = () => {
  const { t } = useTranslation()

  /**
   * Returns the bank label or a placeholder if a label has not been set.
   * @param account - BankAccount.
   */
  const getBankAccountLabel = useCallback(
    (account: BankAccount) => {
      if (!account.label) {
        if (TypeGuards.BankAccount.isSignet(account)) {
          return t('common:form.signetWithoutNicknameLabel', {
            label: account.label,
          })
        }

        if (TypeGuards.BankAccount.isCbit(account)) {
          return t('common:form.cbitWithoutNicknameLabel', {
            label: account.label,
          })
        }
      }

      return account.label
    },
    [t],
  )

  const getTrackingTag = useCallback((account: BankAccount) => {
    if (!account.trackingRef) {
      return ''
    }

    const start = account.trackingRef?.substring(0, 2) ?? 0
    const end =
      account.trackingRef?.substring(account.trackingRef.length - 4) ?? 0
    return `${start}*****${end}`
  }, [])

  return {
    getBankAccountLabel,
    getTrackingTag,
  }
}

import { Default, NotFound, Refresh, Unauthorized } from './variants'

import type { ApolloError } from '@apollo/client'

export interface PageLevelErrorProps {
  /**
   * The variant of the error that should be rendered.
   */
  variant?: 'default' | 'not-found' | 'refresh' | 'unauthorized'
  /**
   * GraphQL errors.
   */
  error?: ApolloError
}

export const PageError: React.FC<PageLevelErrorProps> = ({
  variant = 'default',
  error,
}) => {
  switch (variant) {
    case 'not-found':
      return <NotFound />
    case 'refresh':
      return <Refresh />
    case 'unauthorized':
      return <Unauthorized />
    default:
      return <Default error={error} />
  }
}

import { useMemo } from 'react'

import { Card } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { limits } from '@services/sections/lib/settings'
import { useSegment } from '@services/segment'
import { TransWithLink, currencyToToken } from '@shared/components/common'
import {
  Currency,
  WireTransferTypeEnum,
  useWireTransferLimitQuery,
} from '@shared/graphql'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export interface LimitCardProps {
  /**
   * Specify which token is selected by the user to convert too.
   */
  currency?: Currency
  /**
   * Mode referring to the use case of the card, either deposit or withdraw.
   */
  mode?: WireTransferTypeEnum
}

export const LimitCard: React.FC<LimitCardProps> = ({ currency, mode }) => {
  const { t } = useTranslation('modals.transfer')
  const { trackLinkClick } = useSegment()
  const { money } = useMoney()

  const { data: transferLimitData } = useWireTransferLimitQuery({
    variables: {
      currency: currency ?? Currency.USD,
      type: mode ?? WireTransferTypeEnum.deposit,
    },
  })

  const remainingLimit = useMemo(() => {
    if (transferLimitData?.wireTransferLimit == null) return ''
    return money({
      number: transferLimitData.wireTransferLimit.available.amount,
      variant: currencyToToken(
        transferLimitData.wireTransferLimit.available.currency,
      ),
    })
  }, [money, transferLimitData?.wireTransferLimit])

  const remainingLimitWeekly = useMemo(() => {
    if (transferLimitData?.wireTransferLimit == null) return ''
    return money({
      number: transferLimitData.wireTransferLimit.cap.amount,
      variant: currencyToToken(
        transferLimitData.wireTransferLimit.cap.currency,
      ),
    })
  }, [money, transferLimitData?.wireTransferLimit])

  return (
    <Card>
      <Card.Content>
        <div className="flex items-center justify-between">
          <div className="mb-2 text-sm text-neutral-strong type-body-base">
            {t`limitCard.remainingLimit`}
          </div>
          <div className="text-neutral type-body-sm">{remainingLimit}</div>
        </div>
        <div className="mb-2 flex items-center justify-between">
          <div className="mb-2 text-sm text-neutral-strong type-body-base">
            {t`limitCard.weeklyLimit`}
          </div>
          <div className="text-neutral type-body-sm">
            {remainingLimitWeekly}
          </div>
        </div>
        <div className="mb-3 flex items-center justify-end">
          <Link
            href={limits.route}
            onClick={trackLinkClick}
          >{t`limitCard.buttonText`}</Link>
        </div>
        <p
          className="text-sm leading-5 text-black-500 font-circular-regular"
          data-testid="feeSchedule"
        >
          <span>
            <TransWithLink
              i18nKey="modals.transfer:wire.selectBankAccount.weeklyLimit.message"
              urlI18nKey="modals.transfer:wire.selectBankAccount.weeklyLimit.link"
              variant="url"
            />
          </span>
        </p>
      </Card.Content>
    </Card>
  )
}

import { ModalRoute } from '@circlefin/modal-router'
import {
  IssuerQRCode,
  MetamaskWarning,
  VerifierQRCode,
} from '@features/verite/modals'
import { verite } from '@services/sections/modal/verite/verite'

/**
 * Verite Router.
 */
export const VeriteRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<IssuerQRCode />} path={verite.issuer.challenge} />

      <ModalRoute
        content={<VerifierQRCode />}
        path={verite.verifier.challenge}
      />

      <ModalRoute
        content={<MetamaskWarning />}
        path={verite.metamask.warning}
      />
    </>
  )
}

import type { ReactNode } from 'react'

import classNames from 'classnames'

export interface HeaderProps {
  children: ReactNode
  className?: string
}

export const Header = ({ children, className }: HeaderProps) => (
  <h2 className={classNames('mb-4', className)} data-testid="disclosure-header">
    {children}
  </h2>
)

Header.displayName = 'Disclosures.Header'

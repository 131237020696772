import type { MutationUpdaterFn } from '@apollo/client'
import type { SetWatchListEntryMutation } from '@shared/graphql'

/**
 * Update active value from WatchList Entry.
 */
export const setWatchListEntry: MutationUpdaterFn<SetWatchListEntryMutation> = (
  cache,
  { data },
) => {
  if (data?.setWatchListEntry) {
    cache.modify({
      // find the WatchListEntry item with the id that was modified
      id: cache.identify({
        id: data.setWatchListEntry.id,
        __typename: 'WatchlistEntry',
      }),
      fields: {
        active() {
          // update the active value to the one that was returned
          return data.setWatchListEntry.active
        },
      },
    })
  }
}

import { useContext, useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import { IN_APP_NOTIFICATION_SECTION } from '@services/permissions'
import {
  FORBIDDEN,
  UNAUTHORIZED,
  USER_PERMISSION,
  isErrorCode,
} from '@shared/apollo/lib/error/codes'
import { LongPollInAppNotificationDocument } from '@shared/graphql'
import { UseLongPollingSignal, useLongPollingQuery } from '@shared/hooks/common'
import { useSession } from 'next-auth/react'

import { InAppNotificationsContext } from './context'

import type { InAppNotificationsContextState } from './context'
import type { LongPollInAppNotificationQuery } from '@shared/graphql'

export interface InAppNotificationProviderProps {
  /**
   * Children.
   */
  children?: React.ReactNode
}

export const InAppNotificationProvider: React.FC<InAppNotificationProviderProps> =
  ({ children }) => {
    const session = useSession()
    const [, permissionRes] = usePermission(IN_APP_NOTIFICATION_SECTION)

    const skip =
      !permissionRes.isAuthorized || session.status !== 'authenticated'

    const { data } = useLongPollingQuery<LongPollInAppNotificationQuery>({
      skip,
      document: LongPollInAppNotificationDocument,
      onError: ({ graphQLErrors }) => {
        if (
          isErrorCode(FORBIDDEN, graphQLErrors) ||
          isErrorCode(UNAUTHORIZED, graphQLErrors) ||
          isErrorCode(USER_PERMISSION, graphQLErrors)
        ) {
          return UseLongPollingSignal.stopPolling
        }
      },
    })

    const state: InAppNotificationsContextState = useMemo(
      () => ({
        notification: data?.longPollInAppNotification,
      }),
      [data?.longPollInAppNotification],
    )

    return (
      <InAppNotificationsContext.Provider value={state}>
        {children}
      </InAppNotificationsContext.Provider>
    )
  }

export const useInAppNotificationContext = () => {
  const context = useContext(InAppNotificationsContext)

  if (context === undefined) {
    throw new Error(
      'useInAppNotificationsContext must be used within a InAppNotificationProvider',
    )
  }

  return context
}

import { useMemo } from 'react'

import { VaultUserPolicyRoleEnum } from '@shared/graphql'

import { useCreateVault } from '../../../../hooks/create'

export const usePermissionValidate = () => {
  const [{ usersPolicy = [], touched }] = useCreateVault()

  const atLeastOneApprover = useMemo(
    () =>
      usersPolicy.some(({ permissions }) =>
        permissions.includes(VaultUserPolicyRoleEnum.APPROVER),
      ),
    [usersPolicy],
  )

  const atLeastOneInitiator = useMemo(
    () =>
      usersPolicy.some(({ permissions }) =>
        permissions.includes(VaultUserPolicyRoleEnum.OPERATOR),
      ),
    [usersPolicy],
  )

  const isValid = atLeastOneInitiator
  const isEmpty = usersPolicy.length === 0
  const isTouched = touched?.usersPolicy

  return {
    isValid,
    isEmpty,
    atLeastOneApprover,
    atLeastOneInitiator,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

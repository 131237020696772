import { Card, Icon } from '@circlefin/components'
import { Assets } from '@features/blockchain/components'
import { vaultDetailsSection } from '@services/sections/lib/vaults'
import { VaultStateEnum } from '@shared/graphql'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'
import { VaultPermissionProvider } from '../../hooks/permission/provider'
import { VaultChips } from '../VaultChips/VaultChips'

import { VaultCardBalance } from './VaultCardBalance/VaultCardBalance'

import type { Vault } from '@shared/graphql'

export interface VaultCardProps {
  /**
   * The vault that should be displayed.
   */
  vault: Vault
}

export const VaultCard: React.FC<VaultCardProps> = ({ vault }) => {
  const { t } = useTranslation('vaults')

  const showBalance = ![
    VaultStateEnum.SETUP_IN_PROGRESS,
    VaultStateEnum.READY_FOR_APPROVAL,
    VaultStateEnum.PENDING_APPROVAL,
  ].includes(vault.state)

  return (
    <VaultPermissionProvider vaultMetadata={vault}>
      <li className="list-none">
        <Card>
          <Card.Content>
            <div className="grid w-full grid-cols-3 items-center gap-4">
              <div className="flex flex-col items-start justify-center">
                <Link
                  className="truncate text-2xl leading-9 text-black-800 font-circular-regular"
                  href={{
                    pathname: vaultDetailsSection.route,
                    query: { vaultId: vault.id },
                  }}
                  title={vault.name}
                >
                  {vault.name}
                </Link>
                <div className="mt-3 flex w-full items-center space-x-2">
                  {
                    /**
                     * Vaults that are still in draft will not have a balance yet.
                     */
                    showBalance && (
                      <>
                        <VaultCardBalance
                          data-testid="vault-card-balance"
                          vaultId={vault.id}
                        />
                        <span className="ml-2 size-2 rounded-full bg-black-100" />
                      </>
                    )
                  }
                  <Assets
                    assets={vault.sortedAssets.map((asset) => ({
                      symbol: asset.symbol,
                      icon: asset.icon,
                    }))}
                    visibleCount={showBalance ? 2 : 5}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <VaultChips state={vault.state} stacked />
                <span className="text-black-400">
                  {vault.isCreator ? t`vaultCard.creator` : null}
                </span>
              </div>
              <div className="flex justify-end space-x-6">
                <Buttons.Vault.CompleteSetup variant="primary" />
                <Buttons.Vault.Deposit />
                <Buttons.Vault.Transfer />
                <Link
                  key="details"
                  className="flex items-center justify-center bg-transparent p-2 text-blue-500 hover:text-blue-400"
                  href={{
                    pathname: vaultDetailsSection.route,
                    query: { vaultId: vault.id },
                  }}
                >
                  <Icon name="ChevronRightSolid" size={20} />
                </Link>
              </div>
            </div>
          </Card.Content>
        </Card>
      </li>
    </VaultPermissionProvider>
  )
}

import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { usePermission } from '@circlefin/permissions'
import { useCountries } from '@features/locales/hooks/countries'
import { useStates } from '@features/locales/hooks/states'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { ROLR_REDEMPTION_ONLY_ACCOUNT } from '@services/permissions'
import { Center } from '@shared/components/layout'
import { CountryCode, StateCode } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../Link.Context'

import type { Country } from '@shared/graphql'

const schema = y.object({
  /**
   * Bank Country.
   */
  country: y.mixed<CountryCode>().oneOf(Object.values(CountryCode)).required(),
  /**
   * Bank City.
   */
  city: y.string().when('country', {
    is: (country: CountryCode) => !['US'].includes(country), // exclude from the list
    then: (schema) => schema.required(),
    otherwise: (schema) => schema,
  }),
  /**
   * Canadian Province.
   */
  province: y
    .mixed<StateCode>()
    .oneOf(Object.values(StateCode))
    .when('country', {
      is: (country: CountryCode) => ['CA'].includes(country), // include in the list
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
})

export type FormValues = y.InferType<typeof schema>

export interface LocationFormSubmit extends Omit<FormValues, 'country'> {
  /**
   * Full Country Config.
   */
  country?: Country
}

export interface LocationFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: LocationFormSubmit) => void
}

export const LocationForm: React.FC<LocationFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ location }, { setLocation }] = useLinkBankAccount()
  const { travelRuleRequired } = useTravelRuleRequired()
  const [, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )

  const defaultCountry = useMemo(() => {
    if (travelRuleRequired) return CountryCode.SG
    if (isRolr) return CountryCode.FR // EEA country is required for ROLR customer, use FR as default since US is not EEA
    return CountryCode.US
  }, [travelRuleRequired, isRolr])

  const [Form, { watch }] = useForm<FormValues>({
    schema,
    values: {
      ...location,
      country: location?.country?.code ?? defaultCountry,
    },
  })

  const { countries, data, loading } = useCountries({
    link: {
      locatedIn: true,
    },
    ...(isRolr
      ? {
          onboarding: {
            eea: true,
          },
        }
      : {}),
  })

  const { states, loading: statesLoading } = useStates({
    country: CountryCode.CA,
  })

  const handleSubmit = useCallback(
    (values: FormValues) => {
      // Prepare form data
      const formData: LocationFormSubmit = {
        ...values,
        // Find selected country and get full config
        country: data?.countries.list.find(
          (country) => country.code === values.country,
        ),
      }

      // Set data to context
      setLocation(formData)

      // Pass data to submit callback (for testing)
      onSubmit?.(formData)
    },
    [data?.countries.list, onSubmit, setLocation],
  )

  return (
    <Form className="grid w-104 gap-6" onSubmit={handleSubmit}>
      <Form.Dropdown
        data-testid="dropdown-country"
        inputLoading={loading}
        items={countries.toDropdown()}
        label={t('link.location.country')}
        maxMenuItems={6}
        message={
          watch('country') !== CountryCode.US
            ? t`link.location.nonUsBankInfo`
            : ''
        }
        name="country"
      />

      {/* City for any non-US */}
      {watch('country') !== CountryCode.US && (
        <Form.Input
          className="w-full"
          data-testid="input-city"
          label={t`link.location.city`}
          name="city"
        />
      )}

      {/* Province for Canada */}
      {watch('country') === CountryCode.CA && (
        <Form.Dropdown
          data-testid="dropdown-province"
          inputLoading={statesLoading}
          items={states.toDropdown()}
          label={t`link.location.province`}
          maxMenuItems={3}
          name="province"
        />
      )}

      <Center className="pt-12" variant="horizontal">
        <Form.SubmitButton className="w-60" variant="primary">
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModalHistory } from '@circlefin/modal-router'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const ModalBackButton: React.FC = () => {
  const { t } = useTranslation('common')
  const { track } = useSegment()
  const { back, history } = useModalHistory()

  const handleBack: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      track(SegmentEvents.BackClicked, {
        event: e,
      })
      back()
    },
    [track, back],
  )

  if (history.length === 0) {
    return null
  }

  return (
    <Button className="mr-2.5 w-18" onClick={handleBack} variant="secondary">
      {t('back')}
    </Button>
  )
}

import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { TransWithLink } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

export const Error: React.FC = () => {
  const { t } = useTranslation('address-book/modals')
  const { router } = useModal()

  const handleDone = useCallback(() => {
    router.push({
      pathname: routes.account.addressBook.addNewAddress.customer,
      options: {
        disableBack: true,
      },
    })
  }, [router])

  return (
    <FullScreen>
      <Statement
        status="warning"
        subtitle={
          <TransWithLink
            i18nKey="address-book/modals:addAddress.error.subtitle"
            urlI18nKey="address-book/modals:addAddress.error.subtitleUrl"
            variant="url"
          />
        }
        title={t`addAddress.error.title`}
      >
        <Button onClick={handleDone} variant="primary">
          {t`addAddress.error.btn`}
        </Button>
      </Statement>
    </FullScreen>
  )
}

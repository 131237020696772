import { FullScreen } from '@modals/layout'
import { TransWithLink } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../Link.Context'

import { LocationForm } from './Form/Location.Form'

export const Location: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ steps }] = useLinkBankAccount()

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-8 text-center" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`link.location.title`}
        </h2>

        <p className="w-160">
          <TransWithLink
            i18nKey="modals.bankAccount:link.location.description"
            urlI18nKey="modals.bankAccount:link.location.link"
            variant="url"
          />
        </p>
      </Center>

      <Center variant="horizontal">
        <LocationForm />
      </Center>
    </FullScreen>
  )
}

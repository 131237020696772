import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import { PolicySummary } from '../../../../containers'
import { CreateVaultNavigationStep } from '../../../../containers/Create'
import { useCreateVaultSave } from '../../../../containers/CreateVault'
import { useCreateVault } from '../../../../hooks/create'
import * as VaultLayout from '../../../../layout'

import type { PolicySummaryProps } from '../../../../containers/PolicySummary/types'

export const Policy: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const [save, { error, loading, reset }] = useCreateVaultSave({
    onCompleted: () => {
      modal.router.push(routes.vault.createVault.finished)
    },
    // To avoid uncaught error
    onError: () => null,
  })

  const handleEditClick = useCallback<
    Required<PolicySummaryProps>['onEditClick']
  >((step) => modal.router.push(`/vault/createVault/${step}`), [modal.router])

  const [vaultState] = useCreateVault()

  return (
    <VaultLayout.CreateVault currentStep={CreateVaultNavigationStep.REVIEW}>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <div className="grid max-w-176 grid-cols-1 justify-items-start">
          <h2 className="mb-1 text-4xl text-black-600 font-circular-bold">
            {t('createVault.review.policy.title')}
          </h2>

          <PolicySummary
            className="mt-8"
            {...vaultState}
            onEditClick={handleEditClick}
            condensed
            editEnabled
          />

          <div className="mt-4">
            <ModalBackButton />

            <Button
              className="w-60"
              loading={loading}
              onClick={save}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>
        </div>
      </GraphQLErrorBoundary>
    </VaultLayout.CreateVault>
  )
}

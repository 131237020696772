import { useCallback, useState } from 'react'

import { y } from '@circlefin/form'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import {
  Currency,
  useCircleWalletsLazyQuery,
  useFxQuoteLazyQuery,
  WireTransferTypeEnum,
} from '@shared/graphql'

import { TransferWithFxContext } from './context'
import { getDefaultRail, isSameCurrency } from './helpers'

import type { BankAccount, FxQuote, GetFxQuoteInput } from '@shared/graphql'

export interface TransferWithFxProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Mode, either deposit or withdrawal. Helps the context provider determine some default variables
   * and to know which flow we're on.
   */
  mode: WireTransferTypeEnum
}

export const schema = y.object({
  mode: y.string().oneOf(['withdrawal', 'deposit']).notRequired(),
  fromAmount: y.string().required(),
  fromCurrency: y.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  toAmount: y.string().required(),
  toCurrency: y.mixed<Currency>().oneOf(Object.values(Currency)).required(),
  account: y.mixed<BankAccount>(),
  rail: y.string().required(),
  checkbox: y.boolean().when(['mode', 'fromCurrency', 'toCurrency'], {
    is: (mode: string, fromCurrency: Currency, toCurrency: Currency) => {
      return mode === 'deposit' && !isSameCurrency(fromCurrency, toCurrency)
    },
    then: (schema) => schema.oneOf([true]),
    otherwise: (schema) => schema.notRequired(),
  }),
})

export type FxFormValues = y.InferType<typeof schema>

export const TransferWithFxProvider: React.FC<TransferWithFxProviderProps> = ({
  children,
  mode,
}) => {
  const [latestQuote, setLatestQuote] = useState<FxQuote>()
  const [latestBalance, setLatestBalance] = useState<string>()
  const [formValues, setFormValues] = useState<FxFormValues>()
  const [, { tokenToCurrency }] = useCurrentCurrency()

  const [fetchBalances] = useCircleWalletsLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const setFormValuesWrapper = useCallback(
    (values: Partial<FxFormValues>) => {
      // Updates the form state with any partial formstate input.
      if (formValues) {
        setFormValues({ ...formValues, ...values })
      }
    },
    [formValues],
  )

  const [refetchQuote, { loading, called, error: quoteError }] =
    useFxQuoteLazyQuery({
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    })

  const refetch = useCallback(
    (input: GetFxQuoteInput) => {
      void refetchQuote({
        variables: {
          input,
        },
        onCompleted: (data) => {
          setLatestQuote(data.fxQuote)
          setFormValuesWrapper({
            fromAmount: data.fxQuote.from.amount,
            fromCurrency: data.fxQuote.from.currency,
            toAmount: data.fxQuote.to.amount,
            toCurrency: data.fxQuote.to.currency,
          })
        },
      })
    },
    [refetchQuote, setFormValuesWrapper],
  )

  const initForm = useCallback(
    (fixedCurrency: Currency) => {
      // First if we already have a quote we must have already been initialized.
      if (latestQuote) return

      // Get latest wallet balance info
      void fetchBalances({
        onCompleted(data) {
          setLatestBalance(
            data.circleWallets.find((bal) => {
              return bal.balance.currency === fixedCurrency
            })?.balance.amount,
          )
        },
      })

      // If we do need to initialize, the first thing we need is an initial quote.
      void refetchQuote({
        variables: {
          input: {
            from: {
              amount: '0',
              currency:
                mode === WireTransferTypeEnum.withdrawal
                  ? fixedCurrency
                  : tokenToCurrency(fixedCurrency),
            },
            to: {
              currency:
                mode === WireTransferTypeEnum.deposit
                  ? fixedCurrency
                  : tokenToCurrency(fixedCurrency),
            },
          },
        },
        onCompleted: (data) => {
          // Once fetched, we store this quote in state.
          const { fxQuote: quote } = data
          setLatestQuote(quote)

          // We also use this initial quote to determine some initial form values to show the user.
          setFormValues({
            mode,
            fromAmount: quote.from.amount ?? '',
            fromCurrency:
              mode === WireTransferTypeEnum.withdrawal
                ? fixedCurrency
                : quote.from.currency ?? Currency.USD,
            toAmount: quote.to.amount ?? '',
            toCurrency:
              mode === WireTransferTypeEnum.deposit
                ? fixedCurrency
                : quote.to.currency ?? Currency.USD,
            account: formValues?.account ?? undefined,
            rail:
              formValues?.rail ??
              getDefaultRail({
                account: formValues?.account,
                isDepositMode: mode === WireTransferTypeEnum.deposit,
              }),
            checkbox: formValues?.checkbox ?? false,
          })
        },
      })
    },
    [
      fetchBalances,
      formValues?.account,
      formValues?.checkbox,
      formValues?.rail,
      latestQuote,
      mode,
      refetchQuote,
      tokenToCurrency,
    ],
  )

  return (
    <TransferWithFxContext.Provider
      value={{
        refetch,
        quote: latestQuote,
        loading: loading || !called,
        initForm,
        formValues,
        setFormValues: setFormValuesWrapper,
        balance: latestBalance,
        quoteError,
      }}
    >
      {children}
    </TransferWithFxContext.Provider>
  )
}

import { Card, SkeletonBox, Chip, DescriptionList } from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { TransactionStatus } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { GraphQLErrorBoundary } from '../../errors'

import type { ApolloError } from '@apollo/client'
import type { ChipProps } from '@circlefin/components/lib/Chip'
import type { Payment } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface TransactionCardProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * The transaction that should be displayed.
   */
  transaction?: Payment
  /**
   * Indicate whether a loading state should be shown.
   */
  loading?: boolean
  /**
   * We will display an Error if an error is passed.
   */
  error?: ApolloError
  /**
   * Optional callback which will re-trigger a fetch.
   */
  onRetry?: () => void
  /**
   * Additional classes that will be applied to the element.
   */
  className?: string
}

export const TransactionCard: React.FC<TransactionCardProps> = ({
  className,
  transaction,
  children,
  loading = false,
  error,
  onRetry,
}) => {
  const { t } = useTranslation('common')
  const { money } = useMoney()
  const { date } = useDate()

  const tagVariants: Record<string, ChipProps['variant']> = {
    [TransactionStatus.complete]: 'status/neutral',
    [TransactionStatus.pending]: 'status/neutral',
    [TransactionStatus.failed]: 'status/error',
  }

  return (
    <Card className={className}>
      <Card.Content className="px-10 pb-4 pt-8">
        <GraphQLErrorBoundary error={error} retry={onRetry}>
          <div data-testid="transaction-card">
            <div className="flex">
              <div>
                <SkeletonBox className="h-9 w-64" loading={loading}>
                  <div className="h-9 text-3xl leading-9 text-black-600 font-circular-medium">
                    <span>
                      <SkeletonBox loading={loading}>
                        {transaction &&
                          money({
                            number: transaction.amount.amount,
                            variant: transaction.amount.currency,
                            options: {
                              symbol: false,
                              signDisplay: true,
                            },
                          })}
                      </SkeletonBox>
                    </span>
                    <span className="ml-2 text-xl leading-7 text-black-200 font-circular-black">
                      {transaction?.amount.currency}
                    </span>
                  </div>
                </SkeletonBox>
                <div className="mt-1 text-xl leading-7 text-black-200 font-circular-regular">
                  {t('transactionCard.amount')}
                </div>
              </div>
              <div className="ml-auto text-base leading-7 text-black-500 font-circular-bold">
                <SkeletonBox className="h-7 w-80" loading={loading}>
                  <span>
                    {t('transactionCard.summaryAsOf')}{' '}
                    {transaction &&
                      date(transaction.createDate, 'datetimeWithYear')}
                  </span>
                </SkeletonBox>
              </div>
            </div>
            <div className="mt-10 pb-2 text-2xl leading-8 font-circular-book">
              {t('transactionCard.details')}
            </div>
            <DescriptionList className="auto-rows-min gap-y-1">
              <Label className="w-64">{t('transactionCard.type')}</Label>
              <Description>
                <SkeletonBox className="h-6 w-56" loading={loading}>
                  {transaction &&
                    t(`transaction.typeLabels.${transaction.type}`)}
                </SkeletonBox>
              </Description>
              <Label className="w-64">{t('transactionCard.status')}</Label>
              <Description>
                <SkeletonBox className="h-6 w-48" loading={loading}>
                  {transaction && (
                    <Chip
                      className="text-base"
                      variant={tagVariants[transaction.status]}
                    >
                      {t(`transaction.statusLabels.${transaction.status}`)}
                    </Chip>
                  )}
                </SkeletonBox>
              </Description>
              {children}
            </DescriptionList>
          </div>
        </GraphQLErrorBoundary>
      </Card.Content>
    </Card>
  )
}

import { useCallback, useMemo, useState } from 'react'

import { y, useFormContext } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useVaultWalletsQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type {
  ComboboxOptionsLocale,
  ComboboxProps,
} from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { VaultWalletWithVaultMetadata } from '@shared/graphql'

type ParentWalletItem = SelectListItem<VaultWalletWithVaultMetadata>

export const ParentWalletsSchema = y.mixed<VaultWalletWithVaultMetadata>()

export interface ParentWalletsProps
  extends Pick<
    ComboboxProps<VaultWalletWithVaultMetadata>,
    'className' | 'label' | 'placeholder' | 'disabled' | 'message'
  > {
  /**
   * Parent wallet id used to pre-select dropdown.
   */
  parentWalletId?: string
  /**
   * Name of the form field.
   */
  name: string
  /**
   * OnChange handler.
   */
  onChange?: (parentWallet: ParentWalletItem) => void
}

const Combobox = createFormCombobox()

export const ParentWallets: React.FC<ParentWalletsProps> = ({
  className,
  label,
  placeholder,
  parentWalletId,
  disabled,
  message,
  name,
  onChange,
}) => {
  const { t } = useTranslation('forms')
  const schema = useMemo(
    () =>
      y.object({
        /**
         * Parent wallets.
         */
        [name]: ParentWalletsSchema,
      }),
    [name],
  )

  const { setValue } = useFormContext<y.InferType<typeof schema>>()

  const parentWallets = useVaultWalletsQuery({
    onCompleted: (data) => {
      // If a parentWalletId is provided, auto-select that item (next-tick to prevent Formik errors)
      setTimeout(() => {
        if (parentWalletId) {
          setValue(
            name,
            data.vaultWallets.find((wallet) => wallet.id === parentWalletId),
          )
        }
      }, 0)
    },
  })

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  // Parent Wallets list
  const parentWalletsList: ParentWalletItem[] = useMemo(() => {
    return parentWallets.data
      ? parentWallets.data.vaultWallets.map((parentWallet) => ({
          value: parentWallet,
          label: parentWallet.name,
          tag: parentWallet.vaultMetadata?.name,
          icon: parentWallet.icon,
        }))
      : []
  }, [parentWallets.data])

  const filteredList = useMemo(() => {
    if (debouncedSearchTerm) {
      return matchSorter(parentWalletsList, debouncedSearchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [debouncedSearchTerm, parentWalletsList])

  const handleOnChange = useCallback(
    (item: ParentWalletItem | null) => {
      if (item === null) return

      setSearchTerm('')
      setDebouncedSearchTerm('')
      onChange?.(item)
    },
    [setSearchTerm, onChange],
  )

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.vault.parentWallets.noItemsMessage`,
    }),
    [t],
  )

  return (
    <div className={className} data-testid="vault-parent-wallets-combobox">
      <Combobox
        debounce={300}
        disabled={disabled}
        filtered={filteredList}
        inputLoading={parentWallets.loading}
        items={parentWalletsList}
        label={label ?? t`combobox.vault.parentWallets.label`}
        locale={locale}
        maxMenuItems={6}
        message={message}
        name={name}
        onChange={handleOnChange}
        onDebouncedInputChange={setDebouncedSearchTerm}
        onInputChange={setSearchTerm}
        placeholder={placeholder ?? t`combobox.vault.parentWallets.placeholder`}
        searchTerm={searchTerm}
      />
    </div>
  )
}

import type { ComponentType } from 'react'
import { useCallback } from 'react'
import type { FallbackProps } from 'react-error-boundary'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { useDatadogRum } from '@circlefin/datadog'

import { GenericPageError } from '../GenericPageError/GenericPageError'

export interface ErrorBoundaryProps {
  /**
   * Fallback component.
   */
  FallbackComponent?: ComponentType<FallbackProps>
  /**
   * Perform action on reset.
   */
  onReset?: () => Promise<void> | void
  /**
   * Children.
   */
  children: React.ReactNode
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  FallbackComponent,
  onReset,
  children,
}) => {
  const { addError } = useDatadogRum()

  const onError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      if (error != null) {
        const renderingError = new Error('Unhandled page error')
        renderingError.name = `ReactRenderingError`
        renderingError.stack = info.componentStack
        renderingError.cause = error

        // TODO - Ensure we are not exposing PII before logging
        // @ts-expect-error - cause.message
        renderingError.cause.message = '<REDACTED>'

        addError(renderingError)
      }
    },
    [addError],
  )

  const handleReset = useCallback(() => {
    // if custom onReset passed
    if (onReset != null) {
      void onReset()
      return
    }

    // else default to reload page
    window.location.reload()
  }, [onReset])

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent ?? GenericPageError}
      onError={onError}
      onReset={handleReset}
    >
      {children}
    </ReactErrorBoundary>
  )
}

import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { DepositForm } from './Deposit.Form'

import type { DepositFormValues } from './Deposit.Form'

export interface DepositQueryProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: DepositFormValues) => void
}

export const Deposit: React.FC<DepositQueryProps> = ({ onSubmit }) => {
  const { t } = useTranslation('express/modal')
  const { travelRuleRequired, loading } = useTravelRuleRequired()

  return (
    <FullScreen totalSteps={5}>
      <Center className="mb-8 text-center" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`addRoute.deposit.title`}
        </h2>
        <p className="w-160">{t`addRoute.deposit.subtitle`}</p>
      </Center>

      <Center variant="horizontal">
        <DepositForm
          loading={loading}
          onSubmit={onSubmit}
          travelRuleRequired={travelRuleRequired}
        />
      </Center>
    </FullScreen>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * NOBLE blockchain USDC access permission.
 */
export const NOBLE_BLOCKCHAIN_WITHDRAWAL: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [
    SynthRole.SYNTH_NOBLE_BLOCKCHAIN_ROLE,
    SynthRole.SYNTH_NOBLE_WITHDRAWAL_BLOCKCHAIN_ROLE,
  ],
}

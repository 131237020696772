import { useCallback } from 'react'

import { Card, SkeletonBox, Chip, DescriptionList } from '@circlefin/components'
import { useCustomMoney } from '@circlefin/hooks'
import { vaultDetailsSection } from '@services/sections/lib/vaults'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { MaybeNull, CollapsibleBadge } from '@shared/components/common'
import {
  VaultWalletState,
  useVaultWalletBalanceQuery,
  useVaultWalletQuery,
  TokenActivationState,
} from '@shared/graphql'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import * as Badges from './../Badges'

import type { ChipProps } from '@circlefin/components/lib/Chip'
import type { VaultWalletQuery } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface VaultWalletInformationProps {
  /**
   * Vault id.
   */
  vaultId: string
  /**
   * Vault wallet id.
   */
  walletId: string
}

export const VaultWalletInformation: React.FC<VaultWalletInformationProps> = ({
  vaultId,
  walletId,
}) => {
  const { t } = useTranslation('vaults')
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const wallet = useVaultWalletQuery({
    variables: {
      vaultId,
      walletId,
    },
  })
  const balance = useVaultWalletBalanceQuery({
    variables: {
      vaultId,
      walletId,
    },
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
  })

  const renderStatus = useCallback(
    (vaultWallet: VaultWalletQuery['vaultWallet']) => {
      const { frozen, archived, state, tokenActivationState } = vaultWallet

      const tagVariant: Record<VaultWalletState, ChipProps['variant']> = {
        [VaultWalletState.ACTIVE]: 'default/success',
        [VaultWalletState.DRAFT]: 'default/warning',
        [VaultWalletState.PENDING_APPROVAL]: 'default/info',
      }

      if (archived) {
        return (
          <CollapsibleBadge
            collapsedText={t`vaultChildWalletRow.archived.collapsed`}
            variant="disabled"
          >
            {t`vaultChildWalletRow.archived.notCollapsed`}
          </CollapsibleBadge>
        )
      }

      if (frozen) {
        return (
          <CollapsibleBadge
            collapsedText={t`vaultChildWalletRow.frozen.collapsed`}
            variant="alert"
          >
            <span>
              <Trans
                components={{ b: <b /> }}
                i18nKey="vaults:vaultChildWalletRow.frozen.notCollapsed"
              />
            </span>
          </CollapsibleBadge>
        )
      }

      if (tokenActivationState === TokenActivationState.PENDING_ACTIVATION) {
        return <Badges.Wallet.PendingActivation />
      }

      if (tokenActivationState === TokenActivationState.NOT_ACTIVATED) {
        return (
          <Badges.Wallet.ActivationRequired
            blockchain={vaultWallet.blockchain}
            parentWalletId={vaultWallet.parentWalletId ?? ''}
            walletId={vaultWallet.id}
          />
        )
      }

      if (state != null) {
        return (
          <Chip className="text-sm" variant={tagVariant[state]}>
            {t(`vaultWallets.detail.state.${state}`)}
          </Chip>
        )
      }
    },
    [t],
  )

  const { address, blockchain, assetMetadata, vaultMetadata } =
    wallet.data?.vaultWallet ?? {}

  const { fiat, native } = balance.data?.vaultWalletBalance ?? {}

  return (
    <Card>
      <Card.Content className="px-10 py-4 font-circular text-black-600">
        <h2 className="ml-4 mt-4 pb-2 text-xl leading-8 font-circular-book">
          {t`vaultWallets.detail.information.title`}
        </h2>

        <DescriptionList
          className="pl-4 pt-5"
          data-testid="wallet-description-list"
          density="loose"
        >
          <Label className="w-72">{t`vaultWallets.detail.information.labels.status`}</Label>
          <Description>
            <SkeletonBox className="h-5 w-20" loading={wallet.loading}>
              {wallet.data && renderStatus(wallet.data.vaultWallet)}
            </SkeletonBox>
          </Description>

          <Label className="w-72">{t`vaultWallets.detail.information.labels.asset`}</Label>
          <Description>
            <SkeletonBox className="h-8 w-20" loading={wallet.loading}>
              <div className="flex flex-col">
                <span>{assetMetadata?.name}</span>
                <span className="text-black-300 font-circular-regular">
                  {assetMetadata?.symbol}
                </span>
              </div>
            </SkeletonBox>
          </Description>

          <Label className="w-72">{t`vaultWallets.detail.information.labels.balance`}</Label>
          <Description>
            <SkeletonBox className="h-8 w-20" loading={balance.loading}>
              <MaybeNull>
                {fiat && native && (
                  <div className="flex flex-col">
                    <span>
                      {customMoney({
                        number: native.amount,
                        currencyConfig: {
                          name: native.asset.symbol,
                          decimals: native.asset.decimals,
                          isCryptoCurrency: true,
                        },
                        options: { padded: false },
                      })}
                    </span>
                    <span className="text-black-300 font-circular-regular">
                      {customMoney({
                        number: fiat.amount,
                        currencyConfig: {
                          name: fiat.asset.symbol,
                          decimals: fiat.asset.decimals,
                        },
                      })}
                    </span>
                  </div>
                )}
              </MaybeNull>
            </SkeletonBox>
          </Description>

          <Label className="w-72">{t`vaultWallets.detail.information.labels.blockchain`}</Label>
          <Description>
            <SkeletonBox className="h-5 w-20" loading={wallet.loading}>
              {t(`payments:chainNames.${blockchain ?? ''}`)}
            </SkeletonBox>
          </Description>

          <Label className="w-72">{t`vaultWallets.detail.information.labels.address`}</Label>
          <Description>
            <SkeletonBox className="h-5 w-20" loading={wallet.loading}>
              <MaybeNull>{address}</MaybeNull>
            </SkeletonBox>
          </Description>

          <Label className="w-72">{t`vaultWallets.detail.information.labels.vault`}</Label>
          <Description>
            <SkeletonBox className="h-5 w-20" loading={wallet.loading}>
              <Link
                href={{
                  pathname: vaultDetailsSection.route,
                  query: { vaultId },
                }}
              >
                {vaultMetadata?.name}
              </Link>
            </SkeletonBox>
          </Description>
        </DescriptionList>
      </Card.Content>
    </Card>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Bank Accounts mutation.
 * Allowed users to add a bank account.
 */
export const BANK_ACCOUNT_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.BANK_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.AGENT_ACCOUNT_ROLE,
  ],
}

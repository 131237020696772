import type { ReactNode } from 'react'

import classNames from 'classnames'

export interface SubtitleProps {
  children: ReactNode
  className?: string
}

export const Subtitle = ({ children, className }: SubtitleProps) => (
  <h3
    className={classNames(
      'mb-1.5 text-sm text-black-800 font-circular-regular',
      className,
    )}
    data-testid="disclosure-subtitle"
  >
    {children}
  </h3>
)

Subtitle.displayName = 'Disclosures.Subtitle'

import { useCallback, useMemo, useEffect } from 'react'

import { useForm, y } from '@circlefin/form'
import { useDebounce } from '@circlefin/hooks'
import { LocalsForms } from '@features/locales/forms'
import { useCountries } from '@features/locales/hooks/countries'
import { Center } from '@shared/components/layout'
import { CountryCode, useEntityQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../Create/Create'
import { useLinkBankAccount } from '../../Link.Context'

import type { DropdownItem } from '@circlefin/components/lib/Dropdown'

const schema = y.object({
  /**
   * Holder's Name.
   */
  name: y.string().required(),
  /**
   * Address Line 1.
   */
  line1: y.string().required(),
  /**
   * Address Line 2.
   */
  line2: y.string(),
  /**
   * Postal Code.
   */
  postalCode: y.string().postalCode().required(),
  /**
   * City.
   */
  city: y.string().required(),
  /**
   * Country.
   */
  country: y.mixed<CountryCode>().oneOf(Object.values(CountryCode)).required(),
  /**
   * State/ Province.
   */
  state: LocalsForms.Dropdown.stateSchema.required(),
  /**
   * Flag to determine if an account belongs to organization.
   */
  isOrganizationAccount: y.boolean().required().oneOf([true]),
})

export type AddressFormValues = y.InferType<typeof schema>

export interface AddressFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: AddressFormValues) => void
}

export const AddressForm: React.FC<AddressFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const { data } = useEntityQuery()
  const [{ address, location }, { setAddress }] = useLinkBankAccount()
  const [createBankAccount] = useLinkCreate()

  const [Form, { watch, setValue, getValues, register }] =
    useForm<AddressFormValues>({
      schema,
      defaultValues: {
        country: location?.country?.code ?? CountryCode.US,
        isOrganizationAccount: false,
        ...address,
      },
    })
  const formCountry = watch('country')

  // Detect when country is set to SG and hide the state input
  const StateInput = useMemo(() => {
    if (formCountry === CountryCode.SG) {
      return <input type="hidden" value="Singapore" {...register('state')} />
    } else {
      return (
        <LocalsForms.Dropdown.State
          className="w-full"
          country={formCountry}
          display="floating"
          link={{
            locatedIn: true,
          }}
        />
      )
    }
  }, [formCountry, register])

  const handleCountryChange = useCallback(
    (country: DropdownItem<CountryCode>) => {
      if (country.value === CountryCode.SG) {
        setValue('state', 'Singapore')
      } else {
        setValue('state', '')
      }
    },
    [setValue],
  )

  const updateAddress = useDebounce({
    callback: () => setAddress(getValues()),
    delay: 500,
  })

  useEffect(() => {
    const { unsubscribe } = watch(updateAddress)
    return () => unsubscribe()
  }, [watch, updateAddress])

  const { countries } = useCountries({
    link: {
      registeredIn: true,
    },
  })

  const onFormSubmit = useCallback(
    (address: AddressFormValues) => {
      onSubmit?.(address)
      createBankAccount()
    },
    [createBankAccount, onSubmit],
  )

  const entityName = data?.entity?.institutionName
    ? t('link.address.isOrganizationAccount.entity', {
        entityName: data.entity.institutionName,
      })
    : ''

  return (
    <div data-testid="address-form">
      <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-name"
          display="floating"
          label={t`link.address.name.label`}
          message={t`link.address.name.message`}
          name="name"
        />

        <Form.Input
          className="w-full"
          data-testid="input-line1"
          display="floating"
          label={t`link.address.line1`}
          name="line1"
        />

        <Form.Input
          className="w-full"
          data-testid="input-line2"
          display="floating"
          label={t`link.address.line2`}
          name="line2"
        />

        <Form.Input
          className="w-full"
          data-testid="input-postalCode"
          display="floating"
          label={t`link.address.postalCode`}
          name="postalCode"
        />

        <Form.Input
          className="w-full"
          data-testid="input-city"
          display="floating"
          label={t`link.address.city`}
          name="city"
        />

        <Form.Dropdown
          data-testid="dropdown-country"
          display="floating"
          items={countries.toDropdown()}
          label={t`link.address.country`}
          maxMenuItems={6}
          name="country"
          onChange={handleCountryChange}
        />

        {StateInput}

        <Form.Checkbox
          className="flex items-start text-neutral-strong"
          data-testid="input-isOrganizationAccount"
          label={
            <Trans
              components={{ strong: <strong />, br: <br /> }}
              i18nKey="modals.bankAccount:link.address.isOrganizationAccount.label"
              values={{
                entity: entityName,
              }}
            />
          }
          name="isOrganizationAccount"
        />

        <Center className="pt-6" variant="horizontal">
          <Form.SubmitButton className="w-60" variant="primary">
            {t`common:continue`}
          </Form.SubmitButton>
        </Center>
      </Form>
    </div>
  )
}

import { UserInviteInfoFragmentDoc } from '@shared/graphql'

import type { MutationUpdaterFn } from '@apollo/client'
import type { InviteUserMutation } from '@shared/graphql'

export const inviteUserUpdate: MutationUpdaterFn<InviteUserMutation> = (
  cache,
  { data },
) => {
  if (data?.inviteUser) {
    cache.modify({
      fields: {
        users(existingUsers = []) {
          const inviteUserRef = cache.writeFragment({
            data: data.inviteUser,
            fragment: UserInviteInfoFragmentDoc,
            fragmentName: 'UserInviteInfo',
          })
          return [...existingUsers, inviteUserRef]
        },
      },
    })
  }
}

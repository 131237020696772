import { PermissionProvider } from '@circlefin/permissions/context/provider'
import { useSession } from 'next-auth/react'

export interface AuthenticatedPermissionProviderProps {
  /**
   * React Children.
   */
  children: React.ReactNode
}

export const AuthenticatedPermissionProvider: React.FC<AuthenticatedPermissionProviderProps> =
  ({ children }) => {
    const { data, status } = useSession()

    return (
      <PermissionProvider
        authenticated={data != null}
        loading={status === 'loading'}
        roles={data?.roles}
      >
        {children}
      </PermissionProvider>
    )
  }

import useTranslation from 'next-translate/useTranslation'

interface FxAgreementProps {
  mode: 'deposit' | 'withdraw'
}

export const FxAgreement: React.FC<FxAgreementProps> = ({ mode }) => {
  const { t } = useTranslation('modals.transfer')

  return (
    <>
      <p className="type-body-base-bold">{t(`fx.agreement.title`)}</p>
      <p>{t(`fx.agreement.description.${mode}`)}</p>
    </>
  )
}

import { Statement, Button } from '@circlefin/components'
import { signIn } from '@services/sections/lib/home'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

/**
 * PageError: Unauthorized Error.
 */
export const Unauthorized: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <div data-testid="page-unauthorized-error">
      <Statement
        status="warning"
        subtitle={t`pageUnauthorizedError.subtitle`}
        title={t`pageUnauthorizedError.title`}
      >
        <Link href={signIn.route} legacyBehavior passHref>
          <Button.Link
            data-testid="retry-button"
            variant="primary"
          >{t`pageUnauthorizedError.cta`}</Button.Link>
        </Link>
      </Statement>
    </div>
  )
}

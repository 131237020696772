import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Permission to perform mutation/edit actions on developer dashboard UI.
 */
export const DEVELOPER_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.DEVELOPER_ROLE],
}

import { useCallback, useEffect, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { Currency } from '@shared/graphql'

import { defaultCurrentCurrencyValues, CurrentCurrencyContext } from './context'

import type { CurrentCurrencyState } from './context'

/**
 * Current Currency Provider props.
 */
interface CurrentCurrencyProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<CurrentCurrencyState>
}

/**
 * Current Currency Provider.
 */
export const CurrentCurrencyProvider: React.FC<CurrentCurrencyProviderProps> =
  ({ children, initValues = {} }) => {
    const { events } = useModal()
    const [values, setValues] = useState<CurrentCurrencyState>({
      ...defaultCurrentCurrencyValues,
      ...initValues,
    })

    const handleValueChange = useCallback(
      (values: Partial<CurrentCurrencyState>) => {
        setValues((curr) => ({
          ...curr,
          ...values,
        }))
      },
      [],
    )

    const tokenToCurrency = useCallback((currency: Currency) => {
      switch (currency) {
        case Currency.USDC:
          return Currency.USD
        case Currency.EURC:
          return Currency.EUR
        default:
          return currency
      }
    }, [])

    const currencyToToken = useCallback((currency: Currency) => {
      switch (currency) {
        case Currency.USD:
          return Currency.USDC
        case Currency.EUR:
          return Currency.EURC
        default:
          return currency
      }
    }, [])

    useEffect(() => {
      const resetContext = () => {
        handleValueChange(defaultCurrentCurrencyValues)
      }

      events.on('onCloseEnd', resetContext)

      return () => {
        events.off('onCloseEnd', resetContext)
      }
    })

    return (
      <CurrentCurrencyContext.Provider
        value={[
          values,
          {
            setCurrency: handleValueChange,
            tokenToCurrency,
            currencyToToken,
          },
        ]}
      >
        {children}
      </CurrentCurrencyContext.Provider>
    )
  }

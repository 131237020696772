import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Account transfer.
 * Which users are allowed to withdraw to bank or onchain wallet?
 */
export const ACCOUNT_TRANSFER: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.WALLETS_ROLE,
    Role.WIRES_ROLE,
    Role.WIRES_WITHDRAW_ROLE,
    Role.ONCHAIN_TRANSFER_ROLE,
  ],
}

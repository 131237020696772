import { Icon, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { Currency } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const PolicyLimit: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')
  const formatMoney = useMoney({ locale: 'en-US' }).money

  const {
    condensed,
    currency = Currency.USD,
    policyLimits,
  } = usePolicySummaryContext()

  return (
    <>
      <Label className="h-full">
        {t('policy.summary.labels.policyDailyLimits')}
      </Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="policy-limit"
      >
        {policyLimits && (
          <List hideShowAllButton>
            <List.Item>
              <List.Item.Title
                stepName={WalletApprovalPolicyStep.POLICY_LIMITS}
                showEdit
              >
                <div className="flex items-center gap-x-2">
                  <span>
                    {policyLimits.maxAmount &&
                      formatMoney({
                        number: policyLimits.maxAmount,
                        variant: currency,
                      })}
                  </span>
                  <Icon className="inline-block" name="EllipseSolid" size={4} />
                  {t('policy.summary.policyDailyLimits.transaction', {
                    count: Number(policyLimits.maxTransactions),
                  })}
                </div>
              </List.Item.Title>
            </List.Item>
          </List>
        )}
      </Description>
    </>
  )
}

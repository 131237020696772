import { useCallback, useMemo } from 'react'

import { Button, Card, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import {
  isBankAccountType,
  isRailType,
} from '@services/type-guards/BankAccount'
import { MaybeNull } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  Currency,
  PaymentsDocument,
  useActiveWalletApprovalPolicyQuery,
  useWalletApprovalCreatePayoutTransferMutation,
  WalletApprovalPendingTransactionsDocument,
} from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useWithdraw } from '../../../hooks/withdraw'

import type { CreatePayoutInput } from '@shared/graphql'

export const Review: React.FC = () => {
  const { t } = useTranslation('modals.payout')
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const modal = useModal()
  const { money } = useMoney({ locale: 'en-US' })
  const [{ amount, bankAccount, rail }] = useWithdraw()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()
  const { idempotencyKey } = useIdempotencyKey()

  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)
  const walletPolicyQuery = useActiveWalletApprovalPolicyQuery({
    variables: { currency },
    skip: !isAuthorized,
    fetchPolicy: 'cache-and-network',
  })
  const hasWalletPolicyWithApproverSteps =
    walletPolicyQuery.data?.activeWalletApprovalPolicy?.approvalWorkflow != null

  const [createPayout, { loading }] =
    useWalletApprovalCreatePayoutTransferMutation({
      refetchQueries: [
        /**
         * Refetch Payments Activity Table.
         */
        PaymentsDocument,
        WalletApprovalPendingTransactionsDocument,
        /**
         * NOTE: Doesn't help to refetch balances here as they aren't
         * up-to-date immediately after a successful withdrawal.
         */
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        // If there is an active policy, navigate to the finished screen (this screens tells them their transfer needs approval)
        if (hasWalletPolicyWithApproverSteps) {
          modal.router.push({
            pathname: routes.transfer.withdraw.approvalRequired,
            query: {
              totalSteps: 6,
              actionLink: {
                pathname: circleWalletDetailSection.route,
                query: { currency },
              },
              currency,
            },
          })
          return
        }

        mfaSuccess({
          message: t`common:success`,
        })
      },
      onError: (error) =>
        mfaError({
          error,
          message: t`common:generalError`,
        }),
    })

  const onTransferClick = useCallback(() => {
    if (bankAccount && amount && rail) {
      const input: CreatePayoutInput = {
        destination: {
          id: bankAccount.id,
          type: isBankAccountType(rail) ? rail : undefined,
          railType: isRailType(rail) ? rail : undefined,
          name: bankAccount.label,
        },
        amount: {
          amount,
          currency,
        },
        idempotencyKey,
      }

      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createPayout({
            variables: {
              input,
            },
            context: context(code),
          })
        },
        onAbort: () => modal.close({ context: 'onDismiss' }),
      })
    }
  }, [
    bankAccount,
    amount,
    rail,
    currency,
    idempotencyKey,
    withMfa,
    createPayout,
    context,
    modal,
  ])

  const bankDescription = useMemo(() => {
    if (!bankAccount) {
      return null
    }

    if (TypeGuards.BankAccount.isSignet(bankAccount)) {
      return t`withdraw.review.signet`
    }

    if (TypeGuards.BankAccount.isCbit(bankAccount)) {
      return t`withdraw.review.cbit`
    }

    if (TypeGuards.BankAccount.isXpay(bankAccount)) {
      return t`withdraw.review.xpay`
    }

    return bankAccount.description
  }, [t, bankAccount])

  return (
    <FullScreen totalSteps={5}>
      <GraphQLErrorBoundary
        error={walletPolicyQuery.error}
        retry={walletPolicyQuery.refetch}
        variant="page"
      >
        <Center className="text-center font-circular" variant="horizontal">
          <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
            {t`withdraw.review.title`}
          </h2>
          <h3 className="mb-6 text-lg leading-6 text-black-400">
            {t`withdraw.review.subtitle`}
          </h3>

          <Card className="w-192 px-4 py-2" variant="secondary/base">
            <DescriptionList
              className="bg-transparent px-2"
              data-testid="description-list"
            >
              <DescriptionList.Label>
                {t`withdraw.review.bankAccount`}
              </DescriptionList.Label>
              <DescriptionList.Description data-testid="description">
                <MaybeNull>{bankDescription}</MaybeNull>
              </DescriptionList.Description>
              <DescriptionList.Label>
                {t`withdraw.review.recipient`}
              </DescriptionList.Label>
              <DescriptionList.Description data-testid="nickname">
                {bankAccount?.nickname}
              </DescriptionList.Description>
              <DescriptionList.Label>
                {t`withdraw.review.amount`}
              </DescriptionList.Label>
              <DescriptionList.Description data-testid="amount">
                {amount &&
                  money({
                    number: amount,
                    variant: currency,
                    options: {
                      symbol: true,
                    },
                  })}
              </DescriptionList.Description>
              <DescriptionList.Label>
                {t`withdraw.review.rail`}
              </DescriptionList.Label>
              <DescriptionList.Description data-testid="rail">
                {t(`modals.transfer:fx.rails.${rail}.label`)}
              </DescriptionList.Description>
            </DescriptionList>
          </Card>
        </Center>

        <Center>
          <Button
            className="mt-10 h-10 w-60"
            data-testid="transfer-button"
            disabled={walletPolicyQuery.loading}
            loading={loading}
            onClick={onTransferClick}
            variant="primary"
          >
            {t`common:transfer`}
          </Button>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

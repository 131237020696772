import {
  rolrAmountSection,
  rolrAuthRepSection,
  rolrBusinessInfoSection,
  rolrCreateAccountSection,
  rolrSection,
} from '@services/sections/lib/signup'
import { CountryCode } from '@shared/graphql'

import { rolrValidationMap } from './validations'

import type {
  RolrSetupSteps,
  RolrSetupContextState,
  RolrSetupStepNames,
} from './context'

type RolrStepStatus = {
  /**
   * Is the step valid.
   */
  isValid: boolean
  /**
   * Are all dependencies valid.
   */
  allDependenciesValid: boolean
  /**
   * First invalid dependency.
   */
  firstInvalidDependency?: RolrSetupStepNames
}

export type RolrStepStatuses = Record<RolrSetupStepNames, RolrStepStatus>

export const computeNextState = (
  state: RolrSetupContextState,
  action: RolrSetupSteps,
): RolrSetupContextState | undefined => {
  switch (action.step) {
    case 'landing': {
      if (!rolrValidationMap.landing({ ...state, landing: action.values })) {
        return state
      }

      return {
        ...state,
        step: 'authRep',
        landing: action.values,
      }
    }

    case 'authRep': {
      if (!rolrValidationMap.authRep({ ...state, authRep: action.values })) {
        return state
      }

      return {
        ...state,
        step: 'businessInfo',
        authRep: action.values,
      }
    }

    case 'businessInfo': {
      if (
        action.values == null ||
        !rolrValidationMap.businessInfo({
          ...state,
          businessInfo: action.values,
        })
      ) {
        return state
      }

      return {
        ...state,
        step: 'redemptionAmount',
        businessInfo: action.values,
      }
    }

    case 'redemptionAmount': {
      if (
        !rolrValidationMap.redemptionAmount({
          ...state,
          redemptionAmount: action.values,
        })
      ) {
        return state
      }

      return {
        ...state,
        step: 'createAccount',
        redemptionAmount: action.values,
      }
    }

    case 'createAccount': {
      if (
        !rolrValidationMap.createAccount({
          ...state,
          createAccount: action.values,
        })
      ) {
        return state
      }

      return {
        ...state,
        step: 'createAccount',
        createAccount: action.values,
      }
    }
  }
}

export const getRolrStepRoute = (step: RolrSetupStepNames): string => {
  switch (step) {
    case 'landing':
      return rolrSection.route
    case 'businessInfo':
      return rolrBusinessInfoSection.route
    case 'authRep':
      return rolrAuthRepSection.route
    case 'redemptionAmount':
      return rolrAmountSection.route
    case 'createAccount':
      return rolrCreateAccountSection.route
  }
}

export const findStepBasedOnRoute = (
  route: string,
): RolrSetupStepNames | undefined => {
  switch (route) {
    case rolrSection.route:
      return 'landing'
    case rolrBusinessInfoSection.route:
      return 'businessInfo'
    case rolrAuthRepSection.route:
      return 'authRep'
    case rolrAmountSection.route:
      return 'redemptionAmount'
    case rolrCreateAccountSection.route:
      return 'createAccount'
    default:
      return
  }
}

export const rolrSteps: RolrSetupStepNames[] = [
  'landing',
  'authRep',
  'businessInfo',
  'redemptionAmount',
  'createAccount',
]

const rolrDependenciesMap: Record<RolrSetupStepNames, RolrSetupStepNames[]> = {
  landing: [],
  authRep: ['landing'],
  businessInfo: ['landing', 'authRep'],
  redemptionAmount: ['landing', 'authRep', 'businessInfo'],
  createAccount: ['landing', 'authRep', 'businessInfo', 'redemptionAmount'],
}

export const computeRolrStateMachineNextState = (
  state: RolrSetupContextState,
): RolrStepStatuses => {
  const status: RolrStepStatuses = {
    landing: {
      isValid: false,
      allDependenciesValid: false,
    },
    businessInfo: {
      isValid: false,
      allDependenciesValid: false,
    },
    authRep: {
      isValid: false,
      allDependenciesValid: false,
    },
    redemptionAmount: {
      isValid: false,
      allDependenciesValid: false,
    },
    createAccount: {
      isValid: false,
      allDependenciesValid: false,
    },
  }

  for (const step of rolrSteps) {
    const dependencies = rolrDependenciesMap[step]

    const firstInvalidDependency = dependencies.find(
      (step) => rolrValidationMap[step](state) === false,
    )

    status[step] = {
      isValid: rolrValidationMap[step](state),
      allDependenciesValid:
        dependencies.length === 0 || firstInvalidDependency == null,
      firstInvalidDependency,
    }
  }

  return status
}

/**
 * Calculate is the user an EEA user based on the business information.
 */
export const isEeaUser = (
  businessInfo: Pick<
    NonNullable<RolrSetupContextState['businessInfo']>,
    'countryOfIncorporation' | 'primaryPlaceOfBusiness'
  >,
  eeaCountryCodes: CountryCode[],
) => {
  const { countryOfIncorporation, primaryPlaceOfBusiness } = businessInfo

  // countryOfIncorporation (COI) as SG overrides the default rule
  if (countryOfIncorporation === CountryCode.SG) {
    return false
  }

  // Default rule: Either COI or primaryPlaceOfBusiness (PPOB) must be a EEA countries/territories
  return (
    eeaCountryCodes.includes(countryOfIncorporation) ||
    eeaCountryCodes.includes(primaryPlaceOfBusiness)
  )
}

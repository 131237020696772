import { Chip } from '@circlefin/components'
import { VaultStateEnum } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

export interface VaultChipsProps {
  /**
   * The current state of the vault.
   */
  state: VaultStateEnum
  /**
   * Specify if the tags should be stacked instead of inline.
   */
  stacked?: boolean
}

export const VaultChips: React.FC<VaultChipsProps> = ({
  stacked,
  state,
  ...props
}) => {
  const { t } = useTranslation('vaults')

  const isInactive = [
    VaultStateEnum.SETUP_IN_PROGRESS,
    VaultStateEnum.READY_FOR_APPROVAL,
    VaultStateEnum.PENDING_APPROVAL,
  ].includes(state)

  const isReadyForApproval = [
    VaultStateEnum.READY_FOR_APPROVAL,
    VaultStateEnum.CHANGES_READY_FOR_APPROVAL,
  ].includes(state)

  return (
    <div
      className={classNames('flex', {
        'flex-col space-y-2 items-start': stacked,
        'flex-row space-x-2 mb-2': !stacked,
      })}
      {...props}
    >
      <Chip
        className="text-sm"
        data-testid="status-chip"
        variant={isInactive ? 'default/warning' : 'default/success'}
      >
        {isInactive
          ? t`vault.detail.state.INACTIVE`
          : t`vault.detail.state.ACTIVE`}
      </Chip>
      {state !== VaultStateEnum.ACTIVE && (
        <Chip
          className="text-sm"
          data-testid="status-chip"
          variant={isReadyForApproval ? 'default/info' : 'default/warning'}
        >
          {t(`vault.detail.state.${state}`)}
        </Chip>
      )}
    </div>
  )
}

export interface HeaderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Hide header?
   */
  hidden?: boolean
}

export const Header: React.FC<HeaderProps> = ({ hidden = false, children }) => {
  if (hidden) {
    return null
  }

  return (
    <div className="mb-4 mt-2.5 flex items-center justify-between">
      {children}
    </div>
  )
}

Header.displayName = 'TableCard.Header'

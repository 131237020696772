import { useMemo } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { l3Onboarding } from '@services/sections/lib/onboarding'
import { usePeriodicReviewQualificationQuery } from '@shared/graphql'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface TakeoverProps {
  /**
   * Institution name.
   */
  institutionName?: string
}

export const Takeover: React.FC<TakeoverProps> = ({ institutionName }) => {
  const { t } = useTranslation('modals.onboarding')
  const { locale } = useRouter()
  const { date } = useDate({ locale })
  const { data } = usePeriodicReviewQualificationQuery()
  const { dueDate, dayRemaining } = data?.periodicReviewQualification ?? {}

  const title = useMemo(
    () =>
      dueDate == null || dayRemaining == null
        ? t('periodicReview.title.withoutDueDate')
        : t('periodicReview.title.withDueDate', {
            count: dayRemaining,
          }),
    [dayRemaining, dueDate, t],
  )

  const body = useMemo(
    () =>
      dueDate == null ? (
        t('periodicReview.body.withoutDueDate', {
          institutionName,
        })
      ) : (
        <Trans
          components={{
            b: <b />,
          }}
          i18nKey="modals.onboarding:periodicReview.body.withDueDate"
          values={{
            dueDate: date(dueDate, 'monthDayYear'),
            institutionName,
          }}
        />
      ),
    [date, dueDate, institutionName, t],
  )

  return (
    <>
      <Modal.Header title={title} variant="warn" />
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Link href={l3Onboarding.route} legacyBehavior passHref>
          <Button.Link variant="primary">{t`periodicReview.linkText`}</Button.Link>
        </Link>
      </Modal.Footer>
    </>
  )
}

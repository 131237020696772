import { useCallback, useContext, useEffect, useState } from 'react'

import { Statement } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { MfaContext } from '@shared/mfa/lib/context'
import useTranslation from 'next-translate/useTranslation'

import { Layout } from '../../Layout/Layout'

import { ScaCodesOktaVerify } from './ScaCodes.OktaVerify'
import { ScaCodesPinCode } from './ScaCodes.PinCode'

import type { MfaCode } from '@shared/apollo/links'

export interface ScaCodesProps {
  /**
   * Verifying MFA.
   */
  verifying?: boolean
}

export const ScaCodes: React.FC<ScaCodesProps> = ({ verifying = false }) => {
  const { t } = useTranslation('mfa')
  const { request } = useContext(MfaContext)
  const { events } = useModal()
  const [verify, setVerify] = useState<boolean>(verifying)
  const [oktaCode, setOktaCode] = useState<MfaCode>()

  useEffect(() => {
    // Call on modal close
    const onClose = () => {
      request.onAbort?.()
    }

    // Subscribe to modal close event
    events.on('onCloseEnd', onClose)

    return () => {
      // Unsubscribe to modal close event
      events.off('onCloseEnd', onClose)
    }
  }, [events, request])

  const onOktaCodeComplete = useCallback((oktaCode: MfaCode) => {
    setOktaCode(oktaCode)
  }, [])

  const onPinCodeComplete = useCallback(
    (pinCode: MfaCode) => {
      if (oktaCode) {
        setVerify(true)
        request.onComplete?.({
          type: 'sca',
          oktaCode,
          pinCode,
        })
      }
    },
    [oktaCode, request],
  )

  if (verify) {
    return (
      <Layout title={t`verifyAccount`}>
        <div className="grid h-full content-center">
          <Statement
            iconName="CircleSpinnerSolid"
            padded={false}
            title={t`loading`}
            variant="page"
            loop
          />
        </div>
      </Layout>
    )
  }

  return (
    <Layout title={t`verifyAccount`}>
      {!oktaCode ? (
        <ScaCodesOktaVerify onComplete={onOktaCodeComplete} />
      ) : (
        <ScaCodesPinCode onComplete={onPinCodeComplete} />
      )}
    </Layout>
  )
}

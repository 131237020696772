import { useMoney } from '@circlefin/hooks'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useBalancesMainQuery } from '@shared/graphql'

import { AvailableBalanceText } from '../../../../../containers'
import { useSendOnChain } from '../../SendOnChain.Context'

import { CircleSourceAmountForm } from './CircleSourceAmount.Form'

export const CircleSourceAmount: React.FC = () => {
  const { money } = useMoney({ locale: 'en-US' })
  const [, , { circleSourceWallet }] = useSendOnChain()
  const { data, error, refetch } = useBalancesMainQuery({
    variables: { currency: circleSourceWallet?.currency },
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
    skip: circleSourceWallet?.currency == null,
  })

  return (
    <GraphQLErrorBoundary error={error} retry={refetch}>
      <AvailableBalanceText
        nativeBalance={
          data?.balances.main
            ? money({
                number: data.balances.main?.balance.amount,
                variant: data.balances.main?.balance.currency,
              })
            : undefined
        }
      />
      <Center variant="horizontal">
        <div className="w-104" data-testid="circle-source-amount-form">
          <CircleSourceAmountForm
            availableBalance={data?.balances.main?.balance}
          />
        </div>
      </Center>
    </GraphQLErrorBoundary>
  )
}

import { Button, Modal, Statement } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface AutoPayModalBodyProps {
  /**
   * Whether the modal should show the loading state.
   */
  loading?: boolean
  /**
   * Handle confirm click.
   */
  onConfirm: () => void
  /**
   * Handle cancel click.
   */
  onCancel: () => void
}

export const AutoPayModalBody = ({
  loading = false,
  onConfirm,
  onCancel,
}: AutoPayModalBodyProps) => {
  const { t } = useTranslation('modals/settings/billing')

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <Statement
          iconName="RefreshOutline"
          subtitle={t('autoPay.enable.description')}
          title={t('autoPay.enable.title')}
          variant="page"
        />
      </div>
      <Modal.Footer variant="center">
        <Button className="flex-1" onClick={onCancel} variant="secondary">
          {t('autoPay.enable.cancel')}
        </Button>
        <Button
          className="flex-1"
          loading={loading}
          onClick={onConfirm}
          variant="primary"
        >
          {t('common:confirm')}
        </Button>
      </Modal.Footer>
    </div>
  )
}

import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import Image from 'next/image'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import ScanQRCode from '../../../images/ScanQRCode.png'

export const Overview: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  return (
    <FullScreen>
      <div className="mx-auto flex h-full max-w-192 flex-col justify-between">
        <Center>
          <h2 className="mb-14 text-center text-3xl text-neutral-strong font-circular-bold">
            {t`walletConnect.overview.title`}
          </h2>
          <div className="flex flex-row justify-center">
            <Image
              alt={t`walletConnect.overview.title`}
              height="183"
              src={ScanQRCode}
              width="320"
            />
          </div>
          <ol className="mx-auto mt-19 w-120 list-decimal text-lg">
            <li>
              <Trans
                components={{
                  strong: <strong />,
                }}
                i18nKey="modals.vault:walletConnect.overview.step-1"
              />
            </li>
            <li>{t`walletConnect.overview.step-2`}</li>
            <li>{t`walletConnect.overview.step-3`}</li>
          </ol>
        </Center>
      </div>
    </FullScreen>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Dashboard management permission.
 * Which users are allowed to manage api-related functions (billing, platform, fraud, etc.)?
 */
export const DASHBOARD_MANAGEMENT_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.ACCOUNT_DASHBOARD_MANAGEMENT_ROLE],
}

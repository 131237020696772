import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLETS_QUERY,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import {
  useWalletApprovalPolicyQuery,
  WalletApprovalPolicyState,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { Currency } from '@shared/graphql'

export interface DiscardDraftProps
  extends Pick<ButtonProps, 'variant' | 'disabled'> {
  /**
   * Optional className that should be applied to the button.
   */
  className?: string
  /**
   * Currency.
   */
  currency: Currency
  /**
   * Policy id.
   */
  policyId: string
}

export const DiscardDraft: React.FC<DiscardDraftProps> = ({
  className,
  currency,
  policyId,
  variant,
  disabled,
}) => {
  const { t } = useTranslation('common')

  const [, { isAuthorized: isAuthorizedForQuery }] =
    usePermission(CIRCLE_WALLETS_QUERY)
  const [{ Authorized }] = usePermission(CIRCLE_WALLETS_MUTATION)

  const { data } = useWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: !isAuthorizedForQuery,
  })

  if (data?.walletApprovalPolicy?.state !== WalletApprovalPolicyState.DRAFT) {
    return null
  }

  return (
    <Authorized>
      <ModalLink
        pathname={routes.walletApprovalPolicy.discardDraftPolicy}
        query={{
          policyId,
          currency,
        }}
      >
        <Button
          className={className}
          disabled={disabled}
          size="sm"
          variant={variant}
        >
          {t`discard`}
        </Button>
      </ModalLink>
    </Authorized>
  )
}

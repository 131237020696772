import { Button } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import type { FormInputProps } from '@circlefin/form/Form.Input'

export const ipAddressMaxSchema = y.object({
  /**
   * Upper bound ip address.
   */
  ipMax: y.string().ipAddress(),
})

export type IpAddressMaxFormValues = y.InferType<typeof ipAddressMaxSchema>

export type IpAddressMaxProps = Omit<
  FormInputProps<IpAddressMaxFormValues>,
  '' | 'alignLabelMessage' | 'name'
> & {
  /**
   * On remove range callback.
   */
  onRemoveRange?: () => void
}

export const IpAddressMax: React.FC<IpAddressMaxProps> = ({
  label,
  onChange,
  onRemoveRange,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const [Form] = useForm<IpAddressMaxFormValues>({ schema: ipAddressMaxSchema })

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between type-body-sm-bold">
        <label>{label && <span className="text-neutral">{label}</span>}</label>
        <Button
          className="p-0 normal-case"
          onClick={onRemoveRange}
          variant="text"
        >
          {t('input.settings.ipAddressMax.removeRange')}
        </Button>
      </div>
      <Form.Input
        {...props}
        data-testid="ip-address-max"
        name="ipMax"
        onChange={onChange}
      />
    </div>
  )
}

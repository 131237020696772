import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { graphql } from '@services/sections/lib/api'
import introspection from '@shared/graphql/generated/introspection.json'
import { withScalars } from 'apollo-link-scalars'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import deepmerge from 'deepmerge'
import { buildClientSchema } from 'graphql'
import {
  DateTimeResolver,
  JSONResolver,
  URLResolver,
  UUIDResolver,
  VoidResolver,
} from 'graphql-scalars'
import isEqual from 'lodash.isequal'

import { cacheConfig } from './cache'
import { errorLink } from './links'

import type { HttpOptions, NormalizedCacheObject } from '@apollo/client'
import type { IntrospectionQuery } from 'graphql'
import type {
  NextApiRequest,
  NextApiResponse,
} from 'next/dist/shared/lib/utils'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

interface ApolloClientContext {
  req: NextApiRequest
  res: NextApiResponse
  query: unknown
  resolvedUrl: string
  locales: string[]
  locale: string
  defaultLocale: string
}

/**
 * Create Apollo Client Instance.
 */
function createApolloClient(ctx?: ApolloClientContext) {
  // Check for server-side
  const ssrMode = typeof window === 'undefined'

  // We want to use a relative path in the browser and an absolute one on the server
  const baseUrl = ssrMode ? process.env.INTERNAL_URL ?? '' : ''

  // Default options
  const linkOptions: HttpOptions = {
    uri: `${baseUrl}${graphql.route}`,
    credentials: 'include',
    // Apollo recommends passing this header to protect against CSRF attacks.
    headers: { 'Apollo-Require-Preflight': 'true' },
  }

  // pass cookies for the server instance
  if (ctx?.req.headers.cookie) {
    linkOptions.headers = {
      cookie: ctx.req.headers.cookie,
    }
  }

  const httpLink = createUploadLink(linkOptions)

  return new ApolloClient({
    ssrMode,
    link: from([
      withScalars({
        schema: buildClientSchema(
          introspection as unknown as IntrospectionQuery,
        ),
        typesMap: {
          Uuid: UUIDResolver,
          DateTime: DateTimeResolver,
          Url: URLResolver,
          Void: VoidResolver,
          JSON: JSONResolver,
          // createUploadLink handles the Upload scalar automatically.
        },
      }),
      errorLink,
      httpLink,
    ]),
    cache: new InMemoryCache(cacheConfig),
  })
}

/**
 * Initialize Apollo Client.
 */
function initializeApollo(
  ctx?: ApolloClientContext,
  initialState: NormalizedCacheObject | null = null,
) {
  // On the server `apolloClient` is null, and it falls back to createApolloClient(ctx) for each request (new instance)
  // On the client `apolloClient` it is null for the first time and never null again during the same session (singleton)
  const _apolloClientRef = apolloClient ?? createApolloClient(ctx)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here, in most cases it's the cache coming from the server
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClientRef.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = deepmerge<NormalizedCacheObject>(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (
        destinationArray: NormalizedCacheObject[],
        sourceArray: NormalizedCacheObject[],
      ) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClientRef.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClientRef

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClientRef

  return _apolloClientRef
}

/**
 * Initialize Apollo Instance.
 */
export function initApolloClient(
  initialState: NormalizedCacheObject | null = null,
) {
  return initializeApollo(undefined, initialState)
}

/**
 * Create Instance for Graphql-Codegen.
 */
export function getApolloClient(ctx?: ApolloClientContext) {
  return initializeApollo(ctx)
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Address Book Query Permission.
 */
export const ADDRESS_BOOK_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.AGENT_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_READ_ROLE,
    Role.ADDRESS_BOOK_ROLE,
  ],
}

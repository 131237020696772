import { DescriptionList } from '@circlefin/components'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainAbbreviation } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface ChainExplorerLinkProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Blockchain.
   */
  chain?: BlockchainAbbreviation
  /**
   * Transaction hash.
   */
  transactionHash?: string
  /**
   * Pass the explorer link directly.
   */
  explorerLink?: string
  /**
   * Only generate the <a> tag.
   */
  linkOnly?: boolean
}

export const ChainExplorerLink: React.FC<ChainExplorerLinkProps> = ({
  chain,
  transactionHash,
  explorerLink,
  linkOnly,
}) => {
  const { t } = useTranslation('payments')
  const { trackLinkClick } = useSegment()
  const chainName = chain != null && t(`chainNames.${chain}`)

  const chainExplorerLink =
    chain != null
      ? t(`chainExplorer.links.${chain}`)
      : t('chainExplorer.notAvailable')

  const href =
    explorerLink ??
    (transactionHash && `${chainExplorerLink}${transactionHash}`)

  const linkComponent = (
    <a
      className="block truncate"
      data-testid="chain-explorer-link"
      href={href}
      onClick={trackLinkClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      {href}
    </a>
  )

  return linkOnly ? (
    linkComponent
  ) : (
    <>
      <Label>
        {chainName} {t('transactionDetail.transactionInfo')}
      </Label>
      <Description>{linkComponent}</Description>
    </>
  )
}

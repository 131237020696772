import { useCallback, useMemo } from 'react'

import { Button, Modal } from '@circlefin/components'
import { VaultUserPolicyRoleEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useApprovalWorkflowValidate } from '../../../../containers/CreateVault'
import { useCreateVault } from '../../../../hooks/create'

import type { VaultUserPolicy } from '@shared/graphql'

export interface UnassignedApproversWarningProps {
  /**
   * Open modal.
   */
  open?: boolean
  /**
   * On cancel handler.
   */
  onCancel?: () => void
  /**
   * On continue handler.
   */
  onContinue?: () => void
}

export const UnassignedApproversWarning: React.FC<UnassignedApproversWarningProps> =
  ({ open, onCancel, onContinue }) => {
    const { t } = useTranslation('modals.vault')

    const [{ usersPolicy = [] }, { setUsersPolicy }] = useCreateVault()
    const { missingApproversInWorkflow } = useApprovalWorkflowValidate()

    const approvers = useMemo(
      () => Array.from(missingApproversInWorkflow.values()),
      [missingApproversInWorkflow],
    )

    const handleContinue = useCallback(() => {
      const updatedUsersPolicy = usersPolicy.map((usersPolicy) => {
        const updatedPolicy: VaultUserPolicy = {
          ...usersPolicy,
        }

        if (missingApproversInWorkflow.has(usersPolicy.user.id)) {
          updatedPolicy.permissions = usersPolicy.permissions.filter(
            (permission) => permission !== VaultUserPolicyRoleEnum.APPROVER,
          )
        }

        return updatedPolicy
      })

      setUsersPolicy(updatedUsersPolicy)

      onContinue?.()
    }, [missingApproversInWorkflow, onContinue, setUsersPolicy, usersPolicy])

    return (
      <Modal open={open} size="sm" disableClose>
        <Modal.Title>
          {t`createVault.approval.unassignedApproversWarning.title`}
        </Modal.Title>
        <Modal.Body>
          <p className="text-center text-base text-black-600 font-circular-regular">
            {t`createVault.approval.unassignedApproversWarning.description`}
          </p>

          <hr className="my-6 h-px border-black-100" />

          <div className="text-sm text-black-600">
            {t`createVault.approval.unassignedApproversWarning.newPermissions`}
          </div>
          <ul className="mt-2 space-y-2">
            {approvers.map((approver) => {
              const userPolicy = usersPolicy.find(
                (userPolicy) => userPolicy.user.id === approver.id,
              )

              const canApproveAndInitiate =
                userPolicy?.permissions.includes(
                  VaultUserPolicyRoleEnum.APPROVER,
                ) &&
                userPolicy?.permissions.includes(
                  VaultUserPolicyRoleEnum.OPERATOR,
                )

              return (
                <li key={approver.id} className="flex flex-col gap-y-0.5">
                  <div className="flex gap-x-2">
                    <div className="text-sm leading-5 text-black-600 font-circular-bold">
                      {approver.name}
                    </div>
                    <div className="text-sm leading-5 text-black-400 font-circular-regular">
                      {approver.email}
                    </div>
                  </div>
                  <div className="text-sm leading-5 text-black-400 font-circular-regular">
                    <Trans
                      components={{
                        b: <span />,
                      }}
                      i18nKey={
                        canApproveAndInitiate
                          ? 'modals.vault:createVault.permissions.options.initiate'
                          : 'modals.vault:createVault.permissions.options.viewOnly'
                      }
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer variant="stretch-equal">
          <Button onClick={onCancel} variant="secondary">
            {t('common:cancel')}
          </Button>
          <Button onClick={handleContinue} variant="primary">
            {t('common:continue')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

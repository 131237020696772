import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * PAH blockchain USDC access permission.
 */
export const PAH_BLOCKCHAIN: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_PAH_BLOCKCHAIN_ROLE],
}

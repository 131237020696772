import { useCallback, useState } from 'react'

import { Button, Icon } from '@circlefin/components'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useSetMfaRequiredMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { IconProps } from '@circlefin/components/lib/Icon'

export interface SetupProps {
  /**
   * Callback after successfully agreeing to set up 2FA.
   */
  onSetup?: () => void | Promise<void>
}

const authenticatorApps: Record<string, IconProps['name']> = {
  googleAuthenticator: 'Misc/GoogleAuthenticator',
  oktaVerify: 'Misc/OKTA',
  onePassword: 'Misc/1Password',
  authy: 'Misc/Authy',
  duo: 'Misc/DUO',
}

export const Setup: React.FC<SetupProps> = ({ onSetup }) => {
  const { t } = useTranslation('mfa')
  const { track, trackLinkClick } = useSegment()

  const [setMfaRequired, { error, reset }] = useSetMfaRequiredMutation()

  const [loading, setLoading] = useState(false)

  const submit = useCallback(async () => {
    setLoading(true)

    try {
      await setMfaRequired()
      await onSetup?.()
    } catch {
      // Error handled in GraphQLErrorBoundary
    } finally {
      setLoading(false)
    }
  }, [onSetup, setMfaRequired])

  const handleClick = useCallback(() => {
    track(SegmentEvents.ButtonClicked, {
      props: {
        values: 'Continue',
      },
    })

    void submit()
  }, [submit, track])

  const handleTrackLinkClick = useCallback(
    (name: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      trackLinkClick(e, {
        props: {
          label: name,
        },
      })
    },
    [trackLinkClick],
  )

  const deviceType = /android/i.test(navigator.userAgent) ? 'android' : 'ios'

  return (
    <GraphQLErrorBoundary error={error} retry={reset}>
      <div className="flex max-w-2xl flex-col items-start font-circular">
        {/* Heading */}
        <h2 className="type-h-page-sm">{t`enrollment.setup.title`}</h2>
        <p className="mt-1 text-neutral-subtle type-intro-sm">{t`enrollment.setup.subtitle`}</p>

        {/* Authenticator Apps */}
        <p className="mt-8 text-neutral-subtle type-body-base">{t`enrollment.setup.description`}</p>
        <div className="mt-2 flex flex-row flex-wrap gap-x-4 gap-y-2">
          {Object.entries(authenticatorApps).map(([name, icon]) => {
            return (
              <a
                key={name}
                className="flex flex-row items-center gap-x-1"
                href={t(`enrollment.setup.links.${name}.${deviceType}`)}
                onClick={handleTrackLinkClick(name)}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon name={icon} size={32} />
                <span className="text-neutral type-body-xs">
                  {t(`enrollment.setup.links.${name}.label`)}
                </span>
              </a>
            )
          })}
        </div>

        {/* Continue Button */}
        <Button
          className="mt-12 w-full"
          loading={loading}
          onClick={handleClick}
          variant="primary"
        >
          {t`enrollment.setup.cta`}
        </Button>
      </div>
    </GraphQLErrorBoundary>
  )
}

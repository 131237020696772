import type { MutationUpdaterFn, Reference } from '@apollo/client'
import type { RemoveUserMutation } from '@shared/graphql'

/**
 * Remove User from the cache.
 */
export const removeUser: MutationUpdaterFn<RemoveUserMutation> = (
  cache,
  { data },
) => {
  if (data?.removeUser) {
    cache.modify({
      fields: {
        users(existingUsers = [], { readField }) {
          return existingUsers.filter(
            (userRef: Reference) =>
              data?.removeUser?.userId !== readField('id', userRef),
          )
        },
      },
    })
  }
}

import { useMemo } from 'react'

import { Header, Icon, NumberBox, Tooltip } from '@circlefin/components'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useCircleManagedCustodyBalanceQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const MainWalletsOnlyNumbers: React.FC = () => {
  const { t } = useTranslation('custody/common')

  const { data, loading, error } = useCircleManagedCustodyBalanceQuery({
    pollInterval: BALANCE_POLL_INTERVAL, // 10sec interval
    fetchPolicy: 'cache-and-network',
  })

  const { travelRuleRequired } = useTravelRuleRequired()

  const tooltipText = useMemo(() => {
    return t(
      travelRuleRequired
        ? 'numbers.tooltip.circleManagedUSDCOnly'
        : 'numbers.tooltip.circleManaged',
    )
  }, [t, travelRuleRequired])

  const balanceLoading = !data && loading // if we have cached data, ignore loading

  return (
    <Header.Numbers className="mt-7" primary={false}>
      <NumberBox loading={balanceLoading} size="lg">
        <NumberBox.Amount currency="USD">
          {error ? undefined : data?.custodyBalances.circleManaged?.amount}
        </NumberBox.Amount>
        <NumberBox.Title>
          <div className="flex items-center justify-start">
            <span className="flex flex-row">{t`numbers.total`}</span>
            <Tooltip className="z-60" content={tooltipText} place="bottom">
              <span className="ml-1.5">
                <Icon name="InformationCircleSolid" size={16} />
              </span>
            </Tooltip>
          </div>
        </NumberBox.Title>
      </NumberBox>
    </Header.Numbers>
  )
}

import type { FieldMergeFunction } from '@apollo/client'
import type { DelayedWithdrawalsSettings } from '@shared/graphql'

/**
 * Replace DelayedWithdrawalsSettings.
 */
export const replaceDelayedWithdrawalsSettings: FieldMergeFunction<DelayedWithdrawalsSettings> =
  (_, incoming, { cache }) => {
    cache.modify({
      fields: {
        delayedWithdrawalsSettings() {
          return incoming
        },
      },
    })
    return incoming
  }

import { useCallback, useEffect } from 'react'

import { useForm, y } from '@circlefin/form'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useTransfer } from '../../../hooks/transfer'

import type { BankAccount } from '@shared/graphql'

const schema = y.object({
  /**
   * (required) Currency.
   */
  currency: y.mixed<Currency>().oneOf(Object.values(Currency)).required(),
})

export type SelectCurrencyFormState = y.InferType<typeof schema>

interface SelectCurrencyQueryProps {
  /**
   * Pre-selected bank account.
   */
  bankAccount?: BankAccount
}

export const SelectCurrency: React.FC<SelectCurrencyQueryProps> = ({
  bankAccount,
}) => {
  const { t } = useTranslation('modals.transfer')
  const { track } = useSegment()
  const { router } = useModal()
  const modalHistory = useModalHistory()

  const [{ currency = Currency.USDC }, { setCurrency }] = useCurrentCurrency()
  const [, { handleValueChange }] = useTransfer()

  const { travelRuleRequired } = useTravelRuleRequired()

  useEffect(() => {
    // if the prefilled bank account is a sen account and has a currency defined, we can skip the currency selection screen
    // and replace route with selectBankAccount step
    if (
      bankAccount &&
      TypeGuards.BankAccount.isSen(bankAccount) &&
      bankAccount.currency != null
    ) {
      // update context state before navigating
      setCurrency({
        currency: bankAccount.currency,
      })

      handleValueChange({
        showSelectCurrencyScreen: false,
      })

      router.replace({
        pathname: routes.transfer.wire.selectBankAccount,
        query: {
          bankAccount,
        },
      })
    }

    // Skip currency selection for Singapore customers, only USDC is supported in Singapore
    // TODO: [SG EURC Launch] remove this when EURC is supported in SG
    // TODO: Add unit test to cover the SG case
    if (travelRuleRequired) {
      setCurrency({
        currency: Currency.USD,
      })

      handleValueChange({
        showSelectCurrencyScreen: false,
      })

      router.replace({
        pathname: routes.transfer.wire.selectBankAccount,
        query: {
          bankAccount,
        },
      })
    }
  }, [bankAccount, handleValueChange, router, setCurrency, travelRuleRequired])

  const currencyOptions = [Currency.USD, Currency.EUR]

  const [Form] = useForm<SelectCurrencyFormState>({
    schema,
    values: {
      currency: currencyOptions.includes(currency) ? currency : Currency.USD,
    },
  })

  const handleSubmit = useCallback(
    (values: SelectCurrencyFormState) => {
      setCurrency(values)

      router.push({
        pathname: routes.transfer.wire.selectBankAccount,
        query: {
          bankAccount,
        },
      })
    },
    [bankAccount, router, setCurrency],
  )

  const handleContinueClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ContinueClicked, {
        event,
      })
    },
    [track],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 5}>
      <Center variant="horizontal">
        <Form className="w-176 font-circular" onSubmit={handleSubmit}>
          <div data-testid="select-currency-step-content">
            <h2 className="mb-6 text-center text-xl font-circular-bold">
              {t('wire.selectCurrency.title')}
            </h2>

            <h3 className="mt-2 text-center text-lg leading-6 font-circular-regular">
              {t('wire.selectCurrency.subtitle')}
            </h3>

            <Center className="mt-6">
              <Form.Dropdown
                className="w-100"
                items={currencyOptions.map((currency) => ({
                  label: currency,
                  value: currency,
                }))}
                label={t('common:currency')}
                name="currency"
              />
            </Center>

            <Center className="mt-8">
              <Form.SubmitButton
                className="w-64"
                onClick={handleContinueClick}
                variant="primary"
              >
                {t('common:continue')}
              </Form.SubmitButton>
            </Center>
          </div>
        </Form>
      </Center>
    </FullScreen>
  )
}

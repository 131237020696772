import type { XpayFormValues } from './Forms/CrossRiver/Xpay/Xpay.Form'
import type { CbitFormValues } from './Forms/CustomersBank/Cbit/Cbit.Form'
import type { IbanFormValues } from './Forms/Iban/Iban.Form'
import type { NonIbanCanadaFormValues } from './Forms/NonIban/Canada/Canada.Form'
import type { NonIbanDefaultFormValues } from './Forms/NonIban/Default/Default.Form'
import type { WireFormValues } from './Forms/Wire/Wire.Form'
import type { PixFormValues } from '../AccountInformation/Forms/Pix/Pix.Form'

export enum InformationVariants {
  IBAN = 'IBAN',
  NON_IBAN = 'NON_IBAN',
  NON_IBAN_CANADA = 'NON_IBAN_CANADA',
  CBIT = 'CBIT',
  CUBI_WIRE = 'CUBI_WIRE',
  WIRE = 'WIRE',
  XPAY = 'XPAY',
  CRB_WIRE = 'CRB_WIRE',
  RTP = 'RTP',
  PIX = 'PIX',
}

export interface InformationForms {
  /**
   * IBAN Form Values.
   */
  iban?: IbanFormValues
  /**
   * NonIBAN Form Values.
   */
  nonIban?: NonIbanDefaultFormValues
  /**
   * NonIBAN (Canada) Form Values.
   */
  nonIbanCanada?: NonIbanCanadaFormValues
  /**
   * Cbit Form Values.
   */
  cbit?: CbitFormValues
  /**
   * Wire Form Values.
   */
  wire?: WireFormValues
  /**
   * XPAY Form Values.
   */
  xpay?: XpayFormValues
  /**
   * PIX Form Values.
   */
  pix?: PixFormValues
}

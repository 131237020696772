import { useCallback, useState } from 'react'

import { CodeInput, InlineNotification, TextLink } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface UpdatePinCodeInputsInterface {
  /**
   * Submit handler.
   */
  handleSubmit?: (oldPinCode: string, newPinCode: string) => void
}

const PIN_CODE_LENGTH = 4

export const UpdatePinCodeInputs: React.FC<UpdatePinCodeInputsInterface> = ({
  handleSubmit,
}) => {
  const { t } = useTranslation('mfa')
  const { router } = useModal()

  const [oldPinCode, setOldPinCode] = useState<string>()
  const [newPinCode, setNewPinCode] = useState<string>()
  const [confirmCode, setConfirmCode] = useState<string>()
  const [mismatchedError, setMismatchedError] = useState(false)

  const handleOldCodeChanged = useCallback((code: string) => {
    setOldPinCode(code)
  }, [])

  const handleNewCodeChanged = useCallback((code: string) => {
    setNewPinCode(code)
  }, [])

  const handleConfirmCodeChanged = useCallback((code: string) => {
    setConfirmCode(code)
  }, [])

  const handleOldPinCodeEntered = useCallback(
    (code: string) => {
      handleOldCodeChanged(code)

      if (newPinCode && newPinCode === confirmCode) {
        handleSubmit?.(code, newPinCode)
      }
    },
    [confirmCode, handleOldCodeChanged, handleSubmit, newPinCode],
  )

  const handleNewPinCodeEntered = useCallback(
    (code: string) => {
      handleNewCodeChanged(code)

      // If confirm code hasn't been typed, we don't want to show mismatch error yet
      setMismatchedError(Boolean(confirmCode) && code !== confirmCode)

      if (
        code === confirmCode &&
        oldPinCode &&
        oldPinCode.length === PIN_CODE_LENGTH
      ) {
        handleSubmit?.(oldPinCode, code)
      }
    },
    [confirmCode, handleNewCodeChanged, handleSubmit, oldPinCode],
  )

  const handleConfirmCodeEntered = useCallback(
    (code: string) => {
      handleConfirmCodeChanged(code)

      // If new code hasn't been typed, we don't want to show mismatch error yet
      setMismatchedError(Boolean(newPinCode) && code !== newPinCode)

      if (
        code === newPinCode &&
        oldPinCode &&
        oldPinCode.length === PIN_CODE_LENGTH
      ) {
        handleSubmit?.(oldPinCode, code)
      }
    },
    [handleConfirmCodeChanged, newPinCode, oldPinCode, handleSubmit],
  )

  const handleResetPinCode = useCallback(() => {
    void router.push(routes.mfa.resetPinCode)
  }, [router])

  return (
    <>
      <div className="flex flex-col items-start type-body-sm-bold">
        {/* TODO [LEX-1291]: Add label to the CodeInput component in components-web */}
        <p>{t('updatePinCode.currentPin')}</p>
        <CodeInput
          allowedCharacters="numeric"
          ariaLabel={t('updatePinCode.currentPin')}
          length={PIN_CODE_LENGTH}
          onChange={handleOldCodeChanged}
          onComplete={handleOldPinCodeEntered}
          isPassword
        />
      </div>

      <TextLink
        className="mt-4 w-40 text-left"
        onClick={handleResetPinCode}
        size="xs"
        variant="primary"
      >
        {t('updatePinCode.resetCta')}
      </TextLink>

      <div className="flex flex-col items-start pt-6 type-body-sm-bold">
        {/* TODO [LEX-1291]: Add label to the CodeInput component in components-web */}
        <p>{t('updatePinCode.createPin')}</p>
        <CodeInput
          allowedCharacters="numeric"
          ariaLabel={t('updatePinCode.createPin')}
          disabled={!oldPinCode}
          length={PIN_CODE_LENGTH}
          onChange={handleNewCodeChanged}
          onComplete={handleNewPinCodeEntered}
          isPassword
        />
      </div>

      <div className="flex flex-col items-start pt-8 type-body-sm-bold">
        {/* TODO [LEX-1291]: Add label to the CodeInput component in components-web */}
        <p>{t('updatePinCode.confirmPin')}</p>
        <CodeInput
          allowedCharacters="numeric"
          ariaLabel={t('updatePinCode.confirmPin')}
          disabled={!newPinCode}
          length={PIN_CODE_LENGTH}
          onChange={handleConfirmCodeChanged}
          onComplete={handleConfirmCodeEntered}
          isPassword
        />
      </div>

      <InlineNotification
        className="pt-4"
        intent="warning"
        visible={mismatchedError}
      >
        {t('updatePinCode.mismatchedCodes')}
      </InlineNotification>
    </>
  )
}

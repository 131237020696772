import { useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLET_APPROVAL,
} from '@services/permissions'
import { WalletApprovalTransactionDetailDocument } from '@shared/graphql'

import { WalletApprovalPolicy } from '../../../buttons'

export interface WalletApprovalPendingTransactionVoteButtonsProps {
  /**
   * Pending transaction detail.
   */
  proposalId: string
}

export const WalletApprovalPendingTransactionVoteButtons: React.FC<WalletApprovalPendingTransactionVoteButtonsProps> =
  ({ proposalId }) => {
    const [, WalletsMutationPermissionStatus] = usePermission(
      CIRCLE_WALLETS_MUTATION,
    )
    const [, ApprovalPermissionStatus] = usePermission(CIRCLE_WALLET_APPROVAL)

    const isAuthorized = useMemo(
      () =>
        WalletsMutationPermissionStatus.isAuthorized &&
        ApprovalPermissionStatus.isAuthorized,
      [
        ApprovalPermissionStatus.isAuthorized,
        WalletsMutationPermissionStatus.isAuthorized,
      ],
    )

    const refetchQueries = useMemo(
      () => [
        {
          query: WalletApprovalTransactionDetailDocument,
          variables: {
            proposalId,
          },
        },
      ],
      [proposalId],
    )

    if (!isAuthorized) {
      return null
    }

    return (
      <>
        <WalletApprovalPolicy.RejectApprovalRequest
          proposalId={proposalId}
          refetchQueries={refetchQueries}
          size="base"
        />
        <WalletApprovalPolicy.ApproveApprovalRequest
          proposalId={proposalId}
          refetchQueries={refetchQueries}
          size="base"
        />
      </>
    )
  }

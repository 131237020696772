import { useCallback, useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { BlockchainAlert } from '@features/blockchain/components'
import { BlockchainForms } from '@features/blockchain/forms'
import { InfoAlert } from '@features/delayed-withdrawals/containers'
import { LocalsForms } from '@features/locales/forms'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  BlockchainActivityType,
  Currency,
  useBlockchainsQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const schema = y.object({
  /**
   * Recipient name.
   */
  description: y.string(),
  /**
   * Recipient address.
   */
  address: BlockchainForms.Input.AddressSchema.required(),
  /**
   * Recipient blockchain.
   */
  blockchain: BlockchainForms.Dropdown.BlockchainSchema.required().optional(),
  /**
   * Recipient blockchain currency.
   */
  currency: LocalsForms.Dropdown.CurrencySchema,
  /**
   * Some blockchain addresses require a memo field.
   */
  memo: y.string(),
})

export type AddressDetailsCustomerFormValues = y.InferType<typeof schema>

/**
 * Set chain as required in submit function.
 */
type AddressDetailsSubmitValues = AddressDetailsCustomerFormValues &
  Required<Pick<AddressDetailsCustomerFormValues, 'blockchain'>>

export interface AddressDetailsCustomerFormProps {
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: AddressDetailsSubmitValues) => void
  /**
   * Loading state of mutation.
   */
  loading: boolean
  /**
   * Whether the user requires a travel rule enabled flow.
   */
  travelRuleRequired: boolean
}

export const AddressDetailsCustomerForm: React.FC<AddressDetailsCustomerFormProps> =
  ({ loading, onSubmit, travelRuleRequired }) => {
    const { t } = useTranslation('address-book/modals')

    const blockchains = useBlockchainsQuery({
      variables: {
        activityType: BlockchainActivityType.ALL,
      },
    })

    const [Form, { watch, trigger }] =
      useForm<AddressDetailsCustomerFormValues>({
        schema,
        defaultValues: {
          currency: Currency.USD,
        },
      })

    // Handles form submit
    const handleSubmit = useCallback(
      (values: AddressDetailsCustomerFormValues) => {
        // chain is required
        if (values.blockchain) {
          onSubmit({ ...values, blockchain: values.blockchain })
        }
      },
      [onSubmit],
    )

    // Handle Currency change.
    const handleCurrencyChange = useCallback(
      (newVal: SelectListItem<Currency>) => {
        void blockchains.refetch({
          currency: newVal.value,
        })
      },
      [blockchains],
    )

    // Form Values
    const blockchain = watch('blockchain')

    // Handle field change dependencies
    watch(({ address }, { name }) => {
      // When the blockchain field changed and address is not empty
      if (name === 'blockchain' && address !== undefined) {
        // Trigger revalidation
        void trigger('address')
      }
    })

    const showTravelRuleNotes = useMemo(() => {
      return (
        travelRuleRequired && blockchain != null && !blockchain.isTRMSupported
      )
    }, [travelRuleRequired, blockchain])

    return (
      <GraphQLErrorBoundary
        error={blockchains.error}
        retry={blockchains.refetch}
      >
        <Center>
          <Form
            className="grid w-96 gap-y-6"
            data-testid="address-details-form"
            onSubmit={handleSubmit}
          >
            {/* Currency
             * Shows if singapore launch is not enabled OR
             * if the travel rule test is not required.
             * If travel rule IS required, this isn't shown and instead the user
             * is limited to only USD/USDC
             */}
            {!travelRuleRequired && (
              <LocalsForms.Dropdown.Currency
                className="w-full"
                data-testid="input-currency"
                onChange={handleCurrencyChange}
              />
            )}

            {/* Blockchains */}
            <div className="flex flex-col gap-3">
              <BlockchainForms.Dropdown.Blockchains
                activityType={BlockchainActivityType.WITHDRAWAL}
                className="w-full"
                currency={watch('currency')}
                data-testid="dropdown-blockchain"
                placeholder={t`addAddress.addressDetails.customer.blockchainPlaceholder`}
              />
              {showTravelRuleNotes && (
                <FixedBanner status="info">
                  {t`addAddress.addressDetails.customer.travelRule`}
                </FixedBanner>
              )}
            </div>

            {/* Address */}
            <BlockchainForms.Input.Address
              className="w-full"
              data-testid="input-address"
              placeholder={t`addAddress.addressDetails.customer.addressPlaceholder`}
            />

            {/* Description */}
            <Form.Input
              className="w-full"
              data-testid="input-description"
              label={t`addAddress.addressDetails.customer.description`}
              name="description"
              placeholder={t`addAddress.addressDetails.customer.nicknamePlaceholder`}
            />

            {/* Memo */}
            <BlockchainForms.Input.Memo
              chain={blockchain?.chain}
              className="w-full"
              data-testid="input-memo"
            />

            {/* Blockchain Alert */}
            <BlockchainAlert blockchain={blockchain?.chain} />

            {/* Delayed Withdrawals Alert */}
            <InfoAlert />

            {/* Flow Disclaimer */}
            {blockchain?.chain === BlockchainAbbreviation.FLOW && (
              <p className="w-full text-center text-sm">
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="address-book/modals:addAddress.addressDetails.flowDisclaimer"
                />
              </p>
            )}

            {/* Submit Form */}
            <Center className="pt-6" variant="horizontal">
              <Form.SubmitButton
                className="w-64"
                loading={loading}
                variant="primary"
              >{t`common:continue`}</Form.SubmitButton>
            </Center>
          </Form>
        </Center>
      </GraphQLErrorBoundary>
    )
  }

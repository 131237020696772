import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Wallet policy tab section.
 * Display policy tab on wallet details page.
 *
 */
export const WALLET_POLICY_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.AGENT_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_READ_ROLE,
  ],
}

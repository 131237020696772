import { useCallback } from 'react'

import { Button, Statement } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const WireTransfer: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const { track } = useSegment()
  const modal = useModal()

  const onClick = useCallback(() => {
    track(SegmentEvents.LinkedAccountsClicked)
    modal.router.push(routes.bankAccount.link.location)
  }, [track, modal])

  return (
    <FullScreen>
      <Statement
        className="w-128"
        iconName="LibrarySolid"
        status="info"
        subtitle={t(`noLinked.wireTransfer.subTitle`)}
        title={t`noLinked.wireTransfer.title`}
        variant="page"
      >
        <Button className="w-64" onClick={onClick} variant="secondary">
          {t`common:continue`}
        </Button>
      </Statement>
    </FullScreen>
  )
}

export default WireTransfer

import { Card, Glyph, Icon, TextLink } from '@circlefin/components'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import type { GlyphProps } from '@circlefin/components/lib/Glyph'

const actions: Record<string, GlyphProps['name']> = {
  insights: 'Blog',
  usdc: 'USDC',
  home: 'House',
}

export const AwaitingReview: React.FC = () => {
  const { t } = useTranslation('onboard/account-setup')
  const { trackLinkClick } = useSegment()

  return (
    <div className="m-auto flex max-w-4xl flex-col items-center">
      {/* Header */}
      <div className="max-w-2xl text-center">
        <Icon.Circle
          className="m-auto"
          intent="success"
          name="CheckCircleSolid"
          size="large"
        />
        <h2 className="mb-8 mt-4 text-neutral-strong type-h-page-sm">{t`awaitingReview.title`}</h2>
        <p className="text-neutral type-body-base">
          {t(`awaitingReview.description`)}
        </p>
      </div>

      {/* Actions */}
      <h3 className="mt-12 text-center type-h-title-lg">{t`awaitingReview.actions.title`}</h3>

      <div className="mt-8 flex flex-row flex-wrap justify-center gap-4">
        {Object.keys(actions).map((key) => (
          <Card key={key} className="w-64">
            <Card.Content className="pb-2">
              <div className="flex flex-row items-center gap-x-2">
                <Glyph name={actions[key]} />
                <h4 className="text-neutral-strong font-circular-bold">
                  {t(`awaitingReview.actions.${key}.title`)}
                </h4>
              </div>

              <p className="mt-4 text-left text-neutral-subtle type-body-sm">
                {t(`awaitingReview.actions.${key}.description`)}
              </p>
            </Card.Content>

            <Card.Footer className="pt-0">
              <TextLink.Link
                href={t(`awaitingReview.actions.${key}.link`)}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
                variant="primary"
              >
                {t(`awaitingReview.actions.${key}.cta`)}
              </TextLink.Link>
            </Card.Footer>
          </Card>
        ))}
      </div>
    </div>
  )
}

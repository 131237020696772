import classNames from 'classnames'

import { FormGridElement } from './FormGridElement/FormGridElement'

interface SubComponents {
  Element: typeof FormGridElement
}

export interface FormGridProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const FormGrid: React.FC<FormGridProps> & SubComponents = ({
  children,
  className,
  ...rest
}) => (
  <div
    className={classNames(
      className,
      'grid grid-cols-2 w-full mt-6 content-start gap-x-10 gap-y-5 flex-grow flex-shrink-0 pb-8',
    )}
    {...rest}
  >
    {children}
  </div>
)

FormGrid.displayName = 'FormGrid'
FormGrid.Element = FormGridElement

import type { ChangeEvent } from 'react'
import { useCallback } from 'react'

import { findSectionByPath } from '@services/sections'
import { useRouter } from 'next/router'

import { SegmentClientService, SegmentEvents } from '../client'

import type { Segment } from '../../server/server.types'
import type { SegmentPageName } from '@services/sections/types'

const {
  postToEndpoint,
  getPageProperties,
  getModalScreenAttributeName,
  getModalScreenNameFromEvent,
} = SegmentClientService()

type Event<T = Element> = ChangeEvent<T> | React.MouseEvent<T, MouseEvent>

interface TrackProperties {
  /**
   * Page name where the track call was made.
   */
  page?: SegmentPageName
  /**
   * The name of the modal step where the track call was made.
   */
  modal_screen?: string
  /**
   * Any other tracking properties we want to pass to segment.
   */
  [key: string]: unknown
}

interface TrackBaseOptions {
  /**
   * Event properties.
   */
  props?: TrackProperties
  /**
   * Flag to disable automatic page name tracking.
   */
  trackPageName?: boolean
  /**
   * Flag to disable automatic modal screen name tracking.
   */
  trackModalScreenName?: boolean
  /**
   * Current event object, trigger by a click event for example.
   */
  event?: Event
  /**
   * Traits about the current user.
   */
  userTraits?: Segment.User.Traits
}

/**
 * UseSegment hook - our React components can utilize this hook to track user events in segment.
 */
export const useSegment = () => {
  const router = useRouter()

  // TODO: move identify, group, page, track to SegmentService, and expose these functions via the useSegment hook
  // const { identify, group, page, track } = SegmentService()

  const identify = ({
    traits,
  }: Pick<Segment.Identify.Input, 'traits'> = {}) => {
    void postToEndpoint({
      type: 'identify',
      input: {
        traits,
        pageProperties: getPageProperties(),
      },
    })
  }

  const alias = async () => {
    await postToEndpoint({
      type: 'alias',
    })
  }

  const group = () => {
    void postToEndpoint({
      type: 'group',
      input: {
        pageProperties: getPageProperties(),
      },
    })
  }

  const page = ({
    userTraits,
  }: Pick<Segment.Page.Input, 'userTraits'> = {}) => {
    void postToEndpoint({
      type: 'page',
      input: {
        properties: getPageProperties(),
        userTraits,
      },
    })
  }

  const track = useCallback(
    (eventName: string, options: TrackBaseOptions | undefined = {}) => {
      const {
        trackPageName = true,
        trackModalScreenName = true,
        event,
        props = {},
        userTraits,
      } = options

      const properties: TrackProperties = {
        ...props,
      }

      if (trackPageName && !props?.page) {
        // inject the page name automatically if the caller of this function chooses to do so
        // or they have not manually passed in a page prop
        const pageName = findSectionByPath(router?.pathname)?.segment?.page
        if (pageName) {
          properties.page = pageName
        }
      }

      if (trackModalScreenName && event && !props?.modal_screen) {
        // inject the modal_screen name automatically if the caller of this function chooses to do so
        // or they have not manually passed in a modal_screen prop
        const screenName = getModalScreenNameFromEvent(event)
        if (screenName) {
          properties.modal_screen = screenName
        }
      }

      void postToEndpoint({
        type: 'track',
        input: {
          event: eventName,
          properties,
          userTraits,
          pageProperties: getPageProperties(),
        },
      })
    },
    [router?.pathname],
  )

  const trackLinkClick = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      options: Omit<TrackBaseOptions, 'event'> | undefined = {},
    ) => {
      track(SegmentEvents.LinkClicked, {
        ...options,
        event: e,
        props: {
          ...(options.props ?? {}),
          link_text: e.currentTarget.text,
          link_destination: e.currentTarget.href,
        },
      })
    },
    [track],
  )

  const trackMultiModalCloseEvent = useCallback(
    (
      mapping: Record<number, string> = {},
      stepIndex: number,
      options: TrackBaseOptions = {},
    ) => {
      track(SegmentEvents.CancelClicked, {
        ...options,
        props: {
          ...options?.props,
          modal_screen: mapping[stepIndex] ?? undefined,
        },
      })
    },
    [track],
  )

  return {
    identify,
    alias,
    group,
    page,
    track,
    trackLinkClick,
    trackMultiModalCloseEvent,
    getModalScreenAttributeName,
  }
}

import { useCallback, useMemo, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useCountries } from '../../../hooks/countries'

import type {
  ComboboxOptionsLocale,
  ComboboxOptionsProps,
  ComboboxProps,
} from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { CountriesInput, CountryCode } from '@shared/graphql'

export interface CountryProps
  extends Pick<
      ComboboxProps<CountryCode>,
      | 'dropDirection'
      | 'label'
      | 'placeholder'
      | 'disabled'
      | 'warning'
      | 'className'
      | 'onChange'
    >,
    Pick<ComboboxOptionsProps<CountryCode>, 'maxMenuItems'> {
  /**
   * Custom name?
   */
  name?: string
  /**
   * Loading?
   */
  loading?: boolean
  /**
   * Country query filters.
   */
  filters?: CountriesInput
  /**
   * Manually exclude individual countries.
   */
  excludedCountries?: Array<CountryCode>
}

const Combobox = createFormCombobox()

export const Country: React.FC<CountryProps> = ({
  className,
  disabled = false,
  warning = false,
  dropDirection = 'down',
  label,
  loading,
  maxMenuItems = 6,
  placeholder,
  name = 'country',
  filters,
  excludedCountries,
  onChange,
  ...props
}) => {
  const { t } = useTranslation('forms')
  const countries = useCountries({ ...filters })
  const [value, setValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const handleOnChange = useCallback(
    (item: SelectListItem<CountryCode> | null) => {
      if (item?.label) {
        setValue(item.label)
      }
      setSearchTerm('')
      onChange?.(item)
    },
    [setValue, setSearchTerm, onChange],
  )

  const countryListToDropdown = useMemo(
    () =>
      countries.countries
        .toDropdown()
        .filter(({ value }) => !excludedCountries?.includes(value)),
    [countries, excludedCountries],
  )

  const filtered = useMemo(() => {
    if (searchTerm) {
      return matchSorter(countryListToDropdown, searchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [countryListToDropdown, searchTerm])

  const handleInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue: string) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
    }),
    [t],
  )

  return (
    <SkeletonBox className="mt-6 h-10" loading={loading || countries.loading}>
      <Combobox
        className={className}
        data-testid={`combobox-${name}`}
        disabled={disabled}
        dropDirection={dropDirection}
        filtered={filtered}
        items={countryListToDropdown}
        label={label ?? t`combobox.locale.country.label`}
        locale={locale}
        maxMenuItems={maxMenuItems}
        name={name}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        searchTerm={searchTerm}
        value={value}
        warning={warning}
        {...props}
      />
    </SkeletonBox>
  )
}

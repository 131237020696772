import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export type TransferProps = Pick<ButtonProps, 'variant'>

export const Transfer: React.FC<TransferProps> = ({ variant }) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { router } = useModal()

  const handleClick = useCallback(() => {
    track(SegmentEvents.TransferOutOfVaultClicked)

    router.push(routes.vault.transfer.chooseTransferType)
  }, [track, router])

  return (
    <Button
      className="text-base normal-case font-circular-medium"
      onClick={handleClick}
      variant={variant}
    >{t`common:transfer`}</Button>
  )
}

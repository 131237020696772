import { useCallback } from 'react'

import { Carousel } from '@circlefin/components'
import { platformSection } from '@services/sections/lib/platform'
import { billing, fraudManagement } from '@services/sections/lib/settings'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import { FeatureTourStep } from '../FeatureTour/FeatureTour.Step'

export const ProductTour: React.FC = () => {
  const { track } = useSegment()
  const { t } = useTranslation('modals.common')

  const onBack = useCallback(() => {
    track(SegmentEvents.BackClicked)
  }, [track])

  const onNext = useCallback(() => {
    track(SegmentEvents.ContinueClicked)
  }, [track])

  return (
    <Carousel onBack={onBack} onNext={onNext} variant="modal">
      {/* API Transactions */}
      <Carousel.Step key="apiTransactions">
        <FeatureTourStep
          data-testid="product-tour-api-transactions"
          header={t`productTour.apiTransactions.header`}
          iconName="logo"
          link={{
            text: t('productTour.apiTransactions.linkText'),
            url: platformSection.route,
          }}
          subHeader={t(`productTour.apiTransactions.subHeader`)}
        />
      </Carousel.Step>

      {/* Fraud Management */}
      <Carousel.Step key="fraudManagement">
        <FeatureTourStep
          data-testid="product-tour-fraud-management"
          header={t`productTour.fraudManagement.header`}
          iconName="logo"
          link={{
            text: t('productTour.fraudManagement.linkText'),
            url: fraudManagement.route,
          }}
          subHeader={t`productTour.fraudManagement.subHeader`}
        />
      </Carousel.Step>

      {/* Billing */}
      <Carousel.Step key="billing">
        <FeatureTourStep
          data-testid="product-tour-billing"
          header={t`productTour.billing.header`}
          iconName="logo"
          link={{
            text: t('productTour.billing.linkText'),
            url: billing.route,
          }}
          subHeader={t`productTour.billing.subHeader`}
        />
      </Carousel.Step>

      {/* Reporting */}
      <Carousel.Step key="reporting">
        <FeatureTourStep
          data-testid="product-tour-reporting"
          header={t`productTour.reporting.header`}
          iconName="logo"
          link={{
            text: t('productTour.reporting.linkText'),
            url: platformSection.route,
          }}
          subHeader={t`productTour.reporting.subHeader`}
        />
      </Carousel.Step>
    </Carousel>
  )
}

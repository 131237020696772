import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

export const FRAUD_MANAGEMENT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.ACCOUNT_DASHBOARD_MANAGEMENT_ROLE,
    Role.ACCOUNT_DASHBOARD_MANAGEMENT_READ_ROLE,
  ],
  // Fraud/risk endpoints not available in sandbox.
  excludeAny: [Role.SANDBOX_USER_ROLE, Role.SANDBOX_USER_READ_ROLE],
}

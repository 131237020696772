import { useCallback, useMemo, useRef, useState } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { SegmentEvents, useSegment } from '@services/segment'
import { isErrorCode, MFA_REQUIRE } from '@shared/apollo/lib/error/codes'
import { useIdempotencyKey } from '@shared/idempotency-key'

import { MfaContext } from './context'

import type { MfaError, MfaSuccess, MfaRequest } from './context'
import type { ModalProps } from '@circlefin/components/lib/Modal'
import type { ModalHistory } from '@circlefin/modal-router'
import type { MfaCodes } from '@shared/apollo/links'

export interface MfaRoutesProps {
  /**
   * MFA Code route.
   */
  code: string
  /**
   * MFA Success route.
   */
  success: string
  /**
   * MFA Error route.
   */
  error: string
}

export interface MfaProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * MFA Modal Routes.
   */
  routes: MfaRoutesProps
}

/**
 * Mfa Context Provider.
 */
export const MfaProvider: React.FC<MfaProviderProps> = ({
  children,
  routes,
}) => {
  const { track } = useSegment()
  const { push, replace } = useModalHistory()
  const [request, setRequest] = useState<MfaRequest>({})
  const size = useRef<ModalProps['size']>()
  const { resetIdempotencyKey } = useIdempotencyKey()

  /**
   * Redirect to success screen.
   */
  const success = useCallback(
    ({ message, description, duration = 3000 }: MfaSuccess = {}) => {
      // Track mfa success event in segment
      track(SegmentEvents.MFASuccess)

      // After a successful mfa call, reset the idempotency key so that any new flows can have a new generated idempotency key
      resetIdempotencyKey()

      // Redirect to MFA success screen
      push({
        pathname: routes.success,
        options: {
          disableBack: true,
          size: size.current,
          coolwhip:
            size.current === 'screen'
              ? {
                  variant: 'fade-top/green-blue-violet',
                }
              : undefined,
        },
        query: {
          message,
          description,
          duration,
        },
      })
    },
    [track, resetIdempotencyKey, push, routes.success],
  )

  /**
   * Redirect to error screen.
   */
  const error = useCallback(
    ({ message, error }: MfaError) => {
      // Track mfa failure event in segment
      track(SegmentEvents.MFAFailure)

      const routeContext: ModalHistory = {
        pathname: routes.error,
        options: {
          disableBack: true,
          size: size.current,
          coolwhip:
            size.current === 'screen'
              ? {
                  variant: 'fade-top/green-blue-violet',
                }
              : undefined,
        },
        query: {
          message,
          error,
        },
      }

      // If error is an MFA error, navigate to MFA error screen
      if (isErrorCode(MFA_REQUIRE, error?.graphQLErrors)) {
        push(routeContext)
        return
      }

      // If an error is not an MFA error, replace the current route with error screen.
      // (Clicking on the retry button in the Error screen will redirect back to the step where the mutation took place)
      replace(routeContext)
    },
    [track, routes.error, replace, push],
  )

  /**
   * MFA context for request headers.
   */
  const context = (code: MfaCodes): MfaContext => {
    // If SCA codes
    if (code.type === 'sca') {
      return {
        mfaRequired: false,
        headers: {
          'X-MFA-ID': code.oktaCode.id,
          'X-MFA-CODE': code.oktaCode.code,
          'X-MFA-PIN-CODE-ID': code.pinCode.id,
          'X-MFA-PIN-CODE': code.pinCode.code,
        },
      }
    }

    // If TOTP code
    return {
      mfaRequired: false,
      headers: {
        'X-MFA-ID': code.totpCode.id,
        'X-MFA-CODE': code.totpCode.code,
      },
    }
  }

  /**
   * Open MFA modal.
   */
  const open = useCallback(
    (request: MfaRequest) => {
      // Set onComplete callback
      setRequest(request)

      // Set Modal Size (Default to Multi-Step)
      size.current = request.variant === 'FullScreen' ? 'screen' : undefined

      // Open MFA modal with abort event
      // when the modal close end
      push({
        pathname: routes.code,
        options: {
          size: size.current,
          coolwhip:
            size.current === 'screen'
              ? {
                  variant: 'fade-top/green-blue-violet',
                }
              : undefined,
        },
      })
    },
    [push, routes.code],
  )

  /**
   * Memorize Provider Value.
   */
  const value = useMemo(
    () => ({
      success,
      error,
      context,
      request,
      open,
    }),
    [error, open, request, success],
  )

  return <MfaContext.Provider value={value}>{children}</MfaContext.Provider>
}

import { useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'

interface WarningsProps {
  /**
   * Max Amount.
   */
  maxAmount?: number
  /**
   * Max transactions.
   */
  maxTransactions?: number
  /**
   * Max Amount Error.
   */
  maxAmountError?: string
  /**
   * Max transactions Error.
   */
  maxTransactionsError?: string
}

export const Warnings: React.FC<WarningsProps> = ({
  maxAmount,
  maxTransactions,
  maxAmountError,
  maxTransactionsError,
}) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const { money } = useMoney({ locale: 'en-US' })
  const [{ currency = Currency.USD }, { tokenToCurrency }] =
    useCurrentCurrency()

  const [{ userLimits }] = useWalletApprovalPolicy()

  const showMaximumAmountPerDayWarning = useMemo(() => {
    return (
      !maxAmountError &&
      maxAmount != null &&
      maxAmount > 0 &&
      (userLimits ?? []).some(
        (userLimit) => Number(userLimit.maxAmount ?? 0) > Number(maxAmount),
      )
    )
  }, [maxAmountError, maxAmount, userLimits])

  const showMaximumTransactionsPerDayWarning = useMemo(() => {
    return (
      !maxTransactionsError &&
      maxTransactions != null &&
      maxTransactions > 0 &&
      (userLimits ?? []).some(
        (userLimit) =>
          Number(userLimit.maxTransactions ?? 0) > Number(maxTransactions),
      )
    )
  }, [maxTransactionsError, maxTransactions, userLimits])

  return (
    <>
      <FixedBanner
        className="mt-4"
        status="warning"
        visible={showMaximumAmountPerDayWarning}
      >
        <FixedBanner.Description>
          {maxAmount != null &&
            t('limits.policy.warnings.maxAmount', {
              amount: money({
                number: maxAmount,
                variant: tokenToCurrency(currency),
              }),
            })}
        </FixedBanner.Description>
      </FixedBanner>

      <FixedBanner
        className="mt-4"
        status="warning"
        visible={showMaximumTransactionsPerDayWarning}
      >
        <FixedBanner.Description>
          {t('limits.policy.warnings.maxTransactions', {
            value: maxTransactions,
          })}
        </FixedBanner.Description>
      </FixedBanner>
    </>
  )
}

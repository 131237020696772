import { mergeInAppNotifications } from './mergeInAppNotifications'
import { mergeLongPollInAppNotification } from './mergeLongPollInAppNotification'
import { mergeMarkInAppNotificationsAsRead } from './mergeMarkInAppNotificationsAsRead'
import { mergeSettings } from './mergeSettings'
import { replaceDelayedWithdrawalsSettings } from './replaceDelayedWithdrawalsSettings'

import type { TypePolicies } from '@apollo/client'

/**
 * Type Polices.
 */
export const typePolicies: TypePolicies = {
  OnboardingFieldValue: {
    keyFields: ['id', 'refId'],
  },
  Mutation: {
    fields: {
      /**
       * Merge `mergeMarkInAppNotificationsAsRead` mutation.
       */
      markInAppNotificationsAsRead: {
        merge: mergeMarkInAppNotificationsAsRead,
      },
      /**
       * Set DelayedWithdrawalsSettings on `setDelayedWithdrawalsSettings` mutation.
       */
      setDelayedWithdrawalsSettings: {
        merge: replaceDelayedWithdrawalsSettings,
      },
      /**
       * Merge Settings on `setSettingsFeature` mutation.
       */
      setSettingsFeature: {
        merge: mergeSettings,
      },
    },
  },
  Query: {
    fields: {
      /**
       * Merge Entity in all queries.
       */
      entity: {
        merge: true,
      },
      /**
       * Merge Balances in all queries.
       */
      balances: {
        merge: true,
      },
      /**
       * Merge Settings in all queries.
       */
      settings: {
        merge: true,
      },
      /**
       * Merge Payouts in all queries.
       */
      merchantPayouts: {
        merge: true,
      },
      /**
       * Merge unread in app notification queries.
       */
      unreadInAppNotifications: {
        keyArgs: false,
        merge: mergeInAppNotifications,
      },
      /**
       * Merge all in app notification queries.
       */
      allInAppNotifications: {
        keyArgs: false,
        merge: mergeInAppNotifications,
      },
      /**
       * Merge long poll in app notification queries.
       */
      longPollInAppNotification: {
        merge: mergeLongPollInAppNotification,
      },
    },
  },
}

import { DescriptionList } from '@circlefin/components'

export interface LabelProps
  extends React.ComponentProps<typeof DescriptionList.Label> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Label: React.FC<LabelProps> = ({ children, ...rest }) => (
  <DescriptionList.Label className="text-sm font-circular-bold" {...rest}>
    {children}
  </DescriptionList.Label>
)

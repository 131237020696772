import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Name.
   */
  bankName: y.string().max(35, { key: 'max', max: 35 }).required(),
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank ID.
   */
  bankId: y
    .string()
    .required()
    .numeric()
    .length(8, { key: 'number.length', length: 8 }),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().required(),
  /**
   * Account Type.
   */
  accountType: y.string().required(),
  /**
   * Company Tax ID.
   */
  taxId: y
    .string()
    .required()
    .numeric()
    .matches(/^(?:\d{11}|\d{14})$/, { message: { key: 'taxId' } }),
})

const accTypesShortcut =
  'modals.bankAccount:link.accountInformation.pixAccountTypes'

export type PixFormValues = y.InferType<typeof schema>

export interface PixFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: PixFormValues) => void
}

export const PixForm: React.FC<PixFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const accountTypes = [
    {
      label: t(`${accTypesShortcut}.checking`),
      value: 'checking',
    },
    {
      label: t(`${accTypesShortcut}.savings`),
      value: 'savings',
    },
    {
      label: t(`${accTypesShortcut}.prepaid`),
      value: 'prepaid',
    },
    {
      label: t(`${accTypesShortcut}.salary`),
      value: 'salary',
    },
    {
      label: t(`${accTypesShortcut}.other`),
      value: 'other',
    },
  ]

  const [Form] = useForm<PixFormValues>({
    schema,
    mode: 'onBlur',
    defaultValues: {
      ...information.pix,
    },
  })

  const onFormSubmit = useCallback(
    (pix: PixFormValues) => {
      // Set data to context
      setInformation({ pix })

      // Pass data to submit callback (for testing)
      onSubmit?.(pix)
    },
    [onSubmit, setInformation],
  )
  return (
    <div>
      <Form className="grid w-full gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-bankName"
          label={t('modals.bankAccount:link.accountInformation.bankName')}
          name="bankName"
        />

        <Form.Input
          className="w-full"
          data-testid="input-nickname"
          label={t('modals.bankAccount:link.accountInformation.nickname')}
          name="nickname"
        />

        <Form.Input
          className="w-full"
          data-testid="input-bankId"
          label={t('modals.bankAccount:link.accountInformation.ispb')}
          name="bankId"
        />

        <Form.Input
          className="w-full"
          data-testid="input-accountNumber"
          label={t('modals.bankAccount:link.accountInformation.accountNumber')}
          name="accountNumber"
        />

        <Form.Dropdown
          className="w-full"
          data-testid="input-accountType"
          items={accountTypes}
          label={t('modals.bankAccount:link.accountInformation.accountType')}
          name="accountType"
        />

        <Form.Input
          className="w-full"
          data-testid="input-taxId"
          label={t('modals.bankAccount:link.accountInformation.taxId')}
          name="taxId"
        />

        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Form>
    </div>
  )
}

import { createContext } from 'react'

import type {
  RolrCreateAccountFormValues,
  RolrRedemptionAmountValues,
  RolrBizInfoFormValues,
} from '../../containers'

/**
 * Utility type to extract specific keys from a given type.
 */
type ExtractKeys<T, U> = {
  [K in keyof T]: K extends U ? K : never // Map over all keys of T, and select the key if it extends one of U, otherwise use 'never'.
}[keyof T] // Transforms the mapped type into a union, filtering out 'never' and leaving only the valid keys.

/**
 * Define a generic type where S is constrained to type RolrSetupStepNames, and V is the value type for that key.
 */
type GenericStep<S extends RolrSetupStepNames, V> = {
  step: S
  values?: V
}

export type RolrSetupStepNames = ExtractKeys<
  Required<RolrSetupContextState>,
  'landing' | 'businessInfo' | 'authRep' | 'redemptionAmount' | 'createAccount'
>

export type RolrLandingStep = GenericStep<
  'landing',
  {
    letsGoClicked: boolean
  }
>

export type RolrBusinessInformationStep = GenericStep<
  'businessInfo',
  {
    /**
     * Is Eea user?
     */
    isEeaUser: boolean
  } & RolrBizInfoFormValues
>

export type RolrAuthRepStep = GenericStep<
  'authRep',
  {
    isAuthRep: boolean
  }
>

export type RolrRedemptionAmountStep = GenericStep<
  'redemptionAmount',
  RolrRedemptionAmountValues
>

export type RolrCreateAccountStep = GenericStep<
  'createAccount',
  RolrCreateAccountFormValues
>

export type RolrSetupSteps =
  | RolrLandingStep
  | RolrBusinessInformationStep
  | RolrAuthRepStep
  | RolrRedemptionAmountStep
  | RolrCreateAccountStep

export interface RolrSetupContextState {
  /**
   * Current step.
   */
  step: RolrSetupStepNames
  /**
   * Landing state.
   */
  landing?: RolrLandingStep['values']
  /**
   * Business information state.
   */
  businessInfo?: RolrBusinessInformationStep['values']
  /**
   * Auth rep state.
   */
  authRep?: RolrAuthRepStep['values']
  /**
   * Redemption amount state.
   */
  redemptionAmount?: RolrRedemptionAmountStep['values']
  /**
   * Create account state.
   */
  createAccount?: RolrCreateAccountStep['values']
}

export interface RolrSetupContextActions {
  /**
   * Determine if step is valid.
   */
  isStepValid: (step: RolrSetupStepNames) => boolean
  /**
   * Are all dependencies valid for a given step.
   */
  areAllDependenciesValidForStep: (step: RolrSetupStepNames) => boolean
  /**
   * Go to step.
   */
  goToStep: (step: RolrSetupStepNames) => void
  /**
   * Ensure user visiting current route has met all pre conditions, otherwise redirect to the last unmet step.
   */
  validateStepDependencies: (route: string) => void
  /**
   * Determine next step based on completed step (determine where to route user).
   */
  computeNextStep: (action: RolrSetupSteps) => void
}

export const RolrSetupContext =
  createContext<[RolrSetupContextState, RolrSetupContextActions] | null>(null)

import { useCallback } from 'react'

import { Button, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useVaultRegistrationTokenQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'
import ReactQRCode from 'qrcode.react'

export const Pair: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const { data, error, loading, refetch } = useVaultRegistrationTokenQuery()

  const onDone = useCallback(() => {
    modal.close({ context: 'onDismiss' })
  }, [modal])

  return (
    <FullScreen totalSteps={3}>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <div className="mx-auto flex h-full max-w-192 flex-col justify-between">
          <Center>
            <h2 className="text-center text-3xl text-black-600 font-circular-bold">
              {t`pairNewDevice.pair.title`}
            </h2>
            <ol className="m-auto mt-12 flex max-w-104 flex-col gap-y-8">
              <li className="flex items-center gap-7">
                <span className="flex size-14 shrink-0 items-center justify-center rounded-full border border-black-75 text-2xl text-black-500 font-circular-medium">
                  {t`common:number.1`}
                </span>
                <span>{t`pairNewDevice.pair.step-1`}</span>
              </li>
              <li>
                <span className="flex items-center gap-x-7">
                  <span className="flex size-14 shrink-0 items-center justify-center rounded-full border border-black-75 text-2xl text-black-500 font-circular-medium">
                    {t`common:number.2`}
                  </span>
                  <span>{t`pairNewDevice.pair.step-2`}</span>
                </span>
                <span className="mt-2 flex gap-x-7">
                  <div className="w-14" />
                  <SkeletonBox className="size-32" loading={loading}>
                    <div className="size-32">
                      <ReactQRCode
                        height="100%"
                        renderAs="svg"
                        value={data?.vaultRegistrationToken.pairingUrl ?? ''}
                        width="100%"
                      />
                    </div>
                  </SkeletonBox>
                </span>
              </li>
            </ol>

            <Button
              className="mx-auto mt-12 w-48"
              onClick={onDone}
              variant="primary"
            >
              {t('common:done')}
            </Button>
          </Center>
        </div>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

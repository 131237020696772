import { useCallback, useEffect, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useCountries } from '@features/locales/hooks/countries'
import { UsersForms } from '@features/users/forms'
import { useSegment } from '@services/segment'
import { CountryCode } from '@shared/graphql'
import { useTransComponent } from '@shared/hooks/component'
import useTranslation from 'next-translate/useTranslation'

import { CountryWarningMessage } from '../../components'
import { PersonalEmailWarning } from '../PersonalEmailWarning/PersonalEmailWarning'

export const createAccountFormSchemaFields = {
  firstName: y.string().required(),
  lastName: y.string().required(),
  businessName: y.string().required(),
  businessEmail: y.string().required().email(),
  country: y.mixed<CountryCode>().oneOf(Object.values(CountryCode)).required(),
  password: UsersForms.Input.generatePasswordSchema('businessEmail'),
  marketingConsent: y.boolean(),
  privacyConsent: y.boolean().required().oneOf([true]),
}

export const createAccountFormSchema = y.object(createAccountFormSchemaFields)

export type CreateAccountFormValues = y.InferType<
  typeof createAccountFormSchema
>

export interface CreateAccountFormProps {
  /**
   * Cb invoked when a user fills/submits in the form.
   */
  onSubmit: (values: CreateAccountFormValues) => void | Promise<void>
  /**
   * Option to disable country field.
   */
  disableCountryField?: boolean
  /**
   * Initial values.
   */
  initialValues?: Partial<CreateAccountFormValues>
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  onSubmit,
  disableCountryField = false,
  initialValues,
}) => {
  const { t } = useTranslation('onboard/common')
  const [isEea, setIsEea] = useState(false)
  const [Form, { watch, getFieldState, formState, trigger }] =
    useForm<CreateAccountFormValues>({
      schema: createAccountFormSchema,
      defaultValues: initialValues ?? {
        country: CountryCode.US,
      },
      mode: 'onTouched',
    })
  const {
    data: countriesData,
    loading: countriesLoading,
    countries,
  } = useCountries({
    blockchain: {
      registeredIn: true,
    },
    onboarding: {
      sanctioned: false,
    },
    onCompleted: (data) => {
      if (initialValues?.country != null) {
        const isEea =
          data.countries.list.find(({ code }) => code === initialValues.country)
            ?.onboarding.eea === true

        setIsEea(isEea)
      }
    },
  })
  const { trackLinkClick } = useSegment()
  const [TermsWithLinks] = useTransComponent('onboard/common', (link, i) => (
    <a
      key={`checkbox-link-${i}`}
      href={link}
      onClick={trackLinkClick}
      rel="noopener noreferrer"
      target="_blank"
    />
  ))

  // Re-trigger validation when dependent inputs change
  const handleTrigger = useCallback(() => {
    if (getFieldState('password').isTouched) {
      void trigger('password')
    }
  }, [getFieldState, trigger])

  const handlePasswordChange = useCallback(() => {
    // Trigger validation for password on every change
    void trigger('password')
  }, [trigger])

  const handleSubmit = useCallback(
    async (values: CreateAccountFormValues) => {
      await onSubmit(values)
    },
    [onSubmit],
  )

  useEffect(() => {
    const subscription = watch(({ country }) => {
      if (country != null) {
        const isEea =
          countriesData?.countries.list.find(({ code }) => code === country)
            ?.onboarding.eea === true

        setIsEea(isEea)
      }
    })

    return () => subscription.unsubscribe()
  }, [countriesData?.countries.list, watch])

  return (
    <Form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 lg:flex-row">
        <Form.Input
          className="w-full"
          data-testid="input-firstName"
          label={t`createAccount.form.firstName`}
          name="firstName"
          placeholder={t`createAccount.form.firstName`}
        />
        <Form.Input
          className="w-full"
          data-testid="input-lastName"
          label={t`createAccount.form.lastName`}
          name="lastName"
          placeholder={t`createAccount.form.lastName`}
        />
      </div>
      <Form.Input
        className="w-full"
        data-testid="input-businessName"
        label={t`createAccount.form.businessName`}
        name="businessName"
        placeholder={t`createAccount.form.businessName`}
      />
      <Form.Input
        className="w-full"
        data-testid="input-businessEmail"
        label={t`createAccount.form.businessEmail`}
        message={
          !getFieldState('businessEmail').error && (
            <PersonalEmailWarning email={watch('businessEmail') ?? ''} />
          )
        }
        name="businessEmail"
        onChange={handleTrigger}
        placeholder={t`createAccount.form.businessEmail`}
      />
      <SkeletonBox className="mt-6 h-10 w-full" loading={countriesLoading}>
        <Form.Dropdown
          data-testid="dropdown-country"
          disabled={disableCountryField}
          items={countries.toDropdown()}
          label={t`createAccount.form.country`}
          message={
            <CountryWarningMessage
              countriesQueryData={countriesData}
              countryCode={watch('country')}
            />
          }
          name="country"
        />
      </SkeletonBox>
      <Form.PasswordInput
        aria-describedby="validation-list"
        className="w-full"
        data-testid="input-password"
        label={t`createAccount.form.password`}
        name="password"
        onChange={handlePasswordChange}
        placeholder={t`createAccount.form.password`}
        hideError
      />
      <UsersForms.Input.PasswordValidationList
        disabled={formState.isSubmitting}
        errors={formState.errors.password}
        id="validation-list"
        shouldShowValidation={Boolean(formState.dirtyFields.password)}
      />
      {watch('country') !== CountryCode.US && (
        <Form.Checkbox
          data-testid="marketing-consent"
          label={
            <TermsWithLinks
              i18nArrayKey="createAccount.form.marketingConsent.links"
              i18nKey="createAccount.form.marketingConsent.label"
            />
          }
          name="marketingConsent"
        />
      )}
      <SkeletonBox className="mt-6 h-10 w-full" loading={countriesLoading}>
        <Form.Checkbox
          data-testid="privacy-consent"
          label={
            isEea ? (
              <TermsWithLinks
                i18nArrayKey="createAccount.form.privacyConsent.eea.links"
                i18nKey="createAccount.form.privacyConsent.eea.label"
              />
            ) : (
              <TermsWithLinks
                i18nArrayKey="createAccount.form.privacyConsent.default.links"
                i18nKey="createAccount.form.privacyConsent.default.label"
              />
            )
          }
          name="privacyConsent"
        />
      </SkeletonBox>
      <Form.SubmitButton className="mt-2" variant="primary">
        {t`createAccount.form.submitButton`}
      </Form.SubmitButton>
    </Form>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import { PaymentMethodType } from '@shared/graphql'

/**
 * Pay Invoice Actions.
 */
interface PayInvoiceActions {
  /**
   * Value change handler.
   */
  setPaymentDetails: (values: Partial<PaymentDetails>) => void
}

/**
 * Payment Details.
 */
interface PaymentDetails {
  /**
   * Payment type.
   */
  paymentType: PaymentMethodType
  /**
   * Card ID.
   */
  cardId?: string
  /**
   * Boolean indicating whether a payment method has been selected.
   */
  paymentMethodSelected: boolean
}

const defaultInvoicePaymentInput: PaymentDetails = {
  paymentType: PaymentMethodType.card,
  cardId: undefined,
  paymentMethodSelected: false,
}

/**
 * Create Pay Invoice Context.
 */
const PayInvoiceContext = createContext<[PaymentDetails, PayInvoiceActions]>([
  { ...defaultInvoicePaymentInput },
  { setPaymentDetails: () => null },
])

/**
 * Add Credit Card Provider props.
 */
interface AddPayInvoiceProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: PaymentDetails
}

/**
 * Pay Invoice Provider.
 */
export const PayInvoiceProvider: React.FC<AddPayInvoiceProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<PaymentDetails>({
    ...defaultInvoicePaymentInput,
    ...initValues,
  })

  const setPaymentDetails = useCallback((values: Partial<PaymentDetails>) => {
    setValues((curr) => ({
      ...curr,
      paymentMethodSelected: true,
      ...values,
    }))
  }, [])

  useEffect(() => {
    const resetContext = () => {
      setPaymentDetails(defaultInvoicePaymentInput)
    }

    events.on('onCloseStart', resetContext)

    return () => {
      events.off('onCloseStart', resetContext)
    }
  })

  return (
    <PayInvoiceContext.Provider value={[values, { setPaymentDetails }]}>
      {children}
    </PayInvoiceContext.Provider>
  )
}

/**
 * Pay Invoice hook.
 */
export const usePayInvoice = () => {
  return useContext(PayInvoiceContext)
}

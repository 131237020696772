import { InAppNotificationType } from '@shared/graphql'

import type {
  InAppNotification,
  MainWalletPolicyApprovalRequestNotification,
  MainWalletPendingTransactionApprovalRequestNotification,
} from '@shared/graphql'

/**
 * Type guard to check if a notification is a main wallet policy approval request notification.
 */
export const isMainWalletPolicyApprovalRequest = (
  payload: InAppNotification,
): payload is MainWalletPolicyApprovalRequestNotification =>
  payload.type === InAppNotificationType.currencyPolicyApprovalRequest

/**
 * Type guard to check if a notification is a main wallet pending transaction approval request notification.
 */
export const isMainWalletPendingTransactionApprovalRequestNotification = (
  payload: InAppNotification,
): payload is MainWalletPendingTransactionApprovalRequestNotification =>
  payload.type === InAppNotificationType.transactionApprovalRequest

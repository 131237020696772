import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

// TODO: replace with PROTECTED when this synth role is removed
// https://circlepay.atlassian.net/browse/BBSS-1160
/**
 * In app notification section.
 */
export const IN_APP_NOTIFICATION_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_IN_APP_NOTIFICATION_SECTION_ROLE],
}

import { Chip, Icon } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { VaultWalletConnectPolicy } from '@shared/graphql'

export interface PolicySummaryProps {
  /**
   * List of operators.
   */
  operators?: VaultWalletConnectPolicy['operators']
  /**
   * List of approvers.
   */
  approvers?: VaultWalletConnectPolicy['approvers']
  /**
   * On edit click.
   */
  onEdit?: () => void
}

export const PolicySummary: React.FC<PolicySummaryProps> = ({
  operators = [],
  approvers = [],
  onEdit,
}) => {
  const { t } = useTranslation('modals.vault')

  return (
    <div className="grid grid-cols-1 gap-y-6">
      <div className="flex justify-between">
        <div className="items-center">
          <h4 className="text-sm text-neutral-subtle font-circular-medium">
            {t('forms:combobox.vault.walletConnectOperators.label')}
          </h4>
          <div className="mt-2 flex flex-wrap gap-2">
            {operators.map((user) => (
              <Chip
                key={user.id}
                className="shrink-0 px-4 py-2 text-base text-neutral-strong font-circular-bold"
                data-testid="user-chip"
                variant="default/info"
              >
                {user.name}
              </Chip>
            ))}
          </div>
          {approvers.length > 0 && (
            <>
              <h4 className="mt-9 text-sm text-neutral-subtle font-circular-medium">
                {t('forms:combobox.vault.walletConnectApprovers.label')}
              </h4>
              <div className="mt-2 flex flex-wrap gap-2">
                {approvers.map((user) => (
                  <Chip
                    key={user.id}
                    className="shrink-0 px-4 py-2 text-base text-neutral-strong font-circular-bold"
                    data-testid="user-chip"
                    variant="default/info"
                  >
                    {user.name}
                  </Chip>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="items-start">
          <Icon name="PencilOutline" onClick={onEdit} size={18} />
        </div>
      </div>
    </div>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'
import { VAULT_CREATOR_MUTATION } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export interface CreateProps extends Pick<ButtonProps, 'variant'> {
  /**
   * Optional className that should be applied to the button.
   */
  className?: string
  /**
   * Is wallet connect step visible.
   */
  isWalletConnectStepVisible?: boolean
}

export const Create: React.FC<CreateProps> = ({
  className,
  isWalletConnectStepVisible = false,
  variant,
}) => {
  const [{ Authorized }] = usePermission(VAULT_CREATOR_MUTATION)
  const { t } = useTranslation('vaults')
  const { track } = useSegment()

  const handleClick = useCallback(() => {
    track(SegmentEvents.CreateVaultClicked)
  }, [track])

  return (
    <Authorized>
      <ModalLink
        onClick={handleClick}
        pathname={
          FeatureSwitch.walletConnectEnabled()
            ? routes.vault.chooseVaultType
            : routes.vault.createVault.initialize
        }
        query={{ isWalletConnectStepVisible: isWalletConnectStepVisible }}
      >
        <Button
          className={className}
          variant={variant}
        >{t`buttons.vault.create`}</Button>
      </ModalLink>
    </Authorized>
  )
}

import { useCallback, useMemo } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useSetBankAccountNicknameMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount } from '@shared/graphql'

const schema = y.object({
  /**
   * (required) Bank Account Nickname.
   */
  nickname: y.string().required(),
})

export type SetNicknameFormValues = y.InferType<typeof schema>

export interface SetNicknameQueryProps {
  /**
   * Bank Account.
   */
  bankAccount?: BankAccount
}

export const SetNickname: React.FC<SetNicknameQueryProps> = ({
  bankAccount,
}) => {
  const { t } = useTranslation('modals.bankAccount')
  const modal = useModal()
  const [Form] = useForm<SetNicknameFormValues>({
    schema,
    values: {
      nickname: bankAccount?.nickname ?? '',
    },
  })

  const closeModal = useCallback(() => {
    modal.close()
  }, [modal])

  const [setNickname, { loading, error, reset }] =
    useSetBankAccountNicknameMutation({
      onCompleted: closeModal,
    })

  const handleSubmit = useCallback(
    ({ id, type }: BankAccount) =>
      (variables: SetNicknameFormValues) => {
        void setNickname({
          variables: {
            bankAccountId: id,
            accountType: type,
            ...variables,
          },
        })
      },
    [setNickname],
  )

  const title = useMemo(
    () =>
      bankAccount?.nickname
        ? t`setNickname.title.edit`
        : t`setNickname.title.add`,
    [bankAccount?.nickname, t],
  )

  return (
    <BaseTitle title={title}>
      <PropsErrorBoundary<SetNicknameQueryProps>
        props={{ bankAccount }}
        variant="page"
      >
        {({ bankAccount }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <Form onSubmit={handleSubmit(bankAccount)}>
              {/* Nickname Input */}
              <div className="h-20">
                <Center>
                  <Form.Input
                    className="w-96"
                    data-testid="nickname-input"
                    disabled={loading}
                    label={t`setNickname.placeholder`}
                    name="nickname"
                    autoFocus
                  />
                </Center>
              </div>

              {/* Modal Footer */}
              <Modal.Footer variant="stretch">
                {/* Cancel */}
                <Button
                  disabled={loading}
                  onClick={closeModal}
                  variant="secondary"
                >
                  {t('setNickname.cancel')}
                </Button>

                {/* Save Changes */}
                <Form.SubmitButton loading={loading} variant="primary">
                  {t`setNickname.save`}
                </Form.SubmitButton>
              </Modal.Footer>
            </Form>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { ScrollArea } from '@circlefin/components'

import { MainNav } from '../../MainNav/MainNav'

import { groups } from './SettingsNav.Top.Groups'

export const SettingsNavTop: React.FC = () => {
  return (
    <ScrollArea className="h-full" autoHide>
      <MainNav.Groups groups={groups} />
    </ScrollArea>
  )
}

import { FeatureList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export const VaultFeatures: React.FC = () => {
  const { t } = useTranslation('vaults')

  return (
    <FeatureList direction="horizontal" size="lg" variant="frosting">
      <FeatureList.Item
        heading={t`vaultFeatures.permissions.title`}
        icon="UserGroupOutline"
      >
        {t`vaultFeatures.permissions.description`}
      </FeatureList.Item>
      <FeatureList.Item heading={t`vaultFeatures.assets.title`} icon="Unknown">
        {t`vaultFeatures.assets.description`}
      </FeatureList.Item>
      <FeatureList.Item
        heading={t`vaultFeatures.workflows.title`}
        icon="ShieldCheckOutline"
      >
        {t`vaultFeatures.workflows.description`}
      </FeatureList.Item>
    </FeatureList>
  )
}

import { useCallback } from 'react'

import { Carousel } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { USER_MANAGEMENT_QUERY } from '@services/permissions'
import { useSegment, SegmentEvents } from '@services/segment'

import {
  BankLinking,
  ContactSupport,
  ManageUsers,
  StablecoinExpress,
} from './Steps'

export const FeatureTour: React.FC = () => {
  const { track } = useSegment()
  const [, { isAuthorized }] = usePermission(USER_MANAGEMENT_QUERY)

  const onBack = useCallback(() => {
    track(SegmentEvents.BackClicked)
  }, [track])

  const onNext = useCallback(() => {
    track(SegmentEvents.ContinueClicked)
  }, [track])

  return (
    <Carousel onBack={onBack} onNext={onNext} variant="modal">
      {/* Manage Users */}
      <Carousel.Step key="users" inactive={!isAuthorized}>
        <ManageUsers />
      </Carousel.Step>

      {/* Bank Linking */}
      <Carousel.Step key="bankLinking">
        <BankLinking />
      </Carousel.Step>

      {/* Stablecoin Express */}
      <Carousel.Step key="stablecoinExpress">
        <StablecoinExpress />
      </Carousel.Step>

      {/* Contact Support */}
      <Carousel.Step key="support">
        <ContactSupport />
      </Carousel.Step>
    </Carousel>
  )
}

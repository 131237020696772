import { Icon } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../../../hooks/permission'

export const SubmissionBlocked: React.FC = () => {
  const { t } = useTranslation('vaults')

  const { vaultMetadata } = useVaultPermission()

  return (
    <div className="flex w-76 items-center justify-center space-x-3 text-sm leading-4 text-black-600 font-circular-book">
      <Icon className="flex-none text-yellow-400" name="ExclamationSolid" />
      <p className="block w-full whitespace-normal break-words">
        {t`buttons.vault.helpText.submissionBlocked`}
        <ModalLink
          className="ml-0.5"
          pathname={routes.vault.vaultDetails.submissionBlocked}
          query={{
            vaultId: vaultMetadata?.id,
            vaultPolicyId: vaultMetadata?.draftPolicyId,
          }}
        >{t`common:learn-more`}</ModalLink>
      </p>
    </div>
  )
}

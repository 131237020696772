import { Header, NumberBox } from '@circlefin/components'
import { VAULT_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useVaultBalanceQuery } from '@shared/graphql'

export interface VaultDetailHeaderNumbersProps {
  /**
   * Id of the vault.
   */
  vaultId: string
}

export const VaultDetailHeaderNumbers: React.FC<VaultDetailHeaderNumbersProps> =
  ({ vaultId }) => {
    const { data, loading } = useVaultBalanceQuery({
      variables: {
        vaultId,
      },
      pollInterval: VAULT_BALANCE_POLL_INTERVAL,
    })

    return (
      <Header.Numbers className="mt-7">
        <NumberBox loading={loading} size="lg">
          <NumberBox.CustomAmount
            decimals={data?.vaultBalance.asset.decimals}
            isCryptoCurrency={false}
            symbol={data?.vaultBalance.asset.symbol}
            hideSymbol
            padded
          >
            {data?.vaultBalance.amount ?? ''}
          </NumberBox.CustomAmount>
        </NumberBox>
      </Header.Numbers>
    )
  }

import { useEffect, useRef } from 'react'

import { useLazyQuery } from '@apollo/client'

import type {
  LazyQueryHookOptions,
  OperationVariables,
  DocumentNode,
  NoInfer,
  ApolloError,
} from '@apollo/client'

const delay = async (time: number) => {
  await new Promise((resolve) => setTimeout(resolve, time))
}

export const createContext = (ac?: AbortController) => {
  if (!ac) {
    return
  }

  return {
    fetchOptions: {
      signal: ac.signal,
    },
  }
}

export enum UseLongPollingSignal {
  stopPolling = 'stopPolling',
}

export interface UseLongPollingParams<
  TQuery,
  TVariables extends OperationVariables = OperationVariables,
> extends Pick<LazyQueryHookOptions<TQuery, TVariables>, 'variables'> {
  /**
   * Should skip polling.
   */
  skip?: boolean
  /**
   * Query document node.
   */
  document: DocumentNode
  /**
   * Handles error, responds if it should continue to poll.
   */
  onCompleted?: (data: NoInfer<TQuery>) => void | UseLongPollingSignal
  /**
   * Handles error, responds if it should continue to poll.
   */
  onError?: (err: ApolloError) => void | UseLongPollingSignal
}

/**
 * UseLongPollingQuery - performs long polling.
 */
export function useLongPollingQuery<
  TQuery,
  TVariables extends OperationVariables = OperationVariables,
>({
  skip,
  document,
  variables,
  onCompleted,
  onError,
}: UseLongPollingParams<TQuery, TVariables>) {
  const abortControllerRef = useRef<AbortController>()

  const [poll, pollState] = useLazyQuery<TQuery, TVariables>(document, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (skip || abortControllerRef.current?.signal.aborted) {
        return
      }

      const result = onCompleted?.(data)

      if (result === UseLongPollingSignal.stopPolling) {
        return
      }

      // as soon as the current call finishes, refetch to restart the long poll call
      void poll({
        context: createContext(abortControllerRef.current),
      })
    },
    onError: (err) => {
      if (skip || abortControllerRef.current?.signal.aborted) {
        return
      }

      const result = onError?.(err)

      if (result === UseLongPollingSignal.stopPolling) {
        return
      }

      // As a start delay for some time and try calling the endpoint again.
      // We can add more complex retry logic later
      // eslint-disable-next-line promise/no-promise-in-callback
      void delay(2000).then(() =>
        poll({
          context: createContext(abortControllerRef.current),
        }),
      )
    },
  })

  useEffect(() => {
    const ac = new AbortController()
    abortControllerRef.current = ac

    const cleanup = () => {
      ac.abort()
    }

    if (skip) {
      return cleanup
    }

    void poll({
      context: createContext(abortControllerRef.current),
    })

    return cleanup
  }, [poll, skip])

  return pollState
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export interface EditProps {
  /**
   * Is wallet connect step visible.
   */
  isWalletConnectStepVisible?: boolean
}

export const Edit: React.FC<EditProps> = ({
  isWalletConnectStepVisible = false,
}) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { router } = useModal()
  const { vaultMetadata } = useVaultPermission()

  const handleClick = useCallback(() => {
    /**
     * Even though this should never happen,
     * we will just return to satisfy typescript.
     */
    if (!vaultMetadata?.id) return

    track(SegmentEvents.EditVaultPolicyClicked)

    void router.push({
      pathname: routes.vault.createVault.initialize,
      query: {
        vaultId: vaultMetadata.id,
        /**
         * Always load the draft policy, if there is already an active one to allow the user to continue
         * changing the draft policy.
         */
        policyId: vaultMetadata.draftPolicyId ?? vaultMetadata.policyId,
        isWalletConnectStepVisible,
      },
    })
  }, [vaultMetadata, router, track, isWalletConnectStepVisible])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata || !vaultMetadata.isCreator) return null

  return (
    <Button
      disabled={
        /**
         * Do not allow editing the policy, when the vault is currently in the approval process.
         */
        [
          VaultStateEnum.PENDING_APPROVAL,
          VaultStateEnum.CHANGES_PENDING_APPROVAL,
        ].includes(vaultMetadata.state)
      }
      onClick={handleClick}
      variant="secondary"
    >
      {vaultMetadata.policyId && vaultMetadata.draftPolicyId
        ? t`vaults:buttons.policy.editDraft`
        : t`common:edit`}
    </Button>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * OP blockchain USDC access permission.
 */
export const OP_BLOCKCHAIN: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_OP_BLOCKCHAIN_ROLE],
}

import { useCallback } from 'react'

import { Icon, TextLink } from '@circlefin/components'
import { homeSection } from '@services/sections/lib/home'
import { useRouteHistory } from '@shared/hooks/common/router/history'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export interface HistoryBackButtonProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

export const HistoryBackButton: React.FC<HistoryBackButtonProps> = ({
  className,
  children,
}) => {
  const { t } = useTranslation()
  const { back, replace } = useRouter()

  const { prevRoute } = useRouteHistory()

  const handleBack = useCallback(() => {
    if (prevRoute) {
      back()
      return
    }

    void replace(homeSection.route)
  }, [back, prevRoute, replace])

  return (
    <TextLink
      className={className}
      onClick={handleBack}
      size="sm"
      variant="primary"
    >
      <Icon name="ChevronLeftOutline" />
      {children ?? t('common:back')}
    </TextLink>
  )
}

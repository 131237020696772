import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { SegmentEvents, useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import { Base } from '../Base/Base'

import type { BaseTitleLayoutProps } from '../BaseTitle/BaseTitle'

export interface SaveAndExitProps extends BaseTitleLayoutProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Save and exit disabled?
   */
  disabled?: boolean
  /**
   * Loading.
   */
  loading?: boolean
  /**
   * Error message.
   */
  error?: string
  /**
   * On save handler.
   */
  onSave?: () => void
}

export const SaveAndExit: React.FC<SaveAndExitProps> = ({
  title,
  children,
  disabled,
  loading,
  error,
  onSave,
}) => {
  const { t } = useTranslation('common')
  const modal = useModal()
  const { track } = useSegment()

  const modalScreen = modal.history?.route?.options?.['data-segmentscreenname']

  const handleDiscardChanges = useCallback(() => {
    track(SegmentEvents.DiscardChangesClicked, {
      props: {
        modal_screen: modalScreen,
        confirmSaveAndExit: true,
      },
    })
    modal.close()
  }, [modal, modalScreen, track])

  const handleSaveAndExit = useCallback(() => {
    track(SegmentEvents.SaveAndExitClicked, {
      props: {
        modal_screen: modalScreen,
        confirmSaveAndExit: true,
      },
    })
    onSave?.()
  }, [modalScreen, onSave, track])

  return (
    <>
      <Modal.Title>{title}</Modal.Title>
      <Base variant="center">
        {children}

        <Modal.Footer className="mt-6" variant="stretch" dense>
          <Button onClick={handleDiscardChanges} variant="secondary">
            {t('discardChanges')}
          </Button>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={handleSaveAndExit}
            variant="primary"
          >
            {t('saveAndExit')}
          </Button>
        </Modal.Footer>

        {error && (
          <p className="mt-6 text-sm leading-5 text-red-500 font-circular-regular">
            {error}
          </p>
        )}
      </Base>
    </>
  )
}

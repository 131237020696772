import { FeatureSwitch } from '@services/feature-switch'
import { RESTRICTED, PUBLIC_ONLY } from '@services/permissions'

import type { Section } from '../types'

export const circleMintInviteSection: Section = {
  label: 'layout:page.circle-mint-invite.title',
  route: '/circle-mint-invite/[token]',
  permissions:
    FeatureSwitch.businessAccountCreationEnabled() &&
    FeatureSwitch.inviteOnlyPhase2Enabled()
      ? PUBLIC_ONLY
      : RESTRICTED,
  segment: {
    page: 'Circle Mint Invite',
  },
}

import { Button, FixedBanner } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface ManualAddressVerificationAlertProps {
  /**
   * Visibility.
   */
  visible?: boolean
  /**
   * Custom className.
   */
  className?: string
}

export const ManualAddressVerificationAlert: React.FC<ManualAddressVerificationAlertProps> =
  ({ visible = false, className }) => {
    const { t } = useTranslation('payments')

    return (
      <FixedBanner
        className={className}
        status="warning"
        visible={visible}
        multiline
      >
        <FixedBanner.Title>
          {t('manualAddressVerificationAlert.title')}
        </FixedBanner.Title>
        <FixedBanner.Description>
          {t('manualAddressVerificationAlert.description')}
        </FixedBanner.Description>
        <FixedBanner.Footer>
          <Button.Link
            href={t`manualAddressVerificationAlert.link`}
            target="_blank"
            variant="text"
          >{t`manualAddressVerificationAlert.footer`}</Button.Link>
        </FixedBanner.Footer>
      </FixedBanner>
    )
  }

import { CollapsibleBadge } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

export const PendingActivation: React.FC = () => {
  const { t } = useTranslation('vaults')

  return (
    <CollapsibleBadge
      collapsedText={t`badges.wallet.pendingActivation.collapsed`}
      variant="info"
    >
      {t`badges.wallet.pendingActivation.notCollapsed`}
    </CollapsibleBadge>
  )
}

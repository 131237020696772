import { useCallback } from 'react'

import { InformationVariants } from '../Information/Information.types'
import { useLinkBankAccount } from '../Link.Context'

import {
  useLinkIban,
  useLinkNonIban,
  useLinkNonIbanCanada,
  useLinkPix,
  useLinkWire,
  useLinkXpay,
} from './variants'
import { useLinkCbit } from './variants/Cbit/Cbit.Create'

export const useLinkCreate = () => {
  const [{ variant }] = useLinkBankAccount()
  const [createIban] = useLinkIban()
  const [createNonIban] = useLinkNonIban()
  const [createNonIbanCanada] = useLinkNonIbanCanada()
  const [createCbit] = useLinkCbit()
  const [createWire] = useLinkWire()
  const [createXpay] = useLinkXpay()
  const [createPix] = useLinkPix()

  const create = useCallback(() => {
    switch (variant) {
      case InformationVariants.IBAN:
        createIban()
        break

      case InformationVariants.NON_IBAN:
        createNonIban()
        break

      case InformationVariants.NON_IBAN_CANADA:
        createNonIbanCanada()
        break

      case InformationVariants.CBIT:
        createCbit()
        break

      case InformationVariants.CRB_WIRE:
      case InformationVariants.CUBI_WIRE:
      case InformationVariants.WIRE:
        createWire()
        break

      case InformationVariants.XPAY:
        createXpay()
        break

      case InformationVariants.PIX:
        createPix()
        break
    }
  }, [
    variant,
    createIban,
    createNonIban,
    createNonIbanCanada,
    createCbit,
    createWire,
    createXpay,
    createPix,
  ])

  return [create] as const
}

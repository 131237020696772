import { useCallback, useMemo } from 'react'

import { Icon } from '@circlefin/components'
import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { useMoney } from '@circlefin/hooks'
import { ModalLink } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { routes } from '@services/sections/modal'
import { SegmentEvents, useSegment } from '@services/segment'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useRedemptionOnlyExpress } from '../../hooks/redemption-only-express'

import type { Locale } from '@circlefin/money/variants'
import type { CircleWallet } from '@shared/graphql'

const columnHelper = createColumnHelper<CircleWallet>()

export const useCircleWalletsTableColumns = () => {
  const { t } = useTranslation('custody/common')
  const { locale } = useRouter()
  const { money } = useMoney({ locale: locale as Locale })
  const { track } = useSegment()

  const textClassNames = 'text-sm leading-5 text-neutral'

  const { canRedeem: canDepositAsRolr } = useRedemptionOnlyExpress()

  const handleTransferClick = useCallback(() => {
    track(SegmentEvents.HomePageWalletTransferClicked)
  }, [track])

  return useMemo(() => {
    /**
     * Name column.
     */
    const name = columnHelper.accessor('name', {
      header: t<string>`circleWallets.table.columns.wallet`,
      meta: {
        className: 'w-12 truncate',
      },
      cell: ({ getValue, row }) => (
        <span className={classNames(textClassNames, 'font-circular-medium')}>
          <Icon
            className="-mt-0.5 mr-3 inline text-neutral-strong"
            name={row.original.icon}
            size={18}
          />
          {getValue()}
        </span>
      ),
    })

    /**
     * Amount column.
     */
    const amount = columnHelper.accessor('balance.amount', {
      header: t<string>`circleWallets.table.columns.balance`,
      meta: {
        className: 'whitespace-normal break-all w-16',
      },
      cell: ({ getValue, row }) => (
        <span className={classNames(textClassNames)}>
          {money({
            number: getValue(),
            variant: row.original.balance.currency,
            options: {
              symbol: false,
            },
          })}
        </span>
      ),
    })

    /**
     * Currency column.
     */
    const currency = columnHelper.accessor('balance.currency', {
      header: t<string>`common:currency`,
      meta: {
        className: 'w-20',
      },
      cell: ({ getValue }) => (
        <span className={classNames(textClassNames)}>{getValue()}</span>
      ),
    })

    /**
     * Actions column.
     */
    const actions = columnHelper.display({
      id: 'actions',
      header: '',
      meta: {
        className: 'w-20',
      },
      cell: ({ row }) => (
        <div className="float-right flex items-center gap-x-10">
          <div className="flex gap-x-8">
            <ModalLink
              onClick={handleTransferClick}
              pathname={routes.transfer.chooseTransferType}
              query={{
                currency: row.original.balance.currency,
              }}
            >
              <PaymentButtons.AccountFunding.Transfer
                currency={row.original.balance.currency}
                size="sm"
                variant="text"
              >
                {t('common:transfer')}
              </PaymentButtons.AccountFunding.Transfer>
            </ModalLink>
            <PaymentButtons.AccountFunding.Deposit
              currency={row.original.balance.currency}
              disabled={!canDepositAsRolr(row.original.balance.currency)}
              eventSource="HomePageWallet"
              size="sm"
              variant="text"
            >
              {t('common:deposit')}
            </PaymentButtons.AccountFunding.Deposit>
          </div>
          <Icon
            className="ml-4 text-neutral"
            name="ChevronRightSolid"
            size={20}
          />
        </div>
      ),
    })
    return [name, amount, currency, actions]
  }, [t, money, handleTransferClick, canDepositAsRolr])
}

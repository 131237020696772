import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

export interface ResetPinState {
  /**
   * The Two Factor token used to query the status.
   */
  twoFactorToken?: string
  /**
   * The wallet backup file uploaded in order to reset PIN.
   */
  walletBackupFile?: string
}

interface ResetPinActions {
  /**
   * Set Two Factor token.
   */
  setTwoFactorToken: (twoFactorToken: string) => void
  /**
   * Set the wallet backup file.
   */
  setWalletBackupFile: (walletBackupFile: string) => void
}

const ResetPinContext = createContext<[ResetPinState, ResetPinActions]>([
  {},
  {
    setTwoFactorToken: () => null,
    setWalletBackupFile: () => null,
  },
])

/**
 * Reset Pin Provider props.
 */
interface ResetPinProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<ResetPinState>
}

export const Provider: React.FC<ResetPinProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<ResetPinState>({
    ...initValues,
  })

  const handleValueChange = useCallback((values: Partial<ResetPinState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  const actions: ResetPinActions = useMemo(
    () => ({
      setTwoFactorToken: (twoFactorToken) =>
        handleValueChange({ twoFactorToken }),
      setWalletBackupFile: (walletBackupFile) =>
        handleValueChange({ walletBackupFile }),
    }),
    [handleValueChange],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange({})
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <ResetPinContext.Provider value={[values, actions]}>
      {children}
    </ResetPinContext.Provider>
  )
}

/**
 * Reset PIN hook.
 */
export const useResetPin = () => {
  return useContext(ResetPinContext)
}

import { useMemo } from 'react'

import { useCreateVault } from '../../../../hooks/create'

export const useOutgoingAddressValidate = () => {
  const [{ outgoingAddresses, touched }] = useCreateVault()

  const isValid = useMemo(() => {
    if (!outgoingAddresses) {
      return false
    }

    return (
      outgoingAddresses.external.length > 0 ||
      outgoingAddresses.internal.length > 0
    )
  }, [outgoingAddresses])

  const isEmpty = useMemo(() => {
    if (!outgoingAddresses) {
      return true
    }

    return (
      outgoingAddresses.external.length === 0 &&
      outgoingAddresses.internal.length === 0
    )
  }, [outgoingAddresses])

  const isTouched = touched?.outgoingAddresses

  return {
    isValid,
    isEmpty,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

import { useCallback, useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal'
import { LoadingSpinner } from '@shared/components/common'
import { PropsErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'

import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../hooks/approval-policy/context'

import type { WalletApprovalPolicyInitializeQueryProps } from '../../../../hooks/approval-policy/context'

/**
 * This modal step acts as the entry point for Creating/Updating a policy. We can initialize our context store based on the props passed in.
 */
export const Initialize: React.FC<WalletApprovalPolicyInitializeQueryProps> = (
  props,
) => {
  const {
    startingStep = WalletApprovalPolicyStep.PERMISSIONS,
    currency = Currency.USDC,
    policyInEdit,
  } = props

  const { router, close } = useModal()

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const [, { setCurrency, currencyToToken }] = useCurrentCurrency()
  const [, { setPolicyInEdit }] = useWalletApprovalPolicy()

  useEffect(() => {
    setCurrency({
      currency: currencyToToken(currency),
    })
  }, [currency, currencyToToken, setCurrency])

  // Initialize our context state with the policy passed in as query props. This policy being edited will act as the initial state for the flow.
  useEffect(() => {
    if (policyInEdit) {
      setPolicyInEdit(policyInEdit)
    }
  }, [setPolicyInEdit, policyInEdit])

  useEffect(() => {
    router.replace(
      `${routes.walletApprovalPolicy.createPolicy.switch}${startingStep}`,
    )
  }, [router, startingStep])

  return (
    <FullScreen onCloseClick={handleCloseClick} hideProgressBar>
      <PropsErrorBoundary props={props} variant="page">
        {() => (
          <Center>
            <LoadingSpinner height={65} width={65} />
          </Center>
        )}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

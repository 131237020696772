import Head from 'next/head'

export interface DescriptionProps {
  children: string
}

export const Description: React.FC<DescriptionProps> = ({ children }) => {
  const description = children

  return (
    <Head>
      <meta
        key="og:description"
        content={description}
        property="og:description"
      />
      <meta key="description" content={description} name="description" />
    </Head>
  )
}

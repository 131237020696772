import { useCallback, useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { LoadingSpinner } from '@shared/components/common'
import { PropsErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'

import { usePairDeviceContext } from '../PairDevice.Context'

import type { PairDeviceContextState } from '../PairDevice.Context'

export type InitializeProps = PairDeviceContextState

/**
 * This modal step acts as the entry point for the pair device flow. We can initialize our context store based on the props passed in.
 */
export const Initialize: React.FC<InitializeProps> = (props) => {
  const { successFlow = 'createVault' } = props

  const { router, close } = useModal()

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const [, { setSuccessFlow }] = usePairDeviceContext()

  useEffect(() => {
    setSuccessFlow({ successFlow })

    router.replace({
      pathname: routes.vault.pairDevice.download,
      query: { route: routes.vault.pairDevice.pair },
    })
  }, [router, setSuccessFlow, successFlow])

  return (
    <FullScreen onCloseClick={handleCloseClick} hideProgressBar>
      <PropsErrorBoundary props={props} variant="page">
        {() => (
          <Center>
            <LoadingSpinner height={65} width={65} />
          </Center>
        )}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

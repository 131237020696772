import { useEffect } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { Choose } from '../../containers'
import { useTransfer } from '../../hooks/transfer'

import type { Currency } from '@shared/graphql'

export interface ChooseDepositTypeQueryProps {
  /**
   * Specify if wire transfers should be disabled.
   */
  disableWireTransfer?: boolean
  /**
   * Specify modal currency.
   */
  currency?: Currency
}

export const ChooseDepositType: React.FC<ChooseDepositTypeQueryProps> = ({
  disableWireTransfer,
  currency,
}) => {
  const { t } = useTranslation('modals.transfer')
  const modalHistory = useModalHistory()
  const [, { handleValueChange }] = useTransfer()

  useEffect(() => {
    // If the currency value is passed as a query param to the "ChooseDepositType",
    // then we don't need to let the user select a currency within the wire transfer flow. That step can be skipped.
    handleValueChange({
      showSelectCurrencyScreen: currency == null,
    })
  }, [currency, handleValueChange])

  return (
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <Center variant="horizontal">
        <h2 className="mb-3 text-center text-xl font-circular-bold">
          {t`chooseDepositType.title`}
        </h2>
        <Choose.DepositType
          currency={currency}
          disableWireTransfer={disableWireTransfer}
          showSelectCurrencyScreen={currency == null}
        />
      </Center>
    </FullScreen>
  )
}

import { homeSection } from '@services/sections/lib/home'

import { MainNav } from '../../MainNav/MainNav'
import { UserMenu } from '../../UserMenu/UserMenu'

export const SettingsNavBottom: React.FC = () => {
  return (
    <MainNav className="h-auto self-end" data-testid="settings-nav-bottom">
      {/* Bottom Sections */}
      {[homeSection].map((section, index) => (
        <MainNav.Item key={index} {...section} />
      ))}

      {/* User Menu */}
      <UserMenu />
    </MainNav>
  )
}

import { useCallback } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  WalletApprovalTransactionDetailDocument,
  useCancelWalletApprovalPendingTransactionMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { DocumentNode } from '@apollo/client'

export interface CancelPendingTransactionQueryProps {
  /**
   * Policy id.
   */
  proposalId?: string
  /**
   * Refetch queries.
   */
  refetchQueries?: DocumentNode[]
}

export const CancelPendingTransaction: React.FC<CancelPendingTransactionQueryProps> =
  (props) => {
    const { t } = useTranslation('modals.walletApprovalPolicy')
    const { close } = useModal()
    const toast = useToast()

    const handleCancel = useCallback(() => {
      close({ context: 'onDismiss' })
    }, [close])

    const [cancelPendingTransaction, { loading, error, called, reset }] =
      useCancelWalletApprovalPendingTransactionMutation()

    const handleCancelPendingTransaction = useCallback(
      (proposalId: string) => () => {
        void cancelPendingTransaction({
          variables: {
            proposalId,
          },
          onCompleted: () => {
            toast.success(t(`cancelPendingTransaction.success`))
            handleCancel()
          },
          // Error is handled in the GraphQLErrorBoundary
          onError: () => undefined,
          refetchQueries: [
            {
              query: WalletApprovalTransactionDetailDocument,
              variables: {
                proposalId,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
      [cancelPendingTransaction, handleCancel, t, toast],
    )

    return (
      <PropsErrorBoundary props={props} variant="page">
        {({ proposalId }) => (
          <>
            <Modal.Header title={t`cancelPendingTransaction.title`} />
            <GraphQLErrorBoundary error={error} retry={reset} variant="page">
              <Modal.Body className="text-center">{t`cancelPendingTransaction.description`}</Modal.Body>
              <Modal.Footer variant="stretch-equal">
                <Button
                  disabled={loading || (called && !error)}
                  onClick={handleCancel}
                  variant="secondary"
                >{t`common:cancel`}</Button>
                <Button
                  disabled={called && !error}
                  loading={loading}
                  onClick={handleCancelPendingTransaction(proposalId)}
                  variant="primary"
                >{t`common:confirm`}</Button>
              </Modal.Footer>
            </GraphQLErrorBoundary>
          </>
        )}
      </PropsErrorBoundary>
    )
  }

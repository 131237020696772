import { useCallback } from 'react'

import { Button, Modal, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  IpAllowListingIpAddressesDocument,
  IpAllowListingSettingDocument,
  useIpAllowListingSettingQuery,
  useRemoveIpAllowListingIpAddressMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import type { IpAllowListingIpAddress } from '@shared/graphql'

export interface RemoveQueryProps {
  /**
   * Ip address being removed.
   */
  ipAddress?: IpAllowListingIpAddress
}

export const Remove: React.FC<RemoveQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/security')
  const [withMfa, { context, mfaSuccess, mfaError }] = useMfa()
  const modal = useModal()
  const { track } = useSegment()
  const ipSetting = useIpAllowListingSettingQuery()
  const [removeIp] = useRemoveIpAllowListingIpAddressMutation({
    refetchQueries: [
      IpAllowListingIpAddressesDocument,
      IpAllowListingSettingDocument,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error) => {
      mfaError({ error })
    },
  })

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.CancelClicked, {
        event: e,
      })

      closeModal()
    },
    [closeModal, track],
  )

  const onConfirm = useCallback(
    (ipAddress: IpAllowListingIpAddress) =>
      (e: React.MouseEvent<HTMLButtonElement>) => {
        track(SegmentEvents.ConfirmClicked, {
          event: e,
        })

        withMfa({
          onComplete: (code) => {
            void removeIp({
              variables: {
                id: ipAddress.id,
              },
              context: context(code),
            })
          },
          onAbort: closeModal,
        })
      },
    [closeModal, context, removeIp, track, withMfa],
  )

  return (
    <PropsErrorBoundary props={props} variant="page">
      {({ ipAddress }) => (
        <BaseTitle
          title={t('ipAllowlisting.confirm.remove.title', {
            ip: ipAddress.value,
          })}
          variant="center"
        >
          <GraphQLErrorBoundary
            error={ipSetting.error}
            retry={ipSetting.refetch}
            variant="page"
          >
            <SkeletonBox className="h-8 w-full" loading={ipSetting.loading}>
              {ipSetting.data?.ipAllowListingSetting.enabled && (
                <p className="text-sm leading-5 text-black-600 font-circular-regular">
                  {t(`ipAllowlisting.confirm.remove.description`)}
                </p>
              )}
            </SkeletonBox>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={onCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button onClick={onConfirm(ipAddress)} variant="primary">
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        </BaseTitle>
      )}
    </PropsErrorBoundary>
  )
}

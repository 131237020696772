import classNames from 'classnames'

export interface JustifyContentProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Justify Variant.
   */
  variant?: 'left' | 'center' | 'right'
  /**
   * Custom Styles?
   */
  className?: string
}

const variants: Record<string, string> = {
  /**
   * Left Justify.
   */
  left: 'col-start-1',
  /**
   * Center Justify.
   */
  center: 'col-start-2',
  /**
   * Right Justify.
   */
  right: 'col-start-3',
}

/**
 * Justify Component.
 */
export const JustifyContent: React.FC<JustifyContentProps> = ({
  variant = 'center',
  children,
  className,
  ...props
}) => {
  return (
    <div className={classNames(className, variants[variant])} {...props}>
      {children}
    </div>
  )
}

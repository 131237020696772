import { useMemo } from 'react'

import { Icon, SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { Currency } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { RtpTransferInstruction } from '@shared/graphql'

enum TranslationEnum {
  beneficiaryName = 'beneficiaryName',
  bankAccountNumber = 'bankAccountNumber',
  beneficiaryAddress = 'beneficiaryAddress',
  routingNumber = 'routingNumber',
  bankName = 'bankName',
  bankAddress = 'bankAddress',
  referenceIDMemo = 'referenceIDMemo',
  'swiftCode.USD' = 'swiftCode.USD',
  'swiftCode.EUR' = 'swiftCode.EUR',
  currency = 'currency',
}

type TranslationKeys = keyof typeof TranslationEnum
type GridItem = [TranslationKeys, string | undefined | null]
const isVisible = (i: GridItem | null): i is GridItem => i !== null

export interface RtpTransferInstructionGridProps {
  /**
   * Rtp transfer instruction details.
   */
  rtpTransferInstruction?: RtpTransferInstruction
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const RtpTransferInstructionGrid: React.FC<RtpTransferInstructionGridProps> =
  ({ rtpTransferInstruction, currency, loading = false }) => {
    const { t } = useTranslation('payments')

    const values = useMemo(() => {
      const currencyField: GridItem = [TranslationEnum.currency, currency]

      const beneficiaryName: GridItem = [
        TranslationEnum.beneficiaryName,
        rtpTransferInstruction?.beneficiary.name,
      ]

      const bankAccountNumber: GridItem = [
        TranslationEnum.bankAccountNumber,
        rtpTransferInstruction?.beneficiaryBank.accountNumber,
      ]

      const bankName: GridItem = [
        TranslationEnum.bankName,
        rtpTransferInstruction?.beneficiaryBank.name,
      ]

      const beneficiaryAddress: GridItem = [
        TranslationEnum.beneficiaryAddress,
        [
          rtpTransferInstruction?.beneficiary.address1 ?? '',
          rtpTransferInstruction?.beneficiary.address2 ?? '',
        ].join(' '),
      ]

      const routingNumber: GridItem = [
        TranslationEnum.routingNumber,
        rtpTransferInstruction?.beneficiaryBank.routingNumber,
      ]

      const bankAddress: GridItem = [
        TranslationEnum.bankAddress,
        [
          rtpTransferInstruction?.beneficiaryBank.address ?? '',
          rtpTransferInstruction?.beneficiaryBank.city ?? '',
          rtpTransferInstruction?.beneficiaryBank.postalCode ?? '',
          rtpTransferInstruction?.beneficiaryBank.country ?? '',
        ].join(' '),
      ]

      const swiftCodeUSD: GridItem = [
        `swiftCode.${Currency.USD}`,
        rtpTransferInstruction?.beneficiaryBank.swiftCode,
      ]

      const swiftCodeEUR: GridItem = [
        `swiftCode.${Currency.EUR}`,
        rtpTransferInstruction?.beneficiaryBank.swiftCode,
      ]

      const referenceIDMemo: GridItem = [
        TranslationEnum.referenceIDMemo,
        rtpTransferInstruction?.trackingRef,
      ]

      if (currency === Currency.EUR) {
        return [
          currencyField,
          beneficiaryName,
          bankAccountNumber,
          beneficiaryAddress,
          bankName,
          bankAddress,
          swiftCodeEUR,
          referenceIDMemo,
        ].filter(isVisible)
      }

      return [
        currencyField,
        beneficiaryName,
        bankAccountNumber,
        beneficiaryAddress,
        routingNumber,
        bankName,
        swiftCodeUSD,
        referenceIDMemo,
      ].filter(isVisible)
    }, [currency, rtpTransferInstruction])

    return (
      <>
        {values
          .filter(([fieldName]) => {
            // We filter out referenceIDMemo for virtual accounts
            return !(
              fieldName === 'referenceIDMemo' &&
              rtpTransferInstruction?.virtualAccountEnabled
            )
          })
          .map(([fieldName, value]) => (
            <SkeletonBox
              key={fieldName}
              className="block h-16"
              loading={loading}
            >
              {value && (
                <div data-testid={fieldName}>
                  <CopyToClipboard
                    key={fieldName}
                    label={t(
                      `depositFlow.beneficiaryBankAccountDetails.labels.${fieldName}`,
                    )}
                    value={value}
                  />
                </div>
              )}
            </SkeletonBox>
          ))}

        {rtpTransferInstruction?.virtualAccountEnabled === false &&
          rtpTransferInstruction?.trackingRef && (
            <div className="flex items-end">
              <p className="min-h-10 text-sm leading-5 font-circular-regular">
                <Trans
                  components={{
                    icon: (
                      <Icon
                        className="relative -top-px inline-block text-orange-400"
                        name="ExclamationSolid"
                        size={14}
                      />
                    ),
                    bold: <strong />,
                  }}
                  i18nKey="payments:depositFlow.beneficiaryBankAccountDetails.instructionMessage.rtp"
                />
              </p>
            </div>
          )}
      </>
    )
  }

import { ModalHistoryProvider } from '@circlefin/modal-router'
import { routes, registry } from '@services/sections/modal'
import { IdempotencyKeyProvider } from '@shared/idempotency-key/lib/provider'
import { MfaProvider } from '@shared/mfa/lib/provider'

import { ModalRouter } from './router'

export interface ModalProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Modal Routes.
 */
export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  return (
    <ModalHistoryProvider registry={registry}>
      <IdempotencyKeyProvider>
        <MfaProvider
          routes={{
            code: routes.mfa.code,
            success: routes.mfa.success,
            error: routes.mfa.error,
          }}
        >
          {children}
          <ModalRouter />
        </MfaProvider>
      </IdempotencyKeyProvider>
    </ModalHistoryProvider>
  )
}

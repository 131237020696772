import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'

import { Card } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { WalletForms } from '../../../../../../forms'
import { useWalletApprovalPolicy } from '../../../../../../hooks/approval-policy'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { WalletApprovalPolicyApprovalLevel, User } from '@shared/graphql'

const getTotalNumOfApprovers = (
  selected: User[],
  allowInitiatorToApproveTransfer?: boolean,
) => {
  return selected.length <= 1 ||
    allowInitiatorToApproveTransfer == null ||
    allowInitiatorToApproveTransfer
    ? selected.length
    : selected.length - 1
}

const schema = WalletForms.Combobox.policyApproverSchema.shape({
  /**
   * Allow initiator to approve transfer.
   */
  allowInitiatorToApproveTransfer: y.boolean(),
  /**
   * Min number of approvers to confirm transfer.
   */
  minNeedConfirmed: y
    .string()
    .numeric()
    .when('selected', {
      is: (selected: User[] = []) => selected.length > 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
})

type LevelFormValues = y.InferType<typeof schema>

export interface LevelFormProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * List of all approvers.
   */
  approvers: WalletApprovalPolicyApprovalLevel['approvers']
}

export const LevelForm: React.FC<LevelFormProps> = ({
  className,
  approvers,
}) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')

  const [
    { userLimits, approvalWorkflow, settings },
    { setApprovalWorkflow, setPolicySettings, setApprovalLevelInEdit },
  ] = useWalletApprovalPolicy()

  const defaultAllowInitiatorToApproveTransferValue = useMemo(() => {
    // if we have already saved this setting before, prefill it
    if (settings?.allowInitiatorToApproveTransfer != null) {
      return settings?.allowInitiatorToApproveTransfer
    }

    // Feature flag is enabled, and in this case,
    // the policy is set up in the way that there is only one approver defined in the user permissions step.
    // This is an odd policy setup, it is configured in a way that the one user can initiate and approve their own transaction.
    // So keep current behavior, otherwise API will throw an error. This setting makes sense when there is more than one approver on a policy
    return approvers.length <= 1
  }, [settings?.allowInitiatorToApproveTransfer, approvers])

  const defaultMinNeedConfirmed = useMemo(() => {
    if (!approvalWorkflow?.minNeedConfirmed) {
      return ''
    }

    // if defaultAllowInitiatorToApproveTransferValue is false, there is a chance that policy contains an invalid minNeedConfirmedValue if
    // the user saved a policy prior to the addition of this new flag.
    if (
      Number(approvalWorkflow.minNeedConfirmed) >
      getTotalNumOfApprovers(
        approvalWorkflow.approvers ?? [],
        defaultAllowInitiatorToApproveTransferValue,
      )
    ) {
      // reset the value
      return ''
    }

    return approvalWorkflow.minNeedConfirmed
  }, [
    approvalWorkflow?.approvers,
    approvalWorkflow?.minNeedConfirmed,
    defaultAllowInitiatorToApproveTransferValue,
  ])

  const [Form, { formState, watch, resetField, setValue }] =
    useForm<LevelFormValues>({
      schema,
      defaultValues: {
        selected: approvalWorkflow?.approvers ?? [],
        allowInitiatorToApproveTransfer:
          defaultAllowInitiatorToApproveTransferValue,
        minNeedConfirmed: defaultMinNeedConfirmed,
      },
    })

  const showMinNeedConfirmedError = Boolean(
    formState.touchedFields.minNeedConfirmed &&
      formState.errors.minNeedConfirmed,
  )

  const currentFormValues = watch()

  // sync onChange with provider context for save and exit validation
  useEffect(() => {
    const subscription = watch((values) => {
      setApprovalWorkflow({
        approvers: (values.selected ?? []).filter((u): u is User => u != null),
        minNeedConfirmed: values.minNeedConfirmed ?? '',
      })
      setPolicySettings({
        allowInitiatorToApproveTransfer: values.allowInitiatorToApproveTransfer,
      })
    })

    return () => subscription.unsubscribe()
  }, [setApprovalWorkflow, setPolicySettings, watch])

  const minNumOfApproversDropdownChoices: SelectListItem<string>[] =
    useMemo(() => {
      const { selected = [], allowInitiatorToApproveTransfer } =
        currentFormValues

      const totalNumOfApprovers = getTotalNumOfApprovers(
        selected,
        allowInitiatorToApproveTransfer,
      )

      return Array.from(Array(totalNumOfApprovers)).map((_, index) => ({
        label: `${index + 1}`,
        value: `${index + 1}`,
      }))
    }, [currentFormValues])

  const handleSubmit = useCallback(
    (values: LevelFormValues) => {
      setApprovalWorkflow?.({
        approvers: values.selected ?? [],
        minNeedConfirmed: values.minNeedConfirmed ?? '',
      })
      setPolicySettings({
        allowInitiatorToApproveTransfer: values.allowInitiatorToApproveTransfer,
      })
      setApprovalLevelInEdit(false)
    },
    [setApprovalWorkflow, setPolicySettings, setApprovalLevelInEdit],
  )

  const handleSelectedChange = useCallback(
    (updatedSelected: User[]) => {
      if (Number(watch('minNeedConfirmed')) > updatedSelected.length) {
        // the user has selected a `minNeedConfirmed` value greater than the updated approver list count
        resetField('minNeedConfirmed', {
          keepDirty: false,
          keepTouched: false,
          keepError: false,
          defaultValue: '',
        })
      }

      if (updatedSelected.length <= 1) {
        // The user has updated number of approvers.
        // The policy is set up in the way that there is only one approver defined, reset allowInitiatorToApproveTransfer back to the current behavior of enabling it
        resetField('allowInitiatorToApproveTransfer', {
          keepDirty: false,
          keepTouched: false,
          keepError: false,
          defaultValue: true,
        })
      }

      setValue('selectedApprover', undefined)
      setValue('selected', updatedSelected)
    },
    [watch, resetField, setValue],
  )

  const handleInitiatorToggleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const totalNumOfApprovers = getTotalNumOfApprovers(
        watch('selected') ?? [],
        e.target.checked,
      )

      if (Number(watch('minNeedConfirmed')) > totalNumOfApprovers) {
        // `minNeedConfirmed` value is greater than the total num of approvers
        resetField('minNeedConfirmed', {
          keepDirty: false,
          keepTouched: false,
          keepError: false,
          defaultValue: '',
        })
      }
    },
    [watch, resetField],
  )

  const getUserPermissions = useCallback(
    (user: User) =>
      userLimits?.find((u) => u.user.id === user.id)?.permissions ?? [],
    [userLimits],
  )

  return (
    <Form className={classNames('w-full', className)} onSubmit={handleSubmit}>
      <Card>
        <Card.Content className="grid w-full grid-cols-1 gap-y-6">
          <WalletForms.Combobox.PolicyApprovers
            approvers={approvers}
            className="w-full"
            getUserPermissions={getUserPermissions}
            label={t`approval.level.form.thresholdApprovers.label`}
            name="selectedApprover"
            noItemsMessage={t`approval.level.form.thresholdApprovers.noItemsMessage`}
            onSelectedChange={handleSelectedChange}
            selected={watch('selected') ?? []}
          />

          <hr className="mt-2 h-px border-black-50" />

          <div className="flex flex-col gap-y-6">
            {(watch('selected') ?? []).length > 1 && (
              <div className="flex items-center justify-between">
                <div className="flex flex-col gap-1">
                  <div className="text-neutral type-body-base">
                    {t('approval.level.form.initiatorApprove.label')}
                  </div>
                </div>
                <Form.Checkbox
                  data-testid="checkbox-initiator-approver"
                  name="allowInitiatorToApproveTransfer"
                  onChange={handleInitiatorToggleChange}
                />
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <div className="text-neutral type-body-base">
                  {t('approval.level.form.minNeedConfirmed.label')}
                </div>
                <p
                  className={classNames('type-body-sm', {
                    hidden: !showMinNeedConfirmedError,
                    'text-red-500 block ': showMinNeedConfirmedError,
                  })}
                >
                  {t('common:form.v2.validation.required')}
                </p>
              </div>
              <Form.Dropdown
                className="w-24"
                data-testid="dropdown-num-approvers"
                items={minNumOfApproversDropdownChoices}
                name="minNeedConfirmed"
                placeholder="-"
                hideError
              />
            </div>
          </div>

          <Form.SubmitButton className="w-32" variant="primary">
            {t('common:done')}
          </Form.SubmitButton>
        </Card.Content>
      </Card>
    </Form>
  )
}

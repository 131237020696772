import { settingsSection } from '@services/sections/lib/settings'

import { MainNav } from '../../MainNav/MainNav'
import { UserMenu } from '../../UserMenu/UserMenu'

export const SectionNavBottom: React.FC = () => {
  return (
    <MainNav className="h-auto self-end" data-testid="main-nav-bottom">
      {/* Bottom Sections */}
      {[settingsSection].map((section, index) => (
        <MainNav.Item key={index} {...section} />
      ))}

      {/* User Menu */}
      <UserMenu />
    </MainNav>
  )
}

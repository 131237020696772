import { routes } from './routes'
import { SegmentModalScreenNames } from './types'

import type {
  ModalHistoryOptions,
  ModalRegistry,
} from '@circlefin/modal-router'

/**
 * Default Screen Config.
 */
const screenConfig = (
  options: ModalHistoryOptions = {},
): ModalHistoryOptions => ({
  ...options,
  size: 'screen',
  coolwhip: {
    variant: 'fade-top/green-blue-violet',
  },
})

/**
 * Modal Registry.
 * Register routes with default options.
 */
export const registry: ModalRegistry = {
  /**
   * MFA.
   */
  [routes.mfa.code]: {
    'data-segmentscreenname': SegmentModalScreenNames.MFA,
  },
  [routes.mfa.success]: {
    'data-segmentscreenname': SegmentModalScreenNames.MFASuccess,
  },
  [routes.mfa.error]: {
    'data-segmentscreenname': SegmentModalScreenNames.MFAFailure,
  },
  [routes.mfa.setupTotp]: {
    size: 'sm',
    'data-segmentscreenname': SegmentModalScreenNames.MFASetup,
  },
  [routes.mfa.setupOktaVerify]: {
    size: 'sm',
    'data-segmentscreenname': SegmentModalScreenNames.MFASetupOktaVerify,
  },
  [routes.mfa.updatePinCode]: {
    'data-segmentscreenname': SegmentModalScreenNames.MFAUpdatePinCode,
  },
  [routes.mfa.resetPinCode]: {
    'data-segmentscreenname': SegmentModalScreenNames.MFAResetPinCode,
  },
  /**
   * Feature Tour Options.
   */
  [routes.promotions.featureTour]: {
    size: 'lg',
  },

  /**
   * Export Report.
   */
  [routes.report.exportReport]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname': SegmentModalScreenNames.ExportReport,
  },

  /**
   * Platform Payment Refund.
   */
  [routes.platform.payment.refund]: {
    size: 'xs',
  },

  /**
   * Platform Payment Cancellation.
   */
  [routes.platform.payment.cancel]: {
    size: 'xs',
  },

  /**
   * Account: Add new address.
   */
  [routes.account.addressBook.addNewAddress.selectRecipient]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.AddressBookSelectRecipient,
  }),
  [routes.account.addressBook.addNewAddress.customer]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.AddressBookCustomer,
  }),
  [routes.account.addressBook.addNewAddress.nonCustomer]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.AddressBookNonCustomer,
  }),
  [routes.account.addressBook.addNewAddress.ownershipDisclaimer]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.OwnershipDisclaimer,
  }),
  [routes.account.addressBook.addNewAddress.error]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.AddNewAddressError,
  }),
  [routes.account.addressBook.approvalRequest]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ApprovalRequest,
  }),

  /**
   * Account: Remove address.
   */
  [routes.account.addressBook.removeAddress.confirmation]: {
    size: 'xs',
    'data-segmentscreenname':
      SegmentModalScreenNames.AddressBookRemoveAddressConfirmation,
  },

  /**
   * Account: Verify address.
   */
  [routes.account.addressBook.verifyAddress.ownerType]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.AddressBookVerifyAddressOwnerType,
  }),

  /**
   * Account: Identity challenge for address.
   */
  [routes.account.addressBook.verifyAddress.identityChallenge]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.IdentityChallenge,
    disableBack: true,
  }),

  /**
   * Account: Warning for third party travel rule transactions.
   */
  [routes.account.addressBook.verifyAddress.thirdPartyWarning]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ThirdPartyWarning,
    disableBack: true,
  }),

  /**
   * Account: Add new address with vaults enabled.
   */
  [routes.account.addressBook.vaultsEnabled.addNewAddress.selectRecipient]:
    screenConfig({
      'data-segmentscreenname':
        SegmentModalScreenNames.AddressBookSelectRecipient,
    }),
  [routes.account.addressBook.vaultsEnabled.addNewAddress.default.customer]:
    screenConfig({
      'data-segmentscreenname': SegmentModalScreenNames.AddressBookCustomer,
    }),
  [routes.account.addressBook.vaultsEnabled.addNewAddress.default.nonCustomer]:
    screenConfig({
      'data-segmentscreenname': SegmentModalScreenNames.AddressBookNonCustomer,
    }),
  [routes.account.addressBook.vaultsEnabled.addNewAddress.multiAsset.customer]:
    screenConfig({
      'data-segmentscreenname': SegmentModalScreenNames.AddressBookCustomer,
    }),
  [routes.account.addressBook.vaultsEnabled.addNewAddress.multiAsset
    .nonCustomer]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.AddressBookNonCustomer,
  }),

  /**
   * Account: Express.
   */
  [routes.express.addRoute.deposit]: screenConfig(),
  [routes.express.addRoute.direction]: screenConfig(),
  [routes.express.addRoute.review]: screenConfig(),
  [routes.express.addRoute.withdrawal]: screenConfig(),
  [routes.express.disableRoute]: {
    size: 'xs',
    disableClose: true,
  },

  /**
   * Account: Users.
   */
  [routes.account.users.password.error]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname': SegmentModalScreenNames.UserChangePasswordError,
  },
  [routes.account.users.password.success]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname': SegmentModalScreenNames.UserChangePasswordSuccess,
  },
  [routes.account.users.remove]: {
    size: 'xs',
    'data-segmentscreenname': SegmentModalScreenNames.UserRemove,
  },
  [routes.account.users.removeWithAdminApproval]: {
    size: 'xs',
    'data-segmentscreenname':
      SegmentModalScreenNames.UserRemoveWithAdminApproval,
  },
  [routes.account.users.resendInvite]: {
    size: 'xs',
    'data-segmentscreenname': SegmentModalScreenNames.UserResendInvite,
  },
  [routes.account.users.cancelApprovalRequest]: {
    size: 'xs',
    'data-segmentscreenname': SegmentModalScreenNames.CancelUserApprovalRequest,
  },
  [routes.account.users.vault.removeWarning.default]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname': SegmentModalScreenNames.VaultUserRemoveWarning,
  },
  [routes.account.users.vault.removeWarning.owner]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultUserRemoveOwnerWarning,
  },

  /**
   * Send-On-Chain - Full Screen.
   */
  [routes.blockchain.sendOnchain.recipient]: screenConfig(),
  [routes.blockchain.sendOnchain.amount]: screenConfig(),
  [routes.blockchain.sendOnchain.review]: screenConfig(),
  [routes.blockchain.sendOnchain.approvalRequired]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.TransferApprovalRequired,
    disableBack: true,
  }),

  /**
   * Transfer | Withdraw flow.
   */
  [routes.transfer.withdraw.chooseRecipient]: screenConfig(),
  [routes.transfer.withdraw.amount]: screenConfig(),
  [routes.transfer.withdraw.review]: screenConfig(),
  [routes.transfer.withdraw.approvalRequired]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.TransferApprovalRequired,
    disableBack: true,
  }),

  /**
   * Credit Card | Add payment method flow.
   */
  [routes.account.billing.paymentMethod.addCreditCard.details]: {
    'data-segmentscreenname': SegmentModalScreenNames.AddCardDetails,
  },

  /**
   * Account | Autopay flow.
   */
  [routes.account.billing.autoPay.enable]: {
    'data-segmentscreenname': SegmentModalScreenNames.EnableAutoPay,
  },

  /*
   * Account | Default payment method.
   */
  [routes.account.billing.paymentMethod.setDefault]: {
    'data-segmentscreenname': SegmentModalScreenNames.EnableAutoPay,
    size: 'xs',
  },

  /**
   * Account | Edit Recipient flow.
   */
  [routes.account.addressBook.editRecipient]: {
    'data-segmentscreenname': SegmentModalScreenNames.EditRecipient,
    size: 'xs',
  },

  /**
   * Account | Admin approvals flow.
   */
  [routes.account.security.adminApprovals.settings]: {
    'data-segmentscreenname': SegmentModalScreenNames.AdminApprovalsSettings,
    size: 'sm',
    disableClose: true,
  },
  [routes.account.security.adminApprovals.success]: {
    'data-segmentscreenname': SegmentModalScreenNames.AdminApprovalsSettings,
    size: 'sm',
    disableClose: true,
    disableBack: true,
  },

  /**
   * Account | Ip Allowlisting flow.
   */
  [routes.account.security.ipAllowlisting.toggleSetting]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.IpAllowlistingToggleSetting,
    size: 'xs',
    disableClose: true,
  },
  [routes.account.security.ipAllowlisting.ipAddress.remove]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.IpAllowlistingIpAddressRemove,
    size: 'xs',
    disableClose: true,
  },
  [routes.account.security.ipAllowlisting.ipAddress.create]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.IpAllowlistingIpAddressCreate,
  },
  [routes.account.security.ipAllowlisting.ipAddress.empty]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.IpAllowlistingIpAddressEmpty,
  },

  /**
   * Account | Delayed withdrawals flow.
   */
  [routes.delayedWithdrawals.toggleSetting]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.DelayedWithdrawalsToggleSetting,
    size: 'xs',
    disableClose: true,
  },

  /**
   * Account | Merchant Credit Card.
   */
  [routes.account.billing.payInvoice.paymentMethodSelection]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.PayInvoiceSelectPaymentMethod,
  },
  [routes.account.billing.payInvoice.confirmPayment]: {
    'data-segmentscreenname': SegmentModalScreenNames.PayInvoiceConfirmPayment,
  },

  /** Bank Account routes. */
  [routes.bankAccount.noLinked.wireTransfer]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.NoLinkedBankAccounts,
  }),
  [routes.bankAccount.noLinked.withdraw]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.NoLinkedBankAccounts,
  }),
  [routes.bankAccount.setNickname]: {
    'data-segmentscreenname': SegmentModalScreenNames.setBankAccountNickname,
    size: 'xs',
    disableClose: true,
  },

  /**
   * Bank Account: Link routes.
   */
  [routes.bankAccount.link.address]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),
  [routes.bankAccount.link.information]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),
  [routes.bankAccount.link.location]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),
  [routes.bankAccount.link.type]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),
  [routes.bankAccount.link.accountInformation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),
  [routes.bankAccount.link.accountAddress]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.linkBankAccount,
  }),

  /**
   * Transfer.
   */
  [routes.transfer.chooseTransferType]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ChooseTransferType,
  }),
  [routes.transfer.chooseDepositType]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ChooseDepositType,
  }),
  [routes.transfer.wire.selectBankAccount]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WireTransferSelectBankAccount,
  }),
  [routes.transfer.chooseWalletAndDepositType]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.ChooseWalletAndDepositType,
  }),
  [routes.transfer.chooseWalletAndTransferType]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.ChooseWalletAndTransferType,
  }),

  /**
   * Transfer - Wire.
   */
  [routes.transfer.wire.beneficiaryBankAccount]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WireTransferBeneficiaryBankAccount,
  }),
  [routes.transfer.wire.selectCurrency]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WireTransferSelectCurrency,
  }),
  [routes.transfer.wire.pleaseTransferFunds]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WireTransferDisclosures,
  }),
  [routes.transfer.wire.testTransferLanding]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.TestWireTransfer,
  }),

  /** Transfer - Wallet. */
  [routes.transfer.wallet.chooseBlockchain]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletTransferChooseBlockchain,
  }),
  [routes.transfer.wallet.transferInstructions]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletTransferBlockchainTransferInstruction,
  }),

  /** Transfer - FX. */
  [routes.transfer.fx.deposit.form]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.FxDeposit,
  }),
  [routes.transfer.fx.deposit.instructions]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.FxDepositInstructions,
  }),
  [routes.transfer.fx.withdraw.form]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.FxWithdraw,
  }),
  [routes.transfer.fx.withdraw.review]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.FxReview,
  }),

  /** Vault - Create Vault. */
  [routes.vault.createVault.initialize]: screenConfig({
    disableClose: true,
  }),
  [routes.vault.createVault.policyName]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultPolicyName,
    disableClose: true,
  }),
  [routes.vault.createVault.permissions]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultPermissions,
    disableClose: true,
  }),
  [routes.vault.createVault.policyLimits]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultLimitsPolicy,
    disableClose: true,
  }),
  [routes.vault.createVault.userLimits]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultLimitsUser,
    disableClose: true,
  }),
  [routes.vault.createVault.outgoingAddress]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.CreateVaultOutgoingAddresses,
    disableClose: true,
  }),
  [routes.vault.createVault.approval]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultApproval,
    disableClose: true,
  }),
  [routes.vault.createVault.walletConnect]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultWalletConnect,
    disableClose: true,
  }),
  [routes.vault.createVault.review]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultReview,
    disableClose: true,
  }),
  [routes.vault.createVault.finished]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultFinished,
    disableClose: true,
    disableBack: true,
  }),

  /** Vault - Vault Details. */
  [routes.vault.vaultDetails.learnMore]: {
    'data-segmentscreenname': SegmentModalScreenNames.VaultDetailsLearnMore,
    disableClose: true,
    size: 'xs',
  },
  [routes.vault.vaultDetails.submissionBlocked]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDetailsSubmissionBlocked,
    disableClose: true,
    size: 'xs',
  },
  [routes.vault.vaultDetails.discardDraftPolicy]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDetailsDiscardDraftPolicy,
    disableClose: true,
    size: 'xs',
  },
  [routes.vault.vaultDetails.cancelPendingApproval]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDetailsCancelPendingApproval,
    disableClose: true,
    size: 'xs',
  },

  /** Vault - Vault Wallet Details. */
  [routes.vault.vaultWalletDetails.unarchive]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultWalletDetailsUnarchive,
    disableClose: true,
    size: 'sm',
  },
  [routes.vault.vaultWalletDetails.archive]: {
    'data-segmentscreenname': SegmentModalScreenNames.VaultWalletDetailsArchive,
    disableClose: true,
    size: 'sm',
  },
  [routes.vault.vaultWalletDetails.discardDraft]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultWalletDetailsDiscardDraft,
    disableClose: true,
    size: 'sm',
  },

  /** Vault - Create Vault Wallet. */
  [routes.vault.createVaultWallet.selectAsset]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultWalletAsset,
  }),
  [routes.vault.createVaultWallet.selectBlockchain]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.CreateVaultWalletBlockchain,
  }),
  [routes.vault.createVaultWallet.confirm]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultWalletConfirm,
  }),
  [routes.vault.createVaultWallet.success]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.CreateVaultWalletSuccess,
  }),

  /** Vault - Pair Device. */
  [routes.vault.pairDevice.initialize]: screenConfig({
    disableClose: true,
  }),
  [routes.vault.pairDevice.download]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairDeviceDownload,
  }),
  [routes.vault.pairDevice.pair]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairDevicePair,
  }),
  [routes.vault.pairDevice.pin]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairDevicePin,
    disableBack: true,
  }),
  [routes.vault.pairDevice.success]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairDeviceSuccess,
    disableBack: true,
  }),

  /** Vault - Pair New Device. */
  [routes.vault.pairNewDevice.confirmation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairNewDeviceConfirmation,
  }),
  [routes.vault.pairNewDevice.pair]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairNewDevicePair,
  }),
  [routes.vault.pairNewDevice.download]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.PairNewDeviceDownload,
  }),

  /** Vault - Change Pin. */
  [routes.vault.changePin.confirmation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ChangePinConfirmation,
  }),
  [routes.vault.changePin.success]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ChangePinSuccess,
  }),

  /** Vault - Reset Pin. */
  [routes.vault.resetPin.confirmation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ResetPinConfirmation,
  }),
  [routes.vault.resetPin.uploadBackup]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ResetPinUploadBackup,
  }),
  [routes.vault.resetPin.enterPassphrase]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ResetPinEnterPassphrase,
  }),
  [routes.vault.resetPin.pin]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ResetPinPin,
  }),
  [routes.vault.resetPin.success]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ResetPinSuccess,
  }),

  /** Vault - Deposit - Blockchain. */
  [routes.vault.deposit.chooseDepositType]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.VaultDepositChooseType,
  }),
  [routes.vault.deposit.blockchain.depositDetails]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDepositBlockchainDetails,
  }),
  [routes.vault.deposit.blockchain.transferInstructions]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDepositBlockchainInstruction,
  }),

  /** Vault - Transfer - Choose Transfer Type. */
  [routes.vault.transfer.chooseTransferType]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.VaultTransferChooseType,
  }),

  /** Transfer - Transfer - Send On-Chain. */
  [routes.vault.transfer.sendOnChain.recipientExternal]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultTransferSendOnChainExternalRecipient,
  }),
  [routes.vault.transfer.sendOnChain.recipientInternal]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultTransferSendOnChainInternalRecipient,
  }),
  [routes.vault.transfer.sendOnChain.amount]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultTransferSendOnChainAmount,
  }),
  [routes.vault.transfer.sendOnChain.review]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultTransferSendOnChainReview,
  }),
  [routes.vault.transfer.sendOnChain.finished]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultTransferSendOnChainFinished,
    disableBack: true,
  }),

  /** Vault - Wallet Backup. */
  [routes.vault.walletBackup.confirmation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletBackupConfirmation,
  }),
  [routes.vault.walletBackup.createPassphrase]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletBackupCreatePassphrase,
  }),
  [routes.vault.walletBackup.pin]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletBackupPin,
  }),
  [routes.vault.walletBackup.download]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletBackupDownload,
    disableClose: true,
  }),
  [routes.vault.walletBackup.success]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletBackupSuccess,
  }),
  [routes.vault.walletBackup.error]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletBackupError,
  }),

  /** Vault - WalletConnect. */
  [routes.vault.walletConnect.overview]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletConnectOverview,
  }),
  [routes.vault.walletConnect.confirmation]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.WalletConnectConfirmation,
  }),

  /** Vault - Choose Vault Type. */
  [routes.vault.chooseVaultType]: screenConfig({
    'data-segmentscreenname': SegmentModalScreenNames.ChooseVaultType,
  }),

  [routes.vault.confirmWalletActivationFee]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.ConfirmWalletActivationFee,
  }),
  [routes.vault.downloadTransactionsReport]: {
    size: 'xs',
    disableClose: true,
    'data-segmentscreenname':
      SegmentModalScreenNames.VaultDownloadTransactionsReport,
  },

  /** Developer. */
  [routes.developer.subscriptions.removeSubscription]: {
    size: 'xs',
    disableClose: true,
  },
  [routes.developer.subscriptions.addNewSubscription]: screenConfig({
    size: 'screen',
    'data-segmentscreenname':
      SegmentModalScreenNames.SubscriptionAddConfirmation,
  }),
  [routes.developer.subscriptions.addNewSubscriptionSuccess]: screenConfig({
    size: 'screen',
    disableBack: true,
  }),
  [routes.developer.apiKeys.revoke]: {
    size: 'xs',
  },
  [routes.developer.apiKeys.success]: {
    size: 'xs',
    disableClose: true,
  },

  /** Wallet Approval Policy. */
  [routes.vault.createVault.initialize]: screenConfig({
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.permissions]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyPermissions,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.policyLimits]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyLimitsPolicy,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.userLimits]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyLimitsUser,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.approval]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyApproval,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.settings]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicySettings,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.review]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyReview,
    disableClose: true,
  }),
  [routes.walletApprovalPolicy.createPolicy.finished]: screenConfig({
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyFinished,
    disableClose: true,
    disableBack: true,
  }),

  /**
   * Wallet Approval Policy | Discard draft policy.
   */
  [routes.walletApprovalPolicy.discardDraftPolicy]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyDiscardDraft,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Cancel approval request.
   */
  [routes.walletApprovalPolicy.cancelApprovalRequest]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyCancelApprovalRequest,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Cancel pending transaction.
   */
  [routes.walletApprovalPolicy.cancelPendingTransaction]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyCancelPendingTransaction,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Approve approval request.
   */
  [routes.walletApprovalPolicy.approveApprovalRequest]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyApproveApprovalRequest,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Cancel approval request.
   */
  [routes.walletApprovalPolicy.rejectApprovalRequest]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicyRejectApprovalRequest,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Submission blocked.
   */
  [routes.walletApprovalPolicy.submissionBlocked]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalPolicySubmissionBlocked,
    disableClose: true,
    size: 'xs',
  },

  /**
   * Wallet Approval Policy | Submitted policy progress.
   */
  [routes.walletApprovalPolicy.submittedPolicyProgress]: {
    'data-segmentscreenname':
      SegmentModalScreenNames.WalletApprovalSubmittedPolicyProgress,
    size: 'xs',
  },

  /* Onboarding */
  [routes.onboarding.getStarted.otherInterest]: {
    'data-segmentscreenname': SegmentModalScreenNames.OnboardingOtherInterest,
    size: 'xs',
  },
  [routes.onboarding.periodicReview.takeover]: {
    'data-segmentscreenname': SegmentModalScreenNames.PeriodicReviewTakeover,
    size: 'md',
    disableBack: true,
  },
  [routes.onboarding.pinCode.takeover]: {
    'data-segmentscreenname': SegmentModalScreenNames.PinCodeTakeover,
    size: 'md',
    disableBack: true,
    disableClose: true,
    disableCloseOutside: true,
  },
}

import { useMemo } from 'react'

import { Icon, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { Currency, WalletApprovalPolicyRole } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const UserLimits: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')
  const formatMoney = useMoney({ locale: 'en-US' }).money

  const {
    condensed,
    currency = Currency.USD,
    userLimits = [],
  } = usePolicySummaryContext()

  const operators = useMemo(
    () =>
      userLimits.filter((userLimit) =>
        userLimit.permissions.includes(WalletApprovalPolicyRole.OPERATOR),
      ),
    [userLimits],
  )

  return (
    <>
      <Label className="h-full">
        {t('policy.summary.labels.userDailyLimits')}
      </Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="user-limits"
      >
        <List>
          {operators.map((userPolicy, index) => (
            <List.Item key={userPolicy.user.id}>
              <List.Item.Title
                showEdit={index === 0}
                stepName={WalletApprovalPolicyStep.USER_LIMITS}
              >
                {userPolicy.user.name}
              </List.Item.Title>
              <List.Item.Subtitle>
                <div className="flex items-center gap-x-2">
                  {userPolicy.maxAmount && (
                    <>
                      <span>
                        {formatMoney({
                          number: userPolicy.maxAmount,
                          variant: currency,
                        })}
                      </span>
                      <Icon
                        className="inline-block"
                        name="EllipseSolid"
                        size={4}
                      />
                    </>
                  )}

                  {userPolicy.maxTransactions && (
                    <>
                      {t('policy.summary.policyDailyLimits.transaction', {
                        count: Number(userPolicy.maxTransactions),
                      })}
                    </>
                  )}
                </div>
              </List.Item.Subtitle>
            </List.Item>
          ))}
        </List>
      </Description>
    </>
  )
}

import { useCallback } from 'react'

import { Button, Card, DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  PaymentsDocument,
  useActiveWalletApprovalPolicyQuery,
  useWalletApprovalCreateVaultWalletTransferMutation,
  WalletApprovalPendingTransactionsDocument,
} from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../../SendOnChain.Context'
import { Description } from '../Description'
import { Label } from '../Label'

import type {
  Currency,
  WalletApprovalCreateVaultWalletTransferInput,
} from '@shared/graphql'

export interface CircleSourceReviewProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const CircleSourceReview: React.FC<CircleSourceReviewProps> = ({
  children,
}) => {
  const { t } = useTranslation('modals.vault')
  const { money } = useMoney({ locale: 'en-US' })
  const { idempotencyKey } = useIdempotencyKey()
  const [
    { amount },
    ,
    {
      circleSourceWallet,
      directInternalDestinationWallet,
      directInternalDestinationMappedWalletRef,
      derivedDestination,
    },
  ] = useSendOnChain()
  const modal = useModal()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)
  const walletPolicyQuery = useActiveWalletApprovalPolicyQuery({
    variables: { currency: circleSourceWallet?.currency as Currency },
    skip: circleSourceWallet?.currency == null || !isAuthorized,
    fetchPolicy: 'cache-and-network',
  })
  const hasWalletPolicyWithApproverSteps =
    walletPolicyQuery.data?.activeWalletApprovalPolicy?.approvalWorkflow != null

  const [createTransfer, { loading }] =
    useWalletApprovalCreateVaultWalletTransferMutation({
      refetchQueries: [
        /**
         * Refetch Payments Activity Table.
         * This refetch only works when opening the flow from the homepage.
         */
        PaymentsDocument,
        WalletApprovalPendingTransactionsDocument,
      ],
      onCompleted: () => {
        // If there is an active policy, navigate to the finished screen (this screens tells them their transfer needs approval)
        if (hasWalletPolicyWithApproverSteps && circleSourceWallet) {
          modal.router.push({
            pathname: routes.vault.transfer.sendOnChain.finished,
            query: {
              totalSteps: 6,
              isToVaultWalletTransfer: true,
              actionLink: {
                pathname: circleWalletDetailSection.route,
                query: { currency: circleSourceWallet.currency },
              },
            },
          })

          return
        }

        mfaSuccess({
          message: t`common:success`,
        })
      },
      onError: (error) =>
        mfaError({
          error,
          message: t`common:generalError`,
        }),
    })

  /**
   * Invoke transfer mutation after successful MFA.
   */
  const handleTransferClick = useCallback(() => {
    if (
      !directInternalDestinationWallet ||
      !directInternalDestinationMappedWalletRef ||
      amount === undefined ||
      !circleSourceWallet
    ) {
      return
    }

    const input: WalletApprovalCreateVaultWalletTransferInput = {
      idempotencyKey,
      wallet: {
        id: derivedDestination?.id ?? '',
        name: directInternalDestinationWallet.name,
        address: directInternalDestinationMappedWalletRef.walletAddress,
      },
      vault: {
        id: directInternalDestinationWallet.vaultMetadata.id,
        name: directInternalDestinationWallet.vaultMetadata.name,
      },
      amount: {
        amount: String(amount),
        currency: circleSourceWallet.currency,
      },
    }

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createTransfer({
          variables: {
            input,
          },
          context: context(code),
        })
      },
      onAbort: () => {
        modal.close({ context: 'onDismiss' })
      },
    })
  }, [
    directInternalDestinationWallet,
    directInternalDestinationMappedWalletRef,
    amount,
    circleSourceWallet,
    withMfa,
    createTransfer,
    idempotencyKey,
    derivedDestination?.id,
    context,
    modal,
  ])

  return (
    <GraphQLErrorBoundary
      error={walletPolicyQuery.error}
      retry={walletPolicyQuery.refetch}
    >
      <Card className="mt-10 w-192 px-4 py-2" variant="secondary/base">
        <DescriptionList
          className="bg-transparent"
          data-testid="description-list"
        >
          {children}
          <Label>{t`transfer.sendOnChain.reviewTransferDetails.amount`}</Label>
          <Description>
            <span data-testid="amount">
              <span className="font-circular-bold">
                {amount !== undefined &&
                  circleSourceWallet &&
                  money({
                    number: String(amount),
                    variant: circleSourceWallet?.currency,
                  })}
              </span>
            </span>
          </Description>
        </DescriptionList>
      </Card>
      <Center className="mt-10 text-center" variant="horizontal">
        <Button
          className="h-10 w-60"
          data-testid="transfer-button"
          disabled={walletPolicyQuery.loading}
          loading={loading}
          onClick={handleTransferClick}
          variant="primary"
        >
          {t`transfer.sendOnChain.reviewTransferDetails.transfer`}
        </Button>
      </Center>
    </GraphQLErrorBoundary>
  )
}

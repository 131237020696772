import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../../Create/Create'
import { useLinkBankAccount } from '../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().accountNumber({ variant: 'us' }).required(),
})

export type XpayFormValues = y.InferType<typeof schema>

export interface XpayFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: XpayFormValues) => void
}

export const XpayForm: React.FC<XpayFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [createBankAccount] = useLinkCreate()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<XpayFormValues>({
    schema,
    mode: 'onBlur',
    values: {
      nickname: information.xpay?.nickname ?? '',
      accountNumber: information.xpay?.accountNumber ?? '',
    },
  })

  const onFormSubmit = useCallback(
    (xpay: XpayFormValues) => {
      // Set data to context
      setInformation({ xpay })

      // Pass data to submit callback (for testing)
      onSubmit?.(xpay)

      // Create Bank Account
      createBankAccount()
    },
    [createBankAccount, onSubmit, setInformation],
  )

  return (
    <div className="gap-3">
      <Form className="grid w-full gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-nickname"
          label={t('modals.bankAccount:link.accountInformation.nickname')}
          name="nickname"
        />

        <Form.Input
          className="w-full"
          data-testid="input-accountNumber"
          label={t('modals.bankAccount:link.accountInformation.accountNumber')}
          name="accountNumber"
        />

        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
        <div className="mt-6 flex items-center gap-3">
          <Icon name="SwitchHorizontalOutline" />
          <p className="text-neutral-subtle type-body-sm">
            {t(
              'modals.bankAccount:link.accountInformation.xpayPaymentTypeInfo',
            )}
          </p>
        </div>
      </Form>
    </div>
  )
}

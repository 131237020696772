import { useCallback } from 'react'

import { usePermission } from '@circlefin/permissions'
import { PROTECTED, ROLR_REDEMPTION_ONLY_ACCOUNT } from '@services/permissions'
import {
  useSettingsFeaturesQuery,
  useSetSettingsFeatureMutation,
  SettingsFeaturesType,
} from '@shared/graphql'

import { CustodyFeatureGuideContent } from './CustodyFeatureGuideContent/CustodyFeatureGuideContent'

export const CustodyFeatureGuide: React.FC = () => {
  const [{ Authorized }, { isAuthorized }] = usePermission(PROTECTED)
  const [{ Unauthorized }, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )
  const { data, loading } = useSettingsFeaturesQuery({
    skip: !isAuthorized || isRolr,
  })
  const [resolveFeatureSettings] = useSetSettingsFeatureMutation()

  const tourCompleted = Boolean(data?.settings.features?.custodyTour)

  const handleFinishTour = useCallback(() => {
    void resolveFeatureSettings({
      variables: {
        type: SettingsFeaturesType.custodyTour,
      },
    })
  }, [resolveFeatureSettings])

  return (
    <Unauthorized>
      <Authorized>
        <CustodyFeatureGuideContent
          isVisible={!loading && !tourCompleted}
          onFinishTour={handleFinishTour}
        />
      </Authorized>
    </Unauthorized>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Permission to cancel a pending approval transaction.
 * Both the admin and the agent should be able to cancel a transaction as long as they are the initiator.
 */
export const CIRCLE_WALLETS_CANCEL_TRANSACTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CUSTODY_POLICY_ROLE],
  includeAny: [Role.ACCOUNT_WEB_ROLE, Role.ACCOUNT_WEB_READ_ROLE],
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Stablecoin Express Burn Permissions.
 */
export const STABLECOIN_EXPRESS_BURN: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.STABLECOIN_EXPRESS_ROLE, Role.STABLECOIN_EXPRESS_BURN_ROLE],
}

import { useMemo } from 'react'

import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { Assets } from '@features/blockchain/components'
import { TypeGuards } from '@services/type-guards'
import { DefaultCell } from '@shared/components/tables'
import useTranslation from 'next-translate/useTranslation'

import type { InternalAddress } from '@shared/graphql'

const columnHelper = createColumnHelper<InternalAddress>()

export const useTableColumns = () => {
  const { t } = useTranslation('vaults')

  return useMemo(() => {
    /**
     * Nickname Column.
     */
    const nickname = columnHelper.accessor(
      (row) =>
        TypeGuards.InternalAddress.isDirect(row)
          ? row.name
          : t<string>(`createVault.outgoingAddressesTable.mainWallet`),
      {
        id: 'nickname',
        header: t<string>(
          'createVault.outgoingAddressesTable.headers.nickname',
        ),
        meta: {
          className: 'w-24',
        },
        cell: ({ getValue }) => (
          <DefaultCell className="truncate">{getValue()}</DefaultCell>
        ),
      },
    )

    /**
     * Blockchain Column.
     */
    const blockchain = columnHelper.accessor('blockchain', {
      header: t<string>(
        'createVault.outgoingAddressesTable.headers.blockchain',
      ),
      meta: {
        className: 'w-16',
      },
      cell: ({ getValue }) => (
        <DefaultCell>{t(`payments:chainNames.${getValue()}`)}</DefaultCell>
      ),
    })

    /**
     * Custody Type Column.
     */
    const owner = columnHelper.accessor('type', {
      header: t<string>('createVault.outgoingAddressesTable.headers.type'),
      meta: {
        className: 'w-20',
      },
      cell: ({ getValue }) => (
        <DefaultCell className="whitespace-normal break-all">
          {t(`createVault.outgoingAddressesTable.custodyType.${getValue()}`)}
        </DefaultCell>
      ),
    })

    /**
     * Asset.
     */
    const asset = columnHelper.accessor(
      (row) =>
        TypeGuards.InternalAddress.isDirect(row)
          ? row.asset.symbol
          : row.currency,
      {
        id: 'asset',
        header: t<string>('common:asset'),
        meta: {
          className: 'w-20',
        },
        cell: ({ row: { original } }) => (
          <Assets
            assets={
              TypeGuards.InternalAddress.isDirect(original)
                ? [original.asset]
                : [
                    {
                      symbol: original.currency,
                      icon: original.icon,
                    },
                  ]
            }
            chain={original.blockchain}
          />
        ),
      },
    )

    return [nickname, blockchain, owner, asset]
  }, [t])
}

import { useMemo } from 'react'

import getConfig from 'next/config'
import { useSession } from 'next-auth/react'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export interface UseQRCodeTextProps {
  /**
   * QR code secret.
   */
  secret?: string
}

/**
 * Hook to generate the QR Code text value needed for Okta MFA enrollment;
 * returns `otpUrl` string according to the Google OTPauth spec.
 * Https://code.google.com/archive/p/otpauth/.
 */
export const useQRCodeText = ({ secret }: UseQRCodeTextProps) => {
  const { data: session } = useSession()
  const oktaUrl = publicRuntimeConfig.SIGN_IN_URL

  const otpUrl = useMemo(() => {
    if (!session || !oktaUrl || !secret) {
      return
    }

    const host = new URL(oktaUrl).host

    return `otpauth://totp/${host}:${session?.user.email}?secret=${secret}&issuer=${host}`
  }, [oktaUrl, secret, session])

  return {
    otpUrl,
  }
}

/**
 * Onboarding Routes.
 */
export const onboarding = {
  getStarted: {
    otherInterest: '/onboarding/other-interest',
  },
  periodicReview: {
    takeover: '/periodic-review/takeover',
  },
  pinCode: {
    takeover: '/pin-code/takeover',
  },
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * XLM blockchain EURC withdrawal access permission.
 */
export const XLM_BLOCKCHAIN_WITHDRAWAL_EURC: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [
    SynthRole.SYNTH_XLM_BLOCKCHAIN_EURC_ROLE,
    SynthRole.SYNTH_XLM_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE,
  ],
}

import classNames from 'classnames'

import type { AppLayoutType } from '../AppLayout/AppLayout'

/**
 * Simple properties for the BaseLayout wrapper used by other layouts.
 */
interface BaseLayoutProps {
  /**
   * Additional classes to apply the to the layout wrapper.
   */
  className?: string
}

export const BaseLayout: AppLayoutType<BaseLayoutProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'grid w-full min-h-screen font-circular',
        className,
      )}
    >
      {children}
    </div>
  )
}

import NextImage from 'next/image'

import type { ImageProps as NextImageProps } from 'next/image'

export interface ImageProps {
  /**
   * Source for primary image.
   */
  src: NextImageProps['src']
  /**
   * Alternative image description for screen readers.
   */
  alt: string
  /**
   * Size of primary image.
   */
  size: number
  /**
   * Custom style.
   */
  className?: string
}

export const Image: React.FC<ImageProps> = ({ src, alt, size, className }) => {
  return (
    <div className={className}>
      <NextImage
        alt={alt}
        height={size}
        src={src}
        style={{ objectFit: 'contain' }}
        width={size}
      />
    </div>
  )
}

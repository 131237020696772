import { useCallback, useMemo, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormMultiSelectCombobox } from '@circlefin/form/Form.MultiSelectCombobox'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useCountries } from '../../../hooks/countries'

import type {
  MultiSelectComboboxItem,
  MultiSelectComboboxProps,
  MultiSelectComboboxOptionsLocale,
  MultiSelectComboboxOptionsProps,
} from '@circlefin/components/lib/MultiSelectCombobox'
import type { CountriesInput, CountryCode } from '@shared/graphql'

export interface CountryProps
  extends Pick<
      MultiSelectComboboxProps<CountryCode>,
      'dropDirection' | 'label' | 'placeholder' | 'disabled' | 'className'
    >,
    Pick<
      MultiSelectComboboxOptionsProps<CountryCode>,
      'maxMenuItems' | 'disabled' | 'className'
    > {
  /**
   * Loading state.
   */
  loading?: boolean
  /**
   * Custom name?
   */
  name?: string
  /**
   * Country query filters.
   */
  filters?: CountriesInput
}

export const countryListSchema = y
  .array()
  .of(y.mixed<MultiSelectComboboxItem<CountryCode>>())

const MultiSelectCombobox = createFormMultiSelectCombobox()

export const Country: React.FC<CountryProps> = ({
  className,
  disabled = false,
  dropDirection = 'down',
  label,
  loading: loadingProp = false,
  maxMenuItems = 6,
  placeholder,
  name = 'countries',
  filters,
  ...props
}) => {
  const { t } = useTranslation('forms')
  const { loading, countries } = useCountries({ ...filters })
  const [filtered, setFiltered] = useState<
    Array<MultiSelectComboboxItem<CountryCode>>
  >([])
  const [searchTerm, setSearchTerm] = useState('')

  const countryListToDropdown = useMemo(
    () => countries.toDropdown(),
    [countries],
  )

  const handleInputChange = useCallback(
    (search: string) => {
      setSearchTerm(search)
      setFiltered(
        matchSorter(countryListToDropdown, search, {
          keys: ['label'],
        }),
      )
    },
    [countryListToDropdown, setSearchTerm, setFiltered],
  )

  // Combobox Locale.
  const locale: MultiSelectComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue: string) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
    }),
    [t],
  )

  return (
    <SkeletonBox className="mt-6 h-10" loading={loading || loadingProp}>
      <MultiSelectCombobox
        className={className}
        disabled={disabled}
        dropDirection={dropDirection}
        items={searchTerm !== '' ? filtered : countryListToDropdown}
        label={label ?? t`multiSelectCombobox.locale.country.label`}
        locale={locale}
        maxMenuItems={maxMenuItems}
        name={name}
        onInputChange={handleInputChange}
        placeholder={placeholder}
        searchTerm={searchTerm}
        {...props}
      />
    </SkeletonBox>
  )
}

import type React from 'react'
import { useCallback, useMemo, useState } from 'react'

import {
  CodeInput,
  InlineNotification,
  LottieIcon,
  Modal,
} from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { NoFactorError } from '@features/mfa/containers'
import { LoadingSpinner, TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  FactorStatus,
  FactorType,
  ScaFactorInfoDocument,
  useEnrollPinCodeMutation,
  useFactorQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface TakeoverProps {
  /**
   * Callback after successfully setting up PIN code.
   */
  onActivation?: () => void | Promise<void>
}

export const Takeover: React.FC<TakeoverProps> = ({ onActivation }) => {
  const { t } = useTranslation('modals.onboarding')

  const [pinCode, setPinCode] = useState<string | undefined>()
  const [confirmCode, setConfirmCode] = useState<string | undefined>()
  const modal = useModal()

  const [enrollPinCode, enrollPinCodeResult] = useEnrollPinCodeMutation({
    // Error handled by GraphqlErrorBoundary
    onError: () => null,
  })

  const factorResult = useFactorQuery({
    variables: { factorType: FactorType.PUSH },
  })

  const mismatchedCodes = useMemo(() => {
    if (!pinCode || !confirmCode) return false

    return pinCode !== confirmCode
  }, [confirmCode, pinCode])

  const handleEnrollPinCode = useCallback(
    (code: string) => {
      void enrollPinCode({
        variables: { code },
        refetchQueries: [
          {
            query: ScaFactorInfoDocument,
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          modal.close()
          void onActivation?.()
        },
      })
    },
    [enrollPinCode, modal, onActivation],
  )

  const handlePinCodeEntered = useCallback(
    (code: string) => {
      setPinCode(code)
      if (code === confirmCode) {
        handleEnrollPinCode(code)
      }
    },
    [confirmCode, handleEnrollPinCode],
  )

  const handleConfirmCodeEntered = useCallback(
    (code: string) => {
      setConfirmCode(code)
      if (code === pinCode) {
        handleEnrollPinCode(code)
      }
    },
    [pinCode, handleEnrollPinCode],
  )

  const handleReset = useCallback(() => {
    enrollPinCodeResult.reset()
  }, [enrollPinCodeResult])

  const body = useMemo(() => {
    if (enrollPinCodeResult.loading || factorResult.loading) {
      return <LoadingSpinner />
    }

    // If Okta Verify is not enrolled yet, require them to log out to enroll
    if (factorResult.data?.factor?.status !== FactorStatus.ACTIVE) {
      return <NoFactorError variant="component" />
    }

    if (enrollPinCodeResult.data) {
      return <LottieIcon name="Check" />
    }

    return (
      <>
        <div className="flex flex-col items-start type-body-sm-bold">
          {/* TODO [LEX-1291]: Add label to the CodeInput component in components-web */}
          <p>{t('pinCode.createPin')}</p>
          <CodeInput
            allowedCharacters="numeric"
            length={4}
            onComplete={handlePinCodeEntered}
            isPassword
          />
        </div>
        <div className="flex flex-col items-start pt-8 type-body-sm-bold">
          <p>{t('pinCode.confirmPin')}</p>
          <CodeInput
            allowedCharacters="numeric"
            disabled={!pinCode}
            length={4}
            onComplete={handleConfirmCodeEntered}
            isPassword
          />
        </div>
        <InlineNotification
          className="pt-4"
          intent="warning"
          visible={mismatchedCodes}
        >
          {t('pinCode.mismatchedCodes')}
        </InlineNotification>
      </>
    )
  }, [
    enrollPinCodeResult.loading,
    enrollPinCodeResult.data,
    factorResult.loading,
    factorResult.data?.factor?.status,
    t,
    handlePinCodeEntered,
    pinCode,
    handleConfirmCodeEntered,
    mismatchedCodes,
  ])

  return (
    <>
      <Modal.Header
        iconName="LockClosedSolid"
        title={t('pinCode.title')}
        variant="info"
      >
        {t('pinCode.subtitle')}
      </Modal.Header>
      <Modal.Body className="flex flex-col items-center pt-2">
        <GraphQLErrorBoundary
          error={factorResult.error}
          retry={factorResult.refetch}
        >
          <GraphQLErrorBoundary
            error={enrollPinCodeResult.error}
            retry={handleReset}
          >
            {body}
          </GraphQLErrorBoundary>
        </GraphQLErrorBoundary>
      </Modal.Body>
      <Modal.Footer className="flex justify-center">
        <TransWithLink
          i18nKey="modals.onboarding:pinCode.contactCustomerSupport"
          variant="support"
        />
      </Modal.Footer>
    </>
  )
}

import { useCallback } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useCountries } from '@features/locales/hooks/countries'
import { useSegment, SegmentEvents } from '@services/segment'
import { CountryCode } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CountryWarningMessage } from '../../../components'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const businessCountrySchema = y
  .mixed<CountryCode>()
  .oneOf(Object.values(CountryCode))
  .required()

const schema = y.object({
  /**
   * Business country code.
   */
  businessCountry: businessCountrySchema,
})

type BusinessCountryFormValues = y.InferType<typeof schema>

export interface BusinessCountryProps {
  /**
   * Country code.
   */
  country?: CountryCode
  /**
   * Force loading state.
   */
  loading?: boolean
}

export const BusinessCountry: React.FC<BusinessCountryProps> = ({
  country,
  loading,
}) => {
  const { t } = useTranslation('onboard/account-setup')
  const { track } = useSegment()
  const [Form] = useForm<BusinessCountryFormValues>({
    schema,
  })
  const countries = useCountries({
    blockchain: {
      registeredIn: true,
    },
    onboarding: {
      sanctioned: false,
    },
  })

  const handleChange = useCallback(
    (value: SelectListItem<BusinessCountryFormValues['businessCountry']>) => {
      track(SegmentEvents.DropdownSelected, {
        props: {
          field_name: 'Business country',
          value,
        },
      })
    },
    [track],
  )

  return (
    <SkeletonBox
      className="mt-6 h-10 w-full"
      loading={loading || countries.loading}
    >
      <Form.Dropdown
        data-testid="dropdown-business-country"
        items={countries.countries.toDropdown()}
        label={t`confirm.form.businessCountry.label`}
        message={
          <CountryWarningMessage
            countriesQueryData={countries.data}
            countryCode={country}
          />
        }
        name="businessCountry"
        onChange={handleChange}
      />
    </SkeletonBox>
  )
}

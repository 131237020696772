import { useContext } from 'react'

import { AccountSetupContext } from './context'

export const useAccountSetup = () => {
  const context = useContext(AccountSetupContext)

  if (context === null) {
    throw new Error(
      'useAccountSetup must be used within a AccountSetupProvider',
    )
  }

  return context
}

import { useCallback } from 'react'

import { FixedBanner, Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { Center } from '@shared/components/layout'
import {
  StablecoinExpressRoutesDocument,
  StablecoinExpressType,
  useCreateStablecoinExpressDepositRouteMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../../../AddRoute.Context'
import { BankAccountDetails, Blockchain, Heading } from '../../Details'

import type { ApolloError } from '@apollo/client'
import type { BankAccount, BlockchainRecipientAddress } from '@shared/graphql'

export interface DepositDetailsProps {
  /**
   * Bank Account Source.
   */
  source: BankAccount
  /**
   * Blockchain Destination.
   */
  destination: BlockchainRecipientAddress
}

export const DepositVariant: React.FC<DepositDetailsProps> = ({
  source,
  destination,
}) => {
  const { t } = useTranslation('express/modal')
  const modal = useModal()
  const [data] = useAddRoute()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createDeposit] = useCreateStablecoinExpressDepositRouteMutation({
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error: ApolloError) => {
      mfaError({ error })
    },
    refetchQueries: [StablecoinExpressRoutesDocument],
  })

  const onConfirm = useCallback(() => {
    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        if (
          data.direction === StablecoinExpressType.DEPOSIT &&
          data.source &&
          data.destination &&
          data.currency != null
        ) {
          void createDeposit({
            variables: {
              input: {
                bankId: data.source.id,
                bankType: data.source.type,
                chain: data.destination.chain,
                address: data.destination.address,
                addressTag: data.destination.addressTag,
                currency: data.currency,
              },
            },
            context: context(code),
          })
        } else {
          mfaError({ message: t`common:generalError` })
        }
      },
      onAbort: () => {
        modal.close()
      },
    })
  }, [context, createDeposit, data, mfaError, modal, t, withMfa])

  return (
    <>
      <Heading>{t`addRoute.review.from`}</Heading>
      <BankAccountDetails bankAccount={source} />

      <FixedBanner
        className="mt-6"
        data-testid="alert-container"
        status="info"
        visible
      >
        <FixedBanner.Title>{t`addRoute.review.disclaimer.title`}</FixedBanner.Title>
        <FixedBanner.Description>{t`addRoute.review.disclaimer.description`}</FixedBanner.Description>
      </FixedBanner>

      <Heading>{t`addRoute.review.to`}</Heading>
      <Blockchain.Deposit {...destination} />

      <Center className="pt-12" variant="horizontal">
        <Button className="w-60" onClick={onConfirm} variant="primary">
          {t`common:confirm`}
        </Button>
      </Center>
    </>
  )
}

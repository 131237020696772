import { useCallback } from 'react'

import { hubspot } from '@services/sections/lib/api'
import getConfig from 'next/config'

import type { Hubspot } from '../../lib/hubspot.types'
import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

/**
 * UseHubspot hook.
 */
export const useHubspot = () => {
  /**
   * Submit request to Hubspot-proxy / Next api endpoint.
   */
  const submitForm = useCallback(
    ({ fields, pageName, formGuid }: Hubspot.Client.Form.Body) => {
      const body = { fields, pageName, formGuid }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }

      void fetch(hubspot.route, options)
    },
    [],
  )

  /**
   * Handle onboarding form submission.
   */
  const submitOnboardingForm = useCallback(
    ({ firstName, lastName, email }: Hubspot.Client.Form.Onboarding) => {
      const formGuid = publicRuntimeConfig?.HUBSPOT_ONBOARDING_FORM_GUID

      const formFields: Hubspot.Server.Form.Field[] = [
        {
          objectTypeId: '0-1',
          name: 'firstName',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastName',
          value: lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ]

      void submitForm({
        fields: formFields,
        pageName: 'Onboarding Landing Page',
        formGuid,
      })
    },
    [submitForm],
  )

  /**
   * Handle setting of authorized rep form submission.
   * This occurs when initial sign up confirms role, or when auth rep invited.
   */
  const submitAuthorizedRepForm = useCallback(
    ({
      email,
      firstName,
      lastName,
      businessName,
      businessCountry,
      businessState,
      entityType,
      businessNature,
      mintingVolume,
      activeUsers,
    }: Hubspot.Client.Form.AuthorizedRep) => {
      const formGuid =
        publicRuntimeConfig?.HUBSPOT_ONBOARDING_AUTH_REP_FORM_GUID

      void submitForm({
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: email,
          },
          {
            objectTypeId: '0-1',
            name: 'firstName',
            value: firstName,
          },
          {
            objectTypeId: '0-1',
            name: 'lastName',
            value: lastName,
          },
          {
            objectTypeId: '0-1',
            name: 'pq_authorized_representative',
            value: 'true',
          },
          {
            objectTypeId: '0-1',
            name: 'pq_legal_entity_name',
            value: businessName,
          },
          {
            objectTypeId: '0-1',
            name: 'pq_country_of_incorporation',
            value: businessCountry,
          },
          {
            objectTypeId: '0-1',
            name: 'pq_state_of_incorporation',
            value: businessState,
          },
          {
            objectTypeId: '0-1',
            name: 'pq_entity_type',
            value: entityType,
          },
          {
            objectTypeId: '0-1',
            name: 'pq_nature_of_business',
            value: businessNature,
          },
          {
            objectTypeId: '0-1',
            name: 'expected_mint_volume__monthly_',
            value: mintingVolume,
          },
          {
            objectTypeId: '0-1',
            name: 'active_users_on_platform',
            value: activeUsers,
          },
          {
            objectTypeId: '0-1',
            name: 'date_of_circle_account_form_2_submission',
            value: new Date().toISOString().split('T')[0],
          },
        ],
        pageName: 'Auth Rep Confirm/Invite Page',
        formGuid,
      })
    },
    [submitForm],
  )

  /**
   * Handle sandbox user signup form submission.
   */
  const submitSandboxSignupForm = useCallback(
    ({
      email,
      firstName,
      lastName,
      entityName,
    }: Hubspot.Client.Form.SandboxSignup) => {
      const formGuid = publicRuntimeConfig?.HUBSPOT_SANDBOX_SIGNUP_FORM_GUID

      void submitForm({
        fields: [
          {
            objectTypeId: '0-1',
            name: 'email',
            value: email,
          },
          {
            objectTypeId: '0-1',
            name: 'firstName',
            value: firstName,
          },
          {
            objectTypeId: '0-1',
            name: 'lastName',
            value: lastName,
          },
          {
            objectTypeId: '0-1',
            name: 'company',
            value: entityName,
          },
        ],
        pageName: 'Sandbox Signup Form Page',
        formGuid,
      })
    },
    [submitForm],
  )

  /**
   * Handle business account signup form submission.
   */
  const submitBusinessAccountSignupForm = useCallback(
    ({
      firstName,
      lastName,
      businessEmail,
      businessName,
      country,
      marketingConsent,
      privacyConsent,
    }: Hubspot.Client.Form.BusinessAccountSignup) => {
      const formGuid = publicRuntimeConfig?.HUBSPOT_BUSINESS_SIGNUP_FORM_GUID

      const formRequiredFields: Hubspot.Server.Form.Field[] = [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: businessEmail,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: businessName,
        },
        {
          objectTypeId: '0-1',
          name: 'hq_country',
          value: country,
        },
        {
          objectTypeId: '0-1',
          name: 'date_of_circle_account_form_1_submission',
          value: new Date().toISOString().split('T')[0],
        },
        {
          objectTypeId: '0-1',
          name: 'privacy_consent',
          value: String(privacyConsent),
        },
      ]

      const formFields =
        marketingConsent == null
          ? formRequiredFields
          : [
              ...formRequiredFields,
              {
                objectTypeId: '0-1',
                name: 'marketing_consent',
                value: String(marketingConsent),
              },
            ]

      void submitForm({
        fields: formFields,
        pageName: 'Business Account Signup Form Page',
        formGuid,
      })
    },
    [submitForm],
  )

  /**
   * Handle legacy business information form submission.
   */
  const submitLegacyBusinessInformationForm = useCallback(
    ({
      businessEmail,
      businessName,
      businessCountry,
      businessState,
      entityType,
      businessNature,
      mintingVolume,
      activeUsers,
    }: Hubspot.Client.Form.BusinessInformation) => {
      const formGuid = publicRuntimeConfig?.HUBSPOT_BUSINESS_INFO_FORM_GUID

      const formFields: Hubspot.Server.Form.Field[] = [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: businessEmail,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_legal_entity_name',
          value: businessName,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_country_of_incorporation',
          value: businessCountry,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_state_of_incorporation',
          value: businessState,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_entity_type',
          value: entityType,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_nature_of_business',
          value: businessNature,
        },
        {
          objectTypeId: '0-1',
          name: 'expected_mint_volume__monthly_',
          value: mintingVolume,
        },
        {
          objectTypeId: '0-1',
          name: 'active_users_on_platform',
          value: activeUsers,
        },
        {
          objectTypeId: '0-1',
          name: 'date_of_circle_account_form_1b_submission',
          value: new Date().toISOString().split('T')[0],
        },
      ]

      void submitForm({
        fields: formFields,
        pageName: 'Business Information Form Page',
        formGuid,
      })
    },
    [submitForm],
  )

  /**
   * Handle business information form submission.
   */
  const submitBusinessInformationForm = useCallback(
    ({
      businessEmail,
      businessName,
      businessCountry,
      businessState,
      entityType,
      businessNature,
      mintingVolume,
      activeUsers,
    }: Hubspot.Client.Form.BusinessInformation) => {
      const formGuid = publicRuntimeConfig?.HUBSPOT_BUSINESS_INFO_FORM_GUID

      const formFields: Hubspot.Server.Form.Field[] = [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: businessEmail,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_legal_entity_name',
          value: businessName,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_country_of_incorporation',
          value: businessCountry,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_state_of_incorporation',
          value: businessState,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_entity_type',
          value: entityType,
        },
        {
          objectTypeId: '0-1',
          name: 'pq_nature_of_business',
          value: businessNature,
        },
        {
          objectTypeId: '0-1',
          name: 'expected_quarterly_minting_volume',
          value: mintingVolume,
        },
        {
          objectTypeId: '0-1',
          name: 'active_users_on_platform',
          value: activeUsers,
        },
        {
          objectTypeId: '0-1',
          name: 'date_of_circle_account_form_1b_submission',
          value: new Date().toISOString().split('T')[0],
        },
      ]

      void submitForm({
        fields: formFields,
        pageName: 'Business Information Form Page',
        formGuid,
      })
    },
    [submitForm],
  )

  return {
    submitOnboardingForm,
    submitAuthorizedRepForm,
    submitSandboxSignupForm,
    submitBusinessAccountSignupForm,
    submitBusinessInformationForm,
    submitLegacyBusinessInformationForm,
  }
}

import { useCallback, useMemo } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'

import { useVaultPermission } from '../../../hooks/permission'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { VaultTransactionType } from '@shared/graphql'

export interface DownloadReportProps extends Pick<ButtonProps, 'disabled'> {
  /**
   * Specify from which date transactions are to be downloaded.
   *
   * This is optional. If you do not provide a value the creation date of the vault will be used as start date.
   */
  startDate?: Date
  /**
   * Specify until which date transactions are to be downloaded.
   *
   * This is optional. If you do not provide a value the current value will be used as end date.
   */
  endDate?: Date
  /**
   * Specify which type of transactions should be present in the report.
   */
  transactionTypes?: VaultTransactionType[]
}

export const DownloadReport: React.FC<DownloadReportProps> = ({
  startDate,
  endDate,
  transactionTypes,
  disabled,
}) => {
  const { track } = useSegment()
  const { router } = useModal()

  const { vaultMetadata } = useVaultPermission()

  const isDisabled = useMemo(() => {
    // Do not allow downloading transactions for a vault that is not yet active.
    return (
      !vaultMetadata ||
      [
        VaultStateEnum.SETUP_IN_PROGRESS,
        VaultStateEnum.READY_FOR_APPROVAL,
        VaultStateEnum.PENDING_APPROVAL,
      ].includes(vaultMetadata.state) ||
      disabled
    )
  }, [vaultMetadata, disabled])

  const handleClick = useCallback(() => {
    /**
     * Even though this should never happen,
     * we will just return to satisfy typescript.
     */
    if (!vaultMetadata?.id) return

    track(SegmentEvents.DownloadVaultReportButtonClicked)

    void router.push({
      pathname: routes.vault.downloadTransactionsReport,
      query: {
        input: {
          vaultId: vaultMetadata.id,
          vaultName: vaultMetadata.name,
          startDate: startDate?.toISOString() ?? undefined,
          endDate: endDate?.toISOString() ?? undefined,
          transactionTypes,
        },
      },
    })
  }, [vaultMetadata, router, track, startDate, endDate, transactionTypes])

  // Do not return anything if button was not wrapped with a VaultPermissionProvider.
  if (!vaultMetadata) return null

  return (
    <Button disabled={isDisabled} onClick={handleClick} variant="primary">
      <Trans
        components={{
          span: <span className="ml-1 normal-case" />,
        }}
        i18nKey={`vaults:buttons.downloadReport.${
          startDate != null || endDate != null || transactionTypes != null
            ? 'filtered'
            : 'all'
        }`}
      />
    </Button>
  )
}

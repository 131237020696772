import classNames from 'classnames'

import { MaybeNull } from '../../common'

interface DefaultCellProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom class.
   */
  className?: string
}

export const DefaultCell: React.FC<DefaultCellProps> = ({
  children,
  className,
}) => (
  <div className={classNames('text-sm leading-5 text-black-600', className)}>
    <MaybeNull>{children}</MaybeNull>
  </div>
)

import { useContext } from 'react'

import { Sidenav } from '@circlefin/components'

import { MainNavContext } from './MainNav.Context/MainNav.Context'
import { MainNavFrame } from './MainNav.Frame/MainNav.Frame'
import { MainNavGroups } from './MainNav.Groups/MainNav.Groups'
import { MainNavItem } from './MainNav.Item/MainNav.Item'
import { MainNavLogo } from './MainNav.Logo/MainNav.Logo'

interface SubComponents {
  Frame: typeof MainNavFrame
  Groups: typeof MainNavGroups
  Item: typeof MainNavItem
  Logo: typeof MainNavLogo
}

export interface MainNavProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Style?
   */
  className?: string
}

export const MainNav: React.FC<MainNavProps> & SubComponents = ({
  className,
  children,
  ...props
}) => {
  const { variant, collapsed } = useContext(MainNavContext)

  return (
    <Sidenav
      className={className}
      collapsed={collapsed}
      variant={variant}
      {...props}
    >
      {children}
    </Sidenav>
  )
}

MainNav.Frame = MainNavFrame
MainNav.Groups = MainNavGroups
MainNav.Item = MainNavItem
MainNav.Logo = MainNavLogo

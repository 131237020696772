import { Card, Toggle } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { PolicyForm } from './PolicyForm/PolicyForm'
import { PolicySummary } from './PolicySummary/PolicySummary'

import type { VaultWalletConnectPolicy } from '@shared/graphql'

export interface PolicyProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Is the policy being edited.
   */
  isEdit?: boolean
  /**
   * Is the policy being enabled.
   */
  isEnabled?: boolean
  /**
   * Policy information.
   */
  policy?: VaultWalletConnectPolicy
  /**
   * List of all operators.
   */
  operators: VaultWalletConnectPolicy['operators']
  /**
   * List of all approvers.
   */
  approvers: VaultWalletConnectPolicy['approvers']
  /**
   * OnChange handler.
   */
  onChange: (values: VaultWalletConnectPolicy) => void
  /**
   * OnSubmit handler.
   */
  onSubmit: (values: VaultWalletConnectPolicy) => void
  /**
   * On edit click.
   */
  onEdit?: () => void
  /**
   * On toggle click.
   */
  onToggle?: (enabled: boolean) => void
}

export const Policy: React.FC<PolicyProps> = ({
  className,
  isEdit,
  isEnabled,
  policy,
  operators,
  approvers,
  onChange,
  onSubmit,
  onEdit,
  onToggle,
}) => {
  const { t } = useTranslation('modals.vault')

  return (
    <Card className={classNames('w-full', className)}>
      <Card.Content>
        <div className="flex items-center justify-between">
          <h2 className="text-xl text-neutral-strong font-circular-regular">
            {t('createVault.walletConnect.policy.title')}
          </h2>
          <Toggle onChange={onToggle} selected={isEnabled} />
        </div>

        <hr className="my-4 h-px border-black-50" />

        {(isEdit || !isEnabled) && (
          <h3 className="text-base text-neutral-strong font-circular-regular">
            {t('createVault.walletConnect.policy.description')}
          </h3>
        )}

        {isEdit && isEnabled && (
          <PolicyForm
            approvers={approvers}
            onChange={onChange}
            onSubmit={onSubmit}
            operators={operators}
            policy={policy}
          />
        )}

        {!isEdit && isEnabled && (
          <PolicySummary
            approvers={policy?.approvers}
            onEdit={onEdit}
            operators={policy?.operators}
          />
        )}
      </Card.Content>
    </Card>
  )
}

import { VerticalStepper } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { ProgressShellProps } from './ProgressShell'
import type { AccountSetupStep } from '../../../hooks/account-setup/context'

export interface ARStepperProps extends ProgressShellProps {
  /**
   * Determine if step is complete.
   */
  isStepComplete: (step: AccountSetupStep) => boolean
}

export const ARStepper: React.FC<ARStepperProps> = ({
  currentStep,
  isStepComplete,
}) => {
  const { t } = useTranslation('onboard/account-setup')

  return (
    <VerticalStepper isInteractive={false}>
      <VerticalStepper.Step
        label={t`navigation.steps.welcome`}
        isStepComplete
      />
      <VerticalStepper.Step
        isActive={currentStep === 'CONFIRM_DETAILS'}
        isStepComplete={isStepComplete('CONFIRM_DETAILS')}
        label={t`navigation.steps.confirm`}
      />
    </VerticalStepper>
  )
}

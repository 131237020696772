import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  StablecoinExpressRoutesDocument,
  useSetStablecoinExpressRouteMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface DisableRouteQueryProps {
  /**
   * Route ID.
   */
  id?: string
}

export const DisableRoute: React.FC<DisableRouteQueryProps> = (props) => {
  const { t } = useTranslation('express/modal')
  const modal = useModal()

  const [disableRoute, { loading, error, reset }] =
    useSetStablecoinExpressRouteMutation({
      refetchQueries: [StablecoinExpressRoutesDocument],
      onCompleted: () => {
        modal.close()
      },
    })

  const handleCancel = useCallback(() => {
    modal.close()
  }, [modal])

  const handleDisable = useCallback(
    (id: string) => () => {
      void disableRoute({
        variables: {
          input: {
            id,
          },
        },
      })
    },
    [disableRoute],
  )

  return (
    <BaseTitle title={t`disableRoute.title`} variant="center">
      <PropsErrorBoundary<DisableRouteQueryProps> props={props}>
        {({ id }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            {/* Description */}
            <p>{t`disableRoute.description`}</p>

            {/* Modal Footer */}
            <Modal.Footer variant="stretch">
              {/* Cancel */}
              <Button
                disabled={loading}
                onClick={handleCancel}
                variant="secondary"
                autoFocus
              >
                {t`disableRoute.cancel`}
              </Button>

              {/* Disable */}
              <Button
                loading={loading}
                onClick={handleDisable(id)}
                variant="primary"
              >
                {t`disableRoute.disable`}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../SendOnchain.Context'

import { TransferAmountForm } from './TransferAmount.Form'

import type { SendOnChainDestination } from '../SendOnchain.Context'

interface TransferAmountQueryProps {
  /**
   * Recipient address.
   */
  address?: SendOnChainDestination
  /**
   * Specify modal currency.
   */
  initialCurrency?: Currency
}

export const TransferAmount: React.FC<TransferAmountQueryProps> = ({
  address,
  initialCurrency,
}) => {
  const { router } = useModal()
  const { t } = useTranslation('modals.blockchain')
  const [{ currency }] = useCurrentCurrency({
    initialCurrency,
  })
  const { trackLinkClick } = useSegment()
  const [{ blockchain }, { setTransferState }] = useSendOnChain()

  /**
   * Handle form submit.
   */
  const onFormSubmit = useCallback(() => {
    if (address) {
      setTransferState({
        blockchain: address.chain,
        destination: address,
      })
    }
    router.push(routes.blockchain.sendOnchain.review)
  }, [address, router, setTransferState])

  /**
   * Determine chain.
   */
  const chain = address?.chain ?? blockchain

  return (
    <PropsErrorBoundary props={{ blockchain: chain }} variant="page">
      {({ blockchain }) => (
        <FullScreen totalSteps={5}>
          <Center className="font-circular" variant="horizontal">
            <h2 className="mb-2 w-160 text-center text-2xl text-black-600 font-circular-bold">
              {blockchain != null &&
                t('sendOnchain.transferAmount.title', {
                  blockchain: t<string>(`payments:chainNames.${blockchain}`),
                })}
            </h2>
            {currency === Currency.EURC && (
              <Center>
                <h3 className="mb-4 w-128 text-center text-lg leading-6 text-black-400">
                  {blockchain != null && (
                    <Trans
                      components={{
                        link: (
                          <a
                            href={t`common:helpCenter`}
                            onClick={trackLinkClick}
                            rel="noopener noreferrer"
                            target="_blank"
                          />
                        ),
                      }}
                      i18nKey="modals.blockchain:sendOnchain.transferAmount.subtitle"
                      values={{
                        currency,
                        label: t<string>(`payments:chainNames.${blockchain}`),
                      }}
                    />
                  )}
                </h3>
              </Center>
            )}

            <Center variant="horizontal">
              <div className="w-104">
                <TransferAmountForm
                  blockchain={chain}
                  onSubmit={onFormSubmit}
                />
              </div>
            </Center>
          </Center>
        </FullScreen>
      )}
    </PropsErrorBoundary>
  )
}

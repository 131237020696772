/**
 * Verite Routes.
 */
export const verite = {
  issuer: {
    challenge: '/verite/issuer/challenge',
  },
  verifier: {
    challenge: '/verite/verifier/challenge',
  },
  metamask: {
    warning: '/verite/metamask/warning',
  },
}

import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export const Error: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { router } = useModal()

  const handleDone = useCallback(() => {
    void router.push({
      pathname: routes.vault.walletBackup.confirmation,
      options: {
        disableBack: true,
      },
    })
  }, [router])

  return (
    <FullScreen totalSteps={7}>
      <Statement
        status="warning"
        subtitle={t`walletBackup.error.subtitle`}
        title={t`walletBackup.error.title`}
        variant="page"
      >
        <Button
          data-testid="action-button"
          onClick={handleDone}
          variant="primary"
        >
          {t('walletBackup.error.btn')}
        </Button>
      </Statement>
    </FullScreen>
  )
}

import { L3_ONBOARDING, PUBLIC } from '@services/permissions'

import type { Section } from '../types'

export const secret: Section = {
  label: 'layout:page.api.title',
  route: '/api/secret',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const ping: Section = {
  label: 'layout:page.api.title',
  route: '/api/ping',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const log: Section = {
  label: 'layout:page.api.title',
  route: '/api/log/[method]',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const graphql: Section = {
  label: 'layout:page.api.title',
  route: '/api/graphql',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const segment: Section = {
  label: 'layout:page.api.title',
  route: '/api/segment',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const auth: Section = {
  label: 'layout:page.api.title',
  route: '/api/auth/[...nextauth]',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const authError: Section = {
  label: 'layout:page.api.title',
  route: '/api/auth/error',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const authSignIn: Section = {
  label: 'layout:page.api.title',
  route: '/api/auth/signin',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const hubspot: Section = {
  label: 'layout:page.api.title',
  route: '/api/hubspot',
  permissions: PUBLIC,
  segment: {
    page: 'api',
  },
}

export const l3OnboardingDownload: Section = {
  label: 'layout:page.api.title',
  route: '/api/download/onboarding/l3/[file]',
  permissions: L3_ONBOARDING,
  segment: {
    page: 'api',
  },
}

export const onboardingUserFileDownload: Section = {
  label: 'layout:page.api.title',
  route: '/api/download/onboarding/[onboardingId]/documents/[documentId]',
  permissions: L3_ONBOARDING,
  segment: {
    page: 'api',
  },
}

export const apiSection: Section = {
  label: 'layout:page.api.title',
  route: '/api',
  permissions: PUBLIC,
  pages: [
    secret,
    ping,
    log,
    graphql,
    segment,
    hubspot,
    auth,
    authError,
    authSignIn,
    l3OnboardingDownload,
    onboardingUserFileDownload,
  ],
  segment: {
    page: 'api',
  },
}

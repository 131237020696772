import { useMemo } from 'react'

import { Icon } from '@circlefin/components'
import { y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

export interface PersonalEmailWarningProps {
  /**
   * User entered email.
   */
  email: string
}

const schema = y.object({
  email: y.string().personalEmail(),
})

export const PersonalEmailWarning: React.FC<PersonalEmailWarningProps> = ({
  email,
}) => {
  const { t } = useTranslation('common')

  const isPersonalEmail = useMemo(
    () =>
      schema.isValidSync({
        email,
      }),
    [email],
  )

  if (isPersonalEmail) {
    return (
      <>
        <Icon name="ExclamationCircleOutline" size={14} />
        {t`form.validation.personalEmail`}
      </>
    )
  }

  return null
}

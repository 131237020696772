import { InternalAddressType } from '@shared/graphql'

import type {
  CircleInternalAddress,
  DirectInternalAddress,
  InternalAddress,
} from '@shared/graphql'

/**
 * Type guard to check if a given address is a circle internal address.
 */
export function isCircle(
  address: InternalAddress,
): address is CircleInternalAddress {
  return address.type === InternalAddressType.CIRCLE
}

/**
 * Type guard to check if a given address is a direct internal address.
 */
export function isDirect(
  address: InternalAddress,
): address is DirectInternalAddress {
  return address.type === InternalAddressType.DIRECT
}

import { useCallback } from 'react'

import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { CIRCLE_WALLETS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { ComplexTable, TableCard } from '@shared/components/tables'
import { useCircleWalletsQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useCircleWalletsTableColumns } from './CircleWalletsTable.Columns'

import type { Row } from '@circlefin/components/lib/AdvancedTable'
import type { CircleWallet } from '@shared/graphql'

const { Header, Title, Body } = TableCard

export interface CircleWalletsTableProps {
  /**
   * Custom style.
   */
  className?: string
}

export const CircleWalletsTable: React.FC<CircleWalletsTableProps> = ({
  className,
}) => {
  const router = useRouter()
  const { t } = useTranslation('custody/common')
  const columns = useCircleWalletsTableColumns()

  const { data, loading, error, refetch } = useCircleWalletsQuery({
    pollInterval: CIRCLE_WALLETS_POLL_INTERVAL, // 10sec interval
    fetchPolicy: 'cache-and-network',
  })

  const handleRowClick = useCallback(
    (row: Row<CircleWallet>) => {
      void router.push({
        pathname: circleWalletDetailSection.route,
        query: {
          currency: row.original.balance.currency,
        },
      })
    },
    [router],
  )

  return (
    <TableCard className={className} paddingVariant="sm">
      <Header>
        <div className="flex gap-y-0.5">
          <Title>{t('circleWallets.table.title')}</Title>
        </div>
      </Header>
      <Body>
        <ComplexTable<CircleWallet>
          columns={columns}
          data={data?.circleWallets}
          enableSorting={false}
          error={error}
          loading={loading}
          onRowClick={handleRowClick}
          pageSize={2}
          retry={refetch}
          manualFiltering
        />
      </Body>
    </TableCard>
  )
}

CircleWalletsTable.displayName = 'CircleWalletsTable'

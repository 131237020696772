import classNames from 'classnames'

export interface ContentProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style?
   */
  className?: string
}

/**
 * Content Component.
 */
export const Content: React.FC<ContentProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div {...rest} className={classNames('px-16', className)}>
      <div className={classNames('mx-auto max-w-7xl space-y-7 min-w-4xl')}>
        {children}
      </div>
    </div>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { MerchantPaymentCancellationReason } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { PlatformForms } from '../../../forms'

import type { MerchantPaymentCancellationInput } from '@shared/graphql'

const schema = y.object({
  /**
   * (required) Reason for the refund to be requested.
   */
  reason: PlatformForms.Dropdown.reasonsSchema.required(),
})

export type CancelFormValues = y.InferType<typeof schema>

export interface CancelFormProps {
  /**
   * Payment ID to be refunded.
   */
  paymentId: string
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: MerchantPaymentCancellationInput) => void
  /**
   * On Form Cancel callback.
   */
  onCancel: () => void
}

export const CancelForm: React.FC<CancelFormProps> = ({
  paymentId,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('platform/modals')

  const [Form] = useForm<CancelFormValues>({
    schema,
    defaultValues: {
      reason: MerchantPaymentCancellationReason.duplicate,
    },
  })

  const onFormSubmit = useCallback(
    ({ reason }: CancelFormValues) => {
      onSubmit({
        paymentId,
        reason,
      })
    },
    [onSubmit, paymentId],
  )

  return (
    <Form
      className="flex size-full flex-col items-stretch justify-start font-circular"
      onSubmit={onFormSubmit}
    >
      <div className="px-18">
        <PlatformForms.Dropdown.Reasons
          className="mt-6 w-full"
          label={t`payment.cancel.form.reason`}
          name="reason"
        />
      </div>
      <Modal.Footer variant="stretch-equal">
        <Button className="flex-1" onClick={onCancel} variant="secondary">
          {t`payment.cancel.form.cancel`}
        </Button>
        <Form.SubmitButton
          className="flex-1"
          data-testid="button-submit"
          variant="primary"
        >
          {t`payment.cancel.form.submit`}
        </Form.SubmitButton>
      </Modal.Footer>
    </Form>
  )
}

import type React from 'react'

import { InlineNotification, Card, SkeletonBox } from '@circlefin/components'
import { TypeGuards } from '@services/type-guards'
import useTranslation from 'next-translate/useTranslation'

import { getEligibleRails } from '../../../../hooks/fx/helpers'

import type { BankAccount, BankAccountType, RailType } from '@shared/graphql'

export interface ReviewDepositCardProps {
  /**
   * Bank Account.
   */
  bankAccount?: BankAccount
  /**
   * Loading boolean, true when the query is in flight for fetching a new quote usually.
   */
  loading?: boolean
}

export const ReviewDepositCard: React.FC<ReviewDepositCardProps> = ({
  bankAccount,
  loading = false,
}) => {
  const { t } = useTranslation('modals.transfer')
  const isWireAccount =
    bankAccount != null && TypeGuards.BankAccount.isWire(bankAccount)

  const bankAccountListItem = (
    bankAccountType: BankAccountType | RailType | null,
  ) => {
    if (bankAccountType == null) {
      return null
    }

    return (
      <li key={bankAccountType} className="pl-3 type-body-sm">
        <span>
          {t(`fx.reviewDepositCard.transferMethod.type.${bankAccountType}`)}
        </span>{' '}
        <span>
          (
          {t(`fx.reviewDepositCard.transferMethod.duration.${bankAccountType}`)}
          )
        </span>
      </li>
    )
  }

  return (
    <Card variant="primary/sm">
      <Card.Content className="flex flex-col gap-5">
        {/* Selected Bank */}
        <div className="flex flex-col gap-2">
          <h4 className="type-body-sm-bold">
            {t('fx.reviewDepositCard.selectedBank.title')}
          </h4>
          <SkeletonBox className="h-7" loading={loading}>
            {bankAccount && (
              <dl
                aria-label="term1"
                className="flex items-center justify-between"
              >
                <dt
                  aria-label="bank-account-nickname"
                  className="justify-start type-body-base"
                >
                  {bankAccount.nickname}
                </dt>
                <dd
                  aria-label="bank-account-description"
                  className="justify-end text-neutral-subtle type-body-base"
                >
                  {bankAccount.description}
                </dd>
              </dl>
            )}
          </SkeletonBox>
        </div>

        {/* Available Methods */}
        <div className="flex flex-col gap-2">
          <h4 className="type-body-sm-bold">
            {t('fx.reviewDepositCard.transferMethod.title')}
          </h4>
          <SkeletonBox className="h-7" loading={loading}>
            {bankAccount && (
              <ul className="flex list-inside list-disc flex-col gap-1">
                {isWireAccount && bankAccount.transferTypes
                  ? getEligibleRails({ bankAccount }).map(bankAccountListItem)
                  : bankAccountListItem(bankAccount.type)}
              </ul>
            )}
          </SkeletonBox>
        </div>

        {/* Warning */}
        <InlineNotification
          className="text-neutral-subtle type-body-sm"
          intent="warning"
        >
          {t('fx.reviewDepositCard.selectedBank.bankDisclaimer')}
        </InlineNotification>
      </Card.Content>
    </Card>
  )
}

/**
 * Account: Billing Routes.
 */
export const billing = {
  /**
   * Autopay routes.
   */
  autoPay: {
    enable: '/account/billing/auto-pay/enable',
  },

  /**
   * Pay invoice routes.
   */
  payInvoice: {
    switch: '/account/billing/pay-invoice/',
    paymentMethodSelection:
      '/account/billing/pay-invoice/payment-method-selection',
    confirmPayment: '/account/billing/pay-invoice/confirm-payment',
  },

  /**
   * Payment Methods.
   */
  paymentMethod: {
    setDefault: '/account/billing/payment-method/set-default',
    /**
     * Credit card routes.
     */
    addCreditCard: {
      switch: '/account/billing/add-credit-card/',
      details: '/account/billing/add-credit-card/details',
      holderDetails: '/account/billing/add-credit-card/holder-details',
    },
  },
}

import { useMemo } from 'react'

import { TypeGuards } from '@services/type-guards'
import { Currency } from '@shared/graphql'

import { Grid, GridTitleEnum } from '../../../Grid/Grid'

import { CbitInstructionGrid } from './Rail/CBIT/CbitInstructionGrid'
import { XpayInstructionGrid } from './Rail/Xpay/XpayInstructionGrid'

import type { GridItem } from '../../../Grid/Grid'
import type { BankAccount, TransferInstruction } from '@shared/graphql'

export interface USDDepositInstructionsProps {
  /**
   * Transfer instruction.
   */
  transferInstruction?: TransferInstruction
  /**
   * Bank Account.
   */
  bankAccount: BankAccount
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Is query loading.
   */
  loading?: boolean
}

export const USDDepositInstructionsGrid: React.FC<USDDepositInstructionsProps> =
  ({ loading, transferInstruction, bankAccount, virtualAccountEnabled }) => {
    const values = useMemo(() => {
      const currencyField: GridItem = [GridTitleEnum.currency, Currency.USD]

      const referenceIDMemo: GridItem = [
        GridTitleEnum.referenceIDMemo,
        transferInstruction?.trackingRef,
      ]

      return [currencyField, referenceIDMemo]
    }, [transferInstruction])

    if (
      TypeGuards.TransferInstruction.isXpay(
        transferInstruction,
        bankAccount?.type,
      )
    ) {
      return (
        <XpayInstructionGrid
          gridItems={values}
          loading={loading}
          transferInstruction={transferInstruction}
          virtualAccountEnabled={virtualAccountEnabled}
        />
      )
    }
    if (
      TypeGuards.TransferInstruction.isCbit(
        transferInstruction,
        bankAccount?.type,
      )
    ) {
      return (
        <CbitInstructionGrid
          gridItems={values}
          loading={loading}
          transferInstruction={transferInstruction}
          virtualAccountEnabled={virtualAccountEnabled}
        />
      )
    }

    return (
      <Grid
        gridItems={values}
        loading={loading}
        virtualAccountEnabled={virtualAccountEnabled}
      />
    )
  }

import { useCallback, useEffect, useState } from 'react'

import { FixedBanner, Header, SkeletonBox } from '@circlefin/components'
import { useModalHistory } from '@circlefin/modal-router'
import { TabsNav } from '@containers/layout'
import {
  vaultActivitySection,
  vaultDetailsSection,
  vaultPolicySection,
} from '@services/sections/lib/vaults'
import { routes } from '@services/sections/modal/routes'
import { VaultStateEnum, VaultType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'
import { VaultChips } from '../VaultChips/VaultChips'
import { WalletBackupRequiredNotification } from '../WalletBackupRequiredNotification/WalletBackupRequiredNotification'

import { VaultDetailHeaderNumbers } from './VaultDetailHeader.Numbers'

import type { Vault } from '@shared/graphql'

export interface VaultDetailHeaderProps {
  /**
   * Specify if the content is still being loaded.
   */
  loading?: boolean
  /**
   * The id of the vault.
   */
  vaultId: string
  /**
   * Vault that should be displayed.
   */
  vault?: Vault
}

export const VaultDetailHeader: React.FC<VaultDetailHeaderProps> = ({
  loading,
  vaultId,
  vault,
}) => {
  const { t } = useTranslation('vaults')
  const { route } = useModalHistory()

  const [hasSubmissionError, setSubmissionError] = useState(false)

  const dismissSubmissionError = useCallback(
    () => setSubmissionError(false),
    [],
  )

  useEffect(() => {
    if (route?.pathname === routes.vault.createVault.policyName) {
      dismissSubmissionError()
    }
  }, [dismissSubmissionError, route?.pathname])

  const handleSubmissionError = useCallback(() => setSubmissionError(true), [])

  return (
    <Header loading={loading}>
      <Header.Chips>
        <SkeletonBox className="mb-2 h-7 w-48" loading={loading}>
          {vault?.state != null && <VaultChips state={vault.state} />}
        </SkeletonBox>
      </Header.Chips>
      <Header.Head title={vault?.name ?? ''}>
        <div className="flex h-12 flex-col items-end space-y-3">
          <SkeletonBox className="h-10 w-44" loading={loading}>
            <Buttons.Vault.CompleteSetup
              isWalletConnectStepVisible={vault?.type === VaultType.WEB3}
            />
            <Buttons.Vault.PendingApproval />
            <Buttons.Vault.SubmitForApproval onError={handleSubmissionError} />
            <Buttons.Vault.CancelApproval />
          </SkeletonBox>
        </div>
      </Header.Head>
      <VaultDetailHeaderNumbers vaultId={vaultId} />

      <FixedBanner
        className="mt-7"
        onDismiss={dismissSubmissionError}
        status="error"
        visible={hasSubmissionError}
      >
        <FixedBanner.Description>
          {t`vault.detail.approval.submit.description`}
        </FixedBanner.Description>
      </FixedBanner>

      <FixedBanner
        className="mt-7"
        status="info"
        visible={
          vault?.state === VaultStateEnum.READY_FOR_APPROVAL &&
          !hasSubmissionError
        }
      >
        <FixedBanner.Title>{t`vault.detail.approval.ready.title`}</FixedBanner.Title>
        <FixedBanner.Description>{t`vault.detail.approval.ready.description`}</FixedBanner.Description>
      </FixedBanner>

      <WalletBackupRequiredNotification
        className="mt-7"
        open={vault?.backUpRequired}
        showAction={vault?.isCreator}
      />
      <TabsNav
        className="mt-14"
        query={{ vaultId }}
        sections={[
          vaultDetailsSection,
          vaultActivitySection,
          vaultPolicySection,
        ]}
      />
    </Header>
  )
}

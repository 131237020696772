import {
  CHECKOUT,
  CRYPTO_PAYMENTS,
  PAYOUTS,
  SETTLEMENTS,
  PROTECTED,
  DASHBOARD_MANAGEMENT_QUERY,
} from '@services/permissions'

import type { Section } from '../types'

export const merchantPaymentDetailsSection: Section = {
  label: 'layout:page.platform.payments.detail',
  route: '/platform/payments/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const merchantPaymentsSection: Section = {
  label: 'layout:page.platform.payments.title',
  route: '/platform/payments',
  permissions: PROTECTED,
  pages: [merchantPaymentDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const merchantPaymentIntentsDetailsSection: Section = {
  label: 'layout:page.platform.payment-intents.detail',
  route: '/platform/payment-intents/[id]',
  permissions: CRYPTO_PAYMENTS,
  segment: {
    page: 'platform',
  },
}

export const merchantPaymentIntentsSection: Section = {
  label: 'layout:page.platform.payment-intents.title',
  route: '/platform/payment-intents',
  permissions: CRYPTO_PAYMENTS,
  pages: [merchantPaymentIntentsDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const merchantPayoutDetailsSection: Section = {
  label: 'layout:page.platform.payouts.detail',
  route: '/platform/payouts/[id]',
  permissions: PAYOUTS,
  segment: {
    page: 'platform',
  },
}

export const merchantPayoutsSection: Section = {
  label: 'layout:page.platform.payouts.title',
  route: '/platform/payouts',
  permissions: PAYOUTS,
  pages: [merchantPayoutDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const merchantRefundDetailsSection: Section = {
  label: 'layout:page.platform.refunds.detail',
  route: '/platform/refunds/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const chargebackSection: Section = {
  label: 'layout:page.platform.chargebacks.detail',
  route: '/platform/chargebacks/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const chargebacksSection: Section = {
  label: 'layout:page.platform.chargebacks.title',
  route: '/platform/chargebacks',
  permissions: PROTECTED,
  pages: [chargebackSection],
  segment: {
    page: 'platform',
  },
}

export const settlementDetailsSection: Section = {
  label: 'layout:page.platform.settlements.detail',
  route: '/platform/settlements/[id]',
  permissions: SETTLEMENTS,
  segment: {
    page: 'platform',
  },
}

export const settlementsSection: Section = {
  label: 'layout:page.platform.settlements.title',
  route: '/platform/settlements',
  permissions: SETTLEMENTS,
  pages: [settlementDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const transferDetailsSection: Section = {
  label: 'layout:page.platform.transfers.detail',
  route: '/platform/transfers/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const transfersSection: Section = {
  label: 'layout:page.platform.transfers.title',
  route: '/platform/transfers',
  permissions: PROTECTED,
  pages: [transferDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const reversalDetailSection: Section = {
  label: 'layout:page.platform.reversals.detail',
  route: '/platform/reversals/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const reversalsSection: Section = {
  label: 'layout:page.platform.reversals.title',
  route: '/platform/reversals',
  permissions: PROTECTED,
  pages: [reversalDetailSection],
  segment: {
    page: 'platform',
  },
}

export const reportingSection: Section = {
  label: 'layout:page.platform.reports',
  route: '/platform/reporting',
  permissions: PROTECTED,
  segment: {
    page: 'platform',
  },
}

export const checkoutSessionDetailsSection: Section = {
  label: 'layout:page.platform.checkout-sessions.detail',
  route: '/platform/checkout/sessions/[id]',
  permissions: CHECKOUT,
  segment: {
    page: 'platform',
  },
}

export const checkoutSessionSection: Section = {
  label: 'layout:page.platform.checkout-sessions.title',
  route: '/platform/checkout/sessions',
  permissions: CHECKOUT,
  pages: [checkoutSessionDetailsSection],
  segment: {
    page: 'platform',
  },
}

export const platformSection: Section = {
  icon: 'ReceiptInvoiceOutline',
  label: 'layout:page.platform.title',
  route: '/platform/payments',
  permissions: DASHBOARD_MANAGEMENT_QUERY,
  pages: [
    merchantPaymentsSection,
    merchantPaymentIntentsSection,
    merchantPaymentIntentsDetailsSection,
    merchantPaymentDetailsSection,
    merchantPayoutsSection,
    merchantPayoutDetailsSection,
    merchantRefundDetailsSection,
    settlementsSection,
    chargebacksSection,
    chargebackSection,
    settlementDetailsSection,
    transfersSection,
    transferDetailsSection,
    reversalsSection,
    reversalDetailSection,
    reportingSection,
    checkoutSessionSection,
    checkoutSessionDetailsSection,
  ],
  segment: {
    page: 'platform',
  },
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Vault query.
 * Which users are allowed to see/query vault data?
 *
 * VAULT_MANAGEMENT_ROLE and VAULT_MANAGEMENT_READ_ROLE are disabled in env except local.
 */
export const VAULT_QUERY: PermissionSet = {
  access: FeatureSwitch.vaultCustodyEnabled()
    ? AccessPermissions.protected
    : AccessPermissions.restricted,
  includeAny: [Role.VAULT_MANAGEMENT_ROLE, Role.VAULT_MANAGEMENT_READ_ROLE],
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * AVAX blockchain EURC withdrawal access permission.
 */
export const AVAX_BLOCKCHAIN_WITHDRAWAL_EURC: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [
    SynthRole.SYNTH_AVAX_BLOCKCHAIN_EURC_ROLE,
    SynthRole.SYNTH_AVAX_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE,
  ],
}

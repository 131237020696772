import { useCallback, useEffect, useMemo } from 'react'

import { Dropdown } from '@circlefin/components/lib/Dropdown'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { BlockchainAlert } from '@features/blockchain/components'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import { Currency, InternalWalletType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { FrozenWalletAlert } from '../../../../../components'
import { AssetBalanceText } from '../../../../../containers'
import { VaultForms } from '../../../../../forms'
import { useVaultTransfer } from '../../../VaultTransfer.Context'
import { useSendOnChain } from '../../SendOnChain.Context'

import type { InternalWalletFilterInput, InternalWallet } from '@shared/graphql'

const schema = y.object({
  /**
   * Source Wallet used to transfer.
   */
  sourceWallet: VaultForms.Combobox.InternalWalletsSourceSchema.required(),
  /**
   * Asset Wallet used to transfer.
   */
  assetWallet: VaultForms.Combobox.WalletsByParentSchema.when(
    ['sourceWallet'],
    ([sourceWallet]: (InternalWallet | undefined)[], schema) => {
      return sourceWallet && TypeGuards.InternalWallet.isCircle(sourceWallet)
        ? schema
        : schema.required()
    },
  ),
  /**
   * Destination wallet to be transferred to.
   */
  destination: VaultForms.Combobox.InternalWalletsDestinationSchema.required(),
})

type FormValues = y.InferType<typeof schema>

export const TransferRecipientForm: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const { router } = useModal()
  const [{ currency }] = useCurrentCurrency()
  const [
    { selectedParentWalletId, selectedWalletId },
    { setVaultTransferState },
  ] = useVaultTransfer()
  const [
    { sourceWallet, assetWallet },
    { setSendOnChainState },
    { directSourceWallet, derivedAssetSymbol },
  ] = useSendOnChain()
  const [
    Form,
    {
      formState: { isValid },
      watch,
      getValues,
    },
  ] = useForm<FormValues>({ schema })

  const destinationFilter: InternalWalletFilterInput | undefined =
    useMemo(() => {
      if (!sourceWallet) {
        return
      }

      if (TypeGuards.InternalWallet.isCircle(sourceWallet)) {
        return {
          asset: derivedAssetSymbol,
          excludeType: InternalWalletType.CIRCLE,
        }
      }

      return {
        asset: derivedAssetSymbol,
        excludedParentWalletId: sourceWallet.parentWalletId,
        showWhitelistedWalletsForVault: {
          vaultId: sourceWallet.vaultMetadata.id,
          policyId: sourceWallet.vaultMetadata.policyId ?? '',
          blockchain: sourceWallet.blockchain,
        },
      }
    }, [derivedAssetSymbol, sourceWallet])

  useEffect(() => {
    const subscription = watch(() => {
      // Sync provider states with the form values
      setSendOnChainState({
        // watch is used to trigger the callback, and getValues is used to get the precise field values
        sourceWallet: getValues('sourceWallet'),
        assetWallet: getValues('assetWallet'),
        destination: getValues('destination'),
      })

      // If the parent wallet is auto-selected, clear context value
      if (sourceWallet && selectedParentWalletId) {
        setVaultTransferState({ selectedParentWalletId: undefined })
      }

      // If child/mapped wallet is auto-selected, clear context value
      if (assetWallet && selectedWalletId) {
        setVaultTransferState({ selectedWalletId: undefined })
      }
    })

    return () => subscription.unsubscribe()
  }, [
    assetWallet,
    getValues,
    selectedParentWalletId,
    selectedWalletId,
    setSendOnChainState,
    setVaultTransferState,
    sourceWallet,
    watch,
  ])

  /**
   * Handle Form onSubmit.
   */
  const handleFormOnSubmit = useCallback(() => {
    router.push(routes.vault.transfer.sendOnChain.amount)
  }, [router])

  return (
    <Form onSubmit={handleFormOnSubmit}>
      <VaultForms.Combobox.InternalWallets
        circleCustodyWalletCurrency={currency}
        internalWalletId={selectedParentWalletId}
        label={t`forms:combobox.vault.internalWallets.source.label`}
        name="sourceWallet"
        placeholder={t`forms:combobox.vault.internalWallets.source.placeholder`}
      />

      {sourceWallet && TypeGuards.InternalWallet.isCircle(sourceWallet) ? (
        <Dropdown
          className="mt-6"
          items={[
            { label: sourceWallet.currency, value: sourceWallet.currency },
          ]}
          label={t`forms:combobox.vault.internalWallets.asset.label`}
          message={
            <AssetBalanceText.CircleWallet currency={sourceWallet.currency} />
          }
          placeholder={t`forms:combobox.vault.internalWallets.asset.placeholder`}
          value={sourceWallet.currency}
        >
          <Dropdown.Options
            items={[
              { label: sourceWallet.currency, value: sourceWallet.currency },
            ]}
          />
        </Dropdown>
      ) : (
        <>
          <VaultForms.Combobox.WalletsByParent
            className="mt-6"
            disabled={!sourceWallet}
            label={t`forms:combobox.vault.internalWallets.asset.label`}
            message={
              <AssetBalanceText.DirectWallet
                vaultId={directSourceWallet?.vaultMetadata.id}
                walletId={assetWallet?.id}
              />
            }
            name="assetWallet"
            parentWalletId={directSourceWallet?.parentWalletId}
            placeholder={t`forms:combobox.vault.internalWallets.asset.placeholder`}
            walletId={selectedWalletId}
          />
          <FrozenWalletAlert className="mt-6" vaultWallet={assetWallet} />
        </>
      )}

      <VaultForms.Combobox.InternalWallets
        className="mt-6"
        disabled={!derivedAssetSymbol}
        filter={destinationFilter}
        label={t`forms:combobox.vault.internalWallets.destination.label`}
        name="destination"
        placeholder={t`forms:combobox.vault.internalWallets.destination.placeholder`}
        variation="destination"
      />

      {/* When sending USDC, ensure we display any relevant blockchain alerts. */}
      {derivedAssetSymbol === Currency.USDC && directSourceWallet && (
        <BlockchainAlert
          blockchain={directSourceWallet?.blockchain}
          className="mt-6"
        />
      )}

      <Center className="pt-8" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          disabled={
            !isValid || (Boolean(directSourceWallet) && assetWallet?.frozen)
          }
          variant="primary"
        >
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { useMemo, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useWatchlistTypesQuery, WatchlistTypes } from '@shared/graphql'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { WatchLists } from '@shared/graphql'

export const typesSchema = y
  .string()
  .oneOf(Object.values(WatchlistTypes))
  .required()

type ComboboxProps = React.ComponentProps<typeof Combobox>

export interface TypesProps
  extends Omit<ComboboxProps, 'items' | 'locale' | 'onInputChange'> {
  /**
   * ClassName.
   */
  className?: string
  /**
   * `allow` or `block` variant because the list of types is different between the two.
   */
  variant: WatchLists
}

const Combobox = createFormCombobox()

export const Types: React.FC<TypesProps> = ({
  variant,
  className,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const [search, setSearch] = useState<string>('')

  const { data, loading } = useWatchlistTypesQuery({
    variables: {
      variant,
    },
  })

  const list = useMemo(
    () =>
      (data?.watchlistTypes ?? []).map(({ value, header }) => ({
        value,
        header,
        label: header
          ? t(`dropdown.watchlist.types.headers.${value}`)
          : t(`dropdown.watchlist.types.options.${value}`),
      })),
    [data, t],
  )

  const filteredItems = useMemo(() => {
    if (search) {
      return list.filter(({ label }) => label.match(new RegExp(search, 'gi')))
    }

    return undefined
  }, [search, list])

  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{
              searchTerm: inputValue,
            }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
    }),
    [t],
  )

  return (
    <SkeletonBox
      className={classNames('mt-6 w-full h-10', className)}
      loading={loading}
    >
      <Combobox
        {...props}
        className={classNames('w-full', className)}
        filtered={filteredItems}
        items={list}
        label={t`dropdown.watchlist.types.label`}
        locale={locale}
        onInputChange={setSearch}
      />
    </SkeletonBox>
  )
}

Types.displayName = 'Types'

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Custody settings mutation.
 * Can be used to guard wallet security page.
 *
 * VAULT_MANAGEMENT_ROLE is disabled in env except local.
 */
export const CUSTODY_SETTINGS_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: FeatureSwitch.vaultCustodyEnabled()
    ? [
        Role.ACCOUNT_WEB_ROLE,
        Role.AGENT_ACCOUNT_ROLE,
        Role.VAULT_MANAGEMENT_ROLE,
      ]
    : [Role.ACCOUNT_WEB_ROLE, Role.AGENT_ACCOUNT_ROLE],
}

import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useSegment, SegmentEvents } from '@services/segment'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import type { LinkProps } from 'next/dist/client/link'

export interface ViewAllProps extends LinkProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const ViewAll: React.FC<ViewAllProps> = ({ children, ...linkProps }) => {
  const { track } = useSegment()
  const { t } = useTranslation('common')

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      track(SegmentEvents.ViewAllClicked, {
        event: e,
      })
    },
    [track],
  )

  return (
    <Link {...linkProps} legacyBehavior passHref>
      <a onClick={onClick}>
        {children ?? t`view-all`}
        <Icon className="ml-2 inline" name="ArrowNarrowRightSolid" size={16} />
      </a>
    </Link>
  )
}

import { useCallback } from 'react'

import { TableFilters } from '@circlefin/components'
import { vaultTransactionDetailsSection } from '@services/sections/lib/vaults'
import { VAULT_TRANSACTIONS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { ComplexTable, TableCard } from '@shared/components/tables'
import { Filters } from '@shared/components/tables/TableCard/Filters/Filters'
import { useVaultTransactionsLazyQuery } from '@shared/graphql'
import useIsMounted from 'ismounted'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { VaultFilter } from '../../filters'

import { useVaultTransactionsTableColumns } from './VaultTransactionsTable.Columns'

import type {
  onFetchDataProps,
  Row,
} from '@circlefin/components/lib/AdvancedTable'
import type { FilterDateRangeValue } from '@circlefin/components/lib/TableFilters/types'
import type { VaultTransaction, VaultTransactionType } from '@shared/graphql'
const { Body } = TableCard

export interface TableFilterProps {
  /**
   * Transaction type.
   */
  types?: VaultTransactionType[]
  /**
   * Create Date Range Filter.
   */
  createDate?: FilterDateRangeValue
}

export interface VaultTransactionsTableProps {
  /*
   * Vault ID.
   */
  vaultId?: string
  /*
   * Wallet ID.
   */
  walletId?: string
  /**
   * Controls the number of lines in the table for each paginated page.
   */
  pageSize?: number
  /**
   * Filters that are applied to the data.
   */
  filters: TableFilterProps
  /**
   * Callback that will be invoked, whenever the table filter changed.
   */
  onFilterChange: (filters: TableFilterProps) => void
}

export const VaultTransactionsTable: React.FC<VaultTransactionsTableProps> = ({
  vaultId,
  walletId,
  pageSize = 10,
  filters,
  onFilterChange,
}) => {
  const { t } = useTranslation('vaults')
  const router = useRouter()
  const columns = useVaultTransactionsTableColumns()

  const [fetchTransactions, { called, data, loading, error, refetch }] =
    useVaultTransactionsLazyQuery({
      variables: { page: { pageSize } },
      pollInterval: VAULT_TRANSACTIONS_POLL_INTERVAL,
      fetchPolicy: 'cache-and-network',
    })

  const isMounted = useIsMounted()

  const onRowClick = useCallback(
    ({ original }: Row<VaultTransaction>) => {
      void router.push({
        pathname: vaultTransactionDetailsSection.route,
        query: {
          id: original.id,
        },
      })
    },
    [router],
  )

  const handleFetchData = useCallback(
    ({
      currentPageLink,
      filters: tableFilters,
    }: onFetchDataProps<VaultTransaction, TableFilterProps>) => {
      const { from, to } = tableFilters.createDate ?? {}

      if (isMounted.current) {
        void fetchTransactions({
          variables: {
            vaultId,
            walletId,
            transactionTypes: tableFilters.types,
            page: currentPageLink
              ? {
                  pageSize: currentPageLink.pageSize,
                  from: currentPageLink.from,
                  to: currentPageLink.to,
                  pageAfter: currentPageLink.pageAfter,
                  pageBefore: currentPageLink.pageBefore,
                }
              : { pageSize, to, from },
          },
        })
      }
    },
    [fetchTransactions, isMounted, pageSize, vaultId, walletId],
  )

  return (
    <TableCard>
      <Filters onChange={onFilterChange} size={10}>
        <TableFilters.DateRange
          name="createDate"
          size={3}
          start={6}
          isClearable
        />
        <VaultFilter.TransactionType name="types" size={2} />
      </Filters>
      <Body>
        <ComplexTable<VaultTransaction, TableFilterProps>
          columns={columns}
          data={data?.vaultTransactions}
          empty={{ subtitle: t`transactionsTable.emptyTableLabel` }}
          enableSorting={false}
          error={error}
          filters={filters}
          loading={!called || (!data && loading)}
          onFetchData={handleFetchData}
          onRowClick={onRowClick}
          pageSize={pageSize}
          pagination="links"
          retry={refetch}
          manualFiltering
        />
      </Body>
    </TableCard>
  )
}

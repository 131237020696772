import { createContext } from 'react'

import type { Currency } from '@shared/graphql'

export interface RedemptionOnlyExpressContextProps {
  /**
   * Show the setup Express banner.
   */
  showSetupExpressBanner: boolean
  /**
   * Whether ROLR user can redeem through Express.
   */
  canRedeem: (currency: Currency) => boolean
}

export const RedemptionOnlyExpressContext =
  createContext<RedemptionOnlyExpressContextProps>({
    showSetupExpressBanner: false,
    canRedeem: () => true,
  })

import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { AutoPay, PayInvoice, PaymentMethod } from '@features/billing/modals'
import { billing } from '@services/sections/modal/account/billing/billing'

/**
 * Account: Billing Router.
 */
export const BillingRouter: React.FC = () => {
  return (
    <>
      {/* AutoPay */}
      <ModalRoute content={<AutoPay.Enable />} path={billing.autoPay.enable} />

      {/* Default Payment Method */}
      <ModalRoute
        content={<PaymentMethod.SetDefault.SetDefault />}
        path={billing.paymentMethod.setDefault}
      />

      {/* Pay Invoice routes */}
      <ModalSwitch path={billing.payInvoice.switch}>
        <PayInvoice.PayInvoiceProvider>
          <ModalRoute
            content={<PayInvoice.PaymentMethodSelection />}
            path={billing.payInvoice.paymentMethodSelection}
          />
          <ModalRoute
            content={<PayInvoice.ConfirmPayment />}
            path={billing.payInvoice.confirmPayment}
          />
        </PayInvoice.PayInvoiceProvider>
      </ModalSwitch>

      {/* Add Merchant Credit Card */}
      <ModalSwitch path={billing.paymentMethod.addCreditCard.switch}>
        <PaymentMethod.AddCreditCard.AddCreditCardProvider>
          <ModalRoute
            content={<PaymentMethod.AddCreditCard.Details />}
            path={billing.paymentMethod.addCreditCard.details}
          />
          <ModalRoute
            content={<PaymentMethod.AddCreditCard.HolderDetails />}
            path={billing.paymentMethod.addCreditCard.holderDetails}
          />
        </PaymentMethod.AddCreditCard.AddCreditCardProvider>
      </ModalSwitch>
    </>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Stablecoin Express Query Permission.
 */
export const STABLECOIN_EXPRESS_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.STABLECOIN_EXPRESS_ROLE,
    Role.STABLECOIN_EXPRESS_READ_ROLE,
    Role.STABLECOIN_EXPRESS_MINT_ROLE,
    Role.STABLECOIN_EXPRESS_BURN_ROLE,
  ],
}

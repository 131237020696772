import { useCallback } from 'react'

import { Icon, TextLink } from '@circlefin/components'
import { support } from '@services/sections/lib/home'
import { useSegment } from '@services/segment'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../hooks/account-setup'

import { ARStepper } from './AR.Stepper'
import { FullStepper } from './Full.Stepper'
import { InviteeStepper } from './Invitee.Stepper'

import type { AccountSetupStep } from '../../../hooks/account-setup/context'

export interface ProgressShellProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Step that will be shown as active.
   */
  currentStep: AccountSetupStep
}

export const ProgressShell: React.FC<ProgressShellProps> = ({
  children,
  currentStep,
}) => {
  const { t } = useTranslation('onboard/account-setup')
  const { trackLinkClick } = useSegment()
  const [
    {
      accountSetupStates: {
        hasCompletedKybVerification,
        isOnboardingInitiator,
      },
    },
  ] = useAccountSetup()

  // Determine if step is complete based on index or current step.
  const isStepComplete = useCallback(
    (step: AccountSetupStep) => {
      const stepsOrder: AccountSetupStep[] = [
        'WELCOME',
        'CONFIRM_DETAILS',
        'CONFIRM_ROLE',
        'SECURITY_DOWNLOAD',
        'SECURITY_ACTIVATE',
      ]

      return stepsOrder.indexOf(currentStep) > stepsOrder.indexOf(step)
    },
    [currentStep],
  )

  return (
    <>
      <div className="flex grow flex-col-reverse gap-y-12 self-start pb-12 md:flex-row md:gap-x-13 md:pb-0">
        <div className="shrink-0">
          {hasCompletedKybVerification ? (
            <InviteeStepper
              currentStep={currentStep}
              isStepComplete={isStepComplete}
            />
          ) : isOnboardingInitiator ? (
            <FullStepper
              currentStep={currentStep}
              isStepComplete={isStepComplete}
            />
          ) : (
            <ARStepper
              currentStep={currentStep}
              isStepComplete={isStepComplete}
            />
          )}
        </div>
        <div className="md:max-w-144 md:grow">{children}</div>
      </div>

      <Link href={support.route} legacyBehavior passHref>
        <TextLink.Link
          className="absolute bottom-6 right-6"
          onClick={trackLinkClick}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t`common:contactUs`}
          <Icon name="ExternalLinkOutline" />
        </TextLink.Link>
      </Link>
    </>
  )
}

import useTranslation from 'next-translate/useTranslation'

import {
  BusinessServices,
  DeveloperPlatform,
  PersonalUse,
} from '../../../components'

export const PlatformSelect: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')

  return (
    <div className="relative flex size-full flex-col justify-between pt-12 md:pt-18">
      <div className="mx-auto mb-12 h-full max-w-232 space-y-12 px-6 md:px-4">
        {/* Heading */}
        <h2 className="text-center type-h-page-sm">{t`platformSelect.title`}</h2>

        {/* Selection */}
        <div className="flex flex-col gap-y-6 md:flex-row md:gap-x-6">
          <div className="m-auto flex shrink-0 flex-col gap-y-6 md:w-full md:max-w-lg">
            <DeveloperPlatform />
            <BusinessServices />
          </div>
        </div>
      </div>

      {/* Other Interest */}
      <PersonalUse />
    </div>
  )
}

import type { ComponentType, PropsWithChildren } from 'react'

import type { NextPageWithLayout } from '../../core'
import type { ApolloError } from '@apollo/client'

export type AppLayoutType<P = Record<string, unknown>> = ComponentType<
  PropsWithChildren<P>
>

export interface AppLayoutProps {
  /**
   * Apollo error?
   */
  error?: ApolloError
  /**
   * Page Component without Layout.
   */
  Component: NextPageWithLayout
  /**
   * Children with Apollo Error.
   */
  children: (error?: ApolloError) => JSX.Element
}

export const AppLayout = ({ children, error, Component }: AppLayoutProps) => {
  // Now we know the permission state of the user, we can proceed
  return <>{Component.getLayout(children(error))}</>
}

import { useMemo } from 'react'

import { Card, Icon, SkeletonBox } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useFactorQuery, FactorType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface ActiveFactorProps {
  /**
   * Factor Type.
   */
  factorType: FactorType
}

export const ActiveFactor: React.FC<ActiveFactorProps> = ({ factorType }) => {
  const { t } = useTranslation('mfa')
  const { date } = useDate()
  const { data, loading, error, refetch } = useFactorQuery({
    variables: { factorType },
  })

  const icon = useMemo(() => {
    switch (factorType) {
      case FactorType.PUSH:
        return 'Misc/OKTA'
      case FactorType.PIN_CODE:
        return 'LockClosedSolid'
      case FactorType.TOKEN_SOFTWARE_TOTP:
      default:
        return 'DeviceMobileSolid'
    }
  }, [factorType])

  const translationKey = useMemo(() => {
    switch (factorType) {
      case FactorType.PUSH:
        return 'okta-verify'
      case FactorType.PIN_CODE:
        return 'pin-code'
      case FactorType.TOKEN_SOFTWARE_TOTP:
      default:
        return 'authenticator-app'
    }
  }, [factorType])

  // If the factor does not exist for user, we shouldn't render anything
  if (!loading && !data?.factor) {
    return null
  }

  return (
    <li>
      <GraphQLErrorBoundary error={error} retry={refetch}>
        <SkeletonBox className="block h-28 w-full rounded-lg" loading={loading}>
          <Card data-testid="active-factor">
            <Card.Content className="flex items-center">
              <div className="flex min-w-0 flex-1 items-center">
                <Icon.Circle
                  className="bg-primary text-icon-primary"
                  name={icon}
                />
                <div className="grid min-w-0 flex-1 grid-rows-2 gap-1 px-4">
                  <div className="type-h-title-md">
                    {t(`factor.${translationKey}.name`)}
                  </div>
                  {data?.factor?.dateCreated && (
                    <p className="type-body-sm">
                      {t`added`}: {date(data.factor.dateCreated)}
                    </p>
                  )}
                </div>
              </div>
            </Card.Content>
          </Card>
        </SkeletonBox>
      </GraphQLErrorBoundary>
    </li>
  )
}

import { useCallback } from 'react'

import { Button, Icon } from '@circlefin/components'
import { useForm, y, useFieldArray } from '@circlefin/form'
import { SecurityForms } from '@features/security/forms'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  BlockEntityReason,
  useMerchantAchBankAccountQuery,
  WatchLists,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { TypeEntityProps } from '../Form.types'

export type AchTypeEntityFormValues = y.InferType<typeof schema>

const schema = y.object({
  /**
   * Reason for blocking.
   */
  reason: SecurityForms.Dropdown.Watchlist.blockReasonsSchema.required(),
  /**
   * Entries for blocking.
   */
  entries: y.array().of(
    y.object({
      /**
       * Entry Type.
       */
      type: SecurityForms.Dropdown.Watchlist.typesSchema.required(),
      /**
       * Entry Entity.
       */
      entity: y.string().required(),
    }),
  ),
})

export const AchTypeEntity: React.FC<TypeEntityProps> = ({
  source,
  onSubmit,
}) => {
  const { t } = useTranslation('platform/modals')

  const { data, error, refetch } = useMerchantAchBankAccountQuery({
    variables: {
      accountId: source.id,
    },
  })

  const [Form, { control }] = useForm<AchTypeEntityFormValues>({
    schema,
    values: {
      reason: BlockEntityReason.fraud,
      entries: data?.merchantAchBankAccount.pairEntries ?? [],
    },
  })

  const { fields, remove, append } = useFieldArray({ control, name: 'entries' })

  /**
   * Because we have more than just the form values to send,
   * we are using this submitHandler to gather them before
   * executing the provided callback.
   */
  const submitHandler = useCallback(
    ({ reason, entries }: AchTypeEntityFormValues) => {
      onSubmit(entries?.map(({ type, entity }) => ({ type, entity, reason })))
    },
    [onSubmit],
  )

  const handleAppend = useCallback(() => {
    // @ts-expect-error we want empty record
    append({})
  }, [append])

  const handleRemove = useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      <Form className="flex flex-col gap-y-6" onSubmit={submitHandler}>
        {fields.map((field, index) => (
          <div key={field.id} className="flex content-start gap-4">
            <SecurityForms.Dropdown.Watchlist.Types
              name={`entries.${index}.type`}
              variant={WatchLists.block}
            />
            <SecurityForms.Dropdown.Watchlist.Entities
              name={`entries.${index}.entity`}
              type={field.type}
            />
            <Button
              className="mt-6 shrink-0"
              label={t`common:remove`}
              onClick={handleRemove(index)}
              variant="secondary"
              iconOnly
            >
              <Icon data-testid="remove-button" name="XSolid" />
            </Button>
          </div>
        ))}
        {/* TODO: when adding a new pair, we have to filter the list, displaying only
        the types that could be associated with the transaction source type.
        Follow up ticket: https://circlepay.atlassian.net/browse/BRAAV-10941 */}
        <div>
          <Button onClick={handleAppend} size="sm" variant="text">
            {t`payment.block.addEntity`}
          </Button>
        </div>
        <hr className="mt-6 h-px border-black-100" />
        <div className="flex gap-8">
          <div className="mb-12 mt-8 w-1/2">
            <SecurityForms.Dropdown.Watchlist.BlockReasons name="reason" />
          </div>
        </div>
        <Form.SubmitButton
          className="mx-auto w-64"
          data-testid="button-submit"
          variant="secondary"
        >
          {t`payment.block.cta`}
        </Form.SubmitButton>
      </Form>
    </GraphQLErrorBoundary>
  )
}

AchTypeEntity.displayName = 'AchTypeEntity'

import { BlockchainRecipientAddressOwner } from '@shared/graphql'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { BlockchainRecipientAddressWithMetadata } from '@shared/graphql'

/**
 * Combobox address item.
 */
export type AddressItem = SelectListItem<BlockchainRecipientAddressWithMetadata>

interface GroupedAddresses {
  /**
   * Customer addresses.
   */
  customer: AddressItem[]
  /**
   * Non customer addresses.
   */
  nonCustomer: AddressItem[]
}

export const groupAddresses = (
  addresses?: BlockchainRecipientAddressWithMetadata[],
) => {
  const groupedAddresses: GroupedAddresses = {
    customer: [],
    nonCustomer: [],
  }

  return addresses
    ? addresses.reduce((addresses, item) => {
        const start = item.address.substring(0, 2)
        const end = item.address.substring(item.address.length - 6)
        const tag = `${start}*****${end}`
        const tagIcon = item.delayedWithdrawalInfo?.enabled
          ? 'ClockOutline'
          : undefined

        const addressItem: AddressItem = {
          value: item,
          label: item.description ?? '',
          icon: item.icon,
          tag,
          tagIcon,
        }

        if (item.owner === BlockchainRecipientAddressOwner.customer) {
          addresses.customer.push(addressItem)
        } else {
          addresses.nonCustomer.push(addressItem)
        }

        return addresses
      }, groupedAddresses)
    : groupedAddresses
}

import { TextLink } from '@circlefin/components'
import { controller } from '@circlefin/one-trust/core'
import { useSegment } from '@services/segment'
import classNames from 'classnames'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import PrivacyOptionsSVG from './images/PrivacyOptions.svg'

export interface FooterProps {
  /**
   * Custom class name.
   */
  className?: string
}

export const Footer = ({ className }: FooterProps) => {
  const { t } = useTranslation()
  const { trackLinkClick } = useSegment()

  return (
    <footer className={classNames('py-4 bg-neutral type-body-xs', className)}>
      <div className="mx-auto flex max-w-7xl flex-col-reverse items-center gap-y-4 px-6 lg:min-w-4xl lg:flex-row lg:px-16">
        <span className="text-center leading-4 text-neutral">
          {t('common:copyright', { currentYear: new Date().getFullYear() })}
        </span>
        <div className="flex flex-wrap justify-center gap-4 leading-4 lg:ml-auto">
          <TextLink.Link
            href={t`common:termsOfUse.link`}
            onClick={trackLinkClick}
            rel="noopener noreferrer"
            size="xs"
            variant="primary"
          >
            {t`common:termsOfUse.text`}
          </TextLink.Link>
          <TextLink.Link
            href={t`common:privacyPolicy.link`}
            onClick={trackLinkClick}
            rel="noopener noreferrer"
            size="xs"
            variant="primary"
          >
            {t`common:privacyPolicy.text`}
          </TextLink.Link>
          <TextLink
            onClick={controller.toggleInfoDisplay}
            size="xs"
            variant="primary"
          >
            {t`common:yourPrivacyChoices`}
            <Image
              alt={t`common:ccpaOptOutIcon`}
              className="inline self-center"
              src={PrivacyOptionsSVG}
            />
          </TextLink>
          <TextLink.Link
            href={t`common:cookiePolicy.link`}
            onClick={trackLinkClick}
            rel="noopener noreferrer"
            size="xs"
            variant="primary"
          >
            {t`common:cookiePolicy.text`}
          </TextLink.Link>
        </div>
      </div>
    </footer>
  )
}

import { useCallback, useMemo } from 'react'

import { NetworkStatus } from '@apollo/client'
import { ComplexTable } from '@shared/components/tables'
import { useInternalAddressesQuery } from '@shared/graphql'
import useIsMounted from 'ismounted'

import { useTableColumns } from './CircleAddressesTable.Columns'

import type { Row } from '@circlefin/components/lib/AdvancedTable'
import type { InternalAddress } from '@shared/graphql'

export interface CircleAddressesTableProps {
  /**
   * Selected rows to initialize.
   */
  initialSelected?: InternalAddress[]
  /**
   * Will be called when the user selects addresses from the table.
   */
  onRowSelect?: (addresses: InternalAddress[]) => void
  /**
   * Excluded vault ids.
   */
  excludedVaultIds?: string[]
}

export const CircleAddressesTable: React.FC<CircleAddressesTableProps> = ({
  initialSelected = [],
  onRowSelect,
  excludedVaultIds,
}) => {
  const columns = useTableColumns()
  const isMounted = useIsMounted()

  const { data, error, refetch, networkStatus } = useInternalAddressesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        excludedVaultIds,
      },
    },
  })

  const getRowId = useCallback((row: InternalAddress) => row.id, [])

  const initialSelectedAddresses: Record<string, boolean> = useMemo(
    () =>
      initialSelected.reduce(
        (prev, curr) => ({
          ...prev,
          [getRowId(curr)]: true,
        }),
        {},
      ),
    [getRowId, initialSelected],
  )

  const handleRowSelect = useCallback(
    (rows: Array<Row<InternalAddress>>) => {
      if (isMounted.current) {
        const selected = rows.map((row) => row.original)
        onRowSelect?.(selected)
      }
    },
    [isMounted, onRowSelect],
  )

  return (
    <ComplexTable<InternalAddress>
      columns={columns}
      data={data?.internalAddresses}
      enableSorting={false}
      error={error}
      getRowId={getRowId}
      initialState={{
        rowSelection: initialSelectedAddresses,
      }}
      loading={networkStatus === NetworkStatus.loading}
      onSelectedRowsChange={handleRowSelect}
      retry={refetch}
      selectionType="page"
      enableRowSelection
    />
  )
}

import { useMemo } from 'react'

import { Card, Chip, SkeletonBox, DescriptionList } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { useDate, useMoney } from '@circlefin/hooks'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { BLOCKCHAIN_ADDRESS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { TransWithLink } from '@shared/components/common'
import {
  useBlockchainRecipientAddressWithMetadataQuery,
  useIdentityChallengeQuery,
  BlockchainAbbreviation,
  BlockchainAddressStatus,
  BlockchainRecipientAddressOwner,
} from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { ManualAddressVerificationAlert } from '../../components/ManualAddressVerificationAlert/ManualAddressVerificationAlert'

import type { Locale } from '@circlefin/money/variants'

export interface AddressVerificationInstructionProps {
  /*
   * Source Address ID.
   */
  addressId: string
  /**
   * Custom Styles.
   */
  className?: string
}

const skeletonBoxStyle = 'h-3 w-56 my-1'

export const AddressVerificationInstruction: React.FC<AddressVerificationInstructionProps> =
  ({ addressId, className }) => {
    const { t } = useTranslation('payments')
    const { locale } = useRouter()
    const { money } = useMoney({ locale: locale as Locale })
    const { date } = useDate({ locale: locale as Locale })
    const { travelRuleRequired } = useTravelRuleRequired()

    const { data, stopPolling } =
      useBlockchainRecipientAddressWithMetadataQuery({
        variables: {
          id: addressId,
        },
        skip: !travelRuleRequired,
        pollInterval: BLOCKCHAIN_ADDRESS_POLL_INTERVAL,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
          if (
            data.blockchainRecipientAddressWithMetadata &&
            (data.blockchainRecipientAddressWithMetadata.status ===
              BlockchainAddressStatus.ACTIVE ||
              data.blockchainRecipientAddressWithMetadata?.chain ===
                BlockchainAbbreviation.XLM)
          ) {
            stopPolling()
          }
        },
      })
    const addressData = data?.blockchainRecipientAddressWithMetadata
    const addressPendingVerification = useMemo(
      () =>
        addressData?.owner === BlockchainRecipientAddressOwner.customer &&
        addressData?.status === BlockchainAddressStatus.PENDING_VERIFICATION,
      [addressData?.status, addressData?.owner],
    )
    const isStellarChain = useMemo(
      () => addressData?.chain === BlockchainAbbreviation.XLM,
      [addressData?.chain],
    )

    const challenge = useIdentityChallengeQuery({
      variables: {
        addressId,
      },
      skip: !addressPendingVerification,
    })
    const challengeData = challenge.data?.identityChallenge

    // Return null if address status is not pending_verification so we don't show challenge in the wallet details page
    if (!travelRuleRequired || !addressPendingVerification) {
      return null
    }

    return (
      <Card className={className}>
        <Card.Content className="flex flex-col gap-3">
          <Chip data-testid="status-chip" variant="status/info">
            {t`addressVerificationInstruction.status`}
          </Chip>

          {!isStellarChain && (
            <h2 className="type-h-page-sm">{t`addressVerificationInstruction.title`}</h2>
          )}
          <ManualAddressVerificationAlert visible={isStellarChain} />
          <p className="type-intro-sm">{t`addressVerificationInstruction.instruction`}</p>

          <DescriptionList density="loose">
            {/* Amount */}
            <DescriptionList.Label className="w-64">
              {t`addressVerificationInstruction.amount`}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <SkeletonBox
                className={skeletonBoxStyle}
                loading={challenge.loading}
              >
                {challengeData &&
                  money({
                    number: Number(challengeData.amount.amount),
                    variant: challengeData.amount.currency,
                  })}
              </SkeletonBox>
            </DescriptionList.Description>

            {/* From Wallet */}
            <DescriptionList.Label className="w-64">
              {t`addressVerificationInstruction.fromWallet`}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <SkeletonBox
                className={skeletonBoxStyle}
                loading={challenge.loading}
              >
                <CopyToClipboard
                  className="mt-3 w-48"
                  value={addressData?.address ?? ''}
                />
              </SkeletonBox>
            </DescriptionList.Description>

            {/* To Wallet */}
            <DescriptionList.Label className="w-64">
              {t`addressVerificationInstruction.toWallet`}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <SkeletonBox
                className={skeletonBoxStyle}
                loading={challenge.loading}
              >
                <CopyToClipboard
                  className="mt-3 w-48"
                  value={challengeData?.destinationAddress ?? ''}
                />
              </SkeletonBox>
            </DescriptionList.Description>

            {/* Memo */}
            {isStellarChain && challengeData?.paymentId && (
              <>
                <DescriptionList.Label className="w-64">
                  {t`addressVerificationInstruction.memo`}
                </DescriptionList.Label>
                <DescriptionList.Description>
                  <SkeletonBox
                    className={skeletonBoxStyle}
                    loading={challenge.loading}
                  >
                    <CopyToClipboard
                      className="mt-3 w-48"
                      value={challengeData.paymentId}
                    />
                  </SkeletonBox>
                </DescriptionList.Description>
              </>
            )}

            {/* Chain */}
            <DescriptionList.Label className="w-64">
              {t`addressVerificationInstruction.chain`}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <SkeletonBox
                className={skeletonBoxStyle}
                loading={challenge.loading}
              >
                {challengeData?.chain != null
                  ? t(`payments:chainNames.${challengeData.chain}`)
                  : ''}
              </SkeletonBox>
            </DescriptionList.Description>

            {/* Expiry date */}
            <DescriptionList.Label className="w-64">
              {t`addressVerificationInstruction.expiry`}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <SkeletonBox
                className={skeletonBoxStyle}
                loading={challenge.loading}
              >
                {challengeData &&
                  t('addressVerificationInstruction.timeLimit', {
                    expiryDate: date(challengeData.expiryDate),
                  })}
              </SkeletonBox>
            </DescriptionList.Description>
          </DescriptionList>

          <p className="type-body-sm">
            {isStellarChain ? (
              t`addressVerificationInstruction.shortNote`
            ) : (
              <TransWithLink
                i18nKey="payments:addressVerificationInstruction.note"
                urlI18nKey="payments:addressVerificationInstruction.url"
                variant="url"
              />
            )}
          </p>
        </Card.Content>
      </Card>
    )
  }

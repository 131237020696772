import { useContext } from 'react'

import { MfaContext } from './lib/context'

export const useMfa = () => {
  const {
    open,
    success: mfaSuccess,
    error: mfaError,
    context,
  } = useContext(MfaContext)

  return [open, { mfaSuccess, mfaError, context }] as const
}

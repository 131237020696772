import { getRouteRegex } from 'next/dist/shared/lib/router/utils/route-regex'

import { accountSetupSection } from './lib/accountSetup'
import { addressBook } from './lib/address-book'
import { apiSection } from './lib/api'
import { circleMintInviteSection } from './lib/circleMintInvite'
import { custodySection } from './lib/custody'
import { developerSection } from './lib/developer'
import { expressSection } from './lib/express'
import { getStartedSection } from './lib/getStarted'
import { homeSection } from './lib/home'
import { inAppNotificationsSection } from './lib/inAppNotifications'
import { onboardingSection } from './lib/onboarding'
import { platformSection } from './lib/platform'
import { resetAuthenticatorSection } from './lib/resetAuthenticator'
import { resetPinCodeSection } from './lib/resetPinCode'
import { settingsSection } from './lib/settings'
import { signupSection } from './lib/signup'
import { vaultsSection } from './lib/vaults'
import { verifyEmailSection } from './lib/verifyEmail'

import type { Section } from './types'

/**
 * Collection of all the sections.
 */
export const sections: Section[] = [
  accountSetupSection,
  apiSection,
  developerSection,
  homeSection,
  getStartedSection,
  circleMintInviteSection,
  onboardingSection,
  custodySection,
  platformSection,
  resetAuthenticatorSection,
  resetPinCodeSection,
  settingsSection,
  signupSection,
  vaultsSection,
  verifyEmailSection,
  expressSection,
  addressBook,
  inAppNotificationsSection,
]

/**
 * Sections to display in top navigation.
 * Display in navigation in the order of the array.
 */
export const navSections: Section[] = [
  custodySection,
  inAppNotificationsSection,
  vaultsSection,
  platformSection,
  expressSection,
  addressBook,
  developerSection,
].filter(Boolean)

/**
 * Given a path, it will return the first section that matches the given path.
 * This path must be from a Next.js formatted path.
 *
 * Pass in `useRouter().pathname`.
 */
export function findSectionByPath(
  currentPath: Section['route'],
  currentSections: Section[] = sections,
): Section | null {
  for (const s of currentSections) {
    // Found matching route
    if (s.route === currentPath) {
      return s
    }

    // Search child pages
    if (Array.isArray(s.pages)) {
      const route = findSectionByPath(currentPath, s.pages)

      // Found matching route
      if (route) {
        return {
          // add team from parent section if does not exist on route
          team: route.team ?? s.team,
          ...route,
        }
      }
    }
  }
  return null
}

/**
 * Find Dynamic Section Path.
 */
export function findDynamicSectionByPath(
  currentPath: Section['route'],
  currentSections: Section[] = sections,
): Section | null {
  for (const s of currentSections) {
    // Get Dynamic Route Regex
    const route = getRouteRegex(s.route)

    // Match Dynamic Route Regex to a current path
    if (route.re.test(currentPath)) {
      return s
    }

    // Search child pages
    if (Array.isArray(s.pages)) {
      const route = findDynamicSectionByPath(currentPath, s.pages)

      // Found matching route
      if (route) {
        return route
      }
    }
  }
  return null
}

/**
 * Check if pathname is part of the section.
 */
export function isPartOfSection(
  pathname: string,
  section: Section,
  exactMatch?: boolean,
): boolean {
  // If exactMatch, we don't look at the subpages/sections of a given section and do an
  // exact page of the pathname and route
  if (exactMatch) return pathname === section.route

  return (
    (pathname === section.route ||
      section.pages?.some(
        (page) =>
          pathname === page.route || page?.matchRoute?.includes(pathname),
      )) ??
    false
  )
}

/**
 * Dynamic route: replace dynamic parameters w/ values.
 * @param route - The route that contains dynamic slug (i.e. `/[activation]`).
 * @param parameters - Obj of paramName =\> paramValue pairs.
 * @throws
 */
export function dynamicRoute(
  route: string,
  parameters: Record<string, string>,
): string {
  const paramArray = route.match(/(?<=\[)[^\][]*(?=])/g)

  if (paramArray === null) {
    throw new Error('Route contains no dynamic parameters')
  }

  for (const param of paramArray) {
    if (!Object.keys(parameters).includes(param)) {
      throw new Error(`Parameters object is missing key: ${param}`)
    }
  }

  for (const key in parameters) {
    if (!route.includes(`[${key}]`)) {
      throw new Error(`Route is missing parameter: ${key}`)
    }

    route = route.replace(`[${key}]`, parameters[key])
  }

  return route
}

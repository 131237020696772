import { useCallback } from 'react'

import { ButtonCard, Icon } from '@circlefin/components'
import { StablecoinExpressType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../../../AddRoute.Context'

export const DepositCard: React.FC = () => {
  const { t } = useTranslation('express/modal')
  const [, { setDirection }] = useAddRoute()

  const onClick = useCallback(() => {
    setDirection(StablecoinExpressType.DEPOSIT)
  }, [setDirection])

  return (
    <ButtonCard data-testid="deposit-card" onClick={onClick} hideArrow>
      <span className="mx-auto grid grid-cols-3 items-center gap-12">
        <span>
          <Icon.Circle
            className="mx-auto"
            intent="accent-blue"
            name="LibrarySolid"
            size="large"
          />
          <p className="mt-3 text-lg text-black-600 font-circular-medium">{t`addRoute.direction.bank`}</p>
        </span>

        <span className="items-center">
          <Icon
            className="inline-block text-black-200"
            name="ArrowNarrowRightSolid"
            size={38}
          />
        </span>

        <span className="items-center">
          <Icon.Circle
            className="mx-auto"
            intent="accent-green"
            name="LinkOutline"
            size="large"
          />
          <p className="mt-3 text-lg text-black-600 font-circular-medium ">
            {t`addRoute.direction.blockchain`}
          </p>
        </span>
      </span>
    </ButtonCard>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * User management query permission.
 * Allowed users to see the "Manage Users" page and table data.
 */
export const USER_MANAGEMENT_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.USER_MANAGEMENT_ROLE],
}

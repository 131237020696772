import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { TransWithLink } from '../../common'

import type { StatementProps } from '@circlefin/components/lib/Statement'

type Required<T> = {
  /**
   * Convert Optional<T> to Required<T>.
   */
  [P in keyof T]-?: T[P]
}

export interface PropsErrorBoundaryProps<QueryProps>
  extends Pick<StatementProps, 'variant' | 'status'> {
  /**
   * Check if props are valid.
   */
  props: QueryProps
  /**
   * Callback children with valid props.
   */
  children: (props: Required<QueryProps>) => React.ReactElement
}

/**
 * Type Guard.
 */
function isDefined<QueryProps>(
  props: QueryProps,
): props is Required<QueryProps> {
  // Check if at least one property is undefined, if so, return false.
  // @ts-expect-error todo: fix the type issue here
  return !Object.values(props).some((prop) => prop === undefined)
}

/**
 * Props Type Guard Error Boundary.
 * @returns
 */
export function PropsErrorBoundary<QueryProps>({
  props,
  variant,
  children,
  status = 'warning',
}: PropsErrorBoundaryProps<QueryProps>): React.ReactElement {
  const { t } = useTranslation('common')

  const refresh = useCallback(() => {
    window.location.reload()
  }, [])

  // Check if all props are defined.
  if (isDefined<QueryProps>(props)) {
    // Pass valid props to children.
    return children(props)
  }

  // Fallback to error.
  return (
    <div className="h-full" data-testid="props-error-boundary">
      <Statement
        status={status}
        subtitle={
          <TransWithLink
            i18nKey="common:missingPropsError.subtitle"
            variant="internal-support"
          />
        }
        title={t`missingPropsError.title`}
        variant={variant}
      >
        <Button
          data-testid="refresh-button"
          onClick={refresh}
          variant="primary"
        >{t`missingPropsError.cta`}</Button>
      </Statement>
    </div>
  )
}

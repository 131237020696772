import { Modal } from '@circlefin/components'

export interface BaseHeaderLayoutProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Modal Title.
   */
  title: string
  /**
   * Custom class.
   */
  className?: string
}

/**
 * BaseHeader Modal Layout.
 */
export const BaseHeader: React.FC<BaseHeaderLayoutProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <>
      <Modal.Header title={title} />
      <Modal.Body className={className}>{children}</Modal.Body>
    </>
  )
}

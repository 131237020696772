import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { WalletForms } from '@features/wallets/forms'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { User, VaultWalletConnectPolicy } from '@shared/graphql'

const schema = y.object({
  /**
   * List of operators in policy.
   */
  operators: WalletForms.Combobox.policyApproverSchema.shape({
    selected: y
      .array(y.mixed<User>().required())
      .min(1, { key: 'createVault.shared.form.validation.users' })
      .required(),
  }),
  /**
   * List of approvers in policy.
   */
  approvers: WalletForms.Combobox.policyApproverSchema.shape({
    selected: y.array(y.mixed<User>().required()),
  }),
  /**
   * Min number of approvers to confirm transfers.
   */
  minNeedConfirmed: y
    .string()
    .numeric()
    .when('approvers.selected', {
      is: (selected: User[] = []) => selected.length > 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
})

type PolicyFormValues = y.InferType<typeof schema>

export interface PolicyFormProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Policy information.
   */
  policy?: VaultWalletConnectPolicy
  /**
   * List of all operators.
   */
  operators: VaultWalletConnectPolicy['operators']
  /**
   * List of all approvers.
   */
  approvers: VaultWalletConnectPolicy['approvers']
  /**
   * OnChange handler.
   */
  onChange?: (values: VaultWalletConnectPolicy) => void
  /**
   * OnSubmit handler.
   */
  onSubmit?: (values: VaultWalletConnectPolicy) => void
}

export const PolicyForm: React.FC<PolicyFormProps> = ({
  className,
  policy,
  operators,
  approvers,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation('modals.vault')

  const [Form, { watch, setValue, resetField, formState }] =
    useForm<PolicyFormValues>({
      schema,
      defaultValues: {
        operators: {
          selected: policy?.operators ?? [],
        },
        approvers: {
          selected: policy?.approvers ?? [],
        },
        minNeedConfirmed: policy?.minNeedConfirmed ?? '',
      },
    })

  const handleSubmit = useCallback(
    (values: PolicyFormValues) => {
      onSubmit?.({
        operators: values.operators.selected,
        approvers: values.approvers.selected ?? [],
        minNeedConfirmed: values.minNeedConfirmed ?? '',
      })
    },
    [onSubmit],
  )

  const handleFormChange: React.FormEventHandler<HTMLFormElement> =
    useCallback(() => {
      onChange?.({
        operators: watch('operators.selected') ?? [],
        approvers: watch('approvers.selected') ?? [],
        minNeedConfirmed: watch('minNeedConfirmed') ?? '',
      })
    }, [onChange, watch])

  const handleSelectedOperatorsChange = useCallback(
    (updatedSelected: User[]) => {
      setValue('operators.selectedApprover', undefined)
      setValue('operators.selected', updatedSelected)
    },
    [setValue],
  )

  const handleSelectedApproversChange = useCallback(
    (updatedSelected: User[]) => {
      if (Number(watch('minNeedConfirmed')) > updatedSelected.length) {
        // the user has selected a `minNeedConfirmed` value greater than the updated approver list count
        resetField('minNeedConfirmed', {
          keepDirty: false,
          keepTouched: false,
          keepError: false,
          defaultValue: '',
        })
      }

      setValue('approvers.selectedApprover', undefined)
      setValue('approvers.selected', updatedSelected)
    },
    [resetField, setValue, watch],
  )

  return (
    <Form
      className={classNames('grid grid-cols-1 gap-y-6 mt-6 w-full', className)}
      onChange={handleFormChange}
      onSubmit={handleSubmit}
    >
      <WalletForms.Combobox.PolicyApprovers
        approvers={operators}
        className="w-full"
        label={t`forms:combobox.vault.walletConnectOperators.label`}
        name="operators.selectedApprover"
        noItemsMessage={t`forms:combobox.vault.walletConnectOperators.noItemsMessage`}
        onSelectedChange={handleSelectedOperatorsChange}
        selected={watch('operators.selected') ?? []}
        hideRoleText
      />

      <WalletForms.Combobox.PolicyApprovers
        approvers={approvers}
        className="w-full"
        label={t`forms:combobox.vault.walletConnectApprovers.label`}
        name="approvers.selectedApprover"
        noItemsMessage={t`forms:combobox.vault.walletConnectApprovers.noItemsMessage`}
        onSelectedChange={handleSelectedApproversChange}
        selected={watch('approvers.selected') ?? []}
        hideRoleText
      />

      <div className="flex h-full items-center">
        <Form.Dropdown
          className="w-64"
          data-testid="dropdown-num-approvers"
          items={(watch('approvers.selected') ?? []).map((_, index) => ({
            label: `${index + 1}`,
            value: `${index + 1}`,
          }))}
          label={t('createVault.shared.form.minNeedConfirmed.label')}
          name="minNeedConfirmed"
          placeholder="-"
        />
        <span
          className={classNames(
            'text-sm font-circular-book leading-5 text-neutral-subtle ml-3 mt-5',
            {
              'mb-6':
                formState.errors.minNeedConfirmed &&
                formState.touchedFields.minNeedConfirmed &&
                !watch('minNeedConfirmed'),
            },
          )}
        >
          {t('createVault.shared.form.minNeedConfirmed.count', {
            count: (watch('approvers.selected') ?? []).length,
          })}
        </span>
      </div>

      <Form.SubmitButton className="w-32" variant="primary">
        {t('common:done')}
      </Form.SubmitButton>
    </Form>
  )
}

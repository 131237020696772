import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import {
  AddAddress,
  RemoveAddress,
  EditRecipient,
  VaultsEnabled,
  VerifyAddress,
  ApprovalRequest,
} from '@features/address-book/modals'
import { addressBook } from '@services/sections/modal/account/address-book/address-book'

/**
 * Account: Address Book Router.
 */
export const AddressBookRouter: React.FC = () => {
  return (
    <>
      {/* Add Address */}
      <ModalRoute
        content={<AddAddress.SelectRecipient />}
        path={addressBook.addNewAddress.selectRecipient}
      />
      <ModalRoute
        content={<AddAddress.AddressDetailsNonCustomer />}
        path={addressBook.addNewAddress.nonCustomer}
      />
      <ModalRoute
        content={<AddAddress.AddressDetailsCustomer />}
        path={addressBook.addNewAddress.customer}
      />
      <ModalRoute
        content={<AddAddress.OwnershipDisclaimer />}
        path={addressBook.addNewAddress.ownershipDisclaimer}
      />
      <ModalRoute
        content={<AddAddress.Error />}
        path={addressBook.addNewAddress.error}
      />
      <ModalRoute
        content={<ApprovalRequest.ApprovalRequest />}
        path={addressBook.approvalRequest}
      />

      {/* Verify Address */}
      <ModalRoute
        content={<VerifyAddress.OwnerType />}
        path={addressBook.verifyAddress.ownerType}
      />
      <ModalRoute
        content={<VerifyAddress.IdentityChallenge />}
        path={addressBook.verifyAddress.identityChallenge}
      />
      <ModalRoute
        content={<VerifyAddress.ThirdPartyWarning />}
        path={addressBook.verifyAddress.thirdPartyWarning}
      />

      {/* Add Address with Vaults Enabled */}
      <ModalSwitch path={addressBook.vaultsEnabled.addNewAddress.switch}>
        <VaultsEnabled.AddAddress.Provider>
          <ModalRoute
            content={<VaultsEnabled.AddAddress.SelectRecipient />}
            path={addressBook.vaultsEnabled.addNewAddress.selectRecipient}
          />
          <ModalRoute
            content={
              <VaultsEnabled.AddAddress.Default.AddressDetailsCustomer />
            }
            path={addressBook.vaultsEnabled.addNewAddress.default.customer}
          />
          <ModalRoute
            content={
              <VaultsEnabled.AddAddress.Default.AddressDetailsNonCustomer />
            }
            path={addressBook.vaultsEnabled.addNewAddress.default.nonCustomer}
          />
          <ModalRoute
            content={
              <VaultsEnabled.AddAddress.MultiAsset.AddressDetailsCustomer />
            }
            path={addressBook.vaultsEnabled.addNewAddress.multiAsset.customer}
          />
          <ModalRoute
            content={
              <VaultsEnabled.AddAddress.MultiAsset.AddressDetailsNonCustomer />
            }
            path={
              addressBook.vaultsEnabled.addNewAddress.multiAsset.nonCustomer
            }
          />
        </VaultsEnabled.AddAddress.Provider>
      </ModalSwitch>

      {/* Edit Recipient */}
      <ModalRoute
        content={<EditRecipient />}
        path={addressBook.editRecipient}
      />

      {/* Remove Address */}
      <ModalRoute
        content={<RemoveAddress.Confirmation />}
        path={addressBook.removeAddress.confirmation}
      />
    </>
  )
}

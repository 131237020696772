import { Icon, DescriptionList } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const ApproverLevels: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')

  const { condensed, approvalWorkflow } = usePolicySummaryContext()

  const isEmpty = !approvalWorkflow || approvalWorkflow.approvers.length === 0

  return (
    <>
      <Label className="h-full">
        {t('policy.summary.labels.approvalSteps')}
      </Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="approval-steps"
      >
        {isEmpty ? (
          <span>{t('policy.summary.approvers.noApprovalSteps')}</span>
        ) : (
          <List>
            <List.Item>
              <List.Item.Title
                stepName={WalletApprovalPolicyStep.APPROVAL}
                showEdit
              >
                {t('policy.summary.approvers.transactionApprovals')}
              </List.Item.Title>
              <List.Item.Subtitle>
                <div className="grid grid-cols-1">
                  <div className="flex items-center gap-x-2 text-neutral-strong type-body-base">
                    {t('policy.summary.approvers.allTransactions')}
                    <Icon
                      className="inline-block"
                      name="EllipseSolid"
                      size={4}
                    />

                    {t('policy.summary.approvers.required', {
                      count: Number(approvalWorkflow.minNeedConfirmed),
                    })}
                  </div>
                  <div>
                    {t('policy.summary.approvers.name', {
                      names: approvalWorkflow.approvers
                        .map((approver) => approver.name)
                        .join(', '),
                    })}
                  </div>
                </div>
              </List.Item.Subtitle>
            </List.Item>
          </List>
        )}
      </Description>
    </>
  )
}

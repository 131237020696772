import { useCallback, useMemo } from 'react'
import type { FieldError } from 'react-hook-form'

import { ValidationList } from '@circlefin/components'
import { mapErrorsToKeys, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import type { ValidationListProps } from '@circlefin/components/lib/ValidationList'

const validationItems = [
  'min',
  'hasLowercase',
  'hasUppercase',
  'hasNumber',
  'hasSpecialCharacter',
  'excludesEmail',
]

/**
 * Generates the password schema.
 * @param emailFieldName - The name of the email field that the password validates against.
 * @returns The password schema to merge with your main form schema.
 */
export const generatePasswordSchema = (emailFieldName: string) => {
  return y
    .string()
    .required()
    .min(10)
    .hasLowercase()
    .hasUppercase()
    .hasNumber()
    .hasSpecialCharacter()
    .when([emailFieldName], ([email]: string[], schema) =>
      email
        ? schema.excludesEmail({
            email,
          })
        : schema,
    )
}

export interface PasswordValidationListProps extends ValidationListProps {
  /**
   * If should show validation.
   */
  shouldShowValidation: boolean
  /**
   * Password input errors.
   */
  errors?: FieldError
}

// TODO: Try to wrap the PasswordInput component here when we don't depend on the
// useForm() hook for that in the future.
export const PasswordValidationList: React.FC<PasswordValidationListProps> = ({
  errors,
  shouldShowValidation,
  ...props
}) => {
  const { t } = useTranslation('common')

  const parsedErrors = useMemo(() => mapErrorsToKeys(errors), [errors])

  const isValid = useCallback(
    (name: string) => !parsedErrors.includes(name),
    [parsedErrors],
  )

  if (!shouldShowValidation) {
    return null
  }

  return (
    <ValidationList data-testid="validation-list" {...props}>
      {validationItems.map((item) => (
        <ValidationList.Item key={item} isValid={isValid(item)}>
          {t(`form.v2.validation.password.${item}`)}
        </ValidationList.Item>
      ))}
    </ValidationList>
  )
}

import { DescriptionList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainAbbreviation } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface WithdrawalProps {
  /**
   * Blockchain Abbreviation.
   */
  chain: BlockchainAbbreviation
}

export const Withdrawal: React.FC<WithdrawalProps> = ({ chain }) => {
  const { t } = useTranslation('express/modal')

  return (
    <DescriptionList
      className="min-w-160"
      data-testid="blockchain-withdrawal"
      size="sm"
    >
      <Label className="w-64">{t`addRoute.review.label.blockchain`}</Label>
      <Description>{chain}</Description>
    </DescriptionList>
  )
}

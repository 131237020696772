import type React from 'react'

import { Card, Chip, DescriptionList } from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { Currency, PayoutStatus } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { GraphQLErrorBoundary } from '../../../errors'
import { createPayoutFxRateString, currencyToToken } from '../helpers'

import type { ApolloError } from '@apollo/client'
import type { Payout } from '@shared/graphql'

export interface PayoutCardProps {
  /**
   * Payout object containing information about the transaction.
   */
  payout?: Payout
  /**
   * Apollo Error object in the event fetching payout data failed.
   */
  error?: ApolloError
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const PayoutCard: React.FC<PayoutCardProps> = ({
  payout,
  error,
  children,
}) => {
  const { money } = useMoney()
  const { t } = useTranslation('common')
  const { dateNumeric: formatDate } = useDate()
  const waitingForFxExecution = payout?.toAmount?.amount == null

  const sentAmount = money({
    number: payout?.toAmount?.amount ?? '',
    variant: payout?.toAmount?.currency ?? Currency.USD,
    options: {
      symbol: true,
      signDisplay: false,
    },
  })

  const redeemedAmount = money({
    number: payout?.amount?.amount ?? '',
    variant: currencyToToken(payout?.amount?.currency ?? Currency.USD),
    options: {
      symbol: true,
      signDisplay: false,
    },
  })
  const statusVariant = (() => {
    switch (payout?.status) {
      case PayoutStatus.complete:
        return 'status/success'
      case PayoutStatus.failed:
        return 'status/error'
      default:
        return 'status/neutral'
    }
  })()

  const shouldShowFxRate =
    payout?.toAmount != null &&
    payout?.toAmount.amount != null &&
    payout?.amount != null

  // This creates a string in the format of "$1 = 0.00058 MXN" to show the user their approx. FX rate.
  const fxRateString = createPayoutFxRateString({ payout, money })

  return (
    <Card>
      <Card.Content className="pb-0">
        <GraphQLErrorBoundary error={error}>
          <h2 className="pb-4 pl-4 type-h-title-md">
            {t('fxTransactionCard.details')}
          </h2>
          <DescriptionList className="pl-4">
            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.type')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <p className="type-body-base-bold">
                {t('fxTransactionCard.redeem')}
              </p>
            </DescriptionList.Description>

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.status')}
            </DescriptionList.Label>
            <DescriptionList.Description className="flex flex-col">
              <Chip variant={statusVariant}>
                <p className="capitalize type-body-base-bold">
                  {payout?.status}
                </p>
              </Chip>
              <p className="type-body-xs">
                {formatDate(payout?.updateDate ?? '')}
              </p>
            </DescriptionList.Description>

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.amountRedeemed')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <p className="type-body-base-bold">{redeemedAmount}</p>
            </DescriptionList.Description>

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.amountSent')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              {waitingForFxExecution ? (
                <p className="type-body-base">
                  {t('fxTransactionCard.waitingForFx')}
                </p>
              ) : (
                <p className="type-body-base-bold">{sentAmount}</p>
              )}
            </DescriptionList.Description>

            {shouldShowFxRate && (
              <>
                <DescriptionList.Label className="w-64">
                  {t('fxTransactionCard.fxRate')}
                </DescriptionList.Label>
                <DescriptionList.Description>
                  <p className="type-body-base-bold">{fxRateString}</p>
                </DescriptionList.Description>
              </>
            )}

            {payout?.fees != null && (
              <>
                <DescriptionList.Label className="w-64">
                  {t('fxTransactionCard.fees')}
                </DescriptionList.Label>
                <DescriptionList.Description>
                  <p className="type-body-base-bold">
                    {`${money({
                      number: payout.fees.amount,
                      variant: currencyToToken(payout.fees.currency),
                      options: {
                        symbol: false,
                      },
                    })} ${currencyToToken(payout.fees.currency)}`}
                  </p>
                </DescriptionList.Description>
              </>
            )}

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.sentTo')}
            </DescriptionList.Label>
            <DescriptionList.Description className="flex flex-col">
              <p className="type-body-base-bold">{payout?.destination?.name}</p>
              <p className="uppercase font-circular-light">
                {payout?.destination?.type}
              </p>
            </DescriptionList.Description>

            {children}
          </DescriptionList>
        </GraphQLErrorBoundary>
      </Card.Content>
    </Card>
  )
}

import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import {
  AdminApprovals,
  FraudManagement,
  IpAllowlisting,
} from '@features/security/modals'
import { security } from '@services/sections/modal/account/security/security'

/**
 * Account: Security Router.
 */
export const SecurityRouter: React.FC = () => {
  return (
    <>
      {/* Approval Settings */}
      <ModalRoute
        content={<AdminApprovals.Settings />}
        path={security.adminApprovals.settings}
      />

      <ModalRoute
        content={<AdminApprovals.Success />}
        path={security.adminApprovals.success}
      />

      {/* Fraud Management */}
      <ModalRoute
        content={<FraudManagement.AddToWatchList />}
        path={security.fraudManagement.addToWatchList.form}
      />
      <ModalRoute
        content={<FraudManagement.AddToWatchListSuccess />}
        path={security.fraudManagement.addToWatchList.success}
      />

      {/* Ip Allowlisting */}
      <ModalRoute
        content={<IpAllowlisting.ToggleSetting />}
        path={security.ipAllowlisting.toggleSetting}
      />
      <ModalRoute
        content={<IpAllowlisting.IpAddress.Remove />}
        path={security.ipAllowlisting.ipAddress.remove}
      />
      <ModalRoute
        content={<IpAllowlisting.IpAddress.Empty />}
        path={security.ipAllowlisting.ipAddress.empty}
      />

      <ModalSwitch path={security.ipAllowlisting.switch}>
        <IpAllowlisting.IpAddress.CreateProvider>
          <ModalRoute
            content={<IpAllowlisting.IpAddress.Create />}
            path={security.ipAllowlisting.ipAddress.create}
          />
        </IpAllowlisting.IpAddress.CreateProvider>
      </ModalSwitch>
    </>
  )
}

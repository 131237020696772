import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  BlockchainActivityType,
  useBlockchainsQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { BlockchainAlert, DelayedWithdrawalsAlert } from '../../../components'
import { BlockchainForms } from '../../../forms'
import { useSendOnChain } from '../SendOnchain.Context'

import type { SendOnChainDestination } from '../SendOnchain.Context'

const schema = y.object({
  /**
   * Blockchain used to transfer.
   */
  blockchain: y.string().oneOf(Object.values(BlockchainAbbreviation)),
  /**
   * Destination blockchain address.
   */
  destination: y.mixed<SendOnChainDestination>().required(),
})

type TransferRecipientFormValues = y.InferType<typeof schema>

export const TransferRecipientForm: React.FC = () => {
  const { t } = useTranslation('modals.blockchain')
  const [{ currency }] = useCurrentCurrency()
  const { router } = useModal()

  const blockchains = useBlockchainsQuery({
    variables: {
      currency,
      activityType: BlockchainActivityType.WITHDRAWAL,
    },
  })

  const [values, { setTransferState }] = useSendOnChain()

  const [Form, { resetField, watch }] = useForm<TransferRecipientFormValues>({
    schema,
    defaultValues: {
      destination: values.destination,
      blockchain: values.blockchain,
    },
  })

  const destination = watch('destination')
  const blockchain = watch('blockchain')

  /**
   * Blockchains list.
   */
  const blockchainList = useMemo(() => {
    return (
      blockchains.data?.blockchains.map(({ chain, icon, isBridged }) => {
        const chainName = t(`payments:chainNames.${chain}`)
        const bridgingLabel = isBridged ? t(`common:bridgedUSDC`) : ''
        return {
          label: `${chainName} ${bridgingLabel}`,
          value: chain,
          icon: icon,
        }
      }) ?? []
    )
  }, [blockchains.data?.blockchains, t])

  /**
   * Handle Form onSubmit.
   */
  const handleFormOnSubmit = useCallback(
    ({ blockchain, destination }: TransferRecipientFormValues) => {
      setTransferState({
        destination: destination,
        blockchain: blockchain ?? destination?.chain,
      })
      router.push(routes.blockchain.sendOnchain.amount)
    },
    [router, setTransferState],
  )

  /**
   * Handle Form Dropdown onChange.
   */
  const handleBlockchainChange = useCallback(() => {
    resetField('destination')
  }, [resetField])

  return (
    <Form onSubmit={handleFormOnSubmit}>
      <Form.Dropdown
        items={blockchainList}
        label={t`sendOnchain.transferRecipient.filterWallets`}
        name="blockchain"
        onChange={handleBlockchainChange}
        value={blockchain}
      />

      <BlockchainForms.Combobox.RecipientAddresses
        blockchain={blockchain}
        className="mt-6"
        name="destination"
      />

      {destination?.address && (
        // if the wallet is selected, disclose the full address under combobox
        <div className="mt-6">
          <h5 className="whitespace-nowrap break-words text-neutral type-body-sm-bold">
            {t`sendOnchain.transferRecipient.walletAddress`}
          </h5>
          <p className="mt-4 break-all type-body-base">{destination.address}</p>
        </div>
      )}

      <BlockchainAlert blockchain={watch('blockchain')} className="mt-6" />

      <DelayedWithdrawalsAlert
        className="mt-6"
        delayedWithdrawalInfo={destination?.delayedWithdrawalInfo ?? undefined}
      />

      <Center className="pt-8" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          disabled={Boolean(destination?.delayedWithdrawalInfo?.enabled)}
          variant="primary"
        >
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { useCallback } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { useSubmitEmailApprovalVoteMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { InternalRefetchQueriesInclude } from '@apollo/client'

export interface ApproveApprovalRequestQueryProps {
  /**
   * Policy id.
   */
  proposalId?: string
  /**
   * Refetch queries.
   */
  refetchQueries?: InternalRefetchQueriesInclude
}

export const ApproveApprovalRequest: React.FC<ApproveApprovalRequestQueryProps> =
  (props) => {
    const { t } = useTranslation('modals.walletApprovalPolicy')
    const { close } = useModal()
    const toast = useToast()

    const handleCancel = useCallback(() => {
      close({ context: 'onDismiss' })
    }, [close])

    const [submitVote, { loading, error, called, reset }] =
      useSubmitEmailApprovalVoteMutation()

    const handleApproveApprovalRequest = useCallback(
      (proposalId: string, refetchQueries?: InternalRefetchQueriesInclude) =>
        () => {
          void submitVote({
            variables: {
              input: {
                proposalId,
                isApproved: true,
              },
            },
            onCompleted: () => {
              toast.success(t(`approveApprovalRequest.success`))
              handleCancel()
            },
            // Error is handled in the GraphQLErrorBoundary
            onError: () => undefined,
            refetchQueries,
            awaitRefetchQueries: true,
          })
        },
      [handleCancel, submitVote, t, toast],
    )

    return (
      <PropsErrorBoundary props={props} variant="page">
        {({ proposalId, refetchQueries }) => (
          <>
            <Modal.Header title={t`approveApprovalRequest.title`} />
            <GraphQLErrorBoundary error={error} retry={reset} variant="page">
              <Modal.Body className="text-center">{t`approveApprovalRequest.description`}</Modal.Body>
              <Modal.Footer variant="stretch-equal">
                <Button
                  disabled={loading || (called && !error)}
                  onClick={handleCancel}
                  variant="secondary"
                >{t`common:cancel`}</Button>
                <Button
                  disabled={called && !error}
                  loading={loading}
                  onClick={handleApproveApprovalRequest(
                    proposalId,
                    refetchQueries,
                  )}
                  variant="primary"
                >{t`approveApprovalRequest.action`}</Button>
              </Modal.Footer>
            </GraphQLErrorBoundary>
          </>
        )}
      </PropsErrorBoundary>
    )
  }

import { DescriptionList } from '@circlefin/components'
import { TypeGuards } from '@services/type-guards'
import { MaybeNull } from '@shared/components/common'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { Fees } from '../../components'
import { TransactionDetail } from '../TransactionDetail/TransactionDetail'
import { WalletApprovalTransactionDetailApprovalSummary } from '../WalletApprovalTransactionDetailApprovalSummary/WalletApprovalTransactionDetailApprovalSummary'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type { SepaInstantPaymentDetail as SepaInstantPaymentDetailType } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface SepaInstantPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  sepaInstantPaymentDetail?: SepaInstantPaymentDetailType
}

export const SepaInstantPaymentDetail: React.FC<SepaInstantPaymentDetailProps> =
  ({ sepaInstantPaymentDetail, error }) => {
    const { t } = useTranslation('payments')

    const isReceive = TypeGuards.Payments.isSepaInstantReceiveType(
      sepaInstantPaymentDetail,
    )

    const { data: { walletApprovalTransactionDetailByJobId } = {} } =
      useWalletApprovalTransactionDetailByJobIdQuery({
        variables: {
          jobId: sepaInstantPaymentDetail?.payment.id ?? '',
        },
        skip: !sepaInstantPaymentDetail?.payment.id || isReceive,
      })

    const bankDescriptionLabel = isReceive
      ? t('transactionDetail.fundingSource')
      : t('transactionDetail.destination')

    const bankAccount = isReceive
      ? sepaInstantPaymentDetail?.source
      : sepaInstantPaymentDetail?.destination

    return (
      <TransactionDetail
        error={error}
        transaction={sepaInstantPaymentDetail?.payment}
      >
        {walletApprovalTransactionDetailByJobId && (
          <WalletApprovalTransactionDetailApprovalSummary
            {...walletApprovalTransactionDetailByJobId}
          />
        )}

        <Label className="w-64">{bankDescriptionLabel}</Label>
        <Description>
          <MaybeNull>{bankAccount?.nickname}</MaybeNull>
          <p>
            <MaybeNull>{bankAccount?.description}</MaybeNull>
          </p>
        </Description>
        {sepaInstantPaymentDetail?.fees && (
          <Fees fees={sepaInstantPaymentDetail?.fees} />
        )}

        {isReceive && (
          <>
            <Label className="w-64">{t('transactionDetail.referenceID')}</Label>
            <Description>{bankAccount?.trackingRef}</Description>
          </>
        )}
      </TransactionDetail>
    )
  }

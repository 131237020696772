import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Change password: users need PROTECTED permission but should NOT have the SSO_ENABLED_ROLE role.
 */
export const CHANGE_PASSWORD: PermissionSet = {
  access: AccessPermissions.protected,
  excludeAny: [Role.SSO_ENABLED_ROLE],
}

import getConfig from 'next/config'

/**
 * EnvironmentName:
 * Enum matches env variable ENV.
 */
export enum EnvironmentName {
  local = 'local',
  smokebox = 'smokebox',
  stg = 'stg',
  sandbox = 'sandbox',
  prod = 'prod',
}

/**
 * EnvironmentMap:
 * Boolean entry for each env.
 */
export type EnvironmentMap = {
  [key in EnvironmentName]: boolean
}

/**
 * Value for environment helper.
 * @param valueMap - `{ local: false, smokebox true:, ...}`.
 * @param defaultValue - Default value.
 */
export function valueForEnvironment(
  valueMap: Partial<EnvironmentMap> = {},
  defaultValue = false,
): boolean {
  const config = getConfig()

  // Get env name from next config if exists
  // In the server `publicRuntimeConfig` may not be available, but `process.env` will be.
  const ENV: string = config?.publicRuntimeConfig.ENV ?? process.env.ENV ?? ''

  // Check if the env name exists
  if (ENV && ENV in valueMap) {
    return !!valueMap[ENV as EnvironmentName]
  }
  // fallback to default value
  return defaultValue
}

/**
 * Feature switch service:
 *
 * Controls access to feature per-environment (i.e., local, stg, prod).
 * In the future, we could connect to settings DB as well to manage features there.
 */
export const FeatureSwitch = {
  /**
   * Indicates if Segment events are tracked for the specified environment.
   */
  segmentEnabled() {
    return valueForEnvironment({
      smokebox: false,
      stg: false,
      sandbox: true,
      prod: true,
    })
  },

  /**
   * Indicates if redemption of last resort is enabled.
   */
  rolrEnabled() {
    return valueForEnvironment({
      local: true,
      smokebox: true,
      stg: true,
      prod: true,
    })
  },

  /**
   * Indicates if the testnetExplorerLink should be used.
   */
  testExplorerLinkEnabled() {
    return valueForEnvironment({
      smokebox: true,
      sandbox: true,
      local: true,
    })
  },

  /**
   * Indicates if emails are enabled for the new Address Book.
   */
  newAddressBookEmailsEnabled() {
    return valueForEnvironment({
      local: true,
    })
  },

  /**
   * Indicates if the sandbox signup flow is enabled.
   * Quick/reduced signup without Nova, sandbox bypass.
   */
  sandboxSignup() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        sandbox: true,
      },
      false,
    )
  },

  /**
   * Indicates whether redirection from the regular signup page
   * to the sandbox signup page is disabled.
   */
  signUpRedirectToSandboxDisabled() {
    return valueForEnvironment(
      {
        local: true,
      },
      false,
    )
  },

  /**
   * Indicates if the sandbox invite flow is enabled.
   * Quick/reduced signup without Nova, sandbox bypass.
   */
  sandboxInvite() {
    return valueForEnvironment(
      {
        smokebox: true,
        sandbox: true,
        local: false,
      },
      false,
    )
  },

  /**
   * Indicates if the functionality to archive / unarchive wallets is enabled for the specified environment.
   */
  vaultArchivingWalletsEnabled() {
    return valueForEnvironment({
      local: true,
    })
  },

  /**
   * Indicates if the Sandbox badge on header is enabled for the specified environments.
   */
  sandboxBadgeEnabled() {
    return valueForEnvironment({
      smokebox: true,
      sandbox: true,
    })
  },

  /**
   * Indicates if Auto Transfer is enabled.
   */
  autoTransferEnabled() {
    return valueForEnvironment({
      local: true,
      smokebox: true,
      prod: true,
    })
  },

  /**
   * Indicates if WalletConnect is enabled.
   */
  walletConnectEnabled() {
    return valueForEnvironment({
      local: true,
      smokebox: true,
      stg: true,
    })
  },

  /**
   * Indicates if the (policy/user) limits steps are enabled in the wallet approval flow.
   */
  walletApprovalLimitsStepsEnabled() {
    return valueForEnvironment({}, false)
  },

  /**
   * Indicates if the new stable coin tab/page is enabled in the updated custody experience.
   */
  stableCoinExpressCustodyDashboardEnabled() {
    return valueForEnvironment({
      local: true,
    })
  },

  /**
   * Example playground page.
   */
  devOnlyEnabled() {
    return valueForEnvironment({
      local: true,
      smokebox: true,
    })
  },

  /**
   * Rtp bank support enabled.
   */
  rtpBankSupportEnabled() {
    return valueForEnvironment({}, false)
  },

  /**
   * Indicates if new platform selection page is visible per environment.
   */
  platformSelectionEnabled() {
    return valueForEnvironment(
      {
        sandbox: false,
      },
      true,
    )
  },

  /**
   * Indicates if business account creation flow is enabled.
   */
  businessAccountCreationEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Indicates if we should provide notice in the Developer Dashboard about enabling product api via Circle Rep.
   */
  developerProductsDisabledMessage() {
    return valueForEnvironment(
      {
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Indicates if self-custody vault is enabled.
   * This is currently set to off for all envs except local as the project is on pause.
   */
  vaultCustodyEnabled() {
    return valueForEnvironment({
      local: true,
    })
  },

  /**
   * Indicates if ramp product marketing is live.
   */
  isLiquiditySignupEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
      },
      false,
    )
  },

  /**
   * Indicates if custody tour is enabled.
   * To ensure that it is disabled locally for Cypress tests to work consistently.
   */
  isCustodyTourEnabled() {
    return valueForEnvironment(
      {
        local: false,
      },
      true,
    )
  },

  /**
   * Indicates COP onboarding is available per environment.
   */
  isCopOnboardingEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Indicates COP onboarding is available for new user sign-ups.
   */
  isCopNewUserOnboardingEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Indicates that the France COP onboarding experience is available.
   */
  isCopFranceOnboardingEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Indicates if rfi completeness is being enforced.
   */
  isEnforceRfiCompleteness() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
      },
      true,
    )
  },

  /**
   * Indicates if we want to have strict validation for Onboarding
   * API responses.
   */
  isOnboardingStrictApiValidationEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
      },
      false,
    )
  },

  /**
   * Indicates COP upload doesn't link documents right away.
   */
  isCopUploadWithoutLinkingEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
      },
      false,
    )
  },

  /**
   * Indicates that we are using updated api with integrated RFI data.
   */
  isIntegratedRfiApiEnabled() {
    return valueForEnvironment(
      {
        local: false,
        smokebox: false,
        stg: false,
      },
      false,
    )
  },

  /**
   * When enabled, only users w/ a valid invitation link can sign up.
   */
  inviteOnlyEnabled() {
    return valueForEnvironment(
      {
        local: false,
        smokebox: false,
        sandbox: false,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * When enabled, only users w/ a valid invitation link can sign up.
   * Phase 2 of the flow.
   */
  inviteOnlyPhase2Enabled() {
    return valueForEnvironment(
      {
        local: false,
        smokebox: false,
        sandbox: false,
        stg: true,
        prod: true,
      },
      false,
    )
  },

  /**
   * Permanent flag to allow lower environments to access account creation through a custom token.
   * Accessible via /invite-circle-mint/internal.
   */
  bypassCircleMintInviteToken() {
    return valueForEnvironment(
      {
        local: true,
        stg: true,
      },
      false,
    )
  },

  /**
   * If pin code reset flows are enabled.
   */
  pinCodeResetEnabled() {
    return valueForEnvironment(
      {
        local: true,
        smokebox: true,
        stg: true,
        prod: true,
      },
      false,
    )
  },
}

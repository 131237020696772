import { useCallback, useState } from 'react'

import { TabSwitch } from '@circlefin/components'
import { TableCard } from '@shared/components/tables'
import useTranslation from 'next-translate/useTranslation'

import { CircleAddressesTable } from './CircleAddressesTable/CircleAddressesTable'
import { ExternalAddressesTable } from './ExternalAddressesTable/ExternalAddressesTable'

import type { CircleAddressesTableProps } from './CircleAddressesTable/CircleAddressesTable'
import type { ExternalAddressesTableProps } from './ExternalAddressesTable/ExternalAddressesTable'
import type {
  BlockchainRecipientAddressWithMetadata,
  InternalAddress,
  VaultPolicyOutgoingAddressWhitelist,
} from '@shared/graphql'

const { Header, Body } = TableCard

export enum OutgoingAddressesTableTab {
  EXTERNAL_ADDRESSES = 'EXTERNAL_ADDRESSES',
  CIRCLE_ADDRESSES = 'CIRCLE_ADDRESSES',
}

export type OutgoingAddressesRowSelect =
  | {
      type: OutgoingAddressesTableTab.EXTERNAL_ADDRESSES
      addresses: BlockchainRecipientAddressWithMetadata[]
    }
  | {
      type: OutgoingAddressesTableTab.CIRCLE_ADDRESSES
      addresses: InternalAddress[]
    }

export interface OutgoingAddressesProps
  extends Pick<CircleAddressesTableProps, 'excludedVaultIds'> {
  /**
   * Selected rows to initialize.
   */
  initialSelected?: VaultPolicyOutgoingAddressWhitelist
  /**
   * Will be called when the user selects addresses from the table.
   */
  onRowSelect?: (params: OutgoingAddressesRowSelect) => void
}

export const OutgoingAddresses: React.FC<OutgoingAddressesProps> = ({
  initialSelected,
  onRowSelect,
  excludedVaultIds,
}) => {
  const { t } = useTranslation('vaults')

  const [currentTab, setTab] = useState(
    OutgoingAddressesTableTab.EXTERNAL_ADDRESSES,
  )

  const onChangeTab = useCallback((currentTab: string) => {
    // The tab switch component needs to be able to support generics in order to remove this type conversion
    // TODO: Fix typing for TabSwitch in component-web
    setTab(currentTab as unknown as OutgoingAddressesTableTab)
  }, [])

  const handleExternalAddressesRowSelect = useCallback<
    Required<ExternalAddressesTableProps>['onRowSelect']
  >(
    (addresses) => {
      onRowSelect?.({
        addresses,
        type: OutgoingAddressesTableTab.EXTERNAL_ADDRESSES,
      })
    },
    [onRowSelect],
  )

  const handleCircleAddressesRowSelect = useCallback<
    Required<CircleAddressesTableProps>['onRowSelect']
  >(
    (addresses) => {
      onRowSelect?.({
        addresses,
        type: OutgoingAddressesTableTab.CIRCLE_ADDRESSES,
      })
    },
    [onRowSelect],
  )

  return (
    <TabSwitch onChange={onChangeTab} selected={currentTab}>
      <TableCard>
        <Header>
          <TabSwitch.Tabs variant="secondary">
            <TabSwitch.Tab id={OutgoingAddressesTableTab.EXTERNAL_ADDRESSES}>
              {t('createVault.outgoingAddressesTable.externalAddresses')}
            </TabSwitch.Tab>

            <TabSwitch.Tab id={OutgoingAddressesTableTab.CIRCLE_ADDRESSES}>
              {t('createVault.outgoingAddressesTable.circleAddresses')}
            </TabSwitch.Tab>
          </TabSwitch.Tabs>
        </Header>
        <Body>
          <TabSwitch.Content id={OutgoingAddressesTableTab.EXTERNAL_ADDRESSES}>
            <ExternalAddressesTable
              initialSelected={initialSelected?.external}
              onRowSelect={handleExternalAddressesRowSelect}
            />
          </TabSwitch.Content>
          <TabSwitch.Content id={OutgoingAddressesTableTab.CIRCLE_ADDRESSES}>
            <CircleAddressesTable
              excludedVaultIds={excludedVaultIds}
              initialSelected={initialSelected?.internal}
              onRowSelect={handleCircleAddressesRowSelect}
            />
          </TabSwitch.Content>
        </Body>
      </TableCard>
    </TabSwitch>
  )
}

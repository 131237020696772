import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../../layout'

import { PolicyForm } from './Policy.Form'

export const Policy: React.FC = () => {
  return (
    <WalletApprovalPolicyLayout
      currentStep={WalletApprovalPolicyStep.POLICY_LIMITS}
    >
      <PolicyForm />
    </WalletApprovalPolicyLayout>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useCreateNotificationSubscriptionMutation } from '@shared/graphql'

import { CreateForm } from './CreateForm'

import type { CreateFormProps } from './CreateForm'
import type { Maybe, NotificationSubscription } from '@shared/graphql'

export interface CreateProps {
  /**
   * A callback will be invoked when the create subscription form has been submitted successfully.
   */
  onSuccess?: (newSubscription?: Maybe<NotificationSubscription>) => void
}

export const Create: React.FC<CreateProps> = ({ onSuccess }) => {
  const modal = useModal()
  const { track } = useSegment()

  const [createSubscription, { loading, error, reset }] =
    useCreateNotificationSubscriptionMutation({
      onCompleted: (data) => {
        track(SegmentEvents.AddSubscriptionSuccess)
        modal.router.replace(
          routes.developer.subscriptions.addNewSubscriptionSuccess,
        )
        onSuccess?.(data?.createNotificationSubscription)
      },
      onError: () => track(SegmentEvents.AddSubscriptionError),
    })

  const onFormSubmit = useCallback<CreateFormProps['onSubmit']>(
    (values) => {
      void createSubscription({
        variables: {
          input: {
            endpoint: new URL(values.endpoint),
          },
        },
      })
    },
    [createSubscription],
  )

  return (
    <FullScreen hideProgressBar>
      <Center data-testid="create-subscription-modal-body">
        <GraphQLErrorBoundary error={error} retry={reset}>
          <CreateForm loading={loading} onSubmit={onFormSubmit} />
        </GraphQLErrorBoundary>
      </Center>
    </FullScreen>
  )
}

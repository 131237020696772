import { Header, SkeletonBox } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { WalletBackupRequiredNotification } from '../WalletBackupRequiredNotification/WalletBackupRequiredNotification'

import { VaultsDashboardHeaderActions } from './VaultsDashboardHeader.Actions'

export interface VaultsDashboardHeaderProps {
  /**
   * Is a new wallet backup required?
   */
  backupRequired?: boolean
  /**
   * Specify whether a device was already paired.
   */
  hasPairedDevice?: boolean
  /**
   * Specify whether the loading state should be displayed.
   */
  loading?: boolean
}

export const VaultsDashboardHeader: React.FC<VaultsDashboardHeaderProps> = ({
  backupRequired,
  hasPairedDevice,
  loading,
}) => {
  const { t } = useTranslation('vaults')

  return (
    <>
      <Header>
        <Header.Head title={t`vaultsDashboardHeader.title`}>
          <SkeletonBox className="h-10 w-40" loading={loading}>
            <VaultsDashboardHeaderActions hasPairedDevice={hasPairedDevice} />
          </SkeletonBox>
        </Header.Head>
        <div className="max-w-184">
          <Header.Description>{t`vaultsDashboardHeader.description`}</Header.Description>
        </div>
      </Header>
      <WalletBackupRequiredNotification open={backupRequired} showAction />
    </>
  )
}

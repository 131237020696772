import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import type { ButtonStyleProps } from '@circlefin/components/lib/Button'

export interface CancelPendingTransactionProps
  extends Partial<ButtonStyleProps> {
  /**
   * Policy id.
   */
  proposalId: string
}

export const CancelPendingTransaction: React.FC<CancelPendingTransactionProps> =
  ({ proposalId, ...styleProps }) => {
    const { t } = useTranslation('common')

    return (
      <ModalLink
        pathname={routes.walletApprovalPolicy.cancelPendingTransaction}
        query={{
          proposalId,
        }}
      >
        <Button intent="destructive" variant="primary" {...styleProps}>
          {t('cancel')}
        </Button>
      </ModalLink>
    )
  }

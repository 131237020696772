import Image from 'next/image'

import SandboxSVG from './images/SandboxBadge.svg'

export interface SandboxBadgeProps {
  /**
   * Custom styles.
   */
  className?: string
}

export const SandboxBadge: React.FC<SandboxBadgeProps> = ({ className }) => {
  return (
    <div className={className}>
      <Image alt="Sandbox Environment" src={SandboxSVG} />
    </div>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Permissions for guarding Burn Fee V2 launch.
 */
export const BURN_FEE_V2_ENABLED: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_BURN_FEE_V2_ENABLE_ROLE],
}

import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface SubmittedProps {
  /**
   * Policy id.
   */
  policyId: string
  /**
   * Pending approvers count.
   */
  pendingApprovers?: number
}

export const Submitted: React.FC<SubmittedProps> = ({
  policyId,
  pendingApprovers,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <>
      <FixedBanner.Title>
        {t('policy.banners.submittedPolicy.title', {
          count: pendingApprovers,
        })}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t('policy.banners.submittedPolicy.description')}</p>
        <ModalLink
          pathname={routes.walletApprovalPolicy.submittedPolicyProgress}
          query={{ policyId }}
        >
          <TextLink size="sm" variant="primary">
            {t('policy.banners.viewStatus')}
          </TextLink>
        </ModalLink>
      </FixedBanner.Description>
    </>
  )
}

import { useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { MODAL_TAKEOVER } from '@services/permissions'
import { useTakeoverInfoQuery } from '@shared/graphql'

import {
  usePeriodicReviewTakeover,
  usePinCodeTakeover,
} from '../../hooks/takeover'

export const TakeoverManager: React.FC = () => {
  const [, { isAuthorized }] = usePermission(MODAL_TAKEOVER)
  const modal = useModal()
  const { data } = useTakeoverInfoQuery({
    skip: !isAuthorized,
    fetchPolicy: 'cache-and-network',
    onError: () => {
      // On error, fail silently
    },
  })

  const pinCode = usePinCodeTakeover()
  const periodicReview = usePeriodicReviewTakeover()

  useEffect(() => {
    if (modal.history.route != null || !data) {
      return
    }

    if (pinCode.shouldOpen(data)) {
      pinCode.open()
      return
    }

    if (periodicReview.shouldOpen(data)) {
      periodicReview.open()
    }
  }, [data, modal.history.route, periodicReview, pinCode])

  return null
}

import { Button } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import {
  BANK_DEPOSIT,
  ROLR_REDEMPTION_ONLY_ACCOUNT,
} from '@services/permissions'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export const Deposit: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [, { isAuthorized }] = usePermission(BANK_DEPOSIT)
  const [, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )

  // ROLR (redemption only) customer cannot make bank deposit, do not show this button
  if (isRolr) {
    return null
  }

  return (
    <Button
      data-testid="bank-wires-deposit-button"
      {...props}
      disabled={!isAuthorized}
    >
      {children}
    </Button>
  )
}

import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { TransferWithFxProvider } from '@features/transfer/hooks/fx/provider'
import { TransferProvider } from '@features/transfer/hooks/transfer/provider'
import { WithdrawProvider } from '@features/transfer/hooks/withdraw/provider'
import {
  ChooseDepositType,
  ChooseTransferType,
  ChooseWalletAndDepositType,
  ChooseWalletAndTransferType,
  Wallet,
  Wire,
  Withdraw,
  Fx,
} from '@features/transfer/modals'
import { ApprovalRequired } from '@features/vault/modals'
import { transfer } from '@services/sections/modal/transfer/transfer'
import { WireTransferTypeEnum } from '@shared/graphql'

/**
 * Transfer Router.
 */
export const TransferRouter: React.FC = () => {
  return (
    <>
      {/* TODO: Transfer modals are not organized well, we should fix once the vault work is done */}
      <ModalSwitch path={transfer.switch}>
        {/* Choose Transfer */}
        <ModalRoute
          content={<ChooseTransferType />}
          path={transfer.chooseTransferType}
        />

        <TransferProvider>
          {/* Transfer */}
          <ModalRoute
            content={<ChooseDepositType />}
            path={transfer.chooseDepositType}
          />
          <ModalRoute
            content={<ChooseWalletAndDepositType />}
            path={transfer.chooseWalletAndDepositType}
          />
          <ModalRoute
            content={<ChooseWalletAndTransferType />}
            path={transfer.chooseWalletAndTransferType}
          />

          {/* Wire */}
          <ModalRoute
            content={<Wire.SelectCurrency />}
            path={transfer.wire.selectCurrency}
          />
          <ModalRoute
            content={<Wire.SelectBankAccount />}
            path={transfer.wire.selectBankAccount}
          />
          <ModalRoute
            content={<Wire.BeneficiaryBankAccount />}
            path={transfer.wire.beneficiaryBankAccount}
          />
          <ModalRoute
            content={<Wire.PleaseTransferFunds />}
            path={transfer.wire.pleaseTransferFunds}
          />
          <ModalRoute
            content={<Wire.TestLanding />}
            path={transfer.wire.testTransferLanding}
          />

          {/* Wallet */}
          <ModalRoute
            content={<Wallet.ChooseBlockchain />}
            path={transfer.wallet.chooseBlockchain}
          />
          <ModalRoute
            content={<Wallet.TransferInstructions />}
            path={transfer.wallet.transferInstructions}
          />
        </TransferProvider>
      </ModalSwitch>

      {/* Payout and Send on chain flow */}
      <ModalSwitch path={transfer.withdraw.switch}>
        {/* Shared / Payout */}
        <WithdrawProvider>
          <ModalRoute
            content={<Withdraw.SelectAmount />}
            path={transfer.withdraw.amount}
          />
          <ModalRoute
            content={<Withdraw.ChooseRecipient />}
            path={transfer.withdraw.chooseRecipient}
          />
          <ModalRoute
            content={<Withdraw.Review />}
            path={transfer.withdraw.review}
          />
          <ModalRoute
            content={<ApprovalRequired />}
            path={transfer.withdraw.approvalRequired}
          />
        </WithdrawProvider>
      </ModalSwitch>

      {/* FX transfer deposit flows */}
      <ModalSwitch path={transfer.fx.deposit.switch}>
        <TransferWithFxProvider mode={WireTransferTypeEnum.deposit}>
          <ModalRoute
            content={<Fx.FxDeposit />}
            path={transfer.fx.deposit.form}
          />
          <ModalRoute
            content={<Fx.DepositInstructions />}
            path={transfer.fx.deposit.instructions}
          />
        </TransferWithFxProvider>
      </ModalSwitch>

      <ModalSwitch path={transfer.fx.withdraw.switch}>
        <TransferWithFxProvider mode={WireTransferTypeEnum.withdrawal}>
          <ModalRoute
            content={<Fx.FxWithdraw />}
            path={transfer.fx.withdraw.form}
          />
          <ModalRoute
            content={<Fx.ReviewDetails />}
            path={transfer.fx.withdraw.review}
          />
        </TransferWithFxProvider>
      </ModalSwitch>
    </>
  )
}

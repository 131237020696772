import { createContext, useContext, useEffect, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { IpAllowListingIpAddressType } from '@shared/graphql'

interface State {
  /**
   * The IP address value.
   */
  ipMin: string
  /**
   * Ip address max value.
   */
  ipMax: string
  /**
   * The IP address type.
   */
  ipType: IpAllowListingIpAddressType
}

interface Actions {
  /**
   * Cb that will be called when the form state change.
   */
  onFormChange: (values: Required<State>) => void
}

const defaultState: State = {
  ipMin: '',
  ipMax: '',
  ipType: IpAllowListingIpAddressType.single,
}

const CreateContext = createContext<[State, Actions]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  { onFormChange: () => {} },
])

export interface CreateProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const CreateProvider: React.FC<CreateProviderProps> = ({ children }) => {
  const [values, setValues] = useState<State>(defaultState)

  const modal = useModal()

  useEffect(() => {
    const resetState = () => {
      setValues(defaultState)
    }

    // Subscribe to modal close event
    modal.events.on('onCloseStart', resetState)

    return () => {
      // Unsubscribe to modal close event
      modal.events.off('onCloseStart', resetState)
    }
  })

  const onFormChange = (values: Partial<State>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }

  return (
    <CreateContext.Provider value={[values, { onFormChange }]}>
      {children}
    </CreateContext.Provider>
  )
}

/**
 * Create flow context hook.
 */
export const useCreate = () => {
  return useContext(CreateContext)
}

import type React from 'react'
import { useCallback, useMemo, useState } from 'react'

import { Button, Icon, Modal } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { apiKeys } from '@services/sections/lib/developer'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { ApiKeyType } from '../../../components/ApiKeyTypeRadio'

/**
 * Success modal props.
 */
export interface SuccessProps {
  /**
   * API key value for copy to clipboard.
   */
  apiKey?: string
  /**
   * API Key type. Defaults to standard.
   */
  type?: ApiKeyType
}

export const Success: React.FC<SuccessProps> = ({ apiKey, type }) => {
  const { t } = useTranslation('modals.developer')
  const [hidden, setHidden] = useState(true)
  const router = useRouter()

  const handleVisibleToggle = useCallback(() => {
    setHidden((hidden) => !hidden)
  }, [])

  const handleCloseClick = useCallback(() => {
    void router.push(apiKeys.route)
  }, [router])

  const keyType = useMemo(() => {
    if (type === 'standard') {
      return 'Standard'
    } else if (type === 'restricted') {
      return 'Read-Only'
    } else {
      return ''
    }
  }, [type])

  return (
    <>
      <Modal.BasicClose onClick={handleCloseClick} />
      <Modal.Title>
        <Icon.Circle
          className="mb-4"
          intent="success"
          name="CheckCircleSolid"
        />
        <h1>
          {t('apiKeys.createSuccess.title', {
            keyType: keyType,
          })}
        </h1>
      </Modal.Title>
      <PropsErrorBoundary<SuccessProps> props={{ apiKey }} variant="page">
        {() => (
          <>
            <Modal.Body className="flex flex-col gap-6" variant="center">
              <p className="mt-4 text-sm">{t`apiKeys.createSuccess.description`}</p>
              <CopyToClipboard
                className="p-0"
                masked={hidden}
                value={apiKey ?? ''}
                variant="input"
              />
            </Modal.Body>
            <Modal.Footer className="mt-4" variant="center" dense>
              <Button
                className="px-2"
                onClick={handleVisibleToggle}
                variant="text"
              >
                {hidden
                  ? t`apiKeys.createSuccess.show`
                  : t`apiKeys.createSuccess.hide`}
              </Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </>
  )
}

import { VaultPermissionContext } from './context'

import type { VaultPermissionContextProps } from './context'

export interface VaultPermissionProviderProps
  extends VaultPermissionContextProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const VaultPermissionProvider: React.FC<VaultPermissionProviderProps> =
  ({ children, vaultMetadata }) => {
    return (
      <VaultPermissionContext.Provider value={{ vaultMetadata }}>
        {children}
      </VaultPermissionContext.Provider>
    )
  }

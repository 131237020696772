import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export interface CreatePassphraseFormValues {
  /**
   * Password.
   */
  password: string
}

export interface CreatePassphraseFormProps {
  /**
   * Onsubmit handler.
   */
  onSubmit: (values: CreatePassphraseFormValues) => void
}

const schema = y.object({
  password: y
    .string()
    .required()
    .min(10)
    .max(16)
    .hasLowercase()
    .hasUppercase()
    .hasNumber()
    .hasSpecialCharacter(),
})

export const CreatePassphraseForm: React.FC<CreatePassphraseFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('modals.vault')

  const [Form] = useForm<y.InferType<typeof schema>>({ schema })

  const handleSubmit = useCallback(
    ({ password }: CreatePassphraseFormValues) => {
      onSubmit({ password })
    },
    [onSubmit],
  )

  return (
    <Form className="mt-8 sm:w-100" onSubmit={handleSubmit}>
      <Form.PasswordInput
        className="w-full"
        data-testid="input-password"
        label={t('walletBackup.createPassphrase.label')}
        message={t`walletBackup.createPassphrase.message`}
        name="password"
        hideError
      />
      <Center className="pt-12" variant="horizontal">
        <Form.SubmitButton className="w-60" variant="primary">
          {t('common:continue')}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

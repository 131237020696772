import { TabsNav } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { isPartOfSection } from '@services/sections'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Section } from '@services/sections/types'
import type { ParsedUrlQueryInput } from 'querystring'

export interface TabsNavItemProps extends Section {
  /**
   * Optional query that will be passed to the link component to replace
   * route parameters if a dynamic route is used.
   */
  query?: ParsedUrlQueryInput
  /**
   * Flag to specify whether we want to just have an exact match the given section's URL
   * and exclude matching by sub-page routes.
   */
  exactMatch?: boolean
}

export const TabsNavItem: React.FC<TabsNavItemProps> = ({
  query,
  exactMatch,
  ...section
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [{ Authorized }] = usePermission(section.permissions)

  const isSelected = isPartOfSection(router.pathname, section, exactMatch)

  return (
    <Authorized>
      <Link
        href={{
          pathname: section.route,
          query,
        }}
        legacyBehavior
        passHref
      >
        <TabsNav.Item id={section.label} selected={isSelected}>
          {t(section.label)}
        </TabsNav.Item>
      </Link>
    </Authorized>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Blockchain mutation.
 * Which users are allowed to deposit/withdraw onchain?
 */
export const BLOCKCHAIN_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.WALLETS_ROLE],
}

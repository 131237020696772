import { DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { usePermission } from '@circlefin/permissions'
import {
  TransactionDetail,
  WalletApprovalTransactionDetailApprovalSummary,
} from '@features/payments/containers'
import { CUSTODY_POLICY_SECTION } from '@services/permissions'
import { ChainExplorerLink, MaybeNull } from '@shared/components/common'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { OnchainSend } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface OnchainSendDetailCardProps {
  /**
   * Onchain send.
   */
  onchainSend: OnchainSend
  /**
   * Custom style.
   */
  className?: string
}

export const OnchainSendDetailCard: React.FC<OnchainSendDetailCardProps> = ({
  onchainSend,
  className,
}) => {
  const { t } = useTranslation('payments')
  const { money } = useMoney()
  const [, { isAuthorized }] = usePermission(CUSTODY_POLICY_SECTION)

  const { payment, destination, explorerLink } = onchainSend
  const { chain, description, address, addressTag, email } = destination

  const { data: { walletApprovalTransactionDetailByJobId } = {} } =
    useWalletApprovalTransactionDetailByJobIdQuery({
      variables: {
        jobId: payment.id,
      },
      skip: !isAuthorized,
    })

  return (
    <TransactionDetail className={className} transaction={payment}>
      <Label>{t('transactionDetail.blockchain')}</Label>
      <Description>
        <MaybeNull>{chain != null && t(`chainNames.${chain}`)}</MaybeNull>
      </Description>

      <Label>{t('transactionDetail.amount')}</Label>
      <Description>
        {money({
          number: Math.abs(Number(payment.amount.amount)),
          variant: payment.amount.currency,
          options: {
            symbol: false,
          },
        })}{' '}
        {/* TODO: Revisit L2 treatment in [https://circlepay.atlassian.net/browse/BRAAV-10935]. */}
        {t(
          `currencyByChain.${chain ?? ''}`,
          { currency: payment.amount.currency },
          { fallback: payment.amount.currency.toLowerCase() },
        )}
      </Description>

      {walletApprovalTransactionDetailByJobId && (
        <WalletApprovalTransactionDetailApprovalSummary
          {...walletApprovalTransactionDetailByJobId}
        />
      )}

      {address && (
        <>
          <Label>{t('transactionDetail.recipient')}</Label>
          <Description className="break-words">
            <span>
              {description} {email && `(${email})`}
            </span>
            <div className="break-words">{address}</div>
          </Description>
        </>
      )}
      {!!addressTag && (
        <>
          <Label>{t('transactionDetail.memo')}</Label>
          <Description>{addressTag}</Description>
        </>
      )}
      {!!explorerLink && !(chain == null) && (
        <ChainExplorerLink chain={chain} explorerLink={explorerLink} />
      )}
    </TransactionDetail>
  )
}

import { useApprovalWorkflowValidate } from '../useApprovalWorkflowValidate/useApprovalWorkflowValidate'
import { useOutgoingAddressValidate } from '../useOutgoingAddressValidate/useOutgoingAddressValidate'
import { usePermissionValidate } from '../usePermissionValidate/usePermissionValidate'
import { usePolicyLimitsValidate } from '../usePolicyLimitsValidate/usePolicyLimitsValidate'
import { usePolicyNameValidate } from '../usePolicyNameValidate/usePolicyNameValidate'
import { useUserLimitsValidate } from '../useUserLimitsValidate/useUserLimitsValidate'
import { useWalletConnectValidate } from '../useWalletConnectValidate/useWalletConnectValidate'

export const useCreateVaultValidate = () => {
  const policyNameValidate = usePolicyNameValidate()
  const permissionValidate = usePermissionValidate()
  const policyLimitValidate = usePolicyLimitsValidate()
  const userLimitsValidate = useUserLimitsValidate()
  const outgoingAddressValidate = useOutgoingAddressValidate()
  const approvalWorkflowValidate = useApprovalWorkflowValidate()
  const walletConnectValidate = useWalletConnectValidate()

  const isValid =
    policyNameValidate.isValid &&
    permissionValidate.isValid &&
    policyLimitValidate.isValid &&
    userLimitsValidate.isValid &&
    outgoingAddressValidate.isValid &&
    (approvalWorkflowValidate.isOptional || approvalWorkflowValidate.isValid) &&
    (walletConnectValidate.isOptional || walletConnectValidate.isValid)

  const enableSaveExit =
    policyNameValidate.enableSaveExit &&
    permissionValidate.enableSaveExit &&
    policyLimitValidate.enableSaveExit &&
    userLimitsValidate.enableSaveExit &&
    outgoingAddressValidate.enableSaveExit &&
    approvalWorkflowValidate.enableSaveExit &&
    walletConnectValidate.enableSaveExit

  return {
    isValid,
    enableSaveExit,
  }
}

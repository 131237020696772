import { useCallback } from 'react'

import { Icon, SelectCard, SkeletonBox } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { MaybeNull } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  LegalEntity,
  PaymentMethodType,
  useWalletPaymentMethodQuery,
  Currency,
  useEntityQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { usePayInvoice } from '../../PayInvoice.Context'

import type { WalletPaymentMethod } from '@shared/graphql'

export interface WalletPaymentMethodsProps {
  /**
   * Radio name.
   */
  radioName: string
}

export const WalletPaymentMethods: React.FC<WalletPaymentMethodsProps> = ({
  radioName,
}) => {
  const { t } = useTranslation('modals/settings/billing')
  const { data, loading, error } = useWalletPaymentMethodQuery()
  const { money } = useMoney()
  const [{ paymentType }, { setPaymentDetails }] = usePayInvoice()
  const {
    data: entityData,
    loading: entityLoading,
    error: entityError,
  } = useEntityQuery()

  const onChange = useCallback(() => {
    setPaymentDetails({
      paymentType: PaymentMethodType.wallet,
      cardId: undefined,
    })
  }, [setPaymentDetails])

  const isSupportedWalletCurrencyForBilling = (wallet: WalletPaymentMethod) =>
    entityData?.entity?.legalEntity === LegalEntity.CIRCLE_FR
      ? wallet?.balance.currency === Currency.EURC // for FR, only EURC
      : wallet?.balance.currency === Currency.USDC // all others, USDC

  return (
    <div data-testid="wallet-payment-methods">
      <GraphQLErrorBoundary error={error || entityError}>
        <SkeletonBox className="h-18 w-full" loading={loading || entityLoading}>
          <SelectCard
            checked={paymentType === PaymentMethodType.wallet}
            id="wallet"
            label="wallet"
            name={radioName}
            onChange={onChange}
            type="radio"
            value="wallet"
            variant="primary/sm"
          >
            <div className="flex items-center space-x-10 py-3">
              <Icon name="LinkOutline" size={18} />
              <div className="flex justify-start">
                <div className="flex flex-col">
                  <div>
                    <span className="text-sm leading-5 font-circular-book">
                      {t('invoice.selectPaymentMethod.linkedWallet.mainWallet')}
                    </span>
                    <span className="ml-2">
                      {t('invoice.selectPaymentMethod.linkedWallet.notice')}
                    </span>
                  </div>
                  <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular">
                    <MaybeNull>
                      {data?.walletPaymentMethods.map((wallet, index) => {
                        if (!isSupportedWalletCurrencyForBilling(wallet)) {
                          return null
                        }
                        return (
                          <Trans
                            key={index}
                            i18nKey="modals/settings/billing:invoice.selectPaymentMethod.linkedWallet.availableBalance"
                            values={{
                              amount: money({
                                number: wallet.balance.amount,
                                variant: wallet.balance.currency,
                                options: {
                                  symbol: true,
                                  signDisplay: false,
                                  padded: false,
                                },
                              }),
                            }}
                          />
                        )
                      })}
                    </MaybeNull>
                  </div>
                </div>
              </div>
            </div>
          </SelectCard>
        </SkeletonBox>
      </GraphQLErrorBoundary>
    </div>
  )
}

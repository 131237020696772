import { FeatureSwitch } from '@services/feature-switch'
import { RESTRICTED, PUBLIC, PUBLIC_ONLY } from '@services/permissions'

import type { Section } from '../types'

export const createAccount: Section = {
  label: 'layout:page.get-started.create-account.title',
  route: '/get-started/create-account',
  permissions: FeatureSwitch.businessAccountCreationEnabled()
    ? PUBLIC_ONLY
    : RESTRICTED,
  segment: {
    page: 'Create Account',
  },
}

export const getStartedSection: Section = {
  label: 'layout:page.get-started.title',
  route: '/get-started',
  permissions: FeatureSwitch.platformSelectionEnabled() ? PUBLIC : RESTRICTED,
  pages: [createAccount],
  segment: {
    page: 'Get Started',
  },
}

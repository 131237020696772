import { Button, Card } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface AddApprovalUsersCtaProps {
  /**
   * Custom style.
   */
  className?: string
}

export const AddApprovalUsersCta: React.FC<AddApprovalUsersCtaProps> = ({
  className,
}) => {
  const { t } = useTranslation('modals.vault')

  return (
    <Card className={className} variant="dashed/base">
      <Card.Content>
        <div>
          <h3 className="text-2xl leading-7 text-black-600 font-circular-bold">
            {t`createVault.approval.addApprovalUsersCta.title`}
          </h3>
          <p className="mt-4 text-base text-black-600 text-opacity-70 font-circular-regular">
            {t`createVault.approval.addApprovalUsersCta.description`}
          </p>
          <ModalLink pathname={routes.vault.createVault.permissions}>
            <Button className="mt-4" variant="secondary">
              {t`createVault.approval.addApprovalUsersCta.cta`}
            </Button>
          </ModalLink>
        </div>
      </Card.Content>
    </Card>
  )
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../../../Create/Create'
import { useLinkBankAccount } from '../../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Cbit Wallet Address.
   */
  walletAddress: y.string().required(),
})

export type CbitFormValues = y.InferType<typeof schema>

export interface CbitFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: CbitFormValues) => void
}

export const CbitForm: React.FC<CbitFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const [createBankAccount] = useLinkCreate()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<CbitFormValues>({
    schema,
    defaultValues: {
      ...information.cbit,
    },
  })

  const onFormSubmit = useCallback(
    (cbit: CbitFormValues) => {
      // Set data to context
      setInformation({ cbit })

      // Pass data to submit callback (for testing)
      onSubmit?.(cbit)

      // Create Bank Account
      createBankAccount()
    },
    [createBankAccount, onSubmit, setInformation],
  )

  return (
    <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
      {/* Bank Account Nickname */}
      <Form.Input
        className="w-full"
        data-testid="input-nickname"
        label={t('link.information.nickname')}
        name="nickname"
      />

      {/* Bank Account Routing Number */}
      <Form.Input
        className="w-full"
        data-testid="input-walletAddress"
        label={t('link.information.walletAddress.cbit')}
        name="walletAddress"
      />

      {/* Continue */}
      <Form.SubmitButton
        className="mt-6 w-64"
        variant="primary"
      >{t`common:continue`}</Form.SubmitButton>
    </Form>
  )
}

import { useMemo } from 'react'

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { WalletApprovalProposalApprovalMethod } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const ApprovalMethodsSchema =
  y.mixed<WalletApprovalProposalApprovalMethod>()

export interface ApprovalMethodsProps {
  /**
   * Custom Style?
   */
  className?: string
}

const Dropdown = createFormDropdown()

export const ApprovalMethods: React.FC<ApprovalMethodsProps> = ({
  className,
}) => {
  const { t } = useTranslation('forms')

  const dropdownItems = useMemo(
    () =>
      [
        WalletApprovalProposalApprovalMethod.WEB_APP,
        WalletApprovalProposalApprovalMethod.MOBILE_APP,
      ].map((value) => {
        return {
          label: t(`dropdown.wallet.approvalMethods.items.${value}`),
          value,
        }
      }),
    [t],
  )

  return (
    <Dropdown
      className={className}
      items={dropdownItems}
      label={t(`dropdown.wallet.approvalMethods.label`)}
      name="proposalApprovalMethod"
    />
  )
}

import { useMemo } from 'react'

import { WalletApprovalPolicyRole } from '@shared/graphql'

import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'

export const usePermissionValidate = () => {
  const [{ userLimits = [], touched }] = useWalletApprovalPolicy()

  const atLeastOneApprover = useMemo(
    () =>
      userLimits.some(({ permissions }) =>
        permissions.includes(WalletApprovalPolicyRole.APPROVER),
      ),
    [userLimits],
  )

  const atLeastOneInitiator = useMemo(
    () =>
      userLimits.some(({ permissions }) =>
        permissions.includes(WalletApprovalPolicyRole.OPERATOR),
      ),
    [userLimits],
  )

  const isValid = atLeastOneInitiator
  const isTouched = touched?.userLimits
  const isEmpty = userLimits.length === 0 || !isTouched

  return {
    isValid,
    isEmpty,
    atLeastOneApprover,
    atLeastOneInitiator,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

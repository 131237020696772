import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { useBlockchainRecipientAddressesQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainRecipientAddress } from '@shared/graphql'

export const BlockchainRecipientAddressesSchema =
  y.mixed<BlockchainRecipientAddress>()

export type RecipientAddressesFormValues = y.InferType<
  typeof BlockchainRecipientAddressesSchema
>

const Dropdown = createFormDropdown()

export type RecipientAddressesProps = Omit<
  React.ComponentProps<typeof Dropdown>,
  'items'
>

export const RecipientAddresses: React.FC<RecipientAddressesProps> = ({
  label,
  ...props
}) => {
  const { t } = useTranslation('forms')
  const [{ currency }] = useCurrentCurrency()

  const { data, loading } = useBlockchainRecipientAddressesQuery({
    variables: {
      input: {
        currency,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  const items = useMemo(() => {
    return (
      data?.blockchainRecipientAddresses.map((address) => ({
        label: address.description ?? '',
        value: address,
      })) ?? []
    )
  }, [data?.blockchainRecipientAddresses])

  return (
    <SkeletonBox className="h-10 w-full" loading={loading}>
      <Dropdown
        data-testid="recipient-addresses-dropdown"
        items={items}
        label={label ?? t`dropdown.blockchain.recipientAddresses.label`}
        {...props}
      />
    </SkeletonBox>
  )
}

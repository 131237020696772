import { Modal } from '@circlefin/components'

export interface BaseTitleLayoutProps
  extends Pick<React.ComponentProps<typeof Modal.Body>, 'variant'> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Modal Title.
   */
  title: string
}

/**
 * BaseTitle Modal Layout.
 */
export const BaseTitle: React.FC<BaseTitleLayoutProps> = ({
  children,
  title,
  variant,
}) => {
  return (
    <>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body variant={variant}>{children}</Modal.Body>
    </>
  )
}

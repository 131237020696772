import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useSetBlockchainRecipientAddressWithMetadataMutation } from '@shared/graphql'
import { setBlockchainRecipientAddressWithMetadata as setBlockchainRecipientAddressWithMetadataUpdate } from '@shared/graphql.server/updates'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { EditRecipientForm } from './EditRecipient.Form'

import type { EditFormValues } from './EditRecipient.Form'
import type { BlockchainRecipientAddressWithMetadata } from '@shared/graphql'

export interface EditRecipientQueryProps {
  /**
   * Recipient Address to be edited.
   */
  recipientAddressWithMetadata?: BlockchainRecipientAddressWithMetadata
}

export const EditRecipient: React.FC<EditRecipientQueryProps> = ({
  recipientAddressWithMetadata,
}: EditRecipientQueryProps) => {
  const modal = useModal()

  const { t } = useTranslation('address-book/modals')

  const [setBlockchainRecipientAddressWithMetadata, { loading, error }] =
    useSetBlockchainRecipientAddressWithMetadataMutation({
      onCompleted: () => {
        modal.close()
      },
      onError: () => ({}),
      update: setBlockchainRecipientAddressWithMetadataUpdate,
    })

  const handleSubmit = useCallback(
    (values: EditFormValues) => {
      if (recipientAddressWithMetadata) {
        void setBlockchainRecipientAddressWithMetadata({
          variables: {
            input: {
              id: recipientAddressWithMetadata.id,
              description: values.name,
              email: values.email,
              owner: recipientAddressWithMetadata.owner,
            },
          },
        })
      }

      modal.close({ context: 'onConfirm' })
    },
    [
      modal,
      recipientAddressWithMetadata,
      setBlockchainRecipientAddressWithMetadata,
    ],
  )

  const handleClose = useCallback(() => {
    modal.close({ context: 'onDismiss' })
  }, [modal])

  return (
    <BaseTitle title={t`editRecipient.title`}>
      <PropsErrorBoundary<EditRecipientQueryProps>
        props={{ recipientAddressWithMetadata }}
      >
        {({ recipientAddressWithMetadata }) => (
          <>
            <p
              className={classNames('text-sm text-center text-red-500 pt-1', {
                invisible: !error,
                visible: error,
              })}
              data-testid="error-message"
            >
              {t(
                (error?.graphQLErrors?.[0]?.extensions?.i18n as string) ??
                  'common:generalError',
              )}
            </p>
            <EditRecipientForm
              loading={loading}
              onCancel={handleClose}
              onSubmit={handleSubmit}
              recipientAddressWithMetadata={recipientAddressWithMetadata}
            />
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { useCallback } from 'react'

import { useStatesQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { StatesInput } from '@shared/graphql'

interface UseStatesProps extends StatesInput {
  /**
   * Format locale.
   */
  locale?: 'en'
}

/**
 * States Hook.
 */
export const useStates = ({ locale, ...filter }: UseStatesProps) => {
  const { t } = useTranslation('states')

  // Query Graphql States with priority to cache
  const query = useStatesQuery({
    variables: { filter },
    fetchPolicy: 'cache-first',
  })

  // data format
  const states = {
    /**
     * Format to Dropdown.
     */
    toDropdown: useCallback(() => {
      if (query.data?.states?.codes) {
        return (
          query.data.states.codes
            .map((code) => ({
              label: t(code),
              value: code,
            }))
            // Alphabetic Label Sort
            .sort((a, b) => (a.label < b.label ? -1 : 1))
        )
      }
      return []
    }, [t, query.data?.states?.codes]),
  }
  return { ...query, states }
}

import { FeatureSwitch } from '@services/feature-switch'
import {
  PREQUAL_ONBOARDING,
  PUBLIC_ONLY,
  RESTRICTED,
} from '@services/permissions'

import type { Section } from '../types'

export const welcome: Section = {
  label: 'layout:page.account-setup.welcome.title',
  route: '/account-setup/welcome',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Welcome',
  },
}

export const confirm: Section = {
  label: 'layout:page.account-setup.confirm.title',
  route: '/account-setup/confirm',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Confirm Business Details',
  },
}

export const confirmRole: Section = {
  label: 'layout:page.account-setup.confirm.title',
  route: '/account-setup/confirm-role',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Confirm Business Details',
  },
}

export const securityDownload: Section = {
  label: 'layout:page.account-setup.security-download.title',
  route: '/account-setup/security-download',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Security - Download',
  },
}

export const securityActivate: Section = {
  label: 'layout:page.account-setup.security-activate.title',
  route: '/account-setup/security-activate',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Security - Activate',
  },
}

export const nonAuthRep: Section = {
  label: 'layout:page.account-setup.non-auth-rep.title',
  route: '/account-setup/non-authorized-rep',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Non-Authorized Rep',
  },
}

export const awaitingReview: Section = {
  label: 'layout:page.account-setup.awaiting-review.title',
  route: '/account-setup/awaiting-review',
  permissions: FeatureSwitch.inviteOnlyPhase2Enabled()
    ? RESTRICTED
    : PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Awaiting Business Review',
  },
}

export const error: Section = {
  label: 'layout:page.account-setup.error.title',
  route: '/account-setup/error',
  permissions: PREQUAL_ONBOARDING,
  segment: {
    page: 'Account Setup - Error',
  },
}

export const activateAccount: Section = {
  label: 'layout:page.activateAccount',
  route: '/activate-account/[activationToken]',
  permissions: PUBLIC_ONLY,
  segment: {
    page: 'activate account',
  },
}

export const accountSetupSection: Section = {
  label: 'layout:page.account-setup.title',
  route: '/account-setup',
  permissions: PREQUAL_ONBOARDING,
  pages: [
    welcome,
    confirm,
    confirmRole,
    securityDownload,
    securityActivate,
    nonAuthRep,
    awaitingReview,
    error,
    activateAccount,
  ],
  segment: {
    page: 'Account Setup',
  },
}

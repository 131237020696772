import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { VaultDocument, useDiscardDraftPolicyMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface DiscardDraftPolicyProps {
  /**
   * ID of the vault the policy belongs to.
   */
  vaultId?: string
  /**
   * ID of the draft policy.
   */
  vaultPolicyId?: string
}

export const DiscardDraftPolicy: React.FC<DiscardDraftPolicyProps> = ({
  vaultId,
  vaultPolicyId,
}) => {
  const { t } = useTranslation('modals.vault')
  const { close } = useModal()

  const handleCancel = useCallback(() => {
    close({ context: 'onDismiss' })
  }, [close])

  const [discardDraftPolicy, { loading }] = useDiscardDraftPolicyMutation({
    variables: {
      vaultId: vaultId ?? '',
      vaultPolicyId: vaultPolicyId ?? '',
    },
    onCompleted: handleCancel,
    refetchQueries: [
      {
        query: VaultDocument,
        variables: {
          id: vaultId,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  const handleDiscard = useCallback(() => {
    void discardDraftPolicy()
  }, [discardDraftPolicy])

  return (
    <>
      <Modal.Title>{t`vaultDetails.discardDraftPolicy.title`}</Modal.Title>
      <Modal.Body>
        <p className="mt-2 text-center">{t`vaultDetails.discardDraftPolicy.description`}</p>
      </Modal.Body>
      <Modal.Footer variant="stretch-equal">
        <Button
          disabled={loading}
          onClick={handleCancel}
          variant="secondary"
        >{t`common:cancel`}</Button>
        <Button
          loading={loading}
          onClick={handleDiscard}
          variant="primary"
        >{t`common:discard`}</Button>
      </Modal.Footer>
    </>
  )
}

import { Content } from '@shared/components/layout'
import {
  useVaultDeviceRegistrationStatusQuery,
  useWalletBackupStatusQuery,
  VaultDeviceRegistrationStatusCode,
} from '@shared/graphql'
import { PageLayout } from '@shared/layout'

import { VaultsDashboardHeader } from '../../containers'

import type { AppLayoutType } from '@shared/layout/core'

// TODO: We shouldn't have query in the layout, it should move to the page or context
export const VaultDashboardLayout: AppLayoutType = ({ children }) => {
  const { data: { walletBackupStatus } = {} } = useWalletBackupStatusQuery({
    // do not used cached data
    fetchPolicy: 'network-only',
  })

  const {
    data: { vaultDeviceRegistrationStatus } = {},
    loading: vaultDeviceRegistrationLoading,
  } = useVaultDeviceRegistrationStatusQuery()

  const deviceRegistered =
    vaultDeviceRegistrationStatus?.statusCode ===
    VaultDeviceRegistrationStatusCode.READY

  return (
    <PageLayout>
      <Content>
        <VaultsDashboardHeader
          backupRequired={walletBackupStatus?.isWalletBackupRequired}
          hasPairedDevice={deviceRegistered}
          loading={vaultDeviceRegistrationLoading}
        />
        <div className="mt-8 w-full">{children}</div>
      </Content>
    </PageLayout>
  )
}

import { useCallback } from 'react'

import { Icon, SkeletonBox } from '@circlefin/components'
import { useCustomMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { vaultWalletDetailsSection } from '@services/sections/lib/vaults'
import { routes } from '@services/sections/modal/routes'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { CollapsibleBadge } from '@shared/components/common'
import {
  VaultWalletState,
  useVaultWalletBalanceQuery,
  TokenActivationState,
} from '@shared/graphql'
import classNames from 'classnames'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'
import * as Badges from '../../Badges'

import type { VaultWallet } from '@shared/graphql'

export interface VaultChildWalletRowProps {
  /**
   * The wallet that should be displayed.
   */
  wallet: VaultWallet
  /**
   * The id of the vault the wallet belongs to.
   */
  vaultId: string
  /**
   * The id of the parent wallet id.
   */
  parentWalletId: string
}

export const VaultChildWalletRow: React.FC<VaultChildWalletRowProps> = ({
  wallet,
  vaultId,
  parentWalletId,
}) => {
  const { t } = useTranslation('vaults')
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const { router } = useModal()
  const { vaultMetadata } = useVaultPermission()
  const { loading, data } = useVaultWalletBalanceQuery({
    variables: {
      vaultId,
      walletId: wallet.id,
    },
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
  })
  const isFrozenOrArchived = wallet.frozen || wallet.archived

  const handleBackupWalletsClicked = useCallback(() => {
    router.push(routes.vault.walletBackup.confirmation)
  }, [router])

  const renderBadge = useCallback(() => {
    switch (wallet.state) {
      case VaultWalletState.DRAFT:
      case VaultWalletState.PENDING_APPROVAL:
        return (
          <CollapsibleBadge
            collapsedText={t`vaultChildWalletRow.notOperational.collapsed`}
            variant="info"
          >
            {t`vaultChildWalletRow.notOperational.notCollapsed`}
          </CollapsibleBadge>
        )
      case VaultWalletState.ACTIVE:
        if (wallet.archived) {
          return (
            <CollapsibleBadge
              collapsedText={t`vaultChildWalletRow.archived.collapsed`}
              variant="disabled"
            >
              {t`vaultChildWalletRow.archived.notCollapsed`}
            </CollapsibleBadge>
          )
        } else if (!wallet.backedUp) {
          return (
            <CollapsibleBadge
              collapsedText={t`vaultChildWalletRow.notBackedUp.collapsed`}
              variant="warning"
            >
              <span>{t`vaultChildWalletRow.notBackedUp.notCollapsed`}</span>
              {
                /**
                 * Only the creator of the vault should be able to see the backup wallets button in here.
                 */
                vaultMetadata?.isCreator && (
                  <button
                    className="ml-0.5 text-yellow-600 underline font-circular-regular"
                    onClick={handleBackupWalletsClicked}
                  >
                    {t`vaultChildWalletRow.notBackedUp.backup`}
                  </button>
                )
              }
            </CollapsibleBadge>
          )
        } else if (wallet.frozen) {
          return (
            <CollapsibleBadge
              collapsedText={t`vaultChildWalletRow.frozen.collapsed`}
              variant="alert"
            >
              <span>
                <Trans
                  components={{ b: <b /> }}
                  i18nKey="vaults:vaultChildWalletRow.frozen.notCollapsed"
                />
              </span>
            </CollapsibleBadge>
          )
        } else {
          if (
            wallet.tokenActivationState ===
            TokenActivationState.PENDING_ACTIVATION
          ) {
            return <Badges.Wallet.PendingActivation />
          }

          if (
            wallet.tokenActivationState === TokenActivationState.NOT_ACTIVATED
          ) {
            return (
              <Badges.Wallet.ActivationRequired
                blockchain={wallet.blockchain}
                parentWalletId={parentWalletId}
                walletId={wallet.id}
              />
            )
          }
        }
    }

    return null
  }, [
    wallet.frozen,
    wallet.archived,
    wallet.blockchain,
    wallet.backedUp,
    wallet.id,
    wallet.tokenActivationState,
    wallet.state,
    parentWalletId,
    t,
    vaultMetadata?.isCreator,
    handleBackupWalletsClicked,
  ])

  return (
    <div
      className={classNames(
        'grid grid-cols-12 items-center py-4 px-3 border-t last:border-b border-black-25',
        {
          'text-black-300': isFrozenOrArchived,
          'text-black-600': !isFrozenOrArchived,
        },
      )}
    >
      <div className="col-span-7 flex items-center">
        <Icon
          className={classNames('mr-2', { 'opacity-50': isFrozenOrArchived })}
          name={wallet.icon}
          size={30}
        />
        <Link
          className={classNames(
            'inline-block mr-3 text-sm font-circular-book',
            {
              'text-black-300': isFrozenOrArchived,
              'text-black-600': !isFrozenOrArchived,
            },
          )}
          href={{
            pathname: vaultWalletDetailsSection.route,
            query: {
              vaultId,
              walletId: wallet.id,
            },
          }}
        >
          {wallet.assetMetadata.name}
        </Link>
        {renderBadge()}
      </div>
      <div
        className="col-span-3 text-sm font-circular-book"
        data-testid="vault-wallet-native"
      >
        <SkeletonBox className="h-5 w-2/3" loading={loading}>
          {data &&
            customMoney({
              number: data.vaultWalletBalance.native.amount,
              currencyConfig: {
                name: data.vaultWalletBalance.native.asset.symbol,
                decimals: data.vaultWalletBalance.native.asset.decimals,
                isCryptoCurrency: true,
              },
              options: {
                padded: false,
              },
            })}
        </SkeletonBox>
      </div>
      <div
        className={classNames('col-span-1 text-sm', {
          'flex justify-between items-center': !loading,
        })}
        data-testid="vault-wallet-fiat"
      >
        <SkeletonBox className="h-5 w-2/3" loading={loading}>
          {data && (
            <>
              {customMoney({
                number: data.vaultWalletBalance.fiat.amount,
                currencyConfig: {
                  name: data.vaultWalletBalance.fiat.asset.symbol,
                  decimals: data.vaultWalletBalance.fiat.asset.decimals,
                  isCryptoCurrency: false,
                },
                options: {
                  padded: false,
                },
              })}
            </>
          )}
        </SkeletonBox>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Link
          className="text-black-400"
          href={{
            pathname: vaultWalletDetailsSection.route,
            query: {
              vaultId,
              walletId: wallet.id,
            },
          }}
        >
          <Icon name="ChevronRightSolid" size={18} />
        </Link>
      </div>
    </div>
  )
}

import { SkeletonBox } from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Locale } from '@circlefin/money/variants'
import type { Amount } from '@shared/graphql'

export interface TransactionHeaderProps {
  /**
   * Transaction Amount.
   */
  amount?: Amount
  /**
   * Transaction Create Date.
   */
  createDate?: Date
  /**
   * Is Loading?
   */
  loading?: boolean
  /**
   * Display +/- sign.
   */
  signDisplay?: boolean
}

export const TransactionHeader: React.FC<TransactionHeaderProps> = ({
  amount,
  createDate,
  loading = false,
  signDisplay = true,
}) => {
  const { t } = useTranslation('payments')
  const { locale } = useRouter()
  const { money } = useMoney({ locale: locale as Locale })
  const { date } = useDate({ locale: locale as Locale })

  return (
    <div className="grid grid-cols-2">
      <div className="grid grid-rows-2">
        {amount && (
          <>
            <div className="h-9 text-3xl leading-9 text-black-600 font-circular-medium">
              <SkeletonBox className="h-9 w-64" loading={loading}>
                {money({
                  number: amount.amount,
                  variant: amount.currency,
                  options: {
                    symbol: false,
                    signDisplay,
                  },
                })}
                <span className="ml-2 text-xl leading-7 text-black-200 font-circular-black">
                  {amount.currency}
                </span>
              </SkeletonBox>
            </div>
            <div className="mt-1 text-xl leading-7 text-black-200 font-circular-regular">
              {t('transactionHeader.total')}
            </div>
          </>
        )}
      </div>
      <div className="text-right leading-7 text-black-500 font-circular-bold">
        {createDate && (
          <SkeletonBox className="ml-auto h-7 w-80" loading={loading}>
            <span>{t('transactionHeader.asOf')}</span>
            {date(createDate, 'datetimeWithYear')}
          </SkeletonBox>
        )}
      </div>
    </div>
  )
}

import { useMemo } from 'react'

import { Sidenav } from '@circlefin/components'
import { AccessPermissions, usePermission } from '@circlefin/permissions'
import useTranslation from 'next-translate/useTranslation'

import { Item } from '../Item/Item'

import type { Section } from '@services/sections/types'

export interface GroupProps {
  /**
   * Group Header.
   */
  header: string
  /**
   * Group Items.
   */
  items: Section[]
}

export const Group: React.FC<GroupProps> = (group) => {
  const { t } = useTranslation()
  const [, { checkPermission }] = usePermission({
    access: AccessPermissions.protected,
  })

  const items = useMemo(() => {
    return group.items.filter(
      (item) => checkPermission(item.permissions).isAuthorized,
    )
  }, [checkPermission, group.items])

  if (items.length === 0) {
    return null
  }

  return (
    <Sidenav.Group heading={t(group.header)}>
      {items.map((section, i) => (
        <Item key={i} {...section} />
      ))}
    </Sidenav.Group>
  )
}

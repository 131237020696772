import classNames from 'classnames'

import { HeaderPublicLogOut } from './HeaderPublic.LogOut/HeaderPublic.LogOut'
import { HeaderPublicLogo } from './HeaderPublic.Logo/HeaderPublic.Logo'
import { HeaderPublicSignIn } from './HeaderPublic.SignIn/HeaderPublic.SignIn'

interface SubComponents {
  Logo: typeof HeaderPublicLogo
  SignIn: typeof HeaderPublicSignIn
  LogOut: typeof HeaderPublicLogOut
}

export interface HeaderPublicProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Variant (height) of the header wrapper.
   *
   * `base` = default 80px height header
   * `tall` = alt 128px height header (i.e., onboarding).
   */
  size?: 'base' | 'tall'
}

export const HeaderPublic: React.FC<HeaderPublicProps> & SubComponents = ({
  children,
  size = 'base',
}) => (
  <header
    className={classNames('relative grid grid-cols-3 px-6 w-full', {
      'h-20 items-center': size === 'base',
      'sm:py-7 sm:h-32 h-20': size === 'tall',
    })}
  >
    {children}
  </header>
)

HeaderPublic.displayName = 'HeaderPublic'
HeaderPublic.Logo = HeaderPublicLogo
HeaderPublic.SignIn = HeaderPublicSignIn
HeaderPublic.LogOut = HeaderPublicLogOut

import { PROTECTED } from '@services/permissions'

import type { Section } from '../types'

export const resetPinCodeSection: Section = {
  label: 'layout:page.reset-pin-code.title',
  route: '/reset-pin-code/[activationToken]',
  permissions: PROTECTED,
  segment: {
    page: 'Reset PIN Code - Token',
  },
  takeover: {
    pinCode: false,
    periodicReview: false,
  },
}

// TODO: possibly move this out to components-web, that is why this component does not directly interact with useRouter hook
// TODO Refactor API for this component in https://circlepay.atlassian.net/browse/BBSS-391?atlOrigin=eyJpIjoiYzM4MmUzNWM0ZmE3NGZhYjk5MGM1MjJiYTg3NTU5OWIiLCJwIjoiaiJ9
import { TourGuideBody } from './TourGuideBody/TourGuideBody'

import type { TourGuideBodyProps } from './TourGuideBody/TourGuideBody'

export interface TourGuideProps extends TourGuideBodyProps {
  /**
   * Is tour visible.
   */
  isVisible: boolean
}

export const TourGuide: React.FC<TourGuideProps> = ({ isVisible, ...rest }) => {
  if (!isVisible) {
    return null
  }

  return <TourGuideBody {...rest} />
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { SegmentEvents, useSegment } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import {
  WalletApprovalPolicyDocument,
  useDiscardWalletApprovalDraftPolicyMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface DiscardDraftPolicyProps {
  /**
   * Policy id.
   */
  policyId?: string
  /**
   * Currency.
   */
  currency?: Currency
}

export const DiscardDraftPolicy: React.FC<DiscardDraftPolicyProps> = (
  props,
) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const { close } = useModal()
  const { track } = useSegment()

  const handleCancel = useCallback(() => {
    close({ context: 'onDismiss' })
  }, [close])

  const [discardDraftPolicy, { loading }] =
    useDiscardWalletApprovalDraftPolicyMutation()

  const handleDiscard = useCallback(
    (policyId: string, currency: Currency) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        track(SegmentEvents.DiscardDraftPolicyClicked, { event })

        void discardDraftPolicy({
          variables: {
            input: {
              policyId,
              currency,
            },
          },
          onCompleted: handleCancel,
          refetchQueries: [
            {
              query: WalletApprovalPolicyDocument,
              variables: {
                currency,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
    [discardDraftPolicy, handleCancel, track],
  )

  return (
    <BaseTitle title={t`discardDraftPolicy.title`}>
      <PropsErrorBoundary props={props} variant="page">
        {({ policyId, currency }) => (
          <>
            <p className="mt-2 text-center">{t`discardDraftPolicy.description`}</p>

            <Modal.Footer variant="stretch-equal">
              <Button
                disabled={loading}
                onClick={handleCancel}
                variant="secondary"
              >{t`common:cancel`}</Button>
              <Button
                loading={loading}
                onClick={handleDiscard(policyId, currency)}
                variant="primary"
              >{t`common:discard`}</Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

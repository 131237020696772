import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Verite Permissions will make the feature available only in the environments
 * with the environment variable VERITE_ENABLED=true.
 *
 */
export const VERITE_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.VERITE_API_ROLE],
}

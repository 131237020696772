import { Card, Heading, Icon, TextLink } from '@circlefin/components'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const PersonalSelect: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  return (
    <Card className="h-full w-87 min-w-min bg-bold-neutral bg-[url('/images/USDCSiteBackground.svg')] bg-cover">
      <Card.Content>
        <Icon name="USDC" size={48} />

        <Heading className="mt-4" variant="title/sm">
          <Heading.Header
            as="h3"
            className="text-white"
          >{t`productSelect.personal.title`}</Heading.Header>
        </Heading>

        <p className="text-white type-body-sm">
          {t`productSelect.personal.description`}
        </p>
      </Card.Content>

      <Card.Footer className="mt-3">
        <TextLink.Link
          className="w-full text-white underline"
          href={t`productSelect.personal.cta.link`}
          onClick={trackLinkClick}
          variant="primary"
        >
          {t`productSelect.personal.cta.label`}
          <Icon name="ExternalLinkOutline" />
        </TextLink.Link>
      </Card.Footer>
    </Card>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import {
  BlockchainRecipientAddressOwner,
  BlockchainRecipientAddressWithMetadataDocument,
  BlockchainRecipientAddressesWithMetadataDocument,
  useCreateBlockchainRecipientAddressWithMetadataMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { AddressDetailsNonCustomerForm } from './AddressDetailsNonCustomer.Form'

import type { AddressDetailsNonCustomerFormProps } from './AddressDetailsNonCustomer.Form'

export const AddressDetailsNonCustomer: React.FC = () => {
  const { t } = useTranslation('address-book/modals')
  const modal = useModal()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createBlockchainRecipientAddressMutation, { loading }] =
    useCreateBlockchainRecipientAddressWithMetadataMutation({
      refetchQueries: [
        BlockchainRecipientAddressWithMetadataDocument,
        BlockchainRecipientAddressesWithMetadataDocument,
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        mfaSuccess()
      },
      onError: (error) => {
        mfaError({ error })
      },
    })

  /**
   * Submit form and run mutation.
   */
  const handleSubmit: AddressDetailsNonCustomerFormProps['onSubmit'] =
    useCallback(
      (values) => {
        withMfa({
          variant: 'FullScreen',
          onComplete: (code) => {
            void createBlockchainRecipientAddressMutation({
              variables: {
                input: {
                  address: values.address,
                  chain: values.blockchain.chain,
                  currency: values.currency,
                  description: values.description ?? '', // TODO: Make it optional in graphql with fallback to string
                  email: values.email,
                  owner: BlockchainRecipientAddressOwner.non_customer_entity,
                  ...(values.memo && { addressTag: values.memo }),
                },
              },
              context: context(code),
            })
          },
          onAbort: modal.close,
        })
      },
      [withMfa, context, createBlockchainRecipientAddressMutation, modal.close],
    )

  return (
    <FullScreen totalSteps={4}>
      <Center className="mb-6 text-center" variant="horizontal">
        <h2 className="text-2xl text-black-900 font-circular-bold">
          {t`addAddress.addressDetails.non_customer_entity.title`}
        </h2>
        <h3 className="mt-2 font-circular text-lg leading-6 text-black-400">
          {t`addAddress.addressDetails.non_customer_entity.subtitle`}
        </h3>
      </Center>
      <AddressDetailsNonCustomerForm
        loading={loading}
        onSubmit={handleSubmit}
      />
    </FullScreen>
  )
}

import { FixedBanner, TextLink } from '@circlefin/components'
import { expressSection } from '@services/sections/lib/express'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { useRedemptionOnlyExpress } from '../../hooks/redemption-only-express'

export const SetupExpressBanner: React.FC = () => {
  const { t } = useTranslation('custody/common')
  const { showSetupExpressBanner } = useRedemptionOnlyExpress()

  return (
    <FixedBanner
      data-testid="fixed-banner-setup-rolr-express"
      status="info"
      visible={showSetupExpressBanner}
    >
      <FixedBanner.Title>{t('setupExpress.title')}</FixedBanner.Title>
      <FixedBanner.Description>
        {t('setupExpress.description')}
        <Link href={expressSection.route} legacyBehavior passHref>
          <TextLink.Link size="sm" variant="primary">
            {t('setupExpress.link')}
          </TextLink.Link>
        </Link>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import { useCallback } from 'react'

import { Button, Card, DescriptionList } from '@circlefin/components'
import { useCustomMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { vaultWalletDetailsSection } from '@services/sections/lib/custody'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useCreateDirectCustodySourceTransferMutation } from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../../SendOnChain.Context'
import { Description } from '../Description'
import { Label } from '../Label'

export interface DirectSourceReviewProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const DirectSourceReview: React.FC<DirectSourceReviewProps> = ({
  children,
}) => {
  const { t } = useTranslation('modals.vault')
  const { customMoney } = useCustomMoney()
  const { idempotencyKey } = useIdempotencyKey()
  const [{ assetWallet, transactionFees, amount }, , { derivedDestination }] =
    useSendOnChain()
  const modal = useModal()
  const [createTransfer, { error, reset }] =
    useCreateDirectCustodySourceTransferMutation({
      onCompleted: () => {
        modal.router.push({
          pathname: routes.vault.transfer.sendOnChain.finished,
          query: {
            totalSteps: 6,
            isToVaultWalletTransfer: true,
            actionLink: {
              pathname: vaultWalletDetailsSection.route,
              query: {
                vaultId: assetWallet?.vaultMetadata.id,
                walletId: assetWallet?.id,
              },
            },
          },
        })
      },
      // To avoid uncaught error
      onError: () => null,
    })

  /**
   * Invoke transfer mutation.
   */
  const handleTransferClick = useCallback(() => {
    void createTransfer({
      variables: {
        input: {
          refId: idempotencyKey,
          walletId: assetWallet?.id ?? '',
          vaultId: assetWallet?.vaultMetadata.id ?? '',
          toAddressRefId: derivedDestination?.id ?? '',
          amount: amount ?? 0,
          transactionFee: Number(transactionFees?.maximumFee.native.amount),
          gasPrice: Number(transactionFees?.gasPrice?.amount ?? 0),
        },
      },
    })
  }, [
    amount,
    createTransfer,
    derivedDestination?.id,
    idempotencyKey,
    transactionFees,
    assetWallet,
  ])

  return (
    <GraphQLErrorBoundary error={error} retry={reset}>
      <Card className="mt-10 w-192 px-4 py-2" variant="secondary/base">
        <DescriptionList
          className="bg-transparent"
          data-testid="description-list"
        >
          {children}
          <Label>
            {t`transfer.sendOnChain.reviewTransferDetails.maximumFee`}
          </Label>
          <Description>
            {transactionFees &&
              (transactionFees.availableSpeeds?.length === 0
                ? t`transfer.sendOnChain.reviewTransferDetails.feeUnavailable`
                : `${customMoney({
                    number: transactionFees.maximumFee.native.amount,
                    currencyConfig: {
                      name: transactionFees.maximumFee.native.asset.symbol,
                      decimals:
                        transactionFees.maximumFee.native.asset.decimals,
                      isCryptoCurrency: true,
                    },
                    options: { padded: false },
                  })} (${customMoney({
                    number: transactionFees.maximumFee.fiat.amount,
                    currencyConfig: {
                      name: transactionFees.maximumFee.fiat.asset.symbol,
                      decimals: transactionFees.maximumFee.fiat.asset.decimals,
                      isCryptoCurrency: true,
                    },
                    options: { padded: false },
                  })})`)}
          </Description>
          <Label>{t`transfer.sendOnChain.reviewTransferDetails.amount`}</Label>
          <Description>
            <span data-testid="amount">
              <span className="font-circular-bold">
                {transactionFees &&
                  customMoney({
                    number: transactionFees.amount.native.amount,
                    currencyConfig: {
                      name: transactionFees.amount.native.asset.symbol,
                      decimals: transactionFees.amount.native.asset.decimals,
                      isCryptoCurrency: true,
                    },
                    options: { padded: false },
                  })}
              </span>
              &nbsp; (
              {transactionFees &&
                customMoney({
                  number: transactionFees.amount.fiat.amount,
                  currencyConfig: {
                    name: transactionFees.amount.fiat.asset.symbol,
                    decimals: transactionFees.amount.fiat.asset.decimals,
                    isCryptoCurrency: true,
                  },
                  options: { padded: false },
                })}
              )
            </span>
          </Description>
        </DescriptionList>
      </Card>
      <Center className="mt-10 text-center" variant="horizontal">
        <Button
          className="h-10 w-60"
          data-testid="transfer-button"
          onClick={handleTransferClick}
          variant="primary"
        >
          {t`transfer.sendOnChain.reviewTransferDetails.transfer`}
        </Button>
      </Center>
    </GraphQLErrorBoundary>
  )
}

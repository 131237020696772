import { useCallback, useContext, useEffect, useState } from 'react'

import { CodeInput, Icon, SkeletonBox, Statement } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { FactorType, useFactorQuery } from '@shared/graphql'
import { MfaContext } from '@shared/mfa/lib/context'
import useTranslation from 'next-translate/useTranslation'

import { NoFactorError } from '../../../containers'
import { Layout } from '../../Layout/Layout'

export interface CodeQueryProps {
  /**
   * Verifying MFA.
   */
  verifying?: boolean
}

export const TotpCode: React.FC<CodeQueryProps> = ({ verifying = false }) => {
  const { t } = useTranslation('mfa')
  const { request } = useContext(MfaContext)
  const { events } = useModal()
  const { data, loading, error, refetch } = useFactorQuery({
    variables: {
      factorType: FactorType.TOKEN_SOFTWARE_TOTP,
    },
  })
  const [verify, setVerify] = useState<boolean>(verifying)

  useEffect(() => {
    // Call on modal close
    const onClose = () => {
      request.onAbort?.()
    }

    // Subscribe to modal close event
    events.on('onCloseEnd', onClose)

    return () => {
      // Unsubscribe to modal close event
      events.off('onCloseEnd', onClose)
    }
  }, [events, request])

  const onComplete = useCallback(
    (code: string) => {
      if (data?.factor) {
        setVerify(true)
        request.onComplete?.({
          type: 'totp',
          totpCode: {
            id: data.factor.id,
            code,
          },
        })
      }
    },
    [data?.factor, request],
  )

  if (verify) {
    return (
      <Layout title={t`verifyAccount`}>
        <div className="grid h-full content-center">
          <Statement
            iconName="CircleSpinnerSolid"
            padded={false}
            title={t`loading`}
            variant="page"
            loop
          />
        </div>
      </Layout>
    )
  }

  return (
    <Layout title={t`verifyAccount`}>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        {!loading && !data?.factor ? (
          <NoFactorError />
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <SkeletonBox
              className="mb-6 size-16 rounded-full"
              loading={loading}
            >
              <Icon.Circle
                className="mb-6"
                intent="accent-blue"
                name="DeviceMobileSolid"
                size="large"
              />
            </SkeletonBox>
            <h3 className="mb-4 text-lg font-circular-bold">
              <SkeletonBox className="h-7 w-60" loading={loading}>
                {t`factor.authenticator-app.enter-code.title`}
              </SkeletonBox>
            </h3>
            <p className="mb-6 text-black-500">
              <SkeletonBox
                className="h-5 w-80"
                loading={loading}
              >{t`factor.authenticator-app.enter-code.description`}</SkeletonBox>
            </p>
            <SkeletonBox className="h-11 w-64" loading={loading}>
              <CodeInput
                allowedCharacters="numeric"
                length={6}
                onComplete={onComplete}
              />
            </SkeletonBox>
          </div>
        )}
      </GraphQLErrorBoundary>
    </Layout>
  )
}

import { VerticalStepper } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { ProgressShellProps } from './ProgressShell'
import type { AccountSetupStep } from '../../../hooks/account-setup/context'

export interface InviteeStepperProps extends ProgressShellProps {
  /**
   * Determine if step is complete.
   */
  isStepComplete: (step: AccountSetupStep) => boolean
}

export const InviteeStepper: React.FC<InviteeStepperProps> = ({
  currentStep,
  isStepComplete,
}) => {
  const { t } = useTranslation('onboard/account-setup')

  return (
    <VerticalStepper isInteractive={false}>
      <VerticalStepper.Step
        isActive={
          currentStep === 'SECURITY_DOWNLOAD' ||
          currentStep === 'SECURITY_ACTIVATE'
        }
        isStepComplete={isStepComplete('SECURITY_ACTIVATE')}
        label={t`navigation.steps.securitySetUp`}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === 'SECURITY_DOWNLOAD'}
          isStepComplete={isStepComplete('SECURITY_DOWNLOAD')}
          label={t`navigation.steps.securityDownload`}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === 'SECURITY_ACTIVATE'}
          isStepComplete={isStepComplete('SECURITY_ACTIVATE')}
          label={t`navigation.steps.securityActivate`}
        />
      </VerticalStepper.Step>
    </VerticalStepper>
  )
}

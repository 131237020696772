import { useMemo } from 'react'

import { Button, SkeletonBox } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import {
  ACCOUNT_TRANSFER,
  BANK_WITHDRAW,
  BLOCKCHAIN_TRANSFER,
  CIRCLE_WALLETS_QUERY,
  ROLR_REDEMPTION_ONLY_ACCOUNT,
  STABLECOIN_EXPRESS_QUERY,
} from '@services/permissions'
import { CIRCLE_WALLET_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useActiveWalletApprovalPolicyQuery } from '@shared/graphql'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { Currency } from '@shared/graphql'

export interface TransferProps extends ButtonProps {
  /**
   * Is Loading?
   */
  loading?: boolean
  /**
   * Currency.
   */
  currency: Currency
  /**
   * This type will determine the permission set to check against.
   */
  flowType?: 'wire' | 'onchain' | 'both'
}

export const Transfer: React.FC<TransferProps> = ({
  children,
  loading = false,
  currency,
  flowType = 'both',
  disabled,
  ...props
}) => {
  const [, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )
  const [, { isAuthorized: hasExpressAccess }] = usePermission(
    STABLECOIN_EXPRESS_QUERY,
  )

  const transferPermissionSet = useMemo(() => {
    if (flowType === 'onchain') {
      return BLOCKCHAIN_TRANSFER
    }

    if (flowType === 'wire') {
      return BANK_WITHDRAW
    }

    return ACCOUNT_TRANSFER
  }, [flowType])

  const [, accountFundingPermission] = usePermission(transferPermissionSet)
  const [, circleWalletsPermission] = usePermission(CIRCLE_WALLETS_QUERY)

  const policyQuery = useActiveWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: !circleWalletsPermission.isAuthorized,
    pollInterval: CIRCLE_WALLET_DETAILS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  const canTransfer = useMemo(
    () =>
      policyQuery.data?.activeWalletApprovalPolicy?.canTransfer ??
      accountFundingPermission.isAuthorized,
    [
      accountFundingPermission.isAuthorized,
      policyQuery.data?.activeWalletApprovalPolicy?.canTransfer,
    ],
  )

  // ROLR customers are not allowed to do manual withdraw, do not show the transfer button to them
  if (isRolr && hasExpressAccess) {
    return null
  }

  return (
    <SkeletonBox
      className="h-10 w-28"
      loading={
        loading ||
        accountFundingPermission.loading ||
        circleWalletsPermission.loading ||
        policyQuery.loading
      }
    >
      <Button
        data-testid="account-funding-transfer-button"
        disabled={
          disabled || !accountFundingPermission.isAuthorized || !canTransfer
        }
        {...props}
      >
        {children}
      </Button>
    </SkeletonBox>
  )
}

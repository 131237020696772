import { useMemo } from 'react'

import { Icon, Chip } from '@circlefin/components'
import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { useDate, useMoney } from '@circlefin/hooks'
import { usePermission } from '@circlefin/permissions'
import { FX_LAUNCH_ENABLED } from '@services/permissions'
import { MaybeNull } from '@shared/components/common'
import {
  PaymentTransactionType,
  TransactionStatus,
  TransactionType,
  Currency,
} from '@shared/graphql'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { IconProps } from '@circlefin/components/lib/Icon'
import type { Locale } from '@circlefin/money/variants'
import type { FiatTransactionType, Maybe, Payment } from '@shared/graphql'

const columnHelper = createColumnHelper<Payment>()

/**
 * This allows us to normalize mixed fiat and token columns into only token columns.
 * @param currency - Currency.
 * @returns The relevant stablecoin currency (USDC or EURC), or the original currency if none.
 */
const currencyHelper = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
      return Currency.USDC
    case Currency.EUR:
      return Currency.EURC
    default:
      return currency
  }
}

export const usePaymentsActivityTableColumns = () => {
  const { t } = useTranslation('payments')
  const { locale } = useRouter()
  const { money } = useMoney({ locale: locale as Locale })
  const { date } = useDate({ locale: locale as Locale })
  const [, { isAuthorized: fxEnabled }] = usePermission(FX_LAUNCH_ENABLED)

  const textClassNames = 'text-sm leading-5 text-black-600'

  return useMemo(() => {
    /** Icon name. */
    const iconName: Record<TransactionType, IconProps['name']> = {
      [TransactionType.onchain_send]: 'LinkOutline',
      [TransactionType.onchain_receive]: 'LinkOutline',
      [TransactionType.wire_receive]: 'LibraryOutline',
      [TransactionType.wire_withdrawal]: 'LibraryOutline',
      [TransactionType.sen_receive]: 'LibraryOutline',
      [TransactionType.sen_withdrawal]: 'LibraryOutline',
      [TransactionType.signet_receive]: 'LibraryOutline',
      [TransactionType.signet_withdrawal]: 'LibraryOutline',
      [TransactionType.cbit_receive]: 'LibraryOutline',
      [TransactionType.cbit_withdrawal]: 'LibraryOutline',
      [TransactionType.pix_receive]: 'LibraryOutline',
      [TransactionType.pix_withdrawal]: 'LibraryOutline',
      [TransactionType.xpay_receive]: 'LibraryOutline',
      [TransactionType.xpay_withdrawal]: 'LibraryOutline',
      [TransactionType.rtp_receive]: 'LibraryOutline',
      [TransactionType.rtp_withdrawal]: 'LibraryOutline',
      [TransactionType.rtgs_receive]: 'LibraryOutline',
      [TransactionType.rtgs_withdrawal]: 'LibraryOutline',
      [TransactionType.sepa_receive]: 'LibraryOutline',
      [TransactionType.sepa_withdrawal]: 'LibraryOutline',
      [TransactionType.sepa_instant_receive]: 'LibraryOutline',
      [TransactionType.sepa_instant_withdrawal]: 'LibraryOutline',
      [TransactionType.redemption_fee_gross]: 'ReceiptInvoiceOutline',
      [TransactionType.redemption_fee_net]: 'ReceiptInvoiceOutline',
    }

    /**
     * Icon column.
     */
    const icon = columnHelper.accessor('type', {
      id: 'icon',
      header: t<string>(
        `${
          fxEnabled
            ? 'activity.columns.paymentTransactionType'
            : 'activity.columns.transactionType'
        }`,
      ),
      meta: {
        className: 'w-52',
      },
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <span className={classNames(textClassNames, 'font-circular-book')}>
            <Icon
              className="-mt-1 mr-4 inline text-black-600"
              name={iconName[value]}
              size={15}
            />
            {t(
              `activity.${
                fxEnabled ? 'newTransactionTypeLabels' : 'transactionTypeLabels'
              }.${value}`,
            )}
          </span>
        )
      },
    })

    /**
     * Amount column.
     */
    const amount = columnHelper.accessor('amount', {
      header: t<string>`activity.columns.amount`,
      meta: {
        className: 'w-48 whitespace-normal break-all',
      },
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <span className={classNames(textClassNames, 'font-circular-book')}>
            <MaybeNull>
              {value != null
                ? money({
                    number: value.amount,
                    variant: value.currency,
                    options: {
                      symbol: false,
                      signDisplay: true,
                    },
                  })
                : null}
            </MaybeNull>
          </span>
        )
      },
    })

    /**
     * Currency column.
     */
    const currency = columnHelper.accessor('amount.currency', {
      header: t<string>(
        `${fxEnabled ? 'activity.columns.stablecoin' : 'common:currency'}`,
      ),
      cell: ({ getValue }) => {
        return (
          <span className={classNames(textClassNames, 'font-circular-book')}>
            {fxEnabled ? currencyHelper(getValue()) : getValue()}
          </span>
        )
      },
    })

    /**
     * CreateDate column.
     */
    const createDate = columnHelper.accessor('createDate', {
      header: t<string>`activity.columns.createDate`,
      cell: ({ getValue }) => (
        <span className={classNames(textClassNames, 'font-circular-regular')}>
          {date(getValue())}
        </span>
      ),
    })

    /**
     * PaymentTransactionType column.
     */
    const paymentTransactionType = columnHelper.accessor('type', {
      id: 'type',
      header: t<string>`activity.columns.paymentTransactionType`,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        let type:
          | Maybe<FiatTransactionType>
          | PaymentTransactionType
          | undefined
        switch (value) {
          case TransactionType.wire_withdrawal:
          case TransactionType.wire_receive:
          case TransactionType.sen_withdrawal:
          case TransactionType.sen_receive:
          case TransactionType.signet_withdrawal:
          case TransactionType.signet_receive:
          case TransactionType.cbit_withdrawal:
          case TransactionType.cbit_receive:
          case TransactionType.pix_withdrawal:
          case TransactionType.pix_receive:
          case TransactionType.xpay_withdrawal:
          case TransactionType.xpay_receive:
          case TransactionType.rtp_withdrawal:
          case TransactionType.rtp_receive:
          case TransactionType.rtgs_withdrawal:
          case TransactionType.rtgs_receive:
          case TransactionType.sepa_withdrawal:
          case TransactionType.sepa_receive:
          case TransactionType.sepa_instant_withdrawal:
          case TransactionType.sepa_instant_receive:
            type = original.fiatTransactionType
            break
          case TransactionType.onchain_receive:
          case TransactionType.onchain_send:
            type = PaymentTransactionType.blockchain_wallet
            break
          default:
            throw new Error('renderTypeColumn: Unknown transactionType')
        }
        return (
          <span className={classNames(textClassNames, 'font-circular-regular')}>
            <MaybeNull>
              {type != null &&
                t(`activity.paymentTransactionTypeLabels.${type}`)}
            </MaybeNull>
          </span>
        )
      },
    })

    /**
     * Status column.
     */
    const status = columnHelper.accessor('status', {
      header: t<string>`activity.columns.status`,
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <div className="flex items-center justify-between">
            <Chip
              variant={
                value === TransactionStatus.failed
                  ? 'status/error'
                  : 'status/neutral'
              }
            >
              {t(`activity.statusLabels.${value}`)}
            </Chip>
            <Icon
              className="ml-4 text-black-500"
              name="ChevronRightSolid"
              size={20}
            />
          </div>
        )
      },
    })

    return [
      icon,
      amount,
      currency,
      createDate,
      ...(fxEnabled ? [] : [paymentTransactionType]),
      status,
    ]
  }, [t, fxEnabled, money, date])
}

import { useContext, useEffect } from 'react'

import { CurrentCurrencyContext } from './context'

import type { Currency } from '@shared/graphql'

interface UseCurrentCurrencyProps {
  /**
   * Initial currency to set.
   */
  initialCurrency?: Currency
}

/**
 * Current Currency hook.
 */
export const useCurrentCurrency = ({
  initialCurrency,
}: UseCurrentCurrencyProps = {}) => {
  const ctx = useContext(CurrentCurrencyContext)
  const [, { setCurrency }] = ctx

  useEffect(() => {
    if (initialCurrency != null) {
      setCurrency({ currency: initialCurrency })
    }
  }, [setCurrency, initialCurrency])

  return ctx
}

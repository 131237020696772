import { useMemo } from 'react'

import useTranslation from 'next-translate/useTranslation'

export interface AmountMessageProps {
  /**
   * Amount.
   */
  amount?: number | string
}

export const AmountMessage: React.FC<AmountMessageProps> = ({ amount }) => {
  const { t } = useTranslation('onboard/rolr-signup')

  const showMessage = useMemo(() => {
    const parsed = typeof amount === 'string' ? parseFloat(amount) : amount

    if (parsed == null || isNaN(parsed)) {
      return false
    }

    return parsed >= 1000000
  }, [amount])

  return showMessage ? t`redemptionAmount.form.amount.message` : null
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVault } from '../../../hooks/create'

const schema = y.object({
  /**
   * Policy name.
   */
  name: y.string().required(),
})

export type PolicyNameFormValues = y.InferType<typeof schema>

export interface PolicyNameFormProps {
  /**
   * Onsubmit handler.
   */
  onSubmit: (values: PolicyNameFormValues) => void
}

export const PolicyNameForm: React.FC<PolicyNameFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.vault')
  const [{ name }, { setName }] = useCreateVault()

  const [Form, { watch }] = useForm<PolicyNameFormValues>({
    schema,
    values: {
      name: name ?? '',
    },
  })

  // Handle form submit
  const handleSubmit = useCallback(
    ({ name }: PolicyNameFormValues) => {
      setName(name)
      onSubmit({ name })
    },
    [onSubmit, setName],
  )

  // Update context on change
  watch(({ name }) => setName(name ?? ''))

  return (
    <div className="sm:w-152">
      <h2 className="type-h-page-sm">{t('createVault.policyName.title')}</h2>

      <Form className="mt-4 sm:w-100" onSubmit={handleSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-policyName"
          label={t('createVault.policyName.policyName')}
          name="name"
        />

        <div className="mt-4">
          <Form.SubmitButton className="w-60" variant="primary">
            {t('common:continue')}
          </Form.SubmitButton>
        </div>
      </Form>
    </div>
  )
}

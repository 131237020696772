import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { useCustomMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  useVaultWalletBalanceQuery,
  useVaultWithdrawalLimitsQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { FeeBalanceAlert } from '../../../../../containers'
import { useSendOnChain } from '../../SendOnChain.Context'

import { FeeTable } from './FeeTable/FeeTable'
import * as WithdrawalLimits from './WithdrawalLimits'

import type { VaultAmountCombined } from '@shared/graphql'

const schema = y.object({
  /**
   * Transfer amount.
   */
  amount: y.number().required(),
})

type FormValues = y.InferType<typeof schema>

interface DirectSourceAmountFormProps {
  /**
   * Vault wallet balance object.
   */
  availableBalance?: VaultAmountCombined
}

export const DirectSourceAmountForm: React.FC<DirectSourceAmountFormProps> = ({
  availableBalance,
}) => {
  const { t } = useTranslation('modals.vault')
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const { router } = useModal()
  const [
    { assetWallet, amount, transactionFees },
    { setSendOnChainState },
    { directSourceWallet },
  ] = useSendOnChain()

  const walletBalance = useVaultWalletBalanceQuery({
    variables: {
      vaultId: directSourceWallet?.vaultMetadata.id ?? '',
      walletId: directSourceWallet?.parentWalletId ?? '',
    },
    skip:
      !directSourceWallet?.vaultMetadata.id ||
      !directSourceWallet?.parentWalletId,
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
  })

  const withdrawalLimits = useVaultWithdrawalLimitsQuery({
    variables: {
      vaultId: assetWallet?.vaultMetadata.id ?? '',
      walletId: assetWallet?.id ?? '',
    },
    skip: !assetWallet?.id,
    fetchPolicy: 'cache-and-network',
  })

  const nativeBalance = Number(
    walletBalance.data?.vaultWalletBalance.native.amount,
  )
  const userLimit =
    withdrawalLimits.data?.vaultWithdrawalLimits.operator.max.fiatAmount
  const walletAvailable =
    withdrawalLimits.data?.vaultWithdrawalLimits.wallet.available.fiatAmount

  // Determine if balance is too low to cover fee
  const feeBalanceTooLow = useMemo(() => {
    if (
      !walletBalance.data ||
      !transactionFees ||
      !assetWallet ||
      !directSourceWallet ||
      amount === undefined
    ) {
      return false
    }

    const fee = Number(transactionFees?.maximumFee.native.amount)

    // If Native balance is zero, show error
    if (nativeBalance === 0) {
      return true
    }

    // If transferring native asset, balance must exceed amount + max fee.
    if (assetWallet?.id === directSourceWallet?.parentWalletId) {
      return amount + fee > nativeBalance
    }

    // All other transfers, balance must exceed max fee.
    return fee > nativeBalance
  }, [
    amount,
    assetWallet,
    nativeBalance,
    walletBalance.data,
    transactionFees,
    directSourceWallet,
  ])

  const noTransactionsRemaining = useMemo(() => {
    return (
      withdrawalLimits.data?.vaultWithdrawalLimits.operator.available
        .transactions === 0 ||
      withdrawalLimits.data?.vaultWithdrawalLimits.wallet.available
        .transactions === 0
    )
  }, [withdrawalLimits.data])

  const withdrawalLimitExceeded = useMemo(() => {
    if (!userLimit || !walletAvailable || !transactionFees?.amount) {
      return false
    }

    const fiatAmount = Number(transactionFees.amount.fiat.amount)

    return (
      fiatAmount > Number(userLimit.amount) ||
      fiatAmount > Number(walletAvailable.amount)
    )
  }, [transactionFees?.amount, userLimit, walletAvailable])

  const maxAmount = Number(availableBalance?.native.amount ?? 0)

  // Custom Currency Configuration
  const currencyConfig = useMemo(
    () => ({
      name: assetWallet?.assetMetadata.symbol ?? '',
      decimals: assetWallet?.assetMetadata.decimals ?? 0,
      isCryptoCurrency: true,
    }),
    [assetWallet],
  )

  const [Form, { watch, formState }] = useForm<FormValues>({
    schema: schema.shape({
      amount: y
        .number()
        .moreThan(0, {
          key: 'amount.moreThan',
          moreThan: customMoney({
            number: 0,
            currencyConfig,
            options: { padded: false },
          }),
        })
        .max(maxAmount, {
          key: 'amount.max',
          max: customMoney({
            number: maxAmount,
            currencyConfig,
            options: { padded: false },
          }),
        })
        .required()
        .allowEmpty(),
    }),
    defaultValues: {
      amount,
    },
    mode: 'onTouched',
  })

  /**
   * Handle Form onChange.
   */
  const handleFormOnChange = useCallback(() => {
    const amount = Number(watch('amount'))

    // Sync context state w/ form values
    setSendOnChainState({ amount })
  }, [setSendOnChainState, watch])

  /**
   * Handle form submit.
   */
  const handleFormSubmit = useCallback(() => {
    router.push(routes.vault.transfer.sendOnChain.review)
  }, [router])

  if (!assetWallet?.assetMetadata) {
    return null
  }

  return (
    <GraphQLErrorBoundary
      error={walletBalance.error}
      retry={walletBalance.refetch}
    >
      <GraphQLErrorBoundary
        error={withdrawalLimits.error}
        retry={withdrawalLimits.refetch}
      >
        <Form
          className="mx-auto w-104"
          onChange={handleFormOnChange}
          onSubmit={handleFormSubmit}
        >
          <Form.MoneyInput.Custom
            className="mt-4 w-full"
            currencyConfig={currencyConfig}
            disabled={noTransactionsRemaining}
            label={t<string>`transfer.sendOnChain.transferAmount.amount.label`}
            name="amount"
          />

          {/* Only render FeeTable with valid input */}
          {formState.isValid && <FeeTable amount={amount} />}

          <FeeBalanceAlert
            className="mt-6"
            currencyCode={directSourceWallet?.assetMetadata.symbol ?? ''}
            isBalanceZero={nativeBalance === 0}
            visible={feeBalanceTooLow}
          />

          <WithdrawalLimits.LimitsAlert
            amount={transactionFees?.amount}
            className="mt-6"
            userLimitAmount={userLimit}
            walletAvailableAmount={walletAvailable}
          />

          <Center className="pt-10" variant="horizontal">
            <Form.SubmitButton
              className="h-10 w-60"
              data-testid="submit-button"
              disabled={
                !formState.isValid ||
                transactionFees === undefined ||
                withdrawalLimitExceeded ||
                noTransactionsRemaining ||
                feeBalanceTooLow
              }
              variant="primary"
            >
              {t`common:continue`}
            </Form.SubmitButton>
          </Center>
        </Form>

        <WithdrawalLimits.LimitsTable
          className="mt-13 min-w-104 max-w-248"
          loading={withdrawalLimits.loading}
          withdrawalLimits={withdrawalLimits.data?.vaultWithdrawalLimits}
        />
      </GraphQLErrorBoundary>
    </GraphQLErrorBoundary>
  )
}

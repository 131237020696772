import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export interface CompleteSetupProps
  extends Pick<Partial<ButtonProps>, 'variant'> {
  /**
   * Is wallet connect step visible.
   */
  isWalletConnectStepVisible?: boolean
}

export const CompleteSetup: React.FC<CompleteSetupProps> = ({
  variant = 'primary',
  isWalletConnectStepVisible = false,
}) => {
  const { t } = useTranslation('vaults')
  const { track } = useSegment()
  const { router } = useModal()
  const { vaultMetadata } = useVaultPermission()

  const handleClick = useCallback(() => {
    /**
     * Even though this should never happen,
     * we will just return to satisfy typescript.
     */
    if (!vaultMetadata?.id) return

    track(SegmentEvents.VaultCompleteSetupClicked)

    if (vaultMetadata.hasCompleteDraftPolicy) {
      /**
       * Vault policy is completed. Open add vault wallet flow.
       */
      router.push({
        pathname: routes.vault.createVaultWallet.selectAsset,
        query: {
          vaultId: vaultMetadata.id,
        },
      })
    } else {
      /**
       * Vault policy needs completion. Open create vault flow.
       */
      router.push({
        pathname: routes.vault.createVault.initialize,
        query: {
          vaultId: vaultMetadata.id,
          policyId: vaultMetadata.draftPolicyId,
          isWalletConnectStepVisible,
        },
      })
    }
  }, [router, track, vaultMetadata, isWalletConnectStepVisible])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata?.isCreator) return null

  /**
   * Do not return anything when vault is already setup.
   */
  if (vaultMetadata?.state !== VaultStateEnum.SETUP_IN_PROGRESS) return null

  return (
    <Button
      onClick={handleClick}
      variant={variant}
    >{t`buttons.vault.completeSetup`}</Button>
  )
}

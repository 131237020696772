import { Statement, Button } from '@circlefin/components'
import { homeSection } from '@services/sections/lib/home'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

/**
 * PageError: Not Found.
 */
export const NotFound: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <div data-testid="page-error-notfound">
      <Statement status="warning" subtitle={t`pageNotFound.body`}>
        <Link href={homeSection.route} legacyBehavior passHref>
          <Button.Link
            data-testid="home-button"
            variant="primary"
          >{t`pageNotFound.cta`}</Button.Link>
        </Link>
      </Statement>
    </div>
  )
}

import { useCallback } from 'react'

import { useModal, useModalHistory } from '@circlefin/modal-router'
import { ChooseBlockchain as ChooseBlockchainContainer } from '@features/blockchain/containers'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'

import { useTransfer } from '../../../hooks/transfer'

import type { BlockchainAbbreviation } from '@shared/graphql'

export const ChooseBlockchain: React.FC = () => {
  const [, { handleValueChange }] = useTransfer()
  const { router } = useModal()
  const modalHistory = useModalHistory()

  const onChoose = useCallback(
    (newChosen: BlockchainAbbreviation) => {
      handleValueChange({ chosenBlockchain: newChosen })
      router.push({
        pathname: routes.transfer.wallet.transferInstructions,
      })
    },
    [router, handleValueChange],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 2}>
      <Center>
        <div className="w-184" data-testid="blockchains">
          <ChooseBlockchainContainer onChoose={onChoose} />
        </div>
      </Center>
    </FullScreen>
  )
}

/**
 * Platform routes.
 */
export const platform = {
  payment: {
    cancel: '/platform/payment/cancel',
    refund: '/platform/payment/refund',
    block: '/platform/payment/block',
  },
  paymentIntent: {
    switch: '/platform/paymentIntent/refund/',
    refund: '/platform/paymentIntent/refund/details',
    review: '/platform/paymentIntent/refund/review',
  },
}

import { useContext } from 'react'

import { CreateVaultContext } from './context'

/**
 * Create vault hook.
 */
export const useCreateVault = () => {
  return useContext(CreateVaultContext)
}

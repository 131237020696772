import { useCallback, useMemo } from 'react'

import { Button, DetailsList, Icon, SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { useDate } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { ManualAddressVerificationAlert } from '@features/blockchain/components'
import { FullScreen } from '@modals/layout'
import { TransWithLink } from '@shared/components/common'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  BlockchainAbbreviation,
  BlockchainAddressStatus,
  Currency,
  useBlockchainRecipientAddressWithMetadataQuery,
  useIdentityChallengeLazyQuery,
} from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface IdentityChallengeProps {
  /**
   * Address ID for which to fetch a challenge.
   */
  addressId?: string
}

export const IdentityChallenge: React.FC<IdentityChallengeProps> = ({
  addressId = '',
}) => {
  const { t } = useTranslation('address-book/modals')
  const modal = useModal()
  const { date } = useDate()
  const [
    getIdentityChallenge,
    { loading: challengeLoading, data: challengeData, error: challengeError },
  ] = useIdentityChallengeLazyQuery()
  const {
    loading: addressLoading,
    data: addressData,
    error: addressError,
    refetch: refetchAddress,
  } = useBlockchainRecipientAddressWithMetadataQuery({
    variables: {
      id: addressId,
    },
    skip: !addressId,
    onCompleted: (data) => {
      if (
        data.blockchainRecipientAddressWithMetadata &&
        data.blockchainRecipientAddressWithMetadata.status ===
          BlockchainAddressStatus.PENDING_VERIFICATION
      ) {
        void getIdentityChallenge({ variables: { addressId } })
      } else {
        // If the address does not require an identity challenge
        // Or require a new one to be made, we close the modal.
        modal.close()
      }
    },
  })

  const loading = addressLoading || challengeLoading
  const manualVerification = useMemo(
    () =>
      addressData?.blockchainRecipientAddressWithMetadata?.chain ===
      BlockchainAbbreviation.XLM,
    [addressData?.blockchainRecipientAddressWithMetadata?.chain],
  )

  const CurrencyIcon = useMemo(() => {
    const currency = challengeData?.identityChallenge.amount.currency.toString()
    if (currency === Currency.USDC || currency === Currency.EURC) {
      return <Icon name={currency} />
    }

    return <Icon name="USDC" />
  }, [challengeData?.identityChallenge.amount.currency])

  const handleDoneClick = useCallback(() => {
    modal.close()
  }, [modal])

  //TODO: Update this component to no longer use DetailsList and instead use Input components with read-only set to true.
  //Cleanup Ticket Here: https://circlepay.atlassian.net/browse/LEX-327

  return (
    <FullScreen totalSteps={4}>
      <PropsErrorBoundary<IdentityChallengeProps>
        props={{ addressId }}
        variant="page"
      >
        {() => (
          <GraphQLErrorBoundary
            error={addressError}
            retry={refetchAddress}
            variant="page"
          >
            <GraphQLErrorBoundary error={challengeError} variant="page">
              <Center
                className="mb-6 justify-items-center text-center"
                variant="horizontal"
              >
                <h2
                  className="type-h-page-sm"
                  data-testid="identity-challenge-title"
                >
                  {t('identityChallenge.goToYourWallet')}
                </h2>
                <p
                  className="mt-2 w-192 type-body-base"
                  data-testid="identity-challenge-subtitle-1"
                >
                  {t('identityChallenge.subtitle1')}
                </p>
                <p
                  className="mt-2 w-192 type-body-base"
                  data-testid="identity-challenge-subtitle-2"
                >
                  {t(`identityChallenge.subtitle2`)}
                </p>
              </Center>

              <Center variant="horizontal">
                <DetailsList className="w-100">
                  {/* Amount */}
                  <DetailsList.Label>
                    {t('identityChallenge.amount')}
                  </DetailsList.Label>
                  <SkeletonBox className="mt-2 h-5 w-full" loading={loading}>
                    <DetailsList.Description className="mt-2 flex justify-between">
                      <span className="flex items-center gap-2 type-body-sm">
                        {CurrencyIcon}
                        <span>
                          {challengeData?.identityChallenge.amount.amount}
                        </span>
                      </span>
                      <span className="type-body-base">
                        {challengeData?.identityChallenge.amount.currency}
                      </span>
                    </DetailsList.Description>
                  </SkeletonBox>
                  <DetailsList.Divider className="!mt-4 !border-black-75" />

                  {/* From Wallet */}
                  <DetailsList.Label className="mt-4">
                    {t('identityChallenge.fromWallet')}
                  </DetailsList.Label>
                  <SkeletonBox className="mt-2 h-5 w-full" loading={loading}>
                    <DetailsList.Description className="mt-2 type-body-sm">
                      <CopyToClipboard
                        value={
                          addressData?.blockchainRecipientAddressWithMetadata
                            ?.address ?? ''
                        }
                      />
                    </DetailsList.Description>
                  </SkeletonBox>
                  <DetailsList.Divider className="!mt-4 !border-black-75" />

                  {/* To Wallet */}
                  <DetailsList.Label className="mt-4">
                    {t('identityChallenge.toWallet')}
                  </DetailsList.Label>
                  <SkeletonBox className="mt-2 h-5 w-full" loading={loading}>
                    <DetailsList.Description className="mt-2 flex justify-between type-body-sm">
                      <CopyToClipboard
                        value={
                          challengeData?.identityChallenge.destinationAddress ??
                          ''
                        }
                      />
                    </DetailsList.Description>
                  </SkeletonBox>
                  <DetailsList.Divider className="!mt-4 !border-black-75" />

                  {/* Memo (Optional) */}
                  {challengeData?.identityChallenge.paymentId && (
                    <>
                      <DetailsList.Label className="mt-4">
                        {t('identityChallenge.memo')}
                      </DetailsList.Label>
                      <SkeletonBox
                        className="mt-2 h-5 w-full"
                        loading={loading}
                      >
                        <DetailsList.Description className="mt-2 type-body-sm">
                          <CopyToClipboard
                            value={
                              challengeData?.identityChallenge.paymentId ?? ''
                            }
                          />
                        </DetailsList.Description>
                      </SkeletonBox>
                      <DetailsList.Divider className="!mt-4 !border-black-75" />
                    </>
                  )}

                  {/* Blockchain */}
                  <DetailsList.Label className="mt-4">
                    {t('identityChallenge.sendOnBlockchain')}
                  </DetailsList.Label>
                  <SkeletonBox className="mt-2 h-5 w-full" loading={loading}>
                    <DetailsList.Description className="mt-2 flex items-center gap-2 type-body-sm">
                      <Icon
                        name={
                          addressData?.blockchainRecipientAddressWithMetadata
                            ?.chain ?? 'ETH'
                        }
                      />
                      <span>
                        {t<string>(
                          `common:chain.${
                            addressData?.blockchainRecipientAddressWithMetadata
                              ?.chain ?? BlockchainAbbreviation.ETH
                          }`,
                          undefined,
                          {
                            fallback:
                              addressData
                                ?.blockchainRecipientAddressWithMetadata?.chain,
                          },
                        )}
                      </span>
                    </DetailsList.Description>
                  </SkeletonBox>
                  <DetailsList.Divider className="!mt-4 !border-black-75" />

                  {/* Expiry Date */}
                  <DetailsList.Label className="mt-4">
                    {t('identityChallenge.sendWithin')}
                  </DetailsList.Label>
                  <SkeletonBox className="mt-2 h-5 w-full" loading={loading}>
                    <DetailsList.Description className="mt-2 flex justify-between type-body-sm">
                      <span>{t('identityChallenge.expiryTime')}</span>
                      <span>
                        {challengeData?.identityChallenge.expiryDate && (
                          <Trans
                            i18nKey="address-book/modals:identityChallenge.latestBy"
                            values={{
                              date: date(
                                challengeData?.identityChallenge.expiryDate,
                              ),
                            }}
                          />
                        )}
                      </span>
                    </DetailsList.Description>
                  </SkeletonBox>
                </DetailsList>
              </Center>

              <div className="mt-4 grid grid-cols-1 justify-items-center gap-4">
                <p className="w-100 type-body-sm">
                  {manualVerification ? (
                    t`identityChallenge.verificationAlertShort`
                  ) : (
                    <TransWithLink
                      i18nKey="address-book/modals:identityChallenge.verificationAlertBody"
                      urlI18nKey="address-book/modals:identityChallenge.verificationAlertUrl"
                      variant="url"
                    />
                  )}
                </p>

                <ManualAddressVerificationAlert
                  className="w-100"
                  visible={manualVerification}
                />

                <Button
                  className="mt-2"
                  onClick={handleDoneClick}
                  variant="primary"
                >
                  {t('identityChallenge.doneButton')}
                </Button>
              </div>
            </GraphQLErrorBoundary>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

import { useMemo } from 'react'

import { SectionNav, Footer, HeaderPublic } from '@containers/layout'
import { PageError } from '@shared/components/errors'
import { Main } from '@shared/components/layout'
import { useSession } from 'next-auth/react'

import { BaseLayout } from '../core'

import type { ErrorProps } from 'next/error'

/**
 * Error Layout.
 */
export const ErrorLayout: React.FC<ErrorProps> = ({ statusCode }) => {
  const { data: session } = useSession()

  const errorPage = useMemo(() => {
    switch (statusCode) {
      case 401:
        return <PageError variant="unauthorized" />
      case 404:
        return <PageError variant="not-found" />
      case 500:
        return <PageError variant="refresh" />
      default:
        return <PageError variant="default" />
    }
  }, [statusCode])

  if (session == null) {
    return (
      <BaseLayout className="grid min-h-screen w-full">
        <HeaderPublic>
          <HeaderPublic.Logo asLink />
          <HeaderPublic.SignIn />
        </HeaderPublic>
        <Main className="flex flex-col">
          <div className="flex-1">{errorPage}</div>
          <Footer />
        </Main>
      </BaseLayout>
    )
  }

  return (
    <BaseLayout className="grid min-h-screen w-full">
      <SectionNav />
      <Main className="ml-64 flex flex-col bg-surface-background">
        <div className="grid flex-1 gap-y-10">{errorPage}</div>
        <Footer className="mt-12" />
      </Main>
    </BaseLayout>
  )
}

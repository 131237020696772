import { useCallback, useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useBlockchainsQuery } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { FormDropdownProps } from '@circlefin/form/Form.Dropdown'
import type {
  Blockchain,
  Currency,
  BlockchainActivityType,
} from '@shared/graphql'

export const BlockchainSchema = y.mixed<Blockchain>()

export const schema = y.object({
  /**
   * Blockchain.
   */
  blockchain: BlockchainSchema,
})

export type BlockchainsFormValues = y.InferType<typeof schema>

export interface BlockchainsProps
  extends Omit<
    FormDropdownProps<BlockchainsFormValues>,
    'items' | 'name' | 'onChange'
  > {
  /**
   * Currency.
   */
  currency?: Currency
  /**
   * Label.
   */
  label?: string
  /**
   * Custom style.
   */
  className?: string
  /**
   * Apply blockchain filter.
   */
  activityType: BlockchainActivityType
  /**
   * On change handler.
   */
  onChange?: (chain: Blockchain) => void
}

export const Blockchains: React.FC<BlockchainsProps> = ({
  currency,
  label,
  className,
  activityType,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation('forms')
  const [Form] = useForm<BlockchainsFormValues>({ schema })

  const { data, loading } = useBlockchainsQuery({
    variables: {
      currency,
      activityType,
    },
  })

  const items = useMemo(() => {
    return (
      data?.blockchains.map((blockchain) => {
        const chainName = t(
          `dropdown.blockchain.blockchains.options.${blockchain.chain}`,
        )
        const bridgingLabel = blockchain.isBridged
          ? t(`common:bridgedUSDC`)
          : ''
        return {
          label: `${chainName} ${bridgingLabel}`,
          value: blockchain,
          icon: blockchain.icon,
          isBridged: blockchain.isBridged,
          isTRMSupported: blockchain.isTRMSupported,
        }
      }) ?? []
    )
  }, [data?.blockchains, t])

  const handleChange = useCallback(
    (item: SelectListItem<Blockchain | undefined>) => {
      if (item.value) {
        onChange?.(item.value)
      }
    },
    [onChange],
  )

  return (
    <SkeletonBox
      className={classNames('w-full h-10 mt-6', className)}
      loading={loading}
    >
      <Form.Dropdown
        className={className}
        items={items}
        label={label ?? t`dropdown.blockchain.blockchains.label`}
        name="blockchain"
        onChange={handleChange}
        {...rest}
      />
    </SkeletonBox>
  )
}

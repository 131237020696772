import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

export interface PairDeviceContextState {
  /**
   * Flow to route user to after successfully paring a device.
   */
  successFlow?: 'createVault' | 'walletApprovalPolicy'
}

interface Actions {
  /**
   * Set success flow.
   */
  setSuccessFlow: (state: PairDeviceContextState) => void
}

const defaultValues: PairDeviceContextState = {
  successFlow: 'createVault',
}

const Context = createContext<[PairDeviceContextState, Actions]>([
  { ...defaultValues },
  {
    setSuccessFlow: () => null,
  },
])

export interface PairDeviceProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<PairDeviceContextState>
}

export const Provider: React.FC<PairDeviceProviderProps> = ({
  children,
  initValues = {},
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<PairDeviceContextState>({
    ...defaultValues,
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<PairDeviceContextState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const actions: Actions = useMemo(
    () => ({
      setSuccessFlow: handleValueChange,
    }),
    [handleValueChange],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange(defaultValues)
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  }, [events, handleValueChange])

  return (
    <Context.Provider value={[values, actions]}>{children}</Context.Provider>
  )
}

export const usePairDeviceContext = () => {
  return useContext(Context)
}

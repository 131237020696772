import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { Base } from '@modals/layout'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { LoadingSpinner } from '@shared/components/common'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  useWalletApprovalIncompleteDevicePairingUsersQuery,
  Currency,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface SubmissionBlockedQueryProps {
  /**
   * Currency.
   */
  currency?: Currency
}

export const SubmissionBlocked: React.FC<SubmissionBlockedQueryProps> = (
  props,
) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')

  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)

  const { close } = useModal()

  const {
    data: { walletApprovalIncompleteDevicePairingUsers } = {},
    loading,
    error,
    refetch,
  } = useWalletApprovalIncompleteDevicePairingUsersQuery({
    variables: {
      currency: props.currency ?? Currency.USDC,
    },
    skip: props.currency == null || !isAuthorized,
  })

  const handleClose = useCallback(() => {
    close({ context: 'onDismiss' })
  }, [close])

  return (
    <Base className="flex flex-col items-center text-neutral-strong type-body-sm">
      <PropsErrorBoundary props={props} variant="page">
        {() => (
          <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <h2 className="text-center text-lg leading-6 font-circular-book">
                  {t(`submissionBlocked.title`)}
                </h2>
                <div className="my-6 w-full border-b border-black-50" />
                <div className="w-full">
                  <p className="mb-4">
                    {t('submissionBlocked.description', {
                      count:
                        walletApprovalIncompleteDevicePairingUsers?.minNumOfUsersWhoMustPair,
                    })}
                  </p>
                  {walletApprovalIncompleteDevicePairingUsers?.users.map(
                    ({ user }) => (
                      <div key={user.id} className="mt-2">
                        <strong className="block text-neutral-strong">
                          {user.name}
                        </strong>
                        <span className="block text-neutral-subtle">
                          {user.email}
                        </span>
                      </div>
                    ),
                  )}
                </div>
                <Button
                  className="mt-6 w-40 self-center"
                  onClick={handleClose}
                  variant="primary"
                >
                  {t`submissionBlocked.button`}
                </Button>
              </>
            )}
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </Base>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

import type { VaultWalletWithVaultMetadata } from '@shared/graphql'

/**
 * Vault Deposit flow values.
 */
export interface BlockchainDepositState {
  /**
   * Vault parent wallet.
   */
  parentWallet?: VaultWalletWithVaultMetadata
  /**
   * Vault child wallet.
   */
  childWallet?: VaultWalletWithVaultMetadata
}

/**
 * Vault Deposit flow Actions.
 */
interface Actions {
  /**
   * Value change handler.
   */
  setBlockchainDepositState: (values: Partial<BlockchainDepositState>) => void
  /**
   * Reset context values.
   */
  resetContext: () => void
}

const defaultValues: BlockchainDepositState = {
  parentWallet: undefined,
  childWallet: undefined,
}

/**
 * Create Vault Deposit Context.
 */
const Context = createContext<[BlockchainDepositState, Actions]>([
  { ...defaultValues },
  {
    setBlockchainDepositState: () => null,
    resetContext: () => null,
  },
])

interface BlockchainDepositProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<BlockchainDepositState>
}

/**
 * Vault Deposit Provider.
 */
export const Provider: React.FC<BlockchainDepositProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<BlockchainDepositState>({
    ...defaultValues,
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<BlockchainDepositState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const resetContext = useCallback(() => {
    handleValueChange(defaultValues)
  }, [handleValueChange])

  useEffect(() => {
    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <Context.Provider
      value={[
        values,
        { setBlockchainDepositState: handleValueChange, resetContext },
      ]}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * Vault Deposit context hook.
 */
export const useBlockchainDeposit = () => {
  return useContext(Context)
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { FeatureSwitch } from '@services/feature-switch'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Custody settings section.
 * Which users are allowed to see custody settings?
 *
 * VAULT_MANAGEMENT_ROLE and VAULT_MANAGEMENT_READ_ROLE are disabled in env except local.
 */
export const CUSTODY_SETTINGS_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CUSTODY_POLICY_ROLE],
  includeAny: FeatureSwitch.vaultCustodyEnabled()
    ? [
        Role.VAULT_MANAGEMENT_ROLE,
        Role.VAULT_MANAGEMENT_READ_ROLE,
        Role.ACCOUNT_WEB_ROLE,
        Role.AGENT_ACCOUNT_ROLE,
      ]
    : [Role.ACCOUNT_WEB_ROLE, Role.AGENT_ACCOUNT_ROLE],
}

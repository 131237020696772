import { useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import { TabsNav } from '@containers/layout'
import { STABLECOIN_SECTION, VAULT_QUERY } from '@services/permissions'
import {
  custodySection,
  stableCoinExpressSection,
} from '@services/sections/lib/custody'

export interface CustodyTabsNavProps {
  /**
   * Custom style.
   */
  className?: string
}
export const CustodyTabsNav: React.FC<CustodyTabsNavProps> = ({
  className,
}) => {
  const [, vaultQueryPermissionResult] = usePermission(VAULT_QUERY)
  const [, stableCoinSectionResult] = usePermission(STABLECOIN_SECTION)

  const sections = useMemo(() => {
    return [
      {
        ...custodySection,
        label: vaultQueryPermissionResult.isAuthorized
          ? 'layout:page.custody.vaultsAndWallets.title'
          : custodySection.label,
      },
      stableCoinExpressSection,
    ].filter(Boolean)
  }, [vaultQueryPermissionResult.isAuthorized])

  // if the express tab is not enabled, there will only be one tab we show, in this case hide the tabs
  if (!stableCoinSectionResult.isAuthorized) {
    return null
  }

  return <TabsNav className={className} sections={sections} exactMatch />
}

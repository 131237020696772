import { useCallback, useMemo, useState } from 'react'

import { Dropdown } from '@circlefin/components/lib/Dropdown'
import { Center } from '@shared/components/layout'
import { BankAccountType } from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import CrossRiverLogo from '../../../../../images/CrossRiverBankLogo.png'
import { useLinkBankAccount } from '../../../Link.Context'
import { InformationVariants } from '../../Information.types'
import { WireForm } from '../Wire/Wire.Form'

import { XpayForm } from './Xpay/Xpay.Form'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export type CrossRiverTypes = BankAccountType.xpay | BankAccountType.wire

export interface CrossRiverTypesProps {
  /**
   * Cross River Bank Types.
   */
  type?: CrossRiverTypes
}

export const CrossRiver: React.FC<CrossRiverTypesProps> = ({
  type = BankAccountType.xpay,
}) => {
  const { t } = useTranslation('modals.bankAccount')
  const [, { setType }] = useLinkBankAccount()
  const [selectedType, setSelectedType] = useState<CrossRiverTypes>(type)

  const items: Array<SelectListItem<CrossRiverTypes>> = useMemo(
    () => [
      {
        label: t('link.information.type.wire'),
        value: BankAccountType.wire,
      },
      {
        label: t('link.information.type.xpay'),
        value: BankAccountType.xpay,
      },
    ],
    [t],
  )

  const onChange = useCallback(
    ({ value }: SelectListItem<CrossRiverTypes>) => {
      if (value != null) {
        setSelectedType(value)

        switch (value) {
          case BankAccountType.xpay:
            setType(InformationVariants.XPAY)
            break

          case BankAccountType.wire:
            setType(InformationVariants.CRB_WIRE)
            break
        }
      }
    },
    [setType],
  )

  const Form: React.FC = useCallback(() => {
    switch (selectedType) {
      case BankAccountType.xpay:
        return <XpayForm />

      case BankAccountType.wire:
        return <WireForm />
    }
  }, [selectedType])

  return (
    <div className="grid w-104 gap-6">
      {/* Cross River Logo */}
      <Center>
        <Image
          alt={t`link.information.logo.xpayAlt`}
          className="w-32"
          src={CrossRiverLogo}
        />
      </Center>

      {/* Cross River Types */}
      <Dropdown
        data-testid="dropdown-type"
        items={items}
        label={t('link.information.type.label')}
        onChange={onChange}
        value={selectedType}
      >
        <Dropdown.Options items={items} />
      </Dropdown>

      {/* Cross River Form */}
      <Form />
    </div>
  )
}

import classNames from 'classnames'

import { JustifyContent } from './JustifyContent/JustifyContent'

interface SubComponents {
  Content: typeof JustifyContent
}

export interface JustifyProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Justify Component.
 */
export const Justify: React.FC<JustifyProps> & SubComponents = ({
  children,
  className,
  ...rest
}) => (
  <div
    className={classNames(
      'grid grid-cols-3 mt-6 content-start gap-x-10 gap-y-5 flex-grow flex-shrink-0 pb-8',
      className,
    )}
    {...rest}
  >
    {children}
  </div>
)

Justify.displayName = 'Justify'
Justify.Content = JustifyContent

import { useCallback, useMemo } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import { CreateVault } from '../../../containers'
import {
  CreateVaultNavigationStep,
  OutgoingAddressesTableTab,
} from '../../../containers/Create'
import { useOutgoingAddressValidate } from '../../../containers/CreateVault'
import { useCreateVault } from '../../../hooks/create'
import * as VaultLayout from '../../../layout'

import type { OutgoingAddressesProps } from '../../../containers/Create'

export const OutgoingAddresses: React.FC = () => {
  const { t } = useTranslation('modals.vault')

  const { router } = useModal()

  const [{ outgoingAddresses, vaultId }, { setOutgoingAddresses }] =
    useCreateVault()

  const { isValid } = useOutgoingAddressValidate()

  const totalSelected = useMemo(
    () =>
      (outgoingAddresses?.external.length ?? 0) +
      (outgoingAddresses?.internal.length ?? 0),
    [outgoingAddresses],
  )

  const onContinue = useCallback(() => {
    void router.push(routes.vault.createVault.approval)
  }, [router])

  const onRowSelect: Required<OutgoingAddressesProps>['onRowSelect'] =
    useCallback(
      ({ type, addresses }) => {
        if (type === OutgoingAddressesTableTab.EXTERNAL_ADDRESSES) {
          setOutgoingAddresses({
            external: addresses,
          })

          return
        }

        if (type === OutgoingAddressesTableTab.CIRCLE_ADDRESSES) {
          setOutgoingAddresses({
            internal: addresses,
          })
        }
      },
      [setOutgoingAddresses],
    )

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.OUTGOING_ADDRESS}
    >
      <div className="flex h-full flex-col justify-between">
        <h2 className="text-4xl text-black-600 font-circular-bold sm:w-152">
          {t('createVault.outgoingAddresses.title')}
        </h2>
        <div className="mt-10">
          <CreateVault.OutgoingAddresses
            excludedVaultIds={vaultId ? [vaultId] : undefined}
            initialSelected={outgoingAddresses}
            onRowSelect={onRowSelect}
          />
          <div className="mt-10">
            <ModalBackButton />

            <Button
              className="w-60"
              disabled={!isValid}
              onClick={onContinue}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>

          <p className="mt-6 text-sm font-circular-regular">
            {!isValid
              ? t('createVault.outgoingAddresses.selectAtLeastOne')
              : t('createVault.outgoingAddresses.selectedCount', {
                  count: totalSelected,
                })}
          </p>
        </div>
      </div>
    </VaultLayout.CreateVault>
  )
}

import { possibleTypes } from './possibleTypes'
import { typePolicies } from './typePolicies'

import type { InMemoryCacheConfig } from '@apollo/client'

/**
 * Apollo In Memory Cache Config.
 */
export const cacheConfig: InMemoryCacheConfig = {
  possibleTypes,
  typePolicies,
}

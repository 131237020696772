import { useCallback } from 'react'

import { Sidenav, Menu } from '@circlefin/components'
import { signOutSection, support } from '@services/sections/lib/home'
import { useSegment, SegmentEvents } from '@services/segment'
import { useEntityQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

export const UserMenu: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { data: session, status } = useSession()
  const { data } = useEntityQuery()
  const { track, trackLinkClick } = useSegment()

  const handleLogout = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      track(SegmentEvents.SignOutClicked, {
        event: e,
      })
      void router.push(signOutSection.route)
    },
    [track, router],
  )

  if (status !== 'authenticated') {
    return null
  }

  return (
    <Sidenav.UserMenu
      firstName={session?.user.givenName ?? ''}
      lastName={session?.user.familyName ?? ''}
    >
      <Menu.Options anchorPosition="left" position="top">
        <Menu.Label>{data?.entity?.institutionName}</Menu.Label>
        <Menu.Divider />
        <Menu.Item
          href={support.route}
          icon="QuestionMarkCircleOutline"
          label={t`common:support`}
          onClick={trackLinkClick}
          rel="noopener noreferrer"
          target="_blank"
        />
        <Menu.Item
          icon="LogoutOutline"
          label={t`common:log-out`}
          onClick={handleLogout}
        />
      </Menu.Options>
    </Sidenav.UserMenu>
  )
}

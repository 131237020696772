import { useCallback, useEffect, useMemo } from 'react'

import { Button, FixedBanner, Modal, SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  useVerifiedUsersQuery,
  UserRoleGroupType,
  UserStatusType,
  useSetAdminApprovalsSettingsMutation,
  AdminApprovalsSettingsDocument,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../../buttons'

import type { AdminApprovalsPolicy } from '@shared/graphql'

const schema = y.object({
  /**
   * Requires approval toggle.
   */
  workflowEnabled: y.boolean(),
  /**
   * Number of approvals required.
   */
  approvalsNeeded: y.number(),
})

export type SettingsFormProps = y.InferType<typeof schema>

export type SettingsQueryProps = Partial<
  Pick<AdminApprovalsPolicy, 'approvalsNeeded' | 'enabled' | 'type'>
>

export const Settings: React.FC<SettingsQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/security')
  const modal = useModal()
  const verifiedUsers = useVerifiedUsersQuery({
    variables: {
      filter: {
        groupType: [UserRoleGroupType.ADMINISTRATOR],
        omitCurrentUser: true,
        status: [UserStatusType.ACTIVE],
      },
    },
  })
  const [setAdminApprovalSettings, { error, reset }] =
    useSetAdminApprovalsSettingsMutation({
      refetchQueries: [AdminApprovalsSettingsDocument],
      awaitRefetchQueries: true,
    })

  // Number of active admins
  const numActiveAdmins = useMemo(
    () => verifiedUsers.data?.verifiedUsers.length,
    [verifiedUsers.data?.verifiedUsers.length],
  )

  // Form Schema
  const [Form, { setValue, watch, trigger, resetField }] = useForm({
    schema: schema.shape({
      approvalsNeeded: y.number().when('workflowEnabled', {
        is: true,
        then: (schema) =>
          schema
            .moreThan(0, {
              key: 'adminApprovals.minApprovals',
            })
            .max(numActiveAdmins ?? 0, {
              key: 'adminApprovals.maxApprovals',
              max: numActiveAdmins,
            })
            .required()
            .allowEmpty(),
      }),
    }),
    defaultValues: {
      workflowEnabled: props.enabled,
    },
  })

  // Form Values
  const workflowEnabled = watch('workflowEnabled')
  const approvalsNeeded = watch('approvalsNeeded')

  const settingsUnchanged = useMemo(
    () =>
      workflowEnabled === !props.enabled &&
      (props.enabled || Number(approvalsNeeded) === props.approvalsNeeded),
    [approvalsNeeded, props.approvalsNeeded, props.enabled, workflowEnabled],
  )

  // Handle field change dependencies
  useEffect(() => {
    const subscription = watch(({ workflowEnabled }) => {
      // Trigger revalidation
      if (workflowEnabled) {
        void trigger('workflowEnabled')
      }
    })
    return () => subscription.unsubscribe()
  }, [trigger, watch])

  // Handle clicking of toggle.
  const handleToggle = useCallback(
    (status: boolean) => {
      setValue('workflowEnabled', status)

      if (!status) {
        resetField('approvalsNeeded')
      }
    },
    [resetField, setValue],
  )

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  // Handle submit
  const handleSubmit = useCallback(
    async ({ workflowEnabled, approvalsNeeded }: SettingsFormProps) => {
      if (props.type != null) {
        try {
          await setAdminApprovalSettings({
            variables: {
              input: {
                type: props.type,
                approvalsNeeded,
                workflowEnabled: workflowEnabled ?? false,
              },
            },
          })

          modal.router.push(routes.account.security.adminApprovals.success)
        } catch (e) {
          // Error resolved in GraphQL Error Boundary
        }
      }
    },
    [modal.router, props.type, setAdminApprovalSettings],
  )

  return (
    <PropsErrorBoundary<SettingsQueryProps> props={props} variant="page">
      {({ type, enabled }) => (
        <>
          <Modal.Header title={t(`adminApprovals.settings.title.${type}`)} />
          <GraphQLErrorBoundary error={error} retry={reset}>
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="pt-2">
                <Buttons.Toggles.Settings
                  data-testid="toggle"
                  label={t`adminApprovals.settings.form.toggleLabel`}
                  onChange={handleToggle}
                  selected={enabled}
                />
                <Form.Input
                  className="mt-8 w-24 whitespace-nowrap"
                  disabled={verifiedUsers.loading || !workflowEnabled}
                  label={t`adminApprovals.settings.form.approvalsRequired.label`}
                  message={
                    <SkeletonBox
                      className="h-5 w-24"
                      loading={verifiedUsers.loading}
                    >
                      {t(
                        'adminApprovals.settings.form.approvalsRequired.message',
                        {
                          count: numActiveAdmins ?? 0,
                        },
                      )}
                    </SkeletonBox>
                  }
                  name="approvalsNeeded"
                  type="number"
                />
                {settingsUnchanged && (
                  <FixedBanner className="mt-8" status="info">
                    {t`adminApprovals.settings.unchangedSettingsWarning`}
                  </FixedBanner>
                )}
              </Modal.Body>
              <Modal.Footer variant="stretch-fraction">
                <Button onClick={onCancel} variant="secondary">
                  {t`common:cancel`}
                </Button>
                <Form.SubmitButton
                  disabled={settingsUnchanged}
                  variant="primary"
                >
                  {t`common:save`}
                </Form.SubmitButton>
              </Modal.Footer>
            </Form>
          </GraphQLErrorBoundary>
        </>
      )}
    </PropsErrorBoundary>
  )
}

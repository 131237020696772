import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { FullScreen } from '@modals/layout'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { circleWalletDetailSection } from '@services/sections/lib/custody'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import {
  isBankAccountType,
  isRailType,
} from '@services/type-guards/BankAccount'
import { MaybeNull } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center, Justify } from '@shared/components/layout'
import {
  Currency,
  PaymentsDocument,
  RailType,
  useActiveWalletApprovalPolicyQuery,
  useWalletApprovalCreatePayoutTransferMutation,
  WalletApprovalPendingTransactionsDocument,
} from '@shared/graphql'
import { useIdempotencyKey } from '@shared/idempotency-key'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { FxAgreement } from '../../../../components/FxAgreement/FxAgreement'
import { useTransferWithFx } from '../../../../hooks/fx'

import type { CreatePayoutInput } from '@shared/graphql'

const schema = y.object({
  /**
   * Checkbox state.
   */
  withdrawAgreement: y.boolean().required().oneOf([true]),
})

/**
 * State managed by the form.
 */
export type WithdrawFormProps = y.InferType<typeof schema>

export const ReviewDetails: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const { money } = useMoney({ locale: 'en-US' })
  const modal = useModal()
  const { quote: fxQuote, formValues } = useTransferWithFx()

  const bankAccount = formValues?.account
  const rail = formValues?.rail ?? RailType.wire

  const fromAmount = fxQuote?.from.amount
  const fromCurrency = fxQuote?.from.currency ?? Currency.USDC

  const toAmount = fxQuote?.to.amount ?? ''
  const toCurrency = fxQuote?.to.currency ?? Currency.USD

  const { idempotencyKey } = useIdempotencyKey()
  const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)
  const walletPolicyQuery = useActiveWalletApprovalPolicyQuery({
    variables: { currency: fromCurrency },
    skip: !isAuthorized,
    fetchPolicy: 'cache-and-network',
  })
  const hasWalletPolicyWithApproverSteps =
    walletPolicyQuery.data?.activeWalletApprovalPolicy?.approvalWorkflow != null

  const [Form] = useForm<WithdrawFormProps>({ schema })

  const [createPayout, { loading }] =
    useWalletApprovalCreatePayoutTransferMutation({
      refetchQueries: [
        /**
         * Refetch Payments Activity Table.
         */
        PaymentsDocument,
        WalletApprovalPendingTransactionsDocument,
        /**
         * NOTE: Doesn't help to refetch balances here as they aren't
         * up-to-date immediately after a successful withdrawal.
         */
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        // If there is an active policy, navigate to the finished screen (this screens tells them their transfer needs approval)
        // Reusing the v1 flow.
        if (hasWalletPolicyWithApproverSteps) {
          modal.router.push({
            pathname: routes.transfer.withdraw.approvalRequired,
            query: {
              totalSteps: 6,
              actionLink: {
                pathname: circleWalletDetailSection.route,
                query: { currency: fromCurrency },
              },
              currency: fromCurrency,
            },
          })
          return
        }

        mfaSuccess({
          message: t('fx.withdraw.reviewDetails.success.title'),
          description: t('fx.withdraw.reviewDetails.success.description', {
            amount: money({ number: fromAmount ?? '', variant: fromCurrency }),
            account: bankAccount?.description,
          }),
          duration: 10000,
        })
      },
      onError: (error) =>
        mfaError({
          error,
          message: t`common:generalError`,
        }),
    })

  const onTransferClick = useCallback(() => {
    if (bankAccount && fromAmount) {
      const input: CreatePayoutInput = {
        destination: {
          id: bankAccount.id,
          type: isBankAccountType(rail) ? rail : undefined,
          railType: isRailType(rail) ? rail : undefined,
          name: bankAccount.label,
        },
        amount: {
          amount: fromAmount,
          currency: fromCurrency,
        },
        toAmount: {
          amount: toAmount,
          currency: toCurrency,
        },
        idempotencyKey,
      }

      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createPayout({
            variables: {
              input,
            },
            context: context(code),
          })
        },
        onAbort: () => modal.close({ context: 'onDismiss' }),
      })
    }
  }, [
    bankAccount,
    fromAmount,
    fromCurrency,
    rail,
    toAmount,
    toCurrency,
    idempotencyKey,
    withMfa,
    createPayout,
    context,
    modal,
  ])

  const bankDescription = useMemo(() => {
    if (!bankAccount) {
      return null
    }

    if (
      TypeGuards.BankAccount.isRailWithBankAccount(bankAccount) ||
      TypeGuards.BankAccount.isPix(bankAccount)
    ) {
      return bankAccount.description
    }

    return t(`fx.withdraw.reviewDetails.bankDescription.${bankAccount.type}`)
  }, [t, bankAccount])

  return (
    <FullScreen totalSteps={5}>
      <GraphQLErrorBoundary
        error={walletPolicyQuery.error}
        retry={walletPolicyQuery.refetch}
        variant="page"
      >
        <Form>
          <Center className="mb-8 text-center" variant="horizontal">
            <h2 className="mb-2 text-3xl font-circular-bold">
              {t`fx.withdraw.reviewDetails.header`}
            </h2>
            <h3 className="text-sm text-neutral font-circular-regular">
              {t`fx.withdraw.reviewDetails.subHeader`}
            </h3>
          </Center>

          <Justify>
            <Justify.Content className="text-left" variant="center">
              <div className="mt-4 w-full" data-testid="withdraw-from-amount">
                <h1 className="mb-2 text-sm text-black-500 font-circular-bold">
                  {t('fx.withdraw.reviewDetails.withdrawAmountInput')}
                </h1>
                {fromAmount &&
                  money({
                    number: fromAmount ?? '',
                    variant: fromCurrency,
                    options: {
                      symbol: false,
                    },
                  })}{' '}
                {fromCurrency}
              </div>

              <div className="mt-4 w-full" data-testid="withdraw-to-amount">
                <h1 className="mb-2 text-sm text-black-500 font-circular-bold">
                  {t('fx.withdraw.reviewDetails.receiveAmountInput')}
                </h1>
                {fxQuote &&
                  money({
                    number: fxQuote.to.amount,
                    variant: fxQuote.to.currency,
                    options: {
                      symbol: false,
                    },
                  })}{' '}
                {fxQuote && fxQuote.to.currency}
              </div>

              <div className="mt-4 w-full">
                <h1 className="mb-2 text-sm text-black-500 font-circular-bold">
                  {t('fx.withdraw.reviewDetails.marketExchangeRate')}
                </h1>
                {fxQuote &&
                  t('fx.withdraw.reviewDetails.marketExchangeRateDetail', {
                    fromCurrency: fxQuote.from.currency,
                    rate: 1 / fxQuote.rate,
                    toCurrency: fxQuote.to.currency,
                  })}
              </div>

              <div className="mt-4 w-full" data-testid="transfer-method">
                <h1 className="mb-2 text-sm text-black-500 font-circular-bold">
                  {t('fx.withdraw.reviewDetails.transferMethod')}
                </h1>
                {rail != null &&
                  t(
                    `fx.withdraw.reviewDetails.transferMethodFormatted.${rail}`,
                  )}
              </div>

              <div className="mt-4 w-full" data-testid="withdraw-to-bank">
                <h2 className="mb-2 text-sm leading-7 text-black-500 font-circular-bold">
                  {t('fx.withdraw.reviewDetails.withdrawToBank')}
                </h2>
                <MaybeNull> {bankDescription}</MaybeNull>
              </div>

              <div className="mt-4 w-full">
                <Form.Checkbox
                  className="text-sm text-black-500"
                  data-testid="confirmation"
                  label={<FxAgreement mode="withdraw" />}
                  name="withdrawAgreement"
                />
              </div>
            </Justify.Content>
          </Justify>

          <Center>
            <Form.SubmitButton
              className="mt-2 h-10 w-100"
              data-testid="submit-withdraw-button"
              disabled={walletPolicyQuery.loading}
              loading={loading}
              onClick={onTransferClick}
              variant="primary"
            >
              {t`common:submit`}
            </Form.SubmitButton>
          </Center>
        </Form>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

export enum LocalStaticPermissionSet {
  /**
   * Ensure the user has access to email verification flow when
   * their email is unverified, while restricting access to any other area.
   */
  protectedUnverifiedEmail = 'protectedUnverifiedEmail',
  /**
   * Ensure the user has access to onboarding when it is required,
   * while restricting access to any other area.
   */
  protectedOnboarding = 'protectedOnboarding',
}

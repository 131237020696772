import { useCallback, useMemo } from 'react'

import { Card, Header, DescriptionList } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { linkedAccounts } from '@services/sections/lib/settings'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import { BackButton } from '@shared/components/layout'
import { BankAccountStatus } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as LinkedAccounts from '../../containers/LinkedAccounts'
import { BankSetNicknameAction } from '../BankSetNicknameAction/BankSetNicknameAction'

import type { BankAccount } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface LinkedBankAccountsDetailProps {
  /**
   * Bank account to display.
   */
  bankAccount: BankAccount
}

export const LinkedBankAccountsDetail = ({
  bankAccount,
}: LinkedBankAccountsDetailProps) => {
  const { trackingRef, createDate, nickname } = bankAccount

  const { t } = useTranslation('settings')
  const { date } = useDate()
  const { router } = useModal()

  const title = useMemo(() => {
    if (nickname) {
      return t('settings:linkedAccounts.detail.titleWithNickname', {
        nickname,
      })
    }
    return t`settings:linkedAccounts.detail.title`
  }, [nickname, t])

  const isWireAccount = TypeGuards.BankAccount.isWire(bankAccount)
  const isSignetAccount = TypeGuards.BankAccount.isSignet(bankAccount)
  const isCbitAccount = TypeGuards.BankAccount.isCbit(bankAccount)
  const isXpayAccount = TypeGuards.BankAccount.isXpay(bankAccount)
  const isSenAccount = TypeGuards.BankAccount.isSen(bankAccount)
  const isRtpAccount = TypeGuards.BankAccount.isRtp(bankAccount)
  const isVirtualAccount = isWireAccount && bankAccount.virtualAccountEnabled

  const showTestDepositBtn =
    bankAccount.status === BankAccountStatus.pending &&
    ((isWireAccount && !bankAccount?.userConfirmedWireSend) ||
      isSignetAccount ||
      isCbitAccount)

  const showDepositBtn =
    bankAccount?.status === BankAccountStatus.complete && !isSenAccount

  const goToSelectCurrency = useCallback(() => {
    router.push({
      pathname: routes.transfer.wire.selectCurrency,
      query: {
        bankAccount,
      },
    })
  }, [bankAccount, router])

  const goToTestTransferLanding = useCallback(() => {
    router.push({
      pathname: routes.transfer.wire.testTransferLanding,
      query: {
        bankAccount,
      },
    })
  }, [bankAccount, router])

  const bankDescription = useMemo(() => {
    if (isSignetAccount) {
      return t`linkedAccounts.detail.bankNames.signature`
    }

    if (isCbitAccount) {
      return t`linkedAccounts.detail.bankNames.cbit`
    }

    if (isXpayAccount) {
      return t`linkedAccounts.detail.bankNames.xpay`
    }

    return bankAccount.description
  }, [t, bankAccount, isCbitAccount, isSignetAccount, isXpayAccount])

  return (
    <>
      <BackButton section={linkedAccounts} />
      <Header variant="secondary">
        <Header.Head title={title}>
          {!isSenAccount && <BankSetNicknameAction bankAccount={bankAccount} />}
          {showDepositBtn && (
            <PaymentButtons.BankWires.Deposit
              data-testid="account-verified"
              onClick={goToSelectCurrency}
              variant="primary"
            >
              {t`linkedAccounts.detail.depositBtn`}
            </PaymentButtons.BankWires.Deposit>
          )}
          {showTestDepositBtn && (
            <PaymentButtons.BankWires.Deposit
              data-testid="account-unverified"
              onClick={goToTestTransferLanding}
              variant="primary"
            >
              {t`linkedAccounts.detail.firstDepositBtn`}
            </PaymentButtons.BankWires.Deposit>
          )}
        </Header.Head>
        <Header.Description>
          {t('settings:linkedAccounts.detail.linkDate', {
            date: date(createDate, 'date'),
          })}
        </Header.Description>
      </Header>

      <Card>
        <Card.Content className="pb-8 pl-8 pt-5">
          <div className="px-4 pb-7 pt-4 text-lg leading-6 font-circular-book">{t`linkedAccounts.detail.cardTitle`}</div>
          <DescriptionList className="ml-4" density="loose">
            <Label className="w-64">{t`linkedAccounts.headers.bankName`}</Label>
            <Description>{bankDescription}</Description>
            {isSignetAccount && (
              <>
                <Label className="w-64">{t`linkedAccounts.headers.signetWalletAddress`}</Label>
                <Description>{bankAccount.walletAddress}</Description>
              </>
            )}
            {isCbitAccount && (
              <>
                <Label className="w-64">{t`linkedAccounts.headers.cbitWalletAddress`}</Label>
                <Description>{bankAccount.walletAddress}</Description>
              </>
            )}
            {!isVirtualAccount && (
              <>
                <Label className="w-64">{t`linkedAccounts.headers.referenceId`}</Label>
                <Description>{trackingRef}</Description>
              </>
            )}
            {(isWireAccount || isRtpAccount) && (
              <>
                <Label className="w-64">{t`linkedAccounts.headers.accountHolderAddress`}</Label>
                <Description>
                  {bankAccount.billingDetails?.line1}
                  <p className="text-black-300 font-circular-regular">
                    {bankAccount.billingDetails?.city}
                  </p>
                </Description>
                <Label className="w-64">{t`linkedAccounts.headers.accountHolderName`}</Label>
                <Description>{bankAccount.billingDetails?.name}</Description>
              </>
            )}
            {isWireAccount && bankAccount.ffcMemo && (
              <>
                <Label className="w-64">{t`linkedAccounts.headers.ffcMemo`}</Label>
                <Description>{bankAccount.ffcMemo}</Description>
              </>
            )}
          </DescriptionList>
        </Card.Content>
      </Card>
      <LinkedAccounts.SupportText />
    </>
  )
}

import { useCallback, useEffect, useRef } from 'react'

import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { PROTECTED } from '@services/permissions'
import { findDynamicSectionByPath } from '@services/sections'
import { onboarding } from '@services/sections/modal/onboarding/onboarding'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import type { TakeoverInfoQuery } from '@shared/graphql'

export const usePeriodicReviewTakeover = () => {
  const [, { isAuthorized }] = usePermission(PROTECTED)
  const hasOpenedOnceInCurrentPath = useRef(false)
  const { data: session } = useSession()
  const { pathname } = useRouter()
  const modal = useModal()

  const open = useCallback(() => {
    hasOpenedOnceInCurrentPath.current = true

    modal.router.push({
      pathname: onboarding.periodicReview.takeover,
      query: {
        institutionName: session?.institutionName,
      },
    })
  }, [modal.router, session?.institutionName])

  const shouldOpen = useCallback(
    (data: TakeoverInfoQuery) => {
      const section = findDynamicSectionByPath(pathname)

      if (section?.takeover?.periodicReview === false) {
        return false
      }

      return (
        data.periodicReviewQualification?.actionRequired === true &&
        hasOpenedOnceInCurrentPath.current === false &&
        session != null &&
        isAuthorized
      )
    },
    [isAuthorized, session, pathname],
  )

  useEffect(() => {
    // We want reopen the takeover modal on route change complete if the requirement is still not met
    hasOpenedOnceInCurrentPath.current = false
  }, [pathname])

  return {
    shouldOpen,
    open,
  }
}

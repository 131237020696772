import {
  chargebacksSection,
  checkoutSessionSection,
  merchantPaymentIntentsSection,
  merchantPaymentsSection,
  merchantPayoutsSection,
  reversalsSection,
  settlementsSection,
  transfersSection,
} from '@services/sections/lib/platform'

import { TabsNav } from '../TabsNav/TabsNav'

export interface PlatformNavProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Style?
   */
  className?: string
}

const sections = [
  merchantPaymentsSection,
  merchantPaymentIntentsSection,
  merchantPayoutsSection,
  transfersSection,
  settlementsSection,
  chargebacksSection,
  reversalsSection,
  checkoutSessionSection,
]

export const PlatformNav: React.FC<PlatformNavProps> = ({ className }) => {
  return <TabsNav className={className} sections={sections} />
}

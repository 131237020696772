import {
  Card,
  Header,
  SkeletonBox,
  Chip,
  DescriptionList,
} from '@circlefin/components'
import { useDate, useCustomMoney } from '@circlefin/hooks'
import { vaultsSection } from '@services/sections/lib/vaults'
import {
  isVaultAddressTransactionLocation,
  isVaultRecipientAddressTransactionLocation,
  isVaultWalletTransactionLocation,
} from '@services/type-guards/Vault'
import { ChainExplorerLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { BackButton, Content } from '@shared/components/layout'
import {
  VaultTransactionState,
  VaultTransactionType,
  useVaultTransactionQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransactionApprovals } from '../TransactionApprovals/TransactionApprovals'

import type { ChipProps } from '@circlefin/components/lib/Chip'
import type { VaultTransactionLocation } from '@shared/graphql'
const { Label, Description } = DescriptionList

export interface VaultTransactionDetailCardProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Vault transaction id.
   */
  transactionId: string
}

export const VaultTransactionDetailCard: React.FC<VaultTransactionDetailCardProps> =
  ({ className, transactionId }) => {
    const { t } = useTranslation('payments')
    const { customMoney } = useCustomMoney({ locale: 'en-US' })
    const { date } = useDate()

    const {
      data: {
        vaultTransaction: {
          updateDate = null,
          amount = null,
          fee = null,
          transactionHash = null,
          blockchain = null,
          type = null,
          state = null,
          source = null,
          destination = null,
          issuer = null,
          approval = null,
        } = {},
      } = {},
      loading,
      error,
      refetch,
    } = useVaultTransactionQuery({
      variables: {
        id: transactionId,
      },
    })

    const tagVariants: Record<string, ChipProps['variant']> = {
      [VaultTransactionState.complete]: 'status/neutral',
      [VaultTransactionState.pending]: 'status/neutral',
      [VaultTransactionState.pending_approval]: 'status/neutral',
      [VaultTransactionState.failed]: 'status/error',
      [VaultTransactionState.rejected]: 'status/error',
    }

    const isDeposit = type === VaultTransactionType.deposit
    const isWithdrawal = type === VaultTransactionType.withdrawal

    const renderLocation = (location: VaultTransactionLocation) => {
      if (isVaultAddressTransactionLocation(location)) {
        return <div>{location.address}</div>
      } else if (isVaultRecipientAddressTransactionLocation(location)) {
        return (
          <>
            <div className="break-words">{location.nickname}</div>
            <div>{location.address}</div>
          </>
        )
      } else if (isVaultWalletTransactionLocation(location)) {
        return (
          <>
            <div className="font-circular-bold">{location.vaultName}</div>
            <div className="break-words">{location.walletName}</div>
          </>
        )
      }
    }

    return (
      <Content>
        <BackButton section={vaultsSection}>
          {t('transactionDetail.vault.back')}
        </BackButton>
        <Header>
          <Header.Head title={t`payments:transactionDetail.vault.title`} />
          <Header.Description>{transactionId}</Header.Description>
        </Header>

        <Card className={className}>
          <Card.Content className="px-10 pb-0 pt-8">
            <GraphQLErrorBoundary error={error} retry={refetch}>
              <div data-testid="transaction-card">
                <div className="flex">
                  {/* Transaction amount */}
                  {amount && (
                    <div>
                      <SkeletonBox className="h-9 w-64" loading={loading}>
                        <div className="h-9 text-3xl leading-9 text-black-600 font-circular-medium">
                          <span>
                            <SkeletonBox loading={loading}>
                              {isDeposit ? '+ ' : '- '}
                              {customMoney({
                                number: amount.native.amount,
                                currencyConfig: {
                                  name: amount.native.asset.symbol,
                                  decimals: amount.native.asset.decimals,
                                  isCryptoCurrency: true,
                                },
                                options: {
                                  padded: false,
                                  symbol: false,
                                },
                              })}
                            </SkeletonBox>
                          </span>
                          <span className="ml-2 text-xl leading-7 text-black-200 font-circular-black">
                            {amount.native.asset.symbol}
                          </span>
                        </div>
                      </SkeletonBox>
                      <div className="mt-1 text-xl leading-7 text-black-200 font-circular-regular">
                        {t('common:transactionCard.amount')}
                      </div>
                    </div>
                  )}

                  {/* Summary Date */}
                  {updateDate && (
                    <div className="ml-auto text-base leading-7 text-black-500 font-circular-bold">
                      <SkeletonBox className="h-7 w-80" loading={loading}>
                        <span>
                          {t('common:transactionCard.summaryAsOf')}{' '}
                          {date(updateDate, 'datetimeWithYear')}
                        </span>
                      </SkeletonBox>
                    </div>
                  )}
                </div>

                <div className="mt-10 pb-2 text-2xl leading-8 font-circular-book">
                  {t('common:transactionCard.details')}
                </div>

                <DescriptionList className="pl-2" density="loose">
                  {/* Transaction Type */}
                  {type != null && (
                    <>
                      <Label>{t('common:transactionCard.type')}</Label>
                      <Description>
                        <SkeletonBox className="h-6 w-56" loading={loading}>
                          {t(`common:transaction.typeLabels.${type}`)}
                        </SkeletonBox>
                      </Description>
                    </>
                  )}

                  {/* Amount */}
                  {amount && (
                    <>
                      <Label>{t('transactionDetail.transactionAmount')}</Label>
                      <Description>
                        <div className="flex flex-row">
                          <span className="font-circular-bold">
                            {customMoney({
                              number: amount.native.amount,
                              currencyConfig: {
                                name: amount.native.asset.symbol,
                                decimals: amount.native.asset.decimals,
                                isCryptoCurrency: true,
                              },
                              options: { padded: false },
                            })}
                          </span>
                          <span className="ml-1 text-black-300 font-circular-regular">
                            {`(${customMoney({
                              number: amount.fiat.amount,
                              currencyConfig: {
                                name: amount.fiat.asset.symbol,
                                decimals: amount.fiat.asset.decimals,
                              },
                            })})`}
                          </span>
                        </div>
                      </Description>
                    </>
                  )}

                  {/* Estimated fee */}
                  {isWithdrawal && fee && (
                    <>
                      <Label>
                        {state === VaultTransactionState.complete
                          ? t('transactionDetail.fee')
                          : t('transactionDetail.maximumFee')}
                      </Label>
                      <Description>
                        <div className="flex flex-row">
                          <span className="font-circular-bold">
                            {customMoney({
                              number: fee.native.amount,
                              currencyConfig: {
                                name: fee.native.asset.symbol,
                                decimals: fee.native.asset.decimals,
                                isCryptoCurrency: true,
                              },
                              options: { padded: false },
                            })}
                          </span>
                          <span className="ml-1 text-black-300 font-circular-regular">
                            {`(${customMoney({
                              number: fee.fiat.amount,
                              currencyConfig: {
                                name: fee.fiat.asset.symbol,
                                decimals: fee.fiat.asset.decimals,
                              },
                            })})`}
                          </span>
                        </div>
                      </Description>
                    </>
                  )}

                  {/* Status */}
                  {state != null && (
                    <>
                      <Label>{t('common:transactionCard.status')}</Label>
                      <Description>
                        <SkeletonBox className="h-6 w-48" loading={loading}>
                          <Chip
                            className="text-base"
                            variant={tagVariants[state]}
                          >
                            {t(`common:transaction.statusLabels.${state}`)}
                          </Chip>
                        </SkeletonBox>
                      </Description>
                    </>
                  )}

                  {/* Sender */}
                  {isDeposit && (
                    <>
                      <Label>{t('transactionDetail.sender')}</Label>
                      <Description className="break-words">
                        <div className="break-words">
                          {source && renderLocation(source)}
                        </div>
                      </Description>
                    </>
                  )}

                  {/* Issuer */}
                  {isWithdrawal && (
                    <>
                      <Label>{t('transactionDetail.issuer')}</Label>
                      <Description className="break-words">
                        <div className="break-words">{issuer}</div>
                      </Description>
                    </>
                  )}

                  {/* Recipient/Destination */}
                  {destination && (
                    <>
                      <Label>
                        {isDeposit
                          ? t('transactionDetail.destination')
                          : t('transactionDetail.recipient')}
                      </Label>
                      <Description className="break-words">
                        {renderLocation(destination)}
                      </Description>
                    </>
                  )}

                  {/* Chain Link */}
                  {transactionHash && blockchain != null && (
                    <ChainExplorerLink
                      chain={blockchain}
                      transactionHash={transactionHash}
                    />
                  )}

                  {/* Approvals */}
                  {isWithdrawal && approval && (
                    <TransactionApprovals approval={approval} />
                  )}
                </DescriptionList>
              </div>
            </GraphQLErrorBoundary>
          </Card.Content>
        </Card>
      </Content>
    )
  }

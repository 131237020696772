import { addressBook } from './address-book/address-book'
import { billing } from './billing/billing'
import { security } from './security/security'
import { users } from './users/users'

/**
 * Account Routes.
 */
export const account = {
  addressBook,
  billing,
  security,
  users,
}

import { useCallback, useMemo } from 'react'

import { useCurrentCurrency } from '@features/locales/hooks/currency'
import {
  WalletApprovalPolicyRole,
  Currency,
  useUpsertWalletApprovalDraftPolicyMutation,
  WalletApprovalPolicyDocument,
  WalletApprovalIncompleteDevicePairingUsersDocument,
} from '@shared/graphql'

import { useWalletApprovalPolicy } from '../../../hooks/approval-policy'
import { useApprovalWorkflowValidate } from '../validators'

import type { ApolloError } from '@apollo/client'
import type {
  Maybe,
  UpsertWalletApprovalPolicyInput,
  UpsertWalletApprovalPolicySettings,
} from '@shared/graphql'

const numberOrUndefined = (value: Maybe<string> | undefined) =>
  value ? parseFloat(value) : undefined

interface UseUpsertWalletApprovalPolicyProps {
  /**
   * OnCompleted handler.
   */
  onCompleted?: () => void
  /**
   * OnError handler.
   */
  onError?: (error: ApolloError) => void
}

export const useUpsertWalletApprovalPolicy = ({
  onCompleted,
  onError,
}: UseUpsertWalletApprovalPolicyProps = {}) => {
  const [{ currency = Currency.USDC }] = useCurrentCurrency()

  const [values, { setFlowComplete }] = useWalletApprovalPolicy()
  const approvalWorkflowValidate = useApprovalWorkflowValidate()

  const [upsert, { loading, reset, error }] =
    useUpsertWalletApprovalDraftPolicyMutation({
      onCompleted: () => {
        setFlowComplete(true)
        onCompleted?.()
      },
      onError,
      refetchQueries: [
        {
          query: WalletApprovalPolicyDocument,
          variables: {
            currency,
          },
        },
        {
          query: WalletApprovalIncompleteDevicePairingUsersDocument,
          variables: {
            currency,
          },
        },
      ],
      awaitRefetchQueries: true,
    })

  // ensure every user defined in the userLimits list is listed on the approval workflow, if they are not default them back to operator.
  // This is an edge case for when the user removes an approver on the approver step and then "saves and exit".
  const userLimits = useMemo(() => {
    return (values.userLimits ?? []).map((usersPolicy) => {
      if (
        !approvalWorkflowValidate.missingApproversInWorkflow.has(
          usersPolicy.user.id,
        )
      ) {
        return usersPolicy
      }

      return {
        ...usersPolicy,
        permissions: [WalletApprovalPolicyRole.OPERATOR],
      }
    })
  }, [approvalWorkflowValidate.missingApproversInWorkflow, values.userLimits])

  const userRoles: UpsertWalletApprovalPolicyInput['userRoles'] = useMemo(
    () =>
      userLimits.reduce<UpsertWalletApprovalPolicyInput['userRoles']>(
        (acc, curr) => {
          curr.permissions.forEach((permission) => {
            acc.push({
              userId: curr.user.id,
              role: permission,
            })
          })

          return acc
        },
        [],
      ),
    [userLimits],
  )

  const operatorLimits: UpsertWalletApprovalPolicyInput['userLimits'] =
    useMemo(() => {
      const filtered = userLimits.filter(
        ({ permissions, maxAmount, maxTransactions }) =>
          permissions.includes(WalletApprovalPolicyRole.OPERATOR) &&
          maxAmount &&
          maxTransactions,
      )

      if (filtered.length === 0) {
        return
      }

      return filtered.map(({ user, maxAmount, maxTransactions }) => ({
        userId: user.id,
        maxAmount: numberOrUndefined(maxAmount),
        maxTransactions: numberOrUndefined(maxTransactions),
      }))
    }, [userLimits])

  const policyLimits: UpsertWalletApprovalPolicyInput['policyLimits'] = useMemo(
    () =>
      !values.policyLimits
        ? undefined
        : {
            maxAmount: numberOrUndefined(values.policyLimits.maxAmount),
            maxTransactions: numberOrUndefined(
              values.policyLimits.maxTransactions,
            ),
          },
    [values.policyLimits],
  )

  const approvalWorkflow: UpsertWalletApprovalPolicyInput['approvalWorkflow'] =
    useMemo(() => {
      if (!approvalWorkflowValidate.isValid || !values.approvalWorkflow) {
        return
      }

      const { minNeedConfirmed, approvers } = values.approvalWorkflow

      return {
        approvalsNeeded: parseFloat(minNeedConfirmed),
        votingUserIds: approvers.map((user) => user.id),
      }
    }, [approvalWorkflowValidate.isValid, values.approvalWorkflow])

  const settings: UpsertWalletApprovalPolicySettings = useMemo(() => {
    return {
      ...values.settings,
      allowInitiatorToApproveTransfer:
        values.settings?.allowInitiatorToApproveTransfer,
    }
  }, [values.settings])

  const save = useCallback(() => {
    void upsert({
      variables: {
        input: {
          currency,
          userRoles,
          policyLimits,
          userLimits: operatorLimits,
          approvalWorkflow,
          settings,
        },
      },
    })
  }, [
    upsert,
    currency,
    userRoles,
    policyLimits,
    operatorLimits,
    approvalWorkflow,
    settings,
  ])

  return [
    save,
    {
      loading,
      error,
      reset,
    },
  ] as const
}

import { useMemo } from 'react'

import { Icon, Chip } from '@circlefin/components'
import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { useDate, useMoney } from '@circlefin/hooks'
import {
  WalletApprovalTransactionStatus,
  WalletApprovalTransactionType,
} from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { IconProps } from '@circlefin/components/lib/Icon'
import type { Locale } from '@circlefin/money/variants'
import type { WalletApprovalTransaction } from '@shared/graphql'

const columnHelper = createColumnHelper<WalletApprovalTransaction>()

export const useWalletApprovalPendingTransactionsColumns = () => {
  const { t } = useTranslation('walletApprovalPolicy')
  const { locale } = useRouter()
  const { money } = useMoney({ locale: locale as Locale })
  const { date } = useDate({ locale: locale as Locale })

  return useMemo(() => {
    /** Icon name. */
    const iconName: Record<WalletApprovalTransactionType, IconProps['name']> = {
      [WalletApprovalTransactionType.ONCHAIN]: 'LinkOutline',
      [WalletApprovalTransactionType.PAYOUT]: 'LibraryOutline',
      [WalletApprovalTransactionType.VAULT_WALLET]: 'LinkOutline',
    }

    /**
     * Icon column.
     */
    const icon = columnHelper.accessor('type', {
      id: 'icon',
      header: t<string>`pendingTransactions.columns.type`,
      meta: {
        className: 'w-52',
      },
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <span className="text-neutral-strong font-circular-book type-body-sm">
            <Icon
              className="-mt-1 mr-4 inline text-neutral-strong"
              name={iconName[value]}
              size={15}
            />
            {t(
              `pendingTransactions.${
                value === WalletApprovalTransactionType.PAYOUT
                  ? 'withdrawal'
                  : 'sendOnChain'
              }`,
            )}
          </span>
        )
      },
    })

    /**
     * Amount column.
     */
    const amount = columnHelper.accessor('amount', {
      header: t<string>`pendingTransactions.columns.amount`,
      meta: {
        className: 'w-48 whitespace-normal break-all',
      },
      cell: ({
        getValue,
        row: {
          original: { currency },
        },
      }) => {
        const value = getValue()

        return (
          <span className="text-neutral-strong font-circular-book type-body-sm">
            {money({
              number: value * -1,
              variant: currency,
              options: {
                symbol: false,
                signDisplay: true,
              },
            })}
          </span>
        )
      },
    })

    /**
     * Initiated by column.
     */
    const initiatedBy = columnHelper.accessor('initiator.name', {
      header: t<string>`pendingTransactions.columns.initiatedBy`,
      cell: ({ getValue }) => (
        <span className="text-neutral-strong font-circular-book type-body-sm">
          {getValue()}
        </span>
      ),
    })

    /**
     * Create date column.
     */
    const createDate = columnHelper.accessor('date', {
      header: t<string>`pendingTransactions.columns.date`,
      cell: ({ getValue }) => (
        <span className="text-neutral-strong font-circular-regular type-body-sm">
          {date(getValue())}
        </span>
      ),
    })

    /**
     * Status column.
     */
    const status = columnHelper.accessor('status', {
      header: t<string>`pendingTransactions.columns.status`,
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <div className="flex items-center justify-between">
            <Chip
              variant={
                [
                  WalletApprovalTransactionStatus.REJECTED_BY_USER,
                  WalletApprovalTransactionStatus.CANCELLED_BY_INITIATOR,
                ].includes(value)
                  ? 'status/error'
                  : 'status/neutral'
              }
            >
              {t(`pendingTransactions.status.${value}`)}
            </Chip>
            <Icon
              className="ml-4 text-black-500"
              name="ChevronRightSolid"
              size={18}
            />
          </div>
        )
      },
    })

    return [icon, amount, initiatedBy, createDate, status]
  }, [t, money, date])
}

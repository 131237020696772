import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'

export interface VaultsDashboardHeaderActionsProps {
  /**
   * Specify whether a device was already paired.
   */
  hasPairedDevice?: boolean
}

export const VaultsDashboardHeaderActions: React.FC<VaultsDashboardHeaderActionsProps> =
  ({ hasPairedDevice }) => {
    const { t } = useTranslation('vaults')

    return hasPairedDevice ? (
      <Buttons.Vault.Create variant="primary" />
    ) : (
      <ModalLink
        pathname={routes.vault.pairDevice.download}
        query={{ route: routes.vault.pairDevice.pair }}
      >
        <Button variant="primary">
          {t`vaultsDashboardHeader.buttonLabel.getStarted`}
        </Button>
      </ModalLink>
    )
  }

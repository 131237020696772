import { useCallback } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { useCancelPendingUserProposalMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { User } from '@shared/graphql'

export interface CancelApprovalRequestProps {
  /**
   * User.
   */
  user?: User
}

export const CancelApprovalRequest: React.FC<CancelApprovalRequestProps> = (
  props,
) => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()
  const toast = useToast()

  const [cancelApprovalRequest, { error, loading, reset }] =
    useCancelPendingUserProposalMutation({
      onCompleted: () => {
        modal.close()
        toast.success(
          t(`cancelApprovalRequest.success`, {
            name: props.user?.name,
          }),
        )
      },
      // Error is handled in the GraphQLErrorBoundary
      onError: () => undefined,
    })

  const handleCancel = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleConfirm = useCallback(
    (user: User) => () => {
      void cancelApprovalRequest({
        variables: {
          // ProposalId should always be defined if the user status is in pending approval
          // ,but in case it's not, we'll let the backend throw an error and handle it
          proposalId: user.proposalId ?? '',
        },
      })
    },
    [cancelApprovalRequest],
  )

  return (
    <>
      <PropsErrorBoundary props={props} variant="page">
        {({ user }) => (
          <>
            <Modal.Header title={t('cancelApprovalRequest.title')} />
            <GraphQLErrorBoundary error={error} retry={reset} variant="page">
              <Modal.Body variant="center">
                {t('cancelApprovalRequest.description', {
                  name: user.name,
                })}
              </Modal.Body>
              <Modal.Footer variant="stretch">
                <Button onClick={handleCancel} variant="secondary">
                  {t('common:cancel')}
                </Button>
                <Button
                  loading={loading}
                  onClick={handleConfirm(user)}
                  variant="primary"
                >
                  {t('common:confirm')}
                </Button>
              </Modal.Footer>
            </GraphQLErrorBoundary>
          </>
        )}
      </PropsErrorBoundary>
    </>
  )
}

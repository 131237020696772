import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import { TypeGuards } from '@services/type-guards'

import type {
  BlockchainRecipientAddressWithMetadata,
  CircleInternalWallet,
  DirectInternalMappedWalletRef,
  DirectInternalWallet,
  InternalWallet,
  VaultTransactionFee,
  VaultWalletWithVaultMetadata,
} from '@shared/graphql'

export interface SendOnChainState {
  /**
   * Source internal wallet.
   */
  sourceWallet?: InternalWallet
  /**
   * Destination (either an internal wallet or external address).
   */
  destination?: InternalWallet | BlockchainRecipientAddressWithMetadata
  /**
   * Vault wallet / asset that we are sending (AKA "mapped wallet").
   */
  assetWallet?: VaultWalletWithVaultMetadata
  /**
   * Transfer amount.
   */
  amount?: number
  /**
   * Transfer fee.
   */
  transactionFees?: VaultTransactionFee
}

/**
 * Send-On-Chain Actions.
 */
interface SendOnChainActions {
  /**
   * Set send-on-chain form state handler.
   */
  setSendOnChainState: (state: Partial<SendOnChainState>) => void
}

/**
 * Send-On-Chain Derived State.
 */
interface SendOnChainDerivedState {
  /**
   * Depending on source/destination type, we can derive an destination object
   * that contains necessary data for UI and mutation inputs.
   */
  derivedDestination?: DerivedDestination
  /**
   * If sending from a Circle-custodied wallet, derive a typed source wallet.
   */
  circleSourceWallet?: CircleInternalWallet
  /**
   * If sending from a direct-custodied wallet, derive a typed source wallet.
   */
  directSourceWallet?: DirectInternalWallet
  /**
   * If sending to a direct-custodied wallet, derive a typed direct internal wallet.
   */
  directInternalDestinationWallet?: DirectInternalWallet
  /**
   * If sending to a direct-custodied wallet, derive a typed direct internal mapped wallet ref based on the derivedAssetSymbol.
   */
  directInternalDestinationMappedWalletRef?: DirectInternalMappedWalletRef
  /**
   * Derived asset symbol.
   */
  derivedAssetSymbol?: string
}

interface DerivedDestination {
  /**
   * ID for the destination (address id or wallet id depending on type).
   * @example
   * Used for POST request bodies that require toAddressRefId.
   */
  id: string
  /**
   * Description (address description or wallet name).
   */
  description: string
  /**
   * Additional label (i.e. Vault name if type is direct internal wallet).
   */
  label?: string
}

const defaultSendOnChainValues: SendOnChainState = {
  sourceWallet: undefined,
  destination: undefined,
  assetWallet: undefined,
  amount: undefined,
  transactionFees: undefined,
}

/**
 * Create Send-On-Chain Context.
 */
const SendOnChainContext = createContext<
  [SendOnChainState, SendOnChainActions, SendOnChainDerivedState]
>([
  { ...defaultSendOnChainValues },
  {
    setSendOnChainState: () => null,
  },
  {
    derivedDestination: undefined,
    circleSourceWallet: undefined,
  },
])

/**
 * Send-On-Chain Provider props.
 */
interface SendOnChainProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<SendOnChainState>
}

/**
 * Send-On-Chain Provider.
 */
export const SendOnChainProvider: React.FC<SendOnChainProviderProps> = ({
  children,
  initValues = {},
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<SendOnChainState>({
    ...defaultSendOnChainValues,
    ...initValues,
  })

  const handleValueChange = useCallback((values: Partial<SendOnChainState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  useEffect(() => {
    const resetContext = () => {
      handleValueChange(defaultSendOnChainValues)
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  const directSourceWallet = useMemo(
    () =>
      values.sourceWallet &&
      TypeGuards.InternalWallet.isDirect(values.sourceWallet)
        ? values.sourceWallet
        : undefined,
    [values.sourceWallet],
  )

  const derivedAssetSymbol = useMemo(
    () =>
      // if the source is Circle Main Wallet USDC/EURC -> the source will contain the asset that is being transferred
      values.sourceWallet &&
      TypeGuards.InternalWallet.isCircle(values.sourceWallet)
        ? values.sourceWallet.currency
        : values.assetWallet?.assetMetadata.symbol,
    [values.assetWallet?.assetMetadata.symbol, values.sourceWallet],
  )

  const derivedAssetBlockchain = useMemo(
    () => (directSourceWallet ? values.assetWallet?.blockchain : undefined),
    [directSourceWallet, values.assetWallet?.blockchain],
  )

  // NOTE: Complexity exists in this context so it's easier to follow markup.
  const derivedDestination = useMemo((): DerivedDestination | undefined => {
    const sourceWallet = values.sourceWallet
    const destination = values.destination

    if (!destination) {
      return
    }

    // If destination is an external address, use address ref Id
    if (TypeGuards.VaultTransfer.Destination.isExternalAddress(destination)) {
      return {
        id: destination.id,
        description: destination?.description ?? '',
      }
    }

    if (
      !TypeGuards.VaultTransfer.Destination.isInternalWallet(destination) ||
      !sourceWallet
    ) {
      return
    }

    // If sending to a Circle-custodied wallet, get ID based on blockchain
    if (
      TypeGuards.InternalWallet.isCircle(destination) &&
      derivedAssetBlockchain != null
    ) {
      const addressRef = destination.addressRefIds.find(
        (addressRef) => addressRef.chain === derivedAssetBlockchain,
      )

      if (!addressRef) {
        return // addressRef may be undefined during re-render
      }

      return {
        id: addressRef.id,
        description: destination.name,
      }
    }

    // If sending to Direct-custodied vault wallet, get ID of mapped/child wallet
    if (TypeGuards.InternalWallet.isDirect(destination) && derivedAssetSymbol) {
      const mappedWallet = destination.mappedWalletRefIds.find(
        (mappedWalletRef) =>
          mappedWalletRef.assetMetadata.symbol === derivedAssetSymbol,
      )

      if (!mappedWallet) {
        return // mappedWallet may be undefined during re-render
      }

      return {
        id: mappedWallet.walletId,
        description: destination.name,
        label: destination.vaultMetadata.name,
      }
    }
  }, [
    values.sourceWallet,
    values.destination,
    derivedAssetBlockchain,
    derivedAssetSymbol,
  ])

  const circleSourceWallet = useMemo(
    () =>
      values.sourceWallet &&
      TypeGuards.InternalWallet.isCircle(values.sourceWallet)
        ? values.sourceWallet
        : undefined,
    [values.sourceWallet],
  )

  const directInternalDestinationWallet = useMemo(
    () =>
      values.destination &&
      TypeGuards.VaultTransfer.Destination.isInternalWallet(
        values.destination,
      ) &&
      TypeGuards.InternalWallet.isDirect(values.destination)
        ? values.destination
        : undefined,
    [values.destination],
  )

  const directInternalDestinationMappedWalletRef = useMemo(() => {
    return directInternalDestinationWallet?.mappedWalletRefIds.find(
      (mappedWalletRef) =>
        mappedWalletRef.assetMetadata.symbol === derivedAssetSymbol,
    )
  }, [derivedAssetSymbol, directInternalDestinationWallet?.mappedWalletRefIds])

  const actions: SendOnChainActions = useMemo(
    () => ({
      setSendOnChainState: handleValueChange,
    }),
    [handleValueChange],
  )

  return (
    <SendOnChainContext.Provider
      value={[
        values,
        actions,
        {
          derivedDestination,
          circleSourceWallet,
          directInternalDestinationWallet,
          directInternalDestinationMappedWalletRef,
          directSourceWallet,
          derivedAssetSymbol,
        },
      ]}
    >
      {children}
    </SendOnChainContext.Provider>
  )
}

/**
 * Send on Chain hook.
 */
export const useSendOnChain = () => {
  return useContext(SendOnChainContext)
}

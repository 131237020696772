import { useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useSegment } from '@services/segment'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainAbbreviation } from '@shared/graphql'

export interface BlockchainDepositNativeUSDCAlertProps {
  /**
   * Custom className.
   */
  className?: string
  /**
   * Blockchain abbreviation.
   */
  blockchain: BlockchainAbbreviation
}

export const BlockchainDepositNativeUSDCAlert: React.FC<BlockchainDepositNativeUSDCAlertProps> =
  ({ blockchain, className }) => {
    const { t } = useTranslation('payments')
    const { trackLinkClick } = useSegment()

    const chainsWithNotice = useMemo(
      () =>
        Object.keys(
          t('transferInstructions.depositNativeUSDCOnlyWarning.chains', null, {
            returnObjects: true,
          }),
        ),
      [t],
    )

    if (!chainsWithNotice.includes(blockchain)) {
      return null
    }

    return (
      <FixedBanner
        className={className}
        data-testid="deposit-native-alert"
        status="warning"
        multiline
        visible
      >
        <FixedBanner.Title>
          {t('transferInstructions.depositNativeUSDCOnlyWarning.title')}
        </FixedBanner.Title>
        <FixedBanner.Description>
          <div>
            <Trans
              components={{
                a: (
                  <a
                    href={t(
                      `transferInstructions.depositNativeUSDCOnlyWarning.chains.${blockchain}.link`,
                    )}
                    onClick={trackLinkClick}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ),
              }}
              i18nKey={`payments:transferInstructions.depositNativeUSDCOnlyWarning.chains.${blockchain}.description`}
            />
          </div>
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

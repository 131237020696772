import { useCallback } from 'react'

import { ButtonCard, Heading } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { IconProps } from '@circlefin/components/lib/Icon'

export interface YesNoSelectProps {
  /**
   * Icon to use in the 'YES' button.
   */
  iconYes: IconProps['name']
  /**
   * Icon to use in the 'NO' button.
   */
  iconNo: IconProps['name']
  /**
   * On 'Yes'/'No' click.
   */
  onClick: (choice: boolean) => void
  /**
   * Disable buttons.
   */
  disabled?: boolean
  /**
   * Vertical/horizontal direction.
   */
  direction?: 'horizontal' | 'vertical'
  /**
   * Custom style.
   */
  className?: string
}

export const YesNoSelect: React.FC<YesNoSelectProps> = ({
  iconYes,
  iconNo,
  onClick,
  disabled,
  direction = 'vertical',
  className,
}) => {
  const { t } = useTranslation('onboard/common')

  const onButtonClick = useCallback(
    (selection: boolean) => () => {
      onClick(selection)
    },
    [onClick],
  )

  return (
    <div
      className={classNames(
        'flex gap-4 items-center',
        { 'flex-col': direction === 'vertical' },
        className,
      )}
      data-testid="yes-no-select"
    >
      <ButtonCard
        className="w-full sm:max-w-64"
        data-testid="yes-button"
        disabled={disabled}
        onClick={onButtonClick(true)}
        hideArrow
      >
        <Heading alignment="center" iconName={iconYes}>
          <Heading.Header>{t('common:yes')}</Heading.Header>
        </Heading>
      </ButtonCard>
      <ButtonCard
        className="w-full sm:max-w-64"
        data-testid="no-button"
        disabled={disabled}
        onClick={onButtonClick(false)}
        hideArrow
      >
        <Heading alignment="center" iconName={iconNo}>
          <Heading.Header>{t('common:no')}</Heading.Header>
        </Heading>
      </ButtonCard>
    </div>
  )
}

import { useCallback } from 'react'

import { Card, Icon, Chip } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { useWalletApprovalPolicy } from '../../../../../../hooks/approval-policy'

export interface LevelSummaryProps {
  /**
   * Custom style.
   */
  className?: string
}

export const LevelSummary: React.FC<LevelSummaryProps> = ({ className }) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')

  const [
    { approvalWorkflow, approvalLevelInEdit },
    { setApprovalLevelInEdit },
  ] = useWalletApprovalPolicy()

  const handleLevelEdit = useCallback(() => {
    setApprovalLevelInEdit(true)
  }, [setApprovalLevelInEdit])

  return (
    <Card className={className}>
      <Card.Content className="flex justify-between">
        <div>
          <div className="type-h-title-md">
            {t('approval.level.summary.approvers', {
              count: approvalWorkflow?.minNeedConfirmed,
            })}
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {approvalWorkflow?.approvers.map((user) => (
              <Chip
                key={user.id}
                className="shrink-0 px-4 py-2 text-neutral-strong type-body-base-bold"
                data-testid="user-chip"
                variant="default/info"
              >
                {user.name}
              </Chip>
            ))}
          </div>
        </div>

        {!approvalLevelInEdit && (
          <Icon
            className="mr-2"
            name="PencilOutline"
            onClick={handleLevelEdit}
            size={24}
          />
        )}
      </Card.Content>
    </Card>
  )
}

import { useCallback, useState } from 'react'

import useTranslation from 'next-translate/useTranslation'

import { VaultChildWalletRow } from '../VaultChildWalletRow/VaultChildWalletRow'

import type { VaultWallet } from '@shared/graphql'

export interface VaultArchivedChildWalletsProps {
  /**
   * The id of the vault the wallet belongs to.
   */
  vaultId: string
  /**
   * The archived vault wallets.
   */
  vaultWallets: VaultWallet[]
  /**
   * The id of the parent wallet id.
   */
  parentWalletId: string
}

export const VaultArchivedChildWallets: React.FC<VaultArchivedChildWalletsProps> =
  ({ vaultId, vaultWallets, parentWalletId }) => {
    const { t } = useTranslation('vaults')
    const [hidden, setHidden] = useState(true)

    const handleToggleView = useCallback(() => {
      setHidden((prevState) => !prevState)
    }, [])

    if (vaultWallets.length === 0) {
      return null
    }

    return (
      <>
        <div className="flex flex-row gap-3 pb-3 pl-3 pt-6">
          <span className="text-black-400 font-circular-book">
            {t('vaultArchivedChildWallets.title', {
              count: vaultWallets.length,
            })}
          </span>

          <button
            className="text-blue-500 font-circular-book"
            onClick={handleToggleView}
          >
            {hidden
              ? t`vaultArchivedChildWallets.view`
              : t`vaultArchivedChildWallets.hide`}
          </button>
        </div>

        {!hidden &&
          vaultWallets.map((wallet, index) => (
            <VaultChildWalletRow
              key={index}
              parentWalletId={parentWalletId}
              vaultId={vaultId ?? ''}
              wallet={wallet}
            />
          ))}
      </>
    )
  }

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import ChangePin from '../../../images/ChangePin.png'

export const Success: React.FC = () => {
  const modal = useModal()
  const { t } = useTranslation('modals.vault')

  const onDone = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <FullScreen>
      <Center className="m-auto justify-items-center" variant="horizontal">
        <h2 className="text-4xl text-black-600 font-circular-bold">{t`changePin.success.title`}</h2>

        <p className="mb-6 mt-14 w-152 text-center text-xl leading-7 font-circular-medium">
          {t`changePin.success.message`}
        </p>

        <div className="size-64">
          <Image alt="Change Pin" src={ChangePin} />
        </div>

        <Center className="pt-4" variant="horizontal">
          <Button className="w-60" onClick={onDone} variant="secondary">
            {t('common:done')}
          </Button>
        </Center>
      </Center>
    </FullScreen>
  )
}

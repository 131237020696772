import { DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { ChainExplorerLink, MaybeNull } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

import { BlockchainTransactionDetail } from '../BlockchainTransactionDetail/BlockchainTransactionDetail'

import type { OnchainReceive } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface OnchainReceiveDetailCardProps {
  /**
   * Onchain receive.
   */
  onchainReceive: OnchainReceive
  /**
   * Custom style.
   */
  className?: string
}

export const OnchainReceiveDetailCard: React.FC<OnchainReceiveDetailCardProps> =
  ({ onchainReceive, className }) => {
    const { t } = useTranslation('payments')
    const { money } = useMoney()

    const { payment, explorerLink, source } = onchainReceive
    const { chain, description, address, email, id: addressId } = source

    return (
      <BlockchainTransactionDetail
        addressId={addressId}
        className={className}
        transaction={payment}
      >
        <Label>{t('transactionDetail.blockchain')}</Label>
        <Description>
          <MaybeNull>{chain != null && t(`chainNames.${chain}`)}</MaybeNull>
        </Description>
        <Label>{t('transactionDetail.amount')}</Label>
        <Description>
          {money({
            number: Math.abs(Number(payment.amount.amount)),
            variant: payment.amount.currency,
            options: {
              symbol: false,
            },
          })}{' '}
          {/* TODO: Revisit L2 treatment in [https://circlepay.atlassian.net/browse/BRAAV-10935]. */}
          {t(
            `currencyByChain.${chain ?? ''}`,
            { currency: payment.amount.currency },
            { fallback: payment.amount.currency.toLowerCase() },
          )}
        </Description>
        {!!address && (
          <>
            <Label>{t('transactionDetail.sender')}</Label>
            <Description className="break-words">
              <span>
                {description} {email && `(${email})`}
              </span>
              <div className="break-words">{address}</div>
            </Description>
          </>
        )}
        {!!explorerLink && !(chain == null) && (
          <ChainExplorerLink chain={chain} explorerLink={explorerLink} />
        )}
      </BlockchainTransactionDetail>
    )
  }

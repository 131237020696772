import { Statement, Button } from '@circlefin/components'
import { signOutSection } from '@services/sections/lib/home'
import useTranslation from 'next-translate/useTranslation'

import type { StatementProps } from '@circlefin/components/lib/Statement'

export type NoFactorErrorProps = Pick<
  StatementProps,
  'title' | 'subtitle' | 'variant'
>

export const NoFactorError = ({
  title,
  subtitle,
  variant = 'page',
}: NoFactorErrorProps) => {
  const { t } = useTranslation()

  return (
    <Statement
      status="warning"
      subtitle={subtitle ?? t`mfa:factor.enroll.subtitle`}
      title={title ?? t`mfa:factor.enroll.title`}
      variant={variant}
    >
      <Button.Link
        data-testid="action-button"
        href={signOutSection.route}
        variant="primary"
      >
        {t('common:sign-in')}
      </Button.Link>
    </Statement>
  )
}

import { BankAccountType, RailType } from '@shared/graphql'

import type {
  BankAccount,
  SenBankAccount,
  SignetBankAccount,
  WireBankAccount,
  CbitBankAccount,
  XpayBankAccount,
  RtpBankAccount,
  Deposit,
  Payout,
  PixBankAccount,
} from '@shared/graphql'

/**
 * Type guard to check if a given account is a wire bank account.
 */
export function isWire(account: BankAccount): account is WireBankAccount {
  return (
    account.__typename === 'WireBankAccount' &&
    account.type === BankAccountType.wire
  )
}

/**
 * Type guard to check if a given account is a signet bank account.
 */
export function isSignet(account: BankAccount): account is SignetBankAccount {
  return (
    account.__typename === 'SignetBankAccount' &&
    account.type === BankAccountType.signet
  )
}

/**
 * Type guard to check if a given account is a silvergate bank account.
 */
export function isSilvergate(account: WireBankAccount) {
  const bankName = account.bankAddress?.bankName || ''
  return bankName.toUpperCase().includes('SILVERGATE')
}

/**
 * Type guard to check if a given account is a cbit bank account.
 */
export function isCbit(account: BankAccount): account is CbitBankAccount {
  return (
    account.__typename === 'CbitBankAccount' &&
    account.type === BankAccountType.cbit
  )
}

/**
 * Type guard to check if a given account is a xpay bank account.
 */
export function isXpay(account: BankAccount): account is XpayBankAccount {
  return (
    account.__typename === 'XpayBankAccount' &&
    account.type === BankAccountType.xpay
  )
}

/**
 * Type guard to check if a given account is a sen bank account.
 */
export function isSen(account: BankAccount): account is SenBankAccount {
  return (
    account.__typename === 'SenBankAccount' &&
    account.type === BankAccountType.sen
  )
}

/**
 * Type guard to check if a given account is a rtp bank account.
 */
export function isRtp(account: BankAccount): account is RtpBankAccount {
  return (
    account.__typename === 'RtpBankAccount' &&
    account.type === BankAccountType.rtp
  )
}

/**
 * Type guard to check if a given account is a pix bank account.
 */
export function isPix(account: BankAccount): account is PixBankAccount {
  return (
    account.__typename === 'PixBankAccount' &&
    account.type === BankAccountType.pix
  )
}

/**
 * Type guard to check if a given account is a rail with bank information.
 */
export function isRailWithBankAccount(account: BankAccount): boolean {
  return isRtp(account) || isWire(account)
}

/**
 * Type guard to check if a given account is has bank account information.
 */
export function doesAccountHaveBankInformation(account: BankAccount): boolean {
  return (
    (account.__typename === 'RtpBankAccount' &&
      account.type === BankAccountType.rtp) ||
    (account.__typename === 'WireBankAccount' &&
      account.type === BankAccountType.wire)
  )
}

/**
 * Type guard to determine if a given string is a valid BankAccountType value.
 */
export function isBankAccountType(type: string): type is BankAccountType {
  return Object.values<string>(BankAccountType).includes(type)
}

/**
 * Type guard to determine if a given string is a valid RailType value.
 */
export function isRailType(type: string): type is RailType {
  return Object.values<string>(RailType).includes(type)
}

/**
 * Type guard to determine if a given transaction object is a Deposit object.
 */
export function isDepositTransaction(
  transaction: unknown,
): transaction is Deposit {
  return (
    typeof transaction === 'object' &&
    transaction !== null &&
    '__typename' in transaction &&
    transaction?.__typename === 'Deposit'
  )
}

/**
 * Type guard to determine if a given transaction object is a Payout object.
 */
export function isPayoutTransaction(
  transaction: unknown,
): transaction is Payout {
  return (
    typeof transaction === 'object' &&
    transaction !== null &&
    '__typename' in transaction &&
    transaction?.__typename === 'Payout'
  )
}

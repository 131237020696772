import { DescriptionList } from '@circlefin/components'
import classNames from 'classnames'

export type DescriptionProps = React.ComponentProps<
  typeof DescriptionList.Description
>

export const Description: React.FC<DescriptionProps> = ({
  children,
  className,
  ...rest
}) => (
  <DescriptionList.Description
    className={classNames('text-sm text-left', className)}
    {...rest}
  >
    {children}
  </DescriptionList.Description>
)

import { useCallback } from 'react'

import { SelectCard } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { MaybeNull } from '@shared/components/common'
import { useBalancesMainQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'

import { useRefund } from '../../Refund.Context'

import type { Currency } from '@shared/graphql'

export interface SourceCurrencySelectionProps {
  /**
   * Amount Currency.
   */
  currency: Currency
}

export const SourceCurrencySelection: React.FC<SourceCurrencySelectionProps> =
  ({ currency }) => {
    const { data } = useBalancesMainQuery({
      // Always render a modal step with fresh balance
      fetchPolicy: 'cache-and-network',
      variables: {
        currency,
      },
    })

    const { money } = useMoney()
    const [{ sourceCurrency }, { handleValueChange }] = useRefund()

    const onSelect = useCallback(() => {
      handleValueChange({ sourceCurrency: currency })
    }, [handleValueChange, currency])

    return (
      <div data-testid="currency-selector">
        <SelectCard
          key={currency}
          checked={sourceCurrency === currency}
          className="mt-5"
          id={currency}
          label={currency}
          name="source-currency"
          onChange={onSelect}
          type="radio"
          value={currency}
          variant="primary/sm"
        >
          <div className="flex items-center">
            <div className="flex justify-start">
              <div className="flex flex-col">
                <div className="text-sm leading-5 font-circular-book">
                  {currency}
                </div>
                <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular">
                  <MaybeNull>
                    {data?.balances.main?.balance && (
                      <Trans
                        i18nKey="platform/modals:payment-intent.refund.availableBalance"
                        values={{
                          amount: money({
                            number: data?.balances.main?.balance.amount,
                            variant: currency,
                            options: {
                              symbol: false,
                              signDisplay: false,
                              padded: false,
                            },
                          }),
                        }}
                      />
                    )}
                  </MaybeNull>
                </div>
              </div>
            </div>
          </div>
        </SelectCard>
      </div>
    )
  }

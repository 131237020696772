import { Tabs } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { findSectionByPath } from '@services/sections'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { TabsProps } from '@circlefin/components/lib/Tabs'
import type { Section } from '@services/sections/types'

export interface TabNavigationProps<TypeId extends string> {
  /**
   * Routes that compose the tab navigation.
   */
  routes: Section[]
  /**
   * Optional query that will be passed to the link component to replace
   * route parameters if a dynamic route is used.
   */
  query?: Record<string, string | number>
  /**
   * Variant of the tab items.
   */
  variant?: TabsProps<TypeId>['variant']
  /**
   * Additional classes for the container.
   */
  className?: string
  /**
   * Method called when tab is clicked.
   */
  onClick?: () => void
}

export const TabNavigation = <TypeId extends string>({
  routes,
  variant,
  className,
  onClick,
  query,
}: TabNavigationProps<TypeId>) => {
  const router = useRouter()
  const { t } = useTranslation()

  const current = findSectionByPath(router.pathname, routes)?.route

  const TabNavigationItem = (section: Section) => {
    const [{ Authorized }] = usePermission(section.permissions)

    return (
      <Authorized>
        <Tabs.Item key={section.route} id={section.route}>
          {current === section.route ? (
            t(section.label)
          ) : (
            <Link
              href={{
                pathname: section.route,
                query: query ?? {},
              }}
              onClick={onClick}
            >
              {t(section.label)}
            </Link>
          )}
        </Tabs.Item>
      </Authorized>
    )
  }

  return (
    <nav className={classNames('mb-5 mt-7', className)}>
      <Tabs current={current} variant={variant}>
        {Object.values(routes).map((route, index) => (
          <TabNavigationItem key={index} {...route} />
        ))}
      </Tabs>
    </nav>
  )
}

import { FixedBanner } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export const Draft: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <>
      <FixedBanner.Title>
        {t('policy.banners.draftPolicy.title')}
      </FixedBanner.Title>
      <FixedBanner.Description>
        <p>{t('policy.banners.draftPolicy.description')}</p>
      </FixedBanner.Description>
    </>
  )
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { BlockchainAlert } from '@features/blockchain/components'
import { BlockchainForms } from '@features/blockchain/forms'
import { InfoAlert } from '@features/delayed-withdrawals/containers'
import { Center } from '@shared/components/layout'
import { BlockchainAbbreviation, BlockchainActivityType } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useAddAddress } from '../../../AddAddress.Context'

const schema = y.object({
  /**
   * Wallet nickname.
   */
  nickname: y.string().required(),
  /**
   * Wallet address.
   */
  address: BlockchainForms.Input.AddressSchema.required(),
  /**
   * Blockchain.
   */
  blockchain: BlockchainForms.Dropdown.BlockchainSchema.required().optional(),
  /**
   * Selected asset.
   */
  asset: BlockchainForms.Combobox.assetSchema.required(),
  /**
   * Some blockchain addresses require a memo field.
   */
  memo: BlockchainForms.Input.memoSchema,
})

export type AddAddressMultiAssetCustomerValues = y.InferType<typeof schema>

export interface AddressDetailsCustomerFormProps {
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: AddAddressMultiAssetCustomerValues) => void
  /**
   * Loading state of mutation.
   */
  loading: boolean
}

export const AddressDetailsCustomerForm: React.FC<AddressDetailsCustomerFormProps> =
  ({ loading, onSubmit }) => {
    const { t } = useTranslation('address-book/modals')

    const [{ multiAssetCustomer }, { setMultiAssetCustomer }] = useAddAddress()

    const [Form, { watch, trigger }] =
      useForm<AddAddressMultiAssetCustomerValues>({
        schema,
        defaultValues: {
          ...multiAssetCustomer,
        },
      })

    // Form Values
    const blockchain = watch('blockchain')

    // Handle field change dependencies
    watch(({ address }, { name }) => {
      // When the blockchain field changed and address is not empty
      if (name === 'blockchain' && address !== undefined && address !== '') {
        // Trigger revalidation
        void trigger('address')
      }
    })

    const handleOnSubmit = useCallback(
      (values: AddAddressMultiAssetCustomerValues) => {
        setMultiAssetCustomer(values)
        onSubmit(values)
      },
      [setMultiAssetCustomer, onSubmit],
    )

    return (
      <Center>
        <Form className="grid w-96 gap-y-6" onSubmit={handleOnSubmit}>
          <Form.Input
            className="w-full"
            data-testid="input-nickname"
            display="floating"
            label={t`addAddress.addressDetails.vaultsEnabled.customer.nickname`}
            name="nickname"
          />

          <BlockchainForms.Dropdown.Blockchains
            activityType={BlockchainActivityType.WITHDRAWAL}
            className="w-full"
            data-testid="dropdown-blockchain"
            display="floating"
            label={t`addAddress.addressDetails.vaultsEnabled.selectBlockchain`}
          />

          <BlockchainForms.Combobox.Assets
            blockchain={blockchain}
            className="w-full"
            data-testid="combobox-assets"
            disabled={!blockchain}
          />

          <Form.Input
            className="w-full"
            data-testid="input-address"
            display="floating"
            label={t`addAddress.addressDetails.vaultsEnabled.address`}
            name="address"
          />

          <BlockchainForms.Input.Memo className="w-full" />

          <BlockchainForms.Input.Memo
            chain={blockchain?.chain}
            className="w-full"
          />

          <BlockchainAlert blockchain={blockchain?.chain} className="mt-8" />

          <InfoAlert className="mt-8" />

          {blockchain?.chain === BlockchainAbbreviation.FLOW && (
            <p className="w-full text-sm">
              <Trans
                components={{ b: <b /> }}
                i18nKey="address-book/modals:addAddress.addressDetails.flowDisclaimer"
              />
            </p>
          )}

          <Center className="pt-6" variant="horizontal">
            <Form.SubmitButton
              className="w-64"
              loading={loading}
              variant="secondary"
            >{t`common:continue`}</Form.SubmitButton>
          </Center>
        </Form>
      </Center>
    )
  }

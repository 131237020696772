import { Icon, TextLink } from '@circlefin/components'
import { useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const PersonalUse: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  return (
    <aside className="flex grow-0 justify-center bg-bold-neutral p-6">
      <div className="flex flex-col items-center gap-y-2 sm:flex-row sm:gap-x-2 sm:gap-y-0">
        <p className="text-white type-body-sm">
          {t`platformSelect.personalUse.description`}
        </p>
        <TextLink.Link
          className="text-white"
          href={t`platformSelect.personalUse.cta.link`}
          onClick={trackLinkClick}
          rel="noopener noreferrer nofollow"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t`platformSelect.personalUse.cta.label`}
          <Icon name="ExternalLinkOutline" />
        </TextLink.Link>
      </div>
    </aside>
  )
}

import { SkeletonBox } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { Base } from '@modals/layout'
import { CIRCLE_WALLETS_QUERY } from '@services/permissions'
import { CIRCLE_WALLET_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { useWalletApprovalSubmittedPolicyProgressQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface SubmittedPolicyProgressQueryProps {
  /**
   * Policy id.
   */
  policyId?: string
}

export const SubmittedPolicyProgress: React.FC<SubmittedPolicyProgressQueryProps> =
  (props) => {
    const { t } = useTranslation('modals.walletApprovalPolicy')
    const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)

    const {
      called,
      data: { walletApprovalSubmittedPolicyProgress } = {},
      loading: queryLoading,
      error,
      refetch,
    } = useWalletApprovalSubmittedPolicyProgressQuery({
      variables: {
        policyId: props.policyId ?? '',
      },
      skip: !props.policyId || !isAuthorized,
      fetchPolicy: 'cache-and-network',
      pollInterval: CIRCLE_WALLET_DETAILS_POLL_INTERVAL,
    })

    const approvedBy =
      walletApprovalSubmittedPolicyProgress?.approvedByUser ?? []
    const rejectedBy =
      walletApprovalSubmittedPolicyProgress?.rejectedByUser ?? []
    const pendingApprovals =
      walletApprovalSubmittedPolicyProgress?.pendingApprovalsUser ?? []

    const numOfApprovers =
      approvedBy.length + rejectedBy.length + pendingApprovals.length

    const loading =
      !called ||
      (walletApprovalSubmittedPolicyProgress === undefined && queryLoading)

    return (
      <Base className="min-h-96 px-8 pb-3">
        <h2 className="text-center text-lg leading-6 font-circular-medium">{t`submittedPolicyProgress.title`}</h2>
        <hr className="mt-6 h-px border-black-50" />
        <PropsErrorBoundary props={props} variant="page">
          {() => (
            <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
              <div className="grid grid-cols-1 gap-y-4 divide-y divide-black-50">
                <SkeletonBox className="block h-20" loading={loading}>
                  {pendingApprovals.length > 0 && (
                    <div className="flex flex-col gap-y-4 pt-4">
                      <div className="type-body-sm">
                        <Trans
                          components={{
                            b: <strong className="type-body-sm-bold" />,
                          }}
                          i18nKey="modals.walletApprovalPolicy:submittedPolicyProgress.awaiting.withCount"
                          values={{
                            current: pendingApprovals.length,
                            total: numOfApprovers,
                          }}
                        />
                      </div>
                      <ul className="space-y-4">
                        {pendingApprovals.map(({ name }, index) => (
                          <li key={index} className="flex justify-between">
                            <span className="type-body-sm-bold">{name}</span>
                            <span className="text-neutral-subtle type-body-sm">
                              {t('submittedPolicyProgress.awaiting.label')}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </SkeletonBox>

                <SkeletonBox className="block h-20" loading={loading}>
                  {approvedBy.length > 0 && (
                    <div className="flex flex-col gap-y-4 pt-4">
                      <div className="type-body-sm">
                        <Trans
                          components={{
                            b: <strong className="type-body-sm-bold" />,
                          }}
                          i18nKey="modals.walletApprovalPolicy:submittedPolicyProgress.approved.withCount"
                          values={{
                            current: approvedBy.length,
                            total: numOfApprovers,
                          }}
                        />
                      </div>
                      <ul className="space-y-4">
                        {approvedBy.map(({ name }, index) => (
                          <li key={index} className="flex justify-between">
                            <span className="type-body-sm-bold">{name}</span>
                            <span className="text-success type-body-sm">
                              {t('submittedPolicyProgress.approved.label')}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </SkeletonBox>

                <SkeletonBox className="block h-20" loading={loading}>
                  {rejectedBy.length > 0 && (
                    <div className="flex flex-col gap-y-4 pt-4">
                      <div className="type-body-sm">
                        <Trans
                          components={{
                            b: <strong className="type-body-sm-bold" />,
                          }}
                          i18nKey="modals.walletApprovalPolicy:submittedPolicyProgress.rejected.withCount"
                          values={{
                            current: rejectedBy.length,
                            total: numOfApprovers,
                          }}
                        />
                      </div>
                      <ul className="space-y-4">
                        {rejectedBy.map(({ name }, index) => (
                          <li key={index} className="flex justify-between">
                            <span className="type-body-sm-bold">{name}</span>
                            <span className="text-error type-body-sm">
                              {t('submittedPolicyProgress.rejected.label')}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </SkeletonBox>
              </div>
            </GraphQLErrorBoundary>
          )}
        </PropsErrorBoundary>
      </Base>
    )
  }

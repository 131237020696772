import { Card } from '@circlefin/components'
import cn from 'classnames'

import { Body } from './Body/Body'
import { Content } from './Content/Content'
import { Footer } from './Footer/Footer'
import { Image } from './Image/Image'

import type { CardProps } from '@circlefin/components/lib/Card'

export interface InfoCardProps extends CardProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

interface SubComponents {
  Body: typeof Body
  Content: typeof Content
  Footer: typeof Footer
  Image: typeof Image
}

export const InfoCard: React.FC<InfoCardProps> & SubComponents = ({
  children,
  className,
  ...props
}) => {
  return (
    <Card className={cn('flex', className)} {...props}>
      {children}
    </Card>
  )
}

InfoCard.Body = Body
InfoCard.Content = Content
InfoCard.Footer = Footer
InfoCard.Image = Image

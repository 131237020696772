import { useCallback, useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { LoadingSpinner } from '@shared/components/common'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  useVaultPolicyLazyQuery,
  useWalletConnectTermsAcceptanceLazyQuery,
} from '@shared/graphql'

import { CreateVaultNavigationStep } from '../../../containers/Create'
import { useCreateVault } from '../../../hooks/create'

export interface InitializeQueryProps {
  /**
   * Policy id belonging to the policy to be edited.
   */
  policyId?: string
  /**
   * Vault id of the vault where we will create/update this policy.
   */
  vaultId?: string
  /**
   * Name of the step to start the flow.
   */
  startingStep?: CreateVaultNavigationStep
  /**
   * Is wallet connect step visible.
   */
  isWalletConnectStepVisible?: boolean
}

/**
 * This modal step acts as the entry point for Creating/Updating a vault policy.
 */
export const Initialize: React.FC<InitializeQueryProps> = (props) => {
  const {
    policyId,
    vaultId,
    startingStep = CreateVaultNavigationStep.POLICY_NAME,
    isWalletConnectStepVisible = false,
  } = props

  const { router, close } = useModal()

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const [, { setPolicyInEdit, setVaultId, setWalletConnectStepVisible }] =
    useCreateVault()

  const [getWalletConnectTermsAcceptance] =
    useWalletConnectTermsAcceptanceLazyQuery({
      fetchPolicy: 'cache-and-network',
    })

  const [getPolicy, { loading, error, refetch }] = useVaultPolicyLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setWalletConnectStepVisible(isWalletConnectStepVisible)

    if (vaultId) {
      setVaultId(vaultId)
    }

    // store policy that is being edited in context
    if (policyId && vaultId) {
      void getPolicy({
        variables: {
          policyId,
          vaultId,
        },
        onCompleted: (data) => {
          setPolicyInEdit(data.vaultPolicy)
          router.replace(`/vault/createVault/${startingStep}`)
        },
      })
      return
    }

    // Check if user accepts web3 terms before creating a web3 vault
    if (isWalletConnectStepVisible) {
      void getWalletConnectTermsAcceptance({
        onCompleted: (data) => {
          if (!data.walletConnectTermsAcceptance.hasAccepted)
            router.replace('/vault/walletConnect/confirmation')
          else {
            router.replace(`/vault/createVault/${startingStep}`)
          }
        },
      })
      return
    }

    router.replace(`/vault/createVault/${startingStep}`)
  }, [
    policyId,
    vaultId,
    router,
    setPolicyInEdit,
    setVaultId,
    startingStep,
    getPolicy,
    isWalletConnectStepVisible,
    setWalletConnectStepVisible,
    getWalletConnectTermsAcceptance,
  ])

  return (
    <FullScreen onCloseClick={handleCloseClick} hideProgressBar>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <PropsErrorBoundary props={props} variant="page">
          {() => (
            <>
              {loading && (
                <Center>
                  <LoadingSpinner height={65} width={65} />
                </Center>
              )}
            </>
          )}
        </PropsErrorBoundary>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

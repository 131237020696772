import { AccessPermissions } from '@circlefin/permissions'

import { LocalStaticPermissionSet } from '../../../localStaticPermissionSets'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Means that a user has a session and an unverified email address. This should
 * only be used for pages or actions that a user without a verified email can
 * take (i.e., being redirected to the `/verify-email` page). It should not be
 * used for any other pages/queries/mutations.
 */
export const PROTECTED_UNVERIFIED_EMAIL: PermissionSet = {
  access: AccessPermissions.protected,
  customAccess: LocalStaticPermissionSet.protectedUnverifiedEmail,
}

import { homeSection } from '@services/sections/lib/home'

import { MainNav } from '../MainNav/MainNav'

import { SectionNavBottom } from './SectionNav.Bottom/SectionNav.Bottom'
import { SectionNavTop } from './SectionNav.Top/SectionNav.Top'

export interface SectionNavProps {
  /**
   * Collapsed?
   */
  collapsed?: boolean
}

export const SectionNav: React.FC<SectionNavProps> = ({
  collapsed = false,
}) => {
  return (
    <MainNav.Frame
      className="fixed"
      collapsed={collapsed}
      data-testid="main-nav"
      variant="dark"
    >
      <MainNav.Logo data-testid="main-nav-logo" section={homeSection} />
      <SectionNavTop />
      <SectionNavBottom />
    </MainNav.Frame>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Sandbox sign up section.
 */
export const SANDBOX_SIGN_UP_SECTION: PermissionSet = {
  access: FeatureSwitch.sandboxSignup()
    ? AccessPermissions.publicOnly
    : AccessPermissions.restricted,
}

import type React from 'react'
import { useCallback, useMemo } from 'react'

import { Menu, Avatar, Button, Logo } from '@circlefin/components'
import { homeSection, signOutSection } from '@services/sections/lib/home'
import { OnboardingApplicationOperationType } from '@shared/graphql'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { User } from 'next-auth'

export interface HeaderProps {
  /**
   * User.
   */
  user?: User
  /**
   * Href.
   */
  href?: string
  /**
   * Render back to Circle Mint home button.
   */
  showBackButton?: boolean
  /**
   * Application operation type.
   */
  operationType?: OnboardingApplicationOperationType
}

export const Header: React.FC<HeaderProps> = ({
  user,
  href = homeSection.route,
  showBackButton = false,
  operationType,
}) => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const userInitials: string = useMemo(() => {
    return (user?.givenName?.at(0) ?? '') + (user?.familyName?.at(0) ?? '')
  }, [user])

  const helpArticle = useMemo(() => {
    if (operationType === OnboardingApplicationOperationType.KYC) {
      return t`l3Onboarding:needHelp.link.kyc`
    }

    return t`l3Onboarding:needHelp.link.default`
  }, [operationType, t])

  const handleLogout = useCallback(() => {
    void router.push(signOutSection.route)
  }, [router])

  return (
    <header
      className="z-30 flex h-16 w-full items-center justify-between border-b-2 border-neutral-subtle px-6"
      data-testid="header-l3-onboarding"
    >
      <Link href={href}>
        <Logo size={8} />
      </Link>
      <div className="flex items-center justify-between gap-x-2">
        {showBackButton && (
          <Button.Link href={homeSection.route} variant="secondary">
            {t`backToCircleMint`}
          </Button.Link>
        )}
        <Button.Link
          className="text-neutral-strong"
          href={helpArticle}
          rel="noopener noreferrer"
          target="_blank"
          variant="text"
        >
          {t`needHelp`}
        </Button.Link>
        <Menu>
          <div className="flex items-center space-x-2">
            <Avatar
              className="bg-bold-neutral text-white type-body-sm-bold"
              size="lg"
            >
              {userInitials}
            </Avatar>
            <Menu.Icon name="ChevronDownSolid" size={20} />
          </div>

          <Menu.Options className="absolute z-10">
            <Menu.Item
              icon="LogoutOutline"
              label={t`log-out`}
              onClick={handleLogout}
            />
          </Menu.Options>
        </Menu>
      </div>
    </header>
  )
}

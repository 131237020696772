import { TabSwitch } from '@circlefin/components'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { CbitForm, WireForm, XpayForm } from '../../Forms'
import { TabItem } from '../../TabItem/TabItem'

export interface DefaultVariantProps {
  handleTabSwitch: (tab: string) => void
}

export const DefaultVariant: React.FC<DefaultVariantProps> = ({
  handleTabSwitch,
}) => {
  const { t } = useTranslation('modals.bankAccount')

  return (
    <TabSwitch onChange={handleTabSwitch} selected="wire">
      <Center>
        <TabSwitch.Tabs variant="underlined-black">
          <TabSwitch.Tab id="wire">
            <TabItem
              description={t`link.accountInformation.description.wireRtp`}
              type={t`link.accountInformation.type.wireRtp`}
            />
          </TabSwitch.Tab>
          <TabSwitch.Tab id="xpay">
            <TabItem
              description={t`link.accountInformation.description.instant`}
              type={t`link.accountInformation.type.xpay`}
            />
          </TabSwitch.Tab>
          <TabSwitch.Tab id="cbit">
            <TabItem
              description={t`link.accountInformation.description.instant`}
              type={t`link.accountInformation.type.cbit`}
            />
          </TabSwitch.Tab>
        </TabSwitch.Tabs>
      </Center>

      <div className="mt-6">
        <TabSwitch.Content id="wire">
          <WireForm />
        </TabSwitch.Content>

        <TabSwitch.Content id="xpay">
          <XpayForm />
        </TabSwitch.Content>

        <TabSwitch.Content id="cbit">
          <CbitForm />
        </TabSwitch.Content>
      </div>
    </TabSwitch>
  )
}

import { useMemo } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useMoney, useCustomMoney } from '@circlefin/hooks'
import useTranslation from 'next-translate/useTranslation'

import type { Amount, VaultAmountCombined } from '@shared/graphql'

/**
 * Limits Alert props.
 */
export interface LimitsAlertProps {
  /**
   * Amount - combined amount a user is attempting to transfer.
   */
  amount?: VaultAmountCombined
  /**
   * User's transaction limit.
   */
  userLimitAmount?: Amount
  /**
   * Wallet available amount.
   */
  walletAvailableAmount?: Amount
  /**
   * Custom className.
   */
  className?: string
}

export const LimitsAlert: React.FC<LimitsAlertProps> = ({
  amount,
  userLimitAmount,
  walletAvailableAmount,
  className,
}) => {
  const { t } = useTranslation('modals.vault')
  const { customMoney } = useCustomMoney()
  const { money } = useMoney()

  const i18nSharedQuery = useMemo(() => {
    if (!amount) {
      return
    }

    return {
      nativeAmount: customMoney({
        number: amount.native.amount,
        currencyConfig: {
          name: amount.native.asset.symbol,
          decimals: amount.native.asset.decimals,
          isCryptoCurrency: true,
        },
        options: { padded: false },
      }),
      fiatAmount: customMoney({
        number: amount.fiat.amount,
        currencyConfig: {
          name: amount.fiat.asset.symbol,
          decimals: amount.fiat.asset.decimals,
        },
      }),
    }
  }, [amount, customMoney])

  const alertText = useMemo(() => {
    if (
      !amount ||
      !userLimitAmount ||
      !walletAvailableAmount ||
      !i18nSharedQuery
    ) {
      return
    }

    if (Number(amount.fiat.amount) > Number(userLimitAmount.amount)) {
      return t(
        'transfer.sendOnChain.transferAmount.withdrawalLimits.alert.individualLimit',
        {
          ...i18nSharedQuery,
          limit: money({
            number: userLimitAmount.amount,
            variant: userLimitAmount.currency,
          }),
        },
      )
    }

    if (Number(amount.fiat.amount) > Number(walletAvailableAmount.amount)) {
      return t(
        'transfer.sendOnChain.transferAmount.withdrawalLimits.alert.availableWalletLimit',
        {
          ...i18nSharedQuery,
          available: money({
            number: walletAvailableAmount.amount,
            variant: walletAvailableAmount.currency,
          }),
        },
      )
    }
  }, [
    amount,
    i18nSharedQuery,
    money,
    t,
    userLimitAmount,
    walletAvailableAmount,
  ])

  return (
    <FixedBanner
      className={className}
      data-testid="withdrawal-limits-alert"
      status="error"
      visible={Boolean(alertText)}
    >
      <FixedBanner.Description>{alertText}</FixedBanner.Description>
    </FixedBanner>
  )
}

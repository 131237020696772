import { useCallback, useMemo } from 'react'

import { Menu } from '@circlefin/components'
import { VaultUserPolicyRoleEnum } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { VaultUserPolicyRoleText } from '../../../VaultUserPolicyRoleText/VaultUserPolicyRoleText'

// Enum mapping only for the translation keys
enum Options {
  INITIATE_APPROVE = 'initiateApprove',
  INITIATE = 'initiate',
  APPROVE = 'approve',
  VIEW_ONLY = 'viewOnly',
}

export interface DefaultProps {
  /**
   * Is owner of the vault being created.
   */
  isOwner: boolean
  /**
   * Selected permission.
   */
  selectedPermissions: VaultUserPolicyRoleEnum[]
  /**
   * OnClick handler.
   */
  onClick?: (selected: VaultUserPolicyRoleEnum[]) => void
  /**
   * Remove handler.
   */
  onRemove?: () => void
}

export const Default: React.FC<DefaultProps> = ({
  isOwner,
  selectedPermissions,
  onClick,
  onRemove,
}) => {
  const { t } = useTranslation('modals.vault')

  const handleClick = useCallback(
    (value: VaultUserPolicyRoleEnum[]) => () => {
      onClick?.(isOwner ? [VaultUserPolicyRoleEnum.CREATOR, ...value] : value)
    },
    [isOwner, onClick],
  )

  const permissionValue = useMemo(() => {
    if (
      selectedPermissions.includes(VaultUserPolicyRoleEnum.APPROVER) &&
      selectedPermissions.includes(VaultUserPolicyRoleEnum.OPERATOR)
    ) {
      return Options.INITIATE_APPROVE
    }

    if (selectedPermissions.includes(VaultUserPolicyRoleEnum.APPROVER)) {
      return Options.APPROVE
    }

    if (selectedPermissions.includes(VaultUserPolicyRoleEnum.OPERATOR)) {
      return Options.INITIATE
    }

    return Options.VIEW_ONLY
  }, [selectedPermissions])

  return (
    <Menu>
      <Menu.Dropdown>
        <span>
          <VaultUserPolicyRoleText permissions={selectedPermissions} bolded />
        </span>
      </Menu.Dropdown>
      <Menu.Options anchorPosition="left" className="z-30 max-w-80">
        <Menu.Item
          icon={
            Options.INITIATE_APPROVE === permissionValue
              ? 'CheckOutline'
              : undefined
          }
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey={`modals.vault:createVault.permissions.options.${Options.INITIATE_APPROVE}`}
            />
          }
          onClick={handleClick([
            VaultUserPolicyRoleEnum.OPERATOR,
            VaultUserPolicyRoleEnum.APPROVER,
          ])}
          variant="secondary"
        />
        <Menu.Item
          icon={
            Options.INITIATE === permissionValue ? 'CheckOutline' : undefined
          }
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey={`modals.vault:createVault.permissions.options.${Options.INITIATE}`}
            />
          }
          onClick={handleClick([VaultUserPolicyRoleEnum.OPERATOR])}
          variant="secondary"
        />
        <Menu.Item
          icon={
            Options.APPROVE === permissionValue ? 'CheckOutline' : undefined
          }
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey={`modals.vault:createVault.permissions.options.${Options.APPROVE}`}
            />
          }
          onClick={handleClick([VaultUserPolicyRoleEnum.APPROVER])}
          variant="secondary"
        />
        <Menu.Item
          icon={
            Options.VIEW_ONLY === permissionValue ? 'CheckOutline' : undefined
          }
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey={`modals.vault:createVault.permissions.options.${Options.VIEW_ONLY}`}
            />
          }
          onClick={handleClick([VaultUserPolicyRoleEnum.AUDITOR])}
          variant="secondary"
        />
        <Menu.Divider inactive={isOwner} />
        <Menu.Item
          inactive={isOwner}
          label={t('createVault.permissions.options.remove')}
          onClick={onRemove}
          variant="secondary"
        />
      </Menu.Options>
    </Menu>
  )
}

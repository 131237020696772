import { useCallback } from 'react'

import { Statement } from '@circlefin/components'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { BeneficiaryBankAccountForm } from '@features/bank-account/containers'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { FeatureSwitch } from '@services/feature-switch'
import { routes } from '@services/sections/modal/routes'
import { TypeGuards } from '@services/type-guards'
import { TransWithLink } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import { BankAccountType, Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useTransfer } from '../../../hooks/transfer'

import type { BeneficiaryFormProps } from '@features/bank-account/containers'

const sharedClasses =
  'text-lg font-circular-regular leading-6 text-neutral-strong text-center'

const supportedCurrencies = [Currency.USD, Currency.EUR]

export const BeneficiaryBankAccount: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const { router } = useModal()
  const [{ bankAccount, beneficiaryAgreement }, { handleValueChange }] =
    useTransfer()
  const [{ currency }, { currencyToToken }] = useCurrentCurrency()
  const modalHistory = useModalHistory()

  const subheaderType = bankAccount?.type ?? BankAccountType.wire

  const isSignet = bankAccount && TypeGuards.BankAccount.isSignet(bankAccount)

  const isCbit = bankAccount && TypeGuards.BankAccount.isCbit(bankAccount)

  const isXpay = bankAccount && TypeGuards.BankAccount.isXpay(bankAccount)

  const isRtp =
    bankAccount &&
    TypeGuards.BankAccount.isRtp(bankAccount) &&
    FeatureSwitch.rtpBankSupportEnabled()

  const isBankWire = bankAccount && TypeGuards.BankAccount.isWire(bankAccount)

  const isSilvergate =
    isBankWire && TypeGuards.BankAccount.isSilvergate(bankAccount)

  const isSupportedCurrency = supportedCurrencies.includes(currency!)

  const handleOnContinue = useCallback(
    (v: BeneficiaryFormProps) => {
      handleValueChange(v)

      router.push({
        pathname: routes.transfer.wire.pleaseTransferFunds,
        query: {
          receivingToken: currencyToToken(currency ?? Currency.USDC),
          bankAccount,
        },
      })
    },
    [bankAccount, currency, currencyToToken, handleValueChange, router],
  )

  const validateRequestAndGetRejectedReason = () => {
    if (!isSupportedCurrency) {
      return 'unsupportedCurrency'
    }

    if (isSilvergate) {
      return 'isSilvergate'
    }

    if (isSignet) {
      return 'isSignet'
    }

    if (isCbit || isXpay || isRtp || isBankWire) {
      return null
    }

    return 'unknown'
  }

  const rejectedReason = validateRequestAndGetRejectedReason()

  const renderInstructions = () =>
    bankAccount && (
      <BeneficiaryBankAccountForm
        bankAccount={bankAccount}
        currency={currency}
        initialValues={{
          beneficiaryAgreement: beneficiaryAgreement,
        }}
        onContinue={handleOnContinue}
      />
    )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 2}>
      <Center variant="horizontal">
        <div className="mb-8 text-center">
          <h2 className="mb-3 text-2xl font-circular-bold">
            {t`wire.beneficiaryBankAccount.header`}
          </h2>
          <h3 className="mt-2 font-circular text-lg leading-6 text-black-400">
            {t(`wire.beneficiaryBankAccount.subHeader.${subheaderType}`)}
          </h3>
        </div>

        <div className="w-192">
          {!rejectedReason ? (
            renderInstructions()
          ) : (
            <Statement
              status="warning"
              title={t(
                `wire.beneficiaryBankAccount.disabled.${rejectedReason}.title`,
              )}
            >
              <Center>
                <p className={sharedClasses}>
                  {t(
                    `wire.beneficiaryBankAccount.disabled.${rejectedReason}.body.0`,
                  )}
                </p>
                <p className={sharedClasses}>
                  {t(
                    `wire.beneficiaryBankAccount.disabled.${rejectedReason}.body.1`,
                  )}
                </p>
                <p className={sharedClasses}>
                  <TransWithLink
                    i18nKey="modals.transfer:wire.beneficiaryBankAccount.disabled.supportMessage"
                    variant="internal-support"
                  />
                </p>
              </Center>
            </Statement>
          )}
        </div>
      </Center>
    </FullScreen>
  )
}

import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { Choose } from '../../containers'

export interface ChooseTransferTypeQueryProps {
  /**
   * Specify modal currency.
   */
  currency?: Currency
}

export const ChooseTransferType: React.FC<ChooseTransferTypeQueryProps> = ({
  currency = Currency.USDC,
}) => {
  const { t } = useTranslation('modals.transfer')

  return (
    <FullScreen totalSteps={5}>
      <Center className="font-circular" variant="horizontal">
        <h2 className="text-center text-2xl text-black-600 font-circular-bold">
          {t('chooseTransferType.title')}
        </h2>

        <div className="mt-10">
          <Choose.TransferType currency={currency} />
        </div>
      </Center>
    </FullScreen>
  )
}

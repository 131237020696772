import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import useTranslation from 'next-translate/useTranslation'

export interface UnarchiveProps {
  /**
   * Asset name of the archived wallet.
   */
  asset?: string
}

export const Unarchive: React.FC<UnarchiveProps> = ({ asset }) => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const handleCloseModal = useCallback(() => {
    modal.close()
  }, [modal])

  const handleUnarchiveWallet = useCallback(() => {
    // Todo: Connect to unarchive mutation when endpoint becomes available
  }, [])

  return (
    <BaseTitle
      title={t('vaultWalletDetails.unarchive.title', {
        asset: asset ?? '',
      })}
    >
      <p className="text-center">{t`vaultWalletDetails.unarchive.description`}</p>

      <Modal.Footer variant="stretch-equal">
        <Button onClick={handleCloseModal} variant="secondary">
          {t`common:cancel`}
        </Button>
        <Button onClick={handleUnarchiveWallet} variant="primary">
          {t`vaultWalletDetails.unarchive.action`}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

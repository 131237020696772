import type {
  VaultAddressTransactionLocation,
  VaultRecipientAddressTransactionLocation,
  VaultTransactionLocation,
  VaultWalletTransactionLocation,
} from '@shared/graphql'

/**
 * Type guard to check if a given location is a vault wallet transaction location.
 */
export const isVaultWalletTransactionLocation = (
  location: VaultTransactionLocation,
): location is VaultWalletTransactionLocation =>
  location.__typename === 'VaultWalletTransactionLocation'

/**
 * Type guard to check if a given location is a vault address transaction location.
 */
export const isVaultAddressTransactionLocation = (
  location: VaultTransactionLocation,
): location is VaultAddressTransactionLocation =>
  location.__typename === 'VaultAddressTransactionLocation'

/**
 * Type guard to check if a given location is a vault recipient address transaction location.
 */
export const isVaultRecipientAddressTransactionLocation = (
  location: VaultTransactionLocation,
): location is VaultRecipientAddressTransactionLocation =>
  location.__typename === 'VaultRecipientAddressTransactionLocation'

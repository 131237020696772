import { useCallback } from 'react'

import { Modal, Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { addressBook } from '@services/sections/lib/address-book'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  useRemoveBlockchainRecipientAddressMutation,
  BlockchainRecipientAddressesWithMetadataDocument,
} from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export interface ConfirmationProps {
  /**
   * Address nickname.
   */
  nickname?: string
  /**
   * Address Id.
   */
  addressId?: string
}

export const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const { t } = useTranslation('address-book/modals')

  const modal = useModal()
  const router = useRouter()
  const [removeAddress, { error }] =
    useRemoveBlockchainRecipientAddressMutation({
      variables: {
        addressId: props.addressId ?? '',
      },
      refetchQueries: [BlockchainRecipientAddressesWithMetadataDocument],
    })

  const handleClose = useCallback(() => {
    modal.close({ context: 'onDismiss' })
  }, [modal])

  const handleRemove = useCallback(() => {
    void removeAddress({
      onCompleted: () => {
        modal.close({ context: 'onConfirm' })
        void router.push(addressBook.route)
      },
    })
  }, [removeAddress, modal, router])

  return (
    <PropsErrorBoundary props={props} variant="component">
      {({ nickname }) => (
        <GraphQLErrorBoundary error={error}>
          <Modal.Header
            iconName="TrashOutline"
            title={t`removeAddress.confirmation.title`}
            variant="error"
          />

          <Modal.Body variant="center">
            {t('removeAddress.confirmation.body', { nickname })}
          </Modal.Body>

          <Modal.Footer variant="stretch-fraction">
            <Button onClick={handleClose} variant="secondary">
              {t`removeAddress.confirmation.cta.cancel`}
            </Button>
            <Button
              intent="destructive"
              onClick={handleRemove}
              variant="primary"
            >
              {t`removeAddress.confirmation.cta.remove`}
            </Button>
          </Modal.Footer>
        </GraphQLErrorBoundary>
      )}
    </PropsErrorBoundary>
  )
}

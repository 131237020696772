import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import {
  PolicySummary,
  useUpsertWalletApprovalPolicy,
} from '../../../../../containers/approval-policy'
import { useWalletApprovalPolicy } from '../../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../../layout'

import type { PolicySummaryProps } from '../../../../../containers/approval-policy/PolicySummary/types'

export const Policy: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const modal = useModal()
  const [{ currency = Currency.USDC }, { tokenToCurrency }] =
    useCurrentCurrency()

  const [save, { error, loading, reset }] = useUpsertWalletApprovalPolicy({
    onCompleted: () => {
      modal.router.push(routes.walletApprovalPolicy.createPolicy.finished)
    },
    // To avoid uncaught error
    onError: () => null,
  })

  const handleEditClick = useCallback<
    Required<PolicySummaryProps>['onEditClick']
  >(
    (step) =>
      modal.router.push(
        `${routes.walletApprovalPolicy.createPolicy.switch}${step}`,
      ),
    [modal.router],
  )

  const [policyState] = useWalletApprovalPolicy()

  return (
    <WalletApprovalPolicyLayout currentStep={WalletApprovalPolicyStep.REVIEW}>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <div className="grid max-w-176 grid-cols-1 justify-items-start">
          <h2 className="mb-1 text-neutral-strong type-h-page-sm">
            {t('review.policy.title')}
          </h2>

          <PolicySummary
            className="mt-8"
            {...policyState}
            currency={tokenToCurrency(currency)}
            onEditClick={handleEditClick}
            condensed
            editEnabled
          />

          <div className="mt-4">
            <ModalBackButton />

            <Button
              className="w-60"
              loading={loading}
              onClick={save}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>
        </div>
      </GraphQLErrorBoundary>
    </WalletApprovalPolicyLayout>
  )
}

import { CIRCLE_WALLETS_FTU_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import {
  BankAccountFtuLinkStatus,
  useCircleWalletsFtuStatusQuery,
} from '@shared/graphql'

import { LinkAccount, LinkAccountFailed, Deposit } from './variants'

export interface CircleWalletsFTUBannerProps {
  /**
   * Custom style.
   */
  className?: string
}

export const CircleWalletsFTUBanner: React.FC<CircleWalletsFTUBannerProps> = ({
  className,
}) => {
  const { data: { circleWalletsFtuStatus } = {}, stopPolling } =
    useCircleWalletsFtuStatusQuery({
      fetchPolicy: 'cache-and-network',
      pollInterval: CIRCLE_WALLETS_FTU_POLL_INTERVAL,
      onCompleted: (data) => {
        const { circleWalletsFtuStatus } = data

        // stop polling if user has completed linking at least one bank account and has made one deposit
        if (
          circleWalletsFtuStatus.linkStatus ===
            BankAccountFtuLinkStatus.complete &&
          circleWalletsFtuStatus.hasDepositedOnce
        ) {
          stopPolling()
        }
      },
    })

  if (!circleWalletsFtuStatus) {
    return null
  }

  const { linkStatus, hasDepositedOnce } = circleWalletsFtuStatus

  // user has not started linking an account
  if (linkStatus === BankAccountFtuLinkStatus.none) {
    return <LinkAccount className={className} />
  }

  // user's attempt to link an account failed. It transitioned from none -> pending -> failed
  if (linkStatus === BankAccountFtuLinkStatus.failed) {
    return <LinkAccountFailed className={className} />
  }

  // if they have not done a first deposit, prompt them to do so.
  if (hasDepositedOnce === false) {
    return <Deposit className={className} />
  }

  return null
}

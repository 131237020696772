import { HubspotScript } from '@services/hubspot/react'
import getConfig from 'next/config'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const HubspotProvider: React.FC = () => {
  return <HubspotScript portalId={publicRuntimeConfig?.HUBSPOT_PORTAL_ID} />
}

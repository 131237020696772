import { Card, DescriptionList } from '@circlefin/components'
import classNames from 'classnames'

import { PolicySummaryProvider } from './PolicySummary.Context'
import { ApproverLevels } from './Variants/ApproverLevels/ApproverLevels'
import { ContractInteractions } from './Variants/ContractInteractions/ContractInteractions'
import { OutgoingAddresses } from './Variants/OutgoingAddresses/OutgoingAddresses'
import { PolicyLimit } from './Variants/PolicyLimit/PolicyLimit'
import { PolicyName } from './Variants/PolicyName/PolicyName'
import { UserLimits } from './Variants/UserLimits/UserLimits'
import { UserPermissions } from './Variants/UserPermissions/UserPermissions'

import type { PolicySummaryProps } from './types'

export const PolicySummary: React.FC<PolicySummaryProps> = ({
  className,
  condensed,
  ...rest
}) => {
  const { isWalletConnectStepVisible } = rest

  return (
    <PolicySummaryProvider {...rest} condensed={condensed}>
      <Card
        className={classNames('w-full font-circular', className, {
          'max-w-160 ': condensed,
        })}
      >
        <Card.Content>
          <DescriptionList
            className={classNames('grid items-start', {
              'grid-cols-3': condensed,
              'grid-cols-5': !condensed,
            })}
            density="loose"
          >
            <PolicyName />
            {isWalletConnectStepVisible && <ContractInteractions />}
            <UserPermissions />
            <PolicyLimit />
            <UserLimits />
            <OutgoingAddresses />
            <ApproverLevels />
          </DescriptionList>
        </Card.Content>
      </Card>
    </PolicySummaryProvider>
  )
}

import { homeSection } from '@services/sections/lib/home'
import { settingsSection } from '@services/sections/lib/settings'
import useTranslation from 'next-translate/useTranslation'

import { MainNav } from '../MainNav/MainNav'

import { SettingsNavBottom } from './SettingsNav.Bottom/SettingsNav.Bottom'
import { SettingsNavTop } from './SettingsNav.Top/SettingsNav.Top'

export interface SettingsNavProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const SettingsNav: React.FC<SettingsNavProps> = () => {
  const { t } = useTranslation()

  return (
    <MainNav.Frame
      className="fixed"
      collapsed={false}
      data-testid="settings-nav"
      variant="dark"
    >
      <MainNav.Logo section={homeSection} />
      <h2 className="mt-1 px-6 py-2 text-base uppercase text-white font-circular-bold">
        {t(settingsSection.label)}
      </h2>
      <SettingsNavTop />
      <SettingsNavBottom />
    </MainNav.Frame>
  )
}

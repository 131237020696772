import Script from 'next/script'

export interface HubspotScriptProps {
  /**
   * Hubspot Portal ID.
   */
  portalId?: string
}

export const HubspotScript: React.FC<HubspotScriptProps> = ({ portalId }) => {
  if (!portalId) {
    console.warn('[HubspotScript]: Missing Portal ID')
    return null
  }

  return (
    <Script
      id="hs-script-loader"
      src={`//js.hs-scripts.com/${portalId}.js`}
      type="text/javascript"
    />
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Custody Section Permissions.
 */
export const CUSTODY_POLICY_SECTION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_CUSTODY_POLICY_ROLE],
}

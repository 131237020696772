import { useMemo } from 'react'

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { FeatureSwitch } from '@services/feature-switch'
import { OnboardingQuarterlyMintingVolume } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export const mintingVolumeSchema = FeatureSwitch.inviteOnlyPhase2Enabled()
  ? y
      .mixed<OnboardingQuarterlyMintingVolume>()
      .oneOf(Object.values(OnboardingQuarterlyMintingVolume))
      .required()
  : y.string().required()

export interface QuarterlyMintingVolumeProps
  extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  /**
   * Force loading state.
   */
  loading?: boolean
}

const Dropdown = createFormDropdown()

export const QuarterlyMintingVolume: React.FC<QuarterlyMintingVolumeProps> = ({
  loading,
  ...props
}) => {
  const { t } = useTranslation('onboard/account-setup')

  const data: Array<SelectListItem<OnboardingQuarterlyMintingVolume>> =
    useMemo(() => {
      const orderedValues = [
        OnboardingQuarterlyMintingVolume.from250kto499k,
        OnboardingQuarterlyMintingVolume.from500kto1mil,
        OnboardingQuarterlyMintingVolume.from1milto5mil,
        OnboardingQuarterlyMintingVolume.over5mil,
      ]

      return orderedValues.map((option) => ({
        label: t(
          `onboard/common:form.quarterlyMintingVolume.${OnboardingQuarterlyMintingVolume[option]}`,
        ),
        value: OnboardingQuarterlyMintingVolume[option],
      }))
    }, [t])

  return (
    <Dropdown
      data-testid="dropdown-quarterly-minting-volume"
      inputLoading={loading}
      items={data}
      label={t`confirm.form.quarterlyMintingVolume.label`}
      maxMenuItems={6}
      placeholder={t`confirm.form.quarterlyMintingVolume.placeholder`}
      {...props}
    />
  )
}

import useTranslation from 'next-translate/useTranslation'

import { RolrStepper } from './Stepper'

export interface RolrProgressShellProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const RolrProgressShell: React.FC<RolrProgressShellProps> = ({
  children,
}) => {
  const { t } = useTranslation('onboard/rolr-signup')

  return (
    <div className="flex grow flex-col gap-y-12 self-start pb-12 md:flex-row md:gap-x-13 md:pb-0">
      <div className="shrink-0">
        <h3 className="mb-4 text-neutral type-body-base-bold">
          {t('stepper.title')}
        </h3>
        <RolrStepper />
      </div>
      <div className="md:max-w-144 md:grow">{children}</div>
    </div>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export const CancelApproval: React.FC = () => {
  const { t } = useTranslation('vaults')
  const { track } = useSegment()
  const { vaultMetadata } = useVaultPermission()

  const { router } = useModal()

  const handleClick = useCallback(() => {
    track(SegmentEvents.VaultCancelPendingApprovalClicked)
    router.push({
      pathname: routes.vault.vaultDetails.cancelPendingApproval,
      query: {
        vaultId: vaultMetadata?.id,
      },
    })
  }, [router, track, vaultMetadata?.id])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata?.isCreator) return null

  /**
   * Do not return anything when vault is not in pending state.
   */
  if (
    ![
      VaultStateEnum.PENDING_APPROVAL,
      VaultStateEnum.CHANGES_PENDING_APPROVAL,
    ].includes(vaultMetadata.state)
  )
    return null

  return (
    <Button intent="destructive" onClick={handleClick} variant="text">
      {t('buttons.vault.cancelApproval')}
    </Button>
  )
}

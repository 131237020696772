import { InternalWalletType } from '@shared/graphql'

import type {
  CircleInternalWallet,
  DirectInternalWallet,
  InternalWallet,
} from '@shared/graphql'

/**
 * Type guard to check if a given wallet is a circle internal wallet.
 */
export function isCircle(
  wallet: InternalWallet,
): wallet is CircleInternalWallet {
  return wallet.type === InternalWalletType.CIRCLE
}

/**
 * Type guard to check if a given wallet is a direct internal wallet.
 */
export function isDirect(
  wallet: InternalWallet,
): wallet is DirectInternalWallet {
  return wallet.type === InternalWalletType.DIRECT
}

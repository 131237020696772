import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { Center, Justify } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useTransferWithFx } from '../../../hooks/fx'

import { DepositInstructionsForm } from './Form/DepositInstructions.Form'
import { ReviewDepositCard } from './ReviewDepositCard/ReviewDepositCard'

export const DepositInstructions: React.FC = () => {
  const { t } = useTranslation('modals.transfer')
  const { router } = useModal()
  const { formValues, quote, loading } = useTransferWithFx()
  const bankAccount = useMemo(() => formValues?.account, [formValues?.account])
  const fromCurrency = useMemo(
    () => quote?.from.currency,
    [quote?.from.currency],
  )

  const toCurrency = useMemo(() => quote?.to.currency, [quote?.to.currency])

  const handleOnContinue = useCallback(() => {
    router.push({
      pathname: routes.transfer.wire.pleaseTransferFunds,
      query: {
        receivingToken: toCurrency,
        bankAccount,
      },
    })
  }, [bankAccount, toCurrency, router])

  return (
    <FullScreen totalSteps={3}>
      <Center className="mb-8" variant="horizontal">
        <h2 className="mb-2 text-center text-3xl font-circular-bold">
          {t`fx.instructions.header`}
        </h2>
        <h3 className="text-sm text-neutral font-circular-regular">
          {t`fx.instructions.subHeader`}
        </h3>
      </Center>

      <Justify>
        <Justify.Content variant="center">
          <div className="h-fit">
            {bankAccount && (
              <DepositInstructionsForm
                bankAccount={bankAccount}
                currency={fromCurrency}
                onContinue={handleOnContinue}
              />
            )}
          </div>
        </Justify.Content>
        <Justify.Content className="mt-8" variant="right">
          <ReviewDepositCard bankAccount={bankAccount} loading={loading} />
        </Justify.Content>
      </Justify>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { Button, LottieIcon, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useDownloadVaultTransactionsReportQuery } from '@shared/graphql'
import { padStart } from 'lodash'
import useTranslation from 'next-translate/useTranslation'

import type { DownloadVaultTransactionsReportInput } from '@shared/graphql'

export interface DownloadTransactionsReportProps {
  /**
   * Optional input which configures the transactions that should be downloaded.
   */
  input?: DownloadVaultTransactionsReportInput
}

export const DownloadTransactionsReport: React.FC<DownloadTransactionsReportProps> =
  ({ input = {} }) => {
    const { t } = useTranslation('modals.vault')

    const modal = useModal()

    const handleCancelClick = useCallback(() => {
      modal.close()
    }, [modal])

    const { loading, error, data } = useDownloadVaultTransactionsReportQuery({
      variables: { input },
      fetchPolicy: 'network-only',
    })

    const handleDownloadClick = useCallback(() => {
      const href =
        data != null
          ? `data:text/csv;charset=utf-8,${data.downloadVaultTransactionsReport.reportInCSV}`
          : ''
      const downloadLink = document.createElement('a')

      const currentDate = new Date()

      /**
       * Schemas:
       * transaction_history_20230207_104453_\{vaultId\}.csv
       * transaction_history_20230207_104453_\{vaultId\}_\{walletId\}.csv.
       *
       * Examples:
       * transaction_history_20230207_104453_e940107b-15ab-4767-b17c-0d31805a404a.csv
       * transaction_history_20230207_104453_e940107b-15ab-4767-b17c-0d31805a404a_a4c0504b-8425-4255-9f1a-813cd716a97f.csv.
       */
      const fileName =
        [
          'transaction_history',
          [
            currentDate.getFullYear(),
            padStart((currentDate.getMonth() + 1).toString(), 2, '0'),
            padStart(currentDate.getDate().toString(), 2, '0'),
          ].join(''),
          [
            padStart(currentDate.getHours().toString(), 2, '0'),
            padStart(currentDate.getMinutes().toString(), 2, '0'),
            padStart(currentDate.getSeconds().toString(), 2, '0'),
          ].join(''),
          input.vaultId,
          input.walletId,
        ]
          .filter(Boolean)
          .join('_') + '.csv'

      downloadLink.href = href
      downloadLink.download = fileName
      downloadLink.click()

      // close modal after the download was triggered
      handleCancelClick()
    }, [data, input, handleCancelClick])

    return (
      <BaseTitle
        title={t(
          `report.downloadTransactionsReport.title.${
            input.startDate || input.endDate ? 'filtered' : 'all'
          }`,
        )}
        variant="center"
      >
        <div className="my-6">
          <p className="text-base leading-8 text-black-600 font-circular-book">
            {t(
              `report.downloadTransactionsReport.${
                data ? 'available' : 'processing'
              }`,
            )}
          </p>
          {loading && (
            <>
              <p className="text-base text-black-600">
                {t('report.downloadTransactionsReport.description')}
              </p>
              <div className="mx-auto w-10 pt-6">
                <LottieIcon height={40} name="LoadingSpinner" width={40} loop />
              </div>
            </>
          )}
          {error && (
            <p className="text-base leading-10 text-red-500">
              {t('report.downloadTransactionsReport.error')}
            </p>
          )}
        </div>
        <Modal.Footer variant="stretch">
          <Button
            className="grow"
            onClick={handleCancelClick}
            variant="secondary"
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="grow"
            disabled={loading || Boolean(error)}
            onClick={handleDownloadClick}
            variant="primary"
          >
            {t('common:download')}
          </Button>
        </Modal.Footer>
      </BaseTitle>
    )
  }

import { Table } from '@circlefin/components'

import { StateHandler } from './StateHandler/StateHandler'

import type { StateHandlerProps } from './StateHandler/StateHandler'
import type { TableProps } from '@circlefin/components/lib/Table'

export interface SimpleTableProps
  extends Pick<
      TableProps,
      'layout' | 'hoverHighlight' | 'numLoadingRows' | 'numLoadingColumns'
    >,
    StateHandlerProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style?
   */
  className?: string
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  children,
  className,
  loading,
  layout = 'auto',
  hoverHighlight,
  numLoadingRows,
  numLoadingColumns,
  ...handlerProps
}) => (
  <div className={className}>
    <Table
      hoverHighlight={hoverHighlight}
      layout={layout}
      loading={loading}
      numLoadingColumns={numLoadingColumns}
      numLoadingRows={numLoadingRows}
    >
      {children}
    </Table>
    <StateHandler {...handlerProps} loading={loading} />
  </div>
)

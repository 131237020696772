import { useModalHistory } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { DepositDetailsForm } from './DepositDetails.Form'

export const DepositDetails: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modalHistory = useModalHistory()

  return (
    <FullScreen totalSteps={modalHistory.history.length + 3}>
      <Center className="text-center" variant="horizontal">
        <h2 className="text-4xl font-circular-black">{t`deposit.blockchain.depositDetails.title`}</h2>
        <h3 className="mt-2 text-base font-circular-regular">{t`deposit.blockchain.depositDetails.subtitle`}</h3>
      </Center>
      <Center className="mt-9" variant="horizontal">
        <div className="w-104">
          <DepositDetailsForm />
        </div>
      </Center>
    </FullScreen>
  )
}

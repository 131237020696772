import type {
  FieldFunctionOptions,
  FieldMergeFunction,
  Reference,
  StoreObject,
} from '@apollo/client'
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import type {
  PaginationLinks,
  QueryUnreadInAppNotificationsArgs,
} from '@shared/graphql'

export interface InAppNotificationsRefs extends StoreObject {
  links: PaginationLinks
  list: Array<Reference>
}

/**
 * Merge In app notifications.
 * For infinite scrolling, as new page data is received, merge with existing list.
 */
export const mergeInAppNotifications: FieldMergeFunction<
  InAppNotificationsRefs,
  InAppNotificationsRefs
> = (
  existing,
  incoming,
  { readField, args }: FieldFunctionOptions<QueryUnreadInAppNotificationsArgs>,
): InAppNotificationsRefs => {
  const merged = [...(existing?.list ?? [])]
  let offset = offsetFromCursor(
    readField,
    merged,
    args?.page?.pageAfter ?? undefined,
  )
  // If we couldn't find the cursor, default to appending to
  // the end of the list, so we don't lose any data.
  if (offset < 0) offset = merged.length
  // Now that we have a reliable offset, copy over incoming list starting from cursor
  for (let i = 0; i < incoming.list.length; i++) {
    merged[offset + i] = incoming.list[i]
  }

  return {
    ...incoming,
    list: merged,
  }
}

function offsetFromCursor(
  readField: ReadFieldFunction,
  items: Array<Reference>,
  cursor?: string,
) {
  // Search from the back of the list because the cursor we're
  // looking for is typically the ID of the last item.
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i]

    if (readField('id', item) === cursor) {
      // Add one because the cursor identifies the item just
      // before the first item in the page we care about.
      return i + 1
    }
  }
  // cursor could not be found.
  return -1
}

import { Cbit } from './Cbit/Cbit'
import { Signet } from './Signet/Signet'
import { Wire } from './Wire/Wire'

import type { BankAccount } from '@shared/graphql'

export interface BankAccountDetailsProps {
  /**
   * Selected Bank Account.
   */
  bankAccount: BankAccount
}

export const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({
  bankAccount,
}) => {
  switch (bankAccount.__typename) {
    case 'SignetBankAccount':
      return <Signet {...bankAccount} />

    case 'CbitBankAccount':
      return <Cbit {...bankAccount} />

    case 'WireBankAccount':
      return <Wire {...bankAccount} />
  }

  return null
}

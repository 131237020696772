import type React from 'react'

import { Card, Chip, DescriptionList } from '@circlefin/components'
import { useDate, useMoney } from '@circlefin/hooks'
import { Currency, DepositStatus } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { GraphQLErrorBoundary } from '../../../errors'
import { createDepositFxRateString, currencyToToken } from '../helpers'

import type { ApolloError } from '@apollo/client'
import type { Deposit } from '@shared/graphql'

export interface DepositCardProps {
  /**
   * Deposit object containing information about the transaction.
   */
  deposit?: Deposit
  /**
   * Apollo Error object in the event fetching deposit data failed.
   */
  error?: ApolloError
}

export const DepositCard: React.FC<DepositCardProps> = ({ deposit, error }) => {
  const { t } = useTranslation('common')
  const { money } = useMoney()
  const { date: formatDate } = useDate()
  const sentAmount = money({
    number: deposit?.fromAmount?.amount ?? deposit?.amount?.amount ?? '',
    variant:
      deposit?.fromAmount?.currency ??
      deposit?.amount?.currency ??
      Currency.USD,
    options: {
      symbol: true,
      signDisplay: false,
    },
  })
  const receivedAmount = money({
    number: deposit?.amount?.amount ?? '',
    variant: currencyToToken(deposit?.amount?.currency ?? Currency.USD),
    options: {
      symbol: true,
      signDisplay: false,
    },
  })

  const shouldShowFxRate =
    deposit?.fromAmount != null && deposit?.amount != null
  // This creates a string in the format of "$1 = 0.00058 MXN" to show the user their approx. FX rate.
  const fxRateString = createDepositFxRateString({ deposit, money })

  const statusVariant = (() => {
    switch (deposit?.status) {
      case DepositStatus.complete:
        return 'status/success'
      case DepositStatus.failed:
        return 'status/error'
      default:
        return 'status/neutral'
    }
  })()

  return (
    <Card>
      <Card.Content className="pb-0">
        <GraphQLErrorBoundary error={error}>
          <h2 className="pb-4 pl-4 type-h-title-md">
            {t('fxTransactionCard.details')}
          </h2>
          <DescriptionList density="loose">
            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.type')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <p className="type-body-base-bold">
                {t('fxTransactionCard.mint')}
              </p>
            </DescriptionList.Description>

            <DescriptionList.Label className="h-full w-64">
              {t('fxTransactionCard.status')}
            </DescriptionList.Label>
            <DescriptionList.Description className="flex flex-col">
              <Chip variant={statusVariant}>
                <p className="capitalize type-body-base-bold">
                  {deposit?.status}
                </p>
              </Chip>
              <p className="type-body-xs">
                {formatDate(deposit?.updateDate ?? '', 'datetimeWithYear')}
              </p>
            </DescriptionList.Description>

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.amountSent')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <p className="type-body-base-bold">{sentAmount}</p>
            </DescriptionList.Description>

            <DescriptionList.Label className="w-64">
              {t('fxTransactionCard.received')}
            </DescriptionList.Label>
            <DescriptionList.Description>
              <p className="type-body-base-bold">{receivedAmount}</p>
            </DescriptionList.Description>

            {shouldShowFxRate && (
              <>
                <DescriptionList.Label className="w-64">
                  {t('fxTransactionCard.fxRate')}
                </DescriptionList.Label>
                <DescriptionList.Description>
                  <p className="type-body-base-bold">{fxRateString}</p>
                </DescriptionList.Description>
              </>
            )}

            <DescriptionList.Label className="h-full w-64">
              {t('fxTransactionCard.sentFrom')}
            </DescriptionList.Label>
            <DescriptionList.Description className="flex flex-col">
              <p className="type-body-base-bold">{deposit?.source?.name}</p>
              <p className="uppercase font-circular-light">
                {deposit?.source?.type}
              </p>
            </DescriptionList.Description>
          </DescriptionList>
        </GraphQLErrorBoundary>
      </Card.Content>
    </Card>
  )
}

import { TableFilters } from '@circlefin/components'
import classNames from 'classnames'

import type { TableFiltersProps } from '@circlefin/components/lib/TableFilters'

export interface FiltersProps extends TableFiltersProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Is filters active?
   */
  hidden?: boolean
}

export const Filters: React.FC<FiltersProps> = ({
  hidden = false,
  className,
  children,
  ...props
}) => {
  if (hidden) {
    return null
  }

  return (
    <TableFilters className={classNames('mb-5', className)} {...props}>
      {children}
    </TableFilters>
  )
}

Filters.displayName = 'TableCard.Filters'

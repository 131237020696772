import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BlockchainRecipientAddressOwner,
  BlockchainRecipientAddressWithMetadataDocument,
  BlockchainRecipientAddressesWithMetadataDocument,
  useCreateBlockchainRecipientAddressWithMetadataMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'

import { useAddAddress } from '../AddAddress.Context'

import type { AddAddressMultiAssetCustomerValues } from '../AddressDetails/MultiAsset'
import type {
  BlockchainAbbreviation,
  CreateBlockchainRecipientAddressWithMetadataInput,
} from '@shared/graphql'

export const useAddAddressMutation = () => {
  const modal = useModal()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const [
    {
      defaultCustomer,
      defaultNonCustomer,
      multiAssetCustomer,
      multiAssetNonCustomer,
    },
  ] = useAddAddress()

  const [createBlockchainRecipientAddressMutation, mutationProperties] =
    useCreateBlockchainRecipientAddressWithMetadataMutation({
      refetchQueries: [
        BlockchainRecipientAddressWithMetadataDocument,
        BlockchainRecipientAddressesWithMetadataDocument,
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        mfaSuccess()
      },
      onError: (error) => {
        mfaError({ error })
      },
    })

  const addDefaultCustomerAddress = useCallback(() => {
    if (!defaultCustomer || !defaultCustomer.blockchain) {
      return
    }

    const { address, blockchain, nickname, memo, asset } = defaultCustomer

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createBlockchainRecipientAddressMutation({
          variables: {
            input: {
              address,
              chain: blockchain?.chain,
              description: nickname,
              owner: BlockchainRecipientAddressOwner.customer,
              currency: asset,
              ...(memo && { addressTag: memo }),
            },
          },
          context: context(code),
        })
      },
      onAbort: modal.close,
    })
  }, [
    context,
    createBlockchainRecipientAddressMutation,
    defaultCustomer,
    modal.close,
    withMfa,
  ])

  const addDefaultNonCustomerAddress = useCallback(() => {
    if (!defaultNonCustomer || !defaultNonCustomer.blockchain) {
      return
    }

    const { address, blockchain, recipientName, recipientEmail, memo, asset } =
      defaultNonCustomer

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createBlockchainRecipientAddressMutation({
          variables: {
            input: {
              address,
              chain: blockchain?.chain,
              description: recipientName,
              email: recipientEmail,
              owner: BlockchainRecipientAddressOwner.non_customer_entity,
              currency: asset,
              ...(memo && { addressTag: memo }),
            },
          },
          context: context(code),
        })
      },
      onAbort: modal.close,
    })
  }, [
    context,
    createBlockchainRecipientAddressMutation,
    defaultNonCustomer,
    modal.close,
    withMfa,
  ])

  const getMultiAssetCurrencyAssetInput = useCallback(
    (
      assets: AddAddressMultiAssetCustomerValues['asset'],
      blockchain: BlockchainAbbreviation,
    ) => {
      let currencyInput: CreateBlockchainRecipientAddressWithMetadataInput['currency']
      let assetsInput: CreateBlockchainRecipientAddressWithMetadataInput['assets']

      // when they select ALL ASSETS, set the asset list to be [] and currency to be the same with chain token
      // when they select only 1 asset, for example USD, set the currency to be USD as well as the asset list to be [USD]
      // when they select multiple assets, set the currency to be the chain token, for example blockchain ETH, they select [ETH, USD, EUR], set the currency to be ETH
      if (assets[0]?.value === 'all' || assets.length === 0) {
        currencyInput = blockchain
        assetsInput = []
      } else {
        assetsInput = assets
          .map((a) => a?.value)
          .filter((asset): asset is string => asset != null)

        if (assets.length === 1) {
          currencyInput = assets[0]?.value
        } else {
          currencyInput = blockchain
        }
      }

      return {
        currencyInput,
        assetsInput,
      }
    },
    [],
  )

  const addMultiAssetCustomerAddress = useCallback(() => {
    if (!multiAssetCustomer || !multiAssetCustomer.blockchain) {
      return
    }

    const {
      address,
      blockchain,
      nickname,
      memo,
      asset: assets,
    } = multiAssetCustomer
    const { currencyInput, assetsInput } = getMultiAssetCurrencyAssetInput(
      assets,
      blockchain.chain,
    )

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createBlockchainRecipientAddressMutation({
          variables: {
            input: {
              address,
              chain: blockchain.chain,
              description: nickname,
              owner: BlockchainRecipientAddressOwner.customer,
              currency: currencyInput,
              assets: assetsInput,
              ...(memo && { addressTag: memo }),
            },
          },
          context: context(code),
        })
      },
      onAbort: modal.close,
    })
  }, [
    context,
    createBlockchainRecipientAddressMutation,
    getMultiAssetCurrencyAssetInput,
    modal.close,
    multiAssetCustomer,
    withMfa,
  ])

  const addMultiAssetNonCustomerAddress = useCallback(() => {
    if (!multiAssetNonCustomer || !multiAssetNonCustomer.blockchain) {
      return
    }

    const {
      address,
      blockchain,
      recipientName,
      recipientEmail,
      memo,
      asset: assets,
    } = multiAssetNonCustomer
    const { currencyInput, assetsInput } = getMultiAssetCurrencyAssetInput(
      assets,
      blockchain.chain,
    )

    withMfa({
      variant: 'FullScreen',
      onComplete: (code) => {
        void createBlockchainRecipientAddressMutation({
          variables: {
            input: {
              address,
              chain: blockchain.chain,
              description: recipientName,
              email: recipientEmail,
              owner: BlockchainRecipientAddressOwner.non_customer_entity,
              currency: currencyInput,
              assets: assetsInput,
              ...(memo && { addressTag: memo }),
            },
          },
          context: context(code),
        })
      },
      onAbort: modal.close,
    })
  }, [
    context,
    createBlockchainRecipientAddressMutation,
    getMultiAssetCurrencyAssetInput,
    modal.close,
    multiAssetNonCustomer,
    withMfa,
  ])

  return [
    {
      addDefaultCustomerAddress,
      addDefaultNonCustomerAddress,
      addMultiAssetCustomerAddress,
      addMultiAssetNonCustomerAddress,
    },
    mutationProperties,
  ] as const
}

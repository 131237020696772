import type { ReactNode } from 'react'
import { useLayoutEffect, useState, useRef } from 'react'

import { Button } from '@circlefin/components'
import {
  offset,
  flip,
  shift,
  autoUpdate,
  arrow,
  useFloating,
  FloatingArrow,
  FloatingPortal,
} from '@floating-ui/react'
import useTranslation from 'next-translate/useTranslation'

import type { Placement } from '@floating-ui/react'

const ARROW_HEIGHT = 7
const GAP = 2
const ARROW_OFFSET = ARROW_HEIGHT + GAP

const spotlightClasses = ['rounded-sm', 'border-3', 'border-gradient-selected']

export interface TourGuideStepRoute {
  /**
   * Pathname.
   */
  pathname: string
  /**
   * Query params.
   */
  query?: Record<string, string | string[]>
}

export interface TourGuideStep {
  /**
   * Target selector.
   */
  targetSelector: string
  /***
   * Content to render
   */
  content: ReactNode
  /**
   * Next js route that this step is rendered on.
   */
  route: TourGuideStepRoute
  /**
   * Is authorized to view this step.
   */
  isAuthorized: boolean
  /**
   * Placement.
   */
  placement: Placement
  /**
   * Offset.
   */
  offSet?: number
}

export interface TourGuideContentProps {
  /**
   * Step.
   */
  step: TourGuideStep
  /**
   * Current step index.
   */
  currentStepIndex: number
  /**
   * Is last step.
   */
  isLastStep: boolean
  /**
   * Number of steps.
   */
  numOfSteps: number
  /**
   * On back handler.
   */
  onBack: () => void
  /**
   * On next handler.
   */
  onNext: () => void
  /**
   * On done handler.
   */
  onDone: () => void
}

export const TourGuideContent: React.FC<TourGuideContentProps> = ({
  step: { targetSelector, content, placement, offSet: offSetNum = 0 },
  currentStepIndex,
  isLastStep,
  numOfSteps,
  onBack,
  onNext,
  onDone,
}) => {
  const { t } = useTranslation('common')

  const arrowRef = useRef(null)

  // notice the targetRef is set in a useState and not in a useRef, this is because `useFloating` reads this element during render which is not allowed in react.
  // The docs state to store it in a useState. https://floating-ui.com/docs/react#positioning
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  const isOpen = targetRef != null

  const { refs, floatingStyles, context, elements, isPositioned } = useFloating(
    {
      open: isOpen,
      placement,
      elements: {
        reference: targetRef,
      },
      middleware: [
        offset(ARROW_OFFSET + offSetNum),
        flip({ fallbackAxisSideDirection: 'end' }),
        shift(),
        arrow({
          element: arrowRef,
        }),
      ],
      whileElementsMounted: autoUpdate,
    },
  )

  /**
   * On mount, find the target element and update the target ref.
   * Add the spotlight classes, and remove them during cleanup.
   */
  useLayoutEffect(() => {
    const el = document.querySelector<HTMLElement>(targetSelector)
    el?.classList.add(...spotlightClasses)

    setTargetRef(el)

    return () => {
      el?.classList.remove(...spotlightClasses)
    }
  }, [targetSelector])

  if (!isOpen) {
    return null
  }

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        aria-live="assertive"
        className="z-50"
        data-testid="tour-guide-content"
        style={floatingStyles}
      >
        {elements.floating != null && isPositioned && (
          <FloatingArrow
            ref={arrowRef}
            className="fill-white drop-shadow-sm"
            context={context}
            fill="none"
            height={12}
            strokeWidth={4}
            tipRadius={4}
            width={20}
          />
        )}
        <div className="min-w-128 max-w-168 overflow-hidden rounded-md border-0 bg-surface-primary px-6 pb-4 shadow-md outline-none">
          <div className="mt-4 flex justify-between">
            <div>
              <div>{content}</div>

              <div className="mt-1.5 flex flex-col items-center gap-y-1.5">
                <p className="text-neutral-subtlest font-circular-medium">
                  {t('stepCounter', {
                    step: currentStepIndex + 1,
                    total: numOfSteps,
                  })}
                </p>
                <div className="flex gap-x-20">
                  {currentStepIndex > 0 && (
                    <Button onClick={onBack} size="sm" variant="text">
                      {t('back')}
                    </Button>
                  )}
                  <Button
                    onClick={isLastStep ? onDone : onNext}
                    size="sm"
                    variant="text"
                  >
                    {isLastStep ? t('done') : t('next')}
                  </Button>
                </div>
              </div>
            </div>
            {!isLastStep && (
              <Button onClick={onDone} size="sm" variant="text">
                {t('skip')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </FloatingPortal>
  )
}

import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import { TypeGuards } from '@services/type-guards'
import {
  UsersFromVaultPolicyThatHaveNotFinishedSetupDocument,
  VaultDocument,
  VaultPolicyDocument,
  VaultPolicyState,
  VaultUserPolicyRoleEnum,
  VaultsDashboardDocument,
  useCreateVaultMutation,
  useUpdateActivePolicyMutation,
  useUpdateDraftPolicyMutation,
} from '@shared/graphql'
import { groupBy } from 'lodash'

import { useCreateVault } from '../../../hooks/create'

import type { ApolloError } from '@apollo/client'
import type {
  DirectInternalAddress,
  UpsertVaultApprovalWorkflowInput,
  UpsertVaultOperatorInput,
  UpsertVaultOutgoingAddressInput,
  UpsertVaultPolicyInput,
  UpsertVaultUserInput,
  User,
  UpsertVaultOutgoingAddressMainWalletAddressInput,
} from '@shared/graphql'

interface UseCreateVaultSaveProps {
  /**
   * OnCompleted handler.
   */
  onCompleted?: () => void
  /**
   * OnError handler.
   */
  onError?: (error: ApolloError) => void
}

export const useCreateVaultSave = ({
  onCompleted,
  onError,
}: UseCreateVaultSaveProps = {}) => {
  const { close } = useModal()
  const [values, { setFlowComplete, setVaultId }] = useCreateVault()

  const handleOnCompleted = useCallback(() => {
    setFlowComplete(true)
    onCompleted?.()
  }, [onCompleted, setFlowComplete])

  const [createVault, createVaultMutation] = useCreateVaultMutation({
    onCompleted: ({ createVault }) => {
      setVaultId(createVault.vaultId)
      handleOnCompleted()
    },
    onError,
    refetchQueries: [
      VaultsDashboardDocument,
      UsersFromVaultPolicyThatHaveNotFinishedSetupDocument,
    ],
    awaitRefetchQueries: true,
  })

  const [updateActivePolicy, updateActiveMutation] =
    useUpdateActivePolicyMutation({
      onCompleted: handleOnCompleted,
      onError,
      refetchQueries: [
        VaultsDashboardDocument,
        {
          query: VaultDocument,
          variables: {
            id: values.vaultId,
          },
        },
        {
          query: VaultPolicyDocument,
          variables: {
            policyId: values.id,
            vaultId: values.vaultId,
          },
        },
        UsersFromVaultPolicyThatHaveNotFinishedSetupDocument,
      ],
      awaitRefetchQueries: true,
    })

  const [updateDraftPolicy, updateDraftMutation] = useUpdateDraftPolicyMutation(
    {
      onCompleted: handleOnCompleted,
      onError,
      refetchQueries: [
        VaultsDashboardDocument,
        {
          query: VaultDocument,
          variables: {
            id: values.vaultId,
          },
        },
        {
          query: VaultPolicyDocument,
          variables: {
            policyId: values.id,
            vaultId: values.vaultId,
          },
        },
        UsersFromVaultPolicyThatHaveNotFinishedSetupDocument,
      ],
      awaitRefetchQueries: true,
    },
  )

  const getUserInput = useCallback(
    ({ id }: User): UpsertVaultUserInput => ({
      id,
    }),
    [],
  )

  const auditors: UpsertVaultUserInput[] = useMemo(
    () =>
      (values.usersPolicy ?? [])
        .filter((userPolicy) =>
          userPolicy.permissions.includes(VaultUserPolicyRoleEnum.AUDITOR),
        )
        .map((userPolicy) => getUserInput(userPolicy.user)),
    [values.usersPolicy, getUserInput],
  )

  const operators: UpsertVaultOperatorInput[] = useMemo(
    () =>
      (values.usersPolicy ?? [])
        .filter((userPolicy) =>
          userPolicy.permissions.includes(VaultUserPolicyRoleEnum.OPERATOR),
        )
        .map((userPolicy) => ({
          user: getUserInput(userPolicy.user),
          maxAmount: userPolicy.maxAmount
            ? parseFloat(userPolicy.maxAmount)
            : undefined,
          maxTransactions: userPolicy.maxTransactions
            ? parseFloat(userPolicy.maxTransactions)
            : undefined,
        })),
    [values.usersPolicy, getUserInput],
  )

  const approvalWorkflowInput: UpsertVaultApprovalWorkflowInput[] = useMemo(
    () =>
      (values.approvalWorkflow ?? []).map((level, index) => ({
        orderInFlow: index + 1,
        transferAmount: parseFloat(level.transferAmount),
        minNeedConfirmed: parseFloat(level.minNeedConfirmed),
        approvers: level.approvers.map((approver) => getUserInput(approver)),
      })),
    [values.approvalWorkflow, getUserInput],
  )

  const vaultOutgoingAddressesInput: UpsertVaultOutgoingAddressInput =
    useMemo(() => {
      const mainWalletAddresses = (
        values.outgoingAddresses?.internal ?? []
      ).reduce<UpsertVaultOutgoingAddressMainWalletAddressInput[]>(
        (accumulator, internalAddress) => {
          if (TypeGuards.InternalAddress.isCircle(internalAddress)) {
            accumulator.push({
              currency: internalAddress.currency,
              blockchain: internalAddress.blockchain,
            })
          }

          return accumulator
        },
        [],
      )

      const directAddresses =
        values.outgoingAddresses?.internal.filter(
          (address): address is DirectInternalAddress =>
            TypeGuards.InternalAddress.isDirect(address),
        ) ?? []

      const groupedVaultWalletAddresses = groupBy(directAddresses, 'vaultId')

      return {
        externalAddressRefIds:
          values.outgoingAddresses?.external.map((address) => address.id) ?? [],
        mainWalletAddresses,
        vaultWallets: Object.entries(groupedVaultWalletAddresses).map(
          ([vaultId, vaultWalletAddress]) => ({
            vaultId,
            walletIds: vaultWalletAddress.map(({ id }) => id),
          }),
        ),
      }
    }, [values.outgoingAddresses?.external, values.outgoingAddresses?.internal])

  const save = useCallback(() => {
    const {
      id,
      policyInEditState,
      vaultId,
      name,
      policyLimits,
      walletConnectPolicy,
      isWalletConnectStepVisible,
      isWalletConnectPolicyEnabled,
    } = values

    if (!name) {
      close()
      return
    }

    const input: UpsertVaultPolicyInput = {
      name,
      maxAmount: policyLimits?.maxAmount
        ? parseFloat(policyLimits.maxAmount)
        : undefined,
      maxTransactions: policyLimits?.maxTransactions
        ? parseFloat(policyLimits.maxTransactions)
        : undefined,
      auditors,
      operators,
      approvalWorkflow: approvalWorkflowInput,
      vaultOutgoingAddresses: vaultOutgoingAddressesInput,
    }

    // Only send walletConnect field when it's visible in the flow
    if (isWalletConnectStepVisible) {
      if (isWalletConnectPolicyEnabled && walletConnectPolicy) {
        input.walletConnect = {
          operators: walletConnectPolicy.operators.map(getUserInput),
          approvers: walletConnectPolicy.approvers.map(getUserInput),
          minNeedConfirmed: Number(walletConnectPolicy?.minNeedConfirmed),
        }
      } else {
        input.walletConnect = {
          operators: null,
          approvers: null,
          minNeedConfirmed: null,
        }
      }
    }

    if (!vaultId) {
      void createVault({
        variables: {
          input,
        },
      })
      return
    }

    if (id && policyInEditState != null) {
      if (policyInEditState === VaultPolicyState.ACTIVE) {
        void updateActivePolicy({
          variables: {
            vaultId,
            input,
          },
        })
        return
      }

      void updateDraftPolicy({
        variables: {
          vaultId,
          policyId: id,
          input,
        },
      })
    }
  }, [
    values,
    auditors,
    operators,
    approvalWorkflowInput,
    vaultOutgoingAddressesInput,
    close,
    getUserInput,
    createVault,
    updateDraftPolicy,
    updateActivePolicy,
  ])

  const reset = useCallback(() => {
    if (createVaultMutation.error) {
      createVaultMutation.reset()
      return
    }

    if (updateActiveMutation.error) {
      updateActiveMutation.reset()
      return
    }

    if (updateDraftMutation.error) {
      updateDraftMutation.reset()
    }
  }, [createVaultMutation, updateActiveMutation, updateDraftMutation])

  return [
    save,
    {
      loading:
        createVaultMutation.loading ||
        updateActiveMutation.loading ||
        updateDraftMutation.loading,
      error:
        createVaultMutation.error ??
        updateActiveMutation.error ??
        updateDraftMutation.error,
      reset,
    },
  ] as const
}

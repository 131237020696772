import { useContext } from 'react'

import { Sidenav } from '@circlefin/components'
import classnames from 'classnames'

import { MainNavContext } from '../MainNav.Context/MainNav.Context'

import { Group } from './Group/Group'

import type { GroupProps } from './Group/Group'

export interface MainNavGroupsProps {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Navigation Groups.
   */
  groups: GroupProps[]
}

export const MainNavGroups: React.FC<MainNavGroupsProps> = ({
  className,
  groups,
  ...props
}) => {
  const { variant, collapsed } = useContext(MainNavContext)

  const classes = classnames(
    'grid w-min grid-flow-row-dense auto-rows-min gap-y-3.5',
    className,
  )

  return (
    <div className={classes} {...props}>
      <Sidenav collapsed={collapsed} variant={variant}>
        {groups.map((group, g) => (
          <Group key={g} {...group} />
        ))}
      </Sidenav>
    </div>
  )
}

import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import type { FormInputProps } from '@circlefin/form/Form.Input'
import type { Blockchain } from '@shared/graphql'

export const AddressSchema = y
  .string()
  .when(['blockchain'], ([blockchain]: Blockchain[], schema) =>
    schema.blockchains({
      abbreviation: blockchain?.chain,
    }),
  )

const schema = y.object({
  /**
   * Blockchain Memo.
   */
  address: AddressSchema,
})

export type AddressFormValues = y.InferType<typeof schema>
export type AddressProps = Omit<FormInputProps<AddressFormValues>, 'name'>

export const Address: React.FC<AddressProps> = ({ label, ...props }) => {
  const { t } = useTranslation('forms')
  const [Form] = useForm<AddressFormValues>({ schema })

  return (
    <Form.Input
      {...props}
      data-testid="input-address"
      label={label ?? t`input.blockchain.address.label`}
      name="address"
    />
  )
}

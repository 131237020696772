import { TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

type DepositQueryProps = {
  /**
   * Id of the parent wallet.
   */
  parentWalletId?: string
  /**
   * Id of the wallet.
   */
  walletId?: string
}

export interface AvailableBalanceTextProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Native balance.
   */
  nativeBalance?: string
  /**
   * Fiat balance.
   */
  fiatBalance?: string
  /**
   * Query props to pass to the deposit flow.
   */
  depositQueryProps?: DepositQueryProps
  /**
   * Type.
   */
  type?: 'transfer' | 'walletActivation'
}

export const AvailableBalanceText: React.FC<AvailableBalanceTextProps> = ({
  className,
  nativeBalance,
  fiatBalance,
  depositQueryProps,
  type = 'transfer',
}) => {
  const { t } = useTranslation('common')

  if (!nativeBalance) {
    return null
  }

  return (
    <h3
      className={classNames(
        'mb-4 text-center text-lg leading-6 text-black-400',
        className,
      )}
    >
      <Trans
        components={{
          b: <b />,
        }}
        i18nKey={`common:availableBalanceText.${type}`}
        values={{
          assetBalance: nativeBalance,
          fiatBalance: fiatBalance ? `(${fiatBalance})` : '',
        }}
      />
      <ModalLink
        pathname={routes.vault.deposit.chooseDepositType}
        query={depositQueryProps}
      >
        <TextLink
          className="ml-1"
          data-testid="add-funds-button"
          variant="primary"
        >
          {t`addFunds`}
        </TextLink>
      </ModalLink>
    </h3>
  )
}

export class SynthRole {
  /**
   * Role to enable L3 Onboarding toggle.
   */
  public static readonly SYNTH_L3_ONBOARDING_ROLE = 'SYNTH_L3_ONBOARDING_ROLE'
  /**
   * Role to enable ARB blockchain USDC.
   */
  public static readonly SYNTH_ARB_BLOCKCHAIN_ROLE = 'SYNTH_ARB_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_ARB_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_ARB_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable ALGO blockchain USDC.
   */
  public static readonly SYNTH_ALGO_BLOCKCHAIN_ROLE =
    'SYNTH_ALGO_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_ALGO_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_ALGO_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable AVAX blockchain USDC.
   */
  public static readonly SYNTH_AVAX_BLOCKCHAIN_ROLE =
    'SYNTH_AVAX_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_AVAX_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_AVAX_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable BASE blockchain USDC.
   */
  public static readonly SYNTH_BASE_BLOCKCHAIN_ROLE =
    'SYNTH_BASE_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_BASE_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_BASE_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable BTC blockchain USDC.
   */
  public static readonly SYNTH_BTC_BLOCKCHAIN_ROLE = 'SYNTH_BTC_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_BTC_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_BTC_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable CELO blockchain USDC.
   */
  public static readonly SYNTH_CELO_BLOCKCHAIN_ROLE =
    'SYNTH_CELO_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_CELO_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_CELO_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable ETH blockchain USDC.
   */
  public static readonly SYNTH_ETH_BLOCKCHAIN_ROLE = 'SYNTH_ETH_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_ETH_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_ETH_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable EVMOS blockchain USDC.
   */
  public static readonly SYNTH_EVMOS_BLOCKCHAIN_ROLE =
    'SYNTH_EVMOS_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_EVMOS_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_EVMOS_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable FLOW blockchain USDC.
   */
  public static readonly SYNTH_FLOW_BLOCKCHAIN_ROLE =
    'SYNTH_FLOW_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_FLOW_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_FLOW_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable HBAR blockchain USDC.
   */
  public static readonly SYNTH_HBAR_BLOCKCHAIN_ROLE =
    'SYNTH_HBAR_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_HBAR_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_HBAR_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable MANA blockchain USDC.
   */
  public static readonly SYNTH_MANA_BLOCKCHAIN_ROLE =
    'SYNTH_MANA_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_MANA_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_MANA_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable MATIC blockchain USDC.
   */
  public static readonly SYNTH_MATIC_BLOCKCHAIN_ROLE =
    'SYNTH_MATIC_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_MATIC_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_MATIC_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable NEAR blockchain USDC.
   */
  public static readonly SYNTH_NEAR_BLOCKCHAIN_ROLE =
    'SYNTH_NEAR_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_NEAR_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_NEAR_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable NOBLE blockchain USDC.
   */
  public static readonly SYNTH_NOBLE_BLOCKCHAIN_ROLE =
    'SYNTH_NOBLE_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_NOBLE_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_NOBLE_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable OP blockchain USDC.
   */
  public static readonly SYNTH_OP_BLOCKCHAIN_ROLE = 'SYNTH_OP_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_OP_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_OP_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable PAH blockchain USDC.
   */
  public static readonly SYNTH_PAH_BLOCKCHAIN_ROLE = 'SYNTH_PAH_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_PAH_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_PAH_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable POLY blockchain USDC.
   */
  public static readonly SYNTH_POLY_BLOCKCHAIN_ROLE =
    'SYNTH_POLY_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_POLY_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_POLY_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable SOL blockchain USDC.
   */
  public static readonly SYNTH_SOL_BLOCKCHAIN_ROLE = 'SYNTH_SOL_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_SOL_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_SOL_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable TRX blockchain USDC.
   */
  public static readonly SYNTH_TRX_BLOCKCHAIN_ROLE = 'SYNTH_TRX_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_TRX_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_TRX_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable XLM blockchain USDC.
   */
  public static readonly SYNTH_XLM_BLOCKCHAIN_ROLE = 'SYNTH_XLM_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_XLM_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_XLM_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable ZKS blockchain USDC.
   */
  public static readonly SYNTH_ZKS_BLOCKCHAIN_ROLE = 'SYNTH_ZKS_BLOCKCHAIN_ROLE'
  public static readonly SYNTH_ZKS_WITHDRAWAL_BLOCKCHAIN_ROLE =
    'SYNTH_ZKS_WITHDRAWAL_BLOCKCHAIN_ROLE'
  /**
   * Role to enable AVAX blockchain EURC.
   */
  public static readonly SYNTH_AVAX_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_AVAX_BLOCKCHAIN_EURC_ROLE'
  public static readonly SYNTH_AVAX_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_AVAX_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE'
  /**
   * Role to enable ETH blockchain EURC.
   */
  public static readonly SYNTH_ETH_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_ETH_BLOCKCHAIN_EURC_ROLE'
  public static readonly SYNTH_ETH_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_ETH_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE'
  /**
   * Role to enable XLM blockchain EURC.
   */
  public static readonly SYNTH_XLM_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_XLM_BLOCKCHAIN_EURC_ROLE'
  public static readonly SYNTH_XLM_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_XLM_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE'
  /**
   * Role to enable SOL blockchain EURC.
   */
  public static readonly SYNTH_SOL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_SOL_BLOCKCHAIN_EURC_ROLE'
  public static readonly SYNTH_SOL_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_SOL_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE'
  /**
   * Role to enable BASE blockchain EURC.
   */
  public static readonly SYNTH_BASE_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_BASE_BLOCKCHAIN_EURC_ROLE'
  public static readonly SYNTH_BASE_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE =
    'SYNTH_BASE_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE'
  /**
   * Role to enable custody policy.
   */
  public static readonly SYNTH_CUSTODY_POLICY_ROLE = 'SYNTH_CUSTODY_POLICY_ROLE'
  /**
   * Role to enable email approval method for wallet approval policy.
   */
  public static readonly SYNTH_EMAIL_APPROVAL_ROLE = 'SYNTH_EMAIL_APPROVAL_ROLE'
  /**
   * Role to determine if Periodic Reviews are enabled.
   */
  public static readonly SYNTH_PERIODIC_REVIEW_ROLE =
    'SYNTH_PERIODIC_REVIEW_ROLE'
  /**
   * Role to enable FX deposit and withdraw flow.
   */
  public static readonly SYNTH_FX_TRANSFERS_ROLE = 'SYNTH_FX_TRANSFERS_ROLE'
  /**
   * Role to enable Hong Kong specific features.
   */
  public static readonly SYNTH_HK_LAUNCH_ROLE = 'SYNTH_HK_LAUNCH_ROLE'
  /**
   * Role to enable FFC Memo in a bank account linking flow.
   */
  public static readonly SYNTH_FFC_MEMO_ROLE = 'SYNTH_FFC_MEMO_ROLE'
  /**
   * Role to enable in app notifications.
   */
  public static readonly SYNTH_IN_APP_NOTIFICATION_SECTION_ROLE =
    'SYNTH_IN_APP_NOTIFICATION_SECTION_ROLE'
  /**
   * Role to enable self-serve restricted API Key.
   */
  public static readonly SYNTH_RESTRICTED_API_KEY_ROLE =
    'SYNTH_RESTRICTED_API_KEY_ROLE'
  /**
   * Role to enable PIX account creation and use.
   */
  public static readonly SYNTH_PIX_ENABLED_ROLE = 'SYNTH_PIX_ENABLED_ROLE'
  /**
   * Role to enable onboarding flow.
   */
  public static readonly SYNTH_ONBOARDING = 'SYNTH_ONBOARDING'
  /**
   * Role to enable periodic review flow.
   */
  public static readonly SYNTH_PERIODIC_REVIEW = 'SYNTH_PERIODIC_REVIEW'
  /**
   * Role to enable modal takeover.
   */
  public static readonly SYNTH_TAKEOVER_ROLE = 'SYNTH_TAKEOVER_ROLE'
  /**
   * Role to enable modal takeover.
   */
  public static readonly SYNTH_BURN_FEE_V2_ENABLE_ROLE =
    'SYNTH_BURN_FEE_V2_ENABLE_ROLE'
}

export type SynthPermissionRole = keyof typeof SynthRole

import { createContext } from 'react'

import { v4 as uuidv4 } from 'uuid'

/**
 * Context State.
 */
interface ContextState {
  /**
   * Generated idempotency key.
   */
  idempotencyKey: string
  /**
   * Resets the idempotency key.
   */
  resetIdempotencyKey: () => void
}

/**
 * Create Context.
 */
export const IdempotencyKeyContext = createContext<ContextState>({
  idempotencyKey: uuidv4(),
  resetIdempotencyKey: () => null,
})

import { TruncatedText, DescriptionList } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { CUSTODY_POLICY_SECTION } from '@services/permissions'
import { TypeGuards } from '@services/type-guards'
import { useWalletApprovalTransactionDetailByJobIdQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransactionDetail } from '../TransactionDetail/TransactionDetail'
import { WalletApprovalTransactionDetailApprovalSummary } from '../WalletApprovalTransactionDetailApprovalSummary/WalletApprovalTransactionDetailApprovalSummary'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type { CbitPaymentDetail as CbitPaymentDetailType } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface CbitPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  cbitPaymentDetail?: CbitPaymentDetailType
}

export const CbitPaymentDetail: React.FC<CbitPaymentDetailProps> = ({
  cbitPaymentDetail,
  error,
}) => {
  const { t } = useTranslation('payments')

  const isReceive = TypeGuards.Payments.isCbitReceiveType(cbitPaymentDetail)
  const [, { isAuthorized }] = usePermission(CUSTODY_POLICY_SECTION)

  const { data: { walletApprovalTransactionDetailByJobId } = {} } =
    useWalletApprovalTransactionDetailByJobIdQuery({
      variables: {
        jobId: cbitPaymentDetail?.payment.id ?? '',
      },
      skip: !cbitPaymentDetail?.payment.id || isReceive || !isAuthorized,
    })

  const bankDescriptionLabel = isReceive
    ? t('transactionDetail.fundingSource')
    : t('transactionDetail.destination')

  const bankAccount = isReceive
    ? cbitPaymentDetail?.source
    : cbitPaymentDetail?.destination

  return (
    <TransactionDetail error={error} transaction={cbitPaymentDetail?.payment}>
      {walletApprovalTransactionDetailByJobId && (
        <WalletApprovalTransactionDetailApprovalSummary
          {...walletApprovalTransactionDetailByJobId}
        />
      )}

      <Label>{bankDescriptionLabel}</Label>
      <Description>
        {bankAccount?.nickname}
        <TruncatedText>{bankAccount?.walletAddress ?? ''}</TruncatedText>
      </Description>

      {isReceive && (
        <>
          <Label>{t('transactionDetail.referenceID')}</Label>
          <Description>{bankAccount?.trackingRef}</Description>
        </>
      )}
    </TransactionDetail>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useGraphQLError } from '@shared/hooks/common'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'

export interface ErrorQueryProps {
  /**
   * GraphQL errors.
   */
  error?: ApolloError
}

export const Error: React.FC<ErrorQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/users')
  const { getError } = useGraphQLError()
  const modal = useModal()

  const handleConfirm = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <BaseTitle title={t('password.error.title')} variant="center">
      <PropsErrorBoundary<ErrorQueryProps> props={props}>
        {({ error }) => (
          <>
            <p className="text-sm leading-6 text-black-600">
              {getError(error)}
            </p>
            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={handleConfirm} variant="primary">
                {t`common:gotIt`}
              </Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { y, useFormContext } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { TypeGuards } from '@services/type-guards'
import { useInternalWalletsQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type {
  CircleInternalWallet,
  DirectInternalWallet,
  InternalWallet,
  InternalWalletFilterInput,
  Currency,
  BlockchainRecipientAddressWithMetadata,
} from '@shared/graphql'

type InternalWalletsItem = SelectListItem<InternalWallet>

export const InternalWalletsSourceSchema = y.mixed<InternalWallet>()
export const InternalWalletsDestinationSchema =
  y.mixed<InternalWallet | BlockchainRecipientAddressWithMetadata>()

export interface InternalWalletsProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Internal wallet id used to pre-select dropdown.
   */
  internalWalletId?: string
  /**
   * Circle-custodied wallet.
   */
  circleCustodyWalletCurrency?: Currency
  /**
   * Combobox label.
   */
  label: string
  /**
   * Combobox placeholder.
   */
  placeholder: string
  /**
   * Combobox message.
   */
  message?: string
  /**
   * Combobox disabled.
   */
  disabled?: boolean
  /**
   * Name of the form field.
   */
  name: string
  /**
   * Filters (that match query filters).
   */
  filter?: InternalWalletFilterInput
  /**
   * Variation of the field type.
   */
  variation?: 'source' | 'destination'
}

const Combobox = createFormCombobox()

/**
 * Wrapper around a Combobox component for reusable InternalWallets field.
 */
export const InternalWallets: React.FC<InternalWalletsProps> = ({
  className,
  label,
  placeholder,
  message,
  disabled,
  name,
  internalWalletId,
  circleCustodyWalletCurrency,
  filter,
  variation = 'source',
}) => {
  const { t } = useTranslation('forms')

  const internalWallets = useInternalWalletsQuery({
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  })

  const prevFilterAsset = useRef(filter?.asset ?? '')

  const schema = useMemo(
    () =>
      y.object({
        /**
         * Internal wallets.
         */
        [name]:
          variation === 'source'
            ? InternalWalletsSourceSchema
            : InternalWalletsDestinationSchema,
      }),
    [name, variation],
  )

  const { setValue, resetField } = useFormContext<y.InferType<typeof schema>>()

  // Determine value whenever asset or data changes.
  useEffect(() => {
    if (internalWalletId || circleCustodyWalletCurrency != null) {
      // If internalWalletId or circleCustodyWalletCurrency provided, pre-select dropdown item
      const value = internalWallets.data?.internalWallets.find((wallet) =>
        TypeGuards.InternalWallet.isCircle(wallet)
          ? wallet.currency === circleCustodyWalletCurrency
          : wallet.parentWalletId === internalWalletId,
      )

      if (value) {
        void setValue(name, value, {
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    } else if (filter?.asset && filter.asset !== prevFilterAsset.current) {
      // Clear form value if asset changes (but not when mounting)
      void resetField(name)

      return () => {
        // Set prevFilterAsset for comparison on mount or re-render
        prevFilterAsset.current = filter.asset ?? ''
      }
    }
  }, [
    circleCustodyWalletCurrency,
    filter?.asset,
    internalWalletId,
    internalWallets.data?.internalWallets,
    name,
    resetField,
    setValue,
  ])

  const [searchTerm, setSearchTerm] = useState('')

  // Internal Wallets list
  const internalWalletsList: InternalWalletsItem[] = useMemo(() => {
    const options: InternalWalletsItem[] = []

    const { circle, direct } = (
      internalWallets.data?.internalWallets ?? []
    ).reduce<{
      circle: CircleInternalWallet[]
      direct: DirectInternalWallet[]
    }>(
      (acc, curr) => {
        if (TypeGuards.InternalWallet.isCircle(curr)) {
          acc.circle.push(curr)
        } else {
          acc.direct.push(curr)
        }

        return acc
      },
      { circle: [], direct: [] },
    )

    if (circle.length > 0) {
      if (filter?.excludeType == null) {
        options.push({
          label: t('combobox.vault.internalWallets.circleCustodiedWallets'),
          header: true,
        })
      }
      options.push(
        ...circle.map((circleWallet) => ({
          value: circleWallet,
          label: circleWallet.name,
          icon: circleWallet.icon,
        })),
      )
    }

    if (direct.length > 0) {
      if (filter?.excludeType == null) {
        options.push({
          label: t('combobox.vault.internalWallets.noncustodialVaults'),
          header: true,
        })
      }
      options.push(
        ...direct.map((directWallet) => ({
          value: directWallet,
          label: directWallet.name,
          tag: directWallet.vaultMetadata?.name,
          icon: directWallet.assetMetadata.icon,
        })),
      )
    }

    return options
  }, [filter?.excludeType, internalWallets.data, t])

  const filtered = useMemo(() => {
    if (searchTerm) {
      return matchSorter(internalWalletsList, searchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [internalWalletsList, searchTerm])

  // Update search term
  const onInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  // Clear search term and trigger combobox value changes.
  const onChange = useCallback(() => onInputChange(''), [onInputChange])
  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-neutral-subtlest">
          <Trans
            components={{
              span: <span className="text-neutral-subtle font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.vault.internalWallets.noItemsMessage`,
    }),
    [t],
  )

  return (
    <Combobox
      className={className}
      data-testid="vault-internal-wallets-combobox"
      debounce={300}
      disabled={disabled}
      filtered={filtered}
      inputLoading={internalWallets.loading}
      items={internalWalletsList}
      label={label}
      locale={locale}
      maxMenuItems={6}
      message={message}
      name={name}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={placeholder}
      searchTerm={searchTerm}
    />
  )
}

import { ModalRoute } from '@circlefin/modal-router'
import {
  CancelApprovalRequest,
  Password,
  Remove,
  RemoveWithAdminApproval,
  ResendInvite,
  Vault,
} from '@features/users/modals'
import { users } from '@services/sections/modal/account/users/users'

/**
 * Account: Users Router.
 */
export const UsersRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<Password.Error />} path={users.password.error} />
      <ModalRoute
        content={<Password.Success />}
        path={users.password.success}
      />
      <ModalRoute content={<Remove />} path={users.remove} />
      <ModalRoute
        content={<RemoveWithAdminApproval />}
        path={users.removeWithAdminApproval}
      />
      <ModalRoute content={<ResendInvite />} path={users.resendInvite} />
      <ModalRoute
        content={<CancelApprovalRequest />}
        path={users.cancelApprovalRequest}
      />
      <ModalRoute
        content={<Vault.RemoveWarning.Default />}
        path={users.vault.removeWarning.default}
      />
      <ModalRoute
        content={<Vault.RemoveWarning.Owner />}
        path={users.vault.removeWarning.owner}
      />
    </>
  )
}

import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { UserRoleGroupType, useUserRolesQuery } from '@shared/graphql'

import type { FormDropdownProps } from '@circlefin/form/Form.Dropdown'

export const UserRolesSchema = y.object({
  /**
   * Role Id.
   */
  roleId: y.string().required(),
  /**
   * Role Group Type.
   */
  groupType: y.string().oneOf(Object.values(UserRoleGroupType)).required(),
})

const schema = y.object({
  /**
   * User Roles.
   */
  userRole: UserRolesSchema,
})

export type UserRolesFormValues = y.InferType<typeof schema>

export type UserRolesProps = Omit<
  FormDropdownProps<UserRolesFormValues>,
  'items' | 'name'
>

const Dropdown = createFormDropdown()

export const UserRoles: React.FC<UserRolesProps> = ({
  className,
  ...props
}) => {
  // Error should also be handled in the parent form component for better UI
  const { data, loading, error } = useUserRolesQuery()

  const items = useMemo(
    () =>
      (data?.userRoles ?? []).map(({ name: label, id, groupType }) => ({
        label,
        value: {
          roleId: id,
          groupType,
        },
      })),
    [data?.userRoles],
  )

  return (
    <GraphQLErrorBoundary error={error}>
      <SkeletonBox className="mb-3 mt-6 block h-10" loading={loading}>
        <Dropdown {...props} items={items} name="userRole" />
      </SkeletonBox>
    </GraphQLErrorBoundary>
  )
}

import { useCallback } from 'react'

import { ButtonCard, Icon } from '@circlefin/components'
import { FullScreen } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { Center } from '@shared/components/layout'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import CrossRiverLogo from '../../../images/CrossRiverBankLogo.png'
import CustomersBankLogo from '../../../images/CustomersBankLogo.png'
import { InformationVariants } from '../Information/Information.types'
import { useLinkBankAccount } from '../Link.Context'

export type UsTypes =
  | InformationVariants.CBIT
  | InformationVariants.CUBI_WIRE
  | InformationVariants.WIRE
  | InformationVariants.XPAY
  | InformationVariants.CRB_WIRE
  | InformationVariants.RTP

export const Type: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const { track } = useSegment()
  const [{ steps }, { setType }] = useLinkBankAccount()

  const onSelect = useCallback(
    (type: UsTypes) => (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.BankTypeClicked, {
        event: e,
        props: {
          bank_type: type,
        },
      })

      // Set Selected Type
      setType(type, true)
    },
    [setType, track],
  )

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-8 text-center" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`link.type.title`}
        </h2>

        <div
          className="mt-10 grid h-48 grid-flow-col gap-11"
          data-testid="buttons"
        >
          <ButtonCard
            className="w-48"
            onClick={onSelect(InformationVariants.CUBI_WIRE)}
            hideArrow
          >
            <Image alt={t`link.type.cbit`} src={CustomersBankLogo} />
          </ButtonCard>

          <ButtonCard
            className="w-48"
            onClick={onSelect(InformationVariants.CRB_WIRE)}
            hideArrow
          >
            <Image alt={t`link.type.xpay`} src={CrossRiverLogo} />
          </ButtonCard>

          <ButtonCard
            className="w-48"
            data-testid="other-bank"
            onClick={onSelect(InformationVariants.WIRE)}
            hideArrow
          >
            <Center className="w-full">
              <Icon className="mx-auto mb-2.5" name="LibrarySolid" size={22} />
              <span>{t`link.type.other`}</span>
            </Center>
          </ButtonCard>
        </div>
      </Center>
    </FullScreen>
  )
}

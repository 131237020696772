import { FixedBanner, TextLink } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

export interface WalletBackupRequiredNotificationProps {
  /**
   * Optional className that will be applied to the alert.
   */
  className?: string
  /**
   * Specify whether the back-up wallets link should be displayed since not every user is able to actually download wallet backups.
   */
  showAction?: boolean
  /**
   * Specify whether this alert should be shown.
   */
  open?: boolean
}

export const WalletBackupRequiredNotification: React.FC<WalletBackupRequiredNotificationProps> =
  ({ className, showAction = false, open = false }) => {
    const { t } = useTranslation('vaults')

    return (
      <FixedBanner
        className={className}
        status="warning"
        visible={open}
        multiline
      >
        <FixedBanner.Title>
          {t`vaultsDashboardHeader.backup.title`}
        </FixedBanner.Title>
        <FixedBanner.Description>
          {t`vaultsDashboardHeader.backup.description`}
        </FixedBanner.Description>
        {showAction && (
          <FixedBanner.Footer>
            <ModalLink pathname={routes.vault.walletBackup.confirmation}>
              <TextLink variant="primary">{t`vaultsDashboardHeader.backup.cta`}</TextLink>
            </ModalLink>
          </FixedBanner.Footer>
        )}
      </FixedBanner>
    )
  }

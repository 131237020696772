import { FixedBanner } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal'
import { PAYMENT_ACTIVITY_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { usePendingOnchainReceivesQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface CircleWalletPendingReceiveBannerProps {
  /**
   * Currency filter.
   */
  currency?: Currency
}

export const CircleWalletPendingReceiveBanner: React.FC<CircleWalletPendingReceiveBannerProps> =
  ({ currency }) => {
    const { data, loading } = usePendingOnchainReceivesQuery({
      pollInterval: PAYMENT_ACTIVITY_POLL_INTERVAL,
      fetchPolicy: 'cache-and-network',
      variables: {
        currency,
        dedupe: true,
      },
    })
    const { t } = useTranslation('custody/common')

    if (
      loading ||
      data?.pendingOnchainReceives === undefined ||
      data?.pendingOnchainReceives.length === 0
    )
      return null

    return (
      <FixedBanner status="warning" multiline visible>
        <FixedBanner.Title>
          {t('circleWallets.pendingReceiveBanner.title')}
        </FixedBanner.Title>
        <FixedBanner.Description>
          <div className="grid gap-4">
            {t('circleWallets.pendingReceiveBanner.description')}
            <ul className="list-inside list-disc">
              {data.pendingOnchainReceives.map((receive) => (
                <li key={receive.payment.id}>
                  {receive.source.address ?? ''}
                  <ModalLink
                    className="ml-4"
                    pathname={
                      routes.account.addressBook.verifyAddress.ownerType
                    }
                    query={{
                      transactionId: receive.payment.id,
                      addressId: receive.source.id,
                    }}
                  >
                    {t('circleWallets.pendingReceiveBanner.verifyWallet')}
                  </ModalLink>
                </li>
              ))}
            </ul>
          </div>
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

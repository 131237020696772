import { useCallback, useMemo } from 'react'

import { Modal } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { MultiStep } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { FormGrid } from '@shared/components/form'
import useTranslation from 'next-translate/useTranslation'

import { useAddCreditCard } from '../AddCreditCard.Context'

const schema = y.object({
  /**
   * Credit Card Number.
   */
  cardNumber: y.string().numeric().creditCard().required(),
  /**
   * Credit Card Expiration date.
   */
  expiry: y.string().expiry().required(),
  /**
   * Credit Card CVV.
   */
  cvv: y.string().required().length(3, { key: 'number.length', length: 3 }),
  /**
   * Set as the default credit card?
   */
  isDefault: y.boolean(),
})

export type DetailsFormProps = y.InferType<typeof schema>

export interface DetailsProps {
  /**
   * Optional invoice ID (if routing back to pay invoice modal after adding card).
   */
  invoiceId?: string
}

export const Details: React.FC<DetailsProps> = ({ invoiceId }) => {
  const { t } = useTranslation('modals/settings/billing')
  const { track } = useSegment()
  const { router } = useModal()

  const [creditCard, , { setCardDetails }] = useAddCreditCard()

  const expiry = useMemo(() => {
    const { expMonth, expYear } = creditCard
    if (expMonth && expYear) {
      return `${creditCard.expMonth}/${creditCard.expYear}`
    }
  }, [creditCard])

  const [Form] = useForm<DetailsFormProps>({
    schema,
    defaultValues: {
      cardNumber: creditCard.cardNumber,
      cvv: creditCard.cvv,
      expiry,
    },
  })

  const handleCardExpiryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length === 2) {
        e.target.value = `${e.target.value}/`
      }
    },
    [],
  )

  const handleSubmit = useCallback(
    ({ cardNumber, cvv, expiry, isDefault }: DetailsFormProps) => {
      const [expMonth, expYear] = expiry.split('/')

      // Update context
      setCardDetails({
        cvv,
        expMonth,
        expYear,
        isDefault,
        cardNumber,
      })

      // Route to the next step
      router.push({
        pathname:
          routes.account.billing.paymentMethod.addCreditCard.holderDetails,
        query: {
          invoiceId,
        },
      })
    },
    [invoiceId, router, setCardDetails],
  )

  const handleContinue = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ContinueClicked, {
        event: e,
      })
    },
    [track],
  )

  return (
    <MultiStep
      subtitle={t('addCreditCard.subtitle', {
        current: 1,
        end: 3,
      })}
      title={t`addCreditCard.title`}
    >
      <Form
        className="flex size-full flex-col items-stretch justify-start"
        onSubmit={handleSubmit}
      >
        <div className="mb-6 text-left text-xl font-circular-bold">
          {t('addCreditCard.details.title')}
        </div>

        <Form.Input
          className="w-full"
          data-testid="cardNumber"
          display="floating"
          label={t('addCreditCard.details.cardNumber')}
          name="cardNumber"
        />

        <FormGrid>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="cardExpiry"
              display="floating"
              label={t('addCreditCard.details.cardExpiry')}
              maxLength={7}
              name="expiry"
              onChange={handleCardExpiryChange}
            />
          </FormGrid.Element>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="cardCvv"
              display="floating"
              label={t('addCreditCard.details.cardCvv')}
              maxLength={3}
              name="cvv"
            />
          </FormGrid.Element>
        </FormGrid>

        <Form.Checkbox
          className="self-center"
          label={t('addCreditCard.details.setDefault')}
          name="isDefault"
        />

        <Modal.Footer variant="center">
          <Form.SubmitButton
            className="w-60"
            data-testid="next-button"
            onClick={handleContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Form.SubmitButton>
        </Modal.Footer>
      </Form>
    </MultiStep>
  )
}

import { useCallback, useMemo } from 'react'

import { WalletApprovalPolicyRole } from '@shared/graphql'

import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'

import type { User } from '@shared/graphql'

export const useApprovalWorkflowValidate = () => {
  const [{ approvalWorkflow, approvalLevelInEdit, userLimits = [], touched }] =
    useWalletApprovalPolicy()

  const approvers = useMemo(
    () =>
      userLimits
        .filter(({ permissions }) =>
          permissions.includes(WalletApprovalPolicyRole.APPROVER),
        )
        .map((policy) => policy.user),
    [userLimits],
  )

  const missingApproversInWorkflow: Map<User['id'], User> = useMemo(() => {
    const set = new Map<User['id'], User>()

    approvers.forEach((approver) => {
      const isUserInWorkflow = Boolean(
        approvalWorkflow?.approvers.find(({ id }) => approver.id === id),
      )

      if (!isUserInWorkflow) {
        set.set(approver.id, approver)
      }
    })

    return set
  }, [approvalWorkflow, approvers])

  const isValid = Boolean(
    approvalWorkflow?.minNeedConfirmed &&
      parseFloat(approvalWorkflow.minNeedConfirmed) > 0 &&
      approvers.length > 0,
  )

  const isEmpty =
    !approvalWorkflow ||
    (!approvalWorkflow.minNeedConfirmed &&
      approvalWorkflow.approvers.length === 0)

  const isApproverInWorkflow = useCallback(
    (approver: User) => !missingApproversInWorkflow.has(approver.id),
    [missingApproversInWorkflow],
  )

  const isTouched = touched?.approvalWorkflow

  const approvalHelpSectionHidden =
    approvers.length === 0 ||
    !approvalWorkflow ||
    missingApproversInWorkflow.size === 0 ||
    approvalLevelInEdit

  return {
    isOptional: isEmpty || approvers.length === 0,
    isValid,
    isEmpty,
    approvers,
    missingApproversInWorkflow,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
    approvalHelpSectionHidden,
    isApproverInWorkflow,
  }
}

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVault } from '../../../../hooks/create'

import { Warnings } from './Policy.Warnings'

const maxAmountSchema = y.number().allowEmpty().required()

const schema = y.object({
  /**
   * Maximum amount per day in the vault policy.
   */
  maxAmount: maxAmountSchema,
  /**
   * Maximum transactions per day in the vault policy.
   */
  maxTransactions: y.number().integer().moreThan(0).allowEmpty().required(),
})

export type PolicyFormValues = y.InferType<typeof schema>

export const PolicyForm: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const { money } = useMoney()
  const [
    { policyLimits },
    { setPolicyLimits, resetUserLimitsBasedOnPolicyLimits },
  ] = useCreateVault()

  const [Form, { watch, formState }] = useForm<PolicyFormValues>({
    schema: schema.shape({
      maxAmount: maxAmountSchema.moreThan(0, {
        key: 'amount.moreThan',
        moreThan: money({
          number: 0,
          variant: Currency.USD,
        }),
      }),
    }),
    defaultValues: {
      maxAmount: Number(policyLimits?.maxAmount ?? ''),
      maxTransactions: Number(policyLimits?.maxTransactions ?? ''),
    },
  })

  const handleChange: React.FormEventHandler<HTMLFormElement> =
    useCallback(() => {
      setPolicyLimits({
        maxAmount: watch('maxAmount').toString(),
        maxTransactions: watch('maxTransactions').toString(),
      })
    }, [setPolicyLimits, watch])

  const handleSubmit = useCallback(
    ({ maxAmount, maxTransactions }: PolicyFormValues) => {
      const limits = {
        maxAmount: maxAmount.toString(),
        maxTransactions: maxTransactions.toString(),
      }

      setPolicyLimits(limits)
      resetUserLimitsBasedOnPolicyLimits(limits)

      modal.router.push(routes.vault.createVault.userLimits)
    },
    [modal.router, resetUserLimitsBasedOnPolicyLimits, setPolicyLimits],
  )

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="sm:w-144">
        <h2 className="text-4xl text-black-600 font-circular-bold">
          {t('createVault.limits.policy.title')}
        </h2>
        <h3 className="mt-4 text-lg text-black-600">
          {t('createVault.limits.policy.subtitle')}
        </h3>

        <Form
          className="mt-4 sm:w-100"
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <Form.MoneyInput
            className="mb-4 w-full"
            currencyVariant="USD"
            label={t<string>('createVault.limits.policy.maxAmount')}
            name="maxAmount"
            padded
          />
          <Form.Input
            className="w-full"
            data-testid="transactions-input"
            label={t('createVault.limits.policy.maxTransactions')}
            name="maxTransactions"
          />

          <div className="mt-4">
            <ModalBackButton />

            <Form.SubmitButton className="w-60" variant="primary">
              {t('common:continue')}
            </Form.SubmitButton>
          </div>

          <Warnings
            currentValues={{
              maxAmount: watch('maxAmount').toString(),
              maxTransactions: watch('maxTransactions').toString(),
            }}
            errors={formState.errors}
          />
        </Form>
      </div>
    </div>
  )
}

import { useCallback, useState } from 'react'

import { Heading, Modal, SkeletonBox, Statement } from '@circlefin/components'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import classNames from 'classnames'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import { useOktaVerifyEnroll } from '../../hooks'

export interface SetupOktaVerifyProps {
  /**
   * Callback after successfully activating Okta verify MFA.
   */
  onActivation?: () => void | Promise<void>
}

export const SetupOktaVerify: React.FC<SetupOktaVerifyProps> = ({
  onActivation,
}) => {
  const { t } = useTranslation('mfa')
  const [success, setSuccess] = useState(false)

  const handleOnActivated = useCallback(() => {
    setSuccess(true)

    // Showing success screen for at least 1 second
    setTimeout(() => void onActivation?.(), 1000)
  }, [onActivation])

  const { data, loading, error, resetAndRetry } = useOktaVerifyEnroll({
    onActivated: handleOnActivated,
    onExpired: () => {
      resetAndRetry()
    },
  })

  return (
    <>
      <Modal.Header iconName="Misc/OKTA" title={t`setupOktaVerify.title`} />
      <Modal.Body>
        <div className="flex max-w-2xl flex-col items-start gap-y-6">
          {/* Step 1 */}
          <div className="w-full">
            <Heading variant="title/sm">
              <Heading.Header>{t`setupOktaVerify.steps.stepOne.heading`}</Heading.Header>
              <Heading.Intro>
                {t`setupOktaVerify.steps.stepOne.subheading`}
              </Heading.Intro>
            </Heading>
            <div className="text-neutral-subtle type-body-sm">
              <p>
                <TransWithLink
                  i18nKey="mfa:setupOktaVerify.steps.stepOne.iphone.desc"
                  urlI18nKey="mfa:setupOktaVerify.steps.stepOne.iphone.link"
                  variant="url"
                />
              </p>
              <p>
                <TransWithLink
                  i18nKey="mfa:setupOktaVerify.steps.stepOne.android.desc"
                  urlI18nKey="mfa:setupOktaVerify.steps.stepOne.android.link"
                  variant="url"
                />
              </p>
            </div>
          </div>

          {/* Step 2 */}
          <div className="w-full">
            <Heading className="relative z-10 mb-4" variant="title/sm">
              <Heading.Header>{t`setupOktaVerify.steps.stepTwo.heading`}</Heading.Header>
              <Heading.Intro>{t`setupOktaVerify.steps.stepTwo.subheading`}</Heading.Intro>
            </Heading>
            <GraphQLErrorBoundary error={error} retry={resetAndRetry}>
              <div
                aria-live="polite"
                className={classNames(
                  'flex w-full',
                  // Okta QRCode has padding, and we use these styles to crop the padding
                  { 'overflow-hidden h-40': !success },
                )}
                role="region"
              >
                <SkeletonBox
                  className="size-36"
                  loading={
                    loading || data?.enrollInOktaVerifyMfa.qrHref == null
                  }
                >
                  {success ? (
                    <Statement
                      iconName="CircleSpinnerSolid"
                      title={t`setupOktaVerify.success`}
                      variant="component"
                      loop
                    />
                  ) : (
                    <Image
                      alt={t`setupOktaVerify.steps.stepTwo.qrAlt`}
                      className="-ml-8 -mt-8"
                      height="192"
                      referrerPolicy="no-referrer"
                      src={data?.enrollInOktaVerifyMfa.qrHref ?? ''}
                      width="192"
                    />
                  )}
                </SkeletonBox>
              </div>
            </GraphQLErrorBoundary>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

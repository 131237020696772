import { useContext } from 'react'

import { VaultPermissionContext } from './context'

export const useVaultPermission = () => {
  const context = useContext(VaultPermissionContext)
  if (context === null) {
    throw new Error(
      'useVaultPermissionContext must be used within a VaultPermissionProvider',
    )
  }
  return context
}

import { PROTECTED, RESTRICTED } from '@services/permissions'

import type { Section } from '../types'

export const wireReceiveDetail: Section = {
  label: 'layout:page.payments.transaction.wire',
  route: '/payments/wire-receive/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const wireWithdrawalDetail: Section = {
  label: 'layout:page.payments.transaction.wire',
  route: '/payments/wire-withdrawal/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const senReceiveDetail: Section = {
  label: 'layout:page.payments.transaction.sen',
  route: '/payments/sen-receive/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const senWithdrawalDetail: Section = {
  label: 'layout:page.payments.transaction.sen',
  route: '/payments/sen-withdrawal/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const onchainSend: Section = {
  label: 'layout:page.payments.transaction.onchain',
  route: '/payments/onchain-send/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const onchainReceive: Section = {
  label: 'layout:page.payments.transaction.onchain',
  route: '/payments/onchain-receive/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const signetDetail: Section = {
  label: 'layout:page.payments.transaction.signet',
  route: '/payments/signet/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const cbitDetail: Section = {
  label: 'layout:page.payments.transaction.cbit',
  route: '/payments/cbit/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const xpayDetail: Section = {
  label: 'layout:page.payments.transaction.xpay',
  route: '/payments/xpay/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const rtpDetail: Section = {
  label: 'layout:page.payments.transaction.rtp',
  route: '/payments/rtp/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const sepaDetail: Section = {
  label: 'layout:page.payments.transaction.sepa',
  route: '/payments/sepa/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const sepaInstantDetail: Section = {
  label: 'layout:page.payments.transaction.sepa_instant',
  route: '/payments/sepa-instant/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const withdrawalDetail: Section = {
  label: 'layout:page.payments.transaction.payout',
  route: '/payments/withdrawal/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const depositDetail: Section = {
  label: 'layout:page.payments.transaction.deposit',
  route: '/payments/deposit/[id]',
  permissions: PROTECTED,
  segment: {
    page: 'home',
  },
}

export const redemptionFeeDetail: Section = {
  label: 'layout:page.payments.transaction.redemptionFee',
  route: '/payments/redemption-fee/[id]',
  permissions: RESTRICTED,
  segment: {
    page: 'home',
  },
}

export const paymentsSections: Section[] = [
  wireReceiveDetail,
  wireWithdrawalDetail,
  senReceiveDetail,
  senWithdrawalDetail,
  onchainSend,
  onchainReceive,
  signetDetail,
  cbitDetail,
  xpayDetail,
  rtpDetail,
  sepaDetail,
  sepaInstantDetail,
  withdrawalDetail,
  depositDetail,
  redemptionFeeDetail,
]

import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BankAccountsDocument,
  useAddOtherBankAccountMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'

import { useLinkBankAccount } from '../../../../Link.Context'

import type { ApolloError } from '@apollo/client'
import type {
  AddOtherBankAccountMutationVariables,
  Maybe,
} from '@shared/graphql'

export const useLinkNonIban = () => {
  const modal = useModal()

  const [{ location, information, address }] = useLinkBankAccount()

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [createMutation, mutation] = useAddOtherBankAccountMutation({
    onCompleted: () => {
      mfaSuccess()
    },
    onError: (error: ApolloError) => {
      mfaError({ error })
    },
    refetchQueries: [BankAccountsDocument],
  })

  const variables = useMemo((): Maybe<AddOtherBankAccountMutationVariables> => {
    if (address && information.nonIban && location?.country && location?.city) {
      const { country, city } = location
      const { state, isOrganizationAccount, ...restAddress } = address
      const { bankName, ...restInfo } = information.nonIban

      return {
        bankAccount: {
          ...restInfo,
          bankAddress: {
            bankName,
            city,
            country: country.code,
          },
          billingDetails: {
            ...restAddress,
            district: state,
          },
        },
      }
    }
    return null
  }, [address, information.nonIban, location])

  const create = useCallback(() => {
    if (variables !== null) {
      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createMutation({
            variables,
            context: context(code),
          })
        },
        onAbort: () => {
          modal.close()
        },
      })
    }
  }, [context, createMutation, modal, variables, withMfa])

  return [create, mutation] as const
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import { useSetDelayedWithdrawalsSettingsMutation } from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

export interface ToggleSettingQueryProps {
  /**
   * Determines the content of the confirmation modal.
   */
  variant?: 'enable' | 'disable'
}

export const ToggleSetting: React.FC<ToggleSettingQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/security')
  const [withMfa, { context, mfaSuccess, mfaError }] = useMfa()
  const modal = useModal()
  const { track } = useSegment()
  const [setDelayedWithdrawalsSettings] =
    useSetDelayedWithdrawalsSettingsMutation({
      onCompleted: () => {
        mfaSuccess()
      },
      onError: (error) => {
        mfaError({ error })
      },
    })

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.CancelClicked, {
        event: e,
      })
      closeModal()
    },
    [closeModal, track],
  )

  const onConfirm = useCallback(
    (variant: ToggleSettingQueryProps['variant']) =>
      (e: React.MouseEvent<HTMLButtonElement>) => {
        track(SegmentEvents.ConfirmClicked, {
          event: e,
        })
        withMfa({
          onComplete: (code) => {
            void setDelayedWithdrawalsSettings({
              variables: {
                input: {
                  enabled: variant === 'enable',
                },
              },
              context: context(code),
            })
          },
          onAbort: closeModal,
        })
      },
    [closeModal, context, setDelayedWithdrawalsSettings, track, withMfa],
  )

  return (
    <BaseTitle title={t('delayedWithdrawals.confirm.title')} variant="center">
      <PropsErrorBoundary<ToggleSettingQueryProps> props={props} variant="page">
        {({ variant }) => (
          <>
            <p className="text-sm leading-5 text-black-600 font-circular-regular">
              {t(`delayedWithdrawals.confirm.${variant}.description`)}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={onCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button onClick={onConfirm(variant)} variant="primary">
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

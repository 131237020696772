import classNames from 'classnames'

export interface FooterProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

export const Footer: React.FC<FooterProps> = ({ children, className }) => {
  return (
    <div className={classNames('flex flex-1', className)}>
      <div className="flex flex-wrap gap-4">{children}</div>
    </div>
  )
}

/**
 * Account: Address Book Routes.
 */
export const addressBook = {
  editRecipient: '/address-book/edit-recipient',
  addNewAddress: {
    selectRecipient: '/address-book/new/select-recipient',
    nonCustomer: '/address-book/new/non-customer',
    customer: '/address-book/new/customer',
    ownershipDisclaimer: '/address-book/new/ownership-disclaimer',
    error: '/address-book/new/error',
  },
  removeAddress: {
    confirmation: 'address-book/remove/confirmation',
  },
  approvalRequest: '/address-book/approval-request',
  verifyAddress: {
    ownerType: '/address-book/verify-address/owner-type',
    identityChallenge: '/address-book/verify-address/identity-challenge',
    thirdPartyWarning: '/address-book/verify-address/third-party-warning',
  },
  vaultsEnabled: {
    addNewAddress: {
      switch: '/address-book/vaults-enabled/new/',
      selectRecipient: '/address-book/vaults-enabled/new/select-recipient',
      multiAsset: {
        nonCustomer:
          '/address-book/vaults-enabled/new/multi-asset/non-customer',
        customer: '/address-book/vaults-enabled/new/multi-asset/customer',
      },
      default: {
        nonCustomer: '/address-book/vaults-enabled/new/default/non-customer',
        customer: '/address-book/vaults-enabled/new/default/customer',
      },
    },
  },
}

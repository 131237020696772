import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Billing dashboard management permission.
 * Which users are allowed to manage billing related activities?
 */
export const BILLING_DASHBOARD: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.BILLING_DASHBOARD_ROLE,
    Role.ACCOUNT_DASHBOARD_MANAGEMENT_ROLE,
  ],
  excludeAny: [Role.DEVELOPER_BILLING_ROLE],
}

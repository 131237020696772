import { useCallback, useMemo } from 'react'

import { CountryCode, useCountriesQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { CountriesInput, CountriesQuery } from '@shared/graphql'

interface UseCountriesProps extends CountriesInput {
  /**
   * Format locale.
   */
  locale?: 'en'
  /**
   * On completed.
   */
  onCompleted?: (data: CountriesQuery) => void
}

/**
 * Countries Hook.
 */
export const useCountries = ({
  locale,
  onCompleted,
  ...filter
}: UseCountriesProps = {}) => {
  const { t } = useTranslation('countries')

  // Query Graphql Countries with priority to cache
  const query = useCountriesQuery({
    variables: { filter },
    fetchPolicy: 'cache-first',
    onCompleted,
  })

  const toDropdown = useCallback(() => {
    const countryCodes = query.data?.countries?.codes ?? []
    return (
      countryCodes
        .map((code) => ({
          label: t(code),
          value: code,
        }))
        // Alphabetic Label Sort (prioritizing US and CA at the top)
        .sort((a, b) => {
          if ([CountryCode.US, CountryCode.CA].includes(a.value)) return -1
          if ([CountryCode.US, CountryCode.CA].includes(b.value)) return 1

          return a.label.localeCompare(b.label)
        })
    )
  }, [t, query.data?.countries?.codes])

  // data format
  const countries = useMemo(
    () => ({
      /**
       * Format to Dropdown.
       */
      toDropdown,
    }),
    [toDropdown],
  )

  return useMemo(
    () => ({
      ...query,
      countries,
    }),
    [query, countries],
  )
}

import { useCallback, useMemo, useState } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import {
  REMOVE_VAULT_OWNER_FAILED,
  REMOVE_VAULT_USER_FAILED,
  getError,
} from '@shared/apollo/lib/error/codes'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { UserStatusType, useRemoveUserMutation } from '@shared/graphql'
import { removeUser as removeUserUpdate } from '@shared/graphql.server/updates'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'
import type { User } from '@shared/graphql'

enum ActionVariant {
  CANCEL_INVITE = 'cancelInvite',
  REMOVE_USER = 'removeUser',
}

export interface RemoveQueryProps {
  /**
   * User being removed.
   */
  user?: User
}

export const Remove: React.FC<RemoveQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()
  const toast = useToast()

  const variant = useMemo(() => {
    if (props.user?.status.type === UserStatusType.PENDING) {
      return ActionVariant.CANCEL_INVITE
    }

    return ActionVariant.REMOVE_USER
  }, [props.user?.status.type])

  const [mutationError, setMutationError] =
    useState<ApolloError | undefined>(undefined)

  const [removeUser, { reset, loading }] = useRemoveUserMutation({
    update: removeUserUpdate,
    onCompleted: () => {
      modal.close()
      toast.success(
        t(`${variant}.success`, {
          name: props.user?.name,
        }),
      )
    },
    onError: (error) => {
      const { graphQLErrors } = error
      if (getError(REMOVE_VAULT_USER_FAILED, graphQLErrors)) {
        modal.router.push(routes.account.users.vault.removeWarning.default)
        return
      }

      if (getError(REMOVE_VAULT_OWNER_FAILED, graphQLErrors)) {
        modal.router.push(routes.account.users.vault.removeWarning.owner)
        return
      }

      // if the error is not related to vault user, show this error via the `GraphQLErrorBoundary` component
      setMutationError(error)
    },
  })

  const resetMutationError = useCallback(() => {
    setMutationError(undefined)
    reset()
  }, [reset])

  const handleCancel = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleConfirm = useCallback(
    (user: User) => () => {
      void removeUser({
        variables: {
          userId: user.id,
          email: user.email,
          isActiveUser: variant === ActionVariant.REMOVE_USER,
        },
      })

      return undefined
    },
    [removeUser, variant],
  )

  return (
    <BaseTitle title={t(`${variant}.title`)} variant="center">
      <PropsErrorBoundary props={props} variant="page">
        {({ user }) => (
          <GraphQLErrorBoundary
            error={mutationError}
            retry={resetMutationError}
            variant="page"
          >
            <p className="text-sm leading-6 text-black-600">
              {t(`${variant}.description`, {
                name: user.name,
                email: user.email,
              })}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={handleCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button
                loading={loading}
                onClick={handleConfirm(user)}
                variant="primary"
              >
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

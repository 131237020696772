import { useCallback } from 'react'
import type { ChangeEvent } from 'react'

import { Heading, SelectCard, SkeletonBox } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import { TypeGuards } from '@services/type-guards'
import { CIRCLE_WALLETS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useCircleWalletsQuery } from '@shared/graphql'

import type { Currency } from '@shared/graphql'

export interface ChooseWalletProps {
  /**
   * Currency.
   */
  currency?: Currency
  /**
   * OnChange.
   */
  onChooseCurrency: (value: Currency) => void
  /**
   * Custom Style.
   */
  className?: string
}

export const ChooseWallet: React.FC<ChooseWalletProps> = ({
  currency,
  onChooseCurrency,
  className,
}) => {
  const { money } = useMoney()
  const { data, loading } = useCircleWalletsQuery({
    pollInterval: CIRCLE_WALLETS_POLL_INTERVAL, // 10sec interval
    fetchPolicy: 'cache-and-network',
  })

  const handleChooseWallet = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!TypeGuards.Common.isCurrency(e.target.value)) return
      onChooseCurrency(e.target.value)
    },
    [onChooseCurrency],
  )

  return (
    <SkeletonBox className="h-18 w-116" loading={loading && !data}>
      <div className="flex flex-col gap-5">
        {data &&
          data.circleWallets.map((wallet, index) => {
            return (
              <SelectCard
                key={index}
                checked={currency === wallet.balance.currency}
                className={className}
                id={`radio-wallet-${wallet.balance.currency}`}
                label={wallet.name}
                name={wallet.name}
                onChange={handleChooseWallet}
                type="radio"
                value={wallet.balance.currency}
              >
                <Heading iconName={wallet.icon}>
                  <Heading.Header>{wallet.name}</Heading.Header>
                  <Heading.Intro>
                    {money({
                      number: wallet.balance.amount,
                      variant: wallet.balance.currency,
                    })}
                  </Heading.Intro>
                </Heading>
              </SelectCard>
            )
          })}
      </div>
    </SkeletonBox>
  )
}

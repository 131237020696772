import { DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { TransactionApproval } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface TransactionApprovalsProps {
  /**
   * Transaction approval.
   */
  approval: TransactionApproval
}

export const TransactionApprovals: React.FC<TransactionApprovalsProps> = ({
  approval,
}) => {
  const { t } = useTranslation('vaults')
  const { money } = useMoney({ locale: 'en-US' })

  return (
    <>
      <Label>{t('payments:transactionDetail.approvals')}</Label>
      <Description>
        <div className="flex flex-col text-black-600">
          <span className="font-circular-bold">
            <Trans
              i18nKey="vaults:transactionApprovals.level"
              values={{ num: approval.orderInFlow }}
            />
          </span>
          <span>
            <Trans
              i18nKey="vaults:transactionApprovals.description"
              values={{
                threshold: money({
                  number: Number(approval.transferAmount),
                  variant: 'USD',
                  options: {
                    symbol: true,
                    signDisplay: false,
                    padded: true,
                  },
                }),
                minimum: approval.minNeedConfirmed,
              }}
            />
          </span>
          <span>
            {approval.approvers
              .map((approver) => {
                const status = t(
                  `transactionApprovals.status.${approver.status}`,
                )
                return `${approver.firstName} ${approver.lastName} ${status}`
              })
              .join(', ')}
          </span>
        </div>
      </Description>
    </>
  )
}

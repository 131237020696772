import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseHeader } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  SubscriptionsDocument,
  UpdateAutoPayDocument,
  useUpdateAutoPayMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { AutoPayModalBody } from '../AutoPay.Body'

export const Enable = () => {
  const { t } = useTranslation('modals/settings/billing')
  const modal = useModal()
  const { track } = useSegment()

  const [withMfa, { context, mfaError, mfaSuccess }] = useMfa()
  const [setAutoPay] = useUpdateAutoPayMutation({
    refetchQueries: [UpdateAutoPayDocument, SubscriptionsDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      mfaSuccess({
        message: t`common:success`,
        description: t`autoPay.enable.success`,
      })
    },
    onError: (error) => {
      mfaError({ error })
    },
  })

  const onAbort = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleEnableAutoPay = useCallback(() => {
    track(SegmentEvents.EnableAutoPayClicked)
    withMfa({
      onComplete: (code) => {
        void setAutoPay({
          variables: {
            automaticPaymentEnabled: true,
          },
          context: context(code),
        })
      },
      onAbort,
    })
  }, [context, onAbort, setAutoPay, withMfa, track])

  return (
    <BaseHeader className="h-112 px-8 pb-4" title={t`autoPay.enable.header`}>
      <AutoPayModalBody onCancel={onAbort} onConfirm={handleEnableAutoPay} />
    </BaseHeader>
  )
}

import { DescriptionList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { BlockchainRecipientAddress } from '@shared/graphql'

const { Label, Description } = DescriptionList

export const Deposit: React.FC<BlockchainRecipientAddress> = ({
  chain,
  description,
  address,
  addressTag,
}) => {
  const { t } = useTranslation('express/modal')

  return (
    <DescriptionList
      className="min-w-160"
      data-testid="blockchain-deposit"
      size="sm"
    >
      <Label className="w-64">{t`addRoute.review.label.blockchain`}</Label>
      <Description>{chain}</Description>
      <Label className="w-64">{t`addRoute.review.label.nickname`}</Label>
      <Description>{description}</Description>
      <Label className="w-64">{t`addRoute.review.label.walletAddress`}</Label>
      <Description>{address}</Description>
      <Label className="w-64">{t`addRoute.review.label.memo`}</Label>
      <Description>{addressTag}</Description>
    </DescriptionList>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Which users are allowed to make onchain transfer?
 */
export const BLOCKCHAIN_TRANSFER: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WALLETS_ROLE, Role.ONCHAIN_TRANSFER_ROLE],
}

import { LoadingSpinner } from '@shared/components/common'
import { useUserActivationQuery } from '@shared/graphql'

import { ActivationError } from '../ActivationError/ActivationError'
import { ActivationTokenConsumed } from '../ActivationTokenConsumed/ActivationTokenConsumed'

import { ActivateUserForm } from './ActivateUser.Form'
import { LegacyActivateUserForm } from './LegacyActivateUser.Form'

export interface ActivateUserProps {
  /**
   * Circle authentication token.
   */
  token?: string
}

export const ActivateUser: React.FC<ActivateUserProps> = ({ token }) => {
  const { data, error } = useUserActivationQuery({
    variables: {
      token: token ?? '',
    },
  })

  if (data) {
    if (!data?.userActivation?.legacyInvite) {
      if (data.userActivation?.tokenConsumed) {
        return <ActivationTokenConsumed />
      }

      return <ActivateUserForm {...data?.userActivation} />
    } else {
      return <LegacyActivateUserForm {...data?.userActivation} />
    }
  }

  if (error) {
    return <ActivationError />
  }

  return (
    <div className="flex w-full justify-center pt-22">
      <LoadingSpinner />
    </div>
  )
}

import { useMemo } from 'react'

import { Icon, ProgressBar } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import classNames from 'classnames'
import isNumber from 'lodash.isnumber'

import type { Amount } from '@shared/graphql'

export type LimitCardThreshold = (
  progress: number,
  offset: number | undefined,
) => {
  variant: 'warning' | 'error'
  message: string
} | null

export interface LimitCardProps {
  /**
   * Additional classes.
   */
  className?: string
  /**
   * Variant to be displayed.
   */
  variant: 'Deposit' | 'Withdrawal'
  /**
   * Card title.
   */
  title: string
  /**
   * Max Limit value.
   */
  maxValue: Amount
  /**
   * Current limit value.
   */
  currentValue: Amount
  /**
   * Function to determine alert variant and message.
   */
  threshold?: LimitCardThreshold
}

export const LimitCard: React.FC<LimitCardProps> = ({
  className,
  maxValue,
  currentValue,
  title,
  variant,
  threshold,
}) => {
  const currentValueNumber = useMemo(
    () => parseInt(currentValue.amount, 10),
    [currentValue],
  )

  const maxValueNumber = useMemo(
    () => parseInt(maxValue.amount, 10),
    [maxValue],
  )

  const { money } = useMoney({ locale: 'en-US' })
  const progress = useMemo(() => {
    if (currentValueNumber === 0) {
      return 0
    }

    return currentValueNumber > maxValueNumber
      ? 100
      : (currentValueNumber * 100) / maxValueNumber
  }, [currentValueNumber, maxValueNumber])

  const currentValueLabel = useMemo(
    () =>
      currentValueNumber > maxValueNumber
        ? money({ number: maxValueNumber, variant: maxValue.currency })
        : money({ number: currentValueNumber, variant: currentValue.currency }),
    [
      currentValue.currency,
      maxValue.currency,
      currentValueNumber,
      maxValueNumber,
      money,
    ],
  )

  const offset = useMemo(
    () =>
      currentValueNumber > maxValueNumber
        ? Math.abs(maxValueNumber - currentValueNumber)
        : undefined,
    [currentValueNumber, maxValueNumber],
  )

  const alert = useMemo(
    () => threshold?.(progress, offset),
    [threshold, progress, offset],
  )

  const errorValueLabel = isNumber(offset)
    ? money({
        number: offset,
        variant: maxValue.currency,
        options: { signDisplay: true },
      })
    : undefined

  return (
    <div
      className={classNames(
        'h-72 shadow-md rounded-lg pt-2 px-2 flex flex-col items-center relative font-circular bg-white',
        className,
        variant,
      )}
      data-testid="limit-card"
    >
      <div className="mb-3 h-10 w-full">
        {alert && (
          <div
            className={classNames(
              'rounded-lg flex items-center justify-center h-full',
              {
                'bg-orange-50 text-orange-400': alert.variant === 'warning',
                'bg-red-50 text-red-400': alert.variant === 'error',
              },
            )}
            data-testid="alert-cnt"
          >
            <Icon
              className={classNames(
                'mr-2.5',
                alert.variant === 'warning'
                  ? 'text-orange-400'
                  : 'text-red-400',
              )}
              name="ExclamationSolid"
            />
            <p className="text-sm font-circular-medium" data-testid="alert-msg">
              {alert.message}
            </p>
          </div>
        )}
      </div>
      <div className="mb-7 flex flex-col items-center">
        <div
          className={classNames(
            'p-2.5  rounded-full mb-3',
            variant === 'Deposit'
              ? 'bg-green-75 text-green-300'
              : 'bg-violet-75 text-violet-300',
          )}
        >
          <Icon
            name={variant === 'Deposit' ? 'DownloadSolid' : 'WithdrawalSolid'}
          />
        </div>
        <span className="mb-2 text-xl leading-5 text-black-600 font-circular-bold">
          {money({ number: maxValueNumber, variant: maxValue.currency })}
        </span>
        <span className="text-black-200 font-circular-bold" data-testid="title">
          {title}
        </span>
      </div>
      <ProgressBar
        className="w-full px-3"
        currentValueLabel={currentValueLabel}
        errorValueLabel={errorValueLabel}
        maxValueLabel={money({
          number: maxValueNumber,
          variant: maxValue.currency,
        })}
        progress={progress}
        variant={progress >= 100 ? 'error' : 'primary'}
        withMark={progress >= 75 && progress < 100}
      />
    </div>
  )
}

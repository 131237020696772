import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * User removal mutation permission.
 */
export const USER_REMOVAL_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.USER_MANAGEMENT_ROLE],
}

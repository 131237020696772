import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Permissions for guarding FX launch.
 */
export const FX_LAUNCH_ENABLED: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_FX_TRANSFERS_ROLE],
}

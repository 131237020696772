export interface BodyProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Body: React.FC<BodyProps> = ({ children }) => (
  <div className="my-6 grid gap-y-2 text-lg text-black-400" data-testid="body">
    {children}
  </div>
)

Body.displayName = 'NotificationScreen.Body'

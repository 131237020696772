import {
  createRolrAccountFormSchema,
  createRolrRedemptionAmountSchema,
  rolrBizInfoSchema,
} from '../../../containers/RolrSignUp'

import type { RolrSetupContextState, RolrSetupStepNames } from '../context'

export type RolrStepValidator = (state: RolrSetupContextState) => boolean

const isLandingStepValid: RolrStepValidator = (state) =>
  state.landing?.letsGoClicked === true

const isBusinessInfoStepValid: RolrStepValidator = (state) =>
  rolrBizInfoSchema.isValidSync(state.businessInfo)

const isAuthRepStepValid: RolrStepValidator = (state) =>
  state.authRep?.isAuthRep === true

const isRedemptionAmountStepValid: RolrStepValidator = (state) =>
  createRolrRedemptionAmountSchema(state.businessInfo?.isEeaUser).isValidSync(
    state.redemptionAmount,
  )

const isCreateAccountStepValid: RolrStepValidator = (state) =>
  createRolrAccountFormSchema.isValidSync(state.createAccount)

/**
 * Validation map for each step, goal is to reuse the same validation the form themselves uses.
 */
export const rolrValidationMap: Record<RolrSetupStepNames, RolrStepValidator> =
  {
    landing: isLandingStepValid,
    businessInfo: isBusinessInfoStepValid,
    authRep: isAuthRepStepValid,
    redemptionAmount: isRedemptionAmountStepValid,
    createAccount: isCreateAccountStepValid,
  }

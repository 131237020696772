import { useCallback, useEffect, useMemo } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { routes } from '@services/sections/modal/routes'
import { UserStatusType, useVerifiedUsersQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../containers/Create'
import { useWalletConnectValidate } from '../../../containers/CreateVault'
import { useCreateVault } from '../../../hooks/create'
import * as VaultLayout from '../../../layout'

import { Policy } from './Policy/Policy'

import type { VaultWalletConnectPolicy, User } from '@shared/graphql'

export const WalletConnect: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { router } = useModal()
  const [
    {
      walletConnectPolicy,
      isWalletConnectPolicyEnabled,
      isWalletConnectPolicyInEdit,
    },
    {
      setWalletConnectPolicy,
      setWalletConnectPolicyEnabled,
      setWalletConnectPolicyInEdit,
    },
  ] = useCreateVault()
  const { approvers, operators } = useWalletConnectValidate()
  const usersQuery = useVerifiedUsersQuery()

  // Users list.
  const usersList: User[] = useMemo(() => {
    const users: User[] = []

    for (const user of usersQuery.data?.verifiedUsers ?? []) {
      // List should exclude current user and any inactive users
      if (user.current || user.status.type !== UserStatusType.ACTIVE) {
        continue
      }

      users.push(user)
    }

    return users
  }, [usersQuery.data?.verifiedUsers])

  const onContinue = useCallback(() => {
    void router.push(routes.vault.createVault.review)
  }, [router])

  const onChange = useCallback(
    (updated: VaultWalletConnectPolicy) => {
      setWalletConnectPolicy(updated)
    },
    [setWalletConnectPolicy],
  )

  const onSubmit = useCallback(
    (updated: VaultWalletConnectPolicy) => {
      onChange(updated)

      setWalletConnectPolicyInEdit(false)
    },
    [onChange, setWalletConnectPolicyInEdit],
  )

  const onEdit = useCallback(() => {
    setWalletConnectPolicyInEdit(true)
  }, [setWalletConnectPolicyInEdit])

  const onToggle = useCallback(
    (enabled: boolean) => {
      setWalletConnectPolicyEnabled(enabled)
    },
    [setWalletConnectPolicyEnabled],
  )

  useEffect(() => {
    // user has already interacted with the form
    if (walletConnectPolicy) {
      return
    }

    // user has not interacted with the form, prefill operators and approvers
    setWalletConnectPolicy({
      operators,
      approvers,
      minNeedConfirmed: '',
    })
    setWalletConnectPolicyInEdit(true)
    setWalletConnectPolicyEnabled(true)
  }, [
    approvers,
    onToggle,
    operators,
    setWalletConnectPolicy,
    setWalletConnectPolicyEnabled,
    setWalletConnectPolicyInEdit,
    walletConnectPolicy,
  ])

  return (
    <VaultLayout.CreateVault
      currentStep={CreateVaultNavigationStep.WALLET_CONNECT}
    >
      <div className="grid max-w-176 grid-cols-1 justify-items-start">
        <p className="text-lg text-black-400">{t('common:optional')}</p>
        <h2 className="mt-2 text-4xl text-black-600 font-circular-bold">
          {t('createVault.walletConnect.title')}
        </h2>
        <div className="mt-8 grid w-full grid-cols-1 gap-y-2 pt-1">
          <Policy
            approvers={usersList ?? []}
            isEdit={isWalletConnectPolicyInEdit}
            isEnabled={isWalletConnectPolicyEnabled}
            onChange={onChange}
            onEdit={onEdit}
            onSubmit={onSubmit}
            onToggle={onToggle}
            operators={usersList ?? []}
            policy={walletConnectPolicy}
          />
        </div>

        <div className="mt-4">
          <ModalBackButton />

          <Button
            className="w-60"
            disabled={
              isWalletConnectPolicyEnabled && isWalletConnectPolicyInEdit
            }
            onClick={onContinue}
            variant="primary"
          >
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </VaultLayout.CreateVault>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  NotificationSubscriptionsDocument,
  useDeleteNotificationSubscriptionMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface RemoveConfirmProps {
  /**
   * The id of the subscription that will be deleted.
   */
  subscriptionId?: string
}

export const RemoveConfirm: React.FC<RemoveConfirmProps> = ({
  subscriptionId,
}) => {
  const { t } = useTranslation('modals.developer')
  const modal = useModal()
  const { track } = useSegment()

  const [removeEndpoint, { loading, error, reset }] =
    useDeleteNotificationSubscriptionMutation({
      onCompleted: () => modal.close(),
      onError: () => undefined,
      refetchQueries: [NotificationSubscriptionsDocument],
    })

  const onCancel = useCallback(() => {
    modal.close()
  }, [modal])

  const onConfirm: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      track(SegmentEvents.ButtonClicked, {
        props: {
          button_label: 'Confirm',
        },
        event: e,
      })

      if (subscriptionId) {
        void removeEndpoint({ variables: { id: subscriptionId } })
      }
    },
    [subscriptionId, removeEndpoint, track],
  )

  const onRetry = useCallback(() => {
    reset()
  }, [reset])

  return (
    <BaseTitle title={t`removeSubscriptionConfirmation.title`} variant="center">
      <PropsErrorBoundary<RemoveConfirmProps>
        props={{ subscriptionId }}
        variant="page"
      >
        {() => (
          <GraphQLErrorBoundary error={error} retry={onRetry} variant="page">
            <p className="text-sm leading-6 text-black-600">
              {t`removeSubscriptionConfirmation.subtitle`}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button
                data-testid="remove-subscription-confirm-cancel-btn"
                disabled={loading}
                onClick={onCancel}
                variant="secondary"
              >
                {t`common:cancel`}
              </Button>
              <Button
                data-testid="remove-subscription-confirm-btn"
                loading={loading}
                onClick={onConfirm}
                variant="primary"
              >
                {t`common:confirm`}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

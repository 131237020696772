import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Name.
   */
  bankName: y.string().required().max(35, { key: 'max', max: 35 }),
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Account Iban.
   */
  iban: y.string().required().iban(),
})

export type IbanFormValues = y.InferType<typeof schema>

export interface IbanFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: IbanFormValues) => void
}

export const IbanForm: React.FC<IbanFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<IbanFormValues>({
    schema,
    defaultValues: information.iban,
  })

  const handleSubmit = useCallback(
    (iban: IbanFormValues) => {
      // Set data to context
      setInformation({ iban })

      // Pass data to submit callback (for testing)
      onSubmit?.(iban)
    },
    [onSubmit, setInformation],
  )

  return (
    <Form className="grid w-104 gap-6" onSubmit={handleSubmit}>
      {/* Bank Account Name */}
      <Form.Input
        className="w-full"
        data-testid="input-bankName"
        label={t('link.information.bankName')}
        name="bankName"
      />

      {/* Bank Account Nickname */}
      <Form.Input
        className="w-full"
        data-testid="input-nickname"
        label={t('link.information.nickname')}
        name="nickname"
      />

      {/* Bank Account Number */}
      <Form.Input
        className="w-full"
        data-testid="input-iban"
        label={t('link.information.iban')}
        name="iban"
      />

      {/* Continue */}
      <Center className="pt-6" variant="horizontal">
        <Form.SubmitButton
          className="mt-6 w-64"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { useCallback, useMemo, useState } from 'react'

import { FixedBanner, Icon, SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import useTranslation from 'next-translate/useTranslation'

export enum GridTitleEnum {
  beneficiaryName = 'beneficiaryName',
  bankAccountNumber = 'bankAccountNumber',
  clabe = 'clabe',
  beneficiaryAddress = 'beneficiaryAddress',
  routingNumber = 'routingNumber',
  bankName = 'bankName',
  bankAddress = 'bankAddress',
  referenceIDMemo = 'referenceIDMemo',
  'swiftCode.USD' = 'swiftCode.USD',
  'swiftCode.EUR' = 'swiftCode.EUR',
  'swiftCode.SGD' = 'swiftCode.SGD',
  'swiftCode.MXN' = 'swiftCode.MXN',
  currency = 'currency',
  cbitWalletAddress = 'cbitWalletAddress',
  ispb = 'ispb',
  branchCode = 'branchCode',
  accountType = 'accountType',
  pixKey = 'pixKey',
  accountNumber = 'accountNumber',
}

type TranslationKeys = keyof typeof GridTitleEnum
export type GridItem = [TranslationKeys, string | undefined | null]

export interface GridProps {
  /**
   * Grid Items to display.
   */
  gridItems: GridItem[]
  /**
   * Flag to check if the bank account has virtual account enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const Grid: React.FC<GridProps> = ({
  loading = false,
  gridItems,
  virtualAccountEnabled = false,
}) => {
  const { t } = useTranslation('modals.transfer')
  const referenceIdMemoItem = useMemo(
    () =>
      gridItems.find(
        ([fieldName]) => fieldName === GridTitleEnum.referenceIDMemo,
      ),
    [gridItems],
  )
  const [, referenceIdMemoValue] = referenceIdMemoItem || []

  const [refIdCopyIcon, setRefIdCopyIcon] =
    useState<'DuplicateOutline' | 'CheckOutline'>('DuplicateOutline')
  const handleCopyClick = useCallback(() => {
    void navigator.clipboard.writeText(referenceIdMemoValue ?? '')
    setRefIdCopyIcon('CheckOutline')
    setTimeout(() => {
      setRefIdCopyIcon('DuplicateOutline')
    }, 3000)
  }, [referenceIdMemoValue])

  return (
    <>
      {gridItems
        .filter(([fieldName]) => fieldName !== GridTitleEnum.referenceIDMemo)
        .map(([fieldName, value]) => (
          <SkeletonBox key={fieldName} className="block h-16" loading={loading}>
            {value && (
              <div className="mb-4" data-testid={fieldName}>
                <CopyToClipboard
                  key={fieldName}
                  label={t(
                    `fx.beneficiaryBankAccountDetails.labels.${fieldName}`,
                  )}
                  value={value}
                />
              </div>
            )}
          </SkeletonBox>
        ))}
      <FixedBanner
        visible={referenceIdMemoItem != null && !virtualAccountEnabled}
        hideIcon
      >
        <FixedBanner.Description>
          <div className="flex flex-col gap-1">
            <div className="flex gap-2">
              <p className="type-body-base">
                {t(`fx.beneficiaryBankAccountDetails.referenceId`)}
              </p>
              <span className="flex items-center gap-1 type-body-base-bold">
                {referenceIdMemoValue}
                <Icon
                  name={refIdCopyIcon}
                  onClick={handleCopyClick}
                  size={18}
                />
              </span>
            </div>
            <p className="type-body-sm">
              {t(
                `fx.beneficiaryBankAccountDetails.referenceAgreementDisclaimer`,
              )}
            </p>
          </div>
        </FixedBanner.Description>
      </FixedBanner>
    </>
  )
}

import { Card } from '@circlefin/components'
import { partition } from 'lodash'

import * as Buttons from '../../buttons'

import { VaultArchivedChildWallets } from './VaultArchivedChildWallets/VaultArchivedChildWallets'
import { VaultChildWalletRow } from './VaultChildWalletRow/VaultChildWalletRow'

import type { VaultParentWallet } from '@shared/graphql'

export interface VaultParentWalletCardProps {
  /**
   * The id of the parent wallet's vault.
   */
  vaultId: string
  /**
   * The parent wallet object.
   */
  parentWallet: VaultParentWallet
}

export const VaultParentWalletCard: React.FC<VaultParentWalletCardProps> = ({
  vaultId,
  parentWallet,
}) => {
  const [archivedChildWallets, activeChildWallets] = partition(
    parentWallet.wallets,
    'archived',
  )

  return (
    <Card data-testid="vault-wallet-card">
      <Card.Content>
        <div className="flex justify-between pb-6 pl-3 pt-1">
          <h3
            className="text-xl text-black-600 font-circular-book"
            data-testid="vault-wallet-blockchain"
          >
            {parentWallet.name}
          </h3>
        </div>

        {activeChildWallets.map((wallet, index) => (
          <VaultChildWalletRow
            key={index}
            parentWalletId={parentWallet.id}
            vaultId={vaultId}
            wallet={wallet}
          />
        ))}

        <VaultArchivedChildWallets
          parentWalletId={parentWallet.id}
          vaultId={vaultId}
          vaultWallets={archivedChildWallets}
        />
      </Card.Content>
      <Card.Footer className="grid grid-flow-col justify-end gap-6">
        <Buttons.Wallet.Deposit
          disabled={!parentWallet.enabled}
          parentWalletId={parentWallet.id}
          variant="primary"
          walletState={parentWallet.state}
        />
        <Buttons.Wallet.Transfer
          disabled={!parentWallet.enabled}
          parentWalletId={parentWallet.id}
          variant="primary"
          walletState={parentWallet.state}
        />
      </Card.Footer>
    </Card>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FeatureSwitch } from '@services/feature-switch'
import { routes } from '@services/sections/modal/routes'
import { useVaultWalletQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'

import { VaultWalletDetailMenu } from './VaultWalletDetailMenu/VaultWalletDetailMenu'

interface VaultWalletDetailHeaderActionsProps {
  /**
   * Vault id.
   */
  vaultId: string
  /**
   * Vault wallet id.
   */
  walletId: string
}

export const VaultWalletDetailHeaderActions: React.FC<VaultWalletDetailHeaderActionsProps> =
  ({ vaultId, walletId }) => {
    const { t } = useTranslation('vaults')
    const { router } = useModal()
    const { data } = useVaultWalletQuery({
      variables: {
        vaultId,
        walletId,
      },
    })
    const { parentWalletId, frozen, archived, assetMetadata } =
      data?.vaultWallet ?? {}

    const handleUnarchiveModal = useCallback(() => {
      router.push({
        pathname: routes.vault.vaultWalletDetails.unarchive,
        query: {
          asset: assetMetadata?.name,
        },
      })
    }, [router, assetMetadata])

    // Show unarchive button if wallet is archived and feature switch is enabled
    if (archived && FeatureSwitch.vaultArchivingWalletsEnabled()) {
      return (
        <Button onClick={handleUnarchiveModal} variant="secondary">
          {t`vaultWallets.detail.header.unarchive`}
        </Button>
      )
    }

    // Show no action buttons if:
    if (
      // wallet is frozen
      frozen ||
      // no wallet could be found
      !data?.vaultWallet
    ) {
      return null
    }

    return (
      <>
        <VaultWalletDetailMenu
          archived={data.vaultWallet.archived}
          id={data.vaultWallet.id}
          state={data.vaultWallet.state}
        />

        <Buttons.Wallet.Deposit
          disabled={!data?.vaultWallet.backedUp || data?.vaultWallet.frozen}
          parentWalletId={parentWalletId ?? walletId}
          tokenActivationState={data.vaultWallet.tokenActivationState}
          variant="primary"
          walletId={walletId ?? parentWalletId}
          walletState={data.vaultWallet.state}
        />

        <Buttons.Wallet.Transfer
          disabled={!data.vaultWallet.backedUp || data.vaultWallet.frozen}
          parentWalletId={parentWalletId ?? walletId}
          tokenActivationState={data.vaultWallet.tokenActivationState}
          variant="primary"
          walletId={walletId ?? parentWalletId}
          walletState={data.vaultWallet.state}
        />

        <Buttons.Wallet.Activate
          blockchain={data.vaultWallet.blockchain}
          parentWalletId={parentWalletId ?? walletId}
          tokenActivationState={data.vaultWallet.tokenActivationState}
          walletId={walletId ?? parentWalletId}
          walletState={data.vaultWallet.state}
        />
      </>
    )
  }

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { LocalsForms } from '@features/locales/forms'
import { useCountries } from '@features/locales/hooks/countries'
import { MultiStep } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { FormGrid } from '@shared/components/form'
import {
  CardPaymentMethodsDocument,
  WalletPaymentMethodDocument,
  useAddMerchantCreditCardMutation,
  CountryCode,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useAddCreditCard } from '../AddCreditCard.Context'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const schema = y.object({
  /**
   * City name.
   */
  city: y.string().required(),
  /**
   * Country Code.
   */
  country: y.mixed<CountryCode>().oneOf(Object.values(CountryCode)).required(),
  /**
   * Email Address.
   */
  email: y.string().email().required(),
  /**
   * Address line 1.
   */
  line1: y.string().required(),
  /**
   * Address line 2.
   */
  line2: y.string(),
  /**
   * Cardholder Full-name.
   */
  name: y.string().required(),
  /**
   * Billing Zip Code.
   */
  postalCode: y.string().postalCode().required(),
  /**
   * Billing State.
   */
  state: LocalsForms.Dropdown.stateSchema.required(),
})

export type HolderDetailsFormProps = y.InferType<typeof schema>

export interface HolderDetailsProps {
  /**
   * Optional invoice ID (if routing back to pay invoice modal after adding card).
   */
  invoiceId?: string
}

export const HolderDetails: React.FC<HolderDetailsProps> = ({ invoiceId }) => {
  const { t } = useTranslation('modals/settings/billing')
  const modal = useModal()
  const { router } = useModal()
  const { track } = useSegment()
  const { loading: countriesLoading, countries } = useCountries({
    blockchain: {
      registeredIn: true,
    },
  })

  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [cardDetails, initBillingDetails, { setBillingDetails }] =
    useAddCreditCard()

  const [Form, { watch, resetField }] = useForm<HolderDetailsFormProps>({
    schema,
    defaultValues: {
      ...initBillingDetails,
      line2: initBillingDetails.line2 ?? undefined,
    },
  })

  const [addMerchantCreditCard] = useAddMerchantCreditCardMutation({
    refetchQueries: [
      /**
       * Refetch Card payment methods Table.
       */
      CardPaymentMethodsDocument,
      WalletPaymentMethodDocument,
      /**
       * NOTE: Error message.
       */
    ],
    onCompleted: () => {
      mfaSuccess({
        message: t`common:success`,
      })
      // if routed to AddCreditCard modal from PayInvoice modal, route back to PayInvoice
      if (invoiceId) {
        router.push({
          pathname: routes.account.billing.payInvoice.paymentMethodSelection,
          query: { invoiceId },
        })
      }
    },
    onError: (error) => {
      mfaError({
        error,
        message: t`common:generalError`,
      })
      // if routed to AddCreditCard modal from PayInvoice modal, route back to PayInvoice
      if (invoiceId) {
        router.push({
          pathname: routes.account.billing.payInvoice.paymentMethodSelection,
          query: { invoiceId },
        })
      }
    },
  })

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onSubmit = useCallback(
    (billingDetails: HolderDetailsFormProps) => {
      // Set billing details to the context
      setBillingDetails(billingDetails)

      // Add the credit card
      withMfa({
        onComplete: (code) => {
          void addMerchantCreditCard({
            variables: {
              merchantCreditCardInput: {
                ...cardDetails,
                billingDetails,
              },
            },
            context: context(code),
          })
        },
        onAbort: closeModal,
      })
    },
    [
      addMerchantCreditCard,
      cardDetails,
      closeModal,
      context,
      setBillingDetails,
      withMfa,
    ],
  )

  const handleCountryChange = useCallback(
    (selected: SelectListItem<CountryCode>) => {
      void resetField('state')
      track(SegmentEvents.DropdownSelected, {
        props: {
          field_name: 'Country',
          value: selected.value,
        },
      })
    },
    [resetField, track],
  )

  return (
    <MultiStep
      subtitle={t('addCreditCard.subtitle', {
        current: 2,
        end: 3,
      })}
      title={t`addCreditCard.title`}
    >
      <Form
        className="flex size-full flex-col items-stretch justify-start"
        onSubmit={onSubmit}
      >
        <div className="text-left text-xl font-circular-bold">
          {t('addCreditCard.holderDetails.title')}
        </div>
        <FormGrid>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingFullName"
              display="floating"
              label={t('addCreditCard.holderDetails.fullName')}
              name="name"
            />
          </FormGrid.Element>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingAddressLine1"
              display="floating"
              label={t('addCreditCard.holderDetails.addressLine1')}
              name="line1"
            />
          </FormGrid.Element>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingEmail"
              display="floating"
              label={t('addCreditCard.holderDetails.email')}
              name="email"
            />
          </FormGrid.Element>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingAddressLine2"
              display="floating"
              label={t('addCreditCard.holderDetails.addressLine2')}
              name="line2"
            />
          </FormGrid.Element>

          <Form.Dropdown
            data-testid="dropdown-country"
            display="floating"
            inputLoading={countriesLoading}
            items={countries.toDropdown()}
            label={t`addCreditCard.holderDetails.country`}
            name="country"
            onChange={handleCountryChange}
          />
          <LocalsForms.Dropdown.State
            className="w-full"
            country={watch('country')}
            display="floating"
            link={{ locatedIn: true }}
          />
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingCity"
              display="floating"
              label={t('addCreditCard.holderDetails.city')}
              name="city"
            />
          </FormGrid.Element>
          <FormGrid.Element>
            <Form.Input
              className="w-full"
              data-testid="billingPostalCode"
              display="floating"
              label={t('addCreditCard.holderDetails.postalCode')}
              name="postalCode"
            />
          </FormGrid.Element>
        </FormGrid>
        <div className="flex w-full items-center justify-center">
          <Form.SubmitButton className="w-60" variant="primary">
            {t('common:continue')}
          </Form.SubmitButton>
        </div>
      </Form>
    </MultiStep>
  )
}

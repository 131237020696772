import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import { TransWithLink } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

import { AlreadyHaveAccount } from '../../AlreadyHaveAccount/AlreadyHaveAccount'
import { PersonalEmailWarning } from '../../PersonalEmailWarning/PersonalEmailWarning'

const schema = y.object({
  /**
   * Legal first name.
   */
  firstName: y.string().required(),
  /**
   * Legal last name.
   */
  lastName: y.string().required(),
  /**
   * User email.
   */
  email: y.string().email().required(),
  /**
   * Business entity name.
   */
  entityName: y.string().required(),
  /**
   * Password.
   */
  password: y
    .string()
    .min(10)
    .hasLowercase()
    .hasUppercase()
    .hasNumber()
    .hasSpecialCharacter()
    .required(),
})

/**
 * State managed by the Step.
 */
export type SandboxSignUpFormValues = y.InferType<typeof schema>

export interface SandboxSignUpFormProps {
  /**
   * Is user being redirected to okta.
   */
  redirecting?: boolean
  /**
   * Cb that will be called once user fills in the form.
   */
  onSubmit: (values: SandboxSignUpFormValues) => Promise<void>
}

export const SandboxSignUpForm: React.FC<SandboxSignUpFormProps> = ({
  redirecting,
  onSubmit,
}) => {
  const { t } = useTranslation('onboard/sandbox-signup')
  const [Form, { formState, watch }] = useForm<SandboxSignUpFormValues>({
    schema,
  })

  const { track } = useSegment()

  const onSubmitHandler = useCallback(
    async (formValues: SandboxSignUpFormValues) => {
      track(SegmentEvents.GetStartedClicked, {
        userTraits: {
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          email: formValues.email,
        },
      })

      await onSubmit(formValues)
    },
    [onSubmit, track],
  )

  return (
    <div className="max-w-160" data-testid="landing-form">
      <div className="grid items-center justify-center text-center">
        <h2 className="mb-5 text-3xl text-black-600 font-circular-bold">
          {t('title')}
        </h2>
        <h3 className="mb-12 text-lg text-black-300">{t('subtitle')}</h3>
      </div>
      <Form
        className="flex flex-col items-center justify-center gap-y-10"
        onSubmit={onSubmitHandler}
      >
        <div className="w-full max-w-116" data-testid="form">
          <div className="grid gap-y-6">
            <div className="box-content flex flex-col gap-y-6 sm:flex-row sm:gap-4">
              <Form.Input
                className="w-full"
                data-testid="input-first-name"
                name="firstName"
                placeholder={t('form.legalFirstName')}
              />
              <Form.Input
                className="w-full"
                data-testid="input-last-name"
                name="lastName"
                placeholder={t('form.legalLastName')}
              />
            </div>
            <Form.Input
              className="w-full"
              data-testid="input-entity-name"
              name="entityName"
              placeholder={t('form.entityName')}
            />
            <Form.Input
              className="w-full"
              data-testid="input-email"
              message={
                !formState.errors.email && (
                  <PersonalEmailWarning email={watch('email')} />
                )
              }
              name="email"
              placeholder={t('form.email')}
            />
            <Form.PasswordInput
              className="w-full"
              data-testid="input-password"
              message={t`onboard/common:form.password.message`}
              name="password"
              placeholder={t`onboard/common:form.password.label`}
              hideError
            />
            <Form.SubmitButton
              disabled={
                formState.isSubmitting || formState.isValidating || redirecting
              }
              variant="primary"
            >
              {t`form.submitBtn`}
            </Form.SubmitButton>
          </div>
          <AlreadyHaveAccount className="mt-3 text-center lg:hidden" />
        </div>
        <p
          className="max-w-116 font-circular text-xs text-black-400 font-circular-regular"
          data-testid="terms"
        >
          <TransWithLink
            i18nKey="onboard/sandbox-signup:terms.tos"
            urlI18nKey="onboard/sandbox-signup:terms.tos-link"
            variant="url"
          />
          <br />
          <TransWithLink
            i18nKey="onboard/sandbox-signup:terms.privacy"
            urlI18nKey="onboard/sandbox-signup:terms.privacy-link"
            variant="url"
          />
        </p>
      </Form>
    </div>
  )
}

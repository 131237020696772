import { createContext } from 'react'

import type { FxFormValues } from './provider'
import type { ApolloError } from '@apollo/client'
import type { Currency, FxQuote, GetFxQuoteInput } from '@shared/graphql'

export interface TransferWithFx {
  /**
   * Refetch Quotes.
   */
  refetch: (input: GetFxQuoteInput) => void
  /**
   * A quote object representing the latest quote that the user has requested info for.
   */
  quote?: FxQuote
  /**
   * Whether or not the context is loading data. Should return true if fetching an FX qoute.
   */
  loading?: boolean
  /**
   * Function called to initialize the form state used in the FX forms.
   */
  initForm: (fixedCurrency: Currency) => void
  /**
   * An object representing the most up to date values within the FX form.
   */
  formValues?: FxFormValues
  /**
   * A function that can be called by a component to update the form values.
   */
  setFormValues: (values: Partial<FxFormValues>) => void
  /**
   * A string taken from the latest Circle Wallets query representing the last known balance of the relevant circle wallet (EURC or USDC).
   */
  balance?: string
  /**
   * The most recent error from fetching a quote (if any). Cleared after succesfull quote fetch.
   */
  quoteError?: ApolloError
}

export const TransferWithFxContext = createContext<TransferWithFx>({
  refetch: () => {},
  setFormValues: () => {},
  initForm: () => {},
})

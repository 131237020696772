import * as AutoTransfer from './AutoTransfer'
import * as BankAccount from './BankAccount'
import * as Common from './Common'
import * as InAppNotification from './InAppNotification'
import * as InternalAddress from './InternalAddress'
import * as InternalWallet from './InternalWallet'
import * as Payments from './Payments'
import * as TransferInstruction from './TransferInstruction'
import * as Vault from './Vault'
import * as VaultTransfer from './VaultTransfer'
import * as WalletApprovalTransactionDetail from './WalletApprovalTransactionDetail'

export const TypeGuards = {
  AutoTransfer,
  BankAccount,
  Common,
  InAppNotification,
  InternalAddress,
  InternalWallet,
  TransferInstruction,
  Payments,
  Vault,
  VaultTransfer,
  WalletApprovalTransactionDetail,
}

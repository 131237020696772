import { createContext } from 'react'

import type { ApolloError } from '@apollo/client'
import type { DefaultContext } from '@apollo/client/core/types'
import type { MfaCodes } from '@shared/apollo/links'
import type { GraphQLError } from 'graphql'

export interface MfaContext extends DefaultContext {
  /**
   * MFA Required?
   */
  mfaRequired: boolean
  /**
   * Mfa Headers.
   */
  headers?: Record<string, string>
}

export interface MfaError {
  /**
   * GraphQL errors .
   */
  error?: ApolloError
  /**
   * Fallback message if it's not an error from MFA.
   *
   * This should be the i18key key.
   */
  message?: string
}

export interface MfaSuccess {
  /**
   * Success message.
   */
  message?: string
  /**
   * Success description.
   */
  description?: string
  /**
   * Auto close duration.
   */
  duration?: number
}

export interface MfaRequest {
  /**
   * Mfa Layout Variant.
   */
  variant?: 'MultiStep' | 'FullScreen'
  /**
   * On MFA complete event.
   * Takes in a required TOTP code, as well as an optional PIN code for SCA flows.
   */
  onComplete?: (code: MfaCodes) => void
  /**
   * On MFA abort event.
   */
  onAbort?: () => void
  /**
   * Success or Error message.
   */
  message?: string
  /**
   * Auto close duration.
   */
  duration?: number
  /**
   * Error object.
   */
  error?: readonly GraphQLError[]
}

interface MfaContextProps {
  /**
   * Redirect to success screen.
   */
  success: (success?: MfaSuccess) => void
  /**
   * Redirect to error screen.
   */
  error: (error: MfaError) => void
  /**
   * MFA context for request headers.
   */
  context: (code: MfaCodes) => MfaContext
  /**
   * Open MFA modal.
   */
  open: (code: MfaRequest) => void
  /**
   * Request Context.
   */
  request: MfaRequest
}

/**
 * Create MFA context.
 */
export const MfaContext = createContext<MfaContextProps>({
  success: () => null,
  error: () => null,
  context: () => ({
    mfaRequired: false,
  }),
  open: () => null,
  request: {},
})

import { PropsErrorBoundary } from '@shared/components/errors'
import { PaymentMethodType } from '@shared/graphql'

import { Card, Wallet } from './variants'

export interface PaymentDisplayProps {
  /**
   * The variant of the Payment Method that should be rendered.
   */
  variant: PaymentMethodType
  /**
   * Card ID.
   */
  cardId?: string
}

export const PaymentDisplay: React.FC<PaymentDisplayProps> = (props) => {
  return (
    <>
      {props.variant === PaymentMethodType.card ? (
        <PropsErrorBoundary props={props}>
          {({ cardId }) => <Card cardId={cardId} />}
        </PropsErrorBoundary>
      ) : (
        <Wallet />
      )}
    </>
  )
}

import { useCallback, useEffect, useState } from 'react'

import {
  FactorActivationStatus,
  useEnrollInOktaVerifyMfaMutation,
  useOktaVerifyActivationStatusLazyQuery,
} from '@shared/graphql'

import type { OktaVerifyActivationStatusInput } from '@shared/graphql'

export interface useOktaVerifyEnrollProps {
  /**
   * Callback invoked when okta verify factor is enrolled and activated.
   */
  onActivated: () => void
  /**
   * Callback invoked when activation window expired.
   */
  onExpired: () => void
}

// Interval for okta verify activation status poll, in millisecond
const POLL_INTERVAL = 5000

export const useOktaVerifyEnroll = ({
  onActivated,
  onExpired,
}: useOktaVerifyEnrollProps) => {
  const [pollingInput, setPollingInput] =
    useState<OktaVerifyActivationStatusInput>({ factorId: '' })
  const [, { startPolling, stopPolling, error: pollError }] =
    useOktaVerifyActivationStatusLazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        input: pollingInput,
      },
      onCompleted: (data) => {
        if (
          data.oktaVerifyActivationStatus.status ===
          FactorActivationStatus.COMPLETE
        ) {
          stopPolling()

          onActivated()
        }
        if (
          data.oktaVerifyActivationStatus.status ===
          FactorActivationStatus.TIMEOUT
        ) {
          stopPolling()

          onExpired()
        }
      },
      onError: () => {
        stopPolling()
      },
    })

  const [enroll, { data, error: enrollError, loading, reset }] =
    useEnrollInOktaVerifyMfaMutation({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setPollingInput((prev) => ({
          ...prev,
          factorId: data.enrollInOktaVerifyMfa.id,
        }))
        startPolling(POLL_INTERVAL)
      },
      onError: () => {
        // Ignore uncaught error
      },
    })

  const handleOnResetAndRetry = useCallback(() => {
    reset()
    void enroll()
  }, [enroll, reset])

  useEffect(() => {
    void enroll()
  }, [enroll])

  return {
    data,
    error: enrollError ?? pollError,
    loading,
    resetAndRetry: handleOnResetAndRetry,
  }
}

import { useCallback } from 'react'

import { useModal, useModalHistory } from '@circlefin/modal-router'
import * as PaymentButtons from '@features/payments/buttons'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useVaultDeposit } from '../VaultDeposit.Context'

export interface ChooseDepositTypeQueryProps {
  /**
   * Id of the parent wallet (either parentWalletId or walletId has to be provided).
   */
  parentWalletId?: string
  /**
   * Id of the wallet.
   */
  walletId?: string
}

export const ChooseDepositType: React.FC<ChooseDepositTypeQueryProps> = ({
  walletId,
  parentWalletId,
}) => {
  const { t } = useTranslation('modals.vault')
  const { track } = useSegment()
  const { router } = useModal()
  const modalHistory = useModalHistory()
  const [, { setVaultDepositState }] = useVaultDeposit()

  const handleBlockchainChoose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      track(SegmentEvents.DepositTypeSelected, {
        props: {
          deposit_method: 'blockchain',
        },
        event,
        trackPageName: false,
      })

      setVaultDepositState({
        selectedChildWalletId: walletId,
        selectedParentWalletId: parentWalletId,
      })

      router.push({
        pathname: routes.vault.deposit.blockchain.depositDetails,
      })
    },
    [router, track, walletId, parentWalletId, setVaultDepositState],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 4}>
      <Center variant="horizontal">
        <h2 className="mb-3 text-center text-xl font-circular-bold">
          {t`deposit.chooseDepositType.title`}
        </h2>
        {/* <ButtonCard
          // TODO: Disabled for Beta, should use correct permissioned ButtonCard.
          disabled={true}
          className="w-116"
        >
          <ButtonCard.OptionItem
            icon="LibrarySolid"
            title={t('deposit.chooseDepositType.chooseRail.wire')}
            subtitle={t('deposit.chooseDepositType.chooseRail.wireDescription')}
          />
        </ButtonCard> */}
        <PaymentButtons.Cards.Blockchain
          actionType="deposit"
          className="mt-5 w-116"
          icon="LinkOutline"
          onClick={handleBlockchainChoose}
          subtitle={t(
            'deposit.chooseDepositType.chooseRail.blockchainDescription',
          )}
          title={t('deposit.chooseDepositType.chooseRail.blockchain')}
        />
      </Center>
    </FullScreen>
  )
}

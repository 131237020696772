import { getCookie, deleteCookie, setCookie } from 'cookies-next'
import { v4 as uuidv4 } from 'uuid'

import type { OptionsType } from 'cookies-next/lib/types'

const cookieKey = 'guest-user'

/**
 * Guest user definition.
 */
export interface GuestSession {
  /**
   * Guest Id.
   */
  id: string
  /**
   * User email.
   */
  email?: string
}

/**
 * Retrieves guest session from cookie.
 */
const getSession = (options?: OptionsType) => {
  const cookie = getCookie(cookieKey, options)

  if (cookie == null || typeof cookie !== 'string') {
    return null
  }

  try {
    return JSON.parse(cookie) as GuestSession
  } catch (err) {
    return null
  }
}

/**
 * Store guest session details in cookie.
 */
const setSession = (
  email: string | undefined = undefined,
  options?: OptionsType,
) => {
  const currentUser = getSession(options)

  setCookie(
    cookieKey,
    JSON.stringify({
      id: currentUser?.id ?? uuidv4(),
      email: currentUser?.email ?? email,
    }),
    options,
  )
}

/**
 * Removes guest session from cookie.
 */
function removeSession(options?: OptionsType) {
  deleteCookie(cookieKey, options)
}

/**
 * Guest session singleton.
 */
export const guestSession = {
  getSession,
  setSession,
  removeSession,
}

import { useEffect } from 'react'

import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  easing: 'linear',
  speed: 1000,
  showSpinner: false,
  template:
    '<div class="left-64 bar h-1 bg-blue-400" role="bar"><div class="peg"/></div>',
})

export interface PageLoadProgressProps {
  /**
   * Specify if the page load progress should be hidden.
   */
  hide?: boolean
}

export const PageLoadProgress: React.FC<PageLoadProgressProps> = ({ hide }) => {
  const router = useRouter()

  useEffect(() => {
    const onStart = () => NProgress.start()
    const onDone = () => NProgress.done()

    if (!hide) {
      router.events.on('routeChangeStart', onStart)
      router.events.on('routeChangeComplete', onDone)
      router.events.on('routeChangeError', onDone)
    }

    return () => {
      router.events.off('routeChangeStart', onStart)
      router.events.off('routeChangeComplete', onDone)
      router.events.off('routeChangeError', onDone)
    }
  })

  return null
}

import { useCallback, useEffect, useState } from 'react'

import { FixedBanner } from '@circlefin/components'
import { FeatureSwitch } from '@services/feature-switch'
import { useHubspot } from '@services/hubspot/react'
import { awaitingReview } from '@services/sections/lib/accountSetup'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { YesNoSelect } from '@shared/components/form'
import { useUpdateOnboardingDataMutation } from '@shared/graphql'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

import { useAccountSetup } from '../../../hooks/account-setup'
import { ProgressShell } from '../ProgressShell/ProgressShell'

import { InviteAuthRep } from './InviteAuthRep/InviteAuthRep'

export const ConfirmRole: React.FC = () => {
  const { t } = useTranslation('onboard/account-setup')
  const { track } = useSegment()
  const { data: session } = useSession()
  const { submitAuthorizedRepForm } = useHubspot()
  const router = useRouter()
  const [{ businessDetails }, { completeStep }] = useAccountSetup()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [showInviteForm, setShowInviteForm] = useState(false)
  const [updateOnboardingData, { error, reset }] =
    useUpdateOnboardingDataMutation({
      onCompleted: () => {
        // Fire event to mark user as Authorized Rep as they are confirming role
        if (session) {
          submitAuthorizedRepForm({
            email: session.user.email,
            firstName: session.user.givenName ?? '',
            lastName: session.user.familyName ?? '',
            businessName: businessDetails?.businessName ?? '',
            businessCountry: businessDetails?.businessCountry ?? '',
            businessState: businessDetails?.businessState ?? '',
            entityType: businessDetails?.entityType ?? '',
            businessNature: businessDetails?.businessNature ?? '',
            mintingVolume: businessDetails?.mintingVolume ?? '',
            activeUsers: businessDetails?.activeUsers ?? '',
          })
        }

        // If no errors occur, step is completed
        void completeStep('CONFIRM_ROLE')
      },
      onError: () => {
        setButtonsDisabled(false)

        // Error handled in GraphQLErrorBoundary
        return null
      },
    })

  useEffect(() => {
    if (!FeatureSwitch.inviteOnlyPhase2Enabled()) {
      // Prefetch next possible steps
      void router.prefetch(awaitingReview.route)
    }
  }, [router])

  const handleYesNoSelect = useCallback(
    (yes: boolean) => {
      setButtonsDisabled(true)

      track(SegmentEvents.ButtonClicked, {
        props: {
          button_label: 'Authorized representative',
          value: yes ? 'YES' : 'NO',
        },
      })

      if (yes) {
        // Execute mutation
        void updateOnboardingData({
          variables: {
            input: {
              businessInfoConfirmed: true,
            },
          },
        })
      } else {
        setShowInviteForm(true)
      }
    },
    [track, updateOnboardingData],
  )

  const handleBack = useCallback(() => {
    setShowInviteForm(false)
    setButtonsDisabled(false)

    track(SegmentEvents.ButtonClicked, {
      props: {
        button_label: 'Back to Role in Organization',
      },
    })
  }, [track])

  if (showInviteForm) {
    return (
      <ProgressShell currentStep="CONFIRM_ROLE">
        <InviteAuthRep onBack={handleBack} />
      </ProgressShell>
    )
  }

  return (
    <ProgressShell currentStep="CONFIRM_ROLE">
      <h2 className="type-h-page-sm">{t`confirmRole.title`}</h2>

      <p className="mt-1 text-neutral-subtle type-intro-sm">
        {t`confirmRole.description`}
      </p>

      <FixedBanner className="mt-8">
        <FixedBanner.Title>
          <span className="text-base">{t`confirmRole.banner.title`}</span>
        </FixedBanner.Title>
        <FixedBanner.Description>
          {t`confirmRole.banner.description`}
        </FixedBanner.Description>
      </FixedBanner>

      <GraphQLErrorBoundary error={error} retry={reset}>
        <YesNoSelect
          className="mt-6"
          direction="horizontal"
          disabled={buttonsDisabled}
          iconNo="XCircleOutline"
          iconYes="BadgeCheckOutline"
          onClick={handleYesNoSelect}
        />
      </GraphQLErrorBoundary>
    </ProgressShell>
  )
}

import { useMemo } from 'react'

import { Button } from '@circlefin/components'
import { FeatureSwitch } from '@services/feature-switch'
import { useSegment } from '@services/segment'
import getConfig from 'next/config'
import useTranslation from 'next-translate/useTranslation'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const BuyUSDC: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  // Determine which URL CTA routes to
  // TODO: Cleanup after feature switches are retired [https://circlepay.atlassian.net/browse/LEX-266]
  const ctaRoute = useMemo(() => {
    return FeatureSwitch.isLiquiditySignupEnabled()
      ? publicRuntimeConfig.CIRCLE_LIQUIDITY_SIGNUP_URL
      : t`platformSelect.options.buyUSDC.tempMarketingLink`
  }, [t])

  return (
    <div className="flex items-center rounded-md bg-info p-8">
      <div>
        <h4 className="text-neutral-strong type-h-title-md">
          {t`platformSelect.options.buyUSDC.title`}
        </h4>
        <p className="mb-4 text-neutral-subtle type-body-sm">
          {t`platformSelect.options.buyUSDC.description`}
        </p>
        <Button.Link href={ctaRoute} onClick={trackLinkClick} variant="primary">
          {t`common:learn-more`}
        </Button.Link>
      </div>
    </div>
  )
}

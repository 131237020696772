import { useCallback, useEffect } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useEnrollInTotpMfaMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'
import QRCode from 'qrcode.react'

import { useQRCodeText } from '../../hooks'

export interface SetupTotpSecretProps {
  /**
   * Callback to set factorID after enrollment.
   */
  setFactorId: (factorId: string) => void
}

export const SetupTotpSecret: React.FC<SetupTotpSecretProps> = ({
  setFactorId,
}) => {
  const { t } = useTranslation('mfa')
  const [enrollInMfa, { data, loading, error, reset }] =
    useEnrollInTotpMfaMutation({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const factorId = data?.enrollInTotpMfa.id
        if (factorId) {
          setFactorId(factorId)
        }
      },
      // To avoid uncaught errors
      onError: () => null,
    })
  const { otpUrl } = useQRCodeText({
    secret: data?.enrollInTotpMfa.sharedSecret ?? '',
  })

  useEffect(() => {
    // Call once when screen renders to show the secret
    void enrollInMfa()
  }, [enrollInMfa])

  const retry = useCallback(() => {
    reset()
    void enrollInMfa()
  }, [enrollInMfa, reset])

  return (
    <GraphQLErrorBoundary error={error} retry={retry}>
      {/* MFA Enrollment - QR code and shared secret */}
      <div
        aria-live="polite"
        className="flex w-full items-end gap-x-4"
        role="region"
      >
        <SkeletonBox className="size-28" loading={loading || !otpUrl}>
          <QRCode
            height="112"
            renderAs="svg"
            value={otpUrl ?? ''}
            width="112"
          />
        </SkeletonBox>

        <p className="text-neutral-subtle type-body-xs" data-testid="cant-scan">
          <SkeletonBox className="h-12 w-48" loading={loading || !data}>
            <span className="block text-neutral type-body-xs-bold">{t`setupTotp.secret.issues`}</span>
            <span className="block text-neutral type-body-xs">
              {t`setupTotp.secret.code`}
            </span>
            <span
              className="text-neutral type-body-xs"
              data-testid="shared-secret"
            >
              {data?.enrollInTotpMfa.sharedSecret}
            </span>
          </SkeletonBox>
        </p>
      </div>
    </GraphQLErrorBoundary>
  )
}

const hoistNonReactStatics = require('hoist-non-react-statics')

module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': [
      'common',
      'states',
      'countries',
      'forms',
      'home',
      'layout',
      'mfa',
      'onboard/common',
      'payment.linkBankAccount',
      'payments',
      'custody/common',
      // TODO: Remove vaults dependency once all references are removed
      'vaults',
      'l3Onboarding',
      'inAppNotification',
    ],
    'rgx:^/custody/*': ['walletApprovalPolicy'],
    'rgx:^/custody/vaults': ['custody/vault'],
    'rgx:^/express/*': ['express/page'],
    'rgx:^/vaults/*': ['vaults', 'walletApprovalPolicy'],
    'rgx:^/settings/*': ['settings', 'walletApprovalPolicy'],
    'rgx:^/developer/*': ['developer'],
    'rgx:^/settings/verite/*': ['verite'],
    'rgx:^/periodic-review': ['periodicReview'],
    'rgx:^/get-started/*': ['onboard/get-started', 'onboard/common'],
    'rgx:^/circle-mint-invite/*': ['onboard/get-started', 'onboard/common'],
    'rgx:^/account-setup/*': ['onboard/account-setup'],
    'rgx:^/signup/sandbox': ['onboard/sandbox-signup'],
    'rgx:^/signup/rolr/*': ['onboard/rolr-signup', 'onboard/common'],
    'rgx:^/payments/*': ['walletApprovalPolicy'],
    'rgx:^/support': ['support'],
    'rgx:^/l3Onboarding/*': ['l3Onboarding'],

    // Address Book Feature
    'rgx:^/address-book/*': [
      'address-book/components',
      'address-book/containers',
      'address-book/filters',
    ],

    // Platform Chargebacks Feature
    'rgx:^/platform/chargebacks/*': [
      'platform/reporting/containers',
      'platform/reversals/containers',
      'platform/chargeback/containers',
      'platform/chargeback/filters',
      'platform/chargeback/pages',
    ],

    // Platform Checkout Feature
    'rgx:^/platform/checkout/*': [
      'platform/reporting/containers',
      'platform/checkout/containers',
      'platform/checkout/filters',
      'platform/checkout/pages',
    ],

    // Platform Onboarding Feature
    'rgx:^/platform/*': ['platform/onboarding/containers'],

    // Platform Payments Feature
    'rgx:^/platform/payments/*': [
      'platform/reporting/containers',
      'platform/payment/containers',
      'platform/payment/filters',
      'platform/payment/pages',
    ],

    // Platform Payment-Intents Feature
    'rgx:^/platform/payment-intents/*': [
      'platform/reporting/containers',
      'platform/payment-intents/common',
      'platform/payment-intents/containers',
      'platform/payment-intents/pages',
    ],

    // Platform Payouts Feature
    'rgx:^/platform/payouts/*': [
      'platform/reporting/containers',
      'platform/payouts/common',
      'platform/payouts/containers',
      'platform/payouts/filters',
      'platform/payouts/pages',
    ],

    // Platform Refunds Feature
    'rgx:^/platform/refunds/*': [
      'platform/refunds/containers',
      'platform/refunds/pages',
    ],

    // Platform Reporting Feature
    'rgx:^/platform/reporting/*': [
      'platform/reporting/containers',
      'platform/reporting/pages',
    ],

    // Platform Reversals Feature
    'rgx:^/platform/reversals/*': [
      'platform/reversals/common',
      'platform/reversals/containers',
      'platform/reversals/pages',
    ],

    // Platform Settlements Feature
    'rgx:^/platform/settlements/*': [
      'platform/reporting/containers',
      'platform/settlements/containers',
      'platform/settlements/pages',
    ],

    // Platform Transfers Feature
    'rgx:^/platform/transfers/*': [
      'platform/transfers/common',
      'platform/transfers/containers',
      'platform/transfers/pages',
    ],
  },
  staticsHoc: hoistNonReactStatics,
}

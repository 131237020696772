import { MultiStep } from '@modals/layout'
import useTranslation from 'next-translate/useTranslation'

import { DetailsForm } from './Details.Form'

export interface DetailsFormProps {
  /**
   * Optional Payment Intent ID as called in modal.
   */
  paymentIntentId?: string
}

export const Details: React.FC<DetailsFormProps> = ({ paymentIntentId }) => {
  const { t } = useTranslation('platform/modals')
  return (
    <>
      {paymentIntentId != null && (
        <MultiStep
          subtitle={t('common:step', { start: 1, end: 2 })}
          title={t`payment-intent.refund.title`}
        >
          <DetailsForm paymentIntentId={paymentIntentId} />
        </MultiStep>
      )}
    </>
  )
}

import { useCallback, useState } from 'react'

import { CodeInput, SkeletonBox, Statement } from '@circlefin/components'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useActivateMfaMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface ActivateCodeInputProps {
  /**
   * ID of factor to be activated.
   */
  factorId?: string
  /**
   * Callback after successfully activating 2FA.
   */
  onActivation?: () => void | Promise<void>
}

export const ActivateCodeInput: React.FC<ActivateCodeInputProps> = ({
  factorId,
  onActivation,
}) => {
  const { t } = useTranslation('mfa')
  const { track } = useSegment()

  const [activateMfa, { error, reset }] = useActivateMfaMutation()

  const [loading, setLoading] = useState(false)

  const submit = useCallback(
    async (code: string) => {
      if (factorId) {
        setLoading(true)

        try {
          await activateMfa({
            variables: {
              factorId,
              code,
            },
          })

          await onActivation?.()
        } catch {
          // Error handled in GraphQLErrorBoundary
        } finally {
          setLoading(false)
        }
      }
    },
    [activateMfa, factorId, onActivation],
  )

  const handleComplete = useCallback(
    (code: string) => {
      track(SegmentEvents.FormSubmitted)

      void submit(code)
    },
    [submit, track],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={reset}>
      {/* Code input */}
      {loading ? (
        <div className="mt-4 flex flex-col justify-center gap-y-6">
          <Statement
            iconName="CircleSpinnerSolid"
            padded={false}
            title={t`loading`}
            loop
          />
        </div>
      ) : (
        // Show code input only when factorId is available.
        <SkeletonBox className="mt-4 h-12 w-64" loading={!factorId}>
          <CodeInput
            allowedCharacters="numeric"
            ariaLabel="disabled"
            className="mt-4"
            length={6}
            onComplete={handleComplete}
          />
        </SkeletonBox>
      )}
    </GraphQLErrorBoundary>
  )
}

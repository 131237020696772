import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { Link, NoLinked, SetNickname } from '@features/bank-account/modals'
import { bankAccount } from '@services/sections/modal/bank-account/bank-account'

/**
 * Bank Account Router.
 */
export const BankAccountRouter: React.FC = () => {
  return (
    <>
      <ModalRoute
        content={<NoLinked.WireTransfer />}
        path={bankAccount.noLinked.wireTransfer}
      />
      <ModalRoute
        content={<NoLinked.Withdraw />}
        path={bankAccount.noLinked.withdraw}
      />
      <ModalRoute content={<SetNickname />} path={bankAccount.setNickname} />

      {/* Link Bank Account */}
      <ModalSwitch path={bankAccount.link.switch}>
        <Link.Provider>
          <ModalRoute
            content={<Link.Address />}
            path={bankAccount.link.address}
          />
          <ModalRoute
            content={<Link.Information />}
            path={bankAccount.link.information}
          />
          <ModalRoute
            content={<Link.Location />}
            path={bankAccount.link.location}
          />
          <ModalRoute content={<Link.Type />} path={bankAccount.link.type} />
          <ModalRoute
            content={<Link.AccountInformation />}
            path={bankAccount.link.accountInformation}
          />
          <ModalRoute
            content={<Link.AccountAddress />}
            path={bankAccount.link.accountAddress}
          />
        </Link.Provider>
      </ModalSwitch>
    </>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import useTranslation from 'next-translate/useTranslation'

export interface ArchiveProps {
  /**
   * The id of the vault the wallet belongs to.
   */
  vaultId?: string
  /**
   * The id of the wallet that should be archived.
   */
  vaultWalletId?: string
}

export const Archive: React.FC<ArchiveProps> = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const handleCloseModal = useCallback(() => {
    modal.close()
  }, [modal])

  const handleArchiveWallet = useCallback(() => {
    // Todo: Connect to archive mutation when endpoint becomes available
  }, [])

  return (
    <BaseTitle title={t('vaultWalletDetails.archive.title')}>
      <p className="text-center">{t`vaultWalletDetails.archive.description`}</p>

      <Modal.Footer variant="stretch-equal">
        <Button onClick={handleCloseModal} variant="secondary">
          {t`common:cancel`}
        </Button>
        <Button onClick={handleArchiveWallet} variant="primary">
          {t`vaultWalletDetails.archive.action`}
        </Button>
      </Modal.Footer>
    </BaseTitle>
  )
}

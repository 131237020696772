import { useCallback, useEffect } from 'react'

import { Modal } from '@circlefin/components'
import { ModalRouterProvider, useModal } from '@circlefin/modal-router'
import { CurrentCurrencyProvider } from '@features/locales/hooks/currency/provider'
import { useSegment, SegmentEvents } from '@services/segment'
import { useRouter } from 'next/router'
import DynamicNamespaces from 'next-translate/DynamicNamespaces'

import { AddressBookRouter } from './address-book'
import { BankAccountRouter } from './bank-account'
import { BillingRouter } from './billings'
import { BlockchainRouter } from './blockchain'
import { DelayedWithdrawalsRouter } from './delayed-withdrawals'
import { DeveloperRouter } from './developer'
import { ExpressRouter } from './express'
import { MfaRouter } from './mfa'
import { OnboardingRouter } from './onboarding'
import { PlatformRouter } from './platform'
import { PromotionsRouter } from './promotions'
import { ReportRouter } from './report'
import { SecurityRouter } from './security'
import { TransferRouter } from './transfer'
import { UsersRouter } from './users'
import { VaultRouter } from './vault'
import { VeriteRouter } from './verite'
import { WalletsRouter } from './wallets'

import type { ModalEventCallback } from '@circlefin/modal-router'

/**
 * Modal Routes.
 */
export const ModalRouter: React.FC = () => {
  const pageRouter = useRouter()
  const modalRouter = useModal()
  const segment = useSegment()

  useEffect(() => {
    // Clear modal history to close the modal
    // on page router navigation.
    const onRouteChange = () => {
      // Skip modal close on keep page route open flag.
      if (modalRouter.history.route?.options?.keepOpenOnPageRoute !== true) {
        modalRouter.router.clear()
      }
    }

    // Create route change event
    pageRouter.events.on('routeChangeStart', onRouteChange)

    return () => {
      // Remove route change event
      pageRouter.events.off('routeChangeStart', onRouteChange)
    }
  }, [modalRouter, pageRouter.events])

  /**
   * On Close Start.
   * Triggered when a modal is dismissed without invoking modal.close().
   */
  const handleCloseStart: ModalEventCallback = useCallback(
    (event) => {
      segment.track(SegmentEvents.CancelClicked, { event })
    },
    [segment],
  )

  /**
   * Triggered when user goes back.
   */
  const handleBack: ModalEventCallback = useCallback(
    (event) => {
      segment.track(SegmentEvents.BackClicked, { event })
    },
    [segment],
  )

  return (
    <ModalRouterProvider onBack={handleBack} onCloseStart={handleCloseStart}>
      <DynamicNamespaces
        namespaces={[
          'address-book/modals',
          'platform/modals',
          'modals/settings/billing',
          'express/modal',
          'modals/settings/fraud-management',
          'modals/settings/security',
          'modals/settings/users',
          'modals.bankAccount',
          'modals.blockchain',
          'modals.common',
          'modals.developer',
          'modals.onboarding',
          'modals.payout',
          'modals.transfer',
          'modals.vault',
          'modals.verite',
          'modals.walletApprovalPolicy',
        ]}
      >
        {/* Modal Options */}
        <Modal.Coolwhip />

        {/* Routers */}
        <CurrentCurrencyProvider>
          <AddressBookRouter />
          <BankAccountRouter />
          <BillingRouter />
          <BlockchainRouter />
          <DelayedWithdrawalsRouter />
          <DeveloperRouter />
          <ExpressRouter />
          <MfaRouter />
          <OnboardingRouter />
          <PlatformRouter />
          <PromotionsRouter />
          <ReportRouter />
          <TransferRouter />
          <UsersRouter />
          <SecurityRouter />
          <VaultRouter />
          <VeriteRouter />
          <WalletsRouter />
        </CurrentCurrencyProvider>
      </DynamicNamespaces>
    </ModalRouterProvider>
  )
}

import { useEffect, useState } from 'react'

import { useModal } from '@circlefin/modal-router'

import { WithdrawContext, defaultState } from './context'

import type { WithdrawState } from './context'

/**
 * Withdraw Provider props.
 */
interface WithdrawProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<WithdrawState>
}

export const WithdrawProvider: React.FC<WithdrawProviderProps> = ({
  children,
  initValues = {},
}) => {
  const [values, setValues] = useState<WithdrawState>({
    ...defaultState,
    ...initValues,
  })

  const modal = useModal()

  useEffect(() => {
    const resetState = () => {
      setValues(defaultState)
    }

    // Subscribe to modal close event
    modal.events.on('onCloseEnd', resetState)

    return () => {
      // Unsubscribe to modal close event
      modal.events.off('onCloseEnd', resetState)
    }
  })

  const onFormChange = (values: Partial<WithdrawState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }

  return (
    <WithdrawContext.Provider value={[values, { onFormChange }]}>
      {children}
    </WithdrawContext.Provider>
  )
}

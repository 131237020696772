import { ModalRoute } from '@circlefin/modal-router'
import {
  Code,
  Error,
  Success,
  SetupTotp,
  SetupOktaVerify,
  UpdatePinCode,
  ResetPinCode,
} from '@features/mfa/modals'
import { mfa } from '@services/sections/modal/mfa/mfa'

/**
 * MFA Router.
 */
export const MfaRouter: React.FC = () => {
  return (
    <>
      <ModalRoute content={<Code />} path={mfa.code} />
      <ModalRoute content={<Error />} path={mfa.error} />
      <ModalRoute content={<Success />} path={mfa.success} />
      <ModalRoute content={<SetupTotp />} path={mfa.setupTotp} />
      <ModalRoute content={<SetupOktaVerify />} path={mfa.setupOktaVerify} />
      <ModalRoute content={<UpdatePinCode />} path={mfa.updatePinCode} />
      <ModalRoute content={<ResetPinCode />} path={mfa.resetPinCode} />
    </>
  )
}

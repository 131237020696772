import { useCallback } from 'react'

import { Activate } from '@features/mfa/containers'

import { useAccountSetup } from '../../../hooks/account-setup'
import { ProgressShell } from '../ProgressShell/ProgressShell'

export const SecurityActivate: React.FC = () => {
  const [, { completeStep }] = useAccountSetup()

  const onActivation = useCallback(
    async () => await completeStep('SECURITY_ACTIVATE'),
    [completeStep],
  )

  return (
    <ProgressShell currentStep="SECURITY_ACTIVATE">
      <Activate onActivation={onActivation} />
    </ProgressShell>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { CollapsibleBadge } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../../hooks/permission'

export interface ActivationRequiredProps {
  /**
   * Wallet that needs to be activated.
   */
  walletId: string
  /**
   * Id of the parent wallet.
   */
  parentWalletId: string
  /**
   * Blockchain the activation will take place on.
   */
  blockchain: string
}
export const ActivationRequired: React.FC<ActivationRequiredProps> = ({
  blockchain,
  walletId,
  parentWalletId,
}) => {
  const { t } = useTranslation('vaults')
  const { router } = useModal()
  const { track } = useSegment()

  const { vaultMetadata } = useVaultPermission()

  const handleConfirmWalletOptIn = useCallback(() => {
    track(SegmentEvents.ActivateVaultWalletClicked)

    router.push({
      pathname: routes.vault.confirmWalletActivationFee,
      query: {
        vaultId: vaultMetadata?.id,
        parentWalletId,
        walletId,
      },
    })
  }, [router, walletId, parentWalletId, vaultMetadata?.id, track])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider.
   */
  if (!vaultMetadata) return null

  return (
    <CollapsibleBadge
      collapsedText={t`badges.wallet.activationRequired.collapsed`}
      variant="info"
    >
      <span>
        {t('badges.wallet.activationRequired.notCollapsed', {
          blockchain: t<string>(`payments:chainNames.${blockchain ?? ''}`),
        })}
        {
          /**
           * Only users that are allowed to operate on the vault the wallet belongs to
           * are allowed to confirm an account.
           */
          vaultMetadata.isOperator && (
            <button
              className="ml-0.5 text-blue-500 underline font-circular-regular"
              onClick={handleConfirmWalletOptIn}
            >{t`badges.wallet.activationRequired.confirm`}</button>
          )
        }
      </span>
    </CollapsibleBadge>
  )
}

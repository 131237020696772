export interface HeadingProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Heading: React.FC<HeadingProps> = ({ children }) => (
  <h1 className="mt-2 text-3xl leading-tight tracking-tight text-black-600 font-circular-bold">
    {children}
  </h1>
)

Heading.displayName = 'NotificationScreen.Heading'

import { DescriptionList } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { UserPermissionRoleText } from '../../../../../components'
import { WalletApprovalPolicyStep } from '../../../../../hooks/approval-policy/context'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const UserPermissions: React.FC = () => {
  const { t } = useTranslation('walletApprovalPolicy')

  const { condensed, userLimits = [] } = usePolicySummaryContext()

  return (
    <>
      <Label className="h-full">
        {t('policy.summary.labels.userPermissions')}
      </Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="user-permissions"
      >
        <List>
          {userLimits.map(({ user, permissions }, index) => (
            <List.Item key={user.id}>
              <List.Item.Title
                showEdit={index === 0}
                stepName={WalletApprovalPolicyStep.PERMISSIONS}
              >
                {user.name}
              </List.Item.Title>
              <List.Item.Subtitle>
                <div className="flex items-center gap-x-2">
                  <span>
                    <UserPermissionRoleText permissions={permissions} />
                  </span>
                </div>
              </List.Item.Subtitle>
            </List.Item>
          ))}
        </List>
      </Description>
    </>
  )
}

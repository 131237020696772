import { Button } from '@circlefin/components'
import { signOutSection } from '@services/sections/lib/home'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

export interface HeaderPublicLogOutProps {
  /**
   * Additional classes to apply the to the layout wrapper.
   */
  className?: string
}

export const HeaderPublicLogOut: React.FC<HeaderPublicLogOutProps> = ({
  className,
}) => {
  const { data: session } = useSession()
  const { t } = useTranslation()

  /**
   * If user is currently logged out, hide log out.
   */
  if (!session?.user) {
    return null
  }

  return (
    <span
      className={classNames(
        'flex sm:block sm:top-7 col-start-3 text-right justify-self-end',
        className,
      )}
      data-testid="log-out-container"
    >
      <Button.Link
        href={signOutSection.route}
        size="sm"
        type="button"
        variant="text"
      >
        {t('common:log-out')}
      </Button.Link>
    </span>
  )
}

HeaderPublicLogOut.displayName = 'HeaderPublic.LogOut'

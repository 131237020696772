import { useCallback } from 'react'

import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import { BankAccountType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../Link.Context'

import { CustomersBank, CrossRiver, IbanForm, NonIban, WireForm } from './Forms'
import { InformationVariants } from './Information.types'

export interface InformationProps {
  /**
   * Default Bank Information Variant.
   */
  defaultVariant?: InformationVariants
}

export const Information: React.FC<InformationProps> = ({
  defaultVariant = InformationVariants.WIRE,
}) => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ steps, variant }] = useLinkBankAccount()

  const FormVariant: React.FC = useCallback(() => {
    switch (variant ?? defaultVariant) {
      case InformationVariants.IBAN:
        return <IbanForm />

      case InformationVariants.NON_IBAN:
        return <NonIban.DefaultForm />

      case InformationVariants.NON_IBAN_CANADA:
        return <NonIban.CanadaForm />

      case InformationVariants.CBIT:
        return <CustomersBank />

      case InformationVariants.CUBI_WIRE:
        return <CustomersBank type={BankAccountType.wire} />

      case InformationVariants.XPAY:
        return <CrossRiver />

      case InformationVariants.CRB_WIRE:
        return <CrossRiver type={BankAccountType.wire} />

      case InformationVariants.WIRE:
        return <WireForm />

      default:
        return <WireForm />
    }
  }, [defaultVariant, variant])

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-8 text-center" variant="horizontal">
        <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
          {t`link.information.title`}
        </h2>
      </Center>

      <Center variant="horizontal">
        <FormVariant />
      </Center>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  WalletApprovalPolicyDocument,
  useCancelWalletApprovalSubmittedPolicyMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface CancelApprovalRequestQueryProps {
  /**
   * Policy id.
   */
  policyId?: string
  /**
   * Currency.
   */
  currency?: Currency
}

export const CancelApprovalRequest: React.FC<CancelApprovalRequestQueryProps> =
  (props) => {
    const { t } = useTranslation('modals.walletApprovalPolicy')
    const { close } = useModal()

    const handleCancel = useCallback(() => {
      close({ context: 'onDismiss' })
    }, [close])

    const [cancelPendingPolicy, { loading, error, called, reset }] =
      useCancelWalletApprovalSubmittedPolicyMutation()

    const handleCancelPolicy = useCallback(
      (policyId: string, currency: Currency) => () => {
        void cancelPendingPolicy({
          variables: {
            input: {
              policyId,
              currency,
            },
          },
          onCompleted: handleCancel,
          refetchQueries: [
            {
              query: WalletApprovalPolicyDocument,
              variables: {
                currency,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
      },
      [cancelPendingPolicy, handleCancel],
    )

    return (
      <PropsErrorBoundary props={props} variant="page">
        {({ policyId, currency }) => (
          <>
            <Modal.Header title={t`cancelApprovalRequest.title`} />
            <GraphQLErrorBoundary error={error} retry={reset} variant="page">
              <Modal.Body className="text-center">{t`cancelApprovalRequest.description`}</Modal.Body>
              <Modal.Footer variant="stretch-equal">
                <Button
                  disabled={loading || (called && !error)}
                  onClick={handleCancel}
                  variant="secondary"
                >{t`common:cancel`}</Button>
                <Button
                  disabled={called && !error}
                  loading={loading}
                  onClick={handleCancelPolicy(policyId, currency)}
                  variant="primary"
                >{t`cancelApprovalRequest.action`}</Button>
              </Modal.Footer>
            </GraphQLErrorBoundary>
          </>
        )}
      </PropsErrorBoundary>
    )
  }

import { useUnreadInAppNotificationsCountQuery } from '@shared/graphql'
import classNames from 'classnames'

const MAX_COUNT = 99

export interface UnreadInAppNotificationCountProps {
  /**
   * Custom style.
   */
  className?: string
}

export const UnreadInAppNotificationCount: React.FC<UnreadInAppNotificationCountProps> =
  ({ className }) => {
    const { data: { unreadInAppNotificationsCount } = {} } =
      useUnreadInAppNotificationsCountQuery()

    const showCount =
      unreadInAppNotificationsCount != null && unreadInAppNotificationsCount > 0
    const isCapped = (unreadInAppNotificationsCount ?? 0) > MAX_COUNT

    if (!showCount) {
      return null
    }

    return (
      <span
        className={classNames(
          'inline-flex h-5 items-center justify-center rounded-full bg-bold-error text-invert type-body-xs-bold',
          {
            'w-5': unreadInAppNotificationsCount < 10,
            'w-6':
              unreadInAppNotificationsCount >= 10 &&
              unreadInAppNotificationsCount <= MAX_COUNT,
            'w-8': isCapped,
          },
          className,
        )}
        data-testid="unread-notifications-count"
      >
        {isCapped ? `${MAX_COUNT}+` : unreadInAppNotificationsCount}
      </span>
    )
  }

UnreadInAppNotificationCount.displayName = 'UnreadInAppNotificationCount'

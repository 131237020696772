/**
 * Total Balance:
 * NOTE: doesn't help to poll the `/balances` endpoint any more frequently than
 * every minute. This endpoint has cache of 1 minute in TX-CORE. Polling every
 * 65 seconds ensures we have a fresh balance and not a cached value.
 */
export const TOTAL_BALANCE_POLL_INTERVAL = 65000 // 1min 5sec

/**
 * Balance.
 */
export const BALANCE_POLL_INTERVAL = 10000 // 10sec

/**
 * Payment Activity Table.
 */
export const PAYMENT_ACTIVITY_POLL_INTERVAL = 10000 // 10sec

/**
 * Blockchain Address Table.
 */
export const BLOCKCHAIN_ADDRESS_POLL_INTERVAL = 10000 // 10sec

/**
 * Billing - Invoices.
 */
export const BILLING_INVOICES_POLL_INTERVAL = 60000 // 1min

/**
 * Settlement Table.
 */
export const SETTLEMENT_POLL_INTERVAL = 60000 // 1min

/**
 * Payment Intent Table.
 */
export const PAYMENT_INTENT_POLL_INTERVAL = 60000 // 1min

/**
 * IP Address Allowlisting.
 */
export const IP_ADDRESS_ALLOWLISTING_POLL_INTERVAL = 60000 // 1min

/**
 * Delayed Withdrawals.
 */
export const DELAYED_WITHDRAWALS_SETTINGS_POLL_INTERVAL = 60000 // 1min

/**
 * Admin Approvals.
 */
export const ADMIN_APPROVALS_SETTINGS_POLL_INTERVAL = 60000 // 1min

/**
 * Vault Device Registration Status.
 */
export const VAULT_DEVICE_REGISTRATION_STATUS_POLL_INTERVAL = 5000 // 5sec

/**
 * Two Factor Token Status.
 */
export const TWO_FACTOR_TOKEN_STATUS_POLL_INTERVAL = 5000 // 5sec

/**
 * Vault Details.
 */
export const VAULT_DETAILS_POLL_INTERVAL = 5000 // 5sec

/**
 * Vault Balance.
 */
export const VAULT_BALANCE_POLL_INTERVAL = 5000 // 5sec

/**
 * Vault Wallet Balance.
 */
export const VAULT_WALLET_BALANCE_POLL_INTERVAL = 5000 // 5sec

/**
 * Vault Transactions.
 */
export const VAULT_TRANSACTIONS_POLL_INTERVAL = 5000 // 5sec

/**
 * Vault WalletConnect Activities.
 */
export const VAULT_WALLET_CONNECT_ACTIVITIES_POLL_INTERVAL = 5000 // 5sec

/**
 * Developer Subscriptions List.
 */
export const DEVELOPER_SUBSCRIPTIONS_LIST_POLL_INTERVAL = 5000

/**
 * Circle wallets list.
 */
export const CIRCLE_WALLETS_POLL_INTERVAL = 10000 // 10sec

/**
 * Circle wallets FTU.
 */
export const CIRCLE_WALLETS_FTU_POLL_INTERVAL = 10000 // 10sec

/**
 * API Logs Table.
 */
export const API_LOGS_POLL_INTERVAL = 60000 // 1min

/**
 * Circe Wallet Details.
 */
export const CIRCLE_WALLET_DETAILS_POLL_INTERVAL = 60000 // 1min

/**
 * Users list.
 */
export const USERS_POLL_INTERVAL = 60000 // 1min

import { useMemo } from 'react'

import { TruncatedText, DescriptionList } from '@circlefin/components'
import { TypeGuards } from '@services/type-guards'
import { BlockchainRecipientAddressOwner } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../Create'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const OutgoingAddresses: React.FC = () => {
  const { t } = useTranslation('vaults')

  const { condensed, outgoingAddresses } = usePolicySummaryContext()

  const { externalThirdPartyAddresses, externalBusinessAddresses, internal } =
    useMemo(() => {
      const { external = [], internal = [] } = outgoingAddresses ?? {}

      return {
        externalThirdPartyAddresses: external.filter(
          (address) =>
            address.owner ===
            BlockchainRecipientAddressOwner.non_customer_entity,
        ),
        externalBusinessAddresses: external.filter(
          (address) =>
            address.owner === BlockchainRecipientAddressOwner.customer,
        ),
        internal,
      }
    }, [outgoingAddresses])

  return (
    <>
      <Label className="h-full">
        {t('vault.policy.summary.labels.outgoingAddresses')}
      </Label>
      <Description
        className={classNames('flex flex-col', {
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="outgoing-addresses"
      >
        {externalThirdPartyAddresses.length > 0 && (
          <List>
            {[
              <List.Item key="externalThirdPartyAddresses">
                <List.Item.Title
                  className="font-circular-bold"
                  stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                  showEdit
                >
                  {t('vault.policy.summary.externalThirdParty.label')}
                </List.Item.Title>
                <List.Item.Subtitle>
                  {t(`vault.policy.summary.externalThirdParty.description`)}
                </List.Item.Subtitle>
              </List.Item>,
              ...externalThirdPartyAddresses.map((address) => (
                <List.Item key={address.id}>
                  <List.Item.Title
                    stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                  >
                    {address.description}
                  </List.Item.Title>
                  <List.Item.Subtitle>
                    {t('vault.policy.summary.blockchainWallet', {
                      chain: t<string>(
                        `payments:chainNames.${address.chain}`,
                        undefined,
                        {
                          fallback: address.chain,
                        },
                      ),
                    })}
                    <TruncatedText>{address.address}</TruncatedText>
                  </List.Item.Subtitle>
                </List.Item>
              )),
            ]}
          </List>
        )}
        {externalBusinessAddresses.length > 0 && (
          <>
            {externalThirdPartyAddresses.length > 0 && (
              <hr className="mb-4 h-px border-black-100" />
            )}
            <List>
              {[
                <List.Item key="externalBusinessAddresses">
                  <List.Item.Title
                    className="font-circular-bold"
                    showEdit={externalThirdPartyAddresses.length === 0}
                    stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                  >
                    {t('vault.policy.summary.externalBusiness.label')}
                  </List.Item.Title>
                  <List.Item.Subtitle>
                    {t(`vault.policy.summary.externalBusiness.description`)}
                  </List.Item.Subtitle>
                </List.Item>,
                ...externalBusinessAddresses.map((address) => (
                  <List.Item key={address.id}>
                    <List.Item.Title
                      stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                    >
                      {address.description}
                    </List.Item.Title>
                    <List.Item.Subtitle>
                      {t('vault.policy.summary.blockchainWallet', {
                        chain: t<string>(
                          `payments:chainNames.${address.chain}`,
                          undefined,
                          {
                            fallback: address.chain,
                          },
                        ),
                      })}
                      <TruncatedText>{address.address}</TruncatedText>
                    </List.Item.Subtitle>
                  </List.Item>
                )),
              ]}
            </List>
          </>
        )}
        {internal.length > 0 && (
          <>
            {(externalThirdPartyAddresses.length > 0 ||
              externalBusinessAddresses.length > 0) && (
              <hr className="mb-4 h-px border-black-100" />
            )}
            <List>
              {[
                <List.Item key="internal">
                  <List.Item.Title
                    className="font-circular-bold"
                    showEdit={
                      externalThirdPartyAddresses.length === 0 &&
                      externalBusinessAddresses.length === 0
                    }
                    stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                  >
                    {t('vault.policy.summary.circleAddresses.label')}
                  </List.Item.Title>
                  <List.Item.Subtitle>
                    {t(`vault.policy.summary.circleAddresses.description`)}
                  </List.Item.Subtitle>
                </List.Item>,
                ...internal.map((address) => (
                  <List.Item key={address.id}>
                    <List.Item.Title
                      stepName={CreateVaultNavigationStep.OUTGOING_ADDRESS}
                    >
                      {TypeGuards.InternalAddress.isDirect(address)
                        ? address.name
                        : t(`createVault.outgoingAddressesTable.mainWallet`)}
                    </List.Item.Title>
                    <List.Item.Subtitle>
                      {t('vault.policy.summary.blockchainWallet', {
                        chain: t<string>(
                          `payments:chainNames.${address.blockchain}`,
                          undefined,
                          {
                            fallback: address.blockchain,
                          },
                        ),
                      })}
                      {` - ${
                        TypeGuards.InternalAddress.isDirect(address)
                          ? address.asset.symbol
                          : address.currency
                      }`}
                      {address.address && (
                        <TruncatedText>{address.address}</TruncatedText>
                      )}
                    </List.Item.Subtitle>
                  </List.Item>
                )),
              ]}
            </List>
          </>
        )}
      </Description>
    </>
  )
}

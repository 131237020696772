import { ModalRoute } from '@circlefin/modal-router'
import { APIKey, Subscriptions } from '@features/developer/modals'
import { developer } from '@services/sections/modal/developer/developer'

/**
 * Developer Modal Router.
 */
export const DeveloperRouter: React.FC = () => {
  return (
    <>
      {/* Subscriptions */}
      <ModalRoute
        content={<Subscriptions.Create />}
        path={developer.subscriptions.addNewSubscription}
      />
      <ModalRoute
        content={<Subscriptions.Success />}
        path={developer.subscriptions.addNewSubscriptionSuccess}
      />
      <ModalRoute
        content={<Subscriptions.RemoveConfirm />}
        path={developer.subscriptions.removeSubscription}
      />

      {/* API Keys */}
      <ModalRoute content={<APIKey.Revoke />} path={developer.apiKeys.revoke} />
      <ModalRoute
        content={<APIKey.Success />}
        path={developer.apiKeys.success}
      />
    </>
  )
}

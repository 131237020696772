import { useMemo } from 'react'

import { Icon } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { CountriesQuery, CountryCode } from '@shared/graphql'

export interface CountryWarningMessageProps {
  /**
   * Selected country code.
   */
  countryCode?: CountryCode
  /**
   * Countries query data.
   */
  countriesQueryData?: CountriesQuery
}

export const CountryWarningMessage: React.FC<CountryWarningMessageProps> = ({
  countryCode,
  countriesQueryData,
}) => {
  const { t } = useTranslation('onboard/common')

  /**
   * Boolean to determine if we should show Country "Wallet only" warning.
   */
  const showCountryWarning = useMemo(() => {
    const bankLinkingAllowed = Boolean(
      countriesQueryData?.countries?.list?.some(
        (country) => country.code === countryCode && country.link.locatedIn,
      ),
    )

    return !bankLinkingAllowed
  }, [countriesQueryData?.countries?.list, countryCode])

  return showCountryWarning ? (
    <div className="flex gap-x-1">
      <span className="mt-px">
        <Icon className="mt-0.5" name="ExclamationCircleOutline" />
      </span>
      <span>{t`form.country-wallet-message`}</span>
    </div>
  ) : (
    <div className="-mt-1" />
  )
}

CountryWarningMessage.displayName = 'CountryWarningMessage'

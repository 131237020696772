import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { ModalBackButton } from '@containers/layout'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { Currency, WalletApprovalProposalApprovalMethod } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { WalletForms } from '../../../../forms'
import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../layout'

const schema = y.object({
  /**
   * Approval method for admin to approve the requests.
   */
  proposalApprovalMethod: WalletForms.Dropdown.ApprovalMethodsSchema,
})

export const Settings: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const { router } = useModal()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()
  const [{ settings }, { setPolicySettings }] = useWalletApprovalPolicy()

  const [Form, { watch }] = useForm<y.InferType<typeof schema>>({
    schema,
    defaultValues: {
      proposalApprovalMethod:
        settings?.proposalApprovalMethod ??
        WalletApprovalProposalApprovalMethod.MOBILE_APP,
    },
  })

  const proposalApprovalMethod = watch('proposalApprovalMethod')

  const handleOnClick = useCallback(() => {
    setPolicySettings({
      proposalApprovalMethod: proposalApprovalMethod,
    })
    void router.push(routes.walletApprovalPolicy.createPolicy.review)
  }, [proposalApprovalMethod, router, setPolicySettings])

  return (
    <WalletApprovalPolicyLayout currentStep={WalletApprovalPolicyStep.SETTINGS}>
      <div className="grid max-w-176 grid-cols-1 justify-items-start">
        <h2 className="text-neutral-strong type-h-page-sm">
          {t('settings.title')}
        </h2>

        <p className="mt-4 text-neutral-strong type-intro-sm">
          {t('settings.instruction', { currency })}
        </p>

        <Form className="w-full">
          <WalletForms.Dropdown.ApprovalMethods className="mt-10 w-full" />
        </Form>
        <div className="pt-3 text-sm text-neutral-subtle">
          {t(`settings.note.${proposalApprovalMethod}`)}
        </div>

        <div className="mt-10">
          <ModalBackButton />

          <Button className="w-60" onClick={handleOnClick} variant="primary">
            {t('common:continue')}
          </Button>
        </div>
      </div>
    </WalletApprovalPolicyLayout>
  )
}

import { useMoney } from '@circlefin/hooks'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useBalancesMainQuery } from '@shared/graphql'

import { AssetBalanceText } from '../../../components'

import type { Currency } from '@shared/graphql'

export interface CircleWalletProps {
  /**
   * Currency code to fetch balance for.
   */
  currency?: Currency
}

export const CircleWallet: React.FC<CircleWalletProps> = ({ currency }) => {
  const { money } = useMoney({ locale: 'en-US' })
  const { data, loading, called, error } = useBalancesMainQuery({
    variables: { currency },
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
    skip: currency == null,
  })

  if (!called || currency == null || error) {
    return null
  }

  return (
    <AssetBalanceText
      amount={
        data?.balances.main?.balance &&
        money({
          number: data.balances.main.balance.amount,
          variant: data.balances.main.balance.currency,
        })
      }
      loading={loading}
    />
  )
}

CircleWallet.displayName = 'AssetBalanceText.CircleWallet'

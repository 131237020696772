import { useCustomMoney } from '@circlefin/hooks'
import { VAULT_WALLET_BALANCE_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useVaultWalletBalanceQuery } from '@shared/graphql'

import { AssetBalanceText } from '../../../components'

export interface DirectWalletProps {
  /**
   * Specify vault id.
   */
  vaultId?: string
  /**
   * Specify vault wallet id.
   */
  walletId?: string
}

export const DirectWallet: React.FC<DirectWalletProps> = ({
  vaultId,
  walletId,
}) => {
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const { data, error, called, loading } = useVaultWalletBalanceQuery({
    variables: {
      vaultId: vaultId ?? '',
      walletId: walletId ?? '',
    },
    pollInterval: VAULT_WALLET_BALANCE_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
    skip: !vaultId || !walletId,
  })

  if (!called || !walletId || error) {
    return null
  }

  return (
    <AssetBalanceText
      amount={
        data &&
        customMoney({
          number: data.vaultWalletBalance.native.amount,
          currencyConfig: {
            name: data.vaultWalletBalance.native.asset.symbol,
            decimals: data.vaultWalletBalance.native.asset.decimals,
            isCryptoCurrency: true,
          },
          options: { padded: false },
        })
      }
      loading={loading}
    />
  )
}

DirectWallet.displayName = 'AssetBalanceText.DirectWallet'

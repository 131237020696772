import { useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { LocalsForms } from '@features/locales/forms'
import {
  CountryCode,
  useOnboardingNaturesOfBusinessQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { OnboardingForms } from '../../../../forms'
import { useRolrSetup } from '../../../../hooks/rolr'

import type { CountriesInput } from '@shared/graphql'

export const rolrBizInfoSchema = y.object({
  /**
   * Registered business name.
   */
  businessName: y.string().required(),
  /**
   * Country of incorporation or formation.
   */
  countryOfIncorporation: LocalsForms.Dropdown.countrySchema.required(),
  /**
   * Primary place of business.
   */
  primaryPlaceOfBusiness: LocalsForms.Dropdown.countrySchema.required(),
  /**
   * Legal entity type.
   */
  legalEntityType: OnboardingForms.Dropdown.entityTypeSchema,
  /**
   * Business type (Nature of business).
   */
  businessType: y.string().required(),
})

export type RolrBizInfoFormValues = y.InferType<typeof rolrBizInfoSchema>

export interface RolrBizInfoFormProps {
  /**
   * On submit.
   */
  onSubmit: (values: RolrBizInfoFormValues) => void
  /**
   * Optional class name on form element.
   */
  className?: string
  /**
   * Is form loading?
   */
  loading?: boolean
}

/**
 * Filter for ROLR country field.
 */
export const countryFilter: CountriesInput = {
  link: {
    registeredIn: true,
    locatedIn: true,
  },
  onboarding: {
    sanctioned: false,
  },
} as const

export const RolrBizInfoForm: React.FC<RolrBizInfoFormProps> = ({
  onSubmit,
  className,
  loading = false,
}) => {
  const { t } = useTranslation('onboard/rolr-signup')
  const [{ businessInfo }] = useRolrSetup()

  const naturesOfBusinessQuery = useOnboardingNaturesOfBusinessQuery()

  // Get list of prohibited natures of business
  const prohibited = useMemo(
    () =>
      naturesOfBusinessQuery.data?.onboardingNaturesOfBusiness
        .filter(({ prohibited }) => prohibited)
        .map(({ value }) => value) ?? [],
    [naturesOfBusinessQuery.data],
  )

  const [Form] = useForm<RolrBizInfoFormValues>({
    schema: rolrBizInfoSchema.shape({
      /**
       * Business type (Nature of business).
       */
      businessType: y
        .string()
        .test(
          'validateBusinessNature',
          { key: 'onboard.unsupportedBusinessNature' },
          (value) => value != null && !prohibited.includes(value),
        )
        .required(),
    }),
    defaultValues: businessInfo ?? {
      countryOfIncorporation: CountryCode.US,
      primaryPlaceOfBusiness: CountryCode.US,
    },
  })

  return (
    <Form
      className={className}
      data-testid="rolr-biz-info-form"
      onSubmit={onSubmit}
    >
      <div className="flex flex-col gap-4">
        <Form.Input
          label={t`bizInfo.form.businessName`}
          loading={loading}
          name="businessName"
          placeholder={t`bizInfo.form.placeholder.businessName`}
        />
        <LocalsForms.Dropdown.Country
          data-testid="dropdown-coi"
          filters={countryFilter}
          label={t`bizInfo.form.countryOfIncorporation`}
          loading={loading}
          name="countryOfIncorporation"
        />
        <LocalsForms.Dropdown.Country
          data-testid="dropdown-ppob"
          filters={countryFilter}
          label={t`bizInfo.form.primaryPlaceOfBusiness`}
          loading={loading}
          name="primaryPlaceOfBusiness"
        />
        <OnboardingForms.Dropdown.EntityType
          label={t`bizInfo.form.legalEntityType`}
          loading={loading}
          name="legalEntityType"
          placeholder={t`bizInfo.form.placeholder.legalEntityType`}
        />
        <OnboardingForms.Dropdown.BusinessNatureCOP
          label={t`bizInfo.form.businessType`}
          loading={loading}
          name="businessType"
          placeholder={t`bizInfo.form.placeholder.businessType`}
        />
      </div>
      <Form.SubmitButton className="mt-6 w-full" variant="primary">
        {t`bizInfo.complete`}
      </Form.SubmitButton>
    </Form>
  )
}

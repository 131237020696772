import { useCallback, useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import * as PaymentButtons from '@features/payments/buttons'
import { FX_LAUNCH_ENABLED, VAULT_QUERY } from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import {
  BankAccountStatus,
  Currency,
  useBankAccountsQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../../buttons'

export interface TransferTypeProps {
  /**
   * Specify modal currency.
   */
  currency?: Currency
}

export const TransferType: React.FC<TransferTypeProps> = ({
  currency = Currency.USDC,
}) => {
  const { router } = useModal()
  // TODO: Delete orphan translations [https://circlepay.atlassian.net/browse/BIZ-1099]
  const { t } = useTranslation('modals.transfer')
  const { loading, data } = useBankAccountsQuery()
  const [{ Authorized: VaultAuthorized }] = usePermission(VAULT_QUERY)
  const [, { isAuthorized: fxEnabled }] = usePermission(FX_LAUNCH_ENABLED)

  useCurrentCurrency({
    // Current currency is set in the first modal step so that it's available in subsequent modal steps
    initialCurrency: currency,
  })

  const hasLinkedBankAccounts = useMemo(
    () =>
      data?.bankAccounts.some(
        ({ status }) => status === BankAccountStatus.complete,
      ),
    [data?.bankAccounts],
  )

  const handleSendOnChainModal = useCallback(() => {
    router.push(routes.blockchain.sendOnchain.recipient)
  }, [router])

  const handleBankWithdrawalModal = useCallback(() => {
    if (hasLinkedBankAccounts) {
      // If FX is enabled, we send the user to select their currency then into the FX flow.
      if (fxEnabled) {
        // If a currency is present, we use this currency to skip the user selecting one again in the FX flow.
        // Else, we have to go to the choose wallet modal first then into the FX form.
        router.push(
          currency != null
            ? {
                pathname: routes.transfer.fx.withdraw.form,
                query: { currency },
              }
            : routes.transfer.chooseWalletAndTransferType,
        )
        return
      }

      //Else, we send the user down the default / old select bank flow.
      router.push(routes.transfer.withdraw.amount)
      return
    }

    router.push(routes.bankAccount.noLinked.withdraw)
  }, [hasLinkedBankAccounts, router, currency, fxEnabled])

  const handleTransferVaultModal = useCallback(() => {
    router.push(routes.vault.transfer.sendOnChain.recipientInternal)
  }, [router])

  return (
    <div className="space-y-6">
      <SkeletonBox className="h-25 w-full" loading={loading}>
        <PaymentButtons.Cards.BankWires
          actionType="withdraw"
          className="w-116"
          icon="LibrarySolid"
          onClick={handleBankWithdrawalModal}
          subtitle={t('chooseTransferType.bank.subtitle')}
          title={t('chooseTransferType.bank.title')}
        />
      </SkeletonBox>

      <PaymentButtons.Cards.Blockchain
        actionType="withdraw"
        className="w-116"
        icon="LinkOutline"
        onClick={handleSendOnChainModal}
        subtitle={t('chooseTransferType.on-chain.subtitle')}
        title={t('chooseTransferType.on-chain.title')}
      />

      <VaultAuthorized>
        <Buttons.Cards.TransferVault
          className="w-116"
          icon="SwitchHorizontalOutline"
          onClick={handleTransferVaultModal}
          subtitle={t('chooseTransferType.vaultAccess.vault.subtitle')}
          title={t('chooseTransferType.vaultAccess.vault.title')}
        />
      </VaultAuthorized>
    </div>
  )
}

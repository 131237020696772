import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLETS_QUERY,
  CIRCLE_WALLET_APPROVAL,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import {
  useVaultDeviceRegistrationStatusQuery,
  useWalletApprovalPolicyQuery,
  VaultDeviceRegistrationStatusCode,
  WalletApprovalPolicyState,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { WalletApprovalPolicyInitializeQueryProps } from '../../../hooks/approval-policy/context'
import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { Currency } from '@shared/graphql'

export interface UpsertProps
  extends Pick<ButtonProps, 'variant' | 'disabled'>,
    WalletApprovalPolicyInitializeQueryProps {
  /**
   * Optional className that should be applied to the button.
   */
  className?: string
  /**
   * Currency.
   */
  currency: Currency
}

export const Upsert: React.FC<UpsertProps> = ({
  className,
  variant,
  disabled,
  ...queryProps
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  const [, { isAuthorized: isAuthorizedForQuery }] =
    usePermission(CIRCLE_WALLETS_QUERY)
  const [{ Authorized }] = usePermission(CIRCLE_WALLETS_MUTATION)
  const [, { isAuthorized: isEmailApprovalMethodEnabled }] = usePermission(
    CIRCLE_WALLET_APPROVAL,
  )

  const { data: { vaultDeviceRegistrationStatus } = {} } =
    useVaultDeviceRegistrationStatusQuery()

  const { data: { walletApprovalPolicy } = {} } = useWalletApprovalPolicyQuery({
    variables: {
      currency: queryProps.currency,
    },
    skip: !isAuthorizedForQuery,
  })

  if (walletApprovalPolicy === undefined) {
    return null
  }

  // In order to create or edit a draft, a policy must be active or in draft
  // WalletApprovalPolicy is null if there is no submitted/draft/active policy
  if (
    walletApprovalPolicy !== null &&
    ![
      WalletApprovalPolicyState.ACTIVE,
      WalletApprovalPolicyState.DRAFT,
    ].includes(walletApprovalPolicy.state)
  ) {
    return null
  }

  return (
    <Authorized>
      <ModalLink
        pathname={routes.walletApprovalPolicy.createPolicy.initialize}
        query={queryProps}
      >
        <Button
          className={className}
          // Disable button if user has not set up their device
          disabled={
            (vaultDeviceRegistrationStatus?.statusCode !==
              VaultDeviceRegistrationStatusCode.READY &&
              !isEmailApprovalMethodEnabled) ||
            disabled
          }
          size="sm"
          variant={variant}
        >
          {t('buttons.edit')}
        </Button>
      </ModalLink>
    </Authorized>
  )
}

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import {
  ADDRESS_IS_NOT_ELIGIBLE,
  ADDRESS_IS_NOT_TRM_SUPPORTED,
  OWNER_TYPE_NOT_ALLOWED,
  getError,
} from '@shared/apollo/lib/error/codes'
import { Center } from '@shared/components/layout'
import {
  BlockchainRecipientAddressOwner,
  BlockchainRecipientAddressWithMetadataDocument,
  BlockchainRecipientAddressesWithMetadataDocument,
  useCreateBlockchainRecipientAddressWithMetadataMutation,
  BlockchainAddressStatus,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { AddressDetailsCustomerForm } from './AddressDetailsCustomer.Form'

import type { AddressDetailsCustomerFormProps } from './AddressDetailsCustomer.Form'

export const AddressDetailsCustomer: React.FC = () => {
  const { t } = useTranslation('address-book/modals')
  const modal = useModal()
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()
  const { travelRuleRequired, loading: loadingTravelRule } =
    useTravelRuleRequired()

  const [
    createBlockchainRecipientAddressMutation,
    { loading: loadingAddressMutation },
  ] = useCreateBlockchainRecipientAddressWithMetadataMutation({
    refetchQueries: [
      BlockchainRecipientAddressWithMetadataDocument,
      BlockchainRecipientAddressesWithMetadataDocument,
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      // check address status and initiate challenge if needed
      if (
        travelRuleRequired &&
        data.createBlockchainRecipientAddressWithMetadata.status ===
          BlockchainAddressStatus.PENDING_VERIFICATION
      ) {
        modal.router.push({
          pathname: routes.account.addressBook.verifyAddress.identityChallenge,
          query: {
            addressId: data.createBlockchainRecipientAddressWithMetadata.id,
          },
        })
      } else {
        mfaSuccess()
      }
    },
    onError: (error) => {
      if (travelRuleRequired) {
        const { graphQLErrors } = error

        // First check if this is one of our known error scenarios and handle explicitly.
        if (
          getError(OWNER_TYPE_NOT_ALLOWED, graphQLErrors) ||
          getError(ADDRESS_IS_NOT_ELIGIBLE, graphQLErrors) ||
          getError(ADDRESS_IS_NOT_TRM_SUPPORTED, graphQLErrors)
        ) {
          modal.router.push({
            pathname: routes.account.addressBook.addNewAddress.error,
            options: {
              disableBack: true,
            },
          })
          return
        }
      }

      mfaError({ error })
    },
  })

  /**
   * Submit form and run mutation.
   */
  const handleSubmit: AddressDetailsCustomerFormProps['onSubmit'] = useCallback(
    (values) => {
      withMfa({
        variant: 'FullScreen',
        onComplete: (code) => {
          void createBlockchainRecipientAddressMutation({
            variables: {
              input: {
                address: values.address,
                chain: values.blockchain.chain,
                currency: values.currency,
                description: values.description ?? '', // TODO: Make it optional in graphql with fallback to string
                owner: BlockchainRecipientAddressOwner.customer,
                ...(values.memo && { addressTag: values.memo }),
              },
            },
            context: context(code),
          })
        },
        onAbort: modal.close,
      })
    },
    [withMfa, context, createBlockchainRecipientAddressMutation, modal.close],
  )

  return (
    <FullScreen totalSteps={4}>
      <>
        <Center className="mb-6 text-center" variant="horizontal">
          <h2
            className="text-2xl text-black-900 font-circular-bold"
            data-testid="address-details-step-title"
          >
            {t`addAddress.addressDetails.customer.title`}
          </h2>
          <h3
            className="mt-2 font-circular text-lg leading-6 text-black-400"
            data-testid="address-details-step-subtitle"
          >
            {t`addAddress.addressDetails.customer.subtitle`}
          </h3>
        </Center>
        <AddressDetailsCustomerForm
          loading={loadingAddressMutation || loadingTravelRule}
          onSubmit={handleSubmit}
          travelRuleRequired={travelRuleRequired}
        />
      </>
    </FullScreen>
  )
}

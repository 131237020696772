export enum LocalUnauthorizedReason {
  /**
   * Indicates the user HAS a verified email when they should not. This error
   * could occur if a signed-in user with valid email tried to access the
   * /verify-email error page.
   */
  HasVerifiedEmail = 'hasVerifiedEmail',
  /**
   * Unverified email: will occur if the user logs in with an unverified email.
   */
  UnverifiedEmail = 'unverifiedEmail',
  /**
   * The user is yet to complete the COP onboarding steps.
   */
  OnboardingRequired = 'onboardingRequired',
}

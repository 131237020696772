import { useMemo } from 'react'

import { Icon, TabSwitch } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { HK_LAUNCH_ENABLED } from '@services/permissions/sets/account'
import { CountryCode } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../Link.Context'
import { NonIbanWireForm } from '../../Forms'

export const NonIbanVariant: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ location }] = useLinkBankAccount()
  const [, { isAuthorized }] = usePermission(HK_LAUNCH_ENABLED)
  const labelType = useMemo(
    () =>
      isAuthorized && location?.country?.code === CountryCode.HK
        ? 'wireRtgs'
        : 'wire',
    [isAuthorized, location?.country?.code],
  )

  return (
    <TabSwitch selected="wire">
      <div className="pointer-events-none">
        <TabSwitch.Tabs variant="underlined-black">
          <TabSwitch.Tab id="wire">
            <div className="w-152">
              <p className="text-center text-neutral-strong font-circular-bold type-body-base">
                {t(`link.accountInformation.type.${labelType}`)}
              </p>
              <p className="text-center text-neutral-subtlest type-body-sm">
                {t(`link.accountInformation.description.${labelType}`)}
              </p>
            </div>
          </TabSwitch.Tab>
        </TabSwitch.Tabs>
      </div>

      <div className="mt-6">
        <TabSwitch.Content id="wire">
          <NonIbanWireForm />
          <div className="mt-6 flex items-center gap-3">
            <Icon name="SwitchHorizontalOutline" />
            <p className="text-neutral-subtle type-body-sm">
              {t(
                'modals.bankAccount:link.accountInformation.nonIbanWirePaymentTypeInfo',
              )}
            </p>
          </div>
        </TabSwitch.Content>
      </div>
    </TabSwitch>
  )
}

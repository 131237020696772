import classNames from 'classnames'
import Image from 'next/image'

import Stars from '../../../images/Stars.png'

export interface FeatureStepProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Title.
   */
  title: React.ReactNode
  /**
   * Description.
   */
  description: React.ReactNode
}

export const FeatureStep: React.FC<FeatureStepProps> = ({
  className,
  title,
  description,
}) => (
  <div
    className={classNames(
      'flex flex-col items-center justify-center',
      className,
    )}
  >
    <Image alt="stars" className="mb-2.5" src={Stars} />
    <h2 className="mb-1.5 type-body-base-bold">{title}</h2>
    <p className="text-center type-body-sm">{description}</p>
  </div>
)

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

import type { Scalars } from '@shared/graphql'

/**
 * Vault Transfer flow values.
 */
export interface VaultTransferState {
  /**
   * Vault parent wallet id.
   */
  selectedParentWalletId?: Scalars['ID']['output']
  /**
   * Vault wallet id (child/mapped wallet id).
   */
  selectedWalletId?: Scalars['ID']['output']
}

/**
 * Vault Transfer flow Actions.
 */
interface Actions {
  /**
   * Value change handler.
   */
  setVaultTransferState: (state: Partial<VaultTransferState>) => void
}

const defaultValues: VaultTransferState = {
  selectedParentWalletId: undefined,
  selectedWalletId: undefined,
}

/**
 * Create Vault Transfer Context.
 */
const Context = createContext<[VaultTransferState, Actions]>([
  { ...defaultValues },
  {
    setVaultTransferState: () => null,
  },
])

interface VaultTransferProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<VaultTransferState>
}

/**
 * Vault Transfer Provider.
 */
export const Provider: React.FC<VaultTransferProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<VaultTransferState>({
    ...defaultValues,
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<VaultTransferState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const resetContext = useCallback(() => {
    handleValueChange(defaultValues)
  }, [handleValueChange])

  useEffect(() => {
    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <Context.Provider
      value={[values, { setVaultTransferState: handleValueChange }]}
    >
      {children}
    </Context.Provider>
  )
}

/**
 * Vault Transfer context hook.
 */
export const useVaultTransfer = () => {
  return useContext(Context)
}

import { useContext } from 'react'

import { WalletApprovalPolicyContext } from './context'

/**
 * Wallet Approval Policy context hook.
 */
export const useWalletApprovalPolicy = () => {
  return useContext(WalletApprovalPolicyContext)
}

import { useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import { TabsNav } from '@containers/layout'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { WALLET_POLICY_SECTION } from '@services/permissions'
import {
  circleWalletDetailSection,
  circleWalletPolicySection,
  custodySection,
} from '@services/sections/lib/custody'
import { BackButton, Content } from '@shared/components/layout'
import { PageLayout } from '@shared/layout'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import {
  CircleWalletDetailHeader,
  CircleWalletPendingReceiveBanner,
} from '../../containers'
import { RedemptionOnlyProvider } from '../../hooks/redemption-only-express/provider'

import type { Currency } from '@shared/graphql'
import type { AppLayoutType } from '@shared/layout/core'

export const CircleWalletLayout: AppLayoutType = ({ children }) => {
  const { t } = useTranslation('common')
  const { query } = useRouter()
  const { travelRuleRequired } = useTravelRuleRequired()
  const [, { isAuthorized }] = usePermission(WALLET_POLICY_SECTION)

  const tabs = useMemo(
    () =>
      isAuthorized
        ? [circleWalletDetailSection, circleWalletPolicySection]
        : [circleWalletDetailSection],
    [isAuthorized],
  )
  const currency = query?.currency as Currency

  //TODO: Remove FeatureSwitch.singapore... call, its used within useTravelRuleRequired.
  return (
    <PageLayout>
      <Content>
        <BackButton section={custodySection}>{t('back')}</BackButton>
        <RedemptionOnlyProvider>
          <CircleWalletDetailHeader currency={currency} />
        </RedemptionOnlyProvider>

        {travelRuleRequired && (
          <CircleWalletPendingReceiveBanner currency={currency} />
        )}

        <TabsNav
          className="mt-14 w-full"
          query={{ currency }}
          sections={tabs}
        />
      </Content>
      {children}
    </PageLayout>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Vault creator mutation.
 * Which users are vault creator?
 *
 * VAULT_MANAGEMENT_ROLE and AGENT_ACCOUNT_ROLE are disabled in env except local.
 */
export const VAULT_CREATOR_MUTATION: PermissionSet = {
  access: FeatureSwitch.vaultCustodyEnabled()
    ? AccessPermissions.protected
    : AccessPermissions.restricted,
  includeAll: [Role.VAULT_MANAGEMENT_ROLE],
  // all agents cannot be vault creator
  excludeAny: [Role.AGENT_ACCOUNT_ROLE],
}

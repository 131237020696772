import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { BurnFeeV2Banner } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { SelectAmountForm } from './SelectAmount.Form'

export const SelectAmount: React.FC = () => {
  const { t } = useTranslation('modals.payout')
  const { router } = useModal()
  const [{ currency }] = useCurrentCurrency()

  const handleAmountSubmit = useCallback(() => {
    router.push(routes.transfer.withdraw.chooseRecipient)
  }, [router])

  return (
    <FullScreen totalSteps={5}>
      <Center className="font-circular" variant="horizontal">
        <BurnFeeV2Banner className="mb-6" multiline />
        <h2 className="mb-2 text-center text-2xl text-black-600 font-circular-bold">
          {t('withdraw.selectAmount.title')}
        </h2>

        <Center className="text-center" variant="horizontal">
          <h3 className="w-160 text-lg leading-6 text-black-400">
            {t(
              `withdraw.selectAmount.description.${currency ?? Currency.USDC}`,
            )}
          </h3>
        </Center>

        <Center className="mt-8" variant="horizontal">
          <SelectAmountForm onSubmit={handleAmountSubmit} />
        </Center>
      </Center>
    </FullScreen>
  )
}

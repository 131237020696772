import { useMemo } from 'react'

import { Icon, DescriptionList } from '@circlefin/components'
import { VaultUserPolicyRoleEnum } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../Create'
import { VaultUserPolicyRoleText } from '../../../VaultUserPolicyRoleText/VaultUserPolicyRoleText'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

import type { VaultUserPolicy } from '@shared/graphql'

const { Label, Description } = DescriptionList

export const UserPermissions: React.FC = () => {
  const { t } = useTranslation('vaults')

  const {
    condensed,
    usersPolicy = [],
    isWalletConnectPolicyEnabled,
    walletConnectPolicy,
  } = usePolicySummaryContext()

  const walletConnectUsersPolicy: VaultUserPolicy[] = useMemo(() => {
    const wcUsersPolicyMap = new Map<string, VaultUserPolicy>()
    walletConnectPolicy?.operators.forEach((operator) => {
      wcUsersPolicyMap.set(operator.id, {
        user: operator,
        permissions: [VaultUserPolicyRoleEnum.WC_OPERATOR],
      })
    })

    walletConnectPolicy?.approvers.forEach((approver) => {
      wcUsersPolicyMap.set(approver.id, {
        user: wcUsersPolicyMap.get(approver.id)?.user ?? approver,
        permissions: [
          ...(wcUsersPolicyMap.get(approver.id)?.permissions ?? []),
          VaultUserPolicyRoleEnum.WC_APPROVER,
        ],
      })
    })

    return Array.from(wcUsersPolicyMap).map(([_, { user, permissions }]) => ({
      user,
      permissions,
    }))
  }, [walletConnectPolicy])

  const renderUsersPolicy = (usersPolicy: VaultUserPolicy[]) => {
    return usersPolicy.map(({ user, permissions }) => (
      <List.Item key={user.id}>
        <List.Item.Title stepName={CreateVaultNavigationStep.PERMISSIONS}>
          {user.name}
        </List.Item.Title>
        <List.Item.Subtitle>
          <div className="flex items-start gap-x-2">
            {permissions.includes(VaultUserPolicyRoleEnum.CREATOR) && (
              <span className="flex items-center gap-x-2">
                {t('vault.policy.summary.permissions.owner')}
                <Icon className="inline-block" name="EllipseSolid" size={4} />
              </span>
            )}
            <span>
              <VaultUserPolicyRoleText permissions={permissions} />
            </span>
          </div>
        </List.Item.Subtitle>
      </List.Item>
    ))
  }

  const hasUsersPolicy = usersPolicy.length > 0
  const hasWalletConnectUsersPolicy =
    isWalletConnectPolicyEnabled && walletConnectPolicy != null

  return (
    <>
      <Label className="h-full">
        {t('vault.policy.summary.labels.userPermissions')}
      </Label>
      <Description
        className={classNames('flex flex-col gap-y-2', {
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
        data-testid="user-permissions"
      >
        {hasUsersPolicy && (
          <>
            <List>
              <List.Item>
                <List.Item.Title
                  className="font-circular-bold"
                  stepName={CreateVaultNavigationStep.PERMISSIONS}
                  showEdit
                >
                  {t('vault.policy.summary.labels.transfers')}
                </List.Item.Title>
              </List.Item>
            </List>
            <List>{renderUsersPolicy(usersPolicy)}</List>
          </>
        )}
        {hasUsersPolicy && hasWalletConnectUsersPolicy && (
          <hr className="mb-4 h-px border-black-100" />
        )}
        {hasWalletConnectUsersPolicy && (
          <>
            <List>
              <List.Item>
                <List.Item.Title
                  className="font-circular-bold"
                  stepName={CreateVaultNavigationStep.WALLET_CONNECT}
                  showEdit
                >
                  {t('vault.policy.summary.labels.contractInteractions')}
                </List.Item.Title>
              </List.Item>
            </List>
            <List>{renderUsersPolicy(walletConnectUsersPolicy)}</List>
          </>
        )}
      </Description>
    </>
  )
}

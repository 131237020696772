import { useCallback, useMemo, useEffect } from 'react'

import { Table } from '@circlefin/components'
import { Input } from '@circlefin/components/lib/Input'
import { MoneyInput } from '@circlefin/components/lib/MoneyInput'
import { y } from '@circlefin/form'
import { Currency } from '@shared/graphql'

import type { MoneyInputValues } from '@circlefin/components/lib/MoneyInput'
import type {
  WalletApprovalPolicyUserLimit,
  WalletApprovalPolicyLimits,
} from '@shared/graphql'

export interface UserLimitsRowProps {
  /**
   * Currency.
   */
  currency?: Currency
  /**
   * User limit data.
   */
  userLimit: WalletApprovalPolicyUserLimit
  /**
   * Policy limits.
   */
  policyLimits?: WalletApprovalPolicyLimits
  /**
   * Triggered when form gets changed.
   */
  onChange?: (value: WalletApprovalPolicyUserLimit) => void
  /**
   * Triggered when there is an error.
   */
  onError?: (
    userId: WalletApprovalPolicyUserLimit['user']['id'],
    hasError: boolean,
  ) => void
}

export const UserLimitsRow: React.FC<UserLimitsRowProps> = ({
  currency = Currency.USD,
  policyLimits,
  userLimit,
  onChange,
  onError,
}) => {
  const maxAmountSchema = useMemo(
    () =>
      y.object({
        maxAmount: y
          .number()
          .moreThan(0)
          .max(Number(policyLimits?.maxAmount ?? 0))
          .required(),
      }),
    [policyLimits?.maxAmount],
  )

  const maxTransactionsSchema = useMemo(
    () =>
      y.object({
        maxTransactions: y
          .number()
          .integer()
          .moreThan(0)
          .max(Number(policyLimits?.maxTransactions ?? 0))
          .required(),
      }),
    [policyLimits?.maxTransactions],
  )

  const isMaxAmountValid = useMemo(() => {
    return maxAmountSchema.isValidSync(userLimit)
  }, [maxAmountSchema, userLimit])

  const isMaxTransactionsSchemaValid = useMemo(() => {
    return maxTransactionsSchema.isValidSync(userLimit)
  }, [maxTransactionsSchema, userLimit])

  useEffect(() => {
    onError?.(
      userLimit.user.id,
      !isMaxAmountValid || !isMaxTransactionsSchemaValid,
    )
  }, [
    isMaxAmountValid,
    isMaxTransactionsSchemaValid,
    onError,
    userLimit.user.id,
  ])

  const handleDailyVolumeChange = useCallback(
    ({ value }: MoneyInputValues) => {
      onChange?.({ ...userLimit, maxAmount: value })
    },
    [onChange, userLimit],
  )

  const handleDailyTransactionLimitsChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        onChange?.({
          ...userLimit,
          maxTransactions: e.target.value,
        })
      },
      [onChange, userLimit],
    )

  return (
    <Table.Body.Row data-testid="user-limit-row">
      <Table.Body.Cell className="px-4 py-5">
        {userLimit.user.name}
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        <MoneyInput
          className="w-48"
          currencyVariant={currency}
          error={!isMaxAmountValid}
          name="maxAmount"
          onChange={handleDailyVolumeChange}
          value={userLimit.maxAmount ?? ''}
          padded
        />
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        <Input className="w-20" error={!isMaxTransactionsSchemaValid}>
          <Input.Field.Text
            data-testid="input-daily-transaction-limits"
            name="maxTransactions"
            onChange={handleDailyTransactionLimitsChange}
            value={userLimit.maxTransactions ?? ''}
          />
        </Input>
      </Table.Body.Cell>
    </Table.Body.Row>
  )
}

import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import {
  useWatchlistBlockReasonsQuery,
  BlockEntityReason,
} from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

export const blockReasonsSchema = y
  .string()
  .oneOf(Object.values(BlockEntityReason))

export type BlockReasonsProps = Omit<
  React.ComponentProps<typeof Dropdown>,
  'items'
>

const Dropdown = createFormDropdown()

export const BlockReasons: React.FC<BlockReasonsProps> = ({
  className,
  label,
  ...props
}) => {
  const { data, loading } = useWatchlistBlockReasonsQuery()
  const { t } = useTranslation('forms')

  const items = useMemo(() => {
    return (data?.watchlistBlockReasons ?? []).map(({ value }) => ({
      value,
      label: t(`dropdown.watchlist.blockReasons.options.${value}`),
    }))
  }, [t, data])

  return (
    <SkeletonBox
      className={classNames('mt-6 w-full h-10', className)}
      loading={loading}
    >
      <Dropdown
        {...props}
        className={classNames('w-full', className)}
        items={items}
        label={label ?? t`dropdown.watchlist.blockReasons.label`}
      />
    </SkeletonBox>
  )
}

BlockReasons.displayName = 'BlockReasons'

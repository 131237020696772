import { useCallback, useMemo, useState } from 'react'

import { TableFilters } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { FX_LAUNCH_ENABLED } from '@services/permissions'
import {
  cbitDetail,
  onchainReceive,
  onchainSend,
  rtpDetail,
  senReceiveDetail,
  senWithdrawalDetail,
  sepaDetail,
  sepaInstantDetail,
  signetDetail,
  wireReceiveDetail,
  wireWithdrawalDetail,
  withdrawalDetail,
  depositDetail,
  xpayDetail,
  redemptionFeeDetail,
} from '@services/sections/lib/payments'
import { useSegment, SegmentEvents } from '@services/segment'
import { PAYMENT_ACTIVITY_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { ComplexTable, TableCard } from '@shared/components/tables'
import { TransactionType, usePaymentsLazyQuery } from '@shared/graphql'
import useIsMounted from 'ismounted'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { PaymentFilter } from '../../filters'

import { usePaymentsActivityTableColumns } from './PaymentsActivityTable.Columns'

import type {
  onFetchDataProps,
  Row,
} from '@circlefin/components/lib/AdvancedTable'
import type { FilterDateRangeValue } from '@circlefin/components/lib/TableFilters/types'
import type { Payment, Currency } from '@shared/graphql'

const { Header, Title, Filters, Body } = TableCard

interface TableFilterProps {
  /**
   * Transaction Type Filter.
   */
  type?: TransactionType[]
  /**
   * Create date Filter.
   */
  createDate?: FilterDateRangeValue
}

export interface PaymentsActivityTableProps {
  /**
   * Hide Header?
   */
  hideHeader?: boolean
  /**
   * Hide Filters?
   */
  hideFilters?: boolean
  /**
   * Controls the amount of lines in the table for each paginated page.
   * Defaults to 10.
   */
  pageSize?: number
  /**
   * Whether we want to enable pagination.
   * Defaults to true.
   */
  paginated?: boolean
  /**
   * Filter by currency - show all activity for a specific currency.
   */
  currency?: Currency
  /**
   * Set date range to export - export activity for a date range selected in the table.
   */
  setDateRange?: (dateRange?: FilterDateRangeValue) => void
}

export const PaymentsActivityTable: React.FC<PaymentsActivityTableProps> = ({
  hideHeader = false,
  hideFilters = false,
  pageSize = 10,
  paginated = false,
  currency,
  setDateRange,
}) => {
  const router = useRouter()
  const { track } = useSegment()
  const { t } = useTranslation('payments')
  const isMounted = useIsMounted()
  const columns = usePaymentsActivityTableColumns()
  const [filters, setFilters] = useState<TableFilterProps>({})
  const [, { isAuthorized: useNewTxDetails }] = usePermission(FX_LAUNCH_ENABLED)

  const handleChange = useCallback(
    (filters: TableFilterProps) => {
      setFilters(filters)
      setDateRange?.(filters.createDate)
    },
    [setDateRange],
  )

  const [fetchPayments, { called, loading, data, error, refetch }] =
    usePaymentsLazyQuery({
      pollInterval: PAYMENT_ACTIVITY_POLL_INTERVAL, // 10sec interval
      fetchPolicy: 'cache-and-network', // ensure table has fresh data on render
    })

  const handleFetchData = useCallback(
    ({
      currentPageLink,
      filters: tableFilters,
    }: onFetchDataProps<Payment, TableFilterProps>) => {
      const { from, to } = tableFilters.createDate ?? {}

      // we only want to invoke this query, when the component is still mounted
      if (isMounted.current) {
        void fetchPayments({
          variables: {
            // We set the pageSize/to/from parameters only on the first page.
            // For the other pages we should follow the link parameters.
            // Every time the user changes the filters the page link is
            // set to undefined, and we go back to the first page.
            page: currentPageLink
              ? {
                  pageSize: currentPageLink.pageSize,
                  to: currentPageLink.to,
                  from: currentPageLink.from,
                  pageAfter: currentPageLink.pageAfter,
                  pageBefore: currentPageLink.pageBefore,
                }
              : {
                  pageSize,
                  to,
                  from,
                },
            filter: {
              type: tableFilters.type,
              currency,
            },
          },
        })
      }
    },
    [isMounted, fetchPayments, pageSize, currency],
  )

  // TODO: there is no reason to route transaction details to 4 different pages
  //  We should have one page that display the variant based on the transaction type
  //  It require more refactoring
  const typeRoute: Record<TransactionType, string> = useMemo(
    // TODO: Once enabled we can get rid of the OLD flow here and this code will be just two branches; deposit and withdrawal.
    // In the meantime there isn't much reason to reduce complexity for code that will be removed soon.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => ({
      [TransactionType.onchain_send]: onchainSend.route,
      [TransactionType.onchain_receive]: onchainReceive.route,
      [TransactionType.wire_receive]: useNewTxDetails
        ? depositDetail.route
        : wireReceiveDetail.route,
      [TransactionType.wire_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : wireWithdrawalDetail.route,
      [TransactionType.signet_receive]: useNewTxDetails
        ? depositDetail.route
        : signetDetail.route,
      [TransactionType.signet_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : signetDetail.route,
      [TransactionType.cbit_receive]: useNewTxDetails
        ? depositDetail.route
        : cbitDetail.route,
      [TransactionType.cbit_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : cbitDetail.route,
      [TransactionType.xpay_receive]: useNewTxDetails
        ? depositDetail.route
        : xpayDetail.route,
      [TransactionType.xpay_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : xpayDetail.route,
      [TransactionType.rtp_receive]: useNewTxDetails
        ? depositDetail.route
        : rtpDetail.route,
      [TransactionType.rtp_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : rtpDetail.route,
      [TransactionType.sen_receive]: useNewTxDetails
        ? depositDetail.route
        : senReceiveDetail.route,
      [TransactionType.sen_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : senWithdrawalDetail.route,
      [TransactionType.rtgs_receive]: useNewTxDetails
        ? depositDetail.route
        : wireReceiveDetail.route,
      [TransactionType.rtgs_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : wireWithdrawalDetail.route,
      [TransactionType.sepa_receive]: useNewTxDetails
        ? depositDetail.route
        : sepaDetail.route,
      [TransactionType.sepa_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : sepaDetail.route,
      [TransactionType.sepa_instant_receive]: useNewTxDetails
        ? depositDetail.route
        : sepaInstantDetail.route,
      [TransactionType.sepa_instant_withdrawal]: useNewTxDetails
        ? withdrawalDetail.route
        : sepaInstantDetail.route,
      // Only supporting PIX in the new tx detail page format
      [TransactionType.pix_receive]: depositDetail.route,
      [TransactionType.pix_withdrawal]: withdrawalDetail.route,
      [TransactionType.redemption_fee_gross]: redemptionFeeDetail.route,
      [TransactionType.redemption_fee_net]: redemptionFeeDetail.route,
    }),
    [useNewTxDetails],
  )

  const onRowClick = useCallback(
    ({ original: { id, type } }: Row<Payment>) => {
      const isSepaTransaction =
        type === TransactionType.sepa_receive ||
        type === TransactionType.sepa_withdrawal

      const isSepaInstantTransaction =
        type === TransactionType.sepa_instant_receive ||
        type === TransactionType.sepa_instant_withdrawal

      const isSignetTransaction =
        type === TransactionType.signet_receive ||
        type === TransactionType.signet_withdrawal

      const isCbitTransaction =
        type === TransactionType.cbit_receive ||
        type === TransactionType.cbit_withdrawal

      const isPixTransaction =
        type === TransactionType.pix_receive ||
        type === TransactionType.pix_withdrawal

      const isXpayTransaction =
        type === TransactionType.xpay_receive ||
        type === TransactionType.xpay_withdrawal

      const isRtpTransaction =
        type === TransactionType.rtp_receive ||
        type === TransactionType.rtp_withdrawal

      const isRedemptionFee =
        type === TransactionType.redemption_fee_gross ||
        type === TransactionType.redemption_fee_net

      // TODO: as mentioned above, when we refactor this to use the type query param instead of 4 pages, we should pass the type for all transaction types
      // as query params, for now only sepa, rtp, signet, cbit or xpay supports the type query param
      const query =
        isSignetTransaction ||
        isCbitTransaction ||
        isXpayTransaction ||
        isRtpTransaction ||
        isSepaTransaction ||
        isSepaInstantTransaction ||
        isPixTransaction ||
        isRedemptionFee
          ? {
              id,
              type,
            }
          : {
              id,
            }

      void router.push({
        pathname: typeRoute[type],
        query,
      })
      track(SegmentEvents.TransactionDetailsClicked, {
        props: {
          transaction_type: type,
        },
      })
    },
    [router, track, typeRoute],
  )

  return (
    <TableCard data-testid={loading ? 'loading' : 'payments-activity'}>
      <Header hidden={hideHeader}>
        <Title>{t`activity.header`}</Title>
      </Header>
      <Filters hidden={hideFilters} onChange={handleChange} size={9}>
        <TableFilters.DateRange
          name="createDate"
          placeholder={t`common:dateRange`}
          size={3}
          start={5}
          isClearable
        />
        <PaymentFilter.TransactionType name="type" size={2} />
      </Filters>
      <Body>
        <ComplexTable<Payment, TableFilterProps>
          columns={columns}
          data={data?.payments}
          empty={{ subtitle: t`activity.empty-table-label` }}
          enableSorting={false}
          error={error}
          filters={filters}
          loading={!called || (!data && loading)}
          onFetchData={handleFetchData}
          onRowClick={onRowClick}
          pageSize={pageSize}
          pagination={paginated ? 'links' : 'default'}
          retry={refetch}
          manualFiltering
        />
      </Body>
    </TableCard>
  )
}

PaymentsActivityTable.displayName = 'PaymentsActivityTable'

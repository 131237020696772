import { useCallback, useMemo } from 'react'

import { useModal } from '@circlefin/modal-router'
import { BaseHeader } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import {
  MerchantPaymentSourceType,
  WatchLists,
  useAddEntriesToWatchListMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { AchTypeEntity } from './Form/AchTypeEntity/AchTypeEntity'
import { CardTypeEntity } from './Form/CardTypeEntity/CardTypeEntity'
import { SepaTypeEntity } from './Form/SepaTypeEntity/SepaTypeEntity'
import { WireTypeEntity } from './Form/WireTypeEntity/WireTypeEntity'

import type {
  AddEntryToWatchListInput,
  Maybe,
  MerchantPaymentSource,
} from '@shared/graphql'

export interface BlockQueryProps {
  /**
   * Payment ID.
   */
  paymentId?: string
  /**
   * Merchant Payment Source.
   */
  source?: Maybe<MerchantPaymentSource>
}

export const Block: React.FC<BlockQueryProps> = (props) => {
  const { t } = useTranslation('platform/modals')
  const { router } = useModal()
  const { track } = useSegment()

  const [addEntriesToWatchList, { error, reset }] =
    useAddEntriesToWatchListMutation({
      onCompleted: () => {
        router.push({
          pathname:
            routes.account.security.fraudManagement.addToWatchList.success,
          query: {
            variant: WatchLists.block,
          },
          options: {
            disableBack: true,
          },
        })
      },
    })

  const submitHandler = useCallback(
    (entries: AddEntryToWatchListInput[]) => {
      track(SegmentEvents.BlockEntityClickedFromPayment)
      void addEntriesToWatchList({
        variables: {
          input: {
            variant: WatchLists.block,
            entries,
          },
        },
      })
    },
    [addEntriesToWatchList, track],
  )

  const typeEntityPairs = useMemo(() => {
    if (!props.source) {
      return null
    }

    const source = props.source

    switch (source?.type) {
      case MerchantPaymentSourceType.wire:
        return <WireTypeEntity onSubmit={submitHandler} source={source} />
      case MerchantPaymentSourceType.card:
        return <CardTypeEntity onSubmit={submitHandler} source={source} />
      case MerchantPaymentSourceType.ach:
        return <AchTypeEntity onSubmit={submitHandler} source={source} />
      case MerchantPaymentSourceType.sepa:
        return <SepaTypeEntity onSubmit={submitHandler} source={source} />
      default:
        return null
    }
  }, [submitHandler, props])

  return (
    <BaseHeader title={t`payment.block.title`}>
      <PropsErrorBoundary<BlockQueryProps> props={props} variant="page">
        {({ paymentId }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <section className="px-8">
              <p className="mb-6 text-sm text-black-600">
                {t('payment.block.instructions', {
                  paymentId,
                })}
              </p>
              {typeEntityPairs}
            </section>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseHeader>
  )
}

Block.displayName = 'Payment.Block'

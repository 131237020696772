import { useCallback, useState } from 'react'

import { FixedBanner, Radio } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { BankAccountForms } from '@features/bank-account/forms'
import { useBankAccountTypeByCurrency } from '@features/bank-account/hooks/type-by-currency'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { isWire } from '@services/type-guards/BankAccount'
import { tokenToCurrency } from '@shared/components/common'
import { Center } from '@shared/components/layout'
import { Currency, useWireBankAccountQuery, RailType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { getEligibleRails } from '../../../hooks/fx/helpers'
import { useWithdraw } from '../../../hooks/withdraw'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { BankAccount, BankAccountType } from '@shared/graphql'

const schema = y.object({
  /**
   * Selected bank account.
   */
  bankAccount: BankAccountForms.Combobox.BankAccountSchema.required(),
  /**
   * Selected Rail (or default to account type of chosen bank account).
   */
  rail: y.string().required(),
})

type ChooseRecipientFormValues = y.InferType<typeof schema>

interface ChooseRecipientFormProps {
  /**
   * Handles form submit.
   */
  onSubmit: (values: ChooseRecipientFormValues) => void
}

export const ChooseRecipientForm: React.FC<ChooseRecipientFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('modals.payout')

  const [{ currency }] = useCurrentCurrency()
  const [{ amount, bankAccount: defaultBankAccount }, { onFormChange }] =
    useWithdraw()
  const { bankAccountType } = useBankAccountTypeByCurrency({
    initialCurrency: currency,
  })
  const [eligibleRails, setEligibleRails] =
    useState<(BankAccountType | RailType)[]>()

  const [Form, { watch, setValue }] = useForm<ChooseRecipientFormValues>({
    schema,
    defaultValues: {
      bankAccount: defaultBankAccount,
      rail: undefined,
    },
  })

  const [wireBankAccountId, setWireBankAccountId] = useState<string>()

  const setRailsData = useCallback(
    (acc?: BankAccount) => {
      const rails = getEligibleRails({
        currency,
        bankAccount: acc,
      })
      setEligibleRails(rails)
      setValue('rail', rails[0], { shouldValidate: true })
    },
    [currency, setValue],
  )

  const { loading: loadingDetails } = useWireBankAccountQuery({
    variables: { accountId: wireBankAccountId ?? '' },
    fetchPolicy: 'no-cache',
    skip: wireBankAccountId == null,
    onCompleted: (data) => {
      setRailsData(data.wireBankAccount)
    },
  })

  const [bankAccount, rail] = watch(['bankAccount', 'rail'])

  const handleBankAccountChange = useCallback(
    (account: SelectListItem<BankAccount>) => {
      if (account?.value != null) {
        setValue('bankAccount', account.value, { shouldValidate: true })
        if (isWire(account.value)) {
          setWireBankAccountId(account.value?.id)
        } else {
          setRailsData(account.value)
        }
      }
    },
    [setRailsData, setValue],
  )

  const handleRailChange = useCallback(
    (rail: string | number) => {
      setValue('rail', rail.toString())
    },
    [setValue],
  )

  const handleFormSubmit = useCallback(
    (values: ChooseRecipientFormValues) => {
      onFormChange(values)
      onSubmit(values)
    },
    [onFormChange, onSubmit],
  )

  return (
    <Form className="flex flex-col items-center" onSubmit={handleFormSubmit}>
      <BankAccountForms.Combobox.BankAccounts
        className="my-4 mt-6 w-96"
        name="bankAccount"
        onChange={handleBankAccountChange}
        types={bankAccountType}
      />

      <div className="mt-4 min-h-24 w-100">
        {bankAccount != null &&
          (eligibleRails !== undefined && eligibleRails.length > 0 ? (
            <Radio.Group
              aria-label="rail"
              direction="vertical"
              disabled={eligibleRails?.length === 1}
              label={t`modals.transfer:fx.availableTransferMethods`}
              name="rail"
              onChange={handleRailChange}
              value={rail}
            >
              {eligibleRails?.map(
                (rail) =>
                  rail != null &&
                  // sepa instant does not support more than 100k
                  !(
                    rail === RailType.sepa_instant &&
                    amount != null &&
                    parseInt(amount, 10) > 100000
                  ) && (
                    <Radio key={rail} value={rail}>
                      <span className="flex justify-between">
                        <span>
                          {t(`modals.transfer:fx.rails.${rail}.label`)}
                        </span>
                        <span>
                          {t(`modals.transfer:fx.rails.${rail}.info`)}
                        </span>
                      </span>
                    </Radio>
                  ),
              )}
            </Radio.Group>
          ) : (
            !loadingDetails && (
              <FixedBanner status="warning" visible>
                <FixedBanner.Title>
                  {t('modals.transfer:fx.withdraw.selectAnotherAccount')}
                </FixedBanner.Title>
                <FixedBanner.Description>
                  {t('modals.transfer:fx.withdraw.accountCurrencyMismatch', {
                    currency: tokenToCurrency(currency ?? Currency.USDC),
                  })}
                </FixedBanner.Description>
              </FixedBanner>
            )
          ))}
      </div>

      <Center className="pt-10" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          variant="primary"
        >
          {t('common:continue')}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { FixedBanner, TextLink } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { DelayedWithdrawalInfo } from '@shared/graphql'

/**
 * Delayed Withdrawals: Alert props.
 */
export interface DelayedWithdrawalsAlertProps {
  /**
   * Delayed withdrawals info object.
   */
  delayedWithdrawalInfo?: DelayedWithdrawalInfo
  /**
   * Custom className.
   */
  className?: string
}

export const DelayedWithdrawalsAlert: React.FC<DelayedWithdrawalsAlertProps> =
  ({ delayedWithdrawalInfo, className }) => {
    const { t } = useTranslation('common')

    if (!delayedWithdrawalInfo) {
      return null
    }

    return (
      <FixedBanner
        className={className}
        data-testid="delayed-withdrawals-alert"
        status="warning"
        visible={delayedWithdrawalInfo.enabled}
      >
        <FixedBanner.Description>
          <p>
            {t('delayedWithdrawals.alert.text', {
              count: delayedWithdrawalInfo.withdrawalEnabledAfterHours ?? 0,
            })}
          </p>
          <TextLink.Link
            href={t`delayedWithdrawals.infoAlert.link.url`}
            rel="noopener noreferrer"
            size="sm"
            target="_blank"
            variant="primary"
          >
            {t`delayedWithdrawals.infoAlert.link.text`}
          </TextLink.Link>
        </FixedBanner.Description>
      </FixedBanner>
    )
  }

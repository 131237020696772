import { useContext } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { MultiStep, FullScreen } from '@modals/layout'
import { MfaContext } from '@shared/mfa/lib/context'

export interface LayoutProps {
  /**
   * Mfa title.
   */
  title: string
  /**
   * Children.
   */
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const { history, route } = useModalHistory()
  const { request } = useContext(MfaContext)

  if (request.variant === 'FullScreen') {
    /**
     * Mfa has 2 screens, [0]: code input and [1]: Success/Error.
     */
    const mfaScreenIndex = route?.pathname.includes('code') ? 2 : 1

    /**
     * Number of previous screens + total mfa screens.
     */
    const totalSteps = history.length + mfaScreenIndex

    return <FullScreen totalSteps={totalSteps}>{children}</FullScreen>
  }

  return <MultiStep title={title}>{children}</MultiStep>
}

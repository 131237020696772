import { createContext } from 'react'

import type { BankAccount } from '@shared/graphql'

export interface WithdrawState {
  /**
   * Selected bank account.
   */
  bankAccount?: BankAccount
  /**
   * Withdrawal amount.
   */
  amount?: string
  /**
   * Selected rail (or bank account type if no rail is available).
   */
  rail?: string
}

interface WithdrawActions {
  /**
   * Cb that will be called when the form state change.
   */
  onFormChange: (values: WithdrawState) => void
}

export const defaultState: WithdrawState = {
  amount: '',
  bankAccount: undefined,
}

export const WithdrawContext = createContext<[WithdrawState, WithdrawActions]>([
  defaultState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  { onFormChange: () => {} },
])

import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { MultiStep } from '@modals/layout'
import {
  IpAllowListingIpAddressesDocument,
  IpAllowListingSettingDocument,
  useCreateIpAllowListingIpAddressMutation,
  useIpAllowListingSettingQuery,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import { useCreate } from './Create.Context'
import { CreateForm } from './CreateForm/CreateForm'

import type { IpAddressCreateFormState } from './CreateForm/CreateForm'

export const Create: React.FC = () => {
  const { t } = useTranslation('modals/settings/security')
  const [withMfa, { context, mfaSuccess, mfaError }] = useMfa()
  const modal = useModal()
  const [initialFormState, { onFormChange }] = useCreate()
  const settingQuery = useIpAllowListingSettingQuery()
  const [createIp] = useCreateIpAllowListingIpAddressMutation({
    refetchQueries: [
      IpAllowListingIpAddressesDocument,
      IpAllowListingSettingDocument,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      mfaSuccess({
        message: t`common:success`,
        description: t(
          `ipAllowlisting.createIpAddress.warning.${
            settingQuery.data?.ipAllowListingSetting.enabled
              ? 'enabled'
              : 'disabled'
          }`,
        ),
      })
    },
    onError: (error) => {
      mfaError({ error })
    },
  })

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleSubmit = useCallback(
    (values: IpAddressCreateFormState) => {
      onFormChange({ ipMax: '', ...values })
      withMfa({
        onComplete: (code) => {
          void createIp({
            variables: {
              input: {
                valueMin: values.ipMin,
                valueMax: values.ipMax ? values.ipMax : null,
              },
            },
            context: context(code),
          })
        },
        onAbort: closeModal,
      })
    },
    [closeModal, context, createIp, onFormChange, withMfa],
  )

  return (
    <MultiStep title={t('ipAllowlisting.createIpAddress.title')}>
      <CreateForm initialValues={initialFormState} onSubmit={handleSubmit} />
    </MultiStep>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  VaultStateEnum,
  useSubmitVaultForApprovalMutation,
  useUsersFromVaultPolicyThatHaveNotFinishedSetupQuery,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

import { SubmissionBlocked, NeedsToBeApproved } from './Info'

import type { ApolloError } from '@apollo/client'
import type { ButtonProps } from '@circlefin/components/lib/Button'

export type SubmitForApprovalProps = Omit<
  ButtonProps,
  'variant' | 'children'
> & {
  /**
   * OnError handler.
   */
  onError?: (error: ApolloError) => void
}

export const SubmitForApproval: React.FC<SubmitForApprovalProps> = ({
  onError,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation('vaults')
  const { track } = useSegment()
  const { vaultMetadata } = useVaultPermission()

  const { data, loading: nonSetupUsersLoading } =
    useUsersFromVaultPolicyThatHaveNotFinishedSetupQuery({
      variables: {
        vaultId: vaultMetadata?.id ?? '',
        vaultPolicyId: vaultMetadata?.draftPolicyId ?? '',
      },
      fetchPolicy: 'cache-and-network',
      skip:
        /**
         * Only fetch this data, when we have a vault that is actually ready for approval.
         */
        !vaultMetadata ||
        ![
          VaultStateEnum.READY_FOR_APPROVAL,
          VaultStateEnum.CHANGES_READY_FOR_APPROVAL,
        ].includes(vaultMetadata?.state),
    })

  const [submitVaultForApproval, { loading }] =
    useSubmitVaultForApprovalMutation({
      onError,
    })

  const handleOnClick = useCallback(() => {
    track(SegmentEvents.SubmitVaultForApprovalClicked)

    vaultMetadata?.id &&
      void submitVaultForApproval({
        variables: {
          vaultId: vaultMetadata.id,
        },
      })
  }, [track, vaultMetadata?.id, submitVaultForApproval])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata?.isCreator) return null

  /**
   * Do not return anything if vault cannot be approved.
   */
  if (
    ![
      VaultStateEnum.CHANGES_READY_FOR_APPROVAL,
      VaultStateEnum.READY_FOR_APPROVAL,
    ].includes(vaultMetadata.state)
  )
    return null

  return (
    <div className="inline-flex flex-col items-end justify-center space-y-3">
      <Button
        disabled={
          disabled ??
          // disable the button, when there is at least one user inside of the policy that has not setup the app yet
          ((data?.usersFromVaultPolicyThatHaveNotFinishedSetup ?? []).length >
            0 ||
            // disable, whenever the query is fetching data from the backend
            nonSetupUsersLoading)
        }
        loading={loading}
        onClick={handleOnClick}
        variant="primary"
        {...rest}
      >{t`buttons.vault.submitForApproval`}</Button>
      {!nonSetupUsersLoading &&
        data &&
        data.usersFromVaultPolicyThatHaveNotFinishedSetup.length > 0 && (
          <SubmissionBlocked />
        )}
      {!nonSetupUsersLoading &&
        data &&
        data.usersFromVaultPolicyThatHaveNotFinishedSetup.length === 0 && (
          <NeedsToBeApproved />
        )}
    </div>
  )
}

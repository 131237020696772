import { DescriptionList } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../Create'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const PolicyName: React.FC = () => {
  const { t } = useTranslation('vaults')

  const { condensed, name } = usePolicySummaryContext()

  return (
    <>
      <Label className="h-full">{t('vault.policy.summary.labels.name')}</Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
      >
        <List hideShowAll>
          <List.Item>
            <List.Item.Title
              stepName={CreateVaultNavigationStep.POLICY_NAME}
              showEdit
            >
              {name}
            </List.Item.Title>
          </List.Item>
        </List>
      </Description>
    </>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Stablecoin Express Mint Permissions.
 */
export const STABLECOIN_EXPRESS_MINT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.STABLECOIN_EXPRESS_ROLE, Role.STABLECOIN_EXPRESS_MINT_ROLE],
}

import { useCallback } from 'react'

import { Tooltip, Icon, Button, DescriptionList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import { VAULT_MUTATION } from '@services/permissions'
import { routes } from '@services/sections/modal'
import useTranslation from 'next-translate/useTranslation'

const { Label, Description } = DescriptionList

// We currently do not need the Reset Pin flow.
// Keeping this in case we decide to re-enable 8 digit pin in Cybavo.
export const AuthenticatorSettingsResetPin: React.FC = () => {
  const { t } = useTranslation('settings')
  const { router } = useModal()

  const [VaultMutationPermission] = usePermission(VAULT_MUTATION)

  const openChangePinFlow = useCallback(() => {
    void router.push(routes.vault.changePin.confirmation)
  }, [router])

  const openResetPinFlow = useCallback(() => {
    void router.push(routes.vault.resetPin.confirmation)
  }, [router])

  return (
    <>
      <Label className="flex w-64 items-start">
        <span>{t`walletSecurity.authenticatorSettings.pin.label`}</span>
        <Tooltip
          className="z-60"
          content={t`walletSecurity.authenticatorSettings.pin.tooltip`}
        >
          <span className="ml-1.5">
            <Icon
              className="text-black-200"
              name="InformationCircleSolid"
              size={16}
            />
          </span>
        </Tooltip>
      </Label>
      <Description className="flex items-center">
        {t`walletSecurity.authenticatorSettings.pin.changePin`}
        <Button onClick={openChangePinFlow} variant="text">
          <Icon name="ChevronRightSolid" />
        </Button>
      </Description>

      <VaultMutationPermission.Authorized>
        <Label className="w-64" />
        <Description className="flex items-center">
          {t`walletSecurity.authenticatorSettings.pin.resetPin`}
          <Button onClick={openResetPinFlow} variant="text">
            <Icon name="ChevronRightSolid" />
          </Button>
        </Description>
      </VaultMutationPermission.Authorized>
    </>
  )
}

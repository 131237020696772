import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Agent mutation.
 * Which users are agent?
 *
 * AGENT_ACCOUNT_ROLE is disabled in env except local.
 */
export const AGENT_MUTATION: PermissionSet = {
  access: FeatureSwitch.vaultCustodyEnabled()
    ? AccessPermissions.protected
    : AccessPermissions.restricted,
  includeAll: [Role.AGENT_ACCOUNT_ROLE],
}

import { useMemo } from 'react'

import { AccessPermissions, usePermission } from '@circlefin/permissions'
import {
  circleWalletPolicySection,
  custodySection,
} from '@services/sections/lib/custody'
import { inviteUser } from '@services/sections/lib/settings'
import { TourGuide, TransWithLink } from '@shared/components/common'
import { Currency } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { FeatureStep } from '../FeatureStep/FeatureStep'

import type { TourGuideStep } from '@shared/components/common'

const custodySectionId = "[id='layout:page.custody.title']"
const roleDropdownId = '#roleDropdown > div'
const walletApprovalPolicyStepId = `[id='${circleWalletPolicySection.label}']`

export interface CustodyFeatureGuideContentProps {
  /**
   * Is visible.
   */
  isVisible: boolean
  /**
   * On finish tour.
   */
  onFinishTour: () => void
}

export const CustodyFeatureGuideContent: React.FC<CustodyFeatureGuideContentProps> =
  ({ isVisible, onFinishTour }) => {
    const { t } = useTranslation('custody/common')
    const router = useRouter()
    const [, { checkPermission }] = usePermission({
      access: AccessPermissions.protected,
    })

    const steps: TourGuideStep[] = useMemo(() => {
      const custodySectionStep: TourGuideStep = {
        targetSelector: custodySectionId,
        content: (
          <FeatureStep
            description={t('custodyHomeFeatureGuide.step1.description')}
            title={t(`custodyHomeFeatureGuide.step1.title`)}
          />
        ),
        placement: 'right',
        offSet: -26,
        route: {
          pathname: custodySection.route,
        },
        isAuthorized: checkPermission(custodySection.permissions).isAuthorized,
      }

      const inviteUserStep: TourGuideStep = {
        targetSelector: roleDropdownId,
        content: (
          <FeatureStep
            description={
              <span className="max-w-112">
                <TransWithLink
                  className="text-interactive font-circular-medium"
                  i18nKey="custody/common:custodyHomeFeatureGuide.step2.description"
                  variant="internal-support"
                />
              </span>
            }
            title={t('custodyHomeFeatureGuide.step2.title')}
          />
        ),
        placement: 'top',
        offSet: 10,
        route: {
          pathname: inviteUser.route,
        },
        isAuthorized: checkPermission(inviteUser.permissions).isAuthorized,
      }

      const walletApprovalPolicyStep: TourGuideStep = {
        targetSelector: walletApprovalPolicyStepId,
        content: (
          <FeatureStep
            description={
              <TransWithLink
                className="text-interactive font-circular-medium"
                i18nKey="custody/common:custodyHomeFeatureGuide.step3.description"
                variant="internal-support"
              />
            }
            title={t('custodyHomeFeatureGuide.step3.title')}
          />
        ),
        offSet: 16,
        placement: 'right',
        route: {
          pathname: circleWalletPolicySection.route,
          query: {
            currency: Currency.USDC,
          },
        },
        isAuthorized: checkPermission(circleWalletPolicySection.permissions)
          .isAuthorized,
      }

      return [custodySectionStep, inviteUserStep, walletApprovalPolicyStep]
    }, [t, checkPermission])

    return (
      <TourGuide
        currentRoutePath={router.pathname}
        isVisible={isVisible}
        onDone={onFinishTour}
        push={router.push}
        steps={steps}
      />
    )
  }

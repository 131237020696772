/**
 * Developer Modal Routes.
 */
export const developer = {
  apiKeys: {
    revoke: '/developer/api-key/revoke',
    success: '/developer/api-key/success',
  },
  subscriptions: {
    addNewSubscription: '/developer/subscriptions/new',
    addNewSubscriptionSuccess: '/developer/subscription/new/success',
    removeSubscription: '/developer/subscriptions/remove',
  },
}

import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../Link.Context'

import { AccountAddressForm } from './Form/AccountAddress.Form'

export const AccountAddress: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')
  const [{ steps }] = useLinkBankAccount()

  return (
    <FullScreen totalSteps={steps}>
      <Center className="mb-6" variant="horizontal">
        <div className="flex w-160 flex-col gap-4">
          <h2 className="type-h-page-md">{t`link.accountAddress.title`}</h2>
          <p className="text-center text-neutral-subtle type-intro-sm">{t`link.accountAddress.description`}</p>
        </div>
      </Center>
      <Center variant="horizontal">
        <AccountAddressForm />
      </Center>
    </FullScreen>
  )
}

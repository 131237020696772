import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { limits } from '@services/sections/lib/settings'
import { Limits } from '@shared/components/common'
import {
  Currency,
  useWireTransferLimitQuery,
  WireTransferTypeEnum,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface WireTransferLimitProps {
  /**
   * Wire transfer type - deposit | withdrawal.
   */
  type: WireTransferTypeEnum
  /**
   * Wire transfer currency.
   */
  currency?: Currency
  /**
   * Custom Style?
   */
  className?: string
}

export const WireTransferLimit: React.FC<WireTransferLimitProps> = ({
  className,
  type,
  currency = Currency.USDC,
}) => {
  const { t } = useTranslation('payments')

  const { data, loading } = useWireTransferLimitQuery({
    variables: {
      currency,
      type,
    },
  })

  const title = useMemo(() => {
    return type === WireTransferTypeEnum.withdrawal
      ? t(`wireTransferLimit.withdraw.${currency}`)
      : t('wireTransferLimit.deposit')
  }, [currency, t, type])

  return (
    <div className={className}>
      <SkeletonBox className="block h-10" loading={loading}>
        {data?.wireTransferLimit && (
          <Limits
            cap={data.wireTransferLimit.cap}
            link={{
              label: t('wireTransferLimit.actionLabel'),
              href: limits.route,
            }}
            title={title}
            used={data.wireTransferLimit.used}
          />
        )}
      </SkeletonBox>
    </div>
  )
}

import { Button, Card } from '@circlefin/components'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import GetStartedWithVaultsImg from '../../images/GetStartedWithVaults.svg'

import type { StaticImageData } from 'next/image'

export interface CompletePolicyCTAProps {
  /**
   * Callback that will be invoked, once the user clicks on the CTA.
   */
  onCompleteClick: () => void
}

export const CompletePolicyCTA: React.FC<CompletePolicyCTAProps> = ({
  onCompleteClick,
}) => {
  const { t } = useTranslation('vaults')

  return (
    <Card variant="dashed/base">
      <Card.Content>
        <div className="flex w-full items-center justify-between">
          <div>
            <h2 className="text-2xl leading-7 text-black-600 font-circular-bold">
              {t`completePolicyCTA.title`}
            </h2>
            <p className="mt-2 max-w-160 text-lg text-black-600 text-opacity-70 font-circular-regular">
              {t`completePolicyCTA.description`}
            </p>
            <Button
              className="mt-4"
              onClick={onCompleteClick}
              variant="secondary"
            >
              {t`completePolicyCTA.cta`}
            </Button>
          </div>
          <Image
            alt={t`completePolicyCTA.title`}
            src={GetStartedWithVaultsImg as StaticImageData}
          />
        </div>
      </Card.Content>
    </Card>
  )
}

import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_MUTATION,
  CIRCLE_WALLETS_QUERY,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import {
  useWalletApprovalPolicyQuery,
  WalletApprovalPolicyState,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { Currency } from '@shared/graphql'

export interface CancelApprovalRequestProps {
  /**
   * Optional className that should be applied to the button.
   */
  className?: string
  /**
   * Currency.
   */
  currency: Currency
  /**
   * Policy id.
   */
  policyId: string
}

export const CancelApprovalRequest: React.FC<CancelApprovalRequestProps> = ({
  className,
  currency,
  policyId,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  const [, { isAuthorized: isAuthorizedForQuery }] =
    usePermission(CIRCLE_WALLETS_QUERY)
  const [{ Authorized }] = usePermission(CIRCLE_WALLETS_MUTATION)

  const { data } = useWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: !isAuthorizedForQuery,
  })

  if (
    data?.walletApprovalPolicy?.state !== WalletApprovalPolicyState.SUBMITTED
  ) {
    return null
  }

  return (
    <Authorized>
      <ModalLink
        pathname={routes.walletApprovalPolicy.cancelApprovalRequest}
        query={{
          policyId,
          currency,
        }}
      >
        <Button className={className} size="sm" variant="secondary">
          {t('buttons.cancelApprovalRequest')}
        </Button>
      </ModalLink>
    </Authorized>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'

export interface WalletBackupState {
  /**
   * The Two Factor token used to query the status.
   */
  twoFaToken?: string
  /**
   * The Backup token used to query the download data.
   */
  backupToken?: string
}

interface WalletBackupActions {
  /**
   * Set Two Factor token.
   */
  setTwoFaToken: (twoFaToken: string) => void
  /**
   * Set Backup token.
   */
  setBackupToken: (backupToken: string) => void
}

const WalletBackupContext = createContext<
  [WalletBackupState, WalletBackupActions]
>([
  {},
  {
    setTwoFaToken: () => null,
    setBackupToken: () => null,
  },
])

/**
 * Wallet Backup Provider props.
 */
interface WalletBackupProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<WalletBackupState>
}

export const Provider: React.FC<WalletBackupProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<WalletBackupState>({
    ...initValues,
  })

  const handleValueChange = useCallback(
    (values: Partial<WalletBackupState>) => {
      setValues((curr) => ({
        ...curr,
        ...values,
      }))
    },
    [],
  )

  const actions: WalletBackupActions = useMemo(
    () => ({
      setTwoFaToken: (twoFaToken) => handleValueChange({ twoFaToken }),
      setBackupToken: (backupToken) => handleValueChange({ backupToken }),
    }),
    [handleValueChange],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange({})
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <WalletBackupContext.Provider value={[values, actions]}>
      {children}
    </WalletBackupContext.Provider>
  )
}

/**
 * Wallet Backup hook.
 */
export const useWalletBackup = () => {
  return useContext(WalletBackupContext)
}

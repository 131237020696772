import { onError } from '@apollo/client/link/error'
import { UNAUTHORIZED } from '@circlefin/graphql/apollo/error/codes'

import { MFA_REQUIRE } from '../../lib/error/codes'

import { authHandler, mfaHandler, networkHandler } from './handlers'

/**
 * Apollo Error Link.
 */
export const errorLink = onError((errorResponse) => {
  if (errorResponse.graphQLErrors) {
    for (const error of errorResponse.graphQLErrors) {
      switch (error.extensions.code) {
        case UNAUTHORIZED:
          return authHandler(errorResponse)

        case MFA_REQUIRE:
          return mfaHandler(errorResponse)
      }
    }
  }

  if (errorResponse.networkError) {
    return networkHandler(errorResponse)
  }
})

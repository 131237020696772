import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  /**
   * (required) Password.
   */
  password: y.string().required(),
})

export type EnterPassphraseFormValues = y.InferType<typeof schema>

export interface EnterPassphraseFormProps {
  /**
   * Onsubmit handler.
   */
  onSubmit: (values: EnterPassphraseFormValues) => void
}

export const EnterPassphraseForm: React.FC<EnterPassphraseFormProps> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('modals.vault')
  const [Form] = useForm<EnterPassphraseFormValues>({
    schema,
  })

  const handleSubmit = useCallback(
    ({ password }: EnterPassphraseFormValues) => {
      onSubmit({ password })
    },
    [onSubmit],
  )

  return (
    <Form className="mt-8 sm:w-100" onSubmit={handleSubmit}>
      <Form.PasswordInput
        className="w-full"
        data-testid="input-password"
        label={t('resetPin.enterPassphrase.label')}
        name="password"
      />
      <div className="mt-12">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <Form.SubmitButton className="w-60" variant="primary">
            {t('common:continue')}
          </Form.SubmitButton>
        </Center>
      </div>
    </Form>
  )
}

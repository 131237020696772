import { AccessPermissions } from '@circlefin/permissions'
import { FeatureSwitch } from '@services/feature-switch'

import type { PermissionSet } from '@circlefin/permissions'

export const ROLR_SECTION: PermissionSet = {
  access: FeatureSwitch.rolrEnabled()
    ? AccessPermissions.publicOnly
    : AccessPermissions.restricted,
}

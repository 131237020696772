import { linkedAccounts } from '@services/sections/lib/settings'
import useTranslation from 'next-translate/useTranslation'

import { FeatureTourStep } from '../../FeatureTour.Step'

export const BankLinking: React.FC = () => {
  const { t } = useTranslation('modals.common')

  return (
    <FeatureTourStep
      data-testid="bank-linking"
      header={t`featureTour.bankLinking.header`}
      iconName="Transfer45"
      link={{
        text: t`featureTour.bankLinking.linkText`,
        url: linkedAccounts.route,
      }}
      subHeader={t(`featureTour.bankLinking.subHeader`)}
    />
  )
}

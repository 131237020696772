import { useCallback, useMemo, useState } from 'react'

import { Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen, SaveAndExit } from '@modals/layout'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import classNames from 'classnames'
import isEqual from 'lodash.isequal'
import useTranslation from 'next-translate/useTranslation'

import {
  useApprovalWorkflowValidate,
  usePermissionValidate,
  usePolicyLimitsValidate,
  usePolicySettingsValidate,
  useUpsertWalletApprovalPolicy,
  useUserLimitsValidate,
  useWalletApprovalPolicyValidate,
} from '../../containers/approval-policy'
import { useWalletApprovalPolicy } from '../../hooks/approval-policy'

import { Navigation } from './Navigation/Navigation'

import type { WalletApprovalPolicyStep } from '../../hooks/approval-policy/context'

export interface WalletApprovalPolicyProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Optional class that will be applied to the element that wraps passed children.
   */
  className?: string
  /**
   * Step that will be shown as active.
   */
  currentStep: WalletApprovalPolicyStep
  /**
   * Optional column for help text to guide the user and explain additional things .
   */
  helpTextColumn?: React.ReactNode
  /**
   * Disable save and exit, clicking on the close icon will close out the flow.
   */
  disableSaveAndExit?: boolean
}

export const WalletApprovalPolicy: React.FC<WalletApprovalPolicyProps> = ({
  children,
  className,
  currentStep,
  helpTextColumn,
  disableSaveAndExit = false,
}) => {
  const { t } = useTranslation('modals.walletApprovalPolicy')
  const modal = useModal()
  const [save, { loading, error, reset }] = useUpsertWalletApprovalPolicy({
    onCompleted: () => {
      modal.close()
    },
    // To avoid uncaught error
    onError: () => null,
  })

  const [isSaveAndExitOpen, setSaveAndExitModal] = useState(false)
  const [
    {
      isFlowComplete,
      policyInEditInitialValues,
      policyLimits,
      userLimits,
      approvalWorkflow,
      settings,
    },
  ] = useWalletApprovalPolicy()

  const permissionValidate = usePermissionValidate()
  const policyLimitValidate = usePolicyLimitsValidate()
  const userLimitsValidate = useUserLimitsValidate()
  const approvalWorkflowValidate = useApprovalWorkflowValidate()
  const policySettingsValidate = usePolicySettingsValidate()
  const walletApprovalPolicyValidate = useWalletApprovalPolicyValidate()

  const saveAndExitError = useMemo(() => {
    if (!policySettingsValidate.enableSaveExit) {
      return t`saveAndExit.error.missingPolicyMethod`
    }

    return !walletApprovalPolicyValidate.enableSaveExit
      ? t`saveAndExit.error`
      : undefined
  }, [
    policySettingsValidate.enableSaveExit,
    t,
    walletApprovalPolicyValidate.enableSaveExit,
  ])

  const onCloseSaveAndExit = useCallback(() => {
    setSaveAndExitModal(false)
    reset()
  }, [reset])

  const onClose = useCallback(() => {
    if (
      // Save and exit is disabled for this specific step
      disableSaveAndExit ||
      // user has not interacted with any of the steps, and they are creating a new policy
      (walletApprovalPolicyValidate.allStepsEmpty &&
        !policyInEditInitialValues) ||
      // policy being edited has not been changed
      isEqual(policyInEditInitialValues, {
        policyLimits,
        userLimits,
        approvalWorkflow,
        settings,
      })
    ) {
      modal.close()
      return
    }

    setSaveAndExitModal(true)
  }, [
    approvalWorkflow,
    disableSaveAndExit,
    modal,
    policyInEditInitialValues,
    policyLimits,
    settings,
    userLimits,
    walletApprovalPolicyValidate.allStepsEmpty,
  ])

  return (
    <>
      <Modal onCloseEnd={onCloseSaveAndExit} open={isSaveAndExitOpen} size="xs">
        <GraphQLErrorBoundary error={error} retry={reset} variant="page">
          <SaveAndExit
            disabled={!walletApprovalPolicyValidate.enableSaveExit}
            error={saveAndExitError}
            loading={loading}
            onSave={save}
            title={t('saveAndExit.title')}
          />
        </GraphQLErrorBoundary>
      </Modal>
      <FullScreen
        className="-mb-20 -mt-16"
        onCloseClick={onClose}
        hideProgressBar
      >
        <div className="grid w-full grid-cols-3 gap-8 px-2 py-6 xl:grid-cols-4 xl:gap-12 xl:px-6 xl:py-8 2xl:grid-cols-5 2xl:px-18">
          <div className="col-span-1">
            <Navigation
              currentStep={currentStep}
              isApprovalWorkflowValid={approvalWorkflowValidate.isValid}
              isFlowComplete={isFlowComplete}
              isPermissionsValid={permissionValidate.isValid}
              isPolicyLimitsValid={policyLimitValidate.isValid}
              isPolicySettingsValid={policySettingsValidate.isValid}
              isReviewValid={walletApprovalPolicyValidate.isValid}
              isUserLimitsValid={userLimitsValidate.isValid}
            />
          </div>
          <div
            className={classNames(
              'col-span-2 xl:col-span-2 2xl:col-span-3',
              className,
            )}
          >
            {children}
          </div>
          <div className="col-span-1 hidden xl:block">{helpTextColumn}</div>
        </div>
      </FullScreen>
    </>
  )
}

export default WalletApprovalPolicy

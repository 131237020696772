import { Icon, SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { CbitTransferInstruction } from '@shared/graphql'

export interface CbitTransferInstructionGridProps {
  /**
   * Cbit transfer instruction details.
   */
  cbitTransferInstruction?: CbitTransferInstruction
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const CbitTransferInstructionGrid: React.FC<CbitTransferInstructionGridProps> =
  ({ cbitTransferInstruction, loading = false }) => {
    const { t } = useTranslation('payments')

    return (
      <>
        <SkeletonBox className="block h-16" loading={loading}>
          <div data-testid="cbitWalletAddress">
            <CopyToClipboard
              label={t(
                'depositFlow.beneficiaryBankAccountDetails.labels.cbitWalletAddress',
              )}
              value={cbitTransferInstruction?.walletAddress ?? ''}
            />
          </div>
        </SkeletonBox>
        <div>
          <SkeletonBox className="block h-16" loading={loading}>
            <div data-testid="referenceIDPublicDescription">
              <CopyToClipboard
                label={t(
                  'depositFlow.beneficiaryBankAccountDetails.labels.referenceIDPublicDescription',
                )}
                value={cbitTransferInstruction?.trackingRef ?? ''}
              />
            </div>
          </SkeletonBox>
          <p className="mt-5 text-sm leading-5 font-circular-regular">
            <Trans
              components={{
                icon: (
                  <Icon
                    className="relative -top-px inline-block text-orange-400"
                    name="ExclamationSolid"
                    size={14}
                  />
                ),
                bold: <strong />,
              }}
              i18nKey="payments:depositFlow.beneficiaryBankAccountDetails.instructionMessage.cbit"
            />
          </p>
        </div>
      </>
    )
  }

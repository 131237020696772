import { SkeletonBox, DescriptionList } from '@circlefin/components'
import { WalletApprovalTransactionStatus } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { User, WalletApprovalTransactionDetail } from '@shared/graphql'

const { Label, Description } = DescriptionList

const concatenateNames = (users: User[]) =>
  users.map((approver) => approver.name).join(', ')

export interface WalletApprovalTransactionDetailApprovalSummaryProps
  extends Partial<
    Pick<
      WalletApprovalTransactionDetail,
      | 'approvalsNeeded'
      | 'approvedBy'
      | 'rejectedBy'
      | 'awaitingApproval'
      | 'votingUsers'
      | 'initiator'
      | 'status'
    >
  > {
  /**
   * Is loading?
   */
  loading?: boolean
}

export const WalletApprovalTransactionDetailApprovalSummary: React.FC<WalletApprovalTransactionDetailApprovalSummaryProps> =
  ({
    loading = false,
    approvalsNeeded,
    approvedBy = [],
    rejectedBy = [],
    awaitingApproval = [],
    votingUsers = [],
    initiator,
    status,
  }) => {
    const { t } = useTranslation('walletApprovalPolicy')

    const inFinalState =
      status === WalletApprovalTransactionStatus.COMPLETED ||
      status === WalletApprovalTransactionStatus.REJECTED_BY_USER ||
      status === WalletApprovalTransactionStatus.CANCELLED_BY_INITIATOR

    return (
      <>
        {/* Transaction initiator */}
        <Label className="w-80">
          {t('pendingTransactions.columns.initiatedBy')}
        </Label>
        <Description>
          <SkeletonBox className="h-6 w-48" loading={loading}>
            {initiator?.name}
          </SkeletonBox>
        </Description>

        {/* Transaction approvers */}
        <Label className="w-80">
          {t('pendingTransactions.columns.approvals')}
        </Label>
        <Description>
          <SkeletonBox className="h-6 w-48" loading={loading}>
            <div className="flex flex-col gap-y-2 text-neutral-strong">
              <div>
                {t('pendingTransactions.details.requiredApprovers', {
                  count: approvalsNeeded,
                  total: votingUsers.length,
                })}
              </div>
              {awaitingApproval.length > 0 && !inFinalState && (
                <div className="flex flex-col" data-testid="awaiting-approval">
                  <span className="italic">
                    {t('pendingTransactions.details.awaitingApprovalFrom')}
                  </span>
                  <span>{concatenateNames(awaitingApproval)}</span>
                </div>
              )}
              {approvedBy.length > 0 && (
                <div className="flex flex-col" data-testid="approved-by">
                  <span className="italic">
                    {t('pendingTransactions.details.approvedBy')}
                  </span>
                  <span>
                    <span>{concatenateNames(approvedBy)}</span>
                  </span>
                </div>
              )}
              {rejectedBy.length > 0 && (
                <div className="flex flex-col" data-testid="rejected-by">
                  <span className="italic">
                    {t('pendingTransactions.details.rejectedBy')}
                  </span>
                  <span>
                    <span>{concatenateNames(rejectedBy)}</span>
                  </span>
                </div>
              )}
            </div>
          </SkeletonBox>
        </Description>
      </>
    )
  }

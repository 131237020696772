import { useCallback } from 'react'

import { Button, LottieIcon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

export const Success: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const onDone = useCallback(() => {
    modal.close()
  }, [modal])

  return (
    <FullScreen totalSteps={4}>
      <Center>
        <LottieIcon height={65} name="Check" width={65} />
      </Center>

      <Center variant="horizontal">
        <div className="w-120">
          <div className="mt-8 text-center text-base leading-6 text-black-600">
            <h2 className="text-3xl font-circular-bold" data-testid="title">
              {t`createVaultWallet.success.title`}
            </h2>
            <p
              className="mt-5 text-xl font-circular-regular"
              data-testid="message"
            >{t`createVaultWallet.success.subtitle`}</p>
          </div>

          <div className="mt-9 w-full p-0">
            <Button className="w-full" onClick={onDone} variant="primary">
              {t`createVaultWallet.success.continue`}
            </Button>
          </div>
        </div>
      </Center>
    </FullScreen>
  )
}

import { LottieIcon } from '@circlefin/components'
import { BaseHeader } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import { WatchLists } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export interface AddToWatchListSuccessQueryProps {
  /**
   * Type of operation: `allow` or `block`.
   */
  variant?: WatchLists
}

export const AddToWatchListSuccess: React.FC<AddToWatchListSuccessQueryProps> =
  (props) => {
    const { t } = useTranslation('modals/settings/fraud-management')

    return (
      <PropsErrorBoundary<AddToWatchListSuccessQueryProps>
        props={props}
        variant="page"
      >
        {({ variant }) => (
          <BaseHeader
            title={
              variant === WatchLists.allow
                ? t`allowList.title`
                : t`blockList.title`
            }
          >
            <section className="min-h-80">
              <div className="mt-16 flex flex-col items-center justify-items-center gap-y-7">
                <LottieIcon height={65} name="Check" width={65} />
                <div className="mr-0.5 text-2xl leading-8 font-circular-medium">
                  {variant === WatchLists.allow
                    ? t`allowList.success`
                    : t`blockList.success`}
                </div>
              </div>
            </section>
          </BaseHeader>
        )}
      </PropsErrorBoundary>
    )
  }

AddToWatchListSuccess.displayName = 'AddToWatchListSuccess'

import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../../SendOnChain.Context'

import type { Amount } from '@shared/graphql'

const schema = y.object({
  /**
   * Transfer amount.
   */
  amount: y.number().required(),
})

type FormValues = y.InferType<typeof schema>

interface CircleSourceAmountFormProps {
  /**
   * Vault wallet balance object.
   */
  availableBalance?: Amount
}

export const CircleSourceAmountForm: React.FC<CircleSourceAmountFormProps> = ({
  availableBalance,
}) => {
  const { t } = useTranslation()
  const { money } = useMoney()
  const { router } = useModal()
  const [{ amount }, { setSendOnChainState }] = useSendOnChain()

  const maxAmount = Number(availableBalance?.amount ?? 0)
  const currency = availableBalance?.currency ?? Currency.USDC

  const [Form, { watch }] = useForm<FormValues>({
    schema: schema.shape({
      amount: y
        .number()
        .moreThan(0, {
          key: 'amount.moreThan',
          moreThan: money({
            number: 0,
            variant: currency,
          }),
        })
        .max(maxAmount, {
          key: 'amount.max',
          max: money({
            number: maxAmount,
            variant: currency,
          }),
        })
        .required()
        .allowEmpty(),
    }),
    defaultValues: {
      amount,
    },
    mode: 'onTouched',
  })

  /**
   * Handle Form onChange.
   */
  const handleFormOnChange = useCallback(() => {
    const amount = Number(watch('amount'))

    // Sync context state w/ form values
    setSendOnChainState({ amount })
  }, [setSendOnChainState, watch])

  /**
   * Handle form submit.
   */
  const handleFormSubmit = useCallback(() => {
    // TODO: Add logic to conditionally show correct flow step
    router.push(routes.vault.transfer.sendOnChain.review)
  }, [router])

  if (!availableBalance) {
    return null
  }

  return (
    <Form onChange={handleFormOnChange} onSubmit={handleFormSubmit}>
      <Form.MoneyInput
        className="mt-4 w-full"
        currencyVariant={currency}
        label="Amount"
        name="amount"
      />

      <Center className="pt-10" variant="horizontal">
        <Form.SubmitButton
          className="h-10 w-60"
          data-testid="submit-button"
          variant="primary"
        >
          {t`common:continue`}
        </Form.SubmitButton>
      </Center>
    </Form>
  )
}

import { navSections } from '@services/sections'
import { inAppNotificationsSection } from '@services/sections/lib/inAppNotifications'

import { InAppNotificationNavItem } from '../../InAppNotificationNavItem/InAppNotificationNavItem'
import { MainNav } from '../../MainNav/MainNav'

export const SectionNavTop: React.FC = () => {
  return (
    <MainNav data-testid="main-nav-top">
      {/* Main Sections */}
      {navSections.map((section, index) => {
        if (section.route === inAppNotificationsSection.route) {
          return <InAppNotificationNavItem key={index} />
        }

        return <MainNav.Item key={index} {...section} />
      })}
    </MainNav>
  )
}

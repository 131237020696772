import { useCallback, useState } from 'react'

import { Button, Checkbox, FeatureList } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useTriggerPinChangeMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const Confirmation: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const [agreement, setAgreement] = useState(false)
  const [triggerPinChange, { error, reset }] = useTriggerPinChangeMutation({
    onCompleted: () => {
      modal.router.push({
        pathname: routes.vault.changePin.success,
        options: {
          disableBack: true,
        },
      })
    },
    // To avoid uncaught error
    onError: () => null,
  })

  const onContinue = useCallback(() => {
    void triggerPinChange()
  }, [triggerPinChange])

  const onCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAgreement(event.target.checked)
    },
    [setAgreement],
  )

  return (
    <FullScreen>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="text-4xl text-black-600 font-circular-bold">{t`changePin.confirm.title`}</h2>

          <FeatureList className="mt-14 w-120">
            <FeatureList.Item
              heading={t`changePin.confirm.prompt1.heading`}
              icon="HashtagOutline"
            >
              <TransWithLink
                className="font-circular-book"
                i18nKey="modals.vault:changePin.confirm.prompt1.message"
                variant="internal-support"
              />
            </FeatureList.Item>
            <FeatureList.Item
              heading={t`changePin.confirm.prompt2.heading`}
              icon="LockClosedSolid"
            >
              {t`changePin.confirm.prompt2.message`}
            </FeatureList.Item>
            <FeatureList.Item
              heading={t`changePin.confirm.prompt3.heading`}
              icon="ShieldExclamationOutline"
            >
              {t`changePin.confirm.prompt3.message`}
            </FeatureList.Item>
          </FeatureList>

          <Checkbox
            checked={agreement}
            className="mt-6 text-lg leading-6 text-black-400 font-circular-regular"
            label={t`changePin.confirm.checkbox`}
            onChange={onCheckboxChange}
          />

          <Center className="pt-14" variant="horizontal">
            <Button
              className="w-60"
              disabled={!agreement}
              onClick={onContinue}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </Center>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

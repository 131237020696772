import { FixedBanner } from '@circlefin/components'
import { useDate } from '@circlefin/hooks'
import { useRolrSettingsQuery } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export interface AccountClosureBannerProps {
  /**
   * Custom styles.
   */
  className?: string
}

export const AccountClosureBanner: React.FC<AccountClosureBannerProps> = ({
  className,
}) => {
  const router = useRouter()
  const { t } = useTranslation('custody/common')
  const { date } = useDate({
    locale: router.locale,
  })

  const { data: { rolrSettings } = {} } = useRolrSettingsQuery()

  if (rolrSettings?.accountClosureDate == null) {
    return null
  }

  return (
    <div className={className}>
      <FixedBanner
        data-testid="fixed-banner-account-closure"
        status="warning"
        visible
      >
        <FixedBanner.Title>{t('accountClosure.title')}</FixedBanner.Title>
        <FixedBanner.Description>
          {t('accountClosure.description', {
            date: date(rolrSettings.accountClosureDate),
          })}
        </FixedBanner.Description>
      </FixedBanner>
    </div>
  )
}

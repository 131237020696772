import { AccessPermissions } from '@circlefin/permissions'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * User Support.
 * TODO: Should update to work in onboarding flow only using LD flag.
 */
export const USER_SUPPORT: PermissionSet = {
  access: AccessPermissions.protected,
}

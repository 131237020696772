import { GraphQLErrorBoundary } from '../../../errors'
import { Center } from '../../../layout'

import type { GraphQLErrorBoundaryProps } from '../../../errors'

export interface StateHandlerProps extends GraphQLErrorBoundaryProps {
  /**
   * Table row size.
   */
  size?: number
  /**
   * Loading state.
   */
  loading: boolean
  /**
   * Empty text.
   */
  empty: string
}

export const StateHandler: React.FC<StateHandlerProps> = ({
  children,
  size = 0,
  loading,
  empty,
  ...errorProps
}) => {
  if (loading) {
    // Table handles loading state, so we don't need to return anything here.
    return null
  }

  if (size === 0) {
    return (
      <GraphQLErrorBoundary {...errorProps} variant="component">
        <Center className="py-6 type-body-sm">{empty}</Center>
      </GraphQLErrorBoundary>
    )
  }

  return null
}

import { Button } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { BANK_ACCOUNT_MUTATION } from '@services/permissions'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export const BankAccount: React.FC<ButtonProps> = ({ children, ...props }) => {
  const [, { isAuthorized }] = usePermission(BANK_ACCOUNT_MUTATION)

  return (
    <Button
      data-testid="bank-account-button"
      {...props}
      disabled={!isAuthorized}
    >
      {children}
    </Button>
  )
}

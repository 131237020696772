import { useCallback, useMemo } from 'react'

import { useForm, y } from '@circlefin/form'
import { useMoney } from '@circlefin/hooks'
import { WalletForms } from '@features/wallets/forms'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { useCreateVault } from '../../../../../hooks/create'

import type { VaultPolicyApprovalLevel, User } from '@shared/graphql'

const transferAmountSchema = y.number().allowEmpty().moreThan(0).required()

const schema = WalletForms.Combobox.policyApproverSchema.shape({
  /**
   * Threshold transfer amount.
   */
  transferAmount: transferAmountSchema,
  /**
   * Minimum number of approvers.
   */
  minNeedConfirmed: y
    .string()
    .numeric()
    .when('selected', {
      is: (selected: User[] = []) => selected.length > 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
})

export type LevelFormValues = y.InferType<typeof schema>

export interface LevelFormProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Level information.
   */
  level?: VaultPolicyApprovalLevel
  /**
   * The threshold of the previous level (if existent).
   */
  previousLevelThreshold?: string
  /**
   * List of all approvers.
   */
  approvers: VaultPolicyApprovalLevel['approvers']
  /**
   * OnChange handler.
   */
  onChange?: (values: VaultPolicyApprovalLevel) => void
  /**
   * OnSubmit handler.
   */
  onSubmit?: (values: VaultPolicyApprovalLevel) => void
}

export const LevelForm: React.FC<LevelFormProps> = ({
  className,
  level,
  previousLevelThreshold,
  approvers,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation('modals.vault')

  const [{ usersPolicy, policyLimits }] = useCreateVault()

  const { money } = useMoney({ locale: 'en-US' })

  const handleSubmit = useCallback(
    ({ selected, transferAmount, minNeedConfirmed }: LevelFormValues) => {
      onSubmit?.({
        approvers: selected,
        transferAmount: transferAmount.toString(),
        minNeedConfirmed: minNeedConfirmed ?? '0',
      })
    },
    [onSubmit],
  )

  /**
   * Get highest transaction volume from user policies.
   */
  const maxUserAmountPerTransactionLimit = useMemo(() => {
    return (
      usersPolicy?.reduce((result, userPolicy) => {
        if (!userPolicy.maxAmount) return result

        const parsedAmount = parseFloat(userPolicy.maxAmount)
        return parsedAmount > result ? parsedAmount : result
      }, 0) ?? 0
    )
  }, [usersPolicy])

  const getUserPermissions = useCallback(
    (user: User) =>
      usersPolicy?.find((u) => u.user.id === user.id)?.permissions ?? [],
    [usersPolicy],
  )

  const [Form, { watch, resetField, setValue, formState }] =
    useForm<LevelFormValues>({
      schema: schema.shape({
        transferAmount: transferAmountSchema
          .min(parseFloat(previousLevelThreshold ?? '0'), {
            key: 'vault.createVault.approvalLevelThreshold.lowerOrEqualsPreviousThreshold',
            amount: money({
              number: previousLevelThreshold ?? '0',
              variant: 'USD',
            }),
          })
          .multipleMax({
            limits: [
              {
                /**
                 * The threshold must not be higher than the global policy limit.
                 */
                value: Number(policyLimits?.maxAmount ?? ''),
                error: {
                  key: 'vault.createVault.approvalLevelThreshold.exceedsGlobalLimit',
                  amount: money({
                    number: Number(policyLimits?.maxAmount),
                    variant: 'USD',
                  }),
                },
              },
              {
                /**
                 * The threshold must not be higher than the max value of all `max amount per transaction` values of all configured operators.
                 */
                value: maxUserAmountPerTransactionLimit,
                error: {
                  key: 'vault.createVault.approvalLevelThreshold.exceedsHighestUserLimit',
                  amount: money({
                    number: maxUserAmountPerTransactionLimit,
                    variant: 'USD',
                  }),
                },
              },
            ],
          }),
      }),
      defaultValues: {
        selected: level?.approvers ?? [],
        transferAmount: Number(level?.transferAmount ?? '0'),
        minNeedConfirmed: level?.minNeedConfirmed ?? '',
      },
    })

  const handleFormChange: React.FormEventHandler<HTMLFormElement> =
    useCallback(() => {
      onChange?.({
        transferAmount: watch('transferAmount').toString(),
        approvers: watch('selected'),
        minNeedConfirmed: watch('minNeedConfirmed')?.toString() ?? '',
      })
    }, [onChange, watch])

  const handleSelectedChange = useCallback(
    (updatedSelected: User[]) => {
      if (Number(watch('minNeedConfirmed')) > updatedSelected.length) {
        // the user has selected a `minNeedConfirmed` value greater than the updated approvers list count
        resetField('minNeedConfirmed', {
          keepDirty: false,
          keepTouched: false,
          keepError: false,
          defaultValue: '',
        })
      }

      setValue('selectedApprover', undefined)
      setValue('selected', updatedSelected)
    },
    [watch, resetField, setValue],
  )

  return (
    <Form
      className={classNames('grid grid-cols-1 gap-y-6 mt-6 w-full', className)}
      onChange={handleFormChange}
      onSubmit={handleSubmit}
    >
      <Form.MoneyInput
        className="w-full"
        currencyVariant="USD"
        label={t<string>('createVault.approval.level.form.transferAmount')}
        name="transferAmount"
        padded
      />

      <hr className="h-px border-black-100" />

      <WalletForms.Combobox.PolicyApprovers
        approvers={approvers}
        className="w-full"
        getUserPermissions={getUserPermissions}
        label={t`forms:combobox.vault.thresholdApprovers.label`}
        name="selectedApprover"
        noItemsMessage={t`forms:combobox.vault.thresholdApprovers.noItemsMessage`}
        onSelectedChange={handleSelectedChange}
        selected={watch('selected') ?? []}
      />

      <div className="flex h-full items-center">
        <Form.Dropdown
          className="w-64"
          data-testid="dropdown-num-approvers"
          items={(watch('selected') ?? []).map((_, index) => ({
            label: `${index + 1}`,
            value: `${index + 1}`,
          }))}
          label={t('createVault.shared.form.minNeedConfirmed.label')}
          name="minNeedConfirmed"
          placeholder="-"
        />
        <span
          className={classNames(
            'text-sm font-circular-book leading-5 text-black-400 ml-3 mt-5',
            {
              'mb-6':
                formState.errors.minNeedConfirmed &&
                formState.touchedFields.minNeedConfirmed &&
                !watch('minNeedConfirmed'),
            },
          )}
        >
          {t('createVault.shared.form.minNeedConfirmed.count', {
            count: watch('selected').length,
          })}
        </span>
      </div>

      <Form.SubmitButton className="w-32" variant="primary">
        {t('common:done')}
      </Form.SubmitButton>
    </Form>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { useModal, useModalHistory } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import QRCode from 'qrcode.react'

import { useBlockchainDeposit } from '../BlockchainDeposit.Context'

import type { BlockchainDepositState } from '../BlockchainDeposit.Context'

export const TransferInstructions: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [state, { resetContext }] = useBlockchainDeposit()

  const { close, router } = useModal()
  const modalHistory = useModalHistory()
  const { track } = useSegment()

  const handleDoneClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.DoneClicked, {
        event,
      })

      close()
    },
    [track, close],
  )

  const handleResetClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.DoneClicked, {
        event,
      })
      resetContext()

      router.push(routes.vault.deposit.blockchain.depositDetails)
    },
    [track, resetContext, router],
  )

  return (
    <FullScreen totalSteps={modalHistory.history.length + 2}>
      <PropsErrorBoundary<BlockchainDepositState> props={state} variant="page">
        {({ childWallet }) => {
          const {
            address,
            blockchain,
            assetMetadata: { symbol, decimals },
          } = childWallet

          const blockchainName = t(`payments:chainNames.${blockchain}`)

          return (
            <Center
              className="m-auto w-176 justify-items-center text-center"
              variant="horizontal"
            >
              <h2 className="text-4xl leading-10 text-black-600 font-circular-black">
                {t('deposit.blockchain.transferInstructions.title', {
                  blockchain: blockchainName,
                })}
              </h2>
              <p className="mt-2 text-lg leading-6 text-black-400 font-circular-regular">
                {t(`deposit.blockchain.transferInstructions.instructions`, {
                  symbol,
                  blockchain: blockchainName,
                })}
              </p>

              {address && (
                <div className="mt-6 flex h-56 w-160 flex-row rounded-md p-6 shadow-lg">
                  <div className="text-left">
                    <h4 className="text-lg leading-6 font-circular-bold">
                      {t(
                        'deposit.blockchain.transferInstructions.address.title',
                        {
                          symbol,
                        },
                      )}
                    </h4>
                    <p className="mt-2 text-base leading-5 font-circular-regular">
                      {t(
                        'deposit.blockchain.transferInstructions.address.subtitle',
                        {
                          symbol,
                          blockchain: blockchainName,
                        },
                      )}
                    </p>
                    <CopyToClipboard
                      className="mt-6"
                      label={t(
                        'deposit.blockchain.transferInstructions.address.copyPaste',
                      )}
                      value={address}
                    />
                  </div>
                  <div className="ml-12 shrink grow-0">
                    <p className="mb-2 text-base leading-5 text-black-400 font-circular-regular">
                      {t`deposit.blockchain.transferInstructions.address.scanQR`}
                    </p>
                    <QRCode
                      height="144"
                      renderAs="svg"
                      value={address}
                      width="144"
                    />
                  </div>
                </div>
              )}

              <p className="mt-6 text-left text-sm leading-5 text-black-600 font-circular-regular">
                <Trans
                  components={{
                    b: <b />,
                    br: <br />,
                  }}
                  i18nKey="modals.vault:deposit.blockchain.transferInstructions.warning"
                  values={{ symbol, decimals }}
                />
              </p>

              <p className="mt-6 text-left text-sm leading-5 text-black-600 font-circular-regular">
                <Trans
                  components={{
                    b: <b />,
                  }}
                  i18nKey="modals.vault:deposit.blockchain.transferInstructions.disclaimer"
                />
              </p>

              <Button
                className="mt-12 w-60"
                onClick={handleDoneClick}
                variant="primary"
              >
                {t('common:done')}
              </Button>

              <Button
                className="mt-6 w-60"
                onClick={handleResetClick}
                variant="text"
              >
                {t`deposit.blockchain.transferInstructions.anotherDeposit`}
              </Button>
            </Center>
          )
        }}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

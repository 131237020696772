import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Delayed withdrawal settings.
 * Which users are allowed to view delayed withdrawal settings?
 */
export const DELAYED_WITHDRAWAL_SETTINGS_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.AGENT_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_ROLE,
    Role.BUSINESS_ACCOUNT_READ_ROLE,
    Role.DELAYED_WITHDRAWAL_ROLE,
  ],
}

import { ModalRoute, ModalSwitch } from '@circlefin/modal-router'
import { SendOnchain } from '@features/blockchain/modals'
import { ApprovalRequired } from '@features/vault/modals'
import { blockchain } from '@services/sections/modal/blockchain/blockchain'

/**
 * Blockchain Router.
 */
export const BlockchainRouter: React.FC = () => {
  return (
    <>
      {/* Send-On-Chain */}
      <ModalSwitch path={blockchain.sendOnchain.switch}>
        <SendOnchain.SendOnChainProvider>
          <ModalRoute
            content={<SendOnchain.TransferRecipient />}
            path={blockchain.sendOnchain.recipient}
          />
          <ModalRoute
            content={<SendOnchain.TransferAmount />}
            path={blockchain.sendOnchain.amount}
          />
          <ModalRoute
            content={<SendOnchain.ReviewTransferDetails />}
            path={blockchain.sendOnchain.review}
          />
          <ModalRoute
            content={<ApprovalRequired />}
            path={blockchain.sendOnchain.approvalRequired}
          />
        </SendOnchain.SendOnChainProvider>
      </ModalSwitch>
    </>
  )
}

import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import { useGraphQLError } from '@shared/hooks/common'
import useTranslation from 'next-translate/useTranslation'

import type { ApolloError } from '@apollo/client'

export interface DefaultErrorProps {
  /**
   * GraphQL errors.
   */
  error?: ApolloError
}

export const Default: React.FC<DefaultErrorProps> = ({ error }) => {
  const { t } = useTranslation('common')
  const { getError } = useGraphQLError()

  const refresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <div data-testid="page-error-default">
      <Statement
        status="warning"
        subtitle={getError(error)}
        title={t`failed-loading-data`}
      >
        <Button
          data-testid="refresh-button"
          onClick={refresh}
          variant="primary"
        >{t`pageLoadError.cta`}</Button>
      </Statement>
    </div>
  )
}

import { createContext } from 'react'

import type { SidenavProps } from '@circlefin/components/lib/Sidenav'

const defaultValues: MainNavContextProps = {
  variant: 'dark',
  collapsed: false,
}

export interface MainNavContextProps
  extends Pick<SidenavProps, 'collapsed' | 'variant'> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const MainNavContext = createContext<MainNavContextProps>(defaultValues)

export const MainNavProvider: React.FC<MainNavContextProps> = ({
  children,
  ...values
}) => {
  return (
    <MainNavContext.Provider value={{ ...defaultValues, ...values }}>
      {children}
    </MainNavContext.Provider>
  )
}

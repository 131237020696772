import { Card } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { VAULT_CREATOR_MUTATION } from '@services/permissions'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'
import GetStartedWithVaultsImg from '../../images/GetStartedWithVaults.svg'

import type { StaticImageData } from 'next/image'

export const EmptyVaultListPlaceholder = () => {
  const { t } = useTranslation('vaults')
  const [, { isAuthorized }] = usePermission(VAULT_CREATOR_MUTATION)

  return (
    <Card variant="dashed/base">
      <Card.Content>
        <div className="flex w-full items-center justify-between">
          <div>
            <h2 className="text-2xl leading-7 text-black-600 font-circular-bold">
              {t(
                isAuthorized
                  ? 'emptyVaultListPlaceholder.title'
                  : 'emptyVaultListPlaceholder.noVaultAdmin.title',
              )}
            </h2>
            <p className="mt-2 max-w-160 text-lg text-black-600 text-opacity-70 font-circular-regular">
              {t(
                isAuthorized
                  ? 'emptyVaultListPlaceholder.description'
                  : 'emptyVaultListPlaceholder.noVaultAdmin.description',
              )}
            </p>
            <Buttons.Vault.Create className="mt-4" variant="primary" />
          </div>
          <Image
            alt={t`emptyVaultListPlaceholder.title`}
            src={GetStartedWithVaultsImg as StaticImageData}
          />
        </div>
      </Card.Content>
    </Card>
  )
}

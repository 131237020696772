import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

import { useLinkCreate } from '../../../Create/Create'
import { useLinkBankAccount } from '../../../Link.Context'

const schema = y.object({
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Cbit Wallet Address.
   */
  walletAddress: y.string().required(),
})

export type CbitFormValues = y.InferType<typeof schema>

export interface CbitFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: CbitFormValues) => void
}

export const CbitForm: React.FC<CbitFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [createBankAccount] = useLinkCreate()
  const [{ information }, { setInformation }] = useLinkBankAccount()

  const [Form] = useForm<CbitFormValues>({
    schema,
    mode: 'onBlur',
    values: {
      nickname: information.cbit?.nickname ?? '',
      walletAddress: information.cbit?.walletAddress ?? '',
    },
  })

  const onFormSubmit = useCallback(
    (cbit: CbitFormValues) => {
      // Set data to context
      setInformation({ cbit })

      // Pass data to submit callback (for testing)
      onSubmit?.(cbit)

      // Create Bank Account
      createBankAccount()
    },
    [createBankAccount, onSubmit, setInformation],
  )

  return (
    <div className="gap-3">
      <Form className="grid w-full gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-nickname"
          label={t('modals.bankAccount:link.accountInformation.nickname')}
          name="nickname"
        />

        <Form.Input
          className="w-full"
          data-testid="input-walletAddress"
          label={t('modals.bankAccount:link.accountInformation.walletAddress')}
          name="walletAddress"
        />

        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
        <div className="mt-6 flex items-center gap-3">
          <Icon name="SwitchHorizontalOutline" />
          <p className="text-neutral-subtle type-body-sm">
            {t(
              'modals.bankAccount:link.accountInformation.cbitPaymentTypeInfo',
            )}
          </p>
        </div>
      </Form>
    </div>
  )
}

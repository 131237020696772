import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * User management permission.
 * Which users are allowed to manage users (i.e., add, delete, edit)?
 */
export const USER_MANAGEMENT_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.USER_MANAGEMENT_ROLE],
  // all customers with the SSO_ENABLED_ROLE will manage users in their idp
  excludeAny: [Role.SSO_ENABLED_ROLE],
}

import { useCallback, useMemo } from 'react'

import { Button } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { BankAccountForms } from '@features/bank-account/forms'
import { BlockchainForms } from '@features/blockchain/forms'
import { LocalsForms } from '@features/locales/forms'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import { Currency } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../AddRoute.Context'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

export interface DepositProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: DepositFormValues) => void
  /**
   * If travel rule is required.
   */
  travelRuleRequired: boolean
  /**
   * If the component is loading.
   */
  loading: boolean
}

const schema = y.object({
  /**
   * Bank account.
   */
  bankAccount: BankAccountForms.Dropdown.BankAccountsSchema.required(),
  /**
   * Recipient address.
   */
  recipientAddress:
    BlockchainForms.Dropdown.BlockchainRecipientAddressesSchema.required(),
  /**
   * Currency.
   */
  currency: LocalsForms.Dropdown.CurrencySchema.required(),
})

export type DepositFormValues = y.InferType<typeof schema>

export const DepositForm: React.FC<DepositProps> = ({
  onSubmit,
  travelRuleRequired,
  loading,
}) => {
  const { t } = useTranslation('express/modal')
  const { router } = useModal()
  const [data, { setDeposit }] = useAddRoute()
  const [, { setCurrency }] = useCurrentCurrency({
    initialCurrency: Currency.USD,
  })

  const [Form, { resetField }] = useForm({
    schema,
    defaultValues: {
      currency: Currency.USD,
      bankAccount: undefined,
      recipientAddress: undefined,
      ...data,
    },
  })

  const onFormSubmit = useCallback(
    (values: DepositFormValues) => {
      // Set Deposit Data.
      setDeposit({
        source: values.bankAccount,
        destination: values.recipientAddress,
        currency: values.currency,
      })

      // On Form Submit (for tests)
      onSubmit?.(values)
    },
    [onSubmit, setDeposit],
  )

  const onCurrencyChange = useCallback(
    (newVal: SelectListItem<Currency | undefined>) => {
      const currencyValue = newVal.value ?? Currency.USD
      setCurrency({ currency: currencyValue })

      // We would like to reset below fields on currency change
      // as they changed based on the currency.
      resetField('bankAccount')
      resetField('recipientAddress')
    },
    [setCurrency, resetField],
  )

  const goToLinkBank = useCallback(() => {
    router.push(routes.bankAccount.link.location)
  }, [router])

  const addAddressRoute = useMemo(
    () =>
      travelRuleRequired
        ? routes.account.addressBook.addNewAddress.ownershipDisclaimer
        : routes.account.addressBook.addNewAddress.selectRecipient,
    [travelRuleRequired],
  )

  const goToAddAddress = useCallback(() => {
    router.push(addAddressRoute)
  }, [router, addAddressRoute])

  return (
    <Form className="grid w-104 gap-6" onSubmit={onFormSubmit}>
      {/* Currency */}
      <LocalsForms.Dropdown.Currency
        data-testid="dropdown-currency"
        itemsFilter={travelRuleRequired ? [Currency.USD] : []}
        label={t`addRoute.shared.currency`}
        onChange={onCurrencyChange}
        variant="fiat"
      />

      {/* Bank Source */}
      <span>
        <BankAccountForms.Dropdown.BankAccounts
          data-testid="dropdown-source"
          label={t`addRoute.deposit.source`}
          maxMenuItems={6}
          name="bankAccount"
        />

        {/* Link Bank Account */}
        <div className="mt-1 flex w-full flex-row items-center justify-end text-sm leading-5 font-circular-regular">
          <Button onClick={goToLinkBank} size="sm" variant="text">
            {t`addRoute.shared.link.bank`}
          </Button>
        </div>
      </span>

      {/* Wallet Destination */}
      <span>
        <BlockchainForms.Dropdown.RecipientAddresses
          data-testid="dropdown-destination"
          label={t`addRoute.deposit.destination`}
          name="recipientAddress"
        />

        {/* Add Address Link */}
        <div className="mt-1 flex w-full flex-row items-center justify-end text-sm leading-5 font-circular-regular">
          <Button onClick={goToAddAddress} size="sm" variant="text">
            {t`addRoute.shared.link.wallet`}
          </Button>
        </div>
      </span>

      {/* Continue */}
      <Center className="pt-6" variant="horizontal">
        <Form.SubmitButton
          className="mt-6 w-64"
          loading={loading}
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
      </Center>
    </Form>
  )
}

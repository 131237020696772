import { SkeletonBox } from '@circlefin/components'
import { CopyToClipboard } from '@circlefin/components/lib/CopyToClipboard'
import { usePermission } from '@circlefin/permissions'
import { SANDBOX_EXPERIENCE } from '@services/permissions'
import { useSegment } from '@services/segment'
import { Currency, BlockchainAbbreviation } from '@shared/graphql'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import QRCode from 'qrcode.react'

import {
  BlockchainAlert,
  BlockchainDepositNativeUSDCAlert,
  BlockchainDepositZKSAlert,
} from '../../components'

export interface TransferInformationProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Transfer instructions for this blockchain.
   */
  abbreviation: BlockchainAbbreviation
  /**
   * Blockchain address for this transfer instruction.
   */
  address?: string
  /**
   * Blockchain address tag for this transfer instruction.
   */
  addressTag?: string
  /**
   * Blockchain currency for this transfer instruction.
   */
  currency?: Currency
  /**
   * Loading state.
   */
  loading: boolean
  /**
   * Hide header.
   */
  hideHeader?: boolean
}

export const TransferInformation: React.FC<TransferInformationProps> = ({
  className,
  abbreviation,
  address,
  addressTag,
  loading,
  currency = Currency.USDC,
  hideHeader = false,
}) => {
  const { t } = useTranslation('payments')
  const { trackLinkClick } = useSegment()
  const [, { isAuthorized: isSandbox }] = usePermission(SANDBOX_EXPERIENCE)

  const blockchainName = t(`chainNames.${abbreviation}`)
  /** TODO: Revisit L2 treatment in [https://circlepay.atlassian.net/browse/BRAAV-10935]. */
  const displayCurrency = t(
    `currencyByChain.${abbreviation}`,
    { currency },
    { fallback: currency.toLowerCase() },
  )
  const transferInstructionsKey = isSandbox ? 'sandbox' : 'default'
  const displayTestnet = t(
    `testnets.${abbreviation}`,
    {},
    { fallback: t('testnets.default', { blockchainName }) },
  )

  return (
    <div
      className={classNames('grid grid-cols-1 gap-y-6', className)}
      data-testid="blockchain-deposit-instruction"
    >
      <div>
        <div>
          {!hideHeader && (
            <h2 className="text-center text-4xl leading-10 text-black-600 font-circular-black">
              {t('transferInstructions.shared.title', {
                blockchain: blockchainName,
              })}
            </h2>
          )}
          <p className="mt-2 text-center text-lg leading-6 text-black-400 font-circular-regular">
            {t(`transferInstructions.${transferInstructionsKey}.instructions`, {
              currency: displayCurrency,
              blockchainName: isSandbox ? displayTestnet : blockchainName,
            })}
          </p>

          {currency === Currency.USDC && (
            <BlockchainDepositNativeUSDCAlert
              blockchain={abbreviation}
              className="mt-4"
            />
          )}

          {abbreviation === BlockchainAbbreviation.ZKS && (
            <BlockchainDepositZKSAlert className="mt-4" />
          )}

          <BlockchainAlert
            blockchain={abbreviation}
            className="mt-4"
            isDeposit
          />

          <SkeletonBox className="mt-6 block h-56 w-176" loading={loading}>
            <div className="mt-6 flex w-176 rounded-md p-6 shadow-lg">
              <div>
                <h4 className="text-lg leading-6 font-circular-bold">
                  {t(
                    `transferInstructions.${transferInstructionsKey}.address.title`,
                    {
                      currency: displayCurrency,
                      blockchainName: displayTestnet,
                    },
                  )}
                </h4>
                <p className="mt-2 text-base leading-5 font-circular-regular">
                  {t('transferInstructions.shared.address.subtitle', {
                    currency: displayCurrency,
                    blockchain: blockchainName,
                  })}
                </p>
                <CopyToClipboard
                  className="mt-6"
                  label={t('transferInstructions.shared.address.copyPaste')}
                  value={address ?? ''}
                />
                {addressTag && (
                  <CopyToClipboard
                    className="mt-4"
                    label={t(`transferInstructions.shared.addressTag`)}
                    value={addressTag}
                  />
                )}
              </div>
              <div className="ml-12 shrink grow-0">
                <p className="mb-2 text-base leading-5 text-black-400 font-circular-regular">
                  {t`transferInstructions.shared.address.scanQR`}
                </p>
                <QRCode
                  height="144"
                  renderAs="svg"
                  value={address ?? ''}
                  width="144"
                />
              </div>
            </div>
          </SkeletonBox>

          <p className="mt-6 text-sm leading-5 text-black-600 font-circular-regular">
            <Trans
              components={{
                ...(abbreviation === BlockchainAbbreviation.AVAX
                  ? {
                      a: (
                        <a
                          className="text-sm font-circular-regular"
                          href={t(
                            `payments:transferInstructions.${transferInstructionsKey}.warning.${abbreviation}.link`,
                          )}
                          onClick={trackLinkClick}
                          rel="noopener noreferrer"
                          target="_blank"
                        />
                      ),
                    }
                  : {}),
                b: <b />,
                br: <br />,
              }}
              fallback={`payments:transferInstructions.${transferInstructionsKey}.warning.default`}
              i18nKey={
                currency === Currency.USDC
                  ? `payments:transferInstructions.${transferInstructionsKey}.warning.${abbreviation}.text`
                  : `payments:transferInstructions.${transferInstructionsKey}.warning.default`
              }
              values={{ currency: displayCurrency }}
            />
          </p>
        </div>
      </div>
      <p className="text-sm leading-5 text-black-600 font-circular-regular">
        <Trans
          components={{
            b: <b />,
          }}
          i18nKey="payments:transferInstructions.shared.disclaimer"
        />
      </p>
    </div>
  )
}

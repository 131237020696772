import { useCallback, useMemo } from 'react'

import { ComplexTable } from '@shared/components/tables'
import {
  RecipientAddressFilterBy,
  useBlockchainRecipientAddressesWithMetadataQuery,
} from '@shared/graphql'
import useIsMounted from 'ismounted'

import { useTableColumns } from './ExternalAddressesTable.Columns'

import type { Row } from '@circlefin/components/lib/AdvancedTable'
import type { BlockchainRecipientAddressWithMetadata } from '@shared/graphql'

export interface ExternalAddressesTableProps {
  /**
   * Selected rows to initialize.
   */
  initialSelected?: BlockchainRecipientAddressWithMetadata[]
  /**
   * Will be called when the user selects addresses from the table.
   */
  onRowSelect?: (addresses: BlockchainRecipientAddressWithMetadata[]) => void
}

export const ExternalAddressesTable: React.FC<ExternalAddressesTableProps> = ({
  initialSelected = [],
  onRowSelect,
}) => {
  const columns = useTableColumns()
  const isMounted = useIsMounted()

  const { data, loading, error, refetch } =
    useBlockchainRecipientAddressesWithMetadataQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          show: RecipientAddressFilterBy.VAULT_ADDRESSES,
        },
      },
    })

  const getRowId = useCallback(
    (row: BlockchainRecipientAddressWithMetadata) => row.id,
    [],
  )

  const initialSelectedAddresses: Record<string, boolean> = useMemo(
    () =>
      initialSelected.reduce(
        (prev, curr) => ({
          ...prev,
          [getRowId(curr)]: true,
        }),
        {},
      ),
    [getRowId, initialSelected],
  )

  const handleRowSelect = useCallback(
    (rows: Array<Row<BlockchainRecipientAddressWithMetadata>>) => {
      if (isMounted.current) {
        onRowSelect?.(rows.map((row) => row.original))
      }
    },
    [isMounted, onRowSelect],
  )

  return (
    <ComplexTable<BlockchainRecipientAddressWithMetadata>
      columns={columns}
      data={data?.blockchainRecipientAddressesWithMetadata}
      enableSorting={false}
      error={error}
      getRowId={getRowId}
      initialState={{
        rowSelection: initialSelectedAddresses,
      }}
      loading={loading}
      onSelectedRowsChange={handleRowSelect}
      retry={refetch}
      selectionType="page"
      enableRowSelection
    />
  )
}

export interface TitleProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

export const Title: React.FC<TitleProps> = ({ children }) => (
  <h3 className="ml-5 type-h-section-md">{children}</h3>
)

import { useCallback, useMemo, useState } from 'react'

import { y } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { USERS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { UserStatusType, useVerifiedUsersQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { NonNullableProperties } from '@circlefin/types'
import type { Maybe, User, VerifiedUsersFilterInput } from '@shared/graphql'

type UserItem = SelectListItem<User>

export const verifiedUserSchema = y.mixed<User>()

export interface VerifiedUsersProps
  extends NonNullableProperties<VerifiedUsersFilterInput> {
  /**
   * Custom Style?
   */
  className?: string
  /**
   * Onchange handler.
   */
  onChange?: (selectedUser: Maybe<User>) => void
}

const Combobox = createFormCombobox()

export const VerifiedUsers: React.FC<VerifiedUsersProps> = ({
  className,
  onChange,
  omitCurrentUser = true,
  status = [UserStatusType.ACTIVE],
  groupType,
}) => {
  const { t } = useTranslation('forms')

  const usersQuery = useVerifiedUsersQuery({
    variables: {
      filter: {
        omitCurrentUser,
        status,
        groupType,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: USERS_POLL_INTERVAL,
  })

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  // Users list.
  const usersList: UserItem[] = useMemo(() => {
    const users: UserItem[] = []

    for (const user of usersQuery.data?.verifiedUsers ?? []) {
      users.push({
        value: user,
        label: user.name,
      })
    }

    return users.sort((a, b) => a.label.localeCompare(b.label))
  }, [usersQuery.data?.verifiedUsers])

  const filteredUsers = useMemo(() => {
    if (debouncedSearchTerm) {
      return matchSorter(usersList, debouncedSearchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [debouncedSearchTerm, usersList])

  // Clear search term and trigger API combobox value changes.
  const handleOnChange = useCallback(
    (user: UserItem | null) => {
      onChange?.(user?.value ?? null)
      setSearchTerm('')
      setDebouncedSearchTerm('')
    },
    [onChange],
  )

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.account.users.noItemsMessage`,
    }),
    [t],
  )

  return (
    <Combobox
      className={className}
      data-testid="combobox-verifiedUsers"
      debounce={300}
      filtered={filteredUsers}
      inputLoading={usersQuery.loading}
      items={usersList}
      locale={locale}
      maxMenuItems={6}
      name="verifiedUsers"
      onChange={handleOnChange}
      onDebouncedInputChange={setDebouncedSearchTerm}
      onInputChange={setSearchTerm}
      placeholder={t`combobox.account.users.placeholder`}
      searchTerm={searchTerm}
    />
  )
}

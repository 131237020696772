import { useCallback } from 'react'

import { Button, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { useResendInviteMutation } from '@shared/graphql'
import { resendInvite as resendInviteUpdate } from '@shared/graphql.server/updates'
import useTranslation from 'next-translate/useTranslation'

import type { User } from '@shared/graphql'

export interface ResendInviteQueryProps {
  /**
   * User.
   */
  user?: User
}

export const ResendInvite: React.FC<ResendInviteQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/users')
  const modal = useModal()
  const toast = useToast()

  const [resendInvite, { error, loading, reset }] = useResendInviteMutation({
    update: resendInviteUpdate,
    onCompleted: () => {
      modal.close()
      toast.success(
        t(`resendInvite.success`, {
          name: props.user?.name,
        }),
      )
    },
    // We already show the error state via the `GraphQLErrorBoundary` component below,
    // and so, we don't need to define an explicit error handler -- but if we don't
    // have the onError() handler here, mocked errors in tests will fail
    onError: () => undefined,
  })

  const handleCancel = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const handleConfirm = useCallback(
    (user: User) => () => {
      void resendInvite({
        variables: {
          userId: user.id,
          email: user.email,
        },
      })
    },
    [resendInvite],
  )

  return (
    <BaseTitle title={t('resendInvite.title')} variant="center">
      <PropsErrorBoundary props={props} variant="page">
        {({ user }) => (
          <GraphQLErrorBoundary error={error} retry={reset} variant="page">
            <p className="text-sm leading-6 text-black-600">
              {t('resendInvite.description', {
                name: user.name,
              })}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={handleCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button
                loading={loading}
                onClick={handleConfirm(user)}
                variant="primary"
              >
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </GraphQLErrorBoundary>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

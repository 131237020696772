import { Logo } from '@circlefin/components'
import classNames from 'classnames'

import { Body } from './Body/Body'
import { Heading } from './Heading/Heading'

export interface NotificationScreenProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Additional classes.
   */
  className?: string
  /**
   * With Logo?
   */
  withLogo?: boolean
}

/**
 * Subcomponents Interface.
 */
interface SubComponents {
  Heading: typeof Heading
  Body: typeof Body
}

export const NotificationScreen: React.FC<NotificationScreenProps> &
  SubComponents = ({ className, children, withLogo = false }) => (
  <div
    className={classNames(
      'font-circular flex flex-col items-center text-center',
      className,
    )}
    data-testid="notification-content"
  >
    {withLogo && <Logo className="mb-10" size={3} wordMark={false} />}
    {children}
  </div>
)

NotificationScreen.displayName = 'NotificationScreen'
NotificationScreen.Heading = Heading
NotificationScreen.Body = Body

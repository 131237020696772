import { useMemo } from 'react'

import { Currency } from '@shared/graphql'

import { Grid, GridTitleEnum } from '../../../Grid/Grid'

import type { GridItem } from '../../../Grid/Grid'
import type { WireTransferInstruction } from '@shared/graphql'

export interface USDDepositInstructionsGridProps {
  /**
   * Bank transfer instruction details.
   */
  bankTransferInstruction?: WireTransferInstruction
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const USDDepositInstructionsGrid: React.FC<USDDepositInstructionsGridProps> =
  ({
    bankTransferInstruction,
    virtualAccountEnabled = false,
    loading = false,
  }) => {
    const values = useMemo(() => {
      const currencyField: GridItem = [GridTitleEnum.currency, Currency.USD]

      const beneficiaryName: GridItem = [
        GridTitleEnum.beneficiaryName,
        bankTransferInstruction?.beneficiary.name,
      ]

      const bankAccountNumber: GridItem = [
        GridTitleEnum.bankAccountNumber,
        bankTransferInstruction?.beneficiaryBank.accountNumber,
      ]

      const bankName: GridItem = [
        GridTitleEnum.bankName,
        bankTransferInstruction?.beneficiaryBank.name,
      ]

      const beneficiaryAddress: GridItem = [
        GridTitleEnum.beneficiaryAddress,
        [
          bankTransferInstruction?.beneficiary.address1 ?? '',
          bankTransferInstruction?.beneficiary.address2 ?? '',
        ].join(' '),
      ]

      const routingNumber: GridItem = [
        GridTitleEnum.routingNumber,
        bankTransferInstruction?.beneficiaryBank.routingNumber,
      ]

      const swiftCodeUSD: GridItem = [
        `swiftCode.${Currency.USD}`,
        bankTransferInstruction?.beneficiaryBank.swiftCode,
      ]

      const referenceIDMemo: GridItem = [
        GridTitleEnum.referenceIDMemo,
        bankTransferInstruction?.trackingRef,
      ]

      return [
        currencyField,
        bankAccountNumber,
        swiftCodeUSD,
        routingNumber,
        beneficiaryName,
        beneficiaryAddress,
        bankName,
        referenceIDMemo,
      ]
    }, [bankTransferInstruction])

    return (
      <Grid
        gridItems={values}
        loading={loading}
        virtualAccountEnabled={virtualAccountEnabled}
      />
    )
  }

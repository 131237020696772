import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { Currency, useBankAccountsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import * as Buttons from '../../buttons'

export const PaymentsPageHeaderActions: React.FC = () => {
  const { t } = useTranslation('payments')
  const { track } = useSegment()
  const { router } = useModal()

  const { loading } = useBankAccountsQuery()

  const handleDepositClick = useCallback(() => {
    track(SegmentEvents.DepositClicked)

    router.push({
      pathname: routes.transfer.chooseDepositType,
      query: {
        currency: Currency.USDC,
      },
    })
  }, [track, router])

  const handleTransferClick = useCallback(() => {
    track(SegmentEvents.SendClicked)

    router.push({
      pathname: routes.transfer.chooseTransferType,
      query: {
        currency: Currency.USDC,
      },
    })
  }, [track, router])

  return (
    <>
      <Buttons.AccountFunding.Transfer
        currency={Currency.USDC}
        loading={loading}
        onClick={handleTransferClick}
        type="button"
        variant="secondary"
      >
        {t`balance.transfer`}
      </Buttons.AccountFunding.Transfer>
      <Buttons.AccountFunding.Deposit
        currency={Currency.USDC}
        loading={loading}
        onClick={handleDepositClick}
        type="button"
        variant="primary"
      >
        {t`balance.deposit`}
      </Buttons.AccountFunding.Deposit>
    </>
  )
}

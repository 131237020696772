import { useMemo } from 'react'

import { Toggle } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import {
  SETTINGS_MUTATION,
  DELAYED_WITHDRAWAL_SETTINGS_MUTATION,
  IP_ALLOWLIST_SETTINGS_MUTATION,
} from '@services/permissions'

import type { ToggleProps } from '@circlefin/components/lib/Toggle'

export interface SettingsProps extends ToggleProps {
  type?: 'delayedWithdrawal' | 'ipAllowlist'
}

export const Settings: React.FC<SettingsProps> = ({
  type,
  disabled,
  ...rest
}) => {
  const permission = useMemo(() => {
    // TODO: [LEX-1257] Migrate BillingDashboard and DashboardManagement Setting Toggles
    switch (type) {
      case 'delayedWithdrawal':
        return DELAYED_WITHDRAWAL_SETTINGS_MUTATION
      case 'ipAllowlist':
        return IP_ALLOWLIST_SETTINGS_MUTATION
      default:
        return SETTINGS_MUTATION
    }
  }, [type])

  const [, { isAuthorized }] = usePermission(permission)

  return <Toggle {...rest} disabled={disabled ?? !isAuthorized} />
}

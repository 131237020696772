import { Statement } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { GraphQLErrorBoundary } from '../../../errors'

import type { GraphQLErrorBoundaryProps } from '../../../errors'

export interface EmptyStateText {
  /**
   * Empty title.
   */
  title?: string
  /**
   * Empty subtitle.
   */
  subtitle: string
}

export interface StateHandlerProps
  extends Omit<GraphQLErrorBoundaryProps, 'children'> {
  /**
   * Table row size.
   */
  size?: number
  /**
   * Loading state.
   */
  loading: boolean
  /**
   * Empty text.
   */
  empty?: EmptyStateText
}

export const StateHandler: React.FC<StateHandlerProps> = ({
  size,
  empty,
  loading,
  ...errorProps
}) => {
  const { t } = useTranslation('common')

  if (loading) {
    // Advanced Table handles loading state,
    // so we don't need to do anything here.
    return null
  }

  if (size === 0) {
    return (
      <GraphQLErrorBoundary {...errorProps} variant="component">
        <div className="p-18">
          <Statement
            iconName="DocumentsOutline"
            padded={false}
            subtitle={empty?.subtitle ?? t`emptyTable`}
            title={empty?.title}
          />
        </div>
      </GraphQLErrorBoundary>
    )
  }

  return null
}

import { Button, Icon } from '@circlefin/components'
import { signIn } from '@services/sections/lib/home'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const ActivationTokenConsumed: React.FC = () => {
  const { t } = useTranslation('onboard/common')

  return (
    <div className="flex max-w-2xl flex-col items-center text-center">
      <Icon.Circle
        intent="warning"
        name="ExclamationCircleSolid"
        size="large"
      />

      <h2 className="mt-4 text-neutral-strong type-h-page-sm">
        {t(`accountActivation.tokenConsumed.title`)}
      </h2>

      <p className="mt-6 text-neutral type-body-base">{t`accountActivation.tokenConsumed.description`}</p>

      <Link href={signIn.route} legacyBehavior passHref>
        <Button.Link className="mt-8" variant="primary">
          {t`common:sign-in`}
        </Button.Link>
      </Link>
    </div>
  )
}

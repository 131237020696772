import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { BlockchainAlert } from '@features/blockchain/components'
import { BlockchainForms } from '@features/blockchain/forms'
import { InfoAlert } from '@features/delayed-withdrawals/containers'
import { FeatureSwitch } from '@services/feature-switch'
import { Center } from '@shared/components/layout'
import { BlockchainAbbreviation, BlockchainActivityType } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useAddAddress } from '../../../AddAddress.Context'

const schema = y.object({
  /**
   * Recipient name.
   */
  recipientName: y.string().required(),
  /**
   * Email.
   */
  recipientEmail: y.string().email(),
  /**
   * Wallet address.
   */
  address: BlockchainForms.Input.AddressSchema.required(),
  /**
   * Blockchain.
   */
  blockchain: BlockchainForms.Dropdown.BlockchainSchema.required().optional(),
  /**
   * Selected asset.
   */
  asset: BlockchainForms.Dropdown.assetSchema.required(),
  /**
   * Some blockchain addresses require a memo field.
   */
  memo: BlockchainForms.Input.memoSchema,
})

export type AddAddressDefaultNonCustomerValues = y.InferType<typeof schema>

export interface AddressDetailsNonCustomerFormProps {
  /**
   * On Form Submit callback.
   */
  onSubmit: (values: AddAddressDefaultNonCustomerValues) => void
  /**
   * Loading state of mutation.
   */
  loading: boolean
}

export const AddressDetailsNonCustomerForm: React.FC<AddressDetailsNonCustomerFormProps> =
  ({ loading, onSubmit }) => {
    const { t } = useTranslation('address-book/modals')

    const [{ defaultNonCustomer }, { setDefaultNonCustomer }] = useAddAddress()

    const [Form, { watch, trigger }] =
      useForm<AddAddressDefaultNonCustomerValues>({
        schema,
        defaultValues: {
          ...defaultNonCustomer,
        },
      })

    // Form Values
    const blockchain = watch('blockchain')

    // Handle field change dependencies
    watch(({ address }, { name }) => {
      // When the blockchain field changed and address is not empty
      if (name === 'blockchain' && address !== undefined && address !== '') {
        // Trigger revalidation
        void trigger('address')
      }
    })

    const handleOnSubmit = useCallback(
      (values: AddAddressDefaultNonCustomerValues) => {
        setDefaultNonCustomer(values)
        onSubmit(values)
      },
      [onSubmit, setDefaultNonCustomer],
    )

    return (
      <Center>
        <Form className="grid w-96 gap-y-6" onSubmit={handleOnSubmit}>
          <Form.Input
            className="w-full"
            data-testid="input-recipientName"
            display="floating"
            label={t`addAddress.addressDetails.vaultsEnabled.non_customer_entity.recipientName`}
            name="recipientName"
          />

          {FeatureSwitch.newAddressBookEmailsEnabled() && (
            <Form.Input
              className="w-full"
              data-testid="input-recipientEmail"
              display="floating"
              label={t`addAddress.addressDetails.vaultsEnabled.non_customer_entity.recipientEmail`}
              name="recipientEmail"
            />
          )}

          <BlockchainForms.Dropdown.Blockchains
            activityType={BlockchainActivityType.WITHDRAWAL}
            className="w-full"
            data-testid="dropdown-blockchain"
            display="floating"
            label={t`addAddress.addressDetails.vaultsEnabled.selectBlockchain`}
          />

          <BlockchainForms.Dropdown.Assets
            chain={blockchain?.chain}
            className="w-full"
            data-testid="dropdown-asset"
            disabled={!blockchain}
            label={t`addAddress.addressDetails.vaultsEnabled.selectAsset`}
          />

          <BlockchainForms.Input.Address
            className="w-full"
            display="floating"
            placeholder={t`addAddress.addressDetails.customer.addressPlaceholder`}
          />

          <BlockchainForms.Input.Memo className="w-full" />

          <BlockchainForms.Input.Memo
            chain={blockchain?.chain}
            className="w-full"
          />

          <BlockchainAlert blockchain={blockchain?.chain} className="mt-8" />

          <InfoAlert className="mt-8" />

          {blockchain?.chain === BlockchainAbbreviation.FLOW && (
            <p className="w-full text-sm">
              <Trans
                components={{ b: <b /> }}
                i18nKey="address-book/modals:addAddress.addressDetails.flowDisclaimer"
              />
            </p>
          )}

          <Center className="pt-6" variant="horizontal">
            <Form.SubmitButton
              className="w-64"
              loading={loading}
              variant="secondary"
            >{t`common:continue`}</Form.SubmitButton>
          </Center>
        </Form>
      </Center>
    )
  }

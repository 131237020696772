import { logger } from '@circlefin/logger'

import type { ErrorHandler } from '@apollo/client/link/error'

/**
 * Handle Network Error.
 */
export const networkHandler: ErrorHandler = ({
  networkError,
  forward,
  operation,
}) => {
  if (typeof window === 'undefined' && networkError) {
    logger.warn('[GraphQL Network Error]', {
      operationName: operation.operationName,
      ...networkError,
    })
  }

  return forward(operation)
}

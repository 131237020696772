import { useCallback, useMemo } from 'react'

import { usePermission } from '@circlefin/permissions'
import {
  ROLR_REDEMPTION_ONLY_ACCOUNT,
  STABLECOIN_EXPRESS_QUERY,
} from '@services/permissions'
import { currencyToToken } from '@shared/components/common'
import { useStablecoinExpressRoutesQuery } from '@shared/graphql'

import { RedemptionOnlyExpressContext } from './context'

import type { Currency } from '@shared/graphql'

export interface RedemptionOnlyExpressProviderProps {
  children: React.ReactNode
}

export const RedemptionOnlyProvider: React.FC<RedemptionOnlyExpressProviderProps> =
  ({ children }) => {
    const [, { isAuthorized: isRolr }] = usePermission(
      ROLR_REDEMPTION_ONLY_ACCOUNT,
    )
    const [, { isAuthorized: hasExpressAccess }] = usePermission(
      STABLECOIN_EXPRESS_QUERY,
    )

    const { data, loading } = useStablecoinExpressRoutesQuery()

    const isRestricted = useMemo(
      () => isRolr && hasExpressAccess,
      [isRolr, hasExpressAccess],
    )

    const showSetupExpressBanner = useMemo(() => {
      if (!isRestricted || loading) {
        return false
      }

      // Check no Express withdrawal route is set
      return (data?.stablecoinExpressRoutes ?? []).every(
        (r) => r.__typename !== 'StablecoinExpressWithdrawalRoute',
      )
    }, [data, loading, isRestricted])

    // Express route currency has to match redeem currency
    const canRedeem = useCallback(
      (currency: Currency) => {
        if (!isRestricted) return true

        return (data?.stablecoinExpressRoutes ?? []).some(
          (r) =>
            r.__typename === 'StablecoinExpressWithdrawalRoute' &&
            r.currency != null &&
            currencyToToken(r.currency) === currencyToToken(currency),
        )
      },
      [isRestricted, data?.stablecoinExpressRoutes],
    )

    const value = useMemo(
      () => ({ showSetupExpressBanner, canRedeem }),
      [showSetupExpressBanner, canRedeem],
    )

    return (
      <RedemptionOnlyExpressContext.Provider value={value}>
        {children}
      </RedemptionOnlyExpressContext.Provider>
    )
  }

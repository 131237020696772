import { useCallback } from 'react'

import { ButtonCard, Heading, SkeletonBox } from '@circlefin/components'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { useTravelRuleRequired } from '@features/users/hooks/travel-rule'
import { SegmentEvents, useSegment } from '@services/segment'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { BlockchainActivityType, useBlockchainsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TravelRuleAlert } from '../../components'

import type { BlockchainAbbreviation } from '@shared/graphql'

export interface ChooseBlockchainProps {
  /**
   * Called when a chain is chosen.
   */
  onChoose: (blockchainAbbreviation: BlockchainAbbreviation) => void
}

export const ChooseBlockchain: React.FC<ChooseBlockchainProps> = ({
  onChoose,
}) => {
  const { t } = useTranslation('payments')
  const { track } = useSegment()
  const [{ currency }] = useCurrentCurrency()
  const { travelRuleRequired } = useTravelRuleRequired()
  const { loading, data, error, refetch } = useBlockchainsQuery({
    variables: {
      activityType: BlockchainActivityType.ALL,
      currency,
    },
  })
  const blockchains = data?.blockchains
  const skeletonAmount = 5

  const blockchainOnClick = useCallback(
    (chain: BlockchainAbbreviation) => {
      return () => {
        track(SegmentEvents.BlockchainTypeClicked, {
          props: {
            blockchain_type: chain,
          },
        })

        onChoose(chain)
      }
    },
    [onChoose, track],
  )

  return (
    <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
      <div className="text-center">
        <h2 className="type-h-page-sm">
          {t('chooseBlockchain.chooseUsdcChain', {
            currency,
          })}
        </h2>
        <p className="mt-2 font-circular text-black-600">
          {t('chooseBlockchain.supportsTheFollowingNetworksAndProtocols', {
            currency,
          })}
        </p>
      </div>

      <TravelRuleAlert className="mt-8" visible={travelRuleRequired} />

      <div className="mt-16 grid grid-cols-5 gap-3 text-black-600">
        {blockchains
          ? blockchains.map(({ chain, icon, isBridged }) => (
              <ButtonCard
                key={chain}
                className="h-32"
                data-testid="blockchain-button"
                onClick={blockchainOnClick(chain)}
                variant="primary/sm"
                hideArrow
              >
                <Heading
                  alignment="center"
                  iconName={icon}
                  layout="vertical"
                  variant="title/sm"
                >
                  <Heading.Header>{t(`chainNames.${chain}`)}</Heading.Header>
                  {Boolean(isBridged) && (
                    <Heading.Intro>{t('common:bridgedUSDC')}</Heading.Intro>
                  )}
                </Heading>
              </ButtonCard>
            ))
          : [...Array<undefined>(skeletonAmount)].map((_, i) => (
              <SkeletonBox key={i} className="block h-32" loading={loading} />
            ))}
      </div>
    </GraphQLErrorBoundary>
  )
}

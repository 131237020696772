import { useCallback } from 'react'

import { VerticalStepper } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'
import { rolrSteps } from '../../../hooks/rolr/utils'

import type { RolrSetupStepNames } from '../../../hooks/rolr/context'

export const RolrStepper: React.FC = () => {
  const { t } = useTranslation('onboard/rolr-signup')

  const [state, { isStepValid, areAllDependenciesValidForStep, goToStep }] =
    useRolrSetup()

  const onClick = useCallback(
    (desiredStep: RolrSetupStepNames) => {
      return () => goToStep(desiredStep)
    },
    [goToStep],
  )

  return (
    <VerticalStepper isInteractive={false}>
      {rolrSteps.map((step) => (
        <VerticalStepper.Step
          key={step}
          isActive={step === state.step}
          isDisabled={!areAllDependenciesValidForStep(step)}
          isStepComplete={isStepValid(step)}
          label={t(`stepper.steps.${step}`)}
          onClick={onClick(step)}
        />
      ))}
    </VerticalStepper>
  )
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { y, useFormContext } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useVaultWhitelistedRecipientAddressesQuery } from '@shared/graphql'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

// TODO: Move util logic to DataSource layer [https://circlepay.atlassian.net/browse/BIZ-1324]
import { groupAddresses } from './WhitelistedRecipientAddresses.util'

import type { AddressItem } from './WhitelistedRecipientAddresses.util'
import type {
  ComboboxOptionsLocale,
  ComboboxProps,
} from '@circlefin/components/lib/Combobox'
import type {
  BlockchainRecipientAddressWithMetadata,
  Scalars,
  VaultWalletWithVaultMetadata,
} from '@shared/graphql'

export const WhitelistedRecipientAddressesSchema =
  y.mixed<BlockchainRecipientAddressWithMetadata>()

const schema = y.object({
  /**
   * Wallets by parent.
   */
  destination: WhitelistedRecipientAddressesSchema,
})

export interface WhitelistedRecipientAddressesProps
  extends Pick<
    ComboboxProps<BlockchainRecipientAddressWithMetadata>,
    'className' | 'label' | 'placeholder' | 'disabled'
  > {
  /**
   * Selected vault wallet.
   */
  vaultWallet?: VaultWalletWithVaultMetadata
}

const Combobox = createFormCombobox()

export const WhitelistedRecipientAddresses: React.FC<WhitelistedRecipientAddressesProps> =
  ({ vaultWallet, label, placeholder, disabled, className }) => {
    const { t } = useTranslation('forms')
    const { resetField } = useFormContext<y.InferType<typeof schema>>()
    const { data, loading, refetch } =
      useVaultWhitelistedRecipientAddressesQuery({
        variables: {
          vaultId: vaultWallet?.vaultMetadata.id ?? '',
          vaultPolicyId: vaultWallet?.vaultMetadata.policyId ?? '',
          walletId: vaultWallet?.id ?? '',
        },
        skip: !vaultWallet,
      })

    const prevWalletId = useRef<Scalars['ID']['output']>(vaultWallet?.id ?? '')
    const [searchTerm, setSearchTerm] = useState('')

    // Trigger api when combobox text input changes.
    const onDebouncedInputChange = useCallback(() => {
      // TODO: Why do we need to refetch if we are not passing the searchTerm?
      void refetch({
        vaultId: vaultWallet?.vaultMetadata.id,
        vaultPolicyId: vaultWallet?.vaultMetadata.policyId ?? '',
        walletId: vaultWallet?.id,
      })
    }, [refetch, vaultWallet])

    // Clear search term & value when walletId changes.
    useEffect(() => {
      setSearchTerm('')
      onDebouncedInputChange()

      if (vaultWallet && vaultWallet.id !== prevWalletId.current) {
        // Clear form value if walletId changes (but not when mounting)
        void resetField('destination')

        return () => {
          // Set prevWalletId for comparison on mount or re-render
          prevWalletId.current = vaultWallet.id
        }
      }
    }, [resetField, vaultWallet, onDebouncedInputChange])

    // Address list.
    const addressList = useMemo(() => {
      // Customer header item
      const customerHeader: AddressItem = {
        label: t(
          'modals.vault:transfer.sendOnChain.transferRecipient.to.headerLabel.business',
        ),
        header: true,
      }

      // Non customer header item
      const nonCustomerHeader: AddressItem = {
        label: t(
          'modals.vault:transfer.sendOnChain.transferRecipient.to.headerLabel.thirdParty',
        ),
        header: true,
      }

      // group addresses into each list
      const addresses = groupAddresses(data?.vaultWhitelistedRecipientAddresses)

      // return items with headers
      return [
        ...(addresses.customer.length > 0 ? [customerHeader] : []),
        ...addresses.customer,
        ...(addresses.nonCustomer.length > 0 ? [nonCustomerHeader] : []),
        ...addresses.nonCustomer,
      ]
    }, [t, data])

    // Clear search term and trigger api combobox value changes.
    const onChange = useCallback(() => {
      setSearchTerm('')
      onDebouncedInputChange()
    }, [onDebouncedInputChange])

    // Combobox Locale.
    const locale: ComboboxOptionsLocale = useMemo(
      () => ({
        noResultsMessage: (inputValue) => (
          <span className="text-black-300">
            <Trans
              components={{
                span: <span className="text-black-400 font-circular-bold" />,
              }}
              i18nKey="common:no-search-match"
              values={{ searchTerm: inputValue }}
            />
          </span>
        ),
        clearButtonLabel: t`common:clear-search`,
        noItemsMessage: t`combobox.blockchain.recipientAddresses.noItemsMessage`,
      }),
      [t],
    )

    return (
      <Combobox
        className={className}
        data-testid="vault-whitelisted-recipient-addresses-combobox"
        debounce={300}
        disabled={disabled}
        inputLoading={loading && !disabled}
        items={addressList}
        label={
          label ??
          t`modals.vault:transfer.sendOnChain.transferRecipient.to.label`
        }
        locale={locale}
        maxMenuItems={6}
        name="destination"
        onChange={onChange}
        onDebouncedInputChange={onDebouncedInputChange}
        onInputChange={setSearchTerm}
        placeholder={
          placeholder ??
          t`modals.vault:transfer.sendOnChain.transferRecipient.to.placeholder`
        }
        searchTerm={searchTerm}
      />
    )
  }

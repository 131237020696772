import { useCallback, useMemo, useState } from 'react'

import { y } from '@circlefin/form'
import { createFormCombobox } from '@circlefin/form/Form.Combobox'
import { useBankAccountsQuery } from '@shared/graphql'
import { matchSorter } from 'match-sorter'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import { useBankAccounts } from '../../../hooks/bank-accounts'

import type { ComboboxOptionsLocale } from '@circlefin/components/lib/Combobox'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { BankAccount, BankAccountType } from '@shared/graphql'

type AccountItem = SelectListItem<BankAccount>

export const BankAccountSchema = y.mixed<BankAccount>()

export interface BankAccountsProps
  extends Omit<
    React.ComponentProps<typeof Combobox>,
    'items' | 'onChange' | 'onInputChange' | 'locale'
  > {
  /**
   * Show certain bank types.
   */
  types?: BankAccountType[]
  /**
   * Use tracking ref as tag.
   */
  useTrackingRefAsTag?: boolean
  /**
   * OnChange handler.
   */
  onChange?: (account: SelectListItem<BankAccount>) => void
}

const Combobox = createFormCombobox()

export const BankAccounts: React.FC<BankAccountsProps> = ({
  className,
  disabled,
  types,
  label,
  placeholder,
  onChange,
  useTrackingRefAsTag,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const { data, loading } = useBankAccountsQuery({
    variables: {
      types,
    },
  })
  const { getBankAccountLabel, getTrackingTag } = useBankAccounts()

  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  // Accounts list.
  const accountList: AccountItem[] = useMemo(() => {
    return (data?.bankAccounts ?? [])
      .map((account) => {
        return {
          value: account,
          label: getBankAccountLabel(account),
          tag: useTrackingRefAsTag
            ? getTrackingTag(account)
            : account.description ?? '',
        }
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [
    data?.bankAccounts,
    getBankAccountLabel,
    getTrackingTag,
    useTrackingRefAsTag,
  ])

  const filteredBankAccounts = useMemo(() => {
    if (debouncedSearchTerm) {
      return matchSorter(accountList, debouncedSearchTerm, {
        keys: ['label'],
      })
    }

    return undefined
  }, [accountList, debouncedSearchTerm])

  // Combobox Locale.
  const locale: ComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.payout.bankAccounts.noItemsMessage`,
    }),
    [t],
  )

  // Clear search term and trigger API combobox value changes.
  const handleAccountChange = useCallback(
    (account: SelectListItem<BankAccount> | null) => {
      if (account === null) return
      setSearchTerm('')
      setDebouncedSearchTerm('')
      onChange?.(account)
    },
    [onChange, setSearchTerm],
  )

  return (
    <Combobox
      {...props}
      className={className}
      data-testid="combobox-bankaccounts"
      debounce={300}
      disabled={disabled}
      filtered={filteredBankAccounts}
      inputLoading={loading}
      items={accountList}
      label={label ?? t`combobox.payout.bankAccounts.label`}
      locale={locale}
      maxMenuItems={6}
      onChange={handleAccountChange}
      onDebouncedInputChange={setDebouncedSearchTerm}
      onInputChange={setSearchTerm}
      placeholder={placeholder ?? t`combobox.payout.bankAccounts.placeholder`}
      searchTerm={searchTerm}
    />
  )
}

import { useCallback, useState } from 'react'

import { FixedBanner } from '@circlefin/components'
import { useHubspot } from '@services/hubspot/react'
import { signIn } from '@services/sections/lib/home'
import { Center } from '@shared/components/layout'
import { useSandboxSignupMutation } from '@shared/graphql'
import { useGraphQLError } from '@shared/hooks/common'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import { LandingFooter } from './LandingFooter/LandingFooter'
import { SandboxSignUpForm } from './SandboxSignUpForm/SandboxSignUpForm'

import type { SandboxSignUpFormProps } from './SandboxSignUpForm/SandboxSignUpForm'

export interface SandboxSignUpProps {
  /**
   * Custom style.
   */
  className?: string
}

export const SandboxSignUp: React.FC<SandboxSignUpProps> = ({ className }) => {
  const { submitSandboxSignupForm } = useHubspot()
  const router = useRouter()
  const { getError } = useGraphQLError()

  const [redirecting, setRedirecting] = useState(false)

  const [signup, { error, reset }] = useSandboxSignupMutation({
    onCompleted: () => {
      setRedirecting(true)
      // Route the user to okta, so they can set up MFA
      void router.push(signIn.route)
    },
    onError: () => null,
  })

  const handleReset = useCallback(() => {
    reset()
  }, [reset])

  const handleSubmit: SandboxSignUpFormProps['onSubmit'] = useCallback(
    async (values) => {
      submitSandboxSignupForm({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        entityName: values.entityName,
      })

      await signup({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            institutionName: values.entityName,
            password: values.password,
          },
        },
      })
    },
    [signup, submitSandboxSignupForm],
  )

  return (
    <>
      <div className={classNames('relative px-4 pb-32 w-full', className)}>
        <Center>
          <div aria-atomic="true" aria-live="assertive">
            <FixedBanner
              className="mb-6"
              onDismiss={handleReset}
              status="error"
              visible={Boolean(error)}
            >
              <FixedBanner.Description>
                {getError(error)}
              </FixedBanner.Description>
            </FixedBanner>
          </div>
          <SandboxSignUpForm
            onSubmit={handleSubmit}
            redirecting={redirecting}
          />
        </Center>
      </div>
      <LandingFooter className="absolute bottom-0 mt-auto" />
    </>
  )
}

import { Button } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { DASHBOARD_MANAGEMENT_MUTATION } from '@services/permissions'

import type { ButtonProps } from '@circlefin/components/lib/Button'

export const DashboardManagement: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const [, { isAuthorized }] = usePermission(DASHBOARD_MANAGEMENT_MUTATION)

  return (
    <Button
      data-testid="dashboard-management-button"
      {...props}
      disabled={!isAuthorized}
    >
      {children}
    </Button>
  )
}

/**
 * Vault Routes.
 */
export const vault = {
  createVault: {
    switch: '/vault/createVault/',
    initialize: '/vault/createVault/initialize',
    policyName: '/vault/createVault/policyName',
    permissions: '/vault/createVault/permissions',
    policyLimits: '/vault/createVault/limits',
    userLimits: '/vault/createVault/limits/user',
    outgoingAddress: '/vault/createVault/outgoingAddress',
    approval: '/vault/createVault/approval',
    walletConnect: '/vault/createVault/walletConnect',
    review: '/vault/createVault/review',
    finished: '/vault/createVault/review/finished',
  },
  vaultDetails: {
    learnMore: '/vault/vaultDetails/learnMore',
    submissionBlocked: '/vault/vaultDetails/submissionBlocked',
    discardDraftPolicy: '/vault/vaultDetails/discardDraftPolicy',
    cancelPendingApproval: '/vault/vaultDetails/cancelPendingApproval',
  },
  vaultWalletDetails: {
    unarchive: '/vault/vaultWalletDetails/unarchive',
    archive: '/vault/vaultWalletDetails/archive',
    discardDraft: '/vault/vaultWalletDetails/discardDraft',
  },
  createVaultWallet: {
    switch: '/vault/createVaultWallet/',
    selectAsset: '/vault/createVaultWallet/selectAsset',
    selectBlockchain: '/vault/createVaultWallet/selectBlockchain',
    confirm: '/vault/createVaultWallet/confirm',
    success: '/vault/createVaultWallet/success',
  },
  pairDevice: {
    switch: '/vault/pairDevice/',
    initialize: '/vault/pairDevice/initialize',
    download: '/vault/pairDevice/download',
    pair: '/vault/pairDevice/pair',
    pin: '/vault/pairDevice/pin',
    success: '/vault/pairDevice/success',
  },
  pairNewDevice: {
    confirmation: '/vault/pairNewDevice/confirmation',
    pair: '/vault/pairNewDevice/pair',
    download: '/vault/pairNewDevice/download',
  },
  changePin: {
    confirmation: '/vault/changePin/confirmation',
    success: '/vault/changePin/success',
  },
  resetPin: {
    switch: '/vault/resetPin/',
    confirmation: '/vault/resetPin/confirmation',
    uploadBackup: '/vault/resetPin/uploadBackup',
    enterPassphrase: '/vault/resetPin/enterPassphrase',
    pin: '/vault/resetPin/pin',
    success: '/vault/resetPin/success',
  },
  /**
   * Vault deposit routes.
   */
  deposit: {
    switch: '/vault/deposit/',
    chooseDepositType: '/vault/deposit/chooseDepositType',
    blockchain: {
      switch: '/vault/deposit/blockchain',
      depositDetails: '/vault/deposit/blockchain/depositDetails',
      transferInstructions: '/vault/deposit/blockchain/transferInstructions',
    },
  },
  /**
   * Vault transfer routes.
   */
  transfer: {
    switch: '/vault/transfer/',
    chooseTransferType: '/vault/transfer/chooseTransferType',
    /**
     * Send on-chain routes.
     */
    sendOnChain: {
      switch: '/vault/transfer/send-on-chain',
      recipientExternal: '/vault/transfer/send-on-chain/recipient/external',
      recipientInternal: '/vault/transfer/send-on-chain/recipient/internal',
      amount: '/vault/transfer/send-on-chain/amount',
      review: '/vault/transfer/send-on-chain/review',
      finished: '/vault/transfer/send-on-chain/finished',
    },
  },
  walletBackup: {
    switch: '/vault/walletBackup/',
    confirmation: '/vault/walletBackup/confirmation',
    createPassphrase: '/vault/walletBackup/createPassphrase',
    pin: '/vault/walletBackup/pin',
    download: '/vault/walletBackup/download',
    success: '/vault/walletBackup/success',
    error: '/vault/walletBackup/error',
  },
  walletConnect: {
    overview: '/vault/walletConnect/overview',
    confirmation: '/vault/walletConnect/confirmation',
  },
  confirmWalletActivationFee: '/vault/confirmWalletActivationFee/',
  downloadTransactionsReport: '/vault/report/downloadTransactions',
  chooseVaultType: '/vault/chooseVaultType',
}

import { useMemo } from 'react'

import { Icon, Chip, TruncatedText } from '@circlefin/components'
import { createColumnHelper } from '@circlefin/components/lib/AdvancedTable'
import { useDate, useCustomMoney } from '@circlefin/hooks'
import {
  isVaultAddressTransactionLocation,
  isVaultRecipientAddressTransactionLocation,
  isVaultWalletTransactionLocation,
} from '@services/type-guards/Vault'
import { MaybeNull } from '@shared/components/common'
import { DefaultCell } from '@shared/components/tables'
import { VaultTransactionState } from '@shared/graphql'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Locale } from '@circlefin/money/variants'
import type {
  VaultTransaction,
  VaultTransactionLocation,
  Maybe,
} from '@shared/graphql'

const renderVaultTransactionLocationColumn = (
  location: Maybe<VaultTransactionLocation> | undefined,
) => {
  if (!location) {
    return <DefaultCell />
  }

  if (isVaultWalletTransactionLocation(location)) {
    return (
      <DefaultCell>
        <div className="flex flex-col">
          <span className="font-circular-regular">{location.walletName}</span>
          <span className="text-xs font-circular-book">
            {location.vaultName}
          </span>
        </div>
      </DefaultCell>
    )
  } else if (isVaultAddressTransactionLocation(location)) {
    return (
      <DefaultCell>
        <TruncatedText>{location.address}</TruncatedText>
      </DefaultCell>
    )
  } else if (isVaultRecipientAddressTransactionLocation(location)) {
    return (
      <DefaultCell>
        <div className="flex flex-col">
          {location.nickname !== undefined ? (
            <span className="truncate font-circular-regular">
              {location.nickname}
            </span>
          ) : null}
          <TruncatedText className="text-xs font-circular-book">
            {location.address}
          </TruncatedText>
        </div>
      </DefaultCell>
    )
  }
}

const columnHelper = createColumnHelper<VaultTransaction>()

export const useVaultTransactionsTableColumns = () => {
  const { t } = useTranslation('vaults')
  const { locale } = useRouter()
  const { customMoney } = useCustomMoney({ locale: 'en-US' })
  const { date: formatDate } = useDate({ locale: locale as Locale })

  return useMemo(() => {
    /**
     * Transaction Type column.
     */
    const transactionType = columnHelper.accessor('type', {
      header: t<string>`transactionsTable.columns.type`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => (
        <DefaultCell>
          <Icon
            className="-mt-1 mr-4 inline text-black-600"
            name="LinkOutline"
            size={15}
          />
          {t(`transactionsTable.labels.type.${getValue()}`)}
        </DefaultCell>
      ),
    })

    /**
     * Transaction Amount Column.
     */
    const amount = columnHelper.accessor('amount', {
      header: t<string>`transactionsTable.columns.amount`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <DefaultCell>
            <div className="flex flex-col justify-start">
              <span className="font-circular-book">
                <MaybeNull>
                  {customMoney({
                    number: value.native.amount,
                    currencyConfig: {
                      name: value.native.asset.symbol,
                      decimals: value.native.asset.decimals,
                      isCryptoCurrency: true,
                    },
                    options: { padded: false },
                  })}
                </MaybeNull>
              </span>

              <span className="font-circular-regular">
                <MaybeNull>
                  {customMoney({
                    number: value.fiat.amount,
                    currencyConfig: {
                      name: value.fiat.asset.symbol,
                      decimals: value.fiat.asset.decimals,
                      isCryptoCurrency: false,
                    },
                  })}
                </MaybeNull>
              </span>
            </div>
          </DefaultCell>
        )
      },
    })

    /**
     * Transaction Date Column.
     */
    const date = columnHelper.accessor('createDate', {
      header: t<string>`transactionsTable.columns.date`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => (
        <DefaultCell>{formatDate(getValue(), 'date')}</DefaultCell>
      ),
    })

    /**
     * Transaction Source Column.
     */
    const from = columnHelper.accessor('source', {
      header: t<string>`transactionsTable.columns.source`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => renderVaultTransactionLocationColumn(getValue()),
    })

    /**
     * Transaction Destination Column.
     */
    const to = columnHelper.accessor('destination', {
      header: t<string>`transactionsTable.columns.destination`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => renderVaultTransactionLocationColumn(getValue()),
    })

    /**
     * Transaction Status Column.
     */
    const status = columnHelper.accessor('state', {
      header: t<string>`transactionsTable.columns.status`,
      meta: {
        className: 'w-1/5',
      },
      cell: ({ getValue }) => {
        const value = getValue()

        return (
          <DefaultCell>
            <div className="flex items-center justify-between">
              <Chip
                variant={
                  value === VaultTransactionState.failed
                    ? 'status/error'
                    : 'status/neutral'
                }
              >
                {t(`transactionsTable.labels.state.${value}`)}
              </Chip>
            </div>
          </DefaultCell>
        )
      },
    })

    return [transactionType, amount, date, from, to, status]
  }, [formatDate, customMoney, t])
}

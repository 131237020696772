import { TruncatedText, DescriptionList } from '@circlefin/components'
import { TransactionType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { TransactionDetail } from '../TransactionDetail/TransactionDetail'

import type { TransactionDetailProps } from '../TransactionDetail/TransactionDetail'
import type {
  SignetPaymentDetail as SignetPaymentDetailType,
  SignetReceive,
  SignetWithdrawal,
} from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface SignetPaymentDetailProps
  extends Pick<TransactionDetailProps, 'error'> {
  /**
   * Object that contains payment detail info.
   */
  signetPaymentDetail?: SignetPaymentDetailType
}

export const SignetPaymentDetail: React.FC<SignetPaymentDetailProps> = ({
  signetPaymentDetail,
  error,
}) => {
  const { t } = useTranslation('payments')

  const isReceive = isReceiveType(signetPaymentDetail)

  const bankDescriptionLabel = isReceive
    ? t('transactionDetail.fundingSource')
    : t('transactionDetail.destination')

  const bankAccount = isReceive
    ? signetPaymentDetail?.source
    : signetPaymentDetail?.destination

  return (
    <TransactionDetail error={error} transaction={signetPaymentDetail?.payment}>
      <Label>{bankDescriptionLabel}</Label>
      <Description>
        {bankAccount?.nickname}
        <TruncatedText>{bankAccount?.walletAddress ?? ''}</TruncatedText>
      </Description>

      {isReceive && (
        <>
          <Label>{t('transactionDetail.referenceID')}</Label>
          <Description>{bankAccount?.trackingRef}</Description>
        </>
      )}
    </TransactionDetail>
  )
}

function isReceiveType(
  signetDetail?: SignetReceive | SignetWithdrawal,
): signetDetail is SignetReceive | undefined {
  return signetDetail?.payment.type === TransactionType.signet_receive
}

import { useCallback, useState } from 'react'

import { Button } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { useCurrentCurrency } from '@features/locales/hooks/currency'
import { FeatureSwitch } from '@services/feature-switch'
import { routes } from '@services/sections/modal/routes'
import {
  Currency,
  UserRoleGroupType,
  useUserQuery,
  WalletApprovalPolicyRole,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import {
  ApprovalWarning,
  PermissionsTable,
  usePermissionValidate,
} from '../../../../containers/approval-policy'
import { WalletForms } from '../../../../forms'
import { useWalletApprovalPolicy } from '../../../../hooks/approval-policy'
import { WalletApprovalPolicyStep } from '../../../../hooks/approval-policy/context'
import { WalletApprovalPolicyLayout } from '../../../../layout'

import type { ApprovalWarningProps } from '../../../../containers/approval-policy'
import type {
  Maybe,
  User,
  WalletApprovalPolicyUserLimit,
} from '@shared/graphql'

const schema = y.object({
  /**
   * Verified User to add to permission list.
   */
  verifiedUser: WalletForms.Combobox.verifiedUserSchema,
})

type VerifiedUserFormValues = y.InferType<typeof schema>

export const Permissions: React.FC = () => {
  const { t } = useTranslation('modals.walletApprovalPolicy')

  const { router } = useModal()
  const [{ currency = Currency.USDC }] = useCurrentCurrency()

  const [Form, { reset }] = useForm<VerifiedUserFormValues>({
    schema,
    defaultValues: {
      verifiedUser: undefined,
    },
  })

  const [
    { userLimits = [], approvalWorkflow, ownerOfPolicyInEdit },
    { setUserLimits },
  ] = useWalletApprovalPolicy()

  const { isValid, atLeastOneApprover } = usePermissionValidate()

  const { loading } = useUserQuery({
    onCompleted: ({ user }) => {
      if (userLimits.length !== 0) {
        return
      }

      // initialize users policy list with the currently signed-in user/owner
      setUserLimits(
        [
          {
            user,
            permissions: [
              WalletApprovalPolicyRole.APPROVER,
              WalletApprovalPolicyRole.OPERATOR,
            ],
          },
        ],
        // this is not initiated by the user so pass false for the 'touched' value
        false,
      )
    },
  })

  const [approvalWarning, setApprovalWarning] = useState<
    Omit<ApprovalWarningProps, 'onDismiss'>
  >({
    open: false,
  })

  const onContinue = useCallback(() => {
    void router.push(
      FeatureSwitch.walletApprovalLimitsStepsEnabled()
        ? routes.walletApprovalPolicy.createPolicy.policyLimits
        : routes.walletApprovalPolicy.createPolicy.approval,
    )
  }, [router])

  const onUserSelection = useCallback(
    (selectedUser: Maybe<User>) => {
      if (selectedUser) {
        if (userLimits.find(({ user }) => user.id === selectedUser.id)) {
          reset()
          return
        }

        if (selectedUser.current) {
          // if the owner is selected, they should always be shown first in the list
          setUserLimits([
            {
              user: selectedUser,
              permissions: [
                WalletApprovalPolicyRole.APPROVER,
                WalletApprovalPolicyRole.OPERATOR,
              ],
            },
            ...userLimits,
          ])
        } else {
          setUserLimits([
            ...userLimits,
            {
              user: selectedUser,
              permissions: [WalletApprovalPolicyRole.OPERATOR],
            },
          ])
        }

        reset()
      }
    },
    [userLimits, reset, setUserLimits],
  )

  const isUserInApprovalWorkFlow = useCallback(
    (user: User) => {
      return approvalWorkflow?.approvers.find(
        (approver) => approver.id === user.id,
      )
    },
    [approvalWorkflow],
  )

  const onChange = useCallback(
    (newValue: WalletApprovalPolicyUserLimit) => {
      if (
        isUserInApprovalWorkFlow(newValue.user) &&
        !newValue.permissions.includes(WalletApprovalPolicyRole.APPROVER)
      ) {
        // show warning
        setApprovalWarning({
          open: true,
          action: 'changed',
          user: newValue.user,
        })
        return
      }

      const foundIndex = userLimits.findIndex(
        (value) => value.user.id === newValue.user.id,
      )

      if (foundIndex === -1) {
        return
      }

      const isOperator = newValue.permissions.includes(
        WalletApprovalPolicyRole.OPERATOR,
      )

      const updated = [...userLimits]
      updated[foundIndex] = {
        ...newValue,
        // if the user is no longer an operator, one who can initiate transfers, reset their user limits
        maxTransactions: isOperator
          ? updated[foundIndex].maxTransactions
          : undefined,
        maxAmount: isOperator ? updated[foundIndex].maxAmount : undefined,
      }

      setUserLimits(updated)
    },
    [isUserInApprovalWorkFlow, userLimits, setUserLimits],
  )

  const onRemove = useCallback(
    (user: User) => {
      if (isUserInApprovalWorkFlow(user)) {
        // show warning
        setApprovalWarning({
          open: true,
          action: 'removed',
          user,
        })
        return
      }

      setUserLimits(userLimits.filter((v) => v.user.id !== user.id))
    },
    [isUserInApprovalWorkFlow, userLimits, setUserLimits],
  )

  const onDismissApprovalWarning = useCallback(() => {
    setApprovalWarning((curr) => ({
      ...curr,
      open: false,
    }))
  }, [])

  return (
    <>
      <ApprovalWarning
        {...approvalWarning}
        onDismiss={onDismissApprovalWarning}
      />
      <WalletApprovalPolicyLayout
        currentStep={WalletApprovalPolicyStep.PERMISSIONS}
      >
        <div className="max-w-160">
          <h2 className="text-neutral-strong type-h-page-sm">
            {t('permissions.title')}
          </h2>
          <h3 className="mt-4 type-intro-sm">
            {t('permissions.subtitle', { currency })}
          </h3>

          <Form>
            <WalletForms.Combobox.VerifiedUsers
              className="mt-10"
              groupType={[
                UserRoleGroupType.ADMINISTRATOR,
                UserRoleGroupType.AGENT,
              ]}
              // Show the current user in combobox for selection only if you are editing a policy created by another admin
              omitCurrentUser={ownerOfPolicyInEdit?.current ?? undefined}
              onChange={onUserSelection}
            />
          </Form>

          <hr className="mt-4 h-px border-black-100" />

          <PermissionsTable
            onChange={onChange}
            onRemove={onRemove}
            userLimits={userLimits}
          />

          {!atLeastOneApprover && !loading && userLimits.length > 0 && (
            <p className="mt-4 font-circular-book type-body-sm">
              {t('permissions.atLeastOneApprover')}
            </p>
          )}

          {!isValid && !loading && userLimits.length > 0 && (
            <p className="mt-4 text-error font-circular-book type-body-sm">
              {t('permissions.atLeastOneInitiator')}
            </p>
          )}

          <div className="mt-4">
            <Button
              className="w-60"
              disabled={!isValid || loading}
              onClick={onContinue}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>
        </div>
      </WalletApprovalPolicyLayout>
    </>
  )
}

import { TransWithLink } from '@shared/components/common'
import useTranslation from 'next-translate/useTranslation'

export const NonAuthorizedRep: React.FC = () => {
  const { t } = useTranslation('onboard/account-setup')

  return (
    <div className="w-full py-4 md:pt-9">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="type-h-page-sm">{t`nonAuthorizedRep.title`}</h2>

        <p className="mt-8 text-neutral type-body-base">
          {t`nonAuthorizedRep.description`}
        </p>

        <p className="mt-6 text-neutral type-body-base">
          <TransWithLink
            i18nKey="onboard/account-setup:nonAuthorizedRep.support"
            variant="internal-support"
          />
        </p>
      </div>
    </div>
  )
}

import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { usePermission } from '@circlefin/permissions'
import { FFC_MEMO_ENABLED } from '@services/permissions/sets/account'
import useTranslation from 'next-translate/useTranslation'

import { useLinkBankAccount } from '../../../Link.Context'

const routingNumberLength = 9

const schema = y.object({
  /**
   * Bank Account Nickname.
   */
  nickname: y.string().required(),
  /**
   * Bank Account Routing Number.
   */
  routingNumber: y
    .string()
    .numeric()
    .length(routingNumberLength, { key: 'length', length: routingNumberLength })
    .required(),
  /**
   * Bank Account Number.
   */
  accountNumber: y.string().accountNumber({ variant: 'us' }).required(),
  /**
   * FFC Memo.
   */
  ffcMemo: y.string().max(60, { key: 'max', max: 60 }),
})

export type WireFormValues = y.InferType<typeof schema>

export interface WireFormProps {
  /**
   * On Form Submit.
   */
  onSubmit?: (values: WireFormValues) => void
}

export const WireForm: React.FC<WireFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const [{ information }, { setInformation }] = useLinkBankAccount()
  const [, { isAuthorized }] = usePermission(FFC_MEMO_ENABLED)

  const [Form] = useForm<WireFormValues>({
    schema,
    mode: 'onTouched',
    values: {
      nickname: information.wire?.nickname ?? '',
      accountNumber: information.wire?.accountNumber ?? '',
      routingNumber: information.wire?.routingNumber ?? '',
      ffcMemo: information.wire?.ffcMemo ?? '',
    },
  })

  const onFormSubmit = useCallback(
    (wire: WireFormValues) => {
      // Set data to context
      setInformation({ wire })

      // Pass data to submit callback (for testing)
      onSubmit?.(wire)
    },
    [onSubmit, setInformation],
  )
  return (
    <div className="gap-3">
      <Form className="grid w-full gap-6" onSubmit={onFormSubmit}>
        <Form.Input
          className="w-full"
          data-testid="input-nickname"
          label={t('modals.bankAccount:link.accountInformation.nickname')}
          name="nickname"
        />

        <Form.Input
          className="w-full"
          data-testid="input-routingNumber"
          label={t('modals.bankAccount:link.accountInformation.routingNumber')}
          name="routingNumber"
        />

        <Form.Input
          className="w-full"
          data-testid="input-accountNumber"
          label={t('modals.bankAccount:link.accountInformation.accountNumber')}
          name="accountNumber"
        />

        {isAuthorized && (
          <Form.Input
            className="w-full"
            data-testid="input-ffcMemo"
            label={t('modals.bankAccount:link.accountInformation.ffcMemo')}
            name="ffcMemo"
          />
        )}
        <Form.SubmitButton
          className="mt-3 w-full"
          data-testid="button-continue"
          variant="primary"
        >{t`common:continue`}</Form.SubmitButton>
        <div className="mt-6 flex items-center gap-3">
          <Icon name="SwitchHorizontalOutline" />
          <p className="text-neutral-subtle type-body-sm">
            {t(
              'modals.bankAccount:link.accountInformation.wireRtpPaymentTypeInfo',
            )}
          </p>
        </div>
      </Form>
    </div>
  )
}

import { Button, Icon, SkeletonBox } from '@circlefin/components'
import { homeSection } from '@services/sections/lib/home'
import { LoadingSpinner } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useUserActivationQuery } from '@shared/graphql'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { TokenConsumed } from './TokenConsumed/TokenConsumed'

export interface ResetPinCodeTokenProps {
  /**
   * Activation token.
   */
  token?: string
}

export const ResetPinCodeToken: React.FC<ResetPinCodeTokenProps> = ({
  token,
}) => {
  const { t } = useTranslation('mfa')

  const { data, loading, error, refetch } = useUserActivationQuery({
    variables: {
      token: token ?? '',
    },
    skip: !token,
  })

  if (data?.userActivation?.tokenConsumed || !token) {
    return <TokenConsumed />
  }

  return (
    <div className="flex max-w-2xl flex-col items-center text-center">
      <GraphQLErrorBoundary error={error} retry={refetch}>
        {loading ? (
          <LoadingSpinner height={65} width={65} />
        ) : (
          <Icon.Circle intent="success" name="CheckCircleSolid" size="large" />
        )}

        <h2 className="mt-4 text-neutral-strong type-h-page-sm">
          {t(`resetPinCodeToken.${!loading ? 'success' : 'loading'}.title`)}
        </h2>

        <SkeletonBox className="mt-6 h-6 w-64" loading={loading}>
          <h3 className="mt-6 text-neutral type-body-base">{t`resetPinCodeToken.success.description`}</h3>
        </SkeletonBox>

        <SkeletonBox className="mt-12 h-10 w-128" loading={loading}>
          <Link href={homeSection.route} legacyBehavior passHref>
            <Button.Link className="mt-12 w-128" variant="primary">
              {t`resetPinCodeToken.success.setUp`}
            </Button.Link>
          </Link>
        </SkeletonBox>
      </GraphQLErrorBoundary>
    </div>
  )
}

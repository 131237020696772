import { useCallback } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useEntityQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  /**
   * Checkbox state.
   */
  ownership: y.boolean().required().oneOf([true]),
})

export const OwnershipDisclaimer: React.FC = () => {
  const { t } = useTranslation('address-book/modals')
  const { router } = useModal()
  const [Form] = useForm({ schema })
  const { loading, data, error, refetch } = useEntityQuery()

  const handleSubmit = useCallback(() => {
    router.push(routes.account.addressBook.addNewAddress.customer)
  }, [router])

  return (
    <FullScreen totalSteps={4}>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <Form onSubmit={handleSubmit}>
          <Center className="mb-6 text-center" variant="horizontal">
            <h2
              className="type-h-page-sm"
              data-testid="address-ownership-disclaimer-title"
            >
              {t`addAddress.ownershipDisclaimer.title`}
            </h2>
            <h3
              className="mt-2 type-intro-sm"
              data-testid="address-ownership-disclaimer-subtitle"
            >
              {t`addAddress.ownershipDisclaimer.subtitle`}
            </h3>
            <p className="mt-20 w-136 text-center text-black-900 type-body-base">
              <TransWithLink
                i18nKey="address-book/modals:addAddress.ownershipDisclaimer.body"
                urlI18nKey="address-book/modals:addAddress.ownershipDisclaimer.articleLink"
                variant="url"
              />
            </p>
            <p className="mt-4 w-136 text-center type-body-xs">
              {t(`addAddress.ownershipDisclaimer.footnote`)}
            </p>
            <SkeletonBox className="mx-auto mt-8 h-6 w-112" loading={loading}>
              <Form.Checkbox
                className="mt-8 justify-center"
                data-testid="input-checkbox"
                label={t('addAddress.ownershipDisclaimer.checkbox', {
                  company: data?.entity?.institutionName,
                })}
                name="ownership"
              />
            </SkeletonBox>
            <Center className="mt-6" variant="horizontal">
              <Form.SubmitButton
                className="w-fit"
                data-testid="button-submit"
                variant="primary"
              >
                {t`addAddress.ownershipDisclaimer.continue`}
              </Form.SubmitButton>
            </Center>
          </Center>
        </Form>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BlockchainAbbreviation,
  Currency,
  MerchantPaymentIntentDocument,
  MerchantPaymentsDocument,
  useMerchantPaymentIntentRefundMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

export interface RefundFormValues {
  /**
   * Payment Intent Id.
   */
  paymentIntentId?: string
  /**
   * Destination Address.
   */
  address?: string
  /**
   * Destination chain.
   */
  chain?: BlockchainAbbreviation
  /**
   * Refund Amount.
   */
  refundAmount?: number
  /**
   * Refund Currency.
   */
  refundCurrency?: Currency
  /**
   * Source Currency.
   */
  sourceCurrency?: Currency | undefined
  /**
   * Agreement.
   */
  agreement: boolean
}

/**
 * Add Refund Actions.
 */
interface RefundActions {
  /**
   * Value change handler.
   */
  handleValueChange: (values: Partial<RefundFormValues>) => void
  /**
   * Invoke mutation.
   */
  initiateRefund: (values: Partial<RefundFormValues>) => void
}

const defaultValues: RefundFormValues = {
  paymentIntentId: '',
  address: '',
  chain: BlockchainAbbreviation.ETH,
  refundAmount: undefined,
  sourceCurrency: Currency.USD,
  agreement: false,
}

/**
 * Create Refund Context.
 */
const RefundContext = createContext<[RefundFormValues, RefundActions]>([
  {
    ...defaultValues,
  },
  {
    handleValueChange: () => null,
    initiateRefund: () => null,
  },
])

/**
 * Refund Provider props.
 */
interface RefundProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: RefundFormValues
}

/**
 * Add Refund Provider.
 */
export const RefundProvider: React.FC<RefundProviderProps> = ({
  children,
  ...initValues
}) => {
  const { t } = useTranslation('platform/modals')
  const modal = useModal()

  // Mfa
  const [withMfa, { mfaError, mfaSuccess, context }] = useMfa()

  const [values, setValues] = useState<RefundFormValues>({
    ...defaultValues,
    ...initValues,
  })

  const handleValueChange = useCallback((values: Partial<RefundFormValues>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  const [initiateRefundMutation] = useMerchantPaymentIntentRefundMutation({
    refetchQueries: [MerchantPaymentIntentDocument, MerchantPaymentsDocument],
    onCompleted() {
      mfaSuccess({
        message: t`payment.refund.success`,
      })
    },
    onError(error) {
      mfaError({
        error,
        message: t`payment.refund.failure`,
      })
    },
  })

  const initiateRefund = useCallback(
    (values: Partial<RefundFormValues>) => {
      withMfa({
        variant: 'MultiStep',
        onComplete: (code) => {
          if (
            values.paymentIntentId !== undefined &&
            values.refundAmount !== undefined &&
            values.refundCurrency !== undefined &&
            values.sourceCurrency !== undefined &&
            values.chain !== undefined &&
            values.address !== undefined
          ) {
            void initiateRefundMutation({
              variables: {
                input: {
                  paymentIntentId: values.paymentIntentId,
                  amount: {
                    amount: '0.00',
                    currency: values.sourceCurrency,
                  },
                  toAmount: {
                    amount: String(values.refundAmount),
                    currency: values.refundCurrency,
                  },
                  destination: {
                    address: values.address,
                    chain: values.chain,
                  },
                },
              },
              context: context(code),
            })
          }
        },
        onAbort: modal.close,
      })
    },
    [withMfa, modal.close, context, initiateRefundMutation],
  )

  useEffect(() => {
    const resetContext = () => {
      handleValueChange(defaultValues)
    }

    modal.events.on('onCloseEnd', resetContext)

    return () => {
      modal.events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <RefundContext.Provider
      value={[values, { handleValueChange, initiateRefund }]}
    >
      {children}
    </RefundContext.Provider>
  )
}

/**
 * Refund hook.
 */
export const useRefund = () => {
  return useContext(RefundContext)
}

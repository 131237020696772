import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Bank Wires mutation.
 * Which users are allowed to deposit/withdraw to a bank account?
 */
export const BANK_WIRES_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.WIRES_ROLE],
}

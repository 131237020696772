import { useEffect, useRef } from 'react'

import { guestSession } from '@services/guest-session'
import { useSegment } from '@services/segment'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

/**
 * Segment setup.
 */
export const SegmentSetup: React.FC = () => {
  const identifiedOnce = useRef(false)
  const { data: session } = useSession()
  const router = useRouter()

  const { identify, alias, group, page } = useSegment()

  /**
   * Create a session for a guest user.
   */
  useEffect(() => {
    if (session === null) {
      guestSession.setSession()
    }
  }, [session])

  /**
   * Once we have a session, identify the current user.
   */
  useEffect(() => {
    if (session?.user && !identifiedOnce.current) {
      const setup = async () => {
        // according to segment docs, we need to let alias finish before we call identify
        await alias()
        identify()
        group()

        identifiedOnce.current = true
      }

      void setup()
    }
    // identify and group do not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  /**
   * Track the initially mounted/rendered page triggered by url.
   * Also track the referrer property if there is one, the key for sourcing anlysis.
   */
  useEffect(() => {
    page()
    // Track the initially mounted/rendered page only once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * On page route change, track the page.
   * Notice that this does not track the case where the page is initially mounted/rendered, only on consecutive page changes that we call the segment page function.
   * This is because the initial page mount/render is not caused/triggered by next.js's router.
   */
  useEffect(() => {
    router.events.on('routeChangeComplete', page)

    return () => {
      router.events.off('routeChangeComplete', page)
    }
  }, [page, router])

  return null
}

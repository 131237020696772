import { cloneElement, useCallback, useMemo, useState } from 'react'

import { Button } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { ListItem } from './ListItem'

import type { ListItemProps } from './ListItem'

export interface ListProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Hides show all button.
   */
  hideShowAllButton?: boolean
  /**
   * Controls how many list items will be shown by default.
   */
  maxVisibleItems?: number
  /**
   * List children.
   */
  children:
    | Array<React.ReactElement<ListItemProps>>
    | React.ReactElement<ListItemProps>
}

interface ListFC extends React.FC<ListProps> {
  /**
   * List item to display.
   */
  Item: typeof ListItem
}

export const List: ListFC = ({
  className,
  hideShowAllButton = false,
  maxVisibleItems = 5,
  children,
}) => {
  const { t } = useTranslation('common')

  const [revealed, setRevealed] = useState(false)

  const childrenArray = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children],
  )

  const Items: React.FC = useCallback(() => {
    // A max of 5 items will be shown by default, clicking on view all, reveals the remaining items
    const sliced = revealed
      ? childrenArray
      : childrenArray.slice(0, maxVisibleItems)

    return (
      <>
        {sliced.map((item, index) => {
          return cloneElement(item, {
            ...item.props,
            key: index,
          })
        })}
      </>
    )
  }, [childrenArray, maxVisibleItems, revealed])

  const onShowAllClick = useCallback(() => {
    setRevealed(true)
  }, [])

  return (
    <ul
      className={classNames('divide-y divide-neutral-subtle w-full', className)}
    >
      <Items />
      {!revealed &&
        !hideShowAllButton &&
        childrenArray.length > maxVisibleItems && (
          <li>
            <Button onClick={onShowAllClick} size="sm" variant="text">
              {t('view-all')}
            </Button>
          </li>
        )}
    </ul>
  )
}

List.Item = ListItem

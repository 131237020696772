import { Button } from '@circlefin/components'
import { signIn } from '@services/sections/lib/home'
import classNames from 'classnames'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'

export interface HeaderPublicSignInProps {
  /**
   * Additional classes to apply the to the layout wrapper.
   */
  className?: string
}

export const HeaderPublicSignIn: React.FC<HeaderPublicSignInProps> = ({
  className,
}) => {
  const { data: session, status } = useSession()
  const { t } = useTranslation()

  /**
   * If user has session, they're already signed-in.
   */
  if (session?.user || status === 'loading') {
    return null
  }

  return (
    <span
      className={classNames(
        'col-start-3 font-circular text-base tracking-tight text-right text-black-400',
        className,
      )}
      data-testid="sign-in-container"
    >
      {t`common:have-account`}
      <Button.Link
        className="ml-2"
        href={signIn.route}
        size="sm"
        variant="text"
      >
        {t`common:sign-in`}
      </Button.Link>
    </span>
  )
}

HeaderPublicSignIn.displayName = 'HeaderPublic.SignIn'

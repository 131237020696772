import { useCallback, useState } from 'react'

import { Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { SaveAndExit, FullScreen } from '@modals/layout'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVault as CreateVaultContainer } from '../../containers'
import {
  useApprovalWorkflowValidate,
  useCreateVaultSave,
  useCreateVaultValidate,
  useOutgoingAddressValidate,
  usePermissionValidate,
  usePolicyLimitsValidate,
  usePolicyNameValidate,
  useUserLimitsValidate,
  useWalletConnectValidate,
} from '../../containers/CreateVault'
import { useCreateVault } from '../../hooks/create'

import type { CreateVaultNavigationStep } from '../../containers/Create'

export interface CreateVaultProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Optional class that will be applied to the element that wraps passed children.
   */
  className?: string
  /**
   * Optional column for help text to guide the user and explain additional things .
   */
  helpTextColumn?: React.ReactNode
  /**
   * Disable save and exit, clicking on the close icon will close out the flow.
   */
  disableSaveAndExit?: boolean
  /**
   * Step that will be shown as active.
   */
  currentStep: CreateVaultNavigationStep
}

export const CreateVault: React.FC<CreateVaultProps> = ({
  children,
  className,
  currentStep,
  disableSaveAndExit = false,
  helpTextColumn,
}) => {
  const { t } = useTranslation('modals.vault')
  const { close } = useModal()
  const [save, { loading, error, reset }] = useCreateVaultSave({
    onCompleted: () => {
      close()
    },
    // To avoid uncaught error
    onError: () => null,
  })

  const [{ isFlowComplete, isWalletConnectStepVisible }] = useCreateVault()

  const [isSaveAndExitOpen, setSaveAndExitModal] = useState(false)

  const policyNameValidate = usePolicyNameValidate()
  const permissionValidate = usePermissionValidate()
  const policyLimitValidate = usePolicyLimitsValidate()
  const userLimitsValidate = useUserLimitsValidate()
  const outgoingAddressValidate = useOutgoingAddressValidate()
  const approvalWorkflowValidate = useApprovalWorkflowValidate()
  const walletConnectValidate = useWalletConnectValidate()
  const createVaultValidate = useCreateVaultValidate()

  const isAllStepsEmpty =
    policyNameValidate.isEmpty &&
    permissionValidate.isEmpty &&
    policyLimitValidate.isEmpty &&
    userLimitsValidate.isEmpty &&
    outgoingAddressValidate.isEmpty &&
    approvalWorkflowValidate.isEmpty &&
    walletConnectValidate.isEmpty

  const onCloseSaveAndExit = useCallback(() => {
    setSaveAndExitModal(false)
    reset()
  }, [reset])

  const onClose = useCallback(() => {
    if (disableSaveAndExit || isAllStepsEmpty) {
      close()
      return
    }

    setSaveAndExitModal(true)
  }, [close, disableSaveAndExit, isAllStepsEmpty])

  return (
    <>
      <Modal onCloseEnd={onCloseSaveAndExit} open={isSaveAndExitOpen} size="xs">
        <GraphQLErrorBoundary error={error} retry={reset} variant="page">
          <SaveAndExit
            disabled={!createVaultValidate.enableSaveExit}
            error={
              !createVaultValidate.enableSaveExit
                ? t('createVault.saveAndExit.error')
                : undefined
            }
            loading={loading}
            onSave={save}
            title={t('createVault.saveAndExit.title')}
          />
        </GraphQLErrorBoundary>
      </Modal>
      <FullScreen
        className="-mb-20 -mt-16"
        onCloseClick={onClose}
        hideProgressBar
      >
        <div className="grid w-full grid-cols-3 gap-8 px-2 py-6 xl:grid-cols-4 xl:gap-12 xl:px-6 xl:py-8 2xl:grid-cols-5 2xl:px-18">
          <div className="col-span-1">
            <CreateVaultContainer.Navigation
              currentStep={currentStep}
              isApprovalWorkflowValid={approvalWorkflowValidate.isValid}
              isFlowComplete={isFlowComplete}
              isOutgoingAddressValid={outgoingAddressValidate.isValid}
              isPermissionsValid={permissionValidate.isValid}
              isPolicyLimitsValid={policyLimitValidate.isValid}
              isPolicyNameValid={policyNameValidate.isValid}
              isReviewValid={createVaultValidate.isValid}
              isUserLimitsValid={userLimitsValidate.isValid}
              isWalletConnectStepVisible={isWalletConnectStepVisible}
              isWalletConnectValid={walletConnectValidate.isValid}
            />
          </div>
          <div
            className={classNames(
              'col-span-2 xl:col-span-3 2xl:col-span-4',
              className,
            )}
          >
            {children}
          </div>
          <div className="hidden">{helpTextColumn}</div>
        </div>
      </FullScreen>
    </>
  )
}

export default CreateVault

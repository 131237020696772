import { useContext, useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { MfaContext } from '@shared/mfa/lib/context'
import useTranslation from 'next-translate/useTranslation'

import { Layout } from '../Layout/Layout'

import type { MfaError } from '@shared/mfa/lib/context'

export const Error: React.FC<MfaError> = ({ message, error }) => {
  const modal = useModal()
  const { request } = useContext(MfaContext)
  const { t } = useTranslation('mfa')

  useEffect(() => {
    // Call on modal close
    const onClose = () => {
      request.onAbort?.()
    }

    // Subscribe to modal close event
    modal.events.on('onCloseEnd', onClose)

    return () => {
      // Unsubscribe to modal close event
      modal.events.off('onCloseEnd', onClose)
    }
  })

  return (
    <Layout title={t`error`}>
      <GraphQLErrorBoundary
        error={error}
        fallbackError={message}
        retry={modal.router.back}
        variant="page"
      />
    </Layout>
  )
}

import { useCallback, useMemo, useState } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { y } from '@circlefin/form'
import { createFormMultiSelectCombobox } from '@circlefin/form/Form.MultiSelectCombobox'
import { useBlockchainAssetsQuery } from '@shared/graphql'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type {
  MultiSelectComboboxItem,
  MultiSelectComboboxOptionsLocale,
} from '@circlefin/components/lib/MultiSelectCombobox'
import type { Blockchain, BlockchainAsset } from '@shared/graphql'

export type AssetOptions = MultiSelectComboboxItem<
  BlockchainAsset['txcoreCurrency']
>

export const assetSchema = y.array(y.mixed<AssetOptions>())

export interface AssetsProps {
  /**
   * Selected blockchain.
   */
  blockchain?: Blockchain
  /**
   * Label.
   */
  label?: string
  /**
   * Placeholder.
   */
  placeholder?: string
  /**
   * Disabled.
   */
  disabled?: boolean
  /**
   * Custom style.
   */
  className?: string
}

const MultiSelectCombobox = createFormMultiSelectCombobox()

export const Assets: React.FC<AssetsProps> = ({
  blockchain,
  label,
  placeholder,
  disabled,
  className,
  ...props
}) => {
  const { t } = useTranslation('forms')

  const { loading, data } = useBlockchainAssetsQuery({
    variables: {
      chain: blockchain?.chain,
    },
  })

  const [searchTerm, setSearchTerm] = useState('')

  const allOption = useMemo((): AssetOptions | undefined => {
    const assets = data?.blockchainAssets ?? []

    if (!blockchain || assets.length <= 1) {
      return
    }

    return {
      value: 'all',
      icon: blockchain.icon,
      label: t('payments:allAssets', { chainName: blockchain.name }),
      selectAll: true,
    }
  }, [blockchain, data?.blockchainAssets, t])

  const items = useMemo(() => {
    const assets = data?.blockchainAssets ?? []
    const items: AssetOptions[] = []

    if (!blockchain) {
      return []
    }

    if (allOption) {
      items.push(allOption)
    }

    assets
      .filter((asset) => asset.txcoreCurrency != null)
      .forEach((asset) => {
        items.push({
          value: asset.txcoreCurrency,
          label: asset.name ?? asset.symbol,
          icon: asset.icon,
          description: asset.name ? asset.symbol : undefined,
        })
      })

    return items
  }, [allOption, blockchain, data?.blockchainAssets])

  const filtered = useMemo(
    () =>
      items.filter((item) => {
        const lowerCaseSearch = searchTerm.toLowerCase()

        return (
          item.label.toLowerCase().startsWith(lowerCaseSearch) ||
          item.description?.toLowerCase().startsWith(lowerCaseSearch)
        )
      }),
    [items, searchTerm],
  )

  // Combobox Locale.
  const locale: MultiSelectComboboxOptionsLocale = useMemo(
    () => ({
      noResultsMessage: (inputValue: string) => (
        <span className="text-black-300">
          <Trans
            components={{
              span: <span className="text-black-400 font-circular-bold" />,
            }}
            i18nKey="common:no-search-match"
            values={{ searchTerm: inputValue }}
          />
        </span>
      ),
      clearButtonLabel: t`common:clear-search`,
      noItemsMessage: t`combobox.vault.assets.noItemsMessage`,
    }),
    [t],
  )

  // Update search term.
  const onInputChange = useCallback((search: string) => {
    setSearchTerm(search)
  }, [])

  return (
    <SkeletonBox
      className={classNames('w-full h-10 mt-6', className)}
      loading={loading}
    >
      <MultiSelectCombobox
        {...props}
        className={className}
        disabled={disabled}
        items={filtered}
        label={label ?? t`combobox.vault.assets.label`}
        locale={locale}
        name="assets"
        onInputChange={onInputChange}
        placeholder={placeholder ?? t`combobox.vault.assets.placeholder`}
        searchTerm={searchTerm}
      />
    </SkeletonBox>
  )
}

import { IN_APP_NOTIFICATION_SECTION } from '@services/permissions'

import type { Section } from '../types'

export const allInAppNotificationsSection: Section = {
  label: 'layout:page.inAppNotifications.all.title',
  route: '/notifications/all',
  permissions: IN_APP_NOTIFICATION_SECTION,
  segment: {
    page: 'In app notifications - All',
  },
}

export const unreadInAppNotificationsSection: Section = {
  label: 'layout:page.inAppNotifications.unread.title',
  route: '/notifications/unread',
  permissions: IN_APP_NOTIFICATION_SECTION,
  segment: {
    page: 'In app notifications - Unread',
  },
}

export const inAppNotificationsSection: Section = {
  label: 'layout:page.inAppNotifications.title',
  route: '/notifications/unread',
  permissions: IN_APP_NOTIFICATION_SECTION,
  icon: 'BellOutline',
  segment: {
    page: 'In app notifications - Unread',
  },
  pages: [unreadInAppNotificationsSection, allInAppNotificationsSection],
}

import { y } from '@circlefin/form'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { createFormInput } from '@circlefin/form/Form.Input'
import { CountryCode, StateCode } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useStates } from '../../../hooks/states'

import type { StatesInput } from '@shared/graphql'

export interface StateFormProps extends StatesInput {
  /**
   * Adjust the height of the dropdown menu, default is 6, use 2 or 3 in tight spaces e.g. modals.
   */
  maxMenuItems?: 2 | 3 | 6
  /**
   * Align the drop menu; default is 'down,' use 'up' to expand a menu upward e.g. Windows start menu.
   */
  dropDirection?: 'up' | 'down'
  /**
   * Default Value.
   */
  state?: StateCode | string
  /**
   * Custom style?
   */
  className?: string
  /**
   * Display of label.
   */
  display?: 'floating' | 'fixed' | 'inline'
  /**
   * Is disabled?
   */
  disabled?: boolean
  /**
   * Is warning?
   */
  warning?: boolean
  /**
   * Custom label.
   */
  label?: string
  /**
   * Field name.
   */
  name?: string
}

const dropdownStates = [CountryCode.US, CountryCode.CA] // List of countries to display dropdown variant

export const stateSchema = y.string().when('country', {
  is: (country: CountryCode) => dropdownStates.includes(country),
  then: (schema) => schema.oneOf(Object.values(StateCode), { key: 'state' }),
  otherwise: (schema) => schema,
})

const Dropdown = createFormDropdown()
const Input = createFormInput()

export const State: React.FC<StateFormProps> = ({
  maxMenuItems = 6,
  dropDirection = 'down',
  country,
  className,
  disabled = false,
  warning = false,
  display = 'fixed',
  label,
  name = 'state',
  ...filters
}) => {
  const { t } = useTranslation('forms')
  const { states } = useStates({
    country,
    ...{
      codes: filters.codes,
      state: filters.state,
      link: filters.link,
    },
  })

  if (dropdownStates.includes(country)) {
    return (
      <Dropdown
        className={className}
        data-testid="dropdown-state"
        disabled={disabled}
        display={display}
        dropDirection={dropDirection}
        items={states.toDropdown()}
        label={label ?? t(`dropdown.locale.state.options.${country}`)}
        maxMenuItems={maxMenuItems}
        name={name}
        warning={warning}
      />
    )
  }

  return (
    <Input
      className={className}
      data-testid="input-state"
      disabled={disabled}
      label={label ?? t('dropdown.locale.state.label')}
      name={name}
      warning={warning}
    />
  )
}

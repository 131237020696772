import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Allow users to withdraw to a bank account.
 * In the initial stage, we will assign WIRE_WITHDRAW_ROLE to new EU redemption-only customers, existing customers will have WIRES_ROLE
 * WIRES_ROLE will be deprecated in the future.
 */
export const BANK_WITHDRAW: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WIRES_ROLE, Role.WIRES_WITHDRAW_ROLE],
}

import type {
  CircleInternalWallet,
  DirectInternalWallet,
  VaultWalletWithVaultMetadata,
} from '@shared/graphql'

/**
 * Type guard to check if an auto transfer wallet is a circle internal wallet.
 */
export function isCircleInternalWallet(
  wallet?:
    | CircleInternalWallet
    | DirectInternalWallet
    | VaultWalletWithVaultMetadata,
): wallet is CircleInternalWallet {
  if (!wallet) {
    return false
  }
  return wallet.__typename === 'CircleInternalWallet'
}

/**
 * Type guard to check if an auto transfer wallet is a direct internal wallet.
 */
export function isDirectInternalWallet(
  wallet?:
    | CircleInternalWallet
    | DirectInternalWallet
    | VaultWalletWithVaultMetadata,
): wallet is DirectInternalWallet {
  if (!wallet) {
    return false
  }
  return wallet.__typename === 'DirectInternalWallet'
}

/**
 * Type guard to check if an auto transfer wallet is a vault wallet.
 */
export function isVaultWallet(
  wallet?:
    | CircleInternalWallet
    | DirectInternalWallet
    | VaultWalletWithVaultMetadata,
): wallet is VaultWalletWithVaultMetadata {
  if (!wallet) {
    return false
  }
  return wallet.__typename === 'VaultWalletWithVaultMetadata'
}

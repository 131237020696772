import { Table } from '@circlefin/components'
import { SimpleTable, TableCard } from '@shared/components/tables'
import { Currency } from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { UserLimitsRow } from './UserLimits.Row'

import type { UserLimitsRowProps } from './UserLimits.Row'
import type { WalletApprovalPolicyUserLimit } from '@shared/graphql'

export interface UserLimitsTableProps
  extends Omit<UserLimitsRowProps, 'userLimit'> {
  /**
   * Custom style.
   */
  className?: string
  /**
   * User limits.
   */
  userLimits?: WalletApprovalPolicyUserLimit[]
}

export const UserLimitsTable: React.FC<UserLimitsTableProps> = ({
  className,
  currency = Currency.USD,
  userLimits = [],
  policyLimits,
  onChange,
  onError,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')

  return (
    <TableCard>
      <SimpleTable
        className={classNames('table-fixed', className)}
        empty={t`common:emptyTable`}
        loading={false}
        size={userLimits.length}
      >
        <Table.Head>
          <Table.Head.Row>
            <Table.Head.Cell className="px-4 text-sm normal-case">
              {t('limits.headers.user')}
            </Table.Head.Cell>
            <Table.Head.Cell className="px-4 text-sm normal-case">
              {t('limits.headers.maxAmount')}
            </Table.Head.Cell>
            <Table.Head.Cell className="px-4 text-sm normal-case">
              {t('limits.headers.maxTransactions')}
            </Table.Head.Cell>
          </Table.Head.Row>
        </Table.Head>
        <Table.Body>
          {userLimits.map((userLimit, key) => (
            <UserLimitsRow
              key={key}
              currency={currency}
              onChange={onChange}
              onError={onError}
              policyLimits={policyLimits}
              userLimit={userLimit}
            />
          ))}
        </Table.Body>
      </SimpleTable>
    </TableCard>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import useTranslation from 'next-translate/useTranslation'

import type { User } from '@shared/graphql'

export interface ApprovalWarningProps {
  /**
   * Open warning modal.
   */
  open?: boolean
  /**
   * Warning variant.
   */
  action?: 'changed' | 'removed'
  /**
   * The user this warning is referring to.
   */
  user?: User
  /**
   * On-dismiss handler.
   */
  onDismiss?: () => void
}

export const ApprovalWarning: React.FC<ApprovalWarningProps> = ({
  open,
  action = 'changed',
  user,
  onDismiss,
}) => {
  const { t } = useTranslation('walletApprovalPolicy')
  const { router } = useModal()

  const goToApprovals = useCallback(
    () => router.push(routes.walletApprovalPolicy.createPolicy.approval),
    [router],
  )

  return (
    <Modal open={open} size="sm" disableClose>
      <Modal.Title>{t('permissions.approvalWarning.title')}</Modal.Title>
      <Modal.Body>
        <p className="text-center text-base text-neutral-strong font-circular-regular">
          {t('permissions.approvalWarning.body', {
            name: user?.name,
            action: t<string>(`permissions.approvalWarning.action.${action}`),
          })}
        </p>
      </Modal.Body>
      <Modal.Footer variant="stretch-equal">
        <Button onClick={onDismiss} variant="secondary">
          {t('common:dismiss')}
        </Button>
        <Button onClick={goToApprovals} variant="primary">
          {t('permissions.approvalWarning.goToApprovalStep')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { account } from './account/account'
import { bankAccount } from './bank-account/bank-account'
import { blockchain } from './blockchain/blockchain'
import { delayedWithdrawals } from './delayed-withdrawals/delayed-withdrawals'
import { developer } from './developer/developer'
import { express } from './express/express'
import { mfa } from './mfa/mfa'
import { onboarding } from './onboarding/onboarding'
import { platform } from './platform/platform'
import { promotions } from './promotions/promotions'
import { report } from './report/report'
import { transfer } from './transfer/transfer'
import { vault } from './vault/vault'
import { verite } from './verite/verite'
import { walletApprovalPolicy } from './wallet-approval-policy/wallet-approval-policy'

/**
 * Modal Routes.
 */
export const routes = {
  account,
  bankAccount,
  blockchain,
  delayedWithdrawals,
  developer,
  express,
  mfa,
  onboarding,
  platform,
  promotions,
  report,
  transfer,
  vault,
  verite,
  walletApprovalPolicy,
}

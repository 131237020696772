import { useMemo } from 'react'

import { WalletApprovalPolicyRole } from '@shared/graphql'
import Trans from 'next-translate/Trans'

import type { VaultUserPolicyRoleEnum } from '@shared/graphql'

export interface UserPermissionRoleTextProps {
  /**
   * Should keywords in the copy be bolded?
   */
  bolded?: boolean
  /**
   * Selected Permissions.
   */
  permissions: (
    | keyof typeof WalletApprovalPolicyRole
    | keyof typeof VaultUserPolicyRoleEnum
  )[]
}

export const UserPermissionRoleText: React.FC<UserPermissionRoleTextProps> = ({
  bolded = false,
  permissions,
}) => {
  const i18nKey = useMemo(() => {
    if (
      permissions.includes(WalletApprovalPolicyRole.APPROVER) &&
      permissions.includes(WalletApprovalPolicyRole.OPERATOR)
    ) {
      return 'walletApprovalPolicy:permissions.menu.options.initiateApprove'
    }

    if (permissions.includes(WalletApprovalPolicyRole.APPROVER)) {
      return 'walletApprovalPolicy:permissions.menu.options.approve'
    }

    if (permissions.includes(WalletApprovalPolicyRole.OPERATOR)) {
      return 'walletApprovalPolicy:permissions.menu.options.initiate'
    }

    return 'walletApprovalPolicy:permissions.menu.options.viewOnly'
  }, [permissions])

  return (
    <Trans
      components={{
        b: bolded ? <b /> : <span />,
      }}
      i18nKey={i18nKey}
    />
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Dashboard management permission.
 * Which users are allowed to view api-related pages (billing, platform, fraud, etc.)?
 */
export const DASHBOARD_MANAGEMENT_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [
    Role.ACCOUNT_DASHBOARD_MANAGEMENT_ROLE,
    Role.ACCOUNT_DASHBOARD_MANAGEMENT_READ_ROLE,
  ],
}

import { useContext } from 'react'

import { WithdrawContext } from './context'

/**
 * Withdraw flow hook.
 */
export const useWithdraw = () => {
  return useContext(WithdrawContext)
}

import { Observable } from '@apollo/client'

import type { ErrorHandler } from '@apollo/client/link/error'

/**
 * Handle Auth Error.
 */
export const authHandler: ErrorHandler = () => {
  // Halt operation to prevent the error to surface to the client
  return new Observable(() => {
    // Refresh the current page to trigger sign-in (with callback to the same page)
    return window.location.reload()
  })
}

import { y } from '@circlefin/form'
import { isWire } from '@services/type-guards/BankAccount'
import { currencyToToken, tokenToCurrency } from '@shared/components/common'
import { Currency, RailType } from '@shared/graphql'

import type { FxFormValues } from './provider'
import type { BankAccount, BankAccountType } from '@shared/graphql'

export const fiatCurrencyOptions = [
  Currency.USD,
  Currency.EUR,
  Currency.MXN,
  Currency.SGD,
  Currency.BRL,
]

interface GetDefaultRailProps {
  /**
   * The bank account we wish to get the default for.
   */
  account?: BankAccount
  /**
   * A boolean representing whether or not we are in the deposit flow.
   * (as opposed to the withdrawal flow).
   */
  isDepositMode?: boolean
}
/**
 * This function accepts an account and boolean representing if we are in deposit mode.
 *
 * The function returns the default rail the form should use.
 * In the case of withdrawal, the user can select their rail so we return the first rail from a list.
 * In the case of deposit the user cannot select and we return wire as this is the most general rail option.
 */
export const getDefaultRail = ({
  account,
  isDepositMode,
}: GetDefaultRailProps) => {
  if (!account) return ''

  if (isWire(account) && account.transferTypes != null) {
    if (
      isDepositMode &&
      account.transferTypes.some((rail) => rail?.type === RailType.wire)
    ) {
      return RailType.wire
    }
    return account.transferTypes[0]?.type ?? account.type
  }

  return account.type
}

interface UseRailByCurrencyProps {
  /**
   * Currency used for transfer.
   */
  currency?: Currency
  /**
   * Bank account to withdraw / transfer to.
   */
  bankAccount?: BankAccount
}

/**
 * This function takes as input an optional currency (the currency currently selected in the transfer flow so USDC or EURC)
 * and a bankaccount object.
 *
 * It returns a list of eligible rails.
 * In the case of non wire accounts, this is an array containing the bank account type (CBIT, PIX, etc.)
 * In the case of wire accounts this filters through the account objects `transferTypes` object
 * and returns a list of rails that the currency passed in is supported by.
 * If no currency is passed in no filtering is done by currency and all supported rails from the account are returned.
 *
 * This allows us to do things like only show RTP, a rail that only supports USD, when the user is on USD flows.
 */
export const getEligibleRails = ({
  currency,
  bankAccount,
}: UseRailByCurrencyProps): Array<RailType | BankAccountType> => {
  if (bankAccount == null) {
    return []
  }

  const currencyInFiat =
    currency == null ? undefined : tokenToCurrency(currency)

  if (isWire(bankAccount) && bankAccount?.transferTypes != null) {
    return bankAccount.transferTypes
      .filter((rail) => {
        // If no currency is passed in we return all possible rails.
        return currencyInFiat === undefined
          ? true
          : rail?.currencies.includes(currencyInFiat)
      })
      .map((rail) => {
        return rail?.type
      }) as RailType[]
  }

  return [bankAccount.type]
}

interface GetCurrenciesAndDefault {
  /**
   * A rail key. This is usually whatever new rail was just selected in the UI for which
   * we want to generate eligible fiat currencies for.
   */
  newRail: RailType
  /**
   * A bank account. This could be wire type or non wire type.
   * In the event it is wire type we use the given rail to determine what fiat currencies are supported.
   */
  bankAccount: BankAccount
}

/**
 * This function returns a list of currencies an account supports as well as a default option to start with.
 * This corresponds to the fiat currency dropdown selector in the UI and ensure it starts off with a valid default currency.
 */
export const getCurrenciesAndDefault = ({
  newRail,
  bankAccount,
}: GetCurrenciesAndDefault) => {
  if (!isWire(bankAccount) || bankAccount?.transferTypes == null) {
    return {
      options: fiatCurrencyOptions,
      default: fiatCurrencyOptions[0],
    }
  }

  const relevantRailInfo = bankAccount?.transferTypes.find(
    (railInfo) => railInfo?.type === newRail,
  )

  const options = fiatCurrencyOptions.filter((currencyOption) => {
    if (relevantRailInfo) {
      return relevantRailInfo.currencies.includes(currencyOption)
    }
  })

  return {
    options,
    default: options[0],
  }
}

interface validateTransferLimitsProps {
  /**
   * Amount string that represents the current user input we are validating against any existing transfer limits.
   */
  amount: string
  /**
   * Rail that is currently selected in the form.
   */
  rail: RailType
  /**
   * Bank account that is currently selected in the form.
   */
  account: BankAccount | undefined
  /**
   * Fiat currency currently selected in the form.
   */
  currency: Currency
}

export const validateTransferLimits = ({
  amount,
  rail,
  account,
  currency,
}: validateTransferLimitsProps): boolean => {
  // Return false IFF we are exceeding any transfer limits that appear in the account data.
  if (
    account !== undefined &&
    isWire(account) &&
    account.transferTypes !== undefined
  ) {
    const transferType = account.transferTypes?.find((tt) => tt?.type === rail)
    const limit = transferType?.transactionLimits?.find(
      (lim) => lim?.currency === currency,
    )

    if (limit?.limit != null) {
      const numericalLimit = parseInt(limit?.limit)
      return numericalLimit >= parseInt(amount)
    }
  }

  return true
}

/**
 * Yup validation logic used in the FX form to check amount inputs against any possible
 * transfer limits that may exist.
 */
export const transferLimitValidation = y
  .string()
  .required()
  .test({
    name: 'exceedsTransactionTypeLimit',
    exclusive: false,
    test: (value, context) => {
      const formValues = context.parent as FxFormValues
      return validateTransferLimits({
        amount: value,
        rail: formValues.rail as RailType,
        account: formValues.account!,
        currency: formValues.toCurrency,
      })
    },
  })

/**
 * Function to compare two currencies and their fiat / token equivalents.
 * Returns true if the two are equivalent currencies (Ex. USDC and USD or EURC and EUR).
 */
export const isSameCurrency = (currency1: Currency, currency2: Currency) => {
  return (
    currencyToToken(currency1) === currency2 ||
    tokenToCurrency(currency1) === currency2
  )
}

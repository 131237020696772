import { VerticalStepper } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { ProgressShellProps } from './ProgressShell'
import type { AccountSetupStep } from '../../../hooks/account-setup/context'

export interface FullStepperProps extends ProgressShellProps {
  /**
   * Determine if step is complete.
   */
  isStepComplete: (step: AccountSetupStep) => boolean
}

export const FullStepper: React.FC<FullStepperProps> = ({
  currentStep,
  isStepComplete,
}) => {
  const { t } = useTranslation('onboard/account-setup')

  return (
    <VerticalStepper isInteractive={false}>
      <VerticalStepper.Step
        label={t`navigation.steps.welcome`}
        isStepComplete
      />
      <VerticalStepper.Step
        isActive={
          currentStep === 'CONFIRM_DETAILS' || currentStep === 'CONFIRM_ROLE'
        }
        isStepComplete={isStepComplete('CONFIRM_ROLE')}
        label={t`navigation.steps.confirm`}
      >
        <VerticalStepper.Step.Child
          isActive={currentStep === 'CONFIRM_DETAILS'}
          isStepComplete={isStepComplete('CONFIRM_DETAILS')}
          label={t`navigation.steps.confirmDetails`}
        />
        <VerticalStepper.Step.Child
          isActive={currentStep === 'CONFIRM_ROLE'}
          isStepComplete={isStepComplete('CONFIRM_ROLE')}
          label={t`navigation.steps.confirmRole`}
        />
      </VerticalStepper.Step>
    </VerticalStepper>
  )
}

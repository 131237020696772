import { useCallback } from 'react'

import { Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BeneficiaryBankAccountForm } from '@features/bank-account/containers'
import { PropsErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import type { BankAccount, Currency } from '@shared/graphql'

export interface DepositInstructionQueryProps {
  /**
   * Source bank account associated with this route.
   */
  bankAccount?: BankAccount
  /**
   * Route currency.
   */
  currency?: Currency
}

export const DepositInstruction: React.FC<DepositInstructionQueryProps> = ({
  bankAccount,
  currency,
}) => {
  const { t } = useTranslation('express/modal')
  const { close } = useModal()

  const handleContinue = useCallback(() => {
    close()
  }, [close])

  return (
    <>
      <Modal.Header title={t`instructions.deposit.title`}>
        {t`instructions.deposit.subtitle`}
      </Modal.Header>
      <PropsErrorBoundary<DepositInstructionQueryProps>
        props={{ bankAccount, currency }}
        variant="page"
      >
        {({ bankAccount, currency }) => (
          <Modal.Body>
            <p className="mt-2 type-intro-sm">
              {t(
                `payments:depositFlow.beneficiaryBankAccountDetails.subHeader.${bankAccount.type}`,
              )}
            </p>
            <BeneficiaryBankAccountForm
              bankAccount={bankAccount}
              ctaButtonText={t('common:done')}
              currency={currency}
              onContinue={handleContinue}
            />
          </Modal.Body>
        )}
      </PropsErrorBoundary>
    </>
  )
}

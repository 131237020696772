import { Icon } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

export interface LevelTitleProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Level index.
   */
  levelIndex: number
  /**
   * Is edit/delete disabled for this level.
   */
  disabled?: boolean
  /**
   * Is Level currently being edited.
   */
  isEdit?: boolean
  /**
   * On edit click.
   */
  onEdit?: () => void
  /**
   * On delete click.
   */
  onDelete?: () => void
}

export const LevelTitle: React.FC<LevelTitleProps> = ({
  className,
  levelIndex,
  disabled,
  onEdit,
  onDelete,
  isEdit,
}) => {
  const { t } = useTranslation('modals.vault')

  const deleteDisabled = disabled && !isEdit

  return (
    <div className={classNames('flex justify-between items-center', className)}>
      <div className="text-xl text-black-700 font-circular-book">
        {t('createVault.approval.level.name', { number: levelIndex + 1 })}
      </div>
      <div className="flex">
        {!isEdit && (
          <Icon
            className={classNames('mr-2', {
              'text-black-300': disabled,
            })}
            name="PencilOutline"
            onClick={!disabled ? onEdit : undefined}
            size={18}
          />
        )}

        <Icon
          className={classNames({
            'text-black-300': deleteDisabled,
          })}
          name="TrashOutline"
          onClick={!deleteDisabled ? onDelete : undefined}
          size={18}
        />
      </div>
    </div>
  )
}

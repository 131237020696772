import type { PropsWithChildren } from 'react'
import type React from 'react'
import { useCallback } from 'react'

import { useOneTrust, OneTrustScript } from '@circlefin/one-trust'
import { OneTrustProvider } from '@circlefin/one-trust/react/context/provider'
import getConfig from 'next/config'
import Script from 'next/script'

import { DatadogProvider } from '../DatadogProvider/DatadogProvider'
import { HubspotProvider } from '../HubspotProvider/HubspotProvider'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

const Analytics: React.FC = () => {
  const { hasPerformance, hasTargeting } = useOneTrust()

  const HubSpot = useCallback(() => {
    if (hasPerformance && hasTargeting) {
      return <HubspotProvider />
    }
    return null
  }, [hasPerformance, hasTargeting])

  const Datadog = useCallback(() => {
    if (hasPerformance) {
      return <DatadogProvider />
    }
    return null
  }, [hasPerformance])

  if (hasPerformance) {
    return (
      <>
        <HubSpot />
        <Datadog />
      </>
    )
  }

  return null
}

export const AnalyticsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  if (!publicRuntimeConfig?.ONETRUST_TOKEN) {
    return <>{children}</>
  }

  return (
    <>
      <OneTrustScript
        component={Script}
        oneTrustToken={publicRuntimeConfig.ONETRUST_TOKEN}
      />
      <OneTrustProvider>
        <Analytics />
        {children}
      </OneTrustProvider>
    </>
  )
}

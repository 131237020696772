import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * IP allowlist settings.
 * Which users are allowed to change IP allowlist settings?
 */
export const IP_ALLOWLIST_SETTINGS_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.ACCOUNT_WEB_ROLE, Role.IP_ALLOWLIST_ROLE],
}

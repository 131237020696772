import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useDownloadWalletBackupMutation } from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import DownloadFile from '../../../images/DownloadFile.png'
import { useWalletBackup } from '../WalletBackup.Context'

import type { DownloadWalletBackupMutation } from '@shared/graphql'

export const Download: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const { router } = useModal()
  const [{ backupToken }] = useWalletBackup()

  const [DownloadWalletBackupMutation, { data, error, loading, reset }] =
    useDownloadWalletBackupMutation({
      onCompleted: (data: DownloadWalletBackupMutation) => {
        const walletBackupData = data?.downloadWalletBackup.walletBackupData
        saveToFile(walletBackupData)
        router.push({
          pathname: routes.vault.walletBackup.success,
          options: {
            disableBack: true,
          },
        })
      },
    })

  const onDownload = useCallback(() => {
    const walletBackupData = data?.downloadWalletBackup.walletBackupData
    if (walletBackupData) {
      return saveToFile(walletBackupData)
    }

    void DownloadWalletBackupMutation({
      variables: {
        backupToken: backupToken ?? '',
      },
    })
  }, [DownloadWalletBackupMutation, backupToken, data])

  const saveToFile = (walletBackupData: string) => {
    const href =
      walletBackupData != null
        ? `data:application/octet-stream;uft-8,${walletBackupData}`
        : ''
    const downloadLink = document.createElement('a')
    const fileName = 'walletBackup'

    downloadLink.href = href
    downloadLink.download = fileName
    downloadLink.click()
  }

  return (
    <FullScreen totalSteps={7}>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="text-4xl text-black-600 font-circular-bold">{t`walletBackup.title`}</h2>

          <h3 className="mt-14 w-152 text-center text-xl leading-7 font-circular-medium">
            {t`walletBackup.download.title`}
          </h3>

          <p className="mb-8 w-152 text-center text-xl leading-7 font-circular-regular">
            {t`walletBackup.download.message`}
          </p>

          <div className="size-64">
            <Image alt="Pin" src={DownloadFile} />
          </div>

          <Center className="pt-10" variant="horizontal">
            <Button
              className="w-60"
              data-testid="download-button"
              loading={loading}
              onClick={onDownload}
              variant="primary"
            >
              {t`walletBackup.download.cta`}
            </Button>
          </Center>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { useDelayedWithdrawalsSettingsQuery } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

export const NewAddressText: React.FC = () => {
  const { t } = useTranslation('common')
  const { data: { delayedWithdrawalsSettings } = {}, error } =
    useDelayedWithdrawalsSettingsQuery()

  if (!delayedWithdrawalsSettings?.enabled || error) {
    return null
  }

  return (
    <p className="text-black-400 font-circular-regular">
      {t('delayedWithdrawals.newAddressText', {
        hours: delayedWithdrawalsSettings.delayHours,
      })}
    </p>
  )
}

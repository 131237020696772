import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export const PendingApproval: React.FC = () => {
  const { t } = useTranslation('vaults')
  const { vaultMetadata } = useVaultPermission()

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata?.isCreator) return null

  /**
   * Do not return anything when vault is not in pending state.
   */
  if (
    ![
      VaultStateEnum.PENDING_APPROVAL,
      VaultStateEnum.CHANGES_PENDING_APPROVAL,
    ].includes(vaultMetadata.state)
  )
    return null

  return (
    <div className="inline-flex flex-col items-end justify-center space-y-5">
      <Button
        variant="primary"
        disabled
      >{t`buttons.vault.pendingApproval`}</Button>
      <p className="text-sm leading-4 text-black-600">
        {t(
          vaultMetadata.state === VaultStateEnum.PENDING_APPROVAL
            ? 'buttons.vault.helpText.changesBeingReviewed'
            : 'buttons.vault.helpText.changesNeedToBeApproved',
        )}
        <ModalLink
          className="ml-0.5"
          pathname={routes.vault.vaultDetails.learnMore}
        >{t`common:learn-more`}</ModalLink>
      </p>
    </div>
  )
}

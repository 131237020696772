import { useCallback, useMemo } from 'react'

import { LottieIcon, Modal, useToast } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { LoadingSpinner, TransWithLink } from '@shared/components/common'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import {
  FactorDocument,
  FactorType,
  useFactorQuery,
  useUpdatePinCodeMutation,
} from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { UpdatePinCodeInputs } from './UpdatePinCode.Inputs'

export interface UpdatePinCodeProps {
  /**
   * Should close modal after completion.
   */
  shouldCloseModal?: boolean
}

export const UpdatePinCode: React.FC<UpdatePinCodeProps> = ({
  shouldCloseModal = true,
}) => {
  const { t } = useTranslation('mfa')
  const toast = useToast()
  const modal = useModal()

  const [updatePinCode, updatePinCodeResult] = useUpdatePinCodeMutation({
    refetchQueries: [
      {
        query: FactorDocument,
        variables: {
          factorType: FactorType.PIN_CODE,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (shouldCloseModal) {
        modal.close()
      }
      toast.success(t('updatePinCode.success'))
    },
    onError: () => null,
  })

  const { data: factorData } = useFactorQuery({
    variables: {
      factorType: FactorType.PIN_CODE,
    },
  })

  const handleSubmit = useCallback(
    (oldPinCode: string, newPinCode: string) => {
      if (!factorData?.factor?.id) {
        return
      }

      void updatePinCode({
        variables: {
          input: {
            oldPinCode,
            newPinCode,
            factorId: factorData.factor.id,
          },
        },
      })
    },
    [factorData?.factor?.id, updatePinCode],
  )

  const handleReset = useCallback(() => {
    updatePinCodeResult.reset()
  }, [updatePinCodeResult])

  const body = useMemo(() => {
    if (updatePinCodeResult.loading) {
      return <LoadingSpinner />
    }

    if (updatePinCodeResult.data) {
      return <LottieIcon name="Check" />
    }

    return <UpdatePinCodeInputs handleSubmit={handleSubmit} />
  }, [updatePinCodeResult.loading, updatePinCodeResult.data, handleSubmit])

  return (
    <>
      <Modal.Header
        iconName="LockClosedSolid"
        title={t('updatePinCode.title')}
        variant="info"
      >
        {t('updatePinCode.subtitle')}
      </Modal.Header>
      <Modal.Body className="flex flex-col items-center pt-2">
        <GraphQLErrorBoundary
          error={updatePinCodeResult.error}
          retry={handleReset}
        >
          {body}
        </GraphQLErrorBoundary>
      </Modal.Body>
      <Modal.Footer className="flex justify-center">
        <TransWithLink
          i18nKey="mfa:updatePinCode.contactCustomerSupport"
          variant="support"
        />
      </Modal.Footer>
    </>
  )
}

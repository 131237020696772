import { useCallback, useEffect, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import {
  BankAccountType,
  useSetWireBankAccountMetadataMutation,
} from '@shared/graphql'

import { TransferContext, defaultValues } from './context'

import type { TransferState } from './context'

interface TransferProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Overwrite default values of context. Primarily for testing.
   */
  initValues?: Partial<TransferState>
}

/**
 * Transfer Provider.
 */
export const TransferProvider: React.FC<TransferProviderProps> = ({
  children,
  initValues,
}) => {
  const { events } = useModal()
  const [values, setValues] = useState<TransferState>({
    ...defaultValues,
    ...initValues,
  })
  const [setWireBankAccountMetadata] = useSetWireBankAccountMetadataMutation()

  const handleValueChange = useCallback((values: Partial<TransferState>) => {
    setValues((curr) => ({
      ...curr,
      ...values,
    }))
  }, [])

  const handleBankAccountMetaDataMutation = () => {
    if (
      values.bankAccount &&
      values.beneficiaryAgreement &&
      values.bankAccount.type === BankAccountType.wire
    ) {
      // the user has already confirmed to have made the transfer
      // we will "fire and forget"
      void setWireBankAccountMetadata({
        variables: {
          input: {
            metadata: {
              userConfirmedWireSend: true,
            },
            wireBankAccountId: values.bankAccount.id,
          },
        },
      })
    }
  }

  useEffect(() => {
    const resetContext = () => {
      handleBankAccountMetaDataMutation()
      handleValueChange(defaultValues)
    }

    events.on('onCloseEnd', resetContext)

    return () => {
      events.off('onCloseEnd', resetContext)
    }
  })

  return (
    <TransferContext.Provider value={[values, { handleValueChange }]}>
      {children}
    </TransferContext.Provider>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { usePolicySummaryContext } from '../PolicySummary.Context'

import type { WalletApprovalPolicyStep } from '../../../../hooks/approval-policy/context'

export interface TitleProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
  /**
   * Step name.
   */
  stepName: WalletApprovalPolicyStep
  /**
   * Show edit button.
   */
  showEdit?: boolean
}

export const Title: React.FC<TitleProps> = ({
  className,
  showEdit,
  stepName,
  children,
}) => {
  const { t } = useTranslation('common')

  const { editEnabled, onEditClick } = usePolicySummaryContext()

  const handleEditClick = useCallback(() => {
    onEditClick?.(stepName)
  }, [onEditClick, stepName])

  return (
    <div
      className={classNames(
        'flex justify-between w-full items-center',
        className,
      )}
    >
      <div className="text-base leading-6 text-neutral-strong">{children}</div>
      {editEnabled && showEdit && (
        <Button onClick={handleEditClick} size="sm" variant="text">
          {t('edit')}
        </Button>
      )}
    </div>
  )
}

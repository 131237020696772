import { DescriptionList } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { CreateVaultNavigationStep } from '../../../Create'
import { List } from '../../List/List'
import { usePolicySummaryContext } from '../../PolicySummary.Context'

const { Label, Description } = DescriptionList

export const ContractInteractions: React.FC = () => {
  const { t } = useTranslation('vaults')

  const { condensed, isWalletConnectPolicyEnabled } = usePolicySummaryContext()

  return (
    <>
      <Label className="h-full">
        {t('vault.policy.summary.labels.contractInteractions')}
      </Label>
      <Description
        className={classNames({
          'col-span-2': condensed,
          'col-span-4': !condensed,
        })}
      >
        <List hideShowAll>
          <List.Item>
            <List.Item.Title
              stepName={CreateVaultNavigationStep.WALLET_CONNECT}
              showEdit
            >
              {t(
                `vault.policy.summary.contractInteractions.${
                  isWalletConnectPolicyEnabled ? 'enabled' : 'disabled'
                }`,
              )}
            </List.Item.Title>
          </List.Item>
        </List>
      </Description>
    </>
  )
}

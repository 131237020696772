import useTranslation from 'next-translate/useTranslation'

import type { Amount } from '@shared/graphql'
import type { Maybe } from 'graphql/jsutils/Maybe'

export interface SummaryProps {
  /**
   * Amount Refunded.
   */
  amountRefunded?: Maybe<Amount>
  /**
   * Amount Received.
   */
  amountReceived?: Maybe<Amount>
  /**
   * Amount Refundable.
   */
  amountRefundable?: string
}

export const Summary: React.FC<SummaryProps> = ({
  amountRefunded,
  amountReceived,
  amountRefundable,
}) => {
  const { t } = useTranslation('platform/modals')

  return (
    <>
      <div className="mt-4 bg-black-25 text-sm">
        <div className="grid grid-cols-2" data-testid="refund-summary">
          <div className="col-span-2 ml-4 mt-4 font-circular-medium">
            <label>{t`payment-intent.refund.summary.title`}</label>
          </div>
          <div className="ml-4 mt-2" data-testid="refund-summary-line1">
            <label>{t`payment-intent.refund.summary.line1`}</label>
          </div>
          <div className="mr-4 justify-self-end">
            <label>
              <span className="ml-20 leading-7">{amountReceived?.amount}</span>
              <span className="ml-1 leading-7">{amountReceived?.currency}</span>
            </label>
          </div>
          <div className="ml-4 mt-2" data-testid="refund-summary-line2">
            <label>{t`payment-intent.refund.summary.line2`}</label>
          </div>
          <div className="mr-4 justify-self-end">
            <label>
              <span className="ml-20 leading-7">{amountRefunded?.amount}</span>
              <span className="ml-1 leading-7">{amountRefunded?.currency}</span>
            </label>
          </div>
          <div
            className="mb-4 ml-4 mt-2 font-circular-medium"
            data-testid="refund-summary-line3"
          >
            <label>{t`payment-intent.refund.summary.line3`}</label>
          </div>
          <div className="mr-4 justify-self-end font-circular-medium">
            <label>
              <span className="ml-20 leading-7">{amountRefundable}</span>
              <span className="ml-1 text-right leading-7">
                {amountReceived?.currency}
              </span>
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

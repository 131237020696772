import { useMemo } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { createFormDropdown } from '@circlefin/form/Form.Dropdown'
import { createFormInput } from '@circlefin/form/Form.Input'
import {
  WatchlistTypes,
  useCountriesQuery,
  useWatchlistEntitiesQuery,
} from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

export interface EntitiesProps {
  /**
   * Field Name.
   */
  name: string
  /**
   * The `type` to query the entities.
   */
  type?: WatchlistTypes
  /**
   * ClassName.
   */
  className?: string
}

const Dropdown = createFormDropdown()
const Input = createFormInput()

export const Entities: React.FC<EntitiesProps> = ({
  name,
  type = WatchlistTypes.aba_rtn,
  className,
}) => {
  const { t } = useTranslation('forms')

  const entities = useWatchlistEntitiesQuery({
    variables: {
      type,
    },
  })

  const countries = useCountriesQuery()

  const countryItems = useMemo(
    () =>
      (countries.data?.countries.codes ?? []).map((code) => ({
        value: code,
        label: t(`countries:${code}`),
      })),
    [countries, t],
  )

  const entityItems = useMemo(
    () =>
      (entities.data?.watchlistEntities ?? []).map(({ value }) => ({
        value,
        label:
          type === WatchlistTypes.account_type
            ? t(`dropdown.watchlist.entities.options.${value}`)
            : value,
      })),
    [entities, t, type],
  )

  const isDropdown = useMemo(
    () =>
      // country types are always a dropdown
      type === WatchlistTypes.issuer_country ||
      type === WatchlistTypes.billing_country ||
      // if there are entity items, then it's a dropdown
      entityItems.length > 0,
    [type, entityItems],
  )

  return (
    <SkeletonBox
      className={classNames('mt-6 w-full h-10', className)}
      loading={entities.loading || countries.loading}
    >
      {isDropdown ? (
        <Dropdown
          className={classNames('w-full', className)}
          items={
            type === WatchlistTypes.issuer_country ||
            type === WatchlistTypes.billing_country
              ? countryItems
              : entityItems
          }
          label={t`dropdown.watchlist.entities.label`}
          name={name}
        />
      ) : (
        <Input
          className={classNames('w-full', className)}
          label={t`dropdown.watchlist.entities.label`}
          name={name}
        />
      )}
    </SkeletonBox>
  )
}

Entities.displayName = 'Dropdown.Watchlist.Entities'

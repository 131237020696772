import { useEffect } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { TWO_FACTOR_TOKEN_STATUS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import {
  TwoFactorTokenState,
  useTwoFactorTokenStatusQuery,
} from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import ChangePin from '../../../images/ChangePin.png'
import { useWalletBackup } from '../WalletBackup.Context'

export const Pin: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const [{ twoFaToken }] = useWalletBackup()

  const { error, refetch, startPolling, stopPolling } =
    useTwoFactorTokenStatusQuery({
      variables: {
        twoFaToken: twoFaToken ?? '',
      },
      fetchPolicy: 'network-only',
      onCompleted: ({ twoFactorTokenStatus }) => {
        if (twoFactorTokenStatus.status === TwoFactorTokenState.ACCEPTED) {
          modal.router.push({
            pathname: routes.vault.walletBackup.download,
            options: {
              disableBack: true,
            },
          })
        } else if (
          twoFactorTokenStatus.status === TwoFactorTokenState.NOT_ACCEPTED ||
          twoFactorTokenStatus.status === TwoFactorTokenState.NOT_SUCCESSFUL
        ) {
          modal.router.push({
            pathname: routes.vault.walletBackup.error,
            options: {
              disableBack: true,
            },
          })
        }
      },
    })

  // TODO: manually call both startPolling and stopPolling when mount and unmount the component.
  // Problem with upgrade to React v18.cc.
  // https://github.com/apollographql/apollo-client/issues/6391
  useEffect(() => {
    // on mount start polling
    startPolling(TWO_FACTOR_TOKEN_STATUS_POLL_INTERVAL)

    return () => {
      // on unmount stop polling
      stopPolling()
    }
  }, [startPolling, stopPolling])

  return (
    <FullScreen totalSteps={7}>
      <GraphQLErrorBoundary error={error} retry={refetch} variant="page">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="text-4xl text-black-600 font-circular-bold">{t`walletBackup.title`}</h2>

          <h3 className="mb-6 mt-14 w-152 text-center text-xl leading-7 font-circular-medium">
            {t`walletBackup.pin.title`}
          </h3>

          <div className="size-64">
            <Image alt="Pin" src={ChangePin} />
          </div>
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

import { Icon, TextLink } from '@circlefin/components'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import type { Section } from '@services/sections/types'
import type { ParsedUrlQuery } from 'querystring'

export interface BackButtonProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Section.
   */
  section: Section
  /**
   * Optional query that will be passed to the link component to replace
   * route parameters if a dynamic route is used.
   */
  query?: ParsedUrlQuery
}

export const BackButton: React.FC<BackButtonProps> = ({
  children,
  section,
  query,
}) => {
  const { t } = useTranslation()

  return (
    <Link
      href={{
        pathname: section.route,
        query,
      }}
      legacyBehavior
      passHref
    >
      <TextLink.Link size="sm" variant="secondary">
        <Icon name="ChevronLeftOutline" />
        {children ?? t(section.label)}
      </TextLink.Link>
    </Link>
  )
}

import { createContext } from 'react'

import type { ConfirmFormValues } from '../../containers/AccountSetup/Confirm/Confirm.Form'
import type { ApolloError } from '@apollo/client'

export interface AccountSetupStates {
  /**
   * Indicates if a user has already confirmed their business details.
   */
  hasConfirmedDetails: boolean
  /**
   * Indicates if a user has already enrolled in MFA.
   */
  hasEnrolledInMfa: boolean
  /**
   * Indicates if a user has completed KYB verification.
   */
  hasCompletedKybVerification: boolean
  /**
   * Indicates if a user has completed all verification.
   */
  hasCompletedAllVerifications: boolean
  /**
   * Indicates that user is not the authorized representative.
   */
  isNonAuthorizedRep: boolean
  /**
   * Indicates that user is the user who initiated onboarding.
   */
  isOnboardingInitiator: boolean
  /**
   * Indicates that user is awaiting business review.
   */
  awaitingBusinessReview: boolean
}

export type AccountSetupStep =
  | 'WELCOME'
  | 'CONFIRM_DETAILS'
  | 'CONFIRM_ROLE'
  | 'INVITE_AUTH_REP'
  | 'SECURITY_DOWNLOAD'
  | 'SECURITY_ACTIVATE'

export interface AccountSetupContextState {
  /**
   * An error ocurred.
   */
  error?: ApolloError
  /**
   * Initial rerouting in progress.
   */
  rerouting: boolean
  /**
   * Is business info processing.
   */
  processingBizInfo: boolean
  /**
   * Account setup states.
   */
  accountSetupStates: AccountSetupStates
  /**
   * Business details.
   */
  businessDetails?: ConfirmFormValues
}

export interface AccountSetupContextActions {
  /**
   * Determine next step based on completed step (determine where to route user).
   */
  completeStep: (step: AccountSetupStep) => void | Promise<void>
  /**
   * Set business details.
   */
  setBusinessDetails: (values: ConfirmFormValues) => void
}

export const AccountSetupContext =
  createContext<[AccountSetupContextState, AccountSetupContextActions] | null>(
    null,
  )

import { Heading } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import {
  DeveloperSelect,
  MintSelect,
  PersonalSelect,
} from '../../../components'

export const ProductSelect: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')

  return (
    <div className="relative flex size-full flex-col items-center p-12">
      {/* Heading */}
      <Heading className="text-center" variant="page/sm">
        <Heading.Header as="h2">{t`productSelect.title`}</Heading.Header>
      </Heading>

      {/* Product Selection */}
      <div className="mt-7 flex h-[272px] flex-row flex-wrap gap-10">
        <DeveloperSelect />
        <MintSelect />
        <PersonalSelect />
      </div>
    </div>
  )
}

import { useCallback } from 'react'

import { Button } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import { VaultStateEnum } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useVaultPermission } from '../../../hooks/permission'

export const Discard: React.FC = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { vaultMetadata } = useVaultPermission()
  const { router } = useModal()

  const handleClick = useCallback(() => {
    track(SegmentEvents.DiscardDraftPolicyClicked)

    router.push({
      pathname: routes.vault.vaultDetails.discardDraftPolicy,
      query: {
        vaultId: vaultMetadata?.id,
        vaultPolicyId: vaultMetadata?.draftPolicyId,
      },
    })
  }, [router, track, vaultMetadata])

  /**
   * Do not return anything if button was not wrapped with a VaultPermissionProvider
   * or the user is not the creator of the vault.
   */
  if (!vaultMetadata?.isCreator) return null

  /**
   * Policy can be discarded when all of these are true:
   * - active policy exists
   * - draft policy exists
   * - vault is in state CHANGES_IN_PROGRESS or CHANGES_READY_FOR_APPROVAL.
   */
  if (
    !vaultMetadata.policyId ||
    !vaultMetadata.draftPolicyId ||
    ![
      VaultStateEnum.CHANGES_IN_PROGRESS,
      VaultStateEnum.CHANGES_READY_FOR_APPROVAL,
    ].includes(vaultMetadata.state)
  )
    return null

  return (
    <Button onClick={handleClick} variant="secondary">
      {t`common:discard`}
    </Button>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import { useModal } from '@circlefin/modal-router'
import { v4 as uuidv4 } from 'uuid'

import { IdempotencyKeyContext } from './context'

export interface IdempotencyKeyProviderProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Provider.
 */
export const IdempotencyKeyProvider: React.FC<IdempotencyKeyProviderProps> = ({
  children,
}) => {
  const { events } = useModal()
  const [key, setKey] = useState(uuidv4())

  const resetIdempotencyKey = useCallback(() => {
    setKey(uuidv4())
  }, [])

  useEffect(() => {
    events.on('onCloseEnd', resetIdempotencyKey)

    return () => {
      events.off('onCloseEnd', resetIdempotencyKey)
    }
  })

  /**
   * Memorize Provider Value.
   */
  const value = useMemo(
    () => ({
      idempotencyKey: key,
      resetIdempotencyKey,
    }),
    [key, resetIdempotencyKey],
  )

  return (
    <IdempotencyKeyContext.Provider value={value}>
      {children}
    </IdempotencyKeyContext.Provider>
  )
}

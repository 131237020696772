import { useCallback, useState } from 'react'

import {
  Button,
  ComponentGroup,
  FixedBanner,
  Modal,
  TextLink,
} from '@circlefin/components'
import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  /**
   * (optional) Free text "use case" string input.
   */
  useCase: y.string(),
})

export type FormValues = y.InferType<typeof schema>

export interface OtherInterestProps {
  /**
   * Modal title.
   */
  title?: string
}

export const OtherInterest: React.FC<OtherInterestProps> = ({ title }) => {
  const { t } = useTranslation('modals.onboarding')
  const { track, trackLinkClick } = useSegment()

  const [submitted, setSubmitted] = useState(false)

  const [Form, { watch, reset }] = useForm<FormValues>({
    schema,
  })

  // Form submit handler
  const handleFormSubmit = useCallback(
    (values: FormValues) => {
      track(SegmentEvents.FormSubmitted, {
        props: {
          value: values.useCase ?? '',
        },
      })

      // Reset form / clear input
      reset()

      // Open success alert
      setSubmitted(true)
    },
    [track, reset],
  )

  const handleDismiss = useCallback(() => setSubmitted(false), [])

  return (
    <>
      <Modal.Title className="px-14">
        {title ?? t('getStarted.otherInterest.title.generic')}
      </Modal.Title>
      <Modal.Body>
        <div className="my-4">
          <TextLink.Link
            href={t`getStarted.otherInterest.useCases.personal.link`}
            onClick={trackLinkClick}
            rel="noopener noreferrer"
            target="_blank"
            variant="primary"
          >
            {t`getStarted.otherInterest.useCases.personal.label`}
          </TextLink.Link>
        </div>

        <FixedBanner
          className="mb-4 text-left text-sm"
          onDismiss={handleDismiss}
          status="success"
          visible={submitted}
        >
          <span className="font-circular-bold">{t`getStarted.otherInterest.otherUseCase.alert.title`}</span>
          <span className="w-4/5 pr-2">{t`getStarted.otherInterest.otherUseCase.alert.message`}</span>
        </FixedBanner>

        <Form onSubmit={handleFormSubmit}>
          <ComponentGroup>
            <Form.Input
              label={t`getStarted.otherInterest.otherUseCase.label`}
              name="useCase"
              placeholder={t`getStarted.otherInterest.otherUseCase.placeholder`}
            />
            <Button
              className="mt-6 w-20"
              disabled={!watch('useCase')}
              type="submit"
              variant="primary"
            >{t`common:submit`}</Button>
          </ComponentGroup>
        </Form>
      </Modal.Body>
    </>
  )
}

import classnames from 'classnames'

import { MainNavProvider } from '../MainNav.Context/MainNav.Context'

import type { MainNavContextProps } from '../MainNav.Context/MainNav.Context'

export interface MainNavFrameProps extends MainNavContextProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom Style?
   */
  className?: string
}

export const MainNavFrame: React.FC<MainNavFrameProps> = ({
  variant,
  collapsed,
  className,
  children,
  ...props
}) => {
  const classes = classnames(
    'z-20 flex h-screen w-min flex-col shadow-lg',
    {
      'bg-black-700': variant === 'dark',
      'bg-black-50': variant === 'light-gray',
      'bg-white': variant === 'light',
    },
    className,
  )

  return (
    <MainNavProvider collapsed={collapsed} variant={variant}>
      <div className={classes} {...props}>
        {children}
      </div>
    </MainNavProvider>
  )
}

import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { BaseTitle } from '@modals/layout'
import { useSegment, SegmentEvents } from '@services/segment'
import { PropsErrorBoundary } from '@shared/components/errors'
import {
  CardPaymentMethodsDocument,
  WalletPaymentMethodDocument,
  useSetDefaultPaymentMethodMutation,
} from '@shared/graphql'
import { useMfa } from '@shared/mfa'
import useTranslation from 'next-translate/useTranslation'

import type { PaymentMethodType } from '@shared/graphql'

export interface SetDefaultQueryProps {
  /**
   * Payment type.
   */
  type?: PaymentMethodType.wallet | PaymentMethodType.card

  /**
   * Payment Method ID.
   */
  id?: string

  /**
   * Last 4.
   */
  last4?: string
}

export const SetDefault: React.FC<SetDefaultQueryProps> = (props) => {
  const { t } = useTranslation('modals/settings/billing')
  const [withMfa, { context, mfaSuccess, mfaError }] = useMfa()
  const modal = useModal()
  const { track } = useSegment()
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethodMutation({
    refetchQueries: [WalletPaymentMethodDocument, CardPaymentMethodsDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      mfaSuccess({ message: 'Successfully set default payment method' })
    },
    onError: (error) => {
      mfaError({ error })
    },
  })

  const closeModal = useCallback(
    () => modal.close({ context: 'onDismiss' }),
    [modal],
  )

  const onCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.CancelClicked, {
        event: e,
      })
      closeModal()
    },
    [closeModal, track],
  )

  const onConfirm = useCallback(
    (type: PaymentMethodType, id: string) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        track(SegmentEvents.SetDefaultPaymentClicked, {
          event: e,
        })
        withMfa({
          onComplete: (code) => {
            void setDefaultPaymentMethod({
              variables: {
                defaultPaymentMethodInput: {
                  type,
                  id,
                },
              },
              context: context(code),
            })
          },
          onAbort: closeModal,
        })
      },
    [closeModal, context, setDefaultPaymentMethod, track, withMfa],
  )

  return (
    <BaseTitle title={t('setDefaultPayment.title')} variant="center">
      <PropsErrorBoundary<SetDefaultQueryProps> props={props} variant="page">
        {({ type, last4, id }) => (
          <>
            <p className="text-sm leading-5 text-black-600 font-circular-regular">
              {t(`setDefaultPayment.description.${type}`, {
                last4,
              })}
            </p>

            <Modal.Footer className="mt-6" variant="stretch" dense>
              <Button onClick={onCancel} variant="secondary">
                {t('common:cancel')}
              </Button>
              <Button onClick={onConfirm(type, id)} variant="primary">
                {t('common:confirm')}
              </Button>
            </Modal.Footer>
          </>
        )}
      </PropsErrorBoundary>
    </BaseTitle>
  )
}

import { Icon } from '@circlefin/components'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { useApprovalWorkflowValidate } from '../../../../containers/CreateVault'
import { useCreateVault } from '../../../../hooks/create'

export interface HelpSectionProps {
  /**
   * Custom style.
   */
  className?: string
}

export const HelpSection: React.FC<HelpSectionProps> = ({ className }) => {
  const { t } = useTranslation('modals.vault')

  const [{ approvalWorkflow = [] }] = useCreateVault()
  const { approvers, missingApproversInWorkflow } =
    useApprovalWorkflowValidate()

  if (
    approvers.length === 0 ||
    approvalWorkflow.length === 0 ||
    missingApproversInWorkflow.size === 0
  ) {
    return null
  }

  return (
    <div
      className={classNames(
        'border-gradient from-purple-200 to-blue-300 rounded-sm border-2 p-4 w-56',
        className,
      )}
    >
      <h3 className="text-base leading-4 text-black-600 font-circular-extrablack">
        {t`createVault.approval.helpSection.title`}
      </h3>
      <p className="mt-2 text-xs text-black-600 text-opacity-70 font-circular-regular">
        {t`createVault.approval.helpSection.description`}
      </p>
      <ul className="mt-2 space-y-2">
        {approvers.map((approver) => (
          <li
            key={approver.id}
            className="flex items-center justify-start gap-x-2.5"
          >
            <div className="w-4">
              {missingApproversInWorkflow.has(approver.id) ? (
                <Icon
                  className="text-black-200"
                  name="XCircleSolid"
                  size={16}
                />
              ) : (
                <Icon
                  className="text-green-400"
                  name="CheckCircleSolid"
                  size={16}
                />
              )}
            </div>

            <div className="flex flex-col gap-y-0.5 overflow-hidden">
              <div className="truncate text-xs text-black-600 font-circular-bold">
                {approver.name}
              </div>
              <div className="truncate text-xs text-black-400 font-circular-regular">
                {approver.email}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

import type {
  BlockchainRecipientAddressWithMetadata,
  InternalWallet,
} from '@shared/graphql'

type TransferDestination =
  | InternalWallet
  | BlockchainRecipientAddressWithMetadata

/**
 * Type guard to check if a vault transfer destination is an internal wallet.
 */
export const isInternalWallet = (
  destination: TransferDestination,
): destination is InternalWallet =>
  destination.__typename === 'CircleInternalWallet' ||
  destination.__typename === 'DirectInternalWallet'

/**
 * Type guard to check if a vault transfer destination is an external address.
 */
export const isExternalAddress = (
  destination: TransferDestination,
): destination is BlockchainRecipientAddressWithMetadata =>
  destination.__typename === 'BlockchainRecipientAddressWithMetadata'

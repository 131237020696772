import { useCallback } from 'react'

import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { useCreateWalletBackupMutation } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useWalletBackup } from '../WalletBackup.Context'

import { CreatePassphraseForm } from './Form/CreatePassphraseForm'

import type { CreatePassphraseFormValues } from './Form/CreatePassphraseForm'
import type { CreateWalletBackupMutation } from '@shared/graphql'

export const CreatePassphrase: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()
  const [, { setTwoFaToken, setBackupToken }] = useWalletBackup()

  const [CreateWalletBackupMutation, { error, reset }] =
    useCreateWalletBackupMutation({
      onCompleted: (data: CreateWalletBackupMutation) => {
        setTwoFaToken(data.createWalletBackup.twoFaToken)
        setBackupToken(data.createWalletBackup.backupToken)
        modal.router.push({
          pathname: routes.vault.walletBackup.pin,
          options: {
            disableBack: true,
          },
        })
      },
      // To avoid uncaught error
      onError: () => null,
    })

  const onSubmit = useCallback(
    ({ password }: CreatePassphraseFormValues) => {
      void CreateWalletBackupMutation({
        variables: {
          securityPassphrase: password,
        },
      })
    },
    [CreateWalletBackupMutation],
  )

  return (
    <FullScreen totalSteps={7}>
      <GraphQLErrorBoundary error={error} retry={reset} variant="page">
        <Center className="m-auto justify-items-center" variant="horizontal">
          <h2 className="text-4xl text-black-600 font-circular-bold">{t`walletBackup.title`}</h2>

          <h3 className="mb-1 mt-14 text-center text-xl leading-7 font-circular-medium">
            {t`walletBackup.createPassphrase.title`}
          </h3>

          <CreatePassphraseForm onSubmit={onSubmit} />
        </Center>
      </GraphQLErrorBoundary>
    </FullScreen>
  )
}

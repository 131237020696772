import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Developer Section Permissions
 * Defines if a user has access to see and view developer page.
 */
export const DEVELOPER_QUERY: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.DEVELOPER_ROLE, Role.DEVELOPER_READ_ROLE],
}

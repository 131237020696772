import { Currency } from '@shared/graphql'

import { EURDepositInstructionsGrid } from './currency/EUR/EURDepositInstructionsGrid'
import { MXNDepositInstructionsGrid } from './currency/MXN/MXNDepositInstructionsGrid'
import { SGDDepositInstructionsGrid } from './currency/SGD/SGDDepositInstructionsGrid'
import { USDDepositInstructionsGrid } from './currency/USD/USDDepositInstructionsGrid'

import type { WireTransferInstruction } from '@shared/graphql'

export interface InstructionWithBankAccountDetailsProps {
  /**
   * Currency specific beneficiary bank account details.
   */
  currency: Currency
  /**
   * Transfer instruction.
   */
  transferInstruction?: WireTransferInstruction
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Is query loading.
   */
  loading?: boolean
}

export const InstructionWithBankAccountDetails: React.FC<InstructionWithBankAccountDetailsProps> =
  ({
    loading,
    currency,
    transferInstruction,
    virtualAccountEnabled = false,
  }) => {
    switch (currency) {
      case Currency.USD:
        return (
          <USDDepositInstructionsGrid
            bankTransferInstruction={transferInstruction}
            loading={loading}
            virtualAccountEnabled={virtualAccountEnabled}
          />
        )
      case Currency.SGD:
        return (
          <SGDDepositInstructionsGrid
            bankTransferInstruction={transferInstruction}
            loading={loading}
            virtualAccountEnabled={virtualAccountEnabled}
          />
        )
      case Currency.EUR:
        return (
          <EURDepositInstructionsGrid
            bankTransferInstruction={transferInstruction}
            loading={loading}
            virtualAccountEnabled={virtualAccountEnabled}
          />
        )
      case Currency.MXN:
        return (
          <MXNDepositInstructionsGrid
            bankTransferInstruction={transferInstruction}
            loading={loading}
            virtualAccountEnabled={virtualAccountEnabled}
          />
        )
    }
    return null
  }

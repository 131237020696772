import { useMemo } from 'react'

import { Grid, GridTitleEnum } from '../../../../../Grid/Grid'

import type { GridItem } from '../../../../../Grid/Grid'
import type { PixTransferInstruction } from '@shared/graphql'

export interface PixInstructionGridProps {
  /**
   * Bank transfer instruction details.
   */
  transferInstruction?: PixTransferInstruction
  /**
   * Common grid items.
   */
  gridItems?: GridItem[]
  /**
   * Virtual Account Enabled.
   */
  virtualAccountEnabled?: boolean
  /**
   * Controls skeleton loading state.
   */
  loading?: boolean
}

export const PixInstructionGrid: React.FC<PixInstructionGridProps> = ({
  transferInstruction,
  gridItems,
  virtualAccountEnabled = false,
  loading = false,
}) => {
  const values = useMemo(() => {
    const ispb: GridItem = [GridTitleEnum.ispb, transferInstruction?.ispb]
    const branch: GridItem = [
      GridTitleEnum.branchCode,
      transferInstruction?.branchCode,
    ]
    const number: GridItem = [
      GridTitleEnum.accountNumber,
      transferInstruction?.accountNumber,
    ]
    const name: GridItem = [
      GridTitleEnum.beneficiaryName,
      transferInstruction?.name,
    ]
    const type: GridItem = [
      GridTitleEnum.accountType,
      transferInstruction?.accountType,
    ]
    const taxId: GridItem = [GridTitleEnum.pixKey, transferInstruction?.taxId]

    return [ispb, branch, number, name, type, taxId]
  }, [transferInstruction])

  return (
    <Grid
      gridItems={gridItems != null ? gridItems.concat(values) : values}
      loading={loading}
      virtualAccountEnabled={virtualAccountEnabled}
    />
  )
}

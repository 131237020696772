import { SkeletonBox, DescriptionList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { MaybeNull } from '../../common/MaybeNull/MaybeNull'

import type { BillingDetails, Metadata } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface CustomerInfoProps {
  /**
   * Billing Details.
   */
  billingDetails?: BillingDetails
  /**
   * Metadata.
   */
  metadata?: Metadata
  /**
   * Is Loading?
   */
  loading?: boolean
}

export const CustomerInfo: React.FC<CustomerInfoProps> = ({
  billingDetails,
  metadata,
  loading = false,
}) => {
  const { t } = useTranslation('payments')

  return (
    <div className="flex gap-6">
      <DescriptionList className="w-full" data-testid="block1" density="loose">
        {/* Bank Name */}
        <Label>{t`billingDetails.name`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.name}</MaybeNull>
          </SkeletonBox>
        </Description>

        {/* Metadata */}
        {metadata?.email && metadata?.phoneNumber && (
          <>
            {/* Metadata Email */}
            <Label>{t`billingDetails.email`}</Label>
            <Description>
              <SkeletonBox className="h-6 w-full" loading={loading}>
                <MaybeNull>{metadata?.email}</MaybeNull>
              </SkeletonBox>
            </Description>

            {/* Metadata Phone */}
            <Label>{t`billingDetails.phone`}</Label>
            <Description>
              <SkeletonBox className="h-6 w-full" loading={loading}>
                <MaybeNull>{metadata?.phoneNumber}</MaybeNull>
              </SkeletonBox>
            </Description>
          </>
        )}

        {/* Address Line 1 */}
        <Label>{t`billingDetails.line1`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.line1}</MaybeNull>
          </SkeletonBox>
        </Description>
      </DescriptionList>

      <DescriptionList className="w-full" data-testid="block2" density="loose">
        {/* Address City */}
        <Label>{t`billingDetails.city`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.city}</MaybeNull>
          </SkeletonBox>
        </Description>

        {/* Address District */}
        <Label>{t`billingDetails.district`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.district}</MaybeNull>
          </SkeletonBox>
        </Description>

        {/* Address Country */}
        <Label>{t`billingDetails.country`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.country}</MaybeNull>
          </SkeletonBox>
        </Description>

        {/* Address Postal Code */}
        <Label>{t`billingDetails.postalCode`}</Label>
        <Description>
          <SkeletonBox className="h-6 w-full" loading={loading}>
            <MaybeNull>{billingDetails?.postalCode}</MaybeNull>
          </SkeletonBox>
        </Description>
      </DescriptionList>
    </div>
  )
}

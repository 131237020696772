import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * ETH blockchain EURC withdrawal access permission.
 */
export const ETH_BLOCKCHAIN_WITHDRAWAL_EURC: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [
    SynthRole.SYNTH_ETH_BLOCKCHAIN_EURC_ROLE,
    SynthRole.SYNTH_ETH_WITHDRAWAL_BLOCKCHAIN_EURC_ROLE,
  ],
}

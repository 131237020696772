import { useCallback } from 'react'

import { Button, Modal } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { TransferInformation } from '@features/blockchain/containers'
import { PropsErrorBoundary } from '@shared/components/errors'
import useTranslation from 'next-translate/useTranslation'

import type { TransferInformationProps } from '@features/blockchain/containers'

export interface WithdrawalInstructionQueryProps {
  /**
   * Source wallet's transfer information (chain, address, addressTag) associated with the route.
   */
  blockchainTransferInfo?: TransferInformationProps
}

export const WithdrawalInstruction: React.FC<WithdrawalInstructionQueryProps> =
  ({ blockchainTransferInfo }) => {
    const { t } = useTranslation('express/modal')
    const { close } = useModal()

    const handleDone = useCallback(() => {
      close()
    }, [close])

    return (
      <>
        <Modal.Header title={t`instructions.withdrawal.title`}>
          {blockchainTransferInfo &&
            t('payments:transferInstructions.shared.title', {
              blockchain: t<string>(
                `payments:chainNames.${blockchainTransferInfo.abbreviation}`,
              ),
            })}
        </Modal.Header>
        <PropsErrorBoundary<WithdrawalInstructionQueryProps>
          props={{ blockchainTransferInfo }}
        >
          {({ blockchainTransferInfo }) => (
            <>
              <Modal.Body className="flex flex-col items-center justify-center px-8">
                <TransferInformation
                  {...blockchainTransferInfo}
                  loading={false}
                  hideHeader
                />
              </Modal.Body>
              <Modal.Footer variant="center">
                <Button className="w-64" onClick={handleDone} variant="primary">
                  {t('common:done')}
                </Button>
              </Modal.Footer>
            </>
          )}
        </PropsErrorBoundary>
      </>
    )
  }

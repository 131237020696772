import { DescriptionList } from '@circlefin/components'
import { useMoney } from '@circlefin/hooks'
import useTranslation from 'next-translate/useTranslation'

import type { Amount } from '@shared/graphql'

const { Label, Description } = DescriptionList

export interface FeesProps {
  /**
   * The amount paid in fees for a transaction.
   */
  fees?: Amount | null
}

export const Fees: React.FC<FeesProps> = ({ fees }) => {
  const { t } = useTranslation('payments')
  const { money } = useMoney()
  if (!fees) {
    return null
  }
  const { amount, currency } = fees
  const formattedAmount = money({
    number: amount,
    variant: currency,
    options: {
      symbol: false,
    },
  })
  return (
    <>
      <Label className="w-64">{t('transactionDetail.fees')}</Label>
      <Description>{`${formattedAmount} ${currency}`}</Description>
    </>
  )
}

import { useMemo } from 'react'

import { FullScreen } from '@modals/layout'
import { TypeGuards } from '@services/type-guards'
import { Center } from '@shared/components/layout'
import useTranslation from 'next-translate/useTranslation'

import { useSendOnChain } from '../SendOnChain.Context'

import { CircleSourceAmount } from './CircleSourceAmount/CircleSourceAmount'
import { DirectSourceAmount } from './DirectSourceAmount/DirectSourceAmount'

export const TransferAmount: React.FC = () => {
  const { t } = useTranslation('modals.vault')
  const [{ sourceWallet }] = useSendOnChain()

  const renderSourceAmount = useMemo(() => {
    if (!sourceWallet) {
      return null
    }

    if (TypeGuards.InternalWallet.isDirect(sourceWallet)) {
      return <DirectSourceAmount />
    }

    if (TypeGuards.InternalWallet.isCircle(sourceWallet)) {
      return <CircleSourceAmount />
    }
  }, [sourceWallet])

  return (
    <FullScreen totalSteps={6}>
      <Center className="font-circular" variant="horizontal">
        <h2 className="mb-2 w-160 text-center text-4xl text-black-600 font-circular-bold">
          {t`transfer.sendOnChain.transferAmount.title`}
        </h2>
        {renderSourceAmount}
      </Center>
    </FullScreen>
  )
}

import { useMemo } from 'react'

import { VaultUserPolicyRoleEnum } from '@shared/graphql'

import { useCreateVault } from '../../../../hooks/create'

export const useUserLimitsValidate = () => {
  const [{ usersPolicy = [], policyLimits, touched }] = useCreateVault()

  const operators = useMemo(
    () =>
      usersPolicy.filter((userPolicy) =>
        userPolicy.permissions.includes(VaultUserPolicyRoleEnum.OPERATOR),
      ),
    [usersPolicy],
  )

  const allUserLimitsFieldValid = useMemo(() => {
    if (operators.length === 0) {
      return false
    }

    return operators.every(({ maxTransactions, maxAmount }) => {
      return (
        maxTransactions &&
        maxAmount &&
        parseFloat(maxTransactions) > 0 &&
        parseFloat(maxAmount) > 0
      )
    })
  }, [operators])

  const isEmpty = useMemo(() => {
    if (operators.length === 0) {
      return true
    }

    return operators.every(({ maxTransactions, maxAmount }) => {
      return !maxTransactions && !maxAmount
    })
  }, [operators])

  /**
   * Has at least one user who violates the policy volume limit.
   */
  const isOverPolicyVolumeLimit = useMemo(
    () =>
      operators.some(
        ({ maxAmount }) =>
          Number(maxAmount ?? 0) > Number(policyLimits?.maxAmount ?? 0),
      ),
    [operators, policyLimits?.maxAmount],
  )

  /**
   * Has at least one user who violates the policy transaction limit.
   */
  const isOverPolicyTransactionLimit = useMemo(
    () =>
      operators.some(
        ({ maxTransactions }) =>
          Number(maxTransactions ?? 0) >
          Number(policyLimits?.maxTransactions ?? 0),
      ),
    [operators, policyLimits?.maxTransactions],
  )

  const isValid =
    allUserLimitsFieldValid &&
    !isOverPolicyVolumeLimit &&
    !isOverPolicyTransactionLimit

  const isTouched = touched?.usersPolicy

  return {
    operators,
    isValid,
    isOverPolicyVolumeLimit,
    isOverPolicyTransactionLimit,
    isEmpty,
    enableSaveExit: !isTouched || (isTouched && isEmpty) || isValid,
  }
}

import { useCallback } from 'react'

import { Icon } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import { linkedAccounts } from '@services/sections/lib/settings'
import { routes } from '@services/sections/modal/routes'
import { Center } from '@shared/components/layout'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { ChooseRecipientForm } from './ChooseRecipient.Form'

export const ChooseRecipient: React.FC = () => {
  const { t } = useTranslation('modals.payout')
  const { router: modalRouter } = useModal()

  const handleChooseRecipient = useCallback(() => {
    modalRouter.push(routes.transfer.withdraw.review)
  }, [modalRouter])

  return (
    <FullScreen totalSteps={5}>
      <Center className="text-center font-circular" variant="horizontal">
        <h2 className="mb-2 w-176 text-2xl text-black-600 font-circular-bold">
          {t('withdraw.chooseRecipient.title')}
        </h2>

        <Center variant="horizontal">
          <h3 className="text-lg leading-6 text-black-400">
            {t('withdraw.chooseRecipient.description')}
          </h3>
        </Center>

        <Center className="mt-8" variant="horizontal">
          <div className="w-104 text-left">
            <ChooseRecipientForm onSubmit={handleChooseRecipient} />
          </div>
        </Center>

        <Center>
          <div className="mt-16 flex w-176 justify-between border-t border-black-50 pt-4">
            <div className="flex flex-col">
              <h5 className="leading-6 text-black-600 font-circular-bold">{t`withdraw.chooseRecipient.addNew.title`}</h5>
              <p className="text-black-400 font-circular-regular">
                {t('withdraw.chooseRecipient.addNew.subtitle')}
              </p>
            </div>
            <span>
              <Link href={linkedAccounts.route} legacyBehavior passHref>
                <a data-testid="add-new-address-link">
                  {t`withdraw.chooseRecipient.addNew.action`}
                  <Icon
                    className="ml-2 inline"
                    name="ChevronRightSolid"
                    size={20}
                  />
                </a>
              </Link>
            </span>
          </div>
        </Center>
      </Center>
    </FullScreen>
  )
}

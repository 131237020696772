import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Circle wallets FTU mutation.
 * Allowed users to link a bank account and to deposit from bank and blockchain wallet.
 */
export const CIRCLE_WALLETS_FTU_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.WALLETS_ROLE, Role.WIRES_ROLE],
}

import { Button, Card, Heading } from '@circlefin/components'
import { useSegment } from '@services/segment'
import getConfig from 'next/config'
import useTranslation from 'next-translate/useTranslation'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const DeveloperSelect: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  return (
    <Card className="flex h-full w-87 min-w-min flex-col justify-between">
      <Card.Content>
        <Heading variant="title/lg">
          <Heading.Header as="h3">{t`productSelect.developer.title`}</Heading.Header>
          <Heading.Intro className="type-body-sm">{t`productSelect.developer.subtitle`}</Heading.Intro>
        </Heading>

        <p className="pt-6 text-neutral type-body-sm">
          {t`productSelect.developer.description`}
        </p>
      </Card.Content>

      <Card.Footer>
        <Button.Link
          className="w-full"
          href={publicRuntimeConfig.CIRCLE_CONSOLE_SIGNUP_URL}
          onClick={trackLinkClick}
          variant="primary"
        >
          {t`productSelect.developer.cta`}
        </Button.Link>
      </Card.Footer>
    </Card>
  )
}

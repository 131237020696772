import { useCallback } from 'react'

import { Statement, Button } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { TransWithLink } from '../../../common'

/**
 * PageError: Refresh.
 */
export const Refresh: React.FC = () => {
  const { t } = useTranslation('common')

  const refresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <div data-testid="page-error-refresh">
      <Statement
        status="warning"
        subtitle={
          <TransWithLink
            i18nKey="common:pageLoadError.subtitle"
            variant="support"
          />
        }
        title={t`pageLoadError.title`}
      >
        <Button
          data-testid="refresh-button"
          onClick={refresh}
          variant="primary"
        >{t`pageLoadError.cta`}</Button>
      </Statement>
    </div>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * ROLR Redemption only account.
 */
export const ROLR_REDEMPTION_ONLY_ACCOUNT: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [Role.ROLR_REDEMPTION_ONLY_ROLE],
}

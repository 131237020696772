import { useCallback } from 'react'

import { Modal } from '@circlefin/components'
import { useModalHistory } from '@circlefin/modal-router'
import { SegmentEvents, useSegment } from '@services/segment'

export interface FullScreenProps
  extends React.ComponentProps<typeof Modal.Layout.FullScreen> {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
}

/**
 * Full-Screen Modal Layout.
 */
export const FullScreen: React.FC<FullScreenProps> = ({
  variant = 'stretch',
  children,
  className,
  totalSteps = 0,
  hideHeader = false,
  hideProgressBar = false,
  onCloseClick,
  ...props
}) => {
  const { history } = useModalHistory()
  const { track } = useSegment()

  const handleCloseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.CancelClicked, { event })
      onCloseClick?.(event)
    },
    [onCloseClick, track],
  )

  return (
    <Modal.Layout.FullScreen
      className={className}
      hideHeader={hideHeader}
      hideProgressBar={hideProgressBar}
      onCloseClick={onCloseClick ? handleCloseClick : undefined}
      stepIndex={history.length}
      totalSteps={totalSteps}
      variant={variant}
      {...props}
    >
      {children}
    </Modal.Layout.FullScreen>
  )
}

import { Card } from '@circlefin/components'
import classNames from 'classnames'

export interface ContentProps {
  /**
   * React Node Children.
   */
  children?: React.ReactNode
  /**
   * Custom style.
   */
  className?: string
}

export const Content: React.FC<ContentProps> = ({ children, className }) => {
  return (
    <Card.Content className={classNames('flex flex-col flex-1', className)}>
      {children}
    </Card.Content>
  )
}

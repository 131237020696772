import { useCallback, useEffect } from 'react'

import { SkeletonBox } from '@circlefin/components'
import { GraphQLErrorBoundary } from '@shared/components/errors'
import { useEnrollInTotpMfaMutation } from '@shared/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

export interface ActivateSecretProps {
  /**
   * Callback to set factorID after enrollment.
   */
  setFactorId: (factorId: string) => void
}

export const ActivateSecret: React.FC<ActivateSecretProps> = ({
  setFactorId,
}) => {
  const { t } = useTranslation('mfa')

  const [enrollInTotpMfa, { data, loading, error, reset }] =
    useEnrollInTotpMfaMutation({
      onCompleted: (data) => {
        const factorId = data?.enrollInTotpMfa.id
        if (factorId) {
          setFactorId(factorId)
        }
      },
      // To avoid uncaught errors
      onError: () => null,
    })

  useEffect(() => {
    // Call once when screen renders to show the secret
    void enrollInTotpMfa()
  }, [enrollInTotpMfa])

  const retry = useCallback(() => {
    reset()
    void enrollInTotpMfa()
  }, [enrollInTotpMfa, reset])

  return (
    <GraphQLErrorBoundary error={error} retry={retry}>
      {/* MFA Enrollment - QR code and shared secret */}
      <div aria-live="polite" className="mt-4 w-full" role="region">
        <SkeletonBox className="size-36" loading={loading || !data}>
          <div className="size-36">
            <Image
              alt="QR Code"
              className="-ml-4"
              height="144"
              src={data?.enrollInTotpMfa.qrHref ?? ''}
              width="144"
            />
          </div>
        </SkeletonBox>

        <p
          className="mt-2 text-neutral-subtle type-body-xs"
          data-testid="cant-scan"
        >
          <SkeletonBox className="h-10 w-58" loading={loading || !data}>
            {t`enrollment.activate.cantScan`}{' '}
            <span data-testid="shared-secret">
              {data?.enrollInTotpMfa.sharedSecret}
            </span>
          </SkeletonBox>
        </p>
      </div>
    </GraphQLErrorBoundary>
  )
}

import { useCallback } from 'react'

import { Button, Logo, TabSwitch } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { FullScreen } from '@modals/layout'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import ReactQRCode from 'qrcode.react'

import CirclePhone from '../../images/CirclePhone.png'

enum AppDownloads {
  apple = 'apple',
  google = 'google',
}

interface CybavoAppDownloadQueryProps {
  /**
   * Route to redirect to.
   */
  route?: string
  /**
   * Total step count for progress bar.
   */
  totalSteps?: number
}

export const CybavoAppDownload: React.FC<CybavoAppDownloadQueryProps> = ({
  route,
  totalSteps,
}) => {
  const { t } = useTranslation('modals.vault')
  const modal = useModal()

  const handleOnSubmit = useCallback(
    () => modal.router.push(route ?? ''),
    [modal.router, route],
  )

  return (
    <FullScreen totalSteps={totalSteps}>
      <div className="mx-auto flex h-full max-w-screen-lg justify-between">
        <div className="max-w-128">
          <h2 className="text-4xl text-black-600 font-circular-bold">
            {t`cybavoAppDownload.title`}
          </h2>
          <h3 className="mt-4 text-lg text-black-400">{t`cybavoAppDownload.subtitle`}</h3>
          <div className="mt-18 flex flex-col">
            <TabSwitch selected={AppDownloads.apple}>
              <TabSwitch.Tabs>
                <TabSwitch.Tab id={AppDownloads.apple}>
                  {t`common:appStores.apple`}
                </TabSwitch.Tab>
                <TabSwitch.Tab id={AppDownloads.google}>
                  {t`common:appStores.google`}
                </TabSwitch.Tab>
              </TabSwitch.Tabs>
              <div className="mt-5 flex w-full items-center gap-x-5 rounded-md border border-solid border-black-100 p-5">
                <Logo size={3} wordMark={false} />
                <div className="flex grow items-center justify-between gap-x-4 font-circular-medium">
                  <TabSwitch.Content id={AppDownloads.apple}>
                    {t`cybavoAppDownload.appDownload.apple`}
                    <div className="size-20 shrink-0">
                      <ReactQRCode
                        height="100%"
                        renderAs="svg"
                        value="https://apps.apple.com/app/circle-store-pay-with-usdc/id6448590621"
                        width="100%"
                      />
                    </div>
                  </TabSwitch.Content>
                  <TabSwitch.Content id={AppDownloads.google}>
                    {t`cybavoAppDownload.appDownload.google`}
                    <div className="size-20 shrink-0">
                      <ReactQRCode
                        height="100%"
                        renderAs="svg"
                        value="https://play.google.com/store/apps/details?id=com.circle.authenticator"
                        width="100%"
                      />
                    </div>
                  </TabSwitch.Content>
                </div>
              </div>
            </TabSwitch>
            <Button
              className="mt-18 max-w-64"
              onClick={handleOnSubmit}
              variant="primary"
            >
              {t('common:continue')}
            </Button>
          </div>
        </div>
        <div className="max-w-max">
          <Image alt={t`cybavoAppDownload.phoneAltText`} src={CirclePhone} />
        </div>
      </div>
    </FullScreen>
  )
}

import { useCallback } from 'react'

import { Table } from '@circlefin/components'

import { WalletApprovalPolicyPermissionMenu } from '../WalletApprovalPolicyPermissionMenu/WalletApprovalPolicyPermissionMenu'

import type {
  User,
  WalletApprovalPolicyUserLimit,
  WalletApprovalPolicyRole,
} from '@shared/graphql'

export interface PermissionRowProps {
  /**
   * User limit value.
   */
  userLimit: WalletApprovalPolicyUserLimit
  /**
   * Is owner of the policy being created.
   */
  isOwner?: boolean
  /**
   * Triggered when form gets changed.
   */
  onChange?: (value: WalletApprovalPolicyUserLimit) => void
  /**
   * Handler that will remove user from policy.
   */
  onRemove?: (user: User) => void
}

export const PermissionRow: React.FC<PermissionRowProps> = ({
  userLimit,
  isOwner = false,
  onChange,
  onRemove,
}) => {
  const onMenuItemClick = useCallback(
    (selectedPermissions: WalletApprovalPolicyRole[]) => {
      onChange?.({
        ...userLimit,
        permissions: selectedPermissions,
      })
    },
    [onChange, userLimit],
  )

  const handleRemove = useCallback(
    () => onRemove?.(userLimit.user),
    [onRemove, userLimit.user],
  )

  return (
    <Table.Body.Row data-testid="user-limit-row">
      <Table.Body.Cell className="px-4 py-5">
        {userLimit.user.name}
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        <WalletApprovalPolicyPermissionMenu
          isOwner={isOwner}
          onClick={onMenuItemClick}
          onRemove={handleRemove}
          selectedPermissions={userLimit.permissions}
        />
      </Table.Body.Cell>
    </Table.Body.Row>
  )
}

/**
 * Bank Account Routes.
 * Note:
 * - accountInformation and accountAddress are used for US bank rails.
 * - US banks link flow: location, accountInformation, accountAddress
 * - Non-US banks link flow: location, type, information, address.
 */
export const bankAccount = {
  link: {
    switch: '/bankAccount/link/',
    address: '/bankAccount/link/address',
    information: '/bankAccount/link/information',
    location: '/bankAccount/link/location',
    type: '/bankAccount/link/type',
    accountInformation: '/bankAccount/link/accountInformation',
    accountAddress: '/bankAccount/link/accountAddress',
  },
  noLinked: {
    withdraw: '/bankAccount/noLinkedAccounts/withdraw',
    wireTransfer: '/bankAccount/noLinkedAccounts/wireTransfer',
  },
  setNickname: '/bankAccount/setNickname',
}

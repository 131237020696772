import Head from 'next/head'

interface TitleProps {
  suffix?: string
  children: string
}

export const Title: React.FC<TitleProps> = ({ suffix, children }) => {
  const title = children + (suffix ? ` - ${suffix}` : '')

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="og:title" content={title} property="og:title" />
    </Head>
  )
}

import { useCallback } from 'react'

import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

/**
 * Translate Component to be displayed.
 */
export type TransComponent = (text: string, index: number) => React.ReactElement

export interface TransComponentsProps {
  /**
   * I18n key for the text.
   */
  i18nKey: string
  /**
   * I18n key with array of string.
   */
  i18nArrayKey: string
}

export const useTransComponent = (
  defaultNs: string,
  componentFn: TransComponent,
) => {
  const { t } = useTranslation(defaultNs)

  const TransComponent = useCallback(
    ({ i18nKey, i18nArrayKey }: TransComponentsProps) => {
      // Get array of link by i18key
      const links = t(i18nArrayKey, null, {
        returnObjects: true,
      })

      // We need to check if `link` is array
      // Since in unit test it won't be an array.
      return Array.isArray(links) ? (
        <Trans
          components={links.map(componentFn)}
          i18nKey={`${defaultNs}:${i18nKey}`}
        />
      ) : null
    },
    [t, defaultNs, componentFn],
  )

  return [TransComponent] as const
}

import { useMemo } from 'react'

import { TableFilters } from '@circlefin/components'
import { VaultWalletConnectActivityType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'
import type { FilterBaseProps } from '@circlefin/components/lib/TableFilters/types'

/**
 * Vault WalletConnect Activity Type Filter.
 */
export const WalletConnectActivityType: React.FC<FilterBaseProps> = (props) => {
  const { t } = useTranslation('custody/vault')

  const items: Array<
    SelectListItem<VaultWalletConnectActivityType[] | undefined>
  > = useMemo(
    () => [
      {
        value: undefined,
        label: t('activitiesTable.filter.types.allTypes'),
      },
      {
        value: [VaultWalletConnectActivityType.eth_sign],
        label: t('activitiesTable.filter.types.eth_sign'),
      },
      {
        value: [VaultWalletConnectActivityType.eth_sendRawTransaction],
        label: t('activitiesTable.filter.types.eth_sendRawTransaction'),
      },
      {
        value: [VaultWalletConnectActivityType.eth_signTransaction],
        label: t('activitiesTable.filter.types.eth_signTransaction'),
      },
      {
        value: [VaultWalletConnectActivityType.eth_signTypedData],
        label: t('activitiesTable.filter.types.eth_signTypedData'),
      },
    ],
    [t],
  )

  return <TableFilters.Dropdown {...props} items={items} />
}

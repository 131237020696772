import { useForm, y } from '@circlefin/form'
import useTranslation from 'next-translate/useTranslation'

const schema = y.object({
  /**
   * Subscription endpoint URL.
   */
  endpoint: y
    .string()
    .url()
    .matches(/^(https)/, {
      message: {
        key: 'secure-url',
      },
    })
    .required(),
})

type FormFields = y.InferType<typeof schema>

export interface CreateFormProps {
  /**
   * This method will be invoked when the form is submitted.
   */
  onSubmit: (newValues: FormFields) => void
  /**
   * Flag to indicate whether the form is in loading state.
   */
  loading: boolean
}

export const CreateForm: React.FC<CreateFormProps> = ({
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation('modals.developer')
  const [Form] = useForm<FormFields>({ schema })

  return (
    <>
      <h1 className="mb-4 mt-20 px-5 text-3xl">
        {t`createSubscription.title`}
      </h1>
      <p className="mb-9 text-center text-sm text-black-400">
        {t`createSubscription.subtitle`}
      </p>
      <Form
        className="flex w-full flex-col"
        data-testid="create-subscription-form"
        onSubmit={onSubmit}
      >
        <Form.Input
          className="mb-16 w-full"
          disabled={loading}
          label={t`createSubscription.endpointInput.label`}
          name="endpoint"
          placeholder={t`createSubscription.endpointInput.placeholder`}
        />

        <Form.SubmitButton
          className="mx-25"
          loading={loading}
          variant="primary"
        >
          {t`createSubscription.submitBtn`}
        </Form.SubmitButton>
      </Form>
    </>
  )
}

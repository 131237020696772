import { useMemo, useCallback } from 'react'

import { Button, SkeletonBox } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  ACCOUNT_DEPOSIT,
  CIRCLE_WALLETS_QUERY,
  STABLECOIN_EXPRESS_QUERY,
  ROLR_REDEMPTION_ONLY_ACCOUNT,
} from '@services/permissions'
import { expressSection } from '@services/sections/lib/express'
import { routes } from '@services/sections/modal'
import { useSegment, SegmentEvents } from '@services/segment'
import { CIRCLE_WALLET_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import { useActiveWalletApprovalPolicyQuery } from '@shared/graphql'
import { useRouter } from 'next/router'

import type { ButtonProps } from '@circlefin/components/lib/Button'
import type { Currency } from '@shared/graphql'

export interface DepositProps extends ButtonProps {
  /**
   * Is Loading?
   */
  loading?: boolean
  /**
   * Event Source.
   */
  eventSource?: 'HomePageWallet' | 'WalletDetailsPage'
  /**
   * Currency.
   */
  currency: Currency
}

export const Deposit: React.FC<DepositProps> = ({
  children,
  loading = false,
  currency,
  eventSource,
  disabled,
  ...props
}) => {
  const page = useRouter()
  const modal = useModal()
  const { track } = useSegment()

  const [, accountDepositPermission] = usePermission(ACCOUNT_DEPOSIT)
  const [, circleWalletsPermission] = usePermission(CIRCLE_WALLETS_QUERY)

  const [, { isAuthorized: isRolr }] = usePermission(
    ROLR_REDEMPTION_ONLY_ACCOUNT,
  )
  const [, { isAuthorized: hasExpressAccess }] = usePermission(
    STABLECOIN_EXPRESS_QUERY,
  )

  const policyQuery = useActiveWalletApprovalPolicyQuery({
    variables: {
      currency,
    },
    skip: !circleWalletsPermission.isAuthorized,
    pollInterval: CIRCLE_WALLET_DETAILS_POLL_INTERVAL,
    fetchPolicy: 'cache-and-network',
  })

  const canDeposit = useMemo(
    () =>
      policyQuery.data?.activeWalletApprovalPolicy?.canDeposit ??
      accountDepositPermission.isAuthorized,
    [
      policyQuery.data?.activeWalletApprovalPolicy?.canDeposit,
      accountDepositPermission.isAuthorized,
    ],
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // prevent default redirect, i.e. on homepage, by default clicking a row redirects to wallet details page
      // on clicking the deposit button, we should instead redirect to deposit flow
      e.preventDefault()
      e.stopPropagation()

      // track event
      if (eventSource === 'HomePageWallet') {
        track(SegmentEvents.HomePageWalletDepositClicked)
      } else {
        track(SegmentEvents.DepositClicked)
      }

      // Redirect ROLR user to Express page
      if (isRolr && hasExpressAccess) {
        void page.push({
          pathname: expressSection.route,
        })
      }

      // Non-ROLR user should enter the normal modal flow
      modal.router.push({
        pathname: routes.transfer.chooseDepositType,
        query: {
          currency,
        },
      })
    },
    [
      page,
      modal.router,
      isRolr,
      hasExpressAccess,
      currency,
      eventSource,
      track,
    ],
  )

  return (
    <SkeletonBox
      className="h-10 w-28"
      loading={
        loading ||
        accountDepositPermission.loading ||
        circleWalletsPermission.loading ||
        policyQuery.loading
      }
    >
      <Button
        data-testid="account-funding-deposit-button"
        disabled={disabled || !canDeposit}
        {...props}
        onClick={handleClick}
      >
        {children}
      </Button>
    </SkeletonBox>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * ETH blockchain USDC access permission.
 */
export const ETH_BLOCKCHAIN: PermissionSet = {
  access: AccessPermissions.protected,
  includeAll: [SynthRole.SYNTH_ETH_BLOCKCHAIN_ROLE],
}

export interface TabItemProps {
  /**
   * Bank type.
   */
  type: string
  /**
   * Description of the bank type.
   */
  description: string
}

export const TabItem: React.FC<TabItemProps> = ({ type, description }) => {
  return (
    <div className="w-32 flex-col">
      <p className="text-center text-neutral-strong font-circular-bold type-body-base">
        {type}
      </p>
      <p className="text-center text-neutral-subtlest type-body-sm">
        {description}
      </p>
    </div>
  )
}

import { FixedBanner, TextLink } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import type { VaultWalletWithVaultMetadata } from '@shared/graphql'

/**
 * Frozen wallet alert props.
 */
export interface FrozenWalletAlertProps {
  /**
   * Selected vault wallet object.
   */
  vaultWallet?: VaultWalletWithVaultMetadata
  /**
   * Custom className.
   */
  className?: string
}

export const FrozenWalletAlert: React.FC<FrozenWalletAlertProps> = ({
  vaultWallet,
  className,
}) => {
  const { t } = useTranslation('modals.vault')

  if (!vaultWallet) {
    return null
  }

  return (
    <FixedBanner
      className={className}
      data-testid="frozen-wallet-alert"
      status="error"
      visible={vaultWallet.frozen}
    >
      <FixedBanner.Title>{t`transfer.sendOnChain.transferRecipient.frozenAlert.title`}</FixedBanner.Title>
      <FixedBanner.Description>
        <p className="font-circular-regular">
          {t`transfer.sendOnChain.transferRecipient.frozenAlert.desc`}
        </p>
        <TextLink.Link
          href={t`transfer.sendOnChain.transferRecipient.frozenAlert.link.url`}
          rel="noopener noreferrer"
          size="sm"
          target="_blank"
          variant="primary"
        >
          {t`transfer.sendOnChain.transferRecipient.frozenAlert.link.text`}
        </TextLink.Link>
      </FixedBanner.Description>
    </FixedBanner>
  )
}

import { ButtonCard, Glyph } from '@circlefin/components'
import { useSegment } from '@services/segment'
import getConfig from 'next/config'
import useTranslation from 'next-translate/useTranslation'

import type { NextConfig } from 'next/config'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const DeveloperPlatform: React.FC = () => {
  const { t } = useTranslation('onboard/get-started')
  const { trackLinkClick } = useSegment()

  return (
    <ButtonCard.Link
      href={publicRuntimeConfig.CIRCLE_CONSOLE_SIGNUP_URL}
      onClick={trackLinkClick}
      hideArrow
    >
      <div className="flex items-center gap-4">
        <Glyph className="shrink-0" name="Web3" />
        <div>
          <h4 className="text-neutral-strong type-h-title-md">
            {t`platformSelect.options.developer.title`}
          </h4>
          <p className="text-neutral-subtle type-body-sm">
            {t`platformSelect.options.developer.description`}
          </p>
        </div>
      </div>
    </ButtonCard.Link>
  )
}

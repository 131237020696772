import { useCallback } from 'react'

import { Button, Statement } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { MultiStep } from '@modals/layout'
import { routes } from '@services/sections/modal/routes'
import { useSegment, SegmentEvents } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

export const Empty: React.FC = () => {
  const { t } = useTranslation('modals/settings/security')
  const { router } = useModal()
  const { track } = useSegment()

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track(SegmentEvents.ButtonClicked, {
        props: {
          button_label: 'Add an ip address',
        },
        event: e,
      })

      router.push(routes.account.security.ipAllowlisting.ipAddress.create)
    },
    [router, track],
  )

  return (
    <MultiStep title={t('ipAllowlisting.createIpAddress.title')}>
      <div className="grid h-full content-center">
        <Statement
          iconName="LockClosedSolid"
          subtitle={t`ipAllowlisting.createIpAddress.empty.subtitle`}
          title={t`ipAllowlisting.createIpAddress.empty.title`}
          variant="page"
        >
          <Button className="w-64" onClick={onClick} variant="secondary">
            {t`ipAllowlisting.createIpAddress.empty.ctaBtn`}
          </Button>
        </Statement>
      </div>
    </MultiStep>
  )
}

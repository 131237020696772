import { useCallback } from 'react'

import { useForm, y } from '@circlefin/form'
import { useSegment, SegmentEvents } from '@services/segment'
import { OnboardingEntityType } from '@shared/graphql'

import { useEntityType } from '../../../hooks/entity-type'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

const UNSUPPORTED_ENTITY_TYPES = [OnboardingEntityType.soleTrader]

export const entityTypeSchema = y
  .string()
  .oneOf(Object.values(OnboardingEntityType))
  .test(
    'validateEntityType',
    { key: 'onboard.unsupportedEntityType' },
    (value) => value != null && !UNSUPPORTED_ENTITY_TYPES.includes(value),
  )
  .required()

export interface EntityTypeProps {
  /**
   * Field label.
   */
  label: string
  /**
   * Field name.
   */
  name?: string
  /**
   * Optional field placeholder.
   */
  placeholder?: string
  /**
   * Force loading state.
   */
  loading?: boolean
}

export const EntityType: React.FC<EntityTypeProps> = ({
  loading = false,
  name = 'entityType',
  label,
  placeholder,
}) => {
  const { track } = useSegment()
  const [Form] = useForm({
    schema: y.object({
      [name]: entityTypeSchema,
    }),
  })
  const { data } = useEntityType()

  const handleOnChange = useCallback(
    (value: SelectListItem<OnboardingEntityType>) => {
      if (
        value?.value != null &&
        UNSUPPORTED_ENTITY_TYPES.includes(value.value)
      ) {
        track(SegmentEvents.UnsupportedEntityTypeSelected, {
          props: {
            value,
          },
        })
      }
    },
    [track],
  )

  return (
    <Form.Dropdown
      data-testid="dropdown-entity-type"
      inputLoading={loading}
      items={data}
      label={label}
      maxMenuItems={6}
      name={name}
      onChange={handleOnChange}
      placeholder={placeholder}
    />
  )
}

import { Currency } from '@shared/graphql'

import type { MoneyProps } from '@circlefin/hooks/useMoney/useMoney'
import type { Deposit, Payout } from '@shared/graphql'

/**
 * Converts a given Circle token currency to its fiat equivalent, ex. USDC to USD.
 */
export const tokenToCurrency = (currency: Currency) => {
  switch (currency) {
    case Currency.USDC:
      return Currency.USD
    case Currency.EURC:
      return Currency.EUR
    default:
      return currency
  }
}

/**
 * Converts a given fiat currency to its token equivalent, ex. USD to USDC.
 */
export const currencyToToken = (currency: Currency) => {
  switch (currency) {
    case Currency.USD:
      return Currency.USDC
    case Currency.EUR:
      return Currency.EURC
    default:
      return currency
  }
}

/**
 * This accepts a payout object and the money formatter.
 * Returns a string in the format of "$1 = 0.00058 MXN" representing the approx. FX conversion rate.
 */
export const createPayoutFxRateString = ({
  payout,
  money,
}: {
  payout?: Payout
  money: ({ number, variant, options }: MoneyProps) => string
}): string => {
  if (
    !payout?.toAmount ||
    !payout?.toAmount.amount ||
    payout?.toAmount.currency == null ||
    !payout?.amount
  ) {
    return ''
  }
  const baseAmount = '1'
  const baseCurrency = currencyToToken(payout.amount.currency)
  const baseMoney = money({
    number: baseAmount,
    variant: baseCurrency,
  })

  const toAmount = Number(payout.toAmount.amount) / Number(payout.amount.amount)
  const toCurrency = payout.toAmount.currency
  const toMoney = money({
    number: toAmount,
    variant: toCurrency,
  })
  return `${baseMoney} = ${toMoney}`
}

/**
 * This accepts a deposit object and the money formatter.
 * Returns a string in the format of "$1 = 0.00058 MXN" representing the approx. FX conversion rate.
 */
export const createDepositFxRateString = ({
  deposit,
  money,
}: {
  deposit?: Deposit
  money: ({ number, variant, options }: MoneyProps) => string
}): string => {
  if (!deposit?.fromAmount || !deposit?.amount) {
    return ''
  }
  const baseAmount = '1'
  const baseCurrency = currencyToToken(deposit.amount.currency)
  const baseMoney = money({
    number: baseAmount,
    variant: baseCurrency,
  })

  const fromAmount =
    Number(deposit.fromAmount.amount) / Number(deposit.amount.amount)
  const toCurrency = deposit.fromAmount.currency
  const toMoney = money({
    number: fromAmount,
    variant: toCurrency,
  })
  return `${baseMoney} = ${toMoney}`
}

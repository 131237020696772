import { Sidenav } from '@circlefin/components'
import { usePermission } from '@circlefin/permissions'
import { isPartOfSection } from '@services/sections'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { Section } from '@services/sections/types'

export const Item: React.FC<Section> = (section) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [{ Authorized }] = usePermission(section.permissions)

  const isActive = isPartOfSection(router.pathname, section)

  return (
    <Authorized>
      <Link href={section.route} legacyBehavior passHref>
        <Sidenav.Group.Item active={isActive}>
          {t(section.label)}
        </Sidenav.Group.Item>
      </Link>
    </Authorized>
  )
}

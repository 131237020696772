import { Icon } from '@circlefin/components'
import { useSegment } from '@services/segment'
import classNames from 'classnames'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface DescriptionProps {
  /**
   * Custom Styles.
   */
  className?: string
}

export const AddressDescription: React.FC<DescriptionProps> = ({
  className,
}) => {
  const { t } = useTranslation('modals.bankAccount')
  const { trackLinkClick } = useSegment()

  return (
    <p
      className={classNames(
        'grid grid-flow-col gap-2 auto-cols-max',
        className,
      )}
      data-testid="address-description"
    >
      <Icon className="mt-1 text-orange-400" name="ExclamationSolid" />
      <span>
        <Trans
          components={{
            bold: <strong />,
            br: <br />,
            learnMore: (
              <a
                href={t`link.address.description.learnMore`}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
            allowedCountries: (
              <a
                href={t`link.address.description.allowedCountries`}
                onClick={trackLinkClick}
                rel="noopener noreferrer"
                target="_blank"
              />
            ),
          }}
          i18nKey="modals.bankAccount:link.address.description.template"
        />
      </span>
    </p>
  )
}

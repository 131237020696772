import { useCallback, useEffect, useMemo } from 'react'

import { Table } from '@circlefin/components'
import { Input } from '@circlefin/components/lib/Input'
import { MoneyInput } from '@circlefin/components/lib/MoneyInput'
import { y } from '@circlefin/form'

import type { MoneyInputValues } from '@circlefin/components/lib/MoneyInput'
import type { VaultUserPolicy } from '@shared/graphql'

export interface UserLimitsRowProps {
  /**
   * User policy data.
   */
  userPolicy: VaultUserPolicy
  /**
   * Policy volume limit.
   */
  policyVolumeLimit: string
  /**
   * Policy transaction limit.
   */
  policyTransactionLimit: string
  /**
   * Triggered when form gets changed.
   */
  onChange: (value: VaultUserPolicy) => void
  /**
   * Triggered when there is an error.
   */
  onError: (userId: VaultUserPolicy['user']['id'], hasError: boolean) => void
}

export const UserLimitsRow: React.FC<UserLimitsRowProps> = ({
  policyVolumeLimit,
  policyTransactionLimit,
  userPolicy,
  onChange,
  onError,
}) => {
  const maxVolumeSchema = useMemo(
    () =>
      y.object({
        maxAmount: y
          .number()
          .moreThan(0)
          .max(Number(policyVolumeLimit))
          .required(),
      }),
    [policyVolumeLimit],
  )

  const maxTransactionsSchema = useMemo(
    () =>
      y.object({
        maxTransactions: y
          .number()
          .integer()
          .moreThan(0)
          .max(Number(policyTransactionLimit))
          .required(),
      }),
    [policyTransactionLimit],
  )

  const isMaxVolumeSchemaValid = useMemo(() => {
    return maxVolumeSchema.isValidSync(userPolicy)
  }, [maxVolumeSchema, userPolicy])

  const isMaxTransactionsSchemaValid = useMemo(() => {
    return maxTransactionsSchema.isValidSync(userPolicy)
  }, [maxTransactionsSchema, userPolicy])

  useEffect(() => {
    onError(
      userPolicy.user.id,
      !isMaxVolumeSchemaValid || !isMaxTransactionsSchemaValid,
    )
  }, [
    isMaxVolumeSchemaValid,
    isMaxTransactionsSchemaValid,
    onError,
    userPolicy.user.id,
  ])

  const onDailyVolumeChange = useCallback(
    ({ value }: MoneyInputValues) => {
      onChange({ ...userPolicy, maxAmount: value })
    },
    [onChange, userPolicy],
  )

  const onDailyTransactionLimitsChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        onChange({
          ...userPolicy,
          maxTransactions: e.target.value,
        })
      },
      [onChange, userPolicy],
    )

  return (
    <Table.Body.Row data-testid="user-limit-row">
      <Table.Body.Cell className="px-4 py-5">
        {userPolicy.user.name}
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        <MoneyInput
          className="w-48"
          currencyVariant="USD"
          error={!isMaxVolumeSchemaValid}
          name="maxAmount"
          onChange={onDailyVolumeChange}
          value={userPolicy.maxAmount ?? ''}
          padded
        />
      </Table.Body.Cell>
      <Table.Body.Cell className="px-4 py-5">
        <Input
          className="w-20"
          error={!isMaxTransactionsSchemaValid}
          value={userPolicy.maxTransactions ?? ''}
        >
          <Input.Field.Text
            data-testid="input-daily-transaction-limits"
            name="maxTransactions"
            onChange={onDailyTransactionLimitsChange}
          />
        </Input>
      </Table.Body.Cell>
    </Table.Body.Row>
  )
}

import { useCallback } from 'react'

import { FullScreen } from '@modals/layout'
import { PropsErrorBoundary } from '@shared/components/errors'
import { Center } from '@shared/components/layout'
import { StablecoinExpressType } from '@shared/graphql'
import useTranslation from 'next-translate/useTranslation'

import { useAddRoute } from '../AddRoute.Context'

import { DepositVariant, WithdrawalVariant } from './Variant'

import type { AddRouteContextData } from '../AddRoute.Context'

export const Review: React.FC = () => {
  const { t } = useTranslation('express/modal')
  const [data] = useAddRoute()

  const Details: React.FC<Required<AddRouteContextData>> = useCallback(
    ({ direction, source, destination }) => {
      switch (direction) {
        case StablecoinExpressType.DEPOSIT:
          return <DepositVariant destination={destination} source={source} />

        case StablecoinExpressType.WITHDRAWAL:
          return <WithdrawalVariant destination={destination} source={source} />
      }
    },
    [],
  )

  return (
    <FullScreen totalSteps={5}>
      <PropsErrorBoundary<AddRouteContextData> props={data} variant="page">
        {(data) => (
          <>
            <Center className="mb-8 text-center" variant="horizontal">
              <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
                {t`addRoute.review.title`}
              </h2>
            </Center>

            <Center variant="horizontal">
              <Details {...data} />
            </Center>
          </>
        )}
      </PropsErrorBoundary>
    </FullScreen>
  )
}

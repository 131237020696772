import { FeatureList } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

export interface WalletApprovalPolicyFeaturesProps {
  /**
   * Custom style.
   */
  className?: string
}

export const WalletApprovalPolicyFeatures: React.FC<WalletApprovalPolicyFeaturesProps> =
  ({ className }) => {
    const { t } = useTranslation('walletApprovalPolicy')

    return (
      <FeatureList
        className={className}
        direction="horizontal"
        size="lg"
        variant="frosting"
      >
        <FeatureList.Item
          heading={t`policy.features.permissions.title`}
          icon="UserGroupOutline"
        >
          {t`policy.features.permissions.description`}
        </FeatureList.Item>
        <FeatureList.Item
          heading={t`policy.features.assets.title`}
          icon="Unknown"
        >
          {t`policy.features.assets.description`}
        </FeatureList.Item>
        <FeatureList.Item
          heading={t`policy.features.workflows.title`}
          icon="ShieldCheckOutline"
        >
          {t`policy.features.workflows.description`}
        </FeatureList.Item>
      </FeatureList>
    )
  }

import { AccessPermissions } from '@circlefin/permissions'
import { Role } from '@circlefin/permissions/roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Address book mutation.
 * Allow users to add to or edit the address book.
 */
export const ADDRESS_BOOK_MUTATION: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [Role.WALLETS_ROLE, Role.ADDRESS_BOOK_ROLE],
}

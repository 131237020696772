import { Button } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import GetStartedWithVaultsImg from '@features/vault/images/GetStartedWithVaults.svg'
import { routes } from '@services/sections/modal/routes'
import classNames from 'classnames'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import type { StaticImageData } from 'next/image'

export interface SetupDeviceCTAProps {
  /**
   * Optional className that should be applied.
   */
  className?: string
  /**
   * Context of where this CTA is displayed.
   */
  context: 'vault' | 'circleWallet'
}

export const SetupDeviceCTA: React.FC<SetupDeviceCTAProps> = ({
  className,
  context,
}) => {
  const { t } = useTranslation('custody/common')

  return (
    <div
      className={classNames(
        'flex w-full items-center justify-between',
        className,
      )}
    >
      <div className="w-2/3">
        <h3 className="text-2xl leading-8 text-neutral-strong">
          {t(`setupDeviceCTA.${context}.title`)}
        </h3>
        <p className="mt-4 text-base text-neutral-strong text-opacity-70 font-circular-regular">
          {t(`setupDeviceCTA.${context}.description`)}
        </p>
        <ModalLink
          pathname={routes.vault.pairDevice.initialize}
          query={{
            successFlow:
              context === 'vault' ? 'createVault' : 'walletApprovalPolicy',
          }}
        >
          <Button className="my-5" variant="primary">
            {t`setupDeviceCTA.cta`}
          </Button>
        </ModalLink>
      </div>
      <Image
        alt={t(`setupDeviceCTA.${context}.title`)}
        src={GetStartedWithVaultsImg as StaticImageData}
      />
    </div>
  )
}

import { AccessPermissions } from '@circlefin/permissions'
import { SynthRole } from '@services/synthetic-roles'

import type { PermissionSet } from '@circlefin/permissions'

/**
 * Circle L3 Onboarding.
 */
export const L3_ONBOARDING: PermissionSet = {
  access: AccessPermissions.protected,
  includeAny: [SynthRole.SYNTH_ONBOARDING, SynthRole.SYNTH_PERIODIC_REVIEW],
}

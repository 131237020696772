import { createContext } from 'react'

import { Currency } from '@shared/graphql'

export interface CurrentCurrencyState {
  /**
   * Current Currency.
   */
  currency?: Currency
}

/**
 * Current Currency Actions.
 */
interface CurrentCurrencyActions {
  /**
   * Set transfer form state handler.
   */
  setCurrency: (state: CurrentCurrencyState) => void
  /**
   * Converts tokens to their currency counterpart, ex: USDC to USD.
   */
  tokenToCurrency: (currency: Currency) => Currency
  /**
   * Converts currency to their token counterpart, ex: USD to USDC.
   */
  currencyToToken: (currency: Currency) => Currency
}

export const defaultCurrentCurrencyValues: CurrentCurrencyState = {
  currency: Currency.USDC,
}

/**
 * Create Current Currency Context.
 */
export const CurrentCurrencyContext = createContext<
  [CurrentCurrencyState, CurrentCurrencyActions]
>([
  { ...defaultCurrentCurrencyValues },
  {
    setCurrency: () => null,
    tokenToCurrency: () => Currency.USD,
    currencyToToken: () => Currency.USDC,
  },
])

import { Menu } from '@circlefin/components'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export interface ViewerProps {
  /**
   * Is owner of the vault being created.
   */
  isOwner: boolean
  /**
   * Remove handler.
   */
  onRemove?: () => void
}

export const Viewer: React.FC<ViewerProps> = ({ isOwner, onRemove }) => {
  const { t } = useTranslation('modals.vault')

  return (
    <Menu>
      <Menu.Dropdown>
        <span>
          <Trans
            components={{
              b: <b />,
            }}
            i18nKey="modals.vault:createVault.permissions.options.viewOnly"
          />
        </span>
      </Menu.Dropdown>
      <Menu.Options anchorPosition="left" className="z-30 max-w-80">
        <Menu.Item
          icon="CheckOutline"
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey="modals.vault:createVault.permissions.options.viewOnly"
            />
          }
          variant="secondary"
        />
        <Menu.Item
          inactive={isOwner}
          label={t('createVault.permissions.options.remove')}
          onClick={onRemove}
          variant="secondary"
        />
        <Menu.Divider inactive={isOwner} />
        <Menu.Item
          key="viewerOnlyMessage"
          className="pointer-events-none"
          label={t('createVault.permissions.options.viewerOnlyMessage')}
        />
        <Menu.Item
          icon="XSolid"
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey="modals.vault:createVault.permissions.options.initiate"
            />
          }
          disabled
        />
        <Menu.Item
          icon="XSolid"
          label={
            <Trans
              components={{
                b: <b />,
              }}
              i18nKey="modals.vault:createVault.permissions.options.approve"
            />
          }
          disabled
        />
      </Menu.Options>
    </Menu>
  )
}

import { useCallback, useEffect, useState } from 'react'

import { Button, FixedBanner, SelectCard } from '@circlefin/components'
import { rolrBusinessInfoSection } from '@services/sections/lib/signup'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useRolrSetup } from '../../../hooks/rolr'

export const RolrAuthRep: React.FC = () => {
  const router = useRouter()
  const { t } = useTranslation('onboard/rolr-signup')
  const [{ authRep }, { computeNextStep }] = useRolrSetup()
  const [isAuthorizedRep, setIsAuthorizedRep] = useState<boolean | undefined>(
    authRep?.isAuthRep,
  )

  const complete = useCallback(() => {
    computeNextStep({
      step: 'authRep',
      values: {
        isAuthRep: true,
      },
    })
  }, [computeNextStep])

  const handleOnChange = useCallback(
    (isAuthRep: boolean) => () => {
      setIsAuthorizedRep(isAuthRep)
    },
    [],
  )

  useEffect(() => {
    // Prefetch next step
    void router.prefetch(rolrBusinessInfoSection.route)
  }, [router])

  return (
    <div className="h-full w-96">
      <h1 className="type-h-page-sm">{t`authRep.title`}</h1>
      <p className="mt-1 text-neutral-subtle type-intro-sm">{t`authRep.desc`}</p>
      <FixedBanner className="mt-6" status="info" multiline visible>
        <FixedBanner.Title>{t`authRep.banner.title`}</FixedBanner.Title>
        <FixedBanner.Description>{t`authRep.banner.desc`}</FixedBanner.Description>
      </FixedBanner>
      <fieldset className="mt-6 w-full">
        <legend className="min-h-6 text-neutral type-body-sm-bold">{t`authRep.yesOrNo`}</legend>
        <div className="mt-1 flex w-full gap-6 text-neutral-strong type-body-base-bold">
          <SelectCard
            checked={isAuthorizedRep === true}
            className="grow"
            id="auth-rep-yes"
            label="yes"
            name="authRepYes"
            onChange={handleOnChange(true)}
            type="radio"
            value="true"
          >{t`common:yes`}</SelectCard>
          <SelectCard
            checked={isAuthorizedRep === false}
            className="grow"
            id="auth-rep-no"
            label="no"
            name="authRepNo"
            onChange={handleOnChange(false)}
            type="radio"
            value="false"
          >{t`common:no`}</SelectCard>
        </div>
      </fieldset>
      <Button
        className="mt-6 w-full"
        disabled={isAuthorizedRep !== true}
        onClick={complete}
        variant="primary"
      >
        {t`authRep.complete`}
      </Button>
      {isAuthorizedRep === false && (
        <p className="mt-3 text-warning type-body-xs">{t`authRep.warning`}</p>
      )}
    </div>
  )
}

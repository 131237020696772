import { Icon } from '@circlefin/components'
import { ModalLink } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  CIRCLE_WALLETS_QUERY,
  CIRCLE_WALLET_APPROVAL,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { CIRCLE_WALLET_DETAILS_POLL_INTERVAL } from '@shared/apollo/pollInterval'
import {
  WalletApprovalProposalApprovalMethod,
  useWalletApprovalIncompleteDevicePairingUsersQuery,
} from '@shared/graphql'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { Currency, WalletApprovalPolicy } from '@shared/graphql'

export interface WalletApprovalDraftPolicySubmissionBlockedProps {
  /**
   * Custom style.
   */
  className?: string
  /**
   * Currency.
   */
  currency: Currency
  /**
   * Current Wallet Approval Policy.
   */
  walletApprovalPolicy?: WalletApprovalPolicy | null
}

export const WalletApprovalDraftPolicySubmissionBlocked: React.FC<WalletApprovalDraftPolicySubmissionBlockedProps> =
  ({ className, currency, walletApprovalPolicy }) => {
    const { t } = useTranslation('walletApprovalPolicy')

    const [, { isAuthorized }] = usePermission(CIRCLE_WALLETS_QUERY)
    const [, { isAuthorized: isWebAppApprovalMethodEnabled }] = usePermission(
      CIRCLE_WALLET_APPROVAL,
    )

    const { data: { walletApprovalIncompleteDevicePairingUsers } = {} } =
      useWalletApprovalIncompleteDevicePairingUsersQuery({
        variables: {
          currency,
        },
        fetchPolicy: 'cache-and-network',
        pollInterval: CIRCLE_WALLET_DETAILS_POLL_INTERVAL,
        skip: !isAuthorized,
      })

    if (
      (isWebAppApprovalMethodEnabled &&
        walletApprovalPolicy?.settings.proposalApprovalMethod !==
          WalletApprovalProposalApprovalMethod.MOBILE_APP) ||
      !walletApprovalIncompleteDevicePairingUsers ||
      walletApprovalIncompleteDevicePairingUsers.minNumOfUsersWhoMustPair === 0
    ) {
      return null
    }

    return (
      <div
        className={classNames(
          'flex items-center justify-center space-x-3 w-96 text-neutral-strong font-circular-book type-body-sm',
          className,
        )}
      >
        <Icon className="flex-none text-yellow-400" name="ExclamationSolid" />
        <p className="block w-full whitespace-normal break-words">
          {t`buttons.submissionBlocked`}
          <ModalLink
            className="ml-0.5"
            pathname={routes.walletApprovalPolicy.submissionBlocked}
            query={{
              currency,
            }}
          >{t`buttons.reviewUsers`}</ModalLink>
        </p>
      </div>
    )
  }

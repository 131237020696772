import { TabSwitch } from '@circlefin/components'
import useTranslation from 'next-translate/useTranslation'

import { PixForm } from '../../Forms'

export const BrazilVariant: React.FC = () => {
  const { t } = useTranslation('modals.bankAccount')

  return (
    <TabSwitch selected="pix">
      <div className="pointer-events-none">
        <TabSwitch.Tabs variant="underlined-black">
          <TabSwitch.Tab id="pix">
            <div className="w-152">
              <p className="text-center text-neutral-strong font-circular-bold type-body-base">
                {t(`link.accountInformation.type.pix`)}
              </p>
              <p className="text-center text-neutral-subtlest type-body-sm">
                {t(`link.accountInformation.description.instant`)}
              </p>
            </div>
          </TabSwitch.Tab>
        </TabSwitch.Tabs>
      </div>

      <div className="mt-6">
        <TabSwitch.Content id="pix">
          <PixForm />
        </TabSwitch.Content>
      </div>
    </TabSwitch>
  )
}

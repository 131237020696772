import type React from 'react'
import type { ChangeEventHandler } from 'react'
import { useCallback, useState } from 'react'

import { Button, Icon, Modal } from '@circlefin/components'
import { Input } from '@circlefin/components/lib/Input'
import { useModal } from '@circlefin/modal-router'
import { apiKeys } from '@services/sections/lib/developer'
import { useSegment, SegmentEvents } from '@services/segment'
import {
  GraphQLErrorBoundary,
  PropsErrorBoundary,
} from '@shared/components/errors'
import { ApiKeysDocument, useRevokeApiKeyMutation } from '@shared/graphql'
import { useRouter } from 'next/router'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import type { ApiKey } from '@shared/graphql'

/**
 * Revoke modal props.
 */
export interface RevokeProps {
  /**
   * The API key that will be deleted.
   */
  apiKey?: ApiKey
}

export const Revoke: React.FC<RevokeProps> = ({ apiKey }) => {
  const { t } = useTranslation('modals.developer')
  const { close } = useModal()
  const router = useRouter()
  const { track } = useSegment()
  const [deleteInput, setDeleteInput] = useState('')

  const [revokeApiKey, { loading, error, reset }] = useRevokeApiKeyMutation({
    onCompleted: () => {
      close()
      void router.push(apiKeys.route)
    },
    refetchQueries: [ApiKeysDocument],
    awaitRefetchQueries: true,
    onError: () => null,
  })

  const onDeleteInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setDeleteInput(e.target.value),
    [setDeleteInput],
  )

  const deleteBtnDisabled =
    deleteInput.trim() !== t`apiKeys.revoke.delete`.toUpperCase()

  const onCancel: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      close()
    }, [close])

  const onConfirm: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      track(SegmentEvents.ButtonClicked, {
        props: {
          button_label: 'Delete',
        },
        event: e,
      })

      if (apiKey) {
        void revokeApiKey({
          variables: {
            keyId: apiKey.keyId,
          },
        })
      }
    },
    [track, apiKey, revokeApiKey],
  )

  return (
    <>
      <Modal.Title>
        {apiKey && (
          <Icon.Circle className="mb-4" intent="error" name="TrashOutline" />
        )}
        {apiKey &&
          t('apiKeys.revoke.title', {
            keyName: apiKey?.keyName,
          })}
      </Modal.Title>
      <Modal.Body variant="center">
        <PropsErrorBoundary<RevokeProps> props={{ apiKey }} variant="page">
          {() => (
            <GraphQLErrorBoundary error={error} retry={reset} variant="page">
              <p className="mt-5 text-sm leading-6 text-black-600">
                {t`apiKeys.revoke.description`}
              </p>
              <p className="mt-2 text-sm leading-6 text-black-600">
                <Trans
                  components={[<strong key="0" className="uppercase" />]}
                  i18nKey="modals.developer:apiKeys.revoke.type"
                  values={{
                    confirmText: t<string>('apiKeys.revoke.delete'),
                  }}
                />
              </p>
              <Input
                className="mt-4 w-full"
                data-testid="revoke-confirm-input"
                value={deleteInput}
              >
                <Input.Field.Text
                  onChange={onDeleteInputChange}
                  value={deleteInput}
                />
              </Input>
              <Modal.Footer className="mt-9" variant="stretch" dense>
                <div className="grid grid-cols-3 gap-2">
                  <Button
                    data-testid="revoke-api-key-cancel-btn"
                    disabled={loading}
                    onClick={onCancel}
                    variant="secondary"
                  >
                    {t`common:cancel`}
                  </Button>
                  <Button
                    className="col-span-2"
                    data-testid="revoke-api-key-btn"
                    disabled={deleteBtnDisabled}
                    intent="destructive"
                    loading={loading}
                    onClick={onConfirm}
                    variant="primary"
                  >
                    {t`apiKeys.revoke.delete`}
                  </Button>
                </div>
              </Modal.Footer>
            </GraphQLErrorBoundary>
          )}
        </PropsErrorBoundary>
      </Modal.Body>
    </>
  )
}

import { useCallback } from 'react'

import { Button, Header } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import { usePermission } from '@circlefin/permissions'
import {
  VAULT_QUERY,
  ROLR_REDEMPTION_ONLY_ACCOUNT,
} from '@services/permissions'
import { routes } from '@services/sections/modal/routes'
import { SegmentEvents, useSegment } from '@services/segment'
import useTranslation from 'next-translate/useTranslation'

import { AccountClosureBanner } from '../../components/AccountClosureBanner/AccountClosureBanner'

import { MainWalletsOnlyNumbers } from './CustodyPageHeader.Numbers/MainWalletsOnly.Numbers'
import { VaultsEnabledNumbers } from './CustodyPageHeader.Numbers/VaultsEnabled.Numbers'

export const CustodyPageHeader: React.FC = () => {
  const { t } = useTranslation('custody/common')
  const { router } = useModal()
  const { track } = useSegment()
  const [VaultPermission] = usePermission(VAULT_QUERY)
  const [RedemptionOnlyPermission] = usePermission(ROLR_REDEMPTION_ONLY_ACCOUNT)

  const handleDepositClick = useCallback(() => {
    track(SegmentEvents.HomePageDepositClicked)
    router.push(routes.transfer.chooseWalletAndDepositType)
  }, [router, track])

  const handleTransferClick = useCallback(() => {
    track(SegmentEvents.HomePageTransferClicked)
    router.push(routes.transfer.chooseWalletAndTransferType)
  }, [router, track])

  return (
    <Header>
      <RedemptionOnlyPermission.Authorized>
        <AccountClosureBanner className="pb-8" />
      </RedemptionOnlyPermission.Authorized>
      <Header.Head title={t('title')}>
        {/* Do not show top level CTA for ROLR redemption only customers */}
        <RedemptionOnlyPermission.Unauthorized>
          <Button
            data-testid="custody-page-header-transfer"
            onClick={handleTransferClick}
            variant="secondary"
          >
            {t('firstTimeUserExperience.transfer')}
          </Button>
          <Button
            data-testid="custody-page-header-deposit"
            onClick={handleDepositClick}
            variant="primary"
          >
            {t('firstTimeUserExperience.deposit')}
          </Button>
        </RedemptionOnlyPermission.Unauthorized>
      </Header.Head>
      <VaultPermission.Authorized>
        <VaultsEnabledNumbers />
      </VaultPermission.Authorized>
      <VaultPermission.Unauthorized>
        <MainWalletsOnlyNumbers />
      </VaultPermission.Unauthorized>
    </Header>
  )
}
